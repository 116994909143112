import { Routes } from '@pro4all/shared/config';
import { RouteOption } from '@pro4all/shared/types';

import { generateRoute } from '../generateRoute';

export const getDropdownRoutes = (projectId: string | undefined) => {
  const navRoutesLookUp = projectId ? projNavRoutesLookUp : orgNavRoutesLookUp;
  const generatedRoutes = Object.keys(navRoutesLookUp).map(
    (route: keyof typeof Routes) =>
      projectId
        ? generateRoute(route, {
            params: {
              projectId,
            },
          })
        : generateRoute(route)
  );

  const currentRouteIndex = generatedRoutes.findIndex(
    (route) => route === window.location.pathname
  );

  const routeOptionsForPage = Object.values(navRoutesLookUp)[currentRouteIndex];
  return routeOptionsForPage;
};

export const orgMetadataRouteOptions: RouteOption[] = [
  {
    label: 'Metadata templates',
    route: 'metaDataDocumentsTemplates',
  },
  {
    label: 'Fields',
    route: 'metaDataDocumentsFields',
  },
];

export const projMetadataRouteOptions: RouteOption[] = [
  {
    label: 'Metadata templates',
    route: 'projectMetaDataDocumentsTemplates',
  },
  {
    label: 'Fields',
    route: 'projectMetaDataDocumentsFields',
  },
];

export const projQCRouteOptions: RouteOption[] = [
  {
    label: 'Form templates',
    route: 'projectMetaDataQualityControlForms',
  },
  { label: 'Snag templates', route: 'projectMetaDataQualityControlSnags' },
  { label: 'Fields', route: 'projectMetaDataQualityControlFields' },
  {
    label: 'Report configurations',
    route: 'projectMetaDataQualityControlReportConfigurations',
  },
];

export const orgQCRouteOptions: RouteOption[] = [
  {
    label: 'Form templates',
    route: 'metaDataQualityControlForms',
  },
  { label: 'Snag templates', route: 'metaDataQualityControlSnags' },
  { label: 'Fields', route: 'metaDataQualityControlFields' },
  {
    label: 'Report configurations',
    route: 'metaDataQualityControlReportConfigurations',
  },
];

//Here you defined which routes you would like to show in the navigation dropdown for each field.
const orgNavRoutesLookUp: {
  [key in keyof typeof Routes]?: RouteOption[];
} = {
  metaDataDocumentsFields: orgMetadataRouteOptions,
  metaDataDocumentsTemplates: orgMetadataRouteOptions,
  metaDataQualityControl: orgQCRouteOptions,
  metaDataQualityControlFields: orgQCRouteOptions,
  metaDataQualityControlForms: orgQCRouteOptions,
  metaDataQualityControlReportConfigurations: orgQCRouteOptions,
  metaDataQualityControlSnags: orgQCRouteOptions,
};

const projNavRoutesLookUp: {
  [key in keyof typeof Routes]?: RouteOption[];
} = {
  projectMetaDataDocumentsFields: projMetadataRouteOptions,
  projectMetaDataDocumentsTemplates: projMetadataRouteOptions,
  projectMetaDataQualityControlFields: projQCRouteOptions,
  projectMetaDataQualityControlForms: projQCRouteOptions,
  projectMetaDataQualityControlReportConfigurations: projQCRouteOptions,
  projectMetaDataQualityControlSnags: projQCRouteOptions,
};

export const dropdownNavRouteOptions = {
  orgMetadataRouteOptions,
  orgQCRouteOptions,
  projMetadataRouteOptions,
  projQCRouteOptions,
};
