import { useTranslation } from 'react-i18next';

import { SavedAnswers, SavedAnswersInstance } from '@pro4all/graphql';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps, SelectedIdName } from '@pro4all/shared/types';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/general';

export const useSavedAnswersActions = ({
  returnAll = false,
  selectedSavedAnswersInstance,
}: {
  returnAll?: boolean;
  selectedSavedAnswersInstance?: SavedAnswersInstance;
}) => {
  const { searchParams } = useRouting();
  const { contextMenuRow } = useTableContextMenuRowContext<SavedAnswers>();
  const routeCreate = searchParams.is('action', 'createSavedAnswer');
  const routeView = searchParams.is('action', 'viewSavedAnswer');

  const { t } = useTranslation();

  const { setLocalStorageItem: setSavedAnswersNameToDeleteInLs } =
    useLocalStorage<SelectedIdName[]>({
      key: `prostream-saved-answers-name-to-delete`,
    });

  const deleteAction: ActionProps = {
    ariaLabel: t('Delete'),
    dataTestId: 'delete-saved-answers',
    key: 'delete-saved-answer',
    label: t('Delete'),
    onClick: () => {
      const { id, name } = selectedSavedAnswersInstance || contextMenuRow || {};
      if (id && name) {
        setSavedAnswersNameToDeleteInLs([{ id, name }]);
        searchParams.set({
          deleteSavedAnswers: 'true',
        });
      }
    },
    startIcon: 'delete',
  };

  const editAction: ActionProps = {
    ariaLabel: t('Edit'),
    dataTestId: 'edit-saved-answers',
    key: 'edit-saved-answers',
    label: t('Edit'),
    onClick: () => {
      const { id } = selectedSavedAnswersInstance || contextMenuRow || {};
      searchParams.set({
        action: 'editSavedAnswer',
        id,
      });
    },

    startIcon: 'edit',
  };

  const altActions =
    returnAll || routeView
      ? [editAction, deleteAction]
      : routeCreate
      ? []
      : [deleteAction];

  return altActions;
};
