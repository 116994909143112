import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const SquareMinus: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M16.6665 1.66675H6.6665C5.74984 1.66675 4.99984 2.41675 4.99984 3.33341V13.3334C4.99984 14.2501 5.74984 15.0001 6.6665 15.0001H16.6665C17.5832 15.0001 18.3332 14.2501 18.3332 13.3334V3.33341C18.3332 2.41675 17.5832 1.66675 16.6665 1.66675ZM2.49984 5.00008C2.0415 5.00008 1.6665 5.37508 1.6665 5.83342V16.6667C1.6665 17.5834 2.4165 18.3334 3.33317 18.3334H14.1665C14.6248 18.3334 14.9998 17.9584 14.9998 17.5001C14.9998 17.0417 14.6248 16.6667 14.1665 16.6667H4.1665C3.70817 16.6667 3.33317 16.2917 3.33317 15.8334V5.83342C3.33317 5.37508 2.95817 5.00008 2.49984 5.00008ZM12.4998 9.16675H14.9998C15.4582 9.16675 15.8332 8.79175 15.8332 8.33342C15.8332 7.87508 15.4582 7.50008 14.9998 7.50008H12.4998V5.00008C12.4998 4.54175 12.1248 4.16675 11.6665 4.16675C11.2082 4.16675 10.8332 4.54175 10.8332 5.00008V7.50008H8.33317C7.87484 7.50008 7.49984 7.87508 7.49984 8.33342C7.49984 8.79175 7.87484 9.16675 8.33317 9.16675H10.8332V11.6667C10.8332 12.1251 11.2082 12.5001 11.6665 12.5001C12.1248 12.5001 12.4998 12.1251 12.4998 11.6667V9.16675Z"
      fill="#585858"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.48148 1.66675H16.8519C17.6667 1.66675 18.3333 2.33341 18.3333 3.14823V13.5186C18.3333 14.3334 17.6667 15.0001 16.8519 15.0001H6.48148C5.66667 15.0001 5 14.3334 5 13.5186V3.14823C5 2.33341 5.66667 1.66675 6.48148 1.66675ZM8.7037 9.07416H14.6296C15.037 9.07416 15.3704 8.74082 15.3704 8.33342C15.3704 7.92601 15.037 7.59267 14.6296 7.59267H8.7037C8.2963 7.59267 7.96296 7.92601 7.96296 8.33342C7.96296 8.74082 8.2963 9.07416 8.7037 9.07416Z"
      fill="#585858"
      fillRule="evenodd"
    />
  </SvgIcon>
);
