import { useTranslation } from 'react-i18next';

import {
  SegmentType,
  StandardItemKey,
  useDownloadFilenameCharactersQuery,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { IconName } from '@pro4all/shared/ui/icons';

import { useFieldConfig } from '../../configs/useFieldConfig';
import { fieldCardsGlobalInfo } from '../fieldCardsGlobalInfo';
import { useFieldContext } from '../TemplateMutationContext';

import { Segment } from './configureDownloadNameReducer';

export const useGetLabelAndIcon = () => {
  const { t } = useTranslation();

  const { data } = useDownloadFilenameCharactersQuery({
    fetchPolicy: 'cache-only',
  });

  const { fieldDefinitions, reusableFields, reusableFieldsStandard } =
    useFieldContext();

  const nameStandardFieldDefinition = useActionNamingMapping();

  const inlineFields = fieldDefinitions.filter(
    (fieldDefinition) => !fieldDefinition.fieldDefinitionId
  );

  const fieldConfig = useFieldConfig();

  const showDisplayName = localStorage.getItem(
    StorageKeys.TEMPLATE_DISPLAY_NAME
  );
  const labelPropCustomReusable =
    showDisplayName === 'true' ? 'displayName' : 'name';

  const getLabelAndIcon = (segment: Segment) => {
    let label = '';
    let startIcon: IconName = null;
    switch (segment.type) {
      case SegmentType.Character:
        label =
          data?.downloadFilenameCharacters.find(
            (character) => character.id === segment.id
          )?.name || '';
        break;
      case SegmentType.CustomInline:
        label =
          inlineFields.find((field) => field.id === segment.id)?.name || '';
        startIcon =
          fieldConfig[
            inlineFields.find((field) => field.id === segment.id)?.type
          ]?.icon;
        break;
      case SegmentType.CustomReusable:
        label =
          reusableFields.find((field) => field.id === segment.id)?.[
            labelPropCustomReusable
          ] ||
          fieldDefinitions.find(
            (field) => field.fieldDefinitionId === segment.id
          )?.[labelPropCustomReusable] || //TODO
          '';
        startIcon =
          fieldConfig[
            reusableFields.find((field) => field.id === segment.id)?.type
          ]?.icon ||
          fieldConfig[
            fieldDefinitions.find(
              (field) => field.fieldDefinitionId === segment.id
            )?.type
          ]?.icon;
        break;
      case SegmentType.Standard:
        label = t(
          fieldCardsGlobalInfo[
            reusableFieldsStandard.find((field) => field.id === segment.id)
              ?.name as StandardItemKey
          ].label,
          nameStandardFieldDefinition(
            reusableFieldsStandard.find((field) => field.id === segment.id)
              ?.name as StandardItemKey
          )
        );
        startIcon =
          fieldCardsGlobalInfo[
            reusableFieldsStandard.find((field) => field.id === segment.id)
              ?.name as StandardItemKey
          ].icon;
        break;
    }
    return { label, startIcon };
  };
  return getLabelAndIcon;
};
