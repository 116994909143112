import { useEffect } from 'react';

import { OFFICE_ICON_BY_EXTENSION } from '@pro4all/shared/utils';

interface ThemeProps {
  theme: string;
}

const useBrowserTabStyle = ({ theme }: ThemeProps) => {
  const extension = getCurrentExtension();

  const prostreamConfig = {
    favicon: 'assets/favicons/prostreamFavicon.ico',
    title: 'Prostream',
  };

  const tbqConfig = {
    favicon: 'assets/favicons/tbqFavicon.ico',
    title: 'TBQ, tools 4 building quality',
  };

  const officeConfig = {
    favicon: `assets/favicons/${OFFICE_ICON_BY_EXTENSION[extension]}Favicon.svg`,
    title: prostreamConfig.title,
  };

  const themeConfig =
    theme === 'tbq' ? tbqConfig : extension ? officeConfig : prostreamConfig;
  useEffect(() => {
    document.title = themeConfig.title;
    const link =
      (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
      document.createElement('link');
    link.type = `image/svg+xml`;
    link.rel = 'shortcut icon';
    link.href = themeConfig.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [theme, themeConfig.favicon, themeConfig.title]);
};

const getCurrentExtension = () => {
  const path = window.location.href;
  const extension = path.split('extension=')[1];
  return extension;
};

export default useBrowserTabStyle;
