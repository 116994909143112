import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { useSideNavContext } from '@pro4all/shared/contexts';
import { Box, ListItemText } from '@pro4all/shared/mui-wrappers';
import {
  generateRoute,
  getDropdownRoutes,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import { Count, NotiDot, StyledListItemIcon, StyledTooltip } from './Styled';

interface NavItemProps extends NavLinkProps {
  color?: string;
  count?: number;
  dataTestId?: string;
  iconName?: IconName | null;
  label?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  count = 0,
  exact = false,
  iconName,
  label = '',
  onClick,
  to,
  dataTestId,
}) => {
  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();
  const {
    params: { projectId },
  } = useRouting();

  const isActive = () => {
    const dropdownRoutesForNavItem = getDropdownRoutes(projectId);

    return dropdownRoutesForNavItem?.some(
      (route) =>
        (projectId
          ? generateRoute(route.route, { params: { projectId } })
          : Routes[route.route]) === to
    );
  };
  return (
    <NavLink
      className={`nav-link nav-item ${isActive() ? 'active' : ''}`}
      data-testid={dataTestId}
      exact={exact}
      onClick={onClick}
      to={to}
    >
      <StyledTooltip
        $compact={!isSideNavOpen}
        placement="right"
        title={isSideNavOpen ? '' : label}
      >
        {isSideNavOpen ? (
          <>
            <StyledListItemIcon>
              {iconName && <Icon iconName={iconName} />}
            </StyledListItemIcon>
            {label ? (
              <Box pl="6px">
                <Text variant="h6">
                  <ListItemText disableTypography primary={label} />
                </Text>
              </Box>
            ) : null}
            {count > 0 ? <Count label={count} size="small" /> : null}
          </>
        ) : (
          <StyledListItemIcon>
            {!isSideNavOpen && count > 0 ? <NotiDot /> : null}
            {iconName && <Icon iconName={iconName} />}
          </StyledListItemIcon>
        )}
      </StyledTooltip>
    </NavLink>
  );
};
