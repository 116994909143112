import { TemplateService, TemplateState, TemplateType } from '@pro4all/graphql';

import { useTemplatesInclude } from './useTemplatesInclude';

export const useTBQTemplates = ({
  scopeId = null,
  templateState = null,
}: {
  scopeId?: string;
  templateState?: TemplateState;
}) => {
  const templateType = TemplateType.Tbq;
  const { loading, selectTemplate, templates } = useTemplatesInclude({
    globalId: scopeId,
    includeCreatedAt: true,
    includeCreatedBy: true,
    includeOrgItems: false,
    includeState: true,
    skip: !templateType || !scopeId,
    state: templateState,
    templateService: TemplateService.QualityControl,
    templateType,
  });

  return {
    loading,
    selectTemplate,
    templates,
  };
};
