import React from 'react';
import { RenderElementProps } from 'slate-react';

import { ConditionalTooltip } from '@pro4all/shared/ui/tooltip';
import { isDefined } from '@pro4all/shared/utils';

import {
  GroupMentionElement,
  ImageElement,
  MentionElement,
  MentionType,
} from './CustomTypes';
import { MentionLabel } from './EditorStyles';

interface Props extends Omit<RenderElementProps, 'element'> {
  element: MentionElement | GroupMentionElement | ImageElement;
}

export const Mention = ({ attributes, children, element }: Props) => {
  const listMembers = () => {
    if (element.type === MentionType.group) {
      const members =
        (element as GroupMentionElement).members?.filter(isDefined) || [];
      const list: string =
        members.map((user) => user.displayName).join(', ') || '';
      return list;
    }
    return '';
  };
  // TODO: @Wait for future tooltip for groups story where BE will process mentions
  // const showTooltip = Boolean(element.type === MentionType.group);
  return (
    <ConditionalTooltip
      options={{
        title: listMembers(),
      }}
      // TODO: @Wait for future tooltip for groups story where BE will process mentions
      // showTooltip={showTooltip}
    >
      <MentionLabel {...attributes} contentEditable={false}>
        @{(element as MentionElement | GroupMentionElement).displayName}
        {children}
      </MentionLabel>
    </ConditionalTooltip>
  );
};
