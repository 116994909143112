import React, { InputHTMLAttributes, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

const StyledCheckbox = styled(MuiCheckbox)`
  && {
    color: ${customColors.grey500};

    &:hover {
      color: ${({ theme }) => theme.palette.success.main};
    }

    /* :disabled pseudo-selector does not work, since we're actually
   * styling a span element. */
    &[aria-disabled='true'] {
      color: ${customColors.grey300};
    }
  }
`;

export const Checkbox: React.FC<CheckboxProps> = (props) => (
  <StyledCheckbox {...props} size="medium" />
);

const StyledNativeCheckbox = styled.input.attrs<{ type: string }>({
  type: 'checkbox',
})`
  display: block;
  margin: 0 auto;
  transform: scale(1.2);
  :not(:disabled) {
    cursor: pointer;
  }
  :not(:disabled):checked {
    filter: hue-rotate(310deg) brightness(1.35);
  }
  outline: none;
`;

export const NativeCheckbox: React.FC<
  InputHTMLAttributes<HTMLInputElement> & {
    children?: never;
    indeterminate?: boolean;
  }
> = ({ indeterminate, ...rest }) => {
  const input = useRef(null);

  useLayoutEffect(() => {
    if (input) input.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return <StyledNativeCheckbox {...rest} ref={input} />;
};
