import { DocumentService } from '@pro4all/documents/data-access';
import { Document, DocumentVersion } from '@pro4all/graphql';

export async function documentToAttachment(
  { name, downloadName, extension, id, versionId }: Document | DocumentVersion,
  versions: DocumentVersion[]
) {
  const downloadUrlResult = await DocumentService.getDownloadUrl({
    documentId: id,
    id: versionId || '',
  });

  const documentName = downloadName ?? name;
  const fileName = DocumentService.createFileName({
    extension,
    name: documentName || '',
  });
  const fileSize =
    versions?.find((version) => version?.id === versionId)?.fileSize || 0;

  return {
    documentId: id,
    documentVersionId: versionId,
    fileId: /(\w{4,12}-?){5}/.exec(downloadUrlResult.data)?.[0],
    fileName,
    fileSize,
  };
}
