import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';

import { SelectOption } from './CustomSelection';
import { Status } from './CustomStatus';
import { DisplayTypeValues, FormFields, TypeProps } from './Types';

export const getInitialValues = ({
  fieldType,
  hierarchyListOptions,
  selectedField,
  typeOptions,
}: {
  fieldType: ValueTypeName;
  hierarchyListOptions: Option[];
  selectedField?: FieldDefinition;
  typeOptions: TypeProps[];
}): FormFields => {
  const rangeEnabled =
    selectedField?.valueType?.maxValue - selectedField?.valueType?.minValue > 0;

  const customHierarchyListInitialValues = {
    hierarchyList:
      hierarchyListOptions.find(
        (option) => option.id === selectedField?.valueType?.hierarchyListId
      ) || (null as Option),
    multipleAnswers: selectedField?.valueType?.multipleAnswers || false,
    staticBreadcrumbs: selectedField?.valueType?.staticBreadcrumbs || false,
  };

  const customNumberInitialValues = {
    maxValue: selectedField?.valueType?.maxValue?.toString() || '',
    minValue: selectedField?.valueType?.minValue?.toString() || '',
    rangeEnabled: rangeEnabled || false,
  };

  const customStatusInitialValues = {
    statusOptions: (selectedField?.valueType?.options || []) as Status[],
  };

  const customSelectionInitialValues = {
    multiSelect: selectedField?.valueType?.multiSelect || false,
    selectionOptions: (selectedField?.valueType?.options ||
      []) as SelectOption[],
  };

  const savedTypeOption = typeOptions?.find(
    (t) => t?.id === selectedField?.type
  );

  // Initial value is a concatenation of the basic initial values and the type related initial values.
  return {
    description: selectedField?.description || '',
    displayDescription: selectedField?.displayDescription || '',
    displayName: selectedField?.displayName || '',
    displayType:
      selectedField?.valueType.displayType || DisplayTypeValues.dropdown,
    name: selectedField?.name || '',
    type: savedTypeOption || (null as TypeProps),
    ...customHierarchyListInitialValues,
    ...customNumberInitialValues,
    ...(fieldType === 'Selection' || selectedField?.type === 'Selection'
      ? customSelectionInitialValues
      : customStatusInitialValues),
  };
};
