import { BasePropsHierarchyItem, ItemProps } from '../types';

export const changeItem = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  item,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'item'>) => {
  const otherItems = allItems.filter(
    (currentItem) => currentItem.id !== item.id
  );
  return [...otherItems, item];
};
