import React, { createContext, useContext, useState } from 'react';

import { FilterBaseProps } from '@pro4all/shared/search-utils';

import { getContextMenuEvent } from '../filters/utils/getContextMenuEvent';

interface FilterMenuContextValue {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLLIElement>
  ) => void;
  open: boolean;
  selectedFilterMenu: FilterBaseProps | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFilterMenu: React.Dispatch<
    React.SetStateAction<FilterBaseProps | null>
  >;
}

const FilterMenuContext = createContext({} as FilterMenuContextValue);

export const useFilterMenuContext = () =>
  useContext<FilterMenuContextValue>(FilterMenuContext);

export function FilterMenuProvider({ children }: { children: JSX.Element }) {
  const [open, setOpen] = useState(false);
  const [selectedFilterMenu, setSelectedFilterMenu] =
    useState<FilterBaseProps | null>(null);

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLLIElement>
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const evt = getContextMenuEvent(rect);
    event.currentTarget.dispatchEvent(evt);
    setOpen(true);
  };

  const value = {
    onClick,
    open,
    selectedFilterMenu,
    setOpen,
    setSelectedFilterMenu,
  };

  return (
    <FilterMenuContext.Provider value={value}>
      {children}
    </FilterMenuContext.Provider>
  );
}
