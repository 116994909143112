import { ReactElement } from 'react';

import { Options, Tooltip } from './Tooltip';

interface Props {
  children: ReactElement;
  options?: Options;
  showTooltip?: boolean;
}

export const ConditionalTooltip = ({ options, showTooltip, children }: Props) =>
  showTooltip && options?.title ? (
    <Tooltip {...options}>{children}</Tooltip>
  ) : (
    children
  );
