import React, { useContext, useMemo, useState } from 'react';

import { Position } from '@pro4all/shared/ui/context-menu';

import { BaseRow } from './types';

type TableContextMenuRowValue<Row extends BaseRow> = {
  buttonPosition: Position;
  contextMenuRow: Row | null;
  setContextMenuRow?: (row: Row | null) => void;
  setPosition?: (position: Position) => void;
};

const TableContextMenuRowContext = React.createContext({
  buttonPosition: { left: 0, top: 0 },
  contextMenuRow: null,
});

export function useTableContextMenuRowContext<Row extends BaseRow>() {
  return useContext<TableContextMenuRowValue<Row>>(TableContextMenuRowContext);
}

export const TableContextMenuContextProvider: React.FC = ({ children }) => {
  const [contextMenuRow, setContextMenuRow] = useState(null);
  const [buttonPosition, setPosition] = useState<Position>({ left: 0, top: 0 });

  const value = useMemo(
    () => ({
      buttonPosition,
      contextMenuRow,
      setContextMenuRow,
      setPosition,
    }),
    [buttonPosition, contextMenuRow, setPosition, setContextMenuRow]
  );

  return (
    <TableContextMenuRowContext.Provider value={value}>
      {children}
    </TableContextMenuRowContext.Provider>
  );
};
