import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';

import { Button } from '../Toolbar.styles';

export const ProjectButtons: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();
  const noAccess = !hasAppPermission('OrganizationUpdate');

  if (noAccess) return null;

  return (
    <Button
      color="inherit"
      onClick={() => searchParams.set('action', 'unlinkProjectObject')}
      startIcon="removeCircleOutline"
    >
      {t('Remove')}
    </Button>
  );
};
