import { useCallback } from 'react';

import { QualityControlInstancesQuery } from '@pro4all/graphql';
import { Action } from '@pro4all/shared/config';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useViewResult = (
  projectId: string | undefined,
  results: QualityControlInstancesQuery['qualityControlInstances']
) => {
  const { searchParams } = useRouting();
  const { track } = useAnalytics();
  const { setAnswersForApplying } = useSavedAnswersContext();

  const getInstancetType = (resultId: string) =>
    results?.find((qcInstance) => qcInstance.id === resultId)?.type;

  const getInstanceName = (resultId: string) =>
    results?.find((qcInstance) => qcInstance.id === resultId)?.name;
  const onRowClick = useCallback(
    ({ id }) => {
      setAnswersForApplying([]);

      track(Action.ItemOpened, {
        id,
        location: 'Quality control results',
        name: getInstanceName(id),
        projectId,
        type: getInstancetType(id),
      });

      searchParams.set({
        action: 'viewResult',
        id,
      });
      // Add any other functionality here
    },
    [projectId, searchParams]
  );

  return onRowClick;
};
