import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { Routes } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

export function useProjectActions() {
  const { hasAppPermission } = useAppPermissions();
  const { searchParams } = useRouting();
  const { isExternalUserInProject } = useProjectContext();

  const isMyProjectsRoute = Boolean(useRouteMatch(Routes.myProjects));

  const { t } = useTranslation();

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('Duplicate project'),
      dataTestId: 'duplicate-project',
      disabled:
        !hasAppPermission('ProjectCreate') ||
        isMyProjectsRoute ||
        isExternalUserInProject,
      key: 'duplicate-project',
      label: t('Duplicate project'),
      onClick: () => searchParams.set('action', 'duplicateProject'),
      startIcon: 'projectCreate',
    },
  ];

  return { mainActions };
}
