import React from 'react';
import dayjs from 'dayjs';

import { Document } from '@pro4all/graphql';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { DocumentIcon } from '@pro4all/shared/ui/document-icon';

const DocumentListMobile: React.FC<{
  documents: Document[];
  onClick: (document: Document) => void;
}> = ({ documents, onClick }) => (
  <>
    <Divider />
    <List sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
      {documents &&
        documents.map((document) => (
          <ListItemButton
            dense
            divider
            key={document.id}
            onClick={() => {
              onClick(document);
            }}
          >
            <ListItemIcon>
              <DocumentIcon
                extension={document.extension}
                isExpected={document.isExpected}
              />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography
                  color="text.primary"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {document.name}
                </Typography>
              }
              secondary={
                <>
                  <Typography>
                    {dayjs(document.updatedAt).format('ll')}
                  </Typography>
                  <Typography>{document.updatedBy?.displayName}</Typography>
                </>
              }
            />
          </ListItemButton>
        ))}
    </List>
  </>
);

export default DocumentListMobile;
