import { useTranslation } from 'react-i18next';
import { ChartDataset, Color } from 'chart.js';
import dayjs from 'dayjs';

import { BarChartData, ChartOption, TimeOption, Values } from '../types';

import { ChartColors } from './ChartColorHelpers';

// We have to round the dates, otherwise ChartJS doesnt
// render the labels nicely
export const useBarChartTimeOptions = (): TimeOption[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'day',
      label: t('Day'),
      roundDate: (timeStamp: string) => dayjs(timeStamp).startOf('day'),
    },
    {
      id: 'week',
      label: t('Week'),
      roundDate: (timeStamp: string) => dayjs(timeStamp).startOf('week'),
    },
    {
      id: 'month',
      label: t('Month'),
      roundDate: (timeStamp: string) => dayjs(timeStamp).startOf('month'),
    },
  ];
};

function getBarChartDataTemplate(): BarChartData {
  return {
    datasets: [
      {
        backgroundColor: [] as Color[],
        barPercentage: 0.95,
        borderRadius: { topLeft: 5, topRight: 5 },
        categoryPercentage: 0.95,
        data: [] as number[],
      },
    ] as ChartDataset<'bar', number[]>[],
    labels: [] as number[],
  };
}

export function getTimeFormatForTimeOption(
  timeOption: TimeOption | undefined
): string {
  switch (timeOption?.id) {
    case 'day':
      return 'DD-MM-YYYY';
    case 'week':
      return 'w-YYYY';
    case 'month':
      return 'MM-YYYY';
    default:
      return 'DD-MM-YYYY';
  }
}

export function getBarChartData<T>(
  data: T[],
  option: TimeOption,
  clusterField: ChartOption
) {
  const chartData = getBarChartDataTemplate();

  const values: Values = {};

  // Determine the timeformat for the current choice
  const timeFormat: string = getTimeFormatForTimeOption(option);

  // Process the search documents
  data.forEach((item) => {
    // Skip items that do not have a value for the cluster field
    if (clusterField.valueResolver(item)) {
      const time = option.roundDate(clusterField.valueResolver(item));

      const timeLabel = time.format(timeFormat);

      // Generate values that we can easily process later
      if (!values[timeLabel]) {
        values[timeLabel] = {
          timestamp: time.valueOf(),
          val: 1,
        };
      } else {
        values[timeLabel].val += 1;
      }
    }
  });

  // Get the keys of the values object
  const labels = Object.keys(values);

  // Sort the labels by date ascending
  const sortedLabels = labels.sort((a, b) => {
    if (a === b) {
      return 0;
    }

    return values[a].timestamp > values[b].timestamp ? 1 : -1;
  });

  // Convert the group dates to epoch timestamps as labels for ChartJS
  chartData.labels = sortedLabels.map(
    (label) =>
      // Hack: we pick the timestamp from the value object to parse, which is technically
      // not very accurate, but it works for now. We can not use the label directly,
      // because DayJS can not parse a format that contains a week, e.g. 'w YYYY'
      values[label].timestamp
  );

  // Push the values and colors to the chart data
  sortedLabels.forEach((label) => {
    chartData.datasets[0].data.push(values[label].val || 0);
    (chartData.datasets[0].backgroundColor as string[]).push(ChartColors[0]);
  });

  return chartData;
}
