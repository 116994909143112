import React, { useState } from 'react';

import {
  FormLinkedSnag,
  QualityControlInstance,
  QualityControlInstanceType,
} from '@pro4all/graphql';
import { useMapLinkingContext } from '@pro4all/quality-control/ui/maps';
import { useRouting } from '@pro4all/shared/routing-utils';

import * as Styled from '../styles';

import {
  LinkQuestionButtons,
  QuestionDialogState,
} from './LinkTbqQuestionButtons';
import { UnlinkTbqQuestionDialog } from './UnlinkTbqQuestionDialog';
import { useTbqLocationData } from './useTbqLocationData';
import { useTbqUnlinkQuestionFromSnag } from './useTbqUnlinkQuestionFromSnag';

export const TbqSection = ({
  refetch,
  result,
}: {
  refetch: () => void;
  result: QualityControlInstance;
}) => {
  const { searchParams } = useRouting();

  const [questionDialogState, setQuestionDialogState] =
    useState<QuestionDialogState>(QuestionDialogState.Default);
  const { tbqInstance, setTbqInstance } = useMapLinkingContext();

  const locationData = useTbqLocationData();
  const { linkedSnags } = result || {};
  const taskId = searchParams.get('taskId') || '';
  const routeEdit = searchParams.is('action', 'editResult');
  const { formInstanceResults, isTbqQuestion, allItems } =
    useTbqUnlinkQuestionFromSnag(linkedSnags as FormLinkedSnag[]);

  const { type } = result ?? {};
  if (type === QualityControlInstanceType.Tbq && !tbqInstance) {
    setTbqInstance(true);
  }

  const convertedLinkedSnags = linkedSnags
    ? linkedSnags
        .filter(
          (snag): snag is FormLinkedSnag => snag !== null && snag !== undefined
        )
        .map((snag) => ({
          appData: snag.appData,
          direction: snag.direction,
          id: snag.id,
          linkId: snag.linkId,
          linkType: snag.linkType,
          type: snag.type,
        }))
    : [];

  const linkQuestionButtons =
    Boolean(locationData) &&
    Boolean(locationData.isLinkQuestion) &&
    Boolean(routeEdit) ? (
      <LinkQuestionButtons taskId={taskId} />
    ) : null;

  const tbqQuestionButton =
    Boolean(isTbqQuestion) && Boolean(!locationData) && Boolean(routeEdit) ? (
      <Styled.TbqQuestionButton
        aria-haspopup="true"
        id="tbq-unlink-button"
        onClick={() =>
          setQuestionDialogState(QuestionDialogState.UnlinkQuestion)
        }
        startIcon="questionMark"
      >
        {linkedSnags?.length}
      </Styled.TbqQuestionButton>
    ) : null;

  const unlinkTbqQuestionDialog =
    questionDialogState === QuestionDialogState.UnlinkQuestion ? (
      <UnlinkTbqQuestionDialog
        fieldDefinitions={allItems}
        formInstances={formInstanceResults}
        linkedSnags={convertedLinkedSnags}
        onClose={() => setQuestionDialogState(QuestionDialogState.Default)}
        refetchQualityInstance={refetch}
      />
    ) : null;

  return (
    <>
      {linkQuestionButtons}
      {tbqQuestionButton}
      {unlinkTbqQuestionDialog}
    </>
  );
};
