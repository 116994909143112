import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

import { useOnClickRequestUnlock } from './useOnClickRequestUnlock';

export const useRequestUnlockAction = ({
  editableDocuments,
  isAdmin,
  isLocked,
  isLockedUser,
  isSpecialFolder,
  isVersion,
  position,
  selection,
  uncheckAllRows,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isAdmin'
  | 'isLocked'
  | 'isLockedUser'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const onClick = useOnClickRequestUnlock({
    position,
    selection,
    uncheckAllRows,
  });

  const requestUnlockAction: ActionProps = {
    ariaLabel: t('Request unlock'),
    dataTestId: 'request-unlock',
    disabled:
      editableDocuments.length === 0 ||
      !isLocked ||
      isAdmin ||
      isLockedUser ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    key: 'request-unlock',
    label: t('Request unlock'),
    onClick,
    startIcon: 'lockOpen',
  };

  return requestUnlockAction;
};
