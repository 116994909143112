export const colorPicker = [
  {
    color: '#7E78F9',
    highlightBackground: '#ECEEFF',
    highlightColor: '#28237A',
  },
  {
    color: '#28237A',
    highlightBackground: '#28237A',
    highlightColor: '#ECEEFF',
  },
  {
    color: '#00B5DD',
    highlightBackground: '#CCF0F8',
    highlightColor: '#006379',
  },
  {
    color: '#006379',
    highlightBackground: '#006379',
    highlightColor: '#CCF0F8',
  },
  {
    color: '#F5B05B',
    highlightBackground: '#FCEFD6',
    highlightColor: '#8F4F00',
  },
  {
    color: '#8F4F00',
    highlightBackground: '#8F4F00',
    highlightColor: '#FCEFD6',
  },
  {
    color: '#36CCBA',
    highlightBackground: '#CDF3ED',
    highlightColor: '#006B5E',
  },
  {
    color: '#006B5E',
    highlightBackground: '#006B5E',
    highlightColor: '#CDF3ED',
  },
  {
    color: '#CF6679',
    highlightBackground: '#F5E0E4',
    highlightColor: '#894A56',
  },
  {
    color: '#894A56',
    highlightBackground: '#894A56',
    highlightColor: '#F5E0E4',
  },
  {
    color: '#AFAFAF',
    highlightBackground: '#DDDDDD',
    highlightColor: '#212121',
  },
  {
    color: '#212121',
    highlightBackground: '#212121',
    highlightColor: '#EEEEEE',
  },
];

export const hexToRgb = (hex: string, opacity: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}, ${opacity})`
    : `rgba(132, 132, 132, ${opacity})`;
};

export const applyColor = (color: string) => {
  const colorObj = colorPicker.filter((colorObj) => colorObj.color === color);
  if (colorObj.length > 0) {
    return colorObj[0].highlightColor;
  } else {
    const rgba = hexToRgb(color, '1');
    if (rgba !== '(132, 132, 132, 1)') {
      const rgbaValues = rgba.split(',');
      const red = parseInt(rgbaValues[0].split('(')[1]);
      const green = parseInt(rgbaValues[1]);
      const blue = parseInt(rgbaValues[2]);
      return (color =
        red * 0.299 + green * 0.587 + blue * 0.114 > 186
          ? '#212121'
          : '#ffffff');
    } else {
      return (color = '#000000');
    }
  }
};

export const applyBackground = (color: string) => {
  const colorObj = colorPicker.filter((colorObj) => colorObj.color === color);
  if (colorObj.length > 0) {
    return colorObj[0].highlightBackground;
  } else {
    return color;
  }
};
