import React from 'react';
import { useTranslation } from 'react-i18next';

import { HierarchicalList } from '@pro4all/graphql';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button, ButtonGroupHorizontal } from '@pro4all/shared/ui/buttons';
import { Main } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

export const RedirectToOrganizationHierarchicalLists = ({
  selectedHierarchicalList,
}: {
  selectedHierarchicalList?: HierarchicalList;
}) => {
  const { t } = useTranslation();
  const { id, name } = selectedHierarchicalList;
  const { goTo, searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();

  return (
    <Main p={3}>
      <Box>
        <Box mb={1}>
          <Text variant="h2">{name}</Text>
        </Box>
        <Box mb={3}>
          {t(
            'This hierarchical list is linked from an organization. This can only be edited from within the organization and not from within the project.'
          )}
        </Box>
        <ButtonGroupHorizontal>
          <Button
            disabled={!hasAppPermission('OrganizationUpdate')}
            onClick={() =>
              goTo('metaDataHierarchicalLists', {
                searchParams: { action: 'editHierarchicalList', id },
              })
            }
          >
            {t('To hierarchical list')}
          </Button>
          <Button color="inherit" onClick={() => searchParams.clear()}>
            {t('Cancel')}
          </Button>
        </ButtonGroupHorizontal>
      </Box>
    </Main>
  );
};
