import { useCallback } from 'react';

import { useRouting } from '@pro4all/shared/routing-utils';
import { CdeActions } from '@pro4all/shared/types';

import { useOpenCdeContext } from '../OpenCdeContext';

import { useOpenCdeStateParser } from './useOpenCdeStateParser';
import { useOpenCdeTracking } from './useOpenCdeTracking';

export const useVerifyOpenCdeRequest = () => {
  // Open CDE initialization
  // Routing parameter handling
  const { searchParams } = useRouting();
  const { setOpenCdeRequest } = useOpenCdeContext();

  // State parameter for CDE upload
  const state = searchParams.get('state');
  const { uploadRequestState } = useOpenCdeStateParser(state);

  const { trackOpenCdeInit } = useOpenCdeTracking();

  // Function to check if we have callback url from external application
  return useCallback(() => {
    const callbackUrl = searchParams.get('callbackUrl');
    const expiresIn = searchParams.get('expiresIn');
    const cdeSelect = searchParams.get('action');
    const cdeApplication = searchParams.get('cdeApplication');
    const supportedFileExtensions = JSON.parse(
      searchParams.get('supported_file_extensions') ?? '["ifc","smc"]'
    );

    if (
      callbackUrl &&
      expiresIn &&
      cdeSelect &&
      cdeSelect === CdeActions.CdeSelect &&
      cdeApplication
    ) {
      setOpenCdeRequest((prevState) => ({
        ...prevState,
        callbackUrl,
        cdeApplication,
        cdeSelect: cdeSelect as CdeActions,
        expiresIn: Number(expiresIn),
        supportedFileExtensions,
      }));
      trackOpenCdeInit();
    } else if (
      callbackUrl &&
      expiresIn &&
      cdeSelect &&
      cdeSelect === CdeActions.CdeUpload &&
      cdeApplication &&
      state
    ) {
      setOpenCdeRequest((prevState) => ({
        ...prevState,
        callbackUrl,
        cdeApplication,
        cdeSelect: cdeSelect as CdeActions,
        expiresIn: Number(expiresIn),
        state: uploadRequestState(),
      }));
    }
  }, [searchParams, setOpenCdeRequest, state, uploadRequestState]);
};
