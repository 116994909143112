import styled, { css } from 'styled-components';

import { Chip, ListItemIcon } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const StyledTooltip = styled(Tooltip)<{ $compact?: boolean }>`
  && {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    ${({ $compact }) => css`
      flex: ${$compact ? '0' : '1'};
    `}

    & .tooltip {
      margin-left: 1rem;
      position: static;
      padding: 0.5rem;
      font-size: 1rem;
      border-radius: 0.5rem;
    }
  }
`;

export const Count = styled(Chip)`
  && {
    &.MuiChip-root {
      background-color: ${customColors.orangeAccent};
      color: #fff;
      margin-left: auto;
    }
  }
`;

export const NotiDot = styled.div`
  background-color: ${customColors.orangeAccent};
  border: 1px solid white;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  && {
    position: relative;
  }
`;
