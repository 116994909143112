import {
  AuthenticatedApiService,
  FilesData,
} from '@pro4all/authentication/src/services/authenticated-api-service';

export const SignatureBaseURL = 'https://qualitycontrol.pro4all.io/api/';

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(SignatureBaseURL);
  }

  getSignature = ({
    instanceId,
    signatureId,
  }: {
    instanceId: string;
    signatureId: string;
  }): Promise<string> =>
    this.api.getImageUrl(`media/${instanceId}/${signatureId}`);

  uploadSignature = async (
    instanceId: string,
    fieldId: string,
    file: File,
    onProgress?: (progress: number) => void
  ) => {
    const url = `/media/${instanceId}/${fieldId}`;
    const response = this.api.uploadFile<FilesData>({
      file,
      onProgress,
      url,
    });
    return response;
  };
}

export const SignatureService = new Service();
