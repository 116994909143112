import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle } from '@pro4all/shared/mui-wrappers';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Text } from '@pro4all/shared/ui/typography';

import { useMessageContext } from '../provider/MessageProvider';

import { ErrorWrapper } from './BatchInfoModal.styles';

export const BatchInfoModal = () => {
  const { t } = useTranslation();
  const {
    clearBatchInfo,
    state: { batchInfo },
  } = useMessageContext();

  return (
    <FloatingModal
      data-testid="batch-info-modal"
      maxWidth="lg"
      onClose={clearBatchInfo}
      open={Boolean(batchInfo.length)}
    >
      <FloatingModal.Header iconName="info">
        {t('Detailed error information')}
      </FloatingModal.Header>
      <ErrorWrapper>
        {batchInfo.map((error, i) => {
          const errorMessage = error.code ? (
            <Alert color="error" icon={false} variant="outlined">
              <AlertTitle>{error.displayName}</AlertTitle>
              {t(`API_${error.code}`)}
            </Alert>
          ) : (
            <Alert color="error" icon={false} variant="outlined">
              {error.displayName}
            </Alert>
          );
          return <Text key={i}>{errorMessage}</Text>;
        })}
      </ErrorWrapper>
    </FloatingModal>
  );
};
