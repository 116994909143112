import { useTranslation } from 'react-i18next';

import { useCollectionFolder } from '@pro4all/documents/ui/folders';
import { Document } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/types';

interface Props {
  allDocumentsExpected: boolean;
  isVersion: boolean;
  selection: Document[];
}

export const useCollectionFolderActions = ({
  isVersion,
  selection,
  allDocumentsExpected,
}: Props) => {
  const { t } = useTranslation();
  const { addDocuments, allInCollection, removeDocuments, isInCollection } =
    useCollectionFolder();

  const addDocumentToCollection: ActionProps = {
    ariaLabel: t('Add to collection'),
    dataTestId: 'add-to-collection',
    disabled: isVersion || allInCollection(selection) || allDocumentsExpected,
    key: 'add-documents-to-collection',
    label: t('Add to collection'),
    onClick: () => addDocuments(selection),
    startIcon: 'collectionBin',
  };

  const removeDocumentsFromCollection: ActionProps = {
    ariaLabel: t('Remove from collection'),
    dataTestId: 'remove-from-collection',
    disabled: isVersion || !isInCollection(selection),
    key: 'remove-from-collection',
    label: t('Remove from collection'),
    onClick: () => removeDocuments(selection.map((item) => item.id)),
    startIcon: 'removeCollectionBin',
  };

  return {
    addDocumentToCollection,
    removeDocumentsFromCollection,
  };
};
