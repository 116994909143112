import { SaveResponse } from '../../shared/types';
import { State } from '../types';

export const updatePermissionsAfterSaveAction = ({
  payload,
  state,
}: {
  payload: SaveResponse;
  state: State;
}) => {
  const { displayPermissions, initialPermissionsExplicit, savePermissions } =
    state;

  const { successful, unsuccessful } = payload || {};

  const initialPermissionsExcludingSuccessfullySaved =
    displayPermissions.filter(
      (folder) => !successful.includes(folder.folderId)
    );

  const initialPermissionsExplicitExcludingSuccessfullySaved =
    initialPermissionsExplicit.filter(
      (folder) => !successful.includes(folder.folderId)
    );

  const savePermissionsSuccessfullySaved = savePermissions.filter((folder) =>
    successful.includes(folder.folderId)
  );
  const savePermissionsUnsuccessfullySaved = savePermissions.filter((folder) =>
    unsuccessful.includes(folder.folderId)
  );

  return {
    ...state,
    initialPermissionsDisplay: [
      // Replace the permissions in initialPermissionsDisplay that where modified.
      ...initialPermissionsExcludingSuccessfullySaved,
      ...savePermissionsSuccessfullySaved,
    ],
    initialPermissionsExplicit: [
      // Replace the permissions in initialPermissionsExplicit that where modified.
      ...initialPermissionsExplicitExcludingSuccessfullySaved,
      ...savePermissionsSuccessfullySaved,
    ],
    savePermissions: savePermissionsUnsuccessfullySaved,
  };
};
