import React from 'react';
import { BaseEditor, Editor as SlateEditor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { Icon, IconName } from '@pro4all/shared/ui/icons';

import { EditorButton } from './EditorButton';

interface Props {
  format: string;
  icon: IconName;
  title?: string;
}

export const MarkButton = ({ format, icon, title }: Props) => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isMarkActive(editor, format)}
      onClick={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      title={title}
    >
      <Icon iconName={icon} />
    </EditorButton>
  );
};

const toggleMark = (editor: BaseEditor & ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);
  isActive
    ? SlateEditor.removeMark(editor, format)
    : SlateEditor.addMark(editor, format, true);
};

const isMarkActive = (editor: BaseEditor & ReactEditor, format: string) => {
  const marks: { [key: string]: boolean } | null = SlateEditor.marks(editor); // We manually add index signature
  return marks ? marks[format] : false;
};
