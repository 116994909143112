import { FolderPermission } from '@pro4all/graphql';

import { Permission } from '../types';

import { getThisFolderPermission } from './getThisFolderPermission';

export const getAncestorPermissionIncluded = ({
  folderId,
  permission,
  permissions,
}: {
  folderId: string;
  permission: FolderPermission;
  permissions: Permission[];
}) => {
  if (!folderId) return false;
  if (permissions.length === 0) return false;

  let response = false;
  let parentFolderId = folderId;
  let breakInheritance = false;

  do {
    const parentFolder = getThisFolderPermission(parentFolderId, permissions);

    if (parentFolder) {
      if (
        parentFolder.folderId !== folderId &&
        parentFolder.folderPermissions.includes(permission)
      ) {
        response = true;
      }
      parentFolderId = parentFolder.parentFolderId;
      breakInheritance = parentFolder.breakInheritance;
    } else {
      parentFolderId = null;
    }
  } while (parentFolderId && !breakInheritance);

  return response;
};
