import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const PowerPoint: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g data-name="ppt 48" id="ppt_48">
      <rect fill="#fff" height="30" rx="1" ry="1" width="21" x="23" y="9" />
      <path
        d="M43,10V38H24V10H43m0-2H24a2,2,0,0,0-2,2V38a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2Z"
        fill="#d24726"
      />
      <rect fill="#d24726" height="2" width="12" x="27" y="28" />
      <rect fill="#d24726" height="2" width="12" x="27" y="32" />
      <path d="M32,14a6,6,0,1,0,6,6H32Z" fill="#d24726" />
      <path d="M33,13v6h6A6,6,0,0,0,33,13Z" fill="#d24726" />
      <path d="M28,3,3,7.38V40.62L28,45Z" fill="#d24726" fillRule="evenodd" />
      <path
        d="M13,23.47V18.1l1.4,0a3.85,3.85,0,0,1,1.2.12,2.11,2.11,0,0,1,.87.48,2,2,0,0,1,.53.83,3.6,3.6,0,0,1,.18,1.19A3.82,3.82,0,0,1,17,21.89a2.11,2.11,0,0,1-1.4,1.39,3.82,3.82,0,0,1-1.2.17H13.07M15,15l-5,.29v17l3,.18V26.41l1.69,0A8.26,8.26,0,0,0,16,26.35a6.5,6.5,0,0,0,1.22-.3,5.59,5.59,0,0,0,1.09-.52,5.5,5.5,0,0,0,1-.75,5.57,5.57,0,0,0,.74-.89,5.44,5.44,0,0,0,.53-1,6,6,0,0,0,.32-1.15,8.05,8.05,0,0,0-.28-3.78,4.2,4.2,0,0,0-1.15-1.74,4.52,4.52,0,0,0-1.89-1A8.36,8.36,0,0,0,15,15"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);
