export const naturalCompare = (a: string, b: string): number => {
  const re = /(\D+|\d+)/g;
  const aFragments = a.match(re);
  const bFragments = b.match(re);

  if (aFragments !== null && bFragments !== null) {
    for (let i = 0; i < Math.min(aFragments.length, bFragments.length); i++) {
      const aIsNumber = !isNaN(Number(aFragments[i]));
      const bIsNumber = !isNaN(Number(bFragments[i]));

      if (aIsNumber && bIsNumber) {
        const numDiff = Number(aFragments[i]) - Number(bFragments[i]);
        if (numDiff !== 0) return numDiff;
      } else {
        const strDiff = aFragments[i].localeCompare(bFragments[i]);
        if (strDiff !== 0) return strDiff;
      }
    }
  }

  return a.localeCompare(b);
};
