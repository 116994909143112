import { useMemo } from 'react';

import { OsidDeviceLog } from '@pro4all/graphql';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';

import { OsidEmptyRenderer } from './OsidEmptyRender';
import { useTBQOsidLogsColumns } from './useTBQOsidLogsColumns';

interface Props {
  isLinkedDevice: boolean;
  linkedDeviceLogs: OsidDeviceLog[];
  noOsidDevices?: boolean;
  osidDeviceLogLoading: boolean;
}

export const TBQOsidLinkedDeviceLogs: React.FC<Props> = ({
  isLinkedDevice,
  linkedDeviceLogs,
  osidDeviceLogLoading,
  noOsidDevices,
}) => {
  const columns = useTBQOsidLogsColumns();

  const logsProvider = useMemo(
    () => linkedDeviceLogs || [],
    [linkedDeviceLogs]
  );
  const {
    state: { items },
  } = useOptimisticResponseContext<OsidDeviceLog>();
  useSetItemsInLocalState<OsidDeviceLog>(logsProvider || []);

  const emptyRenderer = () => (
    <OsidEmptyRenderer
      isLinkedDevice={isLinkedDevice}
      noOsidDevices={noOsidDevices}
      osidDeviceLogLoading={osidDeviceLogLoading}
    />
  );

  return (
    <TableContextProvider
      checkable={false}
      columns={columns}
      id="table-tbq-osid-logs"
      items={items}
    >
      <FilterContextProvider<OsidDeviceLog>>
        <Table<OsidDeviceLog> emptyRenderer={emptyRenderer} headerHeight={40} />
      </FilterContextProvider>
    </TableContextProvider>
  );
};
