import { useGenericContext } from '@pro4all/shared/providers';

import { TreeFolder } from './types';

/**
 * In case the matrix is displayed in the `Show final permissions` mode we do not
 * want to display users of groups that do not have any permission set.
 * This hook takes care of filtering out those entities.
 */
export const useGetFilteredFolders = (folders: TreeFolder[]) => {
  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const filterItemsWithoutPermissions = (folder: TreeFolder) => {
    const { entityFolderPermissions } = folder;
    const {
      folderPermissions,
      inheritedFrom,
      inheritedFromParentFolders,
      subFoldersPermissions,
    } = entityFolderPermissions[0];

    return !(
      showFinalPermissions &&
      (!folderPermissions || folderPermissions?.length === 0) &&
      (!inheritedFrom || inheritedFrom?.length === 0) &&
      (!inheritedFromParentFolders ||
        inheritedFromParentFolders?.length === 0) &&
      (!subFoldersPermissions || subFoldersPermissions?.length === 0)
    );
  };

  const folderFiltered = folders
    ? folders.filter(filterItemsWithoutPermissions)
    : [];

  return folderFiltered;
};
