import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  LoginMethodOption,
  OrganizationDocument,
  useSetOrganizationEnforceSsoMutation,
} from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateEnforceSSO] = useSetOrganizationEnforceSsoMutation();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();

  return async (values: { login_method: LoginMethodOption }) => {
    try {
      const loginMethod = values.login_method;
      const enforceSSO = loginMethod === LoginMethodOption.OnlySso;

      await updateEnforceSSO({
        variables: { enforceSSO },
      });

      // Update cache
      const organizationId = getContextScopedOrganizationId();

      const queryParams = {
        query: OrganizationDocument,
        variables: { id: organizationId },
      };
      const cachedOrganization = client?.readQuery(queryParams).organization;

      client.writeQuery({
        data: {
          organization: {
            ...cachedOrganization,
            enforceSSO,
          },
        },
        ...queryParams,
      });

      enqueueSnackbar(t('Organization settings have been updated.'));
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
      console.warn(e);
    }
  };
};
