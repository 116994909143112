import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MessageFormBase } from '@pro4all/communication/ui/general';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';

import { Left, NewCommentFooter, Right } from './Styles';
import { useThreadContext } from './ThreadContext';

interface Props {
  disableSubmit: boolean;
  editMode: boolean;
  inputId: string;
  isUploading: boolean;
  resetEditorValues: () => void;
  resetProgress: () => void;
}

export const OuterFooter: React.FC<Props> = ({
  disableSubmit,
  isUploading,
  inputId,
  resetProgress,
  resetEditorValues,
  editMode,
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const formContext = useFormContext<MessageFormBase>();
  const resetForm = () => formContext.reset();
  const isSubmitting = formContext.formState.isSubmitting;

  const context = useThreadContext();
  if (!context) throw Error('Comments context not initialized.');
  const { callbackOptions } = context;
  const onSubmit = callbackOptions?.onSubmit;

  const onCancel = () => {
    searchParams.delete('editComment');
    resetForm();
    resetProgress();
    resetEditorValues();
  };

  const openFileInput = () => {
    const fileInput = document.getElementById(inputId);
    fileInput && fileInput.click();
  };

  return (
    <NewCommentFooter>
      <Left>
        <Button
          aria-label={t('Add attachment')}
          disabled={isSubmitting || isUploading}
          onClick={openFileInput}
          startIcon="attachment"
          variant="outlined"
        >
          {t('Attachment')}
        </Button>
      </Left>
      <Right>
        {editMode && (
          <Button
            aria-label={t('Cancel')}
            color="inherit"
            onClick={onCancel}
            variant="outlined"
          >
            {t('Cancel')}
          </Button>
        )}
        <Button
          aria-label={t('Send')}
          color="primary"
          disabled={disableSubmit}
          onClick={onSubmit}
          startIcon="send"
          type="submit"
          variant="contained"
        >
          {t('Send')}
        </Button>
      </Right>
    </NewCommentFooter>
  );
};
