import { useState } from 'react';
import { useDrop } from 'react-dnd';

import { client } from '@pro4all/authentication/src/graph-ql';
import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { DocumentVersion, DocumentVersionDocument } from '@pro4all/graphql';
import { useOpenCdeContext } from '@pro4all/opencde/context';
import { DndFolder, DndTypes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { CdeActions } from '@pro4all/shared/types';
import {
  EntityTypeTranslation,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';

import { useDropDocument, ValidateStatus } from './hooks/useDropDocument';
import { useGetFolder } from './hooks/useGetFolder';
import { VersionTableDropConfirmationDialog } from './VersionTableDropConfirmationDialog';
import { VersionTableDropValidationDialog } from './VersionTableDropValidationDialog';

interface Props {
  getHover: (args: { hoversOverCurrent: boolean }) => React.ReactNode;
}

export const VersionTableDropDocument: React.FC<Props> = ({
  children,
  getHover,
}) => {
  const { searchParams } = useRouting();

  const { folder } = useGetFolder();

  const { canUploadVersion } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const selectedDocId = searchParams.get('id');
  const selectedDocument = folder?.documents?.find(
    (document) => document.id === selectedDocId
  );

  const userCanUploadVersion = canUploadVersion(selectedDocument);

  // Droping version selected on DMS
  const [confirmDrop, setConfirmDrop] = useState(false);
  const [confirmDropIssueMessage, setConfirmDropIssueMessage] = useState('');
  const [droppedField, setDroppedField] = useState<{
    currentDocumentVersion: DocumentVersion;
    droppedDocumentId: string;
  }>();
  const { dropDocument, validateDropDocument } = useDropDocument();
  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [DndTypes.DOCUMENT],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: async (field: DndFolder, monitor) => {
      if (!monitor.didDrop()) {
        // Drop of a document.
        const sourceDocument = folder?.documents?.find(
          (document) => document.id === field.id
        );

        // New query to get the DocumentVerion of the dropped document
        const documentsQueryParams = {
          query: DocumentVersionDocument,
          variables: { id: field.id },
        };
        const documentQueryResponse = await client?.query(documentsQueryParams);
        const documentVersions = documentQueryResponse.data
          .documentVersion as DocumentVersion[];

        const validation = validateDropDocument(
          selectedDocument,
          sourceDocument,
          userCanUploadVersion,
          documentVersions.length
        );
        if (validation.state === ValidateStatus.SUCCESS) {
          setConfirmDrop(true);
          setDroppedField({
            currentDocumentVersion: documentVersions[0],
            droppedDocumentId: field.id,
          });
        } else {
          setConfirmDropIssueMessage(validation.message);
        }
      }
    },
  });
  const { showMutationMessage } = useShowMessages();

  const confirmDroppedDocument = async () => {
    setConfirmDrop(false);
    const isDropped = await dropDocument(
      droppedField.droppedDocumentId,
      droppedField.currentDocumentVersion,
      selectedDocument.id
    );
    if (isDropped)
      showMutationMessage({
        action: MessageAction.Update,
        entityType: EntityTypeTranslation.DocumentVersion,
        name: selectedDocument.name,
      });
  };

  const closeDroppedDocument = () => {
    setConfirmDrop(false);
  };

  // Open CDE metadata save to send to the external client
  const { openCdeRequest } = useOpenCdeContext();

  return (
    (!openCdeRequest || openCdeRequest.cdeSelect !== CdeActions.CdeUpload) &&
    drop(
      <div style={{ height: 'inherit' }}>
        {getHover({ hoversOverCurrent })}

        {children}
        <VersionTableDropConfirmationDialog
          cancel={closeDroppedDocument}
          confirm={confirmDroppedDocument}
          showModal={confirmDrop}
        />
        <VersionTableDropValidationDialog
          confirm={() => setConfirmDropIssueMessage('')}
          message={confirmDropIssueMessage}
          showModal={confirmDropIssueMessage !== ''}
        />
      </div>
    )
  );
};
