import React, { useCallback } from 'react';
import { RenderElementProps } from 'slate-react';

import { StaticImage } from '../slate-static-images';

import { CustomElementType, MentionType } from './CustomTypes';
import { Mention } from './Mention';

export const useRenderElement = () =>
  useCallback((props) => <Element {...props} children={props.children} />, []);

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  const style: React.CSSProperties = { textAlign: element.align || 'left' };

  switch (element.mode) {
    case 'bulleted-list':
      return (
        <li style={style} {...props}>
          {children}
        </li>
      );
    case 'title':
      return (
        <h1 style={style} {...props}>
          {children}
        </h1>
      );
    case 'subtitle':
      return (
        <h3 style={style} {...props}>
          {children}
        </h3>
      );
    default:
      switch (element.type) {
        case MentionType.user:
        case MentionType.group:
          return (
            <Mention
              attributes={attributes}
              children={children}
              element={element}
            />
          );
        case CustomElementType.image:
          return (
            <StaticImage
              attributes={attributes}
              children={children}
              element={element}
            />
          );
        default:
          return (
            <div style={style} {...attributes}>
              {children}
            </div>
          );
      }
  }
};
