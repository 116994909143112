import styled from 'styled-components';

export const Container = styled.div<{
  color?: string;
  opacity?: string;
}>`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 20%;
  display: flex;
  min-height: ${({ theme }) => theme.spacing(3.8)};
  justify-content: center;
  min-width: ${({ theme }) => theme.spacing(4.2)};
  opacity: ${({ opacity }) => opacity};
  width: 22px;
  height: 22px;
  margin: ${({ theme }) => theme.spacing(0.5)};
`;

export const Text = styled.div<{
  color: string;
}>`
  font-size: 8px;
  font-weight: 700;
  color: ${({ color }) => color};
`;
