import { List as ListComponent } from './List';
import { Li } from './List.styles';

export type { ListMore, Props as ListProps } from './List';

export const List = Object.assign(ListComponent, {
  Item: Li,
});

export { DisplayType } from './DefaultMore';
