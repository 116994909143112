const getEmailDomain = (email: string) => email?.split('@')[1] || '';

export const isAnySameEmailDomain = ({
  emailToCheck,
  emailsToCheck,
}: {
  emailToCheck: string;
  emailsToCheck: string[];
}) => {
  if (emailsToCheck.length === 0) return false;

  const domainToCheckFor = getEmailDomain(emailToCheck);
  const domainsToCheck = emailsToCheck.map(getEmailDomain);

  return domainsToCheck.some((domain) => domain && domain === domainToCheckFor);
};

export const isAnyOtherEmailDomain = ({
  emailToCheck,
  emailsToCheck,
}: {
  emailToCheck: string;
  emailsToCheck: string[];
}) => {
  if (emailsToCheck.length === 0) return false;

  const domainToCheckFor = getEmailDomain(emailToCheck);
  const domainsToCheck = emailsToCheck.map(getEmailDomain);

  return domainsToCheck.some((domain) => domain && domain !== domainToCheckFor);
};
