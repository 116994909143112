import { FolderPermission } from '@pro4all/graphql';

import { inConflictWithPermissionsOfOwnFolderOrNestedFolders } from './context/helpers/inConflictWithPermissionsOfOwnFolderOrNestedFolders';
import { State } from './context/types';
import { isDocumentsNone } from './shared/helpers/checkPermissionsWrappers';

export const shouldPermissionFolderCellBeDisabled = ({
  allPermissions,
  folderId,
  permission,
  state,
}: {
  allPermissions: FolderPermission[];
  folderId: string;
  permission: FolderPermission;
  state: State;
}) => {
  // User cannot set permission of a parent folder in case there are conflicting permissions on the subfolder.
  // So we have to check on that to see if we have to disable the checkbox yes or no.
  // This is done with the 'inConflictWithPermissionsOfOwnFolderOrNestedFolders' method.

  const noDocumentPermissions = isDocumentsNone(allPermissions);

  switch (permission) {
    case FolderPermission.ApproveContent:
      return (
        noDocumentPermissions ||
        inConflictWithPermissionsOfOwnFolderOrNestedFolders({
          folderId,
          permission: FolderPermission.ApproveContent,
          state,
        })
      );
    case FolderPermission.FinalizeContent:
      return (
        !allPermissions.includes(FolderPermission.ReadAllDocuments) ||
        allPermissions.includes(FolderPermission.ReadOwnAndFinalized) ||
        allPermissions.includes(FolderPermission.ReadOwn) ||
        inConflictWithPermissionsOfOwnFolderOrNestedFolders({
          folderId,
          permission: FolderPermission.FinalizeContent,
          state,
        })
      );
    case FolderPermission.CreateContent:
      return (
        noDocumentPermissions ||
        inConflictWithPermissionsOfOwnFolderOrNestedFolders({
          folderId,
          permission: FolderPermission.CreateContent,
          state,
        })
      );
    default:
      return false;
  }
};
