import React, { createContext, useContext } from 'react';

import { LinkProject, UnlinkProject } from './projects';
import type { UseObjectsResult } from './useObjects';

const ObjectsContext = createContext<UseObjectsResult | undefined>(undefined);

export function useObjectsContext() {
  const context = useContext<UseObjectsResult | undefined>(ObjectsContext);
  if (!context) throw Error('Object context not initialized.');
  return context;
}

export const ObjectsProvider: React.FC<UseObjectsResult> = ({
  children,
  ...value
}) => {
  if (!value) throw Error('Object context not initialized.');
  return (
    <ObjectsContext.Provider value={value}>
      <LinkProject />
      <UnlinkProject />
      {children}
    </ObjectsContext.Provider>
  );
};
