import React from 'react';

import { ErrorMessage } from '@pro4all/shared/assets-sharing';

import { usePubliclySharedMessagesContext } from './PubliclySharedMessagesContext';

export const PublicLinkErrorMessages: React.FC = () => {
  const { error } = usePubliclySharedMessagesContext();
  return <ErrorMessage error={error} />;
};
