import React from 'react';
import { useTranslation } from 'react-i18next';

import { Role, User } from '@pro4all/graphql';
import {
  Box,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import { joinRoles } from '../components/Roles';

import { StatusChip } from './StatusChip';

const OrganizationUserListMobile: React.FC<{
  users: User[];
}> = ({ users }) => {
  const { t } = useTranslation();

  const getRole = (roles: Role[]) =>
    joinRoles(roles?.map((role) => ({ ...role, name: t(role?.name) })));

  return (
    <>
      <Divider />
      <List sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
        {users &&
          users.map((user) => (
            <ListItem dense divider key={user.id}>
              <ListItemIcon>
                <Icon
                  iconName={
                    getRole(user.roles) === 'Administrator'
                      ? 'admin'
                      : 'personOutline'
                  }
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      color="text.primary"
                      sx={{
                        maxWidth: '200px', // Fixed width otherwise conflicts with page count
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {user.displayName}
                    </Typography>
                  </Box>
                }
                secondary={
                  <>
                    <Typography color="text.secondary">{user.email}</Typography>
                    <StatusChip state={user.state} />
                  </>
                }
              />
              {user.phoneNumber && (
                <IconButton edge="end">
                  <Link href={`tel:${user.phoneNumber}`} underline="none">
                    <Icon iconName="phone"></Icon>
                  </Link>
                </IconButton>
              )}
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default OrganizationUserListMobile;
