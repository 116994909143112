import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import {
  FieldDefinition,
  TemplateService,
  useTemplateItemsQuery,
} from '@pro4all/graphql';
import { Collapse, ListItemText } from '@pro4all/shared/mui-wrappers';
import { ReportConfigTypes } from '@pro4all/shared/types';
import { Text } from '@pro4all/shared/ui/typography';

import { useReportOptionsContext } from '../../ReportOptionsProvider';
import { StyledListItemButton } from '../FormStyles';
import { flattenItems } from '../helpers/flattenItems';

import { SectionFieldInclude } from './SectionFieldInclude';

export const ConfigureSectionsAndFields = ({
  template,
}: Pick<ReportConfigTypes, 'template'>) => {
  const { t } = useTranslation();

  const {
    setHiddenSectionsAndFields,
    state: { reportOptions },
  } = useReportOptionsContext();

  const [open, setOpen] = useState(true);
  const [itemsDisplay, setItemsDisplay] = useState<FieldDefinition[]>([]);

  const { data } = useTemplateItemsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: template.id,
      templateService: TemplateService.QualityControl,
    },
  });

  useEffect(() => {
    if (data) {
      setItemsDisplay(data.templateItems as FieldDefinition[]);
    }
  }, [data]);

  if (!template) return null;
  if (!itemsDisplay || !itemsDisplay.length) return null;

  const flattenedItems = flattenItems({ items: itemsDisplay, level: 0 });

  const hiddenSectionsAndFields = template
    ? reportOptions?.templates?.[template.id]
      ? reportOptions?.templates?.[template.id]?.hiddenSectionsAndFields
      : []
    : [];

  const sectionsAndFields = flattenedItems.map((item) => {
    const checked = hiddenSectionsAndFields
      ? !hiddenSectionsAndFields?.includes(item.id)
      : true;

    return (
      <SectionFieldInclude
        checked={checked}
        flattenedItem={item}
        items={itemsDisplay}
        setHiddenSectionsAndFields={setHiddenSectionsAndFields}
        template={template}
      />
    );
  });

  return (
    <>
      <StyledListItemButton onClick={() => setOpen(!open)}>
        <ListItemText primary={<Text variant="h6">{t('Answers')}</Text>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {sectionsAndFields}
      </Collapse>
    </>
  );
};
