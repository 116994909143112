import React from 'react';
import { useTranslation } from 'react-i18next';

import { TbqAnswer } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Loader, OptimisticResponseProvider } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import * as Messages from '../../Messages';

import { BrandcheckHeaderSection } from './BrandcheckHeaderSection';
import { ResultsProgressBars } from './Progressbar';
import {
  BouwbesluitState,
  Main,
  PlusBouwbesluitState,
  Row,
  StyledButton,
} from './ResultsUI.styles';
import { ResultTabs } from './ResultTabs';
import { TBQshortcomings } from './TBQshortcomings';
import { useTBQResults } from './useTBQResults';

export function TBQResults() {
  const { t } = useTranslation();
  const {
    back,
    done,
    error,
    loading,
    resultPlus,
    resultSet,
    toggleResultPlus,
    hasFilter,
    categories,
    qcInstance,
    operationGoals,
    shortcomings,
    filterShortcomings,
    tbqBrandcheckLinkedSnags,
  } = useTBQResults();

  if (error) return <Messages.Error error={error} />;
  if (!done && loading) return <Loader />;

  const isSchadeTabSelected = resultSet.includes('Damage');
  const accomplished =
    categories.filter((c) => c.percentage !== 1).length === 0;

  function statusIcon() {
    if (accomplished) {
      return (
        <BouwbesluitState>
          <Icon
            iconName="checkCircle"
            label={<Text>{t('Accomplished')}</Text>}
          />
        </BouwbesluitState>
      );
    } else {
      return (
        <PlusBouwbesluitState>
          <Icon
            iconName="cancel"
            label={<Text>{t('Not accomplished')}</Text>}
          />
        </PlusBouwbesluitState>
      );
    }
  }

  return (
    <Main>
      <StyledButton
        data-testid="close-instance"
        onClick={back}
        startIcon="arrowBack"
        variant="outlined"
      >
        {t('Go back')}
      </StyledButton>
      <ResultTabs />
      <BrandcheckHeaderSection
        isSchadeTabSelected={isSchadeTabSelected}
        resultPlus={resultPlus}
        statusIcon={statusIcon()}
        toggleResultPlus={toggleResultPlus}
      />
      <Row gap={12}>
        <ResultsProgressBars
          categories={categories}
          onClickProgress={filterShortcomings}
          operationGoals={operationGoals}
        />
      </Row>
      <Box flex="1" mt={3} mx={-3}>
        <OptimisticResponseProvider<TbqAnswer>>
          <TBQshortcomings
            hasFilter={hasFilter}
            qcInstance={qcInstance}
            shortcomings={shortcomings}
            tbqBrandcheckLinkedSnags={tbqBrandcheckLinkedSnags}
          />
        </OptimisticResponseProvider>
      </Box>
    </Main>
  );
}
