import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useKeywordModalConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      name: 'text',
      required: true,
      type: ValueTypeName.Text,
    },
    // For now it is decided to visually hide the score. I leave the code commented out in case we want to show it in the future.
    // {
    //   name: 'score',
    //   required: true,
    //   type: ValueTypeName.Number,
    // },
  ];

  return useFormConfig({ formFields });
};
