import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import {
  ActionBar,
  ButtonOrientation,
  LogoColor,
  LogoType,
  ShapeColor,
  ShapeType,
} from '@pro4all/shared/ui/general';

import { InfoHeader } from './InfoHeader';
import * as Styled from './InfoPage.styles';
import { InfoTopBar } from './InfoTopBar';
import { PageType } from './types';
import { useGetInfoPageUI } from './useGetInfoPageUI';

interface InfoPageProps {
  actions?: ActionProps[];
  buttonOrientation?: ButtonOrientation;
  description: JSX.Element | string;
  logoColorCustom?: LogoColor;
  logoTypeCustom?: LogoType;
  shapeColorCustom?: ShapeColor;
  shapeTypeCustom?: ShapeType;
  title: string;
  type?: PageType;
}

export const InfoPage = ({
  actions,
  buttonOrientation = 'horizontal',
  description,
  logoColorCustom,
  logoTypeCustom,
  shapeColorCustom,
  shapeTypeCustom,
  title,
  type = 'success',
}: InfoPageProps) => {
  const {
    bgElement,
    color,
    iconName,
    logoColor,
    logoType,
    shapeColor,
    shapeType,
  } = useGetInfoPageUI(type);

  return (
    <>
      {bgElement}
      <Styled.Container>
        <InfoTopBar
          color={logoColorCustom || logoColor}
          type={logoTypeCustom || logoType}
        />
        <Styled.InfoContainer>
          <Styled.Block>
            <div>
              <InfoHeader color={color} iconName={iconName} title={title} />
              <Box mb={2}>{description}</Box>
            </div>
            <Styled.ActionsContainer>
              <Styled.Actions $color={color}>
                <ActionBar
                  buttonOrientation={buttonOrientation}
                  flexEnd
                  mainActions={actions}
                />
              </Styled.Actions>
            </Styled.ActionsContainer>
          </Styled.Block>
          <Styled.BackgroundShape>
            <Styled.StyledShape
              color={shapeColorCustom || shapeColor}
              type={shapeTypeCustom || shapeType}
            />
          </Styled.BackgroundShape>
        </Styled.InfoContainer>
      </Styled.Container>
    </>
  );
};
