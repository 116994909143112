import { FolderPermission } from '@pro4all/graphql';

export const allPermissions = Object.values(FolderPermission);

export type EntityFolderPermissionValues = Record<FolderPermission, boolean>;

export type EntityFolderInheritedPermissionValues = Partial<
  Record<FolderPermission, string[]>
>;

export interface EntityFolderPermissionsRow {
  directPermissions: EntityFolderPermissionValues;
  displayName: string;
  entityIsActive: boolean;
  id: string;
  inheritedPermissions: EntityFolderInheritedPermissionValues;
  isGroup?: boolean;
}
