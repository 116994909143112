import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SearchEntities,
  SearchTranslationKeys,
} from '@pro4all/shared/search-utils';

import { ListBoxButton } from './SearchBar.styles';

interface Props {
  show: boolean;
  type: SearchEntities;
}

export const SearchBarHeader: React.FC<Props> = ({ show, type }) => {
  const { t } = useTranslation();
  if (!show) return null;

  return <ListBoxButton>{t(SearchTranslationKeys[type])}</ListBoxButton>;
};
