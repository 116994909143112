import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

export const CustomActionBarWrapper = ({ children }: PropsWithChildren<{}>) => (
  <Box
    ml={3}
    sx={{
      alignItems: 'center',
      display: 'flex',
    }}
  >
    {children}
  </Box>
);

export const CustomDefaultActionBarWrapper = ({
  children,
}: PropsWithChildren<{}>) => (
  <Box
    sx={{
      flexGrow: '1',
    }}
  >
    {children}
  </Box>
);
