import React from 'react';

import { Folder, FolderPermission } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { useGetSinglePermissionsData } from './hooks/useGetSinglePermissionsData';
import { removeExclusiveFolderPermissions } from './shared/helpers/removeExclusiveFolderPermissions';
import { OptionValue } from './types/types';
import * as Styled from './ExclusivePermissionSelector.styles';
import { InheritedPermission } from './InheritedPermissionsIcon';

export interface ExclusivePermissionSelectorProps {
  allPermissions: FolderPermission[];
  disableViewPermissions: boolean;
  folder: Folder;
  handleChange: (option: Option) => void;
  inheritedFromGroups: string[];
  optionByPermissionInherited: OptionValue;
  options: Option[];
  selectedOption: string;
  showFinalPermissions: boolean;
}

export const ExclusivePermissionSelectorComponent = ({
  disableViewPermissions,
  folder,
  handleChange,
  inheritedFromGroups,
  optionByPermissionInherited,
  options,
  selectedOption,
  showFinalPermissions,
}: ExclusivePermissionSelectorProps) => {
  const nonPermissions = [
    OptionValue.None,
    OptionValue.DeleteNone,
    OptionValue.UpdateNone,
  ];
  const permissionExplicit = nonPermissions.includes(
    selectedOption as OptionValue
  )
    ? (optionByPermissionInherited as unknown as FolderPermission)
    : (selectedOption as unknown as FolderPermission);
  const permissionInherited = nonPermissions.includes(
    optionByPermissionInherited as OptionValue
  )
    ? (selectedOption as unknown as FolderPermission)
    : (optionByPermissionInherited as unknown as FolderPermission);

  const finalPermission = removeExclusiveFolderPermissions({
    permissions: [permissionExplicit, permissionInherited],
  });

  const getSinglePermissionData = useGetSinglePermissionsData();
  const finalPermissionData = getSinglePermissionData(finalPermission[0]);

  return (
    <Styled.SelectorWrapper>
      {showFinalPermissions ? (
        finalPermissionData?.icon ? (
          <Tooltip
            placement="bottom"
            title={finalPermissionData.permissionText}
          >
            <Styled.IconWrapper>
              <Icon iconName={finalPermissionData.icon} />
            </Styled.IconWrapper>
          </Tooltip>
        ) : (
          <Styled.IconWrapper>
            <Icon iconName="notInterested" />
          </Styled.IconWrapper>
        )
      ) : (
        <Styled.PermissionSelector
          disabled={disableViewPermissions}
          name="can_view_permission"
          onChange={handleChange}
          options={options}
          updateValue
          value={selectedOption}
        />
      )}
      {showFinalPermissions ? null : (
        <InheritedPermission
          folder={folder}
          groups={inheritedFromGroups}
          permission={permissionInherited}
        />
      )}
    </Styled.SelectorWrapper>
  );
};

const skipUpdate = (
  prevProps: ExclusivePermissionSelectorProps,
  nextProps: ExclusivePermissionSelectorProps
) =>
  prevProps.allPermissions === nextProps.allPermissions &&
  prevProps.disableViewPermissions === nextProps.disableViewPermissions &&
  prevProps.selectedOption === nextProps.selectedOption &&
  prevProps.showFinalPermissions === nextProps.showFinalPermissions;
// We need the prop 'allPermissions' only to decide whether we should update this component.
// Else the 'handleChange' will operate on the previous permissions which will cause issues.

export const ExclusivePermissionSelector = React.memo(
  ExclusivePermissionSelectorComponent,
  skipUpdate
);
