export interface BreakpointsConfig {
  values: {
    lg: number;
    md: number;
    sm: number;
    xl: number;
    xs: number;
  };
}

export const breakpoints: BreakpointsConfig = {
  values: {
    lg: 1200,
    md: 1024,
    sm: 425,
    xl: 1536,
    xs: 0,
  },
};
