import React from 'react';
import { useTranslation } from 'react-i18next';

import { Member, useUsersIncludeQuery } from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { FormikSearchableSelect } from '@pro4all/shared/ui/general';

import { useTaskFormConfig } from './useTaskFormConfig';

export const ParticipantSelect = ({
  projectId,
  disabled,
}: {
  disabled?: boolean;
  projectId: string;
}) => {
  const { t } = useTranslation();
  const { data } = useUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeActive: true,
      includeEmail: true,
      includeInvited: true,
      includeIsAdmin: true,
      projectId,
    },
  });
  const members = (data?.users as Member[]) || [];

  const options = useMemberOptions(members, {
    includeNotInvited: true,
    type: 'User',
  });

  const { getField } = useTaskFormConfig();
  const participantField = getField('participant');

  return (
    <FormikSearchableSelect
      disabled={Boolean(disabled) || !options}
      id={participantField.name}
      label={participantField.label}
      name={participantField.name}
      options={options}
      placeholder={t('Select person')}
    />
  );
};
