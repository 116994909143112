import React from 'react';

import {
  ColoredText,
  DefaultMessageTemplate,
} from '@pro4all/shared/ui/general';

import { useGenerateLinkContext } from '../GenerateLinkContext';

export const ShareDocMessageTemplate: React.FC = () => {
  const { link } = useGenerateLinkContext();

  return (
    <>
      <ColoredText aria-label="share-link">{link}</ColoredText>
      <DefaultMessageTemplate />
    </>
  );
};
