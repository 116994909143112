import { SetProcessingStatusPayload, State } from '../useUploaderEditorReducer';

export const useSetProcessingStatusAction = () => {
  const setProcessingStatusAction = ({
    payload,
    state,
  }: {
    payload: SetProcessingStatusPayload;
    state: State;
  }) => {
    const { documentIds, processingStatus } = payload;
    const { meta } = state;
    const metaUpdated = [...meta];

    documentIds.forEach((documentId) => {
      const index = metaUpdated.findIndex((doc) => doc.id === documentId);

      // Only update if the element has been found.
      if (index !== -1) {
        metaUpdated[index].processingStatus = processingStatus;
      }
    });

    return {
      ...state,
      meta: metaUpdated,
    };
  };

  return setProcessingStatusAction;
};
