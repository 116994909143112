import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useFinalizeOrgInvitationMutation,
  useValidateRegistrationTokenMutation,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Text } from '@pro4all/shared/ui/typography';

import {
  FinalizeInvitationForm,
  FormValues,
} from '../components/finalize-invitation/FinalizeInvitationForm';
import { RegistrationPage } from '../components/RegistrationPage';

type ErrorType = 'invalidToken' | 'unknownError';

export const FinalizeInvitation = () => {
  const { t } = useTranslation();
  const { searchParams, goTo } = useRouting();
  const [validateRegistrationToken] = useValidateRegistrationTokenMutation();
  const [finalizeOrgInvitation] = useFinalizeOrgInvitationMutation();
  const [serverError, setServerError] = useState<ErrorType | null>(null);

  const token = searchParams.get('token');

  const locale = navigator.language;

  const onSubmit = async (values: FormValues) => {
    if (!token) return;

    const { firstName, lastName, password, phoneNumber } = values;

    try {
      const validationResponse = await validateRegistrationToken({
        variables: {
          token,
        },
      });

      // gql mutation returns a boolean which represents standardized success response
      if (validationResponse?.data?.validateRegistrationToken) {
        const finalizeResponse = await finalizeOrgInvitation({
          variables: {
            firstName,
            lastName,
            locale,
            password,
            phoneNumber,
            token,
          },
        });

        localStorage.setItem('i18nextLng', locale);

        if (finalizeResponse?.data?.finalizeOrgInvitation) {
          goTo('base');
        } else {
          setServerError('unknownError');
        }
      } else {
        setServerError('invalidToken');
      }
    } catch (e) {
      setServerError('unknownError');
    }
  };

  const errorMessages = {
    invalidToken: {
      message: t('Ask your team for a new invitation.'),
      title: t('Invitation expired'),
    },
    unknownError: {
      message: `${t('Please try again')}.`,
      title: t('Something went wrong'),
    },
  };

  return (
    <RegistrationPage>
      {serverError ? (
        <>
          <Box mb={1}>
            <Text color="error" variant="h1">
              {errorMessages[serverError].title}
            </Text>
          </Box>
          <Text color="textSecondary" variant="h5">
            {errorMessages[serverError].message}
          </Text>
        </>
      ) : (
        <>
          <Box mb={3}>
            <Text variant="h1">{t('To whom do we owe this pleasure?')}</Text>
          </Box>
          <FinalizeInvitationForm onSubmit={onSubmit} />
        </>
      )}
    </RegistrationPage>
  );
};
