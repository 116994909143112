import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SmartFolder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { SmartFolderForm } from '../smart-folder-form/SmartFolderForm';

interface Props {
  smartFolders: SmartFolder[];
}

export const SmartFolderEdit: React.FC<Props> = ({ smartFolders }) => {
  const { t } = useTranslation();
  const {
    params: { name },
    searchParams,
  } = useRouting();
  const [isOpen, setIsOpen] = useState(false);
  const [smartFolder, setSmartFolder] = useState<SmartFolder>();

  useEffect(() => {
    setSmartFolder(
      (smartFolders || []).find((smartFolder) => smartFolder.name === name)
    );

    setIsOpen(
      searchParams.is('action', 'editSmartFolder') && Boolean(smartFolder)
    );
  }, [name, searchParams, smartFolder, smartFolders]);

  const handleClose = () => searchParams.clear();

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header title={t('Edit smart folder')} />
      <SmartFolderForm onClose={handleClose} smartFolder={smartFolder} />
    </Sidebar>
  );
};
