import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
} from '@pro4all/shared/mui-wrappers';
import { Checkbox } from '@pro4all/shared/ui/general';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import { TFieldDefinitionWithPath } from './SelectSnagFilterModal';

type TFilterModalSnagFieldListProps = {
  filteredSelectedSnagsItemsWithPath: TFieldDefinitionWithPath[];
  handleSelectField: (snag: TFieldDefinitionWithPath) => void;
  onExcludeResultsCallback?: () => void;
  onIncludeResultsCallback?: () => void;
  query: string;
  selectedField?: TFieldDefinitionWithPath[];
};

export const FilterModalSnagFieldList = (
  props: TFilterModalSnagFieldListProps
) => {
  const { t } = useTranslation();
  const [isIncluding, setIsIncluding] = React.useState(false);
  const handleIncludeResults = () => {
    setIsIncluding((currentIncludingResults) => {
      if (currentIncludingResults) {
        props?.onIncludeResultsCallback?.();
      } else {
        props?.onExcludeResultsCallback?.();
      }

      return !currentIncludingResults;
    });
  };

  const getIconName = (type: string): IconName => {
    switch (type) {
      case 'Text':
        return 'text';
      case 'Number':
        return 'number';
      case 'Selection':
        return 'selection';
      case 'Bool':
        return 'boolean';
      case 'UserSelection':
        return 'person';
      case 'Status':
        return 'status';
      case 'DateTime':
        return 'calendar';
      case 'HierarchyList':
        return 'hierarchicalList';
      default:
        return 'text';
    }
  };

  const isSelected = (snag: TFieldDefinitionWithPath) =>
    Boolean(props.selectedField?.find?.((item) => item?.id === snag.id));

  if (!props.filteredSelectedSnagsItemsWithPath?.length) {
    return (
      <p>
        {t('No fields found for')} <strong>{props.query}</strong>
      </p>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mb: (theme) => theme.spacing(2),
        }}
      >
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isIncluding}
                  onChange={handleIncludeResults}
                />
              }
              label={t('Include results from other matching templates')}
            />
          </FormGroup>
        </Box>
      </Box>
      <List>
        {props.filteredSelectedSnagsItemsWithPath?.map((snag) => (
          <ListItemButton
            key={snag.id}
            onClick={() => props.handleSelectField(snag)}
            selected={isSelected(snag)}
          >
            <Icon
              fontSize="large"
              iconName={getIconName(snag.type as string)}
            />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                marginLeft: (theme) => theme.spacing(1),
                width: '100%',
              }}
            >
              <Box width="40%">
                <Text variant="body1">{snag.name}</Text>
              </Box>
              <Box width="60%">
                <Text variant="body1">{snag.path.join(' > ')}</Text>
              </Box>
              {isSelected(snag) && (
                <Box
                  sx={{
                    position: 'absolute',
                    right: (theme) => theme.spacing(2),
                  }}
                >
                  <Icon fontSize="large" iconName="check" />
                </Box>
              )}
            </Box>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
