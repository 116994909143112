import { ValueTypeName } from '@pro4all/graphql';

import { FormFields } from './Types';

export const getFieldDefinitionTypeInput = ({
  valueType,
  type,
}: {
  type: keyof typeof ValueTypeName;
  valueType: FormFields;
}) => {
  const {
    hierarchyList,
    maxValue,
    minValue,
    multipleAnswers,
    multiSelect,
    rangeEnabled,
    statusOptions,
    selectionOptions,
    staticBreadcrumbs,
  } = valueType;

  let fieldDefinitionTypeInput = {};

  // This switch will be extended in the future with other types that contain type related properties.
  switch (type) {
    case ValueTypeName.HierarchyList:
      fieldDefinitionTypeInput = {
        hierarchyListId: hierarchyList.id,
        multipleAnswers,
        staticBreadcrumbs,
      };
      break;
    case ValueTypeName.Number:
      fieldDefinitionTypeInput = rangeEnabled
        ? {
            maxValue: parseInt(maxValue),
            minValue: parseInt(minValue),
          }
        : {};
      break;
    case ValueTypeName.Status:
      fieldDefinitionTypeInput = {
        // Map to remove __typename from object (generated by gql)
        options: statusOptions.map((option, index) => ({
          color: option.color,
          index,
          name: option.name.trim(),
        })),
      };
      break;
    case ValueTypeName.Selection:
      fieldDefinitionTypeInput = {
        displayType: valueType.displayType,
        multiSelect,
        options: selectionOptions.map((option, index) => ({
          index,
          name: option.name.trim(),
        })),
      };
      break;
    case ValueTypeName.UserSelection:
      fieldDefinitionTypeInput = {
        displayType: valueType.displayType,
        multiSelect,
        options: [],
      };
      break;
    default:
  }

  return fieldDefinitionTypeInput;
};
