import {
  HierarchyPayload,
  useHierarchyEditorContext,
} from '@pro4all/shared/hierarchy-editor';

import { getBreadCrumbHierarchy } from './getBreadCrumbHierarchy';

export const useGetPayloadHierarchy = () => {
  const {
    state: { items, itemsNonExisting },
  } = useHierarchyEditorContext();

  const getPayloadHierarchy = ({
    selectedItemsIds,
  }: {
    selectedItemsIds: string[];
  }) => {
    // Extract the items that are included in selectedItemsIds.
    const selectedItems = items.filter((item) =>
      selectedItemsIds.includes(item.id)
    );

    const selectedNonExistingItems = itemsNonExisting.filter((item) =>
      selectedItemsIds.includes(item.value)
    );

    const payloadExisting = selectedItems.map((item) => {
      const nameArray = getBreadCrumbHierarchy({ item, items });
      return { breadcrumbs: nameArray, value: item.id };
    }) as HierarchyPayload[];

    const payloadNonExisting = selectedNonExistingItems.map((item) => {
      const nameArray = item.breadcrumbs;
      return { breadcrumbs: nameArray, value: item.value };
    }) as HierarchyPayload[];

    const payload = [...payloadExisting, ...payloadNonExisting];

    return JSON.stringify(payload);
  };

  return getPayloadHierarchy;
};
