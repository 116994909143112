import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup, Typography } from '@pro4all/shared/mui-wrappers';
import { FormikCheckbox } from '@pro4all/shared/ui/general';

export const ConfigureResults: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Typography variant="h6">{t('Results')}</Typography>
      <FormikCheckbox label={t('Show empty answers')} name="showEmptyAnswers" />
    </FormGroup>
  );
};
