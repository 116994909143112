import React, { Reducer, useCallback, useContext, useReducer } from 'react';

import {
  Action,
  ActionType,
  FillInitialItemsPayload,
  hierarchyEditorReducer,
  MoveItemPayload,
  PasteItemPayload,
  SelectItemPayload,
  State,
} from './hierarchyEditorReducer';
import { BasePropsHierarchyItem } from './types';

type ContextType<HierarchyItem extends BasePropsHierarchyItem> = {
  addItem: (item: HierarchyItem) => void;
  addItems: (items: HierarchyItem[]) => void;
  deleteItem: (id: string) => void;
  deselectItemAction: (id: string) => void;
  editItem: (item: HierarchyItem) => void;
  fillInitialItems: (items: FillInitialItemsPayload<HierarchyItem>) => void;
  forceRender: () => void;
  freezeItems: () => void;
  moveItem: (moveData: MoveItemPayload) => void;
  pasteItem: (pasteData: PasteItemPayload) => void;
  removeNonExistingItem: (id: string) => void;
  resetToFreezedItems: () => void;
  selectItems: (selectData: SelectItemPayload) => void;
  state: State<HierarchyItem>;
};

const HierarchyEditorContext = React.createContext(null);

export function useHierarchyEditorContext<
  HierarchyItem extends BasePropsHierarchyItem
>() {
  return useContext<ContextType<HierarchyItem>>(HierarchyEditorContext);
}

export function HierarchyEditorProvider<
  HierarchyItem extends BasePropsHierarchyItem
>({ children }: { children: JSX.Element }) {
  const [state, dispatch] = useReducer(
    hierarchyEditorReducer as Reducer<
      State<HierarchyItem>,
      Action<HierarchyItem>
    >,
    {
      items: [],
      itemsFreezed: [],
      itemsInitial: [],
      itemsNonExisting: [],
    }
  );
  // Define all actions
  const addItem = useCallback((item: HierarchyItem) => {
    dispatch({
      payload: item,
      type: ActionType.ADD_ITEM,
    });
  }, []);
  const addItems = useCallback((items: HierarchyItem[]) => {
    dispatch({
      payload: items,
      type: ActionType.ADD_ITEMS,
    });
  }, []);
  const deleteItem = useCallback((id: string) => {
    dispatch({
      payload: id,
      type: ActionType.DELETE_ITEM,
    });
  }, []);
  const deselectItemAction = useCallback((id: string) => {
    dispatch({
      payload: id,
      type: ActionType.DESELECT_ITEM,
    });
  }, []);
  const editItem = useCallback((item: HierarchyItem) => {
    dispatch({
      payload: item,
      type: ActionType.EDIT_ITEM,
    });
  }, []);
  const fillInitialItems = useCallback(
    (payload: FillInitialItemsPayload<HierarchyItem>) => {
      dispatch({
        payload,
        type: ActionType.FILL_INITIAL_ITEMS,
      });
    },
    []
  );
  const forceRender = useCallback(() => {
    dispatch({
      type: ActionType.FORCE_RENDER,
    });
  }, []);
  const freezeItems = useCallback(() => {
    dispatch({
      type: ActionType.FREEZE_ITEMS,
    });
  }, []);
  const moveItem = useCallback((moveData: MoveItemPayload) => {
    dispatch({
      payload: moveData,
      type: ActionType.MOVE_ITEM,
    });
  }, []);
  const pasteItem = useCallback((pasteData: PasteItemPayload) => {
    dispatch({
      payload: pasteData,
      type: ActionType.PASTE_ITEM,
    });
  }, []);
  const removeNonExistingItem = useCallback((id: string) => {
    dispatch({
      payload: id,
      type: ActionType.REMOVE_NON_EXISTING_ITEM,
    });
  }, []);

  const resetToFreezedItems = useCallback(() => {
    dispatch({
      type: ActionType.RESET_TO_FREEZED_ITEMS,
    });
  }, []);
  const selectItems = useCallback((selectData: SelectItemPayload) => {
    dispatch({
      payload: selectData,
      type: ActionType.SELECT_ITEMS,
    });
  }, []);

  return (
    <HierarchyEditorContext.Provider
      value={{
        addItem,
        addItems,
        deleteItem,
        deselectItemAction,
        editItem,
        fillInitialItems,
        forceRender,
        freezeItems,
        moveItem,
        pasteItem,
        removeNonExistingItem,
        resetToFreezedItems,
        selectItems,
        state,
      }}
    >
      {children}
    </HierarchyEditorContext.Provider>
  );
}
