import React, { useEffect } from 'react';
import * as MuiIcons from '@mui/icons-material';
import styled from 'styled-components';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import { TMuiIcon } from './composed-snag-form-pin-types';

export type ComposedPinType = 'snag' | 'form' | 'tbq';

export type TComposedPinProps = {
  currentFile?: File | string | null;
  customColor?: string;
  left?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  report?: boolean;
  scale?: number;
  selectedIconName?: TMuiIcon;
  simpleIcon?: boolean;
  top?: number;
  type: ComposedPinType;
};

export const ComposedPin = ({
  top = 0,
  left = 0,
  report = false,
  ...props
}: TComposedPinProps) => {
  const defaultSelectedIcon =
    props.type === QualityControlInstanceType.Tbq
      ? ''
      : props.type === QualityControlInstanceType.Snag
      ? 'ShareLocation'
      : 'Rule';

  const Component = defaultSelectedIcon
    ? MuiIcons[props.selectedIconName || (defaultSelectedIcon as TMuiIcon)]
    : null;

  const currentPin = !props.simpleIcon
    ? props.type === QualityControlInstanceType.Snag
      ? 'changeableSnagIcon'
      : 'changeableFormIcon'
    : props.type === QualityControlInstanceType.Snag
    ? 'changeableSimpleSnagIcon'
    : props.type === QualityControlInstanceType.Tbq
    ? 'tbqMainGreen'
    : 'changeableSimpleFormIcon';

  const scale = props.scale || 1;

  const iconSize = (props.simpleIcon ? 18 : 22) * scale;

  const fileUrl =
    props.currentFile instanceof File
      ? URL.createObjectURL(props.currentFile)
      : props.currentFile;

  const [fileExists, setFileExists] = React.useState(
    Boolean(props.currentFile)
  );

  useEffect(() => {
    if (!fileUrl) return;

    if (fileUrl.startsWith('blob:')) {
      setFileExists(true);
      return;
    }

    (async () => {
      const resp = await fetch(fileUrl);
      if (!resp.ok) {
        setFileExists(false);
      }
    })();
  }, [fileUrl]);
  const containedWidth = (props.simpleIcon ? 20 : 27) * scale;
  const containedHeight = (props.simpleIcon ? 20 : 27) * scale;

  const tailHeight = 18 * scale;

  return (
    <OuterIconContainer
      onClick={(event) => {
        props?.onClick?.(event);
      }}
      style={{
        height: !props.simpleIcon
          ? `${containedHeight + tailHeight}px`
          : 'auto',
        position: 'relative',
      }}
    >
      <InnerIconContainer
        style={{
          cursor: 'pointer',
          height: `${containedHeight}px`,
          position: 'relative',
          width: `${containedWidth}px`,
        }}
      >
        <Icon
          className="report-pin-icon"
          customColor={props.customColor}
          iconName={currentPin}
          scale={scale}
        />

        <IconContainer
          style={{
            alignItems: 'center',
            ...(fileUrl && { backgroundImage: `url(${fileUrl})` }),
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: props.type === 'snag' ? '50%' : '0',
            display: 'flex',
            height: 'calc(100% - 4px)',
            justifyContent: 'center',
            left: left + 'px',
            margin: '2px',
            padding: 0,
            position: 'absolute',
            top: top + 'px',
            width: 'calc(100% - 4px)',
            zIndex: 200,
          }}
        >
          {!fileExists && Component && (
            <Component
              style={{
                fontSize: `${iconSize}px`,
                height: '100%',
                padding: 2 * scale + 'px',
                width: '100%',
              }}
            />
          )}
        </IconContainer>
      </InnerIconContainer>
    </OuterIconContainer>
  );
};

// All styles are inline because we need to render in PDF reports with dynamic styles
const OuterIconContainer = styled(Box)``;

const InnerIconContainer = styled(Box)``;

const IconContainer = styled(Box)``;
