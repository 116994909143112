import { css } from 'styled-components';

export const ScrollbarStyles = css`
  *:hover::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[400]};
  }
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[300]};
    border: none;
  }
  ::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
  }
`;
