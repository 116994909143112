import { ProjectsUnreadCountQuery } from '@pro4all/graphql';

export const getUnreadCountByProjectId = (
  projectId: string,
  messagesData?: ProjectsUnreadCountQuery
) => {
  const projectMessages = messagesData?.projectsUnreadCount.find(
    (message) => message?.id === projectId
  );

  const unreadCount = Number(projectMessages?.unreadCount || 0);

  return unreadCount;
};
