import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useDeactivateUsersMutation, User, UserState } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  useOptimisticResponseContext,
  useTableCheck,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const useDeactivateUsers = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deactivateUsers] = useDeactivateUsersMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<User>();
  const { contextMenuRow } = useTableContextMenuRowContext<User>();
  const { userId } = useOrganizationContext();

  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<User>();

  return async () => {
    try {
      // We filter out users that are not marked as active
      const activeUserIds = contextMenuRow
        ? [contextMenuRow.id]
        : checkedRows
            .filter((user) => user.active === true)
            .map((user) => user.id);

      const meIsIncluded = activeUserIds.find((user) => user === userId); // Store if we are among the users selected to display a warning message
      const userIds = meIsIncluded
        ? activeUserIds.filter((user) => user !== userId)
        : activeUserIds;

      if (userIds.length) {
        await deactivateUsers({
          variables: { userIds },
        });

        editItems(
          items
            .filter((item) => userIds.includes(item.id))
            .map((item) => ({
              ...item,
              active: false,
              state: UserState.Deactivated,
            }))
        );

        if (userIds.length && !contextMenuRow) {
          enqueueSnackbar(
            `${t('{{count}} persons have been deactivated', {
              count: userIds.length, // Count only users that were active and not me
            })}.${
              meIsIncluded ? ` ${t('You cannot deactivate yourself')}.` : ''
            }`
          );
        } else {
          const user =
            checkedRows?.filter(
              (user) => user.active === true && user.id !== userId
            )?.[0] ?? contextMenuRow;
          const message = (
            <ItemChangedMessage
              description={MessageAction.Deactivated}
              entityName={user.displayName}
              entityTypeTranslation={EntityTypeTranslation.User}
            />
          );
          enqueueSnackbar(message);
        }

        uncheckAllRows();
      } else {
        enqueueSnackbar(t('No active users selected'));
      }
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
