import React, { createContext, useContext } from 'react';

import type { UseEditObjectsResult } from './useEditObjects';

const EditObjectsContext = createContext<UseEditObjectsResult | undefined>(
  undefined
);

export function useEditObjectsContext() {
  const context = useContext<UseEditObjectsResult | undefined>(
    EditObjectsContext
  );
  if (!context) throw Error('Object context not initialized.');
  return context;
}

export const EditObjectsProvider: React.FC<UseEditObjectsResult> = ({
  children,
  ...value
}) => {
  if (!value) throw Error('Object context not initialized.');
  return (
    <EditObjectsContext.Provider value={value}>
      {children}
    </EditObjectsContext.Provider>
  );
};
