import {
  Template,
  TemplateService,
  TemplateType,
  useTemplatesByIdsQuery,
} from '@pro4all/graphql';
import { GetReportConfigurationReportOptionsProps } from '@pro4all/shared/types';
import { Option } from '@pro4all/shared/types';

import { useGetDummyTemplates } from './useGetDummyTemplates';

export const useGetReportConfigurationTemplates = ({
  createReportConfiguration,
  reportConfigurationReportOptions,
}: GetReportConfigurationReportOptionsProps) => {
  // In case we are editing a report configuration, we have to fetch data for the templateIds that are included in the report configuration.
  const templateIds = reportConfigurationReportOptions
    ? Object.keys(reportConfigurationReportOptions.templates).filter(
        (key) => key !== 'default'
      )
    : [];

  const { data } = useTemplatesByIdsQuery({
    skip: !reportConfigurationReportOptions,
    variables: {
      ids: templateIds,
      templateService: TemplateService.QualityControl,
    },
  });

  const { dummyTemplates } = useGetDummyTemplates();

  // These are the templates that contain information (the most important one is `items`) that we need to render the report.
  const reportConfigurationTemplates =
    (data?.templatesByIds as Template[]) || ([] as Template[]);

  const reportConfigurationFormTemplateOptions: Option[] =
    reportConfigurationTemplates
      .filter((template) => template.type === TemplateType.Form)
      .map(({ id, name }) => ({ id, label: name || '' }));
  const reportConfigurationSnagTemplateOptions: Option[] =
    reportConfigurationTemplates
      .filter((template) => template.type === TemplateType.Snag)
      .map(({ id, name }) => ({ id, label: name || '' }));

  return {
    reportConfigurationDummyTemplates:
      createReportConfiguration || reportConfigurationReportOptions
        ? dummyTemplates
        : null,
    reportConfigurationFormTemplateOptions,
    reportConfigurationSnagTemplateOptions,
  };
};
