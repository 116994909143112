import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import { useMyOrganizationProjectsQuery } from '@pro4all/graphql';
import { ProjectSidebar } from '@pro4all/projects/ui/sidebar';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import {
  FormFooter,
  FormikSearchableSelect,
  Loader,
} from '@pro4all/shared/ui/general';

import * as Styled from './LinkProject.styles';
import { useLinkProjectModalConfig } from './useLinkProjectModalConfig';
import { useProjectObjects } from './useProjectObjects';

interface FormValues {
  project: Option | null;
}

export const LinkProject: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { linkProject } = useProjectObjects();
  const { data, loading: loadingProjects } = useMyOrganizationProjectsQuery();

  const showModal = searchParams.is('action', 'linkObjectToProject');

  const projectOptions: Option[] =
    data?.projects?.map((project) => ({
      id: project.id,
      label: project.name,
    })) || [];

  const handleSubmit = async ({ project }: FormValues) => {
    if (!project) return;
    await linkProject(project.id);
    searchParams.clear();
  };

  const openProjectSidebar = () => {
    searchParams.set({ action: 'createProject' });
  };

  const onClose = () => {
    searchParams.delete('action');
  };

  const initialValues: FormValues = {
    project: null,
  };

  const { getField, validationSchema } = useLinkProjectModalConfig();
  const projectField = getField('project');

  return (
    <>
      <FloatingModal open={showModal}>
        <FloatingModal.Header iconName="link">
          {t('Link to project')}
        </FloatingModal.Header>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit({ project: values.project })}
          validationSchema={validationSchema}
        >
          {({ dirty, errors, isSubmitting }) => (
            <StyledForm>
              <Styled.Text>
                {t(
                  'Link an object to a project to start gathering information on a specific object.'
                )}
              </Styled.Text>

              {loadingProjects ? (
                <Loader />
              ) : (
                <FormikSearchableSelect
                  label={projectField.label}
                  name={projectField.name}
                  options={projectOptions}
                  placeholder={t('Select a project')}
                />
              )}
              <Styled.Button
                onClick={openProjectSidebar}
                startIcon="projectCreate"
              >
                {t('New Project')}
              </Styled.Button>

              <FormFooter
                disableSubmit={isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                })}
                marginTop="auto"
                onClose={onClose}
                sticky
              />
            </StyledForm>
          )}
        </Formik>
      </FloatingModal>
      <ProjectSidebar />
    </>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
