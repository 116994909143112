import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

import { Headers } from './FormComponents';

interface Props {
  email: string;
}

export const ThankYou: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Headers
        subtitle={t('Registration completed')}
        title={t('Confirmation email sent')}
      />
      <Text>
        {t(
          "Thank you for registering. A confirmation email has been sent to '{{email}}'. Click the link to confirm your account and get started!",
          { email }
        )}
      </Text>
      <Box mt={2}>
        <Button fullWidth href="/">
          {t('To login')}
        </Button>
      </Box>
    </Box>
  );
};
