import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import {
  convertBooleanToString,
  stringifyArrayValue,
  toApiDateUtc,
} from '@pro4all/shared/utils';

export const convertQcInstanceValue = ({
  item,
  value,
}: {
  item: FieldDefinition;
  value: any;
}) =>
  item?.type === ValueTypeName.Bool
    ? convertBooleanToString(value)
    : item?.type === ValueTypeName.DateTime
    ? toApiDateUtc(value)
    : stringifyArrayValue(value);
