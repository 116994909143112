import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Option } from '@pro4all/shared/types';
import {
  Dialog,
  FormFooter,
  FormikForm,
  FormikSearchableSelect,
  InfoBlock,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { usePreSubmit } from './preSubmit';
import { useShareBuild12Context } from './ShareBuild12Context';
import { FormFields } from './types';
import { useGetConnectionOptions } from './useGetConnectionOptions';
import { useGetInitialValues } from './useGetInitialValues';
import { useGetProjectPhaseOptions } from './useGetProjectPhaseOptions';
import { useShareBuild12FormConfig } from './useShareBuild12FormConfig';
import { useSubmit } from './useSubmit';

export const ShareBuild12Form = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { getField, validationSchema } = useShareBuild12FormConfig();
  const initialValues = useGetInitialValues();
  const onSubmit = useSubmit({ onClose });
  const onPreSubmit = usePreSubmit();
  const connectionField = getField('connection');
  const projectPhaseField = getField('projectPhase');

  const { connectionOptions } = useGetConnectionOptions();
  const { projectPhaseOptions } = useGetProjectPhaseOptions();

  const { setSelectedConnection, validationScreen, setValidationScreen } =
    useShareBuild12Context();

  const selectConnection = ({
    option,
    setFieldValue,
    values,
  }: {
    option: Option;
    setFieldValue: (
      field: string,
      // Formik's setFieldValue has no strong typing
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void;
    values: FormFields;
  }) => {
    // We do some things only if the user changed the connection or removed it.
    if (values.connection?.id !== option?.id) {
      // Store the selected connection in React.Context, so that the 'useGetProjectPhaseOptions' hook
      // re-renders to fetch a list of project phases that are related to the selected connection.
      setSelectedConnection && setSelectedConnection(option?.id);

      // Remove the current value of the project phase which is related to the previous connection.
      setFieldValue(projectPhaseField?.name || '', null);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        const isValid = await onPreSubmit(values);
        if (!isValid) {
          helpers.setSubmitting(false);
          return;
        }
        onSubmit(values, false);
      }}
      validationSchema={validationSchema}
    >
      {({
        dirty,
        errors,
        isSubmitting,
        setFieldValue,
        values,
        setSubmitting,
      }) => (
        <FormikForm>
          <FormWrapper noPadding>
            <InfoBlock
              paragraph={t('Select a phase you want to share it to.')}
            />
            <FormikSearchableSelect
              id={connectionField?.name}
              label={connectionField?.displayName}
              name={connectionField?.name || ''}
              onChange={(option) =>
                selectConnection({ option, setFieldValue, values })
              }
              options={connectionOptions}
              placeholder={t('Select connection')}
            />
            {values.connection && (
              <FormikSearchableSelect
                id={projectPhaseField?.name}
                label={projectPhaseField?.displayName}
                name={projectPhaseField?.name || ''}
                options={projectPhaseOptions}
                placeholder={t('Select project phase')}
              />
            )}
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pt={2}
            sticky
          />
          <Dialog
            closeLabel={t('Skip')}
            confirmLabel={t('Mark as sent')}
            iconName="warning"
            name="routerPrompt"
            onClose={() => {
              setSubmitting(true);
              setValidationScreen(false);
              onSubmit(values, false);
            }}
            onConfirm={() => {
              setSubmitting(true);
              setValidationScreen(false);
              onSubmit(values, true);
            }}
            open={validationScreen}
            title={t('Document already exists')}
          >
            <Text>
              {t(
                'One or more documents already exist in the selected 12Build project and phase. Either chose to mark as sent to 12Build or skip the document(s).'
              )}
            </Text>
          </Dialog>
        </FormikForm>
      )}
    </Formik>
  );
};
