import { InstancesGroupedByFpProps } from '@pro4all/shared/types';

import DrawingPage from '../DrawingPage';
import { InstancePage } from '../InstancePage';

const InstancesGroupedByDrawing = ({
  groupedInstances,
  tasks,
  reportDrawings,
  downloadPdfContext,
  enableFrontPage,
  reportOptions,
  miniMaps,
  members,
  photoBlobs,
  signatures,
}: InstancesGroupedByFpProps) => {
  const filterSignatures = (instanceId: string) =>
    signatures?.filter((signature) => signature.instanceId === instanceId);
  return (
    <div>
      {groupedInstances?.map((group, indexGroup) => {
        const reportFloorplanHtml = reportDrawings
          ? reportDrawings[group.visualContextId + group.page]
          : undefined;

        return (
          <div key={indexGroup}>
            {reportFloorplanHtml && reportFloorplanHtml.length > 0 && (
              <DrawingPage
                group={group}
                hasFirstPageBeenPrinted={
                  downloadPdfContext
                    ? enableFrontPage ||
                      Boolean(tasks && tasks.length) ||
                      indexGroup > 0
                    : true
                }
                reportDrawingHtml={reportFloorplanHtml}
                reportOptions={reportOptions}
              />
            )}

            {group.instances.map((instance, indexInstance) => {
              const miniMapHtml = miniMaps ? miniMaps[instance.id] : undefined;
              return (
                <InstancePage
                  hasFirstPageBeenPrinted={
                    downloadPdfContext
                      ? enableFrontPage ||
                        Boolean(tasks && tasks.length) ||
                        Boolean(
                          reportFloorplanHtml && reportFloorplanHtml.length
                        ) ||
                        indexInstance > 0
                      : true
                  }
                  instance={instance}
                  key={instance.id}
                  members={members}
                  miniMapHtml={miniMapHtml}
                  photoBlobs={photoBlobs}
                  reportDrawingHtml={reportFloorplanHtml}
                  reportOptions={reportOptions}
                  signatures={filterSignatures(instance.id)}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default InstancesGroupedByDrawing;
