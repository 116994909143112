export enum EntityScope {
    Project = 0,
    Organization = 1,
  }

export type ApiResponse<Response, Schema> = Omit<Response, 'data'> & {
  data: Schema;
}

export enum EntityType {
  User = 0,
  Group = 1,
}
