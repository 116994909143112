import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const EditObject: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M13 8.82108V12.0011L15.5 14.5L14 15.8511L12 13.7511L8 17.9511V20.0011C8 20.5511 7.55 21.0011 7 21.0011H4C3.45 21.0011 3 20.5511 3 20.0011V17.0011C3 16.4511 3.45 16.0011 4 16.0011H7L11 12.0011V8.82108C9.84 8.40108 9 7.30108 9 6.00108C9 4.17108 10.63 2.73108 12.51 3.04108C13.7 3.24108 14.68 4.18108 14.93 5.36108C15.26 6.93108 14.35 8.33108 13 8.82108Z" />
    <path
      clipRule="evenodd"
      d="M21.6287 15.3464C21.7912 15.5089 21.7912 15.7714 21.6287 15.9339L20.8662 16.6964L19.3037 15.1339L20.0662 14.3714C20.144 14.2934 20.2497 14.2495 20.3599 14.2495C20.4701 14.2495 20.5758 14.2934 20.6537 14.3714L21.6287 15.3464ZM14.2495 21.5422V20.2755C14.2495 20.2172 14.2703 20.1672 14.312 20.1255L18.8578 15.5797L20.4203 17.1422L15.8703 21.688C15.8328 21.7297 15.7787 21.7505 15.7245 21.7505H14.4578C14.3412 21.7505 14.2495 21.6589 14.2495 21.5422Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
