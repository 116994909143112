import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { endTimeConfig } from './endTimeConfig';

export const useGeneratePublicLinkFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    endTimeConfig,
    {
      displayName: 'Set a password', // i18n
      name: 'enablePassword',
      type: ValueTypeName.Bool,
    },
    {
      displayName: 'Show folders', // i18n
      name: 'showFolders',
      type: ValueTypeName.Bool,
    },
  ];

  return useFormConfig({ formFields });
};
