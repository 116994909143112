import { BasePropsHierarchyItem, ItemProps } from '../types';

import { changeItem } from './changeItem';

export function moveItemAfterPaste<
  HierarchyItem extends BasePropsHierarchyItem
>({
  allItems,
  newParentNodeId,
  previousNodeId,
}: Pick<
  ItemProps<HierarchyItem>,
  'allItems' | 'newParentNodeId' | 'previousNodeId'
>) {
  // Get all direct child items of the new parent.
  const childItems = allItems.filter(
    (item) => item.parentNodeId === newParentNodeId
  );

  // Find the first child item.
  const firstChild = childItems.find(
    (item) => item.previousNodeId === null
  ) as HierarchyItem;

  if (firstChild) {
    // Previous first child becomes the second child.
    return changeItem<HierarchyItem>({
      allItems,
      item: { ...firstChild, previousNodeId },
    });
  } else {
    return allItems;
  }
}
