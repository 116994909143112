import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

export const FolderWrapper = styled(Box)`
  && {
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`;

export const FolderIcon = styled(Icon)`
  padding-right: ${({ theme }) => theme.spacing(1)};
`;
