import { useTranslation } from 'react-i18next';

import { ReportConfiguration } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { ColumnProps } from '@pro4all/shared/ui/general';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const useColumnsAdditional = () => {
  const { t } = useTranslation();

  const columnsAdditional: ColumnProps<ReportConfiguration>[] = [
    {
      filterable: true,
      getValue: ({ scope }) => t<string>(scope),
      headerComponent: (
        <FilterHeader<ReportConfiguration, null>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          label="Scope"
          minWidth={100}
          propertyId="scope"
          translateOptions
        />
      ),
      key: 'scope',
      width: 100,
    },
  ];
  return columnsAdditional;
};
