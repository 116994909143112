import { useCallback, useEffect, useState } from 'react';

import { DocumentService } from '@pro4all/documents/data-access';
import { noop } from '@pro4all/shared/utils';

import type { StamperDocument } from '../store';

type Params = Parameters<typeof DocumentService.getPreviewImage>;
type Retry = () => void;
type Src = string | null | undefined;

export interface Preview {
  retries: number;
  retry: Retry;
  src: Src;
  status: Status;
}

export enum Status {
  Error,
  Loading,
  NotFound,
  Ok,
}

const init: Preview = {
  retries: 0,
  retry: noop,
  src: undefined,
  status: Status.Loading,
};

const cache = new Map<string, Preview>();
const requests = new Map<string, number>();

const RETRIES = 3;
const TIMEOUT = 500;

export function useDocumentPreviews(
  current: StamperDocument,
  queue: StamperDocument[]
): Preview {
  const index = queue.indexOf(current);
  const key = `${current?.documentId}${current?.id}0`;

  const [preview, setPreview] = useState<Preview>(cache.get(key) ?? init);

  const setCurrent = useCallback(() => {
    const cached = cache.get(key) ?? init;
    setPreview((preview) => (cached !== preview ? cached : preview));
  }, [key]);

  const fetchPreview = useCallback(
    async ([{ documentId, page, versionId }]: Params) => {
      const key = `${documentId}${versionId}${page}`;

      // The cache stores all blobs returned from getPreviewImage so there's
      // no need to re-request an image if it's already in the cache.
      if (cache.get(key)?.src) return;

      const handle = async () => {
        // Initially we want our cache to be undefined: we don't know whether the
        // requests succeeds, yeilding a string, or fails yielding a null.
        // Before the request fires we want to reset retry to a no-op. We don't
        // want the user to fire of 1000 requests when rapidly clicking a button.
        const { retries } = cache.get(key) ?? init;
        cache.set(key, { ...init, retries });

        setCurrent();

        const result = await DocumentService.getPreviewImage({
          documentId,
          page,
          versionId,
        });
        // If we still have retries, mark the image as being loaded still.
        const src = retries < RETRIES ? result ?? undefined : result;

        const status = src
          ? Status.Ok
          : src === undefined
          ? Status.Loading
          : src === null
          ? Status.NotFound
          : Status.Error;

        if (!src && retries < RETRIES)
          requests.set(key, window.setTimeout(handle, TIMEOUT));

        cache.set(key, {
          retries: src ? retries : retries + 1,
          retry: handle,
          src,
          status,
        });

        setCurrent();
      };

      handle();
    },
    [setCurrent]
  );

  useEffect(() => {
    // The next declaration is a bit hard to parse, but it boils down to this:
    // Construct a params array for getPreviewImage to fetch three previews of
    // the first page of: the previous document, the current document and the
    // next document.
    const params = queue
      .slice(Math.max(0, index - 1), Math.min(queue.length, index + 2))
      .flatMap(({ documentId: id, id: versionId, pages = [0] }) =>
        pages
          .slice(0, 1)
          .map(
            (index) => [{ documentId: id, page: index, versionId }] as Params
          )
      );

    params.forEach(fetchPreview);

    return () => {
      Array.from(requests.values()).forEach((timer) =>
        window.clearTimeout(timer)
      );
      requests.clear();
    };
  }, [fetchPreview, index, queue]);

  useEffect(() => setCurrent(), [setCurrent]);

  return preview;
}
