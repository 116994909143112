import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  OrganizationDocument,
  useUpdateOrganizationMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';

import { FormFields } from './types';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [updateOrganization] = useUpdateOrganizationMutation();
  const { setUserOrganizationName, organization } = useOrganizationContext();

  return async (values: FormFields) => {
    try {
      const { id, name, settings } = values;

      await updateOrganization({
        variables: { id, name, settings },
      });

      // Update organization cache
      const queryParams = {
        query: OrganizationDocument,
        variables: { id: organization.id },
      };
      client.writeQuery({
        data: {
          organization: {
            ...organization,
            settings: {
              ...organization.settings,
              ...settings,
            },
          },
        },
        ...queryParams,
      });

      enqueueSnackbar(t('Changes are successfully saved'), {
        variant: 'success',
      });
      setUserOrganizationName(name);
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
      console.warn(e);
    }
  };
};
