import { BasePropsHierarchyItem, ItemProps } from '../types';

export const getChildItems = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  id,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'id'>) => {
  const childItemsResponse = [] as HierarchyItem[];
  const childItems = allItems.filter((item) => item.parentNodeId === id);
  childItems.forEach((childItem) => {
    childItemsResponse.push(childItem);
    childItemsResponse.push(
      ...getChildItems<HierarchyItem>({ allItems, id: childItem.id })
    );
  });
  return childItemsResponse;
};
