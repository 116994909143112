import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoApiKeys: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="trust"
      text={t(
        'You have not created any API keys. Start integrating your software by creating one.'
      )}
      title={t('No API keys')}
    />
  );
};
