import { Box } from 'libs/shared/mui-wrappers/src/Box';

import { Text } from '@pro4all/shared/ui/typography';

export const FieldDescription = ({
  displayDescription,
}: {
  displayDescription?: string | null;
}) =>
  displayDescription ? (
    <Box color="grey" fontStyle="italic">
      <Text>{displayDescription}</Text>
    </Box>
  ) : null;
