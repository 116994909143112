import { useCallback } from 'react';

import { BaseRow, ColumnProps as Column } from '../types';

export function useTableFilter<Row extends BaseRow>(columns: Column<Row>[]) {
  const filterRows = useCallback(
    (rows: Row[], filter: string) => {
      const filterableColumns = columns.filter(({ filterable }) => filterable);
      if (!rows || !filter || filterableColumns.length === 0) {
        return rows;
      }

      return rows.filter((row) => {
        const searchText = filterableColumns
          .map((column) =>
            column.getValue
              ? column.getValue(row)
              : row[column.key as keyof Row] ?? ''
          )
          .join(' ')
          .toLowerCase();

        return searchText.includes(filter.toLowerCase());
      });
    },
    [columns]
  );

  return filterRows;
}
