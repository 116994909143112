import React from 'react';

import { Loader } from '@pro4all/shared/ui/general';
import { BigMessageError } from '@pro4all/shared/ui/messages';

/**
 * Please note: the children passed to this component
 * are _always_ evaluated, even if they are not returned
 * by this wrapper. This is how react works.
 * If the children in this component rely on data returned
 * by the response, make sure to put a guard inside the children
 * like:
 * <ResponseWrapper isLoading={loading} error={error}>
 *   {data?.propertyUsedinChild && <Child />}
 * </ResponseWrapper>
 * This ensures React will not try to render the child while
 * the request is still being fetched.
 */

// TODO: #19176 refactor response wrapper for Apollo queries

export interface ResponseWrapperProps {
  CustomErrorComponent?: React.ReactNode;
  CustomLoadingComponent?: React.ReactNode;
  error?: Error;
  isLoading?: boolean;
}

export const ResponseWrapper: React.FC<ResponseWrapperProps> = ({
  children,
  CustomErrorComponent,
  CustomLoadingComponent,
  error,
  isLoading,
}) => {
  if (isLoading) {
    if (CustomLoadingComponent) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{CustomLoadingComponent}</>;
    } else {
      return <Loader />;
    }
  }

  if (error) {
    if (CustomErrorComponent) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{CustomErrorComponent}</>;
    } else {
      return <BigMessageError error={error} />;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
