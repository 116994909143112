import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageMetaDataNoDocumentFields: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      text={t('Create fields to use them in templates.')}
      title={t('No fields')}
    />
  );
};
