import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const apiFormat = 'DD-MM-YYYY'; // Format used in API

export const getDateFilterValues = (dateFilter: string, relativeTo?: Date) => {
  const [from, to] = dateFilter
    .replace('between(', '')
    .replace(')', '')
    .split(';');

  const offset = relativeTo ? dayjs(new Date()).diff(relativeTo, 'days') : 0;

  const fromValue = dayjs(from, apiFormat)
    .add(offset, 'days')
    .format(apiFormat);
  const toValue = dayjs(to, apiFormat).add(offset, 'days').format(apiFormat);

  return { fromValue, toValue };
};
