import { useTranslation } from 'react-i18next';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { useDeleteGroups } from './useDeleteGroups';

export const useSidebarActions = () => {
  const { t } = useTranslation();
  const deleteGroups = useDeleteGroups();

  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const isEditing = searchParams.is('action', 'editGroup');
  const isViewing = searchParams.is('action', 'viewGroup');

  const handleClose = () => searchParams.clear();

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdate = projectId
    ? !hasAppPermission('ProjectUpdate')
    : !hasAppPermission('OrganizationUpdate');

  const actionEdit: ActionProps = {
    ariaLabel: t('Edit'),
    dataTestId: 'edit-group',
    disabled: userCannotUpdate,
    key: 'edit-group',
    label: t('Edit'),
    onClick: () => searchParams.set({ action: 'editGroup' }),
    startIcon: 'edit',
  };

  const actionDelete: ActionProps = {
    ariaLabel: t('Delete'),
    dataTestId: 'delete-group',
    disabled: userCannotUpdate,
    key: 'delete-group',
    onClick: () => {
      deleteGroups();
      handleClose();
    },
    startIcon: 'delete',
    title: t('Delete'),
  };

  return {
    handleClose,
    sidebarActions: isEditing
      ? [actionDelete]
      : isViewing
      ? [actionEdit, actionDelete]
      : [],
  };
};
