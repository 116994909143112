import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ShareType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  Loader,
  MessagePreview,
  TextArea,
  TextField,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { LinkCreatedFeedBack } from '../feedback/LinkCreatedFeedBack';
import { ProjectMembersFeedBack } from '../feedback/ProjectMembersFeedBack';
import { CopyToSelfCheckbox } from '../forms/CopyToSelfCheckbox';
import { useGenerateLinkContext } from '../GenerateLinkContext';

import { PasswordSection } from './PasswordSection';
import { ShareDocMessageTemplate } from './ShareDocMessageTemplate';
import { ShareLinkButtons } from './ShareLinkButtons';
import { ButtonLabel, ModalActionsContainer } from './styles';
import { useComposeMessageContentConfig } from './useComposeMessageContentConfig';

export const ComposeMessageContent: React.FC = () => {
  const { t } = useTranslation();
  const { getField } = useComposeMessageContentConfig();
  const messageField = getField('message');
  const subjectField = getField('subject');

  const {
    selectedShareMethod,
    password,
    message,
    setMessage,
    link,
    subject,
    setSubject,
  } = useGenerateLinkContext();

  const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  if (!link) {
    return <StyledLoader />;
  }

  const previewText = `${message}`;
  return (
    <>
      <FormWrapper noPadding>
        <LinkCreatedFeedBack />
        <ProjectMembersFeedBack />

        {password && <PasswordSection password={password} />}

        <TextField
          label={subjectField.label}
          margin="normal"
          name={subjectField.name}
          onChange={onSubjectChange}
          value={subject}
        />
        <TextArea
          label={messageField.label}
          multiline
          name={messageField.name}
          onChange={onMessageChange}
          rows={4}
          value={message}
        />
      </FormWrapper>

      <MessagePreview templateContent={<ShareDocMessageTemplate />}>
        {previewText}
      </MessagePreview>

      {selectedShareMethod !== ShareType.Public && (
        <ModalActionsContainer>
          <Box alignItems="center" display="flex" justifyContent="flex-end">
            <ButtonLabel variant="h6">{t('Share via')}</ButtonLabel>
            <ShareLinkButtons />
          </Box>
          <CopyToSelfCheckbox />
        </ModalActionsContainer>
      )}
    </>
  );
};

const StyledLoader = styled(Loader)`
  margin: auto;
`;
