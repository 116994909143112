import { FieldDefinition } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';

import { useFillInitialItemsHierarchy } from '../shared/hierarchy-list/useFillInitialItemsHierarchy';
import { useSelectedItemsBreadcrumbHierarchy } from '../shared/hierarchy-list/useSelectedItemsBreadcrumbHierarchy';

export const HierarchyListOutput = ({
  item,
  value,
}: {
  item: FieldDefinition;
  value: string;
}) => {
  useFillInitialItemsHierarchy({ item, value });

  const selectedItemsBreadCrumb = useSelectedItemsBreadcrumbHierarchy({
    fieldDefinitionId: item.id,
    staticBreadcrumbs: item.valueType.staticBreadcrumbs,
    value,
  });

  return <Box sx={{ mb: 1 }}>{selectedItemsBreadCrumb}</Box>;
};
