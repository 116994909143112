import { ActionType } from '../shared/types';

import { resetPermissionsAction } from './actions/resetPermissionsAction';
import { setInitialPermissionsAction } from './actions/setInitialPermissionsAction';
import { setPermissionAction } from './actions/setPermissionAction';
import { toggleAllPermissionsAction } from './actions/toggleAllPermissionsAction';
import { togglePermissionAction } from './actions/togglePermissionAction';
import { updatePermissionsAfterSaveAction } from './actions/updatePermissionsAfterSaveAction';
import { Action, EntityPermission, State } from './types';

export function permissionsEntityMatrixReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.ERASE_STATE: {
      const emptyPermissions: EntityPermission[] = [];
      return {
        displayPermissions: emptyPermissions,
        initialPermissionsDisplay: emptyPermissions,
        initialPermissionsExplicit: emptyPermissions,
        savePermissions: emptyPermissions,
        showBackdrop: false,
      };
    }
    case ActionType.RESET_PERMISSIONS: {
      return resetPermissionsAction(state);
    }
    case ActionType.SET_PERMISSION: {
      return setPermissionAction({ payload: action.payload, state });
    }
    case ActionType.SET_INITIAL_PERMISSIONS: {
      return setInitialPermissionsAction({ payload: action.payload, state });
    }
    case ActionType.TOGGLE_ALL_PERMISSIONS: {
      return toggleAllPermissionsAction({ entityId: action.payload, state });
    }
    case ActionType.TOGGLE_BACKDROP: {
      return { ...state, showBackdrop: !state.showBackdrop };
    }
    case ActionType.TOGGLE_PERMISSION: {
      return togglePermissionAction({ payload: action.payload, state });
    }
    case ActionType.UPDATE_PERMISSIONS_AFTER_SAVE: {
      return updatePermissionsAfterSaveAction({
        payload: action.payload,
        state,
      });
    }

    default:
      return state;
  }
}
