import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const ScanCard = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 216px;
    height: 140px;
    background-color: ${customColors.white};
    box-shadow: 0rem 0.188rem 0.75rem rgba(44, 45, 86, 0.24);
    border-radius: 0.625rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
`;
