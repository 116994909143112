import { useEffect } from 'react';

import { SetReportConfigurationReportOptionsProps } from '@pro4all/shared/types';

import { useReportOptionsContext } from '../ReportOptionsProvider';

export const useSetReportConfigurationReportOptions = ({
  reportConfiguration,
  reportConfigurationReportOptions,
}: SetReportConfigurationReportOptionsProps) => {
  const { setActiveReportConfiguration, setReportOptions } =
    useReportOptionsContext() || {};

  useEffect(() => {
    if (reportConfigurationReportOptions && reportConfiguration) {
      const { id, isDefault, name, projectId } = reportConfiguration;
      setActiveReportConfiguration({
        id,
        name,
        setAsDefault: Boolean(isDefault),
        storeOnOrganizationLevel: projectId ? false : true,
      });
      setReportOptions(reportConfigurationReportOptions);
    }
  }, []); // No dependancies because reportConfiguration is non-asynchronous and we only want to run this once.
};
