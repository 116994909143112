import React from 'react';
import { Link } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  LogoColor,
  LogoType,
  LogoutLink,
  ProstreamLogo,
} from '@pro4all/shared/ui/general';

import * as Styled from './InfoTopBar.styles';

export const InfoTopBar = ({
  color = 'white',
  type = 'regular',
}: {
  color?: LogoColor;
  type?: LogoType;
}) => {
  const userId = AuthService.getProfile()?.userId;

  return (
    <Styled.Container>
      <Link to="/">
        <ProstreamLogo color={color} type={type} />
      </Link>
      {userId && <LogoutLink />}
    </Styled.Container>
  );
};
