import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Cube: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M19.1308 8.37252L12 3.83853L4.86921 8.37252L12 12.9065L19.1308 8.37252ZM13.0148 2.29452C12.3972 1.90183 11.6028 1.90183 10.9852 2.29452L3.85444 6.82851C2.71519 7.55289 2.71519 9.19214 3.85444 9.91652L10.9852 14.4505C11.6028 14.8432 12.3972 14.8432 13.0148 14.4505L20.1456 9.91652C21.2848 9.19214 21.2848 7.55289 20.1456 6.82851L13.0148 2.29452Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M20.2554 17.056C20.541 17.4789 20.424 18.0495 19.994 18.3304L13.5513 22.5394C12.6113 23.1535 11.3886 23.1535 10.4487 22.5394L4.00601 18.3304C3.57605 18.0495 3.45901 17.4789 3.74459 17.056C4.03018 16.6331 4.61025 16.518 5.04022 16.7989L11.4829 21.008C11.7962 21.2127 12.2038 21.2126 12.5171 21.008L18.9598 16.7989C19.3898 16.518 19.9698 16.6331 20.2554 17.056Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.96229 7.45324C4.47846 7.45324 4.89689 7.8648 4.89689 8.3725V17.2514C4.89689 17.7591 4.47846 18.1707 3.96229 18.1707C3.44612 18.1707 3.02768 17.7591 3.02768 17.2514V8.3725C3.02768 7.8648 3.44612 7.45324 3.96229 7.45324Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M20.0377 7.45324C20.5539 7.45324 20.9723 7.8648 20.9723 8.3725V17.2526C20.9723 17.7603 20.5539 18.1718 20.0377 18.1718C19.5215 18.1718 19.1031 17.7603 19.1031 17.2526V8.3725C19.1031 7.8648 19.5215 7.45324 20.0377 7.45324Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12 12.9688C12.5162 12.9688 12.9346 13.3804 12.9346 13.8881V21.2422C12.9346 21.7499 12.5162 22.1614 12 22.1614C11.4838 22.1614 11.0654 21.7499 11.0654 21.2422V13.8881C11.0654 13.3804 11.4838 12.9688 12 12.9688Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
