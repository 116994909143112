import { useTranslation } from 'react-i18next';

import { Dialog } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

type ConfirmationModalProps = {
  confirm: () => void;
  message: string;
  showModal: boolean | undefined;
};

export const VersionTableDropValidationDialog = ({
  showModal,
  confirm,
  message,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    showModal && (
      <Dialog
        buttonVariant="outlined"
        confirmLabel={t('Cancel')}
        iconName="reportProblemOutlined"
        onConfirm={confirm}
        open={showModal}
        title={t('Move file error')}
        titleVariant="h3"
      >
        <Text display="inline">{message}</Text>
      </Dialog>
    )
  );
};
