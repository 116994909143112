import {
  SavedAnswers,
  useSavedAnswersOnTemplateIdQuery,
} from '@pro4all/graphql';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetSavedAnswersOnTemplateId = () => {
  const { searchParams } = useRouting();
  const { templateId } = useSavedAnswersContext();

  const applySavedAnswersContext = searchParams.is('applySavedAnswers', 'true');

  const { data, loading } = useSavedAnswersOnTemplateIdQuery({
    fetchPolicy: 'cache-and-network',
    skip: !applySavedAnswersContext,
    variables: {
      templateId,
    },
  });

  return {
    savedAnswersInstances: [
      ...(data?.savedAnswersOnTemplateId || []),
    ] as SavedAnswers[], // must be mutable, that why we use spread operator
    showLoader: loading || !templateId,
  };
};
