import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { SetDummyDataPerFieldTypeProps } from '@pro4all/shared/types';

export const useSetDummyDataPerFieldType = () => {
  const { t } = useTranslation();

  const setDummyDataPerFieldType = ({
    type,
  }: SetDummyDataPerFieldTypeProps) => {
    switch (type) {
      case ValueTypeName.Bool:
        return t('Yes');
      case ValueTypeName.DateTime:
        return '10/15/2024 12:00:00';
      case ValueTypeName.HierarchyList:
        return `[{"breadcrumbs":["Project > Site > Block A > Floor 3 > Room 301"],"value":"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}]`;
      case ValueTypeName.Media:
        return 'pic1,pic2,pic3,pic4,pic5,pic6';
      case ValueTypeName.Number:
        return '450';
      case ValueTypeName.Selection:
        return t('Concrete Type C30/37');
      case ValueTypeName.Signature:
        return 'pic';
      case ValueTypeName.Status:
        return t('In progress');
      case ValueTypeName.Text:
        return t(
          'Ensure that all cables are properly grounded and secured according to industry standards. Pay special attention to junction boxes to prevent overheating. All conduits must be fastened securely to prevent movement, and flexible conduits should be used in areas with vibration. Verify that cable labeling is clear and corresponds to the site plans. Any deviations from the original blueprint must be reported immediately to the site supervisor for approval before making adjustments. Complete all installations with final testing and verification of insulation resistance, continuity, and functionality.'
        );
      case ValueTypeName.UserSelection:
        return '';
      default:
        return '';
    }
  };

  return { setDummyDataPerFieldType };
};
