import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { saveFolderTreeStructure } from '@pro4all/shared/storage-utils';
import { ActionProps } from '@pro4all/shared/types';

export const useCopyFolderTree = (folder?: Folder) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const { projectId } = useRouting().params;

  const copyFolderTree = () => {
    saveFolderTreeStructure({
      folderId: folder?.id,
      folderName: folder?.name,
      projectId,
    });
    enqueueSnackbar(
      folder?.name
        ? t('Folder "{{folderName}}" is copied to your clipboard', {
            folderName: folder?.name,
          })
        : t('Root folder is copied to your clipboard')
    );
  };

  const copyFolderTreeAction: ActionProps = {
    ariaLabel: 'Copy folder tree',
    dataTestId: 'copy-folder-tree',
    disabled: folder && !hasFolderPermission('UpdateFolder'),
    key: 'copy-folder-tree',
    label: t('Copy folder tree'),
    onClick: copyFolderTree,
    startIcon: 'copyFile',
  };

  return {
    copyFolderTreeAction,
  };
};
