import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const QR: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M5 2H7C8.65685 2 10 3.34315 10 5V7C10 8.65685 8.65685 10 7 10H5C3.34315 10 2 8.65685 2 7V5C2 3.34315 3.34315 2 5 2ZM5 4C4.44772 4 4 4.44772 4 5V7C4 7.55228 4.44772 8 5 8H7C7.55228 8 8 7.55228 8 7V5C8 4.44772 7.55228 4 7 4H5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5 14H7C8.65685 14 10 15.3431 10 17V19C10 20.6569 8.65685 22 7 22H5C3.34315 22 2 20.6569 2 19V17C2 15.3431 3.34315 14 5 14ZM5 16C4.44772 16 4 16.4477 4 17V19C4 19.5523 4.44772 20 5 20H7C7.55228 20 8 19.5523 8 19V17C8 16.4477 7.55228 16 7 16H5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17 2H19C20.6569 2 22 3.34315 22 5V7C22 8.65685 20.6569 10 19 10H17C15.3431 10 14 8.65685 14 7V5C14 3.34315 15.3431 2 17 2ZM17 4C16.4477 4 16 4.44772 16 5V7C16 7.55228 16.4477 8 17 8H19C19.5523 8 20 7.55228 20 7V5C20 4.44772 19.5523 4 19 4H17Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4 12C4 11.4477 4.44772 11 5 11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13H5C4.44772 13 4 12.5523 4 12Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M20 17C19.4477 17 19 16.5523 19 16V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V16C21 16.5523 20.5523 17 20 17Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12 20C11.4477 20 11 19.5523 11 19V15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15V19C13 19.5523 12.5523 20 12 20Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17 12C17 12.5523 16.5523 13 16 13H12C11.4477 13 11 12.5523 11 12V5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5L13 11H16C16.5523 11 17 11.4477 17 12Z"
      fillRule="evenodd"
    />
    <path d="M16 15C16 15.5523 15.5523 16 15 16C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14C15.5523 14 16 14.4477 16 15Z" />
    <path d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z" />
  </SvgIcon>
);
