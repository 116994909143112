import { Box, LinearProgress } from '@pro4all/shared/mui-wrappers';

type TTImageUploading = {
  isLoading: boolean;
};
export const ImageUploading = (props: TTImageUploading) => {
  if (!props.isLoading) return null;
  return (
    <Box mt="16px">
      <LinearProgress value={100} />
    </Box>
  );
};
