import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNothingCollected: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Share and/or download from different folders at once. From your folders add your documents to your collection folder.'
      )}
      title={t('Nothing collected')}
    />
  );
};
