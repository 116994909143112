import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const NewTbqResolveTask: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 18 18"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.4165 2.3335H11.2815C10.9665 1.4635 10.1415 0.833496 9.1665 0.833496C8.1915 0.833496 7.3665 1.4635 7.0515 2.3335H3.9165C3.0915 2.3335 2.4165 3.0085 2.4165 3.8335V14.3335C2.4165 15.1585 3.0915 15.8335 3.9165 15.8335H9.24204C9.19288 15.6565 9.16659 15.4701 9.16659 15.2779C9.16659 14.1321 10.1012 13.1946 11.2435 13.1946H13.182V11.2502C13.182 10.1043 14.1167 9.16683 15.259 9.16683C15.4885 9.16683 15.7097 9.20467 15.9165 9.27445V14.3335C15.9165 15.1585 15.2415 15.8335 14.4165 15.8335H15.9166V9.27448C15.9166 9.27447 15.9165 9.27446 15.9165 9.27445V3.8335C15.9165 3.0085 15.2415 2.3335 14.4165 2.3335ZM12.1662 6.83341H6.16618C5.75368 6.83341 5.41618 6.49591 5.41618 6.08341C5.41618 5.67091 5.75368 5.33341 6.16618 5.33341H12.1662C12.5787 5.33341 12.9162 5.67091 12.9162 6.08341C12.9162 6.49591 12.5787 6.83341 12.1662 6.83341ZM6.24992 8.3335C5.78968 8.3335 5.41659 8.70659 5.41659 9.16683C5.41659 9.62707 5.78968 10.0002 6.24992 10.0002H12.0833C12.5435 10.0002 12.9166 9.62707 12.9166 9.16683C12.9166 8.70659 12.5435 8.3335 12.0833 8.3335H6.24992ZM5.41659 12.5002C5.41659 12.0399 5.78968 11.6668 6.24992 11.6668H8.74992C9.21016 11.6668 9.58325 12.0399 9.58325 12.5002C9.58325 12.9604 9.21016 13.3335 8.74992 13.3335H6.24992C5.78968 13.3335 5.41659 12.9604 5.41659 12.5002Z"
        fill="#585858"
        fillRule="evenodd"
      />
      <path
        d="M12.6042 16.354H14.4792V18.229C14.4792 18.7446 14.9011 19.1665 15.4167 19.1665C15.9324 19.1665 16.3542 18.7446 16.3542 18.229V16.354H18.2292C18.7449 16.354 19.1667 15.9321 19.1667 15.4165C19.1667 14.9009 18.7449 14.479 18.2292 14.479H16.3542V12.604C16.3542 12.0884 15.9324 11.6665 15.4167 11.6665C14.9011 11.6665 14.4792 12.0884 14.4792 12.604V14.479H12.6042C12.0886 14.479 11.6667 14.9009 11.6667 15.4165C11.6667 15.9321 12.0886 16.354 12.6042 16.354Z"
        fill="#585858"
      />
    </svg>
  </SvgIcon>
);
