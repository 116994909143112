// Use this map function to filter out __typename
import { Reference, ReferenceInput } from '@pro4all/graphql';

export const toReference = ({
  referenceData,
  referenceId,
  referenceKind,
  referenceType,
  referenceValue,
}: ReferenceInput): Reference => ({
  referenceData: referenceData || null,
  referenceId,
  referenceKind,
  referenceType,
  referenceValue,
});
