import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

interface NoSubjectDialogProps {
  confirm: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const NoSubjectDialog: React.FC<NoSubjectDialogProps> = ({
  confirm,
  open,
  setOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      buttonVariant="outlined"
      iconName="warning"
      name="noSubjectDialog"
      onClose={() => setOpen(false)}
      onConfirm={confirm}
      open={open}
      title={t('No subject')}
    >
      <Text display="inline">
        {t('Do you want to send this message without a subject?')}
      </Text>
    </Dialog>
  );
};
