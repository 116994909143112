import { useTranslation } from 'react-i18next';

import { LockType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useEditMetaDataNewAction = ({
  editableDocuments,
  isSpecialFolder,
  isVersion,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'isSpecialFolder' | 'isVersion'
>) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const editableDocumentsWithoutLocked = editableDocuments.filter(
    (doc) => doc.lockType !== LockType.Prostream
  );
  const hasClient = useFeatureFlag('client');

  const editMetaData = () => {
    goTo({
      searchParams: { action: 'editMetaData', f: undefined, fullscreen: true },
      state: {
        documentIdsForEdit: editableDocumentsWithoutLocked.map(({ id }) => id),
      },
    });
  };

  const editMetaDataNewAction: ActionProps = {
    ariaLabel: t('Edit meta data'),
    dataTestId: 'edit-meta-data',
    disabled:
      editableDocumentsWithoutLocked.length === 0 ||
      isSpecialFolder ||
      isVersion,
    key: 'edit-meta-data',
    label: t('Edit meta data'),
    onClick: editMetaData,
    startIcon: hasClient ? 'editMetadata' : 'edit',
  };

  return editMetaDataNewAction;
};
