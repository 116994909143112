import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  AuthenticatedApiService,
  FileData,
} from '@pro4all/authentication/src/services/authenticated-api-service';
import { AttachmentContext } from '@pro4all/graphql';

export const CommunicationBaseURL = 'https://communication.pro4all.io/api/';

class Service {
  GQLClient: ApolloClient<NormalizedCacheObject>;
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(CommunicationBaseURL);
    this.GQLClient = client;
  }

  getPreDownloadUrl = ({
    messageId,
    organizationId,
    fileId,
    context,
  }: {
    context: AttachmentContext;
    fileId?: string;
    messageId?: string;
    organizationId: string;
  }) => {
    const contextString = context.toLowerCase();
    return `attachment/${contextString}/${organizationId}/${
      messageId ? `${messageId}/` : ''
    }${fileId ? `${fileId}/` : ''}downloadurl`;
  };

  uploadAttachment = async ({
    organizationId,
    file,
    onProgress,
  }: UploadAttachmentProps) => {
    const url = `${CommunicationBaseURL}attachment/${organizationId}/add`;

    return this.api.uploadFile<FileData>({
      chunkSize: 1048500,
      file,
      onProgress,
      url,
    });
  };

  uploadStaticImage = async ({
    organizationId,
    file,
    onProgress,
  }: UploadAttachmentProps) => {
    const url = `${CommunicationBaseURL}image/${organizationId}/add`;

    return this.api.uploadFile<FileData>({
      chunkSize: 1048500,
      file,
      onProgress,
      url,
    });
  };

  getStaticImage = async ({ presignedUrl }: { presignedUrl: string }) =>
    this.api.getStaticImageUrl(presignedUrl);

  downloadAttachment = async ({
    fileId,
    fileName,
    organizationId,
    messageId,
    context,
  }: DownloadAttachmentProps) => {
    const url = this.getPreDownloadUrl({
      context,
      fileId,
      messageId: messageId,
      organizationId,
    });
    this.api.downloadPresigned({ fileName, url });
  };

  downloadAllAttachments = async ({
    fileName,
    messageId,
    organizationId,
    context,
  }: DownloadAllAttachmentsProps) => {
    const url = this.getPreDownloadUrl({ context, messageId, organizationId });
    this.api.downloadPresigned({ fileName, url });
  };

  // If communications returns file directly, use this
  downloadPublicAttachmentDirect = async ({
    url,
    fileName,
  }: PublicDownloadAttachmentProps) => {
    this.api.download({
      fileName,
      options: { method: 'GET' },
      url,
    });
  };
}

export const CommunicationService = new Service();

interface UploadAttachmentProps {
  file: File;
  onProgress?: (progress: number) => void;
  organizationId: string;
  overwriteFilename?: boolean;
}

interface DownloadAttachmentProps {
  context: AttachmentContext;
  fileId: string;
  fileName: string;
  messageId?: string;
  organizationId: string;
}

interface DownloadAllAttachmentsProps {
  context: AttachmentContext;
  fileName: string;
  messageId: string;
  organizationId: string;
}

interface PublicDownloadAttachmentProps {
  fileName: string;
  url: string;
}
