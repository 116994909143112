import React from 'react';

import { StandardItemKey } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { FieldCardGlobal } from './FieldCardGlobal';
import { fieldCardsGlobalInfo } from './fieldCardsGlobalInfo';
import { useFieldContext } from './TemplateMutationContext';
import { useFilterOutUsedFields } from './useFilterOutUsedFields';

export const FieldCardsGlobal: React.FC = () => {
  const { reusableFieldsStandard } = useFieldContext();
  const { projectId } = useRouting().params;
  const isOrganizationContext = Boolean(!projectId);
  const reusableFieldsStandardFiltered = useFilterOutUsedFields({
    usedFields: reusableFieldsStandard,
  });

  return (
    <>
      {reusableFieldsStandardFiltered.map((reusableFieldStandard) => {
        const { name } = reusableFieldStandard;
        const nameStandardFieldDefinition = name as StandardItemKey;
        const cardData = fieldCardsGlobalInfo[nameStandardFieldDefinition];

        if (isOrganizationContext && cardData.hideInOrganizationContext) {
          return null;
        }

        return (
          <FieldCardGlobal
            fieldDefinition={reusableFieldStandard}
            key={reusableFieldStandard.id}
          />
        );
      })}
    </>
  );
};
