import styled from 'styled-components';

import { LinearProgress } from '@pro4all/shared/mui-wrappers';

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const Loader = styled(LinearProgress)`
  && {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;
