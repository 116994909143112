import { ResourceType, useResourcePermissionsQuery } from '@pro4all/graphql';
import {
  ProjectAccessType,
  useProjectContext,
} from '@pro4all/projects/ui/context';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import {
  getHierarchyIndex,
  getPermissionName,
  getPermissionValue,
} from '@pro4all/shared/permissions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { qcResourcePermissionsType } from '../types';
import {
  convertPermissions,
  setPermissionsToAll,
} from '../utils/convertPermissions';

/* This hook checks the permissions you have on a specific resource, not your permissions in general.
It is specifically intended for scenario's where you need to check these permissions: snagCreateAssigned, formUpdateAssigned and snagUpdateAssigned. 
The reason there is a seperate hook for those is that checking if a result has a task linked to it which is assigned to the user is crazy expensive in the FE so we get that info from a BE endpoint.
 If that is the case it will return snagUpdateAssigned: true and/or formUpdateAssigned: true and/or snagCreateAssigned: true
As a bonus it will also return other qcs permissions(not task) in the request so you don't have to do an extra fetch or use an extra hook to get the other qcs permissions you may need.
If you want to fetch permisions but don't need snagCreateAssigned, formUpdateAssigned or snagUpdateAssigned, use the 'useQCPermissions' hook instead.
 */

export const useFetchResourcePermissions = ({
  resourceType,
  resourceId,
  tbqProjectId,
}: {
  resourceId?: string;
  resourceType?: ResourceType;
  tbqProjectId?: string;
}): qcResourcePermissionsType => {
  const { params } = useRouting();
  const { projectId: objectProjectId } = useObjectDetailContext();
  const projectId = params.projectId ?? tbqProjectId ?? objectProjectId;
  const projectContext = useProjectContext();
  const permissionStatus = projectContext?.projectAccessLoadStatus?.status;
  const isOrgOrProjAdmin = permissionStatus === ProjectAccessType.ProjectUpdate;

  const { data } = useResourcePermissionsQuery({
    fetchPolicy: 'cache-and-network',
    skip:
      !projectId ||
      !resourceId ||
      ![ResourceType.Instance, ResourceType.VisualContext].includes(
        resourceType
      ),
    variables: {
      projectId: projectId ?? '',
      resourceId: resourceId,
      resourceType: resourceType,
    },
  });

  const resourcePermissions = data?.resourcePermissions.permissions;
  //the BE returns us resource permissions from both the user's groups and the users individual permissions in 1 array of strings.
  //so you could have both 'snag-update-none' and 'snag-update-all' in this array for example.
  //We need to need to make sure we get the highest variant of each permission and put it in a new array.
  const highestResourcePermissions: string[] = resourcePermissions?.reduce(
    (acc: string[], perm: string) => {
      const permissionName = getPermissionName(perm); // 'snag-create' or 'form-update' etc.
      const permissionValue = getPermissionValue(perm); //'all' or 'none' etc.
      const permissionAlreadyInAcc = acc.find((p) =>
        p.includes(permissionName)
      );
      const permissionAlreadyInAccHierarchyIndex = getHierarchyIndex(
        getPermissionValue(permissionAlreadyInAcc)
      );
      const permHierarchyIndex = getHierarchyIndex(permissionValue);
      if (!permissionAlreadyInAcc) acc.push(perm);
      else if (
        permissionAlreadyInAcc &&
        permHierarchyIndex > permissionAlreadyInAccHierarchyIndex
      )
        acc.map((p) =>
          getPermissionValue(perm) === getPermissionValue(p) ? perm : p
        );
      return acc;
    },
    []
  );

  let permissionsObj = convertPermissions(highestResourcePermissions);

  //Admin overwrite
  if (isOrgOrProjAdmin) {
    permissionsObj = setPermissionsToAll(permissionsObj);
  }
  return permissionsObj;
};
