import { useTranslation } from 'react-i18next';

import { ChartOption } from '@pro4all/shared/ui/charts';

export const useTimeClusterOptionsSearch = () => {
  const { t } = useTranslation();

  const timeClusterOptions: ChartOption[] = [
    {
      id: 'updatedAt',
      label: t('Updated on'),
      valueResolver: (searchDocument) => searchDocument.updatedAt,
    },
    {
      id: 'createdAt',
      label: t('Created on'),
      valueResolver: (searchDocument) => searchDocument.createdAt,
    },
  ];

  return timeClusterOptions;
};
