import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

const StyledUploadOverlay = styled.div<{
  borderType?: 'card' | 'table';
}>`
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border: 4px dashed ${customColors.grey500};
  ${({ borderType }) => {
    switch (borderType) {
      case 'card':
        return `border-radius: 10px`;
      case 'table':
        return `border-bottom-right-radius: ${StylingDefaults.borderRadius}`;
    }
  }}
`;

const StyledMessageWrapper = styled.div<{
  topMarginMessage?: number;
}>`
  z-index: 999;
  margin-top: ${({ topMarginMessage }) => `${topMarginMessage}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export interface FileUploadOverlayProps {
  borderType?: 'table' | 'card';
  isActive: boolean;
  message?: string;
  topMarginMessage?: number;
  withStickyHeader?: boolean;
}

export const FileUploadOverlay: React.FC<FileUploadOverlayProps> = ({
  isActive,
  borderType = 'table',
  children,
  message = 'Let go to upload',
  topMarginMessage = 20,
  withStickyHeader = true,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid="file-upload-overlay"
      display="flex"
      flex="1 1 auto"
      height={withStickyHeader ? '1px' : 'auto'} // This `height: 1px` is a hack to make the sticky headers and vertical scrollbar work in the upload editor.
      position="relative"
    >
      {children}
      {isActive && (
        <StyledUploadOverlay borderType={borderType}>
          <StyledMessageWrapper topMarginMessage={topMarginMessage}>
            <Icon color="primary" fontSize="large" iconName="upload" />
            <Text color="primary" variant="h4">
              {t(message)}
            </Text>
          </StyledMessageWrapper>
        </StyledUploadOverlay>
      )}
    </Box>
  );
};
