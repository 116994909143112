import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Button as MUIButton } from '@pro4all/shared/ui/buttons';
import { Icon as P4aIcon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const Button = styled(MUIButton)`
  && {
    background-color: ${customColors.orangeAccent};
  }
`;

const hideOnExtraSmall = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const Header = styled(Text)`
  && {
    ${hideOnExtraSmall}
  }
`;

export const BodyText = styled(Text)`
  && {
    margin: ${({ theme }) => theme.spacing(1)} 0;
    ${hideOnExtraSmall}
  }
`;

export const Icon = styled(P4aIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const Img = styled.img`
  width: 50%;
  height: auto;
  margin-left: 2rem;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin: 1rem 0;
  & > :not(:last-child) {
    margin-right: 1rem;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    margin: 0;
    & > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-top: 0;
  }
`;
