import { useDrop } from 'react-dnd';

import { DndFolder, DndTypes } from '@pro4all/shared/config';

import { useMoveFolder } from './useMoveFolder';

export function useDropFolderOnRoot() {
  const { moveFolder } = useMoveFolder();

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [DndTypes.FOLDER],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: async (item: DndFolder, monitor) => {
      if (!monitor.didDrop()) {
        // Dropped folder must be moved to the root.
        moveFolder({
          childFolder: {
            currentParentFolderId: item.currentParentFolderId,
            id: item.id,
            name: item.name,
          },
        });
      }
    },
  });

  return {
    drop,
    hoversOverCurrent,
  };
}
