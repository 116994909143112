import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { AiFrame, AiLoader } from '@pro4all/shared/ui/ai';
import { LabelCard } from '@pro4all/shared/ui/label-card';
import { sortByWithInnerSort } from '@pro4all/shared/utils';

import { useDocumentKeywordsContext } from './DocumentKeywordsProvider';
import { DocumentKeyword } from './documentKeywordsReducer';
import { useKeywordsDisplayActions } from './useKeywordsDisplayActions';

export const DocumentKeywordsDisplay = ({
  document,
  editable,
  isFinalized,
  keywordsInitially,
  setDisplayKeywords,
  version,
  versionId,
}: {
  editable: boolean;
  isFinalized: boolean;
  keywordsInitially: DocumentKeyword[];
  setDisplayKeywords: (value: boolean) => void;
  versionId: string;
} & DocumentAndVersionType) => {
  const { t } = useTranslation();

  const {
    fillInitialKeywords,
    state: { keywords },
  } = useDocumentKeywordsContext();

  useEffect(() => {
    fillInitialKeywords(keywordsInitially);
  }, [fillInitialKeywords, keywordsInitially]);

  const { actions, keywordsBeingGenerated, onDelete, onEditClick } =
    useKeywordsDisplayActions({
      document,
      editable,
      isFinalized,
      setDisplayKeywords,
      version,
      versionId,
    });

  return (
    <AiFrame actions={actions} label={t('AI keywords')} maxHeight={300}>
      {keywordsBeingGenerated ? (
        <AiLoader />
      ) : keywords ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          {keywords
            .sort(
              sortByWithInnerSort({
                mainProp: 'score',
                mainPropSortOrder: 'desc',
                subProp: 'text',
              })
            )
            .map(({ id, text }) => (
              <LabelCard
                borderRadius={10}
                colorBackground="grey300"
                key={id}
                label={text}
                // For now it is decided to visually hide the score. I leave the code commented out in case we want to show it in the future.
                // number={score.toString()}
                // numberColorBackground="white"
                // numberColorBorder="grey500"
                onClick={!isFinalized ? () => onEditClick(id) : undefined}
                onDelete={!isFinalized ? () => onDelete(id) : undefined}
              />
            ))}
        </Box>
      ) : null}
    </AiFrame>
  );
};
