import type { ReactNode } from 'react';
import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export interface Props {
  backButton?: ReactNode;
  body?: ReactNode;
  children?: ReactNode;
  closeModalCallback?: () => void;
  iconName?: IconName;
}

export function Header({
  backButton,
  body,
  children,
  closeModalCallback,
  iconName,
}: Props) {
  return (
    <Box width="100%">
      <Box display="flex" width="100%">
        {backButton && backButton}
        {closeModalCallback && (
          <IconButton
            color="default"
            disableBorder
            iconName="arrowBack"
            onClick={closeModalCallback}
          />
        )}
        {iconName && <Icon iconName={iconName} />}
        <Box ml={1}>
          <Text variant="h3">{children}</Text>
        </Box>
      </Box>
      <Box>{body && body}</Box>
    </Box>
  );
}
