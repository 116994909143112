import { Message, SentOrInbox } from '@pro4all/graphql';

export function isUserInMessage(
  message: Message,
  userDisplayName: string,
  userEmail: string
): boolean {
  const { to = [], cc = [], bcc = [] } = message;

  const containsUser = (list: Array<string | null>) =>
    list.some((item) => item === userDisplayName || item === userEmail);

  return (
    containsUser(to || []) || containsUser(cc || []) || containsUser(bcc || [])
  );
}

interface GenerateOptimisticTablesProps {
  isDraft: boolean;
  onlyUpdateDraft?: boolean;
  userInMessage: boolean;
}

export const generateOptimisticTables = ({
  userInMessage,
  isDraft,
  onlyUpdateDraft = false,
}: GenerateOptimisticTablesProps): SentOrInbox[] => {
  if (onlyUpdateDraft) {
    return [SentOrInbox.Draft];
  }
  const optimisticTables: SentOrInbox[] = [
    ...(isDraft ? [] : [SentOrInbox.All]),
    ...(isDraft ? [] : [SentOrInbox.Inbox]),
    ...(!isDraft && userInMessage ? [SentOrInbox.Sent] : []),
    ...(isDraft ? [SentOrInbox.Draft] : []),
  ];
  return optimisticTables;
};
