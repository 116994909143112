import React from 'react';

interface Props {
  color?: '#28237A' | '#E25314' | '#848484';
  iconScale: number;
  statusColor?: string;
}

export const SnagPin: React.FC<Props> = ({ color, statusColor, iconScale }) => (
  <svg
    fill="none"
    height={48 * iconScale}
    viewBox="0 0 32 48"
    width={32 * iconScale}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={color} height="24" rx="12" width="24" y="8" />
    <path
      d="M12 16C9.79086 16 8 17.7909 8 20C8 22.2091 9.79086 24 12 24C14.2091 24 16 22.2091 16 20C16 17.7909 14.2091 16 12 16Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M10 32H14V43C14 43 12.5 48 12 48C11.5 48 10 43 10 43V32Z"
      fill={color}
      fillRule="evenodd"
    />
    {statusColor && statusColor !== '' && (
      <path
        d="M24 15C27.866 15 31 11.866 31 8C31 4.13401 27.866 1 24 1C20.134 1 17 4.13401 17 8C17 11.866 20.134 15 24 15Z"
        fill={statusColor}
        stroke="white"
        strokeWidth="2"
      />
    )}
  </svg>
);
