import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterType } from '@pro4all/graphql';
import { staticFilterTypes } from '@pro4all/shared/search-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import * as Styled from './Filter.styles';
import { useFilters } from './utils';

interface Props {
  error?: string;
  metaDataKey?: string;
  onApply: () => void;
  type: FilterType;
  value: string;
}

export const Footer: React.FC<Props> = ({
  error,
  metaDataKey,
  onApply,
  type,
  value,
}) => {
  const { t } = useTranslation();
  const { resetFilter, removeFilter } = useFilters();

  const handleRemove = () => {
    removeFilter({ metaDataKey, refetch: false, type });
  };

  const handleReset = () => {
    resetFilter(type, metaDataKey);
  };

  return (
    <Styled.Footer>
      {!staticFilterTypes.includes(type) && (
        <Tooltip placement="bottom" title={t('Remove filter')}>
          <IconButton
            color="inherit"
            iconName="remove"
            onClick={handleRemove}
          />
        </Tooltip>
      )}
      <Tooltip placement="bottom" title={t('Reset filter')}>
        <IconButton
          color="inherit"
          disabled={!value}
          iconName="reset"
          onClick={handleReset}
        />
      </Tooltip>
      <Tooltip placement="bottom" title={error ? t(error) : t('Apply filter')}>
        <Styled.ApplyButton
          color="primary"
          disabled={!value || Boolean(error)}
          iconName="arrowForward"
          onClick={onApply}
        />
      </Tooltip>
    </Styled.Footer>
  );
};
