import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Button } from '@pro4all/shared/mui-wrappers';
import { CopyTextButton } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

export const SecretDisplay = ({
  domain,
  domainClaimId,
  handleRevokeToken,
  secret,
}: {
  domain: string;
  domainClaimId: string;
  handleRevokeToken: (domain: string, claimId: string) => void;
  secret: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex">
      <CopyTextButton
        textName={t('Token')}
        textToCopy={secret}
        truncateLength={15}
      >
        <CopyInstruction>{t('Click to copy')}</CopyInstruction>
      </CopyTextButton>

      <Button onClick={() => handleRevokeToken(domain, domainClaimId)}>
        {t('Revoke')}
      </Button>
    </Box>
  );
};

const CopyInstruction = styled(Text)`
  && {
    margin-left: ${({ theme }) => `${theme.spacing(1)}`};
    cursor: pointer;
  }
`;
