import React from 'react';

import { ScheduledTime } from '@pro4all/graphql';

import * as Styled from './TimeInput.styles';

export type Args = { index: number; type: 'hour' | 'minute'; value: string };

interface TimeInputProps extends ScheduledTime {
  disabled?: boolean;
  index: number;
  onBlur: (args: Args) => void;
  onChange: (args: Args) => void;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  disabled,
  hour,
  index,
  minute,
  onBlur,
  onChange,
}) => (
  <Styled.Container>
    <Styled.Input
      disabled={disabled}
      id={`time-${index}-hour`}
      onBlur={(e) => onBlur({ index, type: 'hour', value: e.target.value })}
      onChange={(e) => onChange({ index, type: 'hour', value: e.target.value })}
      onFocus={(e) => e.target.select()}
      type="number"
      value={hour}
    />
    :
    <Styled.Input
      disabled={disabled}
      id={`time-${index}-minute`}
      onBlur={(e) => onBlur({ index, type: 'minute', value: e.target.value })}
      onChange={(e) =>
        onChange({ index, type: 'minute', value: e.target.value })
      }
      onFocus={(e) => e.target.select()}
      type="number"
      value={minute}
    />
  </Styled.Container>
);
