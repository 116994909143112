import { Route } from 'react-router-dom';

import { FolderTreeProviderOuter } from '@pro4all/documents/ui/folders';
import { DocumentsContextProvider } from '@pro4all/documents/ui/share';
import { Routes } from '@pro4all/shared/config';

import { DocumentsBrowser } from './documents-browser/DocumentsBrowser';

export const Documents = () => (
  <Route
    path={[
      // ATTENTION: This order is critical!!
      Routes.projectSmartFolder,
      Routes.smartFolder,
      Routes.docs,
      Routes.projectDocs,
      Routes.projectDocsTasks,
      Routes.projectCollectionFolder,
      Routes.collectionFolder,
      Routes.projectDmsBase,
      Routes.projectDocsNotifications,
    ]}
    render={() => (
      <DocumentsContextProvider>
        <FolderTreeProviderOuter>
          <DocumentsBrowser />
        </FolderTreeProviderOuter>
      </DocumentsContextProvider>
    )}
  />
);
