export type RowRenderData = {
  maxItemsRendered: number;
  newItemsOnScroll: number;
};

export const calculateRowRenderData = ({
  amountOfDocuments,
  amountOfMetaFieldsPerDocument,
}: {
  amountOfDocuments: number;
  amountOfMetaFieldsPerDocument: number;
}): RowRenderData => {
  const amountOfCellsToRender =
    amountOfDocuments * amountOfMetaFieldsPerDocument;

  // This will not be the final result, we have to play a little bit with it to see how the numbers will be in the end.

  if (amountOfCellsToRender <= 200)
    return {
      maxItemsRendered: amountOfDocuments,
      newItemsOnScroll: amountOfDocuments,
    };

  if (amountOfCellsToRender > 200 && amountOfCellsToRender <= 300)
    return {
      maxItemsRendered: 50,
      newItemsOnScroll: 10,
    };

  if (amountOfCellsToRender > 300 && amountOfCellsToRender <= 400)
    return {
      maxItemsRendered: 50,
      newItemsOnScroll: 10,
    };

  if (amountOfCellsToRender > 400 && amountOfCellsToRender <= 500)
    return {
      maxItemsRendered: 40,
      newItemsOnScroll: 10,
    };

  if (amountOfCellsToRender > 500 && amountOfCellsToRender <= 600)
    return {
      maxItemsRendered: 30,
      newItemsOnScroll: 10,
    };

  if (amountOfCellsToRender > 600 && amountOfCellsToRender <= 1000)
    return {
      maxItemsRendered: 30,
      newItemsOnScroll: 10,
    };

  if (amountOfCellsToRender > 1000 && amountOfCellsToRender <= 3000)
    return {
      maxItemsRendered: 30,
      newItemsOnScroll: 10,
    };

  if (amountOfCellsToRender > 3000 && amountOfCellsToRender <= 6000)
    return {
      maxItemsRendered: 30,
      newItemsOnScroll: 6,
    };

  // More than 6000 cells to be rendered.
  return {
    maxItemsRendered: 30,
    newItemsOnScroll: 4,
  };
};
