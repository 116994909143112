import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const EditOwnFiles: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        clipRule="evenodd"
        d="M13.6225 12.6274C13.6225 13.9852 12.5228 15.0849 11.165 15.0849C9.80723 15.0849 8.7075 13.9852 8.7075 12.6274C8.7075 11.2697 9.80723 10.1699 11.165 10.1699C12.5228 10.1699 13.6225 11.2697 13.6225 12.6274ZM6.25 18.7712C6.25 17.1369 9.52462 16.3137 11.165 16.3137C11.8875 16.3137 12.9271 16.4734 13.8642 16.7918L10.7076 19.9999H6.86437C6.52647 19.9999 6.25 19.7235 6.25 19.3855V18.7712Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M22.3308 14.5356C22.5583 14.7631 22.5583 15.1306 22.3308 15.3581L21.2633 16.4256L19.0758 14.2381L20.1433 13.1706C20.2523 13.0614 20.4003 13 20.5546 13C20.7089 13 20.8568 13.0614 20.9658 13.1706L22.3308 14.5356ZM12 23.2098V21.4365C12 21.3548 12.0292 21.2848 12.0875 21.2264L18.4517 14.8623L20.6392 17.0498L14.2692 23.4139C14.2167 23.4723 14.1408 23.5014 14.065 23.5014H12.2917C12.1283 23.5014 12 23.3731 12 23.2098Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.26 4.24953C3.26025 2.74043 4.48106 1.5 6 1.5H13.17C13.8956 1.5 14.5993 1.78684 15.1136 2.3129L19.9371 7.13645C20.4632 7.65075 20.75 8.3544 20.75 9.08V11.25H19.25V9.08C19.25 8.74739 19.1182 8.43273 18.8868 8.20735L18.8796 8.20038L14.0426 3.36326C13.8172 3.1318 13.5026 3 13.17 3H6C5.3191 3 4.76 3.55931 4.76 4.25L4.75 20.25C4.75013 20.9406 5.30918 21.5 5.99 21.5H10.5002L10.5003 23H5.99C4.4709 23 3.25 21.7593 3.25 20.25L3.26 4.24953ZM12.5003 23H14.603V21.5H12.5002L12.5003 23ZM13 8.25V3.75L18.5 9.25H14C13.45 9.25 13 8.8 13 8.25Z"
        fillRule="evenodd"
      />
    </g>
  </SvgIcon>
);
