import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';

import { OrganizationButtons } from './toolbar/OrganizationButtons';
import { ProjectButtons } from './toolbar/ProjectButtons';

export const Toolbar: React.FC = () => {
  const {
    params: { projectId },
  } = useRouting();

  return (
    <Box display="flex" p={1}>
      {projectId ? <ProjectButtons /> : <OrganizationButtons />}
    </Box>
  );
};
