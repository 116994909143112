import { Option } from '@pro4all/shared/types';

import { useShareSnagstreamContext } from './ShareSnagstreamContext';
import { FormFields } from './types';

export const useSelectConnection = () => {
  const { setSelectedConnection } = useShareSnagstreamContext();

  const selectConnection = ({
    option,
    values,
  }: {
    option: Option;
    values: FormFields;
  }) => {
    // We do some things only if the user changed the connection or removed it.
    if (values.connection?.id !== option?.id) {
      // Store the selected connection in React.Context, so that the 'useGetProjectPhaseOptions' hook
      // re-renders to fetch a list of project phases that are related to the selected connection.
      setSelectedConnection && setSelectedConnection(option?.id);
    }
  };

  return selectConnection;
};
