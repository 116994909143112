import { Permission } from '../types';

// Get the direct child folders and all deeper nested folders of a folder
export const getNestedChildFolders = (
  folderId: string,
  permissions: Permission[]
) => {
  let response: Permission[] = [];
  const childFolders =
    permissions.filter((folder) => folder.parentFolderId === folderId) || [];

  if (childFolders.length) {
    response = [...response, ...childFolders];

    childFolders.forEach((folder) => {
      response = [
        ...response,
        ...getNestedChildFolders(folder.folderId, permissions),
      ];
    });
  }

  return response;
};
