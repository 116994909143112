import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export const BoxWrapper = styled(Box)`
  && {
    display: flex;
  }
`;

export const BoxMySubscription = styled(Box)`
  && {
    display: flex;
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(6)};
    }
  }
`;
