import {
  FieldDefinition,
  MetaDataOption,
  ValueType,
  ValueTypeName,
} from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { IconName } from '@pro4all/shared/ui/icons';

type SelectOption = {
  id: string;
  inputValue: string;
  label: string;
};

type StatusOption = SelectOption & {
  iconColor: string;
  iconName: IconName;
};

const mapNameToSelectOption = (name: string): SelectOption => ({
  id: name,
  inputValue: name,
  label: name,
});

const toStatusOption = (option: MetaDataOption): StatusOption => ({
  iconColor: option.color || customColors.purplePrimary,
  iconName: 'tinyCircle',
  ...mapNameToSelectOption(option.name),
});

export const getFieldOptions = (
  field: FieldDefinition
): SelectOption[] | StatusOption[] => {
  const { options = [] } = field.valueType as ValueType;
  return options
    ? options
        .filter((option): option is MetaDataOption => Boolean(option))
        .map((option) =>
          field.type === ValueTypeName.Status
            ? toStatusOption(option)
            : mapNameToSelectOption(option.name)
        )
    : [];
};
