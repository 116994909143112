import {
  Group,
  Reference,
  ReferenceKind,
  ReferenceType,
  User,
} from '@pro4all/graphql';
import { isDefined } from '@pro4all/shared/utils';

import { createReferenceFromExternalMember } from './createReferenceFromExternalMember';
import { createReferenceFromGroup } from './createReferenceFromGroup';
import { createReferenceFromUser } from './createReferenceFromUser';

enum RecipientKey {
  Bcc = 'Bcc',
  Cc = 'Cc',
  To = 'To',
}

type Recipients = {
  [key in keyof typeof RecipientKey]:
    | { id: string; label: string; type: ReferenceType }[]
    | null;
};

export const getReferences = (
  users: User[],
  groups: Group[],
  recipients: Recipients
): Reference[] => {
  const references: Reference[] = [];

  Object.values(RecipientKey).forEach((key) => {
    const values = recipients[key as keyof Recipients];

    if (values?.length) {
      const referenceKind = ReferenceKind[key as keyof typeof ReferenceKind];
      const referenceMembers = values
        .map((value) => {
          switch (value.type) {
            case ReferenceType.User:
              return createReferenceFromUser(referenceKind, value.id, users);
            case ReferenceType.Group:
              return createReferenceFromGroup(value.id, referenceKind, groups);
            case ReferenceType.Email:
              return createReferenceFromExternalMember(
                value.label,
                referenceKind
              );
            default:
              return {} as Reference;
          }
        })
        .filter(isDefined);

      if (referenceMembers.length) {
        referenceMembers.forEach((referenceMember) =>
          references.push(referenceMember)
        );
      }
    }
  });

  return references;
};
