import styled from 'styled-components';

import { Switch as MuiSwitch } from '@pro4all/shared/mui-wrappers';

export const Container = styled.div`
  overflow: hidden;
`;

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Header = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #212121;
  line-height: 150%;
`;

// TODO: now this switch is too small, we need to override the theme to create a different style for small and medium switches
export const Switch = styled(MuiSwitch)``;
