import { BasePropsHierarchyItem, ItemProps } from '../types';

export const deselectItemAction = <
  HierarchyItem extends BasePropsHierarchyItem
>({
  allItems,
  id,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'id'>) => {
  let updatedItems = allItems;

  const itemToSelectOrDeselect = allItems.find((item) => item.id === id);
  const otherItems = allItems.filter((item) => item.id !== id);

  if (itemToSelectOrDeselect) {
    updatedItems = [
      ...otherItems,
      {
        ...itemToSelectOrDeselect,
        selected: !itemToSelectOrDeselect.selected,
      },
    ];
  }

  return updatedItems;
};
