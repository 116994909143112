import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const QualityControl: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M8 7.75C8 7.19772 8.44772 6.75 9 6.75H20C20.5523 6.75 21 7.19772 21 7.75V8.75C21 9.30228 20.5523 9.75 20 9.75H9C8.44772 9.75 8 9.30228 8 8.75V7.75Z" />
    <path d="M1 12C1 11.4477 1.44772 11 2 11H13C13.5523 11 14 11.4477 14 12V13C14 13.5523 13.5523 14 13 14H2C1.44772 14 1 13.5523 1 13V12Z" />
    <path d="M11 16.25C11 15.6977 11.4477 15.25 12 15.25H23C23.5523 15.25 24 15.6977 24 16.25V17.25C24 17.8023 23.5523 18.25 23 18.25H12C11.4477 18.25 11 17.8023 11 17.25V16.25Z" />
    <path
      clipRule="evenodd"
      d="M8.91667 3C8.91667 2.44772 9.36438 2 9.91667 2H10.75C11.3023 2 11.75 2.44772 11.75 3C11.75 3.55228 11.3023 4 10.75 4H9.91667C9.36438 4 8.91667 3.55228 8.91667 3ZM12.25 3C12.25 2.44772 12.6977 2 13.25 2H14.0833C14.6356 2 15.0833 2.44772 15.0833 3C15.0833 3.55228 14.6356 4 14.0833 4H13.25C12.6977 4 12.25 3.55228 12.25 3ZM5 7.28125C5.55228 7.28125 6 7.72897 6 8.28125V9.21875C6 9.77104 5.55228 10.2188 5 10.2188C4.44772 10.2188 4 9.77104 4 9.21875V8.28125C4 7.72897 4.44772 7.28125 5 7.28125ZM19 11.0312C19.5523 11.0312 20 11.479 20 12.0312V12.9688C20 13.521 19.5523 13.9688 19 13.9688C18.4477 13.9688 18 13.521 18 12.9688V12.0312C18 11.479 18.4477 11.0312 19 11.0312ZM5 14.7812C5.55228 14.7812 6 15.229 6 15.7812V16.7188C6 17.271 5.55228 17.7188 5 17.7188C4.44772 17.7188 4 17.271 4 16.7188V15.7812C4 15.229 4.44772 14.7812 5 14.7812ZM8.91667 22C8.91667 21.4477 9.36438 21 9.91667 21H10.75C11.3023 21 11.75 21.4477 11.75 22C11.75 22.5523 11.3023 23 10.75 23H9.91667C9.36438 23 8.91667 22.5523 8.91667 22ZM12.25 22C12.25 21.4477 12.6977 21 13.25 21H14.0833C14.6356 21 15.0833 21.4477 15.0833 22C15.0833 22.5523 14.6356 23 14.0833 23H13.25C12.6977 23 12.25 22.5523 12.25 22Z"
      fillRule="evenodd"
    />
    <path d="M4 5C4 3.34315 5.34315 2 7 2C7 2 8 2 8 3C8 4 7 4 7 4C6.44772 4 6 4.44772 6 5C6 5 6 6 5 6C4 6 4 5 4 5Z" />
    <path d="M17 2C18.6569 2 20 3.34315 20 5C20 5 20 6 19 6C18 6 18 5 18 5C18 4.44772 17.5523 4 17 4C17 4 16 4 16 3C16 2 17 2 17 2Z" />
    <path d="M20 20C20 21.6569 18.6569 23 17 23C17 23 16 23 16 22C16 21 17 21 17 21C17.5523 21 18 20.5523 18 20C18 20 18 19 19 19C20 19 20 20 20 20Z" />
    <path d="M7 23C5.34315 23 4 21.6569 4 20C4 20 4 19 5 19C6 19 6 20 6 20C6 20.5523 6.44772 21 7 21C7 21 8 21 8 22C8 23 7 23 7 23Z" />
  </SvgIcon>
);
