import { useEffect, useRef, useState } from 'react';

export const useScrollRendering = <ItemType>({
  items,
  maxItemsRendered,
  newItemsOnScroll,
  scrollCrossing,
  scrollableDivRef,
}: {
  items: ItemType[];
  maxItemsRendered: number;
  newItemsOnScroll: number;
  scrollCrossing: number;
  scrollableDivRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const [data, setData] = useState<ItemType[] | null>(null); // Array with data.
  const [pageIndex, setPageIndex] = useState(0); // Number of items to display.
  const scrollTopRef = useRef<number | null>(null); // store scrollTop value to set the scrollTop after the children have been re-rendered.

  // useEffect to fill the applicable set of items to render.
  useEffect(() => {
    setData(items.slice(pageIndex, pageIndex + maxItemsRendered));
  }, [items, maxItemsRendered, pageIndex]);

  // useEffect to manage the bottom and top scrolling and set the pageIndex accordingly.
  useEffect(() => {
    const handleScroll = () => {
      const scrollableDiv = scrollableDivRef.current;

      // Calculation to decide whether user scrolled to the bottom.
      const scrolledToBottom =
        scrollableDiv.scrollHeight -
          scrollableDiv.scrollTop -
          scrollableDiv.clientHeight <
        scrollCrossing;

      if (scrolledToBottom) {
        setPageIndex((prev) => {
          let newPageIndex = prev + newItemsOnScroll;
          if (newPageIndex > items.length - maxItemsRendered) {
            newPageIndex = items.length - maxItemsRendered;
            newPageIndex = newPageIndex < 0 ? 0 : newPageIndex; // For if the number of items is less than the max number of items on screen.
          } else {
            // Only make bottom scrollzone (so that the user can down up again) in case there are more items.
            const newValue = scrollableDiv.scrollTop - scrollCrossing - 1;
            scrollTopRef.current = newValue;
          }
          return newPageIndex;
        });
      }

      // Calculation to decide whether user scrolled to the top.
      const scrolledToTop = scrollableDiv.scrollTop < scrollCrossing;
      if (scrolledToTop) {
        setPageIndex((prev) => {
          let newPageIndex = prev - newItemsOnScroll;
          if (newPageIndex < 0) {
            newPageIndex = 0;
          } else {
            // Make a top scrollzone so that the user can scroll up again.
            const newValue = scrollCrossing + 1;
            scrollTopRef.current = newValue;
          }
          return newPageIndex;
        });
      }
    };

    const scrollableDiv = scrollableDivRef.current;

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    items,
    maxItemsRendered,
    newItemsOnScroll,
    pageIndex,
    scrollCrossing,
    scrollableDivRef,
  ]);

  // useEffect to set the stored scrollTop value after the children have been re-rendered.
  useEffect(() => {
    if (scrollTopRef.current)
      scrollableDivRef.current.scrollTop = scrollTopRef.current;

    scrollTopRef.current = null; // For preventing suddenly change of scrollTop when expanding/collapsing items.
  }, [data, scrollableDivRef]);

  return data || [];
};
