import React, { useEffect, useRef } from 'react';

import { UploaderEditorTypes } from './types';
import { UploaderEditorColumns } from './UploaderEditorColumns';
import { ColumnsWrapper, MainWrapper } from './wrappers';

export const UploaderEditorForm = ({
  isUploadContext,
}: Pick<UploaderEditorTypes, 'isUploadContext'>) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const initialRenderRef = useRef(true);

  // This ensures that the horizontal scroll position is reset to the left after focus is applied in `usePreventLoseCursorFocus`.
  // This is done only on mounting. The `initialRenderRef` is used to prevent this from happening on every render.
  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;

    if (scrollableDiv) {
      const handleFocus = () => {
        setTimeout(() => {
          if (initialRenderRef.current) {
            scrollableDiv.scrollLeft = 0;
            initialRenderRef.current = false;
          }
        }, 0);
      };

      scrollableDiv.addEventListener('focusin', handleFocus);

      return () => {
        scrollableDiv.removeEventListener('focusin', handleFocus);
      };
    } else {
      return;
    }
  }, []);

  // These isolated useEffects cover data calculation of which items to render and act on scroll events.
  return (
    <MainWrapper>
      <ColumnsWrapper scrollableDivRef={scrollableDivRef}>
        <UploaderEditorColumns
          isUploadContext={isUploadContext}
          scrollableDivRef={scrollableDivRef}
        />
      </ColumnsWrapper>
    </MainWrapper>
  );
};
