import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

export const useApplyTemplateToFolder = (folder: Folder) => {
  const { searchParams } = useRouting();
  const { t } = useTranslation();

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const openMetaSidebar = () => {
    searchParams.set({
      action: 'applyTemplate',
      folder: folder?.name,
    });
  };

  const applyTemplateMenuItem: ActionProps = {
    ariaLabel: 'Apply meta data template',
    dataTestId: 'apply-template',
    disabled: !hasFolderPermission('UpdateFolder'),
    key: 'apply-template',
    label: t('Apply meta data template'),
    onClick: openMetaSidebar,
    startIcon: 'metaData',
  };

  return { applyTemplateMenuItem };
};
