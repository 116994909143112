import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const SignatureCanvas = styled.div`
  height: 100px;
  width: 300px;
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${customColors.grey500};
  border-radius: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const SignatureImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`;

export const ButtonWrapper = styled.div`
  > * :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
  color: ${({ theme }) => theme.palette.error.main};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Canvas = styled.div<{
  $canvasHeight: number;
  $canvasWidth: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $canvasHeight }) => `${$canvasHeight}px`};
  width: ${({ $canvasWidth }) => `${$canvasWidth}px`};
  border: 1px solid ${customColors.grey500};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
