import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Excel: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g data-name="48" id="_48">
      <rect fill="#fff" height="29" width="19" x="24" y="9" />
      <path
        d="M43,9V38H24V9H43m0-2H24a2,2,0,0,0-2,2V38a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V9a2,2,0,0,0-2-2Z"
        fill="#217346"
      />
      <rect fill="#217346" height="3" width="7" x="33" y="12" />
      <rect fill="#217346" height="3" width="7" x="33" y="17" />
      <rect fill="#217346" height="3" width="7" x="33" y="22" />
      <rect fill="#217346" height="3" width="7" x="33" y="27" />
      <rect fill="#217346" height="3" width="7" x="33" y="32" />
      <rect fill="#217346" height="3" width="7" x="24" y="12" />
      <rect fill="#217346" height="3" width="7" x="24" y="17" />
      <rect fill="#217346" height="3" width="7" x="24" y="22" />
      <rect fill="#217346" height="3" width="7" x="24" y="27" />
      <rect fill="#217346" height="3" width="7" x="24" y="32" />
      <path d="M28,3,3,7.38V40.62L28,45Z" fill="#217346" fillRule="evenodd" />
      <path
        d="M20.86,15l-3.49.21-2.13,5-.14.45L15,21l-.09.33-.07.27h0q0-.2-.09-.38l-.09-.35-.1-.33-.11-.3-1.83-4.75-3.37.21,3.59,7.78L8.87,31.26l3.29.2,2.21-5.1.1-.35.08-.31.07-.26,0-.21h0l.07.37.07.31.06.24.06.17,2.3,5.44L21,32,16.7,23.43,20.86,15"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);
