import {
  DocumentVersionState,
  FilterType,
  FinalizationState,
  StandardItemKey,
} from '@pro4all/graphql';

import { useDocumentActionsLabels } from './useDocumentActionsLabels';

export const useActionNamingMapping = () => {
  const { approve_action_label, reject_action_label, finalize_action_label } =
    useDocumentActionsLabels();

  const getActionNamingMapping = (
    type:
      | StandardItemKey
      | FilterType
      | FinalizationState
      | DocumentVersionState
      | string,
    single?: boolean
  ) => {
    if (
      (type === StandardItemKey.Approved && !single) ||
      type === FilterType.VersionState ||
      type === 'Approved/Rejected'
    ) {
      return `${approve_action_label}/${reject_action_label}`;
    } else if (
      type === StandardItemKey.Finalized ||
      type === FilterType.State ||
      type === FinalizationState.Finalized ||
      type === FinalizationState.NotFinalized ||
      type === 'DocumentFinalizedEvent' ||
      type === 'DocumentUnfinalizedEvent' ||
      type === 'Finalized'
    )
      return finalize_action_label;
    else if (
      type === DocumentVersionState.Approved ||
      type === 'VersionApprovedEvent' ||
      type === 'VersionUnApprovedEvent' ||
      type === 'Approved'
    )
      return approve_action_label;
    else if (
      type === DocumentVersionState.Rejected ||
      type === 'VersionRejectedEvent' ||
      type === 'VersionUnRejectedEvent' ||
      type === 'Rejected'
    )
      return reject_action_label;
    else return type;
  };

  return getActionNamingMapping;
};
