import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Group, User } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import {
  Column,
  Main,
  OptimisticResponseProvider,
} from '@pro4all/shared/ui/general';

import { OrganizationGroupManagement } from '../components/group-management/organization/OrganizationGroupManagement';
import { OrganizationUsersGroupsTabs } from '../components/tabs/OrganizationUsersGroupsTabs';
import { OrganizationUserManagement } from '../components/user-management/organization/OrganizationUserManagement';

export const OrganizationMembers = () => (
  <Main sx={{ overflow: 'hidden' }}>
    <Column>
      <Route exact path={Routes.usersGroups}>
        <Redirect to={Routes.members} />
      </Route>
      <Route path={[Routes.groups, Routes.members]}>
        <OrganizationUsersGroupsTabs />
      </Route>
      <Route path={Routes.groups}>
        <OptimisticResponseProvider<Group>>
          <OrganizationGroupManagement />
        </OptimisticResponseProvider>
      </Route>
      <Route path={Routes.members}>
        <OptimisticResponseProvider<User>>
          <OrganizationUserManagement />
        </OptimisticResponseProvider>
      </Route>
    </Column>
  </Main>
);
