import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiKey, useApiKeyQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { ApiKeyForm } from './ApiKeyForm';
import { CopyTokenDialog } from './CopyTokenDialog';

export const ApiKeySidebar = ({ apiKey }: { apiKey: ApiKey | null }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const isCreating = searchParams.is('action', 'createApiKey');
  const isEditing = searchParams.is('action', 'editApiKey');
  const apiKeyId = searchParams.get('id');
  const [selectedApiKey, setSelectedApiKey] = useState(apiKey);

  const handleClose = () => searchParams.clear();

  const { data } = useApiKeyQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isEditing,
    variables: { id: apiKeyId || '' },
  });

  useEffect(() => {
    if (data?.apiKey) setSelectedApiKey(data.apiKey);
  }, [data]);

  return (
    <>
      <Sidebar onClose={handleClose} open={isCreating || isEditing}>
        <Sidebar.Header
          title={t(isCreating ? 'New API key' : 'Edit API key')}
        />
        <ApiKeyForm
          apiKey={isEditing ? selectedApiKey || apiKey : null}
          onClose={handleClose}
        />
      </Sidebar>
      <CopyTokenDialog />
    </>
  );
};
