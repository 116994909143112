import { useTranslation } from 'react-i18next';

import { Task } from '@pro4all/graphql';
import { useTaskStatuses } from '@pro4all/workflow/ui/utils';

export const useGetAvailableStatuses = ({ task }: { task?: Task }) => {
  const { t } = useTranslation();
  const statuses = useTaskStatuses();

  const availableStatuses = task?.availableStatuses?.map((ts) => ({
    id: ts.taskStatus || '',
    label: t(statuses[ts.taskStatus].label),
  }));

  return { availableStatuses: availableStatuses || [] };
};
