import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoFolders: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="fun"
      text={t('Create a folder to start uploading documents.')}
      title={t('This project has no folders')}
    />
  );
};
