import React from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Section } from '@pro4all/shared/ui/section';

const ICON_WIDTH = 32;

export const Guide: React.FC = () => (
  <svg viewBox={`0 0 ${ICON_WIDTH} 34`}>
    <path
      d="M18,13 v-13"
      fill="transparent"
      stroke={customColors.grey500}
      strokeWidth="2"
    />
    <path
      d="M18,34 v-13"
      fill="transparent"
      stroke={customColors.grey500}
      strokeWidth="2"
    />
  </svg>
);

export const Indent = styled.div<{ $depth: number; $expandable?: boolean }>`
  display: flex;
  justify-content: center;
  width: ${ICON_WIDTH}px;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
  vertical-align: middle;
  display: inline-flex;
  padding-right: 0.5rem;
`;

export const Header = styled(Section.Header).attrs({ as: 'div' })<{
  $canDrag?: boolean;
  $dragging?: boolean;
  $expandable?: boolean;
  $focus?: boolean;
}>`
  cursor: ${({ $canDrag, $dragging }) =>
    $dragging ? 'grabbing' : $canDrag ? 'grab' : 'auto'};
  flex: 1;
  padding-left: ${({ $expandable }) => ($expandable ? '0' : '0.5rem')};

  ${({ $focus, theme }) =>
    $focus &&
    `
    border-color: ${theme.palette.primary.main}};
    &:hover { background-color: inherit; }
  `}
`;

export const Toggle = styled(Section.Toggle)`
  cursor: inherit;
  font-weight: 400;
`;

export const Input = styled.input`
  border: none;
  background-color: transparent;
  color: ${customColors.grey700};
  flex: 1;
  font-size: inherit;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
