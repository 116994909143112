import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useChangePaymentMethodMutation } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';

export const useChangePaymentMethod = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [changePaymentMethod] = useChangePaymentMethodMutation();

  return async () => {
    const response = await changePaymentMethod({
      variables: {
        locale: window.localStorage.getItem('i18nextLng') || 'nl',
        redirectUrl: `${window.location.host}${Routes.settingsOrganizationLicense}`,
      },
    });

    if (response.data?.changePaymentMethod?.mollieRedirect) {
      window.location.href = response.data.changePaymentMethod.mollieRedirect;
    } else {
      enqueueSnackbar(t('Something went wrong'));
    }
  };
};
