import { client } from '@pro4all/authentication/src/graph-ql';
import { Document, Folder, FolderByPathDocument } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useUpdateCacheFolderByPathDocuments = () => {
  const { params } = useRouting();
  const { projectId } = params;

  const updateCacheFolderByPathDocuments = ({
    succeededIds,
  }: {
    succeededIds: string[];
  }) => {
    const cachedFolder = client?.readQuery({
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    })?.folder as Folder;

    client?.writeQuery({
      data: {
        folder: {
          ...cachedFolder,
          documents: [
            ...cachedFolder.documents.filter(
              (document: Document) => !succeededIds.includes(document.id)
            ),
          ],
        },
      },
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    });
  };

  return { updateCacheFolderByPathDocuments };
};
