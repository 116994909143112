import React from 'react';
import styled from 'styled-components';

import { Task, TbqAnswer, TbqRieResult } from '@pro4all/graphql';
import { Icon } from '@pro4all/shared/ui/icons';

interface TaskCounterProps {
  id: string;
  relevantTasksForAllQuestions: {
    question: TbqRieResult | TbqAnswer;
    tasks: Task[];
  }[];
}

export const TaskCounter: React.FC<TaskCounterProps> = ({
  relevantTasksForAllQuestions,
  id,
}) => {
  const matchedTasks =
    relevantTasksForAllQuestions.find((q) => q.question.id === id)?.tasks || [];
  const tasksCount = matchedTasks.length;
  const completedTasksCount = matchedTasks.filter(
    (task) => task.completedAt != null
  ).length;

  return (
    <StyledSpan>
      <StyledSandTimerIcon iconName="sandTimer" />
      {completedTasksCount + '/' + tasksCount}
    </StyledSpan>
  );
};

const StyledSpan = styled.span`
  border: 1px solid #000;
  border-radius: 4px;
  display: inline-block;
  min-width: 55px;
  padding: 1px 8px 1px 4px;
  text-align: center;
`;

export const StyledSandTimerIcon = styled(Icon)`
  && {
    margin-top: 0.28125rem;
    height: 0.9375rem;
    width: 0.9375rem;
  }
`;
