import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const OpenSearch: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M17.2988 6.88921C17.7788 6.11921 18.0488 5.21921 17.9888 4.22921C17.8588 2.07921 16.1488 0.259206 14.0188 0.0292062C11.2988 -0.270794 8.99878 1.83921 8.99878 4.49921C8.99878 6.98921 11.0088 8.99921 13.4888 8.99921C14.3688 8.99921 15.1888 8.73921 15.8788 8.29921L18.2888 10.7092C18.6788 11.0992 19.3188 11.0992 19.7088 10.7092C20.0988 10.3192 20.0988 9.67921 19.7088 9.28921L17.2988 6.88921ZM15.9988 12.9992V16.9992C15.9988 17.5492 15.5488 17.9992 14.9988 17.9992H2.99878C2.44878 17.9992 1.99878 17.5492 1.99878 16.9992V4.99921C1.99878 4.44921 2.44878 3.99921 2.99878 3.99921H6.01878C6.56878 3.99921 7.01878 3.54921 7.01878 2.99921C7.01878 2.44921 6.56878 1.99921 6.01878 1.99921H1.99878C0.898779 1.99921 -0.0012207 2.89921 -0.0012207 3.99921V17.9992C-0.0012207 19.0992 0.898779 19.9992 1.99878 19.9992H15.9988C17.0988 19.9992 17.9988 19.0992 17.9988 17.9992V12.9992C17.9988 12.4492 17.5488 11.9992 16.9988 11.9992C16.4488 11.9992 15.9988 12.4492 15.9988 12.9992ZM10.9988 4.49921C10.9988 5.87921 12.1188 6.99921 13.4988 6.99921C14.8788 6.99921 15.9988 5.87921 15.9988 4.49921C15.9988 3.11921 14.8788 1.99921 13.4988 1.99921C12.1188 1.99921 10.9988 3.11921 10.9988 4.49921Z"
      fillRule="evenodd"
    />
    <path d="M10.1428 16.47V14.28C7.29665 14.28 5.42309 15.13 4 17C4.57333 14.33 6.16023 11.67 10.1428 11.13V9L13.8388 12.36C14.0538 12.56 14.0538 12.89 13.8388 13.09L10.1428 16.47Z" />
  </SvgIcon>
);
