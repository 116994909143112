import React from 'react';

import { Member } from '@pro4all/graphql';
import { Avatar, Loader, Portal } from '@pro4all/shared/ui/general';

import { Option, SuggestionWrap } from './EditorStyles';

interface Props {
  contentRef: React.RefObject<HTMLDivElement>;
  index: number;
  onSelect: () => void;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  show: boolean;
  users?: Member[];
}

export const Suggestions: React.FC<Props> = ({
  index,
  contentRef,
  onSelect,
  setIndex,
  show,
  users,
}) => {
  if (!show) return null;

  const handleSelect = (event: React.MouseEvent) => {
    event.preventDefault();
    onSelect();
  };

  return (
    <Portal>
      <SuggestionWrap ref={contentRef}>
        {!users ? (
          <Loader />
        ) : (
          users.map((user, i) => (
            <Option
              $active={i === index}
              key={i}
              onMouseDown={handleSelect}
              onMouseEnter={() => setIndex(i)}
            >
              <Avatar name={user.displayName} />
              {user.displayName}
            </Option>
          ))
        )}
      </SuggestionWrap>
    </Portal>
  );
};
