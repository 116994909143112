import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { IntegrationType } from '@pro4all/graphql';
import { SchemaExtender } from '@pro4all/shared/forms';
import { FormikInput, InfoBlock } from '@pro4all/shared/ui/general';

import { useIntegrationFormConfig } from './useIntegrationFormConfig';

export const CustomBuild12 = () => {
  const { t } = useTranslation();
  const { getField } = useIntegrationFormConfig();
  const apiKeyField = getField('apiKey');
  const usernameField = getField('userName');

  return (
    <>
      <InfoBlock
        paragraph={`${t(
          'To integrate 12Build with Prostream we ask you to provide the following information'
        )}:`}
      />
      <FormikInput
        label={`* ${usernameField?.label}`}
        name={usernameField?.name}
        type="text"
      />
      <FormikInput
        label={`* ${apiKeyField?.label}`}
        name={apiKeyField?.name}
        type="text"
      />
    </>
  );
};

export const useCustomBuild12ValidationSchema = (): Record<
  string,
  SchemaExtender
> => {
  const { t } = useTranslation();

  const userName = () =>
    Yup.string().when(['application'], {
      is: (application) =>
        application?.id === IntegrationType.Integration12Build,
      then: Yup.string().required(
        t("'{{name}}' is required", { name: t('Username') })
      ),
    });

  const apiKey = () =>
    Yup.string().when(['application'], {
      is: (application) =>
        application?.id === IntegrationType.Integration12Build,
      then: Yup.string().required(
        t("'{{name}}' is required", { name: t('API-key') })
      ),
    });

  return {
    apiKey,
    userName,
  };
};
