import { FolderPermission } from '@pro4all/graphql';

import { getDisabledPermissionsCount } from '../../shared/helpers/getDisabledPermissionsCount';
import { getExclusivePermissionsBasedOnParentPermissions } from '../../shared/helpers/getExclusivePermissionsBasedOnParentPermissions';
import { NOT_INCLUDED_PERMISSONS } from '../../shared/types';
import { getInheritedFromFolderPermissions } from '../helpers/getInheritedFromFolderPermissions';
import { getNonConflictingPermissions } from '../helpers/getNonConflictingPermissions';
import { getThisEntityPermission } from '../helpers/getThisEntityPermission';
import { getUpdatedSaveAndDisplayPermissions } from '../helpers/getUpdatedSaveAndDisplayPermissions';
import { State } from '../types';

const filterPermissions = (permissions: FolderPermission[]) =>
  permissions.filter((p) => !NOT_INCLUDED_PERMISSONS.includes(p));

const allPermissions = Object.values(FolderPermission);

export const toggleAllPermissionsAction = ({
  entityId,
  state,
}: {
  entityId: string;
  state: State;
}) => {
  const thisEntityPermission = getThisEntityPermission(
    entityId,
    state.displayPermissions
  );

  const inheritedFromFolderPermissions = getInheritedFromFolderPermissions({
    entityPermission: thisEntityPermission,
  });

  // Might be that subfolder permissions block permissions of the parent folder.
  // In that case these blocking permissions should be included in the count to determine whether all permissions are checked.
  const disabledPermissionsCount = getDisabledPermissionsCount({
    permissions: thisEntityPermission.subFoldersPermissions.map(
      (subFolderPermission) => subFolderPermission.permission
    ),
  });

  // If not all permissions are there yet we include the missing.
  // If all permissions are there, we remove them all.
  let newPermissions =
    filterPermissions(thisEntityPermission.folderPermissions).length +
      disabledPermissionsCount !==
    filterPermissions(allPermissions).length
      ? filterPermissions(allPermissions)
      : [];

  if (
    newPermissions.length === 0 &&
    inheritedFromFolderPermissions.length > 0
  ) {
    // Only fallback to inherited permissions if breakInheritance is OFF.
    if (!thisEntityPermission.breakInheritance) {
      newPermissions = inheritedFromFolderPermissions;
    }
  } else if (inheritedFromFolderPermissions.length > 0) {
    const { subFolderPermissions } =
      getExclusivePermissionsBasedOnParentPermissions({
        currentSubFolderPermissions: thisEntityPermission.folderPermissions,
        parentFolderPermissions: inheritedFromFolderPermissions,
        subFolderPermissions: newPermissions,
      });
    newPermissions = subFolderPermissions;
  }

  // Filter out the conflicting permissions.
  newPermissions =
    !thisEntityPermission.parentFolderId ||
    thisEntityPermission.breakInheritance
      ? newPermissions
      : getNonConflictingPermissions({
          entityId,
          newPermissions,
          state,
        });

  const updatedEntityPermission = {
    ...thisEntityPermission,
    folderPermissions: newPermissions,
  };

  const { savePermissionsUpdated, displayPermissionsUpdated } =
    getUpdatedSaveAndDisplayPermissions({
      state,
      updatedFolderPermission: updatedEntityPermission,
    });

  return {
    ...state,
    displayPermissions: displayPermissionsUpdated,
    savePermissions: savePermissionsUpdated,
  };
};
