import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { LayoutProps } from '@pro4all/shared/types';

import { Section } from './GeneralElements';

export const DrawingHeader = styled.div`
  width: 675px;
  height: 75px;
  box-sizing: border-box;
  display: flex;
  border: 1px solid #afafaf;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const DrawingTitle = styled.div`
  font-size: 16pt;
  margin: 0;
  margin: 4pt 0;
  display: flex;
  order: 1;
  flex-grow: 1;
  font-weight: 500;
  font-style: normal;
  align-items: center;
`;

export const DrawingLabel = styled.dt<LayoutProps>`
  ${({ inlineLists = true }) =>
    !inlineLists &&
    css`
      --label-width: 100%;
    `}
`;

export const DrawingValue = styled.dd<LayoutProps>`
  ${({ inlineLists = true }) =>
    !inlineLists &&
    css`
      --value-width: 100%;
    `}
  flex: 2;
  color: #585858;
`;

export const DrawingMediaLabel = styled.p`
  font-weight: bold;
`;

export const DrawingItem = styled.div<LayoutProps>`
  page-break-inside: ${({ preventPageBreak }) =>
    preventPageBreak ? 'avoid' : 'auto'};

  ${DrawingLabel} {
    font-weight: bold;
    font-size: var(--font-size);
    line-height: var(--line-height);
    padding: var(--padding-top) 0 var(--padding-bottom) var(--padding-left);
  }

  ${DrawingValue}, p {
    font-weight: normal;
    font-size: var(--font-size-sm);
    line-height: var(--line-height);
    padding: var(--padding-top) 0 var(--padding-bottom) var(--padding-left);
    margin: 0;
  }
`;

export const DrawingList = styled.dl<LayoutProps>`
  padding: 0px 10px;

  --line-height: ${({ compactLists }) => (compactLists ? '12pt' : '14pt')};
  --font-size: ${({ compactLists }) => (compactLists ? '10pt' : '12pt')};
  --font-size-sm: 10pt;
  --padding-top: ${({ compactLists, inlineLists }) =>
    inlineLists ? (compactLists ? '8pt' : '12pt') : '2pt'};
  --padding-right: ${({ compactLists }) => (compactLists ? '8pt' : '12pt')};
  --padding-bottom: ${({ compactLists, inlineLists }) =>
    inlineLists ? (compactLists ? '8pt' : '12pt') : '2pt'};
  --padding-left: ${({ compactLists }) => (compactLists ? '8pt' : '12pt')};

  --label-width: ${({ labelWidth = 30 }) => `${labelWidth}%`};
  --value-width: ${({ labelWidth = 30 }) => `${100 - labelWidth}%`};

  width: 100%;
  margin: 0;
  columns: ${({ columns = 1 }) => columns};

  ${Section} {
    font-weight: bold;
    font-size: var(--font-size);
    line-height: var(--line-height);
    padding: var(--padding-top) 0 var(--padding-bottom) 0;
  }

  ${DrawingItem} {
    ${({ inlineLists }) =>
      inlineLists &&
      css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      `}
  }
`;

export const DrawingContainer = styled.div.attrs({
  className: 'drawing-container',
})`
  width: 675px;
  height: 800px;
  overflow: hidden;
  align-self: center;
  background: ${customColors.white};
`;
