import type { Group } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { FormFields } from './Types';
import { useCreateGroup } from './useCreateGroup';
import { useEditGroup } from './useEditGroup';

export const useSubmitGroupForm = (group: Group) => {
  const { track } = useAnalytics();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { projectId } = useRouting().params;
  const createGroup = useCreateGroup();
  const { editGroup } = useEditGroup();

  return ({
    initialValues,
    onClose,
    values,
  }: {
    initialValues: FormFields;
    onClose: () => void;
    values: FormFields;
  }) => {
    track(TrackingEvent.GroupCreated, { organizationId, projectId });

    if (group) {
      return editGroup({ group, initialValues, onClose, values });
    } else {
      return createGroup({ onClose, values });
    }
  };
};
