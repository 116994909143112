import { useContext, useMemo } from 'react';

import { PhotoContext } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';

export const usePhotoIds = ({
  fieldId,
  initialValue,
}: {
  fieldId: string;
  initialValue: string;
}) => {
  const photoIdsInitial = useMemo(
    () => (initialValue ? initialValue.split(',') : []),
    [initialValue]
  );

  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');

  /**
   * After a snag/form is mutated and saved it returns to the sidebar in display mode.
   * In case of added/deleted pictures we don't wanna show 'initialValue' when the component renders.
   * In that case we wanna show what's in state. state always contains the latest set of pictures to display.
   * We have to memoize the objects array to avoid infinite rendering.
   * We also cannot use 'photos' as a dependancy because that causes infinite renders also.
   * That is why we take all ids from 'photos' and concatenate those in a comma separated string.
   */
  const {
    state: { photos, removals },
  } = useContext(PhotoContext);

  const removalsCurrentField = removals.filter(
    (removal) =>
      removal.fieldId === fieldId && removal.instanceId === instanceId
  );

  const photosCurrentField = (photos ?? []).filter(
    (photo) => photo.fieldId === fieldId && photo.instanceId === instanceId
  );

  const stringPhotoIdsInState = photosCurrentField
    .map((photo) => photo.id)
    .join(',');

  const photoIdsInState = useMemo(
    () => (stringPhotoIdsInState ? stringPhotoIdsInState.split(',') : []),
    [stringPhotoIdsInState]
  );

  // Could be that the photoIdsInState array is empty caused by deletion of all photos.
  // In that case of course we don't wanna show the original photoIds either.
  return {
    photoIds:
      photoIdsInState.length || removalsCurrentField.length
        ? photoIdsInState
        : photoIdsInitial,
  };
};
