import React from 'react';

import { ReportConfiguration } from '@pro4all/graphql';
import { useReportConfigurations } from '@pro4all/shared/hooks';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Loader,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoReportConfigurations } from '@pro4all/shared/ui/messages';

import { ReportConfigurationsActionBar } from '../action-bars/ReportConfigurationsActionBar';
import { useColumnsAdditional } from '../columns/useColumnsAdditionalReportConfigurations';
import { useColumnsReportConfigurations } from '../columns/useColumnsReportConfigurations';
import NavigationDropdown from '../navigation-dropdown/NavigationDropdown';
import { ReportConfigurationSidebar } from '../sidebar-item/ReportConfigurationSidebar';

import {
  CustomActionBarWrapper,
  CustomDefaultActionBarWrapper,
} from './CustomActionBarWrapper';

export const ScreenReportConfigurations = ({
  isProject = false,
}: {
  isProject?: boolean;
}) => {
  const { searchParams } = useRouting();
  const editReportConfiguration = searchParams.is(
    'action',
    'editReportConfiguration'
  );
  const createReportConfiguration = searchParams.is(
    'action',
    'createReportConfiguration'
  );

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<ReportConfiguration>();

  const columnsAdditional = useColumnsAdditional();
  const columnsItems = useColumnsReportConfigurations();
  const additionalColumns = isProject ? columnsAdditional : [];
  const { hasAppPermission } = useAppPermissions();

  const { loading, reportConfigurations } = useReportConfigurations({
    reportConfigurations: null,
  });

  useSetItemsInLocalState<ReportConfiguration>(reportConfigurations);

  if (loading) return <Loader />;

  if (editReportConfiguration || createReportConfiguration)
    return (
      <ReportConfigurationSidebar reportConfigurations={reportConfigurations} />
    );

  return (
    <TableContextProvider
      columns={[...columnsItems, ...additionalColumns]}
      id={`table-report-configurations-${
        isProject ? 'project' : 'organization'
      }`}
      items={items}
    >
      <FilterContextProvider<ReportConfiguration>>
        <>
          <CustomActionBarWrapper>
            <Box>
              <NavigationDropdown />
            </Box>
            <CustomDefaultActionBarWrapper>
              <ReportConfigurationsActionBar />
            </CustomDefaultActionBarWrapper>
          </CustomActionBarWrapper>

          {!items?.length && !itemsInitial?.length ? (
            <BigMessageNoReportConfigurations />
          ) : (
            <Table<ReportConfiguration>
              onRowClick={(row) =>
                hasAppPermission(
                  isProject ? 'ProjectUpdate' : 'OrganizationUpdate'
                ) &&
                searchParams.set({
                  action: 'editReportConfiguration',
                  id: row.id,
                })
              }
            />
          )}
        </>
      </FilterContextProvider>
    </TableContextProvider>
  );
};
