import { useTranslation } from 'react-i18next';

import { LockType } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useEditPopupAction = ({
  editableDocuments,
  isSpecialFolder,
  isVersion,
  subItems,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'isSpecialFolder' | 'isVersion'
> & { subItems: ActionProps[] }) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const editableDocumentsWithoutLocked = editableDocuments.filter(
    (doc) => doc.lockType !== LockType.Prostream
  );

  const editMetaData = () => {
    goTo({
      searchParams: { action: 'editMetaData', f: undefined, fullscreen: true },
      state: {
        documentIdsForEdit: editableDocumentsWithoutLocked.map(({ id }) => id),
      },
    });
  };

  const editMetaDataNewAction: ActionProps = {
    ariaLabel: t('Edit document'),
    dataTestId: 'edit-meta-data',
    disabled:
      editableDocumentsWithoutLocked.length === 0 ||
      isSpecialFolder ||
      isVersion,
    key: 'edit-meta-data',
    label: t('Edit document'),
    onClick: editMetaData,
    startIcon: 'edit',
    subItems: subItems,
  };

  return editMetaDataNewAction;
};
