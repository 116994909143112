import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@pro4all/shared/mui-wrappers';

export const LinkCreatedFeedBack: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="success" variant="filled">
      {t('Link has been created.')}
    </Alert>
  );
};
