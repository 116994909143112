import React from 'react';

import { MiddleEllipsis } from '@pro4all/shared/ui/general';

import {
  HighlightedSpan,
  Main,
  NameWrap,
  OverflowText,
} from './ResultMatch.styles';

interface Props {
  IconComponent?: React.ReactNode;
  InfoComponent?: React.ReactNode;
  caseSensitive?: boolean;
  forcedWidth?: boolean;
  highlightColor?: string;
  inactive?: boolean;
  matchString?: string;
  skipMatch?: boolean;
  text: string;
}

export const ResultMatch: React.FC<Props> = ({
  InfoComponent,
  caseSensitive = false,
  highlightColor = '#c3f0ea',
  IconComponent,
  inactive,
  matchString = '',
  skipMatch,
  text,
  forcedWidth,
}) => {
  const encodedText = encode(text);
  const encodedMatchString = encode(matchString.replace('*', ''));

  let match = undefined;
  let index = 0;
  let head = '';
  let tail = '';

  if (!skipMatch) {
    match = encodedText.match(
      new RegExp(encodedMatchString, caseSensitive ? '' : 'i')
    );
    index = (match ? match['index'] : 0) || 0;
    head = match ? `${encodedText.slice(0, index)}` : '';
    tail = match
      ? encodedText.slice(index + (encodedMatchString?.length || 0))
      : '';
  }

  const decodedMatch = decodeMatchString(match?.toString() || '');
  const decodedHead = match && decodeMatchString(head);
  const decodedTail = match && decodeMatchString(tail);

  return (
    <Main className="resultMatchContainer" scootUp={Boolean(InfoComponent)}>
      <NameWrap $inactive={inactive}>
        {IconComponent && IconComponent}
        {!matchString || !match || skipMatch ? (
          <MiddleEllipsis
            placement="bottom"
            text={text}
            width={forcedWidth ? '312px' : undefined}
          />
        ) : (
          <>
            <OverflowText $flexShrink={1}>
              {decodedHead && decodedHead?.length > 5 ? (
                <MiddleEllipsis
                  placement="bottom"
                  text={decodedHead}
                  width={forcedWidth ? '80px' : undefined}
                />
              ) : (
                decodedHead
              )}
            </OverflowText>
            <HighlightedSpan
              $highlightColor={highlightColor}
              data-testid="highlighted-text"
            >
              {decodedMatch}
            </HighlightedSpan>
            <OverflowText>
              {decodedTail && decodedTail?.length > 5 ? (
                <MiddleEllipsis
                  placement="bottom"
                  text={decodedTail}
                  width={forcedWidth ? '80px' : undefined}
                />
              ) : (
                decodedTail
              )}
            </OverflowText>
          </>
        )}
      </NameWrap>
      {InfoComponent && InfoComponent}
    </Main>
  );
};

const encode = (value: string) =>
  encodeURIComponent(value).replace(
    /[-_~!'()]/g,
    (c) => '%' + c.charCodeAt(0).toString(16)
  );

const decodeMatchString = (match: string) => {
  try {
    return match && decodeURIComponent(match)?.replace(/ /g, '\u00A0');
  } catch (error) {
    console.error(error);
    return '';
  }
};
