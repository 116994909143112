import { useTranslation } from 'react-i18next';

export const useTranslateTbq = () => {
  const { t } = useTranslation();

  const translateTbq = (str: string) =>
    str?.startsWith('TBQ.') ? t(str) : str;

  return { translateTbq };
};
