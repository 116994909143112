import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSortColumn } from './hooks/useSortColumn';
import { useTableFilter } from './hooks/useTableFilter';
import { BaseRow, ColumnProps as Column } from './types';

type TableContextValue<Row extends BaseRow> = {
  checkable: boolean;
  checkableSpacing: boolean;
  columns: Column<Row>[];
  id: string;
  idFilteringSorting?: string;
  items: Row[];
  renderToggle: boolean;
  rows: Row[];
  setRenderToggle?: (value: boolean) => void;
  setRows?: (rows: Row[]) => void;
};

type TableCheckContextValue<Row extends BaseRow> = {
  checkedRows: Row[];
  setCheckedRows?: (rows: Row[]) => void;
};

type lastCheckedRow = {
  index: number;
  selected: boolean;
};

type TableLastCheckedContextValue = {
  lastChecked: lastCheckedRow;
  setLastChecked?: (lastChecked: lastCheckedRow) => void;
};

type TableFilterContextValue = [string?, ((filter: string) => void)?];

const TableContext = React.createContext({
  checkable: false,
  checkableSpacing: false,
  columns: [],
  id: '',
  idFilteringSorting: '',
  items: [],
  renderToggle: false,
  rows: [],
});

const TableCheckContext = React.createContext({
  checkedRows: [],
});

const TableLastCheckedContext = React.createContext({
  lastChecked: { index: -1, selected: false },
});

const TableFilterContext = React.createContext([] as TableFilterContextValue);

export function useTableContext<Row extends BaseRow>() {
  return useContext<TableContextValue<Row>>(TableContext);
}

export function useTableCheckContext<Row extends BaseRow>() {
  return useContext<TableCheckContextValue<Row>>(TableCheckContext);
}

export function useTableLastCheckedContext() {
  return useContext<TableLastCheckedContextValue>(TableLastCheckedContext);
}

export function useTableFilterContext() {
  return useContext<TableFilterContextValue>(TableFilterContext);
}

export function TableContextProvider<Row extends BaseRow>({
  checkable = true,
  checkableSpacing = false,
  children,
  columns,
  id,
  idFilteringSorting,
  items = [],
}: PropsWithChildren<{
  checkable?: boolean;
  checkableSpacing?: boolean;
  columns: Column<Row>[];
  id: string;
  idFilteringSorting?: string;
  items: Row[];
}>) {
  const [checkedRows, setCheckedRows] = useState<Row[]>([]);
  const [lastChecked, setLastChecked] = useState({
    index: -1,
    selected: false,
  });

  const filterState = useState('');
  const [filter] = filterState;
  const [rows, setRows] = useState<Row[]>([]);
  const [renderToggle, setRenderToggle] = useState(false);

  const filterRows = useTableFilter<Row>(columns);

  const { getSortedItems } =
    useSortColumn<Row>({ tableId: idFilteringSorting || id }) || {};

  useEffect(() => {
    // Clear row selection when filtering so we don't get invisible selected items
    setCheckedRows([]);
  }, [filter, setCheckedRows]);

  useEffect(() => {
    if (items) {
      // Filter items based on the search string user entered on table level.
      const filteredItems = filterRows(items, filter);
      const sortedItems = getSortedItems({
        items: filteredItems,
      });
      setRows(sortedItems);
    }
  }, [filter, filterRows, getSortedItems, items, setRows]);

  const mainContextValue = useMemo(
    () => ({
      checkable,
      checkableSpacing,
      columns,
      id,
      idFilteringSorting,
      items,
      renderToggle,
      rows,
      setRenderToggle,
      setRows,
    }),
    [
      checkable,
      checkableSpacing,
      columns,
      id,
      idFilteringSorting,
      items,
      renderToggle,
      rows,
      setRenderToggle,
      setRows,
    ]
  );
  const checkContextValue = useMemo(
    () => ({
      checkedRows,
      setCheckedRows,
    }),
    [checkedRows, setCheckedRows]
  );

  const lastCheckedContextValue = useMemo(
    () => ({
      lastChecked,
      setLastChecked,
    }),
    [lastChecked, setLastChecked]
  );

  return (
    // Using separate providers to limit the amount of unnecessary renders
    <TableContext.Provider value={mainContextValue}>
      <TableFilterContext.Provider value={filterState}>
        <TableCheckContext.Provider value={checkContextValue}>
          <TableLastCheckedContext.Provider value={lastCheckedContextValue}>
            {children}
          </TableLastCheckedContext.Provider>
        </TableCheckContext.Provider>
      </TableFilterContext.Provider>
    </TableContext.Provider>
  );
}
