import { FieldDefinition } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { CutCopyPasteSection, PasteType } from '@pro4all/shared/types';

import { useFieldContext } from './TemplateMutationContext';

export const useHidePaste = ({ item }: { item: FieldDefinition }) => {
  const { fieldDefinitions } = useFieldContext();

  const { getLocalStorageItem: getCutCopyPasteTemplateSectionInLs } =
    useLocalStorage<CutCopyPasteSection>({
      key: StorageKeys.COPY_CUT_PASTE_TEMPLATE_SECTION,
    });

  const { pasteType, sourceSection } =
    getCutCopyPasteTemplateSectionInLs() || {};

  const getParentSectionIds = (item: FieldDefinition) => {
    const ids = [item.id]; // Start with the id of the passed in item.

    const findParentSectionById = ({
      items,
      parentId,
    }: {
      items: FieldDefinition[];
      parentId: string;
    }) => {
      for (const item of items) {
        // Check if the current item is equal to parentSectionId.
        if (item.id === parentId) {
          ids.push(item.id); // Add parent ID to the ids array
          // If the item itself als has a parentSectionId, continue searching upwards.
          if (item.parentSectionId) {
            findParentSectionById({
              items: fieldDefinitions,
              parentId: item.parentSectionId,
            });
          }
          break;
        }

        // Search in the subfitems of this item if they exist.
        if (item.valueType && Array.isArray(item.valueType.subFields)) {
          findParentSectionById({ items: item.valueType.subFields, parentId });
        }
      }
    };

    // Start with the first parentSectionId of the passed in item.
    if (item.parentSectionId) {
      findParentSectionById({
        items: fieldDefinitions,
        parentId: item.parentSectionId,
      });
    }

    return ids;
  };

  const hidePaste = () => {
    const nothingInClipboard = !sourceSection ? true : false;
    const ids = getParentSectionIds(item);
    return (
      (nothingInClipboard || ids.includes(sourceSection?.id)) &&
      pasteType === PasteType.CUT
    );
  };

  return { hidePaste };
};
