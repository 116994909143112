import React from 'react';

import { useVisualContextQuery } from '@pro4all/graphql';
import { QualityControlInstanceType } from '@pro4all/graphql';
import { FieldDefinition } from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import { getColor } from '@pro4all/quality-control/utils';
import { findIndicative } from '@pro4all/quality-control/utils';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { useRouting } from '@pro4all/shared/routing-utils';

import { Map } from './Map';
import { Marker } from './Marker';

interface Props {
  iconName?: string | null;
  iconType?: number;
  instanceId: string;
  items: FieldDefinition[];
  lat: number;
  lng: number;
  page: number;
  templateId?: string;
  type: QualityControlInstanceType;
  visualContextId: string;
}

export const MiniMap = ({
  visualContextId,
  type,
  instanceId,
  lat,
  lng,
  items,
  page,
  iconName,
  templateId,
  iconType,
}: Props) => {
  const { data } = useVisualContextQuery({
    variables: { id: visualContextId },
  });

  const { goTo, params, url } = useRouting();

  const projectId = params.projectId;

  const goToDrawing = (visualContextId: string) => {
    goTo(getDrawingRoute(params), {
      params: {
        objectId: params.objectId,
        projectId,
        visualContextId: visualContextId,
      },
      state: {
        previousPageName: 'results',
        previousPageUrl: url,
      } as DrawingRouterState,
    });
  };

  // VisualContext can be undefined if it has been deleted
  if (!data?.visualContext) {
    return null;
  }

  const { height: mapHeight, width: mapWidth, zoomLevels } = data.visualContext;

  if (!mapHeight || !mapWidth) {
    return null;
  }

  return (
    <Map
      cluster={false}
      disableControls
      height={164}
      lat={lat}
      lng={lng}
      mapHeight={mapHeight}
      mapWidth={mapWidth}
      offsetX={0}
      visualContextId={visualContextId}
      width={164}
      zoomLevels={zoomLevels}
    >
      <Marker
        iconName={iconName}
        iconType={iconType}
        id={instanceId}
        lat={lat}
        lng={lng}
        onClick={() => goToDrawing(visualContextId)}
        page={page}
        selectedId={instanceId}
        statusColor={getColor(findIndicative(items))}
        templateId={templateId}
        type={type}
      />
    </Map>
  );
};
