import { useTranslation } from 'react-i18next';

import {
  RequiredMetaDataFieldOption,
  UploadFileOption,
  ValueTypeName,
} from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useProjectSettingsFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'When uploading a document', // i18n
      name: 'version_name_instead_of_document_name_on_upload',
      options: [
        {
          id: UploadFileOption.KeepCurrent,
          label: t(UploadFileOption.KeepCurrent),
        },
        {
          id: UploadFileOption.UseFromUploaded,
          label: t(UploadFileOption.UseFromUploaded),
        },
      ],
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Required meta data fields', // i18n
      name: 'metadata_required',
      options: [
        {
          id: RequiredMetaDataFieldOption.Enforce,
          label: t(RequiredMetaDataFieldOption.Enforce),
        },
        {
          id: RequiredMetaDataFieldOption.DoNotEnforce,
          label: t(RequiredMetaDataFieldOption.DoNotEnforce),
        },
      ],
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Approve'),
      name: 'approve_action_label',
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Reject'),
      name: 'reject_action_label',
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Finalize'),
      name: 'finalize_action_label',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
