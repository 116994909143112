import { useTranslation } from 'react-i18next';

import {
  useSupportSettingsQuery,
  useUpdateSupportSettingsMutation,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

export const SupportForm = () => {
  const { t } = useTranslation();

  const { data, refetch } = useSupportSettingsQuery({});
  const [updateSupportSettings] = useUpdateSupportSettingsMutation({});

  const date = data?.supportSettings?.split('T')?.[0];
  const enabled = date ? new Date(Date.parse(date)) > new Date() : false;
  const dateString = date && new Date(Date.parse(date)).toLocaleDateString();

  const grantSupport = new Date();
  grantSupport.setDate(grantSupport.getDate() + ApiConfig.supportDays);
  const cancelSupport = new Date(2000, 1, 1);

  const onSupportClick = async (newDate: Date) => {
    // No cqrs in BE for this, we can refetch right away for this specific call
    await updateSupportSettings({ variables: { date: newDate.toISOString() } });
    refetch();
  };

  const userMessage = enabled
    ? t('Access will be automatically revoked on') + ': ' + dateString
    : t('No support access granted');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 1,
      }}
    >
      <Box sx={{ pl: 1 }}>
        <Box sx={{ mb: 1 }}>
          <Text display="inline">
            {t(
              'By giving the Support team access to your account, they can better support you with any problems or questions. When you grant access, the Support team can log into your Prostream account and see the same overviews and perform the same actions as you normally can. You can revoke this access at any time.'
            )}
          </Text>
        </Box>
        <Text display="inline">{userMessage}</Text>
      </Box>
      <Box>
        <Footer pb={3} pt={2} px={3}>
          <Button
            disabled={!enabled}
            onClick={() => onSupportClick(cancelSupport)}
          >
            {t('Revoke')}
          </Button>
          <Button
            onClick={() => onSupportClick(grantSupport)}
            type="submit"
            variant="contained"
          >
            {t('Grant access')}
          </Button>
        </Footer>
      </Box>
    </Box>
  );
};
