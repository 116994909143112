import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { QualityControlInstance } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { QCTaskResourcesProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

const QCTaskResources = ({ instance }: QCTaskResourcesProps) => {
  const { t } = useTranslation();

  const numInstancesFromTemplate = (templateName: string) =>
    instance.linkedSnagInstances?.filter(
      (snag: QualityControlInstance) => snag.name === templateName
    ).length;

  return (
    <>
      <Instance.Item>
        <Instance.Label>{t('Forms')}</Instance.Label>
        <Instance.ValueList>
          {instance.linkedFormInstances?.map((template) => (
            <Instance.Value key={uuid()}>{template.name}</Instance.Value>
          ))}
        </Instance.ValueList>
      </Instance.Item>

      <Instance.Item>
        <Instance.Label>{t('Available Forms')}</Instance.Label>
        <Instance.ValueList>
          {instance.formTemplates?.map((template) => (
            <Instance.Value key={uuid()}>{template.name}</Instance.Value>
          ))}
        </Instance.ValueList>
      </Instance.Item>

      <Instance.Item>
        <Instance.Label>{t('Snags')}</Instance.Label>
        <Instance.ValueList>
          {instance.snagTemplates?.map((template, index) => (
            <Instance.Value
              display="flex"
              justifyContent="space-between"
              key={uuid()}
              width={190}
            >
              <Box>{template.name}</Box>
              <Box>{numInstancesFromTemplate(template.name ?? '')}</Box>
            </Instance.Value>
          ))}
        </Instance.ValueList>
      </Instance.Item>
      <Instance.Item>
        <Instance.Label>{t('Drawings')}</Instance.Label>
        <Instance.ValueList>
          {instance.visualContexts?.map((template) => (
            <Instance.Value key={uuid()}>{template.name}</Instance.Value>
          ))}
        </Instance.ValueList>
      </Instance.Item>
    </>
  );
};

export default QCTaskResources;
