import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteReportConfigurations } from '../hooks/useDeleteReportConfigurations';

export const ReportConfigurationsActionBar = () => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();
  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');
  const deleteReportConfigurations = useDeleteReportConfigurations();

  const disableButton = projectId
    ? userCannotUpdateOrg && userCannotUpdateProject
    : userCannotUpdateOrg;

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t(`New report configuration`),
      dataTestId: `new-report-configuration`,
      disabled: disableButton,
      key: `new-report-configuration`,
      label: t(`New report configuration`),
      onClick: () => {
        searchParams.delete('id');
        searchParams.set('action', 'createReportConfiguration');
      },
      startIcon: 'add',
    },
  ];

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: `delete-report-configuration`,
      disabled: disableButton,
      key: `delete-report-configuration`,
      label: t('Delete'),
      onClick: () => deleteReportConfigurations(),
      startIcon: 'delete',
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="report-configurations-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
