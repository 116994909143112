import {
  ResolveTaskLinks,
  Task,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';
import {
  isTbqBrandcheck,
  isTbqRie,
  splitUniqQuestionId,
} from '@pro4all/workflow/ui/utils';

export const useTBQCalculateCurrentQuestionResolveTasks = (
  tasks: Task[],
  currentQuestion: TbqAnswer | TbqRieResult,
  scanId: string
) => {
  if (!currentQuestion) {
    return [];
  }

  const filterTasks = (task: Task) => {
    const linkedQuestions: ResolveTaskLinks[] = task.tbqLinkedQuestions || [];

    let questionSectionId = '';
    let questionScanId = '';

    if (isTbqBrandcheck(currentQuestion)) {
      questionSectionId = currentQuestion.sectionId;
      questionScanId = scanId ?? '';
    } else if (isTbqRie(currentQuestion)) {
      questionSectionId = currentQuestion?.section.id;
      questionScanId = currentQuestion?.taskId ?? '';
    }

    const matchQuestion = linkedQuestions.filter((question) => {
      const questionId = question?.id ?? '';
      const relevantQuestionId = splitUniqQuestionId(questionId);

      const isAppDataMatch =
        question.appData && question.appData === questionSectionId;
      const isTaskIdMatch = relevantQuestionId === questionScanId;

      return isAppDataMatch && isTaskIdMatch;
    });

    return matchQuestion.length > 0;
  };

  return tasks.filter(filterTasks);
};
