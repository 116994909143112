import { ComposedPin } from '@pro4all/shared/composed-snag-form-pin';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

import { SearchOption } from './SearchBar';
import { CustomIconContainer } from './SearchBar.styles';
interface Props {
  iconName?: IconName;
  isSelectIconEnabled: boolean;
  searchOption: SearchOption;
  showIcon: boolean;
}

export const SearchBarIcon: React.FC<Props> = ({
  iconName,
  isSelectIconEnabled,
  searchOption,
  showIcon,
}) => {
  if (!showIcon || !iconName) return null;

  if ((iconName === 'form' || iconName === 'snag') && isSelectIconEnabled) {
    return (
      <CustomIconContainer>
        <ComposedPin
          currentFile={searchOption?.currentFile}
          customColor={searchOption?.indicateStateColor}
          left={!searchOption?.currentFile ? 6.9 : undefined}
          selectedIconName={searchOption?.customIcon}
          simpleIcon
          top={0.5}
          type={iconName}
        />
      </CustomIconContainer>
    );
  }

  return <Icon iconName={iconName} />;
};
