import { Trans, useTranslation } from 'react-i18next';

import { Loader } from '@pro4all/shared/ui/general';
import { BigMessage } from '@pro4all/shared/ui/messages';

interface OsidEmptyRenderProps {
  isLinkedDevice: boolean;
  noOsidDevices?: boolean;
  osidDeviceLogLoading: boolean;
}

export const OsidEmptyRenderer: React.FC<OsidEmptyRenderProps> = ({
  osidDeviceLogLoading,
  noOsidDevices,
  isLinkedDevice,
}) => {
  const { t } = useTranslation();

  if (osidDeviceLogLoading) {
    return <Loader />;
  }

  if (noOsidDevices) {
    return (
      <BigMessage
        shapeName="flow1"
        tbq
        text={
          <Trans
            components={[
              <a
                href="https://www.tbq.nl/contact/"
                rel="noopener noreferrer"
                target="_blank"
              >
                TBQ
              </a>,
            ]}
            i18nKey="To get started, please contact <0>TBQ</0>."
          />
        }
        title={t('There are no devices available.')}
      />
    );
  }

  if (!isLinkedDevice) {
    return (
      <BigMessage
        shapeName="flow1"
        tbq
        text={t(
          'To get started, please select a device and link it to this object.'
        )}
        title={t('There is no device linked yet')}
      />
    );
  }

  return null;
};
