import { useTranslation } from 'react-i18next';

import { Text } from '@pro4all/shared/ui/typography';

import {
  BigTitleBouwbesluit,
  BigTitlePlus,
  Row,
  StyledCheckbox,
  StyledIcon,
} from './ResultsUI.styles';

interface BrandcheckHeaderSectionProps {
  isSchadeTabSelected: boolean;
  resultPlus: boolean;
  statusIcon: React.ReactNode;
  toggleResultPlus: () => void;
}

export const BrandcheckHeaderSection: React.FC<
  BrandcheckHeaderSectionProps
> = ({ resultPlus, isSchadeTabSelected, statusIcon, toggleResultPlus }) => {
  const { t } = useTranslation();

  return (
    <Row alignItems="center" gap={1}>
      {resultPlus ? (
        <>
          <BigTitlePlus variant="h2">
            {isSchadeTabSelected ? t('Eigen schade') : t('Beter dan Bbl')}
          </BigTitlePlus>
          {statusIcon}
        </>
      ) : (
        <>
          <BigTitleBouwbesluit variant="h2">
            {isSchadeTabSelected ? t('Schade aan derden') : t('Bbl')}
          </BigTitleBouwbesluit>
          {statusIcon}
        </>
      )}
      <StyledCheckbox
        checked={resultPlus}
        checkedIcon={
          <StyledIcon
            iconName="reset"
            label={<Text>{t('Change ambition')}</Text>}
          />
        }
        disableRipple
        icon={
          <StyledIcon
            iconName="reset"
            label={<Text>{t('Change ambition')}</Text>}
          />
        }
        onChange={toggleResultPlus}
      />
    </Row>
  );
};
