import React from 'react';

import { StyledProgress } from '../form/FormStyles';

type UploadProgressProps = {
  progress: number;
  showProgress: boolean;
};

export const UploadProgress: React.FC<UploadProgressProps> = ({
  progress,
  showProgress,
}) => (
  <StyledProgress
    $visible={showProgress}
    color="secondary"
    data-testid="upload-progress"
    value={progress * 100}
    variant="determinate"
  />
);
