import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const FieldsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(2)} 0;
  height: 100%;
  background-color: ${customColors.white};
`;

export const GlobalFields = styled.div`
  overflow: auto;
  height: 100vh;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  max-height: 200px;
`;

export const CustomFields = styled.div`
  overflow: auto;
  height: 100vh;
`;

export const Sticked = styled.div`
  position: sticky;
  top: 0;
  background-color: ${customColors.white};
`;

export const SearchBox = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const Label = styled(Text)`
  && {
    padding: ${({ theme }) => theme.spacing(1)}
      ${({ theme }) => theme.spacing(2)};
    padding-top: 0;
  }
`;
