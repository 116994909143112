import React, { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
  waitBeforeShow?: number;
};

let locky = 0;

// Component to debug. This component will limit rendering by delaying the renders.
// e.g. when opening QCS form, loads of components are rendered at once. If you wrap section component:  <Delayed><Section></Delayed>, then only 1 section will render/update at the same time.
// So instead of freezing the page, the components will render one by one untill whole form is rendered.
export const Delayed: React.FC<Props> = ({ children, waitBeforeShow = 50 }) => {
  const [isShown, setIsShown] = useState(false);
  const [delayCount, setDelayCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (locky < 1) {
        locky++;
        setIsShown(true);
        setTimeout(() => {
          locky--;
        }, 0);
      } else {
        setDelayCount(delayCount + 1);
      }
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [delayCount, waitBeforeShow]);

  return isShown ? <>{children}</> : <>Loading {delayCount}</>;
};
