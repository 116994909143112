import { unflattenFolders } from '../helpers/unflattenFolders';
import { DMSItem, State } from '../types';

export const createDMSItemAction = ({
  payload,
  state,
}: {
  payload: DMSItem;
  state: State;
}) => {
  if (!payload.parentFolderId) {
    // Create root folder.
    const foldersUpdated = [...state.folders, payload];
    return {
      ...state,
      folderTree: unflattenFolders(foldersUpdated),
      folders: foldersUpdated,
    };
  } else {
    // Create subfolder.
    const parentFolder = state.folders.find(
      (folder) => folder.id === payload.parentFolderId
    );
    const currentFoldersExceptParentFolder = state.folders.filter(
      (folder) => folder.id !== payload.parentFolderId
    );
    const foldersUpdated = [
      ...currentFoldersExceptParentFolder,
      { ...parentFolder, hasSubFolders: true },
      payload,
    ];

    return {
      ...state,
      folderTree: unflattenFolders(foldersUpdated),
      folders: foldersUpdated,
    };
  }
};
