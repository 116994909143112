import React from 'react';
import { useTranslation } from 'react-i18next';

import { sortBy } from '@pro4all/shared/utils';

import type { Section } from '../utils';

import { TBQField } from './TBQField';
import * as Styled from './TBQSection.styles';

type Props = Section & { depth?: number };

export const TBQSection = ({ depth = 0, fields, name }: Props) => {
  const { t } = useTranslation();
  return (
    <Styled.Section>
      <Styled.Heading $depth={depth}>{t(`TBQ.${name}`)}</Styled.Heading>
      <Styled.Fields $depth={depth}>
        {[...fields].sort(sortBy({ key: 'name' })).map((field) => (
          <TBQField key={field.name} {...field} depth={depth + 1} />
        ))}
      </Styled.Fields>
    </Styled.Section>
  );
};
