import { useTranslation } from 'react-i18next';

import { TaskType } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

export const useCreateDocumentTaskAction = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const createDocumentTaskAction: ActionProps = {
    ariaLabel: t('Create document task'),
    dataTestId: 'create-document-task',
    disabled: false,
    key: 'create-document-task',
    label: t('Create document task'),
    onClick: () => {
      goTo({
        searchParams: {
          action: 'createTask',
          taskType: TaskType.Document,
        },
      });
    },
    startIcon: 'expectedDocument',
  };
  return createDocumentTaskAction;
};
