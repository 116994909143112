import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DocumentHistoryProperty,
  DocumentPropertyType,
  QcHistoryProperty,
  QcPropertyType,
} from '@pro4all/graphql';
import { Property, PropertyList } from '@pro4all/shared/ui/general';

import { EventProperty } from './EventProperty';
import * as Styled from './EventProperty.styles';

const HIDDEN_DOCUMENT_PROPERTIES = [DocumentPropertyType.LinkId];
const HIDDEN_QC_PROPERTIES = [
  'MetadataInstanceId',
  'TemplateId',
  'TemplateVersion',
  'ResourceType',
  'ResourceId',
  'ProjectId',
];

export const EventPropertyList: React.FC<{
  properties: (DocumentHistoryProperty | QcHistoryProperty)[];
}> = ({ properties }) => {
  const { t } = useTranslation();
  const hideProperty = (
    type: DocumentPropertyType | QcPropertyType,
    name: string,
    __typename: string
  ) => {
    if (__typename === 'DocumentHistoryProperty')
      return HIDDEN_DOCUMENT_PROPERTIES.includes(type as DocumentPropertyType);
    else return HIDDEN_QC_PROPERTIES.includes(name);
  };
  const items: Property[] =
    properties
      ?.filter(
        ({ type, __typename, name }) =>
          type && __typename && name && !hideProperty(type, name, __typename)
      )
      ?.map((property, key) => ({
        id: `event-property-${key}`,
        label: t(`Api.propertyNames.${property.name}`, {
          defaultValue: property.name,
        }),
        value:
          // Don't render fields when there are no values
          property?.addedResolvedValues?.length ||
          property?.deletedResolvedValues?.length ? (
            <Styled.PropertyValues>
              {property?.addedResolvedValues?.map(
                (addedValue, key) =>
                  // Filter out duplicates in values
                  !property?.deletedValues?.some(
                    (deletedValue) => deletedValue === addedValue
                  ) && (
                    <EventProperty
                      key={key}
                      type={property?.type}
                      value={addedValue}
                    />
                  )
              )}

              {property?.deletedResolvedValues?.map((deletedValue, key) => (
                <EventProperty
                  color="default"
                  key={key}
                  type={property?.type}
                  value={deletedValue}
                />
              ))}
            </Styled.PropertyValues>
          ) : null,
      }))
      .filter((property) => Boolean(property.value)) || [];

  return <PropertyList items={items} orientation="horizontal" />;
};
