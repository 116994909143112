import {
  SavedAnswersInstance,
  useSavedAnswersInstanceIncludeQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetSavedAnswersInstance = () => {
  const { searchParams } = useRouting();

  const isEditing = searchParams.is('action', 'editSavedAnswer');
  const isViewing = searchParams.is('action', 'viewSavedAnswer');
  const id = searchParams.get('id');

  const { data } = useSavedAnswersInstanceIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isEditing && !isViewing,
    variables: {
      id: id || '',
      includeItems: true,
    },
  });

  return data?.savedAnswersInstance as SavedAnswersInstance;
};
