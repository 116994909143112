import React, { useEffect, useRef, useState } from 'react';

import { Collapse } from '@pro4all/shared/mui-wrappers';

import * as Styled from './Collapsible.styles';

export interface Props {
  component?: React.ElementType;
  duration?: number;
  id?: string;
  open?: boolean;
  orientation?: 'horizontal' | 'vertical';
}

export const Collapsible: React.FC<Props> = ({
  children,
  component,
  duration,
  open,
  orientation = 'vertical',
}) => {
  const timeout = duration || 250;

  const [childsVisible, setChildsVisible] = useState(open);
  const [collapseOpen, setCollapseOpen] = useState(open);
  const inner = useRef(null);

  useEffect(() => {
    let timeout1: number;
    let timeout2: number;

    if (!open) {
      timeout1 = window.setTimeout(() => {
        setChildsVisible(false);
      }, timeout);
      setCollapseOpen(false);
    } else {
      setChildsVisible(true);
      timeout2 = window.setTimeout(() => {
        setCollapseOpen(true);
      }, 1);
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [open]);

  return (
    <Collapse in={collapseOpen} orientation={orientation} timeout={timeout}>
      <Styled.Inner as={component} ref={inner}>
        {childsVisible ? children : <></>}
      </Styled.Inner>
    </Collapse>
  );
};
