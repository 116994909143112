import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

import { useFilters } from './utils';

export const FilterBreadcrumb = ({ metaDataKey }: { metaDataKey?: string }) => {
  const { currentFilterSection } = useFilters();

  const fieldSetion = currentFilterSection?.find(
    (item) => item.type === metaDataKey
  );

  if (!fieldSetion?.value) return null;

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(1),
        paddingTop: (theme) => theme.spacing(1.5),
      }}
    >
      <Text variant="h6">{fieldSetion?.value}</Text>
    </Box>
  );
};
