import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useGenericContext } from '@pro4all/shared/providers';
import { Checkbox, Switch } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

const QCVisibilityToggle = () => {
  const { t } = useTranslation();

  const {
    state: { showQCGroupPermissions, showHighestQCPermission },
    toggleShowQCGroupPermissions,
    toggleShowHighestQCPermission,
  } = useGenericContext();

  const settingShowQCGroupPermissions = () => {
    toggleShowQCGroupPermissions();
  };

  const checkShowHighestQCPermissions = () => {
    if (showQCGroupPermissions && !showHighestQCPermission) {
      toggleShowQCGroupPermissions();
    }
    toggleShowHighestQCPermission();
  };

  return (
    <Box display="flex">
      <Box alignItems="center" display="flex">
        <Switch
          checked={showQCGroupPermissions}
          disabled={showHighestQCPermission}
          name="show-group-permissions"
          onChange={() => settingShowQCGroupPermissions()}
        />
        <Text variant="body1">{t('Show permission via group(s)')}</Text>
      </Box>
      <Box alignItems="center" display="flex">
        <Checkbox
          checked={showHighestQCPermission}
          onChange={checkShowHighestQCPermissions}
        />
        <Text variant="body1">{t('Show highest permission')}</Text>
      </Box>
    </Box>
  );
};

export default QCVisibilityToggle;
