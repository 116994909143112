import { useTranslation } from 'react-i18next';

import { FolderPermission } from '@pro4all/graphql';
import { IconHeader, LabelHeader, TableCell } from '@pro4all/shared/ui/general';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { TreeFolder } from '../../tree/types';
import { BreakInheritanceForFolderSwitch } from '../BreakInheritanceForFolderSwitch';
import { ExclusivePermissionFolderSelectorWrapper } from '../ExclusivePermissionFolderSelectorWrapper';
import { PermissionCellFolderWrapper } from '../PermissionCellFolderWrapper';
import { EXCLUDED_PERMISSIONS, PermissionHeader } from '../PermissionHeader';
import { SelectAllPermissionsForFolderButton } from '../SelectAllPermissionsForFolderButton';
import { ExclusivePermissionType } from '../types/types';

export const useFolderPermissionColumns = () => {
  const { t } = useTranslation();

  return [
    {
      cellRenderer: TableCell,
      dataKey: 'entityFolderPermissions',
      headerRenderer: () => (
        <LabelHeader label="Documents" /> // i18n
      ),
      key: 'entityFolderPermissions.canView',
      render: (row: TreeFolder) => (
        <ExclusivePermissionFolderSelectorWrapper
          currentPermissions={row.entityFolderPermissions?.[0]}
          exclusivePermissionType={ExclusivePermissionType.Documents}
          folder={row}
        />
      ),
      width: 120,
    },
    {
      cellRenderer: TableCell,
      dataKey: 'entityFolderPermissions',
      headerRenderer: () => (
        <LabelHeader label="Versions" /> // i18n
      ),
      key: 'entityFolderPermissions.canViewVersions',
      render: (row: TreeFolder) => (
        <ExclusivePermissionFolderSelectorWrapper
          currentPermissions={row.entityFolderPermissions?.[0]}
          exclusivePermissionType={ExclusivePermissionType.Versions}
          folder={row}
        />
      ),
      width: 120,
    },
    {
      cellRenderer: TableCell,
      dataKey: 'entityFolderPermissions',
      headerRenderer: () => (
        <LabelHeader label="Delete" /> // i18n
      ),
      key: 'entityFolderPermissions.canDeleteDocuments',
      render: (row: TreeFolder) => (
        <ExclusivePermissionFolderSelectorWrapper
          currentPermissions={row.entityFolderPermissions?.[0]}
          exclusivePermissionType={ExclusivePermissionType.Delete}
          folder={row}
        />
      ),
      width: 120,
    },
    {
      cellRenderer: TableCell,
      dataKey: 'entityFolderPermissions',
      headerRenderer: () => (
        <LabelHeader label="Update" /> // i18n
      ),
      key: 'entityFolderPermissions.canUpdateDocuments',
      render: (row: TreeFolder) => (
        <ExclusivePermissionFolderSelectorWrapper
          currentPermissions={row.entityFolderPermissions?.[0]}
          exclusivePermissionType={ExclusivePermissionType.Update}
          folder={row}
        />
      ),
      width: 120,
    },
    ...Object.values(FolderPermission)
      .filter(
        (permission: FolderPermission) =>
          !EXCLUDED_PERMISSIONS.includes(permission)
      )
      .map((permission: FolderPermission) => ({
        cellRenderer: TableCell,
        dataKey: 'entityFolderPermissions',
        headerRenderer: () => <PermissionHeader permission={permission} />,
        key: `entityFolderPermissions.${permission}`,
        render: (row: TreeFolder) => (
          <PermissionCellFolderWrapper
            folder={row}
            permission={permission}
            userId={row.entityFolderPermissions?.[0].id}
          />
        ),
        width: 76,
      })),
    {
      cellRenderer: TableCell,
      dataKey: 'entityFolderPermissions',
      headerComponent: <div></div>,
      key: 'entityFolderPermissions.selectAll',
      render: (row: TreeFolder) => (
        <SelectAllPermissionsForFolderButton
          currentPermissions={row.entityFolderPermissions?.[0]}
          folderId={row.id}
          projectId={row.projectId}
        />
      ),
      width: 50,
    },
    {
      cellRenderer: TableCell,
      dataKey: 'entityFolderPermissions',
      headerRenderer: () => (
        <Tooltip placement="bottom" title={t('Break permission inheritance')}>
          <IconHeader color="primary" iconName="breakInheritance" />
        </Tooltip>
      ),
      key: `entityFolderPermissions.breakInheritance`,
      render: (row: TreeFolder) =>
        row.parentFolderId ? (
          <BreakInheritanceForFolderSwitch
            folderId={row.id}
            userId={row.entityFolderPermissions?.[0].id}
          />
        ) : null,
      width: 64,
    },
  ];
};
