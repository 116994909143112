import React, { useEffect } from 'react';

import {
  FieldDefinition,
  Template,
  useStandardFieldDefinitionsQuery,
  useTemplateItemsQuery,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { Divider } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, ContentRow, Main } from '@pro4all/shared/ui/general';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { sortBy } from '@pro4all/shared/utils';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { useFieldDefinitions } from '../hooks/useFieldDefinitions';

import { ConfigureDownloadNameForm } from './configure-download-name-form/ConfigureDownloadNameForm';
import { ConfigureDownloadNameProvider } from './configure-download-name-form/ConfigureDownloadNameProvider';
import { TemplateForm } from './templateForm/TemplateForm';
import { TemplateFieldList } from './TemplateFieldList';
import * as Styled from './TemplateFrame.styles';
import { TemplateMutationContextProvider } from './TemplateMutationContext';

export const TemplateFrame = ({
  selectedTemplate,
}: {
  selectedTemplate?: Template;
}) => {
  const { templateService } = useMetaDataContext();
  const { searchParams } = useRouting();
  const createContext = searchParams.is('action', 'createTemplate');
  const configureDownloadContext = searchParams.is('configureDownload', 'true');

  const { error, loading, fieldDefinitions } = useFieldDefinitions({
    includeOrgItems: true,
    templateService,
  });

  const { data: standardFieldDefinitions } = useStandardFieldDefinitionsQuery();

  const reusableFields =
    fieldDefinitions &&
    fieldDefinitions
      .map((fieldDefinition: FieldDefinition) => ({
        ...fieldDefinition,
        // We duplicate the id here to make it clear for TemplateForm that it is a fieldDefinitionID in this context.
        // If we update a field we also have an id, but that is the id of the actual field related to the set.
        // So to prevent confusion, we add the prop fieldDefinitionId here.
        // We leave the id in, because there are a few dependencies (f.i. row requires an id property).
        fieldDefinitionId: fieldDefinition.id,
        linksAllowed: false,
        required: false,
      }))
      .sort(sortBy({ key: 'name' }));

  const standardFieldDefs = standardFieldDefinitions
    ? standardFieldDefinitions?.standardFieldDefinitions
    : [];
  const reusableFieldsStandard =
    standardFieldDefs &&
    standardFieldDefs.map((fieldDefinition: FieldDefinition) => ({
      ...fieldDefinition,
      fieldDefinitionId: fieldDefinition.id,
      linksAllowed: false,
      required: false,
    }));

  const { data, loading: loadingTemplateItems } = useTemplateItemsQuery({
    fetchPolicy: 'no-cache',
    skip: !selectedTemplate,
    variables: { id: selectedTemplate?.id, templateService },
  });

  useEffect(() => {
    // Put the template name in sessionStorage so we also use it in the config download filename form.
    if (selectedTemplate?.name)
      sessionStorage.setItem(StorageKeys.TEMPLATE_NAME, selectedTemplate?.name);

    return () => {
      sessionStorage.removeItem(StorageKeys.TEMPLATE_NAME);
    };
  }, [selectedTemplate?.name]);

  return (
    <ResponseWrapper
      error={error}
      isLoading={(!fieldDefinitions && loading) || loadingTemplateItems}
    >
      <ConfigureDownloadNameProvider>
        <TemplateMutationContextProvider
          fieldDefinitionsInitial={data?.templateItems ?? []}
          reusableFields={reusableFields}
          reusableFieldsStandard={reusableFieldsStandard}
        >
          {configureDownloadContext ? (
            <ConfigureDownloadNameForm selectedTemplate={selectedTemplate} />
          ) : (
            ((!createContext && selectedTemplate && data) || createContext) &&
            fieldDefinitions && (
              <Main>
                <ContentRow>
                  <Styled.StyledColumn>
                    <TemplateFieldList />
                  </Styled.StyledColumn>
                  <Divider orientation="vertical" />

                  <Column flex="1" pt={1}>
                    <TemplateForm
                      selectedTemplate={{
                        ...selectedTemplate,
                        items: data?.templateItems as FieldDefinition[],
                      }}
                    />
                  </Column>
                </ContentRow>
              </Main>
            )
          )}
        </TemplateMutationContextProvider>
      </ConfigureDownloadNameProvider>
    </ResponseWrapper>
  );
};
