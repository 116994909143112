import { changeItem } from '../helpers/changeItem';
import { getChildIds } from '../helpers/getChildIds';
import { getLastSibling } from '../helpers/getLastSibling';
import { MoveItemPayload } from '../hierarchyEditorReducer';
import { AddType, BasePropsHierarchyItem, ItemProps } from '../types';

export const moveItemAction = <HierarchyItem extends BasePropsHierarchyItem>({
  addType,
  appendAfterTarget = false,
  allItems,
  sourceId,
  targetId,
}: Pick<ItemProps<HierarchyItem>, 'allItems'> & MoveItemPayload) => {
  const sourceItem = allItems.find((item) => item.id === sourceId);
  const targetItem = allItems.find((item) => item.id === targetId);

  // If the sourceId or targetId does not exist do not change anything.
  if (!sourceItem || !targetItem) {
    return allItems;
  }

  // If the sourceId is equal to the targetId do not change anything.
  if (sourceItem?.id === targetItem?.id) {
    return allItems;
  }

  // If the dragged item is dropped on one of its children do not change anything.
  const childIds = getChildIds<HierarchyItem>({ allItems, id: sourceId });
  if (childIds.includes(targetId)) {
    return allItems;
  }

  let updatedItems = allItems;

  if (addType === AddType.SIBLING) {
    // SourceItem moved and added as a predecessor of the target item or a succesor in case appendAfterTarget = true.

    if (sourceItem?.id === targetItem?.previousNodeId) {
      // If the sourceId is equal to the previousNodeId of targetItem do not change anything.
      return allItems;
    }

    // Change the item that is currently the successor of sourceItem.
    updatedItems = updatePreviousSuccessorItem<HierarchyItem>({
      allItems: updatedItems,
      sourceItem,
    });

    if (!targetItem.previousNodeId) {
      // a. in front of the current first item.

      if (appendAfterTarget) {
        updatedItems = insertAfterTarget<HierarchyItem>({
          allItems: updatedItems,
          sourceItem,
          targetItem,
        });
      } else {
        // sourceItem becomes the first item.
        updatedItems = changeItem<HierarchyItem>({
          allItems: updatedItems,
          item: {
            ...sourceItem,
            previousNodeId: null,
          },
        });

        // targetItem becomes the second item.
        updatedItems = changeItem<HierarchyItem>({
          allItems: updatedItems,
          item: {
            ...targetItem,
            previousNodeId: sourceItem.id,
          },
        });
      }
    } else {
      // b. in front of the current second or further item.

      if (appendAfterTarget) {
        updatedItems = insertAfterTarget<HierarchyItem>({
          allItems: updatedItems,
          sourceItem,
          targetItem,
        });
      } else {
        // sourceItem becomes the successor of item that of which targetItem previously was the successor of.
        updatedItems = changeItem<HierarchyItem>({
          allItems: updatedItems,
          item: {
            ...sourceItem,
            previousNodeId: targetItem.previousNodeId,
          },
        });

        // targetItem becomes the successor of sourceItem > previousNodeId = sourceItem.id.
        updatedItems = changeItem<HierarchyItem>({
          allItems: updatedItems,
          item: {
            ...targetItem,
            previousNodeId: sourceItem.id,
          },
        });
      }
    }

    if (sourceItem.parentNodeId !== targetItem.parentNodeId) {
      // sourceItem is moved to a different parent or from a parent to the root.

      const sourceItem = updatedItems.find(
        (item) => item.id === sourceId
      ) as HierarchyItem; // Source item might have changed.

      // sourceItem gets the same parentNodeId as the targetItem.
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...sourceItem,
          parentNodeId: targetItem.parentNodeId,
        },
      });
    }
  } else if (addType === AddType.CHILD) {
    // SourceItem moved and added as a child of the target item.

    // Change the item that is currently the successor of sourceItem.
    updatedItems = updatePreviousSuccessorItem<HierarchyItem>({
      allItems: updatedItems,
      sourceItem,
    });

    // Find all direct current child items of the target item.
    const childItemsTarget = updatedItems.filter(
      (item) => item.parentNodeId === targetItem.id
    );

    if (appendAfterTarget) {
      // sourceItem becomes the last child of targetItem.
      const lastChildItem = getLastSibling({ items: childItemsTarget });
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...sourceItem,
          parentNodeId: targetItem.id,
          previousNodeId: lastChildItem?.id || null,
        },
      });
    } else {
      // sourceItem becomes the first child of targetItem.
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...sourceItem,
          parentNodeId: targetItem.id,
          previousNodeId: null,
        },
      });

      // Check if there is an item that previously acted as the first child.
      // The source item will become the first child and the previous first child will become the second child.
      if (childItemsTarget.length > 0) {
        const previousFirstItem = childItemsTarget.find(
          (item) => item.previousNodeId === null
        );
        if (previousFirstItem) {
          updatedItems = changeItem<HierarchyItem>({
            allItems: updatedItems,
            item: {
              ...previousFirstItem,
              previousNodeId: sourceItem.id,
            },
          });
        }
      }
    }
  }

  return updatedItems;
};

const insertAfterTarget = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  sourceItem,
  targetItem,
}: Pick<
  ItemProps<HierarchyItem>,
  'allItems' | 'sourceItem' | 'targetItem'
>) => {
  let updatedItems = allItems;

  const sourceItemUpdated = updatedItems.find(
    (item) => item.id === sourceItem.id
  ) as HierarchyItem;

  // sourceItem becomes the successor of target item.
  updatedItems = changeItem<HierarchyItem>({
    allItems: updatedItems,
    item: {
      ...sourceItemUpdated,
      level: targetItem.level,
      parentNodeId: targetItem.parentNodeId,
      previousNodeId: targetItem.id,
    },
  });

  // sourceItem becomes the predecessor of the previous item that acted as the successor of targetItem.
  const previousSuccessorItem = allItems.find(
    (item) => item.previousNodeId === targetItem.id
  );
  if (previousSuccessorItem) {
    updatedItems = changeItem<HierarchyItem>({
      allItems: updatedItems,
      item: {
        ...previousSuccessorItem,
        previousNodeId: sourceItem.id,
      },
    });
  }
  return updatedItems;
};

const updatePreviousSuccessorItem = <
  HierarchyItem extends BasePropsHierarchyItem
>({
  allItems,
  sourceItem,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'sourceItem'>) => {
  let updatedItems = allItems;

  const previousSuccessorSourceItem = allItems.find(
    (item) => item.previousNodeId === sourceItem.id
  );

  if (previousSuccessorSourceItem) {
    if (sourceItem.previousNodeId) {
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...previousSuccessorSourceItem,
          previousNodeId: sourceItem.previousNodeId
            ? sourceItem.previousNodeId // Previous successor of sourceItem must be connected to the previousNodeId of the source item.
            : null, // Previous successor of sourceItem becomes the first item.
        },
      });
    } else {
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...previousSuccessorSourceItem,
          previousNodeId: null,
        },
      });
    }
  }

  return updatedItems;
};
