import { BasePropsHierarchyItem, ItemProps } from '../types';

export const getChildIds = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  id,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'id'>) => {
  const childIds = [] as string[];
  const childItems = allItems.filter((item) => item.parentNodeId === id);
  childItems.forEach((childItem) => {
    childIds.push(childItem.id);
    childIds.push(
      ...getChildIds<HierarchyItem>({ allItems, id: childItem.id })
    );
  });
  return childIds;
};
