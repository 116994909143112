import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FieldDefinition,
  TemplateService,
  useTemplateItemsQuery,
} from '@pro4all/graphql';
import { AnswerEditContextProvider } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { useCreateSavedAnswersContext } from './CreateSavedAnswersContext';
import { SavedAnswersForm } from './SavedAnswersForm';
import { useGetSavedAnswersInstance } from './useGetSavedAnswersInstance';
import { useSavedAnswersActions } from './useSavedAnswersActions';

export const SavedAnswersSidebar = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const routeCreate = searchParams.is('action', 'createSavedAnswer');
  const routeEdit = searchParams.is('action', 'editSavedAnswer');
  const routeView = searchParams.is('action', 'viewSavedAnswer');
  const isSidebarOpen = routeCreate || routeEdit || routeView;
  const selectedSavedAnswersInstance = useGetSavedAnswersInstance();
  const sidebarActions = useSavedAnswersActions({
    selectedSavedAnswersInstance,
  });
  const { selectedTemplate } = useCreateSavedAnswersContext();

  const { data } = useTemplateItemsQuery({
    fetchPolicy: 'no-cache',
    skip: !routeCreate,
    variables: {
      id: selectedTemplate?.id || '',
      templateService: TemplateService.QualityControl,
    },
  });

  const closeSidebar = () => {
    searchParams.clear();
  };

  return (
    <Sidebar onClose={closeSidebar} open={isSidebarOpen} wide>
      <Sidebar.Header
        title={
          routeCreate
            ? t('New saved answers')
            : selectedSavedAnswersInstance?.name
        }
      >
        <Sidebar.SidebarActionBar sidebarActions={sidebarActions || []} />
      </Sidebar.Header>
      <AnswerEditContextProvider>
        <SavedAnswersForm
          edit={routeCreate || routeEdit}
          items={
            routeCreate
              ? (data?.templateItems as FieldDefinition[])
              : selectedSavedAnswersInstance?.items
          }
          name={selectedSavedAnswersInstance?.name}
          onClose={closeSidebar}
        />
      </AnswerEditContextProvider>
    </Sidebar>
  );
};
