export const FOLDER_TREE_CLIPBOARD = 'FOLDER_TREE_CLIPBOARD';

interface FolderInfo {
  folderId?: string;
  folderName?: string;
  projectId?: string;
}

export function saveFolderTreeStructure(folderInfo: FolderInfo) {
  sessionStorage.setItem(FOLDER_TREE_CLIPBOARD, JSON.stringify(folderInfo));
}

export function getFolderTreeStructure(): FolderInfo {
  const rawFolderInfo = sessionStorage.getItem(FOLDER_TREE_CLIPBOARD);
  return (
    (JSON.parse(rawFolderInfo ?? '') as FolderInfo) ?? {
      folderId: null,
      folderName: null,
      projectId: null,
    }
  );
}
export function isSomethingInClipboard(): boolean {
  return Boolean(sessionStorage.getItem(FOLDER_TREE_CLIPBOARD));
}

export function removeFolderFromClipboard() {
  sessionStorage.removeItem(FOLDER_TREE_CLIPBOARD);
}
