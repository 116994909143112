import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { InheritedPermission } from '@pro4all/graphql';
import { Box, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  getInheritedPermissionsHierarchy,
  getPermissionName,
  getPermissionValue,
  hierarchy,
} from '@pro4all/shared/permissions';
import { useGenericContext } from '@pro4all/shared/providers';
import { Select } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { StyledTooltip } from '@pro4all/shared/ui/side-navigation';
import { Text } from '@pro4all/shared/ui/typography';

import { QCPermissionsFormValue } from '../PermissionsMatrix';
import {
  CurrentPermissionText,
  IconDropdownBox,
  MatrixTableCell,
  MatrixTrRow,
  TextDropdownContainer,
} from '../PermissionsMatrix.styled';
import { PermissionCategory, permissionIconMap } from '../utils';
interface Props {
  category: PermissionCategory;
  handleChange: ({ name, value }: { name: string; value: string }) => void;
  inheritedPermissions: InheritedPermission[];
  isAdmin: boolean;
  targetIsExplicitProjectMember: boolean;
  values: QCPermissionsFormValue;
}

const MatrixTableRow = ({
  category,
  values,
  handleChange,
  isAdmin,
  inheritedPermissions = [],
  targetIsExplicitProjectMember,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    state: { showQCGroupPermissions, showHighestQCPermission },
  } = useGenericContext();

  const getPermissionDescription = ({ formValues, action }: any) => {
    const currentPermission = formValues[action.permissionBase];
    return (
      action?.values?.find((value: any) => value.id === currentPermission)
        ?.label || ''
    );
  };

  const getHighestGroupPermissionIcon = (permissionBase: string) => {
    const hierarchyValues = getInheritedPermissionsHierarchy(
      permissionBase.replace(/-/g, '.'),
      inheritedPermissions
    );
    const highestGroupPermissionValue = hierarchy[Math.max(...hierarchyValues)];
    return permissionIconMap[highestGroupPermissionValue] ?? 'groupOff';
  };

  const generateGroupTooltipText = (permBase: string) => (
    <Box>
      <Box mb={1}>{t('Permissions via group(s)')}:</Box>
      {inheritedPermissions.length > 0
        ? inheritedPermissions?.map((inherited) => (
            <Box key={uuid()}>
              {inherited.groupName}:{' '}
              {t(
                `${getPermissionValue(
                  inherited.permissions.find(
                    (inh) =>
                      getPermissionName(inh) === permBase.replace(/-/g, '.')
                  )
                )}`
              )}
            </Box>
          ))
        : t('This user is not part of a group')}
    </Box>
  );

  return (
    <MatrixTrRow>
      <MatrixTableCell>
        <Box fontSize="13px">
          <Text variant="h5">{t(`${category.name}`)}</Text>
          {t(category.description)}
        </Box>
      </MatrixTableCell>
      {category?.permissions?.map((action) => (
        <MatrixTableCell key={uuid()}>
          <TextDropdownContainer>
            <IconDropdownBox>
              {showHighestQCPermission ? (
                <Icon
                  fontSize="large"
                  htmlColor={theme.palette.grey[700]}
                  iconName={
                    permissionIconMap[
                      getPermissionValue(
                        values[action.permissionBase].replace(/-/g, '.')
                      )
                    ]
                  }
                />
              ) : (
                <StyledTooltip
                  title={
                    !targetIsExplicitProjectMember
                      ? 'You can currently not change personal permissions for users that are part of the project via a group.'
                      : ''
                  }
                >
                  <Select
                    disabled={!isAdmin || !targetIsExplicitProjectMember}
                    name={action.permissionBase}
                    onChange={(e) =>
                      handleChange({ name: action.permissionBase, value: e.id })
                    }
                    options={action.values}
                    updateValue
                    value={values[action.permissionBase]}
                  />
                </StyledTooltip>
              )}
              {showQCGroupPermissions && (
                <StyledTooltip
                  title={generateGroupTooltipText(action.permissionBase)}
                >
                  <Icon
                    htmlColor={theme.palette.secondary.main}
                    iconName={getHighestGroupPermissionIcon(
                      action.permissionBase
                    )}
                  />
                </StyledTooltip>
              )}
            </IconDropdownBox>
            <CurrentPermissionText>
              {t(
                `${getPermissionDescription({
                  action: action,
                  formValues: values,
                })}`
              )}
            </CurrentPermissionText>
          </TextDropdownContainer>
        </MatrixTableCell>
      ))}
    </MatrixTrRow>
  );
};

export default MatrixTableRow;
