/* This context covers the publicly shared links overview:
 *  - Fetch data,
 *  - Show/hide revoke modal
 *  - Show/hide login dialog
 *  - Revoke link
 * */

import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  SharedLinkDataResponse,
  usePublicLinkDataQuery,
  useRevokeShareLinkMutation,
} from '@pro4all/graphql';
import { useShareLinkParams } from '@pro4all/shared/assets-sharing';
import { useRouting } from '@pro4all/shared/routing-utils';

import { ConfirmRevokeDialog } from '../feedback/ConfirmRevokeDialog';
import { LoginToRevokeDialog } from '../feedback/LoginToRevokeDialog';

interface PubliclySharedDocsContextValue {
  cancelRevoke: () => void;
  confirmRevoke: () => void;
  data: SharedLinkDataResponse;
  error: ApolloError;
  handleRevoke: () => void;
  invalidPasswordError: boolean;
  loading?: boolean;
  notFoundError: boolean;
  savedPassword: string;
  setShowLoginDialog: (value: boolean) => void;
  showLoginDialog: boolean;
  submitPassword: (value: string) => void;
  toConfirmDialog: () => void;
}

export const PubliclySharedDocsContext = createContext(null);

export const usePubliclySharedDocsContext = () =>
  useContext<PubliclySharedDocsContextValue>(PubliclySharedDocsContext);

export const PubliclySharedDocsContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { goTo, params } = useRouting();

  const { linkId, passwordProtected, signature, template } =
    useShareLinkParams();

  const userId = AuthService.getProfile()?.userId;

  const passwordFromStorage = userId ? sessionStorage.getItem(linkId) : null;

  const [savedPassword, setSavedPassword] = useState(passwordFromStorage);

  const { data, error, loading } = usePublicLinkDataQuery({
    skip:
      !linkId ||
      !signature ||
      !template ||
      (!savedPassword && passwordProtected),
    variables: { id: linkId, password: savedPassword, signature, template },
  });

  const notFoundError = Boolean(
    data?.publicLinkData.errors?.some((error) => error === 'NOT_FOUND')
  );
  const invalidPasswordError = Boolean(
    data?.publicLinkData.errors?.find((error) => error === 'INVALID_PASSWORD')
  );

  const submitPassword = (value: string) => {
    sessionStorage.setItem(linkId, value);
    setSavedPassword(value);
  };

  const [showLoginDialog, setShowLoginDialog] = useState(false);

  const [revokeLink] = useRevokeShareLinkMutation();

  const handleRevoke = () => {
    userId ? toConfirmDialog() : setShowLoginDialog(true);
  };

  const toConfirmDialog = () => {
    goTo('revokePublicLink', {
      params: { linkId: params.linkId },
      searchParams: { s: signature, t: template },
    });
  };

  const cancelRevoke = () => {
    goTo('publiclySharedDocs', {
      params: { linkId: params.linkId },
      searchParams: { s: signature, t: template },
    });
  };

  const confirmRevoke = async () => {
    try {
      sessionStorage.removeItem(linkId);

      const { data: result } = await revokeLink({
        variables: {
          id: linkId,
        },
      });

      if (result.revokeShareLink.success) {
        goTo('publiclySharedDocs', { searchParams: { revoked: true } });
      } else {
        enqueueSnackbar(
          t(
            'Link was not revoked. It is possible you do not have the required permissions. Make sure you are logged in as the creator of this link.'
          )
        );
      }
    } catch (exception) {
      enqueueSnackbar(
        t(
          'Link was not revoked. It is possible you do not have the required permissions. Make sure you are logged in as the creator of this link.'
        )
      );
    }
  };

  const value = {
    cancelRevoke,
    confirmRevoke,
    data: data?.publicLinkData,
    error,
    handleRevoke,
    invalidPasswordError,
    loading,
    notFoundError,
    savedPassword,
    setShowLoginDialog,
    showLoginDialog,
    signature,
    submitPassword,
    template,
    toConfirmDialog,
  };

  return (
    <PubliclySharedDocsContext.Provider value={value}>
      <ConfirmRevokeDialog
        cancelRevoke={cancelRevoke}
        confirmRevoke={confirmRevoke}
      />
      <LoginToRevokeDialog />
      {children}
    </PubliclySharedDocsContext.Provider>
  );
};
