import React from 'react';

import { ErrorMessage } from '@pro4all/shared/assets-sharing';

import { usePubliclySharedDocsContext } from '../publicly-shared-docs/PubliclySharedDocsContext';

export const PublicLinkError: React.FC = () => {
  const { error } = usePubliclySharedDocsContext();

  return <ErrorMessage error={error} />;
};
