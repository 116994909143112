import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'graphql/jsutils/Maybe';

import { WeekDay } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { isDefined } from '@pro4all/shared/utils';

import { ContentWrap } from '../selectTime/SelectTime.styles';

import { ErrorMessage, ListOfDays } from './SelectDays.styles';

type Props<T extends {}> = {
  enabled?: boolean;
  errors: UseFormMethods<T>['errors'];
  setValue: UseFormMethods<T>['setValue'];
  trigger: UseFormMethods<T>['trigger'];
  watch: UseFormMethods<T>['watch'];
};

type TSelectDaysFields = {
  notificationDays: Maybe<WeekDay>[];
};

export const SelectDays = ({
  setValue,
  watch,
  errors,
  trigger,
  enabled,
}: Props<TSelectDaysFields>) => {
  const { t } = useTranslation();
  const selectedDays = watch('notificationDays')?.filter(isDefined);

  const onSelect = (value: WeekDay) => {
    const included = selectedDays?.includes(value);

    const nextValue = included
      ? selectedDays
        ? [...selectedDays.filter((day) => day !== value)]
        : undefined
      : selectedDays
      ? [...selectedDays, value]
      : [value];

    setValue('notificationDays', nextValue);
    trigger('notificationDays');
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore react-hook-form's errors are incorrectly typed
  const errorMessage = errors.notificationDays?.message || '';

  return (
    <ContentWrap $enabled={enabled} $error={Boolean(errorMessage)}>
      <ListOfDays>
        {Object.values(WeekDay).map((value, index) => {
          const selected = value && selectedDays?.includes(value);

          return (
            <li key={index}>
              <Button
                color={!selected ? 'inherit' : 'secondary'}
                disableElevation
                disabled={!enabled}
                onClick={() => (enabled ? onSelect(value) : false)}
                startIcon={
                  selected ? <Icon iconName="check"></Icon> : undefined
                }
                variant="contained"
              >
                {t(value)}
              </Button>
            </li>
          );
        })}
      </ListOfDays>
      <ErrorMessage>{t(errorMessage)}</ErrorMessage>
    </ContentWrap>
  );
};
