import { useTranslation } from 'react-i18next';

import { FieldDefinition, RequiredMetaDataFieldOption } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';

export const useGetWarningMetadata = () => {
  const { t } = useTranslation();
  const { settings } = useProjectContext();
  const { metadata_required } = settings || {};

  const getWarning = ({
    fieldDefinition,
    value,
  }: {
    fieldDefinition: FieldDefinition;
    value: string;
  }) => {
    const { required } = fieldDefinition;

    return metadata_required === RequiredMetaDataFieldOption.DoNotEnforce &&
      required &&
      !value
      ? t('This field is required')
      : '';
  };

  return getWarning;
};
