import { State } from '../useUploaderEditorReducer';

import { getMetaInvalid } from './helpers/getMetaInvalid';

export const setInvalidMetaAction = ({ state }: { state: State }) => {
  const { meta } = state;

  return {
    ...state,
    metaInvalid: getMetaInvalid(meta),
  };
};
