function getPath(projectId: string, userId: string) {
  return `p4a:ps:prostream-collection-document-${userId}-${
    projectId ?? 'organization'
  }`;
}

export function getCollectionFolderDocuments(
  projectId: string,
  userId: string
) {
  const currentDocuments = (JSON.parse(
    localStorage.getItem(getPath(projectId, userId))
  ) ?? []) as string[];
  return currentDocuments;
}

export function storeDocuments(
  documentIds: string[],
  projectId: string,
  userId: string
) {
  localStorage.setItem(getPath(projectId, userId), JSON.stringify(documentIds));
}

export function removeDocumentsFromStore(
  documentIds: string[],
  projectId: string,
  userId: string
) {
  const currentDocuments = getCollectionFolderDocuments(projectId, userId);

  storeDocuments(
    currentDocuments.filter((documentId) => !documentIds.includes(documentId)),
    projectId,
    userId
  );
}
