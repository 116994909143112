import type { ObjectInput, ObjectNode } from '@pro4all/graphql';

export function toObjectInput({
  __typename,
  createdBy,
  deletedBy,
  projectStatus: _projectStatus,
  project: _project,
  tbqFunction: _tbqFunction,
  tbqFunctionAllowed: _tbqFunctionAllowed,
  tbqType: _tbqType,
  tbqTypeAbstract: _tbqTypeAbstract,
  tbqTypeAllowed: _tbqTypeAllowed,
  ...node
}: ObjectNode): ObjectInput {
  return Object.assign(node, {
    createdBy: createdBy?.id,
    deletedBy: deletedBy?.id,
  });
}
