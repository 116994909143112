import React from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { DndTypes } from '@pro4all/shared/config';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import { Segment } from './configureDownloadNameReducer';
import { ExampleCard } from './ExampleCard';
import * as Styled from './Styles';
import { useGetLabelAndIcon } from './useGetLabelAndIcon';

export const Example = () => {
  const { t } = useTranslation();
  const {
    insertSegment,
    state: { segments },
  } = useConfigureDownloadNameContext();

  const getLabelAndIcon = useGetLabelAndIcon();

  const getFilename = (filename: string, segment: Segment) =>
    `${filename}${getLabelAndIcon(segment).label}`;

  const sampleText = segments.reduce(getFilename, '');

  const [, drop] = useDrop({
    accept: [
      DndTypes.NOT_INCLUDED_CHARACTER,
      DndTypes.NOT_INCLUDED_FIELD,
      DndTypes.INCLUDED_SEGMENT,
    ],
    drop: (segmentDropped: Segment, monitor) => {
      if (!monitor.didDrop()) {
        const { deleteId, id, type } = segmentDropped;
        insertSegment({
          segment: {
            deleteId,
            id,
            type,
          },
        });
      }
    },
  });

  return (
    <Styled.Container>
      <Styled.Header variant="h6">{t('Example')}</Styled.Header>
      {sampleText.length > 0 && (
        <Styled.ExampleSample variant="body1">
          {sampleText}
        </Styled.ExampleSample>
      )}
      {drop(
        <div>
          <Styled.ExampleContainer>
            {segments.map((segment) => (
              <ExampleCard key={segment.deleteId} segment={segment} />
            ))}
          </Styled.ExampleContainer>
        </div>
      )}
    </Styled.Container>
  );
};
