import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Alert } from '@pro4all/shared/mui-wrappers';

import { useMetaDataContext } from '../../views/MetaDataContext';

import { useImportTemplate } from './useImportTemplate';

export const useImportTemplates = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const importTemplate = useImportTemplate();
  const { setNumberOfTemplatesAlreadyImported, setNumberOfTemplatesForImport } =
    useMetaDataContext();

  const importTemplates = useCallback(async () => {
    try {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = '.json';

      await new Promise((resolve, reject) => {
        input.onchange = resolve;
        input.onerror = reject;
        input.click();
      });

      const files = Array.from(input.files); // Convert FileList to array
      const filesWithInvalidJson: string[] = [];

      setNumberOfTemplatesForImport(files.length);

      let counter = 1;
      const newTemplateNames: string[] = [];
      for (const file of files) {
        const reader = new FileReader();

        // We need to be sure that processing of the previous file is done before processing the next one.
        // That's why we have this `Promise/resolve`-construction here.
        // eslint-disable-next-line
        await new Promise<void>((resolve, reject) => {
          reader.onload = async () => {
            try {
              const jsonData = JSON.parse(reader.result as string);
              const newTemplateName = await importTemplate({
                file,
                newTemplateNames,
                template: jsonData,
              });
              if (newTemplateName) newTemplateNames.push(newTemplateName);
              setNumberOfTemplatesAlreadyImported(counter);
              counter++;
              resolve();
            } catch (error) {
              console.log('File import error', error.message);
              filesWithInvalidJson.push(file.name);
              resolve();
            }
          };
          reader.onerror = reject;

          reader.readAsText(file);
        });
      }

      if (filesWithInvalidJson.length > 0) {
        enqueueSnackbar(
          <Alert severity="error" variant="filled">
            {t('These templates are invalid > {{filesInvalid}}', {
              filesInvalid: filesWithInvalidJson.join(', '),
            })}
          </Alert>
        );
      } else {
        enqueueSnackbar(
          <Alert severity="info" variant="filled">
            {t('All templates processed')}
          </Alert>
        );
      }
    } catch (error) {
      enqueueSnackbar(
        <Alert severity="error" variant="filled">
          {t('Error at importing templates')}
        </Alert>
      );
    }
    setNumberOfTemplatesForImport(0);
  }, [
    enqueueSnackbar,
    importTemplate,
    setNumberOfTemplatesAlreadyImported,
    setNumberOfTemplatesForImport,
    t,
  ]);

  return importTemplates;
};
