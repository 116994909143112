import React, { useState } from 'react';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { use3DDocuments } from '@pro4all/documents/ui/3d';
import { FinalizationState, Folder, ScopeType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAi } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { isDefined } from '@pro4all/shared/utils';

import { AiSummary } from './ai-summary/AiSummary';
import { GeneralProperties } from './general/GeneralProperties';
import { Thumbnail } from './general/Thumbnail';
import { DocumentKeywordsDisplay } from './keywords/DocumentKeywordsDisplay';
import { DocumentKeywordsEdit } from './keywords/DocumentKeywordsEdit';
import { DocumentKeywordsProvider } from './keywords/DocumentKeywordsProvider';
import { KeywordModal } from './keywords/KeywordModal';
import { useGetKeywordData } from './keywords/useGetKeywordData';
import { DocumentTags } from './tags/DocumentTags';
import { VersionList } from './versions-list/VersionList';

export type FolderPicked = Pick<Folder, 'id' | 'template'>;

export const DocumentProperties = ({
  document,
  folder,
  version,
}: {
  folder: FolderPicked | undefined;
} & DocumentAndVersionType) => {
  const { canUpdateDocumentOrVersion } = useUserFolderPermissions({
    folderId: folder?.id || document?.folderId || '',
  });

  const canEdit = canUpdateDocumentOrVersion({
    document: document ? document : null,
    version: version ? version : null,
  });

  const hasAiSummaryFeatureFlag = useFeatureFlag('ai-document-summary');
  const hasAiGenerateKeywordsFeatureFlag = useFeatureFlag('generate-keywords');

  const versionIds = document
    ? [document.versionId || '']
    : version
    ? [version.id]
    : [];

  const { urns } = use3DDocuments({ versionIds });
  const [displayKeywords, setDisplayKeywords] = useState(true);

  const { fields: fieldDefinitions } = folder?.template ?? {};
  const { params, searchParams } = useRouting();
  const versionId = searchParams.get('versionid');
  const { projectId } = params;

  const { aiEnabledContext } = useAi(
    projectId ? ScopeType.Project : ScopeType.Organization
  );

  const isFinalized = document
    ? document.state === FinalizationState.Finalized
    : version
    ? version.state === FinalizationState.Finalized
    : false;

  const { hasSupportedExtensionToGenerateKeywords, keywordsInitially } =
    useGetKeywordData({ document, version });

  return (
    <>
      {hasAiSummaryFeatureFlag &&
        aiEnabledContext &&
        Boolean(!document?.isExpected) && (
          <AiSummary
            document={document}
            editable={canEdit}
            isFinalized={isFinalized}
            version={version}
            versionId={versionIds[0]}
          ></AiSummary>
        )}
      <Thumbnail
        document={document || null}
        hide={Boolean(document?.isExpected)}
        urn={urns[0] || undefined}
        version={version || null}
      />
      <GeneralProperties
        document={document || null}
        fieldDefinitions={fieldDefinitions?.filter(isDefined)}
        version={version || null}
      />
      {document && !versionId && (
        <>
          {hasAiGenerateKeywordsFeatureFlag &&
          aiEnabledContext &&
          hasSupportedExtensionToGenerateKeywords &&
          Boolean(!document?.isExpected) ? (
            <DocumentKeywordsProvider>
              <>
                {displayKeywords ? (
                  <DocumentKeywordsDisplay
                    document={document ? document : null}
                    editable={canEdit}
                    isFinalized={isFinalized}
                    keywordsInitially={keywordsInitially}
                    setDisplayKeywords={setDisplayKeywords}
                    version={version ? version : null}
                    versionId={versionIds[0]}
                  />
                ) : (
                  <DocumentKeywordsEdit
                    document={document ? document : null}
                    setDisplayKeywords={setDisplayKeywords}
                    version={version ? version : null}
                    versionId={versionIds[0]}
                  />
                )}
                <KeywordModal
                  document={document ? document : null}
                  version={version ? version : null}
                  versionId={versionIds[0]}
                />
              </>
            </DocumentKeywordsProvider>
          ) : null}
          <DocumentTags document={document} />
          <VersionList
            hide={!document?.versions?.length}
            versions={document?.versions?.filter(isDefined) || []}
          />
        </>
      )}
    </>
  );
};
