import { PasteType } from '@pro4all/shared/types';

import { addItemAction } from './actions/addItemAction';
import { addItemsAction } from './actions/addItemsAction';
import { deleteItemAction } from './actions/deleteItemAction';
import { deselectItemAction } from './actions/deselectItemAction';
import { fillInitialItemsAction } from './actions/fillInitialItemsAction';
import { moveItemAction } from './actions/moveItemAction';
import { pasteItemAction } from './actions/pasteItemAction';
import { selectItemAction } from './actions/selectItemAction';
import { sortItems } from './helpers/sortItems';
import {
  AddType,
  BasePropsHierarchyItem,
  HierarchyPayload,
  ItemProps,
} from './types';

export enum ActionType {
  ADD_ITEM = 'ADD_ITEM',
  ADD_ITEMS = 'ADD_ITEMS',
  DELETE_ITEM = 'DELETE_ITEM',
  DESELECT_ITEM = 'DESELECT_ITEM',
  EDIT_ITEM = 'EDIT_ITEM',
  FILL_INITIAL_ITEMS = 'FILL_INITIAL_ITEMS',
  FORCE_RENDER = 'FORCE_RENDER',
  FREEZE_ITEMS = 'FREEZE_ITEMS',
  MOVE_ITEM = 'MOVE_ITEM',
  PASTE_ITEM = 'PASTE_ITEM',
  REMOVE_NON_EXISTING_ITEM = 'REMOVE_NON_EXISTING_ITEM',
  RESET_TO_FREEZED_ITEMS = 'RESET_TO_FREEZED_ITEMS',
  SELECT_ITEMS = 'SELECT_ITEMS',
}

export type State<HierarchyItem extends BasePropsHierarchyItem> = Pick<
  ItemProps<HierarchyItem>,
  'items' | 'itemsFreezed' | 'itemsInitial' | 'itemsNonExisting'
>;

export type FillInitialItemsPayload<HierarchyItem> = {
  currentBreadcrumbs: HierarchyPayload[];
  items: HierarchyItem[];
};

export type MoveItemPayload = {
  addType: AddType; // sibling or child
  appendAfterTarget: boolean; // true if the item must be moved after the target, false if it must be moved before the target
  sourceId: string; // id of the item that will be moved
  targetId: string; // id of the item that will be the new successor
};

export type PasteItemPayload = {
  newParentNodeId: string; // id of the item that will act as the new parent
  pasteType: PasteType; // copy or cut
  sourceId: string; // id of the item to copy or cut
};

export type SelectItemPayload = {
  ids: string[]; // ids of the items that should be (de)selected
  multipleAnswers: boolean; // can we have multiple items selected at the same time
};

type ActionAddItemPayload<HierarchyItem extends BasePropsHierarchyItem> = {
  payload: HierarchyItem;
  type: ActionType.ADD_ITEM;
};
type ActionAddItemsPayload<HierarchyItem extends BasePropsHierarchyItem> = {
  payload: HierarchyItem[];
  type: ActionType.ADD_ITEMS;
};
type ActionDeleteItemPayload = {
  payload: string; // id of the item that must be removed
  type: ActionType.DELETE_ITEM;
};
type ActionDeselectItemPayload = {
  payload: string;
  type: ActionType.DESELECT_ITEM;
};
type ActionEditItemPayload<HierarchyItem extends BasePropsHierarchyItem> = {
  payload: HierarchyItem;
  type: ActionType.EDIT_ITEM;
};
type ActionFillInitialItemsPayload<
  HierarchyItem extends BasePropsHierarchyItem
> = {
  payload: FillInitialItemsPayload<HierarchyItem>;
  type: ActionType.FILL_INITIAL_ITEMS;
};
type ActionForceRenderPayload = {
  type: ActionType.FORCE_RENDER;
};
type ActionFreezeItemsPayload = {
  type: ActionType.FREEZE_ITEMS;
};
type ActionMoveItemPayload = {
  payload: MoveItemPayload;
  type: ActionType.MOVE_ITEM;
};
type ActionPasteItemPayload = {
  payload: PasteItemPayload;
  type: ActionType.PASTE_ITEM;
};
type ActionRemoveNonExistingItemPayload = {
  payload: string;
  type: ActionType.REMOVE_NON_EXISTING_ITEM;
};
type ActionResetToFreezedItemsPayload = {
  type: ActionType.RESET_TO_FREEZED_ITEMS;
};
type ActionSelectItemsPayload = {
  payload: SelectItemPayload;
  type: ActionType.SELECT_ITEMS;
};

export type Action<HierarchyItem extends BasePropsHierarchyItem> =
  | ActionAddItemPayload<HierarchyItem>
  | ActionAddItemsPayload<HierarchyItem>
  | ActionDeleteItemPayload
  | ActionDeselectItemPayload
  | ActionEditItemPayload<HierarchyItem>
  | ActionFillInitialItemsPayload<HierarchyItem>
  | ActionForceRenderPayload
  | ActionFreezeItemsPayload
  | ActionMoveItemPayload
  | ActionPasteItemPayload
  | ActionRemoveNonExistingItemPayload
  | ActionResetToFreezedItemsPayload
  | ActionSelectItemsPayload;

const sort = <HierarchyItem extends BasePropsHierarchyItem>(
  items: HierarchyItem[]
) => {
  const rootItems = items.filter((item) => !item.parentNodeId);
  const sortedItemsList = items.length
    ? sortItems<HierarchyItem>({
        allItems: items,
        itemsOnEqualLevel: rootItems,
        level: 0,
      })
    : [];
  return sortedItemsList;
};

export function hierarchyEditorReducer<
  HierarchyItem extends BasePropsHierarchyItem
>(state: State<HierarchyItem>, action: Action<HierarchyItem>) {
  switch (action.type) {
    case ActionType.ADD_ITEM: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          addItemAction<HierarchyItem>({
            allItems: state.items,
            newItem: action.payload,
          })
        ),
      };
    }
    case ActionType.ADD_ITEMS: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          addItemsAction<HierarchyItem>({
            allItems: state.items,
            newItems: action.payload,
          })
        ),
      };
    }
    case ActionType.DELETE_ITEM: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          deleteItemAction<HierarchyItem>({
            allItems: state.items,
            id: action.payload,
          })
        ),
      };
    }
    case ActionType.DESELECT_ITEM: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          deselectItemAction<HierarchyItem>({
            allItems: state.items,
            id: action.payload,
          })
        ),
        itemsNonExisting: state.itemsNonExisting.filter(
          (item) => item.value !== action.payload
        ),
      };
    }
    case ActionType.EDIT_ITEM: {
      const itemToEdit = action.payload;
      const otherItems = state.items.filter(
        (item) => item.id !== itemToEdit.id
      );
      return {
        ...state,
        items: sort<HierarchyItem>([...otherItems, itemToEdit]),
      };
    }
    case ActionType.FILL_INITIAL_ITEMS: {
      const { itemsInitial, nonExistingItems } = fillInitialItemsAction(
        action.payload
      );
      const itemsInitialSorted = sort<HierarchyItem>(itemsInitial);
      return {
        ...state,
        items: itemsInitialSorted,
        itemsInitial: itemsInitialSorted,
        itemsNonExisting: nonExistingItems,
      };
    }
    case ActionType.FORCE_RENDER: {
      return {
        ...state,
      };
    }
    case ActionType.FREEZE_ITEMS: {
      return {
        ...state,
        itemsFreezed: state.items,
      };
    }
    case ActionType.MOVE_ITEM: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          moveItemAction<HierarchyItem>({
            allItems: state.items,
            ...action.payload,
          })
        ),
      };
    }
    case ActionType.PASTE_ITEM: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          pasteItemAction<HierarchyItem>({
            allItems: state.items,
            ...action.payload,
          })
        ),
      };
    }
    case ActionType.REMOVE_NON_EXISTING_ITEM: {
      return {
        ...state,
        itemsNonExisting: state.itemsNonExisting.filter(
          (item) => item.value !== action.payload
        ),
      };
    }
    case ActionType.RESET_TO_FREEZED_ITEMS: {
      return {
        ...state,
        items: state.itemsFreezed,
      };
    }
    case ActionType.SELECT_ITEMS: {
      return {
        ...state,
        items: sort<HierarchyItem>(
          selectItemAction<HierarchyItem>({
            allItems: state.items,
            ids: action.payload.ids,
            multipleAnswers: action.payload.multipleAnswers,
          })
        ),
      };
    }

    default:
      return state;
  }
}
