import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { DisplayTypeValues } from './Types';
import { useCustomValidation } from './useCustomValidation';

export const useFieldDefinitionFormConfig = (
  { multiSelectLabel }: { multiSelectLabel: string } = {
    multiSelectLabel: 'Multiple answers possible',
  }
) => {
  const customValidation = useCustomValidation();
  const { t } = useTranslation();
  const characterLimit = 9999;

  const formFields: FormFieldConfig[] = [
    // Default fields
    {
      displayName: 'Display name', // i18n
      name: 'displayName',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Display description', // i18n
      name: 'displayDescription',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Type', // i18n
      name: 'type',
      required: true,
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Name', // i18n
      max: characterLimit,
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Description', // i18n
      name: 'description',
      type: ValueTypeName.Text,
    },

    // CustomHierarchyList fields
    {
      displayName: 'Select hierarchical list', // i18n
      name: 'hierarchyList',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Multiple answers possible', // i18n
      name: 'multipleAnswers',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Show answers by value', // i18n
      name: 'staticBreadcrumbs',
      type: ValueTypeName.Text,
    },

    // CustomNumber fields
    {
      displayName: 'Should be within a range', // i18n
      name: 'rangeEnabled',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'From', // i18n
      name: 'minValue',
      type: ValueTypeName.Number,
    },
    {
      displayName: 'To', // i18n
      name: 'maxValue',
      type: ValueTypeName.Number,
    },

    // CustomSelection fields
    {
      displayName: t(multiSelectLabel),
      name: 'multiSelect',
      type: ValueTypeName.Text,
    },
    {
      displayName: t('FieldDefinitionForm.DisplayName'),
      name: 'displayType',
      options: [
        {
          id: DisplayTypeValues.dropdown,
          label: t('FieldDefinitionForm.Displaytype.Dropdown'),
        },
        {
          id: DisplayTypeValues.buttons,
          label: t('FieldDefinitionForm.Displaytype.Buttons'),
        },
      ],
      type: ValueTypeName.Text,
    },
    {
      name: 'selectionOptions',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },

    // CustomStatus fields
    {
      name: 'statusOptions',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ customValidation, formFields });
};
