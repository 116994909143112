import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Template: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M6.66658 6.45817C6.66658 5.99793 7.03968 5.62484 7.49992 5.62484H16.6666C17.1268 5.62484 17.4999 5.99793 17.4999 6.45817V7.2915C17.4999 7.75174 17.1268 8.12484 16.6666 8.12484H7.49992C7.03968 8.12484 6.66658 7.75174 6.66658 7.2915V6.45817Z"
      fill="#585858"
    />
    <path
      d="M0.833252 9.99984C0.833252 9.5396 1.20635 9.1665 1.66659 9.1665H10.8333C11.2935 9.1665 11.6666 9.5396 11.6666 9.99984V10.8332C11.6666 11.2934 11.2935 11.6665 10.8333 11.6665H1.66659C1.20635 11.6665 0.833252 11.2934 0.833252 10.8332V9.99984Z"
      fill="#585858"
    />
    <path
      d="M9.16658 13.5415C9.16658 13.0813 9.53968 12.7082 9.99992 12.7082H19.1666C19.6268 12.7082 19.9999 13.0813 19.9999 13.5415V14.3748C19.9999 14.8351 19.6268 15.2082 19.1666 15.2082H9.99992C9.53968 15.2082 9.16658 14.8351 9.16658 14.3748V13.5415Z"
      fill="#585858"
    />
    <path
      clipRule="evenodd"
      d="M7.43047 2.49984C7.43047 2.0396 7.80357 1.6665 8.26381 1.6665H8.95825C9.41849 1.6665 9.79158 2.0396 9.79158 2.49984C9.79158 2.96007 9.41849 3.33317 8.95825 3.33317H8.26381C7.80357 3.33317 7.43047 2.96007 7.43047 2.49984ZM10.2083 2.49984C10.2083 2.0396 10.5813 1.6665 11.0416 1.6665H11.736C12.1963 1.6665 12.5694 2.0396 12.5694 2.49984C12.5694 2.96007 12.1963 3.33317 11.736 3.33317H11.0416C10.5813 3.33317 10.2083 2.96007 10.2083 2.49984ZM4.16659 6.06755C4.62682 6.06755 4.99992 6.44064 4.99992 6.90088V7.68213C4.99992 8.14237 4.62682 8.51546 4.16659 8.51546C3.70635 8.51546 3.33325 8.14237 3.33325 7.68213V6.90088C3.33325 6.44064 3.70635 6.06755 4.16659 6.06755ZM15.8333 9.19255C16.2935 9.19255 16.6666 9.56564 16.6666 10.0259V10.8071C16.6666 11.2674 16.2935 11.6405 15.8333 11.6405C15.373 11.6405 14.9999 11.2674 14.9999 10.8071V10.0259C14.9999 9.56564 15.373 9.19255 15.8333 9.19255ZM4.16659 12.3175C4.62682 12.3175 4.99992 12.6906 4.99992 13.1509V13.9321C4.99992 14.3924 4.62682 14.7655 4.16659 14.7655C3.70635 14.7655 3.33325 14.3924 3.33325 13.9321V13.1509C3.33325 12.6906 3.70635 12.3175 4.16659 12.3175ZM7.43047 18.3332C7.43047 17.8729 7.80357 17.4998 8.26381 17.4998H8.95825C9.41849 17.4998 9.79158 17.8729 9.79158 18.3332C9.79158 18.7934 9.41849 19.1665 8.95825 19.1665H8.26381C7.80357 19.1665 7.43047 18.7934 7.43047 18.3332ZM10.2083 18.3332C10.2083 17.8729 10.5813 17.4998 11.0416 17.4998H11.736C12.1963 17.4998 12.5694 17.8729 12.5694 18.3332C12.5694 18.7934 12.1963 19.1665 11.736 19.1665H11.0416C10.5813 19.1665 10.2083 18.7934 10.2083 18.3332Z"
      fill="#585858"
      fillRule="evenodd"
    />
    <path
      d="M3.33325 4.1665C3.33325 2.78579 4.45254 1.6665 5.83325 1.6665C5.83325 1.6665 6.66658 1.6665 6.66658 2.49984C6.66658 3.33317 5.83325 3.33317 5.83325 3.33317C5.37301 3.33317 4.99992 3.70627 4.99992 4.1665C4.99992 4.1665 4.99992 4.99984 4.16659 4.99984C3.33325 4.99984 3.33325 4.1665 3.33325 4.1665Z"
      fill="#585858"
    />
    <path
      d="M14.1666 1.6665C15.5473 1.6665 16.6666 2.78579 16.6666 4.1665C16.6666 4.1665 16.6666 4.99984 15.8333 4.99984C14.9999 4.99984 14.9999 4.1665 14.9999 4.1665C14.9999 3.70627 14.6268 3.33317 14.1666 3.33317C14.1666 3.33317 13.3333 3.33317 13.3333 2.49984C13.3333 1.6665 14.1666 1.6665 14.1666 1.6665Z"
      fill="#585858"
    />
    <path
      d="M16.6666 16.6665C16.6666 18.0472 15.5473 19.1665 14.1666 19.1665C14.1666 19.1665 13.3333 19.1665 13.3333 18.3332C13.3333 17.4998 14.1666 17.4998 14.1666 17.4998C14.6268 17.4998 14.9999 17.1267 14.9999 16.6665C14.9999 16.6665 14.9999 15.8332 15.8333 15.8332C16.6666 15.8332 16.6666 16.6665 16.6666 16.6665Z"
      fill="#585858"
    />
    <path
      d="M5.83325 19.1665C4.45254 19.1665 3.33325 18.0472 3.33325 16.6665C3.33325 16.6665 3.33325 15.8332 4.16659 15.8332C4.99992 15.8332 4.99992 16.6665 4.99992 16.6665C4.99992 17.1267 5.37301 17.4998 5.83325 17.4998C5.83325 17.4998 6.66659 17.4998 6.66658 18.3332C6.66658 19.1665 5.83325 19.1665 5.83325 19.1665Z"
      fill="#585858"
    />
  </SvgIcon>
);
