import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Position } from '@pro4all/shared/types';
import { useRequestUnlock } from '@pro4all/shared/ui/actions';

import { DocumentActionProps } from '../../types';

export const useOnClickRequestUnlock = ({
  position,
  selection,
  uncheckAllRows,
}: Pick<DocumentActionProps, 'position' | 'selection' | 'uncheckAllRows'>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const requestUnlock = useRequestUnlock();

  const onClick = async () => {
    if (!selection[0].lockedBy) {
      enqueueSnackbar(
        t('Document is not locked. You cannot send a request to unlock it.')
      );
    } else {
      const isSuccessful = await requestUnlock({
        document: selection[0],
      });
      if (isSuccessful) {
        // Deselect all rows if applicable.
        if (position !== Position.Contextmenu) uncheckAllRows();
      }
    }
  };

  return onClick;
};
