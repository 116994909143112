import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import * as XLSX from 'xlsx';

import { useHierarchyEditorContext } from './HierarchyEditorProvider';
import { BasePropsHierarchyItem } from './types';

type JsonData = string[][];

export const useImportFromExcel = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const parentItemRef = useRef<BasePropsHierarchyItem | null>(null);

  const { addItems } = useHierarchyEditorContext();

  const handleButtonClick = ({ item }: { item: BasePropsHierarchyItem }) => {
    parentItemRef.current = item;
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0]; // Use ? to check if 'files' exists.
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>): void => {
        const result = e.target?.result;
        if (result) {
          const data = new Uint8Array(result as ArrayBuffer); // Typecast to ArrayBuffer.
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData: JsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          // Process the data.
          processData({ file, jsonData });
        }
      };

      reader.readAsArrayBuffer(file);
    }

    // Reset the value of the input to allow re-selection of the same file.
    event.target.value = '';
  };

  const processData = ({
    file,
    jsonData,
  }: {
    file: File;
    jsonData: JsonData;
  }): void => {
    const newItems: BasePropsHierarchyItem[] = [];
    const itemMapById: { [key: string]: BasePropsHierarchyItem } = {};
    const itemMapByLabel: { [key: string]: BasePropsHierarchyItem } = {};
    const previousNodesByParentAndLevel: { [key: string]: string | null } = {}; // Keep track of previous nodes by parent and level.

    jsonData.forEach((row: string[]) => {
      const path: string = row[0];

      // Skip rows that are empty or undefined.
      if (!path) {
        return;
      }

      const levels: string[] = path.toString().split('\\');

      // parentNodeId depends on whether import is on root level or entry level.
      let parentNodeId: string | null = parentItemRef.current
        ? parentItemRef.current.id
        : null;

      levels.forEach((name: string, index: number) => {
        const newItemId: string = uuid();

        // level depends on whether import is on root level or entry level.
        const level: number = parentItemRef.current
          ? index +
            1 +
            (parentItemRef.current.level ? parentItemRef.current.level : 0)
          : index + 1;

        // Make a unique label taking the parent into account.
        const labelKey = `${name}-${parentNodeId || 'root'}`;

        // Check if an item with a similar name-parent combination already exists.
        let newItem: BasePropsHierarchyItem | undefined =
          itemMapByLabel[labelKey];

        if (!newItem) {
          // Create a new item in case it does not exist yet.
          newItem = {
            expanded: true,
            hasChildren: false,
            id: newItemId,
            level,
            name,
            parentNodeId,
            previousNodeId:
              previousNodesByParentAndLevel[`${parentNodeId}-${level}`] || null,
            selected: false,
          };

          // Add the new item to the the list and the two mappers.
          newItems.push(newItem);
          itemMapById[newItemId] = newItem;
          itemMapByLabel[labelKey] = newItem;

          // Update `hasChildren` if the parent
          if (parentNodeId && itemMapById[parentNodeId]) {
            itemMapById[parentNodeId].hasChildren = true;
          }

          // Update previous node of this parent and level.
          previousNodesByParentAndLevel[`${parentNodeId}-${level}`] =
            newItem.id;
        }

        // Store parentNodeId for the next level.
        parentNodeId = newItem.id;
      });
    });

    if (newItems.length) {
      // Add the items
      addItems(newItems);
    } else {
      enqueueSnackbar(
        t('File `{{name}}` does not contain data for import', {
          name: file.name,
        })
      );
    }
  };

  const excelInputElement = (
    <input
      accept=".xlsx, .xls"
      onChange={handleFileUpload}
      ref={fileInputRef}
      style={{ display: 'none' }} // Hide input element
      type="file"
    />
  );

  return { excelInputElement, handleButtonClick };
};
