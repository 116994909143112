import React from 'react';
import styled from 'styled-components';

import { SampleSignature } from '@pro4all/shared/qc-report-assets';
import { customColors } from '@pro4all/shared/themes';
import { SignatureReportsProps } from '@pro4all/shared/types';

export const SignatureReports = ({
  reportConfigurationDummyTemplate,
  signatureUrl,
}: SignatureReportsProps) => (
  <SignatureCanvas>
    {reportConfigurationDummyTemplate ? (
      <SignatureImage alt="" src={SampleSignature} />
    ) : (
      <SignatureImage alt="" src={signatureUrl} />
    )}
  </SignatureCanvas>
);

export const SignatureCanvas = styled.div`
  height: 100px;
  width: 300px;
  padding: 2px;
  border: 1px solid ${customColors.grey500};
  border-radius: 1px;
  margin-bottom: 1px;
`;

export const SignatureImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`;
