export const FileCopy = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 14 16"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.33341 0.666504H2.00008C1.26675 0.666504 0.666748 1.2665 0.666748 1.99984V10.6665C0.666748 11.0332 0.966748 11.3332 1.33341 11.3332C1.70008 11.3332 2.00008 11.0332 2.00008 10.6665V2.6665C2.00008 2.29984 2.30008 1.99984 2.66675 1.99984H9.33341C9.70008 1.99984 10.0001 1.69984 10.0001 1.33317C10.0001 0.966504 9.70008 0.666504 9.33341 0.666504ZM9.72675 3.7265L12.9467 6.9465C13.1934 7.19317 13.3334 7.53317 13.3334 7.8865V13.9998C13.3334 14.7332 12.7334 15.3332 12.0001 15.3332H4.66008C3.92675 15.3332 3.33342 14.7332 3.33342 13.9998L3.34008 4.6665C3.34008 3.93317 3.93341 3.33317 4.66675 3.33317H8.78008C9.13342 3.33317 9.47342 3.47317 9.72675 3.7265ZM12.3334 7.99984H9.33342C8.96675 7.99984 8.66675 7.69984 8.66675 7.33317V4.33317L12.3334 7.99984Z"
      fill="#006B5E"
      fillRule="evenodd"
    />
  </svg>
);
