import { FacetGroup, FilterType } from '@pro4all/graphql';
import { isDefined } from '@pro4all/shared/utils';

export const getFacetGroup = (
  facetGroups: FacetGroup[],
  type: FilterType,
  mdKey?: string
) => {
  const foundGroup = facetGroups?.find((group) => group.type === type);
  if (!foundGroup) return undefined;

  if (mdKey) {
    const filteredItems = foundGroup.items?.filter(isDefined).filter((item) => {
      if (!item?.mdField?.id) return undefined;
      return mdKey.includes(item?.mdField?.id);
    });
    return {
      ...foundGroup,
      items: filteredItems,
    };
  }

  return foundGroup;
};
