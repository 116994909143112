import { StylingDefaults } from '../defaults/styling-defaults';
import * as variables from '../style-dictionary-dist/variables';

import { customColors } from './customColors';

const globalSpacing8 = Number(variables.GlobalSpacing8.replace('px', ''));

export const components = {
  MuiAlert: {
    styleOverrides: {
      action: {
        padding: 0,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        paddingBottom: '0px !important', // Have to include the !important, else style will be overwritten.
        paddingTop: '0px !important', // Have to include the !important, else style will be overwritten.
      },
      root: {
        '& .MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 12px)',
        },
        padding: '0px',
      },
      tag: {
        marginTop: '6px',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: customColors.purplePrimary,
        fontSize: '0.97692rem',
        height: '1.7rem',
        width: '1.7rem',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      // Todo: Change the borderRadius to design token
      root: { borderRadius: '30px', flexShrink: '0' },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&&.Mui-checked': {
          color: customColors.greenPrimary,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: '#fafafa',
        color: '#585858',
        fontFamily:
          'Roboto,"Helvetica Neue",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 1.43,
        margin: 0,
      },
      html: {},
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        '.MuiIconButton-root': {
          border: 'none',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        '&.MuiAutocomplete-input': {
          border: '1px solid red',
          padding: '10px',
        },
      },
      underline: {
        '&:after, &:before, &:hover:after, &:hover:not($disabled):after, &:hover:not($disabled):before':
          {
            borderBottomWidth: '1px',
            borderRadius: '0',
          },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&:not(.MuiSelect-select)': {
          padding: '0px',
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        '&.MuiListItem-root': {
          '&.Mui-selected': {
            backgroundColor: customColors.grey200,
          },
        },
        padding: '0',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        '&:not(.MuiSelect-select)': {
          padding: '10px 12px !important', // Have to include the !important, else style will be overwritten.
        },
      },
      root: {
        '&&& $input': {
          paddingBottom: '1px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeLarge: { fontSize: '1.5rem' },
      fontSizeSmall: { fontSize: '1rem' },
      root: { fontSize: '1.25rem' },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      colorPrimary: {
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: customColors.greenPrimary,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: 'inherit',
        fontSize: '14px',
        fontWeight: 400,
        minHeight: 'auto',
        minWidth: 'auto',
        padding: '6px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        marginBottom: '8px',
        minHeight: 'auto',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      arrow: {
        color: 'var(--background-color)',
      },
      popperArrow: {
        '&[x-placement*="bottom"] .MuiTooltip-arrow': {
          transform: 'translateX(-50%)',
        },
        '&[x-placement*="left"] .MuiTooltip-arrow': {
          transform: 'translateY(-50%)',
        },
        '&[x-placement*="top"] .MuiTooltip-arrow': {
          transform: 'translateX(-50%) translateY(-50%)',
        },
      },
      tooltip: {
        '&.error': {
          '--background-color': '#CF6679',
        },
        '&.warning': {
          '--background-color': '#F1B030',
        },
        '--background-color': customColors.grey700,
        '--color': customColors.white,
        backgroundColor: 'var(--background-color)',
        borderRadius: StylingDefaults.borderRadiusSmall,
        boxShadow: StylingDefaults.boxShadow,
        color: 'var(--color) !important', //TODO: remove when upgraded to MUI v5, is broken now
        padding: `${globalSpacing8 * 1.33}px ${globalSpacing8 * 2.66}px`,
      },
    },
  },
};
