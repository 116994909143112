import { useEffect } from 'react';

import { useGetSignatureQuery } from '@pro4all/graphql';

export const useGetSignatureHandler = () => {
  const {
    data: getSignatureData,
    refetch: refetchSignature,
    loading: signatureIsLoading,
  } = useGetSignatureQuery();

  const signatureBody = JSON.parse(
    getSignatureData?.getSignature?.data?.body || '[]'
  );
  const signatureId = getSignatureData?.getSignature?.data?.signatureId;

  useEffect(
    () => () => {
      refetchSignature();
    },
    [refetchSignature]
  );

  return { signatureBody, signatureId, signatureIsLoading };
};
