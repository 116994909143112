import { Option } from '@pro4all/shared/types';

export const ImagePreviewViewModes: Option[] = [
  {
    id: 'normal',
    label: 'Normal view',
  },
  {
    id: 'sphere',
    label: 'Panorama view',
  },
];
