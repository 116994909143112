import { useConnectionQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetConnection = () => {
  const { params, searchParams } = useRouting();
  const id = searchParams.get('id');
  const { projectId } = params;
  const { data, loading } = useConnectionQuery({
    fetchPolicy: 'cache-and-network',
    skip: !id,
    variables: { id: id || '', projectId: projectId || '' },
  });

  return {
    dataConnection: data?.connection,
    loadingConnection: loading && !data,
  };
};
