import { useCallback } from 'react';
import { Schema } from 'yup';

interface Error {
  message: string;
  path: string;
  type: string;
}

interface FormError {
  [path: string]: string;
}

const useYupValidationResolver = (validationSchema: Schema<object>) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          errors: {},
          values,
        };
      } catch (errors) {
        const test = {
          errors: errors.inner.reduce(
            (allErrors: FormError, currentError: Error) => ({
              ...allErrors,
              [currentError.path]: currentError.message,
            }),
            {}
          ),
          values: {},
        };
        return test;
      }
    },
    [validationSchema]
  );

export { useYupValidationResolver };
