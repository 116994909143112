import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSignatureCanvas from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from '../styles/Signature.styles';

export const SignatureDrawing = ({
  onClose,
  onSign,
  open,
}: {
  onClose: () => void;
  onSign: (signature: string) => void;
  open: boolean;
}) => {
  // prop 'name' is the name of the form input which equals the input id.

  const { t } = useTranslation();
  const [signature, setSignature] = useState('');
  const signatureRef = React.useRef<ReactSignatureCanvas>();

  const submitSignature = () => {
    onSign(signature);
    onClose();
  };

  const noSignature = signature === '' || !signatureRef.current;

  // We have to pass in a fixed size for the SignatureCanvas.
  // However we want an outside border with a bit of margin.
  // With these props we manage the canvas so that the border sizes along.
  const canvasHeight = 410;
  const canvasWidth = 750;
  const canvasMargin = 8;

  return (
    <FloatingModal
      data-testid="draw-signature-modal"
      maxWidth="md"
      onClose={() => onClose()}
      open={open}
    >
      <FloatingModal.Header iconName="signature">
        {t('Signature')}
      </FloatingModal.Header>

      <Box
        display="flex"
        flexDirection="column"
        flexGrow="1"
        justifyContent="space-between"
      >
        <Box>
          <Box mb={2}>
            <Text variant="body1">
              {t('Draw something in the rectangle below with your cursor.')}
            </Text>
          </Box>
          <Styled.Canvas
            $canvasHeight={canvasHeight}
            $canvasWidth={canvasWidth}
          >
            <SignatureCanvas
              canvasProps={{
                height: canvasHeight - 2 * canvasMargin,
                width: canvasWidth - 2 * canvasMargin,
              }}
              onEnd={() =>
                // Re-renders the component, so the reset/save buttons get enabled/disabled.
                setSignature(
                  signatureRef.current.getTrimmedCanvas().toDataURL('image/png')
                )
              }
              ref={signatureRef}
            />
          </Styled.Canvas>
        </Box>
        <Styled.ButtonsWrapper>
          <Button
            aria-label={t('Reset')}
            data-testid="reset"
            disabled={noSignature}
            onClick={() => {
              setSignature(''); // Re-renders the component, so the reset/save buttons get enabled/disabled.
              signatureRef.current.clear(); // Clears the canvas.
            }}
            startIcon="reset"
            variant="contained"
          >
            {t('Reset')}
          </Button>
          <Styled.ButtonWrapper>
            <Button
              aria-label={t('Close signature drawer')}
              color="inherit"
              data-testid="close-signature-drawer"
              onClick={() => onClose()}
            >
              {t('Cancel')}
            </Button>
            <Button
              aria-label={t('Save signature')}
              color="primary"
              data-testid="save-signature"
              disabled={noSignature}
              onClick={() => submitSignature()}
              startIcon="save"
              variant="contained"
            >
              {t('Save')}
            </Button>
          </Styled.ButtonWrapper>
        </Styled.ButtonsWrapper>
      </Box>
    </FloatingModal>
  );
};
