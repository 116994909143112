export const getBatchFormLinkedSnagsIncludeVariables = ({
  ids,
}: {
  ids: string[];
}) => ({
  ids,
  includeAnswers: false,
  includeCommentStatus: false,
  includeCreatedAt: true,
  includeCreatedBy: true,
  includeDeletedAt: false,
  includeIconName: false,
  includeIndicativeState: false,
  includeIsOptimistic: false,
  includeItems: false,
  includeLinkedFieldId: false,
  includeLinkedSnagInstances: false,
  includeLinkedSnags: false,
  includeName: true,
  includePage: false,
  includePath: false,
  includeProject: false,
  includeProjectId: false,
  includeReference: true,
  includeTask: false,
  includeTaskId: false,
  includeTemplateIconProps: false,
  includeTemplateId: false,
  includeTemplateVersion: false,
  includeTemplates: false,
  includeType: true,
  includeVisualContext: true,
  includeVisualContextId: false,
  includeX: false,
  includeY: false,
});
