import dayjs from 'dayjs';

/**
 * Converts a date-string to a Zulu time string at 12:00.
 * Using `toISOString()` converts timezones to UTC, which is not what we want.
 */
export const toApiDate = (value?: string | Date) =>
  value ? dayjs(value).format('YYYY-MM-DDT12:00:00[Z]') : '';

export const toApiDateUtc = (value?: string | Date) =>
  value ? dayjs(value).format('YYYY-MM-DDT12:00:00') : '';
