import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const NotInterested: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 10.15 4.63 8.45 5.69 7.1L16.9 18.31C15.55 19.37 13.85 20 12 20ZM7.1 5.69L18.31 16.9C19.37 15.55 20 13.85 20 12C20 7.58 16.42 4 12 4C10.15 4 8.45 4.63 7.1 5.69Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
