import { useLocalStorage } from '@pro4all/shared/hooks';

import { ItemFields } from './types';
import { AddData } from './types';

export const useInitialValuesItem = () => {
  const { getLocalStorageItem: getEditHierarchyItemFromLs } =
    useLocalStorage<AddData>({
      key: `prostream-edit-hierarchy-item`,
    });

  const dataEditItem = getEditHierarchyItemFromLs();

  const initialValues: ItemFields = {
    name:
      dataEditItem && dataEditItem.sourceName ? dataEditItem.sourceName : '',
  };

  return initialValues;
};
