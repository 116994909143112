/* This context is for initializing the share flow:
 *  - Open/close modal
 *  - Set versionIds
 *  - Set form values: selected share method, password and link url
 *  - Proceed/reset
 * */

import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { Document, Member } from '@pro4all/graphql';
import { ShareType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { usePersistentState } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { tagLine } from '@pro4all/shared/ui/general';

import { ShareMain } from './ShareMain';

export interface GenerateLinkContextValue {
  closeModal?: (reason?: string) => void;
  composeMessage?: () => void;
  copyLink?: () => void;
  copyToSelf?: boolean;
  latestVersionOnly: boolean;
  link?: string;
  linkId?: string;
  members: Member[];
  message?: string;
  openShareModal?: () => void;
  password?: string;
  recipientGroups?: string[];
  recipientIds?: string[];
  reset?: () => void;
  resourceIds?: string[];
  selectedShareMethod?: ShareType;
  setCopyToSelf?: (value: boolean) => void;
  setLink?: (link: string) => void;
  setLinkId?: (linkId: string) => void;
  setMembers?: (members: Member[]) => void;
  setMessage?: (message: string) => void;
  setPassword?: (password: string) => void;
  setRecipientGroups?: (value: any) => void;
  setRecipientIds?: (recipients: string[]) => void;
  setResources?: (documents: Document[]) => void;
  setSelectedShareMethod?: (method: string) => void;
  setSubject?: (subject: string) => void;
  step?: number;
  subject?: string;
  toggleLatestVersionOnly?: () => void;
}

export const GenerateLinkContext = createContext(null);

export const useGenerateLinkContext = () =>
  useContext<GenerateLinkContextValue>(GenerateLinkContext);

export const GenerateLinkContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { copy } = useClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const { userEmail } = useOrganizationContext();

  const { searchParams } = useRouting();

  /*
   * Step 1: Generate link
   * Step 2: Compose message
   * Step 3: Enter recipient addresses and send (public link only)
   * */
  const [step, setStep] = useState(1);

  const [selectedShareMethod, setSelectedShareMethod] = useState<ShareType>(
    ShareType.Public
  );
  const [password, setPassword] = useState(null);
  const [link, setLink] = useState(null);
  const [linkId, setLinkId] = useState(null);
  const [recipientIds, setRecipientIds] = useState(null);
  const [recipientGroups, setRecipientGroups] = useState(null);
  const [copyToSelf, setCopyToSelf] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);

  const defaultSubject = t('{{email}} shared documents with you.', {
    email: userEmail,
  });
  const defaultMessage = t('I manage my documents with Prostream.');
  const [subject, setSubject] = useState(defaultSubject);
  const [message, setMessage] = useState(defaultMessage);
  const [latestVersionOnly, setLatestVersionOnly] = usePersistentState(
    shareLatestStorageKey,
    true
  );

  const [resources, setResources] = useState<Document[]>([]);
  const resourceIds = resources.map((resource) =>
    latestVersionOnly ? resource.versionId : resource.id
  );

  const toggleLatestVersionOnly = () =>
    setLatestVersionOnly((latestVersionOnly) => !latestVersionOnly);

  /* @Todo: call copy to self endpoint once it is available */
  const copyLink = () => {
    const composedMessage = `${subject} \n${message} \n\n${link} \n\n${tagLine}`;

    copy(composedMessage);
    enqueueSnackbar(t('Link has been copied'));
  };

  const reset = () => {
    setResources([]);
    setStep(0);
    setPassword(null);
    setLink(null);
    setLinkId(null);
    setRecipientIds(null);
    setSelectedShareMethod(ShareType.Public);
    setCopyToSelf(false);
  };

  const openShareModal = () => {
    setStep(1);
    searchParams.set('action', 'share-documents');
  };

  const closeModal = () => {
    setMessage(defaultMessage);
    searchParams.delete('action');
    reset();
  };

  const composeMessage = () => setStep(2);

  const value = {
    closeModal,
    composeMessage,
    copyLink,
    copyToSelf,
    latestVersionOnly,
    link,
    linkId,
    members,
    message,
    openShareModal,
    password,
    recipientGroups,
    recipientIds,
    reset,
    resourceIds,
    selectedShareMethod,
    setCopyToSelf,
    setLink,
    setLinkId,
    setMembers,
    setMessage,
    setPassword,
    setRecipientGroups,
    setRecipientIds,
    setResources,
    setSelectedShareMethod,
    setSubject,
    step,
    subject,
    toggleLatestVersionOnly,
  };

  return (
    <GenerateLinkContext.Provider value={value}>
      <ShareMain />
      {children}
    </GenerateLinkContext.Provider>
  );
};

const shareLatestStorageKey = 'prostream-share-latest-state';
