import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  Document,
  DocumentVersion,
  FinalizationState,
  useFinalizeDocumentsMutation,
  useUnfinalizeDocumentsMutation,
} from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';

export enum FinalizeAction {
  Finalize = 'finalize',
  Unfinalize = 'unfinalize',
}

interface Props {
  documents: Document[] | null;
  versions?: DocumentVersion[] | null;
}

export function useFinalize({ documents, versions }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [finalizeDocuments] = useFinalizeDocumentsMutation();
  const [unfinalizeDocuments] = useUnfinalizeDocumentsMutation();
  const updateCachedDocuments = useUpdateCachedDocuments();
  const { finalize_action_label } = useDocumentActionsLabels();

  const finalizeDocument = useCallback(async () => {
    const finalizableDocumentIds = documents?.length
      ? documents
          .filter(
            (document) =>
              document.state === FinalizationState.NotFinalized &&
              !document.isExpected
          )
          .map((document) => document.id)
      : versions?.length
      ? versions
          .filter((version) => version.state === FinalizationState.NotFinalized)
          .map((version) => version.documentId)
      : null;

    try {
      if (finalizableDocumentIds) {
        const { data } = await finalizeDocuments({
          variables: {
            documentIds: finalizableDocumentIds,
          },
        });
        const finalizeResponse =
          data && data.finalizeDocuments ? data.finalizeDocuments : null;
        if (
          finalizeResponse &&
          finalizeResponse.successful &&
          finalizeResponse.unsuccessful
        ) {
          if (
            finalizeResponse.successful.length > 0 &&
            finalizeResponse.unsuccessful.length === 0
          ) {
            enqueueSnackbar(
              t('{{name}} set for document version(s)', {
                name: finalize_action_label,
              })
            );
          } else if (
            finalizeResponse.successful.length > 0 &&
            finalizeResponse.unsuccessful.length > 0
          ) {
            enqueueSnackbar(
              t('Document(s) {{name}} set, some errors occured', {
                name: finalize_action_label,
              })
            );
          } else if (
            finalizeResponse.successful.length === 0 &&
            finalizeResponse.unsuccessful.length > 0
          ) {
            throw new Error();
          }

          updateCachedDocuments({
            documentIds: finalizeResponse?.successful,
            fieldToUpdate: 'state',
            keyField: 'id',
            value: FinalizationState.Finalized,
          });
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  }, [
    updateCachedDocuments,
    documents,
    enqueueSnackbar,
    finalizeDocuments,
    t,
    versions,
  ]);

  const unfinalizeDocument = useCallback(async () => {
    const unfinalizableDocumentIds = documents?.length
      ? documents
          .filter(
            (document) =>
              document.state === FinalizationState.Finalized &&
              !document.isExpected
          )
          .map((document) => document.id)
      : versions?.length
      ? versions
          .filter((version) => version.state === FinalizationState.Finalized)
          .map((version) => version.documentId)
      : null;

    try {
      if (unfinalizableDocumentIds) {
        const { data } = await unfinalizeDocuments({
          variables: {
            documentIds: unfinalizableDocumentIds,
          },
        });
        const unfinalizeResponse =
          data && data.unfinalizeDocuments ? data.unfinalizeDocuments : null;
        if (
          unfinalizeResponse &&
          unfinalizeResponse.successful &&
          unfinalizeResponse.unsuccessful
        ) {
          if (
            unfinalizeResponse.successful.length > 0 &&
            unfinalizeResponse.unsuccessful.length === 0
          ) {
            enqueueSnackbar(
              t('{{name}} withdrawn from version(s)', {
                name: finalize_action_label,
              })
            );
          } else if (
            unfinalizeResponse.successful.length > 0 &&
            unfinalizeResponse.unsuccessful.length > 0
          ) {
            enqueueSnackbar(
              t('Document(s) {{name}} set, some errors occured', {
                name: finalize_action_label,
              })
            );
          } else if (
            unfinalizeResponse.successful.length === 0 &&
            unfinalizeResponse.unsuccessful.length > 0
          ) {
            throw new Error();
          }

          updateCachedDocuments({
            documentIds: unfinalizeResponse?.successful,
            fieldToUpdate: 'state',
            keyField: 'id',
            value: FinalizationState.NotFinalized,
          });
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  }, [
    documents,
    enqueueSnackbar,
    t,
    unfinalizeDocuments,
    versions,
    updateCachedDocuments,
  ]);

  const finalizeOrUnfinalizeDocument = useCallback(
    (action: FinalizeAction): void => {
      switch (action) {
        case FinalizeAction.Finalize:
          finalizeDocument();
          break;
        case FinalizeAction.Unfinalize:
          unfinalizeDocument();
          break;
        default:
      }
    },
    [finalizeDocument, unfinalizeDocument]
  );

  const allDocumentsFinalized = useMemo(
    (): boolean =>
      documents?.length
        ? documents.every(
            (document) => document?.state === FinalizationState.Finalized
          )
        : versions?.length
        ? versions.every(
            (version) => version?.state === FinalizationState.Finalized
          )
        : false,
    [documents, versions]
  );

  const allDocumentsUnfinalized = useMemo(
    (): boolean =>
      documents?.length
        ? documents.every(
            (document) => document?.state === FinalizationState.NotFinalized
          )
        : versions?.length
        ? versions.every(
            (version) => version?.state === FinalizationState.NotFinalized
          )
        : false,
    [documents, versions]
  );

  const someDocumentsFinalized = useMemo(
    (): boolean =>
      documents?.length
        ? documents.some(
            (document) => document?.state === FinalizationState.Finalized
          )
        : versions?.length
        ? versions.some(
            (version) => version?.state === FinalizationState.Finalized
          )
        : false,
    [documents, versions]
  );

  const someDocumentsUnfinalized = useMemo(
    (): boolean =>
      documents?.length
        ? documents.some(
            (document) => document?.state === FinalizationState.NotFinalized
          )
        : versions?.length
        ? versions.some(
            (version) => version?.state === FinalizationState.NotFinalized
          )
        : false,
    [documents, versions]
  );

  return {
    allDocumentsFinalized,
    allDocumentsUnfinalized,
    finalizeOrUnfinalizeDocument,
    someDocumentsFinalized,
    someDocumentsUnfinalized,
  };
}
