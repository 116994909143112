import React from 'react';

import type { Document } from '@pro4all/graphql';
import { StampStatus } from '@pro4all/graphql';
import { Icon } from '@pro4all/shared/ui/icons';

function getIcon(
  qrCodeState?: StampStatus | null,
  hasPreviousVersionQr?: boolean | null
) {
  if (hasPreviousVersionQr && qrCodeState !== StampStatus.Progress) return 'qr';

  switch (qrCodeState) {
    case StampStatus.Done:
      return 'qr';
    case StampStatus.Failed:
      return 'warning';
    case StampStatus.Progress:
      return 'sync';
    default:
      return null;
  }
}

export const QRCodeStatus: React.FC<
  Document & { children?: never } & { isForVersionTable?: boolean }
> = ({ qrCodeState, hasPreviousVersionQr, isForVersionTable }) => {
  const icon = getIcon(qrCodeState, hasPreviousVersionQr);
  return icon &&
    (!isForVersionTable ||
      qrCodeState === StampStatus.Progress ||
      qrCodeState === StampStatus.Done) ? (
    <Icon
      htmlColor="#848484"
      iconName={icon}
      opacity={
        hasPreviousVersionQr &&
        qrCodeState !== StampStatus.Done &&
        qrCodeState !== StampStatus.Progress
          ? '40%'
          : '100%'
      }
    />
  ) : null;
};
