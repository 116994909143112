import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Group, useAddGroupsToProjectsMutation } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/general';

export interface FormFields {
  projects: Option[];
}

export const useAddGroupsToProjects = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [addGroupsToProjects] = useAddGroupsToProjectsMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<Group>();

  return async ({
    onClose,
    values,
  }: {
    onClose: () => void;
    values: FormFields;
  }) => {
    try {
      await addGroupsToProjects({
        variables: {
          groupIds: checkedRows.map((group) => group.id),
          projectIds: values.projects.map((project) => project.id),
        },
      });

      enqueueSnackbar(t('Selected groups added to selected projects'));
      onClose();
      uncheckAllRows();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
