import { createGlobalStyle } from 'styled-components';

// from: https://gist.github.com/MoOx/9137295
// with modifications
export const Reset = createGlobalStyle`
  html {
    font-size: 14px;
  }

  button {
    width: auto;
    margin: 0;
    padding: 0;
    overflow: visible;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
    line-height: normal;
    background: transparent;
    border: none;
    outline: none;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable 'input' types in iOS */
    appearance: none;

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }
  }
`;
