import React from 'react';

import { PredefinedFilter } from '@pro4all/shared/ui/filtering';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

export const TasksActionBar: React.FC<{
  dataViewToggle?: boolean;
  predefinedFilterSelect?: React.ReactNode;
  predefinedFilters?: PredefinedFilter[];
  search?: boolean;
}> = ({ dataViewToggle, predefinedFilters, search = true }) => (
  <TableActionBar
    dataTestid="my-tasks-action-bar"
    dataViewToggle={dataViewToggle}
    predefinedFilters={predefinedFilters}
    search={search}
  />
);
