import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { usePermissionsFolderMatrixContext } from '@pro4all/documents/ui/folders';
import { useSetFoldersPermissionsForUserMutation } from '@pro4all/graphql';
import { addRequiredPermissions } from '@pro4all/shared/permissions';
import { useGenericContext } from '@pro4all/shared/providers';
import { useShowMessages } from '@pro4all/shared/ui/messages';

export const useSubmit = (userId: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [setFoldersPermissions] = useSetFoldersPermissionsForUserMutation();
  const { showSingleError } = useShowMessages();

  const {
    updatePermissionsAfterSave,
    state: { savePermissions },
    toggleBackdrop,
  } = usePermissionsFolderMatrixContext();

  const { togglePermissionsSaved } = useGenericContext();

  const onSubmit = async () => {
    toggleBackdrop();

    try {
      const variables = {
        folders: savePermissions.map((folder) => ({
          breakInheritance: folder.breakInheritance,
          folderId: folder.folderId,
          permissions: addRequiredPermissions(folder.folderPermissions),
        })),
        userId,
      };
      const response = await setFoldersPermissions({
        variables,
      });

      const { successful, unsuccessful } =
        response?.data?.setFoldersPermissionsForUser || {};
      if (unsuccessful.length === 0) {
        enqueueSnackbar(t('Permissions successfully saved'));
      } else if (unsuccessful.length === 1) {
        // Toast single
        const folderName =
          savePermissions.filter(
            (folder) => folder.folderId === unsuccessful[0]
          )[0]?.name || '';
        enqueueSnackbar(
          `${t("Permissions for '{{folderName}}' not properly saved.", {
            folderName,
          })} ${t('Please try again')}.`
        );
      } else if (unsuccessful.length > 1) {
        // Toast multiple
        enqueueSnackbar(
          `${t('Permissions for {{count}} folders not properly saved.', {
            count: unsuccessful.length,
          })} ${t('Please try again')}.`
        );
      }

      updatePermissionsAfterSave({ successful, unsuccessful });

      setTimeout(() => {
        toggleBackdrop();
        togglePermissionsSaved();
      }, 500); // A little delay else it could go too quick and that would cause an annoying flicker on the screen.
    } catch (e) {
      showSingleError(e);
    }
  };

  return onSubmit;
};
