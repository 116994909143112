import React from 'react';
import { BaseEditor, Editor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { Icon, IconName } from '@pro4all/shared/ui/icons';

import {
  isModeType,
  isTextAlign,
  modeTypes,
  textAlignOptions,
} from '../slate-editor/CustomTypes';

import { EditorButton } from './EditorButton';

interface Props {
  format: textAlignOptions | modeTypes;
  icon: IconName;
  title?: string;
}

export const BlockButton = ({ format, icon, title }: Props) => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isBlockActive(
        editor,
        format,
        isTextAlign(format) ? 'align' : 'mode'
      )}
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      title={title}
    >
      <Icon iconName={icon} />
    </EditorButton>
  );
};

const toggleBlock = (
  editor: BaseEditor & ReactEditor,
  format: textAlignOptions | modeTypes
) => {
  const isActive = isBlockActive(
    editor,
    format,
    isTextAlign(format) ? 'align' : 'mode'
  );

  let newProperties: Partial<SlateElement>;
  if (isTextAlign(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);
  }

  if (isModeType(format)) {
    newProperties = {
      mode: isActive ? undefined : format,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);
  }
};

const isBlockActive = (
  editor: BaseEditor & ReactEditor,
  format: string,
  type: 'align' | 'mode'
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n[type] === format,
    })
  );

  return Boolean(match);
};
