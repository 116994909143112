import { FormikErrors, FormikValues } from 'formik';

export interface Props {
  dirty?: boolean;
  errors: FormikErrors<FormikValues>;
  isSubmitting: boolean;
}

export const isSubmitDisabled = ({
  dirty = true,
  errors,
  isSubmitting,
}: Props): boolean => {
  const isValid =
    Object.keys(errors).length === 0 && errors.constructor === Object;
  return isSubmitting || !dirty || !isValid;
};
