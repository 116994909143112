import { SchemaExtender } from '@pro4all/shared/forms';

import { useCustomHierarchyListValidationSchema } from './CustomHierarchyList';
import { useCustomNumberValidationSchema } from './CustomNumber';
import { useCustomStatusValidationSchema } from './CustomStatus';
import { useCustomSelectionValidationSchema } from './useCustomSelectionValidationSchema';

export const useCustomValidation = (): Record<string, SchemaExtender> => {
  // Type related validation schemas are considered as customValidation.

  const customHierarchyListValidationSchema =
    useCustomHierarchyListValidationSchema();
  const customNumberValidationSchema = useCustomNumberValidationSchema();
  const customStatusValidationSchema = useCustomStatusValidationSchema();
  const customSelectionValidationSchema = useCustomSelectionValidationSchema();

  const customValidation = {
    ...customHierarchyListValidationSchema,
    ...customNumberValidationSchema,
    ...customStatusValidationSchema,
    ...customSelectionValidationSchema,
  };

  return customValidation;
};
