import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  LoadQueriesFragmentFragmentDoc,
  SavedSearch,
  useRestoreQueryMutation,
} from '@pro4all/graphql';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const useOptimisticRestore = ({
  name,
  id,
}: {
  id: string;
  name: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [restoreSavedSearch] = useRestoreQueryMutation({
    onCompleted: () =>
      enqueueSnackbar(
        <ItemChangedMessage
          description={MessageAction.Restore}
          entityName={name}
          entityTypeTranslation={EntityTypeTranslation.SavedSearch}
        />,
        { variant: 'success' }
      ),
    onError: () =>
      enqueueSnackbar(t('Something went wrong'), { variant: 'error' }),
    optimisticResponse: {
      restoreQuery: {
        errors: [],
        name,
        success: true,
      },
    },
    update: (cache) => {
      const data: SavedSearch | null = cache.readFragment({
        fragment: LoadQueriesFragmentFragmentDoc,
        id: `SavedSearch:${id}`,
        optimistic: true,
      });
      if (data) {
        cache.modify({
          broadcast: false,
          fields: {
            deletedAt() {
              return null;
            },
          },
          id: cache.identify(data),
        });
      }
    },
    variables: { id, name },
  });

  return id ? restoreSavedSearch : () => false;
};
