import { Box } from '@pro4all/shared/mui-wrappers';

import { MetadataTabs } from '../../components/tabs/MetadataTabs';

import { TemplatesTasksMain } from './TemplatesTasksMain';

export const TemplatesTasks = () => (
  <Box display="flex" flexDirection="column" flexGrow={1}>
    <MetadataTabs base={null} />
    <TemplatesTasksMain />
  </Box>
);
