// TODO: #20314 - Fix module resolution issue to be able to import from other projects
// We made a copy of it from the shared project as a temporary solution
// until we can be able to import it from there

export const sort = <T>(a?: T, b?: T) => {
  // Empty values last
  if (a === undefined || a === null) return 1;
  if (b === undefined || b === null) return -1;

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b, undefined, { numeric: true });
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  return a < b ? -1 : 1;
};

type SortOrder = 'asc' | 'desc';

export const sortBy =
  <T>({ key, sortOrder = 'asc' }: { key: keyof T; sortOrder?: SortOrder }) =>
  (a: T, b: T) =>
    sortOrder === 'asc' ? sort(a[key], b[key]) : sort(b[key], a[key]);

export const sortByMethod =
  <T>(getData: (item: T) => string | number) =>
  (a: T, b: T) =>
    sort(getData(a), getData(b));

export const sortByWithInnerSort =
  <T>({
    mainProp,
    mainPropSortOrder = 'asc',
    subProp,
    subPropSortOrder = 'asc',
  }: {
    mainProp: keyof T;
    mainPropSortOrder?: SortOrder;
    subProp: keyof T;
    subPropSortOrder?: SortOrder;
  }) =>
  (a: T, b: T) => {
    const mainPropA = a[mainProp];
    const mainPropB = b[mainProp];
    const mainPropComparison =
      typeof mainPropB === 'number' && typeof mainPropA === 'number'
        ? mainPropSortOrder === 'asc'
          ? mainPropA - mainPropB
          : mainPropB - mainPropA
        : mainPropSortOrder === 'asc'
        ? mainPropA.toString().localeCompare(mainPropB.toString())
        : mainPropB.toString().localeCompare(mainPropA.toString());

    const subPropA = a[subProp];
    const subPropB = b[subProp];
    const subPropComparison =
      typeof subPropB === 'number' && typeof subPropA === 'number'
        ? subPropSortOrder === 'asc'
          ? subPropA - subPropB
          : subPropB - subPropA
        : subPropSortOrder === 'asc'
        ? subPropA.toString().localeCompare(subPropB.toString())
        : subPropB.toString().localeCompare(subPropA.toString());

    return mainPropComparison !== 0 ? mainPropComparison : subPropComparison;
  };
