import { toReference } from '@pro4all/communication/data-access';
import { Reference, ReferenceKind, ReferenceType } from '@pro4all/graphql';

export const createReferenceFromExternalMember = (
  label: string,
  referenceKind: ReferenceKind
): Reference =>
  toReference({
    referenceData: null,
    referenceId: null,
    referenceKind,
    referenceType: ReferenceType.Email,
    referenceValue: label,
  });
