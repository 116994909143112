import React, { PropsWithChildren, useEffect, useState } from 'react';
import { NestedMenuItem } from 'mui-nested-menu';

import { MenuProps } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';

import * as Styled from './ContextMenu.styled';
import { Position } from './types';

export interface ContextMenuProps {
  initialPosition?: Position;
  menuItems?: ActionProps[];
  onClose?: () => void;
  open: boolean;
  variant?: MenuProps['variant'];
}

export const OFFSET_X = -2;
export const OFFSET_Y = -4;

export const ContextMenu = ({
  children,
  open,
  onClose,
  menuItems,
  initialPosition = { left: 0, top: 0 },
  variant,
}: PropsWithChildren<ContextMenuProps>) => {
  const [position, setPosition] = useState<Position>(initialPosition);

  const preventAndClose = (event: React.MouseEvent) => {
    event.preventDefault();
    onClose && onClose();
  };

  const setMousePosition = (e: MouseEvent) => {
    setPosition({
      left: e.clientX + OFFSET_X,
      top: e.clientY + OFFSET_Y,
    });
  };

  useEffect(() => {
    setPosition({ left: initialPosition.left, top: initialPosition.top });
  }, [initialPosition.left, initialPosition.top]);

  useEffect(() => {
    window.addEventListener('contextmenu', setMousePosition);
    return () => {
      window.removeEventListener('contextmenu', setMousePosition);
    };
  });

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const showMenu =
    Boolean(menuItems?.length) || Boolean(React.Children.count(children));

  const processMenuItems = (menuItems: ActionProps[]) =>
    menuItems
      .filter((menuItem) => !menuItem.disabled && !menuItem.hidden)
      .map(
        ({
          ariaLabel,
          actuallyDisabled,
          endIcon,
          key,
          label,
          onClick,
          startIcon,
          subItems,
        }) => {
          const startIconAndLabel = (
            <>
              {startIcon && <Icon color="inherit" iconName={startIcon} />}
              <Styled.Label className="Label">{label}</Styled.Label>
            </>
          );
          const leftIcon = startIcon ? (
            <Icon color="inherit" iconName={startIcon} />
          ) : null;

          return subItems?.length ? (
            <NestedMenuItem
              key={key}
              label={label}
              leftIcon={leftIcon}
              parentMenuOpen
            >
              {processMenuItems(subItems)}
            </NestedMenuItem>
          ) : (
            <Styled.MenuItem
              aria-label={ariaLabel}
              data-testid={`${key}`}
              disabled={actuallyDisabled}
              key={key}
              onClick={(event) => {
                onClose && onClose();
                onClick && onClick(event);
              }}
            >
              {startIconAndLabel}
              {endIcon && <Icon color="inherit" iconName={endIcon} />}
            </Styled.MenuItem>
          );
        }
      );

  return showMenu ? (
    <Styled.Menu
      anchorPosition={position}
      anchorReference="anchorPosition"
      data-testid="context-menu"
      id="folder-menu"
      onClick={stopPropagation}
      onClose={onClose}
      onContextMenu={preventAndClose}
      open={open}
      variant={variant}
    >
      {menuItems ? processMenuItems(menuItems) : children}
    </Styled.Menu>
  ) : null;
};

export default ContextMenu;
