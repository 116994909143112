import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Connection,
  IntegrationWithConnection,
  useConnectionsQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import {
  Column,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoConnections } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { DeleteConnectionForm } from '../delete/DeleteConnectionForm';
import { ConnectionForm } from '../form/ConnectionForm';
import { useGetIntegrationTypeData } from '../form/useGetIntegrationTypeData';

import { ConnectionsActionBar } from './ConnectionsActionBar';
import ConnectionsListMobile from './ConnectionsListMobile';
import { useColumnsConnections } from './useColumnsConnections';
import { useConnectionsActions } from './useConnectionsActions';

export const ConnectionsTable = () => {
  const { t } = useTranslation();
  const { params, searchParams } = useRouting();
  const { projectId } = params;

  const isMobileScreen = useIsMobileScreen();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<IntegrationWithConnection>();

  const { data, loading, error } = useConnectionsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    variables: { projectId: projectId || '' },
  });

  const connections = useMemo(
    () => data?.connections || [],
    [data?.connections]
  ) as IntegrationWithConnection[];

  const editConnection = searchParams.is('action', 'editConnection');
  const deleteConnection = searchParams.is('action', 'deleteConnection');

  useSetItemsInLocalState<IntegrationWithConnection>(connections);

  const { altActions } = useConnectionsActions();
  const columnsConnections = useColumnsConnections();

  const onClose = () => searchParams.clear();

  const { integrationTypeIcon, integrationTypeLabel } =
    useGetIntegrationTypeData();

  return (
    <>
      <TableContextProvider
        checkable={false}
        columns={columnsConnections}
        id="table-connections"
        items={items}
      >
        <FilterContextProvider<Connection>>
          <Column>
            <ResponseWrapper error={error} isLoading={!data && loading}>
              <ConnectionsActionBar />
              {items.length === 0 && itemsInitial.length === 0 ? (
                <BigMessageNoConnections />
              ) : (
                <Box flex="auto">
                  {isMobileScreen ? (
                    <ConnectionsListMobile integrationWithConnections={items} />
                  ) : (
                    <Table contextMenuActions={altActions} />
                  )}
                </Box>
              )}
            </ResponseWrapper>
          </Column>
        </FilterContextProvider>
      </TableContextProvider>
      <FloatingModal
        data-testid="edit-connection-modal"
        onClose={onClose}
        open={editConnection}
      >
        <FloatingModal.Header iconName={integrationTypeIcon}>
          {t('Connect with {{integrationType}}', {
            integrationType: integrationTypeLabel,
          })}
        </FloatingModal.Header>
        <ConnectionForm onClose={onClose} />
      </FloatingModal>
      <FloatingModal
        data-testid="delete-connection-modal"
        onClose={onClose}
        open={deleteConnection}
      >
        <FloatingModal.Header iconName="delete">
          {t('Delete connection')}
        </FloatingModal.Header>
        <DeleteConnectionForm onClose={onClose} />
      </FloatingModal>
    </>
  );
};
