import { BasePropsHierarchyItem, ItemProps } from '../types';

import { addItemAction } from './addItemAction';

export const addItemsAction = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  newItems,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'newItems'>) => {
  let updatedItems = allItems;

  newItems.forEach((item) => {
    updatedItems = addItemAction<HierarchyItem>({
      allItems: updatedItems,
      newItem: item,
    });
  });

  return updatedItems;
};
