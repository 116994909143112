import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DialogContent, DialogTitle } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { IconName } from '@pro4all/shared/ui/icons';
import { TextProps } from '@pro4all/shared/ui/typography';

import { Footer } from '../footer';

import * as Styled from './Dialog.styles';

export interface DialogProps {
  additionalActions?: JSX.Element;
  buttonColor?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  closeIcon?: IconName;
  closeLabel?: string;
  confirmIcon?: IconName;
  confirmLabel?: string;
  discard?: boolean;
  iconName?: IconName;
  name?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onSubmit?: (() => void) | undefined;
  open?: boolean;
  submitDisabled?: boolean;
  submitLabel?: string;
  title: React.ReactNode;
  titleVariant?: TextProps['variant'];
}

export const Dialog: React.FC<DialogProps> = ({
  additionalActions,
  buttonColor,
  children,
  closeLabel,
  confirmLabel,
  discard = false,
  iconName, // left in case UX decide they really want icons
  name,
  onClose,
  onConfirm,
  onSubmit,
  open = false,
  submitDisabled = false,
  submitLabel,
  title,
  titleVariant = 'h2',
}) => {
  const { t } = useTranslation();
  return (
    <Styled.DialogContainer onClose={onClose} open={open}>
      <DialogTitle variant={titleVariant}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <Footer $cancelLeft $sticky>
        {onClose && (
          <Button
            color="inherit"
            data-testid={name ? `close-${name}` : undefined}
            onClick={onClose}
            variant="text"
          >
            {closeLabel || t('Cancel')}
          </Button>
        )}
        {additionalActions}
        {onConfirm && (
          <StyledButton
            $color={buttonColor}
            autoFocus
            color={discard ? 'error' : 'primary'}
            data-testid="confirm"
            onClick={onConfirm}
            variant={discard || onSubmit ? 'outlined' : 'contained'}
          >
            {confirmLabel || t('Confirm')}
          </StyledButton>
        )}
        {onSubmit && (
          <StyledButton
            $color={buttonColor}
            autoFocus
            data-testid="submit"
            disabled={submitDisabled}
            onClick={onSubmit}
            variant="contained"
          >
            {submitLabel || t('Save')}
          </StyledButton>
        )}
      </Footer>
    </Styled.DialogContainer>
  );
};

const StyledButton = styled(Button)<{
  $color?: string;
}>`
  && {
    ${({ $color }) => $color && `background-color: ${$color};`}
  }
`;
