import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { FolderPermission } from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';

import { getThisFolderPermission } from '../context/helpers/getThisFolderPermission';
import { usePermissionsFolderMatrixContext } from '../context/PermissionsFolderMatrixProvider';
import { Permission } from '../context/types';

export const useToggleBreakInheritance = (folderId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { approve_action_label, reject_action_label, finalize_action_label } =
    useDocumentActionsLabels();
  const {
    state: { displayPermissions },
    toggleBreakInheritance,
  } = usePermissionsFolderMatrixContext();

  const currentFolder = getThisFolderPermission(folderId, displayPermissions);

  const getParentWithFinalize = (
    folder: Permission,
    permissions: Permission[]
  ): Permission | null => {
    if (!folder.parentFolderId) {
      return null;
    }

    const currentParentFolder = folderId
      ? permissions.find((perm) => perm.folderId === folder.parentFolderId)
      : null;

    const parentHasInvalidPermission =
      currentParentFolder.folderPermissions.includes(
        FolderPermission.FinalizeContent
      );

    if (parentHasInvalidPermission) {
      return currentParentFolder;
    }

    return getParentWithFinalize(currentParentFolder, permissions);
  };

  const getParentWithApprove = (
    folder: Permission,
    permissions: Permission[]
  ): Permission | null => {
    if (!folder.parentFolderId) {
      return null;
    }

    const currentParentFolder = permissions.find(
      (perm) => perm.folderId === folder.parentFolderId
    );

    const parentHasInvalidPermission =
      currentParentFolder.folderPermissions.includes(
        FolderPermission.ApproveContent
      );

    if (parentHasInvalidPermission) {
      return currentParentFolder;
    }

    return getParentWithApprove(currentParentFolder, permissions);
  };

  const handleToggleBreakInheritance = () => {
    const currentReadOnlyOwnAndFinalized = [
      FolderPermission.ReadOwn,
      FolderPermission.ReadOwnAndFinalized,
    ].some((permission) =>
      currentFolder.folderPermissions.includes(permission)
    );

    const currentOnlyApprovedOrLastApproved = [
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastApproval,
    ].some((permission) =>
      currentFolder.folderPermissions.includes(permission)
    );

    const parentWithFinalize = getParentWithFinalize(
      currentFolder,
      displayPermissions
    );
    const parentWithApprove = getParentWithApprove(
      currentFolder,
      displayPermissions
    );

    if (currentReadOnlyOwnAndFinalized && parentWithFinalize) {
      enqueueSnackbar(
        t(
          "Unable to unbreak inheritance with the '{{name}} documents' permission active on parent '{{parentFolderName}}'.",
          {
            name: finalize_action_label,
            parentFolderName: parentWithFinalize.name,
          }
        )
      );
      return;
    }

    if (currentOnlyApprovedOrLastApproved && parentWithApprove) {
      enqueueSnackbar(
        t(
          "Unable to unbreak inheritance with the '{{name}} documents' permission active on parent '{{parentFolderName}}'.",
          {
            name: approve_action_label,
            parentFolderName: parentWithApprove.name,
          }
        )
      );
      return;
    }

    toggleBreakInheritance(folderId);
  };

  return handleToggleBreakInheritance;
};
