import React from 'react';
import styled from 'styled-components';

import { Box, Slide, Snackbar } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

const StyledBox = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    width: 500px;
    background-color: ${customColors.white};
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(88, 88, 91, 0.16);
  }
`;

interface QuickActionProps {
  headerText: string;
  input?: React.ReactNode;
  open: boolean;
}

export const QuickAction: React.FC<QuickActionProps> = ({
  headerText,
  open,
  input,
}) => (
  <Snackbar
    TransitionComponent={Slide}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    data-testid="actionbar"
    open={open}
  >
    <StyledBox>
      <Box m={2}>
        <Text variant="h3">{headerText}</Text>
      </Box>
      {input}
    </StyledBox>
  </Snackbar>
);
