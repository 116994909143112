import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { ShareSnagstreamForm } from './ShareSnagstreamForm';

export const ShareSnagstreamModal = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const onClose = () => searchParams.delete('action');
  const editConnection = searchParams.is('action', 'sharetosnagstream');

  return (
    <FloatingModal
      data-testid="edit-connection-modal"
      onClose={onClose}
      open={editConnection}
    >
      <FloatingModal.Header iconName="snagstream">
        {t('Share to Snagstream')}
      </FloatingModal.Header>
      <ShareSnagstreamForm onClose={onClose} />
    </FloatingModal>
  );
};
