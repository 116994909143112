import styled from 'styled-components';

export const ContentWrap = styled.div<{ $enabled?: boolean; $error?: boolean }>`
  color: ${({ $enabled }) => ($enabled ? '#585858' : '#DDDDDD')};
`;

export const Form = styled.form`
  display: flex;
  margin-top: 22px;
  flex-direction: column;
  min-width: 720px;
  min-height: 160px;
  gap: 28px;
`;
