import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useContextScopedOrganizationId = () => {
  const { params } = useRouting();
  const { projectOrganizationId } = useProjectContext();
  const { userOrganizationId } = useOrganizationContext();

  // Use this for scenario's where the underlying component accesses
  // organizationId and is accessed both by Organization and Project specific code
  const getContextScopedOrganizationId = (): string =>
    params.projectId ? projectOrganizationId : userOrganizationId;

  return getContextScopedOrganizationId;
};
