import { useMemo, useState } from 'react';
import {
  SetFieldValue,
  SetValueConfig,
  UnpackNestedValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  documentToAttachment,
  useCollectionFolder,
} from '@pro4all/documents/ui/folders';
import {
  Document,
  DocumentVersion,
  useDocumentsLazyQuery,
  useVersionsIncludeLazyQuery,
} from '@pro4all/graphql';
import { isDefined, tryDecodeString } from '@pro4all/shared/utils';

import { MessageFormBase } from '../types';

export function useAddCollection<T>() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddingCollection, setIsAddingCollection] = useState(false);

  const { getDocumentsIds } = useCollectionFolder();
  // To avoid unnecessary api calls
  const documentIds = useMemo(() => getDocumentsIds(), [getDocumentsIds]);

  const [getDocumentsCollection] = useDocumentsLazyQuery({
    variables: { documentIds: documentIds },
  });

  const [getVersions] = useVersionsIncludeLazyQuery();

  const addColectionFiles = async (
    setValue: (
      name: string,
      value: SetFieldValue<T>,
      config?: SetValueConfig
    ) => void,
    getValues: () => UnpackNestedValue<MessageFormBase>
  ) => {
    setIsAddingCollection(true);

    const data = (await getDocumentsCollection()).data;
    const docs = data?.documents?.filter(isDefined) as Document[];

    if (!docs.length) {
      setIsAddingCollection(false);
      enqueueSnackbar(t('You have no documents in the collection folder'));
    } else {
      const { data: dataVersions } = await getVersions({
        fetchPolicy: 'cache-and-network',
        variables: {
          ids: docs.map(({ versionId }) => versionId).filter(isDefined),
          includeFileSize: true,
        },
      });

      const oldAttachments = getValues().attachments;

      // Makes sure file names are not already present in Form 'attachments'
      const uploadedFiles = (oldAttachments || []).map((at) =>
        tryDecodeString(at.fileName)
      );

      const nextFiles = docs.filter(
        (document) => !uploadedFiles.includes(document.name)
      );
      if (!nextFiles.length) return;

      const attachments = nextFiles?.map((document) =>
        documentToAttachment(
          document,
          dataVersions?.versions as DocumentVersion[]
        )
      );

      const resolved = await Promise.all(attachments);
      setIsAddingCollection(false);
      setValue('attachments', [...(oldAttachments || []), ...resolved]);
    }
  };

  return { addColectionFiles, isAddingCollection };
}
