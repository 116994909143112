import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Project } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/general';

import { isExpired } from '../utils';

export function useProjectActions() {
  const { hasAppPermission } = useAppPermissions();
  const { goTo, searchParams } = useRouting();
  const { contextMenuRow: project } = useTableContextMenuRowContext<Project>();

  const isMyProjectsRoute = Boolean(useRouteMatch(Routes.myProjects));

  const { id, name, organization } = project || {};

  const { t } = useTranslation();

  const { userOrganizationId } = useOrganizationContext();

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('New project'),
      dataTestId: 'new-project',
      disabled:
        !hasAppPermission('ProjectCreate') ||
        isMyProjectsRoute ||
        isExpired(organization?.expireDate),
      key: 'new-project',
      label: t('New project'),
      onClick: () => searchParams.set('action', 'createProject'),
      startIcon: 'projectCreate',
    },
  ];

  const editButton: ActionProps = {
    ariaLabel: t('Edit'),
    dataTestId: 'edit-project',
    disabled:
      !hasAppPermission('ProjectUpdate') ||
      isExpired(organization?.expireDate) ||
      organization?.id !== userOrganizationId,
    key: 'edit-project',
    label: t('Edit'),
    onClick: () =>
      goTo('projectSettingsProject', { params: { projectId: id } }),
    startIcon: 'edit',
  };

  const deleteButton: ActionProps = {
    ariaLabel: t('Delete'),
    dataTestId: 'delete-project',
    disabled:
      !hasAppPermission('ProjectDelete') || isExpired(organization?.expireDate),
    key: 'delete-project',
    label: t('Delete'),
    onClick: () => searchParams.set({ action: 'deleteProject', id, name }),
    startIcon: 'delete',
  };

  const altActions = [editButton, deleteButton];

  return { altActions, mainActions };
}
