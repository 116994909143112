import { IntegrationType } from '@pro4all/graphql';

import { ApplicationProps } from './types';

export const getInitialValues = (applicationOptions: ApplicationProps[]) => {
  const customBuild12InitialValues = {
    apiKey: '',
    username: '',
  };

  const customSnagstreamInitialValues = {
    username: '',
  };

  // Initial value is a concatenation of the basic initial values and the type related initial values.
  return {
    // application: null as unknown as ApplicationProps, //TODO: After we have more integration types enable this codeline.
    application: applicationOptions.find(
      (option) => option.id === IntegrationType.Integration12Build
    ),
    ...customBuild12InitialValues,
    ...customSnagstreamInitialValues,
  };
};
