import { useStayInCurrentOrganizationMutation } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';

export const useActionStayInCurrentOrganization = () => {
  const [stay] = useStayInCurrentOrganizationMutation();
  const { showSingleError } = useShowMessages();
  const { goTo } = useRouting();
  const stayInCurrentOrganization = async () => {
    try {
      const token = sessionStorage.getItem(StorageKeys.MOVE_ORG_TOKEN);
      await stay({
        variables: {
          token,
        },
      });
      goTo('myOverview');
    } catch (e) {
      showSingleError(e);
    }
  };

  return stayInCurrentOrganization;
};
