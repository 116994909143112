import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';

// This hook is used to determine if the current user is an admin in the current context.
// It takes into account:
// 1. Organization versus Project context.
// 2. Internal versus External user.
export const useIsAdminInContext = () => {
  const {
    params: { projectId },
  } = useRouting();

  const { userIsOrganizationAdmin } = useOrganizationContext();
  const { isExplicitProjectAdmin, isExternalUserInProject } =
    useProjectContext();

  if (projectId) {
    // Project context
    if (isExplicitProjectAdmin) return true;
    if (userIsOrganizationAdmin && !isExternalUserInProject) return true;
  } else {
    // Organization context
    return userIsOrganizationAdmin;
  }

  return false;
};
