import React from 'react';
import { Route } from 'react-router-dom';

import { useIsAdminInContext } from '@pro4all/shared/identity';
import { Column, Main } from '@pro4all/shared/ui/general';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { ScreenFieldDefinitions } from '../components/screens/ScreenFieldDefinitions';
import { ScreenReportConfigurations } from '../components/screens/ScreenReportConfigurations';
import { ScreenTemplates } from '../components/screens/ScreenTemplates';
import { MetadataTabs } from '../components/tabs/MetadataTabs';

import { useIncludeOrgItems } from './useIncludeOrgItems';

export const MetaDataMain = ({
  isProject = false,
  pathFields,
  pathReportConfigurations = [],
  pathTemplates,
}: {
  base: string;
  isProject?: boolean;
  pathFields: string | string[];
  pathReportConfigurations?: string | string[];
  pathTemplates: string | string[];
}) => {
  const includeOrgItems = useIncludeOrgItems();
  const isAdminInContext = useIsAdminInContext();

  return (
    <>
      <MetadataTabs base={null} />
      <Main>
        <Column>
          <Route
            exact
            path={pathTemplates}
            render={() =>
              isAdminInContext ? (
                <ScreenTemplates
                  includeOrgItems={includeOrgItems}
                  isProject={isProject}
                />
              ) : (
                <BigMessageNoAccess />
              )
            }
          />
          <Route
            exact
            path={pathFields}
            render={() =>
              isAdminInContext ? (
                <ScreenFieldDefinitions
                  includeOrgItems={includeOrgItems}
                  isProject={isProject}
                />
              ) : (
                <BigMessageNoAccess />
              )
            }
          />
          <Route
            exact
            path={pathReportConfigurations}
            render={() =>
              isAdminInContext ? (
                <ScreenReportConfigurations isProject={isProject} />
              ) : (
                <BigMessageNoAccess />
              )
            }
          />
        </Column>
      </Main>
    </>
  );
};
