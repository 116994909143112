import { FolderPermission } from '@pro4all/graphql';

import { hasConflictWithApprovePermission } from '../../shared/helpers/hasConflictWithApprovePermission';
import { hasConflictWithFinalizePermission } from '../../shared/helpers/hasConflictWithFinalizePermission';
import { EntityPermission, State } from '../types';

import { getThisEntityPermission } from './getThisEntityPermission';

export const inConflictWithPermissionsOfOwnEntity = ({
  entityId,
  permission,
  state,
}: {
  entityId: string;
  permission: FolderPermission;
  state: State;
}) => {
  let response = false;

  const { displayPermissions } = state;

  // Check own entity.
  const thisEntity = getThisEntityPermission(entityId, displayPermissions);
  if (thisEntity) {
    response = checkPermissions(thisEntity, permission);
  }

  return response;
};

const checkPermissions = (
  entity: EntityPermission,
  permission: FolderPermission
) => {
  let response = false;

  if (!response && permission === FolderPermission.FinalizeContent) {
    response = hasConflictWithFinalizePermission({
      currentPermissions: [FolderPermission.FinalizeContent],
      newPermissions: entity.folderPermissions,
    });
  }

  if (!response && permission === FolderPermission.ApproveContent) {
    // Only continue checking if conflicts have not been found yet.
    response = hasConflictWithApprovePermission({
      currentPermissions: [FolderPermission.ApproveContent],
      newPermissions: entity.folderPermissions,
    });
  }

  return response;
};
