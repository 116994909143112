import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const TBQMainGreen: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 18 20"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25977 6.5L8.75977 3L14.2598 6.5L16.903 4.6707L8.72841 0L0.313018 4.80832L3.25977 6.5Z"
        fill="#19A49A"
      />
      <path
        d="M14.7598 14V7.14115L17.4621 5.32128V15.1655L9.08844 20V17L14.7598 14Z"
        fill="#19A49A"
      />
      <path
        d="M8.25977 17L2.75977 14V7.14115L0 5.63431V15.2023L8.24053 19.96L8.25977 17Z"
        fill="#19A49A"
      />
    </svg>
  </SvgIcon>
);
