import { useRouting } from '@pro4all/shared/routing-utils';

export const useShareLinkParams = () => {
  const { params, searchParams } = useRouting();

  const linkId = params.linkId || '';
  const template = searchParams.get('t') || '';
  const signature = searchParams.get('s') || '';
  const passwordProtected = searchParams.get('passwordProtected') === 'true';
  const isRevoked = searchParams.get('revoked') === 'true';

  return { isRevoked, linkId, passwordProtected, signature, template };
};
