import { CustomIntegrationProps, IntegrationType } from '@pro4all/graphql';

import { FormFields } from './types';

export const getIntegrationCustomTypeInput = ({
  integrationType,
  valueType,
}: {
  integrationType: IntegrationType;
  valueType: FormFields;
}) => {
  const { apiKey, environmentUrl, userName } = valueType;

  let integrationTypeInput = {} as CustomIntegrationProps;

  // This switch will be extended in the future with other types that contain type related properties.
  switch (integrationType) {
    case IntegrationType.Integration12Build:
      integrationTypeInput = {
        apiKey,
        username: userName,
      };
      break;
    case IntegrationType.IntegrationSnagstream:
      integrationTypeInput = {
        environmentUrl,
      };
      break;
    default:
  }

  return integrationTypeInput;
};
