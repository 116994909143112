import { TemplateService, TemplateType } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { IconName } from '@pro4all/shared/ui/icons';
import { EntityTypeTranslation } from '@pro4all/shared/ui/messages';

interface EntityProps {
  entity: string;
  entityIcon: IconName;
  entityId: string;
  entityType: EntityTypeTranslation;
  route: keyof typeof Routes;
}

type FeatureConfigType = {
  [Type in TemplateService]: {
    fieldTypes: {
      [Type in TemplateType]?: EntityProps;
    };
    templateTypes: {
      [Type in TemplateType]?: EntityProps;
    };
  };
};

export const featureConfig: FeatureConfigType = {
  [TemplateService.Documents]: {
    fieldTypes: {
      [TemplateType.Field]: {
        entity: 'meta data field',
        entityIcon: 'addFieldDefinition',
        entityId: 'meta-data-field',
        entityType: EntityTypeTranslation.FieldDefinition,
        route: 'metaDataDocumentsFields',
      },
    },
    templateTypes: {
      [TemplateType.Document]: {
        entity: 'meta data template',
        entityIcon: 'addTemplate',
        entityId: 'template',
        entityType: EntityTypeTranslation.Template,
        route: 'metaDataDocumentsTemplates',
      },
    },
  },
  [TemplateService.QualityControl]: {
    fieldTypes: {
      [TemplateType.Field]: {
        entity: 'field',
        entityIcon: 'addQuestionItem',
        entityId: 'quality-control-field',
        entityType: EntityTypeTranslation.QuestionItem,
        route: 'metaDataQualityControlFields',
      },
    },
    templateTypes: {
      [TemplateType.Form]: {
        entity: 'form template',
        entityIcon: 'addFormTemplate',
        entityId: 'form-template',
        entityType: EntityTypeTranslation.FormTemplate,
        route: 'metaDataQualityControlForms',
      },
      [TemplateType.Section]: {
        entity: 'section',
        entityIcon: 'addQuestionsSet',
        entityId: 'questions-template',
        entityType: EntityTypeTranslation.Section,
        route: 'metaDataQualityControlTemplates',
      },
      [TemplateType.Snag]: {
        entity: 'snag template',
        entityIcon: 'addSnagTemplate',
        entityId: 'snag-template',
        entityType: EntityTypeTranslation.SnagTemplate,
        route: 'metaDataQualityControlSnags',
      },
      [TemplateType.Tbq]: {
        entity: 'tbq scan template',
        entityIcon: 'addSnagTemplate',
        entityId: 'snag-template',
        entityType: EntityTypeTranslation.TBQScan,
        route: 'metaDataQualityControlSnags',
      },
    },
  },
};
