import { useTranslation } from 'react-i18next';

import {
  DocumentVersionState,
  FinalizationState,
  StampStatus,
} from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { useTheme } from '@pro4all/shared/mui-wrappers';

import { DocumentOption } from './types';

export const useDonutOptionsSearch = () => {
  const { t } = useTranslation();

  const { approve_action_label, reject_action_label } =
    useDocumentActionsLabels();

  const theme = useTheme();

  const keyOptions: DocumentOption[] = [
    {
      id: 'extension',
      label: t('Document type'),
      labelColorResolver: (searchDocument) => {
        switch (searchDocument.extension) {
          case 'doc':
          case 'docx':
            return '#4074d9';
          case 'ppt':
          case 'pptx':
            return '#ce5700';
          case 'png':
          case 'jpg':
          case 'jpeg':
            return '#a066aa';
          case 'csv':
          case 'xls':
          case 'xlsx':
            return '#2d8b42';
          case 'dwg':
          case 'ifc':
          case 'rvt':
            return '#fcbb1a';
          case 'zip':
            return '#556080';
          case 'pdf':
            return '#e73520';
          case 'txt':
            return theme.palette.grey[400];
          default:
            return theme.palette.grey[300];
        }
      },
      valueResolver: (searchDocument) =>
        searchDocument.extension?.toLowerCase() || t('(empty)'),
    },
    {
      id: 'qrCodeState',
      label: t('QR Code'),
      labelColorResolver: (searchDocument) => {
        switch (searchDocument.qrCodeState) {
          case StampStatus.None:
            return theme.palette.grey[300];
          case StampStatus.Progress:
            return theme.palette.primary.main;
          case StampStatus.Done:
            return theme.palette.secondary.main;
          case StampStatus.Failed:
            return theme.palette.error.main;
          default:
            return theme.palette.grey[300];
        }
      },
      valueResolver: (searchDocument) => {
        switch (searchDocument.qrCodeState) {
          case StampStatus.None:
            return t('(empty)');
          case StampStatus.Progress:
            return t('Pending');
          case StampStatus.Done:
            return t('Stamped');
          case StampStatus.Failed:
            return t('Stamp failed');
          default:
            return t('(empty)');
        }
      },
    },
    {
      id: 'state',
      label: t('Finalized'),
      labelColorResolver: (searchDocument) => {
        switch (searchDocument.state) {
          case FinalizationState.NotFinalized:
            return theme.palette.grey[300];
          case FinalizationState.Finalized:
            return theme.palette.primary.main;
          default:
            return theme.palette.grey[300];
        }
      },
      valueResolver: (searchDocument) => {
        switch (searchDocument.state) {
          case FinalizationState.NotFinalized:
            return t('Not finalized');
          case FinalizationState.Finalized:
            return t('Finalized');
          default:
            return t('(empty)');
        }
      },
    },
    {
      id: 'updatedBy',
      label: t('Updated by'),
      valueResolver: (searchDocument) =>
        searchDocument.updatedBy?.displayName || t('(empty)'),
    },
    {
      id: 'versionState',
      label: `${approve_action_label}/${reject_action_label}`,
      labelColorResolver: (searchDocument) => {
        switch (searchDocument.versionState) {
          case DocumentVersionState.Approved:
            return theme.palette.secondary.main;
          case DocumentVersionState.Rejected:
            return theme.palette.error.main;
          default:
            return theme.palette.grey[300];
        }
      },
      valueResolver: (searchDocument) => {
        switch (searchDocument.versionState) {
          case DocumentVersionState.Approved:
            return approve_action_label;
          case DocumentVersionState.Rejected:
            return reject_action_label;
          default:
            return t('(empty)');
        }
      },
    },
  ];

  return keyOptions;
};
