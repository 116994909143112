import { useEffect } from 'react';

import { client } from '@pro4all/authentication/src/graph-ql';
import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  Folder,
  FolderByPathDocument,
  StampStatus,
  useVersionsIncludeQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const usePollQrStamps = (documentIds: string[]) => {
  const { data, stopPolling } = useVersionsIncludeQuery({
    fetchPolicy: 'network-only',
    pollInterval: 3000,
    skip: !documentIds.length,
    variables: {
      ids: documentIds,
      includeDocumentId: true,
      includeQrCodeState: true,
    },
  });

  const {
    params: { projectId, path },
  } = useRouting();

  const updateCachedDocuments = useUpdateCachedDocuments();

  const documentsQueryParams = {
    query: FolderByPathDocument,
    variables: projectId ? { path, projectId } : { path },
  };

  useEffect(() => {
    const cachedFolder = client?.readQuery(documentsQueryParams)
      ?.folder as Folder;
    const cachedDocuments = cachedFolder?.documents;

    const qrCodeStampsStatus = data?.versions as {
      documentId: string;
      id: string;
      qrCodeState: StampStatus;
    }[];

    if (qrCodeStampsStatus && qrCodeStampsStatus.length) {
      const qrCodeStampsStatusMap = new Map(
        qrCodeStampsStatus.map((status) => [status.documentId, status])
      );

      // Checking local and incoming data to see if we need to stop polling
      const needPolling = cachedDocuments?.some((doc) => {
        const updatedDocument = qrCodeStampsStatusMap.get(doc.id);

        if (updatedDocument) {
          if (
            updatedDocument?.qrCodeState !== doc.qrCodeState &&
            !(
              updatedDocument?.qrCodeState === StampStatus.None &&
              doc.qrCodeState === StampStatus.Progress
            ) &&
            !(
              doc.qrCodeState === StampStatus.None &&
              updatedDocument.qrCodeState === StampStatus.Done
            )
          ) {
            updateCachedDocuments({
              documentIds: [updatedDocument.documentId],
              fieldToUpdate: 'qrCodeState',
              keyField: 'id',
              value: updatedDocument.qrCodeState,
            });
          }

          if (
            (updatedDocument?.qrCodeState === StampStatus.None &&
              doc.qrCodeState === StampStatus.Progress) ||
            (doc.qrCodeState === StampStatus.None &&
              updatedDocument.qrCodeState === StampStatus.Done)
          ) {
            return true;
          }
          return updatedDocument.qrCodeState === StampStatus.Progress;
        }
      });

      if (!needPolling) {
        stopPolling();
      }
    }
  }, [
    data,
    documentsQueryParams,
    path,
    projectId,
    stopPolling,
    updateCachedDocuments,
  ]);
};
