export const getFileSize = (size?: number) => {
  if (!size) return undefined;

  const numberOfDigits = Math.log(size) * Math.LOG10E + 1;

  return numberOfDigits >= 4
    ? numberOfDigits >= 7
      ? numberOfDigits >= 10
        ? `${(size / Math.pow(1024, 3)).toFixed(0)} GB`
        : `${(size / Math.pow(1024, 2)).toFixed(0)} MB`
      : `${(size / 1024).toFixed(0)} KB`
    : `${size} bytes`;
};
