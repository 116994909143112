import React from 'react';

import { SvgIcon } from '../svg';

export const ObjectLinkActive: React.FC<
  React.HtmlHTMLAttributes<SVGElement> & { 'aria-hidden': true | undefined }
> = ({ color, ...props }) => (
  <SvgIcon {...props} fill={color}>
    <path
      clipRule="evenodd"
      d="M19.612 10.3716L16.7211 8.92628L19.612 7.48079C20.1291 7.22232 20.1296 6.48178 19.612 6.2229L11.3145 2.07416C11.1165 1.97528 10.8835 1.97528 10.6855 2.07416L2.38802 6.2229C1.87094 6.48151 1.87039 7.22204 2.38802 7.48079L5.27887 8.92628L2.38802 10.3716C1.87094 10.6302 1.87039 11.3706 2.38802 11.6295L5.27887 13.075L2.38802 14.5204C1.87094 14.779 1.87039 15.5194 2.38802 15.7783L10.6855 19.927C10.7844 19.9764 10.8922 20.0012 11 20.0012L11 18.5119L4.27492 15.1494L6.85126 13.8611L10.6855 15.7783C10.8833 15.877 11.1161 15.8774 11.3145 15.7783L13.3491 14.761L16.86 13H16.8712L19.612 11.6295C20.1291 11.3711 20.1296 10.6305 19.612 10.3716ZM11 3.4893L17.7251 6.85191L11 10.2144L4.27492 6.85191L11 3.4893ZM11 14.3631L4.27492 11.0007L6.85126 9.71241L10.6855 11.6295C10.8833 11.7283 11.1161 11.7287 11.3145 11.6295L15.1487 9.71241L17.7251 11.0007L11 14.3631Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.9673 19.9133H16.4673C16.7423 19.9133 16.9673 20.1383 16.9673 20.4133C16.9673 20.6883 16.7423 20.9133 16.4673 20.9133H14.9673C13.5873 20.9133 12.4673 19.7933 12.4673 18.4133C12.4673 17.0333 13.5873 15.9133 14.9673 15.9133H16.4673C16.7423 15.9133 16.9673 16.1383 16.9673 16.4133C16.9673 16.6883 16.7423 16.9133 16.4673 16.9133H14.9673C14.1423 16.9133 13.4673 17.5883 13.4673 18.4133C13.4673 19.2383 14.1423 19.9133 14.9673 19.9133ZM19.9673 15.9133H18.4673C18.1923 15.9133 17.9673 16.1383 17.9673 16.4133C17.9673 16.6883 18.1923 16.9133 18.4673 16.9133H19.9673C20.7923 16.9133 21.4673 17.5883 21.4673 18.4133C21.4673 19.2383 20.7923 19.9133 19.9673 19.9133H18.4673C18.1923 19.9133 17.9673 20.1383 17.9673 20.4133C17.9673 20.6883 18.1923 20.9133 18.4673 20.9133H19.9673C21.3473 20.9133 22.4673 19.7933 22.4673 18.4133C22.4673 17.0333 21.3473 15.9133 19.9673 15.9133ZM15.4673 18.4133C15.4673 18.6883 15.6923 18.9133 15.9673 18.9133H18.9673C19.2423 18.9133 19.4673 18.6883 19.4673 18.4133C19.4673 18.1383 19.2423 17.9133 18.9673 17.9133H15.9673C15.6923 17.9133 15.4673 18.1383 15.4673 18.4133Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
