import React from 'react';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

import * as Styled from './LabelCard.styles';
import { LabelCardProps } from './LabelCard.types';

export const LabelCard = ({
  borderRadius = 4,
  colorBackground,
  colorBorder,
  colorFont,
  label,
  number,
  numberColorBackground,
  numberColorBorder,
  numberColorFont,
  onClick,
  onDelete,
  paddingHorizontal = 8,
  paddingVertical = 4,
  startIcon,
}: LabelCardProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <Styled.Container
      borderRadius={borderRadius}
      colorBackground={colorBackground ? customColors[colorBackground] : ''}
      colorBorder={colorBorder ? customColors[colorBorder] : ''}
      colorFont={colorFont ? customColors[colorFont] : ''}
      onClick={onClick}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
    >
      {startIcon && <Icon iconName={startIcon} />}
      {label}
      {number ? (
        <Styled.Number
          numberColorBackground={
            numberColorBackground ? customColors[numberColorBackground] : ''
          }
          numberColorBorder={
            numberColorBorder ? customColors[numberColorBorder] : ''
          }
          numberColorFont={numberColorFont ? customColors[numberColorFont] : ''}
        >
          {number}
        </Styled.Number>
      ) : null}
      {onDelete && <Styled.HoverIcon iconName="close" onClick={handleDelete} />}
    </Styled.Container>
  );
};
