import {
  EXCLUSIVE_PERMISSION_DELETE,
  EXCLUSIVE_PERMISSION_DOCUMENT,
  EXCLUSIVE_PERMISSION_UPDATE,
  EXCLUSIVE_PERMISSION_VERSION,
  ExclusivePermissionType,
} from '../../types/types';

export const getExclusivePermissionsToUse = (
  exclusivePermissionType: ExclusivePermissionType
) =>
  exclusivePermissionType === ExclusivePermissionType.Documents
    ? EXCLUSIVE_PERMISSION_DOCUMENT
    : exclusivePermissionType === ExclusivePermissionType.Versions
    ? EXCLUSIVE_PERMISSION_VERSION
    : exclusivePermissionType === ExclusivePermissionType.Delete
    ? EXCLUSIVE_PERMISSION_DELETE
    : EXCLUSIVE_PERMISSION_UPDATE;
