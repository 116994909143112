import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useMyProfileFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'First name',
      matches: /^[A-Za-z0-9-'`_ \p{L}]+$/u,
      max: 32,
      min: 2,
      name: 'firstName',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Last name',
      matches: /^[A-Za-z0-9-'`_ \p{L}]+$/u,
      max: 32,
      min: 2,
      name: 'lastName',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Email address',
      name: 'email',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Phone number',
      label: '+31612345678',
      name: 'phoneNumber',
      phoneNumber: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Language',
      name: 'language',
      required: true,
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Job title',
      name: 'jobTitle',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
