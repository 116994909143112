import React from 'react';

import { BackgroundField } from './BackgroundField';
import { ConfigureFooter } from './ConfigureFooter';
import { ConfigureLayout } from './ConfigureLayout';
import { ConfigureResults } from './ConfigureResults';

export const GeneralCustomOptions = () => (
  <>
    <ConfigureResults />
    <ConfigureLayout />
    <BackgroundField />
    <ConfigureFooter />
  </>
);
