import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

import { useFormActions } from '../form-actions/useFormActions';

export const MainWrapper = ({ children }: PropsWithChildren<{}>) => {
  const actionButtons = useFormActions();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        px: 3,
      }}
    >
      <Box sx={{ height: '100%' }}>{children}</Box>
      {actionButtons}
    </Box>
  );
};
