import { useProjectQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useFetchProject = () => {
  const { params } = useRouting();
  const { data, loading } = useProjectQuery({
    skip: !params.projectId,
    variables: { projectId: params.projectId },
  });

  return { loading, projectData: data };
};
