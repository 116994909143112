import React, { useContext, useState } from 'react';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { getCollectionFolderDocuments } from '../utils/collectionFolderStorage';

interface CollectionFolderContextValues {
  documentsCount: number;
  updateDocumentsCount?: () => void;
}

const CollectionFolderContext =
  React.createContext<CollectionFolderContextValues>({
    documentsCount: 0,
  });

export function useCollectionFolderContext() {
  return useContext<CollectionFolderContextValues>(CollectionFolderContext);
}

export const CollectionFolderContextProvider: React.FC = ({ children }) => {
  const { userId } = useOrganizationContext();
  const {
    params: { projectId },
  } = useRouting();

  const getDocumentsLength = () =>
    getCollectionFolderDocuments(projectId, userId).length;

  const [documentsCount, setDocumentsCount] = useState(getDocumentsLength());

  const updateDocumentsCount = () => {
    setDocumentsCount(getDocumentsLength());
  };

  const values = {
    documentsCount,
    updateDocumentsCount,
  };

  return (
    <CollectionFolderContext.Provider value={values}>
      {children}
    </CollectionFolderContext.Provider>
  );
};
