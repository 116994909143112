import styled from 'styled-components';

import { IconButton } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const ButtonIcon = styled(IconButton)<{
  $disableBorder: boolean;
}>`
  && {
    padding: 5px;
    background-color: ${({ $disableBorder }) =>
      $disableBorder ? 'transparent' : customColors.white};
    border: ${({ $disableBorder }) =>
      $disableBorder ? 'none' : '1px solid currentColor'};

    &.MuiIconButton-sizeSmall {
      padding: 3px;
    }
  }
`;
