import styled from 'styled-components';

import { Collapse, LinearProgress } from '@pro4all/shared/mui-wrappers';
import { MiddleEllipsis } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

export const AttachmentWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  align-self: flex-start;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  color: ${({ theme }) => theme.palette.primary.main};
  gap: 1rem;
`;

export const StyledCollapse = styled(Collapse)`
  && {
    width: 100%;
    align-self: flex-start;

    .MuiCollapse-wrapper {
      display: flex;
      flex: 1;
    }
  }
`;

export const StyledAttachmentInput = styled.input`
  display: none;
  width: 100%;
  margin-top: 0.5rem;
`;

export const PreviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
`;

export const FileInfoEnd = styled.div`
  && {
    align-items: center;
    color: #585858;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    > p {
      white-space: nowrap;
    }
  }
`;

export const StyledProgress = styled(LinearProgress)`
  display: flex;
  flex: 1;
`;

export const AttachmentLink = styled(MiddleEllipsis)<{
  $downloadable?: boolean;
}>`
  && {
    cursor: ${({ $downloadable }) => ($downloadable ? 'pointer' : 'default')};
    &:hover {
      text-decoration: ${({ $downloadable }) =>
        $downloadable ? 'underline' : 'none'};
    }
  }
`;

export const DownloadText = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledText = styled(Text)`
  && {
    line-height: 20px;
  }
`;
