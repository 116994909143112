import React from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { removeFolderFromClipboard } from '@pro4all/shared/storage-utils';

export const Logout = () => {
  removeFolderFromClipboard();
  AuthService.logout();
  return <span>Signing out...</span>;
};
