import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

export const FormContainer = styled(Box)`
  && {
    height: 100%;
    max-height: 100%;
  }
`;

export const OverflowContainer = styled('div')`
  overflow-y: scroll;
`;

export const StyledPermissionMatrixTable = styled('table')`
  width: 100%;
  border-collapse: collapse;
`;

export const MatrixTableHeaderCell = styled('th')`
  padding: ${({ theme }) => theme.spacing(1)};
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey[400]};
`;

export const MatrixTableCell = styled('td')`
  vertical-align: top;
  padding: 10px;
  width: 10vw;
  :first-child {
    width: 30%;
  }
  :nth-child(even) {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

export const MatrixTrRow = styled('tr')`
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey[400]};
`;

export const CurrentPermissionText = styled(Text)`
  text-align: center;
`;

export const TextDropdownContainer = styled(Box)`
  && .MuiBox-root {
    max-width: 50px;
  }
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  height: 105px;
  margin-top: 20px;
`;

export const IconDropdownBox = styled(Box)`
  display: flex;
  gap: 5px;
`;

export const StyledTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
`;
