import React, { useContext, useEffect, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  AuthType,
  Maybe,
  Me,
  MeProject,
  Organization,
  OrganizationSubscriptionLevel,
  Role,
  useMeQuery,
  useOrganizationQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { AppPermission } from '@pro4all/shared/types';

type OrganizationContextValue = {
  contextScopedOrganizationSubscriptionLevel: OrganizationSubscriptionLevel;
  enforceSSO: boolean;
  meData: Me | undefined;
  organization: Organization | undefined;
  organizationId: string;
  organizationName: string;
  organizationRoles: Role[];
  projectsMe: MeProject[];
  setOrganization: React.Dispatch<React.SetStateAction<Organization>>;
  setUserFirstName: React.Dispatch<React.SetStateAction<string>>;
  setUserJobTitle: React.Dispatch<React.SetStateAction<string>>;
  setUserLastName: React.Dispatch<React.SetStateAction<string>>;
  setUserOrganizationName: React.Dispatch<React.SetStateAction<string>>;
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  userActive: boolean;
  userAuthType: Maybe<AuthType>;
  userDisplayName: string;
  userEmail: string;
  userFirstName: string;
  userId: string;
  userIsOrganizationAdmin: boolean;
  userJobTitle: string;
  userLanguage: string;
  userLastName: string;
  userOrganizationId: string;
  userOrganizationName: string;
  userPhoneNumber: string;
  userflowSignature: string;
};

const OrganizationContext = React.createContext<OrganizationContextValue>({
  contextScopedOrganizationSubscriptionLevel:
    OrganizationSubscriptionLevel.Trial,
  enforceSSO: false,
  meData: undefined,
  organization: undefined,
  organizationId: '',
  organizationName: '',
  organizationRoles: [],
  projectsMe: [],
  setOrganization: () => '',
  setUserFirstName: () => '',
  setUserJobTitle: () => '',
  setUserLastName: () => '',
  setUserOrganizationName: () => '',
  setUserPhoneNumber: () => '',
  userActive: false,
  userAuthType: null,
  userDisplayName: '',
  userEmail: '',
  userFirstName: '',
  userId: '',
  userIsOrganizationAdmin: false,
  userJobTitle: '',
  userLanguage: 'en-US',
  userLastName: '',
  userOrganizationId: '',
  userOrganizationName: '',
  userPhoneNumber: '',
  userflowSignature: '',
});

export function useOrganizationContext() {
  return useContext(OrganizationContext);
}

export const OrganizationContextProvider: React.FC = ({ children }) => {
  const { params } = useRouting();

  const profile = AuthService.getProfile();

  const { data: meQueryData } = useMeQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 120000,
  });

  const [enforceSSO, setEnforceSSO] = useState(false);
  const [meData, setMeData] = useState<Me>();
  const [organization, setOrganization] = useState<Organization>(); //##
  const [organizationId, setOrganizationId] = useState(''); //##
  const [organizationName, setOrganizationName] = useState(''); //##
  const [projectsMe, setProjectsMe] = useState<MeProject[]>([]);
  const [userActive, setUserActive] = useState(false);
  const [userDisplayName, setUserDisplayName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userIsOrganizationAdmin, setUserIsOrganizationAdmin] = useState(false);
  const [userJobTitle, setUserJobTitle] = useState('');
  const [userLanguage, setUserLanguage] = useState('en-US');
  const [userLastName, setUserLastName] = useState('');
  const [userOrganizationId, setUserOrganizationId] = useState('');
  const [userOrganizationName, setUserOrganizationName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userflowSignature, setUserflowSignature] = useState('');
  const [userId, setUserId] = useState('');
  const [organizationRoles, setOrganizationRoles] = useState<Role[]>([]);
  const [
    contextScopedOrganizationSubscriptionLevel,
    setContextScopedOrganizationSubscriptionLevel,
  ] = useState<OrganizationSubscriptionLevel>(
    OrganizationSubscriptionLevel.Trial
  );

  const { data: organizationData } = useOrganizationQuery({
    fetchPolicy: 'cache-first',
    variables: { id: meQueryData?.me?.organization?.organizationId || '' },
  });

  useEffect(() => {
    if (meQueryData?.me) {
      const { organization, projects, user } = meQueryData.me;
      const { organizationId, organizationName, roles } = organization || {};
      const projectsMe = projects ? (projects as MeProject[]) : [];
      const rolesOrganization = roles ? (roles as Role[]) : [];
      const {
        active,
        displayName,
        email,
        firstName,
        jobTitle,
        language,
        lastName,
        phoneNumber,
        userflowSignature,
        userId,
      } = user || {};

      setMeData(meQueryData.me as Me);
      setProjectsMe(projectsMe);
      setUserActive(active || false);
      setUserDisplayName(displayName || '');
      setUserEmail(email || '');
      setUserFirstName(firstName || '');
      setUserJobTitle(jobTitle || '');
      setUserLanguage(language || 'en-US');
      setUserLastName(lastName || '');
      setUserOrganizationId(organizationId || '');
      setUserOrganizationName(organizationName || '');
      setUserPhoneNumber(phoneNumber || '');
      setUserflowSignature(userflowSignature || '');
      setUserId(userId || '');
      setOrganizationRoles(rolesOrganization);
    }
  }, [meQueryData]);

  useEffect(() => {
    if (organizationData && organizationData.organization) {
      const { organization } = organizationData;
      const { enforceSSO, id, name } = organization;
      setEnforceSSO(Boolean(enforceSSO));
      setOrganizationId(id);
      setOrganizationName(name);
      setOrganization(organization);
    }
  }, [organizationData]);

  // This useEffect sets the userIsOrganizationAdmin flag based on the user's roles.
  // TODO - Refactor all `hasAppPermission('OrganizationUpdate');` code to use this flag instead.
  useEffect(() => {
    const roles = meQueryData ? meQueryData.me?.organization?.roles : [];

    const isAdmin =
      roles && roles.length > 0
        ? Boolean(
            roles.find((role) =>
              role?.permissions?.includes(AppPermission.OrganizationUpdate)
            )
          )
        : false;

    setUserIsOrganizationAdmin(isAdmin);
  }, [meQueryData]);

  useEffect(() => {
    // Execute this statement in the scenario where a user moved organizations but did not log out from the app in another browser/device
    if (
      profile &&
      profile.tenantId &&
      meQueryData &&
      meQueryData.me &&
      meQueryData.me.organization &&
      meQueryData.me.organization.organizationId !== profile.tenantId
    ) {
      AuthService.logout();
    }
    if (
      organizationData &&
      organizationData.organization &&
      organizationData.organization.organizationSubscriptionLevel &&
      contextScopedOrganizationSubscriptionLevel !==
        organizationData.organization?.organizationSubscriptionLevel
    ) {
      setContextScopedOrganizationSubscriptionLevel(
        organizationData.organization.organizationSubscriptionLevel
      );
    }
  }, [
    meQueryData,
    profile,
    userOrganizationId,
    params,
    organizationData,
    contextScopedOrganizationSubscriptionLevel,
  ]);

  const organizationContextValue = {
    contextScopedOrganizationSubscriptionLevel,
    enforceSSO,
    meData,
    organization,
    organizationId,
    organizationName,
    organizationRoles,
    projectsMe,
    setOrganization,
    setUserFirstName,
    setUserJobTitle,
    setUserLastName,
    setUserOrganizationName,
    setUserPhoneNumber,
    userActive,
    userAuthType: meQueryData?.me?.user?.authType ?? null,
    userDisplayName,
    userEmail,
    userFirstName,
    userId,
    userIsOrganizationAdmin,
    userJobTitle,
    userLanguage,
    userLastName,
    userOrganizationId,
    userOrganizationName,
    userPhoneNumber,
    userflowSignature,
  };

  return (
    <OrganizationContext.Provider value={organizationContextValue}>
      {children}
    </OrganizationContext.Provider>
  );
};
