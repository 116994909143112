import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  createFilterOptions,
  FilterOptionsState,
  InputProps,
} from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { sortBy } from '@pro4all/shared/utils';
import { sortByMethod } from '@pro4all/shared/utils';

import { Tag, TagProps } from '../../../tags/Tag';
import { TextField, TextFieldProps } from '../../text-field/TextField';
import { useOnSearch } from '../useOnSearch';

const filter = createFilterOptions<Option>();

// A container for tags when the input has a fixed height
const TagsContainer = styled.div`
  width: auto;
  overflow-x: auto;

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

/**
 * Material-ui autcomplete has extensive typings, so our interface
 * extends an initiated version of their types with the following
 * default:
 *  - multiple: true (to allow multiple values to be selected)
 *  - DisableClearable: false (so input can be cleared)
 *  - FreeSolo: true (allows us to fully manipulate user input)
 * Some props are prohibited from being changed from outside, since
 * it's not desirable to change them for our use-case. These are:
 *  - renderInput
 *  - autoComplete
 *  - multiple
 *
 *  The values used in the autocomplete can either be
 *  - Option: Any of the values passed to the Component
 *    or selected from the dropdown
 *  - string: A manual user input, we need to convert
 *    this to an Option element before it is rendered
 *    here or sent to the calling Component as a value.
 *    A string value should _only_ be used internally
 *    in this function.
 */
export interface SearchableMultiSelectType<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'autoComplete'
    | 'defaultValue'
    | 'multiple'
    | 'onBlur'
    | 'onChange'
    | 'renderInput'
    | 'value'
  > {
  autoFocus?: boolean;
  canAddNewOptions?: boolean;
  error?: InputProps['error'];
  fixedHeight?: boolean;
  helperText?: TextFieldProps['helperText'];
  icon?: IconName;
  label?: TextFieldProps['label'];
  margin?: TextFieldProps['margin'];
  name: TextFieldProps['name'];
  onBlur?: InputProps['onBlur'];
  onChange?: (
    event: React.ChangeEvent | null,
    value: Option[],
    reason?: AutocompleteChangeReason
  ) => void;
  onClickTag?: (tagId: string) => void;
  onSearch?: (value: string) => void;
  renderSelectedTagsInInput?: boolean;
  showValuesInTooltip?: boolean;
  sortTagsBy?: (option: Option) => string;
  tagColor?: TagProps['color'];
  tooltipTitle?: string;
  type?: string;
  value?: Option[];
  variant?: TextFieldProps['variant'];
  warning?: boolean;
}

export type SearchableMultiSelectProps = SearchableMultiSelectType<
  Option,
  true,
  false,
  true
>;

// See: https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
// Use ref inside this component and have a ref forwarded to support react hook form
function useCombinedRefs(...refs: React.ForwardedRef<unknown>[]) {
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export const SearchableMultiSelect: React.FC<SearchableMultiSelectProps> =
  React.forwardRef(
    (
      {
        autoFocus,
        canAddNewOptions,
        disabled,
        disableCloseOnSelect = true,
        error,
        fixedHeight,
        getLimitTagsText,
        helperText,
        label,
        limitTags,
        margin = 'dense',
        name,
        onBlur,
        onChange,
        onClickTag,
        onInputChange,
        onSearch,
        options,
        placeholder,
        renderSelectedTagsInInput = true,
        showValuesInTooltip = false,
        sortTagsBy,
        tabIndex,
        tagColor = 'default',
        tooltipTitle = '',
        value = [],
        variant = 'outlined',
        warning = false,
        ...rest
      },
      ref
    ) => {
      const inputRef = useRef(null);
      const [inputValue, setInputValue] = useState('');
      const combinedRef = useCombinedRefs(ref, inputRef);

      const handleOnSearch = useOnSearch({ onSearch });

      const getOptionName = (option: Option) => {
        const { inputValue, label } = option;
        return label ? label : inputValue;
      };

      const getIcon = (option: Option) => {
        const { iconName } = option || {};
        return iconName && <Icon iconName={iconName} />;
      };

      const renderTags = (
        value: Option[],
        getTagProps: AutocompleteGetTagProps
      ) => {
        /** If sorting function is provided, sort the tags in place */
        if (sortTagsBy) {
          value.sort(sortByMethod(sortTagsBy));
        }

        return value.map((option, index) => (
          <Tag
            color={tagColor}
            icon={getIcon(option)}
            name={getOptionName(option)}
            {...getTagProps({ index })}
            disabled={disabled}
            onClickTag={onClickTag}
            onDelete={(event) => {
              /**
               * By default the focus is not put on the autocomplete field
               * when deleting a tag. So here we explicitly put focus on
               * the input before we remove the tag, so we can safely
               * trigger the onChange handler while still maintaining the
               * ability to trigger to this change onBlur.
               */
              combinedRef.current.focus();
              onChange(
                event,
                value.filter((val) => val.id !== option.id),
                'removeOption'
              );
            }}
            tagId={option.id}
          />
        ));
      };

      const filterOptions = (
        options: Option[],
        state: FilterOptionsState<Option>
      ) => {
        const filtered = filter(options, state).sort(
          sortByMethod(getOptionName)
        );

        /**
         * Suggest the creation of a new value if
         *  - Prop `canAddNewOptions` is enabled
         *  - The input is not empty
         *  - There is no option in the list with the same value
         */
        if (
          canAddNewOptions &&
          state.inputValue !== '' &&
          !filtered.some((option) => getOptionName(option) === state.inputValue)
        ) {
          filtered.push({
            id: uuid(),
            inputValue: state.inputValue,
            label: `${state.inputValue}`,
          });
        }

        return (
          filtered
            /** Filter already selected options out of suggestion box */
            .filter(
              (option) =>
                !value.some(
                  (val: Option) => getOptionName(val) === getOptionName(option)
                )
            )
        );
      };

      const handleChange = (
        event: React.ChangeEvent,
        value: (string | Option)[],
        reason: AutocompleteChangeReason
      ) => {
        /**
         * If the user entered a manual entry in the input field
         * and pressed enter, we need to create an Option for it
         * that our SearchableMultiSelect understands before we pass it to
         * the calling Component as a value.
         * We also need to check if the value is already an
         * existing option. If so, we choose that one as the value
         * instead of creating a new one.
         */
        onChange(
          event,
          value
            .map((val: string | Option) => {
              if (typeof val === 'string') {
                const existingOption = options.find(
                  (option) => getOptionName(option) === val
                );
                if (existingOption) {
                  return existingOption;
                } else if (canAddNewOptions) {
                  return {
                    id: uuid(),
                    inputValue: val,
                    label: val,
                  };
                } else {
                  /**
                   * Not a valid option, and not allowed to add options, so we make it null
                   * to be able to filter it out next
                   */
                  return null;
                }
              }
              return val;
            })
            /** Remove entries that were invalid */
            .filter((entry) => entry !== null)
            /**
             * Remove any duplicates from the array. This may be caused
             * by a user typing and pressing enter on an already
             * existing key
             */
            .filter(
              (val, index, self) =>
                self
                  .map((compareVal) => getOptionName(compareVal))
                  .indexOf(getOptionName(val)) === index
            ),
          reason
        );
      };

      const handleInputChange = (
        event: React.ChangeEvent,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => {
        setInputValue(value); // Update the local input value of the textfield input in the Autocomplete input
        if (onSearch) {
          handleOnSearch(value);
        }
        onInputChange && onInputChange(event, value, reason);
      };

      const renderInput = (params: AutocompleteRenderInputParams) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;

        const restParamsUpdates = {
          ...restParams,
          inputProps: { ...restParams.inputProps, value: inputValue },
        };

        return (
          <TextField
            {...restParamsUpdates}
            InputProps={{
              ...restInputProps,
              inputProps: {
                ...restParamsUpdates.inputProps,
                tabIndex,
              },
              startAdornment: fixedHeight ? (
                <TagsContainer>{startAdornment}</TagsContainer>
              ) : (
                startAdornment
              ),
            }}
            autoComplete="off"
            autoFocus={autoFocus}
            error={error}
            fullWidth
            helperText={helperText}
            inputRef={combinedRef}
            label={label}
            margin={margin}
            name={name}
            onBlur={(event) => {
              onBlur && onBlur(event);
              setInputValue(''); // Reset non-confirmed input value textfield input on blur
            }}
            onFocus={() => {
              setInputValue(''); // Reset possible old non-confirmed value textfield input on focus
            }}
            placeholder={placeholder}
            variant={variant}
            warning={warning}
          />
        );
      };

      // Sort the value array.
      const valueSorted = value.sort(sortBy({ key: 'label' }));

      const autocomplete = (
        <Autocomplete
          {...rest}
          autoComplete
          disableClearable={!renderSelectedTagsInInput}
          disableCloseOnSelect={disableCloseOnSelect}
          disabled={disabled}
          filterOptions={filterOptions}
          freeSolo
          getLimitTagsText={(more) =>
            getLimitTagsText ? getLimitTagsText(more) : `+${more}`
          }
          getOptionDisabled={(option: Option) => option.disabled}
          getOptionLabel={(option: Option) => option.label}
          limitTags={limitTags}
          multiple
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={options}
          renderInput={renderInput}
          renderOption={(props, option: Option) => {
            const { iconColor, iconName, id, label, iconComponent } = option;
            return (
              <li {...props} key={id}>
                {iconName && (
                  <StyledIcon htmlColor={iconColor} iconName={iconName} />
                )}
                {iconComponent && iconComponent}
                {label}
              </li>
            );
          }}
          renderTags={renderSelectedTagsInInput ? renderTags : () => null}
          value={valueSorted}
        />
      );

      if (tooltipTitle || (showValuesInTooltip && valueSorted.length > 0)) {
        const tooltipValue = tooltipTitle
          ? [tooltipTitle]
          : (valueSorted as (string | Option)[]).map((option) => {
              if (
                typeof option === 'object' &&
                option !== null &&
                'label' in option
              ) {
                return (option as Option).label;
              }

              return option as string;
            });

        return (
          <Tooltip
            placement="bottom"
            title={
              <div>
                {tooltipValue.map((value, index) => (
                  <div key={index}>{value}</div>
                ))}
              </div>
            }
          >
            {autocomplete}
          </Tooltip>
        );
      } else {
        return autocomplete;
      }
    }
  );
