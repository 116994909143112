import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { useClipboard } from 'use-clipboard-copy';

import { Alert, Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { StyledTooltip } from '@pro4all/shared/ui/side-navigation';
import { Text } from '@pro4all/shared/ui/typography';

import { ButtonLabel } from './styles';

const StyledIcon = styled(Icon)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
`;

export const PasswordSection: React.FC<{ password: string }> = ({
  password,
}) => {
  const { t } = useTranslation();
  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const onClick = () => {
    clipboard.copy(password);
    enqueueSnackbar(t('Password has been copied'));
  };

  return (
    <>
      <Alert severity="warning" variant="filled">
        {t(
          'You will not be able to retrieve your password once you close this window. Make sure to copy and share it.'
        )}
      </Alert>
      <Box alignItems="center" display="flex" my={1}>
        <ButtonLabel variant="h6">{t('Password')}:</ButtonLabel>
        <Text aria-label="password">{password}</Text>
        <Box marginLeft="auto">
          <StyledTooltip placement="right" title={t('Copy password')}>
            <StyledIcon
              color="inherit"
              fontSize="small"
              iconName="copyFile"
              onClick={onClick}
            />
          </StyledTooltip>
        </Box>
      </Box>
    </>
  );
};
