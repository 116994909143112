import React from 'react';

import * as Lite from './custom-lite';
import { IconWrap } from './Icon';

export interface LiteIconProps extends React.HtmlHTMLAttributes<SVGElement> {
  'aria-hidden'?: true | undefined; // SC handles casing differently, so we set this manually to deal with the mismatch
  iconName: LiteIconName;
  label?: React.ReactNode | string;
}

export const liteIconsMap = {
  copy: Lite.Copy,
  deleteOutline: Lite.DeleteOutline,
  edit: Lite.Edit,
  objectLinkActive: Lite.ObjectLinkActive,
  status: Lite.Status,
  tbq: Lite.TBQ,
};

export type LiteIconName = keyof typeof liteIconsMap;

export const LiteIcon: React.FC<LiteIconProps> = ({
  className,
  iconName,
  label,
  ...rest
}) => (
  <IconWrap className={className} hasLabel={Boolean(label)}>
    {React.createElement(liteIconsMap[iconName], {
      'aria-hidden': true,
      ...rest,
    })}
    {label ? label : null}
  </IconWrap>
);
