import { Descendant } from 'slate';

export const defaultEditorValues: Descendant[] = [
  {
    children: [{ text: '' }],
    type: 'Line',
  },
];

export const origin = { offset: 0, path: [0, 0] };
