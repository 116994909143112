import React from 'react';

import { Status } from '@pro4all/graphql';
import { Chip } from '@pro4all/shared/ui/general';
import { useTaskStatuses } from '@pro4all/workflow/ui/utils';

export const StatusChip: React.FC<{ status: Status }> = ({ status }) => {
  const statuses = useTaskStatuses();

  function getColorForStatus(status: Status) {
    switch (status) {
      case 'Done':
        return 'success';
      case 'InProgress':
        return 'primary';
      case 'Paused':
        return 'warning';
      default:
        return 'default';
    }
  }

  return (
    <Chip
      color={getColorForStatus(status)}
      icon={statuses[status]?.icon}
      label={statuses[status]?.label}
    ></Chip>
  );
};
