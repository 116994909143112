// hooks/useMessageForm.ts
import { useForm } from 'react-hook-form';

import { resolver } from '../message-utils/formHelpers';
import { MessageFormFields } from '../types';

export const useMessageForm = (defaultValues: MessageFormFields) =>
  useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    shouldFocusError: true,
    shouldUnregister: false,
  });
