import type { State } from './state';
import { Status } from './state';

export type Action =
  | ({ type: 'init' } & Partial<State>)
  | ({ type: 'next' } & Partial<Pick<State, 'index'>>)
  | ({ type: 'status' } & Pick<State, 'status'>)
  | ({ type: 'update' } & Partial<State>);

const handlers: {
  [key in Action['type']]: (
    state: State,
    data: Extract<Action, { type: key }>
  ) => State;
} = {
  init(state, nextState) {
    return {
      ...state,
      ...nextState,
      status: Status.Idle,
    };
  },

  next(state, { index }) {
    const nextIndex = index ?? state.index + 1;
    const status =
      nextIndex >= state.queue.length ? Status.Done : Status.Initializing;

    return {
      ...state,
      canvas: null,
      index: nextIndex,
      shapes: [],
      status,
    };
  },

  status(state, { status }) {
    return { ...state, status };
  },

  update(state, { status: _, ...nextState }) {
    return { ...state, ...nextState };
  },
};

export function reducer(state: State, { type, ...data }: Action): State {
  const handler = handlers[type];
  // @ts-expect-error TypeScript cannot infer the handler from `type` and
  // resolves the Action union to `never`. Either we (data as never) or
  // allow this error in TS.
  return handler(state, data);
}
