import React from 'react';

import {
  Aha,
  Datadog,
  Growthbook,
  useAnalytics,
  Userflow,
} from '@pro4all/shared/vendor';

Datadog.init();
Userflow.init();
Growthbook.init();

// This component should never render anything and provides a place where
// we can initialize any telemetry, analytics or other global stuff for our
// application. It's nested below our <Providers/> so it's able to access
// any app wide context (e.g. Apollo, Theme, etc.).
export const Init: React.FC<{ children?: never }> = () => {
  useAnalytics();
  Aha.useIdeaWidget();
  Datadog.useIdentify();
  Userflow.useIdentify();
  Growthbook.useIdentify();

  return null;
};
