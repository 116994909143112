import { useDrag, useDrop } from 'react-dnd';

import { DndFolder, DndTypes } from '@pro4all/shared/config';

import { useMoveDocuments } from './useMoveDocuments';
import { useMoveFolder } from './useMoveFolder';

export function useDragAndDropFolder({
  id,
  name,
  parentFolderId,
}: {
  id: string;
  name: string;
  parentFolderId: string;
}) {
  const { moveDocuments } = useMoveDocuments();
  const { moveFolder } = useMoveFolder();

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: {
      currentParentFolderId: parentFolderId,
      id,
      name,
      type: DndTypes.FOLDER,
    },
    type: DndTypes.FOLDER,
  });

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [DndTypes.DOCUMENT, DndTypes.FOLDER],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: async (item: DndFolder, monitor) => {
      if (!monitor.didDrop()) {
        if (item.type === DndTypes.DOCUMENT) {
          // One or more documents have been dropped on this folder.
          // The document(s) must be moved from the original folder to this folder.
          const { checkedRows } = item;

          const documents = checkedRows.length
            ? checkedRows.map((document) => ({
                id: document.id,
                name: document.name,
              }))
            : [{ id: item.id, name: item.name }];

          const currentParentFolderId = checkedRows.length
            ? checkedRows[0].folderId
            : item.currentParentFolderId;

          moveDocuments({
            currentParentFolderId,
            documents,
            folderId: id,
          });
        } else {
          // A different folder has been dropped on this folder.
          // This folder should become the parent of the dropped folder.
          moveFolder({
            childFolder: {
              currentParentFolderId: item.currentParentFolderId,
              id: item.id,
              name: item.name,
            },
            parentFolder: { id, name },
          });
        }
      }
    },
  });

  return {
    drag,
    drop,
    hoversOverCurrent,
    isDragging,
  };
}
