export const tryParseString = (str: string) => {
  try {
    const parsed = JSON.parse(str);

    if (parsed && typeof parsed === 'object') {
      return parsed;
    }
  } catch (e) {
    return false;
  }
};
