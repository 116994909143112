import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar, Tab, Tabs } from '@pro4all/shared/ui/general';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import { useFetchFolder } from '../utils/useFetchFolder';
import { useStandardFolderActions } from '../utils/useStandardFolderActions';

import { FolderProperties } from './FolderProperties';

enum FolderPropertiesTabValues {
  General = 'General',
}

export const FolderSidebar: React.FC<{
  folderId: string;
}> = ({ folderId }) => {
  const { t } = useTranslation();

  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    FolderPropertiesTabValues.General
  );

  const folder = useFetchFolder(folderId, !isOpen);
  const { sidebarActions } = useStandardFolderActions(folder);

  useEffect(() => {
    // If no folder is targeted (i.e. page refresh), do not open sidebar and clear params
    if (!folderId && searchParams.is('action', 'showFolderProperties')) {
      searchParams.clear();
    }
    setIsOpen(
      () =>
        searchParams.is('action', 'showFolderProperties') && Boolean(folderId)
    );
  }, [searchParams, folderId]);

  const folderFromUrl = decodeURIComponent(searchParams.get('folder'));

  const onClose = () => {
    searchParams.clear();
  };

  const folderName = folder?.name;
  const folderNameTruncated = () =>
    folderName?.length > 28 ? folderName.substring(0, 28) + '...' : folderName;

  return (
    <Sidebar onClose={onClose} open={isOpen && folderFromUrl === folderName}>
      <Sidebar.Header
        icon="folder"
        title={
          <Tooltip enterDelay={500} placement="top-end" title={folder?.name}>
            {folderNameTruncated() || ''}
          </Tooltip>
        }
      >
        <Text>{t('Folder properties')}</Text>
        <Sidebar.SidebarActionBar sidebarActions={sidebarActions || []} />
        <Tabs onChange={(_, value) => setCurrentTab(value)} value={currentTab}>
          <Tab
            data-testid={`${FolderPropertiesTabValues.General}-tab`}
            label={t(FolderPropertiesTabValues.General)}
            value={FolderPropertiesTabValues.General}
          />
        </Tabs>
      </Sidebar.Header>

      {currentTab === FolderPropertiesTabValues.General && (
        <FolderProperties
          amountOfDocuments={folder?.fileInformation?.amountOfDocuments}
          amountOfVersions={folder?.fileInformation?.amountOfVersions}
          createdAt={folder?.createdAt}
          creator={folder?.createdBy?.displayName}
          totalFileSize={folder?.fileInformation?.totalFileSize}
        />
      )}
    </Sidebar>
  );
};
