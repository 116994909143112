import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useToFolderAction = ({
  selection,
  sidebarRow,
}: Pick<DocumentActionProps, 'selection' | 'sidebarRow'>) => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();

  const toFolder = () => {
    const route = params.projectId ? 'projectDocs' : 'docs';
    const path = sidebarRow ? sidebarRow.path : selection[0].path;
    const id = sidebarRow ? sidebarRow.id : selection[0].id;
    goTo(route, {
      params: { path, projectId: params.projectId },
      searchParams: { id },
    });
  };

  const toFolderAction: ActionProps = {
    ariaLabel: t('Open document in folder'),
    dataTestId: 'to-folder',
    disabled: !sidebarRow && selection.length > 1,
    key: 'to-folder',
    label: t('Open document in folder'),
    onClick: toFolder,
    startIcon: 'openInFolder',
  };

  return toFolderAction;
};
