import { useDrag, useDrop } from 'react-dnd';

import { TableNode } from '@pro4all/objects/utils';
import { DndTypes } from '@pro4all/shared/config';

import { useEditObjectsContext } from './EditObjectsContext';

export function useDragAndDropObject(target: TableNode) {
  const { getObject, update } = useEditObjectsContext();

  const [{ isDragging }, drag] = useDrag({
    canDrag: !target.tbqType,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: target,
    type: DndTypes.OBJECT,
  });

  const checkIsAncestor: (node: TableNode, target: TableNode) => boolean = (
    node: TableNode,
    target: TableNode
  ) => {
    // target has no parent
    if (!target?.parentNodeId) return false;

    // node is target's parent
    if (target.parentNodeId === node.id) return true;

    const parent = getObject(target.parentNodeId);
    return checkIsAncestor(node, parent);
  };

  const [{ canDrop, hoversOverCurrent }, drop] = useDrop({
    accept: [DndTypes.OBJECT],
    collect: (monitor) => {
      const draggedNode = monitor.getItem<TableNode>();

      const ancestryFound = draggedNode && checkIsAncestor(draggedNode, target);
      const hoversOverSelf = target.id === draggedNode?.id;
      const hoversOverCurrentParent = target.id === draggedNode?.parentNodeId;

      return {
        canDrop: Boolean(
          !ancestryFound && !hoversOverSelf && !hoversOverCurrentParent
        ),
        hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
      };
    },
    drop: (droppedNode: TableNode, monitor) => {
      if (!monitor.didDrop() && canDrop) {
        update({
          id: droppedNode.id,
          parentNodeId: target.id !== 'root' ? target.id : null,
        });
      }
    },
  });

  return {
    canDrop,
    drag,
    drop,
    hoversOverCurrent,
    isDragging,
  };
}
