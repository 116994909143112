import styled from 'styled-components';

import { customColors, ThemeColor } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const HeaderTitle = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledH2 = styled(Text)<{
  $color: ThemeColor;
}>`
  && {
    color: ${({ $color }) => customColors[$color]};
  }
`;
