import { ValueTypeName } from '@pro4all/graphql';
import { Layer } from '@pro4all/shared/ui/layer';

import {
  EditorBoolField,
  EditorDateTimeField,
  EditorSelectionMultiField,
  EditorSelectionSingleField,
  EditorTextField,
} from '../field-types';
import { getFieldOptions, getTabIndex } from '../field-types/helpers';
import { UploaderEditorTypes } from '../types';
import {
  ContentCellWrapper,
  ContentColumnWrapper,
  HeaderWrapper,
  ResizableColumnWrapper,
} from '../wrappers';

export const MetadataColumn = ({
  field,
  initialTabIndex,
  isProcessing,
  isTypingFromHeader,
  meta,
  noOfInputs,
  setTypingFromHeader,
  updateMetaMetadata,
  updateMetaMetadataViaHeader,
  zIndexResizableColumnDown,
}: Pick<
  UploaderEditorTypes,
  | 'field'
  | 'initialTabIndex'
  | 'isProcessing'
  | 'isTypingFromHeader'
  | 'meta'
  | 'noOfInputs'
  | 'setTypingFromHeader'
  | 'updateMetaMetadata'
  | 'updateMetaMetadataViaHeader'
  | 'zIndexResizableColumnDown'
>) => {
  const { id, name, type, valueType } = field;
  const fieldId = id;
  const fieldLabel = name || '';
  const options = getFieldOptions(field);

  const showBool = type === ValueTypeName.Bool;
  const showDateTime = type === ValueTypeName.DateTime;
  const showMultiSelection =
    type === ValueTypeName.Selection && valueType?.multiSelect;
  const showNumberField = type === ValueTypeName.Number;
  const showSingleSelection =
    (type === ValueTypeName.Selection && !valueType?.multiSelect) ||
    type === ValueTypeName.Status;
  const showTextField = type === ValueTypeName.Text;

  // Callback to update the provider after a change in the header.
  const updateHeader = (value: string) =>
    updateMetaMetadataViaHeader({ fieldDefinitionId: fieldId, value });

  // We wanna know if there is at least one cell in this metadata column that is invalid.
  const hasErrorCells = meta.some(
    ({ metaData }) =>
      metaData &&
      metaData.some(
        (fieldMeta) =>
          fieldMeta.fieldDefinitionId === fieldId && fieldMeta.error
      )
  );
  const hasWarningCells = meta.some(
    ({ metaData }) =>
      metaData &&
      metaData.some(
        (fieldMeta) =>
          fieldMeta.fieldDefinitionId === fieldId && fieldMeta.warning
      )
  );

  const header = showBool ? (
    <EditorBoolField label={fieldLabel} updateProvider={updateHeader} />
  ) : showDateTime ? (
    <EditorDateTimeField
      isHeader
      isTypingFromHeader={isTypingFromHeader}
      label={fieldLabel}
      name={`header-${fieldId}`}
      setTypingFromHeader={setTypingFromHeader}
      tabIndex={initialTabIndex}
      updateProvider={updateHeader}
    />
  ) : showMultiSelection ? (
    <EditorSelectionMultiField
      label={fieldLabel}
      name={`header-${fieldId}`}
      options={options}
      tabIndex={initialTabIndex}
      updateProvider={updateHeader}
    />
  ) : showSingleSelection ? (
    <EditorSelectionSingleField
      label={fieldLabel}
      name={`header-${fieldId}`}
      options={options}
      tabIndex={initialTabIndex}
      updateProvider={updateHeader}
    />
  ) : showNumberField || showTextField ? (
    <EditorTextField
      isHeader
      isTypingFromHeader={isTypingFromHeader}
      label={fieldLabel}
      name={`header-${fieldId}`}
      setTypingFromHeader={setTypingFromHeader}
      tabIndex={initialTabIndex}
      type={type === ValueTypeName.Number ? 'number' : 'text'}
      updateProvider={updateHeader}
    />
  ) : null;

  const defaultWidth = showBool
    ? 112
    : showDateTime
    ? 140
    : showMultiSelection
    ? 500
    : showNumberField
    ? 130
    : showSingleSelection
    ? 300
    : showTextField
    ? 300
    : 200;

  return (
    <ResizableColumnWrapper
      defaultWidth={defaultWidth}
      zIndexResizableColumnDown={zIndexResizableColumnDown}
    >
      <HeaderWrapper
        hasErrorCells={hasErrorCells}
        hasWarningCells={hasWarningCells}
      >
        {isProcessing ? fieldLabel : header}
      </HeaderWrapper>
      <ContentColumnWrapper>
        {meta.map(({ id, metaData }, index) => {
          const tabIndex = getTabIndex({ index, initialTabIndex, noOfInputs });
          // Callback to update the provider after a change in an individual cell.
          const updateCell = (value: string) => {
            updateMetaMetadata({
              documentId: id,
              fieldDefinitionId: fieldId,
              value,
            });
          };

          const metaDataInstance = metaData
            ? metaData.find(
                (fieldMeta) => fieldMeta.fieldDefinitionId === fieldId
              )
            : '';

          const { error, value, warning } = metaDataInstance || {};

          const contentCell = showBool ? (
            <EditorBoolField
              errorString={error}
              updateProvider={updateCell}
              value={value}
              warningString={warning}
            />
          ) : showDateTime ? (
            <EditorDateTimeField
              errorString={error}
              isTypingFromHeader={isTypingFromHeader}
              setTypingFromHeader={setTypingFromHeader}
              tabIndex={tabIndex}
              updateProvider={updateCell}
              value={value}
              warningString={warning}
            />
          ) : showMultiSelection ? (
            <EditorSelectionMultiField
              errorString={error}
              name={`header-${fieldId}`}
              options={options}
              tabIndex={tabIndex}
              updateProvider={updateCell}
              value={value}
              warningString={warning}
            />
          ) : showSingleSelection ? (
            <EditorSelectionSingleField
              errorString={error}
              name={`header-${fieldId}`}
              options={options}
              tabIndex={tabIndex}
              updateProvider={updateCell}
              value={value}
              warningString={warning}
            />
          ) : showNumberField || showTextField ? (
            <EditorTextField
              errorString={error}
              isTypingFromHeader={isTypingFromHeader}
              name={`cellName-${id}`}
              setTypingFromHeader={setTypingFromHeader}
              tabIndex={tabIndex}
              type={type === ValueTypeName.Number ? 'number' : 'text'}
              updateProvider={updateCell}
              value={value}
              warningString={warning}
            />
          ) : null;

          return (
            <ContentCellWrapper key={id}>
              {isProcessing && <Layer />}
              {contentCell}
            </ContentCellWrapper>
          );
        })}
      </ContentColumnWrapper>
    </ResizableColumnWrapper>
  );
};
