import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import {
  QcPermissionCategory,
  TaskType,
  TbqModuleType,
  TbqRieScanType,
  TbqScanType,
  TbqTask,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import type { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikDate,
  FormikForm,
  FormikInput,
  FormikRadioGroup,
  FormikTextarea,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import { ParticipantSelect } from '@pro4all/workflow/ui/task-sidebar';
import { canOnlyAssignTaskToSelf } from '@pro4all/workflow/ui/utils';

import { useTBQTask } from '../useTBQTask';

import { useTBQTaskFormConfig } from './useTBQTaskFormConfig';

export interface Fields extends Omit<Partial<TbqTask>, 'assignedUser'> {
  participant?: Option;
}

interface Props {
  onClose: () => void;
}

export const TBQTaskForm: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const { searchParams } = useRouting();
  const { userEmail, userFirstName, userLastName, userId } =
    useOrganizationContext();
  const isRIEOpen = searchParams.is('action', 'createRIEScan');

  const {
    object: { id, name, projectId, tbqScanTypeAllowed, tbqRieScanType },
  } = useObjectDetailContext();
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');
  const permissions = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
  });

  if (!projectId) throw Error('`projectId` required for a TBQTaskForm');

  const toOption = ({ id, name }: TbqScanType | TbqRieScanType): Option => ({
    id,
    label: t(`TBQ.${name}`),
  });

  const optionsScanType = tbqScanTypeAllowed?.map(toOption) ?? [];
  const rieScanTypes = tbqRieScanType?.map(toOption) ?? [];

  const options = isRIEOpen ? rieScanTypes : optionsScanType;

  const submitTaskForm = useTBQTask({ objectName: name, onCreate: onClose });

  const getParticipant = () => {
    if (canOnlyAssignTaskToSelf(permissions, TaskType.TbqScan)) {
      const meOption: Option = {
        id: userId,
        label: `${userFirstName} ${userLastName} (${userEmail})`,
      };
      return meOption;
    } else return undefined;
  };

  const initialValues: Fields = {
    description: '',
    endTime: undefined,
    name: '',
    nodeId: id,
    participant: getParticipant(),
    projectId,
    tbqModuleType: isRIEOpen ? TbqModuleType.Rie : TbqModuleType.Brandcheck,
    tbqScanTypeId: options?.[0]?.id,
  };

  const { getField, validationSchema } = useTBQTaskFormConfig();
  const nameField = getField('name');
  const descriptionField = getField('description');
  const endTimeField = getField('endTime');
  const typeField = getField('tbqScanTypeId');

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={submitTaskForm}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting, dirty }) => (
        <FormikForm>
          <input name="nodeId" type="hidden" value={values.nodeId} />
          <FormWrapper>
            <FormikInput
              autoFocus
              id={nameField.name}
              inputRef={inputRef}
              label={nameField.label}
              name={nameField.name}
            />
            <FormikTextarea
              id={descriptionField.name}
              label={descriptionField.label}
              name={descriptionField.name}
              rows={4}
            />
            <Box mb={1} mt={1}>
              <Text variant="h5">{t('Gebouw')}</Text>
              <Text>{name}</Text>
            </Box>
            {options?.length && (
              <Box mb={2} mt={2}>
                <FormikRadioGroup
                  defaultValue={options?.[0]?.id}
                  label={typeField.label}
                  name={typeField.name}
                  options={options}
                />
              </Box>
            )}
            <ParticipantSelect
              disabled={canOnlyAssignTaskToSelf(permissions, TaskType.TbqScan)}
              projectId={projectId}
            />
            <FormikDate
              label={endTimeField.label}
              minDate={dayjs()}
              name={endTimeField.name}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
