import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const DocumentMoved: React.FC<{
  documentName: string;
}> = ({ documentName }) => {
  const entityTypeTranslation = EntityTypeTranslation.Document;
  return (
    <ItemChangedMessage
      description={MessageAction.Moved}
      entityName={documentName}
      entityTypeTranslation={entityTypeTranslation}
    />
  );
};

export const DocumentsMoved: React.FC<{
  count: number;
}> = ({ count }) => {
  const { t } = useTranslation();

  return <Alert>{t('Moved {{count}} documents', { count: count })}</Alert>;
};
