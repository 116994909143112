import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { PhotoService } from '@pro4all/quality-control/data-access';
import { PhotoContext } from '@pro4all/shared/contexts';
import { useAnswerEditContext } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useFetchThumbnailUrls = ({
  fieldId,
  photoIds,
}: {
  fieldId: string;
  photoIds: string[];
}) => {
  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');
  const [loading, setLoading] = useState(false);
  const {
    addPhotos,
    state: { photos },
  } = useContext(PhotoContext);
  const isMounted = useRef(false);
  const { setCanSubmit } = useAnswerEditContext();

  // Prevent loading photos that have been loaded previously.
  const idsNotLoadedYet = useMemo(
    () =>
      photoIds.filter(
        (id) =>
          !photos.find(
            (photo) =>
              // In case the url was not fetched correctly it will have a null value and we should reload it again.
              photo.id === id && photo.instanceId === instanceId && photo.url
          )
      ),
    [photoIds] // eslint-disable-line react-hooks/exhaustive-deps
    // Don't add 'photos' as a dependancy!! It will cause infinite rendering and it's not needed because 'photoIds' contains all possible new photoIds to be loaded.
  );

  useEffect(() => {
    isMounted.current = true;

    const getThumbnails = async () => {
      if (idsNotLoadedYet.length) {
        setLoading(true);
        setCanSubmit(false);
        const additionalPhotos = await Promise.all(
          idsNotLoadedYet.map(async (id) => {
            const url = await PhotoService.getThumbnailUrl({
              id,
              instanceId,
              timeout: 0,
            });
            return { fieldId, id, instanceId, url };
          })
        );

        if (isMounted.current) {
          if (additionalPhotos.length > 0) {
            addPhotos(additionalPhotos);
          }
          setLoading(false);
          setCanSubmit(true);
        }
      }
    };

    getThumbnails();

    return () => {
      isMounted.current = false;
    };
  }, [addPhotos, fieldId, idsNotLoadedYet, instanceId, setCanSubmit]);

  return { loading };
};
