import React from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

const ICON_WIDTH = 20;

const ChevronIcon = styled(Icon)`
  justify-content: center;
  width: ${ICON_WIDTH}px;
  transition: transform 150ms;
  &.isOpen {
    transform: rotate(90deg);
  }
`;

const Level = styled.div<{ $depth: number; $expandable: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  ${({ $depth = 1, $expandable }) => css`
    /* use the ICON_WIDTH to create offsets for nested items. When an item
    * does not have subfolders, we need to add a level of spacing to
    * compensate for the missing '>' icon. */
    margin: 1px 1px 1px
      ${($expandable ? $depth - 1 : $depth) * ICON_WIDTH + 8}px;
    padding-left: 4px;
  `}
`;

export const ExpandIcon = ({
  depth,
  expandable,
  expanded,
  id,
  isSelected,
  isParentOfSelected,
  onExpand,
}: {
  depth: number;
  expandable: boolean;
  expanded: boolean;
  id: string;
  isParentOfSelected: boolean;
  isSelected: boolean;
  onExpand: (expanded: boolean) => void;
}) => {
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onExpand(!expanded);
  };

  return (
    <Level $depth={depth} $expandable={expandable}>
      {expandable && (
        <ChevronIcon
          className={expanded ? 'isOpen' : ''}
          data-testid={`chevron-${id}`}
          fontSize="small"
          htmlColor={
            isParentOfSelected || isSelected
              ? theme.palette.primary.main
              : customColors.grey600
          }
          iconName="keyboardArrowRight"
          onClick={handleClick}
        />
      )}
    </Level>
  );
};
