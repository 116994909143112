import { State } from '../useUploaderEditorReducer';

import { useCalculateMetaForFileToUpload } from './helpers/useCalculateMetaForFileToUpload';

export const useAppendFilesForUploadAction = () => {
  const calculateMetaForFileToUpload = useCalculateMetaForFileToUpload();

  const appendFilesForUploadAction = ({
    payload,
    state,
  }: {
    payload: File[];
    state: State;
  }) => {
    const {
      currentDocumentsInFolder,
      documentCurrent,
      fields,
      filesToUpload,
      meta,
    } = state;

    const currentFileNames = meta.map((document) => document.name);

    // Filter out the names in the payload (files) that are already in the list of currentFileNames.
    const filteredPayload = payload.filter(
      (file) => !currentFileNames.includes(file.name)
    );

    const additionalMeta = calculateMetaForFileToUpload({
      currentDocumentsInFolder,
      documentCurrent,
      fields,
      filesToUpload: filteredPayload,
    });

    return {
      ...state,
      filesToUpload: [...filesToUpload, ...payload],
      meta: [...meta, ...additionalMeta],
    };
  };

  return appendFilesForUploadAction;
};
