import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const ButtonsContainer = styled.div<{ noWrap: boolean }>`
  display: flex;
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const Button = styled.div<{
  $error: boolean;
  $selected: boolean;
  $warning: boolean;
}>`
  border: solid 1px;
  border-radius: 4px;
  border-color: ${({ $error, $selected, $warning }) =>
    $error
      ? customColors.errorAlert
      : $warning
      ? customColors.warning
      : $selected
      ? ({ theme }) => theme.palette.success.dark
      : customColors.grey700};
  background-color: ${({ $selected }) =>
    $selected
      ? ({ theme }) => theme.palette.success.light
      : customColors.white};
  color: ${({ $selected }) =>
    $selected
      ? ({ theme }) => theme.palette.success.contrastText
      : customColors.grey700};
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;
  user-select: none;
  &:hover {
    cursor: pointer;
    background-color: ${({ $selected }) =>
      $selected
        ? ({ theme }) => theme.palette.success.dark
        : customColors.grey300};
    color: ${({ $selected }) =>
      $selected
        ? ({ theme }) => theme.palette.success.light
        : customColors.grey900};
    border-color: ${({ $selected }) =>
      $selected
        ? ({ theme }) => theme.palette.success.dark
        : customColors.grey900};
  }
`;
