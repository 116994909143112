import styled, { css } from 'styled-components';

const row = css`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 1rem;
  }
`;

export const Footer = styled.footer`
  ${row}
  padding: 1rem 1.5rem 1.5rem;
`;

export const Header = styled.header`
  ${row}
  padding: 1.5rem 1.5rem 1rem;
`;

export const Progress = styled.aside`
  color: #533be2;
  font-size: 1rem;
  margin-right: auto;
  text-align: right;
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto 0 1.5rem;
`;
