import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Button } from '@pro4all/shared/ui/buttons';
import { FormikInput } from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { Buttons, Form } from '../registration-form/FormComponents';
import { PasswordStrengthMeter } from '../registration-form/PasswordStrengthMeter';

import { useFinalizeInvitationFormConfig } from './useFinalizeInvitationFormConfig';

export type FormValues = {
  confirmPassword: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
};

interface Props {
  onSubmit: (values: FormValues) => Promise<void>;
}

export const FinalizeInvitationForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const { getField, validationSchema } = useFinalizeInvitationFormConfig();
  const firstNameField = getField('firstName');
  const lastNameField = getField('lastName');
  const passwordField = getField('password');
  const confirmPasswordField = getField('confirmPassword');
  const phoneNumberField = getField('phoneNumber');

  const initialValues: FormValues = {
    confirmPassword: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <FormWrapper noPadding>
            <FormikInput
              autoFocus
              label={firstNameField.label}
              name={firstNameField.name}
            />
            <FormikInput
              label={lastNameField.label}
              name={lastNameField.name}
            />
            <FormikInput
              label={phoneNumberField.label}
              name={phoneNumberField.name}
            />
            <FormikInput
              label={passwordField.label}
              name={passwordField.name}
              type="password"
            />
            <FormikInput
              label={confirmPasswordField.label}
              name={confirmPasswordField.name}
              type="password"
            />
            <PasswordStrengthMeter value={values.password} />
          </FormWrapper>
          <Buttons>
            <Button color="primary" type="submit">
              {t('Save and login')}
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};
