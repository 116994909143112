import styled from 'styled-components';

import {
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
} from '@pro4all/shared/mui-wrappers';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';

export const MenuItem = styled(MUIMenuItem)`
  && {
    align-items: center;
    display: flex;

    &:hover {
      color: ${customColors.grey900};
      background-color: ${customColors.grey300};

      .MuiSvgIcon-root {
        color: ${customColors.grey900};
      }
    }
  }
`;

export const Menu = styled(MUIMenu)`
  && {
    .MuiPaper-root.MuiPopover-paper {
      border-radius: 8px;
      box-shadow: ${StylingDefaults.boxShadow};

      .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
        padding: 8px;
        color: ${customColors.grey700};
        background-color: ${customColors.white};

        .MuiSvgIcon-root {
          color: ${customColors.grey600};
        }

        &:hover {
          color: ${customColors.grey900};
          background-color: ${customColors.grey300};

          .MuiSvgIcon-root {
            color: ${customColors.grey900};
          }
        }
      }
    }
  }
`;

export const Label = styled.span`
  margin-right: auto;

  * + &,
  & + * {
    margin-left: 0.5rem;
  }
`;
