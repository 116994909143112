import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

import { MoveExistingUserProps } from './types';

export const AlreadyMoved = ({
  newOrganizationName,
}: Pick<MoveExistingUserProps, 'newOrganizationName'>) => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({ isInviteExistingUser: true });

  const description = (
    <Trans
      components={{ strong: <strong /> }}
      defaults="You are already in the organization <strong>{{newOrganizationName}}</strong>. You can login and start collaborating right away."
      values={{
        newOrganizationName,
      }}
    />
  );

  return (
    <InfoPage
      actions={[actionLogin]}
      description={description}
      title={t('You are already moved')}
    />
  );
};
