import React from 'react';

import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useIntegrationsActions } from './useIntegrationsActions';

export const IntegrationsActionBar = () => {
  const { mainActions } = useIntegrationsActions();

  return (
    <TableActionBar
      dataTestid="integrations-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
