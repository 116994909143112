import React from 'react';

import { ChartGroupWrapper } from './styled';
import { CommonChartProps } from './types';
import {
  CountChartSupplementalValue,
  CountChartValue,
  ValueChartTitle,
  ValueWrapper,
  ValueWrapperAligner,
} from './ValueChart.styles';

export const ValueChart: React.FC<
  {
    afterValue?: string;
    preValue?: string;
    prefix?: string;
    suffix?: string;
    title?: string;
    value: string;
  } & CommonChartProps
> = ({
  afterValue,
  chartKey,
  columnSpan,
  preValue,
  prefix,
  rowSpan,
  suffix,
  title,
  value,
}) => {
  const val = `${prefix ? prefix : ''}${value}${suffix ? suffix : ''}`;

  return (
    <ChartGroupWrapper
      columnSpan={columnSpan}
      data-testid={chartKey}
      rowSpan={rowSpan}
    >
      <ValueChartTitle>{title}</ValueChartTitle>
      <ValueWrapperAligner>
        <ValueWrapper>
          {preValue && (
            <CountChartSupplementalValue>
              {preValue}
            </CountChartSupplementalValue>
          )}
          <CountChartValue>{val}</CountChartValue>
          {afterValue && (
            <CountChartSupplementalValue>
              {afterValue}
            </CountChartSupplementalValue>
          )}
        </ValueWrapper>
      </ValueWrapperAligner>
    </ChartGroupWrapper>
  );
};
