import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const Container = styled(Box)`
  && {
    @media print {
      display: none;
    }
  }
`;

export const Tabs = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { projectId } = params;

  const hasQCNotificationsFeature = useFeatureFlag('quality-digest-settings');

  return (
    <Container>
      <SubTabs
        base={`${generateRoute('projectQualityControlMain', {
          params: { projectId },
        })}/`}
      >
        <Tab
          data-testid="qc-results-tab"
          label={t('Results')}
          onClick={() => {
            goTo('projectQualityControlResults', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectQualityControlResults',
            });
          }}
          value="results"
        />
        <Tab
          data-testid="qc-floor-plans-tab"
          label={t('Drawings')}
          onClick={() => {
            goTo('projectQualityControlDrawings', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectQualityControlDrawings',
            });
          }}
          value="drawings"
        />
        <Tab
          data-testid="qc-tasks-tab"
          label={t('Tasks')}
          onClick={() => {
            goTo('projectQualityControlTasks', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectQualityControlTasks',
            });
          }}
          value="tasks"
        />
        <Tab
          data-testid="qc-my-saved-answers"
          label={t('My saved answers')}
          onClick={() => {
            goTo('projectQualityControlMySavedAnswers', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectQualityControlMySavedAnswers',
            });
          }}
          value="mysavedanswers"
        />
        {hasQCNotificationsFeature && (
          <Tab
            data-testid="qc-notifications-tab"
            label={t('Notifications')}
            onClick={() => {
              goTo('projectQualityControlNotifications', {
                params: { projectId },
              });
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId: projectId || '',
                route: 'projectQualityControlNotifications',
              });
            }}
            value="notifications"
          />
        )}
      </SubTabs>
    </Container>
  );
};
