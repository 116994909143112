import styled from 'styled-components';

import { Button as UIButton } from '@pro4all/shared/ui/buttons';
import { Text as MuiText } from '@pro4all/shared/ui/typography';

export const Button = styled(UIButton)`
  && {
    align-self: flex-start;
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
`;

export const Text = styled(MuiText)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
