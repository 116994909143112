import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoMessages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage shapeName="flow1" title={t("There aren't any messages")} />
  );
};
