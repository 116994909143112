import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  DeleteQueryMutation,
  LoadQueriesFragmentFragmentDoc,
  SavedSearch,
  useDeleteQueryMutation,
} from '@pro4all/graphql';

import { SearchDeleted } from './SearchDeleted';
import { useOptimisticRestore } from './useOptimisticRestore';

export const useOptimisticDelete = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const restoreSavedSearch = useOptimisticRestore({ id, name });

  const [deleteSavedSearch] = useDeleteQueryMutation({
    onCompleted: (data: DeleteQueryMutation) =>
      enqueueSnackbar(
        <SearchDeleted
          name={data?.deleteQuery?.name || ''}
          onUndo={restoreSavedSearch}
        />,
        { variant: 'success' }
      ),
    onError: () =>
      enqueueSnackbar(t('Something went wrong'), { variant: 'error' }),
    optimisticResponse: {
      deleteQuery: {
        errors: [],
        name,
        success: true,
      },
    },
    update: (cache) => {
      const data: SavedSearch | null = cache.readFragment({
        fragment: LoadQueriesFragmentFragmentDoc,
        id: `SavedSearch:${id}`,
        optimistic: true,
      });
      if (data) {
        cache.modify({
          broadcast: false,
          fields: {
            deletedAt() {
              return new Date();
            },
          },
          id: cache.identify(data),
        });
      }
    },
    variables: { id, name },
  });

  return id ? deleteSavedSearch : () => false;
};
