import { State } from '../useUploaderEditorReducer';

export const selectDocumentAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const { documentIdsSelected, meta } = state;
  const isSelectedAlready = documentIdsSelected.includes(payload);
  const documentIdsSelectedUpdated = isSelectedAlready
    ? documentIdsSelected.filter((id) => id !== payload)
    : [...documentIdsSelected, payload];

  return {
    ...state,
    allDocumentsSelected: meta.length === documentIdsSelectedUpdated.length,
    documentIdsSelected: documentIdsSelectedUpdated,
  };
};
