import { useEffect } from 'react';

import { useHierarchicalListQuery } from '@pro4all/graphql';
import { FieldDefinition } from '@pro4all/graphql';
import { useHierarchyEditorContext } from '@pro4all/shared/hierarchy-editor';

export const useFillInitialItemsHierarchy = ({
  item,
  value,
}: {
  item: FieldDefinition;
  value: string;
}) => {
  const { valueType } = item || {};
  const hierarchyListId =
    valueType && valueType.hierarchyListId ? valueType.hierarchyListId : '';

  const { fillInitialItems } = useHierarchyEditorContext();

  const { data } = useHierarchicalListQuery({
    fetchPolicy: 'cache-and-network',
    skip: !hierarchyListId,
    variables: {
      id: hierarchyListId,
    },
  });

  useEffect(() => {
    if (data) {
      // Initially fill the provider the moment we have hierarchy data.
      fillInitialItems({
        currentBreadcrumbs: value ? JSON.parse(value) : '',
        items: data.hierarchicalList.hierarchy,
      });
    }
  }, [data, fillInitialItems, value]);
};
