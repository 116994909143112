import { FieldDefinition, TemplateService } from '@pro4all/graphql';

import { useMetaDataContext } from '../../views/MetaDataContext';

import { useFieldContext } from './TemplateMutationContext';

export const useFilterOutUsedFields = ({
  usedFields,
}: {
  usedFields: FieldDefinition[];
}) => {
  const { templateService } = useMetaDataContext();
  const { fieldDefinitions } = useFieldContext();

  // In case of `Quality control` context, fields can be used multiple times over different sections.
  // So we don't filter them out from the available list once they are included in the template.
  if (templateService === TemplateService.QualityControl) return usedFields;

  // But in case of `Documents` context, fields can be used only once.
  // So we want a field to be removed from the list of available fields, the moment the field is included in the template.

  const usedFieldDefinitionIds = fieldDefinitions.map(
    (fieldDefinition) => fieldDefinition.fieldDefinitionId || fieldDefinition.id
  );

  const filteredUsedFields = usedFields.filter(
    (usedField) => !usedFieldDefinitionIds.includes(usedField.id)
  );

  return filteredUsedFields;
};
