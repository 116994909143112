// hooks/useBlockNavigationAndSaveDraft.ts
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface UseListenNavigationParams {
  bodyEdited: boolean;
  handleDraftSave: () => void;
  isDirty: boolean;
  isSubmitted: string | null;
  isSubmitting: boolean;
  readOnly: boolean;
}

export const useListenNavigation = ({
  isDirty,
  bodyEdited,
  isSubmitting,
  readOnly,
  handleDraftSave,
  isSubmitted,
}: UseListenNavigationParams) => {
  const history = useHistory();
  useEffect(() => {
    // Only listen to navigation if the form is editable
    const unlisten = !readOnly
      ? history.listen((changes) => {
          if (changes.search.includes('submitting')) return;
          handleDraftSave();
          return;
        })
      : null;

    // Cleanup the history.Listen() when the component unmounts
    return () => {
      if (unlisten) unlisten();
    };
  }, [
    bodyEdited,
    handleDraftSave,
    history,
    isDirty,
    isSubmitting,
    isSubmitted,
    readOnly,
  ]);
};
