import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Tooltip } from '@pro4all/shared/mui-wrappers';

interface Props extends React.ComponentPropsWithoutRef<'span'> {
  endLength?: number;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  text?: string;
  tooltip?: boolean;
  width?: string;
}

export const MiddleEllipsis: React.FC<Props> = ({
  endLength = 6,
  placement = 'top',
  text = '',
  tooltip = true,
  width,
  ...rest
}) => {
  // Replace spaces with non-breaking spaces
  const t = text.replace(/ /g, '\u00A0');
  const start = t.slice(0, t.length - endLength);
  const end = t.slice(-endLength);
  const [isEllipsisActive, setIsEllipsisActive] = React.useState(false);

  const startRef = useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setIsEllipsisActive(node.offsetWidth < node.scrollWidth);
    });
    resizeObserver.observe(node);
    return () => resizeObserver.disconnect();
  }, []);

  if (text.length < endLength) {
    return (
      <Ellipsis width={width} {...rest}>
        {text}
      </Ellipsis>
    );
  }

  return (
    <Ellipsis width={width} {...rest}>
      <Tooltip
        disableHoverListener={!tooltip || !isEllipsisActive}
        placement={placement}
        title={text}
      >
        <ContentWrapper>
          <Start ref={startRef}>{start}</Start>
          {endLength > 0 && <End>{end}</End>}
        </ContentWrapper>
      </Tooltip>
    </Ellipsis>
  );
};

const ContentWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-grow: 1;
`;

export const Ellipsis = styled.span<{ width?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: ${({ width }) => width ?? '100%'};
  min-width: 0;
`;

const Start = styled.span`
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const End = styled.span`
  flex-basis: content;
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
`;

export default MiddleEllipsis;
