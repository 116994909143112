import { useCallback, useEffect, useState } from 'react';

import {
  Template,
  TemplateService,
  TemplatesIncludeFragmentFragment,
  TemplateState,
  TemplateType,
  useTemplatesIncludeQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';

export const useTemplatesInclude = ({
  globalId,
  includeCreatedAt = false,
  includeCreatedBy = false,
  includeItems = false,
  includeOrgItems = true,
  includeScope = false,
  includeState = false,
  includeType = false,
  includeVersion = false,
  selectedIds,
  skip = false,
  state,
  templateService,
  templateType,
}: {
  globalId?: string;
  includeCreatedAt?: boolean;
  includeCreatedBy?: boolean;
  includeItems?: boolean;
  includeOrgItems?: boolean;
  includeScope?: boolean;
  includeState?: boolean;
  includeType?: boolean;
  includeVersion?: boolean;
  selectedIds?: string[];
  skip?: boolean;
  state?: TemplateState;
  templateService: TemplateService;
  templateType: TemplateType;
}) => {
  const { params, searchParams } = useRouting();
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplatesIncludeFragmentFragment | null>(null);

  const action = searchParams.get('action');
  const {
    state: { items },
    replaceAllItems,
  } = useOptimisticResponseContext<TemplatesIncludeFragmentFragment>();

  const { data, error, loading } = useTemplatesIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !templateType || skip,
    variables: {
      globalId,
      includeCreatedAt,
      includeCreatedBy,
      includeItems,
      includeOrgItems,
      includeScope,
      includeState,
      includeType,
      includeVersion,
      projectId: params.projectId,
      selectedIds,
      state,
      templateService,
      templateType,
    },
  });

  const templates = data?.templates;

  if (action === 'editSet' && !items.length && templates)
    replaceAllItems(templates);

  const templateOptions: Option[] =
    templates
      ?.map((set) => ({
        id: set.id,
        label: set.name,
      }))
      .sort(sortBy({ key: 'label' })) || [];

  const selectTemplate = useCallback(
    (template: Template) => {
      searchParams.set({ action: 'editSet', id: template.id });
    },
    [searchParams]
  );

  useEffect(() => {
    const setFromUrl = searchParams.get('id');
    const setByUrl = items?.find((set) => set.id === setFromUrl);

    if (setByUrl !== selectedTemplate) {
      setSelectedTemplate(setByUrl);
    }
  }, [items, searchParams, selectedTemplate, setSelectedTemplate]);

  return {
    error,
    loading,
    selectTemplate,
    selectedTemplate,
    templateOptions,
    templates,
  };
};
