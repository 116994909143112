import { ThemeColor } from '@pro4all/shared/themes';
import {
  ErrorBackground,
  LogoColor,
  LogoType,
  ShapeColor,
  ShapeType,
  SuccessBackground,
  WarningBackground,
} from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';

import { PageType } from './types';

export const useGetInfoPageUI = (type: PageType) => {
  let bgElement: JSX.Element;
  let color: ThemeColor;
  let iconName: IconName;
  let shapeColor: ShapeColor;
  let shapeType: ShapeType;

  const logoColor: LogoColor = 'grey';
  const logoType: LogoType = 'regular';

  if (type === 'error') {
    bgElement = <ErrorBackground />;
    color = 'errorHover';
    iconName = 'errorOutlined';
    shapeColor = 'orange';
    shapeType = 'trust';
  } else if (type === 'warning') {
    bgElement = <WarningBackground />;
    color = 'warningHover';
    iconName = 'reportProblemOutlined';
    shapeColor = 'orange';
    shapeType = 'build';
  } else {
    bgElement = <SuccessBackground />;
    color = 'successHover';
    iconName = 'checkCircleOutlined';
    shapeColor = 'green';
    shapeType = 'collaborate';
  }

  return {
    bgElement,
    color,
    iconName,
    logoColor,
    logoType,
    shapeColor,
    shapeType,
  };
};
