import React from 'react';
import { useTranslation } from 'react-i18next';

import { FolderPermission } from '@pro4all/graphql';
import { IconHeader } from '@pro4all/shared/ui/general';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { useGetSinglePermissionsData } from './hooks/useGetSinglePermissionsData';
import * as Styled from './PermissionHeader.styles';

interface Props {
  permission: FolderPermission;
}

export const EXCLUDED_PERMISSIONS = [
  FolderPermission.AllowSync,
  FolderPermission.DeleteContent,
  FolderPermission.DeleteOwnContent,
  FolderPermission.ReadAllDocuments,
  FolderPermission.ReadAllVersions,
  FolderPermission.ReadApproval,
  FolderPermission.ReadContent,
  FolderPermission.ReadFolder,
  FolderPermission.ReadLastApproval,
  FolderPermission.ReadLastVersion,
  FolderPermission.ReadLastVersionAndApproved,
  FolderPermission.ReadOwn,
  FolderPermission.ReadOwnAndFinalized,
  FolderPermission.ReadOwnVersions,
  FolderPermission.UpdateContent,
  FolderPermission.UpdateOwn,
];

export const PermissionHeader: React.FC<Props> = ({ permission }) => {
  const { t } = useTranslation();
  const getSinglePermissionData = useGetSinglePermissionsData();
  const singlePermissionData = getSinglePermissionData(permission);

  if (EXCLUDED_PERMISSIONS.includes(permission)) return null;

  return (
    <Styled.PermissionHeaderWrapper>
      <Tooltip
        placement="bottom"
        title={t(singlePermissionData.permissionText)}
      >
        <IconHeader color="primary" iconName={singlePermissionData.icon} />
      </Tooltip>
    </Styled.PermissionHeaderWrapper>
  );
};
