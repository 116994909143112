import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

import { MoveExistingUserProps } from './types';

export const ActiveSubscriptionFound = ({
  currentOrganizationName,
}: Pick<MoveExistingUserProps, 'currentOrganizationName'>) => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({ isInviteExistingUser: true });

  const description = (
    <Trans
      components={{ strong: <strong /> }}
      defaults="{{currentOrganizationName}} has an <strong>active subscription</strong> and you are the <strong>last person</strong> that can edit and cancel the subscription. Please assign someone else to these rights or cancel the subscription yourself and try again."
      values={{
        currentOrganizationName,
      }}
    />
  );

  return (
    <InfoPage
      actions={[actionLogin]}
      description={description}
      title={t('Active subscription found')}
      type="error"
    />
  );
};
