import React from 'react';
import { useTranslation } from 'react-i18next';

import { TemplateService } from '@pro4all/graphql';
import { TableContextProvider, TableSearch } from '@pro4all/shared/ui/general';

import { useMetaDataContext } from '../../views/MetaDataContext';

import { columns } from './Columns';
import { FieldCardsCustom } from './FieldCardsCustom';
import { FieldCardsGlobal } from './FieldCardsGlobal';
import * as Styled from './TemplateFieldList.styles';
import { useFieldContext } from './TemplateMutationContext';
import { useFilterOutUsedFields } from './useFilterOutUsedFields';

export const TemplateFieldList = () => {
  const { t } = useTranslation();
  const { reusableFields } = useFieldContext();
  const { templateService } = useMetaDataContext();

  const isQualityControl = templateService === TemplateService.QualityControl; // We need this to distinct QC from MD.

  const reusableFieldsFiltered = useFilterOutUsedFields({
    usedFields: reusableFields,
  });

  return (
    <TableContextProvider
      checkable={false}
      columns={columns}
      id="table-template-field-list"
      items={reusableFieldsFiltered}
    >
      <Styled.FieldsList data-testid="create-template-selection-list">
        {!isQualityControl && (
          <Styled.GlobalFields>
            <Styled.Sticked>
              <Styled.Label variant="h6">
                {t('Standard meta data fields')}
              </Styled.Label>
            </Styled.Sticked>
            <FieldCardsGlobal />
          </Styled.GlobalFields>
        )}
        <Styled.CustomFields>
          <Styled.Sticked>
            {!isQualityControl && (
              <Styled.Label variant="h6">
                {t('My meta data fields')}
              </Styled.Label>
            )}
            <Styled.SearchBox>
              <TableSearch />
            </Styled.SearchBox>
          </Styled.Sticked>
          <FieldCardsCustom />
        </Styled.CustomFields>
      </Styled.FieldsList>
    </TableContextProvider>
  );
};
