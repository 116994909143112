import { FormFields } from './types';

export const useInitialValues = ({
  aiEnabledOverall,
  aiEnabledOrganization,
  id,
  name,
}: {
  aiEnabledOrganization?: boolean;
  aiEnabledOverall?: boolean;
  id?: string;
  name?: string;
}) => {
  const initialValues: FormFields = {
    id,
    name: name,
    settings: {
      ai_admin_settings: aiEnabledOverall,
      ai_organization_settings: aiEnabledOrganization,
    },
  };

  return initialValues;
};
