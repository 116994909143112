import type { Rect } from './rect';
import { useMove } from './useMove';

const MIN_WIDTH = 12;
const MIN_HEIGHT = 12;
const SLOPE_MARGIN = 2;

export function useRect({
  container,
  rect,
  update,
}: {
  container?: HTMLElement | null;
  rect: Rect;
  update: (nextRect: Partial<Rect>) => void;
}) {
  const constrain = ({ height = 0, width = 0 }) => {
    const constrainedHeight = width / rect.aspectRatio;
    const constrainedWidth = height * rect.aspectRatio;

    const deltaHeight = height - rect.height;
    const deltaWidth = width - rect.width;

    return { constrainedHeight, constrainedWidth, deltaHeight, deltaWidth };
  };

  const getNextRect = () => ({
    bottom: rect.bottom,
    left: rect.left,
    right: rect.right,
    top: rect.top,
  });

  /* Restrict resizing to MIN_WIDTH * MIN_HEIGHT */
  const handleUpdate = (
    nextRect: Pick<Rect, 'bottom' | 'left' | 'right' | 'top'>
  ) => {
    const { bottom, left, right, top } = nextRect;
    const height = bottom - top;
    const width = right - left;

    if (height > MIN_HEIGHT && width > MIN_WIDTH) {
      update(nextRect);
    }
  };

  const useRelativeMove: typeof useMove = (fn) => useMove(fn, container);

  const move = useRelativeMove(({ dx, dy }) => {
    const nextRect = {
      bottom: rect.bottom + dy,
      left: rect.left + dx,
      right: rect.right + dx,
      top: rect.top + dy,
    };

    update(nextRect);
  });

  const resizeBottomLeft = useRelativeMove(({ x, y }) => {
    const nextRect = getNextRect();

    const { constrainedHeight, constrainedWidth, deltaHeight, deltaWidth } =
      constrain({ height: y - rect.top, width: rect.right - x });

    const rectSlope = rect.height / rect.width;
    const deltaSlope = deltaHeight / deltaWidth;

    if (Math.abs(rectSlope - deltaSlope) > SLOPE_MARGIN) {
      return;
    } else if (rect.constrainProportions && deltaHeight < deltaWidth) {
      nextRect.bottom = y;
      nextRect.left = rect.right - constrainedWidth;
    } else if (rect.constrainProportions && deltaHeight > deltaWidth) {
      nextRect.bottom = rect.top + constrainedHeight;
      nextRect.left = x;
    } else {
      nextRect.bottom = y;
      nextRect.left = x;
    }

    handleUpdate(nextRect);
  });

  const resizeBottomRight = useRelativeMove(({ x, y }) => {
    const nextRect = getNextRect();

    const { constrainedHeight, constrainedWidth, deltaHeight, deltaWidth } =
      constrain({ height: y - rect.top, width: x - rect.left });

    const rectSlope = rect.height / rect.width;
    const deltaSlope = deltaHeight / deltaWidth;

    if (Math.abs(rectSlope - deltaSlope) > SLOPE_MARGIN) {
      return;
    } else if (rect.constrainProportions && deltaHeight < deltaWidth) {
      nextRect.bottom = y;
      nextRect.right = rect.left + constrainedWidth;
    } else if (rect.constrainProportions && deltaHeight > deltaWidth) {
      nextRect.bottom = rect.top + constrainedHeight;
      nextRect.right = x;
    } else {
      nextRect.bottom = y;
      nextRect.right = x;
    }

    handleUpdate(nextRect);
  });

  const resizeTopLeft = useRelativeMove(({ x, y }) => {
    const nextRect = getNextRect();

    const { constrainedHeight, constrainedWidth, deltaHeight, deltaWidth } =
      constrain({ height: rect.bottom - y, width: rect.right - x });

    const rectSlope = rect.height / rect.width;
    const deltaSlope = deltaHeight / deltaWidth;

    if (Math.abs(rectSlope - deltaSlope) > SLOPE_MARGIN) {
      return;
    } else if (rect.constrainProportions && deltaHeight < deltaWidth) {
      nextRect.left = rect.right - constrainedWidth;
      nextRect.top = y;
    } else if (rect.constrainProportions && deltaHeight > deltaWidth) {
      nextRect.left = x;
      nextRect.top = rect.bottom - constrainedHeight;
    } else {
      nextRect.left = x;
      nextRect.top = y;
    }

    handleUpdate(nextRect);
  });

  const resizeTopRight = useRelativeMove(({ x, y }) => {
    const nextRect = getNextRect();

    const { constrainedHeight, constrainedWidth, deltaHeight, deltaWidth } =
      constrain({ height: rect.bottom - y, width: x - rect.left });

    const rectSlope = rect.height / rect.width;
    const deltaSlope = deltaHeight / deltaWidth;

    if (Math.abs(rectSlope - deltaSlope) > SLOPE_MARGIN) {
      return;
    } else if (rect.constrainProportions && deltaHeight < deltaWidth) {
      nextRect.right = rect.left + constrainedWidth;
      nextRect.top = y;
    } else if (rect.constrainProportions && deltaHeight > deltaWidth) {
      nextRect.right = x;
      nextRect.top = rect.bottom - constrainedHeight;
    } else {
      nextRect.right = x;
      nextRect.top = y;
    }

    handleUpdate(nextRect);
  });

  return {
    move,
    resizeBottomLeft,
    resizeBottomRight,
    resizeTopLeft,
    resizeTopRight,
  };
}
