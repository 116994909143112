import { useTaskQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useFetchTask = () => {
  const taskId = useRouting().searchParams.get('id') || '';

  const {
    data,
    loading,
    error,
    refetch: refetchTask,
  } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !taskId,
    variables: { id: taskId },
  });

  return { data, error, loading, refetchTask };
};
