import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseRow, Switch, useTableCheck } from '@pro4all/shared/ui/general';

export function CustomCheckRow<Row extends BaseRow>({
  row,
  rowIndex,
}: {
  row: Row;
  rowIndex: number;
}) {
  const { t } = useTranslation();
  const { isChecked, checkRow } = useTableCheck();
  const rowIsChecked = isChecked(row);

  return (
    <Switch
      aria-label={rowIsChecked ? t('Deselect row') : t('Select row')}
      checked={rowIsChecked}
      edge="end"
      onChange={(event: React.ChangeEvent) => {
        event.stopPropagation();
        checkRow(row, rowIndex, false);
      }}
    />
  );
}
