import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Property, PropertyList } from '@pro4all/shared/ui/general';
import { getFileSize } from '@pro4all/shared/utils';

interface Props {
  amountOfDocuments: number;
  amountOfVersions: number;
  createdAt: string;
  creator: string;
  totalFileSize: number;
}

export const FolderProperties: React.FC<Props> = ({
  amountOfDocuments,
  amountOfVersions,
  createdAt,
  creator,
  totalFileSize,
}) => {
  const { t } = useTranslation();

  const formattedCreatedAt = dayjs(createdAt).format('ll') || t('Unknown');
  const formattedAmountOfDocuments = amountOfDocuments?.toString() || '0';
  const formattedAmountOfVersions = amountOfVersions?.toString() || '0';
  const formattedTotalFileSize = getFileSize(totalFileSize) || '0';

  const properties: Property[] = [
    {
      label: t('Created on'),
      value: formattedCreatedAt,
    },
    {
      label: t('Created by'),
      value: creator || t('Unknown'),
    },
    {
      label: t('Number of documents'),
      value: formattedAmountOfDocuments,
    },
    {
      label: t('Number of versions'),
      value: formattedAmountOfVersions,
    },
    {
      label: t('Total size'),
      value: formattedTotalFileSize,
    },
  ];

  return <PropertyList items={properties} orientation="horizontal" px={3} />;
};
