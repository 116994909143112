import { usePersistentState } from '@pro4all/shared/hooks';

export function useOnToggleVersionPane() {
  const [expanded, setExpanded] = usePersistentState(
    `prostream-version-pane-expanded`,
    false
  );

  const onExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };

  return {
    expanded,
    onExpand,
  };
}
