import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { UI, WebViewerInstance } from '@pdftron/webviewer';

import { useUsersIncludeQuery } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { TrackingEvent } from '@pro4all/shared/config';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

import { PdfTronOpenAction } from './pdf-tron-utils/actions';
import {
  Action as PdftronAction,
  Actions as PdftronActions,
  pdftronReducer,
  State as PdftronState,
  Status as PdftronStatus,
} from './pdf-tron-utils/pdftronReducer';
import { redrawPanel } from './pdf-tron-utils/pdftronUtils';
import {
  redrawAnnotations,
  usePdftronUtils,
} from './pdf-tron-utils/pdftronUtils';
import {
  Action as VersionsAction,
  State as VersionsState,
  Status as VersionsStatus,
  versionsReducer,
} from './version-utils/versionsReducer';
import {
  UpdateAnnotationProps,
  useVersionApi,
} from './version-utils/versionUtils';

export const useSetContainerRef = ({
  action,
  document,
  initialVersionId,
  version,
  onLoadingProgress,
  readonly = false,
}: {
  action: PdfTronOpenAction;
  initialVersionId?: string;
  onLoadingProgress?: (percentage: number) => void;
  readonly?: boolean;
} & DocumentAndVersionType) => {
  const { track } = useAnalytics();
  const [ready, setReady] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>();
  const [userMentionData, setUserMentionData] =
    useState<UI.MentionsManager.UserData[]>();
  const setContainerRef = useCallback((container: HTMLDivElement) => {
    containerRef.current = container;
    setReady(true);
  }, []);
  const [versionsState, versionsDispatch] = useReducer(versionsReducer, {});
  const [pdftronState, pdftronDispatch] = useReducer(pdftronReducer, {
    baseVersionId: initialVersionId || document?.versionId || version?.id || '',
    overlayVersionId: '',
    status: PdftronStatus.NOT_INITIALIZED,
  });

  const { userDisplayName, userId } = useOrganizationContext();

  const { data: userData } = useUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeEmail: true,
      projectId: document ? document.projectId : undefined,
    },
  });

  useEffect(() => {
    if (userData && userData.users) {
      setUserMentionData(
        userData.users
          .filter((user) => {
            if (!user) return false;
            if (!user.email || user.email === '') return false;
            if (!user.displayName || user.displayName === '') return false;
            return true;
          })
          .map((user) => ({
            email: user?.email || '',
            value: user?.displayName || 'Unknown',
          }))
      );
    }
  }, [userData]);

  /**
   * To keep all states synchronised between pdftron (iframe with
   * initializer functions) and our application, we use refs to
   * pass around the latest reference to the states
   */
  const instanceRef = useRef<WebViewerInstance>(null);
  const pdftronStateRef = useRef<PdftronState | null>(null);
  const pdftronDispatchRef = useRef<React.Dispatch<PdftronAction> | null>(null);
  const versionsStateRef = useRef<VersionsState | null>(null);
  const versionsDispatchRef = useRef<React.Dispatch<VersionsAction> | null>(
    null
  );
  const handleAnnotationToggleRef = useRef<(versionId: string) => void>();
  const handleAnnotationChangeRef =
    useRef<(props: UpdateAnnotationProps) => void>();
  const handleDocumentLoadedRef = useRef<() => void>();

  const {
    syncVersion,
    initialize,
    updateAnnotation,
    toggleShowAnnotations,
    setSynced,
  } = useVersionApi({
    dispatch: versionsDispatch,
    instance: instanceRef.current,
    onLoadingProgress: (percentage) => {
      onLoadingProgress && onLoadingProgress(percentage);
    },
    state: versionsState,
  });

  /**
   * In case the pdftron editor is accessed directly (on page reload
   * or distributed url), the async load of the document will not be in
   * time to fill the proper versionId. This useEffect takes care of that.
   */
  useEffect(() => {
    pdftronDispatch({
      payload: { versionId: document?.versionId || version?.id || '' },
      type: PdftronActions.SET_BASE_VERSION_ID,
    });
  }, [document, pdftronDispatch, version]);

  /**
   * Update the ref-ed functions used inside pdftron with the latest versions
   * of our functions so we also work with the latest state inside pdftron
   * (Every render the references to our functions change). We only pass
   * referenced useRef functions to pdftron for that reason.
   */
  useEffect(() => {
    pdftronStateRef.current = pdftronState;
    pdftronDispatchRef.current = pdftronDispatch;
    versionsStateRef.current = versionsState;
    versionsDispatchRef.current = versionsDispatch;
    handleAnnotationToggleRef.current = toggleShowAnnotations;
    handleAnnotationChangeRef.current = updateAnnotation;
    handleDocumentLoadedRef.current = () => {
      setPdftronStatus(PdftronStatus.DOCUMENT_LOADED);
      redrawPanel(instanceRef.current);
      /**
       * Enable the leftPanel will re-open the leftpanel if it was opened
       * before the loadDocument call was made
       */
      instanceRef.current?.UI.enableElements(['leftPanel']);
    };
  });

  const {
    loadDocument,
    setStatus: setPdftronStatus,
    initPdfTron,
  } = usePdftronUtils({
    containerRef,
    dispatchRef: pdftronDispatchRef,
    instanceRef,
    stateRef: pdftronStateRef,
    userMentionData,
    versionsDispatchRef,
    versionsStateRef,
  });

  /**
   * Test license will be replaced by NGINX.
   * If for some reason it is not replaced, the Test license will leave a watermark on your document.
   * It also shows up in console.
   */
  const licenseKey = process.env.NX_PDFT_LICENSE || 'TestLicense';

  const trackInit = useCallback(() => {
    track(TrackingEvent.PdftronOpened, {
      action: action,
      documentId: document?.id,
      extension: version?.extension || document?.extension,
      timestamp: new Date().toString(),
      userId,
      versionId: version?.versionId,
    });
  }, [
    document?.extension,
    document?.id,
    track,
    userId,
    version?.extension,
    version?.versionId,
  ]);

  /**
   * This only runs 1 time, once the DOM node we will mount pdftron in
   * is ready. We also initialize the state here with all the versions
   * that are available.
   */
  useEffect(() => {
    if (
      (document || version) &&
      userMentionData &&
      userDisplayName &&
      ready &&
      pdftronState.status === PdftronStatus.NOT_INITIALIZED
    ) {
      if (licenseKey) {
        initPdfTron(
          {
            handleAnnotationChangeRef,
            handleDocumentLoadedRef,
          },
          userDisplayName,
          licenseKey,
          readonly,
          userMentionData
        ).then(trackInit);

        const versions = document
          ? document?.versions
          : version
          ? version?.versions
          : [];

        if (document?.versions || version?.versions) {
          versions?.forEach((someVersion) => {
            const syncNeeded =
              (initialVersionId || document?.versionId || version?.id) ===
              someVersion?.id;
            initialize({ syncNeeded, version: someVersion });
          });
        }
      }
    }
  }, [
    document,
    initPdfTron,
    initialVersionId,
    initialize,
    licenseKey,
    pdftronState.status,
    ready,
    trackInit,
    userDisplayName,
    userMentionData,
    version,
  ]);

  /**
   * Watch for changes to the baseVersion and overlayVersion, and
   * determine if we should load a new version document.
   */
  useEffect(() => {
    if (pdftronState.status === PdftronStatus.DOCUMENT_NOT_LOADED) {
      const base = versionsState[pdftronState.baseVersionId];
      const overlay = versionsState[pdftronState.overlayVersionId];
      if (base) {
        if (overlay) {
          if (overlay.status === VersionsStatus.PENDING_RENDER) {
            loadDocument();
          }
        } else {
          if (base.status === VersionsStatus.PENDING_RENDER) {
            loadDocument();
          }
        }
      }
    }
  }, [
    pdftronState.status,
    loadDocument,
    versionsState,
    pdftronState.baseVersionId,
    pdftronState.overlayVersionId,
  ]);

  /**
   * If any changes are made to versions, or toggling of annotations,
   * the status of a version changes. Here we watch these statusses and
   * act accordingly. For example, if we load the annotations for a version
   * for the first time, it is marked as SYNC_REQUIRED, indicating that
   * we need to start the sync process to fetch the annotations from the
   * API
   */
  useEffect(() => {
    const notReady = [
      PdftronStatus.NOT_INITIALIZED,
      PdftronStatus.INITIALIZING,
    ].includes(pdftronState.status);
    if (notReady) return;

    const versionsArray = Object.values(versionsState);

    versionsArray.forEach((version) => {
      const status = version.status;

      if (status === VersionsStatus.SYNC_REQUIRED) {
        return syncVersion(version.id);
      }

      if (pdftronState.status === PdftronStatus.DOCUMENT_LOADED) {
        if (status === VersionsStatus.PENDING_RENDER) {
          redrawAnnotations({
            annotations: version.annotations,
            instance: instanceRef.current,
            shouldBeHidden: !version.showAnnotations,
          });

          setSynced(version.id);
        }
      }
    });
  }, [versionsState, pdftronState, syncVersion, setSynced]);

  return setContainerRef;
};
