import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { CommunicationService } from '@pro4all/communication/data-access';
import { TrackingEvent } from '@pro4all/shared/config';
import { ActionProps } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

import { usePubliclySharedMessagesContext } from '../../publicly-shared-messages';

export const useDownloadZipButtonProps = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const publicAttachmentsContext = usePubliclySharedMessagesContext();

  const downloadZip = async () => {
    try {
      const zipDownloadUrl = publicAttachmentsContext?.data?.zipDownloadUrl;
      track(TrackingEvent.DownloadZipSelected);
      enqueueSnackbar(t('Download started'));
      zipDownloadUrl &&
        (await CommunicationService.downloadPublicAttachmentDirect({
          fileName: 'Prostream download.zip',
          url: zipDownloadUrl,
        }));
    } catch (exception) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const downloadZipButtonProps: ActionProps = {
    ariaLabel: t('Download .zip'),
    dataTestId: 'download-zip-button',
    key: 'download-zip-button',
    label: t('Download all as .zip'),
    onClick: downloadZip,
    startIcon: 'download',
  };

  return { downloadZipButtonProps };
};
