import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Word: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g data-name="Word 48" id="Word_48">
      <rect fill="#fff" height="32" rx="1" ry="1" width="21" x="23" y="8" />
      <path
        d="M43,9V39H24V9H43m0-2H24a2,2,0,0,0-2,2V39a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V9a2,2,0,0,0-2-2Z"
        fill="#2b579a"
      />
      <rect fill="#2b579a" height="2" width="12" x="27" y="13" />
      <rect fill="#2b579a" height="2" width="12" x="27" y="17" />
      <rect fill="#2b579a" height="2" width="12" x="27" y="21" />
      <rect fill="#2b579a" height="2" width="12" x="27" y="25" />
      <rect fill="#2b579a" height="2" width="12" x="27" y="29" />
      <rect fill="#2b579a" height="2" width="12" x="27" y="33" />
      <path d="M28,3,3,7.38V40.62L28,45Z" fill="#2b579a" fillRule="evenodd" />
      <path
        d="M23,17l-2.89.18L18.42,27c0,.13,0,.26-.07.4s0,.27-.05.4,0,.27,0,.41,0,.28,0,.42h0q0-.26,0-.49c0-.15,0-.29,0-.43s0-.25,0-.36,0-.21-.05-.29l-1.93-9.68-2.74.17-2,9.26c0,.14-.06.28-.08.42s0,.27-.06.41,0,.27,0,.4,0,.26,0,.38h0c0-.17,0-.33,0-.48s0-.29,0-.42,0-.25,0-.36,0-.21,0-.31L9.49,17.81,7,18l2.66,13.4,2.77.17,1.89-9c0-.11,0-.22.06-.35s0-.25.05-.38,0-.27,0-.42,0-.3,0-.45h0c0,.15,0,.3,0,.44s0,.28,0,.41,0,.26.05.38,0,.24.06.36l1.91,9.22,3,.18L23,17"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);
