import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddFile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M6 1.25C4.48106 1.25 3.26025 2.49043 3.26 3.99953L3.26 3.99981V4L3.25 19.9995V20C3.25 21.5093 4.4709 22.75 5.99 22.75H18C19.5142 22.75 20.75 21.5142 20.75 20V8.83C20.75 8.10438 20.4632 7.40071 19.9371 6.88641L15.1136 2.06294C14.5993 1.53685 13.8956 1.25 13.17 1.25H6ZM4.76 4C4.76 3.30931 5.3191 2.75 6 2.75H13.17C13.5026 2.75 13.8173 2.88175 14.0426 3.11321L14.0426 3.11326L14.0497 3.12033L18.8797 7.95033L18.8796 7.95038L18.8868 7.95735C19.1182 8.18273 19.25 8.49739 19.25 8.83V20C19.25 20.6858 18.6858 21.25 18 21.25H5.99C5.30918 21.25 4.75013 20.6908 4.75 20.0002V20L4.76 4.00047L4.76 4ZM13 3.5V8C13 8.55 13.45 9 14 9H18.5L13 3.5Z"
      fillRule="evenodd"
    />
    <path d="M16.875 16.625H14.625V18.875C14.625 19.4937 14.1188 20 13.5 20C12.8812 20 12.375 19.4937 12.375 18.875V16.625H10.125C9.50625 16.625 9 16.1188 9 15.5C9 14.8812 9.50625 14.375 10.125 14.375H12.375V12.125C12.375 11.5062 12.8812 11 13.5 11C14.1188 11 14.625 11.5062 14.625 12.125V14.375H16.875C17.4937 14.375 18 14.8812 18 15.5C18 16.1188 17.4937 16.625 16.875 16.625Z" />
  </SvgIcon>
);
