import { FolderPermission } from '@pro4all/graphql';

import { inConflictWithPermissionsOfOwnEntity } from './entity-context/helpers/inConflictWithPermissionsOfOwnEntity';
import { State } from './entity-context/types';
import { isDocumentsNone } from './shared/helpers/checkPermissionsWrappers';

export const shouldPermissionEntityCellBeDisabled = ({
  allPermissions,
  entityId,
  permission,
  state,
}: {
  allPermissions: FolderPermission[];
  entityId: string;
  permission: FolderPermission;
  state: State;
}) => {
  const noDocumentPermissions = isDocumentsNone(allPermissions);

  switch (permission) {
    case FolderPermission.ApproveContent:
      return (
        noDocumentPermissions ||
        inConflictWithPermissionsOfOwnEntity({
          entityId,
          permission: FolderPermission.ApproveContent,
          state,
        })
      );
    case FolderPermission.FinalizeContent:
      return (
        !allPermissions.includes(FolderPermission.ReadAllDocuments) ||
        allPermissions.includes(FolderPermission.ReadOwnAndFinalized) ||
        allPermissions.includes(FolderPermission.ReadOwn) ||
        inConflictWithPermissionsOfOwnEntity({
          entityId,
          permission: FolderPermission.FinalizeContent,
          state,
        })
      );
    case FolderPermission.CreateContent:
      return (
        noDocumentPermissions ||
        inConflictWithPermissionsOfOwnEntity({
          entityId,
          permission: FolderPermission.CreateContent,
          state,
        })
      );
    default:
      return false;
  }
};
