import React, { PropsWithChildren } from 'react';

import { AppPermissionType, useAppPermissions } from '@pro4all/shared/identity';

/**
 * Use the ShowWithPermission wrapper in case the scope (organization or project)
 * is not relevant for the children to check permission for.
 */
export function ShowWithPermission({
  children,
  appPermission,
}: PropsWithChildren<{
  appPermission?: AppPermissionType;
}>) {
  const { hasAppPermission } = useAppPermissions();

  // Without Fragment tags Linting gives a warning, but this component must return a JSX component.
  return hasAppPermission(appPermission) ? <>{children}</> : null; // eslint-disable-line react/jsx-no-useless-fragment
}
