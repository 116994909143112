import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import {
  FieldDefinition,
  TbqModuleType,
  ValueTypeName,
} from '@pro4all/graphql';
import {
  getFieldsFromItems,
  isFieldVisible,
  unnestInstance,
} from '@pro4all/metadata/ui/utils';
import { useRouting } from '@pro4all/shared/routing-utils';
import { InstanceValues } from '@pro4all/shared/types';

import { useAnswersPercentageContext } from './AnswersPercentageContext';
import {
  calculatePercentage,
  filterFieldsByType,
  isFieldAnswered,
  isMixedSectionsAndFields,
} from './formAnswersPercentageUtils';

type SectionPercentage = {
  id: string;
  percentage: string;
};

export type FieldSummary = {
  fieldDefinition: FieldDefinition;
  isFieldAnswered: boolean;
  parentSectionId: string;
  totalFieldCount: number;
};

type SectionSummary = {
  answeredCountSum: number;
  totalSectionCount: number;
};

type MatchingSectionDataItem = {
  answeredCountSum: number;
  id: string;
  totalSectionCount: number;
};

export function useCountFormAnsweredFieldsPercentage(items: FieldDefinition[]) {
  const { searchParams } = useRouting();
  const { setPercentageTotal } = useAnswersPercentageContext();
  const formikContext = useFormikContext<InstanceValues>();
  const [sectionSummaryMap, setSectionSummaryMap] = useState<
    Map<string, SectionSummary>
  >(new Map());

  const formikUpdatedValues: InstanceValues = formikContext?.values;

  const isTbqBrandcheck = searchParams.is(
    'tbqScanType',
    TbqModuleType.Brandcheck
  );
  const isTbqRie = searchParams.is(
    'tbqScanType',
    TbqModuleType.Rie.toLowerCase()
  );

  //Only section fields
  const unnestedSectionFields: FieldDefinition[] = unnestInstance(
    items as FieldDefinition[]
  ).filter((field) => field.type === ValueTypeName.Section);

  //Fields to match total questions, answers and conditions
  const unnestedFieldsExcludeSections: FieldDefinition[] =
    getFieldsFromItems(items);

  //Check if is mixed form with sections and fields
  const isMixedSectionsAndFieldsForm = isMixedSectionsAndFields(
    unnestedSectionFields,
    unnestedFieldsExcludeSections
  );

  const fields = isTbqRie
    ? filterFieldsByType(unnestedFieldsExcludeSections, true)
    : isTbqBrandcheck
    ? filterFieldsByType(unnestedFieldsExcludeSections)
    : unnestedFieldsExcludeSections;

  const mainSectionsIds = unnestedSectionFields
    .filter((section) => !section.parentSectionId)
    .map((section) => section.id);

  const formThatContainsOnlyFields = unnestedSectionFields.every(
    (section) =>
      !section.parentSectionId && section.type !== ValueTypeName.Section
  );

  useEffect(() => {
    const fieldSummaryMap = new Map<string, FieldSummary>();
    //for all fields, determine if they are answered
    const setFieldSummaryMap = () => {
      fields.forEach((field) => {
        const answered = isFieldAnswered(field, formikContext.values);

        const currentSummary: FieldSummary = {
          fieldDefinition: field,
          isFieldAnswered: answered,
          parentSectionId: field.parentSectionId ?? '',
          totalFieldCount: 0,
        };

        fieldSummaryMap.set(field.id, currentSummary);
      });
    };

    setFieldSummaryMap();

    //For each section, count the number of answered fields
    const tempSectionSummary: Map<string, SectionSummary> = new Map();
    countAnsweredFieldsPerSection(
      fieldSummaryMap,
      tempSectionSummary,
      unnestedSectionFields,
      fields,
      formikUpdatedValues,
      isTbqRie,
      isTbqBrandcheck,
      formThatContainsOnlyFields
    );

    //This function is used to calculate the total percentage of answered fields in form when the form contains at first level fields and sections
    if (isMixedSectionsAndFieldsForm) {
      calculateTotalPercentageInMixedForm();
    }

    setSectionSummaryMap(new Map(tempSectionSummary));
  }, [formikUpdatedValues]);

  const countAnsweredFieldsPerSection = (
    fieldSummaryMap: Map<string, FieldSummary>,
    tempSectionSummary: Map<string, SectionSummary>,
    unnestedSectionFields: FieldDefinition[],
    fields: FieldDefinition[],
    formikUpdatedValues: InstanceValues,
    isTbqRie: boolean,
    isTbqBrandcheck: boolean,
    formThatContainsOnlyFields: boolean
  ) => {
    fieldSummaryMap.forEach((summary) => {
      let parentSectionId = summary.parentSectionId;
      const relevantFields =
        isTbqRie || isTbqBrandcheck
          ? unnestedFieldsExcludeSections.filter(
              (field) => field.type === ValueTypeName.Selection
            )
          : unnestedFieldsExcludeSections;

      if (formThatContainsOnlyFields) {
        const section = tempSectionSummary.get(summary.fieldDefinition.id) || {
          answeredCountSum: 0,
          totalSectionCount: 0,
        };
        section.answeredCountSum += summary.isFieldAnswered ? 1 : 0;
        section.totalSectionCount += 1;
        tempSectionSummary.set(summary.fieldDefinition.id, section);
      }
      if (
        isFieldVisible({
          field: summary.fieldDefinition,
          formValues: formikUpdatedValues,
          items,
        })
      ) {
        //update count in each of the ancestor sections
        while (parentSectionId) {
          const section = tempSectionSummary.get(parentSectionId) || {
            answeredCountSum: 0,
            totalSectionCount: 0,
          };
          section.answeredCountSum += summary.isFieldAnswered ? 1 : 0;
          section.totalSectionCount += 1;

          tempSectionSummary.set(parentSectionId, section);

          //Search parentSectionId of current parentSection and continue up in hierarchy
          const parentSection = unnestedSectionFields.find(
            (section) => section.id === parentSectionId
          );
          parentSectionId = parentSection?.parentSectionId ?? '';
        }
      }
    });
  };

  const calculateTotalPercentageInMixedForm = () => {
    const nonSectionFields = fields.filter(
      (field) => field.type !== ValueTypeName.Section
    );

    //iterates over fields that are not sections to check if they are answered and visible
    const { visibleAnsweredFieldsCount, visibleTotalFieldsCount } =
      nonSectionFields.reduce(
        (acc, field) => {
          const fieldVisible = isFieldVisible({
            field,
            formValues: formikUpdatedValues,
            items,
          });
          if (fieldVisible) {
            acc.visibleTotalFieldsCount += 1;
            if (isFieldAnswered(field, formikContext.values)) {
              acc.visibleAnsweredFieldsCount += 1;
            }
          }
          return acc;
        },
        { visibleAnsweredFieldsCount: 0, visibleTotalFieldsCount: 0 }
      );

    //calculate total percentage of answered fields in forms with only fields
    const percentageTotal = calculatePercentage(
      visibleAnsweredFieldsCount,
      visibleTotalFieldsCount
    );

    setPercentageTotal(percentageTotal);
  };

  const percentageTotalForSectionForms = () => {
    //calculate total percentage of answered fields in forms with sections
    const topLevelSectionsCounts: MatchingSectionDataItem[] = mainSectionsIds
      .filter((id) => sectionSummaryMap?.has(id))
      .map((id) => {
        const summaryData = sectionSummaryMap.get(id);
        return {
          answeredCountSum: summaryData?.answeredCountSum ?? 0,
          id,
          totalSectionCount: summaryData?.totalSectionCount ?? 0,
        };
      });

    //Total sum of answered fields
    const totalAnsweredCountSum: number = topLevelSectionsCounts.reduce(
      (acc, cur) => acc + cur.answeredCountSum,
      0
    );

    //Total sum of all fields
    const totalSectionCountSum: number = topLevelSectionsCounts.reduce(
      (acc, cur) => acc + cur.totalSectionCount,
      0
    );

    return calculatePercentage(totalAnsweredCountSum, totalSectionCountSum);
  };

  //calculate total percentage of answered fields in forms with only fields
  if (formThatContainsOnlyFields && !isMixedSectionsAndFieldsForm) {
    const { totalAnsweredCountSum, totalSectionCount } = Array.from(
      sectionSummaryMap.values()
    ).reduce(
      (acc, { answeredCountSum, totalSectionCount }) => ({
        totalAnsweredCountSum: acc.totalAnsweredCountSum + answeredCountSum,
        totalSectionCount: acc.totalSectionCount + totalSectionCount,
      }),
      { totalAnsweredCountSum: 0, totalSectionCount: 0 }
    );

    const percentageTotal = calculatePercentage(
      totalAnsweredCountSum,
      totalSectionCount
    );

    setPercentageTotal(percentageTotal);
  } else {
    if (!isMixedSectionsAndFieldsForm) {
      const percentageTotal = percentageTotalForSectionForms();
      setPercentageTotal(percentageTotal);
    }
  }

  const sectionPercentages: SectionPercentage[] = Array.from(
    sectionSummaryMap.entries()
  ).map(([id, summary]) => {
    const percentage = calculatePercentage(
      summary.answeredCountSum,
      summary.totalSectionCount
    );
    return { id, percentage };
  });

  return { sectionPercentages };
}
