import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentService } from '@pro4all/documents/data-access';
import {
  DocumentsPdfTronViewer,
  isPdfTronSupportedFileFormat,
  PdfTronOpenAction,
} from '@pro4all/documents/ui/annotate';
import { DocumentVersion, Maybe } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { ImagePreview, PreviewImg } from '@pro4all/shared/ui/image-preview';

import { PreviewProps } from './DocumentPreview.types';

const DocumentPreviewComponent: React.FC<PreviewProps> = ({
  document,
  open,
  onClose,
  version,
}) => {
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const [previewImages, setPreviewImages] = useState<PreviewImg[]>([]);
  const [slidesLoaded, setSlidesLoaded] = useState<boolean>(false);
  const [documentIsImage, setDocumentIsImage] = useState<boolean>(false);
  const [pdfTronSupported, setIsPdfTronSupported] = useState<boolean>(false);

  const currentVersion = version
    ? version
    : document?.versions?.find((version) => version?.id === document.versionId);

  const totalPreviewPages = currentVersion?.totalPreviewPages || 0;

  const unknown = t('Unknown');
  const name = document ? document.name : version ? version.name : unknown;
  const documentId =
    (document && document.id) || (version && version.documentId) || '';
  const versionId =
    (document && document.versionId) || (version && version.id) || '';

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  useEffect(() => {
    const documentVersion: Maybe<DocumentVersion> | undefined =
      document?.versions?.find(
        (version) => version?.id && version?.id === versionId
      );

    let loading = false;

    const getNextImage = async () => {
      loading = true;

      const previewImg: PreviewImg = {
        src: documentIsImage
          ? await DocumentService.getBlobForVersion({
              documentId: document?.id || '',
              id: documentVersion?.id || '',
            })
          : await DocumentService.getPreviewImage({
              documentId,
              page: previewImages.length,
              versionId,
            }),
      };

      // Is the component still mounted after our async call?
      // Otherwise setting the state throws an error
      if (isMounted.current) {
        loading = false;
        const slides = [...previewImages, previewImg];
        setPreviewImages(slides);
        if (slides.length === totalPreviewPages) {
          setSlidesLoaded(true);
        }
      }
    };

    documentVersion?.totalPreviewPages === 1 &&
    documentVersion?.extension &&
    ['jpg', 'jpeg', 'png'].indexOf(documentVersion?.extension.toLowerCase()) !==
      -1
      ? setDocumentIsImage(true)
      : setDocumentIsImage(false);

    if (documentVersion?.extension)
      setIsPdfTronSupported(
        isPdfTronSupportedFileFormat(documentVersion?.extension.toLowerCase())
      );

    if (
      !pdfTronSupported &&
      isMounted.current &&
      open &&
      !loading &&
      previewImages.length < totalPreviewPages
    ) {
      getNextImage();
    }
  }, [
    document,
    documentId,
    isMounted,
    open,
    totalPreviewPages,
    previewImages,
    versionId,
    documentIsImage,
  ]);

  return pdfTronSupported ? (
    <FloatingModal
      disableEscapeKeyDown={false}
      fullScreen
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      open={open}
      padding={false}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          padding: 0,
          width: '100%',
        }}
      >
        <DocumentsPdfTronViewer
          action={PdfTronOpenAction.PREVIEW}
          hideGoBack
          initialVersionId={currentVersion?.id}
        />
      </Box>
    </FloatingModal>
  ) : (
    <ImagePreview
      onClose={onClose}
      open={open}
      previewImages={previewImages}
      slidesLoaded={slidesLoaded}
      sphereModeEnabled={documentIsImage}
      title={name}
      totalPreviewPages={totalPreviewPages}
    ></ImagePreview>
  );
};

function skipUpdate(prevProps: PreviewProps, nextProps: PreviewProps) {
  return (
    prevProps.open === nextProps.open &&
    prevProps.document?.id === nextProps.document?.id &&
    prevProps.version?.id === nextProps.version?.id
  );
}

export const DocumentPreview = React.memo(DocumentPreviewComponent, skipUpdate);
