import { DocumentActionProps } from '../../types';

export const useHideDeleteDocument = ({
  hasFolderPermission,
  selection,
  selectionVersion,
  userId,
}: Pick<
  DocumentActionProps,
  'hasFolderPermission' | 'selection' | 'selectionVersion' | 'userId'
>) => {
  const hideDeleteDocument = () => {
    if (hasFolderPermission('DeleteContent')) return false;

    if (selection.length > 0) {
      // Documents.
      if (
        selection[0]?.createdBy?.id &&
        hasFolderPermission('DeleteOwnContent')
      ) {
        if (userId === selection[0]?.createdBy.id) {
          return false;
        }
      }

      if (
        selection &&
        selection.length > 0 &&
        hasFolderPermission('DeleteOwnContent')
      ) {
        return selection.every((row) => row.createdBy?.id !== userId);
      }
    }

    if (selectionVersion.length > 0) {
      // Versions.
      if (
        selectionVersion[0]?.createdBy?.id &&
        hasFolderPermission('DeleteOwnContent')
      ) {
        if (userId === selectionVersion[0]?.createdBy.id) {
          return false;
        }
      }

      if (
        selectionVersion &&
        selectionVersion.length > 0 &&
        hasFolderPermission('DeleteOwnContent')
      ) {
        return selectionVersion.every((row) => row.createdBy?.id !== userId);
      }
    }

    return true;
  };

  return hideDeleteDocument;
};
