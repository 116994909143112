import React from 'react';

import { Document, DocumentVersionState } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

export const ApprovalStatus: React.FC<Document> = ({ versionState }) => {
  const theme = useTheme();
  return versionState === DocumentVersionState.Approved ? (
    <Icon htmlColor={theme.palette.success.main} iconName="approveFile" />
  ) : versionState === DocumentVersionState.Rejected ? (
    <Icon htmlColor={theme.palette.error.main} iconName="notInterested" />
  ) : null;
};
