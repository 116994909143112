import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const OpenInFolder: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M3.9047 16.7604V9.14163C3.9047 8.61566 4.33108 8.18928 4.85705 8.18928H19.224C19.4766 8.18928 19.7188 8.08894 19.8974 7.91034C20.2693 7.53843 20.2693 6.93543 19.8974 6.56352C19.7188 6.38492 19.4766 6.28458 19.224 6.28458H11.5235L10.1767 4.93776C9.81946 4.58056 9.33499 4.37988 8.82984 4.37988H3.9047C2.85276 4.37988 2 5.23264 2 6.28458V17.7128C2 18.7647 2.85276 19.6175 3.9047 19.6175H7.71409C8.24006 19.6175 8.66644 19.1911 8.66644 18.6651C8.66644 18.1391 8.24006 17.7128 7.71409 17.7128H4.85705C4.33108 17.7128 3.9047 17.2864 3.9047 16.7604Z" />
      <path
        clipRule="evenodd"
        d="M4.70514 11.5367C4.91711 10.6888 5.67896 10.094 6.55297 10.094H20.0946C21.3338 10.094 22.2427 11.2585 21.9422 12.4606L20.5137 18.1747C20.3017 19.0226 19.5401 19.6175 18.6661 19.6175H5.12444C3.8853 19.6175 2.97608 18.4529 3.27661 17.2508L4.70514 11.5367ZM20.0946 11.9987L6.55297 11.9987L5.12444 17.7128H18.6661L20.0946 11.9987Z"
        fillRule="evenodd"
      />
    </g>
  </SvgIcon>
);
