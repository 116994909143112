import { ValueTypeName } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { toApiDate } from '@pro4all/shared/utils';

export const useMetaDataAnswerToApiAnswer = () => {
  const serializeMultiSelectOptions = (value: Option[] = []) => {
    if (!value) return '';
    const optionLabels = value?.map((option) => option.id);
    return optionLabels.join(',');
  };

  const metaDataAnswerToApiAnswer = ({
    multiSelect,
    type,
    value,
  }: {
    multiSelect: boolean;
    type: ValueTypeName;
    value?: string | boolean | Option | Option[];
  }) => {
    switch (type) {
      case ValueTypeName.Bool:
        if (typeof value === 'boolean') {
          return value?.toString() || 'false';
        } else if (typeof value === 'string') {
          //TODO: Boolean type is a mess in the current setup. Can be a boolean, a string an Option. We need to clean this up to avoid regression.
          return value;
        } else {
          return (value as Option)?.id ?? '';
        }
      case ValueTypeName.DateTime:
        return toApiDate(value as string);
      case ValueTypeName.MultiSelect:
        return serializeMultiSelectOptions(value as Option[]) ?? '';
      case ValueTypeName.UserSelection:
      case ValueTypeName.Selection:
        if (multiSelect) {
          return serializeMultiSelectOptions(value as Option[]) ?? '';
        }
        return (value as Option)?.id ?? '';
      case ValueTypeName.Status:
        return (value as Option)?.id ?? '';
      default:
        return value?.toString() ?? '';
    }
  };

  return { metaDataAnswerToApiAnswer };
};
