import styled from 'styled-components';

export const TBQQuestionSidebarSectionAnswersWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;
export const TBQQuestionSidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const TBQQuestionSidebarColumnContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TBQQuestionSidebarWrapper = styled.div`
  width: 100%;
  height: 'auto';
`;
