import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Button as UIButton } from '@pro4all/shared/ui/buttons';

export const Button = styled(UIButton)`
  && {
    border-color: ${customColors.grey500};
    margin-left: auto;
  }
`;
