import { getThisEntityPermission } from '../helpers/getThisEntityPermission';
import { getUpdatedSaveAndDisplayPermissions } from '../helpers/getUpdatedSaveAndDisplayPermissions';
import { EntityPermissionToggle, State } from '../types';

export const togglePermissionAction = ({
  payload,
  state,
}: {
  payload: EntityPermissionToggle;
  state: State;
}) => {
  const { entityId, permission } = payload;
  const { displayPermissions } = state;

  const thisEntityPermission = getThisEntityPermission(
    entityId,
    displayPermissions
  );

  const currentPermissions = thisEntityPermission.folderPermissions;

  const updatedFolderPermission = {
    ...thisEntityPermission,
    folderPermissions: currentPermissions.includes(permission)
      ? currentPermissions.filter((perm) => perm !== permission)
      : [...currentPermissions, permission],
  };

  const { savePermissionsUpdated, displayPermissionsUpdated } =
    getUpdatedSaveAndDisplayPermissions({
      state,
      updatedFolderPermission,
    });

  return {
    ...state,
    displayPermissions: displayPermissionsUpdated,
    savePermissions: savePermissionsUpdated,
  };
};
