import { FormikValues } from 'formik';

import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  HierarchicalList,
  HierarchicalListsIncludeDocument,
  Hierarchy,
  ScopeType,
  useCreateHierarchicalListMutation,
  useEditHierarchicalListMutation,
} from '@pro4all/graphql';
import { useGetUpdatedItems } from '@pro4all/shared/hierarchy-editor';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';

export const useSubmit = ({
  includeOrgItems,
  selectedHierarchicalList,
}: {
  includeOrgItems: boolean;
  selectedHierarchicalList?: HierarchicalList;
}) => {
  const { params, searchParams } = useRouting();
  const createMode = searchParams.is('action', 'createHierarchicalList');
  const [createHierarchicalList] = useCreateHierarchicalListMutation();
  const [editHierarchicalList] = useEditHierarchicalListMutation();
  const { projectId } = params;
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const getUpdatedItems = useGetUpdatedItems();

  const onClose = () => searchParams.clear();

  const { showMutationMessage } = useShowMessages();

  const toNode = (item: Hierarchy) => {
    const { deletedAt, id, name, parentNodeId, previousNodeId } = item;
    return { deletedAt, id, name, parentNodeId, previousNodeId };
  };

  const onSubmit = async (values: FormikValues) => {
    const { name } = values;

    const variables = {
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeOrgItems,
      includeScope: true,
      projectId,
    };

    try {
      const cachedHierarchicalLists = client?.readQuery({
        query: HierarchicalListsIncludeDocument,
        variables,
      }).hierarchicalLists as HierarchicalList[];

      const nodes = getUpdatedItems().map(toNode);

      if (createMode) {
        const response = await createHierarchicalList({
          variables: {
            name,
            nodes,
            projectId,
          },
        });

        const id = response.data?.createHierarchicalList;

        client?.writeQuery({
          data: {
            hierarchicalLists: [
              ...cachedHierarchicalLists,
              {
                __typename: 'HierarchicalList',
                createdAt: new Date().toISOString(),
                createdBy: {
                  __typename: 'User',
                  displayName: userName,
                  id: userId,
                },
                id,
                name,
                scope: projectId ? ScopeType.Project : ScopeType.Organization,
              },
            ],
          },
          query: HierarchicalListsIncludeDocument,
          variables,
        });

        onClose();
        showMutationMessage({
          action: MessageAction.Create,
          entityType: EntityTypeTranslation.HierarchicalList,
          name,
        });
      } else {
        await editHierarchicalList({
          variables: {
            id: selectedHierarchicalList.id,
            name: selectedHierarchicalList.name !== name ? name : '',
            nodes,
          },
        });

        const currentCachedHierarchicalList = cachedHierarchicalLists.find(
          (cachedHierarchicalList) =>
            cachedHierarchicalList.id === selectedHierarchicalList.id
        );
        const otherCachedHierarchicalList = cachedHierarchicalLists.filter(
          (cachedHierarchicalList) =>
            cachedHierarchicalList.id !== selectedHierarchicalList.id
        );

        client?.writeQuery({
          data: {
            hierarchicalLists: [
              ...otherCachedHierarchicalList,
              { ...currentCachedHierarchicalList, name },
            ],
          },
          query: HierarchicalListsIncludeDocument,
          variables,
        });

        onClose();
        showMutationMessage({
          action: MessageAction.Update,
          entityType: EntityTypeTranslation.HierarchicalList,
          name,
        });
      }
    } catch (e) {
      console.log('ERROR', e);
    }
  };

  return onSubmit;
};
