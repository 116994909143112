import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { IntegrationType } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import {
  FormFooter,
  FormikForm,
  FormikInput,
  FormikSearchableSelect,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useIntegrationConfig } from '../config/useIntegrationConfig';

import { getInitialValues } from './getInitialValues';
import { useGetApplicationOptions } from './useGetApplicationOptions';
import { useIntegrationFormConfig } from './useIntegrationFormConfig';
import { useSubmit } from './useSubmit';

export const IntegrationForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const integrationConfig = useIntegrationConfig();

  const { applicationOptions } = useGetApplicationOptions();

  const { getField, validationSchema } = useIntegrationFormConfig();
  const applicationField = getField('application');
  const nameField = getField('name');

  const initialValues = getInitialValues(applicationOptions);

  const onSubmit = useSubmit({ onClose });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values }) => {
        // Get the properties that are specifically related to the type (Build12, Snagstream etc..)
        const { CustomForm } = values?.application?.id
          ? integrationConfig[
              values?.application?.id as keyof typeof IntegrationType
            ]
          : { CustomForm: null };
        return (
          <FormikForm>
            <FormWrapper noPadding>
              <FormikInput
                autoFocus
                label={nameField?.label}
                name={nameField?.name}
                type="text"
              />
              <FormikSearchableSelect
                id={applicationField?.name}
                label={applicationField?.label}
                name={applicationField?.name || ''}
                options={applicationOptions}
                placeholder={t('Select application')}
              />
              {CustomForm && <CustomForm values={values} />}
            </FormWrapper>

            <FormFooter
              ariaLabelSave="Save integration"
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              onClose={onClose}
              pt={2}
              sticky
            />
          </FormikForm>
        );
      }}
    </Formik>
  );
};
