import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const useDropFilesOnUploadEditor = () => {
  const { appendFilesForUpload } = useUploaderEditorContext();

  const dropFilesOnUploadEditor = (files: File[]) => {
    appendFilesForUpload(files);
  };

  return dropFilesOnUploadEditor;
};
