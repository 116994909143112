import { State } from '../useUploaderEditorReducer';

export const selectDocumentsViaHeaderAction = ({ state }: { state: State }) => {
  const { documentIdsSelected, meta } = state;

  const documentIdsForEdit = meta.map((document) => document.id);

  return {
    ...state,
    allDocumentsSelected: documentIdsSelected.length > 1 ? false : true,
    documentIdsSelected:
      documentIdsSelected.length > 1 ? [] : documentIdsForEdit,
  };
};
