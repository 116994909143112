import styled from 'styled-components';

import { Button, IconButton } from '@pro4all/shared/ui/buttons';

export const PhotoPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const PhotoPreviewImage = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhotoPreviewSlider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const PhotoSlider = styled.div`
  display: flex;
  overflow: auto;
  flex: 1;
  min-height: 80px;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const ImageThumbnail = styled.img<{ active: boolean }>`
  object-fit: cover;
  max-height: 80px;
  min-height: ${({ active }) => (active ? '84px' : '80px')};
  border-bottom: ${({ active, theme }) =>
    active ? `4px solid ${theme.palette.primary.main}` : 'none'};
  border-radius: 5px;
  padding-bottom: 1px;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const ImageThumbnailFailed = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.error.main};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing(1)};
  height: 80px;
  border-radius: 5px;
`;

export const ImagePreview = styled.img<{ active: boolean }>`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const UploadButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DeletePhotoButton = styled(IconButton)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
