import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useCountriesQuery } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';
import {
  FormFooter,
  FormikForm,
  FormikInput,
  FormikSearchableSelect,
  InfoBlock,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useCompanyDataRegistrationConfig } from './hooks/useCompanyDataRegistrationConfig';
import { useCompanyDataRegistrationPrevious } from './hooks/useCompanyDataRegistrationPrevious';
import { useSubmitCompanyDataRegistration } from './hooks/useSubmitCompanyDataRegistration';
import { useSubscriptionHelpers } from './hooks/useSubscriptionHelpers';
import * as Styled from './styles/CompanyDataRegistration.styles';
import * as StyledShared from './styles/Shared.styles';
import { LandingPage } from './LandingPage';
import { SubscriptionSelectionCard } from './SubscriptionSelectionCard';

export interface CompanyDataRegistrationFields {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: Option;
  postalcode: string;
  vatIdentificationNumber: string;
}

export const CompanyDataRegistration = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { getSubscriptionLevel } = useSubscriptionHelpers();
  const selectedSubscriptionLevel = searchParams.get('license') as string;
  const selectedSubscription = getSubscriptionLevel(selectedSubscriptionLevel);
  const { getField, validationSchema } = useCompanyDataRegistrationConfig();
  const addressLine1Field = getField('addressLine1');
  const addressLine2Field = getField('addressLine2');
  const postalcodeField = getField('postalcode');
  const cityField = getField('city');
  const countryField = getField('country');
  const vatIdentificationNumber = getField('vatIdentificationNumber');

  const onSubmit = useSubmitCompanyDataRegistration();
  const onPrevious = useCompanyDataRegistrationPrevious();

  const showLanding = searchParams.is('landing', 'true');

  const { data } = useCountriesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const countryOptions = data ? data.countries.countries : [];
  const defaultCountry = data
    ? countryOptions.find((option) => option.id === 'nl')
    : null;
  const defaultCountryOption: Option = {
    id: defaultCountry?.id || '',
    label: defaultCountry?.label || '',
  };

  return (
    <StyledShared.PaneWrapper>
      {showLanding ? (
        <LandingPage />
      ) : (
        selectedSubscription && (
          <Box display="flex" width="100%">
            <Box mr={8}>
              <Styled.LabelChoosenLicence variant="h6">
                {t('Selected license')}
              </Styled.LabelChoosenLicence>
              <SubscriptionSelectionCard
                ignoreIsChecked
                subscriptionLevel={selectedSubscription}
              />
            </Box>
            <Box width="100%">
              <Formik
                enableReinitialize
                initialValues={{
                  addressLine1: '',
                  addressLine2: '',
                  city: '',
                  country: defaultCountryOption,
                  postalcode: '',
                  vatIdentificationNumber: '',
                }}
                onSubmit={(values: CompanyDataRegistrationFields) => {
                  onSubmit(values, selectedSubscriptionLevel);
                }}
                validationSchema={validationSchema}
              >
                {({ dirty, errors, isSubmitting }) => (
                  <FormikForm>
                    <Box maxWidth="620px">
                      <FormWrapper>
                        <Text variant="h2">{t('The billing information')}</Text>
                        <InfoBlock
                          paragraph={t(
                            'After completing your billing information, we ask you to confirm the payment. The confirmation goes through a payment environment, namely Mollie. Here we ask you to fill in your payment details and to transfer 0.01 euros for verification.'
                          )}
                        />
                      </FormWrapper>
                      <Box maxWidth="350px">
                        <FormWrapper>
                          <FormikInput
                            label={addressLine1Field?.label}
                            name={addressLine1Field?.name}
                          />
                          <FormikInput
                            label={addressLine2Field?.label}
                            name={addressLine2Field?.name}
                          />
                          <FormikInput
                            label={postalcodeField?.label}
                            name={postalcodeField?.name}
                          />
                          <FormikInput
                            label={cityField?.label}
                            name={cityField?.name}
                          />
                          <FormikSearchableSelect
                            id={countryField?.name}
                            label={countryField?.label}
                            name={countryField?.name || ''}
                            options={countryOptions}
                          />
                          <FormikInput
                            label={vatIdentificationNumber?.label}
                            name={vatIdentificationNumber?.name}
                          />
                        </FormWrapper>
                      </Box>
                    </Box>

                    <FormFooter
                      disableSubmit={isSubmitDisabled({
                        dirty,
                        errors,
                        isSubmitting,
                      })}
                      onClose={() => searchParams.clear()}
                      sticky
                      submitLabel="Save and confirm"
                    >
                      <Button
                        data-testid="previous"
                        onClick={onPrevious}
                        startIcon="arrowBack"
                        type="button"
                      >
                        {t('Previous')}
                      </Button>
                    </FormFooter>
                  </FormikForm>
                )}
              </Formik>
            </Box>
          </Box>
        )
      )}
    </StyledShared.PaneWrapper>
  );
};
