import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Document } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useFetchProject } from '@pro4all/projects/data-access';
import { TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import {
  AutocompleteRenderInputParams,
  Box,
  TextField,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  OnSearchArgs,
  SearchEntities,
  useSearchTracking,
} from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

import { StorageKeyType, useHistory } from '../filters/';
import { ResultMatch } from '../results';

import {
  ClearButton,
  DeleteOptionIcon,
  ListBoxButton,
  OptionWrap,
  Path,
  SearchBarAutocomplete,
  SearchBarWrap,
  StartAdornment,
} from './SearchBar.styles';
import { SearchBarHeader } from './SearchBarHeader';
import { SearchBarIcon } from './SearchBarIcon';
import { TopTen } from './useTopTen';

export type SearchOption = Option & {
  currentFile?: string | null;
  customIcon?: TMuiIcon;
  drawing?: string;
  iconType?: IconName;
  indicateStateColor?: string;
  page?: string;
  path?: string;
  projectId?: string;
  referenceNumber?: string;
  searchType?: SearchEntities;
  type: string;
};

interface Props {
  currentQuery: string | null;
  historyContext?: StorageKeyType;
  noInputIcon?: boolean;
  onBack?: () => void;
  onSearch: (args: OnSearchArgs) => void;
  onSelect: (option: SearchOption) => void;
  openMyQueries?: () => void;
  setContextQuery?: (arg: string[]) => void;
  setCurrentQuery: (query: string) => void;
  showBackButton?: boolean;
  showSavedSearches?: boolean;
  topTen: TopTen;
  type: SearchEntities;
}

export const SearchBar: React.FC<Props> = ({
  currentQuery,
  setCurrentQuery,
  onSearch,
  onSelect,
  onBack,
  topTen,
  type,
  openMyQueries,
  setContextQuery,
  historyContext,
  showSavedSearches = true,
  showBackButton = true,
  noInputIcon = false,
}) => {
  const { t } = useTranslation();
  const { searchParams, params } = useRouting();
  const { projectId } = params;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { resetInitialItems } = useOptimisticResponseContext<Document>() || {};

  const paramQuery = searchParams.get('search');
  const contextIds = searchParams.get('contextIds');

  const {
    trackClearSearchHistoryClicked,
    trackRecentSearchClicked,
    trackTopTenSearchResultClicked,
  } = useSearchTracking(type);

  const { userOrganizationName } = useOrganizationContext();

  const { projectData } = useFetchProject();

  const { name: projectName } = projectData?.project || {};

  const history = useHistory({
    storageKey: historyContext || StorageKeyType.RecentSearches,
  });

  const [open, setOpen] = useState(false);

  const isSelectIconEnabled = useFeatureFlag('customericons');

  const {
    clearTopTen,
    fetchTopTen,
    loadingTopTen,
    topTenResults,
    topTenOptions,
  } = topTen;

  useEffect(() => {
    if (currentQuery === null && paramQuery && contextIds === 'contextids')
      setCurrentQuery(paramQuery);
  }, [currentQuery, paramQuery, setCurrentQuery, contextIds]);

  const handleOpen = () => {
    setOpen(true);
    searchParams.get('id') && searchParams.delete('id'); // Close sidebar
  };

  const handleInputChange = async (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    // Add typeguard to checkif event.target is an HTMLElement.
    if (event?.target instanceof HTMLElement && event?.type === 'click') {
      const isClearButton =
        event.target.tagName === 'svg' ||
        event.target.tagName === 'path' ||
        event.target.tagName === 'button';

      if (isClearButton) {
        setCurrentQuery('');
        setContextQuery && setContextQuery([]);
      }
      return; // Prevents value from being overwritten with selected option label
    }
    setCurrentQuery(newInputValue);
    await fetchTopTen(newInputValue);
  };

  const submitSearch = (value: string, disableTracking?: boolean) => {
    open && setOpen(false);
    history.set(value);

    onSearch({ disableTracking, query: value });
    setCurrentQuery(value);
    clearTopTen();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      resetInitialItems();
      event.preventDefault();
      submitSearch(currentQuery || '');
    }
  };

  const handleDeleteSearch = (
    event: React.MouseEvent<SVGSVGElement>,
    searchString: string
  ) => {
    event.stopPropagation();
    history.remove(searchString);
  };

  const handleBack = () => {
    setCurrentQuery('');
    setContextQuery && setContextQuery([]);
    onBack && onBack();
  };

  const getOptionLabel = (option: unknown) =>
    typeof option === 'string' ? option : (option as SearchOption).label;

  const historyOptions: SearchOption[] = history.recentSearches.map(
    (queryString) => ({
      id: queryString,
      label: queryString,
      type: 'history',
    })
  );

  const options: SearchOption[] = currentQuery ? topTenOptions : historyOptions;
  const noTopTenResults = Boolean(
    options.find((option) => (option as SearchOption).id === 'no-results')
  );

  const SearchListBox = React.forwardRef<HTMLUListElement>(
    ({ ...rest }, ref) => {
      const { t } = useTranslation();

      const handleClear = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        history.clear();
        trackClearSearchHistoryClicked();
      };

      return (
        <div>
          <Box display="flex" flex={1} justifyContent="space-between">
            {currentQuery ? (
              <SearchBarHeader
                show={!noTopTenResults && !loadingTopTen}
                type={type}
              />
            ) : (
              <>
                <ListBoxButton>{t('History')}</ListBoxButton>
                <ListBoxButton>
                  <ClearButton onMouseDown={handleClear}>
                    {t('Clear all')}
                  </ClearButton>
                </ListBoxButton>
              </>
            )}
          </Box>
          <ul ref={ref} {...rest} />
        </div>
      );
    }
  ) as React.ComponentType; // Cast to resolve type conflict: MUI's ListboxComponent prop expects a React.ComponentType, while also requiring a forwardRef.

  const onChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: unknown
    // reason: AutocompleteChangeReason
  ) => {
    if (!value) return;
    if (typeof value !== 'string') {
      const searchOption = value as SearchOption;
      if (searchOption.type === 'history') {
        trackRecentSearchClicked(searchOption.label);
        submitSearch(searchOption.label, true);
      }
      if (searchOption.type === 'topTen') {
        const listIndex = topTenResults.findIndex(
          (result) => result.id === searchOption.id
        );

        trackTopTenSearchResultClicked(
          searchOption.id,
          listIndex,
          currentQuery || ''
        );
        setOpen(false);
        onSelect(searchOption);
      }
    }
  };

  return (
    <SearchBarWrap>
      {paramQuery !== null && showBackButton && (
        <Button
          color="inherit"
          onClick={handleBack}
          startIcon="arrowBack"
          variant="text"
        >
          {isMobile ? '' : t('Back to documents')}
        </Button>
      )}
      <Box flex={1}>
        <SearchBarAutocomplete
          ListboxComponent={SearchListBox}
          autoComplete
          data-testid="search-bar"
          disablePortal
          filterOptions={(options: Option[]) => options}
          freeSolo
          getOptionDisabled={(option) =>
            (option as SearchOption).type === 'feedback'
          }
          getOptionLabel={getOptionLabel}
          inputValue={currentQuery || ''}
          onBlur={() => setOpen(false)}
          onChange={(event, value) => onChange(event, value)}
          onFocus={handleOpen}
          onInputChange={(event, value) => handleInputChange(event, value)}
          onOpen={handleOpen}
          open={open}
          openOnFocus
          options={options}
          renderInput={(params: AutocompleteRenderInputParams) => {
            const { InputProps, ...restParams } = params;
            return (
              <TextField
                {...restParams}
                InputProps={{
                  ...InputProps,
                  endAdornment: (
                    <div
                      onClick={() => {
                        submitSearch('');
                      }}
                    >
                      {InputProps.endAdornment}
                    </div>
                  ),
                  startAdornment: noInputIcon ? null : (
                    <StartAdornment position="start">
                      <Icon iconName="search" />
                    </StartAdornment>
                  ),
                }}
                aria-label="search documents"
                onKeyDown={handleKeyDown}
                placeholder={
                  projectId
                    ? `${t('Search project')}: ${projectName}`
                    : `${t('Search organization')}: ${userOrganizationName}`
                }
              />
            );
          }}
          renderOption={(props, option: unknown) => {
            const label = getOptionLabel(option);
            const searchOption = option as SearchOption;
            const folderPath = typeof option !== 'string' && searchOption.path;
            const filePath = folderPath && `${folderPath}/${label}`;
            const refNumber =
              searchOption.referenceNumber &&
              `Ref: ${searchOption.referenceNumber} `;
            const drawing = searchOption.drawing;
            const page = searchOption.page;

            const additionalInfo =
              type === SearchEntities.Document
                ? filePath
                : `${refNumber || ''}${drawing || ''}${page || ''}`;
            const isEmpty =
              searchOption.id === 'no-results' ||
              searchOption.id === 'loading-results';
            const showIcon = !loadingTopTen && !isEmpty;
            // const iconType = searchOption.type;
            const iconName = currentQuery?.length
              ? searchOption.iconType
              : 'history';

            return (
              <li {...props} key={searchOption.id}>
                <OptionWrap id={searchOption.type}>
                  <ResultMatch
                    IconComponent={
                      <SearchBarIcon
                        iconName={iconName}
                        isSelectIconEnabled={isSelectIconEnabled}
                        searchOption={searchOption}
                        showIcon={showIcon}
                      />
                    }
                    matchString={currentQuery || undefined}
                    skipMatch={isEmpty}
                    text={label}
                  />
                  {
                    //TODO: Change Path component name to AdditionalInfo
                  }
                  <Path>{additionalInfo}</Path>
                  {!currentQuery && !loadingTopTen && (
                    <DeleteOptionIcon
                      onClick={(event) => handleDeleteSearch(event, label)}
                    />
                  )}
                </OptionWrap>
              </li>
            );
          }}
          value={currentQuery}
        />
      </Box>
      {showSavedSearches && (
        <IconButton
          ariaLabel={t('Saved searches')}
          color="default"
          disableBorder
          iconName="filterList"
          onClick={openMyQueries}
        />
      )}
    </SearchBarWrap>
  );
};
