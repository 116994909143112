import { useTranslation } from 'react-i18next';

import {
  FieldDefinition,
  RequiredMetaDataFieldOption,
  ValueTypeName,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';

export const useGetErrorMetadata = () => {
  const { t } = useTranslation();
  const { settings } = useProjectContext();
  const { metadata_required } = settings || {};

  const getError = ({
    fieldDefinition,
    value,
  }: {
    fieldDefinition: FieldDefinition;
    value: string;
  }) => {
    const { required, type, valueType } = fieldDefinition;
    const { maxValue, minValue } = valueType || {};

    if (type === ValueTypeName.Number && value && maxValue && minValue) {
      const valueNumeric = parseInt(value);
      if (valueNumeric < minValue || valueNumeric > maxValue)
        return t('Value must be between {{minValue}} and {{maxValue}}', {
          maxValue,
          minValue,
        });
    }

    return (metadata_required === RequiredMetaDataFieldOption.Enforce ||
      !metadata_required) &&
      required &&
      !value
      ? t('This field is required')
      : '';
  };

  return getError;
};
