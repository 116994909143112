import React from 'react';

interface Props {
  color?: '#28237A' | '#E25314' | '#848484';
  iconScale: number;
  statusColor?: string;
}

export const FormPin: React.FC<Props> = ({ color, statusColor, iconScale }) => (
  <svg
    fill="none"
    height={48 * iconScale}
    viewBox="0 0 32 48"
    width={32 * iconScale}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={color} height="24" rx="12" width="24" y="8" />
    <path
      clipRule="evenodd"
      d="M16.6667 14H13.88C13.6 13.2267 12.8667 12.6667 12 12.6667C11.1333 12.6667 10.4 13.2267 10.12 14H7.33333C6.6 14 6 14.6 6 15.3333V26C6 26.7333 6.6 27.3333 7.33333 27.3333H16.6667C17.4 27.3333 18 26.7333 18 26V15.3333C18 14.6 17.4 14 16.6667 14ZM12 14C12.3667 14 12.6667 14.3 12.6667 14.6667C12.6667 15.0333 12.3667 15.3333 12 15.3333C11.6333 15.3333 11.3333 15.0333 11.3333 14.6667C11.3333 14.3 11.6333 14 12 14ZM7.33333 25.3333C7.33333 25.7 7.63333 26 8 26H16C16.3667 26 16.6667 25.7 16.6667 25.3333V16C16.6667 15.6333 16.3667 15.3333 16 15.3333H15.3333V16C15.3333 16.7333 14.7333 17.3333 14 17.3333H10C9.26667 17.3333 8.66667 16.7333 8.66667 16V15.3333H8C7.63333 15.3333 7.33333 15.6333 7.33333 16V25.3333Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10 32H14V43C14 43 12.5 48 12 48C11.5 48 10 43 10 43V32Z"
      fill={color}
      fillRule="evenodd"
    />
    {statusColor && statusColor !== '' && (
      <path
        d="M24 15C27.866 15 31 11.866 31 8C31 4.13401 27.866 1 24 1C20.134 1 17 4.13401 17 8C17 11.866 20.134 15 24 15Z"
        fill={statusColor}
        stroke="white"
        strokeWidth="2"
      />
    )}
  </svg>
);
