import { FolderService } from '@pro4all/documents/data-access';
import { Folder, useDownloadFolderZipMutation } from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

type FormFields = {
  includeSubfolders: boolean;
};

export const useSubmit = ({
  folder,
  onClose,
}: {
  folder: Folder;
  onClose: () => void;
}) => {
  const [downloadFolderZip] = useDownloadFolderZipMutation();
  const { showSingleError } = useShowMessages();

  const onSubmit = async (values: FormFields) => {
    const { includeSubfolders } = values;

    try {
      const response = await downloadFolderZip({
        variables: {
          id: folder?.id,
          includeSubfolders,
        },
      });

      if (response?.data?.downloadFolderZip) {
        FolderService.downloadFolderZip({
          folder,
          url: response?.data?.downloadFolderZip,
        });
      }
    } catch (e) {
      showSingleError(e);
    }
    onClose();
  };

  return onSubmit;
};
