import styled from 'styled-components';

export const StyledSvg = styled.svg.attrs({
  'aria-hidden': true,
  focusable: false,
  viewBox: '0 0 10 10',
})`
  display: inline-block;
  width: 10px;
  height: 10px;
`;
