import { EntityFolderPermission, FolderPermission } from '@pro4all/graphql';

export enum OptionValue {
  DeleteContent = 'DeleteContent',
  DeleteNone = 'DeleteNone',
  DeleteOwnContent = 'DeleteOwnContent',
  None = 'None',
  ReadAllDocuments = 'ReadAllDocuments',
  ReadAllVersions = 'ReadAllVersions',
  ReadApproval = 'ReadApproval',
  ReadFolder = 'ReadFolder',
  ReadLastApproval = 'ReadLastApproval',
  ReadLastVersion = 'ReadLastVersion',
  ReadLastVersionAndApproved = 'ReadLastVersionAndApproved',
  ReadOwn = 'ReadOwn',
  ReadOwnAndFinalized = 'ReadOwnAndFinalized',
  ReadOwnVersions = 'ReadOwnVersions',
  UpdateContent = 'UpdateContent',
  UpdateNone = 'UpdateNone',
  UpdateOwn = 'UpdateOwn',
}

export enum ExclusivePermissionType {
  Delete = 'Delete',
  Documents = 'Documents',
  Update = 'Update',
  Versions = 'Versions',
}

export const EXCLUSIVE_PERMISSION = [
  FolderPermission.DeleteContent,
  FolderPermission.DeleteOwnContent,
  FolderPermission.ReadAllDocuments,
  FolderPermission.ReadAllVersions,
  FolderPermission.ReadApproval,
  FolderPermission.ReadFolder,
  FolderPermission.ReadLastApproval,
  FolderPermission.ReadLastVersion,
  FolderPermission.ReadLastVersionAndApproved,
  FolderPermission.ReadOwn,
  FolderPermission.ReadOwnAndFinalized,
  FolderPermission.ReadOwnVersions,
  FolderPermission.UpdateContent,
  FolderPermission.UpdateOwn,
];

export const EXCLUSIVE_PERMISSION_DOCUMENT = [
  FolderPermission.ReadAllDocuments,
  FolderPermission.ReadOwnAndFinalized,
  FolderPermission.ReadOwn,
];

export const EXCLUSIVE_PERMISSION_VERSION = [
  FolderPermission.ReadAllVersions,
  FolderPermission.ReadApproval,
  FolderPermission.ReadLastApproval,
  FolderPermission.ReadLastVersion,
  FolderPermission.ReadLastVersionAndApproved,
  FolderPermission.ReadOwnVersions,
];

export const EXCLUSIVE_PERMISSION_DELETE = [
  FolderPermission.DeleteOwnContent,
  FolderPermission.DeleteContent,
];

export const EXCLUSIVE_PERMISSION_UPDATE = [
  FolderPermission.UpdateContent,
  FolderPermission.UpdateOwn,
];

export interface ExclusivePermissionSelectorProps {
  currentPermissions: EntityFolderPermission;
  exclusivePermissionType: ExclusivePermissionType;
  folderId: string;
  folderName: string;
  projectId: string;
}
