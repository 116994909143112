import { Node } from 'slate';

import { tryParseString } from '@pro4all/shared/utils';

const nodeToPlainText = (nodes: Node[]) =>
  nodes.map((n) => Node.string(n)).join('\n');

export const stringToPlainText = (body: string) => {
  const parsedBody = tryParseString(body);
  return parsedBody ? nodeToPlainText(parsedBody) : body;
};
