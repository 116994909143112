import { useState } from 'react';

import { OFFSET_X, OFFSET_Y, Position } from '@pro4all/shared/ui/context-menu';

export const useShowFilter = () => {
  const [position, setPosition] = useState<Position>({
    left: 0,
    top: 0,
  });

  const [showFilter, setShowFilter] = useState(false);

  const openFilter = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition({
      left: e.clientX + OFFSET_X,
      top: e.clientY + OFFSET_Y,
    });
    setShowFilter(true);
  };

  return { openFilter, position, setShowFilter, showFilter };
};
