import { FolderPermission } from '@pro4all/graphql';

import { ConflictingPermissions } from '../../shared/types';

export const hasConflictWithFinalizePermission = ({
  currentPermissions,
  newPermissions,
}: ConflictingPermissions) => {
  let response = false;

  const hasConflict = ({
    permissionToCheck,
    permissions,
  }: {
    permissionToCheck: FolderPermission;
    permissions: FolderPermission[];
  }) => {
    if (
      (permissionToCheck === FolderPermission.ReadOwn ||
        permissionToCheck === FolderPermission.ReadOwnAndFinalized) &&
      permissions.includes(FolderPermission.FinalizeContent)
    ) {
      response = true;
    }
  };

  // Check for user selecting an exclusive permission.
  newPermissions.forEach((perm) => {
    hasConflict({ permissionToCheck: perm, permissions: currentPermissions });
  });

  // Check for user clicking ToggleAll icon.
  currentPermissions.forEach((perm) => {
    hasConflict({ permissionToCheck: perm, permissions: newPermissions });
  });

  return response;
};
