import { useCallback } from 'react';
import { Buffer } from 'buffer';

import { UploadDocumentsResponse } from '@pro4all/shared/types';

export const useOpenCdeStateParser = (state: string | null) => {
  const uploadRequestState = useCallback((): UploadDocumentsResponse[] => {
    if (!state) {
      return [] as UploadDocumentsResponse[];
    }

    // Parse Base64 encoded state
    const decodedStateString = Buffer.from(state, 'base64').toString('utf-8');
    const parsedState = JSON.parse(decodedStateString);

    const documentsParsed = parsedState.map(
      (document: UploadDocumentsResponse) => {
        const documentNameSplit = document.file_name?.split('.');
        const documentId = documentNameSplit
          ? documentNameSplit[documentNameSplit.length - 2]
          : null;
        return {
          document_id: documentId,
          file_name: documentId
            ? document.file_name?.replace('.' + documentId, '')
            : document.file_name,
          session_file_id: document.session_file_id,
        };
      }
    );
    return documentsParsed;
  }, [state]);

  return { uploadRequestState };
};
