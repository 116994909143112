import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGroupsAndUsersIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';

import { FilterCircularProgress } from '../../FilterCircularProgress';
import { useMDFacets } from '../../utils/useMDFacets';
import { TagSelect } from '../TagSelect';

import { DefinitionSelect } from './DefinitionSelect';

export const QCUserSelection: React.FC<FilterBaseProps> = ({
  ...filterProps
}) => {
  const { filteredAnswers } = useMDFacets(filterProps);
  const { facetLoading, metaDataKey, type, value, name } = filterProps;
  const [options, setOptions] = useState<Option[] | undefined>(undefined);
  const { params } = useRouting();

  const { data, loading } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-first',
    variables: {
      includeActive: true,
      includeMembers: true,
      projectId: params.projectId,
    },
  });

  const projectMembers = useMemo(() => data?.groupsAndUsers || [], [data]);

  const getDisplayNameById = useCallback(
    (id: string): string =>
      projectMembers?.find?.((user) => user?.id === id)?.displayName || '',
    [projectMembers]
  );

  useEffect(() => {
    if (!options && !loading && projectMembers.length) {
      const repeatedValues: Set<string> = new Set();
      filteredAnswers?.forEach((item) => {
        const values = item.value.split(',');
        values.forEach((value) => repeatedValues.add(value));
      });
      const uniqueAnswers = Array.from(repeatedValues).map((item) => ({
        id: `selection-filter-option-${item}`,
        inputValue: item,
        label: getDisplayNameById(item),
      }));

      uniqueAnswers.length && setOptions(uniqueAnswers);
    }
  }, [filteredAnswers, options, getDisplayNameById, projectMembers, loading]);

  const values = value.split(delimiters.multiSelectOptions);
  const initialOptions =
    options?.filter(
      (option) => option.inputValue && values.includes(option.inputValue)
    ) || [];

  if (loading) return <FilterCircularProgress />;

  return name ? (
    <TagSelect
      initialOptions={initialOptions}
      loading={facetLoading}
      metaDataKey={metaDataKey}
      options={options || []}
      showTagIcon
      type={type}
      value={value}
    />
  ) : (
    <DefinitionSelect {...filterProps} />
  );
};
