import { useTranslation } from 'react-i18next';

import { useTheme } from '@pro4all/shared/mui-wrappers';

import { InstanceOption } from './types';

export const useDonutOptionsQcResults = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const keyOptions: InstanceOption[] = [
    {
      id: 'type',
      label: t('Type'),
      valueResolver: (instance) => instance.type || t('(empty)'),
    },
    {
      id: 'createdBy',
      label: t('Created by'),
      valueResolver: (instance) =>
        instance.createdBy?.displayName || t('(empty)'),
    },
    {
      id: 'name',
      label: t('Template'),
      valueResolver: (instance) => instance.name || t('(empty)'),
    },
    {
      id: 'visualContext',
      label: t('Drawing'),
      valueResolver: (instance) =>
        instance.visualContext ? instance.visualContext.name : t('(empty)'),
    },
    {
      id: 'indicativeState',
      label: t('Status'),
      labelColorResolver: (instance) =>
        instance.indicativeState &&
        instance.indicativeState?.color &&
        instance.indicativeState?.color !== ''
          ? instance.indicativeState.color
          : theme.palette.grey[300],
      valueResolver: (instance) =>
        instance.indicativeState &&
        instance.indicativeState?.value &&
        instance.indicativeState?.value !== ''
          ? instance.indicativeState?.value
          : t('(empty)'),
    },
  ];

  return keyOptions;
};
