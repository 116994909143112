import styled from 'styled-components';

import { StylingDefaults } from '@pro4all/shared/themes';
import { LiteIcon as Icon } from '@pro4all/shared/ui/icons';
import { Tooltip as UITooltip } from '@pro4all/shared/ui/tooltip';
import { Text as UIText } from '@pro4all/shared/ui/typography';

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  min-height: ${StylingDefaults.tableRowHeight}px;
`;

export const Text = styled(UIText)`
  && {
    flex: 1;
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Tooltip = styled(UITooltip)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const LiteIcon = styled(Icon)`
  > svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;
