import { RouteParams, Routes } from '@pro4all/shared/config';

export function getDrawingRoute(params: RouteParams) {
  let drawingRoute: keyof typeof Routes = 'projectQualityControlDrawing';
  if (params.objectId) {
    if (params.projectId) {
      drawingRoute = 'projectObjectDrawing';
    } else {
      drawingRoute = 'objectDrawing';
    }
  }

  return drawingRoute;
}

export function getQCSResultRoute(params: RouteParams) {
  let drawingRoute: keyof typeof Routes = 'projectQualityControlResults';
  if (params.objectId) {
    if (params.projectId) {
      drawingRoute = 'projectObjectResults';
    } else {
      drawingRoute = 'objectResults';
    }
  }

  return drawingRoute;
}
