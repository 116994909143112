import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterType } from '@pro4all/graphql';
import { FacetItem } from '@pro4all/graphql';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';

import { useFilters } from '../../utils';
import { IconRight, StyledButton, StyledLi } from '../Styles';

export const BooleanSelect: React.FC<
  FilterBaseProps & { facetItem?: FacetItem }
> = ({ metaDataKey, value }) => {
  const { t } = useTranslation();
  const isYes = value.includes('true');
  const isNo = value.includes('false');
  const isUnanswered = value.includes('unanswered');

  const { setFilterValue } = useFilters();

  const yesValue = Boolean(isYes) && 'true';
  const noValue = Boolean(isNo) && 'false';
  const unansweredValue = isUnanswered ? 'unanswered' : '';

  const toggleYes = () => {
    const toggledYesValue = Boolean(!isYes) && 'true';
    const values = [toggledYesValue, noValue, unansweredValue].filter(Boolean);
    const newValue = values.join(delimiters.multiSelectOptions);
    setFilterValue({
      metaDataKey,
      type: FilterType.Bool,
      value: newValue,
    });
  };

  const toggleNo = () => {
    const toggledNoValue = Boolean(!isNo) && 'false';
    const values = [yesValue, toggledNoValue, unansweredValue].filter(Boolean);
    const newValue = values.join(delimiters.multiSelectOptions);
    setFilterValue({
      metaDataKey,
      type: FilterType.Bool,
      value: newValue,
    });
  };

  return (
    <>
      <StyledLi $active={isYes}>
        <StyledButton onClick={toggleYes}>{t('Yes')}</StyledButton>
        {isYes && <IconRight color="secondary" iconName="check" />}
      </StyledLi>
      <StyledLi $active={isNo} onClick={toggleNo}>
        <StyledButton onClick={toggleYes}>{t('No')}</StyledButton>
        {isNo && <IconRight color="secondary" iconName="check" />}
      </StyledLi>
    </>
  );
};
