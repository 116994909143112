import React from 'react';

import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useMessageActions } from './useMessageActions';
import { usePredefinedFilters } from './usePredefinedFilters';

export const MessageActionBar: React.FC = () => {
  const { tableActions } = useMessageActions();
  const predefinedFilters = usePredefinedFilters();

  return (
    <TableActionBar
      dataTestid="messages-action-bar"
      mainActions={tableActions}
      predefinedFilters={predefinedFilters}
      search
    />
  );
};
