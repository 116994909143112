import React from 'react';

import { FilterBaseProps } from '@pro4all/shared/search-utils';

import { DateFilter } from '../DateFilter';

import { DefinitionSelect } from './DefinitionSelect';

export const MDDateFilter: React.FC<FilterBaseProps> = ({ ...filterProps }) => {
  const { name } = filterProps;

  return (
    <div>
      {name ? (
        <DateFilter {...filterProps} />
      ) : (
        <DefinitionSelect {...filterProps} />
      )}
    </div>
  );
};
