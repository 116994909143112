import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Dialog } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { useApiKeyContext } from './ApiKeyContext';
import { useRevokeApiKey } from './useRevokeApiKey';

export const RevokeWarningDialog = () => {
  const { t } = useTranslation();
  const { setShowRevokeWarning, showRevokeWarning } = useApiKeyContext();

  const revokeApiKey = useRevokeApiKey();

  const onClose = () => {
    setShowRevokeWarning(false);
  };

  const onConfirm = () => {
    revokeApiKey();
    setShowRevokeWarning(false);
  };

  return (
    <Dialog
      confirmLabel={t('Revoke API key')}
      iconName="warning"
      onClose={onClose}
      onConfirm={onConfirm}
      open={showRevokeWarning}
      title={t('Revoke API key')}
    >
      <Box>
        <Text>
          {t(
            'Revoking an API key cannot be done. Are you sure you want to revoke this API key?'
          )}
        </Text>
      </Box>
    </Dialog>
  );
};
