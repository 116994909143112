import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ReportHierarchyFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

const ReportHierarchyField = ({
  linkedSnags,
  item,
  templateOptions,
}: ReportHierarchyFieldProps) => (
  <Instance.Item>
    <Instance.Label>{item.displayName}</Instance.Label>
    <Box>
      {item.value ? (
        JSON.parse(item.value ?? '')?.map((val: { breadcrumbs: string[] }) => {
          const displayValue = val?.breadcrumbs?.join(' > ');
          return (
            <Instance.Value key={displayValue}>
              {displayValue}
              {templateOptions?.showDescription && (
                <FieldDescription
                  displayDescription={item.displayDescription}
                />
              )}
              {templateOptions?.showLinkedSnags && (
                <LinkedSnagsList linkedSnags={linkedSnags} />
              )}
            </Instance.Value>
          );
        })
      ) : (
        <Instance.Value>
          '-'
          {templateOptions?.showDescription && (
            <FieldDescription displayDescription={item.displayDescription} />
          )}
          {templateOptions?.showLinkedSnags && (
            <LinkedSnagsList linkedSnags={linkedSnags} />
          )}
        </Instance.Value>
      )}
    </Box>
  </Instance.Item>
);

const FlexInstanceValue = styled(Instance.Value)`
  display: flex;
`;

export default ReportHierarchyField;
