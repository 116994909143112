import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useShareBuild12FormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: t('Connection'),
      name: 'connection',
      required: true,
      type: ValueTypeName.Selection,
    },
    {
      displayName: t('Project phase'),
      name: 'projectPhase',
      required: true,
      type: ValueTypeName.Selection,
    },
  ];

  return useFormConfig({ formFields });
};
