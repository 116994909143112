import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Radio } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './ItemCard.styles';
import { BasePropsHierarchyItem, ItemProps } from './types';

type ItemCardProps<HierarchyItem extends BasePropsHierarchyItem> = {
  hasChildren: boolean;
} & Pick<
  ItemProps<HierarchyItem>,
  'editItem' | 'item' | 'level' | 'multipleAnswers' | 'selectItems'
>;

const ItemCardSelectComponent = <HierarchyItem extends BasePropsHierarchyItem>({
  editItem,
  hasChildren,
  item,
  level,
  multipleAnswers = false,
  selectItems,
}: ItemCardProps<HierarchyItem>) => {
  const { t } = useTranslation();

  const expandCollapseTooltip = item.expanded ? t('Collapse') : t('Expand');

  const select = multipleAnswers ? (
    <Checkbox checked={item.selected || false} />
  ) : (
    <Radio checked={item.selected || false} />
  );

  return (
    <>
      <Styled.Item editMode={false} level={level}>
        <Styled.Header
          onClick={() => selectItems({ ids: [item.id], multipleAnswers })}
          selected={item.selected || false}
        >
          <Styled.Title>
            <Text variant="h5">{item.name}</Text>
          </Styled.Title>
          <Styled.ActionsWrapper>
            {select}
            <Styled.ExpandCollapse
              onClick={(event) => {
                event.stopPropagation();
                hasChildren && editItem({ ...item, expanded: !item.expanded });
              }}
            >
              {hasChildren && (
                <Tooltip placement="bottom" title={expandCollapseTooltip}>
                  <Icon
                    iconName={item.expanded ? 'expandLess' : 'expandMore'}
                  />
                </Tooltip>
              )}
            </Styled.ExpandCollapse>
          </Styled.ActionsWrapper>
        </Styled.Header>
      </Styled.Item>
      <Styled.ItemBottomMargin level={level} />
    </>
  );
};

const skipUpdate = <HierarchyItem extends BasePropsHierarchyItem>(
  prevProps: ItemCardProps<HierarchyItem>,
  nextProps: ItemCardProps<HierarchyItem>
) =>
  prevProps.item.expanded === nextProps.item.expanded &&
  prevProps.item.selected === nextProps.item.selected; // Item might have been expanded or collapsed.

export const ItemCardSelect = React.memo(
  ItemCardSelectComponent,
  skipUpdate
) as typeof ItemCardSelectComponent;
