import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  ReportConfiguration,
  useDeleteReportConfigurationsMutation,
} from '@pro4all/graphql';
import { useApolloCacheHelpersReportConfigurations } from '@pro4all/shared/qc-report-sources';
import { useTableCheck } from '@pro4all/shared/ui/general';

export const useDeleteReportConfigurations = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteReportConfigurations] = useDeleteReportConfigurationsMutation();
  const { checkedRows } = useTableCheck<ReportConfiguration>();

  const {
    getCurrentlyCachedReportConfigurations,
    updateCachedReportConfigurations,
  } = useApolloCacheHelpersReportConfigurations();

  const updateCache = ({ succeededIds }: { succeededIds: string[] }) => {
    const cachedReportConfigurations = getCurrentlyCachedReportConfigurations();
    const otherReportConfigurations = cachedReportConfigurations.filter(
      (key) => !succeededIds.includes(key.id)
    );
    updateCachedReportConfigurations(otherReportConfigurations);
  };

  return async () => {
    const ids = checkedRows.map((row) => row.id);
    try {
      await deleteReportConfigurations({
        variables: {
          ids,
        },
      });

      updateCache({ succeededIds: ids });
      enqueueSnackbar(t(`Selected report configurations deleted`));
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
