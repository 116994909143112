import { useState } from 'react';

export enum TabValues {
  Comments = 'Comments',
  Log = 'Log',
  Properties = 'Properties',
}

export const useSidebarTabs = (initialTab?: TabValues) => {
  const [currentTab, setCurrentTab] = useState(
    initialTab || TabValues.Properties
  );
  return { currentTab, setCurrentTab };
};
