import React, { createContext, useCallback, useReducer } from 'react';

type RemovePhotoProps = {
  fieldId: string;
  instanceId: string;
  photoId: string;
};

enum ActionType {
  ADD_PHOTOS = 'ADD_PHOTOS',
  ADD_PREVIEW_PHOTO = 'ADD_PREVIEW_PHOTO',
  REMOVE_PHOTO = 'REMOVE_PHOTO',
  RESET_INITIAL_PHOTOS = 'RESET_INITIAL_PHOTOS',
  RESET_PHOTOS = 'RESET_PHOTOS',
}
export type Photo = {
  fieldId: string;
  id: string;
  instanceId: string;
  url: string;
};
export type PreviewPhoto = {
  id: string;
  url: string;
};
type State = {
  initialPhotos: Photo[];
  photos: Photo[];
  previewPhotos: PreviewPhoto[];
  removals: RemovePhotoProps[];
};
type ActionAdd = { payload: Photo[]; type: ActionType.ADD_PHOTOS };
type ActionAddPreviewPhoto = {
  payload: PreviewPhoto;
  type: ActionType.ADD_PREVIEW_PHOTO;
};
type ActionNoPayload = {
  type: ActionType.RESET_PHOTOS | ActionType.RESET_INITIAL_PHOTOS;
};
type ActionStringPayload = {
  payload: RemovePhotoProps;
  type: ActionType.REMOVE_PHOTO;
};
type Action =
  | ActionAdd
  | ActionAddPreviewPhoto
  | ActionNoPayload
  | ActionStringPayload;

type ContextType = {
  addPhotos: (photos: Photo[]) => void;
  addPreviewPhoto: (photo: PreviewPhoto) => void;
  removePhoto: ({ fieldId, instanceId, photoId }: RemovePhotoProps) => void;
  resetInitialPhotos: () => void;
  resetPhotos: () => void;
  state: State;
};

export const PhotoContext = createContext<ContextType>(null);

// Define the reducer
const photoReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_PHOTOS: {
      const fieldId = action.payload[0]?.fieldId;
      const instanceId = action.payload[0]?.instanceId;

      // Prevent adding photo ids which are already in, except for photo IDs of the current field without a url.
      const stateMinusPhotosOfThisFieldWithoutUrl = state.photos.filter(
        (photo) =>
          photo.fieldId !== fieldId || (photo.fieldId === fieldId && photo.url)
      );
      const idsInState = stateMinusPhotosOfThisFieldWithoutUrl.map(
        (photo) => photo.id
      );
      const payloadMinusIdsInState = action.payload.filter(
        (photo) => !idsInState.includes(photo.id)
      );

      // Calculate initialPhotos. The thing is that we can have multiple photo fields in one snag/form.
      // The state prop 'initialPhotos' should contain the collection of all initial photo values for all photo fields for this particular instance.
      // 1. If initialPhotos === null, then assign the entire payload.
      // 2. If initialPhotos !== empty, then check if there's at least one object in the array which has the same fieldId/instanceId combination.
      //    a. If so, initialPhotos stays as it is.
      //    b. If not, add the entire payload to the current value of initialPhotos.

      const initialPhotosPreviouslyFilled = Boolean(
        state.initialPhotos?.find(
          (photo) =>
            photo.fieldId === fieldId && photo.instanceId === instanceId
        )
      );
      const initialPhotos = state.initialPhotos.length
        ? initialPhotosPreviouslyFilled
          ? state.initialPhotos
          : [...state.initialPhotos, ...payloadMinusIdsInState]
        : action.payload;

      return {
        ...state,
        initialPhotos,
        photos: [
          ...stateMinusPhotosOfThisFieldWithoutUrl,
          ...payloadMinusIdsInState,
        ],
      };
    }
    case ActionType.REMOVE_PHOTO:
      return {
        ...state,
        photos: state.photos.filter(
          (photo) => photo.id !== action.payload.photoId
        ),
        removals: [...state.removals, action.payload],
      };
    case ActionType.ADD_PREVIEW_PHOTO:
      return {
        ...state,
        previewPhotos: [...state.previewPhotos, action.payload],
      };
    case ActionType.RESET_PHOTOS: // In case user adds/deletes photos and then cancels.
      return {
        ...state,
        photos: state.initialPhotos,
      };
    case ActionType.RESET_INITIAL_PHOTOS: // In case user adds/deletes photos and then saves.
      return {
        ...state,
        initialPhotos: state.photos,
      };
    default:
      return state;
  }
};

export const PhotoProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(photoReducer, {
    initialPhotos: [],
    photos: [],
    previewPhotos: [],
    removals: [],
  });

  // Define all actions
  const addPhotos = useCallback((photos: Photo[]) => {
    dispatch({ payload: photos, type: ActionType.ADD_PHOTOS });
  }, []);
  const addPreviewPhoto = useCallback((photo: PreviewPhoto) => {
    dispatch({ payload: photo, type: ActionType.ADD_PREVIEW_PHOTO });
  }, []);
  const removePhoto = useCallback(
    ({ fieldId, instanceId, photoId }: RemovePhotoProps) => {
      dispatch({
        payload: { fieldId, instanceId, photoId },
        type: ActionType.REMOVE_PHOTO,
      });
    },
    []
  );
  const resetInitialPhotos = useCallback(() => {
    dispatch({ type: ActionType.RESET_INITIAL_PHOTOS });
  }, []);
  const resetPhotos = useCallback(() => {
    dispatch({ type: ActionType.RESET_PHOTOS });
  }, []);

  return (
    <PhotoContext.Provider
      value={{
        addPhotos,
        addPreviewPhoto,
        removePhoto,
        resetInitialPhotos,
        resetPhotos,
        state,
      }}
    >
      {children}
    </PhotoContext.Provider>
  );
};
