import React, { useCallback, useEffect } from 'react';
import WebViewer, { Core, UI, WebViewerInstance } from '@pdftron/webviewer';
import * as xmlParser from 'fast-xml-parser';
import QRCode from 'qrcode';

import { Annotation } from '@pro4all/graphql';
import { useLocalStorage } from '@pro4all/shared/hooks';

import { VersionPanel } from '../version-panel/VersionPanel';
import {
  Action as VersionsAction,
  State as VersionsState,
} from '../version-utils/versionsReducer';
import { UpdateAnnotationProps } from '../version-utils/versionUtils';

import { Action, Actions, State, Status } from './pdftronReducer';

type InitProps = {
  handleAnnotationChangeRef: React.MutableRefObject<
    (props: UpdateAnnotationProps) => void
  >;
  handleDocumentLoadedRef: React.MutableRefObject<() => void>;
};

type HookProps = {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  dispatchRef: React.MutableRefObject<React.Dispatch<Action>>;
  instanceRef: React.MutableRefObject<WebViewerInstance>;
  stateRef: React.MutableRefObject<State>;
  userMentionData: UI.MentionsManager.UserData[];
  versionsDispatchRef: React.MutableRefObject<React.Dispatch<VersionsAction>>;
  versionsStateRef: React.MutableRefObject<VersionsState>;
};

type RedrawProps = {
  annotations: Annotation[];
  instance: WebViewerInstance;
  shouldBeHidden?: boolean;
};

const icons = {
  versionManagement:
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>',
};

/**
 * Pdftron has no update method for panels, but disabling
 * and re-enabling the element actually achieves the same
 * as it removes the element from the DOM and re-renders it
 * after. It has to happen in a setTimeout, otherwise this
 * does not work.
 */
export const redrawPanel = (
  instance: WebViewerInstance,
  name = 'versionManagement'
) =>
  setTimeout(() => {
    instance.UI.disableElements([name]);
    instance.UI.enableElements([name]);
  });

export const serializeAnnotation = async (annotation: string) => {
  const xmlAsJson = xmlParser.parse(annotation, {
    allowBooleanAttributes: true,
    attributeNamePrefix: '@_',
    ignoreAttributes: false,
    ignoreNameSpace: false,
    parseAttributeValue: true,
    parseNodeValue: true,
  });

  return JSON.stringify(xmlAsJson);
};

export const deserializeAnnotation = (annotation: string) => {
  const parser = new xmlParser.j2xParser({
    attributeNamePrefix: '@_',
    ignoreAttributes: false,
  });

  const annotationJson = JSON.parse(annotation);
  return parser.parse(annotationJson);
};

const parseLocalStorageItem = (signatures: string | {}) => {
  if (typeof signatures === 'string' && signatures !== '{}') {
    let signatureList = [];
    try {
      signatureList = JSON.parse(signatures);
    } catch (e) {
      console.error('Could not parse local storage item:', e);
    }
    return signatureList;
  } else {
    return [];
  }
};

/**
 * START OF QR STAMP CODE
 * This code is left here eventhough it is not used. In the future
 * we want to add a QR code to documents, and the code below is
 * a way to achieve that. As discussed with Wouter and Jan Willem,
 * we will leave it in as an example for later use
 */
export const initHeader = (instance: WebViewerInstance) => {
  instance.UI.setHeaderItems((header) => {
    header.get('viewControlsButton').insertBefore({
      dataElement: 'saveDocumentButton',
      img: icons.versionManagement,
      onClick: () => addQrCode(instance, 'name'),
      title: 'Add QR code',
      type: 'actionButton',
    });
  });
};

const addQrCode = async (instance: WebViewerInstance, name: string) => {
  const { Annotations, annotationManager, documentViewer } = instance.Core;
  const stamp = new Annotations.StampAnnotation();

  stamp.PageNumber = documentViewer.getCurrentPage();
  stamp.Author = annotationManager.getCurrentUser();

  const pageWidth = documentViewer.getPageWidth(stamp.PageNumber);
  const pageHeight = documentViewer.getPageHeight(stamp.PageNumber);
  const stampSize = Math.max(pageWidth / pageHeight, 100);
  stamp.setX(stampSize / 2);
  stamp.setY(stampSize / 2);
  stamp.setWidth(stampSize);
  stamp.setHeight(stampSize);

  const qrCode = await QRCode.toDataURL(name);
  await stamp.setImageData(qrCode);

  annotationManager.addAnnotation(stamp);
  annotationManager.redrawAnnotation(stamp);
};
/** END OF QR STAMP CODE */

export const initVersionPanel = (
  instance: WebViewerInstance,
  onRender: () => HTMLElement
) => {
  instance.UI.setCustomPanel({
    panel: {
      dataElement: 'versionManagement',
      render: onRender,
    },
    tab: {
      dataElement: 'versionManagement',
      img: icons.versionManagement,
      title: 'Version management',
    },
  });
};

export const getPageArray = async (doc: Core.PDFNet.PDFDoc) => {
  const arr = [];
  const itr = await doc.getPageIterator(1);

  for (itr; await itr.hasNext(); itr.next()) {
    const page = await itr.current();
    arr.push(page);
  }

  return arr;
};

/**
 * Annotations in pdftron have a name attribute, which acts as their unique ID.
 * Any modifications to that annotation will result in a new annotation object
 * but with the same name property.
 * We have to do this weird lookup because pdftron does not expose a way to get
 * the proper pdftron Annotation object without importing the annotation.
 */
const getAnnotationId = (annotation: Annotation) => {
  const options = ['annots', 'add', 'modify', 'delete'];
  try {
    const parsedBody = JSON.parse(annotation.body).xfdf;
    const elem = options.filter((option) => parsedBody[option]);
    const annots = Object.values(parsedBody[elem[0]])[0] as unknown as Record<
      string,
      string
    >;
    return annots['@_name'];
  } catch (e) {
    console.warn(e);
    return '';
  }
};

export const redrawAnnotations = async ({
  instance,
  annotations,
  shouldBeHidden,
}: RedrawProps) => {
  const { annotationManager } = instance.Core;
  const deletedAnnotations: string[] = [];

  /**
   * Here we check if somewhere down the line
   * annotations were hidden. If it's the case, we don't render the whole chain
   * up until we get to the hidden notification, we abort early.
   * Once we identify an annotation as being deleted, add it to the array so we
   * can abort early in the following map.
   * Just not rendering the deleted annotation is not enough, since it may have
   * previous versions (e.g. the annotation got moved or something else). In this
   * case if we don't render the final version we just show the version before it,
   * which is uno functionality basically but not what we want for now.
   */
  annotations.forEach((annotation) => {
    if (annotation.deletedAt) {
      deletedAnnotations.push(getAnnotationId(annotation));
    }
  });

  annotations.map(async (annotation) => {
    if (deletedAnnotations.includes(getAnnotationId(annotation))) return;
    const annot: Core.Annotations.Annotation[] =
      await annotationManager.importAnnotations(
        deserializeAnnotation(annotation.body)
      );

    /**
     * Redraw the annotations that were updated in case we need to hide annotations
     * that were previously drawn
     */
    annot.forEach((pdftronAnnotation) => {
      /**
       * We use a custom attribute to store our API id, so we know
       * what id to sent to the API on deletion of the annotation
       */
      pdftronAnnotation.setCustomData('annotationId', annotation.id);

      /**
       * Currently, deleting a annotation will do a request to the API
       * and mark it as deleted. Any adjustment to a annotation (moving,
       * editting changing color etc) will generate a new annotation.
       */
      if (shouldBeHidden) {
        annotationManager.hideAnnotation(pdftronAnnotation);
      } else {
        annotationManager.showAnnotation(pdftronAnnotation);
      }
    });
  });

  redrawPanel(instance);
};

export const usePdftronUtils = ({
  containerRef,
  dispatchRef,
  instanceRef,
  stateRef,
  userMentionData,
  versionsDispatchRef,
  versionsStateRef,
}: HookProps) => {
  const {
    setLocalStorageItem: setSignatureLocalStorage,
    getLocalStorageItem: getSignatureLocalStorage,
  } = useLocalStorage<string>({
    key: 'pdftron-signatures',
  });

  const {
    setLocalStorageItem: setRubberStampLocalStorage,
    getLocalStorageItem: getRubberStampLocalStorage,
  } = useLocalStorage<string>({
    key: 'pdftron-rubber-stamps',
  });

  const setStatus = useCallback(
    (status: Status) =>
      dispatchRef.current({
        payload: { status },
        type: Actions.SET_STATUS,
      }),
    [dispatchRef]
  );

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current?.UI?.mentions?.setUserData(userMentionData);
    }
  }, [instanceRef, userMentionData]);

  /**
   * We have to use Refs here to make sure we deal with the latest states,
   * since this methid is being
   */
  const loadDocument = async () => {
    if (stateRef.current.status === Status.DOCUMENT_NOT_LOADED) {
      const state = stateRef.current;
      const versionsState = versionsStateRef.current;
      const instance = instanceRef.current;
      const baseVersion = versionsState[state.baseVersionId];
      const overlayVersion = versionsState[state.overlayVersionId];

      const loadOptions = {
        extension: baseVersion?.documentVersion?.downloadName
          ? ''
          : baseVersion?.documentVersion?.extension?.replace('.', ''),
        filename:
          baseVersion?.documentVersion?.downloadName ||
          baseVersion?.documentVersion?.name,
      };

      if (overlayVersion) {
        const { PDFNet } = instance.Core;

        const diffPDF = await PDFNet.PDFDoc.create();

        diffPDF.lock();
        const maxPages = Math.max(
          baseVersion.pages.length,
          overlayVersion.pages.length
        );

        /**
         * Create a new page containing the visual intersection between
         * the base version and overlay version. If either of the documents
         * is longer than the other, the diff will show an intersection with itself
         */
        await Promise.all(
          Array(maxPages)
            .fill(undefined)
            .map((value, i) =>
              diffPDF.appendVisualDiff(
                baseVersion.pages[i] ?? overlayVersion.pages[i],
                overlayVersion.pages[i] ?? baseVersion.pages[i]
              )
            )
        );

        diffPDF.unlock();

        instance.UI.loadDocument(diffPDF, loadOptions);
      } else if (baseVersion.downloadUrl) {
        const urlResult = await fetch(baseVersion.downloadUrl);
        const blob = await urlResult.blob();

        instance.UI.loadDocument(blob, loadOptions);
      }
      /**
       * Disabling the left panel allows us to re-open it after the document
       * is loaded by enabling it again.
       */
      instance.UI.disableElements(['leftPanel']);
    }
  };

  const initPdfTron = useCallback(
    async (
      { handleAnnotationChangeRef, handleDocumentLoadedRef }: InitProps,
      displayName: string,
      licenseKey: string,
      readonly: boolean,
      userMentionData: UI.MentionsManager.UserData[]
    ) => {
      setStatus(Status.INITIALIZING);

      instanceRef.current = await WebViewer(
        {
          enableMeasurement: true,
          fullAPI: true,
          path: '/pdftron-public-assets/',
        },
        containerRef.current
      );

      if (instanceRef.current) {
        const { UI } = instanceRef.current;
        const mentions = UI.mentions;

        if (mentions && mentions.setUserData) {
          mentions.setUserData(userMentionData);
        }
      }

      console.log('PDFTron Initialized');

      if (readonly) {
        instanceRef.current.Core.annotationManager.enableReadOnlyMode();
      }

      // Load saved data from localStorage
      const signatures = parseLocalStorageItem(getSignatureLocalStorage());
      const rubberStamps = parseLocalStorageItem(getRubberStampLocalStorage());

      const instance = instanceRef.current;
      const { annotationManager, documentViewer } = instance.Core;

      const signatureTool = documentViewer.getTool(
        instance.Core.Tools.ToolNames.SIGNATURE
      ) as Core.Tools.SignatureCreateTool;

      // Rubber stamp is in the list of tools, but not in the types
      const stampTool = documentViewer.getTool(
        // eslint-disable-next-line
        // @ts-ignore
        instance.Core.Tools.ToolNames.RUBBER_STAMP
      ) as Core.Tools.RubberStampCreateTool;

      signatureTool.addEventListener('signatureSaved', () => {
        signatureTool.exportSignatures().then((signatures) => {
          setSignatureLocalStorage(JSON.stringify(signatures));
        });
      });

      signatureTool.addEventListener('signatureDeleted', () => {
        signatureTool.exportSignatures().then((signatures) => {
          setSignatureLocalStorage(JSON.stringify(signatures));
        });
      });

      // TODO: Enable this when setCustomStamps is fixed
      // stampTool.addEventListener('stampsUpdated', () => {
      //   setRubberStampLocalStorage(JSON.stringify(stampTool.getCustomStamps()));
      // });

      documentViewer.addEventListener('documentLoaded', () => {
        signatures.length > 0 &&
          signatureTool.importSignatures(
            JSON.parse(getSignatureLocalStorage())
          );

        // TODO: This does not work.. Filed a bug with Apryse
        // rubberStamps.length > 0 &&
        //   stampTool.setCustomStamps(JSON.parse(getRubberStampLocalStorage()));

        // Get the distance measurement tool to enable snapping by default
        const distanceMeasurementTool = documentViewer.getTool(
          'AnnotationCreateDistanceMeasurement'
        ) as Core.Tools.DistanceMeasurementCreateTool;

        distanceMeasurementTool.setStyles({
          // Do NOT change the precision to 1.X, it will break PDFtron
          Precision: 0.1,
          // Default scale 1:100 in mm
          Scale: [
            [1, 'mm'],
            [100, 'mm'],
          ],
        });

        // Set default snapping mode
        // TODO: Update PDFtron after bug is fixed to check if
        // the measurement snapping checkbox is checked, bug is filed here:
        // https://support.apryse.com/support/tickets/72788
        distanceMeasurementTool.setSnapMode(
          instance.Core.Tools.SnapModes.DEFAULT
        );

        annotationManager.setSnapDefaultOptions({
          indicatorColor: '#00a5e4',
          indicatorSize: 20,
          radiusThreshold: 50,
        });

        handleDocumentLoadedRef.current();
      });
      annotationManager.setCurrentUser(displayName);
      annotationManager.addEventListener(
        'annotationChanged',
        async (
          annotations: Core.Annotations.Annotation[],
          action: 'add' | 'delete' | 'modify',
          info: Core.AnnotationManager.AnnotationChangedInfoObject
        ) => {
          /**
           * This callback is also triggered when we import annotations,
           * but we just draw them immediately. We only care about API
           * actions on annotations here
           */
          if (info.imported) return;

          const { annotationManager } = instanceRef.current.Core;
          for (const annotation of annotations) {
            if (action === 'add') {
              const baseVersionId = stateRef.current.baseVersionId;
              annotation.setCustomData('versionId', baseVersionId);
            }

            const versionId: string = annotation.getCustomData('versionId');
            const annotationId: string =
              annotation.getCustomData('annotationId');
            const asString = await annotationManager.exportAnnotations({
              annotList: [annotation],
            });
            const serializedAnnotation = await serializeAnnotation(asString);
            handleAnnotationChangeRef.current({
              action,
              annotationId,
              serializedAnnotation,
              versionId,
            });
          }
        }
      );

      /** Initialize custom versions panel
       * Taken from: https://docs.apryse.com/documentation/samples/js/ViewerSnapToNearestTest/
       */
      instance.UI.setCustomPanel({
        panel: {
          dataElement: 'versionManagement',
          render: () =>
            (
              <VersionPanel
                pdftronDispatch={dispatchRef.current}
                pdftronState={stateRef.current}
                redrawPanel={() => redrawPanel(instance)}
                versionsDispatch={versionsDispatchRef.current}
                versionsState={versionsStateRef.current}
              />
            ) as unknown as HTMLElement,
        },
        tab: {
          dataElement: 'versionManagement',
          img: icons.versionManagement,
          title: 'Version management',
        },
      });

      await instance.Core.PDFNet.runWithCleanup(() => null, licenseKey);

      setStatus(Status.DOCUMENT_NOT_LOADED);
    },
    [
      containerRef,
      dispatchRef,
      instanceRef,
      setStatus,
      stateRef,
      versionsDispatchRef,
      versionsStateRef,
    ]
  );

  return {
    initPdfTron,
    loadDocument,
    setStatus,
  };
};
