import React from 'react';

import { useTableContext } from '@pro4all/shared/ui/general';

import { FieldCardCustom } from './FieldCardCustom';

export const FieldCardsCustom: React.FC = () => {
  const { rows } = useTableContext();
  return (
    <>
      {rows.map((row) => (
        <FieldCardCustom fieldDefinition={row} key={row.id} />
      ))}
    </>
  );
};
