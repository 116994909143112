export const toApiSearchUriComponent = ({
  filters, // For the notification service, we can combine filters and facets. Results will be practically identical.
  query,
}: {
  filters: string[];
  query: string;
}) => {
  const queryURIComponent = query && `&query=${encodeURIComponent(query)}`;
  const filterURIComponent = filters
    .filter(Boolean)
    .map((filter) => `&filter=${encodeURIComponent(filter)}`)
    .join('');
  return `${queryURIComponent}${filterURIComponent}`;
};
