import type { ForwardedRef } from 'react';
import React, { InputHTMLAttributes, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  InputAdornment,
  InputProps,
} from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';

import { TextField, TextFieldProps } from '../../text-field/TextField';
import { useOnSearch } from '../useOnSearch';

export const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledInputAdornment = styled(InputAdornment)`
  && {
    margin-right: 0;
  }
`;

interface SearchableSelectType<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'onBlur'
    | 'renderInput'
    | 'multiple'
    | 'fullWidth'
    | 'autocomplete'
    | 'defaultValue'
    | 'onChange'
  > {
  autoFocus?: InputProps['autoFocus'];
  defaultLabel?: string;
  error?: InputProps['error'];
  helperText?: TextFieldProps['helperText'];
  label?: TextFieldProps['label'];
  margin?: TextFieldProps['margin'];
  name: TextFieldProps['name'];
  onBlur?: InputProps['onBlur'];
  onChange?: (value: Option, reason?: AutocompleteChangeReason) => void;
  onSearch?: (value: string) => void;
  tooltipTitle?: string;
  warning?: boolean;
}

export type SearchableSelectProps<
  DisableClearable extends boolean | undefined = boolean
> = SearchableSelectType<Option, false, DisableClearable, true>;

function SearchableSelectComponent<
  DisableClearable extends boolean | undefined
>(
  {
    autoFocus,
    disableCloseOnSelect = false,
    error,
    helperText,
    label,
    disabled,
    margin,
    onInputChange,
    onBlur,
    onChange,
    onSearch,
    options,
    name,
    placeholder,
    tabIndex,
    tooltipTitle = '',
    value,
    warning = false,
    ...rest
  }: SearchableSelectProps<DisableClearable>,
  ref: ForwardedRef<unknown>
) {
  const { t } = useTranslation();
  const handleOnSearch = useOnSearch({ onSearch });

  const handleInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (onSearch) {
      handleOnSearch(value);
    }
    onInputChange && onInputChange(event, value, reason);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    value: string | Option
  ) => {
    if (typeof value === 'string') {
      /**
       * For now we just return an Option, if we add the ability to create new
       * options we should build on this
       */
      onChange && onChange({ id: 'newValue', label: value }, 'createOption');
    } else {
      onChange && onChange(value, 'selectOption');
    }
  };

  interface Props extends AutocompleteRenderInputParams {
    inputProps: InputHTMLAttributes<HTMLInputElement> & {
      ref: Ref<HTMLInputElement>;
      value?: string | number | readonly string[] | undefined;
    };
  }

  const renderInput = (params: Props) => {
    // Check if we have to include an icon in the textfield (startAdornment)
    const selectedOption = options.filter(
      (option) => option.label === params.inputProps.value
    );

    params.InputProps.startAdornment = selectedOption[0]?.iconName && (
      <StyledInputAdornment position="start">
        <StyledIcon
          htmlColor={selectedOption[0].iconColor}
          iconName={selectedOption[0].iconName}
        />
      </StyledInputAdornment>
    );
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          inputProps: {
            ...params.inputProps,
            tabIndex,
          },
        }}
        autoComplete="off"
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        helperText={helperText}
        inputRef={ref}
        label={label}
        margin={margin}
        name={name}
        onBlur={onBlur}
        placeholder={placeholder}
        tooltipTitle={tooltipTitle}
        warning={warning}
      />
    );
  };

  return (
    <Autocomplete
      {...rest}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      getOptionLabel={(option: unknown) => (option as Option).label}
      isOptionEqualToValue={(option: unknown, value: unknown) => {
        const o = option as Option;
        const val = value as string | Option;
        if (typeof val === 'string') {
          return false;
        } else {
          return o.label === val.label;
        }
      }}
      noOptionsText={t('No options')}
      onChange={(event, value) => handleChange(event, value as Option)}
      onInputChange={(event, value, reason) =>
        handleInputChange(event, value, reason)
      }
      options={options}
      renderInput={(params) => renderInput(params)}
      renderOption={(props, option: Option) => {
        const { iconColor, iconName, id, label } = option;
        return (
          <li {...props} key={id}>
            {iconName && (
              <StyledIcon htmlColor={iconColor} iconName={iconName} />
            )}
            {label}
          </li>
        );
      }}
      // Prevent undefined as the starting value, because then the `Autocomplete` will be initialized as uncontrolled and that will cause issues.
      value={value ? value : { id: '', inputValue: '', label: '' }}
    />
  );
}

export const SearchableSelect = React.forwardRef(SearchableSelectComponent);
