import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const SectionTwoBars: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.994923 0 2.22222 0H17.7778C19.0051 0 20 0.895431 20 2V6C20 7.10457 19.0051 8 17.7778 8H2.22222C0.994923 8 0 7.10457 0 6V2ZM17.7778 2H2.22222V6H17.7778V2Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M0 12C0 10.8954 0.994923 10 2.22222 10H17.7778C19.0051 10 20 10.8954 20 12V16C20 17.1046 19.0051 18 17.7778 18H2.22222C0.994923 18 0 17.1046 0 16V12ZM17.7778 12H2.22222V16H17.7778V12Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
