import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  link?: string;
  linkText?: string;
  paragraph: string | React.ReactNode;
  title?: string;
}

const StyledHeader = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const InfoBlock: React.FC<Props> = ({
  title,
  paragraph,
  link,
  linkText,
}) => (
  <>
    {title && <StyledHeader variant="h6">{title}</StyledHeader>}

    <Text variant="body1">{paragraph}</Text>

    {link && linkText && (
      <Button component={Link} to={link}>
        {linkText}
      </Button>
    )}
  </>
);
