import { ScopeType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';

export function useAi(type: ScopeType) {
  const { settings } = useProjectContext();
  const { organization } = useOrganizationContext();

  const aiEnabledOverall = Boolean(organization?.settings?.ai_admin_settings);
  const aiEnabledProject = Boolean(settings.ai_admin_settings);
  const aiEnabledOrganization = Boolean(
    organization?.settings?.ai_organization_settings
  );

  const aiEnabledContext =
    type === ScopeType.Project
      ? aiEnabledOverall && aiEnabledProject
      : aiEnabledOverall && aiEnabledOrganization;

  return {
    aiEnabledContext, // Is AI enabled for that particular context (organization or project, based on the type that is passed in)?
    aiEnabledOrganization, // Is AI enabled for organization context?
    aiEnabledOverall, // Is AI enabled at all?
    aiEnabledProject, // If AI enabled for project context?
  };
}
