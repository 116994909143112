import React from 'react';

import { EditorButton } from '@pro4all/communication/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';

interface MessageEditorButtonsProps {
  attachmentInputId: string;
  handleClickAddCollection: () => void;
  imageInputId: string;
  isAddingCollection: boolean;
  isImageUploading: boolean;
  isSubmitting: boolean;
  isUploading: boolean;
  openFileInput: (id: string) => void;
  t: (key: string) => string; // Type for i18n translation function
}

export const MessageEditorButtons: React.FC<MessageEditorButtonsProps> = ({
  openFileInput,
  attachmentInputId,
  imageInputId,
  handleClickAddCollection,
  isImageUploading,
  isUploading,
  isSubmitting,
  isAddingCollection,
  t,
}) => (
  <>
    <EditorButton
      disabled={isUploading || isSubmitting || isAddingCollection}
      onClick={() => openFileInput(attachmentInputId)}
      title={t('Attachments')}
    >
      <Icon iconName="attachment" />
    </EditorButton>
    <EditorButton
      disabled={isUploading || isSubmitting || isAddingCollection}
      onClick={handleClickAddCollection}
      title={t('Attach collection folder')}
    >
      <Icon iconName="collectionBin" />
    </EditorButton>
    <EditorButton
      disabled={isImageUploading}
      onClick={() => openFileInput(imageInputId)}
      title={t('Add image')}
    >
      <Icon iconName="image" />
    </EditorButton>
  </>
);
