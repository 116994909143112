import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { AppPermission } from '@pro4all/shared/types';

export type AppPermissionType = keyof typeof AppPermission;

export const useAppPermissions = () => {
  const {
    params: { projectId },
  } = useRouting();
  const { projectsMe, organizationRoles } = useOrganizationContext();
  const projectMeData = projectsMe?.find(
    (project) => project?.projectId === projectId
  );
  const { roles: rolesProject } = projectMeData || {};

  // User can have different roles.
  // Each role has a collection of related permissions.
  // Collect all permissions.
  const scopedPermissionsOrganization = new Set(
    organizationRoles?.flatMap((role) => role?.permissions)
  );

  const scopedPermissionsProject = new Set(
    rolesProject?.flatMap((role) => role?.permissions)
  );

  /**
   * UseAppPermission checks permissions based on context.
   * This uses the projectId parameter in the route.
   * For project-scope, user has permissions if granted on either project-
   * or organization level
   */
  const hasAppPermission = (permission: AppPermissionType) =>
    scopedPermissionsOrganization?.has(AppPermission[permission]) ||
    scopedPermissionsProject?.has(AppPermission[permission]);

  return {
    hasAppPermission,
  };
};
