import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const OfficeOnlineProgress: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M8.5 9C10.16 9 11.49 7.66 11.49 6C11.49 4.34 10.16 3 8.5 3C6.84 3 5.5 4.34 5.5 6C5.5 7.66 6.84 9 8.5 9Z" />
    <path
      clipRule="evenodd"
      d="M14.9826 13.226C13.7026 11.7437 10.3576 11 8.5 11C6.17 11 1.5 12.17 1.5 14.5V16C1.5 16.55 1.95 17 2.5 17H11.2085L14.9826 13.226Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M21.8213 12.6085C22.0596 12.8468 22.0596 13.2318 21.8213 13.4701L20.7031 14.5882L18.4117 12.2969L19.5299 11.1787C19.6441 11.0643 19.7991 11 19.9607 11C20.1223 11 20.2773 11.0643 20.3915 11.1787L21.8213 12.6085ZM11 21.6944V19.8368C11 19.7513 11.0306 19.678 11.0917 19.6169L17.758 12.9506L20.0493 15.2419L13.3769 21.9082C13.3219 21.9693 13.2425 21.9999 13.163 21.9999H11.3055C11.1344 21.9999 11 21.8654 11 21.6944Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
