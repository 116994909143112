type Option = {
  inputValue?: string;
};

export function getExistingOptions<T extends Option>(options: T[]) {
  return options.filter((tag) => !tag.inputValue);
}

export function getNewOptions<T extends Option>(options: T[]) {
  return options.filter((tag) => tag.inputValue);
}
