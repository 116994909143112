import React from 'react';

import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
} from '@pro4all/shared/mui-wrappers';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

interface Props extends Omit<MuiChipProps, 'color' | 'icon' | 'variant'> {
  color?:
    | 'error'
    | 'info'
    | 'warning'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success';
  icon?: IconName;
  minWidth?: string;
  variant?: 'filled' | 'outline';
}

export const Chip: React.FC<Props> = ({
  color,
  icon,
  minWidth,
  variant = 'filled',
  size = 'small',
  ...rest
}) => {
  const chipProps: MuiChipProps = {
    size: size,
    style: { minWidth },
    variant: variant === 'filled' ? 'filled' : 'outlined',
    ...rest,
  };

  chipProps.color = color as
    | 'error'
    | 'info'
    | 'warning'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success';

  if (icon) {
    chipProps.icon = <Icon iconName={icon} />;
  }

  return <MuiChip {...chipProps} />;
};
