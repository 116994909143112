import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDefinition, Hierarchy } from '@pro4all/graphql';
import {
  HierarchyEditor,
  useHierarchyEditorContext,
} from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { useGetPayloadHierarchy } from '../shared/hierarchy-list/useGetPayloadHierarchy';
import * as Styled from '../styles/Signature.styles';

import { HierarchyListProps } from './HierarchyList';

export const HierarchyOptionsSelector = ({
  fieldDefinitionId,
  item,
  multipleAnswers,
  onClose,
  setValue,
}: {
  fieldDefinitionId: string;
  item: FieldDefinition;
  multipleAnswers: boolean;
  onClose: () => void;
} & Pick<HierarchyListProps, 'setValue'>) => {
  const { t } = useTranslation();

  const {
    resetToFreezedItems,
    state: { items, itemsNonExisting },
  } = useHierarchyEditorContext();

  const getPayloadHierarchy = useGetPayloadHierarchy();

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const onConfirm = () => {
    const itemIdsNonSelected = itemsNonExisting.map((item) => item.value);
    const selectedItems = items.filter((item) => item.selected);
    const selectedItemsIds = [
      ...selectedItems.map((item) => item.id),
      ...itemIdsNonSelected,
    ];
    setValue(fieldDefinitionId, getPayloadHierarchy({ selectedItemsIds })); // Set the value to the Formik Form context.
    onClose();
  };

  return (
    <FloatingModal
      data-testid="select-option-hierarchy-modal"
      maxWidth="xl"
      onClose={() => onClose()}
      open
    >
      <FloatingModal.Header iconName="hierarchicalList">
        {item.displayName}
      </FloatingModal.Header>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          ref={scrollableDivRef}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '1px',
            mb: 2,
            overflow: 'auto',
          }}
        >
          <HierarchyEditor<Hierarchy>
            editMode={false}
            multipleAnswers={multipleAnswers}
            scrollableDivRef={scrollableDivRef}
          />
        </Box>
        <Styled.ButtonsWrapper>
          <Button
            aria-label={t('Close hierarchy selector')}
            color="inherit"
            data-testid="close-hierarchy-selector"
            onClick={() => {
              resetToFreezedItems();
              onClose();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            aria-label={t('Confirm')}
            color="primary"
            data-testid="add-hierarchy-options"
            onClick={onConfirm}
            variant="contained"
          >
            {t('Confirm')}
          </Button>
        </Styled.ButtonsWrapper>
      </Box>
    </FloatingModal>
  );
};
