import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Fun: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M44.413 56.39l.34.34c-.112-.11-.224-.225-.34-.34zm-3.565 11.565l-4.303-4.167a228.692 228.692 0 004.303 4.164v.003zm-4.85-4.704l.312.31c-.102-.103-.2-.212-.3-.32l-.013.01z"
      fill={colors.primary}
    />
    <path
      d="M200.465 43.915C198.764 20.32 173.699 4.99 151.339 8.499c-19.73 2.429-35.27 21.013-35.038 40.76.251 17.057 11.787 31.5 24.054 42.454-17.101 6.07-36.838.943-51.817-5.922-11.715-5.368-24.057-11.831-35.069-21.01a103.52 103.52 0 01-3.939-3.452 124.161 124.161 0 01-4.763-4.593l-.34-.34-.72-.68c-2.273-2.273-6.124-2.51-8.41-.218-2.164 2.163-1.276 5.477.735 7.753.098.108.197.217.299.32l.235.23 4.303 4.165c.793.751 1.575 1.48 2.337 2.173a113.35 113.35 0 003.742 3.307c10.886 9.28 24.01 15.726 36.879 21.979 20.547 9.984 45.441 14.651 66.495 4.368l10.546-6.232c16.662-11.277 40.338-26.817 39.597-49.646zM151.138 86.24s-.561-.405-.823-.622c-8.276-6.92-15.941-14.683-20.411-24.33-9.773-19.302 6.127-43.805 27.599-42.744 14.679 0 30.388 10.308 31.399 26.044-.051 18.295-24.391 32.607-37.764 41.652z"
      fill={colors.primary}
    />
    <path
      d="M54.769 79.436c-7.94-5.327 20.683 12.485 47.928 16.965 10.583 1.725 28.272.86 38.375-5.092 10.104-5.953 22.262-12.356 34.291-22.792-10.58 15.386-18.03 27.146-25.078 31.249-16.707 9.742-48.912 10.936-95.516-20.33z"
      fill={colors.secondary}
    />
    <path
      d="M239.952 127.603c-.292 2.745-3.133 4.449-5.84 4.463-.558 0-10.172-2.222-10.185-2.228-26.317-8.583-48.565-12.927-73.643-30.059l-9.936-8.065 9.967-6.103.823.622 9.685 7.314c26.34 17.645 74.972 27.276 74.972 27.276 2.984 1.19 4.501 3.517 4.157 6.78z"
      fill={colors.primary}
    />
    <path
      d="M169.672 20.823c-4.899-5.63-18.183-16.498-33.841-6.701-12.005 7.508-22.418 24.724-18.271 37.59 4.374 13.583 19.366 21.867 22.571 24.374-8.297-9.79-17.792-23.054-9.985-40.434 7.321-16.312 26.49-19.969 39.526-14.829z"
      fill={colors.secondary}
    />
    <path
      d="M16.45 41.71c-1.853-2.622-2.299-6.483.341-8.354 2.64-1.871 6.58-1.116 8.433 1.517l5.052 6.497c1.854 2.627 1.878 5.943-.63 8.06a5.581 5.581 0 01-8.12-1.021l-5.075-6.698zM1.306 19.79c-1.728-2.709-1.987-6.587.735-8.328 2.721-1.742 6.616-.806 8.348 1.901l4.745 6.74c1.732 2.72 1.596 6.024-1.02 8.02a5.583 5.583 0 01-8.066-1.387l-4.742-6.947z"
      fill={colors.primary}
    />
    <path
      d="M36.31 63.557l.235.231-.16-.153-.075-.078zM40.849 67.952c.792.752 1.575 1.48 2.337 2.174a5.139 5.139 0 01-.701-.589l-1.636-1.585zM49.523 61.329a124.237 124.237 0 01-4.763-4.593l4.763 4.593z"
      fill={colors.secondary}
    />
  </SvgIcon>
);
