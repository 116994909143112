import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

export const ContentColumnWrapper = ({ children }: PropsWithChildren<{}>) => (
  <Box
    sx={{
      height: 'height: 100%',
    }}
  >
    {children}
  </Box>
);
