import React from 'react';

import { CommentStatus as CommentStatusType } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

type Props = {
  commentStatus: CommentStatusType | undefined | null;
};

export const CommentStatus: React.FC<Props> = ({ commentStatus }) =>
  commentStatus === CommentStatusType.AllCommentsRead ? (
    <Icon htmlColor={customColors.grey700} iconName="chatBubble" />
  ) : null;
