import React from 'react';

import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { StyledLabel, UserChip, UserDisplayWrap } from './Header.styles';

export const UserDisplay: React.FC<{
  limit?: number;
  name: string;
  values: string[];
}> = ({ limit = 4, name, values }) => {
  if (!values.length) return null;
  return (
    <UserDisplayWrap $receiverCount={values.length}>
      <StyledLabel $tight>{name}</StyledLabel>
      {values?.map((value, index) =>
        index < limit ? (
          <UserChip icon="personOutline" key={index} label={value}></UserChip>
        ) : (
          index === limit && (
            <Tooltip
              placement="bottom"
              title={values.slice(limit, value.length + 1).join(', ')}
            >
              <UserChip
                icon="personOutline"
                key={index}
                label={'+' + (values.length - limit)}
              ></UserChip>
            </Tooltip>
          )
        )
      )}
    </UserDisplayWrap>
  );
};
