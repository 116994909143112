import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageMetaDataNoQualityControlFields: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Create a new group and add members to add them to projects or assign permissions.'
      )}
      title={t('No fields')}
    />
  );
};
