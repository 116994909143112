import { State } from '../useUploaderEditorReducer';
import { Meta, MetaData } from '../useUploaderEditorReducer';

import { useGetErrorName, useResetMetaData } from './helpers';

export const useDeleteMetaVersionAction = () => {
  const getError = useGetErrorName();
  const resetMetaData = useResetMetaData();

  const deleteMetaVersionAction = ({
    payload,
    state,
  }: {
    payload: string;
    state: State;
  }) => {
    const { currentDocumentsInFolder, filesToUpload, meta } = state;
    const metaUpdated = [...meta];
    const index = metaUpdated.findIndex((doc) => doc.id === payload);

    const changedMetaDocument = meta.find(
      (meta) => meta.id === payload
    ) as Meta;

    // Only update if the element has been found.
    if (index !== -1) {
      metaUpdated[index].error = getError({
        currentDocumentsInFolder,
        documentId: payload,
        meta,
        uploadContext: Boolean(filesToUpload.length),
        value: changedMetaDocument.name,
      });
      metaUpdated[index].metaData = resetMetaData({
        metaData: changedMetaDocument.metaData as MetaData[],
      });
      metaUpdated[index].version = { newVersionFor: null, versionNumber: 1 };
    }

    return {
      ...state,
      meta: metaUpdated,
    };
  };

  return deleteMetaVersionAction;
};
