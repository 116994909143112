import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export const Scans = styled(Box)`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    overflow: auto;
  }
`;
