export const supportedExtensionsAiKeywordGeneration = [
  'pdf',
  'docx',
  'xlsx',
  'pptx',
  'html',
  'png',
  'bmp',
  'jpg',
  'jpeg',
  'jpe',
  'jif',
  'jfi',
  'jfif',
  'tif',
  'tiff',
  'heif',
  'png',
];
