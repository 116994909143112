import { useRouting } from '@pro4all/shared/routing-utils';

export const useCompanyDataRegistrationPrevious = () => {
  const { searchParams } = useRouting();

  return async () => {
    // For some reason the use of searchParams.clear here does not remove the license parameter,
    // so the license parameter was set to undefined manually
    searchParams.set({ action: 'changeSubscription', license: undefined });
  };
};
