import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { LiteIcon as Icon } from '@pro4all/shared/ui/icons';

export const LiteIcon = styled(Icon)`
  > svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const Link = styled(RouterLink)`
  margin-left: 0.25rem;
  font-size: 1rem;
  font-weight: 500;

  &:visited,
  &:link {
    color: inherit;
  }
`;
