import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { Status, User } from '@pro4all/graphql';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { Card, GreySmallMetaText, Timestamp } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';

interface TaskMeta {
  assignment?: User[] | null;
  endTime?: string;
  id?: string | null;
  name?: string | null;
  status?: Status | null;
}

interface TaskListProps<T> {
  getTaskType: (task: T) => string;
  isCollapsible?: boolean;
  noTasksMessage: string;
  onTaskClick: (taskId: string) => void;
  taskTypeEnum: Record<string, string>;
  tasks?: T[];
}

export function TaskList<T extends TaskMeta>({
  tasks = [],
  taskTypeEnum,
  getTaskType,
  onTaskClick,
  isCollapsible = false,
  noTasksMessage = '',
}: TaskListProps<T>) {
  const { t } = useTranslation();
  const [collapsedActions, setCollapsedActions] = useState<
    Record<string, boolean>
  >(
    Object.fromEntries(
      Object.values(taskTypeEnum).map((value) => [value, false])
    )
  );

  const taskWithTypeExists = (type: string) =>
    tasks.find((task) => getTaskType(task) === type);

  const handleToggleCollapse = (type: string) => {
    setCollapsedActions({
      ...collapsedActions,
      [type]: !collapsedActions[type],
    });
  };

  return (
    <Container>
      {tasks.length ? (
        Object.values(taskTypeEnum).map((type) => (
          <div key={uuid()}>
            {taskWithTypeExists(type) && (
              <div>
                <Row
                  onClick={
                    isCollapsible ? () => handleToggleCollapse(type) : undefined
                  }
                >
                  <Text variant="h5">
                    {t(titleCaseToSentenceCase(type) ?? '')}
                  </Text>
                  {isCollapsible && (
                    <Icon
                      iconName={
                        collapsedActions[type]
                          ? 'keyboardArrowUp'
                          : 'keyboardArrowDown'
                      }
                    />
                  )}
                </Row>
                {!collapsedActions[type] &&
                  tasks
                    .filter((task) => getTaskType(task) === type)
                    .map((task) => (
                      <Card
                        iconName="documentTask"
                        key={uuid()}
                        meta={TaskMetaComponent(task)}
                        onClick={() => task.id && onTaskClick(task.id)}
                        title={task.name ?? ''}
                      />
                    ))}
              </div>
            )}
          </div>
        ))
      ) : (
        // Change text to reflect documents and results
        <p>{t(noTasksMessage)}</p>
      )}
    </Container>
  );
}

const TaskMetaComponent = (task: TaskMeta) => (
  <GreySmallMetaText>
    {task.status && <StatusChip status={task.status} />}
    {task.endTime && (
      <>
        <Icon iconName="calendar" />
        <Timestamp date={task.endTime} format="YYYY-MM-DD" />
      </>
    )}
    {task.assignment?.[0]?.displayName && (
      <>
        <Icon iconName="personOutline" />
        <span>{task.assignment[0].displayName}</span>
      </>
    )}
  </GreySmallMetaText>
);

const Row = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled('div')`
  padding: 0 1.5rem;
`;
