import styled from 'styled-components';

export const ObjectDetailWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

export const ResultTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
