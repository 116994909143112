import React from 'react';
import dayjs from 'dayjs';

import { Timestamp } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  changeDate: Date;
  userName?: string;
}

export const UserNameAndDate: React.FC<Props> = ({ changeDate, userName }) => (
  <Text variant="body2">
    {`${userName} • `}
    <Timestamp
      date={dayjs(changeDate).format('YYYY-MM-DD HH:mm')}
      format="lll"
    />
  </Text>
);
