import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoDocumentVersions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="flow2"
      text={t("There aren't any previous versions for this document.")}
      title={t('No versions')}
    />
  );
};
