import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { Routes } from '@pro4all/shared/config';
import { Chip, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

import {
  CollectionContainer,
  LeftSideContainer,
  NameText,
} from './CollectionFolder.styles';
import { useCollectionFolderContext } from './CollectionFolderContext';

export const CollectionFolder = () => {
  const theme = useTheme();
  const { documentsCount } = useCollectionFolderContext();
  const { t } = useTranslation();
  const isMobileScreen = useIsMobileScreen();

  const {
    goTo,
    params: { projectId },
    searchParams,
  } = useRouting();

  const matchCollection = useRouteMatch([
    Routes.collectionFolder,
    Routes.projectCollectionFolder,
  ]);
  const isSelected = Boolean(matchCollection);

  const handleOnClick = () => {
    searchParams.clear();
    if (projectId) {
      updateLsLastVisitedProjectRoute({
        overwriteCurrentValue: true,
        projectId,
        route: 'projectCollectionFolder',
      });
    }
    goTo(projectId ? 'projectCollectionFolder' : 'collectionFolder', {
      params: { projectId },
    });
  };

  return (
    !isMobileScreen && (
      <CollectionContainer onClick={handleOnClick} selected={isSelected}>
        <LeftSideContainer>
          <Icon
            htmlColor={isSelected ? theme.palette.primary.dark : ''}
            iconName="collectionBin"
          />
          <NameText selected={isSelected} variant="body1">
            {t('Collection folder')}
          </NameText>
        </LeftSideContainer>
        <Chip label={documentsCount} size="small"></Chip>
      </CollectionContainer>
    )
  );
};

export default CollectionFolder;
