import styled from 'styled-components';

import { Footer as UIFooter } from '@pro4all/shared/ui/general';

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1.5rem;
`;

export const Footer = styled(UIFooter)`
  && {
    margin: auto -1.5rem -1.5rem;
    padding: 1rem 1.5rem 1.5rem;
  }
`;
