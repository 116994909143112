import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { AddGroupsToProjectForm } from './AddGroupsToProjectForm';

export const AddGroupsToProjectSidebar = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const isOpen = searchParams.is('action', 'addGroupsToProject');

  const handleClose = () => searchParams.clear();

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header title={t('Add to project')} />
      {isOpen && <AddGroupsToProjectForm onClose={handleClose} />}
    </Sidebar>
  );
};
