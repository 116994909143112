import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import type { ObjectNode } from '@pro4all/graphql';
import { useUpdateNodesMutation } from '@pro4all/graphql';
import { toObjectInput } from '@pro4all/objects/utils';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { sortBy, toRecord } from '@pro4all/shared/utils';

import type { UseObjectsResult } from '../useObjects';
import { useObjects } from '../useObjects';

export interface UseEditObjectsResult extends UseObjectsResult {
  create: () => void;
  goBack: () => void;
  save: () => void;
  staged: Record<ObjectNode['id'], ObjectNode>;
  update: (update: Partial<ObjectNode> & Pick<ObjectNode, 'id'>) => void;
}

export function useEditObjects(): UseEditObjectsResult {
  const { searchParams } = useRouting();
  const {
    getObject: getOriginalObject,
    objects,
    refetch,
    ...objectsResult
  } = useObjects();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();
  const [staged, setStaged] = useState<UseEditObjectsResult['staged']>({});
  const [updateNodes] = useUpdateNodesMutation();

  // Reconcile initial values with staged values.
  const lookup: UseEditObjectsResult['staged'] = {
    ...toRecord(objects, 'id'),
    ...staged,
  };
  const getObject: UseEditObjectsResult['getObject'] = (id) => lookup[id];
  const nodes = Object.values(lookup)
    .filter(notDeleted)
    .sort(sortBy({ key: 'name' }));

  // We only want to show nodes which are not deleted. This includes checking
  // if the deleted timestamp is set on any parent.
  function notDeleted(node: ObjectNode): boolean {
    if (node?.deletedAt) return false;
    if (node?.parentNodeId) return notDeleted(getObject(node.parentNodeId));
    return true;
  }

  const goBack = () => {
    searchParams.delete('action');
    setStaged({});
  };

  const create = () => {
    update({ id: uuid(), name: '' });
  };

  const update: UseEditObjectsResult['update'] = (update) => {
    setStaged((staged) => ({
      ...staged,
      [update.id]: { ...getObject(update.id), ...update },
    }));
  };

  const save = async () => {
    try {
      const nodes = Object.values(staged)
        .filter(({ id, deletedAt }) => !(deletedAt && !getOriginalObject(id)))
        .map(toObjectInput);
      await updateNodes({ variables: { nodes } });
      setStaged({});
      enqueueSnackbar(t('Objects updated'));
      searchParams.delete('action');
      refetch();
    } catch (e) {
      showSingleError(e);
    }
  };

  return {
    ...objectsResult,
    create,
    getObject,
    goBack,
    objects: nodes,
    refetch,
    save,
    staged,
    update,
  };
}
