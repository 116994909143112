import { FolderPermission } from '@pro4all/graphql';

export const hasAnyDocumentsPermission = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadAllDocuments) ||
  permissions.includes(FolderPermission.ReadOwn) ||
  permissions.includes(FolderPermission.ReadOwnAndFinalized);

export const isDocumentsNone = (permissions: FolderPermission[]) =>
  !permissions.includes(FolderPermission.ReadAllDocuments) &&
  !permissions.includes(FolderPermission.ReadOwnAndFinalized) &&
  !permissions.includes(FolderPermission.ReadOwn);

export const isDocumentsAll = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadAllDocuments);

export const isDocumentsOwnAndFinalized = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadOwnAndFinalized);

export const isDocumentsOwn = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadOwn);

export const isVersionsNone = (permissions: FolderPermission[]) =>
  !permissions.includes(FolderPermission.ReadAllVersions) &&
  !permissions.includes(FolderPermission.ReadLastVersionAndApproved) &&
  !permissions.includes(FolderPermission.ReadApproval) &&
  !permissions.includes(FolderPermission.ReadLastApproval) &&
  !permissions.includes(FolderPermission.ReadLastVersion) &&
  !permissions.includes(FolderPermission.ReadOwnVersions);

export const isVersionsAll = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadAllVersions);

export const isVersionsLastAndApproved = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadLastVersionAndApproved);

export const isVersionsApproved = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadApproval);

export const isVersionsLastApproved = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadLastApproval);

export const isVersionsLast = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadLastVersion);

export const isVersionsOwn = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.ReadOwnVersions);

export const isDeleteNone = (permissions: FolderPermission[]) =>
  !permissions.includes(FolderPermission.DeleteOwnContent) &&
  !permissions.includes(FolderPermission.DeleteContent);

export const isDeleteOwn = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.DeleteOwnContent);

export const isDeleteAll = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.DeleteContent);

export const isUpdateNone = (permissions: FolderPermission[]) =>
  !permissions.includes(FolderPermission.UpdateOwn) &&
  !permissions.includes(FolderPermission.UpdateContent);

export const isUpdateOwn = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.UpdateOwn);

export const isUpdateAll = (permissions: FolderPermission[]) =>
  permissions.includes(FolderPermission.UpdateContent);
