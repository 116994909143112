import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import { Project, ProjectDocument } from '@pro4all/graphql';
import {
  useCreateProjectMutation,
  useDuplicateProjectMutation,
  useUpdateProjectMutation,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';
import { toApiDate } from '@pro4all/shared/utils';

import { FormFields } from './types';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [duplicateProject] = useDuplicateProjectMutation();
  const { params, searchParams } = useRouting();
  const createMode = searchParams.is('action', 'createProject');
  const duplicateMode = searchParams.is('action', 'duplicateProject');
  const projectId = createMode ? null : params.projectId;
  const {
    addItems,
    editItems,
    state: { items },
  } = useOptimisticResponseContext<Project>();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const {
    projectData,
    setProjectData,
    setSettings,
    settings: projectSettings,
  } = useProjectContext();
  const organizationId = getContextScopedOrganizationId();

  const { showSingleError } = useShowMessages();

  return async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    try {
      const {
        admins,
        deadline,
        description,
        name,
        projectNumber,
        startDate,
        members: selectedMemberOptions,
        settings,
      } = values;

      const toMemberInput = ({ id, type: memberType }: Option) => ({
        id,
        type: memberType === 'Group' ? 1 : 0,
      });

      const deadlineIso = toApiDate(deadline);
      const startDateIso = toApiDate(startDate);

      const submitValues = {
        ...values,
        admins: admins.map(toMemberInput),
        deadline: deadlineIso,
        description,
        id: projectId,
        members: selectedMemberOptions.map(toMemberInput),
        name,
        projectNumber,
        startDate: startDateIso,
      };

      if (createMode) {
        const response = await createProject({
          variables: submitValues,
        });
        const { name, projectNumber, startDate } = submitValues;
        addItems([
          {
            id: response?.data?.createProject,
            name,
            organization: { id: organizationId, name: '' },
            organizationId,
            projectNumber,
            startDate,
          },
        ]);
        helpers.resetForm();
      } else if (duplicateMode) {
        await duplicateProject({
          variables: { sourceProjectId: projectId, ...submitValues },
        });
        helpers.resetForm();
        searchParams.clear();
      } else if (projectId) {
        await updateProject({
          variables: { ...submitValues, id: projectId },
        });
        const projectToUpdate = items.find(
          (project) => project.id === projectId
        );

        setSettings({
          ...projectSettings,
          ...settings,
        });

        setProjectData({
          ...projectData,
          deadline: deadlineIso,
          description,
          name,
          projectNumber,
          startDate: startDateIso,
        });

        if (projectToUpdate) {
          editItems([
            {
              ...projectToUpdate,
              deadline: deadlineIso,
              description,
              name,
              projectNumber,
              settings,
              startDate: startDateIso,
            },
          ]);

          // Update project settings cache
          client.writeQuery({
            data: {
              project: {
                ...projectToUpdate,
                settings: {
                  ...projectSettings,
                  ...settings,
                },
              },
            },
            query: ProjectDocument,
            variables: {
              id: projectId,
            },
          });
        }
      }

      const message = (
        <ItemChangedMessage
          description={
            createMode
              ? MessageAction.Create
              : duplicateMode
              ? MessageAction.Duplicate
              : MessageAction.Update
          }
          entityName={submitValues.name}
          entityTypeTranslation={EntityTypeTranslation.Project}
        />
      );
      enqueueSnackbar(message);
      if (values.objectId) enqueueSnackbar(t('Project link created'));
    } catch (e) {
      showSingleError(e);
    }
  };
};
