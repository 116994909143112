import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Flow1: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M236.28 88.417c-.395.083-8.288.258-8.683.235h-.049a21.054 21.054 0 01-6.429-1.414l-.327-.129-.387-.16a25.284 25.284 0 01-3.274-1.687 16.616 16.616 0 01-.466-.286l-.462-.294c-2.755-1.821-5.49-4.27-8.206-7.347-5.417-6.131-10.38-14.285-15.189-22.184-9.002-14.76-18.292-29.999-29.841-31.688l-.227-.034-.296-.036-.137-.013c-.486-.05-.974-.074-1.463-.075-1.741 0-3.546.707-5.393 2.118a.046.046 0 00-.021.016c-2.796 2.136-5.693 5.864-8.691 11.185-5.193 9.235-10.001 22.092-14.652 34.523-4.901 13.127-9.986 26.704-15.813 36.971-5.992 10.553-12.364 16.667-19.365 18.534a17.98 17.98 0 01-2.322.462c-.803.105-1.612.158-2.422.157-1.1.001-2.199-.062-3.291-.188l-.625-.065h-.02l-.45-.067c-9.381-1.498-16.034-7.723-22.003-14.801l-.026-.031c-1.584-1.881-3.121-3.821-4.643-5.75-6.225-7.863-12.202-15.406-20.818-18.958-.126-.054-.258-.206-.376-.258v-8.53a5.549 5.549 0 014.483-.692l.17.072c.103.042.204.088.307.132a39.583 39.583 0 013.952 2c.137.077.274.16.408.24 1.53.908 3.003 1.909 4.41 2.997 6.192 4.756 10.94 10.77 15.546 16.584 7.222 9.129 13.468 17.01 22.981 17.01 4.86 0 9.803-4.556 15.13-13.93 5.417-9.558 10.354-22.742 15.124-35.493C132.915 39.495 142.823 13 160.844 13c4.528 0 8.874 1.174 13.11 3.557.093.052.183.1.273.155 3.336 1.922 6.604 4.592 9.836 8.035.16.168.315.338.473.516.082.088.162.178.258.258.095.08.11.122.162.186l.026.028c.064.07.129.142.191.215l.028.033.163.186c5.969 6.743 11.203 15.312 16.267 23.621 8.554 14.051 17.404 28.577 27.15 28.577.529 0 5.329-.312 5.543-.34 4.917-.493 5.691 3.611 5.676 5.01-.016 1.397-.586 4.722-3.72 5.38z"
      fill={colors.primary}
    />
    <path
      d="M230.618 88.606c-4.004-1.427-7.411-2.116-17.629-16.064-6.312-8.613-20.575-47.9-40.902-56.908-8.013-3.55-17.157 9.524-19.528 12.531 3.302-3.93 6.63-4.847 9.287-4.798 2.102.034 9.733 1.71 20.314 15.478 9.426 12.253 24.393 49.835 46.234 49.835.594-.01 1.6-.049 2.224-.074zM96.91 126.665c-.973.222-1.72.366-2.322.462-.794.137-1.6.193-2.407.168a27.076 27.076 0 01-3.307-.199l-.624-.077h-.02l-.45-.068c-9.2-1.509-15.87-7.666-22.004-14.801l-.025-.031c-7.806-9.083-14.745-19.747-24.927-24.421a5.659 5.659 0 01-.54-.287 3.33 3.33 0 01-.378-.258c-1.932-1.382-2.802-3.854-1.966-6.088a5.03 5.03 0 011.966-2.445 5.549 5.549 0 014.49-.689h.016l.155.067c.103.042.204.088.307.132a36.415 36.415 0 013.952 2c.137.076.273.159.407.239.61.366 1.195.746 1.765 1.135 10.979 7.543 18.24 20.637 19.731 22.339 8.322 10.035 15.086 18.584 26.18 22.822z"
      fill={colors.secondary}
    />
    <path
      d="M39.903 87.156c.288.206.596.385.918.534l-.918-.534zM27.539 74.743a4.707 4.707 0 01.95 9.364l-6.535.962a4.709 4.709 0 01-.95-9.367l6.535-.96zM7.653 83.189a4.713 4.713 0 014.986 7.996l-5.44 3.733a4.706 4.706 0 01-4.987-7.981l5.44-3.748z"
      fill={colors.secondary}
    />
  </SvgIcon>
);
