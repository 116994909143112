import { FolderPermission } from '@pro4all/graphql';

export enum ActionType {
  ERASE_STATE = 'ERASE_STATE',
  RESET_PERMISSIONS = 'RESET_PERMISSIONS',
  SET_INITIAL_PERMISSIONS = 'SET_INITIAL_PERMISSIONS',
  SET_PERMISSION = 'SET_PERMISSION',
  TOGGLE_ALL_PERMISSIONS = 'TOGGLE_ALL_PERMISSIONS',
  TOGGLE_BACKDROP = 'TOGGLE_BACKDROP',
  TOGGLE_BREAK_INHERITANCE = 'TOGGLE_BREAK_INHERITANCE',
  TOGGLE_PERMISSION = 'TOGGLE_PERMISSION',
  UPDATE_PERMISSIONS_AFTER_SAVE = 'UPDATE_PERMISSIONS_AFTER_SAVE',
}

export type SaveResponse = {
  successful: string[];
  unsuccessful: string[];
};

export type ConflictingPermissions = {
  currentPermissions: FolderPermission[];
  newPermissions: FolderPermission[];
};

export type Permissions = {
  permissions: FolderPermission[];
};

export const NOT_INCLUDED_PERMISSONS = [
  FolderPermission.AllowSync,
  FolderPermission.DeleteOwnContent,
  FolderPermission.ReadApproval,
  FolderPermission.ReadContent,
  FolderPermission.ReadFolder,
  FolderPermission.ReadLastApproval,
  FolderPermission.ReadLastVersion,
  FolderPermission.ReadLastVersionAndApproved,
  FolderPermission.ReadOwn,
  FolderPermission.ReadOwnAndFinalized,
  FolderPermission.ReadOwnVersions,
  FolderPermission.UpdateOwn,
];

export const permissionsToCheck = [
  FolderPermission.ApproveContent,
  FolderPermission.FinalizeContent,
  FolderPermission.CreateContent,
];
