import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoFolderSelected: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="fun"
      text={t('Select or create a folder to view and upload documents.')}
      title={t('No folder selected')}
    />
  );
};
