import { ApolloError } from '@apollo/client';
import { Maybe } from './generated'

export const getResponseFromError = (e: ApolloError) =>
  e.graphQLErrors?.[0]?.extensions?.response;

export function hasErrorCode(error: ApolloError, code: string) {
  return error?.graphQLErrors?.some(
    ({ extensions }) => extensions?.code === code
  );
}

export function getErrorCodes(error: ApolloError) {
  return error?.graphQLErrors?.map(({ extensions }) => extensions?.code);
}

// HACK: using an empty enum to be used by resolveNumericEnum
enum E {}
/**
 * Converts an integer to its corresponding value in a string-based enum.
 *
 * BE sometimes returns numeric index-based enums. Unfortunately GraphQL
 * codegen currently converts graphql enums to string-based typescript enums.
 * In order to save us from un-DRY code while providing a semblance of
 * type-safety resolveNumericEnum can be used.
 */
export function resolveNumericEnum<T extends typeof E>(
  n: number,
  strEnum: T
) {
  return strEnum[Object.keys(strEnum)[n] as keyof T];
}

export type ApolloQueryReturnType<QueryResult, Key extends string> = QueryResult extends {
  [key in Key]?: Maybe<Array<Maybe<infer QueryReturnType>>>;
}
  ? QueryReturnType
  : never;

/**
 * Hooks can return partial data so the return value of for instance
 * useDocumentQuery might not match the full Document type.
 * Use this type util to get the type returned by an Apollo hook.
 * The first argument is the hook and the second is the key of the result you want.
 *
 * type PartialDocument = ApolloHookReturnType<typeof useDocumentQuery, 'documents'>
 */
export type ApolloHookReturnType<
  Hook extends (baseOptions: unknown) => { data: unknown },
  Key extends string
> = ApolloQueryReturnType<ReturnType<Hook>['data'], Key>;

/**
 * gqlType() sets a type predicate
 *
 * Use to resolve ambiguous types from Unions:
 * Given union Member = Group | User
 * and the query returns a collection of Members
 * (objects with __typeName: 'Users' AND __typeName: 'Group')
 *
 * const users = members.filter(gqlType('User'));
 * const groups = members.filter(gqlType('Group'));
 */
export function gqlType<S extends string>(typename: S) {
  return function <T extends { __typename?: string }>(
    document: T
  ): document is { __typename: S } & T {
    return typename && document.__typename === typename;
  };
}
