import { ReferenceType } from '@pro4all/graphql';

type Reference = {
  id: string;
  label: string;
  type: ReferenceType;
};

type ReferenceMappings = {
  Bcc: Reference[];
  Cc: Reference[];
  To: Reference[];
  _Bcc: string[];
  _Cc: string[];
  _To: string[];
};

type ReferenceParam =
  | { email: string; id: string; type: ReferenceType }[]
  | null;

export const referenceMapper = (
  to: ReferenceParam,
  cc: ReferenceParam,
  bcc: ReferenceParam
): ReferenceMappings => ({
  _Bcc: bcc?.map((recipient) => recipient.email) || [],
  _Cc: cc?.map((recipient) => recipient.email) || [],
  _To: to?.map((recipient) => recipient.email) || [],
  Bcc:
    bcc?.map((recipient) => ({
      id: recipient.id,
      label: recipient.email,
      type: recipient.type,
    })) || [],
  Cc:
    cc?.map((recipient) => ({
      id: recipient.id,
      label: recipient.email,
      type: recipient.type,
    })) || [],
  To:
    to?.map((recipient) => ({
      id: recipient.id,
      label: recipient.email,
      type: recipient.type,
    })) || [],
});
