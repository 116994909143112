import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { OpenOfficeForm } from './OpenOfficeForm';

export const OpenOfficeModal = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const showModal = searchParams.is('action', 'officeonline');

  const onClose = () => {
    searchParams.delete('action');
    searchParams.delete('documentId');
  };

  return (
    <FloatingModal
      data-testid="open-office-modal"
      maxWidth="md"
      onClose={onClose}
      open={showModal}
    >
      <FloatingModal.Header iconName="office">
        {t('Attention! document editing in progress')}
      </FloatingModal.Header>
      <OpenOfficeForm onClose={onClose} />
    </FloatingModal>
  );
};
