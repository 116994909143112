import React, { useState } from 'react';
import {
  DropzoneInputProps,
  DropzoneRootProps,
  useDropzone,
} from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { FolderService } from '@pro4all/documents/data-access';

import { ImportSpreadsheetMessage } from './components/Snackbars';

interface StartImportProcessParams {
  folderId: string;
  organizationId: string;
  projectId?: string;
}

interface State {
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  isDragActive: boolean;
  openFileInput: () => void;
  startImportProcess: ({
    folderId,
    organizationId,
    projectId,
  }: StartImportProcessParams) => void;
}

const UploadSpreadsheetContext = React.createContext({
  getInputProps: null,
  getRootProps: null,
  isDragActive: false,
  openFileInput: null,
  startImportProcess: null,
});

export function useUploadSpreadsheetContext() {
  const context = React.useContext<State>(UploadSpreadsheetContext);

  if (context === undefined) {
    throw new Error('UploadSpreadsheetContext must be used within a Provider');
  }
  return context;
}

export const UploadSpreadsheetContextProvider: React.FC = ({ children }) => {
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const [currentOrganizationId, setCurrentTargetOrganizationId] = useState<
    string | null
  >(null);

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onDrop = async (files: File[]) => {
    enqueueSnackbar(t('Upload and validation has started.'));
    const uploadFolderResponse = await FolderService.uploadFolderStructure({
      file: files[0],
      targetFolderId: currentFolderId,
      targetOrganizationId: currentOrganizationId,
      targetProjectId: currentProjectId,
    });

    closeSnackbar();
    enqueueSnackbar(
      <ImportSpreadsheetMessage uploadFolderResponse={uploadFolderResponse} />
    );
  };

  const startImportProcess = ({
    folderId,
    organizationId,
    projectId,
  }: StartImportProcessParams) => {
    setCurrentFolderId(folderId);
    setCurrentProjectId(projectId);
    setCurrentTargetOrganizationId(organizationId);
    openFileInput();
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileInput,
  } = useDropzone({
    accept: ['.xlsx', '.xls'],
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const values = {
    getInputProps,
    getRootProps,
    isDragActive,
    openFileInput,
    startImportProcess,
  };

  return (
    <UploadSpreadsheetContext.Provider value={values}>
      {children}
    </UploadSpreadsheetContext.Provider>
  );
};
