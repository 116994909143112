import { useEffect, useState } from 'react';

import { useCreateSummaryMutation, useVersionQuery } from '@pro4all/graphql';

interface useAiSummaryProps {
  currentSummary: string;
  versionId?: string | undefined;
}

export const useAiSummary = ({
  currentSummary,
  versionId,
}: useAiSummaryProps) => {
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false);
  const [summary, setSummary] = useState<string | null>(currentSummary);

  const [createSummaryMutation] = useCreateSummaryMutation();

  const {
    data: dataVersion,
    startPolling: startPollingVersion,
    stopPolling: stopPollingVersion,
  } = useVersionQuery({
    fetchPolicy: 'cache-first',
    skip: Boolean(!versionId),
    variables: { id: versionId || '' },
  });

  useEffect(() => {
    if (dataVersion?.version?.summary) {
      const { status, text } = dataVersion.version.summary;
      if (status === 'completed') {
        setSummary(text || '');
        setSummaryLoading(false);
      }
    }
  }, [dataVersion]);

  useEffect(() => {
    summaryLoading ? startPollingVersion(5000) : stopPollingVersion();
  }, [startPollingVersion, stopPollingVersion, summaryLoading]);

  async function createSummary() {
    setSummaryLoading(true);
    await createSummaryMutation({ variables: { versionId: versionId || '' } });
  }

  return { createSummary, summary, summaryLoading };
};
