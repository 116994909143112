import React, { useState } from 'react';

import { FormFooter } from '@pro4all/shared/ui/general';

import { useCloseChangeSubscription } from './hooks/useCloseChangeSubscription';
import { useCompanyDataRegistration } from './hooks/useCompanyDataRegistration';
import { useSubmitChangeSubscription } from './hooks/useSubmitChangeSubscription';
import {
  SubscriptionStatus,
  useSubscriptionHelpers,
} from './hooks/useSubscriptionHelpers';
import * as StyledShared from './styles/Shared.styles';
import { SubscriptionSelectionCard } from './SubscriptionSelectionCard';

export const ChangeSubscription = () => {
  const {
    isCancelled,
    getCurrentSubscriptionStatus,
    getSelectableSubscriptionLevels,
    getSubscriptionLevel,
  } = useSubscriptionHelpers();
  const selecteableSubscriptionLevels = getSelectableSubscriptionLevels();
  const currentSubscription = getSubscriptionLevel();
  const isTrial = getCurrentSubscriptionStatus() === SubscriptionStatus.Trial;

  const [selectedSubscriptionLevel, setSelectedSubscriptionLevel] = useState(
    currentSubscription?.isSelectable ? currentSubscription?.id : ''
  );

  const onClose = useCloseChangeSubscription();
  const onSubmit = useSubmitChangeSubscription();
  const onNext = useCompanyDataRegistration();

  return (
    <>
      <StyledShared.PaneWrapper>
        {selecteableSubscriptionLevels?.map(
          (selecteableSubscriptionLevel, index) => (
            <SubscriptionSelectionCard
              currentLicense={selectedSubscriptionLevel}
              key={index}
              onSelect={setSelectedSubscriptionLevel}
              subscriptionLevel={selecteableSubscriptionLevel}
            />
          )
        )}
      </StyledShared.PaneWrapper>
      <FormFooter
        disableSubmit={
          !selectedSubscriptionLevel ||
          (!isCancelled &&
            Boolean(selectedSubscriptionLevel === currentSubscription?.id))
        }
        onClose={onClose}
        onSubmit={
          isTrial
            ? () => onNext(selectedSubscriptionLevel)
            : () => onSubmit(selectedSubscriptionLevel)
        }
        pb={3}
        pt={2}
        px={3}
        sticky
        submitLabel={isTrial ? 'Next' : 'Save and confirm'}
      />
    </>
  );
};
