import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Project, Task } from '@pro4all/graphql';
import { ProjectSidebar } from '@pro4all/projects/ui/sidebar';
import {
  ProjectsTable,
  ProjectsTablePostFix,
} from '@pro4all/projects/ui/table';
import { Routes } from '@pro4all/shared/config';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/general';

import { MyTasks } from '../components/tasks/MyTasks';

export const MyOverview: React.FC = () => (
  <>
    <Route exact path={Routes.myOverview}>
      <Redirect to={Routes.myProjects} />
    </Route>
    <Route path={Routes.myProjects}>
      <OptimisticResponseProvider<Project>>
        <>
          <ProjectSidebar />
          <ProjectsTable postfix={ProjectsTablePostFix.OWN} />
        </>
      </OptimisticResponseProvider>
    </Route>
    <Route path={Routes.myTasks}>
      <OptimisticResponseProvider<Task>>
        <MyTasks />
      </OptimisticResponseProvider>
    </Route>
  </>
);
