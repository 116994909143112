export const firstCharToLowerCase = (stringValue: string) => {
  if (typeof stringValue !== 'string' || stringValue.length === 0) {
    // Handle invalid input
    return stringValue;
  }

  // Convert the first character to lowercase
  const firstCharacter = stringValue.charAt(0).toLowerCase();

  // Concatenate the modified first character with the rest of the string
  const modifiedString = firstCharacter + stringValue.slice(1);

  return modifiedString;
};
