import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { usePersistentState } from '@pro4all/shared/hooks';

interface SideNavContextValue {
  setSideNavState: (isOpen: SideNavState) => void;
  setSideNavVisibleOnMobile: Dispatch<SetStateAction<boolean>>;
  sideNavState: SideNavState;
  sideNavVisibleOnMobile: boolean;
}

export type NavGroupIds =
  | 'organizationColleagues'
  | 'projectMembers'
  | 'projectSettings'
  | 'permissions'
  | 'workflows'
  | 'Documents'
  | 'qualityControl'
  | 'projectTemplates'
  | 'messages'
  | 'myTasks'
  | 'organizationObjects'
  | 'myProjects'
  | 'projects'
  | 'projectObjects'
  | 'organizationDocuments'
  | 'organizationTemplates'
  | 'organizationAccess'
  | 'organizationSettings';

export const sideNavOpenKey = 'isSideNavOpen';
export type SideNavState = {
  [key in NavGroupIds | typeof sideNavOpenKey]: boolean;
};

export const SideNavigationContext = createContext({} as SideNavContextValue);

export const useSideNavContext = () =>
  useContext<SideNavContextValue>(SideNavigationContext) || {
    setSideNavState: null,
    setSideNavVisibleOnMobile: null,
    sideNavState: null,
    sideNavVisibleOnMobile: null,
  };

const sideNavStorageKey = 'prostream-side-nav-collapse-state';

const defaultState: SideNavState = {
  Documents: false,
  isSideNavOpen: true,
  messages: false,
  myProjects: false,
  myTasks: false,
  organizationAccess: false,
  organizationColleagues: false,
  organizationDocuments: false,
  organizationObjects: false,
  organizationSettings: false,
  organizationTemplates: false,
  permissions: false,
  projectMembers: false,
  projectObjects: false,
  projectSettings: false,
  projectTemplates: false,
  projects: false,
  qualityControl: false,
  workflows: false,
};
export const SideNavContextProvider: React.FC = ({ children }) => {
  const [sideNavState, setSideNavState] = usePersistentState<SideNavState>(
    sideNavStorageKey,
    defaultState
  );
  const [sideNavVisibleOnMobile, setSideNavVisibleOnMobile] = useState(false);

  const value = useMemo(
    () => ({
      setSideNavState,
      setSideNavVisibleOnMobile,
      sideNavState,
      sideNavVisibleOnMobile,
    }),
    [
      sideNavState,
      setSideNavState,
      sideNavVisibleOnMobile,
      setSideNavVisibleOnMobile,
    ]
  );

  return (
    <SideNavigationContext.Provider value={value}>
      {children}
    </SideNavigationContext.Provider>
  );
};
