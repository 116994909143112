import { getNestedFolders } from '../helpers/getNestedFolders';
import { unflattenFolders } from '../helpers/unflattenFolders';
import { State } from '../types';

export const deleteDMSItemAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const folderToBeDeleted = state.folders.find(
    (folder) => folder.id === payload
  );
  const folders = state.folders.filter((folder) => folder.id !== payload);

  const nestedFolders = getNestedFolders({
    childFolderId: payload,
    folders: state.folders,
  });
  const nestedFolderIds = nestedFolders.map((folder) => folder.id);
  const foldersWithoutNested = folders.filter(
    (folder) => !nestedFolderIds.includes(folder.id)
  );

  if (folderToBeDeleted.parentFolderId) {
    // Check if the parent has subfolders left after the deletion of this one.
    const childFolders = state.folders.filter(
      (folder) => folder.parentFolderId === folderToBeDeleted.parentFolderId
    );
    const hasSubFolders = Boolean(childFolders.length > 1);
    const parentFolder = foldersWithoutNested.find(
      (folder) => folder.id === folderToBeDeleted.parentFolderId
    );
    const foldersWithoutParentFolder = foldersWithoutNested.filter(
      (folder) => folder.id !== folderToBeDeleted.parentFolderId
    );
    const foldersUpdated = [
      ...foldersWithoutParentFolder,
      { ...parentFolder, hasSubFolders },
    ];
    return {
      ...state,
      folderTree: unflattenFolders(foldersUpdated),
      folders: foldersUpdated,
      foldersPrevious: state.folders,
    };
  } else {
    return {
      ...state,
      folderTree: unflattenFolders(foldersWithoutNested),
      folders: foldersWithoutNested,
      foldersPrevious: state.folders,
    };
  }
};
