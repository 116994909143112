import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useDocumentTagsConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      name: 'tags',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ formFields });
};
