import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import {
  FieldDefinition,
  FormLinkedSnag,
  useAddSnagToFormLinkMutation,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { isDefined } from '@pro4all/shared/utils';

import { useTbqLocationData } from './useTbqLocationData';

interface Props {
  fieldDefinitions: FieldDefinition[];
  linkedSnags: FormLinkedSnag[];
  onClose: () => void;
  refetchQualityInstance: () => void;
  visualContextId?: string;
}

export const LinkTbqQuestionDialog = ({
  fieldDefinitions,
  onClose,
  linkedSnags,
  refetchQualityInstance,
}: Props) => {
  const { t } = useTranslation();
  const { query, searchInput } = useSearchInput();
  const locationData = useTbqLocationData();

  const { enqueueSnackbar } = useSnackbar();

  const [addSnagToFormLink] = useAddSnagToFormLinkMutation();

  const allSubFields = fieldDefinitions
    .map((fieldDef) => fieldDef.valueType?.subFields)
    .flat();

  const onlyFieldsThatAreNotLinked = useMemo(() => {
    const linkedFieldIds = linkedSnags.map((snag) => snag.appData?.fieldId);

    const subFieldsWithoutLink = allSubFields
      .map((subField) => subField?.valueType?.subFields)
      .flat()
      .filter((subField) => !linkedFieldIds.includes(subField?.id));

    return subFieldsWithoutLink;
  }, [linkedSnags, allSubFields]);

  const filteredQuestions = onlyFieldsThatAreNotLinked
    .filter(isDefined)
    .filter(({ name }) =>
      name
        ? name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        : false
    );

  const findParentSectionQuestion = (sectionId: string) => {
    const question: FieldDefinition | undefined | null = allSubFields.find(
      (section) => section?.id === sectionId
    );

    if (!question) return;
    return question;
  };

  const findParentOfParent = (sectionId: string) => {
    const question: FieldDefinition | undefined = fieldDefinitions.find(
      (section) => section.id === sectionId
    );

    if (!question) return;
    return question;
  };

  const linkQuestion = async (fieldId: string) => {
    try {
      const { data } = await addSnagToFormLink({
        variables: {
          fieldId: fieldId,
          formInstanceId: locationData.formInstanceId,
          snagInstanceId: locationData.snagInstanceId,
        },
      });
      if (data?.addSnagToFormLink?.errors) {
        enqueueSnackbar(t('Something went wrong. Please try again.'));
      }
      refetchQualityInstance();
      enqueueSnackbar(t('Question is succesfully linked to form.'));
    } catch (e) {
      enqueueSnackbar(t('Something went wrong. Please try again.'));
    }
  };

  return (
    <FloatingModal
      maxWidth="md"
      onClose={() => onClose()}
      open
      title={t('Select a Snag')}
    >
      <FloatingModal.Header iconName="link">
        {t('Select question to link')}
      </FloatingModal.Header>
      <ContentWrapper>
        {searchInput}
        {fieldDefinitions?.length < 1 && (
          <p>
            No questions found for <strong>{query}</strong>
          </p>
        )}

        {filteredQuestions?.map(({ id, name, parentSectionId }) => {
          const parentSection: FieldDefinition | undefined =
            findParentSectionQuestion(parentSectionId || '');

          const parentSectionOfParent: FieldDefinition | undefined =
            findParentOfParent(parentSection?.parentSectionId ?? '');

          const parentOfParentName = parentSectionOfParent?.name
            ? `${parentSectionOfParent?.name},`
            : '';
          const parentName = parentSection?.name
            ? `${parentSection?.name},`
            : '';
          const metaCardName = `${parentOfParentName} ${parentName} ${locationData.taskName}`;

          return (
            <Card
              disabledButtonBorder
              iconName="questionMark"
              key={id}
              menuItems={[
                {
                  label: `Select question`,
                  startIcon: 'link',
                },
              ]}
              meta={metaCardName}
              onClick={() => linkQuestion(id)}
              title={name || ''}
            />
          );
        })}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Button color="inherit" onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
