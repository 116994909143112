import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Task, TaskType } from '@pro4all/graphql';
import { DrawingsMain } from '@pro4all/quality-control/ui/floor-plan';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
  ResultsMain,
} from '@pro4all/quality-control/ui/results';
import { Routes } from '@pro4all/shared/config';
import {
  PhotoProvider,
  useObjectDetailContext,
} from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFormSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { Loader, OptimisticResponseProvider } from '@pro4all/shared/ui/general';

import * as Messages from '../../Messages';
import { ProjectStatus } from '../../projects';
import { TBQTasks, TBQType } from '../../tbq';
import { OsidLogs } from '../../tbq/osid/OsidLogs';
import { TBQResolveTasks } from '../../tbq/tasks/list/TBQResolveTasks';

import {
  ObjectDetailWrapper,
  ResultTableWrapper,
} from './ObjectDetailUI.styles';
import { Tabs } from './Tabs';

export const ObjectDetail: React.FC = () => {
  const { id, loading, object } = useObjectDetailContext();
  const routeProjectId = useRouting().params.projectId;
  if (!id) return <Messages.NoObject />;
  if (!object && loading) return <Loader />;
  if (!object) return <Messages.NotFound />;

  const { tasks = [] } = object;
  const routeOrObjectProjectId = routeProjectId ?? object.projectId;
  const procedureId = object?.project?.mainProcedure?.id;

  return (
    <>
      <ObjectDetailWrapper>
        {routeOrObjectProjectId && <Tabs />}
        <Route
          path={[
            Routes.objectTBQ,
            Routes.objectDrawings,
            Routes.objectResults,
            Routes.objectTasks,
            Routes.projectObjectTbq,
            Routes.projectObjectDrawings,
            Routes.projectObjectResults,
            Routes.projectObjectTasks,
          ]}
        >
          <ProjectStatus />
        </Route>
        <Switch>
          <Route path={[Routes.objectTBQ, Routes.projectObjectTbq]}>
            <TBQType />
            <OptimisticResponseProvider<Task>>
              <TBQTasks
                procedureId={procedureId}
                projectId={routeOrObjectProjectId ?? ''}
                tasks={
                  tasks?.filter(
                    (t) =>
                      t.type === TaskType.Tbq || t.type === TaskType.TbqScan
                  ) ?? []
                }
              />
            </OptimisticResponseProvider>
          </Route>
          <Route path={[Routes.objectDrawings, Routes.projectObjectDrawings]}>
            <DrawingsMain projectId={routeOrObjectProjectId ?? ''} />
          </Route>
          <Route path={[Routes.objectResults, Routes.projectObjectResults]}>
            <ResultTableWrapper>
              <SnagFormSearchContextProvider>
                <ResultsMain projectId={routeOrObjectProjectId ?? ''} />
              </SnagFormSearchContextProvider>
            </ResultTableWrapper>
          </Route>
          <Route path={[Routes.objectTasks, Routes.projectObjectTasks]}>
            <OptimisticResponseProvider<Task>>
              <TBQResolveTasks
                procedureId={procedureId ?? ''}
                projectId={routeOrObjectProjectId ?? ''}
              />
            </OptimisticResponseProvider>
          </Route>
          <Route
            path={[Routes.objectTBQOsidLog, Routes.projectObjectTBQOsidLog]}
          >
            <OsidLogs />
          </Route>
        </Switch>
      </ObjectDetailWrapper>
      <PhotoProvider>
        <AnswersPercentageContextProvider>
          <ResultSidebar />
        </AnswersPercentageContextProvider>
      </PhotoProvider>
    </>
  );
};
