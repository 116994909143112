import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';

import { useExportTableData } from './useExportTableData';

export const useExportActions = () => {
  const { t } = useTranslation();
  const { count, exportToCsv, exportToExcel, workersAvailable } =
    useExportTableData();

  const exportToCsvAction: ActionProps = {
    ariaLabel: t('CSV'),
    dataTestId: 'export-to-csv"',
    key: 'export-to-csv',
    label: t('CSV'),
    onClick: exportToCsv,
    startIcon: 'csv',
  };
  const exportToExcelAction: ActionProps = {
    ariaLabel: t('Excel'),
    dataTestId: 'export-to-excel"',
    key: 'export-to-excel',
    label: t('Excel'),
    onClick: exportToExcel,
    startIcon: 'excel',
  };

  return workersAvailable && count > 0 ? (
    <Button
      color="inherit"
      data-testid="export-table-data"
      startIcon="export"
      subItems={[exportToCsvAction, exportToExcelAction]}
      sx={{ display: { sm: 'inherit', xs: 'none' } }}
    >
      {t('Export ({{count}})', { count })}
    </Button>
  ) : null;
};
