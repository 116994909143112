import React from 'react';
import { useTranslation } from 'react-i18next';

import { Role } from '@pro4all/graphql';
import { MiddleEllipsis } from '@pro4all/shared/ui/general';

export const joinRoles = (roles: Role[]) =>
  (roles || [])
    .map((role) => role?.name)
    .sort((a, b) => a.localeCompare(b))
    .filter(Boolean)
    .join(', ');

export const Roles = ({ roles = [] }: { roles: Role[] }) => {
  const { t } = useTranslation();
  if (!roles) return null;

  return (
    <MiddleEllipsis
      endLength={9}
      text={joinRoles(roles?.map((role) => ({ ...role, name: t(role?.name) })))}
    />
  );
};
