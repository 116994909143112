import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  useConnectionQuery,
  useFilesExistsBuild12LazyQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { useShareBuild12Context } from './ShareBuild12Context';
import { FormFields } from './types';

export const usePreSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    selectedDocuments,
    selectedConnection,
    setExistingVersionIds,
    setSelectedDocuments,
    setValidationScreen,
  } = useShareBuild12Context();
  const { showSingleError } = useShowMessages();
  const { params } = useRouting();
  const { projectId } = params;
  const { data: integrationConnection } = useConnectionQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !selectedConnection,
    variables: { id: selectedConnection || '', projectId: projectId || '' },
  });
  const [fileExists12Build] = useFilesExistsBuild12LazyQuery({
    fetchPolicy: 'network-only',
  });

  return async (values: FormFields): Promise<boolean> => {
    const { connection, projectPhase } = values;
    const versionIdsToValidate = selectedDocuments.map(
      (document) => document.versionId || ''
    );

    try {
      const response = await fileExists12Build({
        variables: {
          connectionId: connection.id,
          projectId: integrationConnection?.connection.projectId || '',
          projectPhaseId: projectPhase.id,
          versionIds: versionIdsToValidate,
        },
      });

      if (!response?.data) {
        enqueueSnackbar(t('Something went wrong'));
        setValidationScreen(false);
        return true;
      }

      if (response?.data?.filesExistsBuild12) {
        const fileExistResponse = response?.data?.filesExistsBuild12;

        const rejectedVersionIds =
          fileExistResponse?.rejectedIds
            .filter((id) => Boolean(id))
            .map((id) => id as string) ?? [];

        const alreadyExistsVersionIds =
          fileExistResponse?.alreadyExistsIds
            .filter((id) => Boolean(id))
            .map((id) => id as string) ?? [];

        setExistingVersionIds(alreadyExistsVersionIds);

        setSelectedDocuments &&
          setSelectedDocuments(
            selectedDocuments.filter(
              (document) =>
                document.versionId &&
                !rejectedVersionIds.includes(document.versionId)
            )
          );

        if (alreadyExistsVersionIds.length > 0) {
          setValidationScreen(true);
        }

        return alreadyExistsVersionIds.length === 0;
      }
    } catch (e) {
      showSingleError(e);
    }

    return false;
  };
};
