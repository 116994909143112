import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  FieldDefinition,
  useFolderByPathQuery,
  ValueTypeName,
} from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ClientRedirect } from '@pro4all/shared/ui/client-redirect';
import { Column, Main } from '@pro4all/shared/ui/general';
import { ProgressBar } from '@pro4all/shared/ui/progress-bar';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Text } from '@pro4all/shared/ui/typography';
import { useAnalytics } from '@pro4all/shared/vendor';

import { NavigateAwayDialog } from './other';
import * as Styled from './UploaderEditor.styles';
import { UploaderEditorForm } from './UploaderEditorForm';
import { useUploaderEditorContext } from './UploaderEditorProvider';

type UploadEditorRoutingState = {
  documentCurrent: Document; // In case user drops a file on the sidebar or the version table, we need a reference to the applicable document container.
  documentIdsForEdit: string[]; // If undefined we are in a context in which files are uploaded.
  filesToUpload: File[]; // If undefined we are in a context in which existing documents are edited.
  publishDocument: boolean; // Is it a context where we publish a document yes or no?
};

export const UploaderEditor = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { track } = useAnalytics();
  const { userId } = AuthService.getProfile();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  // Somehow (reason unknown) it initializes twice on the prop `data`.
  // Apart from performance this causes issues in a `upload` context because the ids are generated twice.
  // The `VersionNameColumn` component renders initially on the first id, but then later on checks on the second id which is a problem.
  // So with this `ref` we prevent the second initialization.
  const initializationDone = useRef(false);

  const {
    params: { path, projectId },
    state: {
      documentCurrent,
      documentIdsForEdit,
      filesToUpload,
      publishDocument,
    },
    searchParams,
  } = useRouting<UploadEditorRoutingState>();
  const isUploadContext = filesToUpload ? true : false;

  //Redirect to application
  const { isDialogOpen, setIsDialogOpen } = useClientRedirectContext();

  const {
    initializeDocumentsForEdit,
    initializeDocumentsForUpload,
    state: { filesProcessed, isProcessing, meta, partialUploadProgress },
  } = useUploaderEditorContext();

  const { data, error, loading } = useFolderByPathQuery({
    // We need to make sure we have the latest instance of a folder in order
    // to match (newly created) documentIds to folder.documents.
    fetchPolicy: 'cache-and-network',
    variables: { path: path ? path : '/', projectId },
  });

  useEffect(() => {
    if (data && initializationDone.current === false) {
      const fields =
        data.folder && data.folder.template
          ? (data.folder.template.fields as FieldDefinition[])
          : [];

      // In this context we're not interested in the standard fields.
      const nonStandardFields = fields.filter(
        (field) => field.type !== ValueTypeName.StandardItem
      );

      const sharedProps = {
        currentDocumentsInFolder: data.folder
          ? (data.folder.documents as Document[])
          : [],
        fields: nonStandardFields,
        templateId:
          data.folder && data.folder.template
            ? (data.folder.template.id as string)
            : '',
      };

      const folderId = data.folder?.id || '';
      const analyticProps = {
        fieldCount: nonStandardFields.length + 1,
        folderId,
        organizationId,
        projectId,
        userId,
      };

      if (isUploadContext) {
        track(TrackingEvent.UploadFileIntent, {
          ...analyticProps,
          fileCount: filesToUpload.length,
        });

        initializeDocumentsForUpload({
          ...sharedProps,
          documentCurrent,
          filesToUpload,
          folderId,
          publishDocument,
        });
      } else {
        track(TrackingEvent.MetadataEditIntent, {
          ...analyticProps,
          fileCount: documentIdsForEdit.length,
        });

        initializeDocumentsForEdit({
          ...sharedProps,
          documentIdsForEdit,
          folderId,
        });
      }
      initializationDone.current = true;
    }
  }, [
    data,
    documentCurrent,
    documentIdsForEdit,
    filesToUpload,
    initializeDocumentsForEdit,
    initializeDocumentsForUpload,
    isUploadContext,
    organizationId,
    projectId,
    publishDocument,
    track,
    userId,
  ]);

  const partialProgress =
    partialUploadProgress > 0 && partialUploadProgress < 1
      ? partialUploadProgress
      : 0;

  const handleModalClose = () => {
    searchParams.clear();
    setIsDialogOpen(false);
  };

  return (
    <ResponseWrapper error={error} isLoading={loading}>
      <Main>
        <Column>
          <Styled.FolderWrapper>
            <Styled.FolderIcon
              htmlColor={theme.palette.warning.main}
              iconName="folder"
            />
            <Text variant="h5">{data?.folder?.name}</Text>
          </Styled.FolderWrapper>
          <UploaderEditorForm isUploadContext={isUploadContext} />
        </Column>
      </Main>
      <NavigateAwayDialog />
      {filesToUpload && filesToUpload.length && isProcessing && (
        <ProgressBar
          current={(filesProcessed + partialProgress) / meta.length}
          maximum={1}
          text={t('{{current}} of {{maximum}} documents uploaded', {
            current: filesProcessed,
            maximum: meta.length,
          })}
        />
      )}
      {isDialogOpen && (
        <ClientRedirect
          handleClose={() => handleModalClose()}
          open={isDialogOpen}
        />
      )}
    </ResponseWrapper>
  );
};
