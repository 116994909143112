import React from 'react';
import { useTranslation } from 'react-i18next';

import { SavedAnswers } from '@pro4all/graphql';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';
import { SelectedIdName } from '@pro4all/shared/types';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

import { useRestoreDeletedSavedAnswers } from './useRestoreDeletedSavedAnswers';

export const useSnackbars = () => {
  const { t } = useTranslation();

  const { onRestoreDocument, onRestoreDocuments } =
    useRestoreDeletedSavedAnswers();

  const SavedAnswerInstanceDeleted: React.FC<{
    cachedSavedAnswers: SavedAnswers[];
    succeeded: SelectedIdName[];
  }> = ({ cachedSavedAnswers, succeeded }) => {
    const undoDeleteDocument = (
      <Button
        color="inherit"
        onClick={() => onRestoreDocument({ cachedSavedAnswers, succeeded })}
        size="small"
      >
        {t('Undo')}
      </Button>
    );
    return (
      <ItemChangedMessage
        action={undoDeleteDocument}
        description={MessageAction.Delete}
        entityName={succeeded[0].name}
        entityTypeTranslation={EntityTypeTranslation.SavedAnswers}
      />
    );
  };

  const SavedAnswerInstancesDeleted: React.FC<{
    cachedSavedAnswers: SavedAnswers[];
    succeeded: SelectedIdName[];
  }> = ({ cachedSavedAnswers, succeeded }) => {
    const { t } = useTranslation();
    const undoDeleteDocuments = (
      <Button
        color="inherit"
        onClick={() => onRestoreDocuments({ cachedSavedAnswers, succeeded })}
        size="small"
      >
        {t('Undo')}
      </Button>
    );

    return (
      <Alert action={undoDeleteDocuments}>
        {t('Deleted {{count}} saved answers', {
          count: succeeded.length,
        })}
      </Alert>
    );
  };

  return { SavedAnswerInstanceDeleted, SavedAnswerInstancesDeleted };
};
