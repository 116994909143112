import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDefinition } from '@pro4all/graphql';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button, ButtonGroupHorizontal } from '@pro4all/shared/ui/buttons';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

export const RedirectToOrganizationFields = ({
  selectedFieldDefinition,
}: {
  selectedFieldDefinition?: FieldDefinition;
}) => {
  const { t } = useTranslation();
  const { templateType, templateService } = useMetaDataContext();
  const { id } = selectedFieldDefinition;
  const { goTo, searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();

  const { route } = featureConfig[templateService].fieldTypes[templateType];

  return (
    <Box p={3}>
      <Box mb={3}>{`${t('This item is linked from an organization')}. ${t(
        'This can only be edited from within the organization and not from within the project'
      )}.`}</Box>
      <ButtonGroupHorizontal>
        <Button
          disabled={!hasAppPermission('OrganizationUpdate')}
          onClick={() =>
            goTo(route, {
              searchParams: { action: 'editItem', id },
            })
          }
        >
          {t('To organization item')}
        </Button>
        <Button color="inherit" onClick={() => searchParams.clear()}>
          {t('Cancel')}
        </Button>
      </ButtonGroupHorizontal>
    </Box>
  );
};
