import { useTranslation } from 'react-i18next';

import { Status as StatusType } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { IconName } from '@pro4all/shared/ui/icons';

type TaskStatusMap = {
  [key in StatusType]: {
    color: string;
    icon: IconName;
    label: string;
    textColor: string;
  };
};

export const useTaskStatuses = (): TaskStatusMap => {
  const { t } = useTranslation();
  const theme = useTheme();
  return {
    Done: {
      color: theme.palette.success.main,
      icon: 'check',
      label: t('Done'),
      textColor: theme.palette.success.contrastText,
    },
    InProgress: {
      color: theme.palette.primary.main,
      icon: 'timer',
      label: t('In progress'),
      textColor: theme.palette.primary.contrastText,
    },
    Paused: {
      color: theme.palette.warning.main,
      icon: 'pause',
      label: t('Paused'),
      textColor: theme.palette.warning.contrastText,
    },
    ToDo: {
      color: theme.palette.grey['300'],
      icon: 'timerOff',
      label: t('Open'),
      textColor: theme.palette.text.secondary,
    },
  };
};
