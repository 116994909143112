import React from 'react';
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

// NavLinks colors are inherited from the TopNav color
const StyledNavLink: React.FC<RouterNavLinkProps> = styled(RouterNavLink).attrs(
  { activeClassName: 'isSelected' }
)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease;

  & > .MuiTypography-button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }

  &.${({ activeClassName }) => activeClassName} {
    border-color: ${customColors.white};
  }
`;

export interface NavLinkProps extends RouterNavLinkProps {
  'data-testid'?: string;
  icon?: IconName;
  label?: string;
}

export const NavLink: React.FC<NavLinkProps> = ({ icon, label, ...props }) => (
  <StyledNavLink {...props}>
    {icon && <Icon iconName={icon} />}
    <Text variant="button">{label}</Text>
  </StyledNavLink>
);
