import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Task,
  TemplateService,
  TemplateState,
  TemplateType,
} from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import {
  ComposedPin,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';

import { MultiSelectList } from './MultiSelectList';

interface Props {
  id: string;
  label: string;
  task?: Task;
  tooltip: string;
  type: TemplateType;
}

export const TemplateSelect: React.FC<Props> = ({
  id,
  label,
  task,
  tooltip,
  type,
}) => {
  const { t } = useTranslation();

  const { templates, loading } = useTemplatesInclude({
    includeOrgItems: true,
    state: TemplateState.Published,
    templateService: TemplateService.QualityControl,
    templateType: type,
  });
  const isSelectIconEnabled = useFeatureFlag('customericons');

  const getTitleIconName = () => {
    if (isSelectIconEnabled) {
      return type === TemplateType.Form ? 'staticFormIcon' : 'staticSnagIcon';
    }

    return type === TemplateType.Form ? 'clipboard' : 'snag';
  };

  const titleIconName = getTitleIconName();

  const templatesFiltered = (templates || [])
    .filter(Boolean)
    .map((template) => ({
      iconComponent: isSelectIconEnabled ? (
        <Box sx={{ mr: 1 }}>
          <ComposedPin
            currentFile={
              template.icon?.fileId ? getFileUrlByTemplateId(template.id) : null
            }
            selectedIconName={template.icon?.name as TMuiIcon}
            simpleIcon
            type={type === TemplateType.Snag ? 'snag' : 'form'}
          />
        </Box>
      ) : null,
      id: template.id,
      label: template.name ?? '',
    }));

  return (
    <MultiSelectList
      iconName={type === TemplateType.Form ? 'clipboard' : 'snag'}
      id={id}
      label={label}
      loading={!templates || loading}
      noOptionsText={t(
        `Add ${type.toLowerCase()} templates first to add them to a task`
      )}
      options={templatesFiltered}
      placeholder={t(`Search ${type.toLowerCase()} templates`)}
      task={task}
      titleIconName={titleIconName}
      tooltip={tooltip}
    />
  );
};
