import { useTranslation } from 'react-i18next';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';

export const useDocumentActionsLabels = () => {
  const { t } = useTranslation();

  const defaultNames = [t('Approve'), t('Reject'), t('Finalize')];
  const { organization } = useOrganizationContext();
  const { settings } = useProjectContext();
  const orgSettings = organization?.settings;

  /* Verify Organization settings, if not put default value */
  let approve_action_label =
    orgSettings &&
    orgSettings.approve_action_label &&
    orgSettings.approve_action_label !== ''
      ? orgSettings.approve_action_label
      : defaultNames[0];
  let finalize_action_label =
    orgSettings &&
    orgSettings.finalize_action_label &&
    orgSettings.finalize_action_label !== ''
      ? orgSettings.finalize_action_label
      : defaultNames[2];
  let reject_action_label =
    orgSettings &&
    orgSettings.reject_action_label &&
    orgSettings.reject_action_label !== ''
      ? orgSettings.reject_action_label
      : defaultNames[1];

  /* Check if the project have specific settings */
  if (settings) {
    //const settings = settings;
    approve_action_label =
      settings?.approve_action_label && settings?.approve_action_label !== ''
        ? settings?.approve_action_label
        : approve_action_label;
    finalize_action_label =
      settings?.finalize_action_label && settings?.finalize_action_label !== ''
        ? settings?.finalize_action_label
        : finalize_action_label;
    reject_action_label =
      settings?.reject_action_label && settings?.reject_action_label !== ''
        ? settings?.reject_action_label
        : reject_action_label;
  }

  return {
    approve_action_label,
    finalize_action_label,
    orgSettings,
    reject_action_label,
  };
};
