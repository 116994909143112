import { Route } from 'react-router-dom';
import { Location } from 'history';

import { TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { Column, Main } from '@pro4all/shared/ui/general';

import { TemplateFrame } from '../components/form-template/TemplateFrame';
import { ScreenTBQTemplates } from '../components/screens/ScreenTBQTemplates';
import { MetadataTabs } from '../components/tabs/MetadataTabs';

import { MetaDataContextProvider } from './MetaDataContext';

export const OrganizationTBQ = () => {
  const templateType = TemplateType.Tbq;
  const templateService = TemplateService.QualityControl;

  const qualityControlRoutesTemplates = [
    Routes.metaDataQualityControlTBQTemplates,
    Routes.metaDataQualityControlTBQTemplatesScope,
  ];

  const qualityControlRoutesItems = [Routes.metaDataQualityControlFields];
  const { selectedTemplate } = useTemplatesInclude({
    templateService,
    templateType,
  });

  function isEditOrCreate(location: Location<unknown>) {
    const searchParams = new URLSearchParams(location.search);
    const readOrEdit =
      searchParams.get('action') !== 'createTemplate' &&
      searchParams.get('action') !== 'editSet';
    return readOrEdit;
  }

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <Route
        exact
        path={[...qualityControlRoutesTemplates, ...qualityControlRoutesItems]}
        render={({ location }) =>
          isEditOrCreate(location) ? (
            <>
              <MetadataTabs base={null} />
              <Main>
                <Column>
                  <ScreenTBQTemplates />
                </Column>
              </Main>
            </>
          ) : (
            <TemplateFrame selectedTemplate={selectedTemplate} />
          )
        }
      />
    </MetaDataContextProvider>
  );
};
