import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { Checkbox, SearchableSelect } from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';

import { useReportOptionsContext } from '../ReportOptionsProvider';

export const StylePresetSelect: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectReportConfiguration,
    setActiveReportConfiguration,
    setReportOptions,
    state: { activeReportConfiguration, customMode, reportConfigurations },
    toggleCustomMode,
  } = useReportOptionsContext();

  // Calculate option via state prop `reportConfigurations`.
  const options = reportConfigurations
    ? reportConfigurations
        .map(({ id, name }) => ({
          id,
          label: name,
        }))
        .sort(sortBy({ key: 'label' }))
    : [];

  useEffect(() => {
    if (reportConfigurations) {
      // Get the default report configurations. Can be max 2, one for the organization and one for the project.
      const defaultReportConfigurations = reportConfigurations?.filter(
        (config) => config.isDefault
      );

      // Get the default report configurations for this context.
      if (defaultReportConfigurations.length) {
        const defaultReportConfiguration =
          defaultReportConfigurations.length === 1
            ? defaultReportConfigurations[0]
            : defaultReportConfigurations.find((config) => config.projectId);

        const { id, isDefault, name, projectId, reportOptions } =
          defaultReportConfiguration || {};

        if (id && name && reportOptions && !activeReportConfiguration) {
          setActiveReportConfiguration({
            id,
            name,
            setAsDefault: Boolean(isDefault),
            storeOnOrganizationLevel: projectId ? false : true,
          });
          setReportOptions(JSON.parse(reportOptions));
        }
      }
    }
  }, [
    activeReportConfiguration,
    reportConfigurations,
    setActiveReportConfiguration,
    setReportOptions,
  ]);

  // Calculate current value via state prop `activeReportConfiguration`.
  let id: string;
  let label: string;
  if (activeReportConfiguration) {
    id = activeReportConfiguration.id;
    label = activeReportConfiguration.name;
  } else {
    id = 'default';
    label = 'default';
  }

  const value = useMemo(() => ({ id, label }), [id, label]);

  const memoizedJSX = useMemo(
    () => (
      <>
        <SearchableSelect
          label={t('Report configuration')}
          name="report-configuration-select"
          onChange={(values) => selectReportConfiguration(values)}
          options={options}
          value={value}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={customMode}
              onChange={() => toggleCustomMode()}
            />
          }
          label={t('Customize')}
        />
      </>
    ),
    [customMode, options, value]
  );

  return memoizedJSX;
};
