import { useLocalStorage } from '@pro4all/shared/hooks';
import { CutCopyPasteData } from '@pro4all/shared/types';

import { AddData } from '../types';

export const useInitializeLocalStorage = () => {
  const { setLocalStorageItem: setAddHierarchyItemInLs } =
    useLocalStorage<AddData>({
      key: `prostream-add-hierarchy-item`,
    });

  const { setLocalStorageItem: setEditHierarchyItemInLs } =
    useLocalStorage<AddData>({
      key: `prostream-edit-hierarchy-item`,
    });

  const { setLocalStorageItem: setCopyCutPasteHierarchyItemInLs } =
    useLocalStorage<CutCopyPasteData>({
      key: `prostream-copy-cut-paste-hierarchy-item`,
    });

  const initializeLocalStorage = () => {
    setAddHierarchyItemInLs(null);
    setEditHierarchyItemInLs(null);
    setCopyCutPasteHierarchyItemInLs(null);
  };

  return initializeLocalStorage;
};
