import { useTranslation } from 'react-i18next';

import { FilterType, SearchDocument } from '@pro4all/graphql';
import { useFilters } from '@pro4all/search/ui';
import { DocTablePropertyKeys } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  getFormattedDate,
  MiddleEllipsis,
  Timestamp,
} from '@pro4all/shared/ui/general';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const useOptionalColumns: () => ColumnProps<SearchDocument>[] = () => {
  const { currentFilters } = useFilters();
  const { t } = useTranslation();

  const getColumnVisible = (type: FilterType) =>
    currentFilters?.some((filter) => filter.type === type);

  const createdByColumn: ColumnProps<SearchDocument>[] = [
    {
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId={DocTablePropertyKeys.CreatedBy}
          showFilterIcon={false}
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy, isExpected }) =>
        !isExpected && (
          <MiddleEllipsis
            endLength={9}
            text={createdBy?.displayName || undefined}
          />
        ),
      width: 200,
    },
  ];
  const createdOnColumn: ColumnProps<SearchDocument>[] = [
    {
      getValue: (document: SearchDocument) =>
        document.createdAt
          ? getFormattedDate(document.createdAt, 'lll').label
          : '',
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId={DocTablePropertyKeys.CreatedAt}
          showFilterIcon={false}
        />
      ),
      key: 'createdAt',
      render: ({ createdAt, isExpected }) =>
        !isExpected && <Timestamp date={createdAt} format="lll" />,
      width: 200,
    },
  ];

  return [
    ...(getColumnVisible(FilterType.CreatedBy) ? createdByColumn : []),
    ...(getColumnVisible(FilterType.CreatedAt) ? createdOnColumn : []),
  ];
};
