import { useGroupsAndUsersIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useAccessMembers = ({
  includeExplicitProjectMember,
}: {
  includeExplicitProjectMember?: boolean;
}) => {
  const {
    params: { projectId },
  } = useRouting();

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      fetchInvitedUsers: true,
      includeEmail: true,
      includeExplicitProjectMember,
      includeIsAdmin: true,
      includeOrganizationId: true,
      projectId,
    },
  });

  return data?.groupsAndUsers || [];
};
