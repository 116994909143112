import { CustomConnectionProps, IntegrationType } from '@pro4all/graphql';

import { FormFields } from './types';

export const getConnectionCustomTypeInput = ({
  integrationType,
  valueType,
}: {
  integrationType: IntegrationType;
  valueType: FormFields;
}) => {
  const { project } = valueType;

  let integrationTypeInput = {} as CustomConnectionProps;

  // This switch will be extended in the future with other types that contain type related properties.
  switch (integrationType) {
    case IntegrationType.Integration12Build:
      integrationTypeInput = {
        id: project?.id || '',
        name: project?.label || '',
      };
      break;
    case IntegrationType.IntegrationSnagstream:
      integrationTypeInput = {
        id: project?.id || '',
        name: project?.label || '',
      };
      break;
    default:
  }

  return integrationTypeInput;
};
