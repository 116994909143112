import { useTranslation } from 'react-i18next';

import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { DownloadZipForm } from './DownloadZipForm';

export const DownloadZipModal = ({
  folder,
  hasSubFolders,
}: {
  folder: Folder;
  hasSubFolders: boolean;
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const showModal = searchParams.is('action', 'downloadZip');
  const onClose = () => searchParams.delete('action');

  return (
    <FloatingModal
      data-testid="download-zip"
      onClose={onClose}
      open={showModal}
    >
      <FloatingModal.Header iconName="download">
        {t('Download zip')}
      </FloatingModal.Header>
      <DownloadZipForm
        folder={folder}
        hasSubFolders={hasSubFolders}
        onClose={onClose}
      />
    </FloatingModal>
  );
};
