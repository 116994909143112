export enum EntityTypeTranslation {
  Document = 'Document', // i18n
  DocumentVersion = 'Document version', // i18n
  ExpectedDocument = 'Expected document', // i18n
  FieldDefinition = 'Meta data field', // i18n
  Folder = 'Folder', // i18n
  FormTemplate = 'Form template', // i18n
  HierarchicalList = 'Hierarchical list', // i18n //
  ObjectNode = 'Object', // i18n
  OrganizationGroup = 'Organization group', // i18n
  Project = 'Project', // i18n
  ProjectGroup = 'Project group', // i18n
  QuestionItem = 'Field', // i18n
  Result = 'Result', // i18n
  SavedAnswers = 'Saved answers', // i18n
  SavedSearch = 'Saved search', // i18n
  Section = 'Section', // i18n
  SmartFolder = 'Smart folder', // i18n
  Snag = 'Snag', // i18n
  SnagTemplate = 'Snag template', // i18n
  TBQScan = 'TBQ scan',
  Task = 'Task',
  // i18n
  TaskCategories = 'Task categories',
  // i18n
  TaskCategory = 'Task category', // i18n
  Template = 'Meta data template', // i18n
  User = 'User',
}

export enum MessageAction {
  Activated = "'{{- entityName}}' has been activated", // i18n
  Applied = "'{{- entityName}}' has been applied", // i18n
  Create = "'{{- entityName}}' has been created", // i18n
  CreateMultiple = "'{{- entityName}}' have been created", // i18n
  Deactivated = "'{{- entityName}}' has been deactivated", // i18n
  Delete = "'{{- entityName}}' has been deleted", // i18n
  DeleteMultiple = "'{{- entityName}}' have been deleted", // i18n
  Download = "'{{- entityName}}' has been downloaded", // i18n
  Duplicate = "'{{- entityName}}' will be created. This make take a few minutes.", // i18n
  Moved = "'{{- entityName}}' has been moved", // i18n
  Pasted = "'{{- entityName}}' has been pasted", // i18n
  RenamedTo = "'{{- entityName}}' has been renamed to '{{newName}}'", // i18n
  Restore = "'{{- entityName}}' has been restored", // i18n
  Unlink = "'{{- entityName}}' has been succesfully unlinked", // i18n
  Update = "'{{- entityName}}' has been updated", // i18n
  UpdateMultiple = "'{{- entityName}}' have been updated", // i18n
}
