import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Folder } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FormError,
  FormFooter,
  FormikForm,
  FormikInput,
  Sidebar,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { FormFields } from './types';
import { useNewExpectedDocumentConfig } from './useNewExpectedDocumentConfig';
import { useSubmit } from './useSubmit';

const initialValues: FormFields = {
  fileType: '',
  name: '',
};

export const NewExpectedDocument = ({ folder }: { folder: Folder }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsOpen(() => searchParams.is('action', 'createExpectedDoc'));
  }, [searchParams]);

  const handleClose = () => searchParams.clear();

  const { getField, validationSchema } = useNewExpectedDocumentConfig();
  const titleField = getField('name');
  const fileTypeField = getField('fileType');

  const onSubmit = useSubmit({
    folder,
  });

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header title={t('New expected document')} />

      <Formik
        initialValues={initialValues}
        onReset={() => inputRef.current && inputRef.current.focus()}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, errors, isSubmitting, status }) => (
          <FormikForm>
            <FormWrapper>
              <FormikInput
                autoFocus
                inputRef={inputRef}
                label={titleField?.label}
                name={titleField?.name}
              />
              <FormikInput
                label={fileTypeField?.label}
                name={fileTypeField?.name}
              />

              {status === 'failed' && (
                <FormError>
                  {t('Something went wrong. Please try again.')}
                </FormError>
              )}
            </FormWrapper>

            <FormFooter
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              onClose={handleClose}
              pb={3}
              pt={2}
              px={3}
              sticky
            />
          </FormikForm>
        )}
      </Formik>
    </Sidebar>
  );
};
