import React from 'react';

import { Tab as MuiTab, useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

interface Props {
  disabled?: boolean;
  icon?: IconName;
  label: string;
  onClick?: () => void;
  selected?: boolean;
  small?: boolean;
  value: string;
}

export const Tab: React.FC<Props> = ({ icon, ...props }) => {
  const theme = useTheme();
  const tabIcon = icon ? (
    <Icon
      htmlColor={
        props.selected ? theme.palette.primary.main : customColors.grey700
      }
      iconName={icon}
    />
  ) : undefined;

  return <MuiTab {...props} children={null} icon={tabIcon} />;
};
