import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import styled from 'styled-components';

type DateType = Date | string | null;

export interface TimestampProps {
  color?: string;
  date: DateType;
  // for format options see the dayjs docs for localized formats
  // https://day.js.org/docs/en/display/format#list-of-localized-formats
  format?: string;
}

export const getFormattedDate = (date: DateType, format = 'll') => {
  let dayJsDate = dayjs(date);

  // Date is formatted to allow for grouping (Tasks.tsx) This is a quick fix to revert it to standard format.
  // @Todo: implement robust date time formatting
  if (!dayJsDate.isValid()) dayJsDate = dayjs(date, 'D-MMM-YYYY');

  const dateTime = dayJsDate.format();

  // Label should show local format date but using - instand of /
  const label = dayJsDate.format(format).replace(/\//g, '-');

  return { dateTime, dayJsDate, label };
};

const Time = styled.time<{ color: string }>`
  color: ${({ color }) => color};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Timestamp: React.FC<TimestampProps> = ({
  color = 'initial',
  date,
  format = 'll',
}) => {
  const validDate = moment(date);
  if (!validDate.isValid()) return null;

  const { dateTime, label } = getFormattedDate(date, format);
  return (
    <Time color={color} dateTime={dateTime}>
      {label}
    </Time>
  );
};
