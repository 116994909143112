import React, { PropsWithChildren, useContext, useState } from 'react';

import { TemplateService, TemplateType } from '@pro4all/graphql';

type MetaDataContextValue = {
  displayNames: boolean;
  numberOfTemplatesAlreadyImported: number;
  numberOfTemplatesForImport: number;
  setDisplayNames: (displayNames: boolean) => void;
  setNumberOfTemplatesAlreadyImported: (value: number) => void;
  setNumberOfTemplatesForImport: (value: number) => void;
  templateService: TemplateService;
  templateType: TemplateType;
};

const MetaDataContext = React.createContext({
  displayNames: false,
  numberOfTemplatesAlreadyImported: 0,
  numberOfTemplatesForImport: 0,
  setDisplayNames: null,
  setNumberOfTemplatesAlreadyImported: null,
  setNumberOfTemplatesForImport: null,
  templateService: null,
  templateType: null,
});

export function useMetaDataContext() {
  return useContext<MetaDataContextValue>(MetaDataContext);
}

export const MetaDataContextProvider = ({
  children,
  templateType,
  templateService,
}: PropsWithChildren<{
  templateService: TemplateService;
  templateType: TemplateType;
}>) => {
  const [displayNames, setDisplayNames] = useState(false);
  const [numberOfTemplatesForImport, setNumberOfTemplatesForImport] =
    useState(0);
  const [
    numberOfTemplatesAlreadyImported,
    setNumberOfTemplatesAlreadyImported,
  ] = useState(0);

  const metaDataContextValue = {
    displayNames,
    numberOfTemplatesAlreadyImported,
    numberOfTemplatesForImport,
    setDisplayNames,
    setNumberOfTemplatesAlreadyImported,
    setNumberOfTemplatesForImport,
    templateService,
    templateType,
  };

  return (
    <MetaDataContext.Provider value={metaDataContextValue}>
      {children}
    </MetaDataContext.Provider>
  );
};
