import React from 'react';

/**
 * --- Start of Material-ui temporary workaround ---
 * A MUI Picker does not autoclose when the user clicks outside the picker, but
 * inside the modal. MUI uses its Modal API to render the Drawer in.
 *
 * The workaround is specifically calling blur on the active input. See:
 * https://github.com/mui-org/material-ui-pickers/issues/1852
 *
 * This will be fixed in Material-ui 5.x, but until we migrate (it's in alpha
 * still) this is the temporary fix.
 *
 * TODO: Once we upgrade to Material-ui 5.x we can remove this workaround.
 */
export function handlePickersModalClick<T extends HTMLElement>(
  e: React.MouseEvent<T, MouseEvent>
) {
  if (
    // The click-target has no parent which indicates a Picker
    !e?.currentTarget?.closest('.MuiPickersPopper-paper') &&
    // And the currently focussed element is a Picker
    document.activeElement?.classList.contains('MuiPickersPopper-paper')
  ) {
    // Close it (an active Picker has a blur method).
    (document.activeElement as T).blur();
  }
}
/** --- End of Materiul-ui temporary workaround --- */
