import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Flow2: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M151.858 53.814l.095-.064-.095.07v-.006z"
      fill={colors.secondary}
    />
    <path
      d="M240 88.247c0 2.961-2.34 5.453-5.277 5.453-1.074 0-4.007-.129-4.294-.156h-.122c-15.071-1.495-23.197-11.097-30.463-19.615-2.775-3.251-10.294-16.567-27.833-15.8a24.886 24.886 0 00-14.517 5.219c-.092.115-.176.23-.264.338a44.487 44.487 0 00-5.287 4.888v.129c-1.536 1.65-3.038 3.413-4.602 5.23-7.846 9.162-16.725 19.534-34.149 19.77H111.74l-.797-.024c-15.355-.574-23.93-9.156-31.179-17.498l-.418-.497a208.564 208.564 0 01-1.503-1.748l-.401-.473-.28-.338c-5.892-6.874-11.267-12.97-20.112-14.774l-.372-.077-.189-.038a26.89 26.89 0 00-4.105-.415c-.3 0-.605-.017-.912-.017a5.402 5.402 0 110-10.803h.02a35.584 35.584 0 0114.612 3.045.03.03 0 01.024 0 41.864 41.864 0 019.396 5.871 33.606 33.606 0 01.611.506c.236.2.476.399.709.605 3.515 3.055 6.567 6.462 9.355 9.723 1.688 1.979 3.332 3.893 5.01 5.665 5.575 5.898 11.577 10.257 21.375 10.257 12.752 0 19.072-7.38 26.388-15.922 3.775-4.41 7.785-9.095 12.873-12.796l.095-.07c5.55-3.917 12.346-6.665 21.455-6.665 5.064 0 11.246 1.999 13.052 2.458 9.336 2.384 22.621 18.231 22.621 18.261 6.907 8.04 13.204 14.771 25.321 14.808 3.329 0 5.611 2.462 5.611 5.5z"
      fill={colors.primary}
    />
    <path
      d="M112.89 93.7c-.098 0-1.668-.02-1.931-.03-15.79-.54-23.69-8.81-31.24-17.557-.145-.165-7.704-9.02-10.698-11.617-3.974-3.447-8.846-6.415-17.556-6.685-2.181-.064-3.924-1.053-4.832-2.97 7-4.056 15.17-6.503 19.47-4.795 9.454 3.714 21.061 15.888 24.923 22.185 8.545 13.943 22.283 21.462 22.283 21.462l-.419.007zM234.456 93.7c-.094 0-1.668-.02-1.931-.031-15.79-.54-23.859-8.592-31.037-17.647-.949-1.199-6.583-8.515-10.209-11.594-4.008-3.406-9.474-6.077-18.178-6.33-2.181-.064-3.832-.02-6.327.756 8.005-8.74 16.594-10.517 20.909-8.808 9.453 3.713 20.176 15.783 24.923 22.184 9.74 13.137 22.282 21.463 22.282 21.463l-.432.006z"
      fill={colors.secondary}
    />
    <path
      d="M33.605 48.378a5.83 5.83 0 112.053 11.479l-7.975 1.79a5.83 5.83 0 11-2.052-11.48l7.974-1.789zM8.635 60.67a5.827 5.827 0 016.904 9.386l-6.364 5.139a5.827 5.827 0 01-6.904-9.386l6.364-5.138z"
      fill={colors.primary}
    />
  </SvgIcon>
);
