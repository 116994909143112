import { useTranslation } from 'react-i18next';

import { useAutoFocus } from '@pro4all/shared/hooks';
import { TagList, TextField } from '@pro4all/shared/ui/general';

import * as Styled from './FilterSelect.styles';
import { Footer } from './Footer';
import { FilterComponentTypeProps } from './types';
import { useSelectedOptions } from './useSelectedOptions';
import { useValue } from './useValue';

export const FilterNumber = ({
  getOptions,
  onReset,
  onSet,
}: FilterComponentTypeProps) => {
  const { t } = useTranslation();
  const inputRef = useAutoFocus();

  const { selectedOptions, setSelectedOptions, tags } = useSelectedOptions({
    getOptions,
    onSet,
  });

  const { endAdornment, value, onValueChange, onValueKeyDown } = useValue({
    onSet,
    selectedOptions,
    setSelectedOptions,
  });

  return (
    <>
      <Styled.InputWrap>
        <TextField
          InputProps={value ? { endAdornment } : undefined}
          inputRef={inputRef}
          name="search-input"
          onChange={onValueChange}
          onKeyDown={onValueKeyDown}
          placeholder={t('Type a number')}
          value={value}
        />
      </Styled.InputWrap>
      <Styled.TagListWrap>
        <TagList enableTooltip maxNameLength={24} tags={tags} />
      </Styled.TagListWrap>
      <Footer disableOnReset={Boolean(!tags.length)} onReset={onReset} />
    </>
  );
};
