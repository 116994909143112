import styled from 'styled-components';

import { Button } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

export const BackButton = styled(Button)`
  && {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const FolderName = styled(Text)`
  && {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
