import React from 'react';

import { HierarchicalList } from '@pro4all/graphql';
import { useHierarchicalLists } from '@pro4all/metadata/data-access';
import { useAppPermissions } from '@pro4all/shared/identity';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Loader,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoHierarchicalFields } from '@pro4all/shared/ui/messages';

import { HierarchicalListsActionBar } from '../action-bars/HierarchicalListsActionBar';
import { useColumnsHierarchicalLists } from '../columns/useColumnsHierarchicalLists';

export const ScreenFieldHierarchicalLists = ({
  includeOrgItems = true,
  isProject = false,
}: {
  includeOrgItems?: boolean;
  isProject?: boolean;
}) => {
  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<HierarchicalList>();

  const columns = useColumnsHierarchicalLists();
  const { hasAppPermission } = useAppPermissions();

  const { loading, hierarchicalLists, selectHierarchicalList } =
    useHierarchicalLists({
      includeOrgItems,
    });

  useSetItemsInLocalState<HierarchicalList>(hierarchicalLists);

  if (loading) return <Loader />;

  return (
    <TableContextProvider
      columns={columns}
      id={`table-hierarchical-lists}-${isProject ? 'project' : 'organization'}`}
      items={items}
    >
      <FilterContextProvider<HierarchicalList>>
        <>
          <HierarchicalListsActionBar includeOrgItems={includeOrgItems} />
          {!items?.length && !itemsInitial?.length ? (
            <BigMessageNoHierarchicalFields />
          ) : (
            <Table<HierarchicalList>
              onRowClick={(row) =>
                hasAppPermission(
                  isProject ? 'ProjectUpdate' : 'OrganizationUpdate'
                ) && selectHierarchicalList(row)
              }
            />
          )}
        </>
      </FilterContextProvider>
    </TableContextProvider>
  );
};
