import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { InfoPage } from '@pro4all/shared/ui/infopages';

export const PageNoProjectAccess = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const description = (
    <Trans
      defaults="We can help you in another direction, though.<br /> Where do you want to go?"
      i18nKey="BigMessage.notFound"
    />
  );

  const actions: ActionProps[] = [
    {
      ariaLabel: 'My projects',
      color: 'inherit',
      dataTestId: 'my-projects',
      key: 'my-projects',
      label: t('My projects'),
      onClick: () => goTo('myOverview'),
      startIcon: 'project',
    },
    {
      ariaLabel: 'My tasks',
      color: 'inherit',
      dataTestId: 'my-tasks',
      key: 'my-tasks',
      label: t('My tasks'),
      onClick: () => goTo('myTasks'),
      startIcon: 'barChart',
    },
    {
      ariaLabel: 'Documents',
      color: 'inherit',
      dataTestId: 'documents',
      key: 'documents',
      label: t('Documents'),
      onClick: () => goTo('baseDocs'),
      startIcon: 'file',
    },
  ];

  return (
    <InfoPage
      actions={actions}
      description={description}
      title={t('No project access')}
      type="error"
    />
  );
};
