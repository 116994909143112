import { useRouting } from '@pro4all/shared/routing-utils';

import type { StamperDocument } from './store';

export function useGotoStamper(queue: StamperDocument[]) {
  const { goTo } = useRouting();

  return () => {
    if (!queue.length) return;

    goTo({
      searchParams: { action: 'stampDocuments' },
      state: { queue },
    });
  };
}
