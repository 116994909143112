import { client } from '@pro4all/authentication/src/graph-ql';
import {
  ReportConfiguration,
  ReportConfigurationsIncludeDocument,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { includePropsReportConfigurations } from './includePropsReportConfigurations';

export const useApolloCacheHelpersReportConfigurations = () => {
  const {
    params: { projectId },
  } = useRouting();

  const variables = {
    ...includePropsReportConfigurations,
    projectId: projectId || '',
  };

  const getCurrentlyCachedReportConfigurations = () => {
    const cachedReportConfigurations = client?.readQuery({
      query: ReportConfigurationsIncludeDocument,
      variables,
    }).reportConfigurations as ReportConfiguration[];
    return cachedReportConfigurations;
  };

  const updateCachedReportConfigurations = (
    updatedReportConfigurations: ReportConfiguration[]
  ) => {
    client.writeQuery({
      data: {
        reportConfigurations: updatedReportConfigurations,
      },
      query: ReportConfigurationsIncludeDocument,
      variables,
    });
  };

  return {
    getCurrentlyCachedReportConfigurations,
    updateCachedReportConfigurations,
  };
};
