import { SearchEntities } from '@pro4all/shared/search-utils';

import { SavedSearchScope } from './form/SaveQueryForm.types';

export const calculateScope = (scope: SearchEntities): SavedSearchScope => {
  const scopeValue =
    scope === SearchEntities.QualityControl
      ? SavedSearchScope.QC
      : SavedSearchScope.Document;
  return scopeValue;
};
