import { v4 as uuid } from 'uuid';

import { Option } from '@pro4all/shared/types';

// splitUnique(nextValue, prevValue, callback, pattern = '[ ,;]')

export function splitEmailAddress(
  value: string,
  prevValue: Option[],
  callback: (values: Option[]) => void
) {
  const regexPattern = /[\s,;]/;
  if (!regexPattern.test(value)) return;

  // Return true if address already exists in field
  const findDuplicate = (value: string) =>
    prevValue.find((input) => input.inputValue === value);

  // Remove duplicates from pasted text
  const toUniqueArray = (addresses: string[]) => [...new Set(addresses)];

  // Pasted text is parsed as addresses delimited by whitespace, semi colon or comma
  const uniqueValues = toUniqueArray(
    value
      ?.split(regexPattern)
      .filter(Boolean)
      .filter((value) => !findDuplicate(value))
  );

  const nextValue: Option[] = uniqueValues.map((value) => ({
    id: uuid(),
    inputValue: value,
    label: value,
  }));

  callback([...prevValue, ...nextValue]);
}
