import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SegmentType } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { sortBy } from '@pro4all/shared/utils';

import { useFieldContext } from '../TemplateMutationContext';

import { AvailableMetaDataFieldCard } from './AvailableMetaDataFieldCard';
import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import { LabelCardType } from './configureDownloadNameReducer';
import * as Styled from './Styles';

export const AvailableMetaDataFields = () => {
  const { t } = useTranslation();

  const { fieldDefinitions, reusableFields, reusableFieldsStandard } =
    useFieldContext();

  const {
    state: { availableMetaDataFields, segments },
    setAvailableMetaDataFields,
  } = useConfigureDownloadNameContext();

  const inlineFields = fieldDefinitions.filter(
    (fieldDefinition) => !fieldDefinition.fieldDefinitionId
  );

  // Build a collection of:
  // 1. Standard fields.
  // 2. Custom reusable fields.
  // 3. Custom inline fields.

  // We have to add the correct segmentType to all different field sources.
  const inlineFieldsWithSegmentType = inlineFields.map((field) => ({
    ...field,
    segmentType: SegmentType.CustomInline,
  }));
  const reusableFieldsWithSegmentType = reusableFields.map((field) => ({
    ...field,
    segmentType: SegmentType.CustomReusable,
  }));
  const reusableFieldsStandardWithSegmentType = reusableFieldsStandard.map(
    (field) => ({
      ...field,
      segmentType: SegmentType.Standard,
    })
  );

  const showDisplayName = localStorage.getItem(
    StorageKeys.TEMPLATE_DISPLAY_NAME
  );

  // Set available meta data fields.
  useEffect(() => {
    const availableMetaDataFieldsUpdated: LabelCardType[] = [
      ...inlineFieldsWithSegmentType,
      ...reusableFieldsWithSegmentType,
      ...reusableFieldsStandardWithSegmentType,
    ]
      .map((field) => ({
        id: field.id,
        label:
          showDisplayName === 'true'
            ? field.displayName || field.name
            : field.name,
        segmentType: field.segmentType,
        type: field.type,
      }))
      .sort(sortBy({ key: 'label' }));
    setAvailableMetaDataFields(availableMetaDataFieldsUpdated);
  }, []);

  // Only include fields that are selected in the template.
  // Also preserve the order of the fields as in the template.
  const selectableFields =
    availableMetaDataFields.length > 0
      ? fieldDefinitions.map((fieldDefinition) => {
          const field = availableMetaDataFields.find(
            (availableField) =>
              availableField.id ===
              (fieldDefinition.fieldDefinitionId || fieldDefinition.id)
          );
          return field;
        })
      : [];

  // Only include fields that are not already selected.
  const selectableFieldsNotSelected = selectableFields
    .filter(Boolean) // Could be that the field definition has been deleted
    .filter(
      (field) => segments.findIndex((segment) => segment.id === field.id) === -1
    );

  return (
    <Styled.Container>
      <Styled.Header variant="h6">
        {t('Available meta data fields')}
      </Styled.Header>
      <Styled.FieldContainer>
        {selectableFieldsNotSelected.map((field) => (
          <AvailableMetaDataFieldCard field={field} key={field.id} />
        ))}
      </Styled.FieldContainer>
    </Styled.Container>
  );
};
