import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

import { useSubscriptionHelpers } from './hooks/useSubscriptionHelpers';
import * as Styled from './styles/Dashboard.styles';

export const Dashboard = () => {
  const { t } = useTranslation();

  const {
    getExpireDate,
    getPricePerUnit,
    getUniqueActiveUsers,
    isExpired,
    isFixedPrice,
  } = useSubscriptionHelpers();
  const expireDate = getExpireDate();
  const subscriptionLevelPrice = getPricePerUnit();
  const uniqueActiveUsers = getUniqueActiveUsers();

  return expireDate ? (
    <Box display="flex">
      {!isExpired ? (
        <Styled.Card>
          <Styled.Amount variant="h2">
            {`€ ${subscriptionLevelPrice?.toLocaleString()}`}
          </Styled.Amount>
          <Styled.AmountText>
            <Text variant="body2">
              {!isFixedPrice ? t('/ user / month') : t('Per month')}
            </Text>
          </Styled.AmountText>
        </Styled.Card>
      ) : (
        <Styled.Card $isExpired>
          <Styled.Users variant="h2">
            <Styled.GroupIcon iconName="eventBusy" />
            <div>{t('Expired')}</div>
          </Styled.Users>
          <Styled.UsersText>
            <Text variant="body2">{t('Per month')}</Text>
          </Styled.UsersText>
        </Styled.Card>
      )}

      {!isFixedPrice ? (
        <Styled.Card>
          <Styled.Users>
            <Styled.GroupIcon iconName="group" />
            {uniqueActiveUsers && (
              <div>{uniqueActiveUsers.toLocaleString()}</div>
            )}
          </Styled.Users>
          <Styled.UsersText>
            <Text variant="body2">{t('Users')}</Text>
          </Styled.UsersText>
        </Styled.Card>
      ) : null}
    </Box>
  ) : null;
};
