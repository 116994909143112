import {
  Action as PdftronAction,
  Actions as PdftronActions,
  State as PdftronState,
  Status as PdftronStatus,
} from '../pdf-tron-utils/pdftronReducer';
import {
  Action as VersionsAction,
  Actions as VersionsActions,
  State as VersionsState,
  Status as VersionsStatus,
} from '../version-utils/versionsReducer';

export const useVersionPanelUtils = ({
  versionsDispatch,
  versionsState,
  pdftronDispatch,
  pdftronState,
  redrawPanel,
}: {
  pdftronDispatch: React.Dispatch<PdftronAction>;
  pdftronState: PdftronState;
  redrawPanel: () => void;
  versionsDispatch: React.Dispatch<VersionsAction>;
  versionsState: VersionsState;
}) => {
  const setBaseVersion = ({ versionId }: { versionId: string }) => {
    pdftronDispatch({
      payload: { versionId },
      type: PdftronActions.SET_BASE_VERSION_ID,
    });
  };

  const setOverlayVersion = ({ versionId }: { versionId: string }) => {
    pdftronDispatch({
      payload: { versionId },
      type: PdftronActions.SET_OVERLAY_VERSION_ID,
    });
  };

  const setPdftronStatus = (status: PdftronStatus) => {
    pdftronDispatch({
      payload: { status },
      type: PdftronActions.SET_STATUS,
    });
  };

  const setVersionStatus = ({
    versionId,
    status,
  }: {
    status: VersionsStatus;
    versionId: string;
  }) => {
    versionsDispatch({
      payload: { status, versionId },
      type: VersionsActions.SET_STATUS,
    });
  };

  const handleBaseVersionChange = (versionId: string) => {
    const version = versionsState[versionId];
    setPdftronStatus(PdftronStatus.DOCUMENT_SWITCH);
    setBaseVersion({ versionId });

    if (versionId === pdftronState.overlayVersionId) {
      /**
       * New base version was our previous overlay version,
       * so we reset the overlay version
       */
      setOverlayVersion({ versionId: '' });
    }

    /**
     * A sync is needed if the version we are loading now was not
     * synced before, otherwise a render is enough
     */
    setVersionStatus({
      status:
        version.status === VersionsStatus.INITIAL
          ? VersionsStatus.SYNC_REQUIRED
          : VersionsStatus.PENDING_RENDER,
      versionId,
    });
    updateOtherVersions({ excludeIds: [versionId] });
    setPdftronStatus(PdftronStatus.DOCUMENT_NOT_LOADED);
  };

  const handleOverlayVersionChange = (versionId: string) => {
    const version = versionsState[versionId];

    setPdftronStatus(PdftronStatus.DOCUMENT_SWITCH);
    setOverlayVersion({ versionId });
    setVersionStatus({
      status:
        version?.status === VersionsStatus.INITIAL
          ? VersionsStatus.SYNC_REQUIRED
          : VersionsStatus.PENDING_RENDER,
      versionId: versionId || pdftronState.baseVersionId,
    });
    updateOtherVersions({ excludeIds: [versionId] });
    setPdftronStatus(PdftronStatus.DOCUMENT_NOT_LOADED);
  };

  const updateOtherVersions = ({ excludeIds }: { excludeIds: string[] }) => {
    Object.values(versionsState).forEach((version) => {
      if (excludeIds.includes(version.id)) return;
      /** All versions that were previously rendered need to be rerendered */
      if (version.status === VersionsStatus.SYNCED) {
        setVersionStatus({
          status: VersionsStatus.PENDING_RENDER,
          versionId: version.id,
        });
      }
    });
  };

  const handleToggleAnnotation = (versionId: string) => {
    versionsDispatch({
      payload: { versionId },
      type: VersionsActions.TOGGLE_SHOW_ANNOTATIONS,
    });
    redrawPanel();
  };

  return {
    handleBaseVersionChange,
    handleOverlayVersionChange,
    handleToggleAnnotation,
  };
};
