import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useDocumentsContext } from '@pro4all/documents/ui/share';
import { DocumentVersion } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/general';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useGetFolder } from './hooks/useGetFolder';
import { useOnToggleVersionPane } from './hooks/useOnToggleVersionPane';
import * as Styled from './VersionPane.styles';
import { VersionTable } from './VersionTable';

export const VersionPane = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { expanded, onExpand } = useOnToggleVersionPane();
  const { setVersionPaneExpanded } = useDocumentsContext();
  const { searchParams } = useRouting();

  const isMobileScreen = useIsMobileScreen();
  const { currentView } = useDataViewContext();

  // Info used for tracking
  const { userId } = AuthService.getProfile();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { folder } = useGetFolder();

  const toggleVersionPane = () => {
    const eventInfo = {
      folderId: folder?.id,
      organizationId,
      projectId: folder?.projectId,
      userId,
    };

    track(
      expanded
        ? TrackingEvent.VersionPanelClosed
        : TrackingEvent.VersionPanelOpened,
      eventInfo
    );

    searchParams.delete('versionid');
    onExpand(!expanded);
  };

  useEffect(
    () =>
      expanded ? setVersionPaneExpanded(true) : setVersionPaneExpanded(false),
    [expanded, setVersionPaneExpanded]
  );

  return (
    <OptimisticResponseProvider<DocumentVersion>>
      {!isMobileScreen && currentView === DataViewType.Table && (
        <Styled.Container>
          <Styled.Header>
            <IconButton
              color="default"
              disableBorder
              iconName={expanded ? 'expandMore' : 'expandLess'}
              onClick={toggleVersionPane}
            />
            <Styled.HeaderText onClick={toggleVersionPane} variant="h4">
              {t('Version control')}
            </Styled.HeaderText>
          </Styled.Header>
          <Styled.VersionPaneWrapper $expanded={expanded}>
            <VersionTable expanded={expanded} />
          </Styled.VersionPaneWrapper>
        </Styled.Container>
      )}
    </OptimisticResponseProvider>
  );
};
