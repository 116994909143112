import { Redirect, Route, Switch } from 'react-router-dom';

import {
  PrivateRoute,
  Routes as AuthRoutes,
} from '@pro4all/authentication/src/components';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { PubliclySharedMessages } from '@pro4all/communication/ui/messages';
import { OfficeHostPage } from '@pro4all/documents/ui/office';
import { PubliclySharedDocuments } from '@pro4all/documents/ui/views';
import { Project } from '@pro4all/graphql';
import { OrganizationRoutes } from '@pro4all/organization/config';
import { Organization, OrganizationNavigation } from '@pro4all/organization/ui';
import { ProjectRoutes } from '@pro4all/projects/config';
import { ProjectContextProvider } from '@pro4all/projects/ui/context';
import { Projects } from '@pro4all/projects/ui/views';
import {
  ConfirmEmail,
  FinalizeInvitation,
  Registration,
} from '@pro4all/registration/ui';
import { MoveUser } from '@pro4all/settings/ui';
import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { generateRoute } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/general';
import {
  Page404Authenticated,
  Page404NotAuthenticated,
} from '@pro4all/shared/ui/page-404';

export const AppRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to={generateRoute('myProjects')} />
    </Route>

    <Route exact path={Routes.registration}>
      <Registration />
    </Route>

    <Route path={Routes.confirmEmail}>
      <ConfirmEmail />
    </Route>

    <Route exact path={Routes.finalizeInvitation}>
      <FinalizeInvitation />
    </Route>

    <Route exact path={Routes.publiclySharedDocs}>
      <PubliclySharedDocuments />
    </Route>

    <Route exact path={Routes.publicAttachments}>
      <PubliclySharedMessages />
    </Route>

    <PrivateRoute exact path={Routes.moveUser}>
      <MoveUser />
    </PrivateRoute>

    <PrivateRoute exact path={ProjectRoutes}>
      <ProjectContextProvider>
        <OptimisticResponseProvider<Project>>
          <Projects />
        </OptimisticResponseProvider>
      </ProjectContextProvider>
    </PrivateRoute>

    <PrivateRoute exact path={OrganizationRoutes}>
      <Organization />
    </PrivateRoute>

    <PrivateRoute exact path={Routes.revokePublicLink}>
      <PubliclySharedDocuments />
    </PrivateRoute>

    <PrivateRoute exact path={Routes.office}>
      <OfficeHostPage />
    </PrivateRoute>

    <Route
      exact
      path={[
        '/signin',
        '/signin-oidc',
        '/logout',
        '/logout-callback',
        '/silentrenew',
      ]}
    >
      <AuthRoutes />
    </Route>

    <Route>
      {AuthService.isAuthenticated() ? (
        <Box display="flex" height="100%">
          <OrganizationNavigation orgName="" />
          <Page404Authenticated />
        </Box>
      ) : (
        <Page404NotAuthenticated />
      )}
    </Route>
  </Switch>
);
