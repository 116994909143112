import { SegmentType, ValueTypeName } from '@pro4all/graphql';

export enum ActionType {
  INSERT_SEGMENT = 'INSERT_SEGMENT',
  REMOVE_SEGMENT = 'REMOVE_SEGMENT',
  RESET_SEGMENTS = 'RESET_SEGMENTS',
  SET_AVAILABLE_METADATA_FIELDS = 'SET_AVAILABLE_METADATA_FIELDS',
  SET_ENABLED = 'SET_ENABLED',
  SET_SEGMENTS = 'SET_SEGMENTS',
}

export type Segment = {
  deleteId: string;
  id: string;
  type: SegmentType;
};

export type LabelCardType = {
  id: string;
  label: string;
  segmentType?: SegmentType;
  type: ValueTypeName;
};

export type PayloadInsert = {
  droppedOnId?: string;
  segment: Segment;
};

export type State = {
  availableMetaDataFields: LabelCardType[];
  enabled: boolean;
  segments: Segment[];
  segmentsInitial: Segment[];
};

type ActionBoolean = {
  payload: boolean;
  type: ActionType.SET_ENABLED;
};

type ActionInsertSegment = {
  payload: PayloadInsert;
  type: ActionType.INSERT_SEGMENT;
};

type ActionRemoveSegment = {
  payload: string;
  type: ActionType.REMOVE_SEGMENT;
};

type ActionResetSegments = {
  type: ActionType.RESET_SEGMENTS;
};

type ActionSetAvailableMetaDataFields = {
  payload: LabelCardType[];
  type: ActionType.SET_AVAILABLE_METADATA_FIELDS;
};

type ActionSetSegments = {
  payload: Segment[];
  type: ActionType.SET_SEGMENTS;
};

type Action =
  | ActionBoolean
  | ActionInsertSegment
  | ActionRemoveSegment
  | ActionResetSegments
  | ActionSetAvailableMetaDataFields
  | ActionSetSegments;

export function configureDownloadNameReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.INSERT_SEGMENT: {
      const { segment, droppedOnId } = action.payload;

      // To prevent duplicates in case we drag and drop a segment within the example section, we first filter out the dropped segment.
      const segments = [...state.segments].filter(
        (segmentState) => segmentState.deleteId !== segment.deleteId
      );

      if (droppedOnId) {
        // Dropped on a segment. Appended before the segment where it is dropped on.
        const index = segments.findIndex(
          (segment) => segment.deleteId === droppedOnId
        );
        segments.splice(index, 0, segment);
      } else {
        // Dropped on the example section. Appended to the end.
        segments.push(segment);
      }

      return {
        ...state,
        segments,
      };
    }

    case ActionType.REMOVE_SEGMENT: {
      return {
        ...state,
        segments: state.segments.filter(
          (segment) => segment.deleteId !== action.payload
        ),
      };
    }

    case ActionType.RESET_SEGMENTS: {
      return {
        ...state,
        segments: [...state.segmentsInitial],
      };
    }

    case ActionType.SET_AVAILABLE_METADATA_FIELDS: {
      return {
        ...state,
        availableMetaDataFields: action.payload,
      };
    }

    case ActionType.SET_ENABLED: {
      return {
        ...state,
        enabled: action.payload,
      };
    }

    case ActionType.SET_SEGMENTS: {
      return {
        ...state,
        segments: action.payload,
        segmentsInitial: action.payload,
      };
    }

    default:
      return state;
  }
}
