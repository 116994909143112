import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const TbqResolveTask: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="25"
      viewBox="0 0 20 20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.2999 1.8H11.5379C11.1599 0.756 10.1699 0 8.9999 0C7.8299 0 6.8399 0.756 6.4619 1.8H2.6999C1.7099 1.8 0.899902 2.61 0.899902 3.6V16.2C0.899902 17.19 1.7099 18 2.6999 18H15.2999C16.2899 18 17.0999 17.19 17.0999 16.2V3.6C17.0999 2.61 16.2899 1.8 15.2999 1.8ZM8.99999 1.80002C9.49499 1.80002 9.89999 2.20502 9.89999 2.70002C9.89999 3.19502 9.49499 3.60002 8.99999 3.60002C8.50499 3.60002 8.09999 3.19502 8.09999 2.70002C8.09999 2.20502 8.50499 1.80002 8.99999 1.80002ZM5.98412 8.64201L8.99836 6.98793L11.8747 8.56632L13.6797 7.56889L8.99836 5L4.17913 7.64457L5.98412 8.64201ZM12.2073 12.347V8.92763L13.9999 7.9267V13.341L9.20454 16L9.19828 14.0155L12.2073 12.347ZM5.79243 12.3673L8.71269 13.9866L8.71897 15.978L3.99988 13.3613V8.09887L5.79243 9.0998V12.3673Z"
        fill="#212121"
        fillRule="evenodd"
      />
    </svg>
  </SvgIcon>
);
