import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { useUploadSpreadsheetContext } from '../ImportSpreadsheetContext';

export const useImportFolderSpreadsheetAction = (folder?: Folder) => {
  const { t } = useTranslation();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const { startImportProcess } = useUploadSpreadsheetContext();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const { projectId } = useRouting().params;

  const importFolderSpreadsheetAction: ActionProps = {
    ariaLabel: 'Import folder spreadsheet',
    dataTestId: 'import-folder',
    disabled: folder && !hasFolderPermission('UpdateFolder'),
    key: 'import-folder',
    label: t('Import folder spreadsheet'),
    onClick: () =>
      startImportProcess({ folderId: folder?.id, organizationId, projectId }),
    startIcon: 'importExport',
  };

  return {
    importFolderSpreadsheetAction,
  };
};
