import styled from 'styled-components';

import { Tag as UITag } from '@pro4all/shared/ui/general';

export const Tag = styled(UITag)`
  && {
    &.MuiChip-root {
      display: flex;
      margin-right: 0.5rem;
      text-decoration: line-through;
      &.MuiChip-colorSecondary {
        text-decoration: unset;
      }
      &:not(:last-of-type) {
        margin-bottom: 0.25rem;
      }
    }
  }
`;

export const PropertyValues = styled.div`
  display: flex;
  margin-top: -0.25rem;
  flex-wrap: wrap;
  flex: 2;
  min-width: 0;

  > * {
    margin-top: 0.25rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
