import { getExclusivePermissionsBasedOnParentPermissions } from '../../shared/helpers/getExclusivePermissionsBasedOnParentPermissions';
import { getExclusivePermissionsToUse } from '../../shared/helpers/getExclusivePermissionsToUse';
import { permissionsToRemove } from '../../shared/helpers/permissionsToRemove';
import { getInheritedFromFolderPermissions } from '../helpers/getInheritedFromFolderPermissions';
import { getThisEntityPermission } from '../helpers/getThisEntityPermission';
import { getUpdatedSaveAndDisplayPermissions } from '../helpers/getUpdatedSaveAndDisplayPermissions';
import { EntityPermissionSet, State } from '../types';

export const setPermissionAction = ({
  payload,
  state,
}: {
  payload: EntityPermissionSet;
  state: State;
}) => {
  const { exclusivePermissionType, entityId, permission } = payload;

  const thisEntityPermission = getThisEntityPermission(
    entityId,
    state.displayPermissions
  );

  const exclusivePermissionsToUse = getExclusivePermissionsToUse(
    exclusivePermissionType
  );

  let permissions = thisEntityPermission
    ? [
        ...new Set([
          ...exclusivePermissionsToUse,
          ...thisEntityPermission.folderPermissions,
        ]),
      ].filter((perm) => !permissionsToRemove.get(permission).includes(perm))
    : [...new Set([...exclusivePermissionsToUse])].filter(
        (perm) => !permissionsToRemove.get(permission).includes(perm)
      );

  if (
    !thisEntityPermission.breakInheritance &&
    thisEntityPermission.inheritedFromParentFolders.length > 0
  ) {
    // In case of a subfolder there might be inheritance from the parent which may force the selected permission to be reverted.

    const parentFolderPermissions = getInheritedFromFolderPermissions({
      entityPermission: thisEntityPermission,
    });
    const { subFolderPermissions } =
      getExclusivePermissionsBasedOnParentPermissions({
        currentSubFolderPermissions: thisEntityPermission.folderPermissions,
        parentFolderPermissions,
        subFolderPermissions: permissions,
      });

    permissions = subFolderPermissions;
  }

  const updatedFolderPermission = {
    ...thisEntityPermission,
    folderPermissions: permissions,
  };

  const { savePermissionsUpdated, displayPermissionsUpdated } =
    getUpdatedSaveAndDisplayPermissions({
      includeInherited: false,
      state,
      updatedFolderPermission,
    });

  return {
    ...state,
    displayPermissions: displayPermissionsUpdated,
    savePermissions: savePermissionsUpdated,
  };
};
