import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

export const StyledSection = styled.div`
  &:hover {
    cursor: grab;
  }
`;

export const Header = styled.div<{
  expanded: boolean;
  isReusable: boolean;
}>`
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ isReusable }) =>
    isReusable ? customColors.grey200 : customColors.grey300};
  border: 1px solid ${customColors.grey300};
  border-radius: ${({ expanded }) => (expanded ? '5px 5px 0px 0px' : '5px')};
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${customColors.grey300};
  border-radius: 0px 0px 10px 10px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  vertical-align: -8px;
  padding-right: 2px;
`;

export const ExpandCollapse = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)};
  &:hover {
    cursor: pointer;
  }
`;
