import dayjs from 'dayjs';

import { AuthenticatedApiService } from '@pro4all/authentication/src/services/authenticated-api-service';
import { ApiConfig } from '@pro4all/shared/config';

import { DownloadProps } from '../types';

export const RendererBaseURL = ApiConfig.reportRenderer;

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(RendererBaseURL);
  }

  downloadReport = async (
    { lang = 'en-US', ...data }: DownloadProps,
    callback: () => void
  ) =>
    this.api.download({
      callback,
      fileName: `${
        data.projectName ? data.projectName : 'Prostream-Quality'
      }-${dayjs().format('MM-DD-YYYY')}-report.pdf`,
      options: { data, method: 'POST', timeout: 0 },
      url: `/download?lang=${lang}`,
    });
}

export const ReportRenderService = new Service();
