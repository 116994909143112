import { AuthenticatedApiService } from '@pro4all/authentication/src/services/authenticated-api-service';
import { Folder } from '@pro4all/graphql';

export const SpreadsheetBaseURL =
  'https://documents.pro4all.io/api/v2/Folder/import/folders/paths';

interface ApiError {
  code: string;
  id: string;
  message: string;
}
interface SpreadsheetImportApiResponse {
  errors: ApiError[];
  success: boolean;
}

export interface SpreadsheetApiUploadResponse {
  errorName?: string;
  errorValue?: string;
  success: boolean;
}

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(SpreadsheetBaseURL);
  }

  private validatedExceptions = [
    'IMPORT_SPREADSHEET_NO_COLUMN_ERROR', //i18n
    'IMPORT_SPREADSHEET_MAX_WORD_LENGTH_EXCEEDED_ERROR', //i18n
    'IMPORT_SPREADSHEET_INVALID_CHARACTER_ERROR', //i18n
  ];

  private parseExceptions = (
    apiError: ApiError
  ): SpreadsheetApiUploadResponse => {
    if (
      !this.validatedExceptions.some((exception) =>
        apiError.message.includes(exception)
      )
    ) {
      return {
        success: false,
      };
    }
    const semicolonIndex = apiError.message.indexOf(':');
    const errorName = this.validatedExceptions.find((exception) =>
      apiError.message.includes(exception)
    );
    let errorValue = null;
    if (semicolonIndex > 0) {
      errorValue = apiError.message.substring(semicolonIndex + 1);
    }
    return {
      errorName,
      errorValue,
      success: false,
    };
  };

  downloadFolderZip = async ({
    folder,
    url,
  }: {
    folder: Folder;
    url: string;
  }) => {
    this.api.download({ fileName: `Folder - ${folder.name}.zip`, url });
  };

  uploadFolderStructure = async ({
    targetFolderId,
    targetOrganizationId,
    targetProjectId,
    file,
  }: {
    file: File;
    targetFolderId: string;
    targetOrganizationId: string;
    targetProjectId?: string;
  }): Promise<SpreadsheetApiUploadResponse> => {
    const params = new URLSearchParams();
    params.append('targetOrganizationId', targetOrganizationId);
    if (targetFolderId) {
      params.append('targetFolderId', targetFolderId);
    }
    if (targetProjectId) {
      params.append('targetProjectId', targetProjectId);
    }

    const url = `${SpreadsheetBaseURL}?${params.toString()}`;
    try {
      const response =
        await this.api.uploadFileWithExceptionHandling<SpreadsheetImportApiResponse>(
          {
            file,
            url,
          }
        );
      if (!response.success) {
        return this.parseExceptions(response.errors[0]);
      }
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
      };
    }
  };
}

export const FolderService = new Service();
