import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const List = styled.ul`
  padding: 0;
`;

export const ListItem = styled.li`
  height: 40px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  > span > button {
    visibility: hidden;
  }

  &:hover {
    background-color: ${customColors.grey300};
    > span > button {
      visibility: visible;
    }
  }
`;
