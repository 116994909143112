import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { Keyword } from '@pro4all/graphql';
import { supportedExtensionsAiKeywordGeneration } from '@pro4all/shared/config';
import { DocumentAndVersionType } from '@pro4all/shared/types';

export const toStateKeyword = (keyword: Keyword) => ({
  id: uuid(),
  oldText: keyword?.text as string,
  score: keyword?.score as number,
  text: keyword?.text as string,
});

export const useGetKeywordData = ({
  document,
  version,
}: DocumentAndVersionType) => {
  const keywords = useMemo(
    () => (document ? document.keywords : version ? version.keywords : []),
    [document, version]
  ) as Keyword[];

  const keywordsInitially = useMemo(
    () => keywords?.map(toStateKeyword),
    [keywords]
  );

  const extensions = document
    ? [document?.extension]
    : version
    ? [version?.extension]
    : [];

  // Check if one of the values of the array `extensions` is in the array `supportedExtensionsAiKeywordGeneration`.
  const hasSupportedExtensionToGenerateKeywords = extensions.some((extension) =>
    supportedExtensionsAiKeywordGeneration.includes(extension || '')
  );

  return { hasSupportedExtensionToGenerateKeywords, keywordsInitially };
};
