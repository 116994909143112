import { EntityPermission } from '../../entity-context/types';

export const getInheritedFromFolderPermissions = ({
  entityPermission,
}: {
  entityPermission: EntityPermission;
}) =>
  entityPermission && entityPermission.inheritedFromParentFolders
    ? entityPermission.inheritedFromParentFolders.map(
        (parentFolderPermission) => parentFolderPermission.permission
      )
    : [];
