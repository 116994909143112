import { FieldDefinition, QualityControlInstance } from '@pro4all/graphql';
import { PhotoService } from '@pro4all/quality-control/data-access';
import { FetchArgs } from '@pro4all/shared/types';

// There is a code duplicate in apps/report-service/src/main.tsx because
// sharing logic between these libraries results in a contamination of dependencies

export function getPhotoBlobs(instances: QualityControlInstance[]) {
  const fetchUrl = ({ id, instanceId }: FetchArgs) =>
    PhotoService.getThumbnailUrl({
      id,
      instanceId,
      timeout: 0,
    });

  const keyValue: Record<string, Promise<string>> = {};
  const addUrlToRecord = (value: string, instanceId: string) => {
    const ids = value.split(',') || [value];
    ids.forEach((id) => {
      keyValue[id] = fetchUrl({ id, instanceId });
    });
  };
  const sectionReducer = (instances: FieldDefinition[], instanceId: string) =>
    instances.reduce(
      (record, { valueType: items }) =>
        Object.assign(
          record,
          items?.subFields?.reduce(
            async (prevItems, { id, value, type, valueType, ...rest }) => {
              if (type === 'Section' && valueType && valueType.subFields) {
                sectionReducer(
                  [{ id, type, value, valueType, ...rest }],
                  instanceId
                );
              }
              if (type !== 'Media' || !value) {
                return null;
              }

              addUrlToRecord(value, instanceId);

              return null;
            },
            record
          )
        ),
      {}
    );
  const collectPromises = (instances: QualityControlInstance[]) =>
    instances.reduce(
      (record, { id: instanceId, items }) =>
        Object.assign(
          record,
          items?.reduce(async (prevItems, { value, type }) => {
            if (type === 'Section') {
              sectionReducer(items, instanceId);
            }
            if (type !== 'Media' || !value) {
              return null;
            }

            addUrlToRecord(value, instanceId);

            return null;
          }, record)
        ),
      {}
    );
  return async () => {
    collectPromises(instances);

    const promises = Object.values(keyValue);
    const keys = Object.keys(keyValue);
    const result: { [x: string]: string } = {};

    await Promise.all(promises)
      .then((resolvedBlobs) => {
        keys.forEach((key, index) => {
          result[key] = resolvedBlobs[index];
        });
      })
      .catch((error) => console.error(`Failed to fetch image blob: ${error}`));

    return result;
  };
}
