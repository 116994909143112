import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { PropertyList } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { useChangePaymentMethod } from './hooks/useChangePaymentMethod';
// import { useInvoiceAddressProperties } from './useInvoiceAddressProperties'; //TODO: Should be included initially, but decided later on to postpone.
import { useInvoiceDataProperties } from './hooks/useInvoiceDataProperties';
import {
  SubscriptionStatus,
  useSubscriptionHelpers,
} from './hooks/useSubscriptionHelpers';
import * as StyledShared from './styles/Shared.styles';
import { useSubscriptionContext } from './SubscriptionContext';

export const InvoiceData = () => {
  const { t } = useTranslation();

  const { dataOrg } = useSubscriptionContext();

  const propertiesInvoiceAddress = useInvoiceDataProperties();
  // const propertiesInvoiceData = useInvoiceAddressProperties(); //TODO: Should be included initially, but decided later on to postpone.

  const { getCurrentSubscriptionStatus, isExpired } = useSubscriptionHelpers();
  const isTrial = getCurrentSubscriptionStatus() === SubscriptionStatus.Trial;

  const onChangePaymentMethod = useChangePaymentMethod();

  return dataOrg ? (
    <Box maxWidth="435px">
      <Box mb={4}>
        <StyledShared.TitleWrapper>
          <StyledShared.TitleBox>
            <Text variant="h4">{t('Invoice data')}</Text>
          </StyledShared.TitleBox>
          {!isTrial && !isExpired && (
            <StyledShared.TitleBox>
              <Button
                data-testid="change-invoice-data"
                onClick={onChangePaymentMethod}
                startIcon="edit"
              >
                {t('Change')}
              </Button>
            </StyledShared.TitleBox>
          )}
        </StyledShared.TitleWrapper>
        <PropertyList items={propertiesInvoiceAddress} />
      </Box>

      {/* TODO: Should be included initially, but decided later on to postpone. */}
      {/* <StyledShared.TitleWrapper>
        <StyledShared.TitleBox>
          <Text>{t('Invoice address')}</Text>
        </StyledShared.TitleBox>
        <StyledShared.TitleBox>
          <Button
            data-testid="change-invoice-address"
            onClick={() => console.log('change invoice address')}
            startIcon="edit"
          >
            {t('Change')}
          </Button>
        </StyledShared.TitleBox>
      </StyledShared.TitleWrapper>
      <PropertyList items={propertiesInvoiceData} /> */}
    </Box>
  ) : null;
};
