import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export function BigMessageNoDocumentsSelected<T extends React.ReactElement>({
  anchor = null,
}: {
  anchor: T | null;
}) {
  const { t } = useTranslation();

  if (!anchor) return null;

  return (
    <BigMessage
      shapeName="fun"
      text={
        <Trans
          components={{ anchor }}
          defaults="Go back to <anchor>the folder</anchor> and select some documents to edit."
          i18nKey="BigMessage.noDocumentsSelected"
        />
      }
      title={t('No documents to edit')}
    />
  );
}
