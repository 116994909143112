import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAppPermissions } from '@pro4all/shared/identity';
import { generateRoute } from '@pro4all/shared/routing-utils';

import * as Styled from './SubscriptionCard.styles';

export const SubscriptionCardNoActive = () => {
  const { t } = useTranslation();

  const { hasAppPermission } = useAppPermissions();
  const userCanUpdateOrg = hasAppPermission('OrganizationUpdate');

  return (
    <Styled.Container>
      <Styled.StyledH2>{t('No active')}</Styled.StyledH2>
      <Styled.StyledH2>{t('license')}</Styled.StyledH2>

      {userCanUpdateOrg && (
        <>
          <Styled.StyledH5>{t('Get back to work')}</Styled.StyledH5>
          <Link to={generateRoute('settingsOrganizationLicense')}>
            <Styled.Link>{t('(Re)activate now')}</Styled.Link>
          </Link>
        </>
      )}
    </Styled.Container>
  );
};
