import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@pro4all/shared/mui-wrappers';

interface ItemChangedMessageProps {
  action?: React.ReactNode;
  description?: string;
  entityName?: string;
  entityTypeTranslation?: string;
  newName?: string;
}

// enqueueSnackbar with this component if names with variable lengths need to be displayed
export const ItemChangedMessage: React.FC<ItemChangedMessageProps> = ({
  description = '',
  entityName,
  entityTypeTranslation = '',
  newName,
  action,
  children,
}) => {
  const { t } = useTranslation();
  if (children) {
    return <Alert action={action}>{children}</Alert>;
  }
  return (
    <Alert action={action}>
      {`${t(entityTypeTranslation)} ${t(description, {
        entityName,
        newName,
      })}`}
    </Alert>
  );
};
