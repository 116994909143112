import { useEffect } from 'react';

import {
  Member,
  useFolderWithPermissionsByPathQuery,
  useGroupsIncludeQuery,
  useProjectMembersIncludeQuery,
  useUsersIncludeQuery,
} from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';
import { useRouting } from '@pro4all/shared/routing-utils';

export enum MemberDefinition {
  ApiOrgGroup = 'ApiOrgGroup',
  ApiOrgMember = 'ApiOrgMember',
  ApiProjectGroup = 'ApiProjectGroup',
  ApiProjectMember = 'ApiProjectMember',
}

export const useFolderPermissions = (isGroup?: boolean) => {
  const { params } = useRouting();
  const projectId = params.projectId;

  const {
    state: { permissionsSaved },
    togglePermissionsSaved,
  } = useGenericContext();

  const { data: dataUsersProject, loading: loadingUsersProject } =
    useProjectMembersIncludeQuery({
      fetchPolicy: 'cache-and-network',
      skip: Boolean(isGroup || !projectId),
      variables: {
        projectId,
      },
    });

  const { data: dataUsersOrg, loading: loadingUsersOrg } = useUsersIncludeQuery(
    {
      fetchPolicy: 'cache-and-network',
      skip: Boolean(isGroup || projectId),
    }
  );

  const { data: dataGroups, loading: loadingGroups } = useGroupsIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isGroup,
    variables: {
      projectId,
    },
  });

  const members =
    ((isGroup
      ? dataGroups?.groups
      : projectId
      ? dataUsersProject?.projectMembers
      : dataUsersOrg?.users) as Member[]) || [];

  const isLoadingMembers = isGroup
    ? loadingGroups && !dataGroups
    : projectId
    ? loadingUsersProject && !dataUsersProject
    : loadingUsersOrg && !dataUsersOrg;

  const memberDefinition = isGroup
    ? projectId
      ? MemberDefinition.ApiProjectGroup
      : MemberDefinition.ApiOrgGroup
    : projectId
    ? MemberDefinition.ApiProjectMember
    : MemberDefinition.ApiOrgMember;

  const entityIds = members.map((member) => member.id) || [];

  const {
    data: folderData,
    loading: loadingFolderData,
    error,
    refetch,
  } = useFolderWithPermissionsByPathQuery({
    fetchPolicy: 'network-only', // TODO: Find out why the `cache-and-network` policy is not working, it updates the cache but does not update the UI. See prove when slowly (BE can be slow) switching between 'Users' and 'Groups' tab twice.
    skip: !entityIds.length,
    variables: {
      entityIds,
      forceOrganizationGroups:
        memberDefinition === MemberDefinition.ApiProjectMember,
      inherit: true,
      path: params.path ?? '/',
      projectId,
    },
  });
  const folder = folderData?.folder;

  useEffect(() => {
    if (permissionsSaved) {
      refetch();
      togglePermissionsSaved();
    }
  }, [permissionsSaved, refetch, togglePermissionsSaved]);

  return {
    error,
    folder,
    loading: isLoadingMembers || loadingFolderData,
    refetch,
  };
};
