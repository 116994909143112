import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReportConfiguration, User } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  getFormattedDate,
  MiddleEllipsis,
  Timestamp,
} from '@pro4all/shared/ui/general';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

const StyledBox = styled(Box)`
  && {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }
`;

export const useColumnsReportConfigurations = () => {
  const { t } = useTranslation();

  const columnsFields: ColumnProps<ReportConfiguration>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<ReportConfiguration, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      render: ({ name }) => (
        <StyledBox>
          <MiddleEllipsis text={name}></MiddleEllipsis>
        </StyledBox>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: (template: ReportConfiguration) =>
        template.updatedAt ? getFormattedDate(template.updatedAt).label : '',
      headerComponent: (
        <FilterHeader<ReportConfiguration, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Updated on" // i18n
          minWidth={120}
          propertyId="updatedAt"
        />
      ),
      key: 'updatedAt',
      render: ({ updatedAt }) => <Timestamp date={updatedAt} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ updatedBy }) => updatedBy?.displayName,
      headerComponent: (
        <FilterHeader<ReportConfiguration, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Updated by" // i18n
          minWidth={120}
          propertyId="updatedBy"
          subPropertyId="displayName"
        />
      ),
      key: 'updatedBy.displayName',
      render: ({ updatedBy }) => (
        <MiddleEllipsis endLength={9} text={updatedBy?.displayName} />
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: (template: ReportConfiguration) =>
        template.createdAt ? getFormattedDate(template.createdAt).label : '',
      headerComponent: (
        <FilterHeader<ReportConfiguration, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }) => <Timestamp date={createdAt} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<ReportConfiguration, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }) => (
        <MiddleEllipsis endLength={9} text={createdBy?.displayName} />
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: (template: ReportConfiguration) => {
        const value = template.isDefault
          ? t(template.isDefault.toString())
          : ''; // There was a scenario with a Blue screen, so a safeguard just in case.
        return template.isDefault ? value : '';
      },
      headerComponent: (
        <FilterHeader<ReportConfiguration, {}>
          defaultWidth={80}
          filterType={FilterHeaderType.Select}
          label="Default" // i18n
          minWidth={80}
          propertyId="isDefault"
        />
      ),
      key: 'isDefault',
      render: ({ isDefault }) => (isDefault ? t(isDefault.toString()) : ''),
      width: 80,
    },
  ];
  return columnsFields;
};
