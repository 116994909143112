import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@pro4all/shared/ui/typography';

interface CardProps {
  bgColor: string;
  details: string;
  questionCount?: number;
  textColor?: string;
  title: string;
}

export const RieCard = ({
  title,
  details,
  bgColor,
  textColor,
  questionCount,
}: CardProps) => {
  const { t } = useTranslation();

  return (
    <CardContainer backgroundColor={bgColor}>
      <Title textcolor={textColor} variant="h5">
        {title}
      </Title>
      <DetailsRow>
        <Caption textcolor={textColor}>
          {questionCount +
            ' ' +
            (questionCount === 1 ? t('question') : t('questions'))}
        </Caption>
        <Caption textcolor={textColor}>{details}</Caption>
      </DetailsRow>
    </CardContainer>
  );
};

const Title = styled(Text).withConfig<{
  textcolor?: string;
  variant: 'h5';
}>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['textcolor'].includes(prop) && defaultValidatorFn(prop),
})`
  && {
    color: ${(props) => props.textcolor || 'black'};
    margin: 0;
  }
`;

const Caption = styled.span.withConfig<{ textcolor?: string }>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['textcolor'].includes(prop) && defaultValidatorFn(prop),
})`
  color: ${(props) => props.textcolor || 'black'};
  font-size: 12px;
  font-weight: 400;
`;

const CardContainer = styled.div.withConfig<{ backgroundColor: string }>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['backgroundColor'].includes(prop) && defaultValidatorFn(prop),
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Roboto';
  padding: 8px;
  gap: 8px;
  width: 200px;
  height: 55px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
