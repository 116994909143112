import { unflatten } from 'react-base-table';

import { Folder } from '@pro4all/graphql';
import { sortBy } from '@pro4all/shared/utils';

const sortFolders = (items: Folder[]) => {
  const folders = items
    .filter((item) => item.__typename === 'Folder')
    .sort(sortBy({ key: 'path' }));
  const projectLinks = items
    .filter((item) => item.__typename !== 'Folder')
    .sort(sortBy({ key: 'path' }));

  return [...projectLinks, ...folders];
};

export const unflattenFolders = (folders: Folder[]) =>
  unflatten(sortFolders(folders), null, 'id', 'parentFolderId');
