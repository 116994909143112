import { useTranslation } from 'react-i18next';

import { StorageKeys } from '@pro4all/shared/config';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { Box, CircularProgress } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/general';

import { useDisableSave } from '../helpers';
import { DeleteDocumentsForFailedUploads } from '../other/DeleteDocumentsForFailedUploads';
import { getMetaInvalid } from '../reducer-actions/helpers/getMetaInvalid';
import { useUploaderEditorContext } from '../UploaderEditorProvider';

import { useSaveDocumentMetadata } from './useSaveDocumentMetadata';
import { useSaveDocuments } from './useSaveDocuments';
import { useUploadFiles } from './useUploadFiles';

export const useFormActions = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const saveDocuments = useSaveDocuments();
  const uploadFiles = useUploadFiles();

  const { isPublishAction } = useClientRedirectContext();

  const saveDocumentMetadata = useSaveDocumentMetadata();

  const {
    deleteInvalidMeta,
    setHorizontalTabbing,
    setInvalidMeta,
    state: {
      filesToUpload,
      horizontalTabbing,
      meta,
      metaInvalid,
      isProcessing,
    },
  } = useUploaderEditorContext();
  const uploadContext = Boolean(filesToUpload.length);
  const disableSave = useDisableSave({ uploadContext });

  const { setLocalStorageItem: setHorizontalTabbingLocalStorage } =
    useLocalStorage<boolean>({
      key: StorageKeys.HORIZONTAL_TABBING_IN_EDITOR,
    });

  // Is there at least one invalid cell because else we do not display the `Show invalid` button.
  const areThereInvalidCells = getMetaInvalid(meta).length > 0;

  const onCancel = () => {
    searchParams.clear();
  };

  const enableHorizontalTabbing = () => {
    setHorizontalTabbing(true);
    setHorizontalTabbingLocalStorage(true);
  };

  const enableVerticalTabbing = () => {
    setHorizontalTabbing(false);
    setHorizontalTabbingLocalStorage(false);
  };

  const saveIcon = isProcessing ? undefined : 'save';
  const saveSpinner = isProcessing ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mr: 2 }}>
      <CircularProgress size={16} />
    </Box>
  ) : null;

  const actionButtons = (
    <Footer $sticky pb={3}>
      {!horizontalTabbing && (
        <Button color="inherit" onClick={enableHorizontalTabbing}>
          {t('Switch to horizontal tabbing')}
        </Button>
      )}
      {horizontalTabbing && (
        <Button color="inherit" onClick={enableVerticalTabbing}>
          {t('Switch to vertical tabbing')}
        </Button>
      )}
      {metaInvalid && (
        <Button
          color="inherit"
          onClick={deleteInvalidMeta}
          startIcon="visibilityOff"
        >
          {t('Show all')}
        </Button>
      )}
      {!metaInvalid && areThereInvalidCells && (
        <Button color="inherit" onClick={setInvalidMeta} startIcon="visibility">
          {t('Show invalid')}
        </Button>
      )}

      <Button color="inherit" onClick={onCancel}>
        {t('Close')}
      </Button>
      {!uploadContext ? (
        <>
          <Button
            disabled={disableSave || isPublishAction}
            onClick={() => saveDocuments({ closeEditor: false })}
            startIcon={saveIcon}
            type="submit"
            variant="contained"
          >
            {saveSpinner}
            {t('Save')}
          </Button>
          <Button
            disabled={disableSave && !isPublishAction}
            onClick={() =>
              isPublishAction
                ? saveDocumentMetadata()
                : saveDocuments({ closeEditor: true })
            }
            startIcon={saveIcon}
            type="submit"
            variant="contained"
          >
            {saveSpinner}
            {t('Save and close')}
          </Button>
        </>
      ) : (
        <>
          <Button
            disabled={disableSave}
            onClick={uploadFiles}
            startIcon={saveIcon}
            type="submit"
            variant="contained"
          >
            {saveSpinner}
            {t('Save and upload')}
          </Button>
          <DeleteDocumentsForFailedUploads />
        </>
      )}
    </Footer>
  );

  return actionButtons;
};
