import { useState } from 'react';
import { FormikErrors } from 'formik';

import { Document, QualityControlInstance } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';

import { FormFields } from './types';

export const useCheckDisableStatus = ({
  linkInstances,
}: {
  linkInstances: QualityControlInstance[] | Document[];
}) => {
  const { searchParams } = useRouting();
  const duplicate = searchParams.get('duplicate');
  const documentTask = searchParams.is('taskType', 'Document');
  const [deletedLinkedInstance, setDeletedLinkedInstance] = useState(false);

  const checkDisableStatus = (
    errors: FormikErrors<FormFields>,
    isSubmitting: boolean,
    dirty: boolean
  ) => {
    const isDirty = deletedLinkedInstance || Boolean(duplicate) || dirty;
    // If documentTask is true, check linkInstances before deciding to disable submit

    if (documentTask)
      return (
        !linkInstances ||
        isSubmitDisabled({ dirty: isDirty, errors, isSubmitting })
      );
    // Default case for quality control and tbq
    return isSubmitDisabled({ dirty: isDirty, errors, isSubmitting });
  };

  return { checkDisableStatus, setDeletedLinkedInstance };
};
