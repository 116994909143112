import React from 'react';

import { Scope, useToggleScope } from './useToggleContext';
import {
  useToggleProvider,
  UseToggleProviderOptions,
} from './useToggleProvider';

export type Props = UseToggleProviderOptions & { scope?: Scope };

export const ToggleProvider: React.FC<Props> = ({ scope, ...props }) => {
  const ToggleContext = useToggleScope(scope);
  const value = useToggleProvider(props);

  return <ToggleContext.Provider value={value} {...props} />;
};
