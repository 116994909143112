import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useApiKeysFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Description',
      name: 'description',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Expires at',
      name: 'expiresAt',
      type: ValueTypeName.DateTime,
    },
  ];

  return useFormConfig({ formFields });
};
