import { FieldDefinition } from '@pro4all/graphql';
import { matchesSearchTerm } from '@pro4all/workflow/ui/utils';

export const useGetFilteredSectionsCategoriesAndQuestions = () => {
  const getFilteredSectionsCategoriesAndQuestions = ({
    sections,
    term,
  }: {
    sections: FieldDefinition[];
    term: string;
  }): FieldDefinition[] => {
    const termLower = term.toLowerCase();

    // Reduce sections to those matching the search term or containing matching categories/questions
    return sections?.reduce<FieldDefinition[]>((acc, section) => {
      // Check if the section name matches the search term
      const sectionMatch = matchesSearchTerm(section, termLower);

      // Reduce the categories to only those that match the search term or contain matching questions
      const filteredCategories = (section.valueType?.subFields ?? []).reduce<
        FieldDefinition[]
      >((subAcc, category) => {
        // Check if the category name matches the search term
        const categoryMatch = matchesSearchTerm(category, termLower);

        // If the category matches, include all its questions and fields
        if (categoryMatch) {
          subAcc.push({ ...category });
        } else {
          // Otherwise, filter questions based on the search term
          const filteredQuestions = (category.valueType?.subFields ?? [])
            .map((question) => {
              // Check if the question name matches the search term
              const questionMatch = matchesSearchTerm(question, termLower);

              let fieldsMatch: boolean | undefined = false;
              fieldsMatch = question.valueType?.subFields?.some((field) =>
                matchesSearchTerm(field, termLower)
              );

              // Include the question if it or its fields match
              return questionMatch || fieldsMatch ? { ...question } : null;
            })
            .filter((q) => q !== null) as FieldDefinition[];

          // If there are any matching questions, include the category with these questions
          if (filteredQuestions.length > 0) {
            subAcc.push({
              ...category,
              valueType: {
                ...category.valueType,
                subFields: filteredQuestions,
              },
            });
          }
        }

        return subAcc;
      }, []);

      // If the section matches or contains matching categories, include it in the result
      if (sectionMatch || filteredCategories.length > 0) {
        acc.push({
          ...section,
          valueType: {
            ...section.valueType,
            subFields:
              filteredCategories.length > 0
                ? filteredCategories
                : section.valueType?.subFields,
          },
        });
      }

      return acc;
    }, []);
  };

  return { getFilteredSectionsCategoriesAndQuestions };
};
