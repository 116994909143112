import { toReference } from '@pro4all/communication/data-access';
import {
  Group,
  Reference,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';

import { toGroupSnapshot } from './toGroupSnapshot';

export const createReferenceFromGroup = (
  groupId: string,
  referenceKind: ReferenceKind,
  groups: Group[]
): Reference | undefined => {
  const foundGroup = groups.find((group) => group.id === groupId);
  if (foundGroup) {
    const snapshot = toGroupSnapshot(foundGroup);
    return toReference({
      referenceData: null,
      referenceId: null,
      referenceKind,
      referenceType: ReferenceType.GroupSnapshot,
      referenceValue: JSON.stringify(snapshot),
    });
  }
  return undefined;
};
