import { PhotoService } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';

export function usePhotoUpload({ fieldId }: { fieldId: string }) {
  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');
  const { upload } = useFileUploadContext();

  const handleUploadPhotos = async (files: File[]) => {
    upload(files, (file, onProgress) => {
      const res = PhotoService.uploadPhoto({
        fieldId,
        file,
        instanceId,
        onProgress,
      });
      return res;
    });
  };

  return { handleUploadPhotos };
}
