import React from 'react';

import { FieldDefinition } from '@pro4all/graphql';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Loader,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { ItemsActionBar } from '../action-bars/ItemsActionBar';
import { BigMessage } from '../big-message/BigMessage';
import { useColumnsAdditional } from '../columns/useColumnsAdditional';
import { useColumnsFields } from '../columns/useColumnsFields';
import { useFieldDefinitions } from '../hooks/useFieldDefinitions';
import NavigationDropdown from '../navigation-dropdown/NavigationDropdown';
import { ItemSidebar } from '../sidebar-item/ItemSidebar';

import {
  CustomActionBarWrapper,
  CustomDefaultActionBarWrapper,
} from './CustomActionBarWrapper';

export const ScreenFieldDefinitions = ({
  includeOrgItems = true,
  isProject = false,
}: {
  includeOrgItems?: boolean;
  isProject?: boolean;
}) => {
  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<FieldDefinition>();

  const { templateService } = useMetaDataContext();
  const columnsAdditional = useColumnsAdditional();
  const columnsItems = useColumnsFields();
  const additionalColumns = isProject ? columnsAdditional : [];
  const { hasAppPermission } = useAppPermissions();
  const {
    loading,
    fieldDefinitions,
    selectFieldDefinition,
    selectedFieldDefinition,
  } = useFieldDefinitions({
    includeOrgItems,
    templateService,
  });

  useSetItemsInLocalState<FieldDefinition>(fieldDefinitions);

  if (loading) return <Loader />;

  return (
    <TableContextProvider
      columns={[...columnsItems, ...additionalColumns]}
      id={`table-field-definitions-${templateService}-${
        isProject ? 'project' : 'organization'
      }`}
      items={items}
    >
      <FilterContextProvider<FieldDefinition>>
        <>
          <CustomActionBarWrapper>
            <Box>
              <NavigationDropdown />
            </Box>
            <CustomDefaultActionBarWrapper>
              <ItemsActionBar />
            </CustomDefaultActionBarWrapper>
          </CustomActionBarWrapper>

          <ItemSidebar selectedField={selectedFieldDefinition} />
          {!items?.length && !itemsInitial?.length ? (
            <BigMessage />
          ) : (
            <Table<FieldDefinition>
              onRowClick={(row) =>
                hasAppPermission(
                  isProject ? 'ProjectUpdate' : 'OrganizationUpdate'
                ) && selectFieldDefinition(row)
              }
            />
          )}
        </>
      </FilterContextProvider>
    </TableContextProvider>
  );
};
