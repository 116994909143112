import { Form } from 'formik';
import styled from 'styled-components';

// Extra check on FormikForm because unit tests (f.i. CustomNumber.spec and CustomDateTime.spec)
// see it as undefined and fail because of it.

export const FormikForm =
  Form &&
  styled(Form)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between;
  `;
