import { Task } from '@pro4all/graphql';

import { CardConfig, Drawing, TaskKey } from './types';

export const useShowCards = ({
  currentTask,
  drawings,
}: {
  currentTask: Task;
  drawings: Drawing[];
}) => {
  const showCards = (cardConfig: CardConfig) => {
    const cardLink = cardConfig.key;

    const dataToMap =
      cardLink === 'linkedSnagDrawings'
        ? drawings
        : currentTask[cardLink as TaskKey];

    return cardConfig.condition !== false && dataToMap?.length > 0;
  };

  return { showCards };
};
