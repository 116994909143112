import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddTBQ: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M6.70514 8.62183L11.9686 5.61442L16.9913 8.48422L20.1432 6.6707L11.9686 2L3.55325 6.80832L6.70514 8.62183Z" />
    <path d="M17.5722 12.2497V9.14115L20.7023 7.32128V12.2434L17.5722 12.2497Z" />
    <path d="M11.4698 18.3392L6.37041 15.3951V9.45418L3.24023 7.63431V17.2023L11.4808 21.96L11.4698 18.3392Z" />
    <path d="M15.0002 19H17.0002V21C17.0002 21.55 17.4502 22 18.0002 22C18.5502 22 19.0002 21.55 19.0002 21V19H21.0002C21.5502 19 22.0002 18.55 22.0002 18C22.0002 17.45 21.5502 17 21.0002 17H19.0002V15C19.0002 14.45 18.5502 14 18.0002 14C17.4502 14 17.0002 14.45 17.0002 15V17H15.0002C14.4502 17 14.0002 17.45 14.0002 18C14.0002 18.55 14.4502 19 15.0002 19Z" />
  </SvgIcon>
);
