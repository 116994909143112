import { Log, Profile, UserManager, WebStorageStateStore } from 'oidc-client';

import {
  IDENTITY_CONFIG,
  STORAGE_ID,
} from '@pro4all/authentication/src/config';
import { environment } from '@pro4all/authentication/src/environments';
import { getIdToken } from '@pro4all/authentication/src/utils';

class AuthService {
  userManager: UserManager;

  constructor() {
    this.logout.bind(this);
    this.userManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({
        store: window.localStorage,
      }),
    });

    // Logger
    Log.logger = console;
    Log.level = Log.ERROR;

    this.userManager.events.addUserLoaded(() => {
      // HACK: FusionAuth doesn't acccept an invalid token_hint on logout which
      // presents the user with an unhelpful error page when logging out.
      // Because oidc-client checks the token every 2000ms by default and
      // default expiry is set 1 second before actual token expiration, it has
      // a huge chance to logout with an invalid token. If we give the
      // UserManager a tiny bit more leeway in calling logout the user is
      // properly returned to the login page.
      this.userManager.getUser().then((user) => {
        if (user) {
          user.expires_at = user.profile.exp - 30;
          this.userManager.storeUser(user);
        } else {
          this.logout();
        }
      });

      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    // Refresh token when it's about to expire. Only works when the user is
    // active on the page.
    this.userManager.events.addAccessTokenExpiring(async () => {
      await this.userManager.signinSilent();
    });

    // Refresh token when it's expired. Works when the user returns to the page
    this.userManager.events.addAccessTokenExpired(async () => {
      let user;
      try {
        user = await this.userManager.signinSilent();
      } catch (e) {
        console.error('caught error in token refresh:', e);
      }
      if (user) {
        await this.userManager.storeUser(user);
        window.location.reload();
      } else {
        this.logout();
      }
    });

    // Logout when silent renew fails
    this.userManager.events.addSilentRenewError(this.logout);
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback();
  }

  async getUser() {
    const user = await this.userManager.getUser();
    if (!user) {
      return this.userManager.signinRedirectCallback();
    }
    return user;
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  signinRedirect() {
    const { pathname, search = '' } = window.location;
    localStorage.setItem('redirectUri', `${pathname}${search}`);
    this.userManager.signinRedirect({});
  }

  navigateToScreen() {
    const uri = localStorage.getItem('redirectUri');
    window.location.replace(uri);
  }

  isAuthenticated() {
    const oidcStorage = JSON.parse(localStorage.getItem(STORAGE_ID));
    return Boolean(oidcStorage) && Boolean(oidcStorage.access_token);
  }

  // Instead of UserManager.getUser() (which returns a promise w/ a properly
  // typed User interface) we use a custom, synchronous mechanism to fetch user
  // auth data.
  // TODO: ponder if we ever need to rewrite this to UserManager.
  getProfile(): Profile & { tenantId: string; userId: string } {
    const oidcStorage = JSON.parse(localStorage.getItem(STORAGE_ID));
    return oidcStorage?.profile;
  }

  getToken() {
    const oidcStorage = JSON.parse(localStorage.getItem(STORAGE_ID));
    return oidcStorage?.access_token;
  }

  async signinSilent() {
    await this.userManager.signinSilent();
  }

  signinSilentCallback() {
    this.userManager.signinSilentCallback();
  }

  createSigninRequest() {
    return this.userManager.createSigninRequest();
  }

  logout() {
    this.userManager.signoutRedirect({
      id_token_hint: getIdToken(),
    });
    this.userManager.clearStaleState();
  }

  async signoutRedirectCallback() {
    await this.userManager.signoutRedirectCallback();
    if (environment)
      window.location.replace(environment.authentication.publicUrl);
    this.userManager.clearStaleState();
  }
}

export default new AuthService();
