import { SavedSearch } from '@pro4all/graphql';
import { SearchEntities } from '@pro4all/shared/search-utils-types';

import { DialogMode } from '../DialogMode';

export interface SavedSearchFields {
  name: SavedSearch['name'];
  notificationDays: SavedSearch['notificationDays'];
  notificationTimes: SavedSearch['notificationTimes'];
  notificationsEnabled: SavedSearch['notificationsEnabled'];
}

export interface SaveQueryFormProps extends SavedSearch {
  mode: DialogMode.Create | DialogMode.Edit;
  onClose: () => void;
  scope: SearchEntities;
}

export enum SavedSearchScope {
  Document = 1,
  QC = 2,
}
