import React, { useEffect, useRef, useState } from 'react';

import { Document } from '@pro4all/graphql';
import { Box, Divider, Grid } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { DocumentGridCard } from './DocumentGridCard';

const SPACING = 4;

const DocumentGrid: React.FC<{
  documents: Document[];
  onClick: (document: Document) => void;
}> = ({ documents, onClick }) => {
  const BoxRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const onScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setContainerHeight(target.clientHeight);
    setScrollPosition(target.scrollTop);
  };

  useEffect(() => {
    setContainerHeight(BoxRef.current?.clientHeight);
  }, [BoxRef.current?.clientHeight]);

  return (
    <Box
      onScroll={onScroll}
      ref={BoxRef}
      sx={{ background: customColors.grey100, overflow: 'auto', width: '100%' }}
    >
      <Divider />
      <Grid
        container
        key="document-grid-container"
        spacing={SPACING}
        sx={{ padding: SPACING }}
      >
        {documents &&
          documents.map((document) => (
            <Grid item key={document.id} lg={3} md={6} sm={12} xl={2} xs={12}>
              <DocumentGridCard
                containerHeight={containerHeight}
                document={document}
                onClick={() => {
                  onClick(document);
                }}
                parentScrollPosition={scrollPosition}
              ></DocumentGridCard>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default DocumentGrid;
