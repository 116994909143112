import { State } from '../useUploaderEditorReducer';

export const setHorizontalTabbingAction = ({
  payload,
  state,
}: {
  payload: boolean;
  state: State;
}) => ({
  ...state,
  horizontalTabbing: payload,
});
