import { useTranslation } from 'react-i18next';

import {
  useSubscriptionAnalytics,
  useTrialDaysLeft,
} from '@pro4all/settings/ui/src/subscription';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';

import * as Styled from './SubscriptionCard.styles';

export const SubscriptionCardTrial = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const { hasAppPermission } = useAppPermissions();
  const userCanUpdateOrg = hasAppPermission('OrganizationUpdate');

  const daysLeftIntrial = useTrialDaysLeft();
  const { trackUpgradeLicenseButtonClicked } = useSubscriptionAnalytics();

  const handleUpgradeLicenseClick = () => {
    trackUpgradeLicenseButtonClicked();
    goTo('settingsOrganizationLicense');
  };

  return (
    <Styled.Container>
      <Styled.StyledH2 variant="h2">{t('Trial license')}</Styled.StyledH2>
      <Styled.StyledH5 variant="h5">
        {t('{{count}} days left', { count: daysLeftIntrial })}
      </Styled.StyledH5>

      {userCanUpdateOrg && (
        <Styled.Link onClick={handleUpgradeLicenseClick} variant="h5">
          {t('Upgrade license')}
        </Styled.Link>
      )}
    </Styled.Container>
  );
};
