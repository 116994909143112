import React, { useCallback, useRef } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Descendant } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  AttachmentInput,
  AttachmentList,
  AttachmentsUploadPreview,
  defaultEditorValues,
  ImageUploading,
  SlateEditor,
  stringToPlainText,
} from '@pro4all/communication/ui/general';
import {
  AttachmentContext,
  AttachmentInput as GqlAttachment,
  EmailActions,
  gqlType,
  Member,
  Message,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Text } from '@pro4all/shared/ui/typography';
import { isDefined, tryParseString } from '@pro4all/shared/utils';

import { useSubmitMessage } from '../mutation-utils/useSubmitMessage';
import { toUserInfo } from '../utils';

import { Header } from './header/Header';
import { useAttachments } from './hooks/useAttachments';
import { useEditor } from './hooks/useEditor';
import { useListenNavigation } from './hooks/useListenNavigation';
import { useMessageForm } from './hooks/useMessageForm';
import { useMessageSignature } from './hooks/useMessageSignature';
import { MessageEditorButtons } from './MessageEditorButtons';
import { EditorWrap, SubjectWrap } from './MessageForm.styles';
import { MessageFormFields } from './types';

interface Props {
  focus?: boolean;
  message: Message;
  messageSignature?: string;
  readOnly?: boolean;
  type: 'main' | 'original' | 'related' | 'reply';
  withPlaceholder?: boolean;
}

export const MessageForm: React.FC<Props> = ({
  focus = false,
  message,
  messageSignature,
  readOnly = false,
  type,
  withPlaceholder,
}) => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const { enqueueSnackbar } = useSnackbar();

  const [bodyEdited, setBodyEdited] = React.useState<boolean>(false);

  const hasDraftFeature = useFeatureFlag('draft-messages');

  const isSubmitted = searchParams.get('submitting');

  const submitDraft = useSubmitMessage({
    messageId: message.id,
    threadId: message.threadId,
  });

  const editDraft = useSubmitMessage({
    action: EmailActions.DraftEdit,
    messageId: message.id,
    threadId: message.threadId,
  });

  /* Initialize editor */
  const editor = useEditor();

  const focusSlate = () => {
    ReactEditor.focus(editor);
  };
  const blurSlate = () => {
    ReactEditor.blur(editor);
  };

  const originalSignature = useRef<string>(messageSignature || '');

  const attachmentInputId = `message-input-${message.id}`;
  const imageInputId = `image-input-upload-${message.id}`;

  const openFileInput = (currentInputId: string) => {
    const fileInput = document.getElementById(currentInputId);
    fileInput && fileInput.click();
  };

  const onUploadSuccess = () => form.trigger();

  const handleClickAddCollection = async () => {
    await addColectionFiles(form.setValue, form.getValues);
    form.trigger();
  };

  const fromDms = searchParams.get('dmsAttachment') === 'true';

  const storageKey = `${StorageKeys.MESSAGE_ATTACHMENTS}-${
    projectId ?? 'organization'
  }`;
  const storageEntry = sessionStorage.getItem(storageKey);
  const dmsAttachments: GqlAttachment[] =
    fromDms && storageEntry ? JSON.parse(storageEntry) : [];
  if (!fromDms && storageEntry) localStorage.removeItem(storageKey);

  const defaultValues: MessageFormFields = {
    attachments: [...dmsAttachments, ...(message?.attachments || [])],
    bcc:
      message.references
        .filter(({ referenceKind }) => referenceKind === ReferenceKind.Bcc)
        .map(toUserInfo)
        .filter(isDefined) || [],
    body: message.body || null,
    cc:
      message.references
        .filter(({ referenceKind }) => referenceKind === ReferenceKind.Cc)
        .map(toUserInfo)
        .filter(isDefined) || [],
    mentions: message.references
      .filter(
        ({ referenceKind, referenceType }) =>
          referenceKind === ReferenceKind.Body &&
          referenceType === ReferenceType.Mention
      )
      .map(toUserInfo)
      .filter(isDefined),
    messageId: message.id,
    newFiles: [],
    subject: message.subject || '',
    to:
      message.references
        .filter(({ referenceKind }) => referenceKind === ReferenceKind.To)
        .map(toUserInfo)
        .filter(isDefined) || [],
  };

  // There is an issue with typescript and react hook that gives
  // an error when compiling (you will see that the editor shows no error
  // but when compiling it will refuse to) with DeepMap.
  // Check this link: https://github.com/microsoft/TypeScript/issues/41953#issuecomment-1426266497
  // We can avoid this by avoiding the type and  using this example: https://react-hook-form.com/ts#Resolver
  // as the basis for our resolver

  const form = useMessageForm(defaultValues);

  const {
    handleImageUpload,
    handleUpload,
    addColectionFiles,
    isImageUploading = false,
    isUploading = false,
    uploadProgress,
    isAddingCollection,
  } = useAttachments({
    editor,
    form,
    onUploadSuccess,
  });

  const hasMessageBody = Boolean(message?.body?.length);
  const messageBody: Descendant[] = hasMessageBody
    ? tryParseString(message.body || '')
    : tryParseString(form.getValues().body || '') || defaultEditorValues;

  const value: Descendant[] = messageBody;

  const values = form.getValues();
  const { isSubmitting, isDirty, touched } = form.formState;

  const isDraftEdit = message.status === 'Draft' && message.id !== 'new';

  const CustomButtons: React.ReactNode[] = [
    <MessageEditorButtons
      attachmentInputId={`message-input-${message.id}`}
      handleClickAddCollection={handleClickAddCollection}
      imageInputId={`image-input-upload-${message.id}`}
      isAddingCollection={isAddingCollection}
      isImageUploading={isImageUploading}
      isSubmitting={form.formState.isSubmitting}
      isUploading={isUploading}
      openFileInput={openFileInput}
      t={t}
    />,
  ];

  const handleMention = (member: Member) => {
    if (gqlType('User')(member)) {
      // Add member to "To" field
      const withoutMember = values.to?.filter(({ id }) => id !== member.id);
      form.setValue('to', [
        ...(withoutMember || []),
        { email: member.email, id: member.id, type: ReferenceType.User },
      ]);

      // Add member to "Mention" field
      const currentMentions = values?.mentions || [];
      form.setValue('mentions', [
        ...currentMentions,
        { email: member.email, id: member.id, type: ReferenceType.User },
      ]);

      // Remove member from "CC" field
      const updatedCc = values.cc?.filter((cc) => cc.id !== member.id);
      form.setValue('cc', updatedCc?.length ? updatedCc : null);

      // Remove member from "BCC" field
      const updatedBcc = values.bcc?.filter((bcc) => bcc.id !== member.id);
      form.setValue('bcc', updatedBcc?.length ? updatedBcc : null);
    } else {
      if (gqlType('Group')(member)) {
        const withoutMember = values.to?.filter(({ id }) => id !== member.id);

        const groupAdded = {
          email: member.id,
          id: member.id,
          type: ReferenceType.Group,
        };

        form.setValue('to', [...(withoutMember || []), groupAdded]);
      }
    }

    form.trigger();
  };

  const handleDeleteAttachment = ({
    completed,
    id,
  }: {
    completed: boolean;
    id: string;
  }) => {
    if (completed) {
      const filteredAttachments = values.attachments?.filter(
        (attachment) => attachment.fileId !== id
      );
      form.setValue('attachments', filteredAttachments);
    }

    const name = values.attachments?.find(
      (attachment) => attachment.fileId === id
    )?.fileName;

    if (name) {
      const filteredFiles = values.newFiles?.filter(
        (newFile) => newFile.name !== decodeURIComponent(name)
      );
      form.setValue('newFiles', filteredFiles);
    }

    form.trigger();
  };

  const handleDraftSave = useCallback(() => {
    if (
      (isDirty || bodyEdited) &&
      !isSubmitting &&
      !readOnly &&
      hasDraftFeature &&
      !isSubmitted
    ) {
      isDraftEdit
        ? editDraft({
            draft: true,
            onError: () => {
              // No message is shown to the user
              console.error('Failed to save draft');
            },
            onSuccess: () => {
              enqueueSnackbar(t('Your message has been saved as a draft'));
            },
            values: form.getValues(),
          })
        : submitDraft({
            draft: true,
            onError: () => {
              // No message is shown to the user
              console.error('Failed to save draft');
            },
            onSuccess: () => {
              enqueueSnackbar(t('Your message has been saved as a draft'));
            },
            values: form.getValues(),
          });
    }
  }, [bodyEdited, isSubmitting, readOnly, submitDraft, enqueueSnackbar, t]);

  useListenNavigation({
    bodyEdited,
    handleDraftSave,
    isDirty,
    isSubmitted,
    isSubmitting,
    readOnly,
  });

  useMessageSignature({
    editor,
    hasMessageBody,
    messageSignature: messageSignature || '',
    setOriginalSignature: (value: string) => {
      originalSignature.current = value; // Update the ref directly
    },
  });

  const bodyText = message?.body ? stringToPlainText(message.body) : null;
  const subject = message?.subject?.length
    ? message.subject
    : `(${t('No subject').toLowerCase()})`;

  return (
    <FormProvider {...form}>
      <form>
        <EditorWrap
          $bgColor="white"
          $hasFocus={focus}
          $hasPadding={type !== 'main'}
        >
          <Header isUploading={isUploading} message={message} />
          {readOnly && (
            <SubjectWrap>
              <Text variant="h5">{subject}</Text>
            </SubjectWrap>
          )}
          {(Boolean(bodyText) || !readOnly) && (
            <Controller
              control={form.control}
              name="body"
              render={() => (
                <SlateEditor
                  CustomButtons={CustomButtons}
                  autofocus={focus}
                  displayGroups
                  editor={editor}
                  onBlur={blurSlate}
                  onChange={(value) => {
                    form.setValue('body', value);
                    form.trigger('body').then();
                    originalSignature.current !== form.getValues('body')
                      ? setBodyEdited(true)
                      : setBodyEdited(false);
                  }}
                  onFocus={focusSlate}
                  onMention={handleMention}
                  readOnly={readOnly || isImageUploading}
                  showToolbar={!readOnly}
                  value={value}
                  withPlaceholder={withPlaceholder}
                />
              )}
            />
          )}
          <AttachmentInput
            id={attachmentInputId}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpload(event, form.setValue, form.getValues)
            }
            type="file"
          />
          <AttachmentInput
            id={imageInputId}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleImageUpload(event, form.setValue, form.getValues)
            }
            type="file"
          />
          <ImageUploading isLoading={isImageUploading} />
          {readOnly ? (
            <AttachmentList
              attachments={message.attachments}
              context={AttachmentContext.Msg}
              messageId={message.id}
            />
          ) : (
            <AttachmentsUploadPreview
              attachments={values.attachments}
              context={AttachmentContext.Msg}
              newFiles={values.newFiles}
              onDelete={handleDeleteAttachment}
              uploadProgress={uploadProgress.current || undefined}
            />
          )}
        </EditorWrap>
      </form>
    </FormProvider>
  );
};
