import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoConnections: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="follow"
      text={t(
        'Add an integration in the organization that owns this project. Then you can create a connection between this project and that integration.'
      )}
      title={t('No integrations found')}
    />
  );
};
