import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  InstanceResourceType,
  ResourceType,
  TaskType,
  TbqModuleType,
  useCreateQualityControlInstanceMutation,
  useCreateTbqScanTaskMutation,
  useSetLinkMutation,
} from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { toApiDate } from '@pro4all/shared/utils';

type Props = {
  moduleTypeId: string;
  objectId: string;
  onCreate: () => void;
  procedureId: string;
  projectId: string;
};

export type CallBackProps = {
  description: string;
  endTime: string;
  name: string;
  participant: {
    id: string;
  };
  tbqScanTemplate?: {
    id: string;
  };
};

export const useTBQModulesSubmit = ({
  projectId,
  procedureId,
  objectId,
  moduleTypeId,
  onCreate,
}: Props) => {
  const { t } = useTranslation();
  const [createScanTask] = useCreateTbqScanTaskMutation();
  const [setLinkMutation] = useSetLinkMutation();
  const [createQualityControlInstance] =
    useCreateQualityControlInstanceMutation();
  const { showSingleError } = useShowMessages();
  const { enqueueSnackbar } = useSnackbar();

  return async (values: CallBackProps) => {
    const { description, endTime, name, participant, tbqScanTemplate } = values;
    try {
      const createTaskResult = await createScanTask({
        variables: {
          description,
          endTime: endTime ? toApiDate(endTime) : null,
          name,
          procedureId,
          projectId,
          tbqModuleType: TbqModuleType.Generic,
          tbqScanTypeId: tbqScanTemplate.id as string,
          tbqTypeId: moduleTypeId,
          type: TaskType.TbqScan,
          userId: participant?.id,
        },
      });
      const taskId = createTaskResult?.data?.createTbqScanTask?.id;
      if (!taskId) {
        throw Error('Create task failed');
      }
      const createInstanceResult = await createQualityControlInstance({
        variables: {
          projectId,
          resourceId: taskId,
          resourceType: InstanceResourceType.Tbq,
          templateId: tbqScanTemplate.id as string,
        },
      });
      const instanceId =
        createInstanceResult.data?.createQualityControlInstance?.instanceId;
      if (!instanceId) {
        throw Error('Create instance failed');
      }

      await setLinkMutation({
        variables: {
          resourceAId: taskId,
          resourceAType: ResourceType.Task,
          resourceBLinkIds: objectId,
          resourceBType: ResourceType.Object,
        },
      });
      await setLinkMutation({
        variables: {
          resourceAId: taskId,
          resourceAType: ResourceType.Task,
          resourceBLinkIds: [instanceId],
          resourceBType: ResourceType.TbqInstance,
        },
      });
      enqueueSnackbar(
        t('TBQ Scan "{{name}}" has been created.', { name: values.name })
      );
      onCreate();
    } catch (e) {
      showSingleError(e);
    }
  };
};
