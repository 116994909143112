import styled, { css } from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Footer = styled(Box)<{ $cancelLeft?: boolean; $sticky?: boolean }>`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ${({ theme }) => theme.spacing(2)};
    > * + * {
      margin-left: ${({ theme }) => theme.spacing(1)};
    }

    ${(style) =>
      style.$sticky &&
      css`
        background-color: ${customColors.white};
        position: sticky;
        bottom: 0;
      `}

    ${(style) =>
      style.$cancelLeft &&
      css`
        > *:first-child {
          margin-right: auto;
        }
      `}
  }
`;
