import { StandardDateRange } from '@pro4all/graphql';

export const standardRangeNames: Record<StandardDateRange, string> = {
  [StandardDateRange.Today]: 'Today',
  [StandardDateRange.Yesterday]: 'Yesterday',
  [StandardDateRange.LastThreeDays]: 'Last 3 days',
  [StandardDateRange.LastSevenDays]: 'Last 7 days',
  [StandardDateRange.LastFourteenDays]: 'Last 14 days',
  [StandardDateRange.LastThirtyDays]: 'Last 30 days',
  [StandardDateRange.LastNinetyDays]: 'Last 90 days',
  [StandardDateRange.LastYear]: 'Last 365 days',
};
