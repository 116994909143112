import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { useGenericContext } from '@pro4all/shared/providers';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';
import { VisibilityToggle } from '@pro4all/shared/ui/visibility-toggle';

import * as Styled from './AccessTabs.styles';

const base = `${Routes.access}/`;

export const OrganizationAccessTabs: React.FC = () => {
  const { t } = useTranslation();
  const {
    state: { showFinalPermissions },
    toggleShowFinalPermissions,
  } = useGenericContext();
  const isDocumentAccessRoute = useRouteMatch(Routes.baseAccessDocuments);

  return (
    <Styled.TabsWrapper>
      <SubTabs base={base}>
        <Tab
          data-testid="organization-access-documents-tab"
          label={t('Documents')}
          value="documents"
        />
      </SubTabs>
      {isDocumentAccessRoute ? (
        <VisibilityToggle
          label="Show final permissions"
          toggle={toggleShowFinalPermissions}
          toggleValue={showFinalPermissions}
        />
      ) : null}
    </Styled.TabsWrapper>
  );
};
