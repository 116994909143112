import React from 'react';
import { Redirect } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';

export const SignIn = () => {
  const isAuthenticated = AuthService.isAuthenticated();

  if (!isAuthenticated) {
    AuthService.signinRedirect();
    return <span>Redirecting..</span>;
  }

  return <Redirect to="/" />;
};
