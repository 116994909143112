import React, { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';

import { AuthTileLayer64 } from '@pro4all/quality-control/ui/maps';
import { ReportMapContentsProps } from '@pro4all/shared/types';

export const ReportMapContents = ({
  children,
  lat,
  lng,
  page,
  mapHeight,
  mapWidth,
  visualContextId,
  zoomLevels,
}: ReportMapContentsProps) => {
  const map = useMapEvents({});
  const zoom = map.getZoom() || zoomLevels;
  const xy = map.project([lat, lng], zoom);

  const cameraPosition = map.unproject(xy, zoom);

  map
    .getPanes()
    .tilePane.setAttribute('className', `report-tile-map-${visualContextId}`);

  useEffect(() => {
    const southWest = map.unproject([0, mapHeight], zoom);
    const northEast = map.unproject([mapWidth, 0], zoom);
    const bounds = new LatLngBounds(southWest, northEast);

    if (map) {
      map.fitBounds(bounds);
    }
  }, [map, mapHeight, mapWidth, visualContextId, zoom]);

  useEffect(() => {
    if (map && lat && lng) {
      map.setView(cameraPosition, zoom);
    }
  }, [cameraPosition, lat, lng, map, zoom]);

  if (!map) return null;

  return (
    <>
      <AuthTileLayer64
        maxZoom={zoom}
        minZoom={zoom}
        noWrap
        page={page}
        tileSize={256}
        visualContextId={visualContextId}
        zoomOffset={0}
      />
      {children}
    </>
  );
};
