import React from 'react';
import { useTranslation } from 'react-i18next';

import { Member } from '@pro4all/graphql';
import {
  Divider,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

const ProjectUserManagementMobileList: React.FC<{
  projectMembers: Member[];
}> = ({ projectMembers }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <List sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
        {projectMembers &&
          projectMembers.map((projectMember) => {
            const color =
              projectMember.__typename === 'Group'
                ? 'inherit'
                : projectMember.active
                ? 'inherit'
                : 'grey.500';

            return (
              <ListItemButton dense divider key={projectMember.id}>
                <ListItemIcon>
                  <Icon
                    iconName={
                      projectMember.__typename === 'Group'
                        ? 'group'
                        : projectMember.isAdmin
                        ? 'admin'
                        : 'personOutline'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={projectMember.displayName}
                  primaryTypographyProps={{
                    color: color,
                  }}
                  secondary={
                    <>
                      <Typography color={color}>
                        {projectMember.organization
                          ? projectMember.organization.name
                          : ''}
                      </Typography>
                      <Typography color={color}>
                        {projectMember.__typename === 'User' &&
                        projectMember.active
                          ? projectMember.email
                          : t('Group')}
                      </Typography>
                    </>
                  }
                />
                {projectMember.__typename === 'User' &&
                  projectMember.phoneNumber && (
                    <IconButton color="inherit" edge="end">
                      <Link
                        href={`tel:${projectMember.phoneNumber}`}
                        underline="none"
                      >
                        <Icon iconName="phone"></Icon>
                      </Link>
                    </IconButton>
                  )}
              </ListItemButton>
            );
          })}
      </List>
    </>
  );
};

export default ProjectUserManagementMobileList;
