import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const InTaskAssigned: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M4 21.12C3.45 21.12 2.97917 20.932 2.5875 20.556C2.19583 20.18 2 19.728 2 19.2V5.76C2 5.232 2.19583 4.78 2.5875 4.404C2.97917 4.028 3.45 3.84 4 3.84H8.2C8.41667 3.264 8.77917 2.8 9.2875 2.448C9.79583 2.096 10.3667 1.92 11 1.92C11.6333 1.92 12.2042 2.096 12.7125 2.448C13.2208 2.8 13.5833 3.264 13.8 3.84H18C18.55 3.84 19.0208 4.028 19.4125 4.404C19.8042 4.78 20 5.232 20 5.76V11.5195C19.0878 10.3539 17.6356 9.6 16 9.6C14.3642 9.6 12.9118 10.3541 11.9996 11.52H7C6.71667 11.52 6.47917 11.612 6.2875 11.796C6.09583 11.98 6 12.208 6 12.48C6 12.752 6.09583 12.98 6.2875 13.164C6.47917 13.348 6.71667 13.44 7 13.44H11.1C11.0344 13.7502 11 14.0712 11 14.4C11 14.7288 11.0344 15.0498 11.1 15.36H7C6.71667 15.36 6.47917 15.452 6.2875 15.636C6.09583 15.82 6 16.048 6 16.32C6 16.592 6.09583 16.82 6.2875 17.004C6.47917 17.188 6.71667 17.28 7 17.28H10.7028C9.04514 18.0502 8 19.305 8 21.12H4ZM15 9.6C15.2833 9.6 15.5208 9.508 15.7125 9.324C15.9042 9.14 16 8.912 16 8.64C16 8.368 15.9042 8.14 15.7125 7.956C15.5208 7.772 15.2833 7.68 15 7.68H7C6.71667 7.68 6.47917 7.772 6.2875 7.956C6.09583 8.14 6 8.368 6 8.64C6 8.912 6.09583 9.14 6.2875 9.324C6.47917 9.508 6.71667 9.6 7 9.6H15ZM11 5.04C11.2167 5.04 11.3958 4.972 11.5375 4.836C11.6792 4.7 11.75 4.528 11.75 4.32C11.75 4.112 11.6792 3.94 11.5375 3.804C11.3958 3.668 11.2167 3.6 11 3.6C10.7833 3.6 10.6042 3.668 10.4625 3.804C10.3208 3.94 10.25 4.112 10.25 4.32C10.25 4.528 10.3208 4.7 10.4625 4.836C10.6042 4.972 10.7833 5.04 11 5.04Z"
      fillRule="evenodd"
    />
    <path d="M13.8813 16.434C14.4688 16.998 15.175 17.28 16 17.28C16.825 17.28 17.5312 16.998 18.1187 16.434C18.7062 15.87 19 15.192 19 14.4C19 13.608 18.7062 12.93 18.1187 12.366C17.5312 11.802 16.825 11.52 16 11.52C15.175 11.52 14.4688 11.802 13.8813 12.366C13.2937 12.93 13 13.608 13 14.4C13 15.192 13.2937 15.87 13.8813 16.434Z" />
    <path d="M10 21.024V21.6C10 21.996 10.1469 22.335 10.4406 22.617C10.7344 22.899 11.0875 23.04 11.5 23.04H20.5C20.9125 23.04 21.2656 22.899 21.5594 22.617C21.8531 22.335 22 21.996 22 21.6V21.024C22 20.616 21.8906 20.241 21.6719 19.899C21.4531 19.557 21.1625 19.296 20.8 19.116C20.025 18.744 19.2375 18.465 18.4375 18.279C17.6375 18.093 16.825 18 16 18C15.175 18 14.3625 18.093 13.5625 18.279C12.7625 18.465 11.975 18.744 11.2 19.116C10.8375 19.296 10.5469 19.557 10.3281 19.899C10.1094 20.241 10 20.616 10 21.024Z" />
  </SvgIcon>
);
