import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { useSharedFormFieldsConfig } from '../useSharedFormFieldsConfig';

export const useFinalizeInvitationFormConfig = () => {
  const {
    confirmPasswordConfig,
    confirmPasswordCustomValidation,
    firstNameConfig,
    lastNameConfig,
    passwordConfig,
    phoneNumberConfig,
  } = useSharedFormFieldsConfig();

  const formFields: FormFieldConfig[] = [
    firstNameConfig,
    lastNameConfig,
    passwordConfig,
    confirmPasswordConfig,
    phoneNumberConfig,
  ];

  return useFormConfig({
    customValidation: { confirmPassword: confirmPasswordCustomValidation },
    formFields,
  });
};
