import React from 'react';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  ContentNavWrap,
  ContentRow,
  Resizable,
  TableContent,
} from '@pro4all/shared/ui/general';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import * as Messages from '../Messages';
import { useObjectsContext } from '../ObjectsContext';

import { ObjectDetail } from './detail/ObjectDetail';
import { Table } from './tree/Table';
import { Toolbar } from './tree/Toolbar';

export const ViewObjects: React.FC = () => {
  const {
    params: { projectId },
  } = useRouting();
  const { objects } = useObjectsContext();
  const { hasAppPermission } = useAppPermissions();

  const noAccess = !projectId && !hasAppPermission('OrganizationUpdate');
  const noObjects = !objects.length;

  if (noAccess) return <BigMessageNoAccess />;
  if (!projectId && noObjects) return <Messages.NoObjects />;
  if (projectId && noObjects) return <Messages.NoProjectObjects />;

  return (
    <ContentRow>
      <Resizable threshold={960}>
        <ContentNavWrap>
          <Toolbar />
          <Table />
        </ContentNavWrap>
      </Resizable>
      <TableContent>
        <ObjectDetail />
      </TableContent>
    </ContentRow>
  );
};
