import {
  Meta,
  State,
  UpdateMetaMetadataViaHeaderPayload,
} from '../useUploaderEditorReducer';

import { useChangeMetadataValue } from './helpers';

export const useUpdateMetaMetaDataViaHeaderAction = () => {
  const changeMetaDataValue = useChangeMetadataValue();

  const updateMetaMetaDataViaHeaderAction = ({
    payload,
    state,
  }: {
    payload: UpdateMetaMetadataViaHeaderPayload;
    state: State;
  }) => {
    const { documentIdsSelected, fields, meta } = state;
    const { fieldDefinitionId, value } = payload;
    const metaUpdated = [] as Meta[];

    meta.forEach((doc) => {
      let documentUpdated = doc;
      if (
        documentIdsSelected.length === 0 ||
        documentIdsSelected.includes(doc.id)
      ) {
        documentUpdated = changeMetaDataValue({
          doc,
          fieldDefinitionId,
          fields,
          updateViaHeader: true,
          value,
        });
      }

      metaUpdated.push(documentUpdated);
    });

    return {
      ...state,
      meta: metaUpdated,
    };
  };

  return updateMetaMetaDataViaHeaderAction;
};
