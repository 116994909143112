import { Maybe } from 'graphql/jsutils/Maybe';

import { Option } from '@pro4all/shared/types';

export const parseDevicesToOptions = (data: Maybe<string>[]): Option[] =>
  data
    .map((label, index) =>
      typeof label === 'string' ? { id: `device-${index}`, label: label } : null
    )
    .filter((option) => option !== null) as Option[];
