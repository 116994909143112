import React, { useCallback, useContext, useReducer } from 'react';

import {
  ActionType,
  configureDownloadNameReducer,
  LabelCardType,
  PayloadInsert,
  Segment,
  State,
} from './configureDownloadNameReducer';

type ContextType = {
  insertSegment: (payload: PayloadInsert) => void;
  removeSegment: (id: string) => void;
  resetSegments: () => void;
  setAvailableMetaDataFields: (
    availableMetaDataFields: LabelCardType[]
  ) => void;
  setEnabled: (enabled: boolean) => void;
  setSegments: (segments: Segment[]) => void;
  state: State;
};

const ConfigureDownloadNameContext = React.createContext(null);

export function useConfigureDownloadNameContext() {
  return useContext<ContextType>(ConfigureDownloadNameContext);
}

export function ConfigureDownloadNameProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [state, dispatch] = useReducer(configureDownloadNameReducer, {
    availableMetaDataFields: [],
    enabled: false,
    segments: [],
    segmentsInitial: [],
  });

  // Define all actions
  const insertSegment = useCallback((payload: PayloadInsert) => {
    dispatch({
      payload,
      type: ActionType.INSERT_SEGMENT,
    });
  }, []);
  const removeSegment = useCallback((id: string) => {
    dispatch({
      payload: id,
      type: ActionType.REMOVE_SEGMENT,
    });
  }, []);
  const resetSegments = useCallback(() => {
    dispatch({
      type: ActionType.RESET_SEGMENTS,
    });
  }, []);
  const setAvailableMetaDataFields = useCallback(
    (availableMetaDataFields: LabelCardType[]) => {
      dispatch({
        payload: availableMetaDataFields,
        type: ActionType.SET_AVAILABLE_METADATA_FIELDS,
      });
    },
    []
  );
  const setEnabled = useCallback((enabled: boolean) => {
    dispatch({
      payload: enabled,
      type: ActionType.SET_ENABLED,
    });
  }, []);
  const setSegments = useCallback((segments: Segment[]) => {
    dispatch({
      payload: segments,
      type: ActionType.SET_SEGMENTS,
    });
  }, []);

  return (
    <ConfigureDownloadNameContext.Provider
      value={{
        insertSegment,
        removeSegment,
        resetSegments,
        setAvailableMetaDataFields,
        setEnabled,
        setSegments,
        state,
      }}
    >
      {children}
    </ConfigureDownloadNameContext.Provider>
  );
}
