import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFetchFolder } from '@pro4all/documents/ui/folders';
import { SearchResults } from '@pro4all/documents/ui/search';
import { FilterType } from '@pro4all/graphql';
import {
  Filter,
  folderFilterTypes,
  IconWrap,
  SearchToolbar,
  useFilters,
} from '@pro4all/search/ui';
import { useDocumentSearchContext } from '@pro4all/shared/documents-search-context';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const DocumentsSearchResults: React.FC<{
  onSaveSearch: () => void;
}> = ({ onSaveSearch }) => {
  const { t } = useTranslation();
  const {
    refetch,
    loading,
    facetGroups,
    contextQuery,
    setContextQuery,
    handleSearch,
  } = useDocumentSearchContext();
  const { searchParams } = useRouting();
  const paramQuery = searchParams.get('search');
  const paramFilters = searchParams.get('filters');
  const {
    setFilterValue,
    currentFilters: _currentFilters,
    resetFilter,
    removeFilter,
  } = useFilters();

  const isFolderFilterType = (type: FilterType) =>
    folderFilterTypes.includes(type);

  const folderId = _currentFilters?.find(({ type }) =>
    isFolderFilterType(type)
  )?.value;
  const folder = useFetchFolder(folderId || '', !folderId);

  const currentFilters = _currentFilters?.map((filter) => {
    if (isFolderFilterType(filter.type) && folder && folder.path) {
      return { ...filter, name: folder.name, tooltip: folder.path };
    }
    return filter;
  });

  const folderFilter = currentFilters?.find(({ type }) =>
    isFolderFilterType(type)
  );

  const buttonProps = {
    onApply: refetch,
    removeFilter,
    resetFilter,
    setFilterValue,
  };

  const FolderFilter = folderFilter && (
    <Filter
      key={`${FilterType[folderFilter.type]}-filter-0`}
      {...buttonProps}
      {...folderFilter}
      facetLoading={loading}
    />
  );

  const DocumentIdsFilter = Boolean(contextQuery.length) && (
    <Tooltip placement="bottom" title={t('Selection')}>
      <IconWrap $active>
        <Box display="inline-flex" mr={1}>
          <Icon htmlColor="#006B5E" iconName="fileCopy" />
        </Box>
        {t('Selection')}
        <Icon
          cursor="pointer"
          htmlColor="#1d7c71"
          iconName="close"
          onClick={() => {
            setContextQuery([]);
            handleSearch({
              contextQueryParam: [],
              query: paramQuery || '',
              savedFilters: paramFilters || undefined,
            });
          }}
        />
      </IconWrap>
    </Tooltip>
  );

  return (
    <DataViewContextProvider
      initialView={DataViewType.Table}
      viewKey="search-results"
      views={[DataViewType.Table, DataViewType.Charts]}
    >
      <SearchToolbar
        LocalFilter={DocumentIdsFilter}
        ScopeFilter={FolderFilter}
        contextQuery={contextQuery}
        dataViewToggle
        facetGroups={facetGroups}
        loading={loading}
        onApply={refetch}
        onSave={onSaveSearch}
      />
      <SearchResults />
    </DataViewContextProvider>
  );
};
