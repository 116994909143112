import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import { ActionType } from '../shared/types';

import { permissionsFolderMatrixReducer } from './permissionsFolderMatrixReducer';
import { Permission, PermissionSet, PermissionToggle, State } from './types';

type ContextType = {
  eraseState: () => void;
  resetPermissions: () => void;
  setInitialPermissions: (permissions: Permission[]) => void;
  setPermission: (permission: PermissionSet) => void;
  state: State;
  toggleAllPermissions: (folderId: string) => void;
  toggleBackdrop: () => void;
  toggleBreakInheritance: (folderId: string) => void;
  togglePermission: (permission: PermissionToggle) => void;
  updatePermissionsAfterSave: ({
    successful,
    unsuccessful,
  }: {
    successful: string[];
    unsuccessful: string[];
  }) => void;
};

const PermissionsFolderMatrixContext = React.createContext(null);

export function usePermissionsFolderMatrixContext() {
  return useContext<ContextType>(PermissionsFolderMatrixContext);
}

export function PermissionsFolderMatrixProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [state, dispatch] = useReducer(permissionsFolderMatrixReducer, {
    displayPermissions: [],
    initialPermissionsDisplay: [],
    initialPermissionsExplicit: [],
    savePermissions: [],
  });

  // Define all actions
  const updatePermissionsAfterSave = useCallback(
    ({
      successful,
      unsuccessful,
    }: {
      successful: string[];
      unsuccessful: string[];
    }) => {
      dispatch({
        payload: { successful, unsuccessful },
        type: ActionType.UPDATE_PERMISSIONS_AFTER_SAVE,
      });
    },
    []
  );
  const eraseState = useCallback(() => {
    dispatch({
      type: ActionType.ERASE_STATE,
    });
  }, []);
  const resetPermissions = useCallback(() => {
    dispatch({
      type: ActionType.RESET_PERMISSIONS,
    });
  }, []);
  const setInitialPermissions = useCallback((permissions: Permission[]) => {
    dispatch({
      payload: { permissions },
      type: ActionType.SET_INITIAL_PERMISSIONS,
    });
  }, []);
  const setPermission = useCallback((permission: PermissionSet) => {
    dispatch({
      payload: permission,
      type: ActionType.SET_PERMISSION,
    });
  }, []);
  const toggleAllPermissions = useCallback((folderId) => {
    dispatch({
      payload: folderId,
      type: ActionType.TOGGLE_ALL_PERMISSIONS,
    });
  }, []);
  const toggleBackdrop = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_BACKDROP,
    });
  }, []);
  const toggleBreakInheritance = useCallback((folderId) => {
    dispatch({
      payload: folderId,
      type: ActionType.TOGGLE_BREAK_INHERITANCE,
    });
  }, []);
  const togglePermission = useCallback((permission: PermissionToggle) => {
    dispatch({
      payload: permission,
      type: ActionType.TOGGLE_PERMISSION,
    });
  }, []);

  return (
    <PermissionsFolderMatrixContext.Provider
      value={{
        eraseState,
        resetPermissions,
        setInitialPermissions,
        setPermission,
        state,
        toggleAllPermissions,
        toggleBackdrop,
        toggleBreakInheritance,
        togglePermission,
        updatePermissionsAfterSave,
      }}
    >
      {children}
    </PermissionsFolderMatrixContext.Provider>
  );
}

export function useSetPermissionsFolderInLocalState(permissions: Permission[]) {
  const { setInitialPermissions } = usePermissionsFolderMatrixContext();
  useEffect(() => {
    setInitialPermissions(permissions || []);
  }, [permissions, setInitialPermissions]);
}
