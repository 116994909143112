import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ExpectedDocument: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12.5 12.6666V13.9874C12.5 14.2083 12.5875 14.4208 12.7458 14.5791L14.1667 15.9999L12.7417 17.4249C12.5875 17.5833 12.5 17.7958 12.5 18.0166V19.3333C12.5 19.7916 12.875 20.1666 13.3333 20.1666H16.6667C17.125 20.1666 17.5 19.7916 17.5 19.3333V18.0166C17.5 17.7958 17.4125 17.5833 17.2583 17.4291L15.8333 15.9999L17.2542 14.5833C17.4125 14.4249 17.5 14.2124 17.5 13.9916V12.6666C17.5 12.2083 17.125 11.8333 16.6667 11.8333H13.3333C12.875 11.8333 12.5 12.2083 12.5 12.6666Z" />
    <path d="M6 1.25C4.48106 1.25 3.26025 2.49043 3.26 3.99953L3.26 3.99981V4L3.25 19.9995V20C3.25 21.5093 4.4709 22.75 5.99 22.75H18C19.5142 22.75 20.75 21.5142 20.75 20V8.83C20.75 8.10438 20.4632 7.40071 19.9371 6.88641L15.1136 2.06294C14.5993 1.53685 13.8956 1.25 13.17 1.25H6ZM4.76 4C4.76 3.30931 5.3191 2.75 6 2.75H13.17C13.5026 2.75 13.8173 2.88175 14.0426 3.11321L14.0426 3.11326L14.0497 3.12033L18.8797 7.95033L18.8796 7.95038L18.8868 7.95735C19.1182 8.18273 19.25 8.49739 19.25 8.83V20C19.25 20.6858 18.6858 21.25 18 21.25H5.99C5.30918 21.25 4.75013 20.6908 4.75 20.0002V20L4.76 4.00047L4.76 4ZM13 3.5V8C13 8.55 13.45 9 14 9H18.5L13 3.5Z" />
  </SvgIcon>
);
