import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoIntegrations: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="follow"
      text={t(
        'Setup an integration to connect your external systems with your organization and projects.'
      )}
      title={t('No integrations')}
    />
  );
};
