import { useDebouncedCallback } from 'use-debounce';

interface Props {
  debounceInterval?: number;
  onSearch?: (value: string) => void;
}

export const useOnSearch = ({ onSearch, debounceInterval = 250 }: Props) =>
  useDebouncedCallback(
    (value: string) => onSearch && onSearch(value),
    debounceInterval
  );
