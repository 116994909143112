import { useGroupIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { groupIncludeProps } from './getIncludeProps';

export const useGetGroup = () => {
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const isEditing = searchParams.is('action', 'editGroup');
  const isViewing = searchParams.is('action', 'viewGroup');
  const groupId = searchParams.get('id');

  const { data } = useGroupIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isEditing && !isViewing,
    variables: {
      ...groupIncludeProps,
      groupId,
      projectId,
    },
  });

  return data?.group;
};
