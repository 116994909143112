import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { InviteUsersForm } from './InviteUsersForm';

export const InviteUsersSidebar = ({ refetch }: { refetch: () => void }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const isOpen = searchParams.is('action', 'inviteUsers');
  const { userOrganizationName } = useOrganizationContext();

  const handleClose = () => searchParams.clear();

  return (
    <Sidebar onClose={handleClose} open={isOpen} wide>
      <Sidebar.Header
        title={t('Invite {{- myOrganization}} colleagues', {
          myOrganization: userOrganizationName,
        })}
      />
      {isOpen && <InviteUsersForm onClose={handleClose} refetch={refetch} />}
    </Sidebar>
  );
};
