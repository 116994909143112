import React, { useEffect, useMemo, useRef } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import styled from 'styled-components';

import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  customColors,
  StylingDefaults,
  useIsMobileScreen,
} from '@pro4all/shared/themes';
import { ColumnSortOrder } from '@pro4all/shared/types';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Icon } from '@pro4all/shared/ui/icons';

import { CheckRow } from './components/CheckRow';
import {
  SortIndicator,
  StyledTable,
  TableCell,
  TableHeader,
} from './components/StyledTableElements';
import { useContextMenu } from './hooks/useContextMenu';
import { useOnColumnResize } from './hooks/useOnColumnResize';
import { useSortColumn } from './hooks/useSortColumn';
import { useTableKeyboardControl } from './hooks/useTableKeyboardControl';
import { useTableContext } from './TableContext';
import { useTableContextMenuRowContext } from './TableContextMenuContext';
import {
  BaseRow,
  ColumnProps,
  ColumnResizeEnd,
  ColumnSizes,
  TableHeaderProps,
  TableProps,
} from './types';

const components = {
  SortIndicator,
};

type RowArguments<Row extends BaseRow> = {
  key: keyof Row;
  rowData: Row;
};

const RowContextMenu = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export enum StandardColumnKeys {
  ContextMenu = 'contextMenu',
  Selected = 'selected',
  SelectedSpacing = 'selectedSpacing',
  SelectedSwitch = 'selectedSwitch',
}

export function Table<Row extends BaseRow>({
  withSwitchToSelectAll = false, // case false the first column will be a checkbox to select all rows
  customCheckboxRenderer,
  contextMenuActions,
  dynamicRowHeight = false,
  emptyRenderer,
  enableKeyboardControl,
  fixed = false,
  headerHeight = 40,
  onColumnResizeCallback,
  onRowClick,
  onRowDoubleClick,
  overlayRenderer,
  onTableHorizontalScrollCallback,
  rowHeight = StylingDefaults.tableRowHeight,
  rowRenderer,
  selectedId,
  showDocumentSelectCheckboxes = true,
  stickyColumnKeys = [],
  tableScrollPositionLeft,
  onToggleAllRows,
}: TableProps<Row>) {
  const tableRef = useRef<BaseTable>(null);

  const isMobileScreen = useIsMobileScreen();

  rowHeight = isMobileScreen ? StylingDefaults.tableRowHeightMobile : rowHeight;

  const {
    checkable,
    checkableSpacing,
    columns: customColumns,
    id,
    idFilteringSorting,
    renderToggle, // DO NOT REMOVE!! This is for table re-rendering after custom column resizing.
    rows,
  } = useTableContext<Row>();

  const { storeColumnSortingInLocalStorage, getLocalStorageSources } =
    useSortColumn<Row>({
      tableId: idFilteringSorting || id,
    });

  const { buttonPosition, contextMenuRow, setContextMenuRow } =
    useTableContextMenuRowContext<Row>();

  const currentIndex = useTableKeyboardControl({
    enableKeyboardControl: enableKeyboardControl || false,
    onRowClick,
    selectedId: selectedId || '',
  });

  const { onContextMenu } = useContextMenu<Row>();

  useEffect(() => {
    if (currentIndex !== -1) {
      tableRef?.current?.scrollToRow(currentIndex);
    }
  }, [tableRef, currentIndex]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollToLeft(tableScrollPositionLeft || 0);
    }
  }, [tableScrollPositionLeft]);

  const { columnSizes, onColumnResize } = useOnColumnResize(
    id,
    onColumnResizeCallback
  );

  const combinedColumns = useMemo(() => {
    const left =
      checkable && showDocumentSelectCheckboxes
        ? [
            {
              cellRenderer: TableCell,
              dataKey: StandardColumnKeys.Selected,
              flexGrow: 0,
              flexShrink: 0,
              frozen: stickyColumnKeys?.includes(StandardColumnKeys.Selected),
              key: withSwitchToSelectAll
                ? StandardColumnKeys.SelectedSwitch
                : StandardColumnKeys.Selected,
              render: (row: Row, rowIndex: number) =>
                customCheckboxRenderer ? (
                  customCheckboxRenderer(row, rowIndex, rows)
                ) : (
                  <CheckRow row={row} rowIndex={rowIndex} />
                ),
              sortable: false,
              title: 'Check all',
              width: 56,
            },
          ]
        : checkableSpacing
        ? [
            {
              cellRenderer: TableCell,
              dataKey: StandardColumnKeys.SelectedSpacing,
              flexGrow: 0,
              flexShrink: 0,
              frozen: stickyColumnKeys?.includes(
                StandardColumnKeys.SelectedSpacing
              ),
              key: StandardColumnKeys.SelectedSpacing,
              sortable: false,
              width: 56,
            },
          ]
        : [];

    const contextMenu = contextMenuActions
      ? [
          {
            cellRenderer: TableCell,
            dataKey: StandardColumnKeys.ContextMenu,
            flexGrow: 0,
            flexShrink: 0,
            frozen: stickyColumnKeys?.includes(StandardColumnKeys.ContextMenu),
            key: StandardColumnKeys.ContextMenu,
            render: (row: Row) => (
              <RowContextMenu
                data-testid={`row-context-menu-${row.id}`}
                onClick={(event) => {
                  onContextMenu({ event, row });
                }}
              >
                <Icon htmlColor={customColors.grey600} iconName="moreHoriz" />
              </RowContextMenu>
            ),
            sortable: false,
            width: 56,
          },
        ]
      : [];

    const standardColumns = [...left, ...contextMenu];
    const getWidth = (column: ColumnProps<Row>) =>
      columnSizes?.[column.key as string] || column.width || 100;

    return [
      ...standardColumns,
      ...customColumns.map((column) => ({
        cellRenderer: TableCell,
        dataKey: column.key,
        flexGrow: 0,
        flexShrink: 0,
        frozen: stickyColumnKeys?.includes(column.key) ? 'left' : false,
        resizable: column.resizable || true,
        sortable: !column.disableSort,
        title: column.label,
        ...column,
        width: getWidth(column),
      })),
    ];
  }, [
    checkable,
    checkableSpacing,
    columnSizes,
    contextMenuActions,
    customCheckboxRenderer,
    customColumns,
    onContextMenu,
    customColors.grey600,
    showDocumentSelectCheckboxes,
    stickyColumnKeys,
    withSwitchToSelectAll,
  ]);

  // The initialization of column size data in localStorage is required for column filters.
  // Column width increases in case it turns into an active filtered column.
  // Column width decreases in case the filter is removed.
  // In order to do that we have know the initial column size.
  const { localStorageItem: columnSizesLs, setLocalStorageItem } =
    useLocalStorage<ColumnSizes>({
      key: `prostream-column-sizes-${id}`,
    });
  const columnSizesInitial = customColumns.reduce(
    (acc, curr) => ({ ...acc, [curr.key]: curr.width }),
    {}
  );
  setLocalStorageItem({ ...columnSizesInitial, ...columnSizesLs });

  const clicks = useRef(0);

  const handleRowClick = ({
    event,
    rowData,
  }: {
    event: React.MouseEvent;
    rowData: Row;
  }) => {
    clicks.current = event.detail;

    if (clicks.current === 2) {
      onRowDoubleClick && onRowDoubleClick(rowData);
    } else {
      setTimeout(
        () => onRowClick && clicks.current !== 2 && onRowClick(rowData),
        300
      );
    }
  };

  const columnToSort = customColumns?.find((column) => column.defaultSort);
  useEffect(() => {
    if (
      rows.length > 0 &&
      !getLocalStorageSources()?.sortKeyLs &&
      columnToSort
    ) {
      storeColumnSortingInLocalStorage({
        defaultSortOrder: columnToSort.defaultSortOrder
          ? columnToSort.defaultSortOrder
          : ColumnSortOrder.ASC,
        items: rows,
        propertyId: columnToSort.key.toString(),
      });
    }
  }, [
    columnToSort,
    getLocalStorageSources,
    rows,
    storeColumnSortingInLocalStorage,
  ]);

  return (
    <Container>
      <AutoResizer>
        {({ width, height }) => (
          <StyledTable
            clickable={Boolean(onRowClick)}
            columns={combinedColumns}
            components={components}
            data={rows}
            emptyRenderer={emptyRenderer}
            estimatedRowHeight={dynamicRowHeight ? rowHeight : undefined}
            fixed={Boolean(stickyColumnKeys.length) || fixed}
            headerHeight={headerHeight}
            headerRenderer={(headerRenderProps: TableHeaderProps) => (
              <TableHeader
                {...headerRenderProps}
                onToggleAllRows={onToggleAllRows}
              />
            )}
            height={height}
            onColumnResize={(resizeData: ColumnResizeEnd<Row>) =>
              onColumnResize(resizeData)
            }
            onColumnSort={({ key }: RowArguments<Row>) => {
              storeColumnSortingInLocalStorage({
                items: rows,
                propertyId: key.toString(),
              });
            }}
            onScroll={({ scrollLeft }: { scrollLeft: number }) => {
              onTableHorizontalScrollCallback &&
                onTableHorizontalScrollCallback(scrollLeft);
            }}
            overlayRenderer={overlayRenderer}
            ref={tableRef}
            rowClassName={({ rowData }: RowArguments<Row>) =>
              rowData.id === selectedId && 'row-selected'
            }
            rowEventHandlers={{
              onClick: handleRowClick,
              onContextMenu: ({
                event,
                rowData,
              }: {
                event: React.MouseEvent;
                rowData: Row;
              }) =>
                contextMenuActions && onContextMenu({ event, row: rowData }),
            }}
            rowHeight={dynamicRowHeight ? undefined : rowHeight}
            rowRenderer={rowRenderer}
            useIsScrolling
            width={width}
          />
        )}
      </AutoResizer>
      {contextMenuActions?.length && (
        <ContextMenu
          data-testid="context-menu"
          initialPosition={buttonPosition}
          menuItems={contextMenuActions}
          onClose={() => setContextMenuRow && setContextMenuRow(null)}
          open={Boolean(contextMenuRow)}
          variant="menu"
        />
      )}
    </Container>
  );
}
