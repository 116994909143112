import React from 'react';

import { TemplateService, TemplateType } from '@pro4all/graphql';
import {
  BigMessageMetaDataNoDocumentFields,
  BigMessageMetaDataNoDocumentTemplates,
  BigMessageMetaDataNoQualityControlFields,
  BigMessageMetaDataNoQualityControlFormTemplates,
  BigMessageMetaDataNoQualityControlSections,
  BigMessageMetaDataNoQualityControlSnagTemplates,
  BigMessageMetaDataNoTBQScanTemplates,
} from '@pro4all/shared/ui/messages';

import { useMetaDataContext } from '../../views/MetaDataContext';

type messagesType = {
  [Type in TemplateService]: {
    [Type in TemplateType]?: JSX.Element;
  };
};

export const BigMessage = () => {
  const { templateType, templateService } = useMetaDataContext();

  const messages: messagesType = {
    [TemplateService.QualityControl]: {
      [TemplateType.Form]: <BigMessageMetaDataNoQualityControlFormTemplates />,
      [TemplateType.Field]: <BigMessageMetaDataNoQualityControlFields />,
      [TemplateType.Snag]: <BigMessageMetaDataNoQualityControlSnagTemplates />,
      [TemplateType.Section]: <BigMessageMetaDataNoQualityControlSections />,
      [TemplateType.Tbq]: <BigMessageMetaDataNoTBQScanTemplates />,
    },
    [TemplateService.Documents]: {
      [TemplateType.Field]: <BigMessageMetaDataNoDocumentFields />,
      [TemplateType.Document]: <BigMessageMetaDataNoDocumentTemplates />,
    },
  };

  return messages[templateService][templateType];
};
