import { FolderPermission } from '@pro4all/graphql';

import { hasConflictWithApprovePermission } from './hasConflictWithApprovePermission';
import { hasConflictWithFinalizePermission } from './hasConflictWithFinalizePermission';

export const getDisabledPermissionsCount = ({
  permissions,
}: {
  permissions: FolderPermission[];
}) => {
  let response = 0;

  if (
    hasConflictWithFinalizePermission({
      currentPermissions: [FolderPermission.FinalizeContent],
      newPermissions: permissions,
    })
  )
    response++;

  if (
    hasConflictWithApprovePermission({
      currentPermissions: [FolderPermission.ApproveContent],
      newPermissions: permissions,
    })
  )
    response++;

  return response;
};
