import styled from 'styled-components';

import { QualityControlInstance } from '@pro4all/graphql';
import { Document } from '@pro4all/graphql';
import { GreySmallMetaText, Timestamp } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  instance: QualityControlInstance | Document;
}

const CardMeta = ({ instance }: Props) => (
  <GreySmallMetaText>
    {'versionNumber' in instance && (
      <>
        <TextNoWrap variant="caption">
          version {instance?.versionNumber}
        </TextNoWrap>
        <Dot iconName="circle" />
      </>
    )}
    <TextNoWrap variant="caption">
      {instance?.createdBy?.displayName}
    </TextNoWrap>
    <Dot iconName="circle" />
    <TextNoWrap variant="caption">
      <Timestamp date={instance.createdAt} format="YYYY-MM-DD HH:mm" />
    </TextNoWrap>
    {instance.visualContext?.name && (
      <>
        <Dot iconName="circle" />
        <ElipsedText variant="caption">
          {instance.visualContext?.name}
        </ElipsedText>
      </>
    )}
  </GreySmallMetaText>
);

const Dot = styled(Icon)`
  .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.spacing(1)};
    margin: 0 5px;
  }
`;

const ElipsedText = styled(Text)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
  }
`;

const TextNoWrap = styled(Text)`
  && {
    white-space: nowrap;
    line-height: 1.1;
  }
`;

export default CardMeta;
