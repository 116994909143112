import { FolderPermission } from '@pro4all/graphql';

import { ConflictingPermissions } from '../../shared/types';

import { isDocumentsNone } from './checkPermissionsWrappers';

export const hasConflictWithCreateContentPermission = ({
  currentPermissions,
  newPermissions,
}: ConflictingPermissions) => {
  const noDocumentPermissions = isDocumentsNone(newPermissions);
  if (
    noDocumentPermissions &&
    currentPermissions.includes(FolderPermission.CreateContent)
  ) {
    return true;
  } else {
    return false;
  }
};
