import { MutationFunction } from '@apollo/client';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  DocumentAction,
  QualityControlInstance,
  Status,
  Task,
  TaskDocument,
  TaskType,
  TemplateType,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
} from '@pro4all/graphql';
import { Document } from '@pro4all/graphql';
import { isQualityControlInstance } from '@pro4all/workflow/ui/utils';

import { FormFields } from '../types';

export const editTask = async ({
  task,
  updateTask,
  payload,
  values,
  linkInstances,
  participantNameDisplay,
}: {
  linkInstances: (QualityControlInstance | Document)[];
  participantNameDisplay: string;
  payload: {
    command?: string | null;
    description?: string;
    documentAction?: DocumentAction;
    endDate?: string;
    id: string;
    name?: string;
    procedureId: string;
    status?: Status;
    taskCategoryId: string | null;
    type: TaskType;
    userId: string;
  };

  task: Task;
  updateTask: MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;
  values: FormFields;
}) => {
  const {
    category,
    participant,
    formTemplates = [],
    snagTemplates = [],
    visualContexts = [],
    documentAction,
  } = values;

  await updateTask({
    variables: payload,
  });

  const qcInstances = linkInstances.filter((instance) =>
    isQualityControlInstance(instance)
  ) as QualityControlInstance[];

  const linkedDocuments = linkInstances.filter(
    (instance) => !isQualityControlInstance(instance)
  ) as Document[];

  const formTemplatesWithoutIcon = formTemplates.map((formTemplate) =>
    formTemplate.iconComponent
      ? {
          __typename: 'Template',
          id: formTemplate.id,
          name: formTemplate.label ?? '',
          state: 'published',
          type: TemplateType.Form,
        }
      : formTemplate
  );

  const snagTemplatesWithoutIcon = snagTemplates.map((snagTemplate) =>
    snagTemplate.iconComponent
      ? {
          __typename: 'Template',
          id: snagTemplate.id,
          name: snagTemplate.label ?? '',
          state: 'published',
          type: TemplateType.Snag,
        }
      : snagTemplate
  );
  client?.writeQuery({
    data: {
      task: {
        ...task,
        ...payload,
        assignment: [
          { displayName: participantNameDisplay, id: participant?.id },
        ],
        completedAt:
          task?.status !== Status.Done && payload.status === Status.Done
            ? new Date().toISOString()
            : null,
        deliverables: linkedDocuments,
        documentAction: documentAction?.id,
        formTemplates: formTemplatesWithoutIcon,
        linkedSnagInstances: qcInstances,
        snagTemplates: snagTemplatesWithoutIcon,
        taskCategoryId: category ? category.id : null,
        taskCategoryName: category ? category.label : null,
        visualContexts: visualContexts.map((vc) => ({
          ...vc,
          createdAt: null,
          createdBy: null,
          deletedAt: null,
          name: vc.label,
        })),
      },
    },
    query: TaskDocument,
    variables: { id: task.id },
  });
};
