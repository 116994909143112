import React from 'react';
import isEqual from 'react-fast-compare';
import { useField } from 'formik';

import { FieldDefinition, Instance } from '@pro4all/graphql';

import { AnswerEdit, AnswerEditProps } from './AnswerEdit';

interface AnswerEditFormikProps {
  item: FieldDefinition;
  setValue?: (name: string, value: unknown) => void;
  value: Instance['value'];
}

export const AnswerEditFormik: React.FC<AnswerEditFormikProps> = ({
  item,
  value,
}) => {
  const [field, meta] = useField({ name: item.id });

  return (
    <AnswerEditFormikWrapper
      error={Boolean(meta.touched) && Boolean(meta.error)}
      helperText={Boolean(meta.touched) && meta.error}
      item={item}
      {...field}
      value={value} // This `value` prop must be behind the `{...field}` spread, because else field.value will override it!!
    />
  );
};

const AnswerEditFormikWrapperComponent = ({ ...rest }: AnswerEditProps) => (
  <AnswerEdit {...rest} />
);

/**
 * This 'skipUpdate' method avoids three renders per field per keystroke.
 * So if we have 15 fields, it avoids 45 useless re-renders on EVERY single keystroke.
 */
const skipUpdate = (prevProps: AnswerEditProps, nextProps: AnswerEditProps) =>
  // Render if the value, error state or message of the field has changed.
  isEqual(prevProps.value, nextProps.value) &&
  prevProps.error === nextProps.error &&
  prevProps.helperText === nextProps.helperText;

export const AnswerEditFormikWrapper = React.memo(
  AnswerEditFormikWrapperComponent,
  skipUpdate
);
