import React from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from '@pro4all/shared/mui-wrappers';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

export const VisibilityToggle = ({
  label,
  toggle,
  toggleValue,
}: {
  label: string;
  toggle: () => void;
  toggleValue: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex">
      <Switch
        checked={toggleValue}
        name={label}
        onChange={() => toggle()}
        size="small"
      />
      <Text variant="body1">{t(label)}</Text>
    </Box>
  );
};
