import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import {
  hasErrorCode,
  useCreateFolderMutation,
  useRenameFolderMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

import { useFolderTreeContextInner } from './tree/FolderTreeContext';
import { useFolderTreeContextOuter } from './tree/FolderTreeProviderOuter';
import { findNearestParentFolderWithMetaDataSet } from './utils/findNearestParentFolderWithMetaDataSet';
import { FolderFormProps } from './FolderForm';

export const useSubmit = ({
  folder,
  onClose,
  parentFolder,
}: FolderFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createFolder] = useCreateFolderMutation();
  const [renameFolder] = useRenameFolderMutation();
  const { goTo, params } = useRouting();
  const { projectId } = params;
  const {
    createDMSItem: createFolderInProvider,
    mutateFolderProp,
    state: { folders },
  } = useFolderTreeContextOuter();
  const { reloadFolders } = useFolderTreeContextInner();

  const onSubmit = async (
    values: FormikValues,
    { resetForm, setFieldError }: FormikHelpers<FormikValues>
  ) => {
    const nearestParentFolderWithMetaDataSet =
      findNearestParentFolderWithMetaDataSet({
        folders,
        parentFolderId: parentFolder?.id,
      });

    const { name: newName } = values;
    try {
      if (folder) {
        await renameFolder({
          variables: {
            folderId: folder.id,
            name: newName,
          },
        });

        mutateFolderProp({ folderId: folder.id, key: 'name', value: newName });

        // Redirect to new folder name after renaming the current folder.
        const encodedFolderName = encodeURIComponent(folder.name);

        //Reset form for discard dialog
        resetForm();
        resetForm(values);

        if (params.path?.endsWith(encodedFolderName)) {
          const path = params.path.replace(
            encodedFolderName,
            encodeURIComponent(newName)
          );

          goTo(params.projectId ? 'projectDocs' : 'docs', {
            params: { ...params, path },
          });
        } else {
          onClose();
        }

        // @Todo: implement long-term solution
        // Renaming a folder does not change path, nor does it update the paths for parent-children
        // Temporary solution: reload all folders
        reloadFolders();
      } else {
        const { id } = parentFolder || {};
        const { inheritParentMetadata, metadataSetId } =
          nearestParentFolderWithMetaDataSet || {};

        const response = await createFolder({
          variables: {
            applyToSubfolders: inheritParentMetadata || false,
            metadataSetId,
            name: newName,
            parentFolderId: id,
            projectId,
          },
        });
        createFolderInProvider({
          __typename: 'Folder',
          hasSubFolders: false,
          id: response.data.createFolder.folderId,
          isParentOfSelected: false,
          isPrivate: false,
          isSelected: false,
          metadataSetId,
          name: newName,
          parentFolderId: id || null,
          path: parentFolder ? `${parentFolder.path}/${newName}` : newName,
          projectId,
        });

        resetForm();
      }
      enqueueSnackbar(
        <ItemChangedMessage
          description={folder ? MessageAction.RenamedTo : MessageAction.Create}
          entityName={folder ? folder.name : newName}
          entityTypeTranslation={EntityTypeTranslation.Folder}
          newName={newName}
        />
      );
    } catch (e) {
      if (hasErrorCode(e, 'ERROR_FOLDER_EXISTS')) {
        setFieldError('name', t('Folder already exists'));
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      }
    }
  };

  return onSubmit;
};
