import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FolderTreeProviderOuter,
  PermissionsFolderMatrixProvider,
  PermissionsFolderTree,
  usePermissionsFolderMatrixContext,
} from '@pro4all/documents/ui/folders';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';
import { Backdrop, Footer } from '@pro4all/shared/ui/general';
import {
  BigMessageNoSelectionForSettingPermissions,
  DiscardWrapper,
} from '@pro4all/shared/ui/messages';

import { useSubmit } from './useSubmit';

interface Props {
  selectedOption: Option;
}

const FolderTreePermissionsComponent: React.FC<Props> = ({
  selectedOption,
}) => {
  const { t } = useTranslation();
  const { projectId } = useRouting().params;

  const {
    eraseState,
    resetPermissions,
    state: { savePermissions, showBackdrop },
  } = usePermissionsFolderMatrixContext();

  const onSubmit = useSubmit(selectedOption?.id);

  useEffect(() => {
    if (selectedOption) eraseState();
  }, [selectedOption, eraseState]);

  return selectedOption ? (
    <>
      {showBackdrop && <Backdrop />}
      <DiscardWrapper
        dirty={savePermissions.length > 0}
        onSubmit={onSubmit}
        values={savePermissions}
      >
        <>
          <FolderTreeProviderOuter>
            <PermissionsFolderTree
              projectId={projectId}
              userId={selectedOption?.id}
            />
          </FolderTreeProviderOuter>
          <Footer pb={3} pt={2} px={3}>
            <Button onClick={resetPermissions}>{t('Reset changes')}</Button>
            <Button
              disabled={savePermissions.length === 0}
              onClick={onSubmit}
              type="submit"
              variant="contained"
            >
              {t('Save')}
            </Button>
          </Footer>
        </>
      </DiscardWrapper>
    </>
  ) : (
    <Box mt={15}>
      <BigMessageNoSelectionForSettingPermissions />
    </Box>
  );
};

export const FolderTreePermissions = ({
  selectedOption,
}: {
  selectedOption: Option;
}) => (
  <PermissionsFolderMatrixProvider>
    <FolderTreePermissionsComponent selectedOption={selectedOption} />
  </PermissionsFolderMatrixProvider>
);
