import styled from 'styled-components';

/* eslint-disable-next-line */
export interface SharedAssetsSharingProps {}

const StyledSharedAssetsSharing = styled.div`
  color: pink;
`;

export function SharedAssetsSharing(props: SharedAssetsSharingProps) {
  return (
    <StyledSharedAssetsSharing>
      <h1>Welcome to SharedAssetsSharing!</h1>
    </StyledSharedAssetsSharing>
  );
}

export default SharedAssetsSharing;
