import { FolderPermission } from '@pro4all/graphql';

import { removeExclusiveFolderPermissions } from '../../shared/helpers/removeExclusiveFolderPermissions';
import { Permission } from '../types';

import { getChildFolders } from './getChildFolders';
import { getThisFolderPermission } from './getThisFolderPermission';

export const getUpdatedSubfoldersAllPermissions = ({
  displayPermissions,
  folderId,
  initialPermissionsExplicit,
  otherFolderPermissions,
  parent,
  permissions,
  savePermissions,
  toggleAll,
}: {
  displayPermissions: Permission[];
  folderId?: string;
  initialPermissionsExplicit: Permission[];
  otherFolderPermissions: Permission[];
  parent: Permission;
  permissions: FolderPermission[];
  savePermissions: Permission[];
  toggleAll: boolean;
}) => {
  let response: Permission[] = [];

  const setChildFolders = ({
    folder,
    parentFolder,
  }: {
    folder: Permission;
    parentFolder: Permission;
  }) => {
    const isClickedFolder = folderId === folder.folderId;
    const thisFolder = getThisFolderPermission(
      folder.folderId,
      displayPermissions
    );

    const thisFolderInitial = getThisFolderPermission(
      folder.folderId,
      initialPermissionsExplicit
    );

    const thisFolderSave = getThisFolderPermission(
      folder.folderId,
      savePermissions
    );

    const parentFolderInitial = getThisFolderPermission(
      folder.parentFolderId,
      initialPermissionsExplicit
    );

    const currentExplicitFolderPermissions = !isClickedFolder
      ? thisFolderSave
        ? thisFolderSave.folderPermissions
        : thisFolderInitial.folderPermissions
      : [];

    const breakInheritance = folder.breakInheritance
      ? true
      : !isClickedFolder
      ? thisFolderSave
        ? thisFolderSave.breakInheritance
        : thisFolderInitial.breakInheritance
      : false;

    let folderPermissions: FolderPermission[] = [];
    if (breakInheritance) {
      folderPermissions =
        isClickedFolder && toggleAll
          ? permissions
          : currentExplicitFolderPermissions;
    } else {
      if (parentFolderInitial && parentFolder) {
        folderPermissions = [
          ...new Set([
            ...currentExplicitFolderPermissions,
            ...parentFolder.folderPermissions,
          ]),
        ];
      } else {
        folderPermissions = [
          ...new Set([...permissions, ...currentExplicitFolderPermissions]),
        ];
      }
    }

    const thisFolderUpdated = {
      ...thisFolder,
      breakInheritance,
      folderPermissions: removeExclusiveFolderPermissions({
        permissions: folderPermissions,
      }),
    };

    response = [...response, thisFolderUpdated];

    const childFolders = getChildFolders(
      folder.folderId,
      otherFolderPermissions
    );
    childFolders.forEach((folder) =>
      setChildFolders({ folder, parentFolder: thisFolderUpdated })
    );
  };

  setChildFolders({ folder: parent, parentFolder: null });

  const responseFolderIds = response.map((folder) => folder.folderId);
  const otherFoldersOutsideThisTree = otherFolderPermissions.filter(
    (folder) => !responseFolderIds.includes(folder.folderId)
  );
  return [...response, ...otherFoldersOutsideThisTree];
};
