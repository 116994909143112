import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

import { EditorCheckbox } from '../other';
import { UploaderEditorTypes } from '../types';

type ContentCellWrapperProps = PropsWithChildren<{
  id?: string;
  isSelected?: boolean;
  showCheckbox?: boolean;
}> &
  Pick<UploaderEditorTypes, 'selectDocument'> &
  Partial<Pick<UploaderEditorTypes, 'tabIndex'>>;

export const ContentCellWrapper = ({
  id,
  isSelected = false,
  children,
  selectDocument,
  showCheckbox = false,
  tabIndex,
}: ContentCellWrapperProps) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      maxHeight: '40px',
      mb: 1,
      minHeight: '40px',
      mr: 1,
      overflow: 'hidden',
      position: 'relative',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}
  >
    {showCheckbox && (
      <EditorCheckbox
        checked={isSelected}
        onChangeCallback={() => selectDocument && selectDocument(id || '')}
        tabIndex={tabIndex}
      />
    )}
    {children}
  </Box>
);
