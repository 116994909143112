import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ShowWithPermission } from '@pro4all/identity/ui';
import {
  Box,
  CircularProgress,
  IconButton,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import { DownloadZipModal } from './download-zip/DownloadZipModal';
import { FolderMetaDataSidebar } from './meta-data/FolderMetaDataSidebar';
import { FolderSidebar } from './sidebar/FolderSidebar';
import { useFolderTreeContextOuter } from './tree/FolderTreeProviderOuter';
import { Tree } from './tree/Tree';
import { DMSItem, DMSProjectLink, isFolder } from './tree/types';
import { useDropFolderOnRoot } from './tree/useDropFolderOnRoot';
import { useFolderTreeActions } from './utils/useFolderTreeActions';
import { useGeneralMenuActions } from './utils/useGeneralMenuActions';
import { useProjectLinkActions } from './utils/useProjectLinkActions';
import { FolderCreate } from './FolderCreate';
import { FolderRename } from './FolderRename';
import { useUploadSpreadsheetContext } from './ImportSpreadsheetContext';
import { LinkProject } from './LinkProject';

const StyledHeader = styled(Box)`
  && {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => `0 ${theme.spacing(2)} 0`};
  }
`;

const StyledFoldersLabel = styled.div<{
  hoversOverCurrent: boolean;
}>`
  background: ${({ hoversOverCurrent, theme }) =>
    hoversOverCurrent && theme.palette.primary.light};
`;

export const DocumentFolderTree: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { getRootProps, getInputProps } = useUploadSpreadsheetContext();
  const {
    state: { loadingFolderData },
  } = useFolderTreeContextOuter();

  const {
    buttonPosition: projectLinkButtonPosition,
    closeContextMenu: closeProjectLinkContextMenu,
    contextMenuIsOpen: projectLinkContextMenuIsOpen,
    handleOpenMenu: handleOpenProjectLinkMenu,
    menuItems: projectLinkMenuItems,
  } = useProjectLinkActions();

  const {
    buttonPosition,
    closeContextMenu,
    contextMenuIsOpen,
    onContextMenu,
    menuItems,
    targetFolder,
  } = useFolderTreeActions();

  const {
    handleOpen: openGeneralMenu,
    isOpen: generalMenuIsOpen,
    handleClose: closeGeneralMenu,
    menuItems: generalMenuItems,
    position: generalMenuPosition,
  } = useGeneralMenuActions();

  const creatingRootFolder = searchParams.is('action', 'createRootFolder');

  const targetFolderProjectsIds =
    targetFolder?.children
      .filter((child: DMSItem) => !isFolder(child))
      .map((projectLink: DMSProjectLink) => projectLink.projectId) ?? [];

  const getContextMenuItems = () => {
    if (generalMenuIsOpen) {
      return generalMenuItems;
    }
    if (contextMenuIsOpen) {
      return menuItems;
    }
    if (projectLinkContextMenuIsOpen) {
      return projectLinkMenuItems;
    }
    return [];
  };

  const getContextMenuPosition = () => {
    if (generalMenuIsOpen) {
      return generalMenuPosition;
    }
    if (contextMenuIsOpen) {
      return buttonPosition;
    }
    if (projectLinkContextMenuIsOpen) {
      return projectLinkButtonPosition;
    }
    return buttonPosition;
  };

  const isOpen =
    contextMenuIsOpen || generalMenuIsOpen || projectLinkContextMenuIsOpen;

  const handleCloseContextMenu = () => {
    closeGeneralMenu();
    closeContextMenu();
    closeProjectLinkContextMenu();
  };

  const { drop, hoversOverCurrent } = useDropFolderOnRoot();

  return (
    <>
      <StyledHeader>
        {drop(
          <div style={{ width: '100%' }}>
            <Text variant="h5">
              <StyledFoldersLabel
                hoversOverCurrent={Boolean(hoversOverCurrent)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Box>{t('Folders')}</Box>
                  {loadingFolderData ? (
                    <Box sx={{ flexGrow: 1, ml: 2 }}>
                      <CircularProgress size={16} />
                    </Box>
                  ) : null}
                </Box>
              </StyledFoldersLabel>
            </Text>
          </div>
        )}
        <ShowWithPermission appPermission="ProjectUpdate">
          <IconButton
            data-testid="folder-root-context-menu"
            onClick={openGeneralMenu}
          >
            <Icon iconName="moreHoriz" />
          </IconButton>
        </ShowWithPermission>
      </StyledHeader>
      <div {...getRootProps()}>
        <ContextMenu
          data-testid="context-menu"
          initialPosition={getContextMenuPosition()}
          menuItems={getContextMenuItems()}
          onClose={handleCloseContextMenu}
          open={isOpen}
        />
        <input {...getInputProps()} />
      </div>
      <Tree
        allowDragAndDrop
        onContextMenu={onContextMenu}
        onProjectLinkContextMenu={handleOpenProjectLinkMenu}
      />
      <FolderCreate
        parentFolder={creatingRootFolder ? undefined : targetFolder}
      />
      <FolderRename folder={targetFolder} />
      <FolderMetaDataSidebar folder={targetFolder} />
      <FolderSidebar folderId={targetFolder?.id} />
      <DownloadZipModal
        folder={targetFolder}
        hasSubFolders={Boolean(targetFolder?.hasSubFolders)}
      />
      <LinkProject
        folderId={targetFolder?.id}
        folderName={targetFolder?.name}
        folderPath={targetFolder?.path}
        folderProjectsIds={targetFolderProjectsIds}
      />
    </>
  );
};

export default DocumentFolderTree;
