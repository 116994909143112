import { client } from '@pro4all/authentication/src/graph-ql';
import {
  GetOrganizationTaskCategoriesByTypeDocument,
  GetProjectTaskCategoriesByTypeDocument,
  TaskCategory,
  TaskType,
  useCreateBatchTaskCategoryMutation,
  useDeleteBatchTaskCategoryMutation,
  useUpdateBatchTaskCategoryMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  EntityTypeTranslation,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';

export const useSubmit = () => {
  const [createBatchTaskCategory] = useCreateBatchTaskCategoryMutation();
  const [UpdateBatchTaskCategory] = useUpdateBatchTaskCategoryMutation();
  const [DeleteBatchTaskCategory] = useDeleteBatchTaskCategoryMutation();

  const { organizationId } = useOrganizationContext();
  const {
    params: { projectId },
  } = useRouting();
  const { showMutationMessage, showSingleError } = useShowMessages();
  return async ({
    originalCategories,
    categories,
    taskType,
    resetForm,
  }: {
    categories: TaskCategory[];
    originalCategories: TaskCategory[];
    resetForm: () => void;
    taskType: Option;
  }) => {
    const categoriesToUpdate = categories
      .filter((cat) =>
        originalCategories?.find(
          (oc) =>
            oc.taskCategoryId === cat.taskCategoryId && oc.name !== cat.name
        )
      )
      .map(({ taskType, __typename, ...rest }) => rest);

    const newCategories = categories.filter((cat) => !cat.taskCategoryId);
    const newCategoryNames = newCategories.map((cat) => cat.name);
    const categoriesToDelete = originalCategories
      ?.filter(
        (cat) =>
          !categories
            .map((cat) => cat.taskCategoryId)
            .includes(cat.taskCategoryId)
      )
      .map(({ __typename, taskType, ...rest }) => rest);
    try {
      let createdCategoriesIds: string[] = [];
      if (newCategoryNames.length > 0) {
        const createResult = await createBatchTaskCategory({
          variables: {
            names: newCategoryNames,
            organizationId,
            projectId,
            taskType: taskType.id as TaskType,
          },
        });
        createdCategoriesIds = createResult.data?.createBatchTaskCategory.ids;
        const createdMoreThanOne = newCategoryNames.length > 1;
        showMutationMessage({
          action: createdMoreThanOne
            ? MessageAction.CreateMultiple
            : MessageAction.Create,
          entityType: createdMoreThanOne
            ? EntityTypeTranslation.TaskCategories
            : EntityTypeTranslation.TaskCategory,
          name: newCategoryNames.join(', '),
        });
      }
      if (categoriesToUpdate.length > 0) {
        await UpdateBatchTaskCategory({
          variables: {
            taskCategories: categoriesToUpdate,
          },
        });
        const updatedMoreThanOne = categoriesToUpdate.length > 1;
        const updatedCategoryNames = categoriesToUpdate.map((cat) => cat.name);
        showMutationMessage({
          action: updatedMoreThanOne
            ? MessageAction.UpdateMultiple
            : MessageAction.Update,
          entityType: updatedMoreThanOne
            ? EntityTypeTranslation.TaskCategories
            : EntityTypeTranslation.TaskCategory,
          name: updatedCategoryNames.join(', '),
        });
      }

      if (categoriesToDelete.length > 0) {
        const deletedMoreThanOne = categoriesToDelete.length > 1;
        const deletedCategoryNames = categoriesToDelete.map((cat) => cat.name);
        await DeleteBatchTaskCategory({
          variables: {
            taskCategories: categoriesToDelete,
          },
        });
        showMutationMessage({
          action: deletedMoreThanOne
            ? MessageAction.DeleteMultiple
            : MessageAction.Delete,
          entityType: deletedMoreThanOne
            ? EntityTypeTranslation.TaskCategories
            : EntityTypeTranslation.TaskCategory,
          name: deletedCategoryNames.join(', '),
        });
      }

      const projectCategories = categories
        .filter((cat) => cat.projectId)
        .map((cat) => ({
          __typename: 'TaskCategory',
          name: cat.name,
          organizationId,
          projectId: cat.projectId,
          taskCategoryId: cat.taskCategoryId ?? createdCategoriesIds.shift(),
          taskType: taskType.id as TaskType,
        }));
      client.writeQuery({
        data: {
          getProjectTaskCategoriesByType: projectCategories,
        },
        query: GetProjectTaskCategoriesByTypeDocument,
        variables: { projectId, taskType: taskType.id as TaskType },
      });

      const organizationCategories = categories
        .filter((cat) => !cat.projectId)
        .map((cat) => ({
          __typename: 'TaskCategory',
          name: cat.name,
          organizationId,
          projectId: null,
          taskCategoryId: cat.taskCategoryId ?? createdCategoriesIds.shift(),
          taskType: taskType.id as TaskType,
        }));

      if (organizationCategories.length > 0) {
        client.writeQuery({
          data: {
            getOrganizationTaskCategoriesByType: organizationCategories,
          },
          query: GetOrganizationTaskCategoriesByTypeDocument,
          variables: { taskType: taskType.id as TaskType },
        });
      }
      resetForm();
    } catch (e) {
      showSingleError(e);
    }
  };
};
