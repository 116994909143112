import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@pro4all/shared/ui/buttons';
import { BigMessage } from '@pro4all/shared/ui/messages';

interface Props {
  children?: never;
  retry: () => void;
}

export const ImageNotFound: React.FC<Props> = ({ retry }) => {
  const { t } = useTranslation();

  const text = (
    <Trans
      components={{
        retry: <Button onClick={retry} variant="text" />,
      }}
      defaults="<retry>Try to load the image again</retry><br> or contact support."
      i18nKey="BigMessage.noPreview"
    />
  );

  return (
    <BigMessage
      shapeName="flow1"
      text={text}
      title={t('No preview available')}
    />
  );
};
