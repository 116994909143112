import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoProjectAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Please ask an administrator for access and permissions to this project.'
      )}
      title={t("Hmm, it seems that you don't have access")}
    />
  );
};
