import React from 'react';
import styled from 'styled-components';

import { Link as MuiLink, LinkProps } from '@pro4all/shared/mui-wrappers';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

const StyledIcon = styled(Icon)`
  margin-right: 4px;
  font-size: 1rem;
  vertical-align: text-bottom;
`;

interface Props extends LinkProps {
  startIcon?: IconName;
}

export const Link: React.FC<Props> = ({
  children,
  color = 'primary',
  startIcon,
  onClick,
  href,
  ...rest
}) => (
  <MuiLink
    color={color}
    component={href ? 'a' : 'button'}
    href={href}
    onClick={onClick}
    {...(href ? {} : { type: 'button' })}
    underline="hover"
    {...rest}
  >
    {startIcon && <StyledIcon fontSize="inherit" iconName={startIcon} />}
    {children}
  </MuiLink>
);
