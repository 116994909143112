import { FieldDefinition, Maybe } from '@pro4all/graphql';

export const findIndicative = (
  fields: FieldDefinition[] | Maybe<FieldDefinition[]> | undefined
) => {
  let returnField = {};
  fields?.some((field: FieldDefinition) => {
    if (field.type === 'Section') {
      returnField = findIndicative(field?.valueType?.subFields);
    } else {
      returnField =
        field.type === 'Status' && field.indicative === true ? field : {};
    }
    return Object.keys(returnField).length !== 0;
  });
  return returnField;
};
