import React from 'react';
import { useSnackbar } from 'notistack';

import { Alert } from '@pro4all/shared/mui-wrappers';

import { SnackbarContext } from './SnackbarContext';

interface Props {
  id: number | string;
  message?: React.ReactNode | number | string;
}

// Using this wrapper to hold a ref and parse message
export const Snackbar = React.forwardRef<HTMLDivElement, Props>(
  ({ id, message }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = () => {
      closeSnackbar(id);
    };

    const styledMessage =
      typeof message === 'string' ? (
        <Alert severity="info">{message}</Alert>
      ) : React.isValidElement(message) ? (
        React.cloneElement(message)
      ) : (
        message
      );

    return (
      <SnackbarContext.Provider value={handleDismiss}>
        <div ref={ref}>{styledMessage}</div>
      </SnackbarContext.Provider>
    );
  }
);

export default Snackbar;
