import {
  Document,
  DocumentMetaData,
  ValueType,
  ValueTypeName,
} from '@pro4all/graphql';
import {
  FilterColumnProps,
  FilterHeaderType,
} from '@pro4all/shared/ui/filtering';
import { BaseRow } from '@pro4all/shared/ui/general';

import { FilterHeader } from './FilterHeader';

export function useMetaDataHeader<Row extends BaseRow>() {
  const getMetaDataHeader = ({
    fieldDefinition,
    idProp,
    idPropFallback,
    multiSelectProp,
    nameProp,
    onColumnResizeCallback,
    pullFromLocalStorage,
    typeProp,
    valueTypeProp,
  }: Pick<
    FilterColumnProps,
    'onColumnResizeCallback' | 'pullFromLocalStorage'
  > & {
    fieldDefinition: Row;
    idProp: keyof Row;
    idPropFallback: keyof Row;
    multiSelectProp: keyof ValueType;
    nameProp: keyof Row;
    typeProp: keyof Row;
    valueTypeProp: keyof Row;
  }) => {
    const mapValueType = () => {
      // Map ValueTypeName to FilterHeaderType.
      const valueType = fieldDefinition[typeProp] as unknown as ValueTypeName;
      switch (valueType) {
        case ValueTypeName.Number:
          return FilterHeaderType.Number;
        case ValueTypeName.DateTime:
          return FilterHeaderType.Date;
        case ValueTypeName.Text:
        case ValueTypeName.Description:
          return FilterHeaderType.Text;
        case ValueTypeName.Bool:
        case ValueTypeName.MultiSelect:
        case ValueTypeName.Selection:
        case ValueTypeName.Status:
        default:
          return FilterHeaderType.Select;
      }
    };

    const filterType = mapValueType();
    const headerLabel = fieldDefinition[nameProp] as unknown as string;
    const headerId = (fieldDefinition[idProp] ||
      fieldDefinition[idPropFallback]) as unknown as string;
    const valueTypes = fieldDefinition[valueTypeProp] as ValueType;

    return (
      <FilterHeader<Document, DocumentMetaData>
        defaultWidth={200}
        filterType={filterType}
        isMetaData
        isMultiSelect={Boolean(valueTypes[multiSelectProp])}
        label={headerLabel}
        metaDataHeaderId={headerId}
        minWidth={70}
        onColumnResizeCallback={onColumnResizeCallback}
        propertyId="metaData"
        pullFromLocalStorage={pullFromLocalStorage}
        subPropertyId="answers"
      />
    );
  };
  return getMetaDataHeader;
}
