import styled, { css } from 'styled-components';

const sectionHeading = css`
  color: #19a49a;
  font-size: 1.375rem;
  margin-top: 2rem;
`;

const fieldHeading = css`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

export const Heading = styled.h3<{ $depth: number }>`
  font-weight: 500;
  line-height: 1.6;
  margin: 0;
  ${({ $depth }) => ($depth < 1 ? sectionHeading : fieldHeading)};
`;

export const Fields = styled.div<{ $depth: number }>`
  ${({ $depth }) =>
    $depth &&
    `
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  `}
`;

export const Section = styled.section`
  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;
