import React from 'react';
import styled from 'styled-components';

import { useSideNavContext } from '@pro4all/shared/contexts';
import { Box, LinearProgress } from '@pro4all/shared/mui-wrappers';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['navIsOpen'].includes(prop),
})<{
  navIsOpen?: boolean;
}>`
  --position-left-sm: ${({ navIsOpen }) =>
    navIsOpen
      ? ({ theme }) => theme.spacing(37)
      : ({ theme }) => theme.spacing(12)};
  --position-right-sm: ${({ theme }) => theme.spacing(1)};

  --position-left-md: ${({ navIsOpen }) =>
    navIsOpen
      ? ({ theme }) => theme.spacing(39)
      : ({ theme }) => theme.spacing(13.5)};
  --position-right-md: ${({ theme }) => theme.spacing(3)};

  position: fixed;
  top: ${({ theme }) => theme.spacing(3)};
  left: var(--position-left-sm);
  right: var(--position-right-sm);
  box-shadow: 0px 0px 12px 11px ${customColors.grey200};
  border-radius: 20px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    left: var(--position-left-md);
    right: var(--position-right-md);
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  && {
    height: 16px;
    border-radius: ${StylingDefaults.borderRadius};
  }
`;

const StyledProgressText = styled(Box)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
  }
`;

export interface ProgressBarProps {
  current: number;
  maximum: number;
  text?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  current,
  maximum,
  text,
}) => {
  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  return (
    <Container navIsOpen={isSideNavOpen}>
      <StyledLinearProgress
        color="secondary"
        data-testid="upload-progress"
        value={(current / maximum) * 100}
        variant="determinate"
      />
      {Boolean(text) && (
        <StyledProgressText>
          <Text variant="body2">{text}</Text>
        </StyledProgressText>
      )}
    </Container>
  );
};
