import { unflattenFolders } from '../helpers/unflattenFolders';
import { SetInitialFolderTreePayload } from '../types';
import { State } from '../types';

export const setInitialFolderTreeAction = ({
  payload,
  state,
}: {
  payload: SetInitialFolderTreePayload;
  state: State;
}) => {
  const { folders, foldersPermissions, path } = payload;

  const payloadFolderIds = folders ? folders.map((folder) => folder.id) : [];
  const stateFoldersExcludingPayloadFolders = state.folders.filter(
    (folder) => !payloadFolderIds.includes(folder.id)
  );
  const foldersToProcess = folders
    ? [...folders, ...stateFoldersExcludingPayloadFolders]
    : state.folders;

  const foldersWithSelectionInfo = getFoldersWithSelectionInfo({
    folders: foldersToProcess,
    path,
  });

  const payloadFoldersPermissionsIds = foldersPermissions
    ? foldersPermissions.map((folder) => folder.id)
    : [];
  const stateFoldersPermissionsExcludingPayloadFolders =
    state.foldersPermissions.filter(
      (folder) => !payloadFoldersPermissionsIds.includes(folder.id)
    );
  const foldersPermissionsToProcess = foldersPermissions
    ? [...foldersPermissions, ...stateFoldersPermissionsExcludingPayloadFolders]
    : state.foldersPermissions;

  return {
    ...state,
    folderTree: unflattenFolders(foldersWithSelectionInfo),
    folders: foldersWithSelectionInfo,
    foldersPermissions: foldersPermissionsToProcess,
  };
};

const getFoldersWithSelectionInfo = (payload: SetInitialFolderTreePayload) => {
  const { folders, path } = payload;

  if (!path) return folders;

  const pathIds = path
    .split('/')
    .reduce((acc, pathPart) => {
      const lastFolder = acc.length ? acc[acc.length - 1] : { id: null };
      const newFolder = folders.find(
        (folder) =>
          folder?.parentFolderId === lastFolder.id &&
          folder?.name.toLowerCase() === pathPart.toLowerCase()
      );
      return newFolder ? [...acc, newFolder] : acc;
    }, [])
    .filter(Boolean)
    .map((folder) => folder?.id);

  const response = folders.map((folder) => ({
    ...folder,
    isParentOfSelected: pathIds.includes(folder.id),
    isSelected: pathIds[pathIds.length - 1] === folder.id,
  }));

  return response;
};
