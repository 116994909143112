import React, { useState } from 'react';

import { Option, ReportConfigTypes } from '@pro4all/shared/types';

import { ConfigureImageColumns } from './ConfigureImageColumns';
import { ConfigureSectionsAndFields } from './ConfigureSectionsAndFields';
import { ConfigureSelectTemplate } from './ConfigureSelectTemplate';
import { ConfigureShowDescription } from './ConfigureShowDescription';

export const InstanceCustomOptionsSnag = ({
  templateOptions,
}: Pick<ReportConfigTypes, 'templateOptions'>) => {
  const [template, setTemplate] = useState<Option | null>(null);

  return (
    <>
      <ConfigureSelectTemplate
        elementIdentifier="snag-template-select"
        setTemplate={setTemplate}
        template={template}
        templateOptions={templateOptions}
      />

      {template && templateOptions.length > 0 && (
        <>
          <ConfigureImageColumns template={template} />
          <ConfigureShowDescription
            elementIdentifier="snag-show-description"
            template={template}
          />
          <ConfigureSectionsAndFields template={template} />
        </>
      )}
    </>
  );
};
