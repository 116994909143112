import { FolderPermission } from '@pro4all/graphql';

import {
  isDeleteAll,
  isDeleteNone,
  isDeleteOwn,
  isDocumentsAll,
  isDocumentsNone,
  isDocumentsOwn,
  isDocumentsOwnAndFinalized,
  isUpdateAll,
  isUpdateNone,
  isUpdateOwn,
  isVersionsAll,
  isVersionsApproved,
  isVersionsLast,
  isVersionsLastAndApproved,
  isVersionsLastApproved,
  isVersionsOwn,
} from './checkPermissionsWrappers';

export const getExclusivePermissionsBasedOnParentPermissions = ({
  currentSubFolderPermissions, // Subfolder permissions as they were before the user selected a permission.
  parentFolderPermissions, // Parent folder permissions to check if the subfolder permissions should be changed.
  subFolderPermissions, // Subfolder permission as they are after the user selected a permission but before the possible revert.
}: {
  currentSubFolderPermissions: FolderPermission[];
  parentFolderPermissions: FolderPermission[];
  subFolderPermissions: FolderPermission[];
}) => {
  // This method calculates if the selected permission should be reverted and then returns:
  // 1. All permissions of the parent folder including all nested subfolders.
  // 2. The permission that should was reverted to.

  let permissionRevertedTo: FolderPermission = null;
  let permissionsResponse = subFolderPermissions;

  const getPermissionsDocumentsRevert = (
    permissionToRevertTo: FolderPermission
  ) => {
    permissionRevertedTo = permissionToRevertTo;
    return [
      ...new Set([
        ...permissionsResponse.filter(
          // First remove all documents permissions.
          (perm) =>
            perm !== FolderPermission.ReadAllDocuments &&
            perm !== FolderPermission.ReadOwnAndFinalized &&
            perm !== FolderPermission.ReadOwn
        ),
        permissionToRevertTo, // Then add the documents permission to revert to.
      ]),
    ];
  };

  const getPermissionsVersionsRevert = (
    permissionToRevertTo: FolderPermission
  ) => {
    permissionRevertedTo = permissionToRevertTo;
    return [
      ...new Set([
        ...permissionsResponse.filter(
          // First remove all versions permissions.
          (perm) =>
            perm !== FolderPermission.ReadAllVersions &&
            perm !== FolderPermission.ReadLastVersionAndApproved &&
            perm !== FolderPermission.ReadApproval &&
            perm !== FolderPermission.ReadLastApproval &&
            perm !== FolderPermission.ReadLastVersion &&
            perm !== FolderPermission.ReadOwnVersions
        ),
        permissionToRevertTo, // Then add the version permission to revert to.
      ]),
    ];
  };

  const getPermissionsDeleteRevert = (
    permissionToRevertTo: FolderPermission
  ) => {
    permissionRevertedTo = permissionToRevertTo;
    return [
      ...new Set([
        ...permissionsResponse.filter(
          // First remove all delete permissions.
          (perm) =>
            perm !== FolderPermission.DeleteContent &&
            perm !== FolderPermission.DeleteOwnContent
        ),
        permissionToRevertTo, // Then add the delete permission to revert to.
      ]),
    ];
  };

  const getPermissionsUpdateRevert = (
    permissionToRevertTo: FolderPermission
  ) => {
    permissionRevertedTo = permissionToRevertTo;
    return [
      ...new Set([
        ...permissionsResponse.filter(
          // First remove all updaqte permissions.
          (perm) =>
            perm !== FolderPermission.UpdateContent &&
            perm !== FolderPermission.UpdateOwn
        ),
        permissionToRevertTo, // Then add the update permission to revert to.
      ]),
    ];
  };

  const revertDocumentPermission = () => {
    if (isDocumentsAll(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsDocumentsRevert(
        FolderPermission.ReadAllDocuments
      );
    }
    if (isDocumentsOwnAndFinalized(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsDocumentsRevert(
        FolderPermission.ReadOwnAndFinalized
      );
    }
    if (isDocumentsOwn(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsDocumentsRevert(
        FolderPermission.ReadOwn
      );
    }
  };

  const revertVersionPermission = () => {
    if (isVersionsAll(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsVersionsRevert(
        FolderPermission.ReadAllVersions
      );
    }
    if (isVersionsLastAndApproved(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsVersionsRevert(
        FolderPermission.ReadLastVersionAndApproved
      );
    }
    if (isVersionsApproved(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsVersionsRevert(
        FolderPermission.ReadApproval
      );
    }
    if (isVersionsLastApproved(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsVersionsRevert(
        FolderPermission.ReadLastApproval
      );
    }
    if (isVersionsLast(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsVersionsRevert(
        FolderPermission.ReadLastVersion
      );
    }
    if (isVersionsOwn(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsVersionsRevert(
        FolderPermission.ReadOwnVersions
      );
    }
  };

  const revertDeletePermission = () => {
    if (isDeleteOwn(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsDeleteRevert(
        FolderPermission.DeleteOwnContent
      );
    }
    if (isDeleteAll(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsDeleteRevert(
        FolderPermission.DeleteContent
      );
    }
  };

  const revertUpdatePermission = () => {
    if (isUpdateOwn(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsUpdateRevert(
        FolderPermission.UpdateOwn
      );
    }
    if (isUpdateAll(currentSubFolderPermissions)) {
      permissionsResponse = getPermissionsUpdateRevert(
        FolderPermission.UpdateContent
      );
    }
  };

  if (
    isDocumentsAll(parentFolderPermissions) &&
    (isDocumentsNone(subFolderPermissions) ||
      isDocumentsOwnAndFinalized(subFolderPermissions) ||
      isDocumentsOwn(subFolderPermissions))
  ) {
    revertDocumentPermission();
  }
  if (
    isDocumentsOwnAndFinalized(parentFolderPermissions) &&
    (isDocumentsNone(subFolderPermissions) ||
      isDocumentsOwn(subFolderPermissions))
  ) {
    revertDocumentPermission();
  }
  if (
    isDocumentsOwn(parentFolderPermissions) &&
    isDocumentsNone(subFolderPermissions)
  ) {
    revertDocumentPermission();
  }

  // Check exclusive permissions for Versions
  if (
    isVersionsAll(parentFolderPermissions) &&
    (isVersionsLastAndApproved(subFolderPermissions) ||
      isVersionsApproved(subFolderPermissions) ||
      isVersionsLastApproved(subFolderPermissions) ||
      isVersionsLast(subFolderPermissions) ||
      isVersionsOwn(subFolderPermissions))
  ) {
    revertVersionPermission();
  }
  if (
    isVersionsLastAndApproved(parentFolderPermissions) &&
    (isVersionsApproved(subFolderPermissions) ||
      isVersionsLastApproved(subFolderPermissions) ||
      isVersionsLast(subFolderPermissions) ||
      isVersionsOwn(subFolderPermissions))
  ) {
    revertVersionPermission();
  }
  if (
    isVersionsApproved(parentFolderPermissions) &&
    (isVersionsLastApproved(subFolderPermissions) ||
      isVersionsLast(subFolderPermissions) ||
      isVersionsOwn(subFolderPermissions))
  ) {
    revertVersionPermission();
  }
  if (
    isVersionsLastApproved(parentFolderPermissions) &&
    (isVersionsLast(subFolderPermissions) ||
      isVersionsOwn(subFolderPermissions))
  ) {
    revertVersionPermission();
  }
  if (
    isVersionsLast(parentFolderPermissions) &&
    isVersionsOwn(subFolderPermissions)
  ) {
    revertVersionPermission();
  }

  // Check exclusive permissions for Delete
  if (
    isDeleteAll(parentFolderPermissions) &&
    (isDeleteOwn(subFolderPermissions) || isDeleteNone(subFolderPermissions))
  ) {
    revertDeletePermission();
  }
  if (
    isDeleteOwn(parentFolderPermissions) &&
    isDeleteNone(subFolderPermissions)
  ) {
    revertDeletePermission();
  }

  // Check exclusive permissions for Update
  if (
    isUpdateAll(parentFolderPermissions) &&
    (isUpdateOwn(subFolderPermissions) || isUpdateNone(subFolderPermissions))
  ) {
    revertUpdatePermission();
  }
  if (
    isUpdateOwn(parentFolderPermissions) &&
    isUpdateNone(subFolderPermissions)
  ) {
    revertUpdatePermission();
  }

  return { permissionRevertedTo, subFolderPermissions: permissionsResponse };
};
