import React from 'react';

import { SvgIcon } from '../svg';

export const Edit = () => (
  <SvgIcon fill="currentColor">
    <path
      clipRule="evenodd"
      d="M13.8058 3.75426C14.0658 4.01426 14.0658 4.43426 13.8058 4.69426L12.5858 5.91426L10.0858 3.41426L11.3058 2.19426C11.4304 2.06942 11.5995 1.99927 11.7758 1.99927C11.9522 1.99927 12.1213 2.06942 12.2458 2.19426L13.8058 3.75426ZM1.99915 13.6676V11.6409C1.99915 11.5476 2.03248 11.4676 2.09915 11.4009L9.37248 4.12759L11.8725 6.62759L4.59248 13.9009C4.53248 13.9676 4.44581 14.0009 4.35915 14.0009H2.33248C2.14581 14.0009 1.99915 13.8543 1.99915 13.6676Z"
      fillRule="evenodd"
      transform="translate(4, 4)"
    />
  </SvgIcon>
);
