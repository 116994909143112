import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const UndoFinalize: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M17.4 7.4H15.498L17.022 5.876C17.256 5.642 17.256 5.264 17.022 5.03C16.788 4.796 16.404 4.796 16.17 5.03L13.8 7.4H12.6V6.2L14.97 3.83C15.204 3.596 15.204 3.212 14.97 2.978C14.8579 2.86565 14.7057 2.80251 14.547 2.80251C14.3883 2.80251 14.2361 2.86565 14.124 2.978L12.6 4.502V2.6C12.6 2.27 12.33 2 12 2C11.67 2 11.4 2.27 11.4 2.6V4.502L9.876 2.978C9.7639 2.86565 9.61171 2.80251 9.453 2.80251C9.29429 2.80251 9.1421 2.86565 9.03 2.978C8.796 3.212 8.796 3.596 9.03 3.83L11.4 6.2V7.4H10.2L7.83 5.03C7.596 4.796 7.212 4.796 6.978 5.03C6.744 5.264 6.744 5.642 6.978 5.876L8.502 7.4H6.6C6.27 7.4 6 7.67 6 8C6 8.33 6.27 8.6 6.6 8.6H8.502L6.978 10.124C6.744 10.358 6.744 10.736 6.978 10.97C7.212 11.204 7.596 11.204 7.83 10.97L10.2 8.6H11.4V9.8L9.03 12.17C8.796 12.404 8.796 12.788 9.03 13.022C9.264 13.256 9.642 13.256 9.876 13.022L11.4 11.498V13.4C11.4 13.73 11.67 14 12 14C12.33 14 12.6 13.73 12.6 13.4V11.498L14.124 13.022C14.358 13.256 14.736 13.256 14.97 13.022C15.204 12.788 15.204 12.404 14.97 12.17L12.6 9.8V8.6H13.8L16.17 10.97C16.404 11.204 16.788 11.204 17.022 10.97C17.256 10.736 17.256 10.358 17.022 10.124L15.498 8.6H17.4C17.73 8.6 18 8.33 18 8C18 7.67 17.73 7.4 17.4 7.4Z" />
    <path d="M12.3717 16C10.2588 16 8.3453 16.7893 6.87029 18.073L5.36339 16.5661C4.86109 16.0638 4 16.4146 4 17.1242V21.5811C4 22.0196 4.35879 22.3784 4.7973 22.3784H9.25423C9.96383 22.3784 10.3226 21.5173 9.82031 21.015L8.29746 19.4922C9.40571 18.5673 10.8169 17.9933 12.3797 17.9933C14.8991 17.9933 17.0758 19.4603 18.1123 21.5811C18.3275 22.0276 18.8378 22.2509 19.3082 22.0914C19.8743 21.908 20.1613 21.2622 19.9062 20.72C18.5348 17.9295 15.6805 16 12.3717 16Z" />
  </SvgIcon>
);
