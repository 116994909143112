import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const SmartFolderRounded: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M10.59 4.59C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-1.41-1.41z" />
    <path
      d="M14.071 10.5314v3.75a.418.418 0 00.4167.4167h.8333v2.9791c0 .2125.2792.2875.3875.1042l2.1625-3.7083c.1625-.2792-.0375-.625-.3583-.625h-.9417l1.0375-2.7709c.1042-.2708-.0958-.5625-.3875-.5625h-2.7333a.418.418 0 00-.4167.4167z"
      fill="#fff"
    />
  </SvgIcon>
);
