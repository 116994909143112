import { State } from '../useUploaderEditorReducer';

export const addDocumentToUploadedArrayAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const { documentIdsUploaded } = state;

  return {
    ...state,
    documentIdsUploaded: [...documentIdsUploaded, payload],
  };
};
