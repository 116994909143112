import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { ScopeType, TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';

import { RedirectToOrganizationTemplates } from '../components/form-template/RedirectToOrganizationTemplates';
import { TemplateFrame } from '../components/form-template/TemplateFrame';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';
import { useIncludeOrgItems } from './useIncludeOrgItems';

export const ProjectDocuments = () => {
  const routeTemplates = useRouteMatch(
    Routes.projectMetaDataDocumentsTemplates
  );
  const routeItems = useRouteMatch(Routes.projectMetaDataDocumentsFields);

  const includeOrgItems = useIncludeOrgItems();

  const templateType = routeTemplates
    ? TemplateType.Document
    : routeItems
    ? TemplateType.Field
    : undefined;
  const templateService = TemplateService.Documents;

  const { selectedTemplate } = useTemplatesInclude({
    includeOrgItems,
    includeScope: true,
    templateService,
    templateType,
  });

  const { params, searchParams } = useRouting();
  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');
  const scope = selectedTemplate?.scope.type;

  const { projectId } = params;
  const base = `${generateRoute('projectMetaDataDocuments', {
    params: { projectId },
  })}/`;
  const redirect = `${generateRoute('projectMetaDataDocumentsTemplates', {
    params: { projectId },
  })}`;

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <Route exact path={Routes.projectMetaDataDocuments}>
        <Redirect to={redirect} />
      </Route>
      <Route
        exact
        path={[
          Routes.projectMetaDataDocumentsFields,
          Routes.projectMetaDataDocumentsTemplates,
        ]}
        render={() =>
          !createContext && !editContext ? (
            <MetaDataMain
              base={base}
              isProject
              pathFields={Routes.projectMetaDataDocumentsFields}
              pathTemplates={Routes.projectMetaDataDocumentsTemplates}
            />
          ) : (editContext && scope === ScopeType.Project) || createContext ? (
            <TemplateFrame selectedTemplate={selectedTemplate} />
          ) : (
            scope === ScopeType.Organization && (
              <RedirectToOrganizationTemplates
                selectedTemplate={selectedTemplate}
              />
            )
          )
        }
      />
    </MetaDataContextProvider>
  );
};
