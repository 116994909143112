import React, { useState } from 'react';

import {
  TextArea as TextAreaComponent,
  TextFieldProps,
} from '@pro4all/shared/ui/general';

interface Props extends TextFieldProps {
  setValue: (name: string, value: string) => void;
}

export const TextArea: React.FC<Props> = ({
  name,
  setValue,
  value,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
    setValue(name, newValue);
  };

  return (
    <TextAreaComponent
      {...rest}
      multiline
      name={name}
      onChange={handleChange}
      rows={1}
      value={currentValue}
    />
  );
};
