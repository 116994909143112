import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneState } from 'react-dropzone';

import { Folder } from '@pro4all/graphql';
import { useDocumentActions } from '@pro4all/shared/hooks/src/documents';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

export interface DocumentActionBarProps {
  folderId?: Folder['id'];
  isSpecialFolder?: boolean;
  openFileInput?: DropzoneState['open'];
  setActionLoading: Dispatch<SetStateAction<boolean>>;
  showExportButton?: boolean;
  showTableActions?: boolean;
}

export const DocumentsActionBar: React.FC<DocumentActionBarProps> = ({
  folderId,
  openFileInput,
  isSpecialFolder,
  setActionLoading,
  showExportButton = true,
  showTableActions = true,
}) => {
  const { altActions, mainActions } = useDocumentActions({
    folderId,
    isSpecialFolderProp: isSpecialFolder,
    openFileInput,
    setLoading: setActionLoading,
  });

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="document-action-bar"
      dataViewToggle
      mainActions={mainActions}
      search
      showExportButton={showExportButton}
      showTableActions={showTableActions}
    />
  );
};
