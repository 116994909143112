import { useMapLinkingContext } from '@pro4all/quality-control/ui/maps';

import { FieldsProps } from './SectionsAndFieldsList.types';

type Props = Pick<FieldsProps, 'fields' | 'unnestedItems'>;

export const useSetFields = ({ fields, unnestedItems }: Props) => {
  const {
    fields: contextFields,
    setFields,
    tbqInstance,
  } = useMapLinkingContext();

  if (fields && !contextFields) {
    setFields(unnestedItems);
  }

  return { tbqInstance };
};
