export const environment = {
  authentication: {
    // IO
    authUrl: 'https://auth.prostream.dev',
    clientId: '98d44a3c-1e7f-414c-b51a-de7b79f4fc1f',
    issuer: 'https://auth.prostream.dev/',

    // Production
    // authUrl: 'https://auth.prostream.app',
    // clientId: '<<GET_THIS_FROM_AN_ADMIN>>',
    // issuer: 'https://auth.prostream.app/',
    publicUrl: window.location.origin,
  },
  production: false,
};
