import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Text } from '@pro4all/shared/ui/typography';

import { InitialsBadge } from '../initials';

const FullName = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  display: flex;
  margin-left: 35px;
  margin-top: 0.1%;
  width: auto;
  max-width: 200px;
  border-radius: 30px;
  &:hover {
    background-color: #eeeeee;
  }
  > div.user-name {
    cursor: pointer;
    display: block;
    margin-left: 9px;
    margin-top: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface Position {
  left: number;
  top: number;
}

export const UserFullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  const profileRef = useRef<HTMLInputElement>(null);

  const [position, setPosition] = useState<Position>();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const showDeviceUi = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const { goTo } = useRouting();

  const handleOpen = (event: React.MouseEvent) => {
    event && event.preventDefault();
    if (profileRef.current) {
      setPosition({
        left: profileRef.current.offsetLeft,
        top: profileRef.current.offsetTop + 47,
      });
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setPosition(undefined);
    setIsOpen(false);
  };

  const goToProfileAction: ActionProps = {
    ariaLabel: 'My profile',
    dataTestId: 'my-profile',
    disabled: false,
    key: 'my-profile',
    label: t('My profile'),
    onClick: (_) => goTo('myProfile'),
    startIcon: 'person',
  };

  const logOutAction: ActionProps = {
    ariaLabel: 'Sign out',
    dataTestId: 'sign-out',
    disabled: false,
    key: 'sign-out',
    label: t('Sign out'),
    onClick: (_) => goTo('logout'),
    startIcon: 'logout',
  };

  const menuItems: ActionProps[] = [goToProfileAction, logOutAction];

  const styles = {
    height: '38px',
    width: '38px',
  };

  return (
    <FullName
      data-testid="profile-button"
      isOpen={isOpen}
      onClick={handleOpen}
      ref={profileRef}
    >
      <InitialsBadge
        firstInitial={firstName?.charAt(0).toUpperCase()}
        secondInitial={lastName?.charAt(0).toUpperCase()}
        styles={styles}
      />

      {!showDeviceUi && (
        <div className="user-name">
          <Text variant="h5">
            {firstName} {lastName}
          </Text>
        </div>
      )}

      <ContextMenu
        data-testid="context-menu"
        initialPosition={position}
        menuItems={menuItems}
        onClose={() => {
          handleClose();
        }}
        open={isOpen}
      />
    </FullName>
  );
};
