import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Collaborate: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M84.507 78.958c0 .022.022.047.036.068-.036.129-.069.273-.099.39.02-.15.039-.305.063-.458z"
      fill={colors.secondary}
    />
    <path
      d="M165.499 71.612l7.952 6.238 7.946-6.24-7.946-6.236-7.952 6.238z"
      fill={colors.primary}
    />
    <path
      d="M240 48.965c-.063 5.034-4.774 4.048-8.776 4.16-34.335-1.455-48.194 21.888-74.303 35.844-27.955 13.494-66.93 8.334-89.95-12.345C50.428 63.19 36.375 53.308 9.3 53.01l-2.116-.906c-.819-.287-1.597-.546-2.35-.794V44.65c29.725-2.73 50.536 8.804 71.673 26.964 23.147 21.394 67.489 20.7 89.52.415 2.683-2.029 5.304-4.139 7.955-6.233 16.158-13.409 35.341-21.408 57.235-21.419 4.114-.259 8.769.112 8.782 4.587z"
      fill={colors.secondary}
    />
    <path
      d="M239.318 91.388c-1.092 6.278-6.732 4.485-12.891 4.99-24.342.62-43.471-9.77-60.653-24.553l-.273-.216c-13.103-11.793-34.618-21.473-58.21-17.902 2.58-.385 5.231-.65 8.067-.792-10.646-6.063-21.487-5.375-29.447-2.891 28.35-11.616 65.269-5.154 87.542 15.35 14.27 12.23 31.206 23.032 52.961 22.254 6.036.025 12.312-2.077 12.904 3.76z"
      fill={colors.secondary}
    />
    <path
      d="M228.488 53.166h-.038c-16.265.819-27.755 5.78-37.299 11.919-23.92 15.38-35.628 38.18-79.624 21.951 1.212.142 8.25.579 8.643.584 33.474.502 48.757-21.629 67.866-31.88 1.01-.546 22.975-9.554 39.93-2.776l.522.202zM116.922 52.948l-1.419.065c-1.638.071-8.127.688-8.215.694-15.978 1.029-23.876 6.633-36.853 16.77-4.35 3.395-21.338 15.59-25.837 17.957-.384.202-2.56 1.17-2.661 1.193-16.415 4.144-36.98-2.184-37.127-2.242 38.574 5.343 63.274-31.413 81.06-37.353 9.18-3.066 17.558-2.703 29.155 2.329.055.019 1.897.587 1.897.587zM226.703 87.628c-12.156 5.206-19.29 6.407-27.783 4.535.096.027.29.095.293.093 9.511 3.275 22.442 4.965 34.997 4.02 3.295-.248 5.315-2.129 5.13-4.935-.221-3.404-2.774-4.346-5.635-4.076 0 0-5.847.352-7.002.363zM14.507 53.155c12.49-4.346 19.972-5.918 28.312-3.456-.093-.035-.273-.115-.273-.112-9.268-3.928-22.334-5.648-34.924-5.585-3.306.016-5.46 1.752-5.46 4.564-.013 3.412 2.457 4.529 5.34 4.458-.006.003 5.85.06 7.005.131z"
      fill={colors.primary}
    />
    <path
      d="M44.601 88.434s-2.296 1.053-2.662 1.193c-6.005 2.306-20.089 6.754-30.397 6.532C4.834 96.017.076 94.663.002 91.958c-.087-3.246 2.154-4.957 4.824-4.573 5.345.776 8.752.353 9.282.494 4.927 1.33 16.15 3.574 30.493.555z"
      fill={colors.secondary}
    />
  </SvgIcon>
);
