import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useComposeMessageContentConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Subject', // i18n
      name: 'subject',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Message', // i18n
      name: 'message',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Send a copy to myself', // i18n
      name: 'sendCopy',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
