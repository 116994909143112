import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Connection, IntegrationWithConnection } from '@pro4all/graphql';
import { IntegrationTypeIcon } from '@pro4all/settings/ui/src/integrations';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  getFormattedDate,
  MiddleEllipsis,
  Timestamp,
} from '@pro4all/shared/ui/general';
import {
  FilterHeader,
  NO_VALUE,
} from '@pro4all/shared/ui/table-column-filtering';

const StyledBox = styled(Box)`
  && {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const useColumnsConnections = () => {
  const { t } = useTranslation();

  const columns: ColumnProps<IntegrationWithConnection>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<IntegrationWithConnection, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Application" // i18n
          minWidth={100}
          propertyId="integrationName"
          pullFromLocalStorage
        />
      ),
      key: 'integrationName',
      render: ({ integrationName, integrationType }) => (
        <StyledBox>
          <IntegrationTypeIcon integrationType={integrationType} />
          {integrationName}
        </StyledBox>
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ integrationConnection }) =>
        integrationConnection?.connectionName,
      headerComponent: (
        <FilterHeader<IntegrationWithConnection, Connection>
          defaultWidth={150}
          filterType={FilterHeaderType.Select}
          label="Connection" // i18n
          minWidth={120}
          propertyId="integrationConnection"
          subPropertyId="connectionName"
        />
      ),
      key: 'integrationConnection.connectionName',
      render: ({ connectionStatus, integrationConnection }) =>
        connectionStatus && integrationConnection
          ? integrationConnection.connectionName
          : t('None'),
      width: 150,
    },
    {
      filterable: true,
      getValue: ({ integrationConnection }) =>
        integrationConnection?.createdBy?.displayName,
      headerComponent: (
        <FilterHeader<IntegrationWithConnection, Connection>
          customCallbackId="integrationConnection.createdBy.displayName"
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(integrationConnection: Connection) =>
            integrationConnection?.createdBy?.displayName ?? NO_VALUE
          }
          label="Connected by" // i18n
          minWidth={120}
          propertyId="integrationConnection"
        />
      ),
      key: 'integrationConnection.createdBy.displayName',
      minWidth: 120,
      render: ({ integrationConnection }) => (
        <MiddleEllipsis
          endLength={9}
          text={integrationConnection?.createdBy?.displayName}
        />
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: (connection: IntegrationWithConnection) => {
        const { integrationConnection } = connection;
        return integrationConnection && integrationConnection.createdAt
          ? getFormattedDate(integrationConnection.createdAt).label
          : '';
      },
      headerComponent: (
        <FilterHeader<IntegrationWithConnection, Connection>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Connected at" // i18n
          minWidth={120}
          propertyId="integrationConnection"
          subPropertyId="createdAt"
        />
      ),
      key: 'integrationConnection.createdAt',
      render: ({ integrationConnection }) => (
        <Timestamp date={integrationConnection?.createdAt ?? null} />
      ),
      width: 200,
    },
  ];
  return columns;
};
