import { OrganizationSubscriptionLevel } from '@pro4all/graphql';

type SubscriptionAccess = {
  hasDmsLicense: boolean;
  hasGeneralProjectLicense: boolean;
  hasQualityControlLicense: boolean;
};

export const useSubscriptionRestriction = (
  subscriptionLevel: OrganizationSubscriptionLevel
): SubscriptionAccess => {
  let hasDmsLicense = false;
  let hasGeneralProjectLicense = false;
  let hasQualityControlLicense = false;

  if (
    subscriptionLevel !== OrganizationSubscriptionLevel.Qualitycontrol &&
    subscriptionLevel !== OrganizationSubscriptionLevel.Inactive
  ) {
    hasDmsLicense = true;
  }

  if (
    subscriptionLevel !== OrganizationSubscriptionLevel.Projectmanagement &&
    subscriptionLevel !== OrganizationSubscriptionLevel.Inactive
  ) {
    hasQualityControlLicense = true;
  }

  if (subscriptionLevel !== OrganizationSubscriptionLevel.Inactive) {
    hasGeneralProjectLicense = true;
  }

  return {
    hasDmsLicense,
    hasGeneralProjectLicense,
    hasQualityControlLicense,
  };
};
