import { datadogRum } from '@datadog/browser-rum';

import { useOrganizationContext } from '@pro4all/organization/context';

export const useStoreErrorInDataDog = () => {
  const { userId } = useOrganizationContext();

  const storeErrorInDataDog = ({
    additionalProps,
    errorTitle,
    reactComponent,
  }: {
    additionalProps?: Record<string, unknown>;
    errorTitle: string;
    reactComponent: string;
  }) => {
    datadogRum.addError(errorTitle, {
      reactComponent,
      userId,
      ...additionalProps,
    });
  };

  return { storeErrorInDataDog };
};
