import { ReportSignatureFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';
import { SignatureReports } from '../elements/SignatureReports';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportSignatureField = ({
  item,
  linkedSnags,
  reportConfigurationDummyTemplate,
  signatures,
  signatureUrl,
  templateOptions,
}: ReportSignatureFieldProps) => {
  const { displayName, displayDescription } = item;
  return (
    <Instance.Item>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value>
        {signatures || reportConfigurationDummyTemplate ? (
          <SignatureReports
            reportConfigurationDummyTemplate={reportConfigurationDummyTemplate}
            signatureUrl={signatureUrl}
          />
        ) : null}
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        {templateOptions?.showLinkedSnags && (
          <LinkedSnagsList linkedSnags={linkedSnags} />
        )}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportSignatureField;
