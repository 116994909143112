import { FieldDefinition } from '@pro4all/graphql';

export type CutCopyPasteData = {
  id: string;
  pasteType: PasteType;
} | null;

export type CutCopyPasteSection = {
  pasteType: PasteType;
  sourceSection: FieldDefinition;
} | null;

export enum PasteType {
  COPY = 'COPY',
  CUT = 'CUT',
}
