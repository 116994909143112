import React from 'react';

import { SvgIcon } from '../svg';

export const TBQ: React.FC<
  React.HtmlHTMLAttributes<SVGElement> & { 'aria-hidden': true | undefined }
> = ({ color, ...props }) => (
  <SvgIcon {...props} fill={color}>
    <path d="M6.70514 8.62183L11.9686 5.61442L16.9913 8.48422L20.1432 6.6707L11.9686 2L3.55325 6.80832L6.70514 8.62183Z" />
    <path d="M17.5722 15.3582V9.14115L20.7023 7.32128V17.1655L12.3287 22L12.3177 18.3919L17.5722 15.3582Z" />
    <path d="M11.4698 18.3392L6.37041 15.3951V9.45418L3.24023 7.63431V17.2023L11.4808 21.96L11.4698 18.3392Z" />
  </SvgIcon>
);
