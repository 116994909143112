import { useTranslation } from 'react-i18next';

import { FolderPermission } from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { IconName } from '@pro4all/shared/ui/icons';
import { firstCharToLowerCase } from '@pro4all/shared/utils';

type PermissionData = {
  icon: IconName;
  permissionText: string;
};

export const useGetSinglePermissionsData = () => {
  const { t } = useTranslation();
  const { approve_action_label, finalize_action_label } =
    useDocumentActionsLabels();

  const getSinglePermissionData = (
    folderPermission: FolderPermission
  ): PermissionData => {
    switch (folderPermission) {
      // Documents selector
      case FolderPermission.ReadAllDocuments:
        return {
          icon: 'visibility',
          permissionText: 'Read all documents', // i18n
        };
      case FolderPermission.ReadOwn:
        return {
          icon: 'accountCircle',
          permissionText: 'Read own documents', // i18n
        };
      case FolderPermission.ReadOwnAndFinalized:
        return {
          icon: 'ownAndFinalizedFiles',
          permissionText: t('Read own and {{name}} set documents', {
            name: firstCharToLowerCase(finalize_action_label),
          }),
        };

      // Versions selector
      case FolderPermission.ReadAllVersions:
        return {
          icon: 'visibility',
          permissionText: 'Read all versions', // i18n
        };
      case FolderPermission.ReadLastVersionAndApproved:
        return {
          icon: 'mostRecentApproved',
          permissionText: t('Read own, most recent and {{name}} set versions', {
            name: firstCharToLowerCase(approve_action_label),
          }),
        };
      case FolderPermission.ReadApproval:
        return {
          icon: 'checkCircleOutlined',
          permissionText: t('Read own and {{name}} set versions', {
            name: firstCharToLowerCase(approve_action_label),
          }),
        };
      case FolderPermission.ReadLastApproval:
        return {
          icon: 'lastApproved',
          permissionText: t('Read own and most recent {{name}} set versions', {
            name: firstCharToLowerCase(approve_action_label),
          }),
        };
      case FolderPermission.ReadLastVersion:
        return {
          icon: 'mostRecent',
          permissionText: 'Read own and most recent versions', // i18n
        };
      case FolderPermission.ReadOwnVersions:
        return {
          icon: 'accountCircle',
          permissionText: 'Read own versions', // i18n
        };

      // Delete selector
      case FolderPermission.DeleteContent:
        return {
          icon: 'deleteFile',
          permissionText: 'Delete all documents', // i18n
        };
      case FolderPermission.DeleteOwnContent:
        return {
          icon: 'deleteOwnFile',
          permissionText: 'Delete own documents', // i18n
        };

      // Update selector
      case FolderPermission.UpdateContent:
        return {
          icon: 'editFile',
          permissionText: 'Update all documents', // i18n
        };
      case FolderPermission.UpdateOwn:
        return {
          icon: 'editOwnFiles',
          permissionText: 'Update own documents', // i18n
        };

      case FolderPermission.CreateSubFolder:
        return {
          icon: 'createFolder',
          permissionText: 'Create subfolders', // i18n
        };
      case FolderPermission.UpdateFolder:
        return {
          icon: 'updateFolderPermissions',
          permissionText: 'Update folders/permissions', // i18n
        };
      case FolderPermission.DeleteFolder:
        return {
          icon: 'deleteFolder',
          permissionText: 'Delete folders', // i18n
        };
      case FolderPermission.CreateContent:
        return {
          icon: 'addFile',
          permissionText: 'Create documents/versions', // i18n
        };
      case FolderPermission.ApproveContent:
        return {
          icon: 'approveFile',
          permissionText: t('Set {{name}} for documents', {
            name: firstCharToLowerCase(approve_action_label),
          }),
        };
      case FolderPermission.FinalizeContent:
        return {
          icon: 'finalize',
          permissionText: t('Set {{name}} for documents', {
            name: firstCharToLowerCase(finalize_action_label),
          }),
        };
    }
  };

  return getSinglePermissionData;
};
