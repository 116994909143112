import { FieldDefinition, Template, TemplateType } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useExportTemplate = () => {
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationIdSource = getContextScopedOrganizationId();
  const {
    params: { projectId },
  } = useRouting();

  const { track } = useAnalytics();

  const onExportTemplate = ({
    fieldDefinitions,
    selectedTemplate,
    templateType,
  }: {
    fieldDefinitions: FieldDefinition[];
    selectedTemplate?: Template;
    templateType: TemplateType;
  }) => {
    if (!selectedTemplate) {
      return;
    }

    const { id, items, name, scope, type } = selectedTemplate;
    const exportObject = {
      fieldDefinitionsSource: fieldDefinitions, // Have to provide fieldDefinitions data, because `item.scope` is null and Wouter requested to not involve BE if possible.
      id,
      items,
      name,
      organizationIdSource,
      scope,
      type,
    };
    const exportJson = JSON.stringify(exportObject);

    const blob = new Blob([exportJson], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${templateType}Template_${name}.json`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    track(TrackingEvent.TemplateExported, {
      id,
      name,
      organizationId: organizationIdSource,
      projectId,
    });
  };

  return onExportTemplate;
};
