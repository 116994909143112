import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useAppPermissions } from '@pro4all/shared/identity';

export const useIncludeOrgItems = () => {
  const { hasAppPermission } = useAppPermissions();
  const { userOrganizationId } = useOrganizationContext();
  const { projectOrganizationId } = useProjectContext();

  return Boolean(
    hasAppPermission('OrganizationUpdate') &&
      (!projectOrganizationId || projectOrganizationId === userOrganizationId)
  );
};
