import { ApprovalAction, useApproval } from '@pro4all/documents/ui/approval';
import { Document, DocumentVersion } from '@pro4all/graphql';
import { Action, DmsTrackingLocationType } from '@pro4all/shared/config';
import { Position, PositionType } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useHandleApprovalOrRejection = ({
  folderId,
  organizationId,
  position,
  projectId,
  selection,
  selectionVersion,
  trackingPosition,
  uncheckAllRows,
  userId,
  versionIds,
}: {
  folderId: string;
  organizationId: string;
  position: PositionType;
  projectId: string;
  selection: Document[];
  selectionVersion: DocumentVersion[];
  trackingPosition: DmsTrackingLocationType;
  uncheckAllRows: () => void;
  userId: string;
  versionIds: string[];
}) => {
  const { track } = useAnalytics();

  const { approveOrRejectVersion } = useApproval({
    documents: selection,
    versions: selectionVersion,
  });

  const handleApprovalOrRejection = (action: ApprovalAction) => {
    const trackingAction =
      action === ApprovalAction.Approve
        ? Action.ApproveButtonClicked
        : action === ApprovalAction.Reject
        ? Action.RejectButtonClicked
        : action === ApprovalAction.Unapprove
        ? Action.UnapproveButtonClicked
        : Action.UnrejectButtonClicked;

    track(trackingAction, {
      buttonLocation: trackingPosition,
      folderId,
      organizationId,
      projectId,
      userId,
      versionIds,
    });

    approveOrRejectVersion(action);
    if (position !== Position.Contextmenu) uncheckAllRows();
  };

  return handleApprovalOrRejection;
};
