import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

export const useRenameFolderAction = (folder: Folder) => {
  const { searchParams } = useRouting();
  const { t } = useTranslation();

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const handleRenameFolder = () => {
    searchParams.set({ action: 'renameFolder' });
  };

  const renameFolderMenuItem: ActionProps = {
    ariaLabel: 'Rename folder',
    dataTestId: 'rename-folder',
    disabled: !hasFolderPermission('UpdateFolder'),
    key: 'rename-folder',
    label: t('Rename'),
    onClick: handleRenameFolder,
    startIcon: 'edit',
  };

  return { renameFolderMenuItem };
};
