import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

export const Available = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const openProjectModal = () => {
    searchParams.set({ action: 'linkObjectToProject' });
  };

  return (
    <>
      <Text>{t('This object is not linked to any project.')}</Text>
      <Button onClick={openProjectModal} startIcon="project">
        {t('Link to project')}
      </Button>
    </>
  );
};
