import type { RefObject } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

export function useListOverflow({
  containerRef,
  items,
  overflow,
  flexEnd,
}: {
  containerRef: RefObject<Element>;
  flexEnd: boolean;
  items: unknown[];
  overflow: boolean;
}) {
  const [limit, setLimit] = useState(Infinity);

  // An IntersectionObserver only triggers a callback on a new intersection of
  // an observed element. So on first render, `entries` is all the observed
  // elements in the container. Any next invocation of the callback could be
  // from any element in the list triggering an intersection. In order to
  // reliably set a new limit we utilze a cache so we can process all entries
  // in DOM order..
  const entryCache = useRef<
    Map<Element, IntersectionObserverEntry | undefined>
  >(new Map());

  const handleOverflow: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      // Whenever we get an entry update the cache with the new intersection.
      // This is needed for determining the new limit below.
      entryCache.current.set(entry.target, entry);

      // When an element is overflowing our container, we still want to keep
      // track of updated intersections when the container is resized. In order
      // to do this, we hide the element visually, prevent any interactions and
      // 'lock' it into place.
      if (entry.isIntersecting) {
        entry.target.removeAttribute('style');
      } else {
        const rootBounds = entry.rootBounds ?? { left: 0, top: 0 };
        const target = entry.target as HTMLElement;

        const { height, width } = entry.boundingClientRect;
        const left = entry.boundingClientRect.left - rootBounds.left;
        const top = entry.boundingClientRect.top - rootBounds.top;

        Object.assign(target.style, {
          height: `${height}px`,
          left: `${left}px`,
          ponterEvents: 'none',
          position: 'absolute',
          top: `${top}px`,
          visibility: 'hidden',
          width: `${width}px`,
        });
      }
    });

    // Now that all entries have been processed regarding their display, we can
    // get the index of the first overflowing entry for the new limit.
    const currentValues = flexEnd
      ? Array.from(entryCache.current.values()).reverse()
      : Array.from(entryCache.current.values());
    const index = currentValues.findIndex(
      (entry) => entry && !entry.isIntersecting
    );

    setLimit((limit) =>
      index !== -1 ? index : Math.max(limit, entryCache.current.size)
    );
  };

  useLayoutEffect(() => {
    if (!overflow || !containerRef.current) return;

    const cache = entryCache.current;
    const observer = new IntersectionObserver(handleOverflow, {
      root: containerRef.current,
      // The negative margin-right is an educated guestimate of how much a
      // default more component takes up.

      rootMargin: flexEnd ? '0px 0px 0px -48px' : '0px -48px 0px 0px',
      threshold: 1,
    });

    // Reset observed elements and clear cache in case `items` has changed.
    cache.forEach((_, item) => observer.unobserve(item));
    cache.clear();

    // Pre-fill the IntersectionObserverEntry cache with Elements as keys and
    // start observing the elements for intersections.
    Array.from(containerRef.current.children).forEach((item) => {
      if (item.hasAttribute('data-list-more')) return;
      cache.set(item, undefined);
      observer.observe(item);
    });

    return () => {
      cache.clear();
      observer.disconnect();
    };
  }, [containerRef, items, overflow]);

  return limit;
}
