import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorCode } from '@pro4all/authentication/src/services/authenticated-api-service';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';

export interface Props {
  droppedDrawingId: string;
}

export const DrawingUploadStatusTextCell: React.FC<Props> = ({
  droppedDrawingId,
}) => {
  const { filesUploadedUnsuccessfully, filesUploadedSuccessfully } =
    useFileUploadContext();

  const { t } = useTranslation();

  const getFileText = (droppedDrawingId: string) => {
    const unsuccessfulFile = filesUploadedUnsuccessfully.find(
      (upload) => upload.file.id === droppedDrawingId && upload.done
    );

    if (unsuccessfulFile) {
      if (
        unsuccessfulFile.error.message ===
        ErrorCode.API_ERROR_FLOORPLAN_NAME_EXISTS
      ) {
        return t('Name taken. Please try with another one.');
      } else {
        return t('Upload error. Please try again');
      }
    } else {
      const isSuccessfullyUploaded = filesUploadedSuccessfully.some(
        (upload) => upload.file.id === droppedDrawingId && upload.done
      );
      return isSuccessfullyUploaded ? t('Success') : '';
    }
  };

  const text = getFileText(droppedDrawingId);

  return <p>{text}</p>;
};
