import React from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { Folder, FolderPermission } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';

import { getAncestorPermissionIncluded } from './context/helpers/getAncestorPermissionIncluded';
import { usePermissionsFolderMatrixContext } from './context/PermissionsFolderMatrixProvider';
import { removeExclusiveFolderPermissions } from './shared/helpers/removeExclusiveFolderPermissions';
import { PermissionFolderCell } from './PermissionFolderCell';
import { shouldPermissionFolderCellBeDisabled } from './shouldPermissionFolderCellBeDisabled';

export interface PermissionCellFolderWrapperProps {
  folder: Folder;
  permission: FolderPermission;
  userId: string;
}

/**
 * We use this wrapper to achieve optimal rendering for PermissionFolderCell (the permission checkbox).
 * If we don't, ALL checkboxes will be re-rendered if a user clicks on whatever checkbox or changes
 * whatever value for whatever folder. Which would have a major bad impact on performance in case of thousands of folders.
 */
export const PermissionCellFolderWrapper = ({
  folder,
  permission,
  userId,
}: PermissionCellFolderWrapperProps) => {
  const { state, togglePermission } = usePermissionsFolderMatrixContext();
  const { displayPermissions } = state;
  const { id: folderId } = folder || {};

  const currentPermissions = displayPermissions.find(
    (perm) => perm.folderId === folderId
  );

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const { userId: loggedInUserId } = AuthService.getProfile();

  const { breakInheritance, folderPermissions, inheritedFrom } =
    currentPermissions || {};
  const allPermissions =
    inheritedFrom && folderPermissions
      ? [
          ...new Set([
            ...inheritedFrom.map((inh) => inh.permission),
            ...folderPermissions,
          ]),
        ]
      : [];

  const allPermissionsFinal = removeExclusiveFolderPermissions({
    permissions: allPermissions,
  });

  const inheritedFromGroups = inheritedFrom
    ? inheritedFrom.find(
        (inheritedPermission) => inheritedPermission.permission === permission
      )?.groups || []
    : [];

  const isAncestorPermissionIncluded = getAncestorPermissionIncluded({
    folderId,
    permission,
    permissions: displayPermissions,
  });

  const disabled = breakInheritance
    ? loggedInUserId === userId ||
      shouldPermissionFolderCellBeDisabled({
        allPermissions: allPermissionsFinal,
        folderId,
        permission,
        state,
      })
    : loggedInUserId === userId ||
      shouldPermissionFolderCellBeDisabled({
        allPermissions: allPermissionsFinal,
        folderId,
        permission,
        state,
      }) ||
      isAncestorPermissionIncluded;

  const hasCurrentPermission =
    currentPermissions?.folderPermissions.includes(permission);

  return (
    <PermissionFolderCell
      checked={
        breakInheritance
          ? hasCurrentPermission
          : hasCurrentPermission || isAncestorPermissionIncluded
      }
      disabled={disabled}
      folder={folder}
      inheritedFromGroups={inheritedFromGroups}
      permission={permission}
      showFinalPermissions={showFinalPermissions}
      togglePermission={togglePermission}
    />
  );
};
