import { Box, CircularProgress } from '@pro4all/shared/mui-wrappers';

export const FilterCircularProgress = () => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: 2,
      width: '100%',
    }}
  >
    <CircularProgress size={24} />
  </Box>
);
