import React from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { RowIconWrapRight } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';

const ProjectIcon = styled(Icon)`
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const LinkName: React.FC<{
  name: string;
}> = ({ name }) => {
  const Row = (
    <>
      <ProjectIcon iconName="project" label={name} />
      <RowIconWrapRight>
        <Icon
          htmlColor={customColors.grey600}
          iconName="moreHoriz"
          onClick={(e) => {
            e.stopPropagation();
            const rect = e.currentTarget.getBoundingClientRect();
            const event = new MouseEvent('contextmenu', {
              bubbles: true,
              clientX: rect.right,
              clientY: rect.top,
            });
            e.currentTarget.dispatchEvent(event);
          }}
        />
      </RowIconWrapRight>
    </>
  );

  return Row;
};
