import React, { ElementType } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';

// Specify the generic types used by MUI5 Autocomplete.
export type AutocompletePropsWithGenerics<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType<any> = React.FC<any>
> = MuiAutocompleteProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>;

export const Autocomplete = MuiAutocomplete;

export type { AutocompletePropsWithGenerics as AutocompleteProps };
