export const useLightBoxConfig = (viewMode: string) => ({
  animation: { fade: 1, navigation: 1, swipe: 1 },
  carousel: { finite: true, preload: 999 },
  styles: `
        body, html {
          overflow: hidden;
        }
        .yarl__container {
          background-color: #f2f7f9;
        }
        .yarl__slide {
          align-items: start;
          padding: 0;
        }
        .yarl__slide_image {
          padding: 0;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 8px;
        }
        .yarl__icon {
          height: var(--yarl__icon_size,20px);
          width: var(--yarl__icon_size,20px);
        }
        .yarl__button:focus:not(:focus-visible), .yarl__button:focus, .yarl__button, .yarl__button:hover, .yarl__button:disabled {
          color: #848484;
        }
        .yarl__button {
          filter: none;
          color: #848484;
          padding: 4px;
          margin: 4px;
          border: 1px solid currentColor;
          background-color: #FFFFFF;
          border-radius: 30px;
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        .yarl__button:hover:not(:disabled) {
          background-color: #eee;
        }
        .yarl__button:disabled {
          cursor: default;
          opacity: 0.5;
        }
        .yarl__thumbnails_container {
          background-color: #f2f7f9;
          border: none;
        }
        .yarl__thumbnails_thumbnail {
          background-color: #f2f7f9;
          border: none;
          width: 68px;
        }
        .yarl__thumbnails_track {
          background-color: #f2f7f9;
        }
        .yarl__toolbar {
          display: ${viewMode !== 'normal' ? 'none' : 'flex'};
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
      `,
  thumbnails: { gap: 0, height: 50, vignette: false },
  zoom: { maxZoomPixelRatio: 10 },
});
