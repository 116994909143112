import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { useTaskCategoriesValidationSchema } from './useTaskCategoriesValidationSchema';

export const useTaskCategoriesFormConfig = () => {
  const customTaskCategoriesValidationSchema =
    useTaskCategoriesValidationSchema();

  const customValidation = {
    ...customTaskCategoriesValidationSchema,
  };

  const formFields: FormFieldConfig[] = [
    {
      name: 'selectionOptions',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ customValidation, formFields });
};
