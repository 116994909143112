import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { DocumentService } from '@pro4all/documents/data-access';
import { Document, DocumentVersion } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { ActionProps } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useDownloadAction = ({
  allDocumentsExpected,
  isSpecialFolder,
  selection,
  selectionVersion,
}: Pick<
  DocumentActionProps,
  'allDocumentsExpected' | 'isSpecialFolder' | 'selection' | 'selectionVersion'
>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();

  const downloadDocuments = useCallback(
    async (selection: Document[], selectionVersion: DocumentVersion[]) => {
      track(TrackingEvent.DocumentDownloadSelected, {
        fileCount: selection.length || selectionVersion.length,
      });
      enqueueSnackbar(t('Download started'));
      const response = selection.length
        ? selection.length > 1
          ? await DocumentService.downloadDocuments(
              selection.map((row) => ({
                extension: row.extension,
                id: row.id,
                name: row.name,
                versionId: row.versionId,
              })),
              isSpecialFolder
            )
          : await DocumentService.downloadDocument({
              document: selection.shift(),
            })
        : await DocumentService.downloadDocument({
            version: selectionVersion.shift(),
          });

      if (response !== 200) {
        enqueueSnackbar(t('Could not download document'));
      }
    },
    [enqueueSnackbar, t, track, isSpecialFolder]
  );

  const downloadAction: ActionProps = {
    ariaLabel: t('Download'),
    dataTestId: 'download-documents',
    disabled: allDocumentsExpected,
    key: 'download-documents',
    label: t('Download'),
    onClick: () => downloadDocuments(selection, selectionVersion),
    startIcon: 'download',
  };

  return downloadAction;
};
