import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Document, FinalizationState, LockType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { StorageKeys } from '@pro4all/shared/config';
import { TrackingEvent } from '@pro4all/shared/config';
import { useClipboardContext } from '@pro4all/shared/contexts';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps, MoveDocumentCountData } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useCutAction = ({
  hasFolderPermission,
  ownedByUserDocuments,
  selection,
}: Pick<
  DocumentActionProps,
  'hasFolderPermission' | 'ownedByUserDocuments' | 'selection'
>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { searchParams } = useRouting();
  const { track } = useAnalytics();
  const { setDocumentsToMove } = useClipboardContext();
  const { organizationId } = useOrganizationContext();

  const { setLocalStorageItem: setMoveDocumentsCount } =
    useLocalStorage<MoveDocumentCountData>({
      key: StorageKeys.MOVE_DOCUMENTS_COUNT,
    });

  const hasDeletePermission = hasFolderPermission('DeleteContent');
  const hasDeleteOwnPermission = hasFolderPermission('DeleteOwnContent');

  const cutDocuments = useCallback(
    async (selection: Document[]) => {
      track(TrackingEvent.DocumentMoved, {
        fileCount: selection.length,
      });

      setDocumentsToMove(
        selection.map((doc) => ({
          ...doc,
          isExpected: false,
          lastUpdatedBy: null,
          lockType: LockType.None,
          lockedAt: null,
          lockedBy: null,
          organizationId: organizationId,
          state: FinalizationState.NotFinalized,
        }))
      );

      // Only interesting if user misses the `DeleteContent` permission, so can only delete owned documents.
      const notAllSelectedCanBeCut =
        !hasDeletePermission && selection.length > ownedByUserDocuments.length;

      if (notAllSelectedCanBeCut) {
        setMoveDocumentsCount({
          count: ownedByUserDocuments.length,
          total: selection.length,
        });
        searchParams.set({ action: 'moveDocumentsInfo' });
      }

      if (selection.length === 1) {
        enqueueSnackbar(t('Document moved to clipboard'));
      } else {
        enqueueSnackbar(
          t('{{count}} Documents moved to clipboard', {
            count: notAllSelectedCanBeCut
              ? ownedByUserDocuments.length
              : selection.length,
          })
        );
      }
    },
    [
      enqueueSnackbar,
      hasDeletePermission,
      organizationId,
      ownedByUserDocuments,
      searchParams,
      setDocumentsToMove,
      setMoveDocumentsCount,
      t,
      track,
    ]
  );

  const copyAction: ActionProps = {
    ariaLabel: t('Cut'),
    dataTestId: 'cut-documents',
    disabled:
      (!hasDeletePermission && !hasDeleteOwnPermission) ||
      (hasDeleteOwnPermission && !ownedByUserDocuments.length),
    key: 'cut-documents',
    label: t('Cut'),
    onClick: () => cutDocuments(selection),
    startIcon: 'cut',
  };

  return copyAction;
};
