import { UploaderEditorTypes } from '../../types';

export const getTabIndex = ({
  index,
  initialTabIndex,
  noOfInputs,
}: { index: number } & Pick<
  UploaderEditorTypes,
  'initialTabIndex' | 'noOfInputs'
>) => {
  const tabIndex = initialTabIndex
    ? (index + 1) * noOfInputs + initialTabIndex
    : undefined;

  return tabIndex;
};
