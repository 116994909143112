import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const DeleteFile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M6 1.25C4.48106 1.25 3.26025 2.49043 3.26 3.99953L3.26 3.99981V4L3.25 19.9995V20C3.25 21.5093 4.4709 22.75 5.99 22.75H18C19.5142 22.75 20.75 21.5142 20.75 20V8.83C20.75 8.10438 20.4632 7.40071 19.9371 6.88641L15.1136 2.06294C14.5993 1.53685 13.8956 1.25 13.17 1.25H6ZM4.76 4C4.76 3.30931 5.3191 2.75 6 2.75H13.17C13.5026 2.75 13.8173 2.88175 14.0426 3.11321L14.0426 3.11326L14.0497 3.12033L18.8797 7.95033L18.8796 7.95038L18.8868 7.95735C19.1182 8.18273 19.25 8.49739 19.25 8.83V20C19.25 20.6858 18.6858 21.25 18 21.25H5.99C5.30918 21.25 4.75013 20.6908 4.75 20.0002V20L4.76 4.00047L4.76 4ZM13 3.5V8C13 8.55 13.45 9 14 9H18.5L13 3.5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.75 11H16C16.275 11 16.5 11.225 16.5 11.5C16.5 11.775 16.275 12 16 12H10C9.725 12 9.5 11.775 9.5 11.5C9.5 11.225 9.725 11 10 11H11.25L11.605 10.645C11.695 10.555 11.825 10.5 11.955 10.5H14.045C14.175 10.5 14.305 10.555 14.395 10.645L14.75 11ZM11 19.5C10.45 19.5 10 19.05 10 18.5V13.5C10 12.95 10.45 12.5 11 12.5H15C15.55 12.5 16 12.95 16 13.5V18.5C16 19.05 15.55 19.5 15 19.5H11Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
