import React from 'react';
import styled from 'styled-components';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { ProjectForm } from './ProjectForm';

export const ProjectFormEdit: React.FC = () => {
  const { projectData } = useProjectContext();

  return (
    <ResponseWrapper>
      <StyledBox height="100%" maxWidth="400px">
        <ProjectForm project={projectData} showCancel={false} />
      </StyledBox>
    </ResponseWrapper>
  );
};

export const StyledBox = styled(Box)`
  && {
    overflow: auto;
  }
`;
