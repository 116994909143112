import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@pro4all/shared/types';

export const useBoolOptions = () => {
  const { t } = useTranslation();
  const selectOptions: Option[] = useMemo(
    () => [
      { id: 'true', label: t('Yes') },
      { id: 'false', label: t('No') },
    ],
    [t]
  );
  return selectOptions;
};
