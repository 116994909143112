import React, { useState } from 'react';
import styled from 'styled-components';

import {
  AdapterDayjs,
  DatePicker,
  DatePickerProps,
  InputAdornment,
  LocalizationProvider,
} from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';

import { TextField } from '../text-field/TextField';

const StyledDateIconButton = styled(IconButton)`
  && {
    background-color: transparent;
    border: none;
    &:hover {
      color: ${({ theme }) => `${theme.palette.primary.dark}`};
      background-color: transparent;
      border: none;
    }
    &:focus {
      color: ${({ theme }) => `${theme.palette.primary.dark}`};
      background-color: transparent;
      border: none;
    }
  }
`;

export const DateField: React.FC<
  Pick<DatePickerProps, 'onChange' | 'label' | 'minDate' | 'maxDate'> & {
    error?: boolean;
    helperText?: string;
    inputRefProp?: React.RefObject<HTMLInputElement>;
    name: string;
    setShowDatePicker?: (value: boolean) => void;
    showDatePicker?: boolean;
    tabIndex?: number;
    tooltipTitle?: string;
    value: string;
    warning?: boolean;
  }
> = ({
  error,
  helperText,
  inputRefProp,
  onChange,
  label,
  name,
  maxDate = new Date('2099-12-31'),
  minDate = new Date('1900-01-01'),
  setShowDatePicker,
  showDatePicker,
  tabIndex,
  tooltipTitle = '',
  value,
  warning = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const open = showDatePicker === undefined ? isOpen : showDatePicker;

  const locale = localStorage.getItem('i18nextLng') || 'nl-NL';

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
      <DatePicker
        inputFormat="DD-MM-YYYY"
        mask="__-__-____"
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        onClose={() => {
          setShowDatePicker && setShowDatePicker(false);
          setIsOpen(false);
          setIsFocused(false);
        }}
        onOpen={() => {
          setShowDatePicker && setShowDatePicker(true);
          setIsOpen(true);
          setIsFocused(true);
        }}
        open={open}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <TextField
            InputProps={{
              ...InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <StyledDateIconButton
                    color={open ? 'primary' : 'default'}
                    iconName="calendar"
                    onClick={() => {
                      setShowDatePicker && setShowDatePicker(!open);
                      setIsOpen(!open);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            error={error}
            focused={isFocused || open}
            fullWidth
            helperText={error && helperText}
            inputProps={{ ...inputProps, ref: inputRef, tabIndex }}
            inputRef={inputRefProp}
            label={label}
            margin="normal"
            name={name}
            onBlur={() => {
              setIsFocused(false);
            }}
            onFocus={() => setIsFocused(true)}
            tooltipTitle={tooltipTitle}
            warning={warning}
          />
        )}
        value={value || null}
      />
    </LocalizationProvider>
  );
};
