import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useDeleteSavedAnswersMutation } from '@pro4all/graphql';
import { SelectedIdName } from '@pro4all/shared/types';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { useApolloClientCacheHelpers } from './useApolloClientCacheHelpers';
import { useSnackbars } from './useSnackbars';

export const useDeleteSavedAnswers = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteAnswers] = useDeleteSavedAnswersMutation();

  const { getErrors, showBatchErrors } = useShowMessages();
  const { getCurrentlyCachedSavedAnswers, updateCachedSavedAnswers } =
    useApolloClientCacheHelpers();

  const { SavedAnswerInstanceDeleted, SavedAnswerInstancesDeleted } =
    useSnackbars();

  const deleteSavedAnswers = async (savedAnswersSelected: SelectedIdName[]) => {
    const ids = savedAnswersSelected.map((savedAnswers) => savedAnswers.id);

    try {
      await deleteAnswers({
        variables: {
          ids,
        },
      });

      handleFinalMessage({
        savedAnswersSelected,
        succeeded: savedAnswersSelected,
      });
      updateCache({ succeededIds: ids });
    } catch (e) {
      const error = e as ApolloError;
      const errors = getErrors(error);

      const failedIds = errors.map((error) => error.id);
      const succeededIds = ids.filter((id) => !failedIds.includes(id));
      const succeeded = savedAnswersSelected.filter((savedAnswers) =>
        succeededIds.includes(savedAnswers.id)
      );

      handleFinalMessage({ savedAnswersSelected, succeeded });
      updateCache({ succeededIds });

      showBatchErrors({
        apolloError: error,
        customToastError: t('Failed to move {{count}} document(s)', {
          count: errors?.length,
        }),
        identifierData: savedAnswersSelected.map((savedAnswers) => ({
          displayName: savedAnswers.name,
          id: savedAnswers.id,
        })),
      });
    }
  };

  const updateCache = ({ succeededIds }: { succeededIds: string[] }) => {
    const cachedSavedAnswers = getCurrentlyCachedSavedAnswers();
    const otherSavedAnswers = cachedSavedAnswers.filter(
      (key) => !succeededIds.includes(key.id)
    );
    updateCachedSavedAnswers(otherSavedAnswers);
  };

  const handleFinalMessage = ({
    savedAnswersSelected,
    succeeded,
  }: {
    savedAnswersSelected: SelectedIdName[];
    succeeded: SelectedIdName[];
  }) => {
    const cachedSavedAnswers = getCurrentlyCachedSavedAnswers();
    const message =
      succeeded.length === 1 && savedAnswersSelected.length === 1 ? (
        <SavedAnswerInstanceDeleted
          cachedSavedAnswers={cachedSavedAnswers}
          succeeded={succeeded}
        />
      ) : succeeded.length > 1 ? (
        <SavedAnswerInstancesDeleted
          cachedSavedAnswers={cachedSavedAnswers}
          succeeded={succeeded}
        />
      ) : null;
    if (message) enqueueSnackbar(message);
  };

  return {
    deleteSavedAnswers,
  };
};
