import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  RadioGroup,
  RadioGroupProps,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import { Radio } from '../../inputs/radio/Radio';

export interface RadioOption extends Option {
  tooltip?: string;
}

const StyledFormControl = styled(FormControl)`
  && {
    display: block;
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

const StyledIcon = styled(Icon)`
  color: ${customColors.grey600};
`;

const IconWithLabel = styled(Icon)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

export const FormikRadioGroup: React.FC<
  RadioGroupProps &
    Omit<FormControlLabelProps, 'control'> & {
      options: RadioOption[];
    }
> = ({ options, name, ...props }) => {
  const [field, meta] = useField<string>({ name });
  const hasError = Boolean(meta.touched) && Boolean(meta.error);
  return (
    <StyledFormControl error={hasError}>
      {props.label && (
        <Text color={hasError ? 'error' : 'inherit'} variant="h5">
          {props.label}
        </Text>
      )}
      <RadioGroup {...props} {...field}>
        <Box mt={2}>
          {options.map((option: RadioOption) => (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              key={option.id}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={option.disabled}
                key={option.id}
                label={
                  option.iconName ? (
                    <IconWithLabel
                      iconName={option.iconName}
                      label={option.label}
                    />
                  ) : (
                    option.label
                  )
                }
                labelPlacement={props.labelPlacement}
                value={option.id}
              />
              {option.tooltip && (
                <Tooltip
                  aria-label={`${option.id}-tooltip`}
                  title={option.tooltip}
                >
                  <StyledIcon iconName="info" />
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>
      </RadioGroup>
      {hasError && <FormHelperText>{meta.error}</FormHelperText>}
    </StyledFormControl>
  );
};
