import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { Option } from '@pro4all/shared/types';
import { Select } from '@pro4all/shared/ui/general';

import type { Fields } from '../useTBQSettings';

import * as Styled from './TBQEntityInput.styles';

interface Props {
  defaultValue?: Option | string;
  disabled?: boolean;
  id: string;
  label: string;
  name: string;
  options: Option[];
}

export const TBQEntityInput = ({
  disabled,
  defaultValue,
  id,
  label,
  name,
  options,
}: Props) => {
  const { control } = useFormContext<Fields>();
  return (
    <>
      <Styled.Label htmlFor={id}>{label}</Styled.Label>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={(props) => (
          <Select
            {...props}
            disabled={disabled}
            id={id}
            options={options}
            value={props.value?.id ?? defaultValue}
          />
        )}
      />
    </>
  );
};
