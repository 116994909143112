import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';

import { Button } from '../Toolbar.styles';

export const OrganizationButtons: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const editObjects = () => searchParams.set('action', 'editObjects');

  return (
    <Button color="inherit" onClick={editObjects} startIcon="editObject">
      {t('Edit')}
    </Button>
  );
};
