import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUnlinkOsidDeviceMutation } from '@pro4all/graphql';

export const useUnlinkOsidDevice = ({ objectId }: { objectId: string }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [unlinkOsidDevice] = useUnlinkOsidDeviceMutation();

  return async () => {
    try {
      await unlinkOsidDevice({
        variables: { objectId },
      });
      enqueueSnackbar(t('Osid device has been unlinked.'));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('Could not unlink device'));
    }
  };
};
