import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pro4all/shared/ui/buttons';

export const BackButton: React.FC<{ goBack: () => void }> = ({ goBack }) => {
  const { t } = useTranslation();

  return (
    <Button label="Go back" onClick={goBack} startIcon="arrowBack">
      {t('Go back')}
    </Button>
  );
};
