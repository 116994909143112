import { DrawingReportMapsProps } from '@pro4all/shared/types';

import { ReportDrawing } from '../ReportDrawing';

export const DrawingReportMaps = ({
  instancesGroupedByDrawing,
}: DrawingReportMapsProps) => {
  const validGroupedInstances = instancesGroupedByDrawing.filter((group) =>
    Boolean(group.visualContextId !== '00000000-0000-0000-0000-000000000000')
  );

  return (
    <>
      {validGroupedInstances.map((group, key) => (
        <div
          id={`temp-drawing-${group.visualContextId}${group.page}`}
          key={key}
        >
          <ReportDrawing
            instances={group.instances}
            key={key}
            page={group.page}
            visualContextId={group.visualContextId}
          />
        </div>
      ))}
    </>
  );
};
