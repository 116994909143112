import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfirmEmailMutation } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { InfoPage } from '@pro4all/shared/ui/infopages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { useAnalytics } from '@pro4all/shared/vendor';

import { PageFail } from '../components/confirm-email/PageFail';
import { PageSuccess } from '../components/confirm-email/PageSuccess';

export const ConfirmEmail: React.FC = () => {
  const { t } = useTranslation();
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>();
  const [error, setError] = useState<Error>();
  const { token } = useRouting().params;
  const [confirmEmailMutation] = useConfirmEmailMutation();
  const { track } = useAnalytics();

  const loaded = emailConfirmed !== undefined;

  useEffect(() => {
    if (!token) return;

    const confirmEmail = async () => {
      try {
        const result = await confirmEmailMutation({
          variables: {
            token,
          },
        });
        if (result?.data?.confirmEmail) {
          track(TrackingEvent.SignupSuccessful);
          setEmailConfirmed(true);
        } else {
          setEmailConfirmed(false);
        }
      } catch (e) {
        setEmailConfirmed(false);
        setError(e);
      }
    };

    !loaded && confirmEmail();
  }, [token, confirmEmailMutation, track, loaded]);

  return (
    <ResponseWrapper
      CustomErrorComponent={
        <InfoPage
          description={t('Please ask for a new invitation and try again.')}
          title={t('Confirmation failed')}
          type="error"
        />
      }
      error={error}
      isLoading={!loaded}
    >
      {emailConfirmed ? <PageSuccess /> : <PageFail />}
    </ResponseWrapper>
  );
};
