import { useEffect, useState } from 'react';

import {
  FieldDefinition,
  FormLinkedSnag,
  QualityControlInstance,
  QualityControlInstanceType,
  useBatchQualityControlInstancesIncludeQuery,
} from '@pro4all/graphql';

export const useTbqUnlinkQuestionFromSnag = (
  linkedSnags?: FormLinkedSnag[] | null | undefined
) => {
  const [formInstanceResults, setFormInstanceResults] = useState<
    QualityControlInstance[]
  >([]);
  const [isTbqQuestion, setIsTbqQuestion] = useState(false);

  const formLinkedIds = (linkedSnags?.map((snag) => snag?.id).filter(Boolean) ||
    []) as string[];

  const formQualityControlInstances =
    useBatchQualityControlInstancesIncludeQuery({
      fetchPolicy: 'no-cache',
      skip: !formLinkedIds,
      variables: { ids: formLinkedIds, includeItems: true },
    });

  const formInstances = formQualityControlInstances.data
    ?.qualityControlInstances as QualityControlInstance[];

  if (
    formInstances &&
    JSON.stringify(formInstances) !== JSON.stringify(formInstanceResults)
  ) {
    setFormInstanceResults(formInstances);
  }

  useEffect(() => {
    const tbqExists = formInstanceResults.some(
      (instance) => instance.type === QualityControlInstanceType.Tbq
    );
    if (isTbqQuestion !== tbqExists) {
      setIsTbqQuestion(tbqExists);
    }
  }, [formInstanceResults, isTbqQuestion]);

  const allItems = [
    ...new Set(
      formInstanceResults
        .map((result) => result.items)
        .filter((item) => Boolean(item))
        .flat()
    ),
  ] as FieldDefinition[];

  return { allItems, formInstanceResults, isTbqQuestion };
};
