import { useEffect, useState } from 'react';
import Snowfall from 'react-snowfall';
import styled from 'styled-components';

declare global {
  interface Window {
    snowfall: () => void;
  }
}

const SnowContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9000;
  pointer-events: none;
`;

export const Snow = () => {
  const [currentSnowFlakeCount, setCurrentSnowFlakeCount] = useState<number>(0);
  const [fadeState, setFadeState] = useState<'in' | 'out' | 'none'>('none');

  const MAX_SNOWFLAKES = 300;
  const FADE_INTERVAL = 3;

  window.snowfall = () => {
    toggleFade();
  };

  const toggleFade = () => {
    setFadeState(fadeState === 'in' ? 'out' : 'in');
  };

  useEffect(() => {
    if (fadeState === 'in' && currentSnowFlakeCount <= MAX_SNOWFLAKES) {
      const timer = window.setTimeout(() => {
        setCurrentSnowFlakeCount(currentSnowFlakeCount + 1);
      }, FADE_INTERVAL);
      return () => window.clearTimeout(timer);
    } else if (fadeState === 'out' && currentSnowFlakeCount > 0) {
      const timer = window.setTimeout(() => {
        setCurrentSnowFlakeCount(currentSnowFlakeCount - 1);
      }, FADE_INTERVAL);
      return () => window.clearTimeout(timer);
    }
  }, [currentSnowFlakeCount, fadeState]);

  return (
    <SnowContainer>
      <Snowfall snowflakeCount={currentSnowFlakeCount} />
    </SnowContainer>
  );
};
