import { useTranslation } from 'react-i18next';

import { isPdfTronSupportedFileFormat } from '@pro4all/documents/ui/annotate';
import { Action, TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useDocumentToolsAction = ({
  allDocumentsExpected,
  sidebarRow,
}: Pick<DocumentActionProps, 'allDocumentsExpected' | 'sidebarRow'>) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();
  const { track } = useAnalytics();

  const isSupported =
    sidebarRow?.extension &&
    isPdfTronSupportedFileFormat(sidebarRow?.extension);

  const openDocumentTools = () => {
    track(TrackingEvent.DocumentViewed, {
      action: Action.SidebarButtonClicked,
    });
    goTo({
      searchParams: {
        annotate: true,
        fullscreen: true,
      },
    });
  };

  const documentToolsAction: ActionProps = {
    ariaLabel: t('Document tools'),
    dataTestId: 'download-tools',
    disabled: !isSupported || allDocumentsExpected,
    key: 'document-tools',
    label: t('Document tools'),
    onClick: openDocumentTools,
    startIcon: 'removeRedEye',
  };

  return documentToolsAction;
};
