import { useEffect } from 'react';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { getConditions } from '@pro4all/metadata/ui/utils';

import {
  useConditionsSidebarContext,
  useFieldContext,
} from '../TemplateMutationContext';

export const useFilterInvalidConditions = () => {
  const { fieldDefinitions } = useFieldContext();
  const { conditions, setConditions } = useConditionsSidebarContext();

  useEffect(() => {
    const conditions = getConditions(fieldDefinitions);
    setConditions(conditions);
  }, []);

  const filterInvalidConditions = (fieldDefinition: FieldDefinition) => {
    let newConditions = [...conditions];

    if (fieldDefinition.type === ValueTypeName.Section) {
      newConditions = conditions.filter(
        (condition) => condition.section !== fieldDefinition.id
      );
    } else if (fieldDefinition.type === ValueTypeName.Selection) {
      newConditions = conditions.filter(
        (condition) => condition.field !== fieldDefinition.id
      );
    }

    setConditions(newConditions);
  };

  return filterInvalidConditions;
};
