import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const FlagDenmark: React.FC<SvgIconProps> = () => (
  <svg
    height="14"
    id="flag-icons-dk"
    viewBox="0 0 640 480"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h640.1v480H0z" fill="#c8102e" />
    <path d="M205.7 0h68.6v480h-68.6z" fill="#fff" />
    <path d="M0 205.7h640.1v68.6H0z" fill="#fff" />
  </svg>
);
