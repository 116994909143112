import { EntityFolderPermission } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';

/**
 * In case the matrix is displayed in the `Show final permissions` mode we do not
 * want to display users of groups that do not have any permission set.
 * This hook takes care of filtering out those entities.
 */
export const useGetFilteredEntities = (
  entityFolderPermissions: EntityFolderPermission[]
) => {
  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const filterItemsWithoutPermissions = (row: EntityFolderPermission) => {
    const {
      folderPermissions,
      inheritedFrom,
      inheritedFromParentFolders,
      subFoldersPermissions,
    } = row;
    return !(
      showFinalPermissions &&
      (!folderPermissions || folderPermissions?.length === 0) &&
      (!inheritedFrom || inheritedFrom?.length === 0) &&
      (!inheritedFromParentFolders ||
        inheritedFromParentFolders?.length === 0) &&
      (!subFoldersPermissions || subFoldersPermissions?.length === 0)
    );
  };

  const itemsFiltered = entityFolderPermissions
    ? entityFolderPermissions.filter(filterItemsWithoutPermissions)
    : [];

  return itemsFiltered;
};
