import { Member, useGroupsAndUsersIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetMembers = () => {
  const { params } = useRouting();

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeEmail: true,
      includeIsAdmin: true,
      includeOrganization: true,
      projectId: params.projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  return members;
};
