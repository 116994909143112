import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DocumentPropertyType, Maybe, QcPropertyType } from '@pro4all/graphql';
import { Timestamp } from '@pro4all/shared/ui/general';
import { getFileSize } from '@pro4all/shared/utils';

import * as Styled from './EventProperty.styles';

interface Props {
  color?: 'primary' | 'secondary' | 'default';
  type?: Maybe<DocumentPropertyType | QcPropertyType> | undefined;
  value: string;
  variant?: 'outlined' | 'default';
}

// Temporary hack to remove time from date time string
// @Todo: move to resolver once BE returns proper types
const formatDate = (value: string) => {
  if (dayjs(value, 'MM/DD/YYYY HH:mm:ss', true).isValid())
    return dayjs(value).format('ll');
  else return value;
};

export const EventProperty: React.FC<Props> = ({
  value,
  type,
  color = 'secondary',
}) => {
  const { t } = useTranslation();

  const formatToDateTime = (<Timestamp date={value} />) as unknown as string;
  let valueString = value;

  switch (type) {
    case DocumentPropertyType.DateTime:
      return <Styled.Tag color={color} name={formatToDateTime} />;
    case DocumentPropertyType.FileSize:
      valueString = t(getFileSize(parseInt(value)) || 'Unknown');
      return <Styled.Tag color={color} name={valueString} />;
    case DocumentPropertyType.Selection: {
      const tags = value
        .split(',')
        .map((value, id) => (
          <Styled.Tag color={color} key={`${value}${id}`} name={value} />
        ));
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{tags}</>; // Encapsulate in tags so it can be passed as element
    }
    default:
      valueString = formatDate(
        t(`Api.propertyValueNames.${value}`, {
          defaultValue: value,
        })
      );
      return <Styled.Tag color={color} name={valueString} />;
  }
};
