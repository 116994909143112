import { TaskType, useProjectTasksQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useFetchTasks = ({
  typeFilter,
  projectId,
  skip,
}: {
  projectId?: string;
  skip?: boolean;
  typeFilter: TaskType[];
}) => {
  const { projectId: routingProjectId } = useRouting().params;
  projectId = routingProjectId ?? projectId;
  const projectTasks = useProjectTasksQuery({
    fetchPolicy: 'cache-and-network',
    skip,
    variables: {
      id: projectId,
      typeFilter: typeFilter,
    },
  });
  return projectTasks;
};
