import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { useGetIntegrationTypeData } from '../form/useGetIntegrationTypeData';

export const useConnectionFormConfig = () => {
  const { t } = useTranslation();
  const { integrationTypeLabel } = useGetIntegrationTypeData();

  const formFields: FormFieldConfig[] = [
    // Default fields none

    // CustomBuild12 fields
    // CustomSnagstream fields
    {
      displayName: t('{{integrationType}} project', {
        integrationType: integrationTypeLabel,
      }),
      name: 'project',
      required: true,
      type: ValueTypeName.Selection,
    },
  ];

  return useFormConfig({ formFields });
};
