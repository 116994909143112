import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { Document, useUnlockDocumentsMutation } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useUnlock = () => {
  const [unlockDocuments] = useUnlockDocumentsMutation();
  const { track } = useAnalytics();
  const { userId } = AuthService.getProfile();
  const { showSingleError } = useShowMessages();

  const unlock = async ({
    document,
    withNewVersion,
  }: {
    document: Document;
    withNewVersion: boolean;
  }) => {
    try {
      // Unlock the document.
      await unlockDocuments({
        variables: {
          documentIds: [document.id], // For now only on a per document base.
        },
      });

      // Analytics
      track(TrackingEvent.DocumentUnlocked, {
        documentId: document.id,
        extension: document.extension,
        folderId: document.folderId,
        lockedOn: new Date().toISOString(),
        locker: document?.lockedBy?.id,
        unlocker: userId,
        withNewVersion,
      });

      return true;
    } catch (e) {
      showSingleError(e);
      return false;
    }
  };

  return unlock;
};
