import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDownloadFilenameCharactersQuery } from '@pro4all/graphql';

import { AvailableCharacterCard } from './AvailableCharacterCard';
import * as Styled from './Styles';

export const AvailableCharacters = () => {
  const { t } = useTranslation();
  const { data } = useDownloadFilenameCharactersQuery();

  return (
    <Styled.Container>
      <Styled.Header variant="h6">{t('Available characters')}</Styled.Header>
      <Styled.FieldContainer>
        {data?.downloadFilenameCharacters.map((character, index) => (
          <AvailableCharacterCard character={character} key={index} />
        ))}
      </Styled.FieldContainer>
    </Styled.Container>
  );
};
