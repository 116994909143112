import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { Action, TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useCommentTracking = (documentId: string, folderId: string) => {
  const { track } = useAnalytics();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const { userId, name: senderName } = AuthService.getProfile();
  const {
    params: { projectId },
  } = useRouting();

  const trackAttachmentAdded = () =>
    track(TrackingEvent.CommentAttachmentsAdded, {
      documentId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  const trackAttachmentButtonClicked = () =>
    track(Action.CommentAttachmentButtonClicked, {
      documentId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  const trackAttachmentDownload = () =>
    track(TrackingEvent.CommentAttachmentDownloaded, {
      documentId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  const trackAttachmentRemoved = () =>
    track(TrackingEvent.CommentAttachmentRemoved, {
      documentId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  const trackInputClick = () =>
    track(Action.CommentTextboxClicked, {
      documentId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  const trackSubmit = () =>
    track(Action.CommentSaveButtonClicked, {
      documentId,
      folderId,
      organizationId,
      projectId,
      senderName,
      userId,
    });

  return {
    trackAttachmentAdded,
    trackAttachmentButtonClicked,
    trackAttachmentDownload,
    trackAttachmentRemoved,
    trackInputClick,
    trackSubmit,
  };
};
