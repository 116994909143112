import { useDrag, useDrop } from 'react-dnd';

import { DndItemCard, DndTypes } from '@pro4all/shared/config';

import { getChildIds } from './helpers/getChildIds';
import {
  AddType,
  BasePropsHierarchyItem,
  CallBackProps,
  ItemProps,
} from './types';

export const useItemCardDragAndDrop = <
  HierarchyItem extends BasePropsHierarchyItem
>({
  callbackDrag,
  callbackDrop,
  item,
  items,
  level,
  moveItem,
}: Pick<CallBackProps<HierarchyItem>, 'callbackDrag' | 'callbackDrop'> &
  Pick<ItemProps<HierarchyItem>, 'item' | 'items' | 'level' | 'moveItem'>) => {
  const [, dragCard] = useDrag({
    canDrag: () => (callbackDrag ? callbackDrag(item) : true),
    item: { id: item.id },
    type: DndTypes.ITEM_CARD,
  });

  const dropCard = ({
    addType,
    appendAfterTarget = false,
    itemSource,
    itemTarget,
  }: {
    addType: AddType;
    appendAfterTarget?: boolean;
    itemSource: DndItemCard;
    itemTarget: HierarchyItem;
  }) => {
    const sourceId = itemSource.id;
    const sourceItem = items.find(
      (itemCheck) => itemCheck.id === sourceId
    ) as HierarchyItem;
    if (
      callbackDrop &&
      !callbackDrop({
        addType,
        sourceItem,
        targetItem: itemTarget,
      })
    )
      return;
    moveItem({
      addType,
      appendAfterTarget,
      sourceId,
      targetId: itemTarget.id,
    });
  };

  const [{ delta, draggedItem, hoversOverCurrent: hoverOverCard }, dropOnCard] =
    useDrop({
      accept: [DndTypes.ITEM_CARD],
      canDrop: (draggedItem: DndItemCard) => {
        const childIds = getChildIds({
          allItems: items,
          id: draggedItem.id,
        });
        return !childIds.includes(item.id);
      },
      collect: (monitor) => ({
        delta: monitor.getDifferenceFromInitialOffset(), // Unfortunately this updates internal state in EVERY ItemCard, so multiple rerenders are triggered.
        draggedItem: monitor.getItem(), // Unfortunately this updates internal state in EVERY ItemCard, so multiple rerenders are triggered.
        hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
      }),
      drop: (itemSource: DndItemCard, monitor) => {
        if (!monitor.didDrop()) {
          // Drop action.
          if (itemSource.id === item.id) {
            // We dropped the item on itself, so now we're gonna check if we need to move it to the left or to the right.
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta && delta.x > 10) {
              // We have to move it to the right, so one level deeper (if possible).
              if (item.previousNodeId) {
                const previousItem = items.find(
                  (itemCheck) => itemCheck.id === item.previousNodeId
                ) as HierarchyItem;
                dropCard({
                  addType: AddType.CHILD,
                  appendAfterTarget: true,
                  itemSource,
                  itemTarget: previousItem,
                });
              }
            } else if (delta && delta.x < -10) {
              // We have to move it to the left, so one level less deep (if possible).
              if (item.parentNodeId) {
                const parentItem = items.find(
                  (itemCheck) => itemCheck.id === item.parentNodeId
                ) as HierarchyItem;
                dropCard({
                  addType: AddType.SIBLING,
                  appendAfterTarget: true,
                  itemSource,
                  itemTarget: parentItem,
                });
              }
            }
          } else {
            dropCard({
              addType: AddType.SIBLING,
              itemSource,
              itemTarget: item,
            });
          }
        }
      },
    });

  const [
    { hoversOverCurrent: hoverOverCardBottomMargin },
    dropOnCardBottomMargin,
  ] = useDrop({
    accept: [DndTypes.ITEM_CARD],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: (itemSource: DndItemCard, monitor) => {
      if (!monitor.didDrop()) {
        // Drop action.
        dropCard({ addType: AddType.CHILD, itemSource, itemTarget: item });
      }
    },
  });

  // In case we're dragging the item on itself to the right or to the left, we want to adjust indentation of the dropzone.
  const levelDropZone =
    delta && draggedItem?.id === item.id
      ? delta.x > 10
        ? level + 1
        : delta.x < -10
        ? level - 1
        : level
      : level;

  return {
    dragCard,
    dropOnCard,
    dropOnCardBottomMargin,
    hoverOverCard,
    hoverOverCardBottomMargin,
    levelDropZone,
  };
};
