import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

export const QueryTooGenericFeedback = () => {
  const { t } = useTranslation();

  return (
    <Alert color="info" icon={<Icon iconName="errorOutlined"></Icon>}>
      {t(
        'Your search query returned too many results. Showing a limited overview. For a complete list, refine your query with more specific keywords and filters.'
      )}
    </Alert>
  );
};
