import React from 'react';

import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDownloadZipButtonProps } from './actions/useDownloadZipButtonProps';
import { useRevokeLinkButtonProps } from './actions/useRevokeLinkButtonProps';

export const DocumentsActionBar: React.FC = () => {
  const { downloadZipButtonProps } = useDownloadZipButtonProps();
  const { revokeLinkButtonProps } = useRevokeLinkButtonProps();

  return (
    <TableActionBar
      dataTestid="shared-docs-action-bar"
      mainActions={[downloadZipButtonProps, revokeLinkButtonProps]}
      search
      showExportButton={false}
    />
  );
};
