import React, { Reducer, useCallback, useContext, useReducer } from 'react';

import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';

import {
  Action,
  ActionType,
  InitializeDocumentsForEditPayload,
  InitializeDocumentsForUploadPayload,
  SetProcessingStatusPayload,
  State,
  UpdateMetaMetadataPayload,
  UpdateMetaMetadataViaHeaderPayload,
  UpdateMetaNamePayload,
  UpdateMetaVersionPayload,
  useUploaderEditorReducer,
} from './useUploaderEditorReducer';

type ContextType = {
  addDocumentToContainerCreatedArray: (payload: string) => void;
  addDocumentToUploadedArray: (payload: string) => void;
  appendFilesForUpload: (payload: File[]) => void;
  cancelUploading: () => void;
  deleteFileFromProcessingList: (payload: string) => void;
  deleteInvalidMeta: () => void;
  deleteMetaVersion: (payload: string) => void;
  incrementFilesProcessed: () => void;
  initializeDocumentsForEdit: (
    payload: InitializeDocumentsForEditPayload
  ) => void;
  initializeDocumentsForUpload: (
    payload: InitializeDocumentsForUploadPayload
  ) => void;
  selectDocument: (payload: string) => void;
  selectDocumentsViaHeader: () => void;
  setHorizontalTabbing: (payload: boolean) => void;
  setInvalidMeta: () => void;
  setPartialUploadProgress: (payload: number) => void;
  setProcessingStatus: (payload: SetProcessingStatusPayload) => void;
  setTypingFromHeader: (payload: boolean) => void;
  startProcessing: (payload: boolean) => void;
  state: State;
  stopProcessing: (payload: boolean) => void;
  updateMetaMetadata: (payload: UpdateMetaMetadataPayload) => void;
  updateMetaMetadataViaHeader: (
    payload: UpdateMetaMetadataViaHeaderPayload
  ) => void;
  updateMetaName: (payload: UpdateMetaNamePayload) => void;
  updateMetaNameViaHeader: (payload: string) => void;
  updateMetaVersion: (payload: UpdateMetaVersionPayload) => void;
};

const UploaderEditorContext = React.createContext(null);

export function useUploaderEditorContext() {
  return useContext<ContextType>(UploaderEditorContext);
}

export function UploaderEditorProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const uploaderEditorReducer = useUploaderEditorReducer();

  const { getLocalStorageItem: getHorizontalTabbingLocalStorage } =
    useLocalStorage<boolean>({
      key: StorageKeys.HORIZONTAL_TABBING_IN_EDITOR,
    });

  const [state, dispatch] = useReducer(
    uploaderEditorReducer as Reducer<State, Action>,
    {
      allDocumentsSelected: false,
      currentDocumentsInFolder: [],
      documentCurrent: null,
      documentIdsContainerCreated: [],
      documentIdsSelected: [],
      documentIdsUploaded: [],
      fields: [],
      filesProcessed: 0,
      filesToUpload: [],
      folderId: '',
      horizontalTabbing: getHorizontalTabbingLocalStorage() === true || false,
      isProcessed: false,
      isProcessing: false,
      isTypingFromHeader: false,
      meta: [],
      metaInvalid: null,
      partialUploadProgress: 0,
      publishDocument: false,
      templateId: '',
      uploadingCancelled: false,
    }
  );
  // Define all actions
  const addDocumentToContainerCreatedArray = useCallback((payload: string) => {
    dispatch({
      payload,
      type: ActionType.ADD_DOCUMENT_TO_CONTAINER_CREATED_ARRAY,
    });
  }, []);
  const addDocumentToUploadedArray = useCallback((payload: string) => {
    dispatch({
      payload,
      type: ActionType.ADD_DOCUMENT_TO_UPLOADED_ARRAY,
    });
  }, []);
  const appendFilesForUpload = useCallback((payload: File[]) => {
    dispatch({
      payload,
      type: ActionType.APPEND_FILES_FOR_UPLOAD,
    });
  }, []);
  const cancelUploading = useCallback(() => {
    dispatch({
      type: ActionType.CANCEL_UPLOADING,
    });
  }, []);
  const deleteFileFromProcessingList = useCallback((payload: string) => {
    dispatch({
      payload,
      type: ActionType.DELETE_FILE_FROM_PROCESSING_LIST,
    });
  }, []);
  const deleteInvalidMeta = useCallback(() => {
    dispatch({
      type: ActionType.DELETE_INVALID_META,
    });
  }, []);
  const deleteMetaVersion = useCallback((payload: string) => {
    dispatch({
      payload,
      type: ActionType.DELETE_META_VERSION,
    });
  }, []);
  const incrementFilesProcessed = useCallback(() => {
    dispatch({
      type: ActionType.INCREMENT_FILES_PROCESSED,
    });
  }, []);
  const initializeDocumentsForEdit = useCallback(
    (payload: InitializeDocumentsForEditPayload) => {
      dispatch({
        payload,
        type: ActionType.INITIALIZE_DOCUMENTS_FOR_EDIT,
      });
    },
    []
  );
  const initializeDocumentsForUpload = useCallback(
    (payload: InitializeDocumentsForUploadPayload) => {
      dispatch({
        payload,
        type: ActionType.INITIALIZE_DOCUMENTS_FOR_UPLOAD,
      });
    },
    []
  );
  const selectDocument = useCallback((payload: string) => {
    dispatch({
      payload,
      type: ActionType.SELECT_DOCUMENT,
    });
  }, []);
  const selectDocumentsViaHeader = useCallback(() => {
    dispatch({
      type: ActionType.SELECT_DOCUMENTS_VIA_HEADER,
    });
  }, []);
  const setHorizontalTabbing = useCallback((payload: boolean) => {
    dispatch({
      payload,
      type: ActionType.SET_HORIZONTAL_TABBING,
    });
  }, []);
  const setInvalidMeta = useCallback(() => {
    dispatch({
      type: ActionType.SET_INVALID_META,
    });
  }, []);
  const setPartialUploadProgress = useCallback((payload: number) => {
    dispatch({
      payload,
      type: ActionType.SET_PARTIAL_UPLOAD_PROGRESS,
    });
  }, []);
  const setProcessingStatus = useCallback(
    (payload: SetProcessingStatusPayload) => {
      dispatch({
        payload,
        type: ActionType.SET_PROCESSING_STATUS,
      });
    },
    []
  );
  const setTypingFromHeader = useCallback((payload: boolean) => {
    dispatch({
      payload,
      type: ActionType.SET_TYPING_FROM_HEADER,
    });
  }, []);
  const startProcessing = useCallback((payload: boolean) => {
    dispatch({
      payload,
      type: ActionType.START_PROCESSING,
    });
  }, []);
  const stopProcessing = useCallback((payload: boolean) => {
    dispatch({
      payload,
      type: ActionType.STOP_PROCESSING,
    });
  }, []);
  const updateMetaMetadata = useCallback(
    (payload: UpdateMetaMetadataPayload) => {
      dispatch({
        payload,
        type: ActionType.UPDATE_META_METADATA,
      });
    },
    []
  );
  const updateMetaMetadataViaHeader = useCallback(
    (payload: UpdateMetaMetadataViaHeaderPayload) => {
      dispatch({
        payload,
        type: ActionType.UPDATE_META_METADATA_VIA_HEADER,
      });
    },
    []
  );
  const updateMetaName = useCallback((payload: UpdateMetaNamePayload) => {
    dispatch({
      payload,
      type: ActionType.UPDATE_META_NAME,
    });
  }, []);
  const updateMetaNameViaHeader = useCallback((payload: string) => {
    dispatch({
      payload,
      type: ActionType.UPDATE_META_NAME_VIA_HEADER,
    });
  }, []);
  const updateMetaVersion = useCallback((payload: UpdateMetaVersionPayload) => {
    dispatch({
      payload,
      type: ActionType.UPDATE_META_VERSION,
    });
  }, []);

  return (
    <UploaderEditorContext.Provider
      value={{
        addDocumentToContainerCreatedArray,
        addDocumentToUploadedArray,
        appendFilesForUpload,
        cancelUploading,
        deleteFileFromProcessingList,
        deleteInvalidMeta,
        deleteMetaVersion,
        incrementFilesProcessed,
        initializeDocumentsForEdit,
        initializeDocumentsForUpload,
        selectDocument,
        selectDocumentsViaHeader,
        setHorizontalTabbing,
        setInvalidMeta,
        setPartialUploadProgress,
        setProcessingStatus,
        setTypingFromHeader,
        startProcessing,
        state,
        stopProcessing,
        updateMetaMetadata,
        updateMetaMetadataViaHeader,
        updateMetaName,
        updateMetaNameViaHeader,
        updateMetaVersion,
      }}
    >
      {children}
    </UploaderEditorContext.Provider>
  );
}
