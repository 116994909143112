import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDefinition } from '@pro4all/graphql';

import { StyledCardNew, StyledIcon, StyledQuestionFieldName } from './Styles';
import { useSectionSidebarContext } from './TemplateMutationContext';

export const NewCardSection = ({
  parentSection,
}: {
  parentSection?: FieldDefinition;
}) => {
  const { t } = useTranslation();
  const { setIsOpenSectionSidebar, setParentSectionSidebar, setSection } =
    useSectionSidebarContext();

  return (
    <StyledCardNew
      onClick={() => {
        setIsOpenSectionSidebar(true);
        setSection(null);
        setParentSectionSidebar(parentSection);
      }}
    >
      <StyledIcon iconName="add" />
      <StyledQuestionFieldName variant="h6">
        {t('New section')}
      </StyledQuestionFieldName>
    </StyledCardNew>
  );
};
