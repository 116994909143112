import { useState } from 'react';

import { useRouting } from '@pro4all/shared/routing-utils';
import { tryParseString } from '@pro4all/shared/utils';

export enum StorageKeyType {
  QualityControl = 'QualityControl',
  RecentSearches = 'recent-searches',
}

export interface HistoryProps {
  maxRecentSearches?: number;
  storageKey: StorageKeyType;
}

export const useHistory = ({
  storageKey,
  maxRecentSearches = 8,
}: HistoryProps) => {
  const { params } = useRouting();
  const { projectId } = params;
  const [recentSearches, setRecentSearches] = useState<string[] | null>(null);

  const scopedKey = `${storageKey}-${projectId ? `proj-${projectId}` : 'org'}`;

  const searchesFromStorage = localStorage.getItem(scopedKey);

  const loadFromStorage = () => {
    if (!searchesFromStorage) {
      // Init empty search history
      setRecentSearches([]);
      return;
    }
    const deserialized: StorageObject = tryParseString(searchesFromStorage);
    deserialized && setRecentSearches(deserialized.recentSearches);
  };

  // Init recent searches
  if (!recentSearches) {
    loadFromStorage();
  }

  /* Add query to localstorage and update state */
  const storeSearch = (value: string) => {
    if (!value || !recentSearches) return;

    let clonedSearches = [...recentSearches];
    const index = clonedSearches.indexOf(value); // Find duplicate

    if (index >= 0) {
      // Re-insert in case of duplicate
      clonedSearches.splice(index, 1);
      clonedSearches.unshift(value);
    } else {
      const newLength = clonedSearches.unshift(value); // Insert at index 0
      const delta = maxRecentSearches ? maxRecentSearches - newLength - 1 : 0;
      // Remove tail if max is exceeded
      clonedSearches =
        delta < 0
          ? [value, ...recentSearches].slice(0, delta)
          : [value, ...recentSearches];
    }
    // Update state
    setRecentSearches(clonedSearches);

    // Update localstorage
    const newSearchObject: StorageObject = {
      recentSearches: clonedSearches,
    };
    localStorage.setItem(scopedKey, JSON.stringify(newSearchObject));
  };

  const remove = (searchString: string) => {
    if (!searchesFromStorage) return;

    const deserialized: StorageObject = tryParseString(searchesFromStorage);
    if (!deserialized) return;

    const filteredSearches = deserialized.recentSearches.filter(
      (s) => s !== searchString
    );
    const updatedStorageObject: StorageObject = {
      recentSearches: filteredSearches,
    };

    setRecentSearches(filteredSearches);
    localStorage.setItem(scopedKey, JSON.stringify(updatedStorageObject));
  };

  const clear = () => {
    localStorage.removeItem(scopedKey);
    setRecentSearches([]);
  };

  return {
    clear,
    recentSearches: recentSearches || [],
    remove,
    set: storeSearch,
  };
};

export type History = {
  clear: () => void;
  recentSearches: string[] | null;
  remove: (searchString: string) => void;
  set: (value: string) => void;
};

type StorageObject = {
  recentSearches: string[];
};
