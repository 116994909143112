import styled from 'styled-components';

import { Feedback, TextField } from '@pro4all/shared/ui/general';

export const EntitySearchContainer = styled('div')`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const EntitySearchTextField = styled(TextField)`
  && {
    margin: ${({ theme }) => theme.spacing(1)};
    max-height: 40px;
  }
`;

export const EntitySearchFeedback = styled(Feedback)`
  margin: ${({ theme }) => theme.spacing(1)};
`;
