import { Meta, State } from '../useUploaderEditorReducer';

export const updateMetaNameViaHeaderAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const { documentIdsSelected, meta } = state;
  const shouldUpdate = (doc: Meta) =>
    documentIdsSelected.length === 0 || documentIdsSelected.includes(doc.id);

  const metaUpdated =
    payload !== ''
      ? (meta.map((doc, index) => {
          const updateDocument = shouldUpdate(doc);
          return updateDocument
            ? {
                ...doc,
                name: `${payload}_${index}`,
              }
            : doc;
        }) as Meta[])
      : (meta.map((doc) => {
          const updateDocument = shouldUpdate(doc);
          return updateDocument ? { ...doc, name: doc.nameInitial } : doc;
        }) as Meta[]);

  return {
    ...state,
    meta: metaUpdated,
  };
};
