import { useCallback, useEffect, useState } from 'react';

import {
  useGetTbqOsidDeviceLinkStateQuery,
  useGetTbqOsidDeviceLogsQuery,
  useGetTbqOsidDevicesQuery,
} from '@pro4all/graphql';

export const useOsidData = (objectId: string) => {
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  // Fetch OSID devices
  const { data: osidData } = useGetTbqOsidDevicesQuery({
    fetchPolicy: 'network-only',
  });
  const osidDevices = osidData?.getTBQOsidDevices?.data?.data || [];
  const noOsidDevices = osidDevices.length === 0;

  // Fetch device link state
  const {
    data: osidDeviceLinkState,
    error: osidDeviceLinkStateError,
    refetch: refetchLinkState,
  } = useGetTbqOsidDeviceLinkStateQuery({
    fetchPolicy: 'network-only',
    skip: noOsidDevices,
    variables: { objectId },
  });

  const linkedDeviceName =
    osidDeviceLinkState?.getTBQOsidDeviceLinkState.data || '';

  const updateLinkState = useCallback(() => {
    if (linkedDeviceName) setSelectedDevice(linkedDeviceName);
    refetchLinkState();
  }, [linkedDeviceName, refetchLinkState]);

  useEffect(() => {
    updateLinkState();
  }, [updateLinkState]);

  useEffect(() => {
    if (!linkedDeviceName) setSelectedDevice('');
  }, [linkedDeviceName]);

  const isLinkedDevice = Boolean(linkedDeviceName) && !osidDeviceLinkStateError;

  // Fetch device logs
  const {
    data: osidDeviceLogData,
    loading: osidDeviceLogLoading,
    refetch: refetchDeviceLogs,
  } = useGetTbqOsidDeviceLogsQuery({
    fetchPolicy: 'network-only',
    skip: noOsidDevices || !isLinkedDevice,
    variables: { deviceCode: selectedDevice, objectId },
  });

  const deviceLogs = osidDeviceLogData?.getTBQOsidDeviceLogs || [];

  const handleLinkStatusChange = async () => {
    await refetchLinkState();
    await refetchDeviceLogs();
  };

  return {
    deviceLogs,
    handleLinkStatusChange,
    isLinkedDevice,
    linkedDeviceName,
    noOsidDevices,
    osidDeviceLogLoading,
    osidDevices,
    setSelectedDevice,
  };
};
