import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  FieldDefinition,
  FormLinkedSnag,
  useQualityControlInstanceQuery,
  useTaskQuery,
} from '@pro4all/graphql';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { StyledTooltip } from '@pro4all/shared/ui/side-navigation';

import { LinkTbqQuestionDialog } from './LinkTbqQuestionDialog';
import { UnlinkTbqQuestionDialog } from './UnlinkTbqQuestionDialog';
import { useTbqLocationData } from './useTbqLocationData';

export enum QuestionDialogState {
  Default,
  LinkQuestion,
  UnlinkQuestion,
}
export const LinkQuestionButtons = ({ taskId }: { taskId: string }) => {
  const { t } = useTranslation();
  const [indicativeState, setState] = useState<QuestionDialogState>(
    QuestionDialogState.Default
  );
  const locationData = useTbqLocationData();

  const {
    data: snagInstance,
    refetch: refetchQualityInstance,
    error,
  } = useQualityControlInstanceQuery({
    fetchPolicy: 'no-cache',
    skip: !locationData.snagInstanceId,
    variables: { id: locationData.snagInstanceId },
  });

  const snagData = snagInstance?.qualityControlInstance || null;
  const linkedSnags = snagData?.linkedSnags || [];

  const { data: taskData, error: taskError } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId,
    variables: { id: taskId },
  });

  const items: FieldDefinition[] =
    (taskData?.task?.tbqFormInstances?.[0]?.items as FieldDefinition[]) || [];

  const convertedLinkedSnags = linkedSnags
    ? linkedSnags
        .filter(
          (snag): snag is FormLinkedSnag => snag !== null && snag !== undefined
        )
        .map((snag) => ({
          appData: snag.appData,
          direction: snag.direction,
          id: snag.id,
          linkId: snag.linkId,
          linkType: snag.linkType,
          type: snag.type,
        }))
    : [];

  const renderDialog = () => {
    switch (indicativeState) {
      case QuestionDialogState.LinkQuestion:
        return (
          <LinkTbqQuestionDialog
            fieldDefinitions={items}
            linkedSnags={linkedSnags}
            onClose={() => setState(QuestionDialogState.Default)}
            refetchQualityInstance={refetchQualityInstance}
          />
        );
      case QuestionDialogState.UnlinkQuestion:
        return (
          <UnlinkTbqQuestionDialog
            fieldDefinitions={items}
            linkedSnags={convertedLinkedSnags}
            onClose={() => setState(QuestionDialogState.Default)}
            refetchQualityInstance={refetchQualityInstance}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderDialog()}
      <ButtonsWrapper>
        <StyledTooltip placement="bottom" title={t('Link form and question')}>
          <IconButton
            color="default"
            disableBorder
            iconName="link"
            onClick={() => {
              setState(QuestionDialogState.LinkQuestion);
            }}
          />
        </StyledTooltip>
        <StyledTooltip placement="bottom" title={t('Unlink question')}>
          <StyledTbqQuestionButton
            aria-haspopup="true"
            id="demo-customized-button"
            onClick={() => setState(QuestionDialogState.UnlinkQuestion)}
            startIcon="questionMark"
          >
            {linkedSnags.length}
          </StyledTbqQuestionButton>
        </StyledTooltip>
      </ButtonsWrapper>
    </>
  );
};

export const StyledTbqQuestionButton = styled(Button)`
  && {
    border-radius: 4px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  padding: 10px;
  position: relative;
  right: 12px;
`;
