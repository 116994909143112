import { ViewerNode } from '../../viewers/Viewer.types';

import { TreeList } from './EntityTree.styles';
import { EntityTreeNode } from './EntityTreeNode';

export const EntityTree: React.FC<{
  activeNodes: ViewerNode[];
  onFocus: (nodes: ViewerNode[]) => void;
  onHide: (nodes: ViewerNode[]) => void;
  onIsolate: (nodes: ViewerNode[]) => void;
  onSelect: (nodes: ViewerNode[]) => void;
  onShowAll: () => void;
  rootNode: ViewerNode;
}> = ({
  activeNodes,
  onHide,
  onIsolate,
  onFocus,
  onSelect,
  onShowAll,
  rootNode,
}) => (
  <TreeList>
    <EntityTreeNode
      activeNodes={activeNodes}
      level={0}
      node={rootNode}
      onFocus={onFocus}
      onHide={onHide}
      onIsolate={onIsolate}
      onSelect={onSelect}
      onShowAll={onShowAll}
    ></EntityTreeNode>
  </TreeList>
);
