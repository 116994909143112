import { useCallback } from 'react';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  DocumentVersion,
  DocumentVersionDocument,
  DocumentVersionState,
  Folder,
  FolderByPathDocument,
  UploadFileOption,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useUpdateCachedDocumentsList = () => {
  const {
    params: { projectId, path },
  } = useRouting();
  const { settings } = useProjectContext();
  // The keyField prop it's necessary because sometimes
  // the backend it's recibing the document id and sometimes
  // use the versionId of the document.
  const updateCachedDocumentList = useCallback(
    async ({
      currentDocumentId,
      currentDocumentVersion,
      destinationDocumentId,
      newVersionId,
    }: {
      currentDocumentId: string;
      currentDocumentVersion: DocumentVersion;
      destinationDocumentId: string;
      newVersionId: string;
    }) => {
      const documentsQueryParams = {
        query: FolderByPathDocument,
        variables: projectId ? { path, projectId } : { path },
      };
      const cachedFolder = client?.readQuery(documentsQueryParams)
        ?.folder as Folder;
      const cachedDocuments = cachedFolder?.documents;
      const destinationDocument = cachedDocuments?.find(
        (document) => document?.id === destinationDocumentId
      );

      // Looking for the version data of the destination document
      const destinationVersionQueryParams = {
        query: DocumentVersionDocument,
        variables: { id: destinationDocumentId },
      };

      const destinationCachedVersions = client?.readQuery(
        destinationVersionQueryParams
      )?.documentVersion as DocumentVersion[] | null;

      client.writeQuery({
        ...destinationVersionQueryParams,
        data: {
          documentVersion: [
            {
              ...currentDocumentVersion,
              id: newVersionId,
              versionNumber: (destinationDocument?.amountOfVersions ?? 0) + 1,
              versionState: DocumentVersionState.None,
            },
            ...(destinationCachedVersions ?? []),
          ],
        },
      });

      // Update destination Document version
      const cachedDocumentsClone = cachedDocuments?.map((iterator) => {
        if (iterator?.id === destinationDocumentId && iterator.versionNumber) {
          return {
            ...iterator,
            metaData: currentDocumentVersion.metaData,
            name:
              settings.version_name_instead_of_document_name_on_upload ===
              UploadFileOption.UseFromUploaded
                ? currentDocumentVersion.name
                : iterator.name,
            versionNumber: (iterator.amountOfVersions ?? 0) + 1,
            versionState: DocumentVersionState.None,
          };
        }
        return iterator;
      });

      client.writeQuery({
        ...documentsQueryParams,
        data: {
          folder: {
            ...cachedFolder,
            documents: cachedDocumentsClone?.filter(
              (iterator) => iterator?.id !== currentDocumentId
            ),
          },
        },
      });
    },
    [path, projectId]
  );

  return updateCachedDocumentList;
};
