import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Snag: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M11.6667 15.3333C15.3486 15.3333 18.3333 12.3486 18.3333 8.66667C18.3333 4.98477 15.3486 2 11.6667 2C7.98477 2 5 4.98477 5 8.66667C5 12.3486 7.98477 15.3333 11.6667 15.3333ZM11.6669 11.1669C13.0476 11.1669 14.1669 10.0476 14.1669 8.6669C14.1669 7.28619 13.0476 6.1669 11.6669 6.1669C10.2862 6.1669 9.1669 7.28619 9.1669 8.6669C9.1669 10.0476 10.2862 11.1669 11.6669 11.1669Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10 14.5H13.3333V17.8333C13.3333 17.8333 12.0833 22 11.6667 22C11.25 22 10 17.8333 10 17.8333V14.5Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
