import React from 'react';
import styled from 'styled-components';

import { Chip, ChipProps } from '@pro4all/shared/mui-wrappers';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

const StyledTag = styled(Chip)``;

export interface TagProps extends ChipProps {
  name: string;
  onClickTag?: (tagId: string) => void;
  startIcon?: IconName;
  tagId?: string;
}

export const Tag: React.FC<TagProps> = ({
  name,
  startIcon,
  onClickTag,
  onDelete,
  color = 'default',
  variant = 'filled',
  tagId,
  ...rest
}) => (
  <StyledTag
    clickable={Boolean(onClickTag)}
    color={color}
    deleteIcon={<Icon fontSize="small" iconName="close" />}
    icon={startIcon && <Icon iconName={startIcon} />}
    label={name}
    onClick={() => onClickTag && onClickTag(tagId)}
    onDelete={onDelete}
    size="small"
    variant={variant}
    {...rest}
  />
);
