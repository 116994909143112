import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  QcPermissionCategory,
  QualityControlInstance,
  QualityControlInstanceType,
  ResourceType,
  useDeleteInstanceMutation,
  useRestoreInstanceMutation,
} from '@pro4all/graphql';
import { TaskType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  useFetchResourcePermissions,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import { Action } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import {
  ItemChangedMessage,
  useDiscardModalContext,
} from '@pro4all/shared/ui/messages';
import { useAnalytics } from '@pro4all/shared/vendor';

import { ResultTabKey } from './ResultsSidebarTabs';

export const useSidebarActions = ({
  result,
  currentTab,
}: {
  currentTab: ResultTabKey;
  result: QualityControlInstance;
}) => {
  const { t } = useTranslation();
  const { params, searchParams } = useRouting();
  const { track } = useAnalytics();

  const routeView = searchParams.is('action', 'viewResult');
  const routeEdit = searchParams.is('action', 'editResult');

  const { setBypass } = useDiscardModalContext();
  const { setAnswersForApplying } = useSavedAnswersContext();

  const { hasAppPermission } = useAppPermissions();
  const userCannotReadProject = !hasAppPermission('ProjectRead');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');

  const { userId } = useOrganizationContext();
  const {
    snagUpdateAll,
    formUpdateAll,
    formUpdateOwnAssigned,
    snagUpdateOwnAssigned,
  } = useFetchResourcePermissions({
    resourceId: result?.id,
    resourceType: ResourceType.Instance,
  });

  const { deleteItems, restoreItems } =
    useOptimisticResponseContext<QualityControlInstance>();

  const { resolveCreateAll, resolveCreateOwn } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
  });

  const { formDeleteAll, snagDeleteAll, snagDeleteOwn, formDeleteOwn } =
    useQCPermissions({
      category: QcPermissionCategory.Qcs,
      qcGroupPermissionsFlag,
    });
  const canDeleteSnag =
    snagDeleteAll ||
    (snagDeleteOwn &&
      result?.type === QualityControlInstanceType.Snag &&
      result?.createdBy?.id === userId);
  const canDeleteForm =
    formDeleteAll ||
    (formDeleteOwn &&
      result?.type === QualityControlInstanceType.Form &&
      result?.createdBy?.id === userId);

  const itemType =
    result?.type === QualityControlInstanceType.Snag
      ? QualityControlInstanceType.Snag
      : QualityControlInstanceType.Form;

  const canDelete = {
    [QualityControlInstanceType.Snag]: canDeleteSnag,
    [QualityControlInstanceType.Form]: canDeleteForm,
  }[itemType];

  const deleteQualityResultsFeatureFlag = useFeatureFlag(
    'delete-quality-results'
  );

  const { enqueueSnackbar } = useSnackbar();

  const [deleteInstanceMutation] = useDeleteInstanceMutation();
  const [restoreInstanceMutation] = useRestoreInstanceMutation();

  const handleRestoreInstance = async () => {
    try {
      await restoreInstanceMutation({
        variables: {
          ids: [result?.id],
        },
      });
      restoreItems([result?.id]);
      enqueueSnackbar(
        <Alert severity="info">
          {t(`{{instanceType}} restored`, {
            instanceType: itemType,
          })}
        </Alert>
      );
    } catch (_) {
      enqueueSnackbar(
        t(`Failed to restore {{instanceType}}`, {
          instanceType: itemType,
        }),
        {
          variant: 'error',
        }
      );
    }
  };

  const handleDeleteSnag = async () => {
    const instanceIds = [result.id];

    try {
      const deleteResponse = await deleteInstanceMutation({
        variables: {
          ids: instanceIds,
        },
      });
      const successDeletedItems = deleteResponse.data?.deleteInstance?.filter(
        (item) => item?.success
      );

      const message = successDeletedItems?.length
        ? t(`${itemType} deleted`)
        : t(`failed to delete ${itemType}`);
      enqueueSnackbar(
        <ItemChangedMessage
          action={
            <Button
              color="inherit"
              onClick={handleRestoreInstance}
              size="small"
            >
              {t('Undo')}
            </Button>
          }
        >
          {message}
        </ItemChangedMessage>
      );

      searchParams.set({ action: '' });
      deleteItems?.(instanceIds);
    } catch (_) {
      enqueueSnackbar(t('Failed to delete items'), {
        variant: 'error',
      });
    }
  };

  const showEditButton =
    currentTab === ResultTabKey.Properties &&
    ((result?.type === QualityControlInstanceType.Snag &&
      (snagUpdateOwnAssigned || snagUpdateAll)) ||
      (result?.type === QualityControlInstanceType.Form &&
        (formUpdateAll || formUpdateOwnAssigned)) ||
      result?.type === QualityControlInstanceType.Tbq);

  const showResolveButton =
    result?.type === QualityControlInstanceType.Snag &&
    (resolveCreateAll || resolveCreateOwn);

  const resolveButton: ActionProps = {
    ariaLabel: t('Resolve'),
    dataTestId: 'resolve-result',
    disabled: userCannotReadProject,
    key: 'resolve-result',
    label: t('Resolve task'),
    onClick: () => {
      track(Action.CreateResolveTaskButtonClicked, {
        itemAmount: 1,
        location: 'Snag details',
        projectId: params.projectId,
      });

      searchParams.set({
        action: 'createTask',
        taskType: TaskType.Resolve,
      });
    },
    startIcon: 'taskSucceeded',
  };

  const editResultButton: ActionProps = {
    ariaLabel: t('Edit'),
    dataTestId: 'edit-result',
    disabled: userCannotReadProject,
    key: 'edit-result',
    label: t('Edit'),
    onClick: () => {
      setAnswersForApplying([]); // Initialize SavedAnswers.
      setTimeout(() => {
        searchParams.set({ action: 'editResult', id: result.id });
      }, 100); // Little delay to give the answersForApplying state time to update.
    },
    startIcon: 'edit',
  };

  const createSavedAnswersButton: ActionProps = {
    ariaLabel: t('Create saved answers'),
    dataTestId: 'create-saved-answers',
    disabled: userCannotReadProject,
    key: 'create-saved-answers',
    label: t('Create saved answers'),
    onClick: () => {
      setBypass(true);
      setTimeout(() => {
        searchParams.set({ createSavedAnswers: 'true' });
      }, 100); // Little delay to give the bypass state time to update.
    },
    startIcon: 'bookmark',
  };

  const applySavedAnswersButton: ActionProps = {
    ariaLabel: t('Apply saved answers'),
    dataTestId: 'apply-saved-answers',
    disabled: userCannotReadProject,
    key: 'apply-saved-answers',
    label: t('Apply saved answers'),
    onClick: () => {
      setBypass(true);
      setTimeout(() => {
        searchParams.set({ applySavedAnswers: 'true' });
      }, 100); // Little delay to give the bypass state time to update.
    },
    startIcon: 'bookmarkAdd',
  };

  const deleteSnagButton: ActionProps = {
    ariaLabel: t(
      `Delete ${
        result?.type === QualityControlInstanceType.Snag ? 'Snag' : 'Form'
      }`
    ),
    dataTestId: 'delete-snag',
    key: 'delete-snag',
    label: t(
      `Delete ${
        result?.type === QualityControlInstanceType.Snag ? 'Snag' : 'Form'
      }`
    ),
    onClick: () => {
      handleDeleteSnag();
    },
    startIcon: 'delete',
  };

  const viewActions = [
    showResolveButton && resolveButton,
    showEditButton && editResultButton,
    createSavedAnswersButton,
    deleteQualityResultsFeatureFlag && canDelete && deleteSnagButton,
  ].filter(Boolean) as ActionProps[];

  const editActions = [
    createSavedAnswersButton,
    applySavedAnswersButton,
  ].filter(Boolean) as ActionProps[];

  return result ? (routeView ? viewActions : routeEdit ? editActions : []) : [];
};
