import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Answer, useAddSavedAnswersMutation } from '@pro4all/graphql';
import {
  convertQcInstanceValue,
  getItem,
} from '@pro4all/quality-control/ui/shared';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { stringifyArrayValue } from '@pro4all/shared/utils';

import { getExcludedItemIds } from './getExcludedItemIds';
import { FormFields } from './types';

export const useSubmit = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    params: { projectId },
  } = useRouting();
  const { answers, items, templateId } = useSavedAnswersContext();
  const [addSavedAnswers] = useAddSavedAnswersMutation();
  const { showSingleError } = useShowMessages();

  return async (values: FormFields) => {
    const fieldIdsOfExcludedTypes = getExcludedItemIds(items);

    const resultArray = answers
      ? Object.entries(answers)
          .map(([key, value]) => {
            const item = getItem({ id: key, items });
            const valuePayload = item
              ? convertQcInstanceValue({ item, value })
              : stringifyArrayValue(value);
            return {
              fieldId: key,
              value: valuePayload,
            };
          })
          .filter((answer) => !fieldIdsOfExcludedTypes.includes(answer.fieldId))
      : [];

    try {
      await addSavedAnswers({
        variables: {
          answers: resultArray as Answer[],
          name: values.name || '',
          projectId,
          templateId,
        },
      });

      onClose();

      enqueueSnackbar(
        t("Your saved answers '{{name}}' has been saved successfully", {
          name: values.name || '',
        })
      );
    } catch (e) {
      showSingleError(e);
      onClose();
    }
  };
};
