import { generatePath } from 'react-router-dom';

import { encodeURIPath } from '@pro4all/shared/utils';

type Path = Parameters<typeof generatePath>[0];
type Params = Parameters<typeof generatePath>[1];

export interface UrlOptions {
  params?: Params;
  searchParams?: Params;
}

export const generateUrl = (
  routePath: Path,
  { params, searchParams }: UrlOptions = {}
): string => {
  const { path, ...rest } = params ?? {};
  let url = '';

  // generatePath does an encodeURIComponent on every param, so we need
  // to append path for folders separately. Because we want to come as close
  // to the original generatePath functionality but with non-url-encoded paths,
  // we need to strip out the variable;
  const routeDefinition = routePath.replace(/:path[?*]?/g, '__PATH__');

  // and squeeze the path string in.
  url += generatePath(routeDefinition, rest).replace(
    '__PATH__',
    encodeURIPath(path)
  );

  if (searchParams) {
    const search = new URLSearchParams();
    Object.entries(searchParams).forEach(([name, value]) => {
      if (value === null || value === undefined) return;
      search.set(name, value.toString());
    });

    const queryString = search.toString();
    if (queryString) url += `?${queryString}`;
  }

  return decodeURIComponent(url);
};
