import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const InactiveUser: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        clipRule="evenodd"
        d="M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm1.78 14.1c.159.751.22 1.421.22 1.9h5c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4-.359 0-.765.024-1.2.072a6.494 6.494 0 011.645 1.729h-.005l.021.024a8.82 8.82 0 01.885 1.774c.144.413.29.91.425 1.502h.008z"
        fillRule="evenodd"
      />
      <path d="M4.912 14.436c-.48-.08-.912.312-.912.8 0 .392.288.72.68.784a4.015 4.015 0 013.264 3.264c.064.392.392.68.784.68a.803.803 0 00.8-.912 5.598 5.598 0 00-4.616-4.616zM4 17.563v2.4h2.4c0-1.328-1.072-2.4-2.4-2.4z" />
      <path d="M4.88 11.202a.808.808 0 00-.88.8.79.79 0 00.704.792 7.197 7.197 0 016.464 6.464c.04.4.384.696.792.696.48 0 .848-.416.8-.888a8.817 8.817 0 00-7.88-7.864z" />
    </g>
    <path d="M4.828 2l16.97 16.97-1.413 1.415-16.97-16.97L4.827 2z" />
    <path d="M3.414 3.414l16.97 16.97L18.97 21.8 2 4.83l1.414-1.415z" />{' '}
  </SvgIcon>
);
