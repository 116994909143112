import { useMemo } from 'react';

import {
  QualityControlInstance,
  ResolveTaskLinks,
  Task,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  isTbqBrandcheck,
  isTbqRie,
  splitUniqQuestionId,
} from '@pro4all/workflow/ui/utils';

export const useCalculateResolveTaskPerQuestion = (
  questions: TbqAnswer[] | TbqRieResult[],
  tasks: Task[],
  qcInstance?: QualityControlInstance
) => {
  const { searchParams } = useRouting();
  const scanId = qcInstance?.task?.id ?? searchParams.get('taskId');

  const calculateRelevantTasks = (question: TbqAnswer | TbqRieResult) =>
    tasks.filter((task) => {
      const linkedQuestions = task.tbqLinkedQuestions || [];

      let questionSectionId = '';
      let questionScanId = '';

      if (isTbqBrandcheck(question)) {
        questionSectionId = question.sectionId;
        questionScanId = scanId ?? '';
      } else if (isTbqRie(question)) {
        questionSectionId = question?.section.id;
        questionScanId = question?.taskId ?? '';
      }

      return linkedQuestions.some((linkedQuestion: ResolveTaskLinks) => {
        const questionId = linkedQuestion?.id ?? '';
        const relevantQuestionId = splitUniqQuestionId(questionId);

        const isAppDataMatch =
          linkedQuestion.appData &&
          linkedQuestion.appData === questionSectionId;
        const isTaskIdMatch = relevantQuestionId === questionScanId;

        return isAppDataMatch && isTaskIdMatch;
      });
    });

  const relevantTasksForAllQuestions = useMemo(
    () =>
      questions.map((question) => ({
        question,
        tasks: calculateRelevantTasks(question),
      })),
    [questions, tasks]
  );

  return relevantTasksForAllQuestions;
};
