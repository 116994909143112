import React from 'react';

import { FilterType } from '@pro4all/graphql';
import {
  delimiters,
  FILE_TYPE_PREFIX,
  FilterBaseProps,
  removeDotFromExtension,
} from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { isDefined } from '@pro4all/shared/utils';

import { TagSelect } from './TagSelect';

export const FileTypeFilter: React.FC<FilterBaseProps> = ({
  value,
  facetGroup,
  facetLoading,
}) => {
  const options: Option[] =
    facetGroup?.items?.filter(isDefined).map((facetItem) => ({
      id: `filetype-option-${facetItem.name}-${facetItem.value}`,
      inputValue: facetItem.value || '',
      label: removeDotFromExtension(facetItem.value) || '',
    })) || [];

  const labels = value.split(delimiters.multiSelectOptions);
  const initialOptions: Option[] = options.filter((option) =>
    labels.includes(option?.inputValue || option.label)
  );

  return (
    <TagSelect
      freeSolo
      initialOptions={initialOptions}
      loading={facetLoading}
      options={options}
      parseTagValue={removeDotFromExtension}
      prefix={FILE_TYPE_PREFIX}
      type={FilterType.Extension}
      value={value}
    />
  );
};
