import React from 'react';
import { Route } from 'react-router-dom';

import { Logout } from '@pro4all/authentication/src/routes/logout';
import { LogoutCallback } from '@pro4all/authentication/src/routes/logout-callback';
import { SignIn } from '@pro4all/authentication/src/routes/signin';
import { SilentRenew } from '@pro4all/authentication/src/routes/silent-renew';
import { Sso } from '@pro4all/authentication/src/routes/sso';

export const Routes = () => (
  <>
    <Route component={SignIn} exact path="/signin" />
    <Route component={Sso} exact path="/signin-oidc" />
    <Route component={Logout} exact path="/logout" />
    <Route component={LogoutCallback} exact path="/logout-callback" />
    <Route component={SilentRenew} exact path="/silentrenew" />
  </>
);
