import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  Document,
  DocumentFragmentFragmentDoc,
  StampParams,
  StampStatus,
  useStampDocumentMutation,
} from '@pro4all/graphql';

import type { StamperDocument } from '../store';

export function useStamp() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [stampDocument] = useStampDocumentMutation();

  const updateCachedDocuments = useUpdateCachedDocuments();

  return async (stamperDocument: StamperDocument, params?: StampParams) => {
    if (!params) return;

    const { documentId, id: documentVersionId } = stamperDocument;

    try {
      const { data } = await stampDocument({
        update: (cache, data) => {
          if (data?.data?.stampDocument?.success) {
            const document: Document | null = cache.readFragment({
              fragment: DocumentFragmentFragmentDoc,
              id: `Document:${documentId}`,
            });

            if (document) {
              cache.writeFragment({
                data: {
                  ...document,
                  hasPreviousVersionQr: true,
                  qrCodeState:
                    document.versionId === documentVersionId
                      ? StampStatus.Progress
                      : document.qrCodeState,
                },
                fragment: DocumentFragmentFragmentDoc,
                id: `Document:${documentId}`,
              });
            }
          }
        },
        variables: { documentId, documentVersionId, params },
      });

      updateCachedDocuments({
        documentIds: [documentId],
        fieldToUpdate: 'qrCodeState',
        keyField: 'id',
        value: StampStatus.Progress,
      });
      updateCachedDocuments({
        documentIds: [documentVersionId],
        fieldToUpdate: 'hasPreviousVersionQr',
        keyField: 'id',
        value: true,
      });

      if (data?.stampDocument?.success) {
        enqueueSnackbar(t('Document stamped'));
      } else {
        enqueueSnackbar(
          t(data?.stampDocument?.errors?.[0] ?? 'Something went wrong')
        );
      }
    } catch (error) {
      enqueueSnackbar(
        t('Something went wrong. Please try again or contact an administrator.')
      );
    }
  };
}
