import React, { useEffect, useState } from 'react';

import { FacetItem } from '@pro4all/graphql';
import { FilterBaseProps, isMetaDataType } from '@pro4all/shared/search-utils';
import { isDefined, toValueTypeName } from '@pro4all/shared/utils';

import { useFilters } from './useFilters';

export const useMDFacets = ({
  facetGroup,
  metaDataKey,
  type,
}: FilterBaseProps) => {
  const { currentFilters, setFieldDefinition } = useFilters();
  const uniqueId = Array.from(
    new Set(facetGroup?.items?.map((facet) => facet?.mdField?.id))
  );

  const [selectedFacet, setSelectedFacet] = useState<FacetItem | undefined>();
  const [inputValue, setInputValue] = useState<string>('');

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const uniqueFacets: FacetItem[] = [];
  uniqueId.forEach((id) => {
    const foundFacet = facetGroup?.items?.find(
      (facet) =>
        facet?.mdField?.id === id &&
        facet?.mdField?.valueType === toValueTypeName(type)
    );
    foundFacet && uniqueFacets.push(foundFacet);
  });

  /* FacetItem that matches the metaDataKey */
  const initialFacetItem =
    uniqueFacets?.find((facetItem) => facetItem.mdField?.id === metaDataKey) ||
    undefined;

  /* All metaDataKeys included in parsed params */
  const metaDataKeys = currentFilters
    ?.filter(
      (filter) => isMetaDataType(filter.type) && Boolean(filter.metaDataKey)
    )
    .map((filter) => filter.metaDataKey);

  /* All faceted metadata keys included in params */
  const paramFieldIds = metaDataKeys
    ?.map((key) => {
      if (!key) return null;
      return key;
    })
    .filter(isDefined);

  const availableFacetItems = uniqueFacets.filter(
    (facet) => !paramFieldIds?.includes(facet.mdField?.id || '')
  );

  const reset = () => {
    setSelectedFacet(undefined);
  };

  useEffect(() => {
    if (
      !initialFacetItem ||
      !initialFacetItem.name ||
      !initialFacetItem.mdField
    )
      reset();
    else setSelectedFacet(initialFacetItem);
  }, [initialFacetItem, metaDataKey]);

  const selectFacet = (facetItem: FacetItem) => {
    if (!facetItem.mdField) return;

    const { id, valueType } = facetItem.mdField;
    const newKey = id;

    const fieldDefinition = {
      newKey,
      oldKey: metaDataKey,
      type: valueType,
    };

    setFieldDefinition(fieldDefinition);
    setSelectedFacet(facetItem);

    setInputValue('');
  };

  const filteredAnswers = facetGroup?.items
    ?.filter((item) => item?.mdField?.id === selectedFacet?.mdField?.id)
    .filter(isDefined);

  const filteredFacetItems = availableFacetItems.filter((facetItem) =>
    facetItem.name.toLowerCase().match(inputValue.toLowerCase())
  );

  return {
    availableFacetItems,
    filteredAnswers,
    filteredFacetItems,
    inputValue,
    onInputChange,
    selectFacet,
    selectedFacet,
    setSelectedFacet,
  };
};
