import { FolderPermission } from '@pro4all/graphql';

import { hasConflictWithApprovePermission } from './hasConflictWithApprovePermission';
import { hasConflictWithCreateContentPermission } from './hasConflictWithCreateContentPermission';
import { hasConflictWithFinalizePermission } from './hasConflictWithFinalizePermission';

export function shouldAlterPermission(
  selectedPermission: FolderPermission[],
  allPermissions: FolderPermission[]
): { isApproved?: boolean; message: string; shouldAlterPermission: boolean } {
  if (
    selectedPermission.length === 0 &&
    hasConflictWithCreateContentPermission({
      currentPermissions: allPermissions,
      newPermissions: selectedPermission,
    })
  ) {
    return {
      message:
        "This permission cannot be modified with the 'Create documents/versions' permission active.",
      shouldAlterPermission: false,
    };
  }

  if (
    hasConflictWithApprovePermission({
      currentPermissions: allPermissions,
      newPermissions: selectedPermission,
    })
  ) {
    return {
      isApproved: true,
      message:
        "This permission cannot be modified with the '{{name}} documents' permission active.",
      shouldAlterPermission: false,
    };
  }

  if (
    hasConflictWithFinalizePermission({
      currentPermissions: allPermissions,
      newPermissions: selectedPermission,
    })
  ) {
    return {
      message:
        "This permission cannot be modified with the '{{name}} documents' permission active.",
      shouldAlterPermission: false,
    };
  }

  return { message: null, shouldAlterPermission: true };
}
