import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@pro4all/shared/ui/typography';

export const LinkedDescendant = () => {
  const { t } = useTranslation();

  /* @Todo: add a go-to-linked-object-button once we get a reference from BE */
  return (
    <Text>{t('Another object nested inside this one is already linked.')}</Text>
  );
};
