import { useEffect } from 'react';

import {
  FieldDefinition,
  QualityControlInstance,
  useQualityControlInstanceQuery,
} from '@pro4all/graphql';
import { useStopPollingOnResponse } from '@pro4all/shared/hooks';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetResult = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const { searchParams } = useRouting();
  const resultId = searchParams.get('id') ?? '';

  const {
    data: { qualityControlInstance: result } = {},
    refetch,
    stopPolling,
  } = useQualityControlInstanceQuery({
    fetchPolicy: 'no-cache',
    // Changing from cache and network to no cache
    // This changes were added due to some issue with cache on regards to editing QC Results when they had sections within sections
    pollInterval: 500,
    skip: !isSidebarOpen || !resultId,
    variables: { id: resultId },
  });

  const { setItems, setTemplateId } = useSavedAnswersContext();

  useEffect(() => {
    if (result) {
      if (result?.page) searchParams.set('page', result.page.toString());
      setItems(result.items as FieldDefinition[]);
      setTemplateId(result.templateId || '');
    }
  }, [result, searchParams, setItems, setTemplateId]);

  useStopPollingOnResponse<QualityControlInstance>(
    stopPolling,
    result as QualityControlInstance
  );

  return { refetch, result: result as QualityControlInstance };
};
