export const statusColors = [
  '#533BE2',
  '#0693e3',
  '#36CCBA',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#9b51e0',
  '#cf2e2e',
  '#E25314',
  '#f78da7',
  '#ff6900',
  '#fcb900',
  '#828A92',
  '#abb8c3',
  '#F2F7F9',
  '#ffffff',
  '#000000',
];

export const colorPicker = [
  {
    color: '#7E78F9',
    highlightBackground: '#ECEEFF',
    highlightColor: '#28237A',
  },
  {
    color: '#28237A',
    highlightBackground: '#28237A',
    highlightColor: '#ECEEFF',
  },
  {
    color: '#00B5DD',
    highlightBackground: '#CCF0F8',
    highlightColor: '#006379',
  },
  {
    color: '#006379',
    highlightBackground: '#006379',
    highlightColor: '#CCF0F8',
  },
  {
    color: '#F5B05B',
    highlightBackground: '#FCEFD6',
    highlightColor: '#8F4F00',
  },
  {
    color: '#8F4F00',
    highlightBackground: '#8F4F00',
    highlightColor: '#FCEFD6',
  },
  {
    color: '#36CCBA',
    highlightBackground: '#CDF3ED',
    highlightColor: '#006B5E',
  },
  {
    color: '#006B5E',
    highlightBackground: '#006B5E',
    highlightColor: '#CDF3ED',
  },
  {
    color: '#CF6679',
    highlightBackground: '#F5E0E4',
    highlightColor: '#894A56',
  },
  {
    color: '#894A56',
    highlightBackground: '#894A56',
    highlightColor: '#F5E0E4',
  },
  {
    color: '#AFAFAF',
    highlightBackground: '#DDDDDD',
    highlightColor: '#212121',
  },
  {
    color: '#212121',
    highlightBackground: '#212121',
    highlightColor: '#EEEEEE',
  },
];
