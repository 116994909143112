import { IntegrationType, useConnectionsQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { sortBy } from '@pro4all/shared/utils';

export const useGetConnectionOptions = () => {
  const { projectId } = useRouting().params;

  const { data } = useConnectionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filterOnActiveConnections: false,
      filterOnIntegrationType: IntegrationType.IntegrationSnagstream,
      projectId: projectId || '',
    },
  });

  const connectionOptions = data
    ? data?.connections
        .filter((connection) => connection.integrationConnection)
        .map((connection) => ({
          id: connection.integrationConnection?.id,
          label: connection.integrationConnection?.connectionName,
        }))
    : [];

  return {
    connectionOptions: [...connectionOptions.sort(sortBy({ key: 'label' }))],
  };
};
