import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const FilterQCEmptyState = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Text color={customColors.black} fontWeight={600} variant="body2">
        {t('This field has no values yet')}
      </Text>
    </Box>
  );
};
