import { useEffect, useState } from 'react';

import { getToken } from '@pro4all/authentication/src/utils';

export const CommunicationBaseURL = 'https://communication.pro4all.io/api/';

interface WebSocketHook {
  lastUpdate: Date | null;
}

export const useWebSocket = (subscribeId: string): WebSocketHook => {
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
  const token = getToken();

  useEffect(() => {
    // Keep default communicationBaseUrl with https. So only 1 config is needed in k8s / pipelines.
    const baseUrl = CommunicationBaseURL.replace('https://', 'wss://');
    const socket = new WebSocket(
      baseUrl +
        'liveupdates/subscribe/?subscribeId=' +
        subscribeId +
        '&token=' +
        token
    );

    socket.onmessage = () => {
      setLastUpdate(new Date());
    };

    return () => {
      socket.close();
    };
  }, [subscribeId, token]);

  return { lastUpdate };
};
