import { LinkedSnagsListProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

const LinkedSnagsList = ({ linkedSnags }: LinkedSnagsListProps) =>
  linkedSnags.length > 0 ? (
    <table>
      <tbody>
        <Instance.LinkedItemsList>
          <Instance.LinkedItemsTitle>Linked snags:</Instance.LinkedItemsTitle>
          {linkedSnags.map((linkedSnag, index) => (
            <tr key={index}>
              <Instance.LinkedItemsListProperty>
                {linkedSnag.reference}
              </Instance.LinkedItemsListProperty>
              <Instance.LinkedItemsListProperty>
                {linkedSnag.name}
              </Instance.LinkedItemsListProperty>
              <Instance.LinkedItemsListProperty>
                {linkedSnag.visualContext?.name}
              </Instance.LinkedItemsListProperty>
              <Instance.LinkedItemsListProperty>
                {linkedSnag.page}
              </Instance.LinkedItemsListProperty>
            </tr>
          ))}
        </Instance.LinkedItemsList>
      </tbody>
    </table>
  ) : null;

export default LinkedSnagsList;
