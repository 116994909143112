import { jwtDecode, JwtPayload } from 'jwt-decode';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';

type TokenData = JwtPayload & { authenticationType: string };

export const getTokenData = () => {
  const token = AuthService.getToken();
  return jwtDecode(token) as TokenData;
};
