import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';

export const HierarchicalListForm = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ItemListWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  height: 1px;
`;

export const TitleBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleInput = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  background-color: ${customColors.white};
  width: 400px;
`;

export const ActionZone = styled.div`
  margin-top: 1px;
`;

export const ActionButton = styled(Button)`
  && {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;
