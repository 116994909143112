import { debounce } from 'lodash';
import * as Yup from 'yup';

import { ProjectSettings, ValueTypeName } from '@pro4all/graphql';
import { RadioOption } from '@pro4all/shared/ui/general';

import { useStandardValidations } from './useStandardValidations';
declare module 'yup' {
  interface ObjectSchema {
    debounce(delay: number): this;
  }
}

Yup.addMethod(Yup.object, 'debounce', function (this: Yup.ObjectSchema, delay) {
  return this.test({
    exclusive: true,
    message: '',
    name: 'debounce',
    test: function (): Promise<boolean | Yup.ValidationError> {
      return new Promise((resolve) => {
        debounce(() => resolve(true), delay)();
      }) as Promise<boolean | Yup.ValidationError>;
    },
  });
});

type ValidationSchema = Record<string, Yup.Schema<unknown, unknown>>;

export interface FormFieldConfig {
  displayName?: string;
  email?: boolean;
  label?: string;
  matches?: RegExp;
  max?: number;
  min?: number;
  name: string;
  notOneOf?: string[];
  options?: RadioOption[];
  phoneNumber?: boolean;
  required?: boolean;
  settings?: ProjectSettings;
  type: ValueTypeName;
  valueType?: FormFieldConfigValueType;
}

export type FormFieldConfigValueType = {
  id?: string;
  maxValue?: number;
  minValue?: number;
  multiSelect?: boolean;
  name?: ValueTypeName;
  rangeEnabled?: boolean;
  subFields?: FormFieldConfig[];
  subType?: string;
};

export type SchemaExtender<
  S extends Yup.Schema<unknown, unknown> = Yup.Schema<unknown, unknown>
> = (schema: S) => S;

export type CustomValidationObject = Record<string, SchemaExtender>;

export const emptySchemaExtender: SchemaExtender = (item) => item;

export const useValidation = ({
  customValidation = {},
  formFields,
}: {
  customValidation?: CustomValidationObject;
  formFields: FormFieldConfig[];
}) => {
  const { checkDate, checkNumber, checkSelect, checkMultiSelect, checkString } =
    useStandardValidations();

  const getValidation = (
    acc: ValidationSchema,
    item: FormFieldConfig
  ): ValidationSchema => {
    if (item.type === ValueTypeName.Section) {
      return item?.valueType?.subFields?.reduce(getValidation, acc) || {};
    } else {
      let schema;
      switch (item.type) {
        case ValueTypeName.DateTime:
          schema = checkDate(item);
          break;
        case ValueTypeName.Number:
          schema = checkNumber(item);
          break;
        case ValueTypeName.Selection:
          if (item.valueType?.multiSelect) {
            schema = checkMultiSelect(item);
          } else {
            schema = checkSelect(item);
          }
          break;
        case ValueTypeName.Status:
          schema = checkSelect(item);
          break;
        default:
          schema = checkString(item);
      }

      const schemaExtender = customValidation[item.name] || emptySchemaExtender;
      acc[item.name] = schemaExtender(schema);

      return acc;
    }
  };

  const schema = formFields.reduce(getValidation, {});

  return Yup.object(schema).debounce(100);
};
