export const stringifyArrayValue = (value: any) => {
  if (Array.isArray(value)) {
    const valuesArray = value.map((val) => val.id);
    return JSON.stringify(valuesArray);
  } else if (typeof value === 'object') {
    return value?.id || '';
  } else {
    return value;
  }
};
