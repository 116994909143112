import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const ContentWrap = styled.div<{ $maxRows?: number }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  background-color: ${customColors.white};
  max-height: ${({ $maxRows }) => ($maxRows ? `${$maxRows * 30}px` : '300px')};
  overflow-y: auto;
`;
