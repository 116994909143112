import { flatten } from '@pro4all/metadata/ui/utils';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { TranslateInstancesProps } from '@pro4all/shared/types';

export const useTranslateInstances = ({
  instances,
}: TranslateInstancesProps) => {
  const { translateTbq } = useTranslateTbq();

  const allItems = flatten(
    instances.flatMap((i) => i.items),
    (i) => i?.valueType?.subFields ?? []
  );

  const translatedItems = allItems?.map((it) => {
    if (it) {
      return { ...it, value: translateTbq(it?.value) };
    }
    return it;
  });

  const translatedInstances = instances?.map((i) => ({
    ...i,
    answers: i.answers?.map((a) => ({
      ...a,
      value: translateTbq(a.value),
    })),
    items: i.items?.map((item) => {
      const translatedItem = translatedItems.find((ti) => ti.id === item.id);
      return translatedItem ? translatedItem : item;
    }),
  }));

  return { instances: translatedInstances };
};
