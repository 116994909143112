import React from 'react';
import { useTranslation } from 'react-i18next';

import { Template } from '@pro4all/graphql';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button, ButtonGroupHorizontal } from '@pro4all/shared/ui/buttons';
import { Main } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

export const RedirectToOrganizationTemplates = ({
  selectedTemplate,
}: {
  selectedTemplate?: Template;
}) => {
  const { t } = useTranslation();
  const { templateType, templateService } = useMetaDataContext();
  const { id, name } = selectedTemplate;
  const { goTo, searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();

  const { entity, route } =
    featureConfig[templateService].templateTypes[templateType];

  return (
    <Main p={3}>
      <Box>
        <Box mb={1}>
          <Text variant="h2">{name}</Text>
        </Box>
        <Box mb={3}>{`${t('This {{entity}} is linked from an organization', {
          entity: t(entity),
        })}. ${t(
          'This can only be edited from within the organization and not from within the project'
        )}.`}</Box>
        <ButtonGroupHorizontal>
          <Button
            disabled={!hasAppPermission('OrganizationUpdate')}
            onClick={() =>
              goTo(route, {
                searchParams: { action: 'editSet', id },
              })
            }
          >
            {t('To organization {{entity}}', {
              entity: t(entity),
            })}
          </Button>
          <Button color="inherit" onClick={() => searchParams.clear()}>
            {t('Cancel')}
          </Button>
        </ButtonGroupHorizontal>
      </Box>
    </Main>
  );
};
