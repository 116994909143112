import { useTranslation } from 'react-i18next';

import { Task, User } from '@pro4all/graphql';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  MiddleEllipsis,
  Timestamp,
} from '@pro4all/shared/ui/general';
import {
  FilterHeader,
  NO_VALUE,
} from '@pro4all/shared/ui/table-column-filtering';

export const useTBQResolveTaskColumns = () => {
  const { t } = useTranslation();

  const columns: ColumnProps<Task>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label={t('Title')}
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      minWidth: 100,
      width: 200,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<Task, User>
          customCallbackId="assignment.displayName"
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(assignment: User[]) =>
            assignment?.[0]?.displayName ?? NO_VALUE
          }
          label={t('Responsible')}
          minWidth={120}
          propertyId="assignment"
          translateOptions
        />
      ),
      key: 'assignment.displayName',
      minWidth: 120,
      render: ({ assignment }) =>
        assignment?.[0]?.displayName ? (
          <MiddleEllipsis endLength={9} text={assignment[0].displayName} />
        ) : (
          ''
        ),
      width: 200,
    },
    {
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label={t('Deadline')}
          minWidth={120}
          propertyId="endTime"
        />
      ),
      key: 'endTime',
      render: ({ endTime }) => <Timestamp date={endTime} />,
      width: 200,
    },
    {
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Select}
          label={t('Status')}
          minWidth={120}
          propertyId="status"
          translateOptions
        />
      ),
      key: 'status',
      render: ({ status }) => <StatusChip status={status} />,
      width: 120,
    },
    {
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label={t('Completed at')}
          minWidth={120}
          propertyId="completedAt"
        />
      ),
      key: 'completedAt',
      render: ({ completedAt }) => <Timestamp date={completedAt} />,
      width: 120,
    },
  ];
  return columns;
};
