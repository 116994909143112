import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

import { UploaderEditorTypes } from '../types';
import {
  ContentCellWrapper,
  ContentColumnWrapper,
  HeaderWrapper,
  ResizableColumnWrapper,
} from '../wrappers';

export const VersionNumberColumn = ({
  meta,
}: Pick<UploaderEditorTypes, 'meta'>) => (
  <ResizableColumnWrapper defaultWidth={40} zIndexResizableColumnDown={1}>
    <HeaderWrapper />
    <ContentColumnWrapper>
      {meta.map(({ id, version }) => (
        <VersionNumberCell
          key={id}
          versionNumber={version?.versionNumber || 0}
        />
      ))}
    </ContentColumnWrapper>
  </ResizableColumnWrapper>
);

type VersionNumberCellProps = {
  versionNumber: number;
};

const VersionNumberCellComponent = ({
  versionNumber,
}: VersionNumberCellProps) => (
  <ContentCellWrapper>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {versionNumber}
    </Box>
  </ContentCellWrapper>
);

const skipUpdate = (
  prevProps: VersionNumberCellProps,
  nextProps: VersionNumberCellProps
) => prevProps.versionNumber === nextProps.versionNumber;

const VersionNumberCell = React.memo(VersionNumberCellComponent, skipUpdate);
