export const titleCaseToSentenceCase = (titleCase: string) => {
  const exceptions = {
    ForQrStamp: 'For QR stamp',
  };
  if (!titleCase) return '';
  else if (exceptions[titleCase as keyof typeof exceptions])
    return exceptions[titleCase as keyof typeof exceptions];
  const result = titleCase.replace(/([A-Z])/g, ' $1');
  if (result.length) {
    return result[1].toUpperCase() + result.substring(2).toLowerCase();
  }
};

export const camelCaseToSentenceCase = (camelCase: string): string => {
  if (!camelCase) return '';
  const sentenceCase = camelCase
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase();
  return sentenceCase.charAt(0).toUpperCase() + sentenceCase.slice(1);
};
