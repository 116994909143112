import { useFormikContext } from 'formik';

import { InstanceValues } from '@pro4all/shared/types';

import { FieldsProps } from './SectionsAndFieldsList.types';

type Props = Pick<FieldsProps, 'fields'>;

export const useGetValues = ({ fields }: Props) => {
  const formikContext = useFormikContext<InstanceValues>();
  const values: InstanceValues = formikContext?.values;

  const conditionFieldsIds = fields
    ?.map((field) => field.conditions)
    .filter((x) => x?.length > 0)
    .reduce((a, b) => a.concat(b), [])
    ?.map((x) => x.fieldId);
  const conditionFieldsValues = conditionFieldsIds?.map((id) => values?.[id]);

  return { conditionFieldsValues, values };
};
