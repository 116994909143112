import React from 'react';

import {
  FieldDefinition,
  Instance,
  TbqModuleType,
  ValueTypeName,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import * as View from './view';

export type AnswerComponent = React.FC<{
  children: string;
  item?: FieldDefinition;
  tagHasMargin?: boolean;
}>;

export interface AnswerViewProps {
  item: FieldDefinition;
  tagHasMargin?: boolean;
  value: Instance['value'];
}

export const AnswerView: React.FC<AnswerViewProps> = ({
  tagHasMargin = true,
  item,
  value,
}) => {
  const { searchParams } = useRouting();
  const isTbqRie =
    searchParams.get('tbqScanType')?.toUpperCase() === TbqModuleType.Rie;

  const Component =
    isTbqRie && item.type === ValueTypeName.Text
      ? View.TBQRieTextArea
      : View[item.type as keyof typeof View] ?? View.TextField;

  return (
    <Component
      {...([ValueTypeName.Selection].includes(item.type)
        ? { tagHasMargin: tagHasMargin }
        : {})}
      item={item}
    >
      {value}
    </Component>
  );
};
