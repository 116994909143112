import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const Container = styled(Box)`
  && {
    @media print {
      display: none;
    }
  }
`;

export const Tabs = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const isMobileScreen = useIsMobileScreen();
  const { projectId } = params;

  const hasTaskTab = Boolean(projectId);

  const base = projectId
    ? `${generateRoute('projectDmsBase', {
        params: { projectId },
      })}/`
    : `${Routes.baseDms}/`;

  return (
    <Container>
      <SubTabs base={base}>
        <Tab
          data-testid="dms-docs-tab"
          label={t('Folders')}
          onClick={() => {
            const goToRoute = projectId ? 'projectDocs' : 'baseDocs';
            goTo(
              goToRoute,
              projectId
                ? {
                    params: { projectId },
                  }
                : undefined
            );

            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: goToRoute,
            });
          }}
          value="docs"
        />
        {hasTaskTab && (
          <Tab
            data-testid="dms-tasks-tab"
            label={t('Tasks')}
            onClick={() => {
              goTo('projectDocsTasks', {
                params: { projectId },
              });
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectDocsTasks',
              });
            }}
            value="document-tasks"
          />
        )}
        {!isMobileScreen && (
          <Tab
            data-testid="dms-tasks-tab"
            label={t('Notifications')}
            onClick={() => {
              const goToRoute = projectId
                ? 'projectDocsNotifications'
                : 'organizationNotifications';

              goTo(
                goToRoute,
                projectId
                  ? {
                      params: { projectId },
                    }
                  : undefined
              );
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: goToRoute,
              });
            }}
            value="notifications"
          />
        )}
      </SubTabs>
    </Container>
  );
};
