import React from 'react';
import styled from 'styled-components';

import {
  Box,
  Radio as MuiRadio,
  RadioProps,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

interface Props extends RadioProps {
  label?: React.ReactNode | string;
}

export const Radio: React.FC<Props> = ({ label, ...props }) => (
  <StyledOptionWrapper>
    <StyledRadio {...props} size="small" />
    {/* @TODO: Future css rework needed */}
    {/* Checkingif we recieve a string or react node */}
    {label && typeof label === 'string' ? (
      <StyledLabel htmlFor={props.id}>
        <Text>{label}</Text>
      </StyledLabel>
    ) : (
      <StyledLabel htmlFor={props.id}>{label}</StyledLabel>
    )}
  </StyledOptionWrapper>
);

const StyledOptionWrapper = styled(Box)`
  && {
    display: flex;
    align-items: center;
  }
`;

const StyledRadio = styled(MuiRadio)`
  && {
    color: ${customColors.grey500};
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
`;
