import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useReportConfigFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Name', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Set as default'), // i18n
      name: 'setAsDefault',
      required: false,
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Make reusable for other projects'), // i18n
      name: 'storeOnOrganizationLevel',
      required: false,
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
