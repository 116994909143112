import React, { useContext } from 'react';
import styled from 'styled-components';

import { Box, BoxProps } from '@pro4all/shared/mui-wrappers';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

import { SnackbarContext } from '../snackbar/SnackbarContext';

export type Severity = 'success' | 'info' | 'warning' | 'error';
export interface FeedbackProps extends BoxProps {
  action?: React.ReactNode;
  dropShadow?: boolean;
  fullWidth?: boolean;
  hideIcon?: boolean;
  severity?: Severity;
  variant?: 'plain' | 'filled';
}

interface ContainerProps
  extends Omit<FeedbackProps, 'fullWidth' | 'dropShadow'> {
  $dropShadow?: boolean;
  $fullWidth?: boolean;
}

const StyledContainer = styled(Box)<ContainerProps>`
  && {
    display: flex;
    width: 100%;
    min-width: 250px;
    max-width: ${({ $fullWidth }) => !$fullWidth && '640px'};
    padding: ${({ theme }) => theme.spacing(1)}
      ${({ theme }) => theme.spacing(2)};
    border-radius: 8px;

    .icon,
    .action,
    .message {
      display: flex;
    }

    .icon {
      flex: 0 1 auto;
    }
    .message {
      flex: 1 1 auto;
      align-items: center;
      line-height: ${({ theme }) => theme.typography.body1.fontSize};
    }

    .action {
      flex: 0 0 auto;
      color: ${({ theme }) => `${theme.palette.primary.dark}`};
      cursor: pointer;
      align-items: center;
      line-height: ${({ theme }) => theme.typography.body1.fontSize};
    }

    &.plain {
      box-shadow: ${({ $dropShadow }) =>
        $dropShadow ? StylingDefaults.boxShadow : 'none'};
      &.error {
        color: ${({ theme }) => `${theme.palette.error.main}`};
        background-color: ${({ theme }) => `${theme.palette.error.light}`};
      }
      &.info {
        color: ${customColors.grey900};
        background-color: ${({ theme }) => `${theme.palette.primary.light}`};

        .icon {
          color: ${customColors.grey600};
        }
      }
      &.warning {
        color: ${({ theme }) => `${theme.palette.warning.contrastText}`};
        background-color: ${({ theme }) => `${theme.palette.warning.light}`};

        .icon {
          color: ${({ theme }) => `${theme.palette.warning.main}`};
        }
      }
      &.success {
        color: ${({ theme }) => `${theme.palette.success.contrastText}`};
        background-color: ${({ theme }) => `${theme.palette.success.light}`};

        .icon {
          color: ${({ theme }) => `${theme.palette.success.main}`};
        }
      }
    }

    &.filled {
      box-shadow: ${StylingDefaults.boxShadow};
      &.error {
        color: ${({ theme }) => `${theme.palette.error.contrastText}`};
        background-color: ${({ theme }) => `${theme.palette.error.main}`};
      }
      &.info {
        color: ${customColors.grey900};
        background-color: ${({ theme }) => `${theme.palette.primary.light}`};
      }
      &.warning {
        color: ${({ theme }) => `${theme.palette.warning.contrastText}`};
        background-color: ${({ theme }) => `${theme.palette.warning.main}`};
        .action {
          color: ${({ theme }) => `${theme.palette.primary.main}`};
        }
      }
      &.success {
        color: ${({ theme }) => `${theme.palette.success.contrastText}`};
        background-color: ${({ theme }) => `${theme.palette.success.main}`};
      }
    }
  }
`;

export const Feedback: React.FC<FeedbackProps> = ({
  dropShadow = true,
  severity = 'info',
  variant = 'filled',
  fullWidth = false,
  hideIcon = false,
  action = null,
  children,
  ...boxProps
}) => {
  const iconMap: {
    [key in FeedbackProps['severity']]: IconName;
  } = {
    error: 'errorOutlined',
    info: 'info',
    success: 'checkCircleOutlined',
    warning: 'reportProblemOutlined',
  };

  const onDismiss = useContext(SnackbarContext);

  return (
    <Box {...boxProps}>
      <StyledContainer
        $dropShadow={dropShadow}
        $fullWidth={fullWidth}
        className={`${severity} ${variant}`}
        severity={severity}
        variant={variant}
      >
        {!hideIcon && (
          <Box className="icon" pr={1}>
            <Icon iconName={iconMap[severity]} />
          </Box>
        )}
        <Box className="message" pr={2}>
          {children}
        </Box>
        {action && (
          <Box className="action" pr={2}>
            {action}
          </Box>
        )}
        {onDismiss && (
          <Box className="action">
            <Icon iconName="close" onClick={onDismiss} />
          </Box>
        )}
      </StyledContainer>
    </Box>
  );
};
