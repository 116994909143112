import { useTranslation } from 'react-i18next';

import { TaskType } from '@pro4all/graphql';
import { Action } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useCreateTask = () => {
  const { track } = useAnalytics();

  const { t } = useTranslation();
  const { hasAppPermission } = useAppPermissions();
  const { params, searchParams } = useRouting();

  const createTaskButtonProps: ActionProps = {
    ariaLabel: t('New quality task'),
    dataTestId: 'new-task',
    disabled: !hasAppPermission('ProjectRead'),
    key: 'new-task',
    label: t('New quality task'),
    onClick: () => {
      track(Action.CreateQualityTaskButtonClicked, {
        projectId: params.projectId,
      });

      searchParams.set({
        action: 'createTask',
        taskType: TaskType.QualityControl,
      });
    },
    startIcon: 'qcTask',
  };

  return { createTaskButtonProps };
};
