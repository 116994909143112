import { changeItem } from '../helpers/changeItem';
import { getChildIds } from '../helpers/getChildIds';
import { BasePropsHierarchyItem, ItemProps } from '../types';

export const deleteItemAction = <HierarchyItem extends BasePropsHierarchyItem>({
  id,
  allItems,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'id'>) => {
  let updatedItems = allItems;

  const itemToDelete = allItems.find((item) => item.id === id);

  if (itemToDelete?.previousNodeId) {
    // Delete second or further item on this level.

    // Find item that has previousNodeId filled with 'id' and set previousNodeId to the previousNodeId of the item to be deleted.
    const itemNext = allItems.find((item) => item.previousNodeId === id);
    if (itemNext) {
      itemNext.previousNodeId = itemToDelete.previousNodeId;
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: itemNext,
      });
    }
  } else {
    // Delete first item on this level.

    // Find item that has previousNodeId filled with 'id' and set previousNodeId for that one on null.
    const itemNext = allItems.find((item) => item.previousNodeId === id);
    if (itemNext) {
      itemNext.previousNodeId = null;
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: itemNext,
      });
    }
  }

  // Get all child ids of the item to be deleted.
  const childIds = getChildIds<HierarchyItem>({ allItems, id });

  // Delete all (deeper nested) children of the item.
  updatedItems = updatedItems.filter(
    (item) => ![id, ...childIds].includes(item.id)
  );

  return updatedItems;
};
