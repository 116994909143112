import React from 'react';

import type { Field } from '../utils';

import { TBQFieldOptions } from './TBQFieldOptions';
import { TBQSection } from './TBQSection';

type Props = Field & { depth?: number };

export const TBQField = ({ depth = 0, ...field }: Props) => {
  if ('fields' in field) {
    if (!field.fields.length) return null;
    return <TBQSection {...field} depth={depth} />;
  }

  return <TBQFieldOptions {...field} />;
};
