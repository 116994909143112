import { useUserExistsQuery } from '@pro4all/graphql';

export const useValidateUniqueEmail = () => {
  const { refetch: userExists } = useUserExistsQuery({
    skip: true,
  });

  return async (email?: string) => {
    if (!email) return true;
    const { data } = await userExists({ email });
    return !data.userExists;
  };
};
