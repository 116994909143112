import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { DocumentService } from '@pro4all/documents/data-access';
import { Document, FinalizationState, LockType } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import { Position } from '@pro4all/shared/types';
import { useDocumentState, useLock } from '@pro4all/shared/ui/actions';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/general';

export const useEditFile = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();
  const lock = useLock();
  const getDocumentState = useDocumentState();
  const { editItems, setItem } = useOptimisticResponseContext<Document>();

  const { uncheckAllRows } = useTableCheck<Document>();

  const lockAndDownloadFile = async (document: Document) => {
    // First of all check if another user locked the document while this user has the same folder open.
    const state = await getDocumentState({ document });
    if (
      document.lockType === LockType.Prostream ||
      state === FinalizationState.Finalized
    ) {
      enqueueSnackbar(
        t('Document has been locked or finalized. You cannot lock it now.')
      );
    } else {
      // Start the download.
      const downloadResponse = await DocumentService.downloadDocument({
        document,
        locked: true,
      });

      if (downloadResponse !== 200) {
        enqueueSnackbar(t('Could not download document'));
      } else {
        const response = await lock({ document, showError: true });

        // If locking was successful.
        if (response) {
          // Optimistic response.
          const updatedItem = {
            ...document,
            lockType: LockType.Prostream,
            lockedAt: new Date().toISOString(),
            lockedBy: {
              displayName: userName,
              id: userId,
            },
          };
          setItem(updatedItem);
          editItems([updatedItem]);

          const position = window.sessionStorage.getItem(
            StorageKeys.ACTION_TRIGGERED_FROM
          );

          // Deselect all rows if applicable.
          if (position !== Position.Contextmenu) uncheckAllRows();
        }
      }
    }
  };

  return lockAndDownloadFile;
};
