import { css } from 'styled-components';

export const MuiAutompleteStyles = css`
  /**
   * Since the Autocomplete popper is a top level presentation layer separate
   * from the autocomplete styled component, we style the dropdown
   * here globally.
   * Also it is not supported by the default theme, since its a labs feature
   */
  .MuiAutocomplete-paper .MuiAutocomplete-listbox {
    padding: 0;
    .MuiAutocomplete-option {
      padding: 6px 8px;
    }
    .MuiAutocomplete-option.Mui-selected {
      background-color: ${({ theme }) => theme.palette.grey[200]};
    }
    .MuiAutocomplete-option:hover {
      color: ${({ theme }) => theme.palette.grey[900]};
      background-color: ${({ theme }) => theme.palette.grey[300]};
    }
    li {
      overflow-x: hidden;
    }
  }
`;
