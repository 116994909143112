import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import * as Styled from './styles/OrganizationSubscription.styles';
import { Dashboard } from './Dashboard';
import { FeedbackSubscriptionExpires } from './FeedbackSubscriptionExpires';
import { FeedbackTrialPeriod } from './FeedbackTrialPeriod';
import { InvoiceData } from './InvoiceData';
import { MySubscription } from './MySubscription';

export const OrganizationSubscription = () => (
  <Box display="flex" flexDirection="column" overflow="scroll">
    <ResponseWrapper>
      <Box mb={5} ml={3} mr={3}>
        <FeedbackSubscriptionExpires />
        <FeedbackTrialPeriod />
        <Dashboard />
      </Box>
      <Styled.BoxWrapper ml={3} mr={3}>
        <Styled.BoxMySubscription>
          <MySubscription />
        </Styled.BoxMySubscription>
        <Styled.BoxMySubscription>
          <InvoiceData />
        </Styled.BoxMySubscription>
      </Styled.BoxWrapper>
    </ResponseWrapper>
  </Box>
);
