import React from 'react';

import { ErrorMessage } from '@pro4all/shared/assets-sharing';
import { Box } from '@pro4all/shared/mui-wrappers';

import { useSharedDocsContext } from './SharedDocsContext';

export const LinkError: React.FC = () => {
  const { error } = useSharedDocsContext();
  return error ? (
    <Box display="flex" flex={1} pb={3} pt={3}>
      <ErrorMessage error={error} />
    </Box>
  ) : null;
};
