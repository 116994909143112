import { useTranslation } from 'react-i18next';

import { QcPermissionCategory, TaskType } from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';
import { TaskForm } from '@pro4all/workflow/ui/task-sidebar';

export const ResolveTaskForm = ({ onClose }: { onClose: () => void }) => {
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');
  const { t } = useTranslation();
  const {
    params: { projectId },
  } = useRouting();

  const { resolveCreateAll, resolveCreateOwn } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
    taskProjectId: projectId,
  });

  return resolveCreateAll || resolveCreateOwn ? (
    <>
      <Sidebar.Header
        icon={'taskSucceeded' as IconName}
        title={t('New resolve task')}
      ></Sidebar.Header>
      <TaskForm
        onClose={onClose}
        projectId={projectId || ''}
        taskType={TaskType.Resolve}
      />
    </>
  ) : (
    <BigMessageNoAccess />
  );
};
