import { NavigateAwayPrompt } from '@pro4all/shared/ui/general';

import { useDiscardModalContext } from '../provider/DiscardModalProvider';

export const DiscardModal = () => {
  const { bypass, isDirty, isValid, onSubmit, setIsDirty } =
    useDiscardModalContext();

  if (bypass || !isDirty) {
    return null; // Do not render `DiscardModal` if there are no unsaved changes or for route changes for which a discard modal is not desired.
  }

  const messageEdit =
    'You have unsaved changes. When leaving you will lose these changes.'; // i18n
  const titleEdit = 'Unsaved changes'; // i18n
  const bypassRouterPrompt = !isDirty;

  return (
    <NavigateAwayPrompt
      bypassRouterPrompt={bypassRouterPrompt}
      message={messageEdit}
      onConfirm={() => setIsDirty(false)}
      onSubmit={onSubmit}
      submitDisabled={!isValid}
      title={titleEdit}
    />
  );
};
