import { Folder } from '@pro4all/graphql';

export const getNestedFolders = ({
  childFolderId,
  folders,
}: {
  childFolderId: string;
  folders: Folder[];
}) => {
  let returnValue: Folder[] = [];

  const getIds = (id: string) => {
    const childFolders = folders.filter(
      (folder) => folder.parentFolderId === id
    );
    if (childFolders.length) {
      returnValue = [...returnValue, ...childFolders];
      childFolders.forEach((folder) => getIds(folder.id));
    }
  };

  getIds(childFolderId);

  return returnValue;
};
