import { Option } from '@pro4all/shared/ui/general';

export const languageOptions: Option[] = [
  {
    iconName: 'flagHolland',
    id: 'nl-NL',
    label: 'Nederlands',
  },
  {
    iconName: 'flagGreatBritain',
    id: 'en-US',
    label: 'English',
  },
  {
    iconName: 'flagNorway',
    id: 'no',
    label: 'Norwegian',
  },
  {
    iconName: 'flagDenmark',
    id: 'da',
    label: 'Danish',
  },
  {
    iconName: 'flagGermany',
    id: 'de',
    label: 'Deutsch',
  },
  {
    id: 'none',
    label: '',
  },
];
