import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export const LinksContainer = styled.div`
  margin: 1rem 0;
`;

export const LabelRow = styled(Box)`
  && {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;
