import { useEffect, useRef, useState } from 'react';

import { Key, Scope, useToggleContext } from './useToggleContext';

export interface UseToggleOptions<K extends Key = Key> {
  // The initial state of the toggle. `true` = on, `false` = off.
  initial?: boolean;
  // Like `React.Key`, you can set a key to identify specific toggles (using
  // uuids for instance). By default `key` is set to a Symbol() to guarantee
  // uniqueness.
  key?: K;
  // If you use multiple `ToggleProvider` components you can assign a `scope`
  // to each of them. Using `scope` on `useToggle` makes sure that toggles are
  // assigned to the proper scope.
  scope?: Scope;
}

/**
 * A simple toggle hook with some advanced options.
 *
 * A stand-alone `useToggle` will be the same as the following: const [open,
 * setOpen] = useState(false);
 *
 * A `useToggle` wrapped in a `ToggleProvider` will take into account the
 * provider's configuration for `cycle` and `limit`. Nested providers are
 * possible and override parent configurations as per how React Context works.
 *
 * A `useToggle` can receive a `scope` option which hooks it into a specific
 * ToggleProvider within the same `scope`. This allows toggles to be managed on
 * a higher level and can be useful to prevent (for example) multiple pop-ups
 * to be open at the same time.
 */
export function useToggle<K extends Key>(init?: boolean | UseToggleOptions<K>) {
  const args: UseToggleOptions<K> & Required<Pick<UseToggleOptions<K>, 'key'>> =
    {
      initial: false,
      key: Symbol() as K,
      ...(typeof init === 'boolean' ? { initial: init } : init),
    };

  const [_toggled, _setToggle] = useState(args.initial);
  const context = useToggleContext(args?.scope);

  // When the Component using `useToggle` is mounted, we assign it a
  // key to be used in the ToggleContext to keep track whether the
  // Component is toggled or not.
  const key = useRef(args.key);

  useEffect(() => {
    // We only want to trigger this effect once on mount because we assign a
    // specific key to toggles used in a context. Any changes to the `context`
    // or `options` reference (e.g. on every render) will reset the context's
    // toggled state and bork our toggle state.
    if (args?.initial) context?.toggle(key.current, args.initial);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // We don't want to trigger a state update in our useEffect for setting the
  // initial toggle state in a toggle context.
  const toggled = context?.toggled(key.current) ?? _toggled;

  const toggle = (force?: boolean) => {
    if (force === toggled) return;
    if (context) return context.toggle(key.current, force);
    _setToggle((_toggled) => force ?? !_toggled);
  };

  return {
    container: context?.container,
    toggle,
    toggled,
  };
}
