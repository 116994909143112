import React from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentAction } from '@pro4all/graphql';
import { FormikSearchableSelect } from '@pro4all/shared/ui/general';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';

import { useDocumentTaskFormConfig } from './useDocumentTaskFormConfig';

export const DocumentActionSelect = () => {
  const { t } = useTranslation();

  const options = Object.values(DocumentAction).map((documentAction) => ({
    id: documentAction,
    label: t(titleCaseToSentenceCase(documentAction ?? '') ?? ''),
  }));

  const { getField } = useDocumentTaskFormConfig();
  const documentActionField = getField('documentAction');

  return (
    <FormikSearchableSelect
      disabled={!options}
      id={documentActionField?.name}
      label={documentActionField?.label}
      name={documentActionField?.name ?? ''}
      options={options}
      placeholder={t('Select document action')}
    />
  );
};
