import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDefinition, TemplateType } from '@pro4all/graphql';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

import { StyledCardNew, StyledIcon, StyledQuestionFieldName } from './Styles';
import { useFieldSidebarContext } from './TemplateMutationContext';

export const NewCardField = ({
  parentSection,
}: {
  parentSection?: FieldDefinition;
}) => {
  const { t } = useTranslation();
  const { setIsOpenFieldSidebar, setParentFieldSidebar, setField } =
    useFieldSidebarContext();
  const { templateService } = useMetaDataContext();
  const { entity } =
    featureConfig[templateService].fieldTypes[TemplateType.Field];

  return (
    <StyledCardNew
      onClick={() => {
        setIsOpenFieldSidebar(true);
        setField(null);
        setParentFieldSidebar(parentSection);
      }}
    >
      <StyledIcon iconName="add" />
      <StyledQuestionFieldName variant="h6">
        {t(`New ${entity}`)}
      </StyledQuestionFieldName>
    </StyledCardNew>
  );
};
