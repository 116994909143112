import React from 'react';
import { Formik } from 'formik';
import { v4 as uuid } from 'uuid';

import { SmartFolder, ValueTypeName } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import {
  FormFooter,
  FormikForm,
  FormikInput,
  FormikTextarea,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useSectionSidebarContext } from '../TemplateMutationContext';
import { useFieldContext } from '../TemplateMutationContext';

import { useSectionFormConfig } from './useSectionFormConfig';

interface Props {
  onClose: () => void;
  smartFolder?: SmartFolder;
}

export const SectionForm: React.FC<Props> = ({ onClose }) => {
  const { appendField, editField } = useFieldContext();
  const { section } = useSectionSidebarContext();
  const isReusable = false; // TODO: For now we only have inline sections.

  const editMode = Boolean(section);

  const { getField, validationSchema } = useSectionFormConfig();
  const displayNameField = getField('displayName');
  const nameField = getField('name');

  return (
    <Formik
      initialValues={{
        displayName: section ? section.displayName : '',
        name: section ? section.name : '',
      }}
      onSubmit={async (values) => {
        const { displayName, name } = values;
        if (section) {
          editField({ ...section, displayName, name });
        } else {
          appendField({
            displayName,
            id: uuid(),
            name,
            type: ValueTypeName.Section,
            valueType: { subFields: [] },
          });
        }
        onClose();
      }}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <FormikTextarea
              autoFocus
              fieldsToSync={editMode ? [] : ['name']}
              id={displayNameField.name}
              label={displayNameField.label}
              name={displayNameField.name}
              rows={1}
            />
            {isReusable && (
              <FormikInput label={nameField.label} name={nameField.name} />
            )}
          </FormWrapper>
          <FormFooter
            ariaLabelSave="Save section"
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
