import React from 'react';
import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { IconName } from '@pro4all/shared/ui/icons';

import {
  CustomBooleanForm,
  CustomHierarchyListForm,
  CustomNumberForm,
  CustomSelectionForm,
  CustomStatusForm,
  CustomUserSelectionForm,
} from '../form-field-definition';

type ConfigFieldDefinitionTypeType = {
  [Type in ValueTypeName]?: {
    CustomForm?: React.ElementType;
    icon: IconName;
    label: string;
  };
};

export const useFieldConfig = (): ConfigFieldDefinitionTypeType => {
  const { t } = useTranslation();
  return {
    Bool: {
      CustomForm: CustomBooleanForm,
      icon: 'boolean',
      label: t('Yes/No'),
    },
    DateTime: {
      icon: 'dateRange',
      label: t('Date'),
    },
    Description: {
      icon: 'info',
      label: t('Description'),
    },
    HierarchyList: {
      CustomForm: CustomHierarchyListForm,
      icon: 'hierarchicalList',
      label: t('Hierarchical list'),
    },
    Media: {
      icon: 'image',
      label: t('Photo'),
    },
    MultiSelect: {
      CustomForm: CustomSelectionForm,
      icon: 'selection',
      label: t('Selection'),
    },
    Number: {
      CustomForm: CustomNumberForm,
      icon: 'number',
      label: t('Number'),
    },
    Selection: {
      CustomForm: CustomSelectionForm,
      icon: 'selection',
      label: t('Selection'),
    },
    Signature: {
      icon: 'signature',
      label: t('Signature'),
    },
    Status: {
      CustomForm: CustomStatusForm,
      icon: 'status',
      label: t('Status'),
    },
    Text: {
      icon: 'text',
      label: t('Text'),
    },
    TimeSpan: {
      icon: 'timer',
      label: t('Stopwatch'),
    },
    TreeSelection: {
      icon: 'text',
      label: t('TreeSelection'),
    },
    UserSelection: {
      CustomForm: CustomUserSelectionForm,
      icon: 'personOutline',
      label: t('User'),
    },
  };
};
