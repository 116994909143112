import { StopWatchRound } from './edit/useStopWatch';

//Format the duration from milliseconds to HH:MM:SS to show it to the user.
export const formatTimeSpanDuration = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
    2,
    '0'
  );
  const seconds = String(totalSeconds % 60).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export const parseTimeSpanToMilliseconds = (timeSpan: string): number => {
  const [hours, minutes, seconds] = timeSpan.split(':').map(Number);
  return (hours * 3600 + minutes * 60 + seconds) * 1000;
};

export const sortAndSeparateRounds = (
  rounds: StopWatchRound[]
): StopWatchRound[] => {
  const roundsWithoutFinal = rounds
    .filter((round: StopWatchRound) => round.label !== 'Final Time')
    .sort(
      (a: StopWatchRound, b: StopWatchRound) =>
        parseTimeSpanToMilliseconds(b.time) -
        parseTimeSpanToMilliseconds(a.time)
    );

  const finalTimeRound = rounds.find(
    (round: StopWatchRound) => round.label === 'Final Time'
  );
  if (finalTimeRound) {
    roundsWithoutFinal.unshift(finalTimeRound);
  }

  return roundsWithoutFinal;
};
