import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useTaskFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Title', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Description', // i18n
      name: 'description',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Date', // i18n
      name: 'endTime',
      type: ValueTypeName.DateTime,
    },
    {
      displayName: 'Status', // i18n
      name: 'nextStatus',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Participant', // i18n
      name: 'participant',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Category', // i18n
      name: 'category',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Type', // i18n
      name: 'type',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Forms', // i18n
      name: 'formTemplates',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Snags', // i18n
      name: 'snagTemplates',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Drawings', // i18n
      name: 'visualContexts',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ formFields });
};
