import styled from 'styled-components';

import { customColors, StylingDefaults } from '@pro4all/shared/themes';

const maxHeight = 200;

export const ThumbnailImg = styled.img<{ enablePreview: boolean }>`
  display: block;
  margin: ${({ theme }) => theme.spacing(1)} auto;
  justify-content: center;
  max-width: 350px;
  max-height: calc(${maxHeight}px - ${({ theme }) => theme.spacing(2)});
  cursor: ${({ enablePreview }) => (enablePreview ? 'pointer' : 'default')};
  box-shadow: ${StylingDefaults.boxShadow};
`;

export const ThumbnailContainer = styled.div`
  align-items: center;
  background: ${customColors.grey100};
  border-radius: ${StylingDefaults.borderRadiusSmall};
  display: flex;
  height: ${maxHeight}px;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(1)};
`;
