import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const SearchDeleted: React.FC<{
  name: string;
  onUndo: () => void;
}> = ({ name, onUndo }) => {
  const { t } = useTranslation();
  return (
    <ItemChangedMessage
      action={
        <Button color="inherit" onClick={onUndo} size="small">
          {t('Undo')}
        </Button>
      }
      description={MessageAction.Delete}
      entityName={name}
      entityTypeTranslation={EntityTypeTranslation.SavedSearch}
    />
  );
};
