import React from 'react';
import { useTranslation } from 'react-i18next';

import { FacetItem } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FilterBaseProps } from '@pro4all/shared/search-utils';
import { Loader, TextField } from '@pro4all/shared/ui/general';

import { ResultMatch } from '../../../results';
import { useFilterMenuContext } from '../../../search-bar/FilterMenuContext';
import { SelectorInputWrap } from '../../AddFilterButton.styles';
import { ContentWrap } from '../../Filter.styles';
import { useMDFacets } from '../../utils/useMDFacets';
import { MenuHeader, StyledButton, StyledLi } from '../Styles';

export const DefinitionSelect: React.FC<FilterBaseProps> = ({
  ...filterProps
}) => {
  const { t } = useTranslation();
  const {
    availableFacetItems,
    filteredFacetItems,
    onInputChange,
    selectedFacet,
    selectFacet,
    inputValue,
  } = useMDFacets(filterProps);

  const { setOpen } = useFilterMenuContext();

  const handleSelect = (facetItem: FacetItem) => {
    setOpen(false);
    selectFacet(facetItem);
  };

  const { facetLoading } = filterProps;

  const checkSelected = () => {
    if (
      !selectedFacet?.mdField ||
      !selectedFacet?.name ||
      !filterProps?.metaDataKey
    )
      return false;

    const { mdField, name } = selectedFacet;
    const { metaDataKey } = filterProps;

    return metaDataKey.includes(mdField.id) && metaDataKey.includes(name);
  };

  if (facetLoading) {
    return (
      <>
        <StyledLi>
          <StyledButton disabled>{t('Loading questions')}</StyledButton>
        </StyledLi>
        <ContentWrap>
          <Loader />
        </ContentWrap>
      </>
    );
  }

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {availableFacetItems.length > 0 || checkSelected() ? (
        <SelectorInputWrap>
          <TextField
            name="select-field"
            onChange={onInputChange}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </SelectorInputWrap>
      ) : (
        <MenuHeader>{t('No fields available')}</MenuHeader>
      )}
      {filteredFacetItems.map((facet) => {
        if (!facet || !facet.mdField) return null;
        const selected = selectedFacet?.mdField?.id === facet.mdField.id;
        const uniqueId = facet.mdField.id;

        return (
          <StyledLi $active={selected} key={`definition-${uniqueId}`}>
            <StyledButton onClick={() => handleSelect(facet)}>
              <ResultMatch matchString={inputValue} text={facet.name || ''} />
            </StyledButton>
          </StyledLi>
        );
      })}
    </Box>
  );
};
