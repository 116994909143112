import { useEffect, useState } from 'react';

import { DocumentService, ThreeDService } from '@pro4all/documents/data-access';
import { useThreeDConversionStatusesLazyQuery } from '@pro4all/graphql';

export const useGetThumbnail = ({
  debounceDuration = 0,
  documentId,
  versionId,
  load = true,
  threeDQuality = 400,
}: {
  debounceDuration?: number;
  documentId?: string;
  load?: boolean;
  threeDQuality?: number;
  versionId?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [debounce, setDebounce] = useState<number>(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [getThreeDConversionStatus] = useThreeDConversionStatusesLazyQuery({
    variables: { versionIds: versionId ? versionId : [] },
  });

  useEffect(() => {
    setLoading(false);
    const processThumbnail = async () => {
      const { data } = await getThreeDConversionStatus();

      const getBimThumbUrl = async (urn: string) => {
        if (urn) {
          const bimThumbnailUrl = await ThreeDService.getThumbnailUrl(
            urn,
            threeDQuality,
            threeDQuality
          );
          setThumbnailUrl(bimThumbnailUrl);
          setLoading(false);
        }
      };

      const getThumbnail = async (documentId: string, versionId: string) => {
        const thumbnailUrl = await DocumentService.getThumbnailUrl(
          documentId,
          versionId
        );
        setThumbnailUrl(thumbnailUrl);
        setLoading(false);
      };

      if (versionId) {
        if (
          data &&
          data.threeDConversionStatuses &&
          data.threeDConversionStatuses[0] &&
          data.threeDConversionStatuses[0].fileUrn
        ) {
          getBimThumbUrl(data.threeDConversionStatuses[0].fileUrn);
        } else if (documentId && versionId) {
          getThumbnail(documentId, versionId);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    if (!hasLoaded && load && document) {
      setLoading(true);
      setDebounce(
        window.setTimeout(() => {
          setHasLoaded(true);
          processThumbnail();
        }, debounceDuration)
      );
    }

    return () => {
      window.clearTimeout(debounce);
    };
  }, [document, getThreeDConversionStatus, threeDQuality, load]);

  return { debounce, loading, thumbnailUrl };
};
