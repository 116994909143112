export enum ActionType {
  START_UPLOADING = 'START_UPLOADING',
  STOP_UPLOADING = 'STOP_UPLOADING',
}
export type State = {
  idToUploadFor: string;
};
type ActionStartUploading = {
  payload: string;
  type: ActionType.START_UPLOADING;
};
type ActionStopUploading = {
  type: ActionType.STOP_UPLOADING;
};
type Action = ActionStartUploading | ActionStopUploading;

// Define the reducer
export const fileUploadReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.START_UPLOADING: {
      return {
        ...state,
        idToUploadFor: action.payload,
      };
    }
    case ActionType.STOP_UPLOADING: {
      return { ...state, idToUploadFor: '' };
    }
    default:
      return state;
  }
};
