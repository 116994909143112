import {
  Folder,
  TemplateService,
  TemplateState,
  TemplateType,
} from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Option } from '@pro4all/shared/types';

import { FormFields } from './FolderMetaDataSidebar';

export const useInitialValues = (folder: Folder) => {
  const { templateOptions } = useTemplatesInclude({
    state: TemplateState.Published,
    templateService: TemplateService.Documents,
    templateType: TemplateType.Document,
  });

  const currentTemplateOption: Option = templateOptions?.find(
    (option) => option.id === folder?.metadataSetId
  );

  const initialValues: FormFields = {
    applyToSubfolders: folder.inheritParentMetadata ?? true,
    selectedTemplate: currentTemplateOption || (null as Option),
  };

  return { initialValues, templateOptions };
};
