import { FilterDate } from './FilterDate';
import { FilterNumber } from './FilterNumber';
import { FilterSelect } from './FilterSelect';
import { FilterText } from './FilterText';
import { FilterComponentType, FilterHeaderType } from './types';

export const ComponentByType: FilterComponentType = {
  [FilterHeaderType.Date]: FilterDate,
  [FilterHeaderType.Number]: FilterNumber,
  [FilterHeaderType.Select]: FilterSelect,
  [FilterHeaderType.Text]: FilterText,
  [FilterHeaderType.SelectNumber]: FilterSelect,
};
