import { useLocation } from 'react-router';

export interface LocationData {
  formInstanceId: string;
  isLinkQuestion: boolean;
  snagInstanceId: string;
  taskId: string;
  taskName: string;
}

export const useTbqLocationData = (): LocationData => {
  const location = useLocation();
  return location.state as LocationData;
};
