import { FolderPermission } from '@pro4all/graphql';

import { addReadAllVersionsPermission } from '../../shared/helpers/addReadAllVersionsPermission';
import { getExclusivePermissionsToUse } from '../../shared/helpers/getExclusivePermissionsToUse';
import { permissionsToRemove } from '../../shared/helpers/permissionsToRemove';
import { removeExclusiveFolderPermissions } from '../../shared/helpers/removeExclusiveFolderPermissions';
import { ExclusivePermissionType, OptionValue } from '../../types/types';
import { Permission } from '../types';

import { getAncestorPermissionIncluded } from './getAncestorPermissionIncluded';
import { getChildFolders } from './getChildFolders';
import { getThisFolderPermission } from './getThisFolderPermission';
import { shouldChangeSubFolderPermission } from './shouldChangeSubFolderPermission';

export const getUpdatedSubfoldersSinglePermission = ({
  displayPermissions,
  exclusivePermissionType,
  folderId,
  initialPermissionsExplicit,
  otherFolderPermissions,
  parent,
  permission,
  savePermissions,
}: {
  displayPermissions: Permission[];
  exclusivePermissionType?: ExclusivePermissionType;
  folderId: string;
  initialPermissionsExplicit: Permission[];
  otherFolderPermissions: Permission[];
  parent: Permission;
  permission: FolderPermission;
  savePermissions: Permission[];
}) => {
  // In case of a subfolder there might be inheritance from the parent.
  // This means that not every permission a user selects for the subfolder can be applied.
  // The method `getExclusivePermissionsBasedOnParentPermissions` will return:
  //   1. the permissions that can be applied for that subfolder.
  //   2. the permission the conflicting permission was reverted to.

  let response: Permission[] = [];

  // If isPermissionIncluded then user unchecked the permission!
  // This applies to the permission cells (on or off) or dropdowns turned to `None`.
  const isPermissionIncluded = parent.folderPermissions.includes(permission);

  const setChildFolders = ({
    folder,
    isChild,
    isPermissionIncluded,
    updatedFolderPermissionsParent,
  }: {
    folder: Permission;
    isChild: boolean;
    isPermissionIncluded: boolean;
    updatedFolderPermissionsParent: FolderPermission[];
  }) => {
    const thisFolderInitialExplicit = getThisFolderPermission(
      folder.folderId,
      initialPermissionsExplicit
    );

    const thisFolderSave = getThisFolderPermission(
      folder.folderId,
      savePermissions
    );

    const thisFolderDisplay = getThisFolderPermission(
      folder.folderId,
      displayPermissions
    );

    if (folderId !== folder.folderId && thisFolderDisplay.breakInheritance) {
      // User did change the permission of a parent and this subFolder has breakInheritance set te true.
      // So prevent inheritance from this subFolder on and all deeper nested subfolders.
      response = [...response, thisFolderDisplay];
    } else {
      const thisFolderSaveOrDisplay = thisFolderSave || thisFolderDisplay;
      const thisFolderSaveOrInitial =
        thisFolderSave || thisFolderInitialExplicit;

      const { breakInheritance } = thisFolderDisplay;

      // ************ Start logic to calculate `initialFolderPermissions` **//
      const isPermissionIncludedInitially =
        thisFolderInitialExplicit.folderPermissions.includes(permission);

      // In case user previously explicitly enabled permission for the subfolder.
      const isPermissionIncludedSave = thisFolderSave
        ? thisFolderSave.folderPermissions.includes(permission)
        : false;

      // In case user previously explicitly disabled permission for the subfolder.
      const isPermissionNotIncludedSave = thisFolderSave
        ? !thisFolderSave.folderPermissions.includes(permission)
        : false;

      // In case we disabled the permission of a parent,
      // the subfolders should return to their initial value for this specific permission,
      // except if the permission was explicitly changed on subfolders.
      const isAncestorPermissionIncluded = isPermissionIncluded // if isPermissionIncluded then user unchecked the permission!
        ? getAncestorPermissionIncluded({
            folderId: thisFolderInitialExplicit.parentFolderId,
            permission,
            permissions: initialPermissionsExplicit,
          })
        : false;

      // The value of the constant `initialFolderPermissions` is used to reset to the initial value of the checkbox permission for subfolders
      // in case the related parent folder permission is de-checked.
      const initialFolderPermissions =
        folderId !== folder.folderId &&
        (isPermissionIncludedInitially ||
          isPermissionIncludedSave ||
          isAncestorPermissionIncluded) &&
        !isPermissionNotIncludedSave
          ? [permission]
          : [];
      // ************ End logic to calculate `initialFolderPermissions` **//

      const exclusivePermissionsToUse = exclusivePermissionType
        ? getExclusivePermissionsToUse(exclusivePermissionType)
        : [];

      // For a few changes related to exclusive permissions we don't want to update.
      const updateFolderPermissions =
        // In case of a child folder we only update the permission in case the parent permission is less restrictive than the current permission of the child.
        // The method `shouldChangeSubFolderPermission` takes care of that logic.
        (isChild &&
          shouldChangeSubFolderPermission({
            currentSubFolderPermissions:
              thisFolderSaveOrInitial.folderPermissions,
            permission: permission as unknown as OptionValue, // This is what we get if two types flow over into each other.
          })) ||
        // In case of a parent folder we always want to update.
        !isChild;

      let updatedFolderPermissions: FolderPermission[] = [];
      if (isChild && !updateFolderPermissions && !breakInheritance) {
        // Do not change the permissions of the child folder in case the constant `updateFolderPermissions` is false.
        updatedFolderPermissions = removeExclusiveFolderPermissions({
          permissions: [
            ...thisFolderSaveOrInitial.folderPermissions,
            ...updatedFolderPermissionsParent,
          ],
        });
      } else {
        if (isPermissionIncluded) {
          updatedFolderPermissions = [
            ...new Set([
              ...exclusivePermissionsToUse,
              ...thisFolderSaveOrDisplay.folderPermissions,
              permission,
            ]),
          ].filter(
            (perm) => !permissionsToRemove.get(permission)?.includes(perm)
          );
        } else {
          // This applies to
          // 1. de-checked permission cells for both parent and child folders (in this case `initialFolderPermissions` can have a value).
          // 2. dropdowns of parent folders turned to `None`.
          if (updateFolderPermissions) {
            updatedFolderPermissions = [
              ...new Set([
                ...thisFolderSaveOrDisplay.folderPermissions
                  .filter((perm) => perm !== permission)
                  .filter(
                    (perm) =>
                      !permissionsToRemove.get(permission)?.includes(perm)
                  ),
                ...initialFolderPermissions,
              ]),
            ];
          } else {
            updatedFolderPermissions =
              thisFolderSaveOrDisplay.folderPermissions;
          }
        }

        updatedFolderPermissions = removeExclusiveFolderPermissions({
          permissions: [
            ...updatedFolderPermissions,
            ...updatedFolderPermissionsParent,
          ],
        });
      }

      // In case the user did select any documents permission (`ReadAllDocuments`, `ReadOwn` or `ReadOwnAndFinalized`)
      // and currently there's no active versions permission, we add the `ReadAllVersions` permission to the folderPermissions prop.
      updatedFolderPermissions = addReadAllVersionsPermission(
        updatedFolderPermissions
      );

      const thisFolderUpdated = {
        ...thisFolderSaveOrDisplay,
        folderPermissions: updatedFolderPermissions,
      };

      response = [...response, thisFolderUpdated];

      const childFolders = getChildFolders(
        folder.folderId,
        otherFolderPermissions
      );

      childFolders.forEach((folder) =>
        setChildFolders({
          folder,
          isChild: true,
          isPermissionIncluded,
          updatedFolderPermissionsParent: updatedFolderPermissions,
        })
      );
    }
  };

  setChildFolders({
    folder: parent,
    isChild: false,
    isPermissionIncluded,
    updatedFolderPermissionsParent: [],
  });

  const responseFolderIds = response.map((folder) => folder.folderId);
  const otherFoldersOutsideThisTree = otherFolderPermissions.filter(
    (folder) => !responseFolderIds.includes(folder.folderId)
  );
  return [...response, ...otherFoldersOutsideThisTree];
};
