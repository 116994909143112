import { Document } from '@pro4all/graphql';
import {
  convertForbiddenFilenameCharacters,
  truncateDownloadNameForEditPublish,
} from '@pro4all/shared/utils';

export const getDownloadName = (document: Document) => {
  const downloadName = truncateDownloadNameForEditPublish(document);
  const splittedName = downloadName.split('.');

  const downloadNameMinusExtension =
    splittedName[splittedName.length - 1] ===
    document.extension.replace('.', '')
      ? splittedName.slice(0, -1).join('.')
      : downloadName;
  return convertForbiddenFilenameCharacters(downloadNameMinusExtension);
};
