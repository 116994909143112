// Appends (n) to a name when it is found in collection "names"
// Where n is the highest number in collection + 1
export function resolveNameDuplicates(name: string, names: string[]) {
  const appendedIndexPattern = / ?\((\d+)\)$/;
  const baseName = name.split(appendedIndexPattern)[0];
  const nameMatch = names.find((name) => name.match(new RegExp(baseName)));
  if (!nameMatch) {
    return name; // no duplicates found
  }

  const foundIndices: string[] = [];

  names.forEach((n) => {
    const match = n.match(appendedIndexPattern);
    match && foundIndices.push(match[1]);
  });

  const latest: number = foundIndices.length
    ? Math.max.apply(null, foundIndices)
    : 0;

  return `${baseName} (${latest + 1})`;
}
