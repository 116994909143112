import { useTranslation } from 'react-i18next';

import { UserState } from '@pro4all/graphql';
import { ThemeColor } from '@pro4all/shared/themes';
import { IconName } from '@pro4all/shared/ui/icons';

type UserStatusMap = {
  [key in UserState]: {
    color: ThemeColor;
    icon: IconName;
    label: string;
    textColor: ThemeColor;
  };
};

export const useUserStatuses = (): UserStatusMap => {
  const { t } = useTranslation();
  return {
    Active: {
      color: 'successBg',
      icon: 'activeUser',
      label: t('Active'),
      textColor: 'successHover',
    },
    Deactivated: {
      color: 'grey300',
      icon: 'inactiveUser',
      label: t('Deactivated'),
      textColor: 'grey800',
    },
    InvitationExpired: {
      color: 'errorBg',
      icon: 'activeUser',
      label: t('Invitation expired'),
      textColor: 'errorHover',
    },
    InvitationNotSent: {
      color: 'warningBg',
      icon: 'hourglassEmpty',
      label: t('Invitation not sent'),
      textColor: 'warningHover',
    },
    InvitationSent: {
      color: 'pendingBg',
      icon: 'send',
      label: t('Invitation sent'),
      textColor: 'pendingHover',
    },
  };
};
