import { SchemaExtender } from '@pro4all/shared/forms';

import { useCustomBuild12ValidationSchema } from './CustomBuild12';
import { useCustomSnagstreamValidationSchema } from './CustomSnagstream';

export const useCustomValidation = (): Record<string, SchemaExtender> => {
  // Application related validation schemas are considered as customValidation.

  const customBuild12ValidationSchema = useCustomBuild12ValidationSchema();
  const customSnagstreamValidationSchema =
    useCustomSnagstreamValidationSchema();

  const customValidation = {
    ...customBuild12ValidationSchema,
    ...customSnagstreamValidationSchema,
  };

  return customValidation;
};
