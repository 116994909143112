import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import type { ObjectNode } from '@pro4all/graphql';
import {
  useLinkProjectMutation,
  useUnlinkProjectMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { useObjectsContext } from '../ObjectsContext';
import type { UseObjectsResult } from '../useObjects';

interface UseProjectObjectsResult extends UseObjectsResult {
  linkProject: (projectId?: string | null) => void;
  projectObject?: ObjectNode;
  unlinkProject: (objectId?: string | null, projectId?: string | null) => void;
}

export function useProjectObjects(): UseProjectObjectsResult {
  const {
    params: { projectId: projectIdParam },
  } = useRouting();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();
  const [linkProject] = useLinkProjectMutation();
  const [unlinkProject] = useUnlinkProjectMutation();
  const { objects, selectedObject, refetch, ...objectsResult } =
    useObjectsContext();

  const projectObject = selectedObject?.projectId
    ? selectedObject
    : projectIdParam
    ? objects.find((objects) => objects.projectId === projectIdParam)
    : objects.find(({ parentNodeId, projectId }) => !parentNodeId && projectId);

  const handleLinkProject: UseProjectObjectsResult['linkProject'] = async (
    projectId = projectIdParam
  ) => {
    if (!projectId || !selectedObject) return;

    try {
      await linkProject({
        variables: { nodeId: selectedObject.id, projectId },
      });
      enqueueSnackbar(t('Project link created'));
      refetch();
    } catch (e) {
      showSingleError(e);
    }
  };

  const handleUnlinkProject: UseProjectObjectsResult['unlinkProject'] = async (
    objectId,
    projectId = projectObject?.projectId
  ) => {
    if (!objectId || !projectId) return;

    try {
      await unlinkProject({ variables: { projectId } });
      enqueueSnackbar(
        t('{{- objectName}} removed from project {{- projectName}}', {
          objectName: projectObject?.name,
          projectName: projectObject?.project?.name,
        })
      );
      refetch();
    } catch (e) {
      showSingleError(e);
    }
  };

  return {
    ...objectsResult,
    linkProject: handleLinkProject,
    objects,
    projectObject,
    refetch,
    selectedObject,
    unlinkProject: handleUnlinkProject,
  };
}
