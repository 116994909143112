import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { FormFooter, FormikForm, InfoBlock } from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { FormikProjectSelect } from '../../form-elements/FormikProjectSelect';
import { useAddGroupsUsersToProjectFormConfig } from '../../form-elements/useAddGroupsUsersToProjectFormConfig';

import { useAddGroupsToProjects } from './useAddGroupsToProjects';

interface Props {
  onClose: () => void;
}

export const AddGroupsToProjectForm: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const addGroupsToProjects = useAddGroupsToProjects();

  const { validationSchema } = useAddGroupsUsersToProjectFormConfig();

  return (
    <Formik
      initialValues={{
        projects: [],
      }}
      onSubmit={async (values) => addGroupsToProjects({ onClose, values })}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <FormWrapper>
            <InfoBlock
              paragraph={t(
                'The group is placed in a project, but without permissions. These can be set in the project.'
              )}
            />
            <FormikProjectSelect />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
            submitLabel="Connect"
          />
        </FormikForm>
      )}
    </Formik>
  );
};
