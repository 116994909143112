import React from 'react';

import { AttachmentContext, Message } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';

import { Attachment, AttachmentProps } from './Attachment';
import { DownloadAllAttachments } from './DownloadAllAttachments';

interface Props {
  attachments: Message['attachments'];
  context: AttachmentContext;
  initExpanded?: boolean;
  messageId: string;
  onDownload?: AttachmentProps['onDownload'];
}

export const AttachmentList: React.FC<Props> = ({
  attachments,
  onDownload,
  messageId,
  context,
}) => {
  const hasMultipleAttachments = attachments && attachments?.length > 1;

  return (
    <Box display="flex" flexDirection="column" py={1} width="100%">
      {context === AttachmentContext.Msg && hasMultipleAttachments && (
        <DownloadAllAttachments
          context={context}
          messageId={messageId}
          onDownload={onDownload}
        />
      )}

      {attachments?.map((attachment) => (
        <Attachment
          completed
          context={context}
          id={attachment?.fileId}
          key={attachment?.fileId}
          messageId={messageId}
          name={attachment.fileName}
          onDownload={onDownload}
          size={attachment.fileSize}
        />
      ))}
    </Box>
  );
};
