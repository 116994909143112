import { useEffect } from 'react';

import { Maybe, QualityControlInstance, Task } from '@pro4all/graphql';

import { Drawing } from './types';

export const useSetDrawings = ({
  currentTask,
  setDrawingsState,
}: {
  currentTask: Task;
  setDrawingsState: (drawings: Drawing[]) => void;
}) => {
  const setDrawings = (
    linkedSnags:
      | QualityControlInstance[]
      | undefined
      | Maybe<QualityControlInstance>[]
  ) => {
    const fps: Drawing[] = [];

    linkedSnags?.forEach((snag) => {
      if (snag?.visualContext?.name) {
        const unique =
          fps.filter((fp) => fp.name === snag?.visualContext?.name).length ===
          0;
        if (unique)
          fps.push({
            deletedAt: snag.visualContext.deletedAt,
            id: snag.visualContext.id,
            name: snag.visualContext.name,
          });
      }
    });
    setDrawingsState([...new Set(fps)]);
  };

  useEffect(() => {
    setDrawings(currentTask?.linkedSnagInstances ?? []);
  }, [currentTask.linkedSnagInstances]);
};
