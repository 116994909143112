import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const CollectionBin: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M5 18H19C19.55 18 20 17.55 20 17V7C20 6.45 20.45 6 21 6C21.55 6 22 6.45 22 7V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V7C2 6.5 2.5 6 3 6C3.5 6.00009 4 6.5 4 7V17C4 17.55 4.45 18 5 18Z" />
    <rect height="5" rx="1" width="5" x="6" y="11" />
    <rect height="5" rx="1" width="5" x="6" y="4" />
    <rect height="5" rx="1" width="5" x="13" y="11" />
  </SvgIcon>
);
