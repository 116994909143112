import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

export const useLinkProject = (folder?: Folder) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const linkProjectAction: ActionProps = {
    ariaLabel: 'Place and link project',
    dataTestId: 'link-project-to-folder',
    disabled: folder && !hasFolderPermission('UpdateFolder'),
    key: 'link-project-to-folder',
    label: t('Place and link project'),
    onClick: () => searchParams.set({ action: 'linkProjectToFolder' }),
    startIcon: 'objectLinkActive',
  };

  return {
    linkProjectAction,
  };
};
