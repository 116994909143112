import React, { useEffect, useState } from 'react';

import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';

import { useMDFacets } from '../../utils/useMDFacets';
import { TagSelect } from '../TagSelect';

import { DefinitionSelect } from './DefinitionSelect';

// TODO: Waiting for the BE to match single individual selections.
// See story #30318
export const SelectionFilter: React.FC<FilterBaseProps> = ({
  ...filterProps
}) => {
  const { filteredAnswers } = useMDFacets(filterProps);
  const { facetLoading, metaDataKey, type, value, name } = filterProps;
  const [options, setOptions] = useState<Option[] | undefined>(undefined);

  useEffect(() => {
    if (!options) {
      const repeatedValues: Set<string> = new Set();
      filteredAnswers?.forEach((item) => {
        const values = item.value.split(',');
        values.forEach((value) => repeatedValues.add(value));
      });
      const uniqueAnswers = Array.from(repeatedValues).map((item) => ({
        id: `selection-filter-option-${item}`,
        inputValue: item,
        label: item,
      }));

      uniqueAnswers.length && setOptions(uniqueAnswers);
    }
  }, [filteredAnswers, options]);

  const values = value.split(delimiters.multiSelectOptions);
  const initialOptions =
    options?.filter(
      (option) => option.inputValue && values.includes(option.inputValue)
    ) || [];

  return name ? (
    <TagSelect
      initialOptions={initialOptions}
      loading={facetLoading}
      metaDataKey={metaDataKey}
      options={options || []}
      showTagIcon
      type={type}
      value={value}
    />
  ) : (
    <DefinitionSelect {...filterProps} />
  );
};
