import { TemplateService, TemplateState, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetTemplates = () => {
  const { searchParams } = useRouting();

  const selectTemplateContext = searchParams.is('action', 'selectTemplate');

  const { templates: snagTemplates, loading: loadingSnags } =
    useTemplatesInclude({
      includeOrgItems: true,
      includeType: true,
      skip: !selectTemplateContext,
      state: TemplateState.Published,
      templateService: TemplateService.QualityControl,
      templateType: TemplateType.Snag,
    });

  const { templates: formTemplates, loading: loadingForms } =
    useTemplatesInclude({
      includeOrgItems: true,
      includeType: true,
      skip: !selectTemplateContext,
      state: TemplateState.Published,
      templateService: TemplateService.QualityControl,
      templateType: TemplateType.Form,
    });

  const showLoader = loadingSnags || loadingForms;

  return { formTemplates, showLoader, snagTemplates };
};
