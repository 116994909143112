import React from 'react';

import { NumberInput, TextFieldProps } from '@pro4all/shared/ui/general';

import { useHandleValueChangeInputs } from './useHandleValueChangeInputs';

interface Props extends TextFieldProps {
  setValue: (name: string, value: string) => void;
  value: string;
}

export const Number = ({ name, setValue, value, ...rest }: Props) => {
  const { currentValue, handleChangeText } = useHandleValueChangeInputs({
    name,
    setValue,
    value,
  });

  return (
    <NumberInput
      {...rest}
      name={name}
      onChange={handleChangeText}
      value={currentValue}
    />
  );
};
