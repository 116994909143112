import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

export const Form = styled(FormikForm)`
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  button:last-child {
    flex: 1 0 auto;
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Headers = ({
  title,
  subtitle,
}: {
  subtitle: string;
  title: string;
}) => (
  <Box mb={3}>
    <Text variant="h2">{subtitle}</Text>
    <Text variant="h1">{title}</Text>
  </Box>
);

export const StepHeader = ({ step }: { step: number }) => {
  const { t } = useTranslation();
  const stepHeaders = [
    t('To whom do we owe this pleasure?'),
    t('We are almost there. Make it official.'),
  ];
  const subtitle = t('Step {{number}} of {{total}}', {
    number: step + 1,
    total: stepHeaders.length,
  });

  return <Headers subtitle={subtitle} title={stepHeaders[step]} />;
};
