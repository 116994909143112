import React from 'react';
import { useTranslation } from 'react-i18next';

import { useInterval } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

const REDIRECT_TIMEOUT = 10000;

export const PageSuccess: React.FC = () => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({});
  const { goTo } = useRouting();

  useInterval(() => goTo('base'), REDIRECT_TIMEOUT);

  return (
    <InfoPage
      actions={[actionLogin]}
      description={t(
        'If it takes longer than expected, click on the button below.'
      )}
      title={t('You are being redirected to login')}
    />
  );
};
