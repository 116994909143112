import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SelectedIdName } from '@pro4all/shared/types';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { FormFooter } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { useDeleteSavedAnswers } from './useDeleteSavedAnswers';

export const DialogDeleteSavesAnswersConfirmation = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const deleteSavedAnswersContext = searchParams.is(
    'deleteSavedAnswers',
    'true'
  );
  const { deleteSavedAnswers } = useDeleteSavedAnswers();

  const { getLocalStorageItem: getSavedAnswersNameToDeleteInLs } =
    useLocalStorage<SelectedIdName[]>({
      key: `prostream-saved-answers-name-to-delete`,
    });

  const onClose = () => {
    searchParams.delete('deleteSavedAnswers');
  };

  return (
    <FloatingModal
      data-testid="delete-saved-answers-modal"
      onClose={onClose}
      open={deleteSavedAnswersContext}
    >
      <FloatingModal.Header iconName="delete">
        {t('Delete saved answers')}
      </FloatingModal.Header>
      <Text paragraph variant="body1">
        {t('Are you sure you want to delete the selected saved answers?')}
      </Text>
      <FormFooter
        onClose={onClose}
        onSubmit={() => {
          const savedAnswersData =
            getSavedAnswersNameToDeleteInLs() as SelectedIdName[];
          deleteSavedAnswers(savedAnswersData);
          onClose();
        }}
        pt={2}
        sticky
        submitLabel={t('Delete')}
      />
    </FloatingModal>
  );
};
