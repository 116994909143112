import React from 'react';
import styled from 'styled-components';

import { SvgIcon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import type { AnswerComponent } from '../AnswerView';

export const Status: AnswerComponent = ({ children, item }) =>
  children ? (
    <StyledStatus
      sx={{
        color: item.valueType.options.find(({ name }) => children === name)
          ?.color,
      }}
      variant="body1"
    >
      <SvgIcon>
        <circle cx="12" cy="12" fill="currentColor" r="4" />
      </SvgIcon>
      {children}
    </StyledStatus>
  ) : null;

const StyledStatus = styled(Text)`
  && {
    display: inline-flex;
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing(1)} 0
      ${({ theme }) => theme.spacing(0.5)};
    border: solid 1px;
    border-radius: 20px;
  }
`;
