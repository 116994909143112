import { Event } from 'react-big-calendar';

import { Task } from '@pro4all/graphql';
import { useTaskStatuses } from '@pro4all/workflow/ui/utils';

export const useGetCalendarEvents = (tasks: Task[]): Event[] => {
  const taskStatusMap = useTaskStatuses();

  const events: Event[] = tasks.map((task) => ({
    allDay: true,
    end: new Date(task.endTime),
    resource: {
      color: taskStatusMap[task.status || 'ToDo'].color,
      id: task.id,
      textColor: taskStatusMap[task.status || 'ToDo'].textColor,
    },
    start: new Date(task.endTime),
    title: task.name,
  }));

  return events;
};
