import * as Serilogger from 'serilogger';

import { StructuredConsoleSink } from './structuredConsoleSink';

const logLevel = process.env.LOG_LEVEL || Serilogger.LogEventLevel.warning;
const runsInContainer = process.env.RUNS_IN_CONTAINER || 1;
const forceColoredConsole = process.env.FORCE_COLORED_CONSOLE || 0;

const targetSink =
  !runsInContainer || forceColoredConsole
    ? new Serilogger.ColoredConsoleSink()
    : new StructuredConsoleSink();

export const logger = Serilogger.configure()
  .minLevel(logLevel)
  .writeTo(targetSink)
  .create();
