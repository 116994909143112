import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Option } from '@pro4all/shared/types';

import { ViewerPropertyGroup } from '../viewers/Viewer.types';

import { PropertyGroup } from './PropertyGroup';
import { PropertyGroupSelect } from './PropertyViewer.styles';

const PropertyContainer = styled('div')`
  height: 100%;
  overflow: hidden;
`;

export const PropertyViewer: React.FC<{
  propertyGroups: ViewerPropertyGroup[];
}> = ({ propertyGroups }) => {
  const [groupView, setGroupView] = useState<string>();
  const [groupToShow, setGroupToShow] = useState<
    ViewerPropertyGroup | undefined
  >();
  const [groupOptions, setGroupOptions] = useState<Option[]>([]);

  const handleGroupChange = (value: Option) => {
    const group = propertyGroups.find(
      (propertyGroup) => propertyGroup.label === value.label
    );
    group ? setGroupToShow(group) : setGroupToShow(propertyGroups[0]);
  };

  useEffect(() => {
    setGroupOptions(
      propertyGroups.map((propertyGroup) => ({
        id: propertyGroup.label,
        label: propertyGroup.label,
      }))
    );
  }, [propertyGroups]);

  useEffect(() => {
    const group = propertyGroups.find(
      (propertyGroup) => propertyGroup.label === groupView
    );
    group ? setGroupToShow(group) : setGroupToShow(propertyGroups[0]);
  }, [groupView, propertyGroups]);

  if (!groupView && propertyGroups && propertyGroups.length > 0) {
    setGroupView(propertyGroups[0].label);
  }

  return (
    <PropertyContainer>
      <PropertyGroupSelect
        name="viewModeSelect"
        onChange={handleGroupChange}
        options={groupOptions}
        value={groupView}
      />
      {groupToShow && (
        <PropertyGroup propertyGroup={groupToShow}></PropertyGroup>
      )}
    </PropertyContainer>
  );
};
