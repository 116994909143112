import type { Document, DocumentVersion } from '@pro4all/graphql';
import { StampType } from '@pro4all/graphql';

import { Rect } from '../shape';

export interface StamperDocument
  extends Pick<Document, 'name'>,
    Pick<DocumentVersion, 'id' | 'documentId'> {
  pages?: number[];
}

export interface Canvas {
  height: number;
  width: number;
}

export interface Shape {
  children: React.ReactNode;
  id?: string;
  rect: Rect;
  type?: StampType;
}

export enum Status {
  Done,
  Initializing,
  Idle,
  Pending,
}

export interface State {
  canvas: Canvas | null;
  index: number;
  queue: StamperDocument[];
  status: Status;
}

export const initial: State = {
  canvas: null,
  index: 0,
  queue: [],
  status: Status.Initializing,
};
