import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoOrganizationFoldersForPermissions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="flow1"
      text={t('Create a folder to start adding permissions.')}
      title={t('This organization has no folders')}
    />
  );
};
