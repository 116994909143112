import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

export const SearchFieldWrapper = ({ children }: PropsWithChildren<{}>) => (
  <Box mb={1} ml={3} mr={3}>
    {children}
  </Box>
);

export const SearchFieldWrapperDialog = ({
  children,
}: PropsWithChildren<{}>) => <Box mb={1}>{children}</Box>;
