import { useTranslation } from 'react-i18next';

import { EntityTreeContainer } from '../../Documents3DViewer.styles';
import { ViewerNode } from '../../viewers/Viewer.types';
import { EntityTree } from '../tree/EntityTree';

import {
  EntitySearchContainer,
  EntitySearchFeedback,
  EntitySearchTextField,
} from './EntitySearch.styled';

export const EntitySearch: React.FC<{
  activeNodes: ViewerNode[];
  displayWarningTreshhold?: number | undefined;
  nodes: ViewerNode[] | undefined;
  onFocus: (nodes: ViewerNode[]) => void;
  onHide: (nodes: ViewerNode[]) => void;
  onIsolate: (nodes: ViewerNode[]) => void;
  onSearch: (term: string) => void;
  onSelect: (nodes: ViewerNode[]) => void;
  onShowAll: () => void;
  searchQuery: string;
}> = ({
  activeNodes,
  displayWarningTreshhold,
  onHide,
  onIsolate,
  onFocus,
  onSelect,
  onShowAll,
  onSearch,
  nodes,
  searchQuery,
}) => {
  const { t } = useTranslation();

  return (
    <EntitySearchContainer>
      <EntitySearchTextField
        margin="none"
        name="Search"
        onChange={(event) => onSearch(event.target.value.toString())}
        placeholder={t('Search elements')}
        role="search"
        value={searchQuery}
      />
      {displayWarningTreshhold &&
        nodes &&
        displayWarningTreshhold <= nodes.length && (
          <EntitySearchFeedback
            dropShadow={false}
            fullWidth
            severity="warning"
            variant="plain"
          >
            {t('Search limited to')} {displayWarningTreshhold} {t('entities')}
          </EntitySearchFeedback>
        )}
      <EntityTreeContainer>
        {nodes &&
          nodes.length > 0 &&
          nodes.map((node) => (
            <EntityTree
              activeNodes={activeNodes}
              key={`${node.model.id}-${node.id}`}
              onFocus={onFocus}
              onHide={onHide}
              onIsolate={onIsolate}
              onSelect={onSelect}
              onShowAll={onShowAll}
              rootNode={node}
            ></EntityTree>
          ))}
      </EntityTreeContainer>
    </EntitySearchContainer>
  );
};
