import { useSubscriptionHelpers } from './useSubscriptionHelpers';

export const useTrialDaysLeft = () => {
  const { getSubscriptionEndDate } = useSubscriptionHelpers();

  const endDate = new Date(getSubscriptionEndDate());
  const currentDate = new Date();
  const differenceInMs = endDate.valueOf() - currentDate.valueOf();
  const differenceInDays = Math.ceil(differenceInMs / (1000 * 3600 * 24));

  return differenceInDays;
};
