import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Integration } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/general';

import { useDeleteIntegration } from './useDeleteIntegration';

export const DeleteIntegrationForm = ({
  items,
  onClose,
}: {
  items: Integration[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const deleteIntegration = useDeleteIntegration({ onClose });

  const id = searchParams.get('id') || '';
  const integration = items.find((item) => item.id === id)?.integrationType;

  const integrationName = integration?.replace('Integration', '');

  return (
    <>
      <Trans
        components={{ strong: <strong /> }}
        defaults="Are you sure you want to break the integration with <strong>{{- integrationName}}</strong>? Connections configured with this integration in projects will be removed. <strong>Once deleted we cannot restore it</strong>."
        values={{
          integrationName,
        }}
      />
      <FormFooter
        onClose={onClose}
        onSubmit={deleteIntegration}
        pt={2}
        sticky
        submitLabel={t('Delete')}
      />
    </>
  );
};
