import { useTranslation } from 'react-i18next';

import { TemplateState } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';

export const usePredefinedFilters = () => {
  const { t } = useTranslation();

  const { projectData } = useProjectContext();

  const getPublishedFilterValue = () => [t(TemplateState.Published)];
  const getDraftFilterValue = () => [t(TemplateState.Draft)];
  const getOrganizationFilterValue = () => [t('Organization')];
  const getProjectFilterValue = () => [t('Project')];

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getPublishedFilterValue(),
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Published'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getDraftFilterValue(),
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Draft'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getOrganizationFilterValue(),
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'scope',
          subPropertyId: 'type',
          translateOptions: true,
        },
      ],
      title: t('Organization templates'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getProjectFilterValue(),
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'scope',
          subPropertyId: 'type',
          translateOptions: true,
        },
      ],
      title: t('Project templates'),
    },
  ];

  return predefinedFiltes.filter((predefinedFilter) => {
    if ([t('Organization'), t('Project')].includes(predefinedFilter.title)) {
      if (projectData.id === '') {
        return false;
      }
    }
    return true;
  });
};
