import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Project,
  ProjectsUnreadCountQuery,
  useProjectsUnreadCountQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

import { getUnreadCountByProjectId } from './getUnreadCountByProjectId';
import { FilterMessageLabels, ProjectWithMessage } from './types';

export const useAddMessagesLabelForFiltering = ({
  projects,
}: {
  projects: Project[];
}) => {
  const { t } = useTranslation();

  const [projectState, setProjectState] = useState<ProjectWithMessage[]>([]);
  const projectStateRef = useRef(projectState);

  const { data: messagesData, loading: messagesLoading } =
    useProjectsUnreadCountQuery({
      pollInterval: 10000 * ApiConfig.pollEnabled,
    });

  const addMessagesLabelForFiltering = useCallback(
    (project: Project, messagesData?: ProjectsUnreadCountQuery) =>
      ({
        unreadLabel,
        noUnreadLabel,
      }: {
        noUnreadLabel: FilterMessageLabels;
        unreadLabel: FilterMessageLabels;
      }): ProjectWithMessage => {
        const unreadCount = getUnreadCountByProjectId(project.id, messagesData);
        return {
          ...project,
          projectMessages: {
            hasUnread: unreadCount > 0 ? unreadLabel : noUnreadLabel, //18n
            unreadCount,
          },
        };
      },
    []
  );

  useEffect(() => {
    const hasProjects = Number(projects?.length || 0) > 0;
    const isProjectStateRefEmpty = projectStateRef.current.length === 0;
    const hasProjectDataChanged = projects !== projectStateRef.current;

    if (
      projects &&
      hasProjects &&
      (isProjectStateRefEmpty || hasProjectDataChanged)
    ) {
      setProjectState(
        projects.map((project) =>
          addMessagesLabelForFiltering(
            project,
            messagesData
          )({
            noUnreadLabel: t('No Unread Messages'),
            unreadLabel: t('Unread Messages'),
          })
        )
      );
    }
  }, [addMessagesLabelForFiltering, messagesData, projects, t]);

  return {
    getUnreadCountByProjectId,
    messagesData,
    messagesLoading,
    projectState,
  };
};
