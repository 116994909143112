import React from 'react';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import {
  ButtonsBoolean,
  SearchableMultiSelect,
  SearchableSelect,
} from '@pro4all/shared/ui/general';

import { DisplayTypeValues } from '../../form-field-definition/Types';

import { useHandleValueChangeSelects } from './useHandleValueChangeSelects';
import { useSelection } from './useSelection';

interface Props {
  item: FieldDefinition;
  name: string;
  setValue: (name: string, value: Option | Option[]) => void;
  value: string;
}

export const Selection = ({ item, name, setValue, value, ...rest }: Props) => {
  const { valueType } = item;
  const { multiSelect, displayType } = valueType ?? {};

  const { selectOptions, toMembers } = useSelection({
    item,
  });

  const {
    currentValueMultiOption,
    currentValueOption,
    handleChange,
    handleChangeMulti,
  } = useHandleValueChangeSelects({ multiSelect, name, setValue, value });

  const isUserSelection = item.type === ValueTypeName.UserSelection;

  const valueSingle =
    isUserSelection && currentValueOption
      ? toMembers(currentValueOption)[0]
      : currentValueOption;

  const valueMulti =
    isUserSelection && currentValueMultiOption
      ? toMembers(currentValueMultiOption)
      : currentValueMultiOption;

  return displayType === DisplayTypeValues.buttons ? (
    <ButtonsBoolean
      handleChange={handleChange}
      handleChangeMulti={handleChangeMulti}
      options={selectOptions}
      value={multiSelect ? null : valueSingle}
      values={multiSelect ? valueMulti : null}
    />
  ) : multiSelect ? (
    <SearchableMultiSelect
      {...rest}
      name={name}
      onChange={handleChangeMulti}
      options={selectOptions}
      type={item.valueType.name}
      value={valueMulti}
    />
  ) : (
    <SearchableSelect
      {...rest}
      name={name}
      onChange={handleChange}
      options={selectOptions}
      value={valueSingle}
    />
  );
};
