import React, { useMemo, useState } from 'react';

import { ApiKey, useApiKeysIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Column,
  Main,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoApiKeys } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { ApiKeyContextProvider } from './ApiKeyContext';
import { ApiKeysActionBar } from './ApiKeysActionBar';
import { ApiKeySidebar } from './ApiKeySidebar';
import { RevokeWarningDialog } from './RevokeWarningDialog';
import { useColumns } from './useColumns';

export const ApiKeysTable = () => {
  const { searchParams } = useRouting();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<ApiKey>();

  const { data, loading, error } = useApiKeysIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: { includeExpiresAt: true },
  });

  const apiKeys = useMemo(
    () => data?.apiKeys || [],
    [data?.apiKeys]
  ) as ApiKey[];
  useSetItemsInLocalState<ApiKey>(apiKeys);

  const columns = useColumns();

  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null);

  const selectApiKey = (row: ApiKey) => {
    setSelectedApiKey(row);
    searchParams.set({
      action: 'editApiKey',
      id: row.id,
    });
  };

  return (
    <Main>
      <ApiKeyContextProvider>
        <TableContextProvider columns={columns} id="api-keys" items={items}>
          <FilterContextProvider<ApiKey>>
            <Column>
              <ApiKeysActionBar />
              <ResponseWrapper error={error} isLoading={!data && loading}>
                {items.length === 0 && itemsInitial.length === 0 ? (
                  <BigMessageNoApiKeys />
                ) : (
                  <Table<ApiKey> onRowClick={(row) => selectApiKey(row)} />
                )}
              </ResponseWrapper>
              <RevokeWarningDialog />
            </Column>
          </FilterContextProvider>
        </TableContextProvider>
        <ApiKeySidebar apiKey={selectedApiKey} />
      </ApiKeyContextProvider>
    </Main>
  );
};
