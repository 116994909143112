import { useFolderQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

export const useFetchFolder = (folderId: string, skip: boolean) => {
  const { data } = useFolderQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip,
    variables: {
      id: folderId,
    },
  });

  return data?.folder;
};
