import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  ActionProps,
  CutCopyPasteData,
  PasteType,
} from '@pro4all/shared/types';

import { getChildIds } from './helpers/getChildIds';
import { useHierarchyEditorContext } from './HierarchyEditorProvider';
import { BasePropsHierarchyItem } from './types';
import { AddData, AddType, CallBackProps } from './types';
import { useImportFromExcel } from './useImportFromExcel';

export const useItemActions = <HierarchyItem extends BasePropsHierarchyItem>({
  callbackCopy,
  callbackCut,
  callbackDelete,
  callbackPaste,
}: Pick<
  CallBackProps<HierarchyItem>,
  'callbackCopy' | 'callbackCut' | 'callbackDelete' | 'callbackPaste'
>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const {
    deleteItem,
    forceRender,
    pasteItem,
    state: { items },
  } = useHierarchyEditorContext();

  const { excelInputElement, handleButtonClick } = useImportFromExcel();

  const itemsTyped = items as HierarchyItem[]; // We have to do this because TypeScript is confused and returns items typed as BasePropsHierarchyItem[].

  const debouncedPaste = useDebouncedCallback((item: HierarchyItem) => {
    const copyPasteData = getCutCopyPasteHierarchyItemFromLs();
    const { id, pasteType } = copyPasteData;
    const sourceItem = itemsTyped.find(
      (item) => item.id === id
    ) as HierarchyItem;
    if (
      callbackPaste &&
      !callbackPaste({ pasteType, sourceItem, targetItem: item })
    )
      return;
    pasteItem({
      newParentNodeId: item.id,
      pasteType,
      sourceId: id,
    });
  }, 300);

  const {
    getLocalStorageItem: getCutCopyPasteHierarchyItemFromLs,
    setLocalStorageItem: setCutCopyPasteHierarchyItemInLs,
  } = useLocalStorage<CutCopyPasteData>({
    key: `prostream-copy-cut-paste-hierarchy-item`,
  });

  const { setLocalStorageItem: setAddHierarchyItemInLs } =
    useLocalStorage<AddData>({
      key: `prostream-add-hierarchy-item`,
    });

  const { setLocalStorageItem: setEditHierarchyItemInLs } =
    useLocalStorage<AddData>({
      key: `prostream-edit-hierarchy-item`,
    });

  const getItemActions = (item: HierarchyItem) => {
    // Paste action is disabled in case:
    // 1. There is nothing in local storage.
    // 2. The context is a cut operation and the item is equal to the item to be pasted.
    // 3. The context is a cut operation and the item is a child of the item to be pasted.
    const cutCopyPasteData =
      getCutCopyPasteHierarchyItemFromLs() as CutCopyPasteData;

    let disablePasteAction = true;
    if (cutCopyPasteData) {
      const childIds = getChildIds<HierarchyItem>({
        allItems: itemsTyped,
        id: cutCopyPasteData.id,
      });
      disablePasteAction =
        Object.getOwnPropertyNames(getCutCopyPasteHierarchyItemFromLs())
          .length === 0 ||
        (cutCopyPasteData.pasteType === PasteType.CUT &&
          (cutCopyPasteData.id === item.id || childIds.includes(item.id)));
    }

    const itemActions: ActionProps[] = [
      {
        ariaLabel: t('Copy'),
        dataTestId: 'copy-item',
        key: 'copy-item',
        label: t('Copy'),
        onClick: (event) => {
          event.stopPropagation();
          if (callbackCopy && !callbackCopy(item)) return;
          setCutCopyPasteHierarchyItemInLs({
            id: item.id,
            pasteType: PasteType.COPY,
          });
          forceRender(); // Only to re-render the items, so that the `Paste` action becomes enabled in case the local storage is populated.
        },
        startIcon: 'copyFile',
      },
      {
        ariaLabel: t('Cut'),
        dataTestId: 'cut-item',
        key: 'cut-item',
        label: t('Cut'),
        onClick: (event) => {
          event.stopPropagation();
          if (callbackCut && !callbackCut(item)) return;
          setCutCopyPasteHierarchyItemInLs({
            id: item.id,
            pasteType: PasteType.CUT,
          });
          forceRender(); // Only to re-render the items, so that the `Paste` action becomes enabled in case the local storage is populated.
        },
        startIcon: 'cut',
      },
      {
        ariaLabel: t('Paste'),
        dataTestId: 'paste-item',
        disabled: disablePasteAction,
        key: 'paste-item',
        label: t('Paste'),
        onClick: (event) => {
          event.stopPropagation();
          debouncedPaste(item);
        },
        startIcon: 'clipboard',
      },
      {
        ariaLabel: t('Edit'),
        dataTestId: 'edit-item',
        key: 'edit-item',
        label: t('Edit'),
        onClick: (event) => {
          event.stopPropagation();
          setEditHierarchyItemInLs({
            sourceId: item.id,
            sourceName: item.name,
          });
          searchParams.set({ edititem: 'true' });
        },
        startIcon: 'edit',
      },
      {
        ariaLabel: t('Insert below'),
        dataTestId: 'insert-item',
        key: 'insert-item',
        label: t('Insert below'),
        onClick: (event) => {
          event.stopPropagation();
          setAddHierarchyItemInLs({
            addType: AddType.SIBLING,
            sourceId: item.id,
            sourceName: item.name,
            sourceParentId: item.parentNodeId || null,
          });
          searchParams.set({ additem: 'true' });
        },
        startIcon: 'add',
      },
      {
        ariaLabel: t('Add subitem'),
        dataTestId: 'add-subitem',
        key: 'add-subitem',
        label: t('Add subitem'),
        onClick: (event) => {
          event.stopPropagation();
          setAddHierarchyItemInLs({
            addType: AddType.CHILD,
            sourceId: item.id,
            sourceName: item.name,
          });
          searchParams.set({ additem: 'true' });
        },
        startIcon: 'playlistAdd',
      },
      {
        ariaLabel: t('Import Excel'),
        dataTestId: 'import-excel',
        key: 'import-excel',
        label: t('Import Excel'),
        onClick: (event) => {
          event.stopPropagation();
          handleButtonClick({ item });
        },
        startIcon: 'formatShapes',
      },
      {
        ariaLabel: t('Delete'),
        dataTestId: 'delete-item',
        key: 'delete-item',
        label: t('Delete'),
        onClick: (event) => {
          event.stopPropagation();
          if (callbackDelete && !callbackDelete(item)) return;
          deleteItem(item.id);
        },
        startIcon: 'delete',
      },
    ];

    return itemActions;
  };

  return { excelInputElement, getItemActions };
};
