import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoQualityControlDrawings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Upload a document or convert one of your existing documents to create a new drawing.'
      )}
      title={t('No drawings')}
    />
  );
};
