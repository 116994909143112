import React from 'react';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

const StyledBox = styled(Box)`
  && {
    & > :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export const ButtonGroupHorizontal: React.FC = ({ children }) => (
  <StyledBox>
    {React.Children.map(children, (child: React.ReactElement) =>
      React.cloneElement(child)
    )}
  </StyledBox>
);
