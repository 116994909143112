import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoSelectionForSettingPermissions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="flow1"
      text={t('Select a user or group to assign or edit permissions.')}
      title={t('No selection')}
    />
  );
};
