import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { useMemberOptions } from '@pro4all/identity/ui';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikDate,
  FormikSearchableMultiSelect,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useGenerateLinkContext } from '../GenerateLinkContext';
import { StyledForm } from '../modal/styles';
import { PrivateLinkFormValues, ShareModalBaseProps } from '../modal/types';

import { useGeneratePrivateLinkFormConfig } from './useGeneratePrivateLinkFormConfig';
import { useSubmit } from './useSubmit';

export const GeneratePrivateLinkForm: React.FC<ShareModalBaseProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { submitPrivateLink } = useSubmit();

  const { members } = useGenerateLinkContext();

  const membersOptions = useMemberOptions(members, {
    includeNotInvited: true,
  });

  const initialValues: PrivateLinkFormValues = {
    endTime: dayjs().add(7, 'day').format('YYYY-MM-DD'),
    selectedMembers: [] as Option[],
  };

  const { getField, validationSchema } = useGeneratePrivateLinkFormConfig();
  const endTimeField = getField('endTime');
  const selectedMembersField = getField('selectedMembers');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitPrivateLink}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <StyledForm>
          <FormWrapper noPadding>
            <FormikDate
              label={endTimeField.label}
              maxDate={dayjs().add(20, 'year')}
              minDate={dayjs()}
              name={endTimeField.name}
            />
            <FormikSearchableMultiSelect
              label={selectedMembersField.label}
              name={selectedMembersField.name}
              options={membersOptions}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            marginTop="auto"
            onClose={onClose}
            pt={2}
            sticky
            submitLabel={t('Next')}
          />
        </StyledForm>
      )}
    </Formik>
  );
};
