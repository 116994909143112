import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useSaveDownloadFileNameConfigMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';

export const useOnSubmit = (templateId: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();

  const {
    setSegments,
    state: { segments, enabled },
  } = useConfigureDownloadNameContext();

  // Config downloadname.
  const segmentsUpdated = segments.map((segment, index) => ({
    id: segment.id,
    order: index,
    type: segment.type,
  }));

  const { searchParams } = useRouting();
  const [saveDownloadFileNameConfig] = useSaveDownloadFileNameConfigMutation();

  const onSubmit = async () => {
    try {
      await saveDownloadFileNameConfig({
        variables: {
          enabled,
          segments: segmentsUpdated,
          templateId,
        },
      });

      enqueueSnackbar(t('Configuration download name saved successfully'));

      setSegments(segments); // This resets the initial state.
      searchParams.delete('configureDownload');
    } catch (error) {
      showSingleError(error);
    }
  };

  return onSubmit;
};
