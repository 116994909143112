import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const Card = styled(Box)<{ $isExpired?: boolean }>`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ $isExpired }) =>
      $isExpired ? ({ theme }) => theme.palette.warning.main : 'inherit'};
    padding: ${({ theme }) => theme.spacing(2)}
      ${({ theme }) => theme.spacing(4)};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 10px;
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

export const Amount = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const AmountText = styled(Box)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Users = styled(Text)`
  && {
    display: flex;
    color: ${customColors.grey700};
  }
`;

export const GroupIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const UsersText = styled(Box)`
  && {
    color: ${customColors.grey700};
  }
`;
