import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { CommunicationService } from '@pro4all/communication/data-access';
import { AttachmentContext } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';

interface Props {
  context: AttachmentContext;
  messageId: string;
  onDownload?: () => void;
}

export function useDownloadAll({ messageId, onDownload, context }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const downloadZip = useCallback(async () => {
    const fileName = 'Prostream Attachment.zip';
    try {
      await CommunicationService.downloadAllAttachments({
        context,
        fileName,
        messageId,
        organizationId,
      });
      onDownload && onDownload();
    } catch (error) {
      enqueueSnackbar(t('Something went wrong'));
    }
  }, [context, enqueueSnackbar, messageId, onDownload, organizationId, t]);

  return downloadZip;
}
