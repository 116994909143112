import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { ShareBuild12Form } from './ShareBuild12Form';

export const ShareBuild12Modal = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const onClose = () => searchParams.delete('action');
  const editConnection = searchParams.is('action', 'shareto12build');

  return (
    <FloatingModal
      data-testid="edit-connection-modal"
      onClose={onClose}
      open={editConnection}
    >
      <FloatingModal.Header iconName="build12">
        {t('Share to 12Build')}
      </FloatingModal.Header>
      <ShareBuild12Form onClose={onClose} />
    </FloatingModal>
  );
};
