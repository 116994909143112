import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoSavedAnswers: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="simplify"
      text={t(
        'You have not created saved answers. Create one and start filling your forms and snags super quickly.'
      )}
      title={t('No saved answers')}
    />
  );
};
