import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box, MenuItem } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';

import { TextField, TextFieldProps } from '../text-field/TextField';

const StyledSelect = styled(TextField)`
  && {
    &:hover .MuiInput-root {
      border-color: inherit;
    }

    .MuiInputBase-input.MuiSelect-select {
      padding: 10px 8px 9px 8px;
      &:focus {
        background-color: transparent;
      }

      &.MuiInputBase-inputMarginDense {
        padding: 6px 8px 5px;
      }
    }
  }
`;

const StyledBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    width: calc(100% - 12px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export interface SelectProps
  extends Omit<TextFieldProps, 'onBlur' | 'onChange'> {
  onBlur?: (option: Option) => void;
  onChange: (option: Option) => void;
  options: Option[];
  updateValue?: boolean;
  value?: Option | string;
}

export const Select = forwardRef(
  (
    {
      options,
      onChange,
      onBlur,
      placeholder,
      updateValue,
      value,
      ...rest
    }: SelectProps,
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
      if (updateValue && value !== currentValue) {
        setCurrentValue(value);
      }
    }, [value, currentValue, updateValue]);

    const findOptionById = (option: string | Option) => {
      if (option !== null && typeof option === 'object') {
        return options.find(({ id }: Option) => id === option.id);
      } else {
        return options.find(({ id }: Option) => id === option);
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(findOptionById(event.target.value)?.id || '');
      onChange(findOptionById(event.target.value));
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) =>
      onBlur && onBlur(findOptionById(event.target.value));

    return (
      <StyledSelect
        {...rest}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            // getContentAnchorEl: null, // Todo: Does not exist in MUI v5
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          },
          displayEmpty: true,
          renderValue: (value: string) => {
            const option = findOptionById(value);
            return option ? (
              <StyledBox>
                {option.iconName && <StyledIcon iconName={option.iconName} />}
                {option.label}
              </StyledBox>
            ) : (
              placeholder
            );
          },
        }}
        // innerRef={ref} // Todo: Does not exist in MUI v5
        margin="normal"
        onBlur={handleBlur}
        onChange={handleChange}
        select
        value={currentValue}
      >
        {options.map((option) => (
          <MenuItem
            disabled={option.disabled}
            key={option.id}
            value={option.id}
          >
            <Box alignItems="center" display="flex">
              {option.iconName && <StyledIcon iconName={option.iconName} />}
              {option.label}
            </Box>
          </MenuItem>
        ))}
      </StyledSelect>
    );
  }
);
