import { MapElements } from '@pro4all/shared/types';

export async function getReportDrawings(drawingIds: string[]) {
  const updatedMapRecord: MapElements = {};

  const promises = drawingIds.map((id) => waitForElm(id));
  await Promise.all(promises).then(() => {
    const preloadedMaps = document.getElementById('temp-drawings-container');
    if (!preloadedMaps?.children) return updatedMapRecord;

    for (let i = 0; i < (preloadedMaps?.children?.length || 0); i++) {
      if (preloadedMaps?.children && preloadedMaps?.children.length) {
        const child = preloadedMaps.children[i];
        const drawingId = child.id.replace('temp-drawing-', '');

        updatedMapRecord[drawingId] = child.innerHTML;
      }
    }

    if (!Object.keys(updatedMapRecord).length) return null;
    return updatedMapRecord;
  });
  if (!Object.keys(updatedMapRecord).length) return null;
  return updatedMapRecord;
}

function waitForElm(drawingId: string) {
  const selector = `#temp-drawing-${drawingId}`;

  return new Promise<Element | null>((resolve) => {
    const element = document.querySelector(selector);
    if (element) {
      return resolve(element);
    }

    const observer = new MutationObserver(() => {
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
