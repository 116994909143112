import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFileSize } from '@pro4all/shared/utils';

export const FileSize: React.FC<{ bytes: number }> = ({ bytes }) => {
  const { t } = useTranslation();

  return t(getFileSize(bytes) || 'Unknown');
};
