import { FolderPermission } from '@pro4all/graphql';

import { Permission } from '../types';

import { getThisFolderPermission } from './getThisFolderPermission';

export const getCollectedPermissionsAncestors = ({
  folder,
  initialPermissionsExplicit,
  savePermissions,
}: {
  folder: Permission;
  initialPermissionsExplicit: Permission[];
  savePermissions: Permission[];
}) => {
  if (!folder.parentFolderId) return [];

  let response: FolderPermission[] = [];
  let parentFolderId = folder.parentFolderId;

  do {
    const parentFolderInitial = getThisFolderPermission(
      parentFolderId,
      initialPermissionsExplicit
    );

    const parentFolderSave = getThisFolderPermission(
      parentFolderId,
      savePermissions
    );

    const parentPermissions = parentFolderSave
      ? parentFolderSave.folderPermissions
      : parentFolderInitial.folderPermissions;

    response = [...new Set([...response, ...parentPermissions])];

    parentFolderId = parentFolderInitial.parentFolderId;
  } while (parentFolderId);

  return response;
};
