import { useTranslation } from 'react-i18next';

import { Template, ValueTypeName } from '@pro4all/graphql';

export const useGetDummyTemplates = () => {
  const { t } = useTranslation();

  const dummyTemplates: Template[] = [
    {
      id: '1',
      items: [
        {
          displayName: t('First section'),
          id: '1',
          type: ValueTypeName.Section,
          valueType: {
            subFields: [
              {
                displayName: t('Yes/No field'),
                id: '2',
                type: ValueTypeName.Bool,
              },
              {
                displayName: t('Date field'),
                id: '3',
                type: ValueTypeName.DateTime,
              },
              {
                displayName: t('Description field'),
                id: '4',
                type: ValueTypeName.Description,
              },
              {
                displayName: t('Hierarchy field'),
                id: '5',
                type: ValueTypeName.HierarchyList,
              },
              {
                displayName: t('Number field'),
                id: '6',
                type: ValueTypeName.Number,
              },
              {
                displayName: t('Photo field'),
                id: '7',
                type: ValueTypeName.Media,
              },
            ],
          },
        },
        {
          displayName: t('Second section'),
          id: '8',
          type: ValueTypeName.Section,
          valueType: {
            subFields: [
              {
                displayName: t('Selection field'),
                id: '9',
                type: ValueTypeName.Selection,
              },
              {
                displayName: t('Signature field'),
                id: '10',
                type: ValueTypeName.Signature,
              },
              {
                displayName: t('Status field'),
                id: '11',
                type: ValueTypeName.Status,
              },
              {
                displayName: t('Stopwatch field'),
                id: '12',
                type: ValueTypeName.TimeSpan,
              },
              {
                displayName: t('Text field'),
                id: '13',
                type: ValueTypeName.Text,
              },
              {
                displayName: t('User  field'),
                id: '14',
                type: ValueTypeName.UserSelection,
              },
            ],
          },
        },
      ],
      name: 'Template',
    },
  ];

  return { dummyTemplates };
};
