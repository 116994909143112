import { qcAndTaskPermissions } from '../types';

export const convertPermissions = (permissionsArray: string[]) => {
  const permissionObj = Object.keys(qcAndTaskPermissions).reduce(
    (obj, perm) => {
      const userHasPermission = permissionsArray?.find(
        (permission: string) =>
          permission ===
          qcAndTaskPermissions[perm as keyof typeof qcAndTaskPermissions]
      );
      const newPermissionObj = { ...obj };
      newPermissionObj[perm as keyof typeof qcAndTaskPermissions] =
        Boolean(userHasPermission);
      obj = newPermissionObj;
      return obj;
    },
    {} as any
  );
  return permissionObj;
};

export const setPermissionsToAll = (permissionObj: any) =>
  Object.keys(qcAndTaskPermissions).reduce((acc, key) => {
    acc[key as keyof typeof qcAndTaskPermissions] =
      qcAndTaskPermissions[key as keyof typeof qcAndTaskPermissions].endsWith(
        '.all'
      );
    return acc;
  }, permissionObj);
