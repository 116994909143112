import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { Option } from '../types';

import * as Styled from './Buttons.styles';

type Props = {
  error?: boolean;
  handleChange?: (option: Option) => void;
  handleChangeMulti?: (
    event: React.ChangeEvent | null,
    value: Option[]
  ) => void;
  noWrap?: boolean;
  options: Option[];
  tooltipTitle?: string;
  value?: Option;
  values?: Option[];
  warning?: boolean;
};

export const ButtonsBoolean = ({
  error = false,
  handleChange,
  handleChangeMulti,
  noWrap = false,
  options,
  tooltipTitle = '',
  value,
  values,
  warning = false,
}: Props) => {
  const isOptionSelected = (option: Option) => {
    if (value) return option.id === value?.id;
    else if (values) {
      return values.some((val) => val.id === option.id);
    }
  };
  const clickButton = (option: Option, selected: boolean) => {
    if (handleChangeMulti && values) {
      const optionInExistingValues = values.some((val) => val.id === option.id);
      const payload = optionInExistingValues
        ? values.filter((val) => val.id !== option.id)
        : [...values, option];
      handleChangeMulti(null, payload);
    } else if (selected) handleChange && handleChange(null);
    else handleChange && handleChange(option);
  };
  const buttons = (
    <Styled.ButtonsContainer noWrap={noWrap}>
      {options.map((option: Option) => {
        const selected = isOptionSelected(option);
        return (
          <Styled.Button
            $error={error}
            $selected={selected}
            $warning={warning}
            key={option.id}
            onClick={() => clickButton(option, Boolean(selected))}
          >
            {selected && <Icon iconName="check" />}
            {option.label}
          </Styled.Button>
        );
      })}
    </Styled.ButtonsContainer>
  );

  return tooltipTitle ? (
    <Box sx={{ flexGrow: '1' }}>
      <Tooltip placement="bottom" title={tooltipTitle}>
        {buttons}
      </Tooltip>
    </Box>
  ) : (
    buttons
  );
};
