import React from 'react';
import { useTranslation } from 'react-i18next';

import { gqlType, ShareType } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/ui/buttons';

import { useSubmit } from '../forms/useSubmit';
import { useGenerateLinkContext } from '../GenerateLinkContext';

export const SendAndCloseButton: React.FC = () => {
  const { t } = useTranslation();
  const { submitEmailsToMembers } = useSubmit();

  const {
    link,
    closeModal,
    members,
    recipientIds,
    recipientGroups,
    selectedShareMethod,
  } = useGenerateLinkContext();

  const sendAndClose = async () => {
    const users = members.filter(gqlType('User')) || [];
    const groups = members.filter(gqlType('Group')) || [];
    const userIds =
      selectedShareMethod === ShareType.Private
        ? recipientIds
        : users.map((recipient) => recipient.id);

    const groupIds =
      selectedShareMethod === ShareType.Private
        ? recipientGroups
        : groups.map((recipient) => recipient.id);

    await submitEmailsToMembers({
      groupIds,
      userIds,
    });

    closeModal();
  };

  if (link) {
    return (
      <Button
        color="primary"
        data-testid="send-and-close"
        onClick={sendAndClose}
        startIcon="mail"
        variant="contained"
      >
        {t('Send and close')}
      </Button>
    );
  } else return null;
};
