export const OFFICE_ICON_BY_EXTENSION: { [key: string]: string } = {
  csv: 'excel',
  doc: 'word',
  docm: 'word',
  docx: 'word',
  dot: 'word',
  dotm: 'word',
  dotx: 'word',
  odp: 'powerPoint',
  ods: 'excel',
  odt: 'word',
  pot: 'powerPoint',
  potm: 'powerPoint',
  potx: 'powerPoint',
  pps: 'powerPoint',
  ppsm: 'powerPoint',
  ppsx: 'powerPoint',
  ppt: 'powerPoint',
  pptm: 'powerPoint',
  pptx: 'powerPoint',
  rtf: 'word',
  wopi: 'word',
  wopitest: 'word',
  wopitestx: 'word',
  xls: 'excel',
  xlsb: 'excel',
  xlsm: 'excel',
  xlsx: 'excel',
};

export const VALID_OFFICE_EXTENSIONS = Object.keys(OFFICE_ICON_BY_EXTENSION);
