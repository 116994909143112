import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Organization,
  OrganizationDocument,
  OrganizationSettings,
  useUpdateOrganizationSettingsMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';

import { FormFields } from './types';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organization, setOrganization } = useOrganizationContext();
  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  return async (values: FormFields) => {
    try {
      const orgSettings: OrganizationSettings = {
        ...values,
        approve_action_label: values.approve_action_label ?? '',
        finalize_action_label: values.finalize_action_label ?? '',
        reject_action_label: values.reject_action_label ?? '',
      };

      if (organization) {
        const orgToUpdate: Organization = {
          ...organization,
          settings: {
            ...orgSettings,
          },
        };
        await updateOrganizationSettings({
          variables: { id: organization.id || '', settings: values },
        });

        // In here you have to update the Apollo client cache.
        client.writeQuery({
          data: {
            organization: { ...orgToUpdate },
          },
          query: OrganizationDocument,
          variables: {
            id: organization.id,
          },
        });

        setOrganization(orgToUpdate);
        enqueueSnackbar('Organization settings have been updated.');
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
      console.warn(e);
    }
  };
};
