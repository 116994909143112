import React from 'react';
import { useField, useFormikContext } from 'formik';

import { TextField, TextFieldProps } from '../../inputs';

interface Props extends TextFieldProps {
  autoFocus?: boolean;
  fieldsToSync?: string[];
  inputRef?: React.Ref<HTMLInputElement>;
  label?: string;
  name: string | undefined;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  placeholder?: string;
  showErrorMessage?: boolean;
  type?: string;
}

export const FormikInput: React.FC<Props> = ({
  autoFocus = false,
  inputRef,
  label,
  margin = 'normal',
  name = '',
  fieldsToSync = [],
  placeholder,
  type,
  onKeyDown,
  showErrorMessage = true,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name });
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true);
    helpers.setValue(event.target.value);
    fieldsToSync.forEach((field) => {
      setFieldValue(field, event.target.value);
      setFieldTouched(field, true, true);
    });
  };

  return (
    <TextField
      {...field}
      autoFocus={autoFocus}
      error={hasError}
      fullWidth
      helperText={showErrorMessage && hasError && meta.error}
      inputRef={inputRef}
      label={label}
      margin={margin}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      type={type}
      {...rest}
    />
  );
};
