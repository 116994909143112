import { useRef } from 'react';
import { createEditor } from 'slate';
import { Editor } from 'slate/dist/interfaces/editor';
import { ReactEditor, withReact } from 'slate-react';

import { withImages, withMentions } from '@pro4all/communication/ui/general';

export const useEditor = () => {
  const editorRef = useRef<Editor>();
  if (!editorRef.current) {
    editorRef.current = withMentions(
      withImages(withReact(createEditor() as ReactEditor))
    );
  }
  const editor = editorRef.current;

  if (!editor.selection && editor) {
    editor.selection = {
      anchor: { offset: 0, path: [0, 0] },
      focus: { offset: 0, path: [0, 0] },
    };
  }

  return editor;
};
