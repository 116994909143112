import isEqual from 'react-fast-compare';
import { MutationFunction } from '@apollo/client';

import {
  Document,
  QualityControlInstance,
  ResourceType,
  SetLinkMutation,
  SetLinkMutationVariables,
  SetTaskTemplateLinksMutation,
  SetTaskTemplateLinksMutationVariables,
  SetTaskVisualContextLinksMutation,
  SetTaskVisualContextLinksMutationVariables,
  TaskType,
  Template,
} from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';

export const setQcLinks = ({
  formTemplates,
  formTemplatesInitial,
  snagTemplates,
  snagTemplatesInitial,
  setTaskTemplateLinks,
  taskId,
  visualContexts,
  visualContextsInitial,
  setTaskVisualContextLinks,
  duplicated,
}: {
  duplicated: boolean;
  formTemplates: Template[];
  formTemplatesInitial: Template[];
  setTaskTemplateLinks: MutationFunction<
    SetTaskTemplateLinksMutation,
    SetTaskTemplateLinksMutationVariables
  >;
  setTaskVisualContextLinks: MutationFunction<
    SetTaskVisualContextLinksMutation,
    SetTaskVisualContextLinksMutationVariables
  >;
  snagTemplates: Template[];
  snagTemplatesInitial: Template[];
  taskId: string;
  visualContexts: Option[];
  visualContextsInitial: Option[];
}) => {
  if (
    duplicated ||
    !isEqual(formTemplates, formTemplatesInitial) ||
    !isEqual(snagTemplates, snagTemplatesInitial)
  ) {
    const templateIds = ([...formTemplates, ...snagTemplates] || []).map(
      ({ id }) => id
    );

    setTaskTemplateLinks({
      variables: {
        taskId,
        templateIds,
      },
    });
  }

  if (duplicated || !isEqual(visualContexts, visualContextsInitial)) {
    const visualContextIds = visualContexts.map(({ id }) => id);

    setTaskVisualContextLinks({
      variables: {
        taskId,
        visualContextIds,
      },
    });
  }
};

export const setDocumentAndResolveTaskLinks = async ({
  type,
  taskId,
  linkInstances,
  setLinkMutation,
}: {
  linkInstances: (QualityControlInstance | Document)[];
  setLinkMutation: MutationFunction<SetLinkMutation, SetLinkMutationVariables>;
  taskId: string;
  type: TaskType;
}) => {
  const resourceBType =
    type === TaskType.Document ? ResourceType.Document : ResourceType.Instance;

  const variables = {
    resourceAId: taskId,
    resourceAType: ResourceType.Task,
    resourceBLinkIds: linkInstances.map(
      (linkInstance: QualityControlInstance | Document) => linkInstance.id
    ),
    resourceBType,
  };

  await setLinkMutation({
    variables,
  });
};
