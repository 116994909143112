import { LinkedInstance } from '@pro4all/workflow/ui/utils';

import { CardConfig } from './types';

export const useDetermineMenuItems = () => {
  const determineMenuItems = (
    card: LinkedInstance,
    cardLink: string, // TODO: Refactor to Enum (deliverables, formTemplates, linkedSnagInstances, snagTemplates, visualContexts, linkedSnagDrawings)
    cardConfig?: CardConfig
  ) => {
    if (['snagTemplates', 'deliverables'].includes(cardLink)) return [];
    else
      return [
        {
          onClick: () => cardConfig?.iconClick?.(card?.id),
          startIcon: cardConfig?.startIcon ?? 'arrowForward',
        },
      ];
  };

  return { determineMenuItems };
};
