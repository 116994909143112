import React, { PropsWithChildren, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { Condition } from '@pro4all/metadata/ui/utils';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  ActionProps,
  CutCopyPasteSection,
  PasteType,
} from '@pro4all/shared/types';

import { useTemplateMutationContext } from './useTemplateMutationContext';

type InsertFieldType = {
  fieldToInsert: FieldDefinition;
  move: boolean;
  nextField: FieldDefinition;
  parentSection?: FieldDefinition;
};

type PasteSectionProps = {
  parentSection?: FieldDefinition;
  targetSectionOrField: FieldDefinition;
};

// Field context
type FieldContextValue = {
  appendField?: (fieldDefinition: FieldDefinition) => void;
  editField?: (fieldDefinition: FieldDefinition) => void;
  editFields?: (fieldDefinitions: FieldDefinition[]) => void;
  fieldDefinitions: FieldDefinition[];
  fieldDefinitionsInitial: FieldDefinition[];
  forceRender?: () => void;
  insertField?: ({
    fieldToInsert,
    nextField,
    move,
    parentSection,
  }: InsertFieldType) => void;
  pasteSection?: (pasteSectionProps: PasteSectionProps) => void;
  removeField?: (fieldDefinition: FieldDefinition) => void;
  reusableFields: FieldDefinition[];
  reusableFieldsStandard: FieldDefinition[];
};

const FieldContext = React.createContext({
  fieldDefinitions: [],
  fieldDefinitionsInitial: [],
  reusableFields: [],
  reusableFieldsStandard: [],
});

export function useFieldContext() {
  return useContext<FieldContextValue>(FieldContext);
}

// Section sidebar context
type FieldSidebarContextValue = {
  field: FieldDefinition;
  fieldSidebarActions: ActionProps[];
  isOpenFieldSidebar: boolean;
  parentFieldSidebar: FieldDefinition;
  setField?: (value: FieldDefinition) => void;
  setIsOpenFieldSidebar?: (value: boolean) => void;
  setParentFieldSidebar?: (value: FieldDefinition) => void;
};

type SectionSidebarContextValue = {
  isOpenSectionSidebar: boolean;
  parentSectionSidebar: FieldDefinition;
  section: FieldDefinition;
  sectionSidebarActions: ActionProps[];
  setIsOpenSectionSidebar?: (value: boolean) => void;
  setParentSectionSidebar?: (value: FieldDefinition) => void;
  setSection?: (value: FieldDefinition) => void;
};

type ConditionsSidebarContextValue = {
  conditions: Condition[];
  setConditions?: (value: Condition[]) => void;
};

const FieldSidebarContext = React.createContext({
  field: null,
  fieldSidebarActions: [],
  isOpenFieldSidebar: false,
  parentFieldSidebar: null,
});

const SectionSidebarContext = React.createContext({
  isOpenSectionSidebar: false,
  parentSectionSidebar: null,
  section: null,
  sectionSidebarActions: [],
});

const ConditionSettingsSidebar = React.createContext({
  conditions: [],
});

export function useFieldSidebarContext() {
  return useContext<FieldSidebarContextValue>(FieldSidebarContext);
}

export function useSectionSidebarContext() {
  return useContext<SectionSidebarContextValue>(SectionSidebarContext);
}

export function useConditionsSidebarContext() {
  return useContext<ConditionsSidebarContextValue>(ConditionSettingsSidebar);
}

export function TemplateMutationContextProvider({
  children,
  fieldDefinitionsInitial = [],
  reusableFields = [],
  reusableFieldsStandard = [],
}: PropsWithChildren<{
  fieldDefinitionsInitial: FieldDefinition[];
  reusableFields: FieldDefinition[];
  reusableFieldsStandard: FieldDefinition[];
}>) {
  const { t } = useTranslation();
  const {
    appendFieldInSection,
    canInsertField,
    editFieldInSection,
    insertFieldAfterDropOrPaste,
    removeFieldsFromSection,
  } = useTemplateMutationContext();

  const { getLocalStorageItem: getCutCopyPasteTemplateSectionFromLs } =
    useLocalStorage<CutCopyPasteSection>({
      key: StorageKeys.COPY_CUT_PASTE_TEMPLATE_SECTION,
    });

  const [fieldDefinitions, setFieldDefinitions] = useState<FieldDefinition[]>(
    fieldDefinitionsInitial
  );

  const [renderToggle, setRenderToggle] = useState(false);

  const appendField = (fieldDefinition: FieldDefinition) => {
    if (fieldDefinition.type === ValueTypeName.Section) {
      // Append section.
      setFieldDefinitions(
        parentSectionSidebar
          ? appendFieldInSection({
              fieldDefinition,
              fields: fieldDefinitions,
              parentSection: parentSectionSidebar,
            })
          : [...fieldDefinitions, fieldDefinition]
      );
    } else {
      // Append question.
      if (canInsertField({ droppedField: fieldDefinition, fieldDefinitions })) {
        const {
          description,
          displayDescription,
          displayName,
          fieldDefinitionId,
          linksAllowed,
          name,
          required,
          indicative,
          type,
          valueType,
        } = fieldDefinition;

        const defaultFieldsToAdd = {
          description,
          displayDescription,
          displayName,
          id: uuid(),
          indicative,
          linksAllowed,
          name,
          required,
          type,
        };

        const fieldToAdd = fieldDefinitionId
          ? {
              ...defaultFieldsToAdd,
              fieldDefinitionId,
            }
          : {
              ...defaultFieldsToAdd,
              valueType,
            };

        setFieldDefinitions(
          parentFieldSidebar
            ? appendFieldInSection({
                fieldDefinition: fieldToAdd,
                fields: fieldDefinitions,
                parentSection: parentFieldSidebar,
              })
            : [...fieldDefinitions, fieldToAdd]
        );
      }
    }
  };

  const insertField = ({
    fieldToInsert,
    move,
    nextField,
    parentSection,
  }: InsertFieldType) => {
    if (
      canInsertField({
        droppedField: fieldToInsert,
        fieldDefinitions,
        move,
        parentSection,
      })
    ) {
      setFieldDefinitions(
        insertFieldAfterDropOrPaste({
          dropSection: parentSection,
          fieldToInsert,
          fields: fieldDefinitions,
          move,
          nextField,
        })
      );
    }
  };

  const editField = (fieldDefinition: FieldDefinition) => {
    setFieldDefinitions(
      fieldDefinition.parentSectionId
        ? editFieldInSection({ fieldDefinition, fields: fieldDefinitions })
        : fieldDefinitions.map((field) =>
            field.id === fieldDefinition.id ? fieldDefinition : field
          )
    );
  };

  const editFields = (fieldDefinitions: FieldDefinition[]) => {
    setFieldDefinitions(fieldDefinitions);
  };

  const forceRender = () => {
    setRenderToggle(!renderToggle);
  };

  const removeField = (fieldDefinition: FieldDefinition) => {
    setFieldDefinitions(
      fieldDefinition.parentSectionId
        ? removeFieldsFromSection({
            fieldDefinition,
            fields: fieldDefinitions,
          })
        : fieldDefinitions.filter((field) => field.id !== fieldDefinition.id)
    );
  };

  const updateSectionAndFieldIds = (item: FieldDefinition) => {
    // item can be a field or a section.
    // Create a new id for the item itself.
    const newItem = { ...item, id: uuid() };

    // Check if the item is a section that does contain subitems.
    if (newItem.valueType?.subFields?.length) {
      newItem.valueType.subFields = newItem.valueType.subFields.map(
        (subField) =>
          // Call the function recursively for each subitem.
          updateSectionAndFieldIds(subField)
      );
    }

    return newItem;
  };

  const pasteSection = ({
    parentSection, // In case this prop has a value, user pastes on a field. Otherwise, user pastes on a section.
    targetSectionOrField, // In case this prop has no value we are pasting in the root.
  }: PasteSectionProps) => {
    const { pasteType, sourceSection } =
      getCutCopyPasteTemplateSectionFromLs() || {};

    // When copying a section we have to calculate new ids for all sections and fields within that section.
    const sourceSectionUpdated =
      pasteType === PasteType.COPY
        ? updateSectionAndFieldIds(sourceSection)
        : sourceSection;

    insertField({
      fieldToInsert: sourceSectionUpdated,
      move: pasteType === PasteType.COPY ? false : true,
      nextField: parentSection ? targetSectionOrField : null,
      parentSection: parentSection || targetSectionOrField,
    });
  };

  const fieldSidebarActions: ActionProps[] = [
    {
      ariaLabel: 'Delete field',
      dataTestId: 'delete-field',
      disabled: false,
      key: 'delete-field',
      label: t('Delete'),
      onClick: () => {
        setIsOpenFieldSidebar(false);
        removeField(field);
      },
      startIcon: 'delete',
    },
  ];

  const sectionSidebarActions: ActionProps[] = [
    {
      ariaLabel: 'Delete section',
      dataTestId: 'delete-section',
      disabled: false,
      key: 'delete-section',
      label: t('Delete'),
      onClick: () => {
        setIsOpenSectionSidebar(false);
        removeField(section);
      },
      startIcon: 'delete',
    },
  ];

  const [parentFieldSidebar, setParentFieldSidebar] =
    useState<FieldDefinition>(null);
  const [parentSectionSidebar, setParentSectionSidebar] =
    useState<FieldDefinition>(null);
  const [field, setField] = useState<FieldDefinition>(null);
  const [section, setSection] = useState<FieldDefinition>(null);
  const [isOpenFieldSidebar, setIsOpenFieldSidebar] = useState(false);
  const [isOpenSectionSidebar, setIsOpenSectionSidebar] = useState(false);
  const [conditions, setConditions] = useState<Condition[]>([]); //TODO

  const fieldContextValue = {
    appendField,
    editField,
    editFields,
    fieldDefinitions,
    fieldDefinitionsInitial,
    forceRender,
    insertField,
    pasteSection,
    removeField,
    reusableFields,
    reusableFieldsStandard,
  };

  const sectionSidebarContextValue = {
    isOpenSectionSidebar,
    parentSectionSidebar,
    section,
    sectionSidebarActions,
    setIsOpenSectionSidebar,
    setParentSectionSidebar,
    setSection,
  };

  const conditionsSidebarContextValue = {
    conditions,
    setConditions,
  };

  const fieldSidebarContextValue = {
    field,
    fieldSidebarActions,
    isOpenFieldSidebar,
    parentFieldSidebar,
    setField,
    setIsOpenFieldSidebar,
    setParentFieldSidebar,
  };

  return (
    <FieldContext.Provider value={fieldContextValue}>
      <FieldSidebarContext.Provider value={fieldSidebarContextValue}>
        <SectionSidebarContext.Provider value={sectionSidebarContextValue}>
          <ConditionSettingsSidebar.Provider
            value={conditionsSidebarContextValue}
          >
            {children}
          </ConditionSettingsSidebar.Provider>
        </SectionSidebarContext.Provider>
      </FieldSidebarContext.Provider>
    </FieldContext.Provider>
  );
}
