import { useTranslation } from 'react-i18next';

import {
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';

export const usePredefinedFilters = () => {
  const { t } = useTranslation();

  const getActiveFilterValues = () => [t('Active')];
  const getInctiveFilterValues = () => [
    t('Deactivated'),
    t('Invitation expired'),
    t('Invitation not sent'),
    t('Invitation sent'),
  ];
  const getAdministratorFilterValues = () => [t('Administrator')];
  const getUserFilterValues = () => [t('User')];

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getActiveFilterValues(),
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Active'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getInctiveFilterValues(),
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Not active'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getAdministratorFilterValues(),
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'roles',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Administrators'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: getUserFilterValues(),
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'roles',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Users'),
    },
  ];

  return predefinedFiltes;
};
