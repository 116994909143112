import styled from 'styled-components';

import { Box, Link, Typography } from '@pro4all/shared/mui-wrappers';

export const Icon = styled.div`
  margin-bottom: 20px;
  max-width: 200px;
`;

export const RedirectLink = styled(Link)`
  color: inherit;
  cursor: pointer;
`;

export const Paragraph = styled(Typography)`
  max-width: 455px;
`;

export const Root = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
`;

export const DialogBox = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
`;
