import { v4 as uuid } from 'uuid';

import { BasePropsHierarchyItem, ItemProps } from '../types';

export function duplicateChildItems<
  HierarchyItem extends BasePropsHierarchyItem
>({
  childItemsToBeCopied,
  newParentNodeId,
  parentNodeId,
}: Pick<
  ItemProps<HierarchyItem>,
  'childItemsToBeCopied' | 'newParentNodeId' | 'parentNodeId'
>) {
  // This method makes an exact copy of the child items object array (childItemsToBeCopied).

  let updatedItems = [] as HierarchyItem[];

  const childItemsDirect = childItemsToBeCopied.filter(
    (item) => item.parentNodeId === parentNodeId
  );

  let previousNewId = '';

  childItemsDirect.forEach((item, index) => {
    const newId = uuid();

    // Add the new child item to the response array.
    updatedItems.push({
      ...item,
      id: newId,
      parentNodeId: newParentNodeId,
      previousNodeId: index === 0 ? null : previousNewId,
    });

    // Call the recursive function to also add deeper nested child items.
    updatedItems = [
      ...updatedItems,
      ...duplicateChildItems<HierarchyItem>({
        childItemsToBeCopied,
        newParentNodeId: newId,
        parentNodeId: item.id,
      }),
    ];

    previousNewId = newId;
  });

  return updatedItems;
}
