import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  gqlType,
  Member,
  ProjectMembersIncludeDocument,
  useDeleteMembersFromProjectMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/general';

import { projectMembersIncludeProps } from './getIncludeProps';

export const useActions = ({ contextMenuRow }: { contextMenuRow?: Member }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params, searchParams } = useRouting();
  const { projectId } = params;
  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrgAndProject = !hasAppPermission('ProjectUpdate');
  const [deleteMembersFromProject] = useDeleteMembersFromProjectMutation();
  const { checkedRows } = useTableCheck<Member>();
  const selection = contextMenuRow ? [contextMenuRow] : checkedRows;
  const { userOrganizationName } = useOrganizationContext();
  const { isExternalUserInProject } = useProjectContext();

  const {
    state: { items },
  } = useOptimisticResponseContext<Member>();

  const removeUsers = async () => {
    try {
      await deleteMembersFromProject({
        variables: {
          members: selection.map((member) => ({
            id: member.id,
            type: gqlType('User')(member) ? 0 : 1,
          })),
          projectId: projectId,
        },
      });

      const idsDeletedMembers = selection.map((member) => member.id);
      const itemsMinusDeleted = items.filter(
        (item) => !idsDeletedMembers.includes(item.id)
      );
      client.writeQuery({
        data: {
          projectMembers: itemsMinusDeleted,
        },
        query: ProjectMembersIncludeDocument,
        variables: {
          ...projectMembersIncludeProps,
          projectId,
        },
      });

      enqueueSnackbar(t('Selected members deleted from the project'));
    } catch (e) {
      const genericMessage = `${t('Something went wrong')}. ${t(
        'Please try again'
      )}.`;
      const unauthorizedMessage = t('You are not allowed to do this');
      const message =
        e.message === 'Missing required permission'
          ? unauthorizedMessage
          : genericMessage;
      enqueueSnackbar(message);
    }
  };

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('Add members'),
      dataTestId: 'add-members-to-project',
      disabled: isExternalUserInProject || userCannotUpdateOrgAndProject,
      key: 'add-members-to-project',
      label: t('Invite {{- myOrganization}} colleagues', {
        myOrganization: userOrganizationName,
      }),
      onClick: () => searchParams.set('action', 'addMembers'),
      startIcon: 'personAdd',
    },
    {
      ariaLabel: t('Invite external'),
      dataTestId: 'add-externals-to-project',
      disabled: userCannotUpdateOrgAndProject,
      key: 'add-externals-to-project',
      label: t('Invite external'),
      onClick: () => searchParams.set('action', 'inviteExternals'),
      startIcon: 'mail',
    },
  ];

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Edit roles'),
      dataTestId: 'edit-project-user-role',
      disabled: userCannotUpdateOrgAndProject,
      key: 'edit-project-user-role',
      label: t('Edit roles'),
      onClick: () => searchParams.set('action', 'editRoles'),
      startIcon: 'verifiedUser',
    },
    {
      ariaLabel: t('Remove from project'),
      dataTestId: 'delete-user-from-project',
      disabled: userCannotUpdateOrgAndProject,
      key: 'delete-user-from-project',
      label: t('Remove from project'),
      onClick: removeUsers,
      startIcon: 'delete',
    },
  ];

  return {
    altActions,
    mainActions,
  };
};
