import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateDocumentOrVersion } from '@pro4all/documents/ui/utils';
import {
  DocumentKeyword,
  Keyword,
  useMutateDocumentKeywordsMutation,
} from '@pro4all/graphql';
import { DocumentAndVersionType } from '@pro4all/shared/types';

import { useDocumentKeywordsContext } from './DocumentKeywordsProvider';
import { InitialValues } from './types';

export const useOnSubmitDocumentKeywords = ({
  closeEditMode,
  document,
  version,
  versionId,
}: {
  closeEditMode: () => void;
  versionId: string;
} & DocumentAndVersionType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { keywordsForReset },
  } = useDocumentKeywordsContext();

  const [mutateDocumentKeywords] = useMutateDocumentKeywordsMutation();
  const updateCachedDocumentOrVersion = useUpdateDocumentOrVersion();

  const onSubmit = async (values: InitialValues) => {
    const keywordMutations: DocumentKeyword[] = [];
    const keywordUpdateClientCache: Keyword[] = [];
    const keywordsDeleted: string[] = [];

    // Map through all previous keywords.
    keywordsForReset.forEach((keyword) => {
      // Find the keyword in the form values.
      const keywordInForm = values.items.find((item) => item.id === keyword.id);

      // If the keyword is not found in the form values, it has been deleted.
      if (!keywordInForm) {
        keywordMutations.push({
          newText: '',
          oldText: keyword.oldText,
        });
        keywordsDeleted.push(keyword.id);
        return;
      }

      // If the keyword is found in the form values, check if it has been edited.
      if (
        keywordInForm.text !== keyword.text ||
        keywordInForm.score !== keyword.score.toString()
      ) {
        keywordMutations.push({
          newScore: parseInt(keywordInForm.score, 10),
          newText: keywordInForm.text,
          oldText: keyword.oldText,
        });

        keywordsDeleted.push(keyword.id); // We delete the old item and add the updated item.

        keywordUpdateClientCache.push({
          score: parseInt(keywordInForm.score, 10),
          text: keywordInForm.text,
        });
      }
    });

    if (keywordMutations.length) {
      try {
        await mutateDocumentKeywords({
          variables: { keywords: keywordMutations, versionId },
        });
        const newKeywords = [
          ...keywordsForReset
            .filter((keyword) => !keywordsDeleted.includes(keyword.id))
            .map(({ score, text }) => ({
              __typename: 'Keyword',
              score,
              text,
            })),
          ...keywordUpdateClientCache,
        ] as Keyword[];

        updateCachedDocumentOrVersion({
          document,
          fieldToUpdate: 'keywords',
          value: newKeywords,
          version,
        });
      } catch (error) {
        enqueueSnackbar(t('Could not change keywords, please try again.'));
      }
    }

    closeEditMode();
  };

  return onSubmit;
};
