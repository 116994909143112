import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DocumentEventType,
  DocumentHistoryEvent,
  Maybe,
  QcHistoryEvent,
  QcInstanceEventType,
} from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { SubSection } from '@pro4all/shared/ui/general';
import { Section } from '@pro4all/shared/ui/section';

import { UserNameAndDate } from '../../../documents/ui/history/src/UserNameAndDate';

import { EventPropertyList } from './EventPropertyList';

interface EventLogProps {
  events?: Maybe<Maybe<DocumentHistoryEvent | QcHistoryEvent>[]> | null;
  groupName: string;
  open?: boolean;
}

export const EventLog: React.FC<EventLogProps> = ({
  open = true,
  events,
  groupName,
}) => {
  const { t } = useTranslation();
  const getActionNamingMapping = useActionNamingMapping();

  if (!events?.length) return null;

  const findShareType = (event: DocumentHistoryEvent, type: string): boolean =>
    event.properties
      ? event.properties.some((element) => element.name === type)
      : false;

  const handleEventTitle = (
    event: DocumentHistoryEvent | QcHistoryEvent | null
  ) => {
    // Safely return if event is null
    if (!event) return '';
    if (event.name === DocumentEventType.HyperlinkCreatedEvent) {
      if (findShareType(event, 'PublicShare')) {
        return t('Shared as Public link');
      } else if (findShareType(event, 'PrivateShare')) {
        return t('Shared as Private link');
      } else if (findShareType(event, 'ProjectScopeShare')) {
        return t('Shared to all Project members');
      }
    }
    if (
      event.name === DocumentEventType.AnswersAddedEvent ||
      event.name === DocumentEventType.AnswerAddedEvent ||
      event.name === DocumentEventType.AnswerUpdatedEvent ||
      event.name === QcInstanceEventType.AnswerAddedEvent ||
      event.name === QcInstanceEventType.AnswerUpdatedEvent
    ) {
      if (event.__typename === 'QcHistoryEvent') {
        return t('Answers updated');
      } else if (event.__typename === 'DocumentHistoryEvent') {
        return t('Metadata updated');
      }
    }

    const eventName = event?.name || '';
    if (validateNaming(eventName)) {
      return t(`Api.eventNames.${eventName}{{name}}`, {
        defaultValue: eventName,
        name: validateNaming(eventName),
      });
    }
    return t(`Api.eventNames.${eventName}`, {
      defaultValue: eventName,
    });
  };

  const validateNaming = (eventName: string) => {
    const value = getActionNamingMapping(eventName);
    return value !== eventName ? value : false;
  };

  return (
    <Section defaultOpen={open} title={t(groupName)}>
      {events?.map((event, key: number) => {
        // Check if there is any content to render. If not, hide collapse UI
        const hasContent = Boolean(
          (
            event?.properties?.map(
              (property) =>
                Boolean(property?.addedResolvedValues?.length) ||
                Boolean(property?.deletedResolvedValues?.length)
            ) || [false]
          ).filter(Boolean).length
        );

        return (
          <SubSection
            caption={
              <UserNameAndDate
                changeDate={event?.changeDate}
                userName={event?.user ? event.user.displayName : t('Anonymous')}
              />
            }
            hasContent={hasContent}
            info={
              'versionNumber' in event && event.versionNumber
                ? `${t('Version')} ${event.versionNumber}`
                : null
            }
            key={key}
            title={handleEventTitle(event)}
          >
            <EventPropertyList properties={event?.properties || undefined} />
          </SubSection>
        );
      })}
    </Section>
  );
};
