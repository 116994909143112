import React from 'react';
import { ApolloError } from '@apollo/client';

import { Main } from '@pro4all/shared/ui/general';
import {
  BigMessageLinkExpired,
  BigMessageNoAccess,
} from '@pro4all/shared/ui/messages';

import { useShareLinkParams } from './useShareLinkParams';

export const ErrorMessage: React.FC<{ error: ApolloError }> = ({ error }) => {
  const { isRevoked } = useShareLinkParams();

  const notAuthorizedError = error?.message?.includes('401');
  const forbiddenError = error?.message?.includes('403');
  const invalidLinkError = error?.message?.includes('404');

  if (
    !forbiddenError &&
    !notAuthorizedError &&
    !invalidLinkError &&
    !isRevoked
  ) {
    return null;
  }

  return (
    <Main>
      {(forbiddenError || notAuthorizedError) && <BigMessageNoAccess />}
      {(invalidLinkError || isRevoked) && <BigMessageLinkExpired />}
    </Main>
  );
};
