import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DndTypes } from '@pro4all/shared/config';
import { LabelCard } from '@pro4all/shared/ui/label-card';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import { Segment } from './configureDownloadNameReducer';
import * as Styled from './Styles';
import { useGetLabelAndIcon } from './useGetLabelAndIcon';

export const ExampleCard = ({ segment }: { segment: Segment }) => {
  const { removeSegment } = useConfigureDownloadNameContext();
  const getLabelAndIcon = useGetLabelAndIcon();
  const { label, startIcon } = getLabelAndIcon(segment);
  const { insertSegment } = useConfigureDownloadNameContext();

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: segment,
    type: DndTypes.INCLUDED_SEGMENT,
  });

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [
      DndTypes.NOT_INCLUDED_CHARACTER,
      DndTypes.NOT_INCLUDED_FIELD,
      DndTypes.INCLUDED_SEGMENT,
    ],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: (segmentDropped: Segment, monitor) => {
      if (!monitor.didDrop()) {
        const { deleteId, id, type } = segmentDropped;
        insertSegment({
          droppedOnId: segment.deleteId,
          segment: {
            deleteId,
            id,
            type,
          },
        });
      }
    },
  });

  if (isDragging) return null;

  return label
    ? drag(
        drop(
          <div>
            <Styled.ExampleCardContainer>
              {hoversOverCurrent && <Styled.DropZone />}
              <LabelCard
                colorBackground="white"
                colorBorder="grey700"
                colorFont="grey700"
                label={label}
                onClick={() => removeSegment(segment.deleteId)}
                onDelete={() => removeSegment(segment.deleteId)}
                startIcon={startIcon}
              />
            </Styled.ExampleCardContainer>
          </div>
        )
      )
    : null;
};
