import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { getMultiValue } from '@pro4all/shared/qc-meta';
import { InstanceValues } from '@pro4all/shared/types';

export const useInitialValues = () => {
  const getInitialValues = (items: FieldDefinition[]): InstanceValues => {
    const reduceFieldDefinitionsToInstanceValues = (
      values: InstanceValues,
      item: FieldDefinition
    ): InstanceValues => {
      if (item.type === ValueTypeName.Section) {
        return (item?.valueType?.subFields ?? []).reduce(
          reduceFieldDefinitionsToInstanceValues,
          values
        );
      } else {
        switch (item.type) {
          case ValueTypeName.Bool:
            values[item.id] = item && item.value ? item?.value : ''; // So that when switching from View to Edit mode `intialValues` is initially equal to `values`.
            break;
          case ValueTypeName.UserSelection:
          case ValueTypeName.Selection: {
            if (item.valueType.multiSelect) {
              if (item.value) {
                const multiValue = getMultiValue(item.value);
                values[item.id] = multiValue.map((value) => ({
                  id: value,
                  label: value,
                }));
              } else {
                values[item.id] = []; // So that when switching from View to Edit mode `intialValues` is initially equal to `values`.
              }
            } else {
              if (item.value) {
                values[item.id] = { id: item.value, label: item.value };
              } else {
                values[item.id] = null;
              }
            }
            break;
          }
          case ValueTypeName.Status:
            values[item.id] = item.value
              ? { id: item.value, label: item.value }
              : null;
            break;
          default:
            values[item.id] = item?.value;
        }
        return values;
      }
    };

    return items?.reduce(reduceFieldDefinitionsToInstanceValues, {});
  };

  return { getInitialValues };
};
