import { Document, useDocumentStateLazyQuery } from '@pro4all/graphql';

export const useDocumentState = () => {
  const [queryDocumentState] = useDocumentStateLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const getDocumentState = async ({ document }: { document: Document }) => {
    const { data } = await queryDocumentState({
      variables: {
        id: document.id,
      },
    });
    const { state } = data?.documentState || {};
    return state;
  };

  return getDocumentState;
};
