import { QualityControlInstance } from '@pro4all/graphql';
import { MiniMap } from '@pro4all/quality-control/ui/maps';

export const ResultMiniMap = ({
  result,
}: {
  result: QualityControlInstance;
}) => {
  const { type, id, visualContext, x, y, items } = result ?? {};

  if (!items || !x || !y || !visualContext) {
    return null;
  }

  return (
    <MiniMap
      iconName={result?.templateIconProps?.iconName}
      iconType={result.templateIconProps?.iconType || 1}
      instanceId={id}
      items={items}
      lat={y}
      lng={x}
      page={result.page}
      templateId={result?.templateId || ''}
      type={type}
      visualContextId={visualContext.id}
    />
  );
};
