import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SavedSearch } from '@pro4all/graphql';
import { Switch } from '@pro4all/shared/mui-wrappers';

import * as Styled from './ToggleNotifications.styles';

export const ToggleNotifications: React.FC = () => {
  const { t } = useTranslation();
  const { setValue, trigger, watch } = useFormContext<SavedSearch>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    setValue('notificationsEnabled', !value);
    trigger('notificationDays');
  };

  const notificationsEnabled = Boolean(watch('notificationsEnabled'));

  return (
    <Styled.Container>
      <Styled.Header>{t('Email notification')}</Styled.Header>
      <Styled.Body>
        <label htmlFor="notificationsEnabled">
          {t(
            'I want to receive periodic email notifications for new search results.'
          )}
        </label>
        <Switch
          checked={notificationsEnabled}
          inputProps={{
            'aria-label': `notificationsEnabled`,
          }}
          name="notificationsEnabled"
          onChange={handleChange}
          value={notificationsEnabled}
        />
      </Styled.Body>
    </Styled.Container>
  );
};
