import React, { Dispatch, SetStateAction } from 'react';

import { useDocumentActions } from '@pro4all/shared/hooks/src/documents';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

export const SearchResultsActionBar: React.FC<{
  setActionLoading: Dispatch<SetStateAction<boolean>>;
}> = ({ setActionLoading }) => {
  const { searchActions } = useDocumentActions({
    isSpecialFolderProp: true,
    setLoading: setActionLoading,
  });

  return <TableActionBar altActions={searchActions} />;
};
