import { ReportConfiguration, ScopeType } from '@pro4all/graphql';
import { UpdateOtherReportConfigurationsProps } from '@pro4all/shared/types';

export const useUpdateOtherReportConfigurations = () => {
  const updateOtherReportConfigurations = ({
    reportConfigurations,
    setAsDefault,
    storeOnOrganizationLevel,
  }: UpdateOtherReportConfigurationsProps) => {
    if (!setAsDefault) return reportConfigurations;

    // If this report configuration is set as default, we might need to update the other report configurations.
    const updatedReportConfigurations = reportConfigurations.map(
      (reportConfiguration) => ({
        ...reportConfiguration,
        isDefault:
          storeOnOrganizationLevel &&
          reportConfiguration.scope === ScopeType.Organization
            ? false
            : !storeOnOrganizationLevel &&
              reportConfiguration.scope === ScopeType.Project
            ? false
            : reportConfiguration.isDefault,
      })
    ) as ReportConfiguration[];

    return updatedReportConfigurations;
  };

  return { updateOtherReportConfigurations };
};
