import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const DocumentTaskIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M18.4099 4.8H14.5379C14.1599 3.756 13.1699 3 11.9999 3C10.8409 3 9.8399 3.756 9.4619 4.8H5.6999C4.7099 4.8 3.8999 5.61 3.8999 6.6V19.2C3.8999 20.19 4.7099 21 5.6999 21H18.4099C19.2899 21 20.0999 20.19 20.0999 19.2V6.6C20.0999 5.61 19.2899 4.8 18.4099 4.8ZM12 4.80002C12.495 4.80002 12.9 5.20502 12.9 5.70002C12.9 6.19502 12.495 6.60002 12 6.60002C11.505 6.60002 11.1 6.19502 11.1 5.70002C11.1 5.20502 11.505 4.80002 12 4.80002ZM9.3501 9.5C9.3501 9.36193 9.46203 9.25 9.6001 9.25H12.3501V11.2C12.3501 11.6142 12.6859 11.95 13.1001 11.95H15.0501V16.5C15.0501 16.6381 14.9382 16.75 14.8001 16.75H9.6001C9.46203 16.75 9.3501 16.6381 9.3501 16.5V9.5ZM9.6001 7.75C8.6336 7.75 7.8501 8.5335 7.8501 9.5V16.5C7.8501 17.4665 8.6336 18.25 9.6001 18.25H14.8001C15.7666 18.25 16.5501 17.4665 16.5501 16.5V11.2C16.5501 11.0011 16.4711 10.8103 16.3304 10.6697L14.9804 9.31967L13.6304 7.96967C13.4898 7.84002 13.409 7.75 13.1001 7.75H9.6001Z"
      fill="#585858"
      fillRule="evenodd"
    />
  </SvgIcon>
);
