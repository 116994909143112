import { FolderPermission } from '@pro4all/graphql';

import { hasConflictWithApprovePermission } from '../../shared/helpers/hasConflictWithApprovePermission';
import { hasConflictWithFinalizePermission } from '../../shared/helpers/hasConflictWithFinalizePermission';

export const shouldDisableOrOmitBasedOnSubfolderPermissions = ({
  permissionToCheck,
  subFolderPermissions,
}: {
  permissionToCheck: FolderPermission;
  subFolderPermissions: FolderPermission[];
}) => {
  let response = false;

  response = checkPermissions(permissionToCheck, subFolderPermissions);

  return response;
};

const checkPermissions = (
  permissionToCheck: FolderPermission,
  subFolderPermissions: FolderPermission[]
) => {
  let response = false;

  if (!response && permissionToCheck === FolderPermission.FinalizeContent) {
    response = hasConflictWithFinalizePermission({
      currentPermissions: [permissionToCheck],
      newPermissions: subFolderPermissions,
    });
  }

  if (!response && permissionToCheck === FolderPermission.ApproveContent) {
    // Only continue checking if conflicts have not been found yet.
    response = hasConflictWithApprovePermission({
      currentPermissions: [permissionToCheck],
      newPermissions: subFolderPermissions,
    });
  }

  return response;
};
