import { useSideNavContext } from '@pro4all/shared/contexts';
import { Icon } from '@pro4all/shared/ui/icons';

import * as Styled from './SubscriptionCard.styles';

export const SubscriptionCardWarning = () => {
  const {
    sideNavState,
    sideNavState: { isSideNavOpen },
    setSideNavState,
  } = useSideNavContext();

  const onClick = () =>
    setSideNavState({
      ...sideNavState,
      isSideNavOpen: !isSideNavOpen,
    });

  return (
    <Styled.Container>
      <Styled.IconWrapper>
        <Icon
          className="Warning"
          fontSize="large"
          iconName="reportProblemOutlined"
          onClick={onClick}
        />
      </Styled.IconWrapper>
    </Styled.Container>
  );
};
