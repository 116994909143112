import { State } from '../useUploaderEditorReducer';

export const stopProcessingAction = ({
  payload,
  state,
}: {
  payload: boolean;
  state: State;
}) => {
  const { meta } = state;

  const metaUpdated = meta.map((document) => ({
    ...document,
    metaData: document?.metaData?.map((metaDataCell) => ({
      ...metaDataCell,
      valueInitial: metaDataCell.value,
    })),
    nameInitial: document.name,
    processingStatus: {
      error: '',
      isCurrentlyProcessing: false,
      successfullyProcessed: true,
    },
  }));

  return {
    ...state,
    isProcessed: true,
    isProcessing: false,
    meta: payload ? metaUpdated : meta,
  };
};
