import { FieldDefinitionTypesQuery, ValueTypeName } from '@pro4all/graphql';

import { useFieldConfig } from '../configs/useFieldConfig';

import { FieldDefinitionType, TypeProps } from './Types';

export const useGetTypeOptions = ({
  data,
}: {
  data: FieldDefinitionTypesQuery;
}) => {
  const fieldConfig = useFieldConfig();
  const typeOptions: TypeProps[] = data
    ? data.fieldDefinitionTypes
        .map((fieldDefinitionType) => {
          const { label } = fieldDefinitionType as FieldDefinitionType;
          return {
            iconName: fieldConfig[label]?.icon,
            id: ValueTypeName[label],
            label: fieldConfig[label]?.label,
          };
        })
        .filter((option) => option !== null)
    : [];

  return { typeOptions };
};
