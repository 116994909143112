import React from 'react';
import { v4 as uuid } from 'uuid';

import { Collapsible } from '../collapsible';
import { useToggle } from '../toggle/useToggle';

import * as Styled from './SubSection.styles';

export interface SubSectionProps {
  caption?: React.ReactNode;
  hasContent?: boolean;
  id?: string;
  info?: React.ReactNode;
  open?: boolean;
  title: string;
}

export const SubSection: React.FC<SubSectionProps> = ({
  caption,
  children,
  hasContent = false,
  id = uuid(),
  open,
  title,
  info,
}) => {
  const { toggled, toggle } = useToggle(open);

  return (
    <Styled.SubSection id={`section-${id}`}>
      <Styled.Header
        caption={caption}
        hasContent={hasContent}
        info={info}
        onToggle={() => toggle()}
        target={`section-content-${id}`}
        title={title}
        toggled={toggled}
      />
      <Collapsible
        component={Styled.Content}
        id={`section-content-${id}`}
        open={toggled}
      >
        {children}
      </Collapsible>
    </Styled.SubSection>
  );
};
