import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Trust: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M162.61 75.536c0 8.934-4.546 17.24-9.018 24.242a45.162 45.162 0 01-29.351 20.04 49.53 49.53 0 01-4.264.182 50.041 50.041 0 01-37.55-17.018 49.992 49.992 0 01-12.01-39.424 49.986 49.986 0 0121.69-35.048 50.031 50.031 0 0140.654-6.862 45.15 45.15 0 00-8.978-.893 45.14 45.14 0 00-33.392 14.738 45.1 45.1 0 00-11.764 30.392 45.11 45.11 0 008.715 26.668 45.14 45.14 0 0021.077 15.781 39.49 39.49 0 0021.465.288 45.295 45.295 0 0026.112-23.072 39.31 39.31 0 00-1.329-32.48 39.267 39.267 0 00-17.428-17.337 39.297 39.297 0 00-24.326-3.583 45.347 45.347 0 019.996-1.153h.136c.576 0 1.159.012 1.747.035 11.792.446 22.411 4.507 29.408 13.028 4.143 5.044 6.343 12.937 7.341 19.15.017.113.037.228.054.343.047.289.087.577.13.865.017.132.035.265.055.398.505 3.552.782 7.132.83 10.72z"
      fill={colors.primary}
    />
    <path
      d="M170 70a49.988 49.988 0 01-13.173 33.817 50.023 50.023 0 01-32.586 16.001 45.162 45.162 0 0029.351-20.04c4.471-7.003 7.732-15.645 8.416-24.542.277-3.47.197-6.96-.24-10.415-.02-.132-.037-.265-.054-.397a51.524 51.524 0 00-.13-.865c-.017-.115-.038-.23-.055-.343-.974-5.792-3.244-12.216-8.041-18.055-6.997-8.518-16.979-13.66-28.71-14.12a43.86 43.86 0 00-1.748-.035h-.135a45.34 45.34 0 00-9.996 1.152 39.25 39.25 0 00-28.215 20.91 39.21 39.21 0 00-.17 35.108 44.885 44.885 0 01-5.881-22.277A45.1 45.1 0 0190.39 35.5a45.14 45.14 0 0133.393-14.744 45.15 45.15 0 018.978.893 50.039 50.039 0 0126.851 17.842A49.999 49.999 0 01170 70z"
      fill={colors.secondary}
    />
  </SvgIcon>
);
