import { Folder } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useApplyTemplateToFolder } from '../actions/useApplyTemplateToFolder';
import { useCopyFolderTree } from '../actions/useCopyFolderTree';
import { useDeleteFolderAction } from '../actions/useDeleteFolderAction';
import { useEditFolderPermissionsAction } from '../actions/useEditFolderPermissionsAction';
import { useImportFolderSpreadsheetAction } from '../actions/useImportFolderSpreadsheet';
import { useLinkProject } from '../actions/useLinkProject';
import { usePasteDocuments } from '../actions/usePasteDocuments';
import { usePasteFolderTree } from '../actions/usePasteFolderTree';
import { useRenameFolderAction } from '../actions/useRenameFolderAction';

export const useStandardFolderActions = (folder: Folder) => {
  const { params } = useRouting();
  const { isExternalUserInProject } = useProjectContext();

  const { applyTemplateMenuItem } = useApplyTemplateToFolder(folder);
  const { deleteFolderMenuItem } = useDeleteFolderAction(folder);
  const { renameFolderMenuItem } = useRenameFolderAction(folder);
  const { editFolderPermissionsMenuItem } =
    useEditFolderPermissionsAction(folder);
  const { importFolderSpreadsheetAction } =
    useImportFolderSpreadsheetAction(folder);
  const { linkProjectAction } = useLinkProject(folder);
  const { copyFolderTreeAction } = useCopyFolderTree(folder);
  const { pasteFolderTreeAction } = usePasteFolderTree(folder);
  const { pasteDocumentsAction } = usePasteDocuments(folder);

  return {
    menuItems: [
      applyTemplateMenuItem,
      deleteFolderMenuItem,
      renameFolderMenuItem,
      editFolderPermissionsMenuItem,
      (!params.projectId || !isExternalUserInProject) && linkProjectAction,
      importFolderSpreadsheetAction,
      copyFolderTreeAction,
      pasteFolderTreeAction,
      pasteDocumentsAction,
    ].filter(Boolean),
    sidebarActions: [
      applyTemplateMenuItem,
      deleteFolderMenuItem,
      renameFolderMenuItem,
      editFolderPermissionsMenuItem,
    ],
  };
};
