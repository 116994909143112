import React from 'react';
import styled from 'styled-components';

import { Box, TooltipProps } from '@pro4all/shared/mui-wrappers';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { Tag, TagProps } from './Tag';

const StyledBox = styled(Box)`
  && {
    > * {
      display: inline-flex;
      flex-wrap: wrap;
      vertical-align: middle;
      margin-top: ${({ theme }) => theme.spacing(1)};
      margin-right: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

interface Props {
  enableTooltip?: boolean;
  maxNameLength?: number;
  tags: TagProps[];
  toolTipPlacement?: TooltipProps['placement'];
}

export const TagList: React.FC<Props> = ({
  enableTooltip = false,
  maxNameLength,
  tags,
  toolTipPlacement = 'right',
}) => {
  const addElipsis = (text: string) => {
    const t = text.replace(/ /g, '\u00A0');
    const start = t.slice(0, maxNameLength);
    const end = '...';
    return `${start}${end}`;
  };

  const isNameEllipsed = (nameLength: number) =>
    Boolean(maxNameLength && nameLength > maxNameLength);

  return (
    <StyledBox data-testid="taglist" marginY={1}>
      {tags.map((tagProps, index) =>
        enableTooltip && isNameEllipsed(tagProps.name.length) ? (
          <Tooltip
            key={`tag-${index}`}
            placement={toolTipPlacement}
            title={tagProps.name}
          >
            <Tag
              {...tagProps}
              key={tagProps.id}
              name={
                isNameEllipsed(tagProps.name.length)
                  ? addElipsis(tagProps.name)
                  : tagProps.name
              }
            />
          </Tooltip>
        ) : (
          <Tag {...tagProps} key={tagProps.id} />
        )
      )}
    </StyledBox>
  );
};

export default TagList;
