import { Box } from '@pro4all/shared/mui-wrappers';
import { FormikCheckbox } from '@pro4all/shared/ui/general';

import { useFieldDefinitionFormConfig } from './useFieldDefinitionFormConfig';

export const CustomUserSelectionForm = () => {
  const { getField } = useFieldDefinitionFormConfig({
    multiSelectLabel: 'Can select multiple users',
  });
  const userSelectField = getField('multiSelect');

  return (
    <Box alignItems="center" display="flex" mb={2}>
      <FormikCheckbox
        label={userSelectField.label}
        name={userSelectField.name}
      />
    </Box>
  );
};
