import { useSubscriptionOrganizationExpireQuery } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useFetchProject } from '@pro4all/projects/data-access';
import { useSubscriptionHelpers } from '@pro4all/settings/ui/src/subscription';

export const useBlockProjectRoutes = () => {
  // Get organizationId to which this project is related. Can be an external organization!
  const { projectData, loading: loadingProjectData } = useFetchProject();
  const { organizationId: organizationIdProject } = projectData?.project || {};

  // Get organizationId to which the current user is related.
  const { userOrganizationId } = useOrganizationContext();

  const isCurrentOrganization = organizationIdProject === userOrganizationId;
  const isLoadingBlockProjectRoutesInfo =
    loadingProjectData || !userOrganizationId;
  const skip = isLoadingBlockProjectRoutesInfo || isCurrentOrganization;

  // In case of an external project, trigger a query to retrieve expireDate for that organization.
  const { data: dataExpire, loading } = useSubscriptionOrganizationExpireQuery({
    fetchPolicy: 'cache-and-network',
    skip,
    variables: { organizationId: organizationIdProject },
  });

  // Calculate isExpired.
  const { isExpired: isExpiredCurrentOrganization } = useSubscriptionHelpers();
  let blockRoutes = true;
  if (isLoadingBlockProjectRoutesInfo) {
    blockRoutes = true;
  } else if (isCurrentOrganization) {
    // Current organization.
    blockRoutes = isExpiredCurrentOrganization;
  } else {
    // External organization.
    if (dataExpire) {
      const { expireDate } = dataExpire?.subscriptionOrganizationExpire || {};
      blockRoutes = new Date(expireDate) < new Date();
    }
  }

  return {
    blockRoutes,
    isLoadingBlockProjectRoutesInfo: isLoadingBlockProjectRoutesInfo || loading,
  };
};
