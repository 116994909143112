import * as variables from '../style-dictionary-dist/variables';

export const customColors = {
  black: '#000000',
  blueGrey: '#D6DEE5',
  errorAlert: '#CF6679',
  errorBg: '#F5E0E4',
  errorHover: '#894A56',
  greenAccent: '#6EE0DC',
  greenDark: '#07847B',
  greenDarkTbq: '#006A62',
  greenLight: '#E9F5F3',
  greenMonoHighlight: '#38E5C4',
  greenPrimary: '#36CCBA',
  greenPrimaryTbq: '#19A49A',
  greenShapeShadow: '#07847B',
  greenWhiteLight: '#D1EDEB',
  grey: '#DDDDDD',
  grey50: variables.GlobalJustWhite,
  grey100: '#FAFAFA',
  grey200: '#F7F7F7',
  grey300: '#EEEEEE',
  grey400: '#DDDDDD',
  grey500: '#AFAFAF',
  grey600: '#848484',
  grey700: variables.GlobalCharcoalGrey,
  grey800: '#424242',
  grey900: variables.GlobalNotBlack,
  greyNotBlack: variables.GlobalNotBlack,
  lightPurple: '#ECEEFF',
  lightPurpleOpaque: 'rgba(88, 88, 88, 0.12)',
  notBlack: variables.GlobalNotBlack,
  orangeAccent: '#E25314',
  orangeMonoHighlight: '#E8754D',
  orangeShapeShadow: '#AF3F12',
  pendingBg: '#CCF0F8',
  pendingHover: '#006379',
  purpleAccent: '#7E78F9',
  purpleDark: '#28237A',
  purpleDarkOpaque: 'rgba(255, 255, 255, 0.12)',
  purpleHover: 'rgb(236, 238, 255)',
  purpleLight: '#E1DBFB',
  purpleMonoHighlight: '#6656FC',
  purplePrimary: '#533BE2',
  purpleShapeShadow: '#28237A',
  silverMonoHighlight: '#D6DEE5',
  silverPrimary: '#EEEEEE',
  silverShapeShadow: '#C3CAD1',
  stillBluePurple: '#2C2D56',
  success: '#36CCBA',
  successBg: '#7bdcb5',
  successHover: '#006B5E',
  tbqDarkOpaque: 'rgba(255, 255, 255, 0.12)',
  tbqtLightOpaque: 'rgba(88,88,88,0.12)',
  warning: '#F5B05B',
  warningBg: '#FCEFD6',
  warningDark: '#8F4F00',
  warningHover: '#8F4F00',
  warningLight: '#FCEFD6',
  white: variables.GlobalJustWhite,
};

export type CustomColor = keyof typeof customColors;
