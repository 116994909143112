import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Group, Member } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { generateRoute } from '@pro4all/shared/routing-utils';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  Column,
  Main,
  OptimisticResponseProvider,
} from '@pro4all/shared/ui/general';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { ProjectGroupManagement } from '../components/group-management/project/ProjectGroupManagement';
import { ProjectUsersGroupsTabs } from '../components/tabs/ProjectUsersGroupsTabs';
import { ProjectUserManagement } from '../components/user-management/project/ProjectUserManagement';

export const ProjectMembers = () => {
  const isAdminInContext = useIsAdminInContext();
  const {
    params: { projectId },
  } = useRouting();
  const base = generateRoute('project', { params: { projectId } });

  return (
    <Main sx={{ overflow: 'hidden' }}>
      <Column>
        <Route exact path={`${base}${Routes.usersGroups}`}>
          <Redirect to={`${base}${Routes.members}`} />
        </Route>
        <Route path={[Routes.projectGroups, Routes.projectMembers]}>
          {isAdminInContext ? <ProjectUsersGroupsTabs /> : null}
        </Route>
        <Route path={Routes.projectGroups}>
          <OptimisticResponseProvider<Group>>
            {isAdminInContext ? (
              <ProjectGroupManagement />
            ) : (
              <BigMessageNoAccess />
            )}
          </OptimisticResponseProvider>
        </Route>
        <Route path={Routes.projectMembers}>
          <OptimisticResponseProvider<Member>>
            {isAdminInContext ? (
              <ProjectUserManagement />
            ) : (
              <BigMessageNoAccess />
            )}
          </OptimisticResponseProvider>
        </Route>
      </Column>
    </Main>
  );
};
