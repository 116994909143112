import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

import { StandardDateRange } from '@pro4all/graphql';
import {
  AdvancedFilterValue,
  CompareType,
  FilterColumnProps,
} from '@pro4all/shared/ui/filtering';

dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const isExactMatch = ({
  columnValue,
  filterValues,
}: {
  columnValue: string;
  filterValues: string[] | AdvancedFilterValue[];
}) => {
  const filterValuesLowerCase = filterValues.map((filterValue) =>
    typeof filterValue === 'string' ? filterValue.toLowerCase() : filterValue
  );
  return filterValuesLowerCase.includes(columnValue?.toString().toLowerCase());
};

export const isFilterValueIncluded = ({
  columnValue,
  filterValue,
}: {
  columnValue: string | string[];
  filterValue: string | AdvancedFilterValue;
}) =>
  typeof filterValue === 'string'
    ? typeof columnValue === 'string'
      ? columnValue.toLowerCase().includes(filterValue.toLowerCase())
      : columnValue
          .map((value) => value.toLowerCase())
          .includes(filterValue.toLowerCase())
    : false; // In this case it is advanced filtering that will be handled per type individually.

export const includeDate = ({
  columnValue,
  filterValues,
}: { columnValue: string } & Pick<FilterColumnProps, 'filterValues'>) => {
  const columnDate = dayjs(columnValue);
  const beforeToday = columnDate.isBefore(dayjs());

  if (typeof filterValues[0] === 'string') {
    switch (filterValues[0]) {
      case StandardDateRange.Today:
        return columnDate.isToday();
      case StandardDateRange.Yesterday:
        return columnDate.isYesterday();
      case StandardDateRange.LastThreeDays:
        return columnDate.isSameOrAfter(dayjs().add(-3, 'day')) && beforeToday;
      case StandardDateRange.LastSevenDays:
        return columnDate.isSameOrAfter(dayjs().add(-7, 'day')) && beforeToday;
      case StandardDateRange.LastFourteenDays:
        return columnDate.isSameOrAfter(dayjs().add(-14, 'day')) && beforeToday;
      case StandardDateRange.LastThirtyDays:
        return columnDate.isSameOrAfter(dayjs().add(-30, 'day')) && beforeToday;
      case StandardDateRange.LastNinetyDays:
        return columnDate.isSameOrAfter(dayjs().add(-90, 'day')) && beforeToday;
      case StandardDateRange.LastYear:
        return columnDate.isSameOrAfter(dayjs().add(-1, 'year')) && beforeToday;
      default:
        return false;
    }
  } else {
    // Advanced filter.

    // TODO: For now only applicable for one filter.
    // We will expand this in the future to multiple filters per column.
    const compareDateFirst = dayjs(filterValues[0].valueFirst);
    const compareDateSecond = dayjs(filterValues[0].valueSecond);
    switch (filterValues[0].compareType) {
      case CompareType.is:
        return columnDate.diff(compareDateFirst, 'day') === 0;
      case CompareType.isAfter:
        return columnDate.isSameOrAfter(compareDateFirst, 'day');
      case CompareType.isBefore:
        return columnDate.isBefore(compareDateFirst, 'day');
      case CompareType.isBetween:
        return (
          columnDate.isSameOrAfter(compareDateFirst, 'day') &&
          columnDate.isBefore(compareDateSecond, 'day')
        );
      case CompareType.isNot:
        return columnDate.diff(compareDateFirst, 'day') !== 0;
    }
  }
};
