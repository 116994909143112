import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderPermission, GroupPermission } from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { Option } from '@pro4all/shared/types';
import { firstCharToLowerCase } from '@pro4all/shared/utils';

import { isDocumentsNone } from '../shared/helpers/checkPermissionsWrappers';
import { removeExclusiveFolderPermissions } from '../shared/helpers/removeExclusiveFolderPermissions';
import { ExclusivePermissionType, OptionValue } from '../types/types';

import { useGetSinglePermissionsData } from './useGetSinglePermissionsData';

const getOptionByPermissions = (
  permissions: FolderPermission[],
  exclusivePermissionType: ExclusivePermissionType
) => {
  const noDocumentPermissions = isDocumentsNone(permissions);

  if (!noDocumentPermissions) {
    if (
      permissions.includes(FolderPermission.UpdateContent) &&
      exclusivePermissionType === ExclusivePermissionType.Update
    ) {
      return OptionValue.UpdateContent;
    }
    if (
      permissions.includes(FolderPermission.UpdateOwn) &&
      exclusivePermissionType === ExclusivePermissionType.Update
    ) {
      return OptionValue.UpdateOwn;
    }
    if (exclusivePermissionType === ExclusivePermissionType.Update) {
      return OptionValue.UpdateNone;
    }
    if (
      permissions.includes(FolderPermission.DeleteContent) &&
      exclusivePermissionType === ExclusivePermissionType.Delete
    ) {
      return OptionValue.DeleteContent;
    }
    if (
      permissions.includes(FolderPermission.DeleteOwnContent) &&
      exclusivePermissionType === ExclusivePermissionType.Delete
    ) {
      return OptionValue.DeleteOwnContent;
    }
    if (exclusivePermissionType === ExclusivePermissionType.Delete) {
      return OptionValue.DeleteNone;
    }
    if (
      permissions.includes(FolderPermission.ReadOwnAndFinalized) &&
      exclusivePermissionType === ExclusivePermissionType.Documents
    ) {
      return OptionValue.ReadOwnAndFinalized;
    }
    if (
      permissions.includes(FolderPermission.ReadOwn) &&
      exclusivePermissionType === ExclusivePermissionType.Documents
    ) {
      return OptionValue.ReadOwn;
    }
    if (
      permissions.includes(FolderPermission.ReadLastVersionAndApproved) &&
      exclusivePermissionType === ExclusivePermissionType.Versions
    ) {
      return OptionValue.ReadLastVersionAndApproved;
    }
    if (
      permissions.includes(FolderPermission.ReadApproval) &&
      exclusivePermissionType === ExclusivePermissionType.Versions
    ) {
      return OptionValue.ReadApproval;
    }
    if (
      permissions.includes(FolderPermission.ReadLastApproval) &&
      exclusivePermissionType === ExclusivePermissionType.Versions
    ) {
      return OptionValue.ReadLastApproval;
    }
    if (
      permissions.includes(FolderPermission.ReadLastVersion) &&
      exclusivePermissionType === ExclusivePermissionType.Versions
    ) {
      return OptionValue.ReadLastVersion;
    }
    if (
      permissions.includes(FolderPermission.ReadOwnVersions) &&
      exclusivePermissionType === ExclusivePermissionType.Versions
    ) {
      return OptionValue.ReadOwnVersions;
    }
    return exclusivePermissionType === ExclusivePermissionType.Versions
      ? OptionValue.ReadAllVersions
      : OptionValue.ReadAllDocuments;
  }
  return OptionValue.None;
};

const getInheritedFromGroupsOnType = ({
  exclusivePermissionType,
  inheritedFrom,
}: {
  exclusivePermissionType: ExclusivePermissionType;
  inheritedFrom: GroupPermission[];
}) => {
  switch (exclusivePermissionType) {
    case ExclusivePermissionType.Documents:
      return inheritedFrom.filter((groupPermission) =>
        [
          FolderPermission.ReadAllDocuments,
          FolderPermission.ReadOwn,
          FolderPermission.ReadOwnAndFinalized,
        ].includes(groupPermission.permission)
      );
    case ExclusivePermissionType.Versions:
      return inheritedFrom.filter((groupPermission) =>
        [
          FolderPermission.ReadAllVersions,
          FolderPermission.ReadLastVersionAndApproved,
          FolderPermission.ReadApproval,
          FolderPermission.ReadLastApproval,
          FolderPermission.ReadLastVersion,
          FolderPermission.ReadOwnVersions,
        ].includes(groupPermission.permission)
      );
    case ExclusivePermissionType.Delete:
      return inheritedFrom.filter((groupPermission) =>
        [
          FolderPermission.DeleteContent,
          FolderPermission.DeleteOwnContent,
        ].includes(groupPermission.permission)
      );
    case ExclusivePermissionType.Update:
      return inheritedFrom.filter((groupPermission) =>
        [FolderPermission.UpdateContent, FolderPermission.UpdateOwn].includes(
          groupPermission.permission
        )
      );
  }
};

export const useExclusivePermissionSelector = ({
  exclusivePermissionType,
  folderPermissions,
  inheritedFrom = [],
}: {
  exclusivePermissionType: ExclusivePermissionType;
  folderPermissions: FolderPermission[];
  inheritedFrom: GroupPermission[];
}) => {
  const { t } = useTranslation();
  const getSinglePermissionData = useGetSinglePermissionsData();
  const { finalize_action_label, approve_action_label } =
    useDocumentActionsLabels();

  // We can have multiple inherited permissions from multiple groups.
  // We need to remove the lowest permission.
  // Let's say the passed in permissions array contains both `DeleteContent` and `DeleteOwnContent`
  // We  keep `DeleteContent` and remove `DeleteOwnContent`.
  const inheritedPermissions = removeExclusiveFolderPermissions({
    permissions: inheritedFrom.map((perm) => perm.permission),
  });

  const options: Option[] =
    exclusivePermissionType === ExclusivePermissionType.Documents
      ? [
          {
            iconName: 'visibility',
            id: OptionValue.ReadAllDocuments,
            label: t('All'),
          },
          {
            iconName: 'ownAndFinalizedFiles',
            id: OptionValue.ReadOwnAndFinalized,
            label: t('Own and {{name}} set', {
              name: firstCharToLowerCase(finalize_action_label),
            }),
          },
          {
            iconName: 'accountCircle',
            id: OptionValue.ReadOwn,
            label: t('Own'),
          },
          {
            iconName: 'notInterested',
            id: OptionValue.None,
            label: t('None'),
          },
        ]
      : exclusivePermissionType === ExclusivePermissionType.Versions
      ? [
          {
            iconName: 'visibility',
            id: OptionValue.ReadAllVersions,
            label: t('All'),
          },
          {
            iconName: 'mostRecentApproved',
            id: OptionValue.ReadLastVersionAndApproved,
            label: t('Own, most recent and {{name}} set', {
              name: firstCharToLowerCase(approve_action_label),
            }),
          },
          {
            iconName: 'checkCircleOutlined',
            id: OptionValue.ReadApproval,
            label: t('Own and {{name}} set', {
              name: firstCharToLowerCase(approve_action_label),
            }),
          },
          {
            iconName: 'lastApproved',
            id: OptionValue.ReadLastApproval,
            label: t('Own and most recent {{name}} set', {
              name: firstCharToLowerCase(approve_action_label),
            }),
          },
          {
            iconName: 'mostRecent',
            id: OptionValue.ReadLastVersion,
            label: t('Own and most recent'),
          },
          {
            iconName: 'accountCircle',
            id: OptionValue.ReadOwnVersions,
            label: t('Own'),
          },
        ]
      : exclusivePermissionType === ExclusivePermissionType.Delete
      ? [
          {
            iconName: 'deleteFile',
            id: OptionValue.DeleteContent,
            label: t('All'),
          },
          {
            iconName: 'deleteOwnFile',
            id: OptionValue.DeleteOwnContent,
            label: t('Own'),
          },
          {
            iconName: 'notInterested',
            id: OptionValue.DeleteNone,
            label: t('None'),
          },
        ]
      : [
          {
            iconName: 'editFile',
            id: OptionValue.UpdateContent,
            label: t('All'),
          },
          {
            iconName: 'editOwnFiles',
            id: OptionValue.UpdateOwn,
            label: t('Own'),
          },
          {
            iconName: 'notInterested',
            id: OptionValue.UpdateNone,
            label: t('None'),
          },
        ];

  const allPermissions = useMemo(
    () => [...new Set([...folderPermissions, ...inheritedPermissions])],
    [folderPermissions, inheritedPermissions]
  );

  const optionByPermission = useMemo(
    () => getOptionByPermissions(allPermissions, exclusivePermissionType),
    [allPermissions, exclusivePermissionType]
  );

  const allPermissionsInherited = useMemo(
    () => [...new Set([...inheritedPermissions])],
    [inheritedPermissions]
  );
  const optionByPermissionInherited = useMemo(
    () =>
      getOptionByPermissions(allPermissionsInherited, exclusivePermissionType),
    [allPermissionsInherited, exclusivePermissionType]
  );

  // Get all inherited groups and their permissions for this exclusive permission type (documents, versions, delete, update).
  const inheritedFromGroupsOnType = getInheritedFromGroupsOnType({
    exclusivePermissionType,
    inheritedFrom,
  });

  // Construct an array with the group names and their permissions for this exclusive permission type (documents, versions, delete, update).
  // To display later on in the tooltip.
  let groups: string[] = [];
  inheritedFromGroupsOnType.forEach((permission) => {
    const groupWithPermissionText = permission.groups.map(
      (group) =>
        `${group} (${t(
          getSinglePermissionData(permission.permission)?.permissionText
        )})`
    );
    groups = [...groups, ...groupWithPermissionText];
  });

  return {
    allPermissions,
    getOptionByPermissions,
    inheritedFromGroups: groups,
    optionByPermission,
    optionByPermissionInherited,
    options,
  };
};
