import { State, UpdateMetaNamePayload } from '../useUploaderEditorReducer';

import { useGetErrorName } from './helpers';

export const useUpdateMetaNameAction = () => {
  const getError = useGetErrorName();

  const updateMetaNameAction = ({
    payload,
    state,
  }: {
    payload: UpdateMetaNamePayload;
    state: State;
  }) => {
    const { currentDocumentsInFolder, filesToUpload, meta } = state;
    const metaUpdated = [...meta];
    const { documentId, value } = payload;
    const index = metaUpdated.findIndex((doc) => doc.id === documentId);

    // Only update if the element has been found.
    if (index !== -1) {
      metaUpdated[index].name = value;
      metaUpdated[index].error = getError({
        currentDocumentsInFolder,
        documentId,
        meta,
        uploadContext: Boolean(filesToUpload.length),
        value,
      });
    }

    return {
      ...state,
      meta: metaUpdated,
    };
  };

  return updateMetaNameAction;
};
