import React from 'react';

import { Loader } from '../loader/Loader';

import * as Styled from './Backdrop.styles';

export const Backdrop = () => (
  <Styled.Backdrop>
    <Loader />
  </Styled.Backdrop>
);
