import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComposedPin, TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { Box, Tooltip, tooltipClasses } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';

import { IconList } from './components/IconList';

export type TFileParams = {
  file?: File;
};

type TSelectSnagIconProps = {
  currentSelectedFile?: string;
  onSelect?: (iconName?: TMuiIcon, fileParams?: TFileParams) => void;
  selectedIcon?: TMuiIcon;
};

export function SelectSnagIcon(props: TSelectSnagIconProps) {
  const { t } = useTranslation();
  const routing = useRouting();
  const [isOpen, setIsOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState<File | string | null>(
    props.currentSelectedFile || null
  );
  const [selectedIcon, setSelectedIcon] = useState<TMuiIcon | undefined>(
    props?.selectedIcon
  );

  useEffect(() => {
    if (props?.currentSelectedFile) setCurrentFile(props?.currentSelectedFile);
  }, [props?.currentSelectedFile]);

  useEffect(() => {
    window.onclick = () => {
      setIsOpen(false);
    };
  }, []);

  const handleListToggle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOnIconSelect = (
    iconName?: TMuiIcon,
    fileParams?: TFileParams
  ) => {
    setSelectedIcon(iconName);
    setIsOpen(false);
    props?.onSelect?.(iconName as TMuiIcon, { file: fileParams?.file });
    setCurrentFile(fileParams?.file || null);
  };

  const isSnag = routing.url.includes('/snags');

  return (
    <Box sx={{ position: 'relative' }}>
      <Tooltip
        placement="bottom"
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: '0px',
                },
            },
          },
        }}
        title={t('Change icon')}
      >
        <Box
          onClick={handleListToggle}
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            alignItems: 'center',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
            paddingRight: 4,
            position: 'relative',
            width: '100%',
          }}
        >
          <ComposedPin
            currentFile={currentFile}
            selectedIconName={selectedIcon}
            type={isSnag ? 'snag' : 'form'}
          />
          <Box
            onClick={handleListToggle}
            sx={{
              bottom: '10px',
              position: 'absolute',
              right: '15px',
            }}
          >
            <Icon color="inherit" fontSize="small" iconName="editSnag" />
          </Box>
        </Box>
      </Tooltip>
      {isOpen && (
        <IconList
          onIconSelect={handleOnIconSelect}
          selectedIcon={selectedIcon}
        />
      )}
    </Box>
  );
}

export default SelectSnagIcon;
