import React from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

export const SubSection = styled.section`
  background-color: ${customColors.white};
  padding: 1rem;
  padding-bottom: 0.25rem;
`;

export const Content = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
`;

const StyledHeader = styled.label<{ $expandable: boolean }>`
  display: flex;
  cursor: ${({ $expandable }) => ($expandable ? 'pointer' : 'text')};
  user-select: none;
`;

const ToggleButton = styled(IconButton)<{
  $hidden: boolean;
  $toggled: boolean;
}>`
  && {
    display: flex;
    visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'initial')};
    color: ${customColors.grey600};
    box-sizing: content-box;
    margin-left: ${({ theme }) => theme.spacing(1)};
    padding: 0.25rem;
    align-self: center;

    .MuiSvgIcon-root {
      transition: transform 250ms ease-in-out;
      ${({ $toggled }) => $toggled && `transform: scaleY(-1);`}
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.div`
  flex: 1;
`;

const Caption = styled(Text)`
  && {
    padding-top: 0.25rem;
    color: ${customColors.grey600};
  }
`;

const Information = styled(Text)`
  && {
    color: ${customColors.grey600};
  }
`;

interface Props {
  caption?: React.ReactNode;
  hasContent: boolean;
  info?: React.ReactNode;
  onToggle: () => void;
  target: string;
  title: string;
  toggled: boolean;
}

export const Header: React.FC<Props> = ({
  caption,
  hasContent,
  info,
  onToggle,
  target,
  title,
  toggled,
}) => (
  <StyledHeader $expandable={hasContent}>
    <Heading>
      <Title>
        <Text variant="h5">{title}</Text>
        {info && <Information variant="body2">{info}</Information>}
      </Title>
      {caption && <Caption variant="caption">{caption}</Caption>}
    </Heading>

    <ToggleButton
      $hidden={!hasContent}
      $toggled={toggled}
      aria-controls={target}
      aria-expanded={toggled}
      color="inherit"
      disableBorder
      iconName="expandMore"
      onClick={hasContent ? onToggle : null}
    />
  </StyledHeader>
);
