import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Export: React.FC<SvgIconProps> = (props) => (
  <SvgIcon fill="none" {...props}>
    <svg fill="none" viewBox="-3 -3 24 24">
      <path
        d="M13.0011 0.792893C12.6105 0.402369 11.9774 0.402369 11.5868 0.792893C11.1963 1.18342 11.1963 1.81658 11.5868 2.20711L13.0011 0.792893ZM15.3515 5.97181L16.0587 6.67892L17.4729 5.26471L16.7658 4.5576L15.3515 5.97181ZM11.5868 2.20711L15.3515 5.97181L16.7658 4.5576L13.0011 0.792893L11.5868 2.20711Z"
        fill="currentColor"
      />
      <path
        d="M15.3515 4.55852L16.0586 3.85141L17.4728 5.26562L16.7657 5.97273L15.3515 4.55852ZM13.001 9.73744C12.6105 10.128 11.9773 10.128 11.5868 9.73744C11.1963 9.34691 11.1963 8.71375 11.5868 8.32322L13.001 9.73744ZM16.7657 5.97273L13.001 9.73744L11.5868 8.32322L15.3515 4.55852L16.7657 5.97273Z"
        fill="currentColor"
      />
      <path
        d="M7.58824 1.5H3C1.89543 1.5 1 2.39543 1 3.5V15.5C1 16.6046 1.89543 17.5 3 17.5H15C16.1046 17.5 17 16.6046 17 15.5V10.9118"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M14.1769 5.26562H10.6475C8.43832 5.26562 6.64746 7.05649 6.64746 9.26563V12.795"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);
