import { FolderPermission } from '@pro4all/graphql';

import { State } from '../types';

import { getThisEntityPermission } from './getThisEntityPermission';
import { shouldDisableOrOmitBasedOnSubfolderPermissions } from './shouldDisableOrOmitBasedOnSubfolderPermissions';

export const inConflictWithPermissionsOfSubfolder = ({
  entityId,
  permission,
  state,
}: {
  entityId: string;
  permission: FolderPermission;
  state: State;
}) => {
  let response = false;
  const { displayPermissions } = state;

  const thisEntity = getThisEntityPermission(entityId, displayPermissions);
  if (thisEntity && thisEntity.subFoldersPermissions) {
    const subFolderPermissions = thisEntity.subFoldersPermissions
      ? thisEntity.subFoldersPermissions.map(
          (subFolderPermission) => subFolderPermission.permission
        )
      : [];
    response = shouldDisableOrOmitBasedOnSubfolderPermissions({
      permissionToCheck: permission,
      subFolderPermissions,
    });
  }

  return response;
};
