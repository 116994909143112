import React from 'react';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '@pro4all/shared/ui/general';

import { FieldDefinitionForm } from '../form-field-definition/FieldDefinitionForm';

import { useFieldSidebarContext } from './TemplateMutationContext';

export const FieldSidebar = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation();
  const {
    field,
    setIsOpenFieldSidebar,
    setParentFieldSidebar,
    setField,
    fieldSidebarActions,
  } = useFieldSidebarContext();
  const handleClose = () => {
    setIsOpenFieldSidebar(false);
    setParentFieldSidebar(null);
    setField(null);
  };

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header
        icon="addFieldDefinition"
        title={field ? t(`Edit field`) : t(`New field`)}
      >
        {field && (
          <Sidebar.SidebarActionBar sidebarActions={fieldSidebarActions} />
        )}
      </Sidebar.Header>
      {isOpen && (
        <FieldDefinitionForm onClose={handleClose} selectedField={field} />
      )}
    </Sidebar>
  );
};
