import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useRestoreTaskMutation } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const TaskDeleted: React.FC<{
  procedureId: string;
  restoreItems: (ids: string[]) => void;
  taskId: string;
  taskName: string;
}> = ({ procedureId, restoreItems, taskId, taskName }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const entityTypeTranslation = EntityTypeTranslation.Task;

  const [restoreTask] = useRestoreTaskMutation({
    onCompleted: () => {
      const message = (
        <ItemChangedMessage
          description={MessageAction.Restore}
          entityName={taskName}
          entityTypeTranslation={entityTypeTranslation}
        />
      );
      enqueueSnackbar(message);
    },
    onError: () => {
      enqueueSnackbar(t('Something went wrong'));
    },
  });

  const onRestoreTask = async () => {
    restoreTask({
      variables: {
        id: taskId,
        procedureId,
      },
    });
    restoreItems([taskId]);
  };

  const undoAction = (
    <Button color="inherit" onClick={onRestoreTask} size="small">
      {t('Undo')}
    </Button>
  );

  return (
    <ItemChangedMessage
      action={undoAction}
      description={MessageAction.Delete}
      entityName={taskName}
      entityTypeTranslation={entityTypeTranslation}
    />
  );
};

export default TaskDeleted;
