import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid ${customColors.grey400};
  padding: ${({ theme }) => theme.spacing(1)};
`;
