import { useCallback, useEffect, useState } from 'react';

import { FieldDefinition } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import {
  ButtonsBoolean,
  SearchableSelectProps,
} from '@pro4all/shared/ui/general';

import { useBoolOptions } from '../useBoolOptions';
interface Props {
  item: FieldDefinition;
  name: string;
  setValue: (name: string, value: boolean) => void;
  value: string;
}

export const Bool = ({ name, setValue, value }: Props) => {
  const selectOptions = useBoolOptions();

  const getDefaultValue = useCallback(
    () => selectOptions.find((opt) => value?.toString() === opt?.id),
    [selectOptions, value]
  );

  const [currentValue, setCurrentValue] = useState(getDefaultValue());

  const handleValueChange = useCallback(
    ({ name, value }: { name: string; value: Option }) => {
      setCurrentValue(value);
      setValue?.(name, value?.id ? JSON.parse(value.id) : '');
    },
    []
  );

  useEffect(() => {
    handleValueChange({ name, value: getDefaultValue() });
  }, [getDefaultValue, handleValueChange, name, value]);

  const handleChange: SearchableSelectProps['onChange'] = (value: Option) => {
    handleValueChange({ name, value });
  };

  return (
    <ButtonsBoolean
      handleChange={handleChange}
      options={selectOptions}
      value={currentValue}
    />
  );
};
