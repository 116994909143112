import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

export const LevelCard = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 435px;
    padding: ${({ theme }) => theme.spacing(4)};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 10px;
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

export const Title = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Subtitle = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const ExpiredDate = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
