import { useTranslation } from 'react-i18next';

export enum Reason {
  Different = 'Other reason.',
  DifferentSolution = "I'm switching to a different solution.",
  HowToUse = 'Not sure how to use Prostream.',
  MissingFeatures = 'Missing features I need.',
  NeedShortPeriod = 'I only needed the product for a (short-term) project.',
  NotEffective = 'It was not effective, and I didn’t see the results I wanted.',
  PriceTooHigh = 'The price is too high to justify.',
  TechnicalIssues = "I'm having (too many) technical issues.",
}

export const useCancelSubscriptionOptions = () => {
  const { t } = useTranslation();
  return [
    {
      id: Reason.DifferentSolution,
      label: t(Reason.DifferentSolution),
    },
    {
      id: Reason.HowToUse,
      label: t(Reason.HowToUse),
    },
    {
      id: Reason.MissingFeatures,
      label: t(Reason.MissingFeatures),
    },
    {
      id: Reason.NeedShortPeriod,
      label: t(Reason.NeedShortPeriod),
    },
    {
      id: Reason.NotEffective,
      label: t(Reason.NotEffective),
    },
    {
      id: Reason.PriceTooHigh,
      label: t(Reason.PriceTooHigh),
    },
    {
      id: Reason.TechnicalIssues,
      label: t(Reason.TechnicalIssues),
    },
    {
      id: Reason.Different,
      label: t(Reason.Different),
    },
  ];
};
