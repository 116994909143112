import { LoginMethodOption } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';

export const useInitialValues = () => {
  const { enforceSSO } = useOrganizationContext();

  const initialValues = {
    login_method: enforceSSO
      ? LoginMethodOption.OnlySso
      : LoginMethodOption.NotOnlySso,
  };

  return initialValues;
};
