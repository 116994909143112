import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const FolderLink = styled(Link)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${customColors.grey200};
  }

  ${({ selected, theme }) => css`
    background: ${selected && theme.palette.primary.light};
    color: ${(selected && theme.palette.primary.main) ||
    theme.palette.primary.dark};
  `}
`;
