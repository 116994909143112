import { useRouting } from '@pro4all/shared/routing-utils';

export const useCompanyDataRegistration = () => {
  const { searchParams } = useRouting();

  return async (licenseId: string) => {
    searchParams.clear();
    searchParams.set({ action: 'companyDataRegistration', license: licenseId });
  };
};
