import React from 'react';
import { useTranslation } from 'react-i18next';
import { FastField, FieldArrayRenderProps } from 'formik';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { FormikInput } from '@pro4all/shared/ui/general';

export const StyledFormikInput = styled(FormikInput)`
  margin: 0;
`;

interface CustomSelectionOptionProps {
  arrayHelpers: FieldArrayRenderProps;
  hideDeleteButton?: boolean;
  index?: number;
  onPasteOverWriteHandler?: (
    event: React.ClipboardEvent<HTMLInputElement>
  ) => void;
}

export const CustomSelectionOptionComponent = ({
  arrayHelpers,
  index,
  hideDeleteButton = false,
  onPasteOverWriteHandler,
}: CustomSelectionOptionProps) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" flexWrap="nowrap" key={index}>
      {/* TODO: Warning: <FastField render> has been deprecated. Please use a child callback function instead: <FastField name={selectionOptions[7].name}>{props => ...}</FastField> instead. */}
      <StyledFastField
        name={`selectionOptions[${index}].name`}
        onPaste={onPasteOverWriteHandler}
        placeholder={t('Name')}
      />
      {!hideDeleteButton && (
        <IconButton
          color="default"
          disableBorder
          iconName="close"
          onClick={() => arrayHelpers.remove(index)}
        />
      )}
    </Box>
  );
};

export const StyledFastField = styled(FastField)<{ $error: boolean }>`
  width: 335px;
  padding: 6px 6px 5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: rgb(175, 175, 175);
`;

const skipUpdate = (
  prevProps: CustomSelectionOptionProps,
  nextProps: CustomSelectionOptionProps
) => prevProps.index === nextProps.index;

export const CustomSelectionOption = React.memo(
  CustomSelectionOptionComponent,
  skipUpdate
);
