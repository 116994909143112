import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoProjectUsers: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="collaborate"
      text={t('Add users from your organization to collaborate in a project.')}
      title={t("This project doesn't have any users yet")}
    />
  );
};
