import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { User } from '@pro4all/graphql';
import { Icon } from '@pro4all/shared/ui/icons';

export const UserChip: React.FC<{ users: User[] }> = ({ users }) => {
  const { t } = useTranslation();

  return (
    <NameWrapper>
      <Icon iconName="person" />
      {users
        .map((user) => user?.displayName)
        .filter(Boolean)
        .join(', ') || t('No user')}
    </NameWrapper>
  );
};

const NameWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
`;
