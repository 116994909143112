import React from 'react';
import styled from 'styled-components';

import { Member, useGroupsAndUsersIncludeQuery } from '@pro4all/graphql';
import { getMultiValue } from '@pro4all/shared/qc-meta';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DisplayType, List, Tag } from '@pro4all/shared/ui/general';

import type { AnswerComponent } from '../AnswerView';

export const UserSelection: AnswerComponent = ({
  children,
  tagHasMargin = true,
}) => {
  const { params } = useRouting();

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeEmail: true,
      includeIsAdmin: true,
      projectId: params.projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  if (!children) return null;

  const items = getMultiValue(children);

  const filteredMembers = members.filter((member) => items.includes(member.id));

  return filteredMembers.length && children ? (
    <ListNoFlex
      direction="horizontal"
      displayType={DisplayType.Counter}
      marginRight={5}
    >
      {filteredMembers.map((member) => (
        <TagWithSpacing
          $hasMargin={tagHasMargin}
          key={member.id}
          name={
            member.__typename === 'User'
              ? `${member.displayName} ${member.email}`
              : `${member.displayName}`
          }
          startIcon={
            member.__typename === 'User'
              ? member?.isAdmin
                ? 'admin'
                : 'personOutline'
              : 'group'
          }
        />
      ))}
    </ListNoFlex>
  ) : null;
};

const ListNoFlex = styled(List)`
  && {
    display: block;
  }
`;

const TagWithSpacing = styled(Tag)<{ $hasMargin: boolean }>`
  && {
    margin-top: ${({ $hasMargin }) => ($hasMargin ? '6px' : '0px')};
  }
`;
