import { useEffect } from 'react';

import { useDeleteDocumentsMutation } from '@pro4all/graphql';

import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const DeleteDocumentsForFailedUploads: React.FC = () => {
  const { state } = useUploaderEditorContext();
  const { documentIdsContainerCreated, documentIdsUploaded, isProcessed } =
    state;

  // The sole purpose of this component is to delete all documents for
  // failed uploads that were uploaded as a version 1 document.
  // It needs useUploaderEditorContext which is the reason why it is embedded in
  // useFormActions as a seperate component.

  const [deleteDocuments] = useDeleteDocumentsMutation();

  useEffect(() => {
    // If the files upload has been finished and there are failures, we need to remove the corresponding document of the files that failed to upload.
    if (isProcessed) {
      // Check which ids of documentIdsContainerCreated are not included in documentIdsUploaded.
      const documentIds = documentIdsContainerCreated.filter(
        (id) => !documentIdsUploaded.includes(id)
      );

      if (documentIds.length) deleteDocuments({ variables: { documentIds } });
    }
  }, [
    deleteDocuments,
    documentIdsContainerCreated,
    documentIdsUploaded,
    isProcessed,
  ]);

  return null;
};
