import { useEffect, useState } from 'react';

import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';

import * as Styled from './FilterSelect.styles';
import { FilterColumnProps } from './types';

export const useSearchValue = ({
  columnValues,
  selectedOptions,
  setUnselectedOptions,
}: Pick<
  FilterColumnProps,
  'columnValues' | 'selectedOptions' | 'setUnselectedOptions'
>) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onSearchValueKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && searchValue) {
      setSearchValue('');
    }
    event.stopPropagation();
  };

  useEffect(() => {
    const filteredOptions = columnValues.filter((columnValue) =>
      columnValue?.toLowerCase().includes(searchValue.toLowerCase())
    );
    const filtereOptionsMinusSelected = filteredOptions.filter(
      (columnValue) => !selectedOptions.includes(columnValue)
    );
    setUnselectedOptions(filtereOptionsMinusSelected);
  }, [columnValues, searchValue, selectedOptions, setUnselectedOptions]);

  const startAdornment = (
    <Styled.StyledInputAdornment position="start">
      <Icon iconName="search" />
    </Styled.StyledInputAdornment>
  );

  const endAdornment = (
    <Styled.StyledInputAdornment position="end">
      <IconButton
        color="default"
        disableBorder
        iconName="cancel"
        onClick={() => setSearchValue('')}
      />
    </Styled.StyledInputAdornment>
  );

  return {
    endAdornment,
    onSearchValueChange,
    onSearchValueKeyDown,
    searchValue,
    startAdornment,
  };
};
