import { useEffect, useRef } from 'react';

export const useStopPollingOnResponse = <T,>(
  stopPolling: () => void,
  result: T
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (result) {
      stopPolling();
    } else {
      timeoutRef.current = setTimeout(stopPolling, 10000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [result, stopPolling]);
};
