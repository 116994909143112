import React, { useCallback, useContext, useReducer } from 'react';

import { useRouting } from '@pro4all/shared/routing-utils';

import { folderTreeReducerOuter } from './folderTreeReducerOuter';
import {
  ActionType,
  DMSItem,
  MoveFolderPayload,
  MutateFolderPropPayload,
  SetInitialFolderTreePayload,
  State,
  TreeFolder,
} from './types';

type ContextType = {
  clearFolderTree: () => void;
  createDMSItem: (dmsItem: TreeFolder | DMSItem) => void;
  deleteDMSItem: (folderId: string) => void;
  moveFolder: (moveData: MoveFolderPayload) => void;
  mutateFolderProp: (mutateData: MutateFolderPropPayload) => void;
  path: string;
  restoreFolder: () => void;
  setInitialFolderTree: (
    setInitialFolderTreeData: SetInitialFolderTreePayload
  ) => void;
  setLoadingFolderData: (loading: boolean) => void;
  state: State;
};

const FolderTreeContextOuter = React.createContext(null);

export function useFolderTreeContextOuter() {
  return useContext<ContextType>(FolderTreeContextOuter);
}

export function FolderTreeProviderOuter({
  children,
}: {
  children: JSX.Element;
}) {
  const {
    params: { path = '' },
  } = useRouting();

  const [state, dispatch] = useReducer(folderTreeReducerOuter, {
    folderTree: [],
    folders: [],
    foldersPermissions: [],
    foldersPrevious: [],
    loadingFolderData: false,
  });

  // Define all actions
  const clearFolderTree = useCallback(() => {
    dispatch({
      type: ActionType.CLEAR_FOLDER_TREE,
    });
  }, []);
  const createDMSItem = useCallback((dmsItem: TreeFolder) => {
    dispatch({
      payload: dmsItem,
      type: ActionType.CREATE_DMS_ITEM,
    });
  }, []);
  const deleteDMSItem = useCallback((dmsItemId: string) => {
    dispatch({
      payload: dmsItemId,
      type: ActionType.DELETE_DMS_ITEM,
    });
  }, []);
  const moveFolder = useCallback((moveData: MoveFolderPayload) => {
    dispatch({
      payload: moveData,
      type: ActionType.MOVE_FOLDER,
    });
  }, []);
  const mutateFolderProp = useCallback(
    (renameData: MutateFolderPropPayload) => {
      dispatch({
        payload: renameData,
        type: ActionType.MUTATE_FOLDER,
      });
    },
    []
  );
  const restoreFolder = useCallback(() => {
    dispatch({
      type: ActionType.RESTORE_FOLDER,
    });
  }, []);
  const setInitialFolderTree = useCallback(
    (setInitialFolderTreeData: SetInitialFolderTreePayload) => {
      dispatch({
        payload: setInitialFolderTreeData,
        type: ActionType.SET_INITIAL_FOLDER_TREE,
      });
    },
    []
  );
  const setLoadingFolderData = useCallback((loading: boolean) => {
    dispatch({
      payload: loading,
      type: ActionType.SET_LOADING_FOLDER_DATA,
    });
  }, []);

  return (
    <FolderTreeContextOuter.Provider
      value={{
        clearFolderTree,
        createDMSItem,
        deleteDMSItem,
        moveFolder,
        mutateFolderProp,
        path,
        restoreFolder,
        setInitialFolderTree,
        setLoadingFolderData,
        state,
      }}
    >
      {children}
    </FolderTreeContextOuter.Provider>
  );
}
