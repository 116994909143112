import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { useGenerateLinkContext } from '../GenerateLinkContext';

import { ComposeMessage } from './ComposeMessage';
import { GenerateLink } from './GenerateLink';

export const ShareDocumentsModal: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { step, resourceIds, closeModal } = useGenerateLinkContext();
  const fileCount = resourceIds?.length || 0;

  const open =
    searchParams.is('action', 'share-documents') &&
    Boolean(resourceIds?.length);

  /* Add event listener to prevent space bar from bubbling up to sidebar preview */
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      if (event.code === 'Space' && open) {
        event.stopPropagation();
      }
    };

    window.document.addEventListener('keydown', onKeyDown, {
      capture: true,
    });

    return () => {
      window.document.removeEventListener('keydown', onKeyDown, {
        capture: true,
      });
    };
  }, [open]);

  return (
    <FloatingModal
      data-testid="share-files-modal"
      onClose={closeModal}
      open={open}
    >
      <FloatingModal.Header iconName="share">
        {fileCount === 1 ? t('Share document') : t('Share documents')}
      </FloatingModal.Header>
      {step === 1 && <GenerateLink />}
      {step === 2 && <ComposeMessage />}
    </FloatingModal>
  );
};
