import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const FlagNorway: React.FC<SvgIconProps> = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 22 14"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h22v14H0z" fill="#ed2939" />
    <path d="M4.4 0h5.6v14H4.4z" fill="#fff" />
    <path d="M0 6.72h22v0.56H0z" fill="#fff" />
    <path d="M5.6 0h2.8v14H5.6z" fill="#002664" />
    <path d="M0 7.28h22v0.44H0z" fill="#002664" />
  </svg>
);
