import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ValueTypeName } from '@pro4all/graphql';
import {
  FormFieldConfig,
  SchemaExtender,
  useFormConfig,
} from '@pro4all/shared/forms';

import { Reason } from './useCancelSubscriptionOptions';

export const useCancelSubscriptionConfig = () => {
  const { t } = useTranslation();

  const reasonDifferent: SchemaExtender = (schema) =>
    schema.when(['reason'], {
      is: (reason: Reason) => reason === Reason.Different,
      then: Yup.string().required(t('This field is required')),
    });

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Reason for termination', // i18n
      name: 'reason',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Different reason comment', // i18n
      name: 'reasonDifferent',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Optional explanation', // i18n
      name: 'description',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ customValidation: { reasonDifferent }, formFields });
};
