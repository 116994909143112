import { useOrganizationContext } from '@pro4all/organization/context';
import { isE2E } from '@pro4all/shared/utils';

// We need to set a private property on analyticsjs in order to be in
// accordance with what analyticsjs expects. Unfortonately this property isn't
// typed on the types package.
declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS & { _writeKey: string };
  }
}

const SEGMENT_WRITE_KEY =
  process.env.NX_SEGMENT_WRITE_KEY ?? 'process.env.SEGMENT_WRITE_KEY';

// We don't want to load analytics.js when there's no WRITE_KEY set.
if (window.analytics && !SEGMENT_WRITE_KEY.endsWith('SEGMENT_WRITE_KEY')) {
  window.analytics._writeKey = SEGMENT_WRITE_KEY;
  window.analytics.load(SEGMENT_WRITE_KEY);
  window.analytics.page();
}

const mockedAnalytics = {
  track: (...args: unknown[]) => console.log('analytics.track', args),
};

let identified: null | string = null;

export const useAnalytics = () => {
  const skip = isE2E();
  const { meData, userId } = useOrganizationContext();

  const serialized = JSON.stringify(
    userId && {
      id: userId,
    }
  );

  if (meData && identified !== serialized) {
    window.analytics.identify(userId);
    identified = serialized;
  }

  return skip ? mockedAnalytics : window.analytics;
};
