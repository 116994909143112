import type {
  TbqConfig,
  TbqFieldOption,
  TbqObjectConfig,
  TbqSetting,
} from '@pro4all/graphql';
import type { Option } from '@pro4all/shared/types';

export interface Section {
  fields: Field[];
  name: string;
}
export type Field = Section | TbqFieldOption;

type Sections = Record<string, Section>;

export function unflattenFields(configs: TbqConfig[]): Section[] {
  return configs.map(({ fields, type }) => {
    const sections: Sections = {};
    return {
      fields:
        fields?.reduce<Field[]>((fields, field) => {
          const parts = field.name.split('.');

          if (parts.length === 1) return [...fields, field];

          parts.reduce((prevName, name) => {
            const parent = sections[prevName];
            name = parent ? `${prevName}.${name}` : name;
            const section = (sections[name] = sections[name] ??
              parent ?? { fields: [], name });

            if (!fields.includes(section)) fields.push(section);
            if ('fields' in section && !section.fields.includes(field))
              section.fields.push(field);

            return name;
          }, '');

          return fields;
        }, []) ?? [],
      name: type,
    };
  });
}

export function configToRecord(
  tbqConfig: TbqObjectConfig[] | null = []
): Record<string, string> {
  return (
    tbqConfig?.reduce(
      (tbqConfig, config) =>
        Object.assign(
          tbqConfig,
          config.fields?.reduce(
            (tbqConfig, { id, value }) =>
              Object.assign(tbqConfig, { [id]: value?.id }),
            tbqConfig
          )
        ),
      {}
    ) ?? {}
  );
}

export function normalizeConfig(
  config: Record<string, string | Option>
): TbqSetting[] {
  return Object.entries(config).map(([settingId, value]) => ({
    settingId,
    valueId: typeof value === 'string' ? value : value.id,
  }));
}
