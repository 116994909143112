import React from 'react';
import { useTranslation } from 'react-i18next';

import { gqlType, Member, Organization, Role, User } from '@pro4all/graphql';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  Avatar,
  BooleanOutput,
  ColumnProps,
  getBooleanOutput,
  Link,
  MiddleEllipsis,
  StyledCellContent,
} from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

import { joinRoles, Roles } from '../components/Roles';

export const useProjectColumns = (): ColumnProps<Member>[] => {
  const { t } = useTranslation();
  const isAdminInContext = useIsAdminInContext();

  const getRole = (roles: Role[]) =>
    joinRoles(roles?.map((role) => ({ ...role, name: t(role?.name) })));

  // exclude the two last columns if is admin
  const columns = [
    {
      excludeFromExport: true,
      flexGrow: 0,
      key: 'id',
      minWidth: 48,
      render: ({ displayName }: Member) => <Avatar name={displayName} />,
      width: 48,
    },
    {
      defaultSort: true,
      filterable: true,
      getValue: (member: Member) => member.displayName,
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="displayName"
          pullFromLocalStorage
        />
      ),
      key: 'displayName',
      render: (member: Member) =>
        gqlType('User') && (
          <StyledCellContent
            active={member.__typename === 'User' ? member.active : true}
          >
            <Icon
              iconName={
                member.__typename === 'Group'
                  ? 'group'
                  : member.isAdmin
                  ? 'admin'
                  : 'personOutline'
              }
              label={<MiddleEllipsis endLength={9} text={member.displayName} />}
            />
          </StyledCellContent>
        ),
      width: 300,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={400}
          filterType={FilterHeaderType.Text}
          label="Email" // i18n
          minWidth={70}
          propertyId="email"
        />
      ),
      key: 'email',
      render: (member: Member) =>
        member.__typename === 'User' && (
          <StyledCellContent active={member.active}>
            <MiddleEllipsis endLength={9} text={member.email} />
          </StyledCellContent>
        ),
      width: 400,
    },
    {
      filterable: true,
      getValue: ({ roles }: Member) => getRole(roles),
      headerComponent: (
        <FilterHeader<User, Role>
          customCallbackId="roles.name"
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(roles: Role[]) => getRole(roles)}
          label="Role"
          minWidth={75}
          propertyId="roles"
          translateOptions
        />
      ),
      key: 'roles.name',
      minWidth: 75,
      render: (member: Member) => (
        <StyledCellContent
          active={member.__typename === 'User' ? member.active : true}
        >
          <Roles roles={member.roles} />
        </StyledCellContent>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ active }: Member) =>
        t(getBooleanOutput({ value: active })) as string,
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={100}
          filterType={FilterHeaderType.Select}
          label="Active" // i18n
          minWidth={70}
          propertyId="active"
          translateOptions
        />
      ),
      key: 'active',
      render: (member: Member) =>
        member.__typename === 'User' && (
          <StyledCellContent active={member.active}>
            <BooleanOutput value={member.active} />
          </StyledCellContent>
        ),
      width: 100,
    },
    {
      filterable: true,
      getValue: ({ organization }: Member) => organization?.name,
      headerComponent: (
        <FilterHeader<User, Organization>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Organization" // i18n
          minWidth={70}
          propertyId="organization"
          subPropertyId="name"
        />
      ),
      key: 'organization.name',
      render: (member: User) => (
        <MiddleEllipsis endLength={9} text={member.organization?.name ?? ''} />
      ),
      width: 200,
    },
  ];

  if (isAdminInContext) {
    columns.push(
      {
        filterable: true,
        headerComponent: (
          <FilterHeader<User, {}>
            defaultWidth={200}
            filterType={FilterHeaderType.Select}
            label="Job title" // i18n
            minWidth={70}
            propertyId="jobTitle"
          />
        ),
        key: 'jobTitle',
        render: (member: User) => (
          <MiddleEllipsis endLength={9} text={member.jobTitle ?? ''} />
        ),
        width: 200,
      },
      {
        filterable: true,
        headerComponent: (
          <FilterHeader<User, {}>
            defaultWidth={200}
            filterType={FilterHeaderType.Select}
            label="Phone number" // i18n
            minWidth={70}
            propertyId="phoneNumber"
          />
        ),
        key: 'phoneNumber',
        render: (member: User) => (
          <StyledCellContent active={isAdminInContext}>
            <Link href={`tel:${member.phoneNumber}`}>{member.phoneNumber}</Link>
          </StyledCellContent>
        ),
        width: 200,
      }
    );
  }

  return columns;
};
