import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  useRestoreDocumentMutation,
  useRestoreDocumentsMutation,
  useRestoreDocumentVersionMutation,
  useRestoreFolderMutation,
} from '@pro4all/graphql';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const DocumentDeleted: React.FC<{
  documentId: string;
  documentName: string;
  onComplete?: () => void;
  restoreItems: (ids: string[]) => void;
  versionId?: string;
}> = ({ documentId, documentName, restoreItems, versionId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [restoreDocument] = useRestoreDocumentMutation();
  const [restoreDocumentVersion] = useRestoreDocumentVersionMutation();

  const entityTypeTranslation = versionId
    ? EntityTypeTranslation.DocumentVersion
    : EntityTypeTranslation.Document;

  const onRestoreDocument = async () => {
    let message = (
      <ItemChangedMessage
        description={MessageAction.Restore}
        entityName={documentName}
        entityTypeTranslation={entityTypeTranslation}
      />
    );
    try {
      if (versionId) {
        await restoreDocumentVersion({
          variables: { documentId, versionId },
        });
        restoreItems([versionId]);
      } else {
        await restoreDocument({
          variables: { id: documentId },
        });
        restoreItems([documentId]);
      }
    } catch (e) {
      message = t('Something went wrong');
    }
    enqueueSnackbar(message);
  };

  const undoAction = (
    <Button color="inherit" onClick={onRestoreDocument} size="small">
      {t('Undo')}
    </Button>
  );

  return (
    <ItemChangedMessage
      action={undoAction}
      description={MessageAction.Delete}
      entityName={documentName}
      entityTypeTranslation={entityTypeTranslation}
    />
  );
};

export const DocumentsDeleted: React.FC<{
  count: number;
  documentIds: string[];
  restoreItems: (ids: string[]) => void;
}> = ({ count, documentIds, restoreItems }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [restoreDocuments] = useRestoreDocumentsMutation();

  const onRestoreDocuments = async () => {
    try {
      const { data } = await restoreDocuments({
        variables: { documentIds },
      });

      restoreItems(data?.restoreDocuments?.successful);

      const succeeded = data?.restoreDocuments?.successful.length;
      const failed = data?.restoreDocuments?.unsuccessful.length;

      if (succeeded > 0) {
        enqueueSnackbar(
          t('Restored {{count}} document(s)', { count: succeeded })
        );
      }
      if (failed > 0) {
        enqueueSnackbar(
          t('Restoring {{count}} document(s) failed', { count: failed })
        );
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const undo = (
    <Button color="inherit" onClick={onRestoreDocuments} size="small">
      {t('Undo')}
    </Button>
  );

  return (
    <Alert action={undo} severity="success">
      {t('Deleted {{count}} document(s)', { count })}
    </Alert>
  );
};

export const FolderDeleted: React.FC<{
  folderId: string;
  folderName: string;
  restoreFolderToUpdateState: () => void;
}> = ({ folderId, folderName, restoreFolderToUpdateState }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [restoreFolder] = useRestoreFolderMutation();

  const entityTypeTranslation = EntityTypeTranslation.Folder;

  const onRestoreFolder = async () => {
    let message = (
      <ItemChangedMessage
        description={MessageAction.Restore}
        entityName={folderName}
        entityTypeTranslation={entityTypeTranslation}
      />
    );
    try {
      await restoreFolder({
        variables: { id: folderId },
      });
      restoreFolderToUpdateState();
    } catch (e) {
      message = t('Something went wrong');
    }
    enqueueSnackbar(message);
  };

  const undoAction = (
    <Button color="inherit" onClick={onRestoreFolder} size="small">
      {t('Undo')}
    </Button>
  );

  return (
    <ItemChangedMessage
      action={undoAction}
      description={MessageAction.Delete}
      entityName={folderName}
      entityTypeTranslation={entityTypeTranslation}
    />
  );
};

export const SmartFolderCreatedOrUpdated: React.FC<{
  isUpdate: boolean;
  smartFolderName: string;
}> = ({ smartFolderName, isUpdate }) => {
  const suffix = isUpdate ? MessageAction.Update : MessageAction.Create;

  return (
    <ItemChangedMessage
      description={suffix}
      entityName={smartFolderName}
      entityTypeTranslation={EntityTypeTranslation.SmartFolder}
    />
  );
};
