import styled from 'styled-components';

import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const PermissionCellContainer = styled(Tooltip)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
