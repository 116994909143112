import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddCondition: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M7.79 4H3.5C3.22 4 3 4.22 3 4.5V8.79C3 9.24 3.54 9.46 3.85 9.14L5.29 7.7L10 12.4V19C10 19.55 10.45 20 11 20C11.55 20 12 19.55 12 19V12C12 11.74 11.89 11.48 11.71 11.29L6.71 6.28L8.15 4.84C8.46 4.54 8.24 4 7.79 4ZM13.8502 4.85L15.2902 6.29L12.4102 9.17L13.8302 10.59L16.7102 7.71L18.1502 9.15C18.4602 9.46 19.0002 9.24 19.0002 8.79V4.5C19.0002 4.22 18.7802 4 18.5002 4H14.2102C13.7602 4 13.5402 4.54 13.8502 4.85ZM17 19H15C14.45 19 14 18.55 14 18C14 17.45 14.45 17 15 17H17V15C17 14.45 17.45 14 18 14C18.55 14 19 14.45 19 15V17H21C21.55 17 22 17.45 22 18C22 18.55 21.55 19 21 19H19V21C19 21.55 18.55 22 18 22C17.45 22 17 21.55 17 21V19Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
