import React from 'react';
import ContentLoader from 'react-content-loader';

export const AiLoader = () => (
  <ContentLoader
    backgroundColor="#c87a08"
    foregroundColor="#f5c55b"
    height={80}
    speed={2}
    viewBox="0 0 100% 80"
    width="100%"
  >
    <rect height="11" rx="3" ry="3" width="54" x="1" y="1" />
    <rect height="11" rx="3" ry="3" width="113" x="66" y="1" />
    <rect height="11" rx="3" ry="3" width="43" x="90" y="43" />
    <rect height="11" rx="3" ry="3" width="58" x="139" y="43" />
    <rect height="11" rx="3" ry="3" width="81" x="1" y="43" />
    <rect height="11" rx="3" ry="3" width="113" x="149" y="21" />
    <rect height="11" rx="3" ry="3" width="140" x="1" y="21" />
    <rect height="11" rx="3" ry="3" width="39" x="192" y="1" />
    <rect height="11" rx="3" ry="3" width="113" x="240" y="1" />
    <rect height="11" rx="3" ry="3" width="54" x="204" y="1" />
    <rect height="11" rx="3" ry="3" width="113" x="269" y="1" />
    <rect height="11" rx="3" ry="3" width="43" x="293" y="43" />
    <rect height="11" rx="3" ry="3" width="58" x="342" y="43" />
    <rect height="11" rx="3" ry="3" width="81" x="204" y="43" />
    <rect height="11" rx="3" ry="3" width="113" x="352" y="21" />
    <rect height="11" rx="3" ry="3" width="140" x="204" y="21" />
    <rect height="11" rx="3" ry="3" width="39" x="395" y="1" />
    <rect height="11" rx="3" ry="3" width="70" x="443" y="1" />
    <rect height="11" rx="3" ry="3" width="81" x="411" y="42" />
    <rect height="11" rx="3" ry="3" width="113" x="151" y="64" />
    <rect height="11" rx="3" ry="3" width="140" x="1" y="64" />
    <rect height="11" rx="3" ry="3" width="113" x="354" y="64" />
    <rect height="11" rx="3" ry="3" width="140" x="206" y="64" />
  </ContentLoader>
);
