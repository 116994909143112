import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const WithdrawRejection: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12.3717 16C10.2588 16 8.3453 16.7893 6.87029 18.073L5.36339 16.5661C4.86109 16.0638 4 16.4146 4 17.1242V21.5811C4 22.0196 4.35879 22.3784 4.7973 22.3784H9.25423C9.96383 22.3784 10.3226 21.5173 9.82031 21.015L8.29746 19.4922C9.40571 18.5673 10.8169 17.9933 12.3797 17.9933C14.8991 17.9933 17.0758 19.4603 18.1123 21.5811C18.3275 22.0276 18.8378 22.2509 19.3082 22.0914C19.8743 21.908 20.1613 21.2622 19.9062 20.72C18.5348 17.9295 15.6805 16 12.3717 16Z" />
    <path
      clipRule="evenodd"
      d="M12 2C8.688 2 6 4.688 6 8C6 11.312 8.688 14 12 14C15.312 14 18 11.312 18 8C18 4.688 15.312 2 12 2ZM12 12.8C9.348 12.8 7.2 10.652 7.2 7.99999C7.2 6.88999 7.578 5.86999 8.214 5.05999L14.94 11.786C14.13 12.422 13.11 12.8 12 12.8ZM9.05977 4.214L15.7858 10.94C16.4218 10.13 16.7998 9.11 16.7998 8C16.7998 5.348 14.6518 3.2 11.9998 3.2C10.8898 3.2 9.86977 3.578 9.05977 4.214Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
