import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Simplify: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M232.419 56.04a117.828 117.828 0 00-8.148 1.727c1.132-.264 2.262-.517 3.388-.759h.027c1.986-.424 3.964-.812 5.935-1.164l1.073-.185a81.79 81.79 0 00-2.275.38zM42.885 82.927c14.978 5.851 36.693 11.864 61.762 11.864 40.672 0 61.268-10.761 81.185-21.168 6.707-3.501 13.278-6.938 20.538-9.948a150.969 150.969 0 00-10.541 4.797c-9.452 4.73-18.11 8.998-27.655 12.049l-.349.112c-10.283 3.292-21.651 5.098-36.157 4.54h-.222l-.419-.017c-.469-.018-.942-.042-1.419-.067l-.915-.05-.923-.057a160.278 160.278 0 01-5.714-.47c-5.331.492-11.129 1.023-17.439 1.023-27.767 0-50.225-5.725-59.93-11.094a4.467 4.467 0 00-6.411 4.025c0 1.193.38 2.323 1.341 2.927.786.482 3.268 1.534 3.268 1.534z"
      fill={colors.secondary}
    />
    <path
      d="M195.652 68.126c-9.342 4.944-17.954 9.307-27.478 12.395 9.545-3.05 18.203-7.32 27.655-12.05a150.969 150.969 0 0110.541-4.796l.405-.164c.284-.118.568-.23.853-.343a122.515 122.515 0 0116.633-5.4c-9.663 2.258-19.415 5.494-28.609 10.358z"
      fill={colors.secondary}
    />
    <path
      d="M122.057 84.505c1.967.195 3.872.351 5.714.469l.923.056.915.051c15.493.805 27.432-1.043 38.217-4.448l.349-.112c9.545-3.05 18.203-7.32 27.655-12.05a150.925 150.925 0 0110.541-4.796c.136-.056.268-.113.405-.164.284-.118.568-.23.853-.343a129.904 129.904 0 0119.63-6.071l.427-.094a178.07 178.07 0 014.273-.878l.481-.088.703-.129c.432-.075 1.32-.225 1.76-.295 2.827-.445 5.097-2.415 5.097-5.266 0-2.852-2.326-5.881-5.106-5.267-.649.097-1.29.196-1.928.3-24.106 3.915-40.133 12.5-57.209 22.182C153.822 79.984 123.506 84.303 122.1 84.5h-.046l.003.006z"
      fill={colors.primary}
    />
    <path
      d="M6.903 62.218c2.57 1.261 5.564.963 7.206-1.373 1.642-2.337 1.49-5.803-1.153-7.244 0 0-5.052-3.281-5.452-3.488-2.57-1.26-4.8-.6-6.439 1.739-1.639 2.34-1.486 5.803 1.157 7.243-.003.011 4.279 2.927 4.681 3.123zM25.112 74.245c2.57 1.261 5.564.963 7.206-1.376 1.642-2.34 1.489-5.8-1.154-7.244 0 0-5.052-3.283-5.451-3.487-2.57-1.261-4.8-.599-6.44 1.74-1.638 2.34-1.485 5.804 1.157 7.245.008.008 4.29 2.926 4.682 3.122z"
      fill={colors.secondary}
    />
  </SvgIcon>
);
