import { Option } from '@pro4all/shared/types';
import { RadioOption } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';

export interface Permission {
  label: string;
  permissionBase: string;
  values?: RadioOption[];
}

export interface PermissionCategory {
  description: string;
  name: string;
  permissions: Permission[];
}

export const permissionIconMap: { [key: string]: IconName } = {
  all: 'TaskAltRounded',
  assigned: 'inTaskAssigned',
  none: 'notInterested',
  own: 'personCalendar',
  ownAssigned: 'personPin',
};

const all = (permissionBase: string): Option => ({
  iconName: permissionIconMap['all'],
  id: permissionBase + '-all',
  label: 'All',
});

const ownAssigned = (permissionBase: string): Option => ({
  iconName: permissionIconMap['ownAssigned'],
  id: permissionBase + '-ownAssigned',
  label: 'Created by, or in tasks assigned to the user(s)',
});

const none = (permissionBase: string): Option => ({
  iconName: permissionIconMap['none'],
  id: permissionBase + '-none',
  label: 'None',
});

const own = (permissionBase: string, ownLabelOverwrite?: string): Option => ({
  iconName: permissionIconMap['own'],
  id: permissionBase + '-own',
  label: ownLabelOverwrite ?? 'Created by the user(s)',
});

const assigned = (permissionBase: string): Option => ({
  iconName: permissionIconMap['assigned'],
  id: permissionBase + '-assigned',
  label: 'In tasks assigned to the user(s)',
});

const ownAssignedAndAll = (permissionBase: string): Option[] => [
  ownAssigned(permissionBase),
  all(permissionBase),
];

const noneOwnCreatedAll = (permissionBase: string): Option[] => [
  none(permissionBase),
  own(permissionBase),
  all(permissionBase),
];

const ownOwnAssignedAll = (permissionBase: string): Option[] => [
  own(permissionBase),
  ownAssigned(permissionBase),
  all(permissionBase),
];

const noneAll = (permissionBase: string): Option[] => [
  none(permissionBase),
  all(permissionBase),
];

const assignedAndAll = (permissionBase: string): Option[] => [
  assigned(permissionBase),
  all(permissionBase),
];
const ownAll = (permissionBase: string): Option[] => [
  own(permissionBase),
  all(permissionBase),
];
const noneOwnAll = (
  permissionBase: string,
  ownLabelOverwrite?: string
): Option[] => [
  none(permissionBase),
  own(permissionBase, ownLabelOverwrite),
  all(permissionBase),
];

export const qcPermissionCategories: PermissionCategory[] = [
  {
    description: 'PermissionsDescriptionForms',
    name: 'Forms',
    permissions: [
      {
        label: 'Create new',
        permissionBase: 'form-create',
        values: assignedAndAll('form-create'),
      },
      {
        label: 'View',
        permissionBase: 'form-read',
        values: ownAssignedAndAll('form-read'),
      },
      {
        label: 'Update existing',
        permissionBase: 'form-update',
        values: ownAssignedAndAll('form-update'),
      },
      {
        label: 'Delete',
        permissionBase: 'form-delete',
        values: noneOwnAll('form-delete'),
      },
    ],
  },
  {
    description: 'PermissionsDescriptionSnags',
    name: 'Snags',
    permissions: [
      {
        label: 'Create new',
        permissionBase: 'snag-create',
        values: assignedAndAll('snag-create'),
      },
      {
        label: 'View',
        permissionBase: 'snag-read',
        values: ownAssignedAndAll('snag-read'),
      },
      {
        label: 'Update existing',
        permissionBase: 'snag-update',
        values: ownAssignedAndAll('snag-update'),
      },
      {
        label: 'Delete',
        permissionBase: 'snag-delete',
        values: noneOwnAll('snag-delete'),
      },
    ],
  },
  {
    description: 'PermissionsDescriptionDrawings',
    name: 'Drawings',
    permissions: [
      {
        label: 'Upload new',
        permissionBase: 'floor-create',
        values: noneAll('floor-create'),
      },
      {
        label: 'View',
        permissionBase: 'floor-read',
        values: ownAssignedAndAll('floor-read'),
      },
      {
        label: 'Update existing',
        permissionBase: 'floor-update',
        values: ownAll('floor-update'),
      },
      {
        label: 'Delete',
        permissionBase: 'floor-delete',
        values: noneOwnAll('floor-delete'),
      },
    ],
  },
  //TEMPORARILY TURNED OFF REPORTS AS REQUESTED BY THE PO

  // {
  //   description: 'PermissionsDescriptionReports',
  //   name: 'Reports',
  //   permissions: [
  //     {
  //       label: 'Generate reports',
  //       permissionBase: 'report-create',
  //       values: [
  //         {
  //           iconName: permissionIconMap['all'],
  //           id: 'report-create',
  //           label: 'All',
  //         },
  //         {
  //           iconName: permissionIconMap['none'],
  //           id: '',
  //           label: 'None',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const getTaskTypePermissionCategories = ({
  hasDmsLicense,
  hasQualityControlLicense,
  hasObjectsFeature,
  hasQcFeature,
}: {
  hasDmsLicense: boolean;
  hasObjectsFeature: boolean;
  hasQcFeature: boolean;
  hasQualityControlLicense: boolean;
}): PermissionCategory[] =>
  [
    hasDmsLicense && {
      description: 'PermissionsDescriptionDocTasks',
      name: 'Document tasks',
      permissions: [
        {
          label: 'Create and assign',
          permissionBase: 'taskType-document-create',
          values: noneOwnAll('taskType-document-create', 'Assign to self'),
        },
        {
          label: 'View',
          permissionBase: 'taskType-document-read',
          values: ownAssignedAndAll('taskType-document-read'),
        },
        {
          label: 'Update existing',
          permissionBase: 'taskType-document-update',
          values: ownOwnAssignedAll('taskType-document-update'),
        },
        {
          label: 'Delete',
          permissionBase: 'taskType-document-delete',
          values: noneOwnAll('taskType-document-delete'),
        },
      ],
    },
    hasQualityControlLicense &&
      hasQcFeature && {
        description: 'PermissionsDescriptionQcTasks',
        name: 'Quality control tasks',
        permissions: [
          {
            label: 'Create and assign',
            permissionBase: 'taskType-quality-create',
            values: noneOwnAll('taskType-quality-create', 'Assign to self'),
          },
          {
            label: 'View',
            permissionBase: 'taskType-quality-read',
            values: ownAssignedAndAll('taskType-quality-read'),
          },
          {
            label: 'Update existing',
            permissionBase: 'taskType-quality-update',
            values: ownOwnAssignedAll('taskType-quality-update'),
          },
          {
            label: 'Delete',
            permissionBase: 'taskType-quality-delete',
            values: noneOwnAll('taskType-quality-delete'),
          },
        ],
      },
    hasQualityControlLicense &&
      hasQcFeature && {
        description: 'PermissionsDescriptionResolveTasks',
        name: 'Resolve tasks',
        permissions: [
          {
            label: 'Create and assign',
            permissionBase: 'taskType-resolve-create',
            values: noneOwnAll('taskType-resolve-create', 'Assign to self'),
          },
          {
            label: 'View',
            permissionBase: 'taskType-resolve-read',
            values: ownAssignedAndAll('taskType-resolve-read'),
          },
          {
            label: 'Update existing',
            permissionBase: 'taskType-resolve-update',
            values: ownOwnAssignedAll('taskType-resolve-update'),
          },
          {
            label: 'Delete',
            permissionBase: 'taskType-resolve-delete',
            values: noneOwnCreatedAll('taskType-resolve-delete'),
          },
        ],
      },
    hasQcFeature &&
      hasObjectsFeature && {
        description: 'PermissionsDescriptionTbqScanTasks',
        name: 'TBQ tasks',
        permissions: [
          {
            label: 'Create and assign',
            permissionBase: 'taskType-tbqScan-create',
            values: noneOwnAll('taskType-tbqScan-create', 'Assign to self'),
          },
          {
            label: 'View',
            permissionBase: 'taskType-tbqScan-read',
            values: ownAssignedAndAll('taskType-tbqScan-read'),
          },
          {
            label: 'Update existing',
            permissionBase: 'taskType-tbqScan-update',
            values: ownOwnAssignedAll('taskType-tbqScan-update'),
          },
          {
            label: 'Delete',
            permissionBase: 'taskType-tbqScan-delete',
            values: noneOwnAll('taskType-tbqScan-delete'),
          },
        ],
      },
    hasObjectsFeature &&
      hasQcFeature && {
        description: 'PermissionsDescriptionTbqResolveTasks',
        name: 'TBQ resolve tasks',
        permissions: [
          {
            label: 'Create new',
            permissionBase: 'taskType-tbqResolve-create',
            values: noneAll('taskType-tbqResolve-create'),
          },
          {
            label: 'View',
            permissionBase: 'taskType-tbqResolve-read',
            values: ownAssignedAndAll('taskType-tbqResolve-read'),
          },
          {
            label: 'Update existing',
            permissionBase: 'taskType-tbqResolve-update',
            values: ownOwnAssignedAll('taskType-tbqResolve-update'),
          },
          {
            label: 'Delete',
            permissionBase: 'taskType-tbqResolve-delete',
            values: noneOwnAll('taskType-tbqResolve-delete'),
          },
        ],
      },
  ].filter(Boolean);
