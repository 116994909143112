import { useCallback, useEffect, useRef, useState } from 'react';

import { Folder, useMyProjectsLazyQuery } from '@pro4all/graphql';
import {
  useFoldersLazyQuery,
  useProjectByFolderLazyQuery,
} from '@pro4all/graphql';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useGenericContext } from '@pro4all/shared/providers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableRowToggle } from '@pro4all/shared/ui/general';

import { useFolderTreeContextOuter } from './FolderTreeProviderOuter';
import { DMSItem, DMSProjectLink, isFolder, TreeFolder } from './types';

interface Props {
  inherit?: boolean;
  projectId?: string;
  userId?: string;
}

type GetFolders = {
  folders: Folder[];
  refetchFolders?: () => void;
};

export function getJustFoldersTree(treeFolder: TreeFolder): TreeFolder {
  if (!isFolder(treeFolder)) {
    return null;
  }

  if (treeFolder.children?.length > 0) {
    const newChildren = treeFolder.children
      .map((child) => getJustFoldersTree(child as TreeFolder))
      .filter(Boolean);

    return {
      ...treeFolder,
      children: newChildren,
      hasSubFolders: newChildren.length > 0,
    };
  } else {
    return treeFolder;
  }
}

export const useFolderTree = ({ inherit, projectId, userId }: Props) => {
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const userIdRef = useRef(userId);
  const initialFetchDone = useRef(false);
  const initialFetchProjectsDataDone = useRef(false);
  const currentVisibleProjectIds = useRef<string[]>(null);

  const {
    clearFolderTree,
    setInitialFolderTree,
    setLoadingFolderData,
    state: {
      folders: foldersInState,
      foldersPermissions: foldersPermissionsInState,
    },
  } = useFolderTreeContextOuter();

  const [getMyProjects] = useMyProjectsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [getFoldersByParentId] = useFoldersLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [getProjectLinksByFolder] = useProjectByFolderLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const {
    params: { path },
  } = useRouting();

  const {
    state: { permissionsSaved },
    togglePermissionsSaved,
  } = useGenericContext();

  const { setLocalStorageItem: setLocalStorageExpandedKeys } = useLocalStorage<
    string[]
  >({
    key: `prostream-docs.${projectId ? `${projectId}.` : ''}expandedRowKeys`,
  });

  const setFoldersInLocalState = useCallback(
    ({
      folders,
      parentFolderIds,
    }: {
      folders: DMSItem[];
      parentFolderIds: string[];
    }) => {
      const folderIds = folders.map(({ id }) => id);

      const foldersUpdated = [
        // Remove existing folders from the list
        ...foldersInState.filter(
          (folder) =>
            !parentFolderIds.includes(folder.parentFolderId || 'top') &&
            !folderIds.includes(folder.id)
        ),
        // Add the new folders
        ...folders,
      ];

      const foldersPermissionsUpdated = [
        // Remove existing folders from the list
        ...foldersPermissionsInState.filter(
          (folder) =>
            !parentFolderIds.includes(folder.parentFolderId || 'top') &&
            !folderIds.includes(folder.id)
        ),
      ];

      setInitialFolderTree({
        folders: foldersUpdated,
        foldersPermissions: foldersPermissionsUpdated,
        path,
      });
    },
    [foldersInState, foldersPermissionsInState, path, setInitialFolderTree]
  );

  const getFolders = useCallback(
    async (parentFolderIds: string[] = []): Promise<GetFolders> => {
      try {
        const { refetch: refetchFolders, data } = await getFoldersByParentId({
          variables: {
            entityId: userId,
            inherit,
            parentFolderIds,
            projectId,
          },
        });

        return {
          folders: data.folders,
          refetchFolders,
        };
      } catch (error) {
        setLoading(false);
        setNoAccess(true);
        return { folders: [] };
      }
    },
    [getFoldersByParentId, inherit, projectId, userId]
  );

  const getFoldersWithProjects = useCallback(
    async ({
      folders,
      id,
      takeExpandedFolderIds = false,
    }: {
      folders?: Folder[];
      id?: string;
      takeExpandedFolderIds?: boolean;
    }) => {
      // For the permission matrix, we are not interested in projects data.
      if (!inherit) return folders as DMSItem[];

      const ids = takeExpandedFolderIds
        ? expandedFolderIds
        : id
        ? [id]
        : folders.map((folder) => folder.id);

      const { data } = await getProjectLinksByFolder({
        variables: { ids },
      });

      // Create and initialize a structure to get project id
      // and name using the folder id.
      const projectByFolderId: {
        [index: string]: {
          id: string;
          linkId: string;
          name: string;
        }[];
      } = {};

      if (!currentVisibleProjectIds.current) {
        const myProjects = await getMyProjects();
        currentVisibleProjectIds.current = myProjects.data.projects.map(
          (project) => project.id
        );
      }

      const availableProjects =
        data?.projectsByFolder?.data?.filter((item) =>
          currentVisibleProjectIds.current.includes(item.projectId)
        ) ?? [];

      for (const item of availableProjects) {
        // If there are more than one project by folder id
        // then the new project it's added to a list.
        if (projectByFolderId[item.folderId]) {
          projectByFolderId[item.folderId].push({
            id: item.projectId,
            linkId: item.linkId,
            name: item.projectName,
          });
        } else {
          projectByFolderId[item.folderId] = [
            { id: item.projectId, linkId: item.linkId, name: item.projectName },
          ];
        }
      }

      const projectList: DMSProjectLink[] = folders
        .flatMap((folder) =>
          projectByFolderId[folder.id]
            ? projectByFolderId[folder.id].map((project) => ({
                id: project.linkId,
                name: project.name,
                parentFolderId: folder.id,
                path: `${folder.path}/${project.name}`,
                projectId: project.id,
              }))
            : null
        )
        .filter(Boolean);

      // It's necessary to set the "hasSubFolders" property to true in all
      // folders that have a project link to be able to expand the folder and
      // see the link even if this folder does not have another folder as a sub-folder
      const foldersWithProjectsAsSubfolders = folders.map((folder) =>
        projectByFolderId[folder.id]
          ? { ...folder, hasSubFolders: true }
          : folder
      );

      return [...foldersWithProjectsAsSubfolders, ...projectList] as DMSItem[];
    },
    [getProjectLinksByFolder]
  );

  const includedInFolderList = (id: string) =>
    foldersInState.map(({ id }) => id).includes(id);

  const onToggle = useCallback(
    async (id: string, toggled: boolean) => {
      // Only if the folder is being expanded we wanna fetch new data (subfolders and project links)
      if (!toggled) {
        const parentFolderIds = [id];
        setLoadingFolderData(true);
        const { folders } = await getFolders(parentFolderIds);
        const toggledFolder = foldersInState.find((folder) => folder.id === id); // The folder for which we wanna fetch projects link data.
        const foldersWithProjects = await getFoldersWithProjects({
          folders: [...folders, toggledFolder],
          id,
        });
        setLoadingFolderData(false);
        setFoldersInLocalState({
          folders: foldersWithProjects,
          parentFolderIds,
        });
      }
    },
    [
      foldersInState,
      getFolders,
      setFoldersInLocalState,
      getFoldersWithProjects,
      setLoadingFolderData,
    ]
  );

  const { toggle, toggles: expandedRowKeys } = useTableRowToggle({
    filter: includedInFolderList,
    key: `prostream-docs.${projectId ? `${projectId}.` : ''}expandedRowKeys`,
    onToggle,
  });

  const [expandedFolderIds, setExpandedFolderIds] = useState(expandedRowKeys);

  const setExpandedRowKeys = (pathIds: string[]) => {
    if (pathIds) {
      initialFetchDone.current = false;
      initialFetchProjectsDataDone.current = false;
      const pathIdsMinusLast = pathIds.slice(0, -1);
      setLocalStorageExpandedKeys(pathIdsMinusLast);
      setExpandedFolderIds(pathIdsMinusLast);
    }
  };

  useEffect(() => {
    setExpandedFolderIds(expandedRowKeys);
  }, [expandedRowKeys]);

  const reloadFolders = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getFoldersByParentId({
        variables: {
          entityId: userId,
          inherit,
          parentFolderIds: ['top', ...expandedFolderIds],
          projectId,
        },
      });
      const foldersWithProjects = await getFoldersWithProjects({
        folders: data.folders,
      });

      setInitialFolderTree({
        folders: foldersWithProjects,
        path,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [
    expandedFolderIds,
    getFoldersByParentId,
    inherit,
    path,
    projectId,
    setInitialFolderTree,
    userId,
    getFoldersWithProjects,
  ]);

  useEffect(() => {
    clearFolderTree();
    initialFetchDone.current = false;
    initialFetchProjectsDataDone.current = false;
  }, [projectId]);

  useEffect(() => {
    const parentFolderIds = ['top', ...expandedFolderIds];

    const trigger = async () => {
      // Prevent a reload of all folders on every folder click.
      if (!initialFetchDone.current) {
        setLoadingFolderData(true);
        const { folders } = await getFolders(parentFolderIds);
        setLoadingFolderData(false);

        initialFetchDone.current = true;

        setFoldersInLocalState({
          folders: folders as DMSItem[],
          parentFolderIds,
        });
      }
      setLoading(false);
    };

    trigger();
  }, [
    expandedFolderIds,
    getFolders,
    path,
    setFoldersInLocalState,
    setLoadingFolderData,
  ]);

  useEffect(() => {
    const parentFolderIds = ['top', ...expandedFolderIds];

    const trigger = async () => {
      if (!initialFetchProjectsDataDone.current && foldersInState.length > 1) {
        setLoadingFolderData(true);
        const foldersWithProjects = await getFoldersWithProjects({
          folders: foldersInState,
          takeExpandedFolderIds: true,
        });
        setLoadingFolderData(false);

        initialFetchProjectsDataDone.current = true;

        setFoldersInLocalState({
          folders: foldersWithProjects,
          parentFolderIds,
        });
      }
    };

    trigger();
  }, [
    expandedFolderIds,
    foldersInState,
    getFoldersWithProjects,
    path,
    setFoldersInLocalState,
    setLoadingFolderData,
  ]);

  useEffect(() => {
    setInitialFolderTree({ path }); // Call is only done to mutate selected folder in state prop 'folders'.
  }, [path, setInitialFolderTree]);

  useEffect(() => {
    if ((userId !== userIdRef.current || permissionsSaved) && !inherit) {
      // We only want to reload if the context of the folder tree is the permission matrix (so in case !inherit).
      userIdRef.current = userId;
      reloadFolders();
      togglePermissionsSaved();
    }
  }, [
    inherit,
    permissionsSaved,
    reloadFolders,
    togglePermissionsSaved,
    userId,
  ]);

  return {
    expandedRowKeys: expandedFolderIds,
    loading,
    noAccess,
    reloadFolders,
    setExpandedRowKeys,
    toggle,
  };
};
