import { FolderPermission } from '@pro4all/graphql';

const documentsPermissionsOrder = [
  FolderPermission.ReadAllDocuments,
  FolderPermission.ReadOwnAndFinalized,
  FolderPermission.ReadOwn,
];
const versionsPermissionsOrder = [
  FolderPermission.ReadAllVersions,
  FolderPermission.ReadLastVersionAndApproved,
  FolderPermission.ReadApproval,
  FolderPermission.ReadLastApproval,
  FolderPermission.ReadLastVersion,
  FolderPermission.ReadOwnVersions,
];
const deletePermissionsOrder = [
  FolderPermission.DeleteContent,
  FolderPermission.DeleteOwnContent,
];
const updatePermissionsOrder = [
  FolderPermission.UpdateContent,
  FolderPermission.UpdateOwn,
];

/*
This method removes permissions that should be excluded based on the existence of another permission.
This goes for all permissions in the dropdowns (`Documents`, `Versions`, `Delete` and `Update`).
Let's say the passed in permissions array contains both `DeleteContent` and `DeleteOwnContent`
We  keep `DeleteContent` and remove `DeleteOwnContent`.
*/
export const removeExclusiveFolderPermissions = ({
  permissions,
}: {
  permissions: FolderPermission[];
}) => {
  let permissionsResponse: FolderPermission[] = [];

  // Check all permissions and add a permission to the response only if the permission is not in conflict with another permission.
  permissions.forEach((permission) => {
    if (
      documentsPermissionsOrder.includes(permission) ||
      versionsPermissionsOrder.includes(permission) ||
      deletePermissionsOrder.includes(permission) ||
      updatePermissionsOrder.includes(permission)
    ) {
      const exclusivePermissionsOrders = [
        documentsPermissionsOrder,
        versionsPermissionsOrder,
        deletePermissionsOrder,
        updatePermissionsOrder,
      ];
      exclusivePermissionsOrders.forEach((exclusivePermissionsOrder) => {
        permissionsResponse = [
          ...permissionsResponse,
          ...addExclusivePermission({
            exclusivePermissionsOrder,
            permissions,
          }),
        ];
      });
    } else {
      // This is a non-exclusive (checkbox) permission. Add it to the response.
      permissionsResponse.push(permission);
    }
  });

  return [...new Set(permissionsResponse)]; // Remove duplicates.
};

/*
This method scans for multiple permissions in the permissions array of the same exclusive type
and returns only the highest permission.
*/
const addExclusivePermission = ({
  exclusivePermissionsOrder,
  permissions,
}: {
  exclusivePermissionsOrder: FolderPermission[];
  permissions: FolderPermission[];
}) => {
  const response: FolderPermission[] = [];
  // Get all related exclusive permissions.
  const exclusivePermissions = permissions.filter((permission) =>
    exclusivePermissionsOrder.includes(permission)
  );

  if (exclusivePermissions.length > 1) {
    // The permissions array contains multiple exclusive permission. We can only keep one.

    let indexToAdd: number = null;
    // Add the highest permission.
    exclusivePermissions.forEach((updatePermission) => {
      const index = exclusivePermissionsOrder.indexOf(updatePermission);
      if (indexToAdd === null || index < indexToAdd) {
        indexToAdd = index;
      }
    });
    response.push(exclusivePermissionsOrder[indexToAdd]);
  } else if (exclusivePermissions.length === 1) {
    response.push(exclusivePermissions[0]);
  }

  return [...new Set(response)]; // Remove duplicates.
};
