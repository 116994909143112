import { useEffect, useState } from 'react';

import { formatTimeSpanDuration } from '../helpers';

interface TimerInfo {
  duration: number;
  startTime: string | null;
}

type Props = {
  initialRounds?: StopWatchRound[];
  initialValue: number;
};

export interface StopWatchRound {
  id: string;
  label: string;
  time: string;
}

export const useStopWatch = ({ initialValue, initialRounds = [] }: Props) => {
  const [timerInfo, setTimerInfo] = useState<TimerInfo>({
    duration: initialValue,
    startTime: null,
  });
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isRunning, setIsRunning] = useState(false);
  const [rounds, setRounds] = useState<StopWatchRound[]>(initialRounds);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isRunning && timerInfo.startTime) {
      interval = setInterval(() => {
        const newDuration =
          new Date().getTime() -
          new Date(timerInfo.startTime).getTime() +
          timerInfo.duration;
        const roundedDownToNearestThousand =
          Math.floor(newDuration / 1000) * 1000;
        setCurrentValue(roundedDownToNearestThousand);
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [isRunning, timerInfo]);

  const startStopWatch = () => {
    setIsRunning(true);
    setTimerInfo((prevTimerInfo) => ({
      ...prevTimerInfo,
      startTime: new Date().toISOString(),
    }));
  };

  const addRound = (time: number) => {
    const roundNumber = rounds.length + 1;
    const timeAsString = formatTimeSpanDuration(time); //Convert time in string
    setRounds((prevRounds) => [
      {
        id: roundNumber.toString(),
        label: `Round ${roundNumber}`,
        time: timeAsString,
      },
      ...prevRounds,
    ]);
  };

  const stopStopWatch = () => {
    if (timerInfo.startTime) {
      const newDuration =
        new Date().getTime() -
        new Date(timerInfo.startTime).getTime() +
        timerInfo.duration;
      const roundedDownToNearestThousand =
        Math.floor(newDuration / 1000) * 1000;
      setTimerInfo({ duration: roundedDownToNearestThousand, startTime: null });
      setCurrentValue(roundedDownToNearestThousand);
      setIsRunning(false);
    }
  };

  const updateStopWatch = (millis: number) => {
    setTimerInfo({ duration: millis, startTime: null });
    setCurrentValue(millis);
  };

  const resetStopWatch = () => {
    setTimerInfo({ duration: 0, startTime: null });
    setCurrentValue(0);
    setRounds([]);
    setIsRunning(false);
  };

  return {
    addRound,
    currentValue,
    isRunning,
    resetStopWatch,
    rounds,
    setRounds,
    startStopWatch,
    stopStopWatch,
    updateStopWatch,
  };
};
