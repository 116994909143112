import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormLinkedSnag,
  QualityControlInstance,
  Task,
  TbqAnswer,
  useObjectIncludeQuery,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessage } from '@pro4all/shared/ui/messages';
import { TBQTaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { TbqQuestionSidebar } from './question-sidebar/TbqQuestionSidebar';
import { QuestionsResultActionBar } from './QuestionsResultActionBar';
import { useCalculateResolveTaskPerQuestion } from './useCalculateResolveTaskPerQuestion';
import { useTBQCalculateCurrentQuestionResolveTasks } from './useTBQCalculateResolveTasksState';
import { useTBQResultsColumns } from './useTBQResultsColumns';

interface TBQResultsProps {
  hasFilter: boolean;
  qcInstance: QualityControlInstance;
  shortcomings: TbqAnswer[] | undefined;
  tbqBrandcheckLinkedSnags: FormLinkedSnag[];
}

export const TBQshortcomings = ({
  hasFilter = false,
  shortcomings,
  qcInstance,
  tbqBrandcheckLinkedSnags,
}: TBQResultsProps) => {
  const {
    searchParams,
    params: { objectId: id },
  } = useRouting();
  const { t } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState<TbqAnswer>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [brandcheckSelectedRows, setBrandcheckSelectedRows] = useState<
    TbqAnswer[]
  >([]);
  const [tableKey, setTableKey] = useState(0);

  const scanId = searchParams.get('taskId') ?? '';
  const objectId = id ?? '';

  const { data: objectData } = useObjectIncludeQuery({
    fetchPolicy: 'no-cache',
    skip: !objectId,
    variables: {
      id: objectId,
      includeProject: true,
      includeProjectId: true,
      includeTasks: true,
    },
  });

  const procedureId = objectData?.object?.project?.mainProcedure?.id ?? '';
  const projectId = objectData?.object?.project?.id ?? '';
  const objectTasks = objectData?.object?.tasks
    ? objectData?.object?.tasks
    : [];

  useEffect(() => {
    if (objectTasks.length > 0) {
      const updatedTasks = objectTasks
        .flatMap((task) => task)
        .filter((link) => link.tbqLinkedQuestions !== null) as Task[];
      setTasks(updatedTasks);
    }
  }, [objectTasks]);

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<TbqAnswer>();

  const selectedId = searchParams.get('selectedId') ?? '';
  const action = searchParams.get('action');

  const isTBQEditQuestionnaire = searchParams.is(
    'edit-tbq',
    'edit-tbq-dashboard'
  );

  useSetItemsInLocalState<TbqAnswer>(shortcomings || []);

  const relevantTasksForAllQuestions = useCalculateResolveTaskPerQuestion(
    items,
    tasks,
    qcInstance
  );

  useEffect(() => {
    if (relevantTasksForAllQuestions.length > 0) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [relevantTasksForAllQuestions]);

  const columns = useTBQResultsColumns(relevantTasksForAllQuestions);

  useEffect(() => {
    setCurrentQuestion(items.find((item) => item.id === selectedId));
  }, [selectedId, items]);

  const relevantTasks = useTBQCalculateCurrentQuestionResolveTasks(
    tasks,
    currentQuestion as TbqAnswer,
    scanId
  );

  const handleCloseQuestionSidebar = () => {
    searchParams.delete('selectedId');
    searchParams.delete('action');
    searchParams.delete('view');
    searchParams.delete('id');
  };

  const handleCloseEditQuestionnaireSidebar = () => {
    searchParams.delete('edit-tbq-dashboard');
    searchParams.delete('action');
  };

  const handleRowClick = (row: TbqAnswer) => {
    if (isTBQEditQuestionnaire) handleCloseEditQuestionnaireSidebar();
    searchParams.set('selectedId', row.id);
    searchParams.set('action', 'properties');
    searchParams.set('view', 'view-question-sidebar');
    searchParams.set('id', qcInstance.id);
  };

  if (!hasFilter) {
    return (
      <Box mr={30} mt={15}>
        <BigMessage
          shapeName="collaborate"
          text={t(
            'Curious about what you can do to improve fire safety? Select a goal or category to view the corresponding shortcomings'
          )}
          title={t('No Goal Or Category Selected')}
        />
      </Box>
    );
  }

  if (!items.length && !itemsInitial.length) {
    return (
      <BigMessage
        shapeName="trust"
        text={t(
          'No shortcomings found, which means that you are up-to-date with your fire-safety on this goal.'
        )}
        title={t('Everything is okay here')}
      />
    );
  }

  return (
    <TableContextProvider
      checkable
      columns={columns}
      id="table-tbq-results"
      items={items}
      key={tableKey}
    >
      <FilterContextProvider<TbqAnswer>>
        <QuestionsResultActionBar
          instanceId={qcInstance.id}
          projectId={projectId}
          setBrandcheckSelectedRows={setBrandcheckSelectedRows}
        />
        <Table
          headerHeight={40}
          onRowClick={(row) => handleRowClick(row as TbqAnswer)}
          selectedId={selectedId}
        />
        {Boolean(selectedId) && Boolean(action) && (
          <TbqQuestionSidebar
            linkedSnags={tbqBrandcheckLinkedSnags}
            linkedTasks={relevantTasks}
            onClose={handleCloseQuestionSidebar}
            qcInstance={qcInstance}
            question={currentQuestion}
          />
        )}
        <TBQTaskSidebar
          procedureId={procedureId}
          projectId={projectId}
          tbqBrandcheckQcInstance={qcInstance}
          tbqQuestion={
            brandcheckSelectedRows.length
              ? brandcheckSelectedRows
              : currentQuestion
          }
          tbqRieOnClose={handleCloseQuestionSidebar}
        />
      </FilterContextProvider>
    </TableContextProvider>
  );
};
