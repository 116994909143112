import React, { createContext, useContext } from 'react';
import { ApolloError } from '@apollo/client';

import {
  SharedAttachmentDataResponse,
  usePublicAttachmentDataQuery,
} from '@pro4all/graphql';
import { useShareLinkParams } from '@pro4all/shared/assets-sharing';

export const PubliclySharedMessagesContext = createContext(null);

interface PubliclySharedMessageContextValue {
  data: SharedAttachmentDataResponse;
  error: ApolloError;
  linkIsExpiredError?: boolean;
  loading?: boolean;
}

export const usePubliclySharedMessagesContext = () => {
  const context = useContext<PubliclySharedMessageContextValue>(
    PubliclySharedMessagesContext
  );

  if (context === undefined) {
    throw new Error(
      'PubliclySharedMessageCONTEXT must be used within a Provider'
    );
  }
  return context;
};

export const PubliclySharedMessagesContextProvider: React.FC = ({
  children,
}) => {
  const { linkId, signature, template } = useShareLinkParams();

  const { data, error, loading } = usePublicAttachmentDataQuery({
    variables: { id: linkId, signature, template },
  });

  const linkIsExpiredError = Boolean(
    data?.publicAttachmentData?.errors?.some((error) => error === '401')
  );

  const value = {
    data: data?.publicAttachmentData,
    error,
    linkIsExpiredError,
    loading,
  };

  return (
    <PubliclySharedMessagesContext.Provider value={value}>
      {children}
    </PubliclySharedMessagesContext.Provider>
  );
};
