import { environment } from '@pro4all/authentication/src/environments';

export const STORAGE_ID = `oidc.user:${environment.authentication.authUrl}:${environment.authentication.clientId}`;

export const IDENTITY_CONFIG = {
  authority: environment.authentication.authUrl,
  automaticSilentRenew: true,
  client_id: environment.authentication.clientId,
  filterProtocolClaims: false,
  loadUserInfo: true,
  post_logout_redirect_uri: `${window.location.origin}/logout-callback`,
  redirect_uri: `${window.location.origin}/signin-oidc`,
  response_type: 'code',
  scope: 'openid profile offline_access',
  silent_redirect_uri: `${window.location.origin}/silentrenew`,
};
