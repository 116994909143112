import React, { useEffect, useState } from 'react';

import { DatePickerProps } from '@pro4all/shared/mui-wrappers';
import { DateField, getFormattedDate } from '@pro4all/shared/ui/general';

import { UploaderEditorTypes } from '../types';

import { usePreventLoseCursorFocus } from './usePreventLoseCursorFocus';

type Props = Pick<
  UploaderEditorTypes,
  | 'errorString'
  | 'isHeader'
  | 'isTypingFromHeader'
  | 'label'
  | 'name'
  | 'setTypingFromHeader'
  | 'tabIndex'
  | 'updateProvider'
  | 'value'
  | 'warningString'
>;

const EditorDateTimeFieldComponent = ({
  errorString,
  isHeader = false,
  isTypingFromHeader,
  label,
  name,
  setTypingFromHeader,
  tabIndex,
  updateProvider,
  value,
  warningString,
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value);
  const { inputRef } = usePreventLoseCursorFocus({
    errorString,
    isTypingFromHeader,
    warningString,
  });

  // In case value is changed in the provider, we have to set the updated value else it won't re-render.
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange: DatePickerProps['onChange'] = (date: Date) => {
    setTypingFromHeader(isHeader);
    const formattedDate = (date && getFormattedDate(date)?.dateTime) || '';
    updateProvider(formattedDate);
    setCurrentValue(formattedDate);
  };

  return (
    <DateField
      error={errorString ? true : false}
      inputRefProp={inputRef}
      label={label}
      name={name || ''}
      onChange={handleChange}
      tabIndex={tabIndex}
      tooltipTitle={errorString || warningString}
      value={currentValue || ''}
      warning={warningString ? true : false}
    />
  );
};

const skipUpdate = (prevProps: Props, nextProps: Props) =>
  prevProps.value === nextProps.value &&
  prevProps.errorString === nextProps.errorString &&
  prevProps.tabIndex === nextProps.tabIndex &&
  prevProps.warningString === nextProps.warningString;

export const EditorDateTimeField = React.memo(
  EditorDateTimeFieldComponent,
  skipUpdate
);
