import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ConfigurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomActionBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const BottomActionBar = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
