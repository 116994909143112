import { useCallback, useEffect, useState } from 'react';

import { DatePickerProps } from '@pro4all/shared/mui-wrappers';
import { TextFieldProps } from '@pro4all/shared/ui/general';

export const useHandleValueChangeInputs = ({
  name, // prop 'name' is the name of the form input which equals the input id.
  setValue,
  value,
}: {
  name: string;
  setValue: (name: string, value: string) => void;
  value: string;
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleValueChange = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      setCurrentValue(value);
      setValue?.(name, value);
    },
    []
  );

  useEffect(() => {
    handleValueChange({ name, value });
  }, [handleValueChange, name, value]);

  const handleChangeText: TextFieldProps['onChange'] = (event) => {
    handleValueChange({ name, value: event.target.value });
  };

  const handleChangeDate: DatePickerProps['onChange'] = (date: Date) => {
    const selectedDateObject = new Date(date);
    const selectedDate = date ? selectedDateObject.toString() : '';
    handleValueChange({ name, value: selectedDate });
  };

  return { currentValue, handleChangeDate, handleChangeText };
};
