/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import { SignatureService } from '@pro4all/quality-control/data-access';
import {
  FetchSignatureReportsProps,
  InstanceWithSignature,
} from '@pro4all/shared/types';

export const useFetchSignatureReports = ({
  instancesWithSignatures,
}: FetchSignatureReportsProps) => {
  const [signatures, setSignatures] = useState<
    {
      instanceId: string;
      itemId: string;
      signatureId: string;
      signatureUrl: string;
    }[]
  >([]);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    const getSignatures = async (
      instancesWithSignatures: InstanceWithSignature[]
    ) => {
      const signatureUrls = await Promise.all(
        instancesWithSignatures.map(async (instance: InstanceWithSignature) => {
          const signature = await SignatureService.getSignature({
            instanceId: instance.instanceId,
            signatureId: instance.signatureId,
          });
          return {
            instanceId: instance.instanceId,
            itemId: instance.itemId,
            signatureId: instance.signatureId,
            signatureUrl: signature,
          };
        })
      );

      if (isMounted.current) {
        setSignatures(signatureUrls);
      }
    };

    getSignatures(instancesWithSignatures);

    return () => {
      isMounted.current = false;
    };
  }, []);

  return { signatures };
};
