import styled from 'styled-components';

export const Outer = styled.div`
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;

  &[hidden] {
    display: block;
  }
`;

export const Inner = styled.div`
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  width: 100%;
`;
