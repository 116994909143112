import React from 'react';

import { useObjectsContext } from '../ObjectsContext';
import { Banner } from '../view/detail/Banners.styles';

import * as Status from './project-status';

export const ProjectStatus = () => {
  const { selectedObject } = useObjectsContext();
  const StatusComponent =
    Status[selectedObject?.projectStatus as keyof typeof Status];

  if (!StatusComponent) return null;

  return (
    <Banner>
      <StatusComponent />
    </Banner>
  );
};
