import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Member,
  ProjectMembersIncludeDocument,
  useAddExternalUsersMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

import { InviteExternalsFormValues } from '../components/types';
import { projectMembersIncludeProps } from '../project/getIncludeProps';

export const useAddExternalUsers = (
  onClose: () => void,
  refetchMembers: () => void,
  roleOptions: { id: string; label: string }[]
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    params: { projectId },
  } = useRouting();

  const [addExternalUsers] = useAddExternalUsersMutation();

  const {
    state: { items },
  } = useOptimisticResponseContext<Member>();

  return async (values: InviteExternalsFormValues) => {
    const { externalUsersToAdd, message, roleId } = values;

    const roldIdOption = roleOptions.find((role) => role.id === roleId);

    const emails = externalUsersToAdd
      .map((externalUser) => externalUser.label)
      .filter(Boolean);

    try {
      await addExternalUsers({
        variables: {
          emails,
          message,
          projectId,
          roleId,
        },
      });
      enqueueSnackbar(t('Invitation sent'));

      const newItems = externalUsersToAdd.map((externalUser) => ({
        __typename: 'User',
        active: true,
        displayName: ' ',
        email: externalUser.label,
        id: uuid(),
        isAdmin: roldIdOption?.label !== 'User' ? true : false,
        organization: null,
        roles: [
          {
            id: roleId,
            name: roldIdOption?.label || '',
            permissions: [],
          },
        ],
      }));

      client.writeQuery({
        data: {
          projectMembers: [...items, ...newItems],
        },
        query: ProjectMembersIncludeDocument,
        variables: {
          ...projectMembersIncludeProps,
          projectId,
        },
      });

      // We create an temporary id for the new member to update Apollo client cache, however BE is in charge of this id.
      // So we do a refetch to update the tabel with the new id we get from BE.
      // However because BE is slow sometimes. So we do not refetch instantly but after 5 seconds to give BE some time.
      setTimeout(() => {
        refetchMembers();
      }, 5000);

      onClose();
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };
};
