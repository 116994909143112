import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export const CopyTextButton: React.FC<{
  children?: React.ReactNode;
  textName: string;
  textToCopy: string;
  truncateLength?: number;
}> = ({ textName, textToCopy, truncateLength, children }) => {
  // Include textName here
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copyReference = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar(
          t('{{textName}} copied to clipboard', {
            textName,
          })
        );
      })
      .catch((err) => {
        enqueueSnackbar(
          t('Failed to copy {{textName}}', {
            textName,
          }),
          { variant: 'error' }
        );
      });
  };

  const truncateText = (text: string): string =>
    truncateLength && text.length > truncateLength
      ? text.substring(0, truncateLength) + '...'
      : text;

  return (
    <StyledCopyTextButton onClick={copyReference}>
      {truncateText(textToCopy)}
      {children}
    </StyledCopyTextButton>
  );
};

const StyledCopyTextButton = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export default CopyTextButton;
