import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Backdrop } from '@pro4all/shared/ui/general';
import {
  BigMessageNoAccess,
  BigMessageNoOrganizationFoldersForPermissions,
  BigMessageNoProjectAccess,
  BigMessageNoProjectFoldersForPermissions,
} from '@pro4all/shared/ui/messages';

import {
  FolderTreeContextProvider,
  useFolderTreeContextInner,
} from '../tree/FolderTreeContext';
import { useFolderTreeContextOuter } from '../tree/FolderTreeProviderOuter';
import { Tree } from '../tree/Tree';
import { TreeFolder } from '../tree/types';
import { getJustFoldersTree } from '../tree/useFolderTree';

import { useSetPermissionsFolderInLocalState } from './context/PermissionsFolderMatrixProvider';
import { Permission } from './context/types';
import { useFolderPermissionColumns } from './hooks/useFolderPermissionColumns';

interface Props {
  projectId?: string;
  userId?: string;
}

const StyledWrapper = styled.div`
  height: 100%;
`;

export const PermissionsFolderTree: React.FC<Props> = ({
  userId,
  projectId,
}) => (
  <FolderTreeContextProvider
    inherit={false}
    projectId={projectId}
    userId={userId}
  >
    <FolderTreeContent projectId={projectId} userId={userId} />
  </FolderTreeContextProvider>
);

const FolderTreeContent: React.FC<Props> = ({ projectId, userId }) => {
  const { loading, noAccess } = useFolderTreeContextInner();
  const {
    state: { folderTree },
  } = useFolderTreeContextOuter();

  const folderTreeWithoutLinks = useMemo(
    () => folderTree.map((tree) => getJustFoldersTree(tree)),
    [folderTree]
  );

  const permissions = useMemo(() => {
    const getPermissions = (
      values: Permission[],
      item: TreeFolder
    ): Permission[] => {
      let response: Permission[] = [];
      if (item.children.length > 0) {
        response = item.children.reduce(getPermissions, []);
      }
      const { entityFolderPermissions, id, name, parentFolderId } = item;

      return [
        ...values,
        ...response,
        {
          breakInheritance: entityFolderPermissions[0].breakInheritance,
          folderId: id,
          folderPermissions: entityFolderPermissions[0].folderPermissions,
          inheritedFrom: entityFolderPermissions[0].inheritedFrom,
          name,
          parentFolderId,
          subFoldersPermissions:
            entityFolderPermissions[0].subFoldersPermissions,
        },
      ];
    };
    return folderTreeWithoutLinks.reduce(getPermissions, []);
  }, [folderTreeWithoutLinks]);

  useSetPermissionsFolderInLocalState(permissions);

  const columns = useFolderPermissionColumns();

  if (projectId && noAccess) return <BigMessageNoProjectAccess />;
  if (!projectId && noAccess) return <BigMessageNoAccess />;

  if (!userId) {
    return null;
  }

  if (loading)
    return (
      <>
        <Backdrop />
        <StyledWrapper></StyledWrapper>
      </>
    );

  if (projectId && !folderTreeWithoutLinks?.length)
    return <BigMessageNoProjectFoldersForPermissions />;

  if (!projectId && !folderTreeWithoutLinks?.length)
    return <BigMessageNoOrganizationFoldersForPermissions />;

  return (
    <Tree
      extraColumns={columns}
      navigateOnClick={false}
      rowHeight={32}
      showHeaders
      showOnlyFolders
    />
  );
};
