import React, { PropsWithChildren } from 'react';
import { Resizable } from 're-resizable';

export const ResizableColumnWrapper = ({
  children,
  defaultWidth = 200,
  zIndexResizableColumnDown,
}: PropsWithChildren<{
  defaultWidth: number;
  zIndexResizableColumnDown: number;
}>) => (
  <Resizable
    defaultSize={{
      height: '100%',
      width: defaultWidth,
    }}
    enable={{ right: true }}
    minWidth="40px"
    style={{
      position: 'relative',
      zIndex: zIndexResizableColumnDown,
      // This zIndexResizableColumnDown is passed in because the most left column should have the highest zIndex
      // and the most right column should have the lowest zIndex. If not, only the last column will be resizable.
      // This is caused because the header must be sticky and has a background. This background has impact on the
      // Resizable component. A complex confluence of circumstances but this is the best we can do.
      // ATTENTION!! There is a side-effect > When resizing the left column always overlaps the right columnn.
    }}
  >
    {children}
  </Resizable>
);
