import {
  Meta,
  State,
  UpdateMetaMetadataPayload,
} from '../useUploaderEditorReducer';

import { useChangeMetadataValue } from './helpers';

export const useUpdateMetaMetaDataAction = () => {
  const changeMetaDataValue = useChangeMetadataValue();

  const updateMetaMetaDataAction = ({
    payload,
    state,
  }: {
    payload: UpdateMetaMetadataPayload;
    state: State;
  }) => {
    const { fields, meta } = state;
    const { documentId, fieldDefinitionId, value } = payload;
    const metaUpdated = [] as Meta[];

    meta.forEach((doc) => {
      let documentUpdated = doc;
      if (doc.id === documentId) {
        documentUpdated = changeMetaDataValue({
          doc,
          fieldDefinitionId,
          fields,
          updateViaHeader: false,
          value,
        });
      }
      metaUpdated.push(documentUpdated);
    });

    return {
      ...state,
      meta: metaUpdated,
    };
  };

  return updateMetaMetaDataAction;
};
