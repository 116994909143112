import React, { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';

import { AuthTileLayer64 } from '@pro4all/quality-control/ui/maps';
import { ReportDrawingContentsProps } from '@pro4all/shared/types';

export const ReportDrawingContents = ({
  children,
  page,
  mapHeight,
  mapWidth,
  visualContextId,
  zoomLevels,
}: ReportDrawingContentsProps) => {
  const map = useMapEvents({});
  const zoom = zoomLevels;

  map
    .getPanes()
    .tilePane.setAttribute('className', `report-tile-map-${visualContextId}`);

  useEffect(() => {
    const southWest = map.unproject([0, mapHeight], zoomLevels);
    const northEast = map.unproject([mapWidth, 0], zoomLevels);
    const bounds: LatLngBounds = new LatLngBounds(southWest, northEast);

    if (map) {
      map.fitBounds(bounds);
    }
  }, [map, mapHeight, mapWidth, visualContextId, zoom, zoomLevels]);

  if (!map) return null;

  return (
    <>
      <AuthTileLayer64
        maxZoom={zoomLevels}
        minZoom={0}
        noWrap
        page={page}
        tileSize={256}
        visualContextId={visualContextId}
        zoomOffset={0}
      />
      {children}
    </>
  );
};
