export interface MoveExistingUserProps {
  currentOrganizationId: string;
  currentOrganizationName: string;
  inviterEmail: string;
  inviterName: string;
  newOrganizationId: string;
  newOrganizationName: string;
  updateMoveState: (newState: MoveUserState) => void;
  userId: string;
}

export enum MoveUserState {
  ActiveSubscriptionFound = 1,
  AlreadyMoved = 2,
  EligibleForMove = 4,
  LastAdmin = 3,
  MoveInvitationExpired = 5,
  None = 0,
  MoveSuccesful = 6,
  Error = 7,
}
