import React from 'react';

import { CommentsList } from '@pro4all/communication/ui/comments';
import { ThreadContextProvider } from '@pro4all/communication/ui/comments';
import { ReferenceInput, ReferenceKind, ReferenceType } from '@pro4all/graphql';

export const TaskComments: React.FC<{
  projectId?: string;
  taskId: string;
}> = ({ taskId, projectId }) => {
  const references: ReferenceInput[] = [
    ...(projectId
      ? [
          {
            referenceId: null,
            referenceKind: ReferenceKind.LinkInfo,
            referenceType: ReferenceType.Project,
            referenceValue: projectId,
          },
        ]
      : []),
  ];

  const target: ReferenceInput = {
    referenceId: null,
    referenceKind: ReferenceKind.Target,
    referenceType: ReferenceType.Task,
    referenceValue: taskId,
  };

  return (
    <ThreadContextProvider
      newComment
      references={references}
      target={target}
      targetId={taskId}
    >
      <CommentsList projectId={projectId} />
    </ThreadContextProvider>
  );
};
