import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ViewFile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M6 1.25C4.48106 1.25 3.26025 2.49043 3.26 3.99953L3.26 3.99981V4L3.25 19.9995V20C3.25 21.5093 4.4709 22.75 5.99 22.75H18C19.5142 22.75 20.75 21.5142 20.75 20V8.83C20.75 8.10438 20.4632 7.40071 19.9371 6.88641L15.1136 2.06294C14.5993 1.53685 13.8956 1.25 13.17 1.25H6ZM4.76 4C4.76 3.30931 5.3191 2.75 6 2.75H13.17C13.5026 2.75 13.8173 2.88175 14.0426 3.11321L14.0426 3.11326L14.0497 3.12033L18.8797 7.95033L18.8796 7.95038L18.8868 7.95735C19.1182 8.18273 19.25 8.49739 19.25 8.83V20C19.25 20.6858 18.6858 21.25 18 21.25H5.99C5.30918 21.25 4.75013 20.6908 4.75 20.0002V20L4.76 4.00047L4.76 4ZM13 3.5V8C13 8.55 13.45 9 14 9H18.5L13 3.5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13 12.25C10.5 12.25 8.365 13.805 7.5 16C8.365 18.195 10.5 19.75 13 19.75C15.5 19.75 17.635 18.195 18.5 16C17.635 13.805 15.5 12.25 13 12.25ZM13 18.5C11.62 18.5 10.5 17.38 10.5 16C10.5 14.62 11.62 13.5 13 13.5C14.38 13.5 15.5 14.62 15.5 16C15.5 17.38 14.38 18.5 13 18.5ZM11.5 16C11.5 15.17 12.17 14.5 13 14.5C13.83 14.5 14.5 15.17 14.5 16C14.5 16.83 13.83 17.5 13 17.5C12.17 17.5 11.5 16.83 11.5 16Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
