import { StandardItemKey } from '@pro4all/graphql';
import { IconName } from '@pro4all/shared/ui/icons';

type FieldCardsGlobalType = {
  [key in StandardItemKey]: {
    hideInOrganizationContext?: boolean;
    icon: IconName;
    label: string;
  };
};

export const fieldCardsGlobalInfo: FieldCardsGlobalType = {
  [StandardItemKey.Approved]: {
    icon: 'approveFile',
    label: '{{Approved}}/{{Rejected}}',
  },
  [StandardItemKey.Build12]: {
    icon: 'build12',
    label: '12Build',
  },
  [StandardItemKey.Comments]: {
    icon: 'chatBubble',
    label: 'Comments',
  },
  [StandardItemKey.Extension]: {
    icon: 'file',
    label: 'Document type',
  },
  [StandardItemKey.Finalized]: {
    icon: 'finalize',
    label: '{{name}}',
  },
  [StandardItemKey.Name]: {
    icon: 'text',
    label: 'Name',
  },
  [StandardItemKey.Qr]: {
    icon: 'qr',
    label: 'QR code',
  },
  [StandardItemKey.Snagstream]: {
    icon: 'snagstream',
    label: 'Snagstream',
  },
  [StandardItemKey.Tags]: {
    icon: 'label',
    label: 'Tags',
  },
  [StandardItemKey.ThreeD]: {
    icon: 'cube',
    label: '3D',
  },
  [StandardItemKey.UpdatedBy]: {
    icon: 'personOutline',
    label: 'Updated by',
  },
  [StandardItemKey.UpdatedOn]: {
    icon: 'calendar',
    label: 'Updated on',
  },
  [StandardItemKey.Version]: {
    icon: 'number',
    label: 'Version number',
  },
};
