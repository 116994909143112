import { AuthService } from '@pro4all/authentication/src/services/auth-service';

import { DocumentActionProps } from './types';

export const useIsLockedUser = ({
  selection,
}: Pick<DocumentActionProps, 'selection'>) => {
  const { userId } = AuthService.getProfile();
  return userId === selection[0]?.lockedBy?.id;
};
