import React from 'react';

import {
  Typography,
  TypographyProps,
  TypographyTypeMap,
} from '@pro4all/shared/mui-wrappers';

export interface TextProps extends Omit<TypographyProps, 'variant'> {
  variant?: TypographyTypeMap['props']['variant'];
}

export const Text: React.FC<TextProps> = ({ variant = 'body1', ...rest }) => (
  <Typography {...rest} style={{ whiteSpace: 'pre-line' }} variant={variant} />
);
