import { useCallback, useEffect, useMemo, useState } from 'react';

import { Document } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export function useDocumentSelection(documents: Document[] = []) {
  const { searchParams } = useRouting();
  const [selectedDocument, setSelectedDocument] = useState<Document>(null);

  const findDocument = useCallback(
    // We want to use generics to properly type this utility function, so
    // we cannot use a fat-arrow function.
    // eslint-disable-next-line prefer-arrow-callback
    function <Prop extends keyof Document, Value extends Document[Prop]>(
      prop: Prop,
      value: Value,
      comparator: (
        documentValue: Value,
        compareValue: Value
      ) => boolean = Object.is
    ) {
      return (documents ?? []).find((document) =>
        comparator(document[prop], value)
      );
    },
    [documents]
  );

  const findDocumentByName = useCallback(
    (name: Document['name']) =>
      findDocument(
        'name',
        name,
        (documentName, name) =>
          // Document names need case-insensitive checks in order to cater to
          // people with case-insenstive file systems.
          documentName.toLowerCase() === name.toLowerCase()
      ),
    [findDocument]
  );

  // Selects the current document by URL
  useEffect(() => {
    const document = findDocument('id', searchParams.get('id'));
    if (
      document &&
      JSON.stringify(document) !== JSON.stringify(selectedDocument)
    )
      setSelectedDocument(document);
  }, [searchParams, selectedDocument, findDocument]);

  const selectDocument = useCallback(
    (document: Document) => {
      setSelectedDocument(document);
      searchParams.delete('versionid');
      searchParams.set({ id: document.id });
    },
    [setSelectedDocument, searchParams]
  );

  const selectVersion = useCallback(
    (version: Document) => {
      setSelectedDocument(version);
      searchParams.set({ versionid: version.id });
    },
    [setSelectedDocument, searchParams]
  );

  const deselectDocument = useCallback(() => {
    setSelectedDocument(null);
    searchParams.delete('id');
    searchParams.delete('focusComment');
  }, [setSelectedDocument, searchParams]);

  const deselectVersion = useCallback(() => {
    setSelectedDocument(null);
    searchParams.delete('versionid');
  }, [setSelectedDocument, searchParams]);

  // It is necessary to create another function that does
  // not use searchParams to avoid unnecessary re-renders
  // or having to omit dependencies in a useEffect.
  const changeToAnotherFolder = useCallback(
    () => setSelectedDocument(null),
    []
  );

  const methods = useMemo(
    () => ({
      deselectDocument,
      deselectVersion,
      findDocument,
      findDocumentByName,
      selectDocument,
      selectVersion,
      selectedDocument,
      setSelectedDocument,
    }),
    [
      deselectDocument,
      deselectVersion,
      findDocument,
      findDocumentByName,
      selectDocument,
      selectVersion,
      selectedDocument,
      setSelectedDocument,
    ]
  );

  return { ...methods, changeToAnotherFolder };
}
