import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageSelectDocumentToShowVersions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="flow2"
      text={t('Select a document you want to see versions of.')}
      title={t('Select a document')}
    />
  );
};
