import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBar } from '@pro4all/shared/ui/progress-bar';

type CustomData = {
  [key: string]: string;
};

export interface Upload {
  customData?: CustomData;
  done: boolean;
  error: Error | undefined;
  file?: FileWithId | FileId;
  id: string;
  progress: number;
  uploaded: boolean;
  uploading: boolean;
}

export interface FileId {
  id?: string;
}

export interface FileWithId extends File {
  id?: string; // We need this id to map objects from filesUploadedUnsuccessfully with the applicable row in the Documents editor.
}

export type OnProgress = (progress: number) => void;

interface Uploader {
  addUnsuccessfulFiles: (fileIds: string[]) => void;
  expectedAmountOfFiles: number;
  filesProcessed: Upload[];
  filesSelectedToUpload: Upload[];
  filesUploadedSuccessfully: Upload[];
  filesUploadedUnsuccessfully: Upload[];
  isDone: boolean;
  isUploading: boolean;
  progress: number;
  queued: Upload[];
  reset: () => void;
  setExpectedAmountOfFiles: (amount: number) => void;
  upload: (
    files: File[],
    uploadHandler: (
      file: FileWithId,
      onProgress: OnProgress
    ) => Promise<unknown | Error>
  ) => void;
}

export interface UploaderValue extends Uploader {
  disableResetBefore: () => void;
  enableResetBefore: () => void;
  hideProgress: () => void;
  showProgress: () => void;
}

type UploadQueue = {
  file: FileWithId;
  upload: (onProgress: OnProgress) => Promise<unknown | Error>;
}[];

interface Props {
  concurrency?: number;
  resetAfter?: boolean;
  resetBefore?: boolean;
}

export const FileUploadContext = createContext<UploaderValue | undefined>(
  undefined
);

type CallbackCustomDataFunctionType = (data: any) => CustomData;

let callbackCustomData: CallbackCustomDataFunctionType | null;

export const useFileUploadContext = (
  customDataCallback?: (data: any) => CustomData
) => {
  if (customDataCallback) callbackCustomData = customDataCallback;
  return useContext(FileUploadContext);
};

export const FileUploadProvider: React.FC<Props> = ({
  children,
  ...options
}) => {
  const { t } = useTranslation();
  const [resetBefore, setResetBefore] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(true);
  const modifiedOptions = { ...options, resetBefore };
  const uploader = useBatchUpload(modifiedOptions);

  const disableResetBefore = () => {
    if (resetBefore) {
      setResetBefore(false);
    }
  };
  const enableResetBefore = () => {
    if (!resetBefore) {
      setResetBefore(true);
    }
  };
  const hideProgress = () => {
    setShowProgressBar(false);
  };

  const showProgress = () => {
    setShowProgressBar(true);
  };

  return (
    <FileUploadContext.Provider
      value={{
        ...uploader,
        disableResetBefore,
        enableResetBefore,
        hideProgress,
        showProgress,
      }}
    >
      {children}
      {uploader.isUploading && showProgressBar && (
        <ProgressBar
          current={uploader.progress}
          maximum={1}
          text={t('{{current}} of {{maximum}} documents uploaded', {
            current: uploader.filesProcessed.length,
            maximum: Math.max(
              uploader.expectedAmountOfFiles,
              uploader.filesSelectedToUpload.length
            ),
          })}
        />
      )}
    </FileUploadContext.Provider>
  );
};

const toUpload = (file: FileWithId | FileId): Upload => ({
  done: false,
  error: undefined,
  file,
  id: '',
  progress: 0,
  uploaded: false,
  uploading: false,
});

const toUploadWithId = (fileId: string): Upload => ({
  done: false,
  error: undefined,
  file: { id: fileId },
  id: '',
  progress: 0,
  uploaded: false,
  uploading: false,
});

function useBatchUpload({
  // Amount of simultaneous uploads for this batchUploader
  concurrency = 5,
  // By default the uploads state is reset before a next upload is triggered,
  // but only when the uploads are all done. This can be useful if you want
  // to display the uploads file list progress. The batchUploader exposes a
  // reset() method for more control.
  resetAfter = false, // reset uploads list after `isDone`
  resetBefore = true, // reset uploads list after `!isUploading` and upload()
}: Props = {}): Uploader {
  const _concurrentUploads = useRef<number>(0);
  const _uploadFromExpected = useRef<number>(0);
  const _queue = useRef<UploadQueue>([]);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [expectedAmountOfFiles, setExpectedAmountOfFiles] = useState(0);

  const addUnsuccessfulFiles = (fileIds: string[]) => {
    setUploads((currentUploads) => [
      ...currentUploads,
      ...fileIds.map((id) => ({
        ...toUploadWithId(id),
        done: true,
        error: { message: 'Error uploading', name: 'Upload error' },
      })),
    ]);
    _uploadFromExpected.current += fileIds.length;
  };

  const reset = useCallback(() => {
    _queue.current = []; // We want to stop uploading after a reset.
    _uploadFromExpected.current = 0;
    setExpectedAmountOfFiles(0);
    setUploads([]);
    callbackCustomData = null;
  }, [setUploads]);

  const uploadNext = async () => {
    const _upload = _queue.current.shift();

    if (!_upload) return;

    const update = (params: Partial<Upload>) => {
      setUploads((uploads) =>
        uploads.map((upload) => {
          // We can't check for upload === _upload since state and queue do not share
          // referential equality; the file property does.
          if (upload.file === _upload.file) return { ...upload, ...params };
          return upload;
        })
      );
    };

    const onProgress: OnProgress = (progress) =>
      update({ progress: Math.max(0, Math.min(progress, 1)) });

    _concurrentUploads.current++;
    _uploadFromExpected.current++;

    update({ uploading: true });

    try {
      const response = (await _upload.upload(onProgress)) as string;
      if (response) {
        const customData = callbackCustomData
          ? callbackCustomData(response)
          : {};
        if (customData['error']) {
          update({
            customData,
            done: true,
            error: {
              message: customData['error'],
              name: 'uploadFailed',
            },
            id: response,
            uploaded: false,
          });
        } else {
          update({ customData, done: true, id: response, uploaded: true });
        }
      } else {
        update({
          done: true,
          error: { message: 'Upload failed', name: 'uploadFailed' },
        });
      }
    } catch (error) {
      update({ done: true, error });
    } finally {
      update({
        done: true,
        progress: 1,
        uploading: false,
      });
    }

    _concurrentUploads.current--;
    uploadNext();
  };

  const upload: Uploader['upload'] = (files, uploadHandler) => {
    if (!files.length) return;
    if (resetBefore && uploads.every(({ done }) => done)) reset();

    const toQueue = (file: FileWithId) => ({
      file,
      upload: (onProgress: OnProgress) => uploadHandler(file, onProgress),
    });

    _queue.current = [..._queue.current, ...files.map(toQueue)];
    setUploads((uploads) => [...uploads, ...files.map(toUpload)]);

    // only fire off new concurrent uploads when we are not already uploading
    // at maximum concurrency
    for (
      let i = Math.min(files.length, concurrency - _concurrentUploads.current);
      i >= 0;
      i--
    ) {
      uploadNext();
    }
  };

  useEffect(() => {
    if (resetAfter && uploads.length && uploads.every(({ done }) => done)) {
      reset();
    }
  }, [reset, resetAfter, uploads]);

  return {
    addUnsuccessfulFiles,
    expectedAmountOfFiles,
    get filesProcessed() {
      return uploads.filter(({ done }) => done);
    },
    filesSelectedToUpload: uploads,
    get filesUploadedSuccessfully() {
      return uploads.filter(({ uploaded }) => uploaded);
    },
    get filesUploadedUnsuccessfully() {
      return uploads.filter(({ error }) => Boolean(error));
    },
    get isDone() {
      return (
        expectedAmountOfFiles === _uploadFromExpected.current &&
        uploads.every(({ done }) => done)
      );
    },
    get isUploading() {
      return (
        _uploadFromExpected.current < expectedAmountOfFiles ||
        uploads.some(({ uploading }) => uploading)
      );
    },
    get progress() {
      return (
        uploads.reduce((progress, upload) => progress + upload.progress, 0) /
        Math.max(expectedAmountOfFiles, uploads.length)
      );
    },
    get queued() {
      return uploads.filter(({ done }) => !done);
    },
    reset,
    setExpectedAmountOfFiles,
    upload,
  };
}
