import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const EditFile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M6 1.25C4.48106 1.25 3.26025 2.49043 3.26 3.99953L3.26 3.99981V4L3.25 19.9995V20C3.25 21.5093 4.4709 22.75 5.99 22.75H18C19.5142 22.75 20.75 21.5142 20.75 20V8.83C20.75 8.10438 20.4632 7.40071 19.9371 6.88641L15.1136 2.06294C14.5993 1.53685 13.8956 1.25 13.17 1.25H6ZM4.76 4C4.76 3.30931 5.3191 2.75 6 2.75H13.17C13.5026 2.75 13.8173 2.88175 14.0426 3.11321L14.0426 3.11326L14.0497 3.12033L18.8797 7.95033L18.8796 7.95038L18.8868 7.95735C19.1182 8.18273 19.25 8.49739 19.25 8.83V20C19.25 20.6858 18.6858 21.25 18 21.25H5.99C5.30918 21.25 4.75013 20.6908 4.75 20.0002V20L4.76 4.00047L4.76 4ZM13 3.5V8C13 8.55 13.45 9 14 9H18.5L13 3.5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.3543 11.8155C17.5493 12.0105 17.5493 12.3255 17.3543 12.5205L16.4393 13.4355L14.5643 11.5605L15.4793 10.6455C15.5727 10.5519 15.6996 10.4993 15.8318 10.4993C15.9641 10.4993 16.0909 10.5519 16.1843 10.6455L17.3543 11.8155ZM8.49927 19.2506V17.7306C8.49927 17.6606 8.52427 17.6006 8.57427 17.5506L14.0293 12.0956L15.9043 13.9706L10.4443 19.4256C10.3993 19.4756 10.3343 19.5006 10.2693 19.5006H8.74927C8.60927 19.5006 8.49927 19.3906 8.49927 19.2506Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
