import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const StyledNewCards = styled.div`
  display: flex;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const StyledCardNew = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${customColors.white};
  border: 2px dashed ${customColors.grey400};
  border-radius: 5px;

  &:hover {
    background: ${customColors.grey200};
    cursor: pointer;
  }
`;

export const StyledFieldCustomCard = styled.div<{
  isDeleted: boolean;
  isReusable: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ isDeleted, isReusable }) =>
    isReusable
      ? isDeleted
        ? customColors.errorBg
        : customColors.grey200
      : customColors.grey300};
  border: ${({ isDeleted }) =>
    isDeleted
      ? `1px solid ${customColors.errorAlert}`
      : `1px solid ${customColors.grey300}`};
  border-radius: 5px;

  &:hover {
    cursor: grab;
  }
`;

export const StyledTitleZone = styled(Box)`
  && {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledToggleZone = styled(Box)`
  && {
    display: flex;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing(2)};
    cursor: pointer;
    .MuiSwitch-root {
      right: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${customColors.grey600};
`;

export const StyledDropZone = styled.div`
  min-height: ${({ theme }) => theme.spacing(5)};
`;

export const StyledMarginBottomZone = styled.div`
  min-height: ${({ theme }) => theme.spacing(1)};
`;

export const StyledName = styled(Text)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
    font-weight: 500;
  }
`;

export const StyledQuestionFieldName = styled(Text)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
    color: ${customColors.grey500};
  }
`;

export const StyledType = styled(Box)`
  && {
    color: ${customColors.grey700};
  }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTemplateForm = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 ${({ theme }) => theme.spacing(2)};
    overflow: auto;
  }
`;

export const StyledFormWrapper = styled(Box)`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const StyledTitleInput = styled(Box)`
  && {
    position: sticky;
    top: 6px;
    z-index: 1;
    margin-bottom: ${({ theme }) => theme.spacing(3)};
    background-color: ${customColors.white};
  }
`;

export const StyledFormDropZone = styled.div`
  height: 100%;
`;

export const StyledActionZone = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledButton = styled(Button)`
  && {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;
