import React from 'react';

import { customColors } from '@pro4all/shared/themes';

export type LogoColor = 'default' | 'white' | 'grey';
export type LogoType =
  | 'compact'
  | 'regular'
  | 'stacked'
  | 'shapeless'
  | 'square';

export interface ProstreamLogoProps {
  color?: LogoColor;
  type?: LogoType;
}

export const ProstreamLogo: React.FC<ProstreamLogoProps> = ({
  color = 'default',
  type = 'regular',
}) => {
  const colorScheme: {
    [key in LogoColor]: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
  } = {
    default: {
      primary: customColors.purplePrimary,
      secondary: customColors.stillBluePurple,
      tertiary: customColors.grey900,
    },
    grey: {
      primary: customColors.grey900,
      secondary: customColors.grey900,
      tertiary: customColors.grey900,
    },
    white: {
      primary: customColors.white,
      secondary: customColors.white,
      tertiary: customColors.white,
    },
  };

  const compactLogo = (
    <svg fill="none" height="18" viewBox="0 0 32 18" width="32">
      <path
        d="M29.7869 8.43595C29.0864 8.30478 28.758 7.67955 28.8995 7.04077L29.3469 5.02342C29.4883 4.38508 30.0474 3.96797 30.7479 4.09651C31.4484 4.22549 31.7767 4.85291 31.6353 5.49169L31.1879 7.50903C31.0478 8.14738 30.4891 8.56449 29.7869 8.43595Z"
        fill={colorScheme[color].primary}
      />
      <path
        d="M1.15119 17.7573C0.440633 17.6878 0.00895718 17.0831 0.0960804 16.4351L0.371897 14.3872C0.459458 13.7392 1.03386 13.2832 1.74442 13.3527C2.45497 13.4218 2.85994 14.0265 2.77282 14.6749L2.497 16.7224C2.40988 17.3703 1.86175 17.8264 1.15119 17.7573Z"
        fill={colorScheme[color].primary}
      />
      <path
        d="M29.3991 9.28062C28.6838 9.14727 28.2849 9.57531 28.0739 10.3243C27.5441 12.1501 27.0232 14.6987 25.8849 14.7026C24.765 14.7066 24.4734 12.6354 24.0697 10.4839C23.2471 6.10246 22.3793 3.05327 19.3672 3.05327C16.5154 3.05327 15.677 6.24718 14.7987 10.3234C14.4139 12.1103 13.9932 15.6103 12.5515 15.6103C10.9434 15.6103 10.848 11.5953 10.3576 7.73417C9.9172 4.26568 9.29551 0.913501 6.25889 0.908691C4.83646 0.908691 3.45693 2.21336 2.88778 3.93514C2.01917 6.57684 1.39924 8.55046 0.97019 11.2145C0.85242 11.9437 1.44784 12.4321 1.89309 12.4798C2.38036 12.5327 3.11063 12.4361 3.31858 11.5126C3.71261 9.76375 4.81456 2.9562 6.3158 2.97675C7.87571 2.99774 7.89191 8.85958 8.67514 13.3568C9.21583 16.4541 10.3388 17.7798 12.6649 17.7798C15.7256 17.7798 16.3775 13.969 17.1016 10.6491C17.4457 9.07513 18.3222 5.13401 19.4907 5.13401C20.9026 5.13401 21.618 9.25789 21.9153 10.7768C22.4091 13.2873 22.4529 16.7698 25.8503 16.7698C29.3146 16.7698 30.1333 11.2096 30.2555 10.6487C30.3921 10.0195 30.0935 9.40917 29.3991 9.28062Z"
        fill={colorScheme[color].primary}
      />
    </svg>
  );

  const shapelessLogo = (
    <svg fill="none" height="20" viewBox="0 0 1253 144" width="107">
      <path
        d="M57.72 94.9503H19.87V140.95H0V3.35034H57.25C78.52 3.35034 104.46 17.6103 104.46 49.3503C104.46 78.3603 79 94.9503 57.72 94.9503ZM58.42 21.5503H19.87V77.4303H57C69.62 77.4303 85.51 65.9803 85.51 49.4303C85.5937 45.8126 84.956 42.2144 83.6341 38.8457C82.3123 35.4771 80.3326 32.4055 77.8109 29.8101C75.2891 27.2148 72.2756 25.1477 68.9463 23.7296C65.6171 22.3115 62.0387 21.5706 58.42 21.5503Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M190.2 86.3103C201.41 103.6 214.5 123.46 225.48 140.98H203.28C203.28 140.98 173.37 93.9803 167.76 86.9803H141.59V140.98H122L121.73 3.35034H183.18C204.92 3.35034 227.12 18.5403 227.12 44.7103C227.12 66.4403 208.65 83.9703 190.2 86.3103ZM183.89 20.4103H141.59V69.2503H183.89C196.5 69.2503 208.43 57.7903 208.43 44.7103C208.43 30.2203 196.5 20.4103 183.89 20.4103Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M308.66 143.55C271.27 143.55 236.66 114.81 236.66 72.0403C236.66 28.3503 271.24 0.540283 308.66 0.540283C346.27 0.540283 380.86 29.5403 380.86 72.2703C380.86 115.28 346.27 143.55 308.66 143.55ZM308.66 18.3003C278.28 18.3003 255.61 42.6003 255.61 72.3003C255.61 101.3 278.28 125.35 308.66 125.35C339.26 125.35 361.46 101.52 361.46 72.3503C361.46 42.8403 339 18.3103 308.66 18.3103V18.3003Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M398.709 125.24L406.079 94.8803C425.949 107.61 443.589 111.4 453.639 111.4C468.819 111.4 484.229 106.26 484.229 97.5603C484.229 94.2103 481.109 91.3003 472.619 89.2903C462.999 86.8403 452.999 86.6103 442.699 85.0503C421.259 81.9203 396.699 74.5503 396.699 44.6403C396.699 7.34033 437.999 1.09033 458.769 1.09033C482.449 1.09033 495.169 5.78033 513.269 15.8303L507.269 45.3003C489.819 34.8103 473.509 30.5503 459.229 30.5503C435.999 30.5503 430.419 37.9303 430.419 42.8503C430.419 50.8503 449.629 52.2203 457.669 52.6703C476.199 53.5503 517.999 56.9103 517.999 97.5503C517.999 135.55 477.579 143.09 454.799 143.09C433.319 143.1 422.379 139.98 398.709 125.24Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M613.059 141.1H577.779L577.559 34.5503H530.219V3.33032H660.399V34.5503H613.059V141.1Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M720.679 90.4103H710.679V141.1H675.999L675.789 3.33032H747.469C776.049 3.33032 791.909 20.7403 791.909 46.8603C791.909 66.9603 776.049 81.4803 758.419 87.2803L798.609 136.41V141.1H762.429L720.679 90.4103ZM710.629 34.5503V58.4703H740.549C753.949 58.2603 758.189 51.7803 758.189 45.9703C758.189 40.6103 755.739 34.5803 742.559 34.5803L710.629 34.5503Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M815.121 3.33032H922.751V34.5503H849.751V57.5503H911.801V87.7303H850.001V109.61H924.811V141.1H815.121V3.33032Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M1040.41 117.66H983.699L973.699 141.1H940.599L940.379 138.2L1000.22 3.10034H1026.35L1083.52 138.2L1083.73 141.1H1049.57L1040.41 117.66ZM996.209 87.0603H1027.91L1012.29 48.6503L996.209 87.0603Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M1221.67 142.08V49.9503L1185.4 142.08H1160.31L1124.05 51.1303V142.13H1094.64V3.10034H1135L1173.23 97.0003L1209.5 3.10034H1252.03V142.1L1221.67 142.08Z"
        fill={colorScheme[color].tertiary}
      />
    </svg>
  );

  const stackedLogo = (
    <svg fill="none" height="50" viewBox="0 0 107 50" width="107">
      <path
        d="M81.9171 14.5759C80.5624 14.3269 79.9265 13.1119 80.2 11.8749L81.0653 7.96824C81.3388 6.73206 82.42 5.92431 83.7747 6.17324C85.1295 6.42725 85.7653 7.63803 85.491 8.87505L84.6291 12.7851C84.3547 14.0179 83.2752 14.8256 81.9171 14.5759Z"
        fill={colorScheme[color].primary}
      />
      <path
        d="M26.5355 32.6284C25.1613 32.4938 24.3265 31.3228 24.4958 30.068L25.0292 26.102C25.1986 24.8472 26.3086 23.9641 27.6828 24.0988C29.0578 24.2325 29.841 25.4035 29.6725 26.6583L29.1391 30.6242C28.9698 31.8791 27.9097 32.7622 26.5355 32.6284Z"
        fill={colorScheme[color].primary}
      />
      <path
        d="M81.1661 16.2117C79.7817 15.9577 79.0112 16.7824 78.6031 18.2336C77.5778 21.7694 76.5711 26.7006 74.3696 26.7124C72.2038 26.7201 71.6399 22.7093 70.8584 18.5427C69.2725 10.0579 67.5935 4.15305 61.7691 4.15305C56.2529 4.15305 54.6323 10.3382 52.9338 18.2319C52.1887 21.6924 51.3751 28.4693 48.5869 28.4693C45.4778 28.4693 45.2932 20.695 44.3449 13.2178C43.4931 6.50094 42.29 0.00592688 36.4173 0C33.6672 0 30.9984 2.52654 29.8977 5.86084C28.2179 10.9766 27.019 14.7986 26.1892 19.9566C25.9623 21.3698 27.1138 22.3155 27.9749 22.4078C28.9173 22.5103 30.3287 22.3232 30.7317 20.5349C31.4938 17.1482 33.624 3.96508 36.5282 4.00488C39.545 4.04552 39.5763 15.3972 41.0911 24.1055C42.1359 30.1043 44.3085 32.6715 48.8062 32.6715C54.7254 32.6715 55.9862 25.2917 57.3874 18.8627C58.053 15.8146 59.748 8.18248 62.0079 8.18248C64.7376 8.18248 66.122 16.1685 66.696 19.1099C67.6511 23.9717 67.7273 30.7156 74.3019 30.7156C81.0027 30.7156 82.586 19.9482 82.8214 18.8619C83.0855 17.6435 82.5089 16.4615 81.1661 16.2117Z"
        fill={colorScheme[color].primary}
      />
      <path
        d="M45.5675 31.9569C44.311 31.8545 42.4585 29.6607 41.6617 26.6685C39.3672 18.0474 39.8981 1.77558 35.7348 4.31567C40.0691 -1.32841 42.4348 3.79326 43.1858 6.60175C44.8275 12.7361 44.8351 22.5374 46.4998 26.6363C47.1653 28.2772 48.5488 29.1095 50.061 27.7124C49.028 29.6751 47.5945 32.0001 45.5675 31.9569Z"
        fill={colorScheme[color].secondary}
      />
      <path
        d="M71.5027 30.1239C70.8372 30.1239 68.4174 28.5313 67.6325 24.4002C65.6309 13.8715 64.1839 6.6018 61.1976 8.51449C65.1584 3.21247 68.3809 6.68139 70.3275 15.7563C70.9329 18.5775 71.4697 22.4782 72.291 24.7093C72.9421 26.4772 74.3764 27.3543 75.6837 26.0411C74.9936 27.5415 73.3968 30.1239 71.5027 30.1239Z"
        fill={colorScheme[color].secondary}
      />
      <path
        d="M4.88714 45.8843H1.68239V49.7791H0V38.1294H4.84734C6.64826 38.1294 8.8446 39.3359 8.8446 42.0242C8.8446 44.4796 6.68891 45.8843 4.88714 45.8843ZM4.9464 39.6721H1.68239V44.4009H4.82617C5.8947 44.4009 7.24011 43.4314 7.24011 42.0301C7.24685 41.724 7.19261 41.4196 7.08053 41.1347C6.96845 40.8498 6.80078 40.59 6.5873 40.3705C6.37381 40.1511 6.11878 39.9763 5.83706 39.8564C5.55535 39.7364 5.25258 39.6738 4.9464 39.6721Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M16.1042 45.1527C17.0533 46.6167 18.1616 48.2982 19.0913 49.7816H17.2116C17.2116 49.7816 14.6792 45.8022 14.2042 45.2095H11.9884V49.7816H10.3297L10.3094 38.1294H15.5123C17.353 38.1294 19.2327 39.4147 19.2327 41.6305C19.2302 43.4712 17.6663 44.9546 16.1042 45.1527ZM15.5699 39.573H11.9884V43.7083H15.5699C16.6376 43.7083 17.6477 42.7388 17.6477 41.6305C17.6477 40.4045 16.6376 39.573 15.5699 39.573Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M26.1341 50.0001C22.9683 50.0001 20.0379 47.5667 20.0379 43.9462C20.0379 40.2461 22.9658 37.8915 26.1341 37.8915C29.3186 37.8915 32.2473 40.3469 32.2473 43.9657C32.2473 47.6065 29.3186 50.0001 26.1341 50.0001ZM26.1341 39.3952C23.5619 39.3952 21.6424 41.4527 21.6424 43.9674C21.6424 46.4228 23.5619 48.4549 26.1341 48.4549C28.725 48.4549 30.6047 46.4363 30.6047 43.9674C30.6047 41.4722 28.703 39.3952 26.1341 39.3952Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M33.7587 48.449L34.3827 45.8784C36.0651 46.9563 37.5586 47.2772 38.4096 47.2772C39.6948 47.2772 40.9996 46.8428 40.9996 46.1053C40.9996 45.8217 40.7354 45.5753 40.0166 45.4051C39.2021 45.1977 38.3554 45.1782 37.4833 45.0444C35.668 44.7802 33.5885 44.1562 33.5885 41.6229C33.5885 38.4647 37.0862 37.9355 38.8439 37.9355C40.8489 37.9355 41.9259 38.3326 43.4584 39.1836L42.9504 41.6788C41.4755 40.7906 40.0945 40.4316 38.8863 40.4316C36.9202 40.4316 36.4469 41.0556 36.4469 41.4714C36.4469 42.1487 38.0734 42.2656 38.7542 42.3028C40.3231 42.379 43.8589 42.6618 43.8589 46.1036C43.8589 49.3211 40.4366 49.9603 38.5078 49.9603C36.6891 49.962 35.7628 49.7013 33.7587 48.449Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M51.9076 49.7917H48.9205L48.9018 40.7726H44.8936V38.1267H55.9159V40.7726H51.9076V49.7917Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M61.0198 45.4997H60.1731V49.7917H57.2367L57.2172 38.1267H63.2864C65.7062 38.1267 67.0491 39.6017 67.0491 41.8132C67.0491 43.5142 65.7062 44.7436 64.2135 45.2356L67.6164 49.3946V49.7917H64.5547L61.0198 45.4997ZM60.1731 40.7726V42.7962H62.7064C63.8409 42.7776 64.1999 42.229 64.1999 41.7379C64.1999 41.284 63.9925 40.7726 62.8766 40.7726H60.1731Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M69.016 38.1267H78.129V40.7726H71.9481V42.72H77.2018V45.2728H71.9693V47.1263H78.3034V49.7917H69.016V38.1267Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M88.0912 47.8071H83.2896L82.4429 49.7917H79.6403L79.6217 49.5462L84.6883 38.1073H86.9008L91.7413 49.5462L91.7591 49.7917H88.8668L88.0912 47.8071ZM84.3488 45.2136H87.0328L85.7103 41.9623L84.3488 45.2136Z"
        fill={colorScheme[color].tertiary}
      />
      <path
        d="M103.438 49.8755V42.0741L100.367 49.8755H98.2431L95.173 42.1706V49.8755H92.6829V38.1064H96.1001L99.337 46.0569L102.408 38.1064H106.009V49.8755H103.438Z"
        fill={colorScheme[color].tertiary}
      />
    </svg>
  );

  const regularLogo = (
    <svg
      fill="none"
      style={{
        height: '20px',
        width: '172px',
      }}
      viewBox="0 0 172 20"
    >
      <g>
        <path
          d="M34.9776 8.95413C34.146 8.80167 33.6966 8.0571 33.8644 7.30044L34.3936 4.90944C34.5614 4.15197 35.285 3.65747 36.1159 3.81074C36.9475 3.9632 37.3969 4.70696 37.2291 5.46363L36.7039 7.85543C36.5321 8.6121 35.8085 9.10659 34.9776 8.95413Z"
          fill={colorScheme[color].primary}
        />
        <path
          d="M1.26172 19.9734C1.07412 19.9593 0.891452 19.9066 0.725156 19.8186C0.558859 19.7307 0.412515 19.6093 0.29529 19.4622C0.178064 19.315 0.092482 19.1453 0.0439035 18.9635C-0.00467491 18.7818 -0.0152033 18.592 0.0129777 18.406L0.339683 15.9787C0.442938 15.2099 1.12297 14.6702 1.96433 14.7517C2.8057 14.8324 3.28568 15.5511 3.18242 16.3191L2.85572 18.7464C2.75246 19.5152 2.10308 20.0556 1.26172 19.9734Z"
          fill={colorScheme[color].primary}
        />
        <path
          d="M34.5485 9.92216C33.7015 9.76082 33.2296 10.2714 32.9795 11.1596C32.3528 13.3239 31.734 16.3449 30.3909 16.3498C29.0655 16.3546 28.7203 13.8991 28.2419 11.3484C27.2658 6.15658 26.2405 2.54265 22.675 2.54265C19.2983 2.54265 18.3061 6.3284 17.2703 11.1612C16.8145 13.2796 16.3168 17.4275 14.6082 17.4275C12.7045 17.4275 12.5915 12.6681 12.0107 8.09099C11.4872 3.97935 10.7507 0 7.15613 0C5.47259 0 3.83746 1.5464 3.16711 3.5873C2.13859 6.71964 1.5602 9.05901 1.04877 12.2172C0.910017 13.0819 1.61344 13.6603 2.14182 13.7176C2.71859 13.7797 3.58335 13.6651 3.82939 12.5705C4.29565 10.4981 5.44275 2.4273 7.22228 2.4515C9.06877 2.47651 9.08652 9.42524 10.0158 14.7566C10.6555 18.4286 11.9865 20 14.7389 20C18.3617 20 19.1337 15.4826 19.9912 11.5468C20.3945 9.68177 21.4368 5.01271 22.8202 5.01271C24.4909 5.01271 25.3387 9.90118 25.6904 11.7025C26.2712 14.6783 26.3188 18.8069 30.3441 18.8069C34.4453 18.8069 35.4149 12.2155 35.5593 11.5468C35.7239 10.8006 35.3705 10.077 34.5485 9.92216Z"
          fill={colorScheme[color].primary}
        />
        <path
          d="M12.7568 19.5628C11.9881 19.4998 10.92 18.1995 10.3973 16.4111C8.86461 11.1677 9.33894 0.91637 6.64624 2.6346C9.29941 -0.82041 10.8216 2.31677 11.2822 4.0358C12.2865 7.79089 12.3075 13.8168 13.3272 16.3054C13.791 17.4347 14.5824 17.8195 15.5052 16.9644C14.876 18.1656 13.9983 19.5894 12.7568 19.5628Z"
          fill={colorScheme[color].secondary}
        />
        <path
          d="M28.6372 18.4399C28.2339 18.4399 26.7592 17.4065 26.2672 14.9365C24.9821 8.50242 24.261 4.0076 22.3225 5.21278C24.7474 1.9667 26.7705 4.07778 27.9047 9.64548C28.2508 11.3484 28.6106 13.7595 29.1148 15.1261C29.5124 16.2078 30.3909 16.7451 31.1911 15.9408C30.7709 16.8596 29.7924 18.4399 28.6372 18.4399Z"
          fill={colorScheme[color].secondary}
        />
        <path
          d="M49.5406 13.2763H45.6758V17.9776H43.6462V3.92203H49.4931C51.6654 3.92203 54.3138 5.37405 54.3138 8.62335C54.3138 11.5814 51.713 13.2763 49.5406 13.2763ZM49.6108 5.78304H45.6742V11.4871H49.4656C50.7563 11.4871 52.3769 10.3174 52.3769 8.62335C52.381 6.83253 50.9491 5.78304 49.6108 5.78304Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M63.0712 12.3938C64.2158 14.1596 65.5533 16.1852 66.6746 17.9776H64.4078C64.4078 17.9776 61.3529 13.1811 60.7778 12.4656H58.1068V17.9776H56.1031L56.0789 3.92203H62.3564C64.5764 3.92203 66.8432 5.47247 66.8432 8.1458C66.8416 10.365 64.9564 12.155 63.0712 12.3938ZM62.4258 5.66365H58.1068V10.6481H62.4266C63.7173 10.6481 64.933 9.47843 64.933 8.14257C64.933 6.66554 63.7149 5.66365 62.4266 5.66365H62.4258Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M75.1698 18.2406C71.351 18.2406 67.8193 15.3051 67.8193 10.9377C67.8193 6.47519 71.351 3.63568 75.1698 3.63568C79.0104 3.63568 82.5428 6.59378 82.5428 10.9611C82.5428 15.3527 79.0104 18.2406 75.1698 18.2406ZM75.1698 5.4491C72.0665 5.4491 69.7513 7.93125 69.7513 10.9611C69.7513 13.9208 72.0665 16.3796 75.1698 16.3796C78.2949 16.3796 80.5624 13.945 80.5624 10.9611C80.5624 7.95464 78.2707 5.4491 75.1698 5.4491V5.4491Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M84.3666 16.3699L85.1184 13.2691C87.148 14.5694 88.9501 14.9558 89.9762 14.9558C91.5267 14.9558 93.1005 14.4315 93.1005 13.5425C93.1005 13.2005 92.7778 12.9036 91.9147 12.6987C90.9338 12.4479 89.9077 12.4245 88.859 12.2656C86.6697 11.9429 84.1609 11.1935 84.1609 8.13777C84.1609 4.32944 88.379 3.69055 90.5006 3.69055C92.9206 3.69055 94.2178 4.16972 96.0667 5.19582L95.4504 8.20554C93.6757 7.13427 92.0067 6.70108 90.5474 6.70108C88.1757 6.70108 87.6054 7.4529 87.6054 7.95547C87.6054 8.77666 89.5673 8.91299 90.3885 8.95817C92.2809 9.05013 96.5458 9.39135 96.5458 13.5425C96.5458 17.4194 92.4172 18.1946 90.0924 18.1946C87.9006 18.1946 86.7834 17.8752 84.3666 16.3699Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M106.258 17.9889H102.655L102.632 7.11085H97.7971V3.91882H111.093V7.11085H106.258V17.9889Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M117.248 12.8125H116.222V17.9889H112.687L112.665 3.91882H119.985C122.904 3.91882 124.524 5.69352 124.524 8.36524C124.524 10.4174 122.904 11.9009 121.103 12.4938L125.208 17.5106V17.9889H121.512L117.248 12.8125ZM116.222 7.11085V9.55106H119.279C120.646 9.52928 121.079 8.86699 121.079 8.27408C121.079 7.72716 120.829 7.11085 119.484 7.11085H116.222Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M126.891 3.91882H137.886V7.11085H130.429V9.4599H136.768V12.539H130.451V14.7743H138.091V17.9889H126.891V3.91882Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M149.903 15.5955H144.111L143.085 17.9889H139.71L139.686 17.6937L145.798 3.89948H148.467L154.305 17.6977L154.328 17.993H150.839L149.903 15.5955ZM145.385 12.4704H148.623L147.03 8.55079L145.385 12.4704Z"
          fill={colorScheme[color].tertiary}
        />
        <path
          d="M168.414 18.0906V8.68065L164.711 18.0906H162.149L158.445 8.80084V18.0906H155.442V3.89301H159.566L163.47 13.4828L167.173 3.89301H171.518V18.0906H168.414Z"
          fill={colorScheme[color].tertiary}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="20" width="171.518" />
        </clipPath>
      </defs>
    </svg>
  );

  const squareLogo = (
    <svg
      fill="none"
      height="128"
      viewBox="0 0 128 128"
      width="128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#36CCBA" height="128" rx="16" width="128" />
      <path
        d="M103.465 61.4186C101.478 61.0534 100.545 59.2711 100.946 57.4565L102.216 51.7256C102.617 49.9122 104.203 48.7273 106.19 49.0925C108.178 49.4651 109.11 51.2412 108.708 53.0559L107.444 58.7917C107.041 60.6001 105.458 61.785 103.465 61.4186Z"
        fill="#533BE2"
      />
      <path
        d="M22.2239 87.9005C20.208 87.703 18.9834 85.9852 19.2318 84.1445L20.0143 78.3267C20.2627 76.486 21.891 75.1906 23.9069 75.388C25.924 75.5843 27.0729 77.302 26.8257 79.1428L26.0432 84.9605C25.7948 86.8013 24.2397 88.0967 22.2239 87.9005Z"
        fill="#533BE2"
      />
      <path
        d="M102.363 63.8182C100.333 63.4456 99.2023 64.6553 98.6037 66.7842C97.0995 71.971 95.6227 79.2047 92.3934 79.2221C89.2162 79.2333 88.389 73.3497 87.2426 67.2376C84.9162 54.791 82.4533 46.1289 73.9092 46.1289C65.8172 46.1289 63.4399 55.2021 60.9484 66.7817C59.8554 71.858 58.6618 81.7994 54.5717 81.7994C50.0109 81.7994 49.7401 70.3949 48.349 59.4263C47.0995 49.5731 45.3345 40.0453 36.7197 40.0366C32.6855 40.0366 28.7705 43.7429 27.1559 48.6341C24.6916 56.1386 22.9329 61.7452 21.7157 69.3118C21.3828 71.3848 23.072 72.7721 24.3352 72.9075C25.7176 73.0578 27.7881 72.7833 28.3793 70.1601C29.4971 65.1919 32.6221 45.8531 36.8824 45.9115C41.3078 45.9711 41.3538 62.6233 43.5758 75.3978C45.1085 84.1978 48.2956 87.9637 54.8934 87.9637C63.5766 87.9637 65.426 77.138 67.4816 67.7071C68.4578 63.2357 70.9444 52.0398 74.2594 52.0398C78.2638 52.0398 80.2946 63.7548 81.1367 68.0697C82.5377 75.2016 82.6495 85.0945 92.294 85.0945C102.124 85.0945 104.446 69.2994 104.792 67.7058C105.179 65.9185 104.333 64.1846 102.363 63.8182Z"
        fill="#533BE2"
      />
      <path
        d="M50.1427 86.9156C48.2995 86.7653 45.5819 83.5472 44.4131 79.1578C41.0472 66.5112 41.8259 42.6414 35.7188 46.3676C42.0768 38.0881 45.5471 45.6013 46.6488 49.7211C49.0571 58.7198 49.0683 73.0978 51.5102 79.1106C52.4865 81.5177 54.516 82.7386 56.7343 80.6892C55.219 83.5683 53.1162 86.979 50.1427 86.9156Z"
        fill="#28237A"
      />
      <path
        d="M88.1878 84.2262C87.2116 84.2262 83.6618 81.8899 82.5104 75.8299C79.5742 60.385 77.4515 49.7208 73.0708 52.5266C78.8811 44.7488 83.6084 49.8375 86.4638 63.1498C87.3519 67.2884 88.1394 73.0105 89.3442 76.2833C90.2993 78.8767 92.4033 80.1635 94.3211 78.237C93.3088 80.438 90.9663 84.2262 88.1878 84.2262Z"
        fill="#28237A"
      />
    </svg>
  );

  switch (type) {
    case 'regular':
      return regularLogo;
    case 'stacked':
      return stackedLogo;
    case 'shapeless':
      return shapelessLogo;
    case 'square':
      return squareLogo;
    default:
      return compactLogo;
  }
};
