export enum TrackingEvent {
  ChartOptionSelected = 'ChartOptionSelected',
  ChooseLicenseClicked = 'ChooseLicenseClicked',
  CommentAttachmentDownloaded = 'CommentAttachmentDownloaded',
  CommentAttachmentRemoved = 'CommentAttachmentRemoved',
  CommentAttachmentsAdded = 'CommentAttachmentsAdded',
  DataViewChanged = 'DataViewChanged',
  DocumentCopied = 'Document copied',
  DocumentDeleteSelected = 'Document delete selected',
  DocumentDownloadSelected = 'Document download Selected',
  DocumentLocked = 'Document locked',
  DocumentMoved = 'Document moved',
  DocumentPreviewed = 'Document previewed',
  DocumentShareSelected = 'Document share selected',
  DocumentUnlocked = 'Document unlocked',
  DocumentUploadSelected = 'Document upload selected',
  DocumentViewed = 'Document viewed',
  DownloadZipSelected = 'Download .zip selected',
  ErrorOcurred = 'Error ocurred',
  ExpectedDocumentCreated = 'Expected document created',
  GroupCreated = 'Group created',
  GroupDeleted = 'Group deleted',
  GroupSaved = 'Group saved',
  HierarchicalListDeleted = 'Hierarchical list deleted',
  LicenseSelected = 'LicenseSelected',
  LinkSharedToMicrosoftTeams = 'Link shared to Microsoft Teams',
  MetadataEditIntent = 'Intent to edit metadata',
  MetadataTemplateAssigned = 'Metadata template assigned to folder',
  MetadataTemplateCreated = 'Metadata template created',
  MetadataTemplateDeleted = 'Metadata template deleted',
  MetadataTemplatePublished = 'Metadata template published',
  MetadataTemplateSaved = 'Metadata template saved',
  NavigationSelected = 'Navigation selected',
  OfficeOnlineOpened = 'Office Online opened',
  OpenCdeInit = 'Connection to Prostream opened from openCDE client',
  OpenCdeShareDocument = 'Document from Prostream opened in openCDE client',
  OpenCdeUploadDocument = 'Document from openCDE client uploaded in Prostream',
  PdftronOpened = 'Pdftron opened',
  PredefinedFilterSelect = 'PredefinedFilterSelect',
  ProjectSelected = 'Project selected',
  PublishFileIntent = 'Intend to publish a file',
  SignupSuccessful = 'Signup successful',
  TemplateExported = 'Template exported',
  TemplateImported = 'Template imported',
  UpgradeLicenseButtonClicked = 'UpgradeLicenseButtonClicked',
  UploadFileIntent = 'Intend to upload a file',
  UserProfileUpdated = 'User Profile Updated',
  VersionPanelClosed = 'Version panel closed',
  VersionPanelOpened = 'Version panel opened',
}

export enum Action {
  ApproveButtonClicked = 'ApproveButtonClicked',
  CommentAttachmentButtonClicked = 'CommentAttachmentButtonClicked',
  CommentSaveButtonClicked = 'CommentSaveButtonClicked',
  CommentTextboxClicked = 'CommentTextboxClicked',
  CreateDrawingButtonClicked = 'CreateDrawingButtonClicked',
  CreateQualityTaskButtonClicked = 'CreateQualityTaskButtonClicked',
  CreateReportButtonClicked = 'CreateReportButtonClicked',
  CreateResolveTaskButtonClicked = 'CreateResolveTaskButtonClicked',
  CreateTaskReportButtonClicked = 'CreateTaskReportButtonClicked',
  DeleteDrawingButtonClicked = 'DeleteDrawingButtonClicked',
  DocumentPreviewModeChange = 'Document preview mode change',
  FinalizeButtonClicked = 'FinalizeButtonClicked',
  ItemOpened = 'ItemOpened',
  LinkedSnagOpened = 'LinkedSnagOpened',
  OfficeOnlineButtonClicked = 'OfficeOnlineButtonClicked',
  OpenDrawing = 'OpenDrawing',
  OpenTask = 'OpenTask',
  RejectButtonClicked = 'RejectButtonClicked',
  SidebarButtonClicked = 'Sidebar button clicked',
  SpaceBarPressed = 'Space bar pressed',
  TableButtonClicked = 'Table button clicked',
  ThumbnailClicked = 'Thumbnail clicked',
  UnapproveButtonClicked = 'UnapproveButtonClicked',
  UnfinalizeButtonClicked = 'UnfinalizeButtonClicked',
  UnrejectButtonClicked = 'UnrejectButtonClicked',
  ViewInTableButtonClicked = 'ViewInTableButtonClicked',
  ViewOnDrawingButtonClicked = 'ViewOnDrawingButtonClicked',
}

export enum DmsTrackingLocation {
  DocumentContextMenu = 'Document Context Menu',
  DocumentSidebar = 'Document Sidebar',
  DocumentTable = 'Document Table',
  VersionContextMenu = 'Version Context Menu',
  VersionSidebar = 'Version Sidebar',
}

export type DmsTrackingLocationType =
  | DmsTrackingLocation.DocumentContextMenu
  | DmsTrackingLocation.DocumentSidebar
  | DmsTrackingLocation.DocumentTable
  | DmsTrackingLocation.VersionContextMenu
  | DmsTrackingLocation.VersionSidebar;
