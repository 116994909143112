import { FinalizeAction, useFinalize } from '@pro4all/documents/ui/finalize';
import { Document, DocumentVersion } from '@pro4all/graphql';
import { Action, DmsTrackingLocationType } from '@pro4all/shared/config';
import { Position, PositionType } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useHandleFinalizeOrUnfinalize = ({
  folderId,
  organizationId,
  position,
  projectId,
  selection,
  selectionVersion,
  trackingPosition,
  uncheckAllRows,
  userId,
}: {
  folderId: string;
  organizationId: string;
  position: PositionType;
  projectId: string;
  selection: Document[];
  selectionVersion: DocumentVersion[];
  trackingPosition: DmsTrackingLocationType;
  uncheckAllRows: () => void;
  userId: string;
}) => {
  const { track } = useAnalytics();

  const { finalizeOrUnfinalizeDocument } = useFinalize({
    documents: selection,
    versions: selectionVersion,
  });

  const handleFinalizeOrUnfinalize = (action: FinalizeAction) => {
    const trackingAction =
      action === FinalizeAction.Finalize
        ? Action.FinalizeButtonClicked
        : Action.UnfinalizeButtonClicked;

    track(trackingAction, {
      buttonLocation: trackingPosition,
      documentIds: selection.map((row) => row.id),
      folderId,
      organizationId,
      projectId,
      userId,
    });

    finalizeOrUnfinalizeDocument(action);
    if (position !== Position.Contextmenu) uncheckAllRows();
  };

  return handleFinalizeOrUnfinalize;
};
