import React from 'react';
import { useTranslation } from 'react-i18next';

import { StampState } from '@pro4all/graphql';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { isDefined } from '@pro4all/shared/utils';

import { stampStateLabels, stampStateValues } from '../records/State';
import { hasLabel } from '../utils/';

import { TagSelect } from './TagSelect';

export const QrStampFilter: React.FC<FilterBaseProps> = ({
  value,
  type,
  facetGroup,
  facetLoading,
}) => {
  const { t } = useTranslation();

  const getLabel = (facetValue: string) =>
    Object.entries(stampStateValues).reduce((foundLabel, [key, value]) => {
      if (value === facetValue) return stampStateLabels[key as StampState];
      return foundLabel;
    }, '');

  const ids = value.split(delimiters.multiSelectOptions);
  const options: Option[] =
    facetGroup?.items
      ?.filter(isDefined)
      .map((facetItem) => ({
        id: facetItem.value,
        inputValue: facetItem.value,
        label: t(getLabel(facetItem.value)),
      }))
      .filter(hasLabel) || [];

  const initialOptions = options.filter((option) => ids.includes(option.id));

  return (
    <TagSelect
      initialOptions={initialOptions}
      loading={facetLoading}
      options={options}
      showInputFilter={false}
      type={type}
      value={value}
    />
  );
};
