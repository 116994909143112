import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

export const Wrapper = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    > .MuiSvgIcon-root {
      display: block;
      width: 240px;
      height: auto;
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

export const CustomText = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const ButtonContinue = styled(Button)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;
