import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import TreeBreadcrumbs from './TreeBreadcrumbs';

interface TreeCollapseButtonProps {
  onClick: () => void;
  open: boolean;
}

const TreeCollapseButton: React.FC<TreeCollapseButtonProps> = ({
  onClick,
  open,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      onClick={onClick}
      sx={{
        borderBottom: isMobile ? `1px solid ${theme.palette.divider}` : 'none',
        borderRight: isMobile ? 'none' : `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        height: isMobile ? '40px' : '100%',
        justifyContent: isMobile ? 'space-between' : 'center',
        paddingX: isMobile ? 1 : 0,
        paddingY: 1,
      }}
    >
      {isMobile && <TreeBreadcrumbs />}
      {isMobile ? (
        <IconButton size="small">
          <Icon iconName={open ? 'expandLess' : 'expandMore'} />
        </IconButton>
      ) : (
        <IconButton size="small">
          <Icon
            iconName={open ? 'chevronLeftRounded' : 'chevronRightRounded'}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default TreeCollapseButton;
