import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const EditorWrap = styled.div<{
  $bgColor?: string;
  $borderColor?: string;
  $hasFocus: boolean;
  $hasPadding?: boolean;
  $marginLeft?: number;
}>`
  margin-left: ${({ $marginLeft }) => ($marginLeft ? `${$marginLeft}px` : '0')};
  ${({ $borderColor }) => $borderColor && `border: 1px solid ${$borderColor};`}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : 'white')};
  color: ${customColors.grey700};
  border-radius: 20px;
  position: relative;
  animation-duration: 1s;
  animation-delay: 0.5s;

  /**
  * Selects a paragraph in the slate editor.
  * The paragraph is a special placeholder that works even if the textarea has content, like an initial signature.
  **/
  div[role='textbox'],
  div[contenteditable='false'] {
    & > p.custom-placeholder ~ p.custom-placeholder {
      &:not(:first-child) {
        &::after {
          content: '';
        }
      }
    }
  }
`;

export const SubjectWrap = styled.div`
  padding: 0.5rem 0 1.5rem;
`;
