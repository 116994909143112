import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Build12State, Document } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import * as Styled from './ShareBuild12Status.styled';

export const ShareBuild12Status: React.FC<Document> = ({ stateBuild12 }) => {
  const { t } = useTranslation();
  const { connections: projectConnections } = useProjectContext();

  const {
    connections: unfilteredConnections,
    oldConnections: unfilteredOldConnections,
    state: unfilteredState,
  } = stateBuild12 || {};

  // Filter unused connections out for the correct status and connections
  const connections = useMemo(
    () =>
      unfilteredConnections?.filter(
        (x) =>
          x != null && projectConnections.find((pc) => pc.id === x.connectionId)
      ) ?? [],
    [unfilteredConnections, projectConnections]
  );

  const oldConnections = useMemo(
    () =>
      unfilteredOldConnections?.filter(
        (x) =>
          x != null && projectConnections.find((pc) => pc.id === x.connectionId)
      ) ?? [],
    [unfilteredOldConnections, projectConnections]
  );

  const state = useMemo(
    () =>
      unfilteredConnections?.length === connections.length &&
      (unfilteredOldConnections == null ||
        unfilteredOldConnections?.length === oldConnections.length)
        ? unfilteredState
        : connections.length > 0
        ? Build12State.Shared
        : oldConnections.length > 0
        ? Build12State.PreviousVersionShared
        : Build12State.NotShared,
    [
      unfilteredConnections?.length,
      connections.length,
      unfilteredOldConnections,
      oldConnections.length,
      unfilteredState,
    ]
  );

  const connectionsLabel = (
    <>
      {t('Connections through which the current version is shared.')}
      <br />
      <br />
    </>
  );
  const connectionsInfo = connections?.map((connection, index) => (
    <React.Fragment key={index}>
      {`- ${connection?.connectionName} / ${connection?.phaseName}`}
      <br />
    </React.Fragment>
  ));

  const alsoPreviousLabel = (
    <React.Fragment>
      <br />
      {t('A previous version has also been shared with 12Build.')}
      <br />
    </React.Fragment>
  );
  const title = (
    <>
      {connectionsLabel}
      {connectionsInfo}
      {oldConnections && oldConnections.length > 0 && alsoPreviousLabel}
    </>
  );

  return state === Build12State.Shared ? (
    <Tooltip placement="bottom" title={title}>
      <Icon iconName="build12" />
    </Tooltip>
  ) : state === Build12State.PreviousVersionShared ? (
    <Tooltip
      placement="bottom"
      title={t('A previous version has been shared with 12Build.')}
    >
      <Styled.IconPreviousVersion>
        <Icon iconName="build12" />
      </Styled.IconPreviousVersion>
    </Tooltip>
  ) : null;
};
