import styled from 'styled-components';

// We don't want to use MUI's Icon here for performance reasons.
export const SvgIcon = styled.svg.attrs({
  'aria-hidden': true,
  focusable: false,
  viewBox: '0 0 24 24',
})`
  display: inline-block;
  width: 1rem;
  height: 1rem;
`;
