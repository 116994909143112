import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { AiFrame } from '@pro4all/shared/ui/ai';
import { Button } from '@pro4all/shared/ui/buttons';
import { FormikForm } from '@pro4all/shared/ui/general';
import { sortByWithInnerSort } from '@pro4all/shared/utils';

import { useDocumentKeywordsContext } from './DocumentKeywordsProvider';
import * as Styled from './Keyword.styles';
import { KeywordEdit } from './KeywordEdit';
import { InitialValues } from './types';
import { useOnSubmitDocumentKeywords } from './useOnSubmitDocumentKeywords';

export const DocumentKeywordsEdit = ({
  document,
  setDisplayKeywords,
  version,
  versionId,
}: {
  setDisplayKeywords: (value: boolean) => void;
  versionId: string;
} & DocumentAndVersionType) => {
  const { t } = useTranslation();
  const [formDirty, setFormDirty] = useState(false);
  const [deletedKeywords, setDeletedKeywords] = useState<string[]>([]);
  const initialValuesToCompare = useRef<InitialValues>();

  const {
    resetKeywords,
    state: { keywords },
  } = useDocumentKeywordsContext();

  const keywordsToDisplay = keywords
    .filter((keyword) => !deletedKeywords.includes(keyword.id))
    .sort(
      sortByWithInnerSort({
        mainProp: 'score',
        mainPropSortOrder: 'desc',
        subProp: 'text',
      })
    );

  const initialValues: InitialValues = {
    items: keywordsToDisplay.map((keyword) => ({
      id: keyword.id,
      oldText: keyword.oldText,
      score: keyword.score.toString(),
      text: keyword.text,
    })),
  };

  // `initialValuesToCompare.current` contains updated values but also deleted keywords.
  // `intialValues` does not contain updated values but does not contain the deleted keywords.
  // So we have to calculate `initialValues` to pass into Formik.
  const idsInitialValues = initialValues.items.map((item) => item.id);
  const initialValuesFiltered = initialValuesToCompare.current
    ? {
        items: initialValuesToCompare.current.items.filter((item) =>
          idsInitialValues.includes(item.id)
        ),
      }
    : initialValues;

  const validationSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        score: Yup.string().required('Score is required.'),
        text: Yup.string().required('Keyword is required.'),
      })
    ),
  });

  const closeEditMode = () => {
    setDeletedKeywords([]);
    setDisplayKeywords(true);
  };

  const onSubmit = useOnSubmitDocumentKeywords({
    closeEditMode,
    document,
    version,
    versionId,
  });

  return (
    <AiFrame label={t('AI keywords')}>
      <Formik
        enableReinitialize
        initialValues={initialValuesFiltered}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, errors, isSubmitting, values }) => {
          initialValuesToCompare.current = values; // To calculate `initialValues`.
          return (
            <FormikForm>
              <Box
                sx={{
                  maxHeight: 300,
                  mb: 1,
                  overflowY: 'auto',
                }}
              >
                {values.items.map((item, index) => (
                  <Styled.KeywordWrapper key={item.id}>
                    <KeywordEdit
                      index={index}
                      item={item}
                      onDelete={(id: string) => {
                        setFormDirty(true); // To make the form dirty after deleting a keyword.
                        setDeletedKeywords([...deletedKeywords, id]);
                      }}
                      values={values}
                    />
                  </Styled.KeywordWrapper>
                ))}
              </Box>
              <Box display="flex">
                <Button
                  color="inherit"
                  onClick={() => {
                    resetKeywords(); // undo all changes.
                    closeEditMode();
                  }}
                  startIcon="cancel"
                  variant="text"
                >
                  {t('Cancel')}
                </Button>
                <Button
                  color="inherit"
                  disabled={isSubmitDisabled({
                    dirty: dirty || formDirty,
                    errors,
                    isSubmitting,
                  })}
                  onClick={() => onSubmit(values)}
                  startIcon="ai"
                  variant="text"
                >
                  {t('Save and close')}
                </Button>
              </Box>
            </FormikForm>
          );
        }}
      </Formik>
    </AiFrame>
  );
};
