const ChartColorsHex = [
  '#533BE2',
  '#0693e3',
  '#36CCBA',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#9b51e0',
  '#cf2e2e',
  '#E25314',
  '#f78da7',
  '#ff6900',
  '#fcb900',
  '#828A92',
  '#abb8c3',
  '#F2F7F9',
  '#ffffff',
  '#000000',
  '#533BE2',
  '#0693e3',
  '#36CCBA',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#9b51e0',
  '#cf2e2e',
  '#E25314',
  '#f78da7',
  '#ff6900',
  '#fcb900',
  '#828A92',
  '#abb8c3',
  '#F2F7F9',
  '#ffffff',
  '#000000',
  '#533BE2',
  '#0693e3',
  '#36CCBA',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#9b51e0',
  '#cf2e2e',
  '#E25314',
  '#f78da7',
  '#ff6900',
  '#fcb900',
  '#828A92',
  '#abb8c3',
  '#F2F7F9',
  '#ffffff',
  '#000000',
  '#533BE2',
  '#0693e3',
  '#36CCBA',
  '#7bdcb5',
  '#00d084',
  '#8ed1fc',
  '#9b51e0',
  '#cf2e2e',
  '#E25314',
  '#f78da7',
  '#ff6900',
  '#fcb900',
  '#828A92',
  '#abb8c3',
  '#F2F7F9',
  '#ffffff',
  '#000000',
];

export const ChartColors = ChartColorsHex.map((color) => hexToRGBA(color, 1));

export function hexToRGBA(hex: string, opacity: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
