import { useMoveFolderMutation } from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { useFolderTreeContextOuter } from './FolderTreeProviderOuter';

export function useMoveFolder() {
  const [move] = useMoveFolderMutation();
  const { showSingleError } = useShowMessages();
  const { moveFolder: moveFolderInLocalState } = useFolderTreeContextOuter();

  const moveFolder = async ({
    childFolder,
    parentFolder,
  }: {
    childFolder: { currentParentFolderId: string; id: string; name: string };
    parentFolder?: { id: string; name: string };
  }) => {
    // Move the folder only if it has been dropped in a different parent folder.

    if (!childFolder.currentParentFolderId && !parentFolder) return; // Moving root folder to root.
    if (childFolder?.currentParentFolderId === parentFolder?.id) return; // Moving subfolder to same parent folder.

    const childFolderId = childFolder.id;
    const parentFolderId = parentFolder ? parentFolder.id : '';

    try {
      await move({
        variables: {
          childFolderId,
          parentFolderId,
        },
      });
      moveFolderInLocalState({ childFolderId, parentFolderId });
    } catch (e) {
      showSingleError(e);
    }
  };

  return {
    moveFolder,
  };
}
