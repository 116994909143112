import { RenderElementProps } from 'slate-react';

import { ImageElement } from '../slate-editor/CustomTypes';

type TStaticImage = Omit<RenderElementProps, 'element'> & {
  element: ImageElement;
};

export const StaticImage = ({
  children,
  attributes,
  element,
}: TStaticImage) => {
  const fileUrl = element.url || '';

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} style={{ position: 'relative' }}>
        <img alt={fileUrl} src={fileUrl} style={{ maxWidth: '100%' }} />
      </div>
    </div>
  );
};
