import { useCallback, useEffect, useState } from 'react';

import { FieldDefinition } from '@pro4all/graphql';

import { useFieldContext } from '../TemplateMutationContext';

export const useUpdateIndicativeFields = () => {
  const { editFields, fieldDefinitions } = useFieldContext();
  const [toggleIndicativeField, setToggleIndicativeField] = useState('');

  const updateIndicativeFields = useCallback(
    (id: string, fieldDefinitions: FieldDefinition[]) => {
      fieldDefinitions.forEach((field) => {
        if (field.type === 'Section') {
          updateIndicativeFields(id, field.valueType.subFields);
        } else {
          field.id === id
            ? (field.indicative = !field.indicative)
            : (field.indicative = false);
        }
      });
    },
    []
  );

  useEffect(() => {
    if (toggleIndicativeField !== '') {
      const newFieldDefinitions = fieldDefinitions;
      updateIndicativeFields(toggleIndicativeField, newFieldDefinitions);
      editFields(newFieldDefinitions);
    }
    setToggleIndicativeField('');
  }, [
    editFields,
    fieldDefinitions,
    toggleIndicativeField,
    updateIndicativeFields,
  ]);

  return setToggleIndicativeField;
};
