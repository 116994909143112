// hooks/useAttachments.ts
import { UseFormMethods } from 'react-hook-form';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  useAddCollection,
  useImageUpload,
  useUpload,
} from '@pro4all/communication/ui/general';

import { MessageFormFields } from '../types';
interface UseAttachmentsProps {
  editor: BaseEditor & ReactEditor;
  form: UseFormMethods<MessageFormFields>;
  onUploadSuccess: () => void;
}

export const useAttachments = ({
  onUploadSuccess,
  editor,
}: UseAttachmentsProps) => {
  const { handleImageUpload, isImageUploading } =
    useImageUpload<MessageFormFields>({
      editor,
      editorName: 'body',
      onSuccess: onUploadSuccess,
    });

  const { handleUpload, isUploading, uploadProgress } =
    useUpload<MessageFormFields>({
      onSuccess: onUploadSuccess,
    });
  const { addColectionFiles, isAddingCollection } =
    useAddCollection<MessageFormFields>();

  return {
    addColectionFiles,
    handleImageUpload,
    handleUpload,
    isAddingCollection,
    isImageUploading,
    isUploading,
    uploadProgress,
  };
};
