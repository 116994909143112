import { FieldDefinition } from '@pro4all/graphql';

export const getItem = ({
  id,
  items,
}: {
  id: string;
  items: FieldDefinition[];
}): FieldDefinition | undefined => {
  let item = items.find((item) => item.id === id);

  if (!item) {
    // So on this level we can't find the item, but we can try to find it in the children.
    // Find all items that have a prop called `valueType` that has a prop called `subFields`.
    const itemsWithSubFields = items.filter(
      (item) => item.valueType?.subFields?.length
    );

    itemsWithSubFields.forEach((itemWithSubFields) => {
      const subItems = itemWithSubFields.valueType?.subFields || [];
      const itemSublevel = getItem({ id, items: subItems });
      if (itemSublevel) {
        item = itemSublevel;
      }
    });
  }

  return item;
};
