import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import zxcvbn from 'zxcvbn';

import { Grid, LinearProgress } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

const scorePalette = ['error', 'error', 'warning', 'warning', 'secondary'];

interface Props {
  value: string;
}
interface LabelProps {
  score: 0 | 1 | 2 | 3 | 4;
}

const Label = styled.label<LabelProps>`
  width: 100%;
  color: ${({ score, theme }) => theme.palette[scorePalette[score]].main};
  text-align: right;
`;

const StrengthLabel: React.FC<LabelProps> = ({ score }) => {
  const { t } = useTranslation();
  const labels = [t('weak'), t('weak'), t('okay'), t('good'), t('great')];
  return <Label score={score}>{labels[score]}</Label>;
};

export const PasswordStrengthMeter: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  const passwordStrength = zxcvbn(value);

  return (
    <Grid alignItems="center" container justifyContent="center">
      <Grid item xs>
        <Text variant="body2">
          {t('Password strength')}:{' '}
          {value && <StrengthLabel score={passwordStrength.score} />}
        </Text>
      </Grid>
      <Grid item xs>
        <LinearProgress
          color={passwordStrength.score > 3 ? 'secondary' : 'primary'}
          value={passwordStrength.score * 25}
          variant="determinate"
        />
      </Grid>
    </Grid>
  );
};
