import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ImportExport: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M5.85612 6.1449L8.64612 3.3549C8.83612 3.1649 9.15612 3.1649 9.34612 3.3549L12.1361 6.1449C12.4561 6.4549 12.2361 6.9949 11.7861 6.9949H9.99612V13.0049C9.99612 13.5549 9.54612 14.0049 8.99612 14.0049C8.44612 14.0049 7.99612 13.5549 7.99612 13.0049V6.9949H6.20612C5.75612 6.9949 5.53612 6.4549 5.85612 6.1449ZM15.9961 11.0049V17.0149H17.7961C18.2361 17.0149 18.4661 17.5549 18.1461 17.8649L15.3561 20.6449C15.1561 20.8349 14.8461 20.8349 14.6461 20.6449L11.8561 17.8649C11.5361 17.5549 11.7561 17.0149 12.2061 17.0149H13.9961V11.0049C13.9961 10.4549 14.4461 10.0049 14.9961 10.0049C15.5461 10.0049 15.9961 10.4549 15.9961 11.0049Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
