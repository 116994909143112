import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import type { ApiKey } from '@pro4all/graphql';
import { ApiKeysIncludeDocument } from '@pro4all/graphql';

export const useApolloClientCacheHelpers = () => {
  const userId = AuthService.getProfile()?.userId;

  const variables = {
    includeExpiresAt: true,
    userId,
  };

  const getCurrentlyCachedApiKeys = () => {
    const cachedApiKeys = client?.readQuery({
      query: ApiKeysIncludeDocument,
      variables,
    }).apiKeys as ApiKey[];
    return cachedApiKeys;
  };

  const updateCachedApiKeys = (updatedApiKeys: ApiKey[]) => {
    client.writeQuery({
      data: {
        apiKeys: updatedApiKeys,
      },
      query: ApiKeysIncludeDocument,
      variables,
    });
  };

  return { getCurrentlyCachedApiKeys, updateCachedApiKeys };
};
