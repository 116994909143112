import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const WithdrawApproval: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12 2C11.4859 2 10.9883 2.31094 10.5165 2.60571C10.2766 2.75561 10.0435 2.90133 9.81818 2.99984C9.55807 3.11359 9.21995 3.15847 8.8771 3.20397C8.36915 3.27139 7.85081 3.34019 7.56037 3.63636C7.23356 3.96963 7.15734 4.55283 7.08323 5.11994C7.03739 5.47074 6.99235 5.81539 6.88929 6.09091C6.80561 6.31463 6.6589 6.54529 6.50848 6.7818C6.25933 7.17353 6 7.58128 6 8C6 8.3366 6.22073 8.66611 6.43496 8.98592C6.58353 9.20769 6.72896 9.4248 6.79548 9.63636C6.88153 9.91004 6.90749 10.2555 6.93401 10.6084C6.97626 11.1707 7.01993 11.7518 7.31038 12.0909C7.64661 12.4835 8.25511 12.6103 8.84933 12.7341C9.19614 12.8064 9.53808 12.8777 9.81818 13.0002C10.0435 13.0987 10.2766 13.2444 10.5165 13.3943C10.9882 13.6891 11.4859 14 12 14C12.5141 14 13.0117 13.6891 13.4835 13.3943C13.7233 13.2444 13.9566 13.0987 14.1818 13.0002C14.4619 12.8777 14.8039 12.8064 15.1507 12.7341C15.7449 12.6103 16.3534 12.4835 16.6896 12.0909C16.9801 11.7518 17.0237 11.1707 17.066 10.6084C17.0925 10.2555 17.1185 9.91004 17.2045 9.63636C17.271 9.4248 17.4165 9.20769 17.565 8.98592C17.7793 8.66611 18 8.3366 18 8C18 7.58128 17.7407 7.17353 17.4915 6.7818C17.3411 6.54529 17.1944 6.31463 17.1107 6.09091C17.0077 5.81539 16.9626 5.47074 16.9168 5.11994C16.8427 4.55283 16.7664 3.96963 16.4396 3.63636C16.1492 3.34019 15.6309 3.27139 15.1229 3.20397C14.78 3.15847 14.4419 3.11359 14.1818 2.99984C13.9565 2.90133 13.7234 2.75561 13.4835 2.60571C13.0117 2.31094 12.5141 2 12 2ZM12 12.3636C9.59455 12.3636 7.63636 10.4055 7.63636 8C7.63636 5.59455 9.59455 3.63636 12 3.63636C14.4055 3.63636 16.3636 5.59455 16.3636 8C16.3636 10.4055 14.4055 12.3636 12 12.3636ZM10.9091 9.18364L14.1164 5.97636C14.3291 5.76364 14.6782 5.76364 14.8909 5.97636C15.1036 6.18909 15.1036 6.53273 14.8909 6.74545L11.2964 10.34C11.0836 10.5527 10.74 10.5527 10.5273 10.34L9.11455 8.92727C8.90182 8.71455 8.90182 8.37091 9.11455 8.15818C9.21645 8.05604 9.35481 7.99864 9.49909 7.99864C9.64337 7.99864 9.78173 8.05604 9.88364 8.15818L10.9091 9.18364Z"
      fillRule="evenodd"
    />
    <path d="M12.3717 16C10.2588 16 8.3453 16.7893 6.87029 18.073L5.36339 16.5661C4.86109 16.0638 4 16.4146 4 17.1242V21.5811C4 22.0196 4.35879 22.3784 4.7973 22.3784H9.25423C9.96383 22.3784 10.3226 21.5173 9.82031 21.015L8.29746 19.4922C9.40571 18.5673 10.8169 17.9933 12.3797 17.9933C14.8991 17.9933 17.0758 19.4603 18.1123 21.5811C18.3275 22.0276 18.8378 22.2509 19.3082 22.0914C19.8743 21.908 20.1613 21.2622 19.9062 20.72C18.5348 17.9295 15.6805 16 12.3717 16Z" />
  </SvgIcon>
);
