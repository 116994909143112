import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import type { RemoveStampInput } from '@pro4all/graphql';
import { StampStatus, useRemoveStampsMutation } from '@pro4all/graphql';

export function useRevoke() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [removeStamps] = useRemoveStampsMutation();

  const updateCachedDocuments = useUpdateCachedDocuments();

  return async (stamps: RemoveStampInput[] = []) => {
    if (!stamps?.length) return;

    try {
      const { data } = await removeStamps({ variables: { stamps } });
      const errors = data?.removeStamps?.errors;

      if (data?.removeStamps?.success) {
        const vars = {
          count: stamps.length,
          errorCount: errors?.length,
        };
        const documentsIds = stamps.map(({ documentId }) => documentId);

        updateCachedDocuments({
          documentIds: documentsIds,
          fieldToUpdate: 'qrCodeState',
          keyField: 'id',
          value: StampStatus.None,
        });

        const message =
          errors?.length && errors.length > 0
            ? t('{{count}} QR codes revoked with {{errorCount}} errors.', vars)
            : t('{{count}} QR codes revoked', vars);

        enqueueSnackbar(message);
      } else {
        enqueueSnackbar(
          t(data?.removeStamps?.errors?.[0] ?? 'Something went wrong')
        );
      }
    } catch (error) {
      enqueueSnackbar(
        t('Something went wrong. Please try again or contact an administrator.')
      );
    }
  };
}
