import React from 'react';
import { createContext, useContext } from 'react';

import type { ObjectNode, ObjectQueryResult } from '@pro4all/graphql';
import { useObjectQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

export interface Context extends Omit<ObjectQueryResult, 'data'> {
  id: ObjectNode['id'] | undefined;
  object: ObjectNode | undefined;
  projectId?: string;
}

const ObjectDetailContext = createContext<Context | undefined>(undefined);

export const useObjectDetailContext = () => {
  const context = useContext(ObjectDetailContext);
  if (!context) throw Error('Object detail context not initialized.');
  return context;
};

export const ObjectDetailProvider: React.FC = ({ children }) => {
  const {
    params: { objectId: id },
  } = useRouting();

  const { data, ...queryResult } = useObjectQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000 * ApiConfig.pollEnabled,
    skip: !id,
    variables: { id },
  });

  const object = (data?.object ?? undefined) as ObjectNode | undefined;
  const projectId = object?.projectId ?? '';

  return (
    <ObjectDetailContext.Provider
      value={{ ...queryResult, id, object, projectId }}
    >
      {children}
    </ObjectDetailContext.Provider>
  );
};
