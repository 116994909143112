import {
  AuthenticatedApiService,
  FilesData,
} from '@pro4all/authentication/src/services/authenticated-api-service';

export const DmsBaseURL = 'https://qualitycontrol.pro4all.io/api/';

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(DmsBaseURL);
  }

  updateDrawing = async (
    visualContextId: string,
    file: File,
    onProgress?: (progress: number) => void
  ) => {
    const url = `/visualcontexts/${visualContextId}/overwrite`;
    return this.api.uploadFile<FilesData>({ file, onProgress, url });
  };

  uploadDrawing = async (
    projectId: string,
    file: File,
    onProgress?: (progress: number) => void
  ) => {
    const url = `/visualcontexts/project/${projectId}`;
    return this.api.uploadFile<FilesData>({ file, onProgress, url });
  };
}

export const DrawingService = new Service();
