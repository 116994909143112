import { FacetGroup, FilterType } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/types';

export enum SearchEntities {
  Document,
  QualityControl,
}

export const SearchTranslationKeys: Record<SearchEntities, string> = {
  [SearchEntities.Document]: 'Documents',
  [SearchEntities.QualityControl]: 'QC',
};

export type TFetchSearch = (params: {
  contextQueryParam?: string[];
  disableTracking?: boolean;
  filters: string[];
  query?: string;
  reloadFacets?: boolean;
}) => Promise<void>;

export type FilterBaseProps = {
  facetGroup?: FacetGroup;
  facetLoading?: boolean;
  hidden?: boolean;
  metaDataKey?: string;
  name?: string;
  onError?: (error: string) => void;
  open?: boolean;
  readonly?: boolean;
  tooltip?: string;
  type: FilterType;
  value: string;
};

export interface FilterBarProps {
  LocalFilter?: React.ReactNode;
  ScopeFilter?: React.ReactNode;
  addFilterColor?: ActionProps['color'];
  contextQuery?: string[];
  dataViewToggle?: boolean;
  facetGroups?: FacetGroup[];
  loading?: boolean;
  onApply: FilterButtonProps['onApply'];
  onSave?: () => void;
  showResetButton?: boolean;
  showSaveButton?: boolean;
}

export interface SetFilterValueArgs {
  hidden?: boolean;
  metaDataKey?: string;
  reload?: boolean;
  type: FilterType;
  value: string;
}

export interface FilterButtonProps {
  onApply: () => void;
  removeFilter: ({
    metaDataKey,
    refetch,
    type,
  }: {
    metaDataKey?: string;
    refetch?: boolean;
    type: FilterType;
  }) => void;
  resetFilter: (type: FilterType) => void;
  setFilterValue: ({
    metaDataKey,
    reload,
    type,
    value,
  }: SetFilterValueArgs) => void;
}

export interface FilterAndButtonProps extends FilterBaseProps {
  buttonProps?: FilterButtonProps;
}
