import React from 'react';
import { useTranslation } from 'react-i18next';

import { BoxProps } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';

import { StyledFormFooter } from './FormFooter.styled';

interface FormFooterProps extends BoxProps {
  ariaLabelSave?: string;
  cancelLabel?: string;
  disableSubmit?: boolean;
  goBackLabel?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  onSubmitWithoutClose?: () => void;
  saveLabel?: string;
  showCancel?: boolean;
  showSubmit?: boolean;
  showSubmitWithoutClose?: boolean;
  sticky?: boolean;
  submitLabel?: string;
  submitType?: 'submit' | 'reset' | 'button';
}

export const FormFooter: React.FC<FormFooterProps> = ({
  ariaLabelSave,
  cancelLabel = 'Cancel',
  children,
  disableSubmit = false,
  onClose,
  onSubmitWithoutClose,
  onSubmit,
  showCancel = true,
  showSubmit = true,
  showSubmitWithoutClose = false,
  sticky = true,
  submitLabel = 'Save and close',
  submitType = 'submit',
  saveLabel = 'Save',
  ...boxProps
}) => {
  const { t } = useTranslation();

  return (
    <StyledFormFooter {...boxProps} $cancelLeft={showCancel} $sticky={sticky}>
      {showCancel && (
        <Button
          aria-label={t(cancelLabel)}
          color="inherit"
          data-testid="close-instance"
          onClick={onClose}
          variant="text"
        >
          {t(cancelLabel)}
        </Button>
      )}
      {children}
      {showSubmitWithoutClose && (
        <Button
          aria-label={ariaLabelSave ? t(ariaLabelSave) : t('Save')}
          data-testid="save-instance"
          disabled={disableSubmit}
          onClick={onSubmitWithoutClose}
          type={submitType}
          variant="outlined"
        >
          {t(saveLabel)}
        </Button>
      )}
      {showSubmit && (
        <Button
          aria-label={ariaLabelSave ? t(ariaLabelSave) : t('Save')}
          data-testid="save-instance"
          disabled={disableSubmit}
          onClick={onSubmit}
          type={submitType}
          variant="contained"
        >
          {t(submitLabel)}
        </Button>
      )}
    </StyledFormFooter>
  );
};
