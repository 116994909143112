export class Rect {
  constrainProportions?: boolean;

  bottom: number;
  left: number;
  right: number;
  top: number;

  originalHeight: number;
  originalWidth: number;

  constructor({
    constrainProportions = false,
    height = 0,
    width = 0,
    x = 0,
    y = 0,
  }) {
    this.constrainProportions = constrainProportions;

    this.bottom = y + height;
    this.left = x;
    this.right = x + width;
    this.top = y;

    this.originalHeight = height;
    this.originalWidth = width;
  }

  set(rect: Partial<Rect>) {
    Object.assign(this, rect);
  }

  get aspectRatio() {
    return this.constrainProportions
      ? this.originalWidth / this.originalHeight
      : this.width / this.height;
  }

  get style() {
    const { height, left, top, width } = this;
    return { height, left, top, width };
  }

  get height() {
    return this.bottom - this.top;
  }

  get width() {
    return this.right - this.left;
  }
}
