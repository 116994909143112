import { ChartData, ChartDataset } from 'chart.js';

import { ChartOption } from '../types';

import { ChartColors, hexToRGBA } from './ChartColorHelpers';

function getDonutChartDataTemplate(): ChartData<'doughnut', number[], string> {
  return {
    datasets: [
      {
        backgroundColor: [] as string[],
        borderRadius: 2,
        borderWidth: 1,
        data: [] as number[],
        hoverOffset: 4,
      },
    ] as ChartDataset<'doughnut', number[]>[],
    labels: [] as string[],
  };
}

function sortChartData(chartData: ChartData<'doughnut', number[], string>) {
  if (!chartData.labels) return;
  if (!chartData.datasets[0]) return;

  const dataset = chartData.datasets[0];

  // Map three arrays together into objects
  const arrayOfObj = chartData.labels.map((d, i) => ({
    // We need to check if the backgroundColor is an array or not,
    // as ChartJS has it typed to either a string, string[], Color or Color[]
    backgroundColor: Array.isArray(dataset.backgroundColor)
      ? dataset.backgroundColor[i]
      : '',
    data: dataset.data[i] || 0,
    label: d,
  }));

  // Sort the array of objects by the data value
  const sortedArrayOfObj = arrayOfObj.sort((a, b) => b.data - a.data);

  // Create new arrays for the sorted data
  const newSortedLabels: string[] = [];
  const newSortedData: number[] = [];
  const newSortedBackgroundColors: string[] = [];
  sortedArrayOfObj.forEach((d) => {
    newSortedLabels.push(d.label);
    newSortedData.push(d.data);
    newSortedBackgroundColors.push(d.backgroundColor);
  });

  // Set the new sorted arrays to the chart data
  chartData.labels = newSortedLabels;
  dataset.data = newSortedData;
  dataset.backgroundColor = newSortedBackgroundColors;
}

export function getDonutChartData<T>(data: T[], option: ChartOption) {
  const chartData = getDonutChartDataTemplate();

  data.forEach((item) => {
    // Define the label for the data item based on the valueResolver
    const label = option.valueResolver(item);

    if (!chartData.labels) {
      chartData.labels = [];
    }

    if (!chartData.datasets[0].backgroundColor) {
      chartData.datasets[0].backgroundColor = [];
    }

    // Check if the label already exists in the chart data
    let index = chartData.labels?.indexOf(label);

    if (index === -1) {
      chartData.labels.push(label);
      chartData.datasets[0].data.push(0);

      // Resolve the color for the label based on the labelColorResolver
      const resolvedLabelColor = option.labelColorResolver
        ? option.labelColorResolver(item)
        : undefined;

      let labelColor = resolvedLabelColor
        ? resolvedLabelColor
        : ChartColors[chartData.labels.length - 1];

      // If the color is supplied as a hex color, convert it to rgba
      const isHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(labelColor);
      if (isHexColor) {
        labelColor = hexToRGBA(labelColor, 1);
      }

      (chartData.datasets[0].backgroundColor as string[]).push(labelColor);
      index = chartData.labels.length - 1;
    }

    // Increment the value for the label
    chartData.datasets[0].data[index] += 1;
  });

  // Because its neater
  sortChartData(chartData);

  return chartData;
}
