import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TbqAnswer, TbqRieResult } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Card } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import {
  isTbqBrandcheck,
  isTbqRie,
  TBQLinkedInstance,
  TBQLinkedInstanceType,
} from '@pro4all/workflow/ui/utils';

interface Props {
  linkInstances: TbqRieResult[] | TbqAnswer[];
}

function TBQInstancesToLink({ linkInstances }: Props) {
  const { t } = useTranslation();
  const typeName = linkInstances[0]?.['__typename'];

  const labelIcons: { [key in TBQLinkedInstanceType]: IconName } = {
    QualityControlInstance: 'tbq',
    TBQAnswer: 'questionMark',
    TBQRieResult: 'questionMark',
  };
  const labelTitles: { [key in TBQLinkedInstanceType]: string } = {
    QualityControlInstance: '',
    TBQAnswer: t('Linked questions'),
    TBQRieResult: t('Linked questions'),
  };

  const cardIcons: { [key in TBQLinkedInstanceType]: IconName } = {
    QualityControlInstance: 'tbq',
    TBQAnswer: 'questionMark',
    TBQRieResult: 'questionMark',
  };

  const getCardTitle = (instance: TBQLinkedInstance): string => {
    let cardTitle = '';

    if (isTbqBrandcheck(instance)) {
      cardTitle = instance.sectionText ?? '';
    } else if (isTbqRie(instance)) {
      cardTitle = instance.section?.name ?? '';
    }

    return cardTitle;
  };

  const getCardMeta = (instance: TBQLinkedInstance) => {
    if (isTbqBrandcheck(instance)) {
      return `${(instance as TbqAnswer)?.reference}, ${
        (instance as TbqAnswer)?.categoryName
      }`;
    } else if (isTbqRie(instance)) {
      return `${instance.result?.name}, ${instance.category}`;
    } else {
      return;
    }
  };

  return (
    <div>
      <Box mt={10} mx={0}>
        <Label>
          <Icon
            iconName={labelIcons[typeName] ?? 'snag'}
            label={<Text variant="h4">{labelTitles[typeName]}</Text>}
          />
        </Label>
        {linkInstances?.map((instance: TBQLinkedInstance) => (
          <Card
            cardStyle="default"
            disabledButtonBorder
            iconName={cardIcons[typeName] ?? 'flame'}
            key={instance.id}
            menuItems={[]}
            meta={getCardMeta(instance)}
            title={getCardTitle(instance)}
          />
        ))}
      </Box>
    </div>
  );
}

export const Label = styled(Box)`
  && {
    display: block;
    margin-bottom: 1rem;
  }
`;

export default TBQInstancesToLink;
