import { useCallback, useLayoutEffect, useState } from 'react';

import type { SetState, UseState } from '@pro4all/shared/utils';

type InitState<S> = S | ((initialState?: S) => S);

interface UsePersistentStateOptions {
  // A `prefix` for the `key` under which state is stored. Defaults to
  // `p4a:ps:`.
  prefix?: string;
  // The Storage instance to use. Can be any store conforming to the Storage
  // interface (see: https://developer.mozilla.org/en-US/docs/Web/API/Storage).
  // Defaults to `window.localStorage`.
  storage?: Storage;
}

export function usePersistentState<S>(
  key: string,
  initial: InitState<S>,
  options?: UsePersistentStateOptions
): [S, SetState<S>];
export function usePersistentState<S = undefined>(
  key: string
): [S | undefined, SetState<S | undefined>];

/**
 * A `useState` function which, aside from keeping data in state,
 * serializes data to JSON and stores it in a WebStorage-compatible
 * back-end. By default `window.localStorage` is used as a back-end.
 * Note that persisted storage (e.g. data found in `window.localStorage`
 * takes precedence over `initial` state.
 */
export function usePersistentState<S>(
  key: string,
  initial?: InitState<S>,
  {
    prefix = 'p4a:ps:',
    storage = window.localStorage,
  }: UsePersistentStateOptions = {}
): [S | undefined, SetState<S>] {
  const _key = `${prefix}${key}`;
  const setItem = useCallback(
    (state: S | undefined) => storage.setItem(_key, JSON.stringify(state)),
    [_key, storage]
  );

  const [state, setState] = useState<S | undefined>(() => {
    const item = storage.getItem(_key);
    let persistedState: S | undefined = undefined;

    try {
      // JSON.parse(null) === JSON.parse('null') <-- notice the quotes ''
      // Both return the same result, but we only want to return early when
      // the `key` exists on `storage`, otherwise we set it to `initial`.
      // if (item) return JSON.parse(item) as S;
      if (item) persistedState = JSON.parse(item) as S;
    } catch (error) {
      console.error(error);
    }

    const state =
      initial instanceof Function
        ? initial(persistedState)
        : persistedState ?? initial;

    try {
      setItem(state);
    } catch (error) {
      console.error(error);
    }

    return state;
  });

  // The following effect makes sure that persistent state is synchronized
  // across browser sessions (e.g. tabs or windows). It does _not_ synchronize
  // state across components.
  useLayoutEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.storageArea !== storage) return;
      if (event.key !== _key) return;

      // newValue === `null` means storage was cleared or item was removed:
      // https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent#newvalue
      if (event.newValue === null) {
        storage.removeItem(_key);
        setState(initial);
        return;
      }

      try {
        const nextState = JSON.parse(event.newValue);
        setState(nextState);
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener('storage', handler, { passive: true });

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [_key, initial, storage]);

  return [
    state,
    useCallback(
      (stateArg) => {
        const item = storage.getItem(_key);
        let prevState: S, nextState: S;

        try {
          prevState = item !== null ? JSON.parse(item) : undefined;
          nextState =
            stateArg instanceof Function ? stateArg(prevState as S) : stateArg;
          setItem(nextState);
        } catch (error) {
          console.error(error);
        }

        setState((fallbackState) =>
          prevState === nextState ? fallbackState : nextState
        );
      },
      [_key, setItem, storage]
    ),
  ];
}

/*
 * In order to properly support the dependency array of `useCallback`,
 * `useEffect`, `useMemo`, etc.  we need a stable reference of our wrapped
 * `useState` functions. We cache usePersistentState by the `key` passed to it.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStates = new Map<string, UseState<any>>();

/**
 * Creates a new function with the same signature as `useState` so it can
 * be used in hook dependency injection.
 */
export function createUseState<S>(
  key: string,
  options?: UsePersistentStateOptions
): UseState<S> {
  if (useStates.has(key)) return useStates.get(key) as UseState<S>;

  const useState = (initial: InitState<S>) =>
    usePersistentState(key, initial, options);

  useStates.set(key, useState);

  return useState;
}
