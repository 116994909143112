import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { InheritedPermission } from '@pro4all/graphql';
import { Text } from '@pro4all/shared/ui/typography';

import { QCPermissionsFormValue } from '../PermissionsMatrix';
import {
  MatrixTableHeaderCell,
  StyledPermissionMatrixTable,
} from '../PermissionsMatrix.styled';
import { PermissionCategory } from '../utils';

import MatrixTableRow from './MatrixTableRow';

interface Props {
  handleChange: ({ name, value }: { name: string; value: string }) => void;
  inheritedPermissions: InheritedPermission[];
  isAdmin: boolean;
  permissionCategories: PermissionCategory[];
  targetIsExplicitProjectMember: boolean;
  values: QCPermissionsFormValue;
}

const QCPermissionMatrixTable = ({
  permissionCategories,
  handleChange,
  values,
  isAdmin,
  inheritedPermissions,
  targetIsExplicitProjectMember,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledPermissionMatrixTable>
      <thead>
        <tr>
          <MatrixTableHeaderCell />
          {permissionCategories[0]?.permissions?.map((action) => (
            <MatrixTableHeaderCell key={uuid()}>
              <Text variant="h6">{t(`${action.label}`)}</Text>
            </MatrixTableHeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {permissionCategories?.map((category) => (
          <MatrixTableRow
            category={category}
            handleChange={handleChange}
            inheritedPermissions={inheritedPermissions}
            isAdmin={isAdmin}
            key={uuid()}
            targetIsExplicitProjectMember={targetIsExplicitProjectMember}
            values={values}
          />
        ))}
      </tbody>
    </StyledPermissionMatrixTable>
  );
};
export default QCPermissionMatrixTable;
