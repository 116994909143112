import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoPage } from '@pro4all/shared/ui/infopages';

export const UnexpectedError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <InfoPage
      description={t(
        'Something unexpected went wrong during the process to confirm your movement to another organization. Please retry or ask the person who invited you for assistance.'
      )}
      title={t('Unexpected error')}
      type="error"
    />
  );
};
