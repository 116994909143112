import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAllowedGroupsIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import type { Option } from '@pro4all/shared/types';
import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/general';

import { useGroupFormConfig } from './useGroupFormConfig';

export const GroupSelect = ({ groupId }: { groupId: string }) => {
  const { t } = useTranslation();
  const { projectId } = useRouting().params;

  const { data, loading } = useAllowedGroupsIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      groupId,
      projectId,
    },
  });

  const groupsOptions: Option[] = (data?.allowedGroups || []).map((group) => ({
    iconName: 'group',
    id: group.id,
    label: group.displayName,
  }));

  const { getField } = useGroupFormConfig();
  const subgroupsField = getField('subgroups');

  return (
    <FormikSearchableMultiSelect
      label={subgroupsField.label}
      limitTags={10}
      loading={loading}
      name={subgroupsField.name}
      options={groupsOptions}
      placeholder={t('Add subgroups')}
    />
  );
};
