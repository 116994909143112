import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddObject: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M13 8.82121V12.0012L15.5 14.5001L14 15.8512L12 13.7512L8 17.9512V20.0012C8 20.5512 7.55 21.0012 7 21.0012H4C3.45 21.0012 3 20.5512 3 20.0012V17.0012C3 16.4512 3.45 16.0012 4 16.0012H7L11 12.0012V8.82121C9.84 8.40121 9 7.30121 9 6.00121C9 4.17121 10.63 2.73121 12.51 3.04121C13.7 3.24121 14.68 4.18121 14.93 5.36121C15.26 6.93121 14.35 8.33121 13 8.82121Z" />
    <path d="M15 19H17V21C17 21.55 17.45 22 18 22C18.55 22 19 21.55 19 21V19H21C21.55 19 22 18.55 22 18C22 17.45 21.55 17 21 17H19V15C19 14.45 18.55 14 18 14C17.45 14 17 14.45 17 15V17H15C14.45 17 14 17.45 14 18C14 18.55 14.45 19 15 19Z" />
  </SvgIcon>
);
