import React, { useEffect, useState } from 'react';

import { useScrollRendering } from '@pro4all/shared/scroll-rendering';

import {
  FileRemoveAndUploadStatusColumn,
  MetadataColumn,
  NameColumn,
  VersionNameColumn,
  VersionNumberColumn,
} from './columns';
import { calculateRowRenderData, RowRenderData } from './helpers';
import { UploaderEditorTypes } from './types';
import { useUploaderEditorContext } from './UploaderEditorProvider';
import { Meta } from './useUploaderEditorReducer';

export const UploaderEditorColumns = ({
  isUploadContext,
  scrollableDivRef,
}: Pick<UploaderEditorTypes, 'isUploadContext' | 'scrollableDivRef'>) => {
  const {
    deleteFileFromProcessingList,
    deleteMetaVersion,
    state: {
      allDocumentsSelected,
      currentDocumentsInFolder,
      documentIdsSelected,
      fields,
      folderId,
      horizontalTabbing,
      isProcessing,
      isTypingFromHeader,
      meta,
      metaInvalid,
      publishDocument,
    },
    selectDocument,
    selectDocumentsViaHeader,
    setTypingFromHeader,
    updateMetaName,
    updateMetaNameViaHeader,
    updateMetaVersion,
    updateMetaMetadata,
    updateMetaMetadataViaHeader,
  } = useUploaderEditorContext();

  const [rowRenderData, setRowRenderData] = useState<RowRenderData>({
    maxItemsRendered: 30,
    newItemsOnScroll: 4,
  });

  const metaFinal = metaInvalid || meta;

  const amountOfDocuments = metaFinal?.length || 0;
  const amountOfMetaFieldsPerDocument = fields?.length || 0;

  useEffect(() => {
    setRowRenderData(
      calculateRowRenderData({
        amountOfDocuments,
        amountOfMetaFieldsPerDocument,
      })
    );
  }, [amountOfDocuments, amountOfMetaFieldsPerDocument]);

  // These isolated useEffects cover data calculation of which items to render and act on scroll events.
  const data = useScrollRendering<Meta>({
    items: metaFinal,
    maxItemsRendered: rowRenderData.maxItemsRendered,
    newItemsOnScroll: rowRenderData.newItemsOnScroll,
    scrollCrossing: 2, // Do not change this, because you might get unexpected scroll behaviour in terms of that it will render a previous range again.
    scrollableDivRef,
  });

  // In `upload modus` we have to add tabIndex for:
  //   1. Remove row column.
  //   2. Checkbox `Name` column,
  //   3. Input `Name` column,
  //   4. `New version for` column.
  // In `edit modus` we have to add tabIndex for:
  //   1. Remove row column.
  //   2. Checkbox `Name` column
  //   3. Input `Name` column
  const noOfInputs = fields.length + (isUploadContext ? 4 : 3);

  return (
    <>
      <FileRemoveAndUploadStatusColumn
        deleteFileFromProcessingList={deleteFileFromProcessingList}
        initialTabIndex={horizontalTabbing ? 1 : undefined}
        meta={data}
        noOfInputs={noOfInputs}
      />
      <NameColumn
        allDocumentsSelected={allDocumentsSelected}
        documentIdsSelected={documentIdsSelected}
        initialTabIndex={horizontalTabbing ? 2 : undefined} // The name column contains two elements with a `tabIndex`.
        isProcessing={isProcessing}
        isTypingFromHeader={isTypingFromHeader}
        meta={data}
        noOfInputs={noOfInputs}
        publishDocument={publishDocument}
        selectDocument={selectDocument}
        selectDocumentsViaHeader={selectDocumentsViaHeader}
        setTypingFromHeader={setTypingFromHeader}
        updateMetaName={updateMetaName}
        updateMetaNameViaHeader={updateMetaNameViaHeader}
        zIndexResizableColumnDown={fields.length + 10}
      />
      {isUploadContext && (
        <>
          <VersionNumberColumn meta={data} />
          <VersionNameColumn
            currentDocumentsInFolder={currentDocumentsInFolder}
            deleteMetaVersion={deleteMetaVersion}
            folderId={folderId}
            initialTabIndex={horizontalTabbing ? 4 : undefined}
            isProcessing={isProcessing}
            meta={data}
            noOfInputs={noOfInputs}
            publishDocument={publishDocument}
            updateMetaVersion={updateMetaVersion}
            zIndexResizableColumnDown={fields.length + 9}
          />
        </>
      )}
      {fields.map((field, index) => (
        <MetadataColumn
          field={field}
          initialTabIndex={
            horizontalTabbing ? index + (isUploadContext ? 5 : 4) : undefined
          }
          isProcessing={isProcessing}
          isTypingFromHeader={isTypingFromHeader}
          key={index}
          meta={data}
          noOfInputs={noOfInputs}
          setTypingFromHeader={setTypingFromHeader}
          updateMetaMetadata={updateMetaMetadata}
          updateMetaMetadataViaHeader={updateMetaMetadataViaHeader}
          zIndexResizableColumnDown={fields.length + 10 - (index + 2)}
        />
      ))}
    </>
  );
};
