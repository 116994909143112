import React from 'react';

import { SvgIcon } from '../svg';

export const Copy = () => (
  <SvgIcon fill="currentColor">
    <path
      clipRule="evenodd"
      d="M10.3333 0.666748H2.99996C2.26663 0.666748 1.66663 1.26675 1.66663 2.00008V10.6667C1.66663 11.0334 1.96663 11.3334 2.33329 11.3334C2.69996 11.3334 2.99996 11.0334 2.99996 10.6667V2.66675C2.99996 2.30008 3.29996 2.00008 3.66663 2.00008H10.3333C10.7 2.00008 11 1.70008 11 1.33341C11 0.966748 10.7 0.666748 10.3333 0.666748ZM13 3.33341H5.66663C4.93329 3.33341 4.33329 3.93341 4.33329 4.66675V14.0001C4.33329 14.7334 4.93329 15.3334 5.66663 15.3334H13C13.7333 15.3334 14.3333 14.7334 14.3333 14.0001V4.66675C14.3333 3.93341 13.7333 3.33341 13 3.33341ZM6.33329 14.0001H12.3333C12.7 14.0001 13 13.7001 13 13.3334V5.33341C13 4.96675 12.7 4.66675 12.3333 4.66675H6.33329C5.96663 4.66675 5.66663 4.96675 5.66663 5.33341V13.3334C5.66663 13.7001 5.96663 14.0001 6.33329 14.0001Z"
      fillRule="evenodd"
      transform="translate(4, 4)"
    />
  </SvgIcon>
);
