import { useState } from 'react';

import { SignatureService } from '@pro4all/quality-control/data-access';
import { useAnswerEditContext } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { urlToFile } from '@pro4all/shared/utils';

export const useUploadSignature = ({
  fieldId,
  setValue,
}: {
  fieldId: string;
  setValue: (name: string, value: string) => void;
}) => {
  const [currentValue, setCurrentValue] = useState(null);
  const { upload } = useFileUploadContext();
  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');
  const { setCanSubmit } = useAnswerEditContext();

  const handleChange = async (signature: string) => {
    setCurrentValue(signature);

    if (signature) {
      // Convert the base64 string to a file that we can upload.
      const file = await urlToFile(signature, 'signature.png', 'image/png');

      // Upload the signature file.
      upload([file], async (file, onProgress) => {
        setCanSubmit(false);
        const response = await SignatureService.uploadSignature(
          instanceId,
          fieldId,
          file,
          onProgress
        );
        setCanSubmit(true);

        const signatureId = response.data.files[0].id;
        setValue && setValue(fieldId, signatureId);
      });
    } else {
      setValue && setValue(fieldId, '');
    }
  };

  return { currentValue, handleChange };
};
