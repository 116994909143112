import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { TrackingEvent } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { Typography, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { getDonutChartData } from './helpers/DonutChartHelpers';
import {
  ChartGroupWrapper,
  ChartInputWrapper,
  ChartSelect,
  ChartWrapper,
} from './styled';
import { ChartOption, CommonChartProps, DonutChartData } from './types';

export const DonutChart: React.FC<
  {
    data: any[];
    initialOptionId?: string;
    options: ChartOption[];
  } & CommonChartProps
> = ({ columnSpan, chartKey, data, initialOptionId, options, rowSpan }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { params } = useRouting();
  const { projectId } = params;
  const { projectOrganizationId } = useProjectContext();

  const { setLocalStorageItem, getLocalStorageItem } = useLocalStorage<string>({
    key: `donutchart-option-${chartKey}`,
  });

  const localStorageTimeClusterOptionId = getLocalStorageItem();
  const timeClusterOptionFromLocalStorage = options.find(
    (option) => option.id === localStorageTimeClusterOptionId
  );
  const initialOption = initialOptionId
    ? options.find((option) => option.id === initialOptionId)
    : undefined;

  const [currentOption, setCurrentOption] = useState<ChartOption>(
    timeClusterOptionFromLocalStorage || initialOption || options[0]
  );
  const [currentChartData, setCurrentChartData] =
    useState<DonutChartData | null>(null);

  useEffect(() => {
    setCurrentChartData(getDonutChartData(data, currentOption));
  }, [currentOption, data]);

  function handleOptionChange(option: ChartOption) {
    setCurrentOption(option);
    setLocalStorageItem(option.id);
    track(TrackingEvent.ChartOptionSelected, {
      chartKey: `${chartKey}`,
      chartType: 'donut',
      option: currentOption.id,
      projectId,
      projectOrganizationId,
    });
  }

  return (
    <ChartGroupWrapper
      columnSpan={columnSpan}
      data-testid={chartKey}
      rowSpan={rowSpan}
    >
      <ChartInputWrapper>
        <Typography variant="h6">{t('Group by')}</Typography>
        <ChartSelect
          name="Key"
          onChange={(option) => {
            handleOptionChange(option as ChartOption);
          }}
          options={options}
          size="small"
          value={currentOption.id}
        />
      </ChartInputWrapper>
      {currentChartData && (
        <ChartWrapper>
          <Doughnut
            data={currentChartData}
            height="100%"
            options={{
              layout: {
                padding: {
                  bottom: Number(theme.spacing(2.5).replace('px', '')),
                  left: Number(theme.spacing(4).replace('px', '')),
                  top: Number(theme.spacing(2.5).replace('px', '')),
                },
              },
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                  display: 'auto',
                  offset: -2,
                },
                legend: {
                  display: true,
                  labels: {
                    // Only show first labels to avoid weird visual issues with the legend
                    // filter: (item: LegendItem) =>
                    //   item.index !== undefined ? item.index < 8 : false,
                    padding: 5,
                    pointStyle: 'rectRounded',
                    usePointStyle: true,
                  },
                  position: 'right',
                },
              },
              responsive: true,
            }}
          ></Doughnut>
        </ChartWrapper>
      )}
    </ChartGroupWrapper>
  );
};

export default DonutChart;
