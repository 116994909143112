import { useEffect, useMemo, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { EntityFolderPermission, Folder } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';

import { usePermissionsEntityMatrixContext } from './entity-context/PermissionsEntityMatrixProvider';
import { useExclusivePermissionEntityChangeHandler } from './hooks/useExclusivePermissionEntityChangeHandler';
import { useExclusivePermissionSelector } from './hooks/useExclusivePermissionSelector';
import { isDocumentsNone } from './shared/helpers/checkPermissionsWrappers';
import { ExclusivePermissionType } from './types/types';
import { ExclusivePermissionSelector } from './ExclusivePermissionSelector';

export interface ExclusivePermissionEntitySelectorWrapper {
  currentPermissions: EntityFolderPermission;
  entityId: string;
  exclusivePermissionType: ExclusivePermissionType;
  folder: Folder;
}

/**
 * We use this wrapper to achieve optimal rendering for ExclusivePermissionSelector (the dropdown).
 * If we don't, ALL dropdowns will be re-rendered if a user clicks on whatever checkbox or changes
 * whatever value for whatever folder. Which would have a major bad impact on performance in case of thousands of folders.
 */
export const ExclusivePermissionEntitySelectorWrapper = ({
  currentPermissions,
  entityId,
  exclusivePermissionType,
  folder,
}: ExclusivePermissionEntitySelectorWrapper) => {
  const {
    state: { displayPermissions },
  } = usePermissionsEntityMatrixContext();

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const entityPermissions = useMemo(
    () =>
      displayPermissions.find((perm) => perm.entityId === entityId)
        ?.folderPermissions || [],
    [displayPermissions, entityId]
  );

  const breakInheritance = useMemo(
    () =>
      displayPermissions.find((perm) => perm.entityId === entityId)
        ?.breakInheritance || false,
    [displayPermissions, entityId]
  );

  const { id: userId } = currentPermissions;
  const { userId: loggedInUserId } = AuthService.getProfile();

  const {
    allPermissions,
    getOptionByPermissions,
    inheritedFromGroups,
    optionByPermission,
    optionByPermissionInherited,
    options,
  } = useExclusivePermissionSelector({
    exclusivePermissionType,
    folderPermissions: entityPermissions,
    inheritedFrom: currentPermissions.inheritedFrom,
  });

  const [selectedOption, setSelectedOption] =
    useState<string>(optionByPermission);

  const handleChange = useExclusivePermissionEntityChangeHandler({
    entityId,
    entityPermissions,
    exclusivePermissionType,
    folderId: folder.id,
    selectedOption,
    setSelectedOption,
  });

  useEffect(() => {
    setSelectedOption(
      getOptionByPermissions(entityPermissions, exclusivePermissionType)
    );
  }, [
    entityPermissions,
    getOptionByPermissions,
    setSelectedOption,
    exclusivePermissionType,
  ]);

  const noDocumentPermissions = isDocumentsNone(entityPermissions);

  const disableViewPermissions = breakInheritance
    ? loggedInUserId === userId
    : loggedInUserId === userId ||
      (noDocumentPermissions &&
        exclusivePermissionType === ExclusivePermissionType.Versions) ||
      (noDocumentPermissions &&
        exclusivePermissionType === ExclusivePermissionType.Delete) ||
      (noDocumentPermissions &&
        exclusivePermissionType === ExclusivePermissionType.Update);

  return (
    <ExclusivePermissionSelector
      allPermissions={allPermissions}
      disableViewPermissions={disableViewPermissions}
      folder={folder}
      handleChange={handleChange}
      inheritedFromGroups={inheritedFromGroups}
      optionByPermissionInherited={optionByPermissionInherited}
      options={options}
      selectedOption={selectedOption}
      showFinalPermissions={showFinalPermissions}
    />
  );
};
