import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router';

import {
  FieldDefinition,
  FormLinkedSnag,
  QualityControlInstance,
  Task,
  TaskType,
} from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { TaskListDisplay } from '@pro4all/shared/linked-tasks';
import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { AnswerEditContextProvider } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  Column,
  Footer,
  Loader,
  PropertyList,
  ResponsiveRowOrColumn,
  Sidebar,
} from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';
import { useLinkedTasks } from '@pro4all/workflow/data-access';

import { useAnswersPercentageContext } from '../form-answers-percentages/AnswersPercentageContext';

import { DialogSelectSavedAnswers } from './saved-answers/DialogSelectSavedAnswers';
import { SavedAnswersForm } from './saved-answers/SavedAnswersForm';
import { TbqSection } from './tbq/TbqSection';
import { ResultButtons } from './ResultButtons';
import { ResultComments } from './ResultComments';
import { ResultForm } from './ResultForm';
import { ResultLogs } from './ResultLogs';
import { ResultMiniMap } from './ResultMiniMap';
import { ResultTabKey } from './ResultsSidebarTabs';
import * as Styled from './styles';
import { useResultProperties } from './useResultProperties';
import { useSidebarActions } from './useSidebarActions';
import { useResultTabs } from './useTabs';

export const ResultFormWrapper = ({
  goBack,
  refetch,
  result,
}: {
  goBack: () => void;
  refetch: () => void;
  result: QualityControlInstance;
}) => {
  const history = useHistory();
  const {
    params: { projectId = '', objectId = '' },
    searchParams,
    state: routingState,
    goTo,
  } = useRouting();

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const routeEdit = searchParams.is('action', 'editResult');
  const { iconName, items = [], name, linkedSnags, type } = result || {};
  const initialTab =
    (searchParams.get('tab') as ResultTabKey) || ResultTabKey.Properties;
  const resultId = searchParams.get('id') ?? '';
  const { Tabs, currentTab } = useResultTabs({ initialTab });
  const sidebarActions = useSidebarActions({ currentTab, result });
  const showComments = currentTab === ResultTabKey.Comments;
  const showLogs = currentTab === ResultTabKey.Log;
  const showLinkedTasks = currentTab === ResultTabKey.Link;
  const showProperties = currentTab === ResultTabKey.Properties;
  const { percentageTotal } = useAnswersPercentageContext();
  const properties = useResultProperties(result, type, percentageTotal);
  const inWorkflow = useRouteMatch([Routes.projectWorkflows]);

  const { loading, linkedTasks } = useLinkedTasks();

  const isObjects = useRouteMatch([Routes.objectsBase, Routes.projectObjects]);

  const handleTaskClick = (taskId: string) => {
    const searchParams = { action: 'viewTask', id: taskId };

    if (isObjects) {
      const route = projectId ? 'projectObjectTbq' : 'objectTBQ';
      const params = {
        objectId: objectId || undefined,
        ...(projectId && { projectId }),
      };
      goTo(route, { params, searchParams });
      return;
    }

    projectId &&
      goTo('projectQualityControlTasks', {
        params: { projectId },
        searchParams,
      });
  };

  return (
    <>
      <Sidebar.Header icon={iconName as IconName} title={name}>
        <Sidebar.SidebarActionBar sidebarActions={sidebarActions || []} />
        <Tabs />
      </Sidebar.Header>
      {!result && <Loader />}
      {result && showProperties && (
        <>
          <TbqSection refetch={refetch} result={result} />
          <ResponsiveRowOrColumn flex="none" mb={2}>
            <Column>
              <PropertyList
                items={properties}
                orientation="horizontal"
                pb={3}
                pl={3}
              />
              {!isMobile && <ResultButtons result={result} />}
            </Column>
            {!isMobile && (
              <Box flex="0 0 auto" pr={3} width="188px">
                <ResultMiniMap result={result} />
              </Box>
            )}
          </ResponsiveRowOrColumn>

          <AnswerEditContextProvider>
            <Styled.StyledBox flex="1" mb="auto" mt={3} mx={3}>
              <ResultForm
                edit={routeEdit}
                items={items as FieldDefinition[]}
                linkedSnags={linkedSnags as FormLinkedSnag[]}
                onClose={goBack}
                onSuccessfulSubmit={goBack}
                refetchQualityInstance={refetch}
                result={result}
              />
              {!routeEdit && inWorkflow && (
                <Footer>
                  <Styled.Button
                    color="inherit"
                    onClick={goBack}
                    startIcon="arrowBack"
                    variant="outlined"
                  >
                    {t('Go back')}
                  </Styled.Button>
                </Footer>
              )}
            </Styled.StyledBox>
          </AnswerEditContextProvider>
        </>
      )}
      {showLinkedTasks && result && (
        <TaskListDisplay
          getTaskType={(task: Task) => task?.type ?? ''}
          loading={loading}
          noTasksMessage="This result has not been linked to any task yet" // I18n
          onTaskClick={handleTaskClick}
          taskTypeEnum={TaskType}
          tasks={linkedTasks as Task[]}
        />
      )}
      {showLogs && <ResultLogs resultId={resultId} />}
      {showComments && result && (
        <ResultComments
          instance={result}
          projectId={projectId || result.projectId || undefined}
        />
      )}
      <SavedAnswersForm />
      <DialogSelectSavedAnswers />
    </>
  );
};
