import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box } from '@pro4all/shared/mui-wrappers';
import { getFormattedDate } from '@pro4all/shared/ui/general';

import { useSubscriptionHelpers } from './hooks/useSubscriptionHelpers';

export const FeedbackTrialPeriod = () => {
  const { t } = useTranslation();

  const { getExpireDate, isTrial } = useSubscriptionHelpers();
  const expireDate = getExpireDate();

  return isTrial() ? (
    <Box maxWidth="860px" mb={2}>
      {' '}
      <Alert severity="warning" variant="filled">
        {t(
          'Trial period until {{date}}. Choose a license to continue using the powers of Prostream.',
          {
            date: getFormattedDate(expireDate, 'll').label,
          }
        )}
      </Alert>
    </Box>
  ) : null;
};
