import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder, FolderPermission } from '@pro4all/graphql';
import { useEditDocuments } from '@pro4all/shared/hooks/src/documents';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';

interface Props {
  folder: Folder;
}

export const InvalidDocumentsFeedback = ({ folder }: Props) => {
  const { t } = useTranslation();
  const { userId: loggedInUserId } = AuthService.getProfile() || {};

  const invalidDocuments =
    folder.template &&
    folder.documents?.filter(
      (document) => document.metaData && !document.metaData?.isValid
    );

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const invalidDocumentsEditable = hasFolderPermission(
    FolderPermission.UpdateOwn
  )
    ? invalidDocuments?.filter(
        (document) => document.createdBy?.id === loggedInUserId
      )
    : invalidDocuments;

  const { editDocuments } = useEditDocuments(invalidDocumentsEditable);

  if (!invalidDocumentsEditable?.length) return null;

  return (
    <Alert
      action={
        <Button
          color="inherit"
          onClick={editDocuments}
          size="small"
          tabIndex={0}
        >
          {t('Edit all')}
        </Button>
      }
      severity="warning"
      sx={{ margin: 2 }}
      variant="filled"
    >
      {t(
        'Not all required meta data fields are filled, complete where necessary.'
      )}
    </Alert>
  );
};
