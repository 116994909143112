import { useTranslation } from 'react-i18next';

import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const Tabs = () => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const projectId = params.projectId;
  const objectId = params.objectId;

  const base = !projectId
    ? `${generateRoute('objects', {
        params: { objectId },
      })}/`
    : `${generateRoute('projectObjects', {
        params: { objectId, projectId },
      })}/`;

  return (
    <SubTabs base={base}>
      <Tab data-testid="tbq-tab" label={t('TBQ')} value="tbq" />
      <Tab
        data-testid="floor-plans-tab"
        label={t('Drawings')}
        value="floor-plans"
      />
      <Tab data-testid="results" label={t('Results')} value="results" />
      <Tab data-testid="tasks-tab" label={t('Tasks')} value="tasks" />
      <Tab data-testid="osid-log-tab" label="Osid log" value="osid-log" />
    </SubTabs>
  );
};
