import { Tag } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';

type TagWithNewProp = Tag & { newTag?: boolean };
type ReturnType = {
  newTagsToCreate: Option[];
  tagIds: string[];
};

export const useGetDocumentTagsData =
  () =>
  ({ allTags, tags }: { allTags: Tag[]; tags: Option[] }): ReturnType => {
    // It might be that someone added a new tag called `Ruwbouw` but there is an existing tag called `ruwbouw`.
    // BE throws an error when trying to create a tag with the same name but different case.
    // So we have to convert this new `Ruwbouw` to the existing `ruwbouw` tag.
    const existingTags = allTags.map((tag) => tag.name.toLowerCase());
    const newTags = tags.map((tag) => tag.label.toLowerCase());
    const inputTags = newTags.map((tag) => {
      const newTag = tags.filter(
        (inputTag) => inputTag.label.toLowerCase() === tag
      )[0];
      return existingTags.includes(tag)
        ? allTags.find((t) => t.name.toLowerCase() === tag)
        : {
            __typename: 'Tag',
            id: newTag.id,
            name: newTag.label,
            newTag: true,
          };
    }) as TagWithNewProp[];

    const tagIds = inputTags.map((t) => t.id);
    const newTagsToCreate = inputTags
      .filter((inputTag) => inputTag.newTag)
      .map((tag) => ({
        id: tag.id,
        label: tag.name,
      }));

    return { newTagsToCreate, tagIds };
  };
