import React from 'react';

import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';

import { useMDFacets } from '../../utils/useMDFacets';
import { TagSelect } from '../TagSelect';

import { DefinitionSelect } from './DefinitionSelect';

export const TextFilter: React.FC<FilterBaseProps> = ({ ...filterProps }) => {
  const { filteredAnswers } = useMDFacets(filterProps);
  const { facetLoading, metaDataKey, type, value, name } = filterProps;

  const options: Option[] =
    filteredAnswers?.map((facetItem, index) => ({
      id: `text-filter-option-${index}-${facetItem.value}`,
      inputValue: facetItem.value,
      label: facetItem.value,
    })) || [];

  const values = value.split(delimiters.multiSelectOptions);

  const initialOptions = options.filter(
    ({ inputValue }) => inputValue && values?.includes(inputValue)
  );

  return (
    <div>
      {name ? (
        <TagSelect
          freeSolo
          initialOptions={initialOptions}
          loading={facetLoading}
          maxNameLength={10}
          metaDataKey={metaDataKey}
          options={options}
          type={type}
          value={value}
        />
      ) : (
        <DefinitionSelect {...filterProps} />
      )}
    </div>
  );
};
