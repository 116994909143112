export function encodeURIPath(
  path?: boolean | number | string,
  encodeParts = true
) {
  if (path === '/') return path;
  return path
    ? path
        .toString()
        .split('/')
        .filter(Boolean)
        .map((part) => (encodeParts ? encodeURIComponent(part) : part))
        .join('/')
    : '';
}
