import { useTranslation } from 'react-i18next';

import { QualityControlInstance } from '@pro4all/graphql';
import { ChartOption } from '@pro4all/shared/ui/charts';

export const useTimeClusterOptionsQcResults = () => {
  const { t } = useTranslation();

  const timeClusterOptions: ChartOption[] = [
    {
      id: 'createdAt',
      label: t('Created on'),
      valueResolver: (instance: QualityControlInstance) => instance.createdAt,
    },
  ];

  return timeClusterOptions;
};
