import { useTaskIncludeQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useTBQFetchTask = () => {
  const taskId = useRouting().searchParams.get('id') ?? '';

  const {
    data,
    error,
    loading,
    refetch: refetchTask,
  } = useTaskIncludeQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !taskId,
    variables: {
      id: taskId,
      includeAssignment: true,
      includeAvailableStatuses: true,
      includeCompletedAt: true,
      includeCreatedAt: true,
      includeCreatedByUser: true,
      includeDeliverables: true,
      includeDocumentAction: true,
      includeEndTime: true,
      includeFormTemplates: true,
      includeName: true,
      includeProject: true,
      includeSnagTemplates: true,
      includeStatus: true,
      includeTaskCategoryId: true,
      includeTaskCategoryName: true,
      includeTbq: true,
      includeTbqLinkedQuestions: true,
      includeVisualContexts: true,
    },
  });

  return { data, error, loading, refetchTask };
};
