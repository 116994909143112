import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

const Default: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.612 11.3716L17.7211 9.92628L20.612 8.48079C21.1291 8.22232 21.1296 7.48179 20.612 7.2229L12.3145 3.07416C12.1165 2.97528 11.8835 2.97528 11.6855 3.07416L3.38802 7.2229C2.87094 7.48151 2.87039 8.22204 3.38802 8.48079L6.27887 9.92628L3.38802 11.3716C2.87094 11.6302 2.87039 12.3706 3.38802 12.6295L6.27887 14.075L3.38802 15.5204C2.87094 15.779 2.87039 16.5194 3.38802 16.7783L11.6855 20.927C11.8831 21.0258 12.1161 21.0262 12.3145 20.927L20.612 16.7783C21.1291 16.5197 21.1296 15.7793 20.612 15.5204L17.7211 14.075L20.612 12.6295C21.1291 12.3711 21.1296 11.6305 20.612 11.3716ZM12 4.4893L18.7251 7.85191L12 11.2144L5.27492 7.85191L12 4.4893ZM18.7251 16.1494L12 19.5119L5.27492 16.1494L7.85126 14.8612L11.6855 16.7783C11.8833 16.877 12.1161 16.8774 12.3145 16.7783L16.1487 14.8612L18.7251 16.1494ZM12 15.3631L5.27492 12.0007L7.85126 10.7124L11.6855 12.6295C11.8833 12.7283 12.1161 12.7287 12.3145 12.6295L16.1487 10.7124L18.7251 12.0007L12 15.3631Z" />
  </SvgIcon>
);

const Add: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M21.5675 11.3012L18.3557 9.69535L21.5675 8.08935C22.142 7.80218 22.1427 6.97942 21.5675 6.69179L12.3488 2.0824C12.1287 1.97253 11.8699 1.97253 11.6499 2.0824L2.4311 6.69179C1.8566 6.97912 1.85599 7.80188 2.4311 8.08935L5.64294 9.69535L2.4311 11.3012C1.8566 11.5885 1.85599 12.4111 2.4311 12.6988L5.64294 14.3047L2.4311 15.9106C1.8566 16.1979 1.85599 17.0205 2.4311 17.3081L11.6499 21.9175C11.8695 22.0273 12.1283 22.0277 12.3488 21.9175L14.6535 20.7652L15.1491 20.5174L13.5609 19.5645L11.9993 20.3453L4.52752 16.6094L7.38993 15.1782L11.6499 17.3081C11.8696 17.4179 12.1283 17.4183 12.3488 17.3081L15.96 15.5025L15.953 15.4997L18.353 14.2995L18.3597 14.3028L21.5675 12.6988C22.142 12.4116 22.1427 11.5888 21.5675 11.3012ZM11.9993 3.65467L19.4711 7.39065L11.9993 11.1265L4.52752 7.39065L11.9993 3.65467ZM11.9993 15.7359L4.52752 12L7.38993 10.5688L11.6499 12.6988C11.8696 12.8085 12.1283 12.8089 12.3488 12.6988L16.6087 10.5688L19.4711 12L11.9993 15.7359Z" />
    <path d="M16.9531 20H18.9531V22C18.9531 22.55 19.4031 23 19.9531 23C20.5031 23 20.9531 22.55 20.9531 22V20H22.9531C23.5031 20 23.9531 19.55 23.9531 19C23.9531 18.45 23.5031 18 22.9531 18H20.9531V16C20.9531 15.45 20.5031 15 19.9531 15C19.4031 15 18.9531 15.45 18.9531 16V18H16.9531C16.4031 18 15.9531 18.45 15.9531 19C15.9531 19.55 16.4031 20 16.9531 20Z" />
  </SvgIcon>
);

export const Project = {
  Add,
  Default,
};
