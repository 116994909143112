import { SentOrInbox } from '@pro4all/graphql';

export const initialState = {
  batchInfo: [] as BatchInfo[],
  selectedTab: SentOrInbox.Inbox,
};

export enum ActionType {
  CLEAR_BATCH_INFO = 'CLEAR_BATCH_INFO',
  SET_BATCH_INFO = 'SET_BATCH_INFO',
  SET_SELECTED_TAB = 'SET_SELECTED_TAB',
}

export type BatchInfo = {
  code?: string;
  displayName: string;
};

export type State = typeof initialState;

type ActionSetBatchInfo = {
  payload: BatchInfo[];
  type: ActionType.SET_BATCH_INFO;
};
type ActionClearBatchInfo = {
  type: ActionType.CLEAR_BATCH_INFO;
};
type ActionSetSelectedTab = {
  payload: SentOrInbox;
  type: ActionType.SET_SELECTED_TAB;
};

type Action = ActionSetBatchInfo | ActionClearBatchInfo | ActionSetSelectedTab;

export function messageReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.CLEAR_BATCH_INFO: {
      return {
        ...state,
        batchInfo: [],
      };
    }
    case ActionType.SET_BATCH_INFO: {
      return {
        ...state,
        batchInfo: action.payload,
      };
    }
    case ActionType.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    default:
      return state;
  }
}
