import { useSnagFormHistoryLogQuery } from '@pro4all/graphql';
import { EventLog } from '@pro4all/shared/history';

interface Props {
  resultId: string;
}
export const ResultLogs = ({ resultId }: Props) => {
  const { data } = useSnagFormHistoryLogQuery({
    variables: {
      id: resultId,
    },
  });
  return (
    <div>
      <EventLog events={data?.snagFormHistoryLog?.today} groupName="Today" />
      <EventLog
        events={data?.snagFormHistoryLog?.thisWeek}
        groupName="This week"
      />
      <EventLog
        events={data?.snagFormHistoryLog?.thisMonth}
        groupName="This month"
      />
      <EventLog events={data?.snagFormHistoryLog?.older} groupName="Older" />
    </div>
  );
};
