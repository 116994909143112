import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import { BoxNoBottomMargin } from '@pro4all/shared/styles';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import {
  FormFooter,
  FormikCheckbox,
  FormikForm,
  FormikInput,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useReportOptionsContext } from '../../ReportOptionsProvider';

import { useReportConfigFormConfig } from './useReportConfigFormConfig';
import { useSubmit } from './useSubmit';

export const ReportConfigForm = () => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();
  const createMode = searchParams.get('createReportConfig') === 'true';
  const editMode = searchParams.get('editReportConfig') === 'true';
  const open = createMode || editMode;

  const { getField, validationSchema } = useReportConfigFormConfig();
  const nameField = getField('name');
  const setAsDefaultTemplateField = getField('setAsDefault');
  const storeOnOrganizationLevelField = getField('storeOnOrganizationLevel');

  const {
    state: { activeReportConfiguration },
  } = useReportOptionsContext();

  const onClose = () => {
    setTimeout(() => {
      searchParams.delete('createReportConfig');
      searchParams.delete('editReportConfig');
    }, 100); // Little delay to give the bypass state time to update.
  };

  const onSubmit = useSubmit({ onClose });
  const initialValues =
    editMode && activeReportConfiguration
      ? {
          name: activeReportConfiguration.name,
          setAsDefault: activeReportConfiguration.setAsDefault,
          storeOnOrganizationLevel:
            activeReportConfiguration.storeOnOrganizationLevel,
        }
      : {
          name: '',
          setAsDefault: false,
          storeOnOrganizationLevel: false,
        };

  return (
    <FloatingModal
      data-testid="add-report-configuration"
      onClose={onClose}
      open={open}
    >
      <FloatingModal.Header>
        {createMode
          ? t('New report configuration')
          : t('Update report configuration')}
      </FloatingModal.Header>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ dirty, errors, isSubmitting }) => (
          <FormikForm>
            <FormWrapper noPadding>
              <FormikInput
                autoFocus
                label={nameField?.label}
                name={nameField?.name}
                type="text"
              />
              <BoxNoBottomMargin>
                <FormikCheckbox
                  label={setAsDefaultTemplateField?.label || ''}
                  name={setAsDefaultTemplateField?.name || ''}
                />
              </BoxNoBottomMargin>
              {projectId && (
                <FormikCheckbox
                  label={storeOnOrganizationLevelField?.label || ''}
                  name={storeOnOrganizationLevelField?.name || ''}
                />
              )}
            </FormWrapper>
            <FormFooter
              disableSubmit={
                !editMode &&
                isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                })
              }
              onClose={onClose}
              pt={2}
              sticky
              submitLabel="Save"
            />
          </FormikForm>
        )}
      </Formik>
    </FloatingModal>
  );
};
