import React from 'react';
import styled from 'styled-components';

import { TaskFragmentFragment } from '@pro4all/graphql';
import { Box, Grid } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import {
  ChipContainer,
  EndTimeChip,
  StatusChip,
  UserChip,
} from '@pro4all/shared/ui/chips';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  task?: TaskFragmentFragment;
}

//copy from: Pro4all.Prostream.Frontend\libs\workflow\ui\task-overview\src\task\Task.tsx
export const TbqLinkedResolveTaskCard: React.FC<Props> = ({ task }) => {
  const { searchParams } = useRouting();

  const onSelectTask = (task: TaskFragmentFragment | undefined) => {
    searchParams.set({ action: 'viewTask', id: task?.id });
  };

  return (
    <CardBox onClick={() => onSelectTask(task)}>
      <TaskGrid>
        <Box display="flex">
          <Box flex={1}>
            <Box alignItems="center" display="flex">
              <CardIcon iconName="tbqResolveTask" />
              <Text variant="h5">{task?.name}</Text>
            </Box>
            <ChipContainer>
              <EndTimeChip endTime={task?.endTime} status={task?.status} />
              <UserChip users={task?.assignment} />
            </ChipContainer>
          </Box>
        </Box>

        <ActionsContainer>
          <StatusChip status={task?.status} />
        </ActionsContainer>
      </TaskGrid>
    </CardBox>
  );
};

const TaskGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
`;

const ActionsContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
  }
`;

const CardBox = styled(Box)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
    padding: ${({ theme }) => theme.spacing(2)};
    background: ${customColors.white};
    border: 1px solid ${customColors.grey400};
    border-radius: 10px;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.up('md')} {
      box-shadow: 0px 3px 7px rgb(44, 45, 86, 0.12);
    }
  }
`;

const CardIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
