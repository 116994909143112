import { gqlType, Group, Member } from '@pro4all/graphql';

export const findGroup = (groupId: string, members: Member[]) => {
  const availableGroups: Group[] = members.filter(gqlType('Group'));
  return availableGroups.find((group) => group.id === groupId);
};

export const getGroupMemberIds = ({
  group,
  members,
  recursive,
}: {
  group: Group;
  members: Member[];
  recursive?: boolean;
}): string[] => {
  const allMemberIds = new Set<string>();

  // Parent group comes with a list of members from BE
  if (group?.memberIds) {
    // Add the member IDs of the base group to the set
    group.memberIds.forEach((memberId) => allMemberIds.add(memberId));
  }

  // Subgroups come with no attached members from the BE. We need to get a list of them
  if (!group?.members) {
    const foundGroup = findGroup(group.id, members);
    if (foundGroup.members)
      foundGroup.members?.forEach((member) => allMemberIds.add(member.id));
  }

  if (group?.subgroupIds && recursive) {
    // Recursively call the function for each subgroup and add their member IDs to the list
    group.subgroupIds.forEach((subgroupId) => {
      const subgroupMemberIds = getGroupMemberIds({
        group: findGroup(subgroupId, members),
        members,
        recursive,
      });
      subgroupMemberIds.forEach((id) => allMemberIds.add(id));
    });
  }

  return Array.from(allMemberIds);
};
