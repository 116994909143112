import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { getDropdownRoutes } from '@pro4all/shared/routing-utils';

import { Tabs } from './Tabs';

const getActiveTabWithBase = (pathname: string, base = '') => {
  const path = pathname
    .trim()
    .replace(base.trim(), '')
    .split('/')
    .filter(Boolean);

  return path[0];
};

export const getActiveTabWithoutBase = (
  projectId: string,
  pathname: string
) => {
  const activeTabKey = getDropdownRoutes(projectId)?.[0].route;
  if (!activeTabKey) return pathname;
  return projectId
    ? generateRoute(activeTabKey, { params: { projectId } })
    : Routes[activeTabKey];
};

interface Props {
  alternativeValues?: Record<string, string>;
  base?: string;
  small?: boolean;
}

export const RouterTabs: React.FC<Props> = ({
  // base path: this allows you to use relative path values in <Tab />
  base = '',
  ...props
}) => {
  if (base && !base.endsWith('/')) {
    throw new Error('Expected `base` to have a trailing slash');
  }

  const history = useHistory();
  const { hash, pathname, search } = useLocation();
  const {
    params: { projectId },
  } = useRouting();

  return (
    <Tabs
      onChange={(event, path: string) => {
        if (pathname === path) return;
        history.push({ hash, pathname: path, search });
      }}
      value={
        base
          ? getActiveTabWithBase(pathname, base)
          : getActiveTabWithoutBase(projectId, pathname)
      }
      {...props}
    />
  );
};
