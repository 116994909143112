import { InitializeDocumentsForUploadPayload } from '../useUploaderEditorReducer';

import { useCalculateMetaForFileToUpload } from './helpers/useCalculateMetaForFileToUpload';

export const useInitializeDocumentsForUploadAction = () => {
  const calculateMetaForFileToUpload = useCalculateMetaForFileToUpload();

  const initializeDocumentsForEditAction = (
    payload: InitializeDocumentsForUploadPayload
  ) => {
    const {
      currentDocumentsInFolder,
      documentCurrent,
      filesToUpload,
      fields,
      folderId,
      publishDocument,
      templateId,
    } = payload;

    // We have to fill the meta state property that is used to render data in the UploaderEditor component.
    // The meta state property is an array of objects, each representing a document/file.
    // This is the key prop because it takes changes that will re-render the UploaderEditor component.

    const meta = calculateMetaForFileToUpload({
      currentDocumentsInFolder,
      documentCurrent,
      fields,
      filesToUpload,
    });

    return {
      currentDocumentsInFolder,
      documentCurrent,
      fields,
      filesToUpload,
      folderId,
      meta,
      publishDocument,
      templateId,
    };
  };

  return initializeDocumentsForEditAction;
};
