import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import styled from 'styled-components';

import {
  TaskType,
  useGetOrganizationTaskCategoriesByTypeQuery,
  useGetProjectTaskCategoriesByTypeQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { Box, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  Dialog,
  FormFooter,
  FormikForm,
  Select,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { StyledFastField as SelectionFastField } from '../../components/form-field-definition/CustomSelectionOption';
import { SelectionOptions } from '../../components/form-field-definition/SelectionOptions';

import { useTaskCategoriesFormConfig } from './validation/useTaskCategoriesFormConfig';
import { useSubmit } from './useSubmit';

export const AddTaskCategories = () => {
  const { t } = useTranslation();
  const { validationSchema } = useTaskCategoriesFormConfig();
  const { organizationName } = useOrganizationContext();
  const { projectName } = useProjectContext();
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const objectsFeature = useFeatureFlag('objects');

  const {
    params: { projectId },
  } = useRouting();

  const theme = useTheme();

  const typeOptions: Option[] = [
    hasQualityControlLicense &&
      hasQualityControlFeatureFlag && {
        id: TaskType.QualityControl,
        label: t(`Quality control task`),
      },
    hasQualityControlLicense &&
      hasQualityControlFeatureFlag && {
        id: TaskType.Resolve,
        label: t(`Resolve task`),
      },
    hasDmsLicense && {
      id: TaskType.Document,
      label: t(`Document task`),
    },
    hasQualityControlFeatureFlag &&
      objectsFeature && { id: TaskType.TbqScan, label: t(`Tbq scan`) },
    hasQualityControlFeatureFlag &&
      objectsFeature && {
        id: TaskType.TbqResolve,
        label: t(`Tbq resolve task`),
      },
  ].filter(Boolean);

  const [taskType, setTaskType] = useState<Option>({
    id: TaskType.QualityControl,
    label: t(`${TaskType.QualityControl}`),
  });

  const [desiredTaskType, setDesiredTaskType] = useState<Option>({
    id: TaskType.QualityControl,
    label: t(`${TaskType.QualityControl}`),
  });

  const onSubmit = useSubmit();

  const { data: projCategoriesData } = useGetProjectTaskCategoriesByTypeQuery({
    skip: !projectId,
    variables: {
      projectId,
      taskType: taskType.id as TaskType,
    },
  });
  const projCategories = projCategoriesData?.getProjectTaskCategoriesByType;

  const { data: orgCategoriesData } =
    useGetOrganizationTaskCategoriesByTypeQuery({
      variables: { taskType: taskType.id as TaskType },
    });
  const orgCategories = orgCategoriesData?.getOrganizationTaskCategoriesByType;

  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const handleChange = (e: Option, dirty: boolean) => {
    if (!dirty) setTaskType(e);
    else if (e !== taskType) {
      setDesiredTaskType(e);
      setShowErrorDialog(true);
    }
  };

  const ErrorDialog = () => {
    const { t } = useTranslation();
    return (
      <Dialog
        buttonVariant="outlined"
        confirmLabel={t('Discard')}
        iconName="reportProblemOutlined"
        onClose={() => {
          setDesiredTaskType(taskType);
          setShowErrorDialog(false);
        }}
        onConfirm={() => {
          setTaskType(desiredTaskType);
          setShowErrorDialog(false);
        }}
        open
        title={t('Unsaved changes')}
      >
        {t(
          'Your unsaved changes will be lost. Do you want to save your changes?'
        )}
      </Dialog>
    );
  };

  const getInitialCategories = () =>
    projectId ? projCategories ?? [] : orgCategories ?? [];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
    >
      <Formik
        enableReinitialize
        initialValues={{
          selectionOptions: getInitialCategories(),
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit({
            categories: values.selectionOptions,
            originalCategories: getInitialCategories(),
            resetForm,
            taskType,
          });
        }}
        validationSchema={validationSchema}
      >
        {({ dirty, errors, isSubmitting, values }) => (
          <StyledFormikForm
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            <FormWrapper noPadding>
              {!projectId && (
                <Box>
                  <Text variant="h5">{t('Task category')}</Text>
                  <Text variant="body1">
                    {t(
                      'When you add task categories to an organisation, they will be visible in every project.'
                    )}
                  </Text>
                </Box>
              )}
              <Box maxWidth="385px">
                <FormWrapper noPadding>
                  <Box>
                    <Text variant="h6">{t('Type')}</Text>
                    <Select
                      name="select-task-type"
                      onChange={(e) => handleChange(e, dirty)}
                      options={typeOptions}
                      updateValue
                      value={taskType}
                    />
                  </Box>
                  <Box>
                    <Text variant="h6">{`${
                      projectId ? projectName : organizationName
                    } ${t('Categories').toLowerCase()}`}</Text>
                    <SelectionOptions
                      addOptionPlaceholder={
                        projectId
                          ? t('Add a project category')
                          : t('Add an organization category')
                      }
                      selectionOptions={values.selectionOptions}
                    />

                    {projectId && (
                      <Box maxHeight="400px" overflow="scroll">
                        <Text variant="h6">{`${organizationName} ${t(
                          'Categories'
                        ).toLowerCase()}`}</Text>
                        {!orgCategories?.length ? (
                          <Box color={theme.palette.grey[500]}>
                            <Text color="inherit" variant="body1">
                              {t(
                                'No organizational categories added. To add categories at organization level, exit the project and go to Templates › Tasks.'
                              )}
                            </Text>
                          </Box>
                        ) : (
                          orgCategories.map((cat) => (
                            <StyledFastField
                              name={cat.taskCategoryId}
                              value={cat.name}
                            />
                          ))
                        )}
                      </Box>
                    )}
                  </Box>
                </FormWrapper>
              </Box>
            </FormWrapper>
            <FormFooter
              ariaLabelSave="Save field"
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              pb={3}
              pt={2}
              px={3}
              showCancel={false}
              sticky
            />
          </StyledFormikForm>
        )}
      </Formik>
      {showErrorDialog ? <ErrorDialog /> : null}
    </Box>
  );
};

const StyledFormikForm = styled(FormikForm)`
  & #task-category-box {
    width: 385px;
    & .MuiInput-root {
      width: 335px;
    }
  }
`;

const StyledFastField = styled(SelectionFastField)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  pointer-events: none;
`;
