import isEqual from 'react-fast-compare';

import { Permission, State } from '../types';

import { getOtherFolderPermissions } from './getOtherFolderPermissions';

export const getUpdatedSavePermissions = (
  updatedFolderPermission: Permission,
  state: State
) => {
  const { initialPermissionsExplicit, savePermissions } = state;

  const savePermissionsOtherFolders = getOtherFolderPermissions(
    updatedFolderPermission.folderId,
    savePermissions
  );

  // Now we have to check if the updated permissions of the folder is equal to the initial state.
  // In that case we do not have to save the permissions for this folder,
  // so we don't have to add it the the savePermissions state prop.
  const initialFolderPermission = initialPermissionsExplicit.find(
    (perm) => perm.folderId === updatedFolderPermission.folderId
  );

  const isEqualToInitialState =
    isEqual(
      updatedFolderPermission.folderPermissions.sort(),
      initialFolderPermission.folderPermissions.sort()
    ) &&
    updatedFolderPermission.breakInheritance ===
      initialFolderPermission.breakInheritance;

  return isEqualToInitialState
    ? savePermissionsOtherFolders
    : [...savePermissionsOtherFolders, updatedFolderPermission];
};
