import type { Stamp } from '@pro4all/graphql';
import { StampStatus, StampType } from '@pro4all/graphql';
import { useQrCodeStateQuery, useStampsQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

import { Canvas } from './canvas';
import { useDocumentPreviews, useRevoke, useStamp } from './data';
import type { StamperDocument } from './store';
import {
  Status,
  toRemoveStampInput,
  toShape,
  toStampInput,
  useStore,
} from './store';

export function useStamper(queue: StamperDocument[]) {
  const [state, dispatch] = useStore({ queue });

  const current = state.queue[state.index];
  const background = useDocumentPreviews(current, state.queue);

  const { data, loading, refetch } = useStampsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { ids: queue.map(({ id }) => id) },
  });

  const { data: qrCode, loading: loadingQrState } = useQrCodeStateQuery({
    pollInterval: 5000 * ApiConfig.pollEnabled,
    skip: !background.src || [Status.Done, Status.Idle].includes(state.status),
    variables: { versionId: current?.id },
  });

  const stamp = useStamp();
  const revoke = useRevoke();

  if (
    !loading &&
    current &&
    state.canvas &&
    qrCode?.qrCodeState !== StampStatus.Progress &&
    state.status !== Status.Idle &&
    !loadingQrState // For some reason, polling continues despite skip: true. Including loading state here stops it for reasons unknown.
  ) {
    dispatch({ type: 'init' });
  }

  const allStamps = (data?.stamps?.data ?? []) as Stamp[];
  const defaultStamps = [{ type: StampType.QrCode, versionId: current?.id }];
  const currentStamps = allStamps.filter(
    (stamp) => stamp && current?.id === stamp.versionId
  );

  const shapes = state.canvas
    ? (currentStamps.length ? currentStamps : defaultStamps).map(
        toShape(state.canvas)
      )
    : [];

  const getParams = () => {
    if (!state.canvas || !shapes.length) return;
    // TODO: BE support multiple stamps
    return shapes.map(toStampInput(state.canvas))[0];
  };

  const revokeOne = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    revoke(toRemoveStampInput(current?.documentId, currentStamps));
    refetch();
    dispatch({ type: 'next' });
  };

  const revokeAll = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    revoke(
      state.queue.slice(state.index).flatMap(({ documentId, id }) =>
        toRemoveStampInput(
          documentId,
          allStamps.filter(({ versionId }) => id === versionId)
        )
      )
    );
    refetch();
    dispatch({ index: state.queue.length, type: 'next' });
  };

  const stampOne = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    stamp(current, getParams());
    refetch();
    dispatch({ type: 'next' });
  };

  return {
    canvas: (
      <Canvas
        {...state.canvas}
        background={background}
        onInit={(canvas) => dispatch({ canvas, type: 'update' })}
        shapes={shapes}
        status={state.status}
      />
    ),
    count: state.index + 1,
    current,
    hasQRCode: shapes?.some(({ id, type }) => id && type === StampType.QrCode),
    next: () => dispatch({ type: 'next' }),
    revokeAll,
    revokeOne,
    stampOne,
    status: state.status,
    total: state.queue.length,
  };
}
