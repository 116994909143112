import React, { useEffect } from 'react';

import { Member, useGroupsAndUsersIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { FilesCannotBeSharedDialog } from './feedback/FilesCannotBeSharedDialog';
import { SendLinkByEmailModal } from './modal/SendLinkByEmailModal';
import { ShareDocumentsModal } from './modal/ShareDocumentsModal';
import { useGenerateLinkContext } from './GenerateLinkContext';

export const ShareMain = () => {
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const shareByEmail = searchParams.is('action', 'share-by-email');
  const shareDocuments = searchParams.is('action', 'share-documents');

  const { setMembers } = useGenerateLinkContext();
  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !shareByEmail && !shareDocuments, // So it won't get fetched on every DMS visit.
    variables: {
      includeActive: true,
      includeEmail: true,
      includeInvited: true,
      includeMemberIds: true,
      includeSubgroupIds: true,
      projectId,
    },
  });

  useEffect(() => {
    const members = (data?.groupsAndUsers as Member[]) || [];
    setMembers(members);
  }, [data?.groupsAndUsers, setMembers]);

  return (
    <>
      <FilesCannotBeSharedDialog />
      <ShareDocumentsModal />
      <SendLinkByEmailModal />
    </>
  );
};
