import { useCallback } from 'react';

import { TbqRieResult } from '@pro4all/graphql';

import { ResultTypesRieResult } from './useTBQRieResultsColumns';

export type RieRiskLevelResultCount = {
  attentionRequired: number;
  improvementRequired: number;
  measuresRequired: number;
  noPriority: number;
  notAnswered: number;
  workOnHold: number;
};

const resultTypeToCountKey = {
  [ResultTypesRieResult.AttentionRequired]: 'attentionRequired',
  [ResultTypesRieResult.ImprovementRequired]: 'improvementRequired',
  [ResultTypesRieResult.MeasuresRequired]: 'measuresRequired',
  [ResultTypesRieResult.NoPriority]: 'noPriority',
  [ResultTypesRieResult.NotAnswered]: 'notAnswered',
  [ResultTypesRieResult.WorkOnHold]: 'workOnHold',
};

export const useCountRieResultsByRiskLevel = () => {
  const countRieResultsByRiskLevel = useCallback(
    (rieResults: TbqRieResult[]): RieRiskLevelResultCount => {
      const resultCounts: RieRiskLevelResultCount = {
        attentionRequired: 0,
        improvementRequired: 0,
        measuresRequired: 0,
        noPriority: 0,
        notAnswered: 0,
        workOnHold: 0,
      };

      rieResults.forEach((item) => {
        const resultName = item.result?.name;
        if (resultName && resultTypeToCountKey[resultName]) {
          const countKey = resultTypeToCountKey[
            resultName
          ] as keyof RieRiskLevelResultCount;
          resultCounts[countKey]++;
        }
      });

      return resultCounts;
    },
    []
  );

  return countRieResultsByRiskLevel;
};
