import React from 'react';

import { TaskFragmentFragment } from '@pro4all/graphql';
import { BigMessageTBQNoTasks } from '@pro4all/shared/ui/messages';

import { SidebarCategoryTitle } from '../LinkedResolveTasksTitle';
import { TbqLinkedResolveTaskCard } from '../TbqLinkedResolveTaskCard';

import {
  TBQQuestionSidebarColumnContentWrapper,
  TBQQuestionSidebarContentWrapper,
} from './TBQQuestionSidebar.styled';

interface TaskContentProps {
  linkedTasks?: TaskFragmentFragment[];
}

export const TaskContent: React.FC<TaskContentProps> = ({ linkedTasks }) => (
  <TBQQuestionSidebarContentWrapper>
    <SidebarCategoryTitle iconName="tbqResolveTask" textTitle="Tasks" />
    {linkedTasks?.length ? (
      linkedTasks.map((task) => (
        <TbqLinkedResolveTaskCard key={task.id} task={task} />
      ))
    ) : (
      <TBQQuestionSidebarColumnContentWrapper>
        <BigMessageTBQNoTasks />
      </TBQQuestionSidebarColumnContentWrapper>
    )}
  </TBQQuestionSidebarContentWrapper>
);
