import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Stamp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.55 18.0996H3.76663C3.6691 18.0996 3.57692 18.1446 3.5171 18.2215C3.45695 18.2981 3.43572 18.3985 3.45947 18.4929C3.68113 19.3802 4.47471 19.9996 5.38894 19.9996H18.9277C19.8419 19.9996 20.6355 19.3802 20.8572 18.4929C20.8809 18.3985 20.8597 18.2981 20.7995 18.2215C20.7396 18.1445 20.6475 18.0996 20.55 18.0996Z" />
    <path d="M19.6 13.3497H15.1667C14.4681 13.3497 13.9 12.7816 13.9 12.083V11.5453C13.9 9.93191 14.3696 8.29791 15.2962 6.68922C15.7306 5.93523 15.8887 5.07325 15.7534 4.19736C15.4985 2.54469 14.1036 1.21024 12.4363 1.02403C11.3552 0.904015 10.2719 1.24665 9.46725 1.96675C8.66197 2.68716 8.19996 3.71984 8.19996 4.79965C8.19996 5.51244 8.39883 6.20691 8.77568 6.80856C9.64207 8.1927 10.1 9.90018 10.1 11.7467V12.083C10.1 12.7815 9.53186 13.3496 8.83331 13.3496H4.4C3.35214 13.3496 2.5 14.2018 2.5 15.2496V17.1496C2.5 17.3247 2.64187 17.4663 2.81665 17.4663H21.1833C21.3584 17.4663 21.5 17.3247 21.5 17.1496V15.2496C21.5 14.2018 20.6479 13.3497 19.6 13.3497Z" />
  </SvgIcon>
);
