import styled from 'styled-components';

import { Box, DialogContent, TextField } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

const { black } = customColors;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  height: auto;
  min-height: 500px;
  max-height: 800px;
  overflow-y: auto;
`;

export const StopWatchDisplay = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: ${black};
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const FixedHeightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const RoundItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
  width: 40%;
  margin-top: 20px;
`;

export const RoundItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid lightgray;
  &:last-child {
    border-bottom: none;
  }
`;

export const RoundLabel = styled(Text)`
  padding-left: 20px;
`;

export const RoundTime = styled(Text)`
  padding-right: 20px;
`;

export const StopWatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StopWatchRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const TimeFieldContainer = styled.div`
  margin-left: 20px;
`;

export const StyledEditTextField = styled(TextField)`
  width: 200px;
`;

export const StopWatchButtonWrapper = styled.div`
  > * :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
