import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { SendLinkByEmailForm } from '../forms/SendLinkByEmailForm';
import { useGenerateLinkContext } from '../GenerateLinkContext';

export const SendLinkByEmailModal: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { resourceIds } = useGenerateLinkContext();

  const open =
    searchParams.is('action', 'share-by-email') && Boolean(resourceIds?.length);

  return (
    <FloatingModal open={open}>
      <FloatingModal.Header iconName="share">
        {t('Email link')}
      </FloatingModal.Header>
      <SendLinkByEmailForm />
    </FloatingModal>
  );
};
