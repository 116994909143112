import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layer } from '@pro4all/shared/ui/layer';

import { EditorTextField } from '../field-types';
import { getTabIndex } from '../field-types/helpers';
import { UploaderEditorTypes } from '../types';
import {
  ContentCellWrapper,
  ContentColumnWrapper,
  HeaderWrapper,
  ResizableColumnWrapper,
} from '../wrappers';

export const NameColumn = ({
  allDocumentsSelected,
  documentIdsSelected,
  initialTabIndex,
  isProcessing,
  isTypingFromHeader,
  meta,
  noOfInputs,
  publishDocument,
  selectDocument,
  selectDocumentsViaHeader,
  setTypingFromHeader,
  updateMetaName,
  updateMetaNameViaHeader,
  zIndexResizableColumnDown,
}: Pick<
  UploaderEditorTypes,
  | 'allDocumentsSelected'
  | 'documentIdsSelected'
  | 'initialTabIndex'
  | 'isProcessing'
  | 'isTypingFromHeader'
  | 'meta'
  | 'noOfInputs'
  | 'publishDocument'
  | 'selectDocument'
  | 'selectDocumentsViaHeader'
  | 'setTypingFromHeader'
  | 'updateMetaName'
  | 'updateMetaNameViaHeader'
  | 'zIndexResizableColumnDown'
>) => {
  const { t } = useTranslation();

  const updateNameCell = ({
    documentId,
    value,
  }: {
    documentId: string;
    value: string;
  }) => updateMetaName({ documentId, value });

  const blockNameChange = publishDocument || isProcessing;

  // We wanna know if there is at least one cell in this namecolumn that is invalid.
  const hasErrorCells = meta.some(({ error }) => error);

  return (
    <ResizableColumnWrapper
      defaultWidth={300}
      zIndexResizableColumnDown={zIndexResizableColumnDown}
    >
      <HeaderWrapper
        allDocumentsSelected={allDocumentsSelected}
        hasErrorCells={hasErrorCells}
        selectDocumentsViaHeader={selectDocumentsViaHeader}
        showCheckbox={!blockNameChange}
        tabIndex={initialTabIndex}
      >
        {blockNameChange ? (
          t('Name')
        ) : (
          <EditorTextField
            isTypingFromHeader={isTypingFromHeader}
            label={t('Name')}
            name="headerName"
            setTypingFromHeader={setTypingFromHeader}
            tabIndex={initialTabIndex ? initialTabIndex + 1 : initialTabIndex}
            updateProvider={(value: string) => updateMetaNameViaHeader(value)}
          />
        )}
      </HeaderWrapper>
      <ContentColumnWrapper>
        {meta.map(({ error, id, name }, index) => {
          const tabIndex = getTabIndex({ index, initialTabIndex, noOfInputs });
          return (
            <NameCell
              blockNameChange={blockNameChange}
              documentIdsSelected={documentIdsSelected}
              error={error}
              id={id}
              isProcessing={isProcessing}
              isTypingFromHeader={isTypingFromHeader}
              key={id}
              name={name}
              selectDocument={selectDocument}
              setTypingFromHeader={setTypingFromHeader}
              tabIndex={tabIndex}
              updateNameCell={updateNameCell}
            />
          );
        })}
      </ContentColumnWrapper>
    </ResizableColumnWrapper>
  );
};

type NameCellProps = {
  blockNameChange: boolean;
  documentIdsSelected: string[];
  error: string;
  id: string;
  name: string;
  updateNameCell: ({
    documentId,
    value,
  }: {
    documentId: string;
    value: string;
  }) => void;
} & Pick<
  UploaderEditorTypes,
  | 'isProcessing'
  | 'isTypingFromHeader'
  | 'selectDocument'
  | 'setTypingFromHeader'
  | 'tabIndex'
>;

const NameCellComponent = ({
  blockNameChange,
  documentIdsSelected,
  error,
  id,
  isProcessing,
  isTypingFromHeader,
  name,
  selectDocument,
  setTypingFromHeader,
  tabIndex,
  updateNameCell,
}: NameCellProps) => (
  <ContentCellWrapper
    id={id}
    isSelected={documentIdsSelected.includes(id)}
    selectDocument={selectDocument}
    showCheckbox={!blockNameChange || !isProcessing}
    tabIndex={tabIndex}
  >
    {blockNameChange && <Layer />}
    <EditorTextField
      errorString={error}
      isTypingFromHeader={isTypingFromHeader}
      name={`cellName-${id}`}
      setTypingFromHeader={setTypingFromHeader}
      tabIndex={tabIndex ? tabIndex + 1 : tabIndex}
      updateProvider={(value) => updateNameCell({ documentId: id, value })}
      value={name}
    />
  </ContentCellWrapper>
);

const skipUpdate = (prevProps: NameCellProps, nextProps: NameCellProps) => {
  const idSwop =
    (prevProps.documentIdsSelected.includes(nextProps.id) &&
      !nextProps.documentIdsSelected.includes(prevProps.id)) ||
    (!prevProps.documentIdsSelected.includes(nextProps.id) &&
      nextProps.documentIdsSelected.includes(prevProps.id));
  return (
    prevProps.name === nextProps.name &&
    prevProps.error === nextProps.error &&
    prevProps.isProcessing === nextProps.isProcessing &&
    prevProps.tabIndex === nextProps.tabIndex &&
    !idSwop
  );
};

const NameCell = React.memo(NameCellComponent, skipUpdate);
