import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthType, useUnreadCountQuery } from '@pro4all/graphql';
import { ShowWithPermission } from '@pro4all/identity/ui';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useSubscriptionHelpers } from '@pro4all/settings/ui/src/subscription';
import { ApiConfig } from '@pro4all/shared/config';
import { useSideNavContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  MenuItem,
  NavigationItems,
  OrganizationTitle,
  SideNavigation,
} from '@pro4all/shared/ui/side-navigation';

export const OrganizationNavigation = ({ orgName }: { orgName: string }) => {
  const { t } = useTranslation();

  const { hasAppPermission } = useAppPermissions();
  const canUpdateOrganizationSettings = hasAppPermission('OrganizationUpdate');
  const { userAuthType } = useOrganizationContext();

  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');
  const hasObjectsFeature = useFeatureFlag('objects');
  const hasHierarchyFeature = useFeatureFlag('hierarchy-field');
  const hasTBQAdminFeature = useFeatureFlag('tbq-admin');
  const hasScim = useFeatureFlag('scim');
  const hasDraftFeature = useFeatureFlag('draft-messages');

  const { isExpired } = useSubscriptionHelpers();
  const theme = useTheme();
  const tooSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const organizationRead = Boolean(
    ShowWithPermission({ appPermission: 'OrganizationRead' })
  );

  const organizationUpdate = Boolean(
    ShowWithPermission({ appPermission: 'OrganizationUpdate' })
  );

  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  const {
    userOrganizationName,
    contextScopedOrganizationSubscriptionLevel,
    organizationId,
  } = useOrganizationContext();

  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  const organizationName = userOrganizationName || orgName;

  const { data } = useUnreadCountQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 10000 * ApiConfig.pollEnabled,
    variables: {
      isOrganization: true,
    },
  });
  const unreadAllMessages =
    data?.unreadCount.find((item) => item?.id === organizationId)?.all || 0;
  const unreadInboxMessages =
    data?.unreadCount.find((item) => item?.id === organizationId)?.inbox || 0;
  const unreadSentMessages =
    data?.unreadCount.find((item) => item?.id === organizationId)?.sent || 0;
  const unreadDraftMessages = 0;
  // data?.unreadCount.find((item) => item?.id === organizationId)?.sent || 0;

  const menuItems: MenuItem[] = [
    {
      dataTestId: 'organization-myTasks',
      displayName: t('My tasks'),
      icon: 'qcTask',
      id: 'myTasks',
      route: 'myTasks',
      trackPayload: {
        targetLocation: 'My Tasks',
      },
    },
    {
      dataTestId: 'organization-myProjects',
      displayName: t('My projects'),
      icon: 'myProjects',
      id: 'myProjects',
      route: 'myProjects',
      trackPayload: {
        targetLocation: 'My Projects',
      },
    },
    {
      condition: !isExpired,
      dataTestId: 'organization-messages',
      displayName: t('Messages'),
      icon: unreadAllMessages > 0 ? 'prostreamChatUnread' : 'prostreamChat',
      id: 'messages',
      messageCount: unreadAllMessages,
      route: 'inboxMessages',
      subItems: [
        {
          dataTestId: 'organization-messages-all',
          displayName: t('All'),
          icon: null,
          messageCount: unreadAllMessages,
          route: 'allMessages',
        },
        {
          dataTestId: 'organization-messages-inbox',
          displayName: t('Inbox'),
          icon: null,
          messageCount: unreadInboxMessages,
          route: 'inboxMessages',
        },
        {
          dataTestId: 'organization-messages-sent',
          displayName: t('Sent'),
          messageCount: unreadSentMessages,
          route: 'sentMessages',
        },
        {
          condition: hasDraftFeature,
          dataTestId: 'organization-messages-draft',
          displayName: t('Draft'),
          messageCount: unreadDraftMessages,
          route: 'draftMessages',
        },
      ],
      trackPayload: {
        targetLocation: 'Messages',
      },
    },
    {
      condition: !isExpired && organizationUpdate,
      dataTestId: 'organization-allProjects',
      displayName: t('All projects'),
      icon: 'project',
      id: 'projects',
      route: 'projects',
      trackPayload: {
        targetLocation: 'Projects',
      },
    },
    {
      condition: organizationRead && !isExpired && hasDmsLicense,
      dataTestId: 'organization-documents',
      displayName: t('Documents'),
      icon: 'folderOpen',
      id: 'organizationDocuments',
      route: 'baseDocs',
      subItems: [
        {
          dataTestId: 'organization-documents-folders',
          displayName: t('Folders'),
          route: 'baseDocs',
        },
        {
          condition: !tooSmallScreen,
          dataTestId: 'organization-documents-notifications',
          displayName: t('Notifications'),
          icon: null,
          route: 'organizationNotifications',
        },
      ],
      trackPayload: {
        targetLocation: 'Documents',
      },
    },
    {
      condition:
        !tooSmallScreen &&
        organizationUpdate &&
        hasObjectsFeature &&
        !isExpired,
      dataTestId: 'organization-objects',
      displayName: t('Objects'),
      icon: 'deviceHub',
      id: 'organizationObjects',
      route: 'objectsBase',
      trackPayload: {
        targetLocation: 'Objects',
      },
    },
    {
      condition: organizationUpdate && !isExpired && !tooSmallScreen,
      dataTestId: 'organization-templates',
      displayName: t('Templates'),
      icon: 'qualityControl',
      id: 'organizationTemplates',
      route: 'metaDataDocumentsTemplates',
      subItems: [
        {
          condition: hasDmsLicense,
          dataTestId: 'organization-templates-documents',
          displayName: t('Metadata'),
          route: 'metaDataDocumentsTemplates',
        },
        {
          condition: hasQualityControlFeature && hasQualityControlLicense,
          dataTestId: 'organization-templates-qc',
          displayName: t('Quality control'),
          route: 'metaDataQualityControlForms',
        },
        {
          condition:
            hasQualityControlFeature &&
            hasQualityControlLicense &&
            hasTBQAdminFeature,
          dataTestId: 'organization-templates-tbq',
          displayName: t('TBQ scans'),
          route: 'metaDataQualityControlTBQTemplates',
        },
        {
          condition:
            hasQualityControlFeature &&
            hasQualityControlLicense &&
            hasHierarchyFeature,
          dataTestId: 'project-hierarchical-lists',
          displayName: t('Hierarchical lists'),
          route: 'metaDataHierarchicalLists',
        },
        {
          condition:
            (hasQualityControlLicense && hasQualityControlFeature) ||
            hasDmsLicense,
          dataTestId: 'organization-templates-task-categories',
          displayName: t('Tasks'),
          route: 'orgTemplatesTasks',
        },
      ],
      trackPayload: {
        targetLocation: 'Documents',
      },
    },
    {
      condition:
        !tooSmallScreen && organizationUpdate && !isExpired && hasDmsLicense,
      dataTestId: 'organization-permissions',
      displayName: t('Permissions'),
      icon: 'security',
      id: 'organizationAccess',
      route: 'baseAccessDocuments',
      subItems: [
        {
          dataTestId: 'organization-permissions-documents',
          displayName: t('Documents'),
          route: 'baseAccessDocuments',
        },
      ],
      trackPayload: {
        targetLocation: 'Access',
      },
    },
    {
      condition: organizationUpdate,
      dataTestId: 'organization-settings',
      displayName: t('Settings'),
      icon: 'settingsApplications',
      id: 'organizationSettings',
      route: 'settingsOrganizationOrganization',
      subItems: [
        {
          dataTestId: 'organization-settings-organization',
          displayName: t('Organization'),
          route: 'settingsOrganizationOrganization',
        },
        {
          dataTestId: 'organization-settings-license',
          displayName: t('License'),
          route: 'settingsOrganizationLicense',
        },
        {
          condition: hasDmsLicense,
          dataTestId: 'organization-settings-integrations',
          displayName: t('Integrations'),
          route: 'settingsOrganizationIntegrations',
        },
        {
          condition: hasDmsLicense,
          dataTestId: 'organization-settings-documents',
          displayName: t('Documents'),
          route: 'settingsOrganizationDocuments',
        },
        {
          condition:
            userAuthType === AuthType.Sso && canUpdateOrganizationSettings,
          dataTestId: 'organization-settings-security',
          displayName: t('Security'),
          route: 'settingsOrganizationSettings',
        },
        {
          condition: hasScim,
          dataTestId: 'organization-settings-domains',
          displayName: t('Domains'),
          route: 'settingsOrganizationDomains',
        },
        {
          condition: hasScim,
          dataTestId: 'organization-settings-provisioning',
          displayName: t('Provisioning'),
          route: 'settingsOrganizationProvisioning',
        },
      ],
      trackPayload: {
        targetLocation: 'Access',
      },
    },
    {
      condition: organizationUpdate,
      dataTestId: 'organization-colleagues',
      displayName: t('Colleagues'),
      icon: 'settings',
      id: 'organizationColleagues',
      route: 'members',
      subItems: [
        {
          dataTestId: 'organization-colleagues-users',
          displayName: t('Users'),
          route: 'members',
        },
        {
          dataTestId: 'organization-colleagues-groups',
          displayName: t('Groups'),
          route: 'groups',
        },
      ],
    },
  ];

  return (
    <SideNavigation color="dark">
      {isSideNavOpen && organizationName && (
        <OrganizationTitle organizationName={organizationName} />
      )}
      <ShowWithPermission appPermission="OrganizationRead">
        <NavigationItems menuItems={menuItems} />
      </ShowWithPermission>
    </SideNavigation>
  );
};
