import React from 'react';
import { AutoResizer, unflatten } from 'react-base-table';

import { TableNode } from '@pro4all/objects/utils';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { StylingDefaults } from '@pro4all/shared/themes';
import { useTableRowToggle } from '@pro4all/shared/ui/general';
import { ExpandIcon, TableCell } from '@pro4all/shared/ui/general';

import { useObjectsContext } from '../../ObjectsContext';

import { StyledObjectsTable } from './ObjectsTable.styles';
import { Row } from './Row';

const components = { ExpandIcon };
const columns = [
  {
    cellRenderer: TableCell,
    dataKey: 'name',
    flexGrow: 1,
    flexShrink: 1,
    key: 'name',
    minWidth: 265,
    render: (row: TableNode) => <Row {...row} />,
    title: '',
  },
];

export const Table: React.FC = () => {
  const { goTo, params } = useRouting();
  const { projectId } = params;

  const { objects, selectedObject } = useObjectsContext();

  const rows = unflatten(objects, null, 'id', 'parentNodeId');
  const defaultExpandedRowKeys = objects.reduce(
    (keys: string[], { parentNodeId: id }) => (id ? [...keys, id] : keys),
    []
  );

  const { toggle, toggles: expandedRowKeys } = useTableRowToggle({
    initial: defaultExpandedRowKeys,
    key: `prostream-view-objects.expandedRowKeys`,
  });

  const selectNode = (objectId: string) => {
    projectId
      ? goTo('projectObjectTbq', { params: { objectId, projectId } })
      : goTo('objectTBQ', { params: { objectId } });
  };

  const expandIconProps = ({
    rowData: { id, children },
  }: {
    rowData: TableNode;
  }) => ({
    expandable: Boolean(children?.length),
    id,
    isParentOfSelected:
      children?.length &&
      Object.values(children).find((child) => child.id === selectedObject?.id),
    isSelected: id === selectedObject?.id,
  });

  const estimatedRowHeight = ({ rowData }: { rowData: TableNode }) => {
    const textLength = rowData.name.length;
    const lines = Math.ceil(textLength / 30);
    return Math.max(StylingDefaults.tableRowHeight, lines * 20);
  };

  return (
    <Box flex={1}>
      <AutoResizer>
        {({ width, height }) => (
          <StyledObjectsTable
            clickable
            columns={columns}
            components={components}
            data={rows}
            defaultExpandedRowKeys={defaultExpandedRowKeys}
            estimatedRowHeight={estimatedRowHeight}
            expandColumnKey="name"
            expandIconProps={expandIconProps}
            expandedRowKeys={expandedRowKeys}
            headerHeight={0}
            height={height}
            margin={0}
            onRowExpand={({
              expanded,
              rowKey,
            }: {
              expanded: boolean;
              rowKey: string;
            }) => {
              toggle(rowKey, expanded);
            }}
            rowClassName={({ rowData }: { rowData: TableNode }) =>
              rowData.id === selectedObject?.id && 'row-selected'
            }
            rowEventHandlers={{
              onClick: ({ rowData }: { rowData: TableNode }) => {
                selectNode(rowData.id);
              },
              onDoubleClick: ({ rowKey }: { rowKey: string }) => {
                toggle(rowKey);
              },
            }}
            rowHeight={StylingDefaults.tableRowHeight}
            sortKey="name"
            width={width}
          />
        )}
      </AutoResizer>
    </Box>
  );
};
