import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const MSTeams: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M19.4981 9.93651C19.2848 9.84581 19.0916 9.71563 18.9279 9.55186C18.7649 9.38809 18.6339 9.19409 18.5432 8.98161C18.3568 8.53565 18.3568 8.03427 18.5432 7.58915C18.7255 7.15915 19.0681 6.81733 19.4981 6.63425C19.9441 6.4478 20.4455 6.4478 20.8906 6.63425C21.3206 6.81649 21.6624 7.15915 21.8455 7.58915C22.0319 8.03511 22.0319 8.53649 21.8455 8.98161C21.6632 9.41161 21.3206 9.75342 20.8906 9.93651C20.4446 10.123 19.9432 10.123 19.4981 9.93651Z" />
    <path d="M18.4038 16.6057V11.2131H21.9983V14.7471C22.0109 15.0654 21.9211 15.3787 21.7422 15.6416C21.5717 15.8784 21.35 16.0749 21.093 16.2143C20.8209 16.3621 20.5269 16.4646 20.2221 16.5184C19.9189 16.5755 19.6115 16.604 19.3024 16.6057H18.4038Z" />
    <path d="M16.1866 8.81196C15.9666 8.90602 15.7297 8.95389 15.4912 8.95221C15.2527 8.95389 15.0167 8.90686 14.7975 8.81196C14.7181 8.778 14.6655 8.73925 14.6063 8.69572C14.544 8.64991 14.4745 8.59879 14.3591 8.54237V5.77929C14.4742 5.72324 14.5437 5.67207 14.6062 5.6261C14.6654 5.58252 14.7182 5.54362 14.7975 5.5097C15.0159 5.41564 15.251 5.36777 15.4887 5.36945C15.7281 5.36777 15.9649 5.41564 16.1849 5.5097C16.6149 5.69195 16.9567 6.0346 17.1398 6.4646C17.3271 6.91056 17.3271 7.41194 17.1407 7.85706C16.9584 8.28706 16.6166 8.62887 16.1866 8.81196Z" />
    <path d="M14.587 18.0881C14.5259 18.0716 14.4558 18.0527 14.3591 18.0317V10.0894H17.7294V16.3294C17.7412 16.6426 17.6631 16.9534 17.5035 17.223C17.359 17.4539 17.1634 17.6488 16.9307 17.7916C16.6939 17.9352 16.4335 18.0368 16.1623 18.0914C15.896 18.1485 15.6239 18.177 15.3518 18.1787C15.1754 18.1779 14.9991 18.1653 14.8244 18.1401C14.7334 18.1276 14.6681 18.11 14.587 18.0881Z" />
    <path
      clipRule="evenodd"
      d="M13.6847 21.7741L2 19.7517V4.02234L13.6847 2V21.7741ZM3.77539 7.76184L10.2466 7.36328V8.9993L7.81975 9.10836V16.2681L6.20221 16.1723V9.17258L3.77539 9.27146V7.76184Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
