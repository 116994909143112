import { toApiDate } from '@pro4all/shared/utils';

import { useHierarchyEditorContext } from '../HierarchyEditorProvider';

export const useGetUpdatedItems = () => {
  const {
    state: { items, itemsInitial },
  } = useHierarchyEditorContext();

  const getUpdatedItems = () => {
    // Items that have been added.
    const newItems = items.filter(
      (item) => !itemsInitial.find((initialItem) => initialItem.id === item.id)
    );

    // Items that have been removed.
    const removedItems = itemsInitial
      .filter(
        (itemInitial) => !items.find((item) => item.id === itemInitial.id)
      )
      .map((item) => ({ ...item, deletedAt: toApiDate(new Date()) }));

    // Items that have been updated.
    // Find all items that are part of itemsInitial but have a different value for `name`, `parentNodeId` or `previousNodeId`.
    const updatedItems = items
      .filter((item) =>
        itemsInitial.find((itemInitial) => itemInitial.id === item.id)
      )
      .filter((item) => {
        const initialItem = itemsInitial.find(
          (itemInitial) => itemInitial.id === item.id
        );
        return initialItem
          ? item.name !== initialItem.name ||
              item.parentNodeId !== initialItem.parentNodeId ||
              item.previousNodeId !== initialItem.previousNodeId
          : false;
      });

    return [...newItems, ...removedItems, ...updatedItems];
  };

  return getUpdatedItems;
};
