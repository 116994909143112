import React, { createContext, useContext, useState } from 'react';

type AnswersPercentageContextType = {
  percentageTotal: string;
  setPercentageTotal: (percentage: string) => void;
};

const defaultValue: AnswersPercentageContextType = {
  percentageTotal: '',
  setPercentageTotal: () => '',
};

const AnswersPercentageContext =
  createContext<AnswersPercentageContextType>(defaultValue);

export const useAnswersPercentageContext = () =>
  useContext(AnswersPercentageContext);

export const AnswersPercentageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [percentageTotal, setPercentageTotal] = useState<string>('');

  return (
    <AnswersPercentageContext.Provider
      value={{ percentageTotal, setPercentageTotal }}
    >
      {children}
    </AnswersPercentageContext.Provider>
  );
};
