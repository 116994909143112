import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteGroups } from '../useDeleteGroups';

import { AddGroupsToProjectSidebar } from './AddGroupsToProjectSidebar';

export const OrganizationGroupsActionBar: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const deleteGroups = useDeleteGroups();

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Add to project'),
      dataTestId: 'add-user-to-project',
      disabled: userCannotUpdateOrg,
      key: 'add-user-to-project',
      label: t('Add to project'),
      onClick: () => searchParams.set({ action: 'addGroupsToProject' }),
      startIcon: 'project',
    },
    {
      ariaLabel: t('Delete'),
      dataTestId: 'delete-groups',
      disabled: userCannotUpdateOrg,
      key: 'delete-groups',
      label: t('Delete'),
      onClick: deleteGroups,
      startIcon: 'delete',
    },
  ];

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('New group'),
      dataTestId: 'new-group',
      disabled: userCannotUpdateOrg,
      key: 'new-group',
      label: t('New group'),
      onClick: () => {
        searchParams.delete('name');
        searchParams.set({ action: 'createGroup' });
      },
      startIcon: 'groupAdd',
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={altActions}
        dataTestid="groups-action-bar"
        mainActions={mainActions}
        search
      />
      <AddGroupsToProjectSidebar />
    </>
  );
};
