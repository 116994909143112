import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  negative?: string;
  positive?: string;
  value: boolean;
}

export const getBooleanOutput = ({
  negative = 'No',
  positive = 'Yes',
  value,
}: Props): string => (value ? positive : negative);

export const BooleanOutput: React.FC<Props> = ({
  negative,
  positive,
  value,
}) => {
  const { t } = useTranslation();
  return t(getBooleanOutput({ negative, positive, value }));
};
