import { useRouteMatch } from 'react-router';

import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useNavigationActions = () => {
  const { searchParams } = useRouting();
  const inWorkflow = useRouteMatch([Routes.projectWorkflows]);

  const closeSidebar = () => {
    const routeEdit = searchParams.is('action', 'editResult');
    const routeView = searchParams.is('action', 'viewResult');
    const isTBQEditQuestionnaire = searchParams.is(
      'edit-tbq',
      'edit-tbq-dashboard'
    );
    const taskId = searchParams.get('taskId') || '';

    const page = searchParams.get('page');
    const search = searchParams.get('search');
    // Search does not use createSavedAnswers but it should not be an issue
    if (page || search) {
      searchParams.deleteMultiple(['action', 'id', 'createSavedAnswers']);
    } else if (isTBQEditQuestionnaire) {
      searchParams.deleteMultiple(['action', 'createSavedAnswers', 'edit-tbq']);
    } else if (taskId && (routeView || routeEdit)) {
      // If there is still a taskId in the query params we know the user came from a QC task
      searchParams.set('action', 'viewTask');
      searchParams.set('id', taskId);
      searchParams.delete('taskId');
    } else {
      searchParams.clear();
    }
  };

  const goBack = () => {
    const routeView = searchParams.is('action', 'viewResult');
    const taskId = searchParams.get('taskId') || '';

    if (routeView && inWorkflow) {
      searchParams.set({ action: 'viewTask', id: taskId });
    } else {
      // Next line prevents the following issue.
      // 1. Do some changes in a snag or form and press `Save and close`.
      // 2. Sidebar appears in view mode with the updated values.
      // 3. Click on the `Edit` button.
      // 4. Sidebar appears in view mode with the PREVIOUS!! values.
      // So by closing the sidebar entirely and then re-opening it again, we prevent this issue.
      // Spent a couple of days to analyze the weird rendering of `values` in `ResultForm`,
      // but did not succeed to find a solution there.
      searchParams.deleteMultiple(['action', 'applySavedAnswers']);
      searchParams.set({ action: 'viewResult' });
    }
  };

  return { closeSidebar, goBack };
};
