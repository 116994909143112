import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { QuickAction } from '@pro4all/shared/ui/general';

import { FolderForm } from './FolderForm';

interface Props {
  parentFolder?: Folder;
}

export const FolderCreate: React.FC<Props> = ({ parentFolder }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(
      () =>
        searchParams.is('action', 'createFolder') ||
        searchParams.is('action', 'createRootFolder')
    );
  }, [searchParams]);

  const handleClose = () => searchParams.clear();

  return (
    <QuickAction
      headerText={t('New folder')}
      input={
        isOpen && (
          <FolderForm onClose={handleClose} parentFolder={parentFolder} />
        )
      }
      open={isOpen}
    />
  );
};
