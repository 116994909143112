import React from 'react';
import { useTranslation } from 'react-i18next';
import userflow from 'userflow.js';

import { IconButton } from '@pro4all/shared/ui/buttons';

export const HelpMenu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IconButton
      ariaLabel={t('Prostream Resource Center')}
      color="default"
      disableBorder
      iconName="helpOutline"
      onClick={() => userflow.toggleResourceCenter()}
    />
  );
};
