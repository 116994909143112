import { PreviewDimensions } from './ImagePreview.types';

export const calculatePreviewDimensions = () => {
  // We have to calculate the aspect ratio of the container in order to fully and properly
  // Show the preview. It is requried to show Lightbox inline

  // Use the responsive breakpoint to calculate the available width. 762 is the
  // fixed width above the breakpoint. there after we pick innerwidth minus paddings
  // TODO: This is a bit nasty; parametrize these numbers?
  // 64 = 4 * 18 padding
  // 116 = modal header + 2*21 padding + 2*18padding
  const ratioWidth = window.innerWidth > 833 ? 762 : window.innerWidth - 64;
  const ratioHeight = window.innerHeight - 116 - 16;
  const dimensions: PreviewDimensions = {
    height: ratioHeight,
    width: ratioWidth,
  };
  return dimensions;
};
