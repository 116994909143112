import { getOptionLabelForUser } from '@pro4all/identity/ui';
import { useOrganizationContext } from '@pro4all/organization/context';

import { FormFields, ProjectType } from './types';

export const useInitialValues = ({
  aiEnabledProject,
  objectId,
  project,
}: {
  aiEnabledProject?: boolean;
  objectId?: string;
  project?: ProjectType;
}) => {
  const { userDisplayName, userEmail, userId } = useOrganizationContext();

  const {
    deadline,
    description,
    name: projectName,
    projectNumber,
    startDate,
  } = project || {};

  const initialValues: FormFields = {
    admins: [
      {
        iconName: 'person',
        id: userId,
        label: getOptionLabelForUser({
          displayName: userDisplayName,
          email: userEmail,
        }),
      },
    ],
    deadline: deadline ?? '',
    description: description ?? '',
    members: [],
    name: projectName ?? '',
    objectId,
    projectNumber: projectNumber ?? '',
    settings: {
      ai_admin_settings: aiEnabledProject ?? false,
    },
    startDate: startDate ?? '',
  };

  return initialValues;
};
