import { useTranslation } from 'react-i18next';

import { FormFieldConfig } from './useValidation';

export const useWarnings = () => {
  const { t } = useTranslation();

  const requiredWarning = (item: FormFieldConfig) => {
    if (item.displayName) {
      return t("'{{name}}' is required", {
        name: item.displayName,
      });
    } else {
      return t('Required');
    }
  };

  const greaterWarning = (item: FormFieldConfig) =>
    t("'{{name}}' must be greater than or equal to {{number}}", {
      name: item.displayName,
      number: item.valueType?.minValue,
    });

  const lessWarning = (item: FormFieldConfig) =>
    t("'{{name}}' must be less than or equal to {{number}}", {
      name: item.displayName,
      number: item.valueType?.maxValue,
    });

  const maxCharWarning = (item: FormFieldConfig) =>
    t('No more than {{number}} characters', {
      number: item.max,
    });

  const minCharWarning = (item: FormFieldConfig) =>
    t('At least {{number}} characters', {
      number: item.min,
    });

  return {
    greaterWarning,
    lessWarning,
    maxCharWarning,
    minCharWarning,
    requiredWarning,
  };
};
