import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

const StyledBox = styled(Box)`
  && {
    > * {
      margin-bottom: 16px;
    }
  }
`;

export const FormWrapper = ({
  children,
  noPadding = false,
}: PropsWithChildren<{ noPadding?: boolean }>) =>
  // In case if Formik forms are embedded in a <FloatingModal/>, the padding is already covered via the <Content/> component that is embedded in the <FloatingModal/>.
  noPadding ? (
    <StyledBox>{children}</StyledBox>
  ) : (
    <StyledBox px={3}>{children}</StyledBox>
  );
