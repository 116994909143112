import React from 'react';
import { useTranslation } from 'react-i18next';

import { Template } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Loader,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { ProgressBar } from '@pro4all/shared/ui/progress-bar';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { SetsActionBar } from '../action-bars/SetsActionBar';
import { BigMessage } from '../big-message/BigMessage';
import { useColumnsAdditional } from '../columns/useColumnsAdditional';
import { useColumnsTemplates } from '../columns/useColumnsTemplates';
import NavigationDropdown from '../navigation-dropdown/NavigationDropdown';

import {
  CustomActionBarWrapper,
  CustomDefaultActionBarWrapper,
} from './CustomActionBarWrapper';

export const ScreenTemplates = ({
  includeOrgItems = true,
  isProject = false,
}: {
  includeOrgItems?: boolean;
  isProject?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    state: { items, itemsInitial },
    filterItems,
  } = useOptimisticResponseContext<Template>();

  const {
    numberOfTemplatesForImport,
    numberOfTemplatesAlreadyImported,
    templateType,
    templateService,
  } = useMetaDataContext();
  const columnsAdditional = useColumnsAdditional();
  const columnsSets = useColumnsTemplates();
  const additionalColumns = isProject ? columnsAdditional : [];
  const { hasAppPermission } = useAppPermissions();

  const { loading, templates, selectTemplate } = useTemplatesInclude({
    includeCreatedAt: true,
    includeCreatedBy: true,
    includeOrgItems,
    includeScope: true,
    includeState: true,
    includeType: true, // We don't need this for the table, but we do need it for the export from the template editor.
    templateService,
    templateType,
  });

  // If user switches from FieldDefinitions to Templates the data provider needs a sec to overwrite the old items list.
  // This causes issues in terms of showing Field definitions in a Templates table.
  // TODO: For now it is the best solution to trigger filtering on the templates array.
  // Later on we need to refactor so that we have separate providers for Field definitions and Templates.
  if (items && items[0] && items[0].__typename !== 'Template') {
    filterItems(templates);
  }

  useSetItemsInLocalState<Template>(templates);

  if (loading) return <Loader />;

  return (
    <TableContextProvider
      columns={[...columnsSets, ...additionalColumns]}
      id={`table-templates-${templateType}-${
        isProject ? 'project' : 'organization'
      }`}
      items={items}
    >
      <FilterContextProvider<Template>>
        <>
          <CustomActionBarWrapper>
            <Box>
              <NavigationDropdown />
            </Box>
            <CustomDefaultActionBarWrapper>
              <SetsActionBar />
            </CustomDefaultActionBarWrapper>
          </CustomActionBarWrapper>
          {!items?.length && !itemsInitial?.length ? (
            <BigMessage />
          ) : (
            <Table<Template>
              onRowClick={(row) =>
                hasAppPermission(
                  isProject ? 'ProjectUpdate' : 'OrganizationUpdate'
                ) && selectTemplate(row)
              }
            />
          )}
        </>
      </FilterContextProvider>
      {numberOfTemplatesForImport && numberOfTemplatesForImport > 0 ? (
        <ProgressBar
          current={
            numberOfTemplatesAlreadyImported / numberOfTemplatesForImport
          }
          maximum={1}
          text={t('{{current}} of {{maximum}} templates imported', {
            current: numberOfTemplatesAlreadyImported,
            maximum: numberOfTemplatesForImport,
          })}
        />
      ) : null}
    </TableContextProvider>
  );
};
