import React from 'react';
import { useTranslation } from 'react-i18next';

import { FinalizationState } from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';

import { documentStateLabels, documentStateValues } from '../records/';
import { useMDFacets } from '../utils/useMDFacets';

import { TagSelect } from './TagSelect';

export const StateFilter: React.FC<FilterBaseProps> = (filterProps) => {
  const { t } = useTranslation();
  const enumValues = Object.values(FinalizationState);
  const { filteredAnswers } = useMDFacets(filterProps);
  const { value, type } = filterProps;

  /* Action naming mapping for filter */
  const getActionNamingMapping = useActionNamingMapping();
  const options = enumValues.map((key) => ({
    id: documentStateValues[key],
    inputValue: documentStateValues[key],
    label: t(documentStateLabels[key], { name: getActionNamingMapping(key) }),
  }));
  const ids = value.split(delimiters.multiSelectOptions);

  const filteredValues = filteredAnswers?.map((answer) => answer.value) || [];
  const filteredOptions = options.filter((option) =>
    filteredValues.includes(option.inputValue)
  );

  const initialOptions = filteredOptions.filter((option) =>
    ids.includes(option.id)
  );

  return (
    <TagSelect
      initialOptions={initialOptions}
      options={filteredOptions}
      showInputFilter={false}
      type={type}
      value={value}
    />
  );
};
