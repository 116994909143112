import { ObjectNode } from '@pro4all/graphql';

// Returns branch from any target as root
export function getFlatBranch({
  allNodes,
  targetNode,
}: {
  allNodes: ObjectNode[];
  targetNode: ObjectNode;
}) {
  const flatBranch: ObjectNode[] = [targetNode];
  const children = allNodes.filter(
    (node) => node.parentNodeId === targetNode.id
  );
  // Recurse down if children are found
  if (children.length) {
    children.forEach((child) => {
      flatBranch.push(...getFlatBranch({ allNodes, targetNode: child }));
    });
  }
  return flatBranch;
}
