import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetNotificationsQuery,
  NotificationScope,
  NotificationsUser,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Column,
  ContentRow,
  Main,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessage } from '@pro4all/shared/ui/messages';

import { NotificationsTable } from './NotificationsTable';
import { useColumns } from './useColumns';

type NotificationsTableProps = {
  dataQuery?: GetNotificationsQuery;
  scope: NotificationScope;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const NotificationsTableContainer = ({
  setIsModalOpen,
  dataQuery,
  scope,
}: NotificationsTableProps) => {
  const { t } = useTranslation();
  const columns = useColumns();

  const {
    params: { projectId },
  } = useRouting();

  const isProject = Boolean(projectId);

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<NotificationsUser>();

  const notificationsData = useMemo(
    () => dataQuery?.getNotifications?.users || [],
    [dataQuery?.getNotifications?.users]
  ) as NotificationsUser[];
  useSetItemsInLocalState(notificationsData);

  return (
    <Main>
      <ContentRow>
        <Column>
          <TableContextProvider
            checkable
            columns={columns}
            id="notifications-table"
            items={items}
          >
            <FilterContextProvider<NotificationsUser>>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                my={2}
                px={4}
              >
                {scope === NotificationScope.Qc ? (
                  <Box>
                    {t(
                      'Set email notifications for your project members in the Quality Control module.'
                    )}
                  </Box>
                ) : (
                  <Box>
                    {t(
                      isProject
                        ? 'Set email notifications for your project members when a new document or version is uploaded.'
                        : 'Set email notifications for your members when a new document or version is uploaded.'
                    )}
                  </Box>
                )}
                <Button
                  onClick={() => setIsModalOpen((current) => !current)}
                  startIcon="notificationRules"
                >
                  {t('Notification settings')}
                </Button>
              </Box>
              <Box display="flex" flex={1}>
                {!items.length && !itemsInitial.length ? (
                  <BigMessage
                    shapeName="flow1"
                    title={t("There isn't any data")}
                  />
                ) : (
                  <NotificationsTable scope={scope} />
                )}
              </Box>
            </FilterContextProvider>
          </TableContextProvider>
        </Column>
      </ContentRow>
    </Main>
  );
};
