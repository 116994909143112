import { useTranslation } from 'react-i18next';

import {
  RequiredMetaDataFieldOption,
  UploadFileOption,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';

import { FormFields } from './types';

export const useInitialValues = () => {
  const { settings } = useProjectContext();
  const { orgSettings } = useDocumentActionsLabels();
  const { t } = useTranslation();

  const {
    metadata_required,
    version_name_instead_of_document_name_on_upload,
    approve_action_label,
    reject_action_label,
    finalize_action_label,
  } = settings || {};

  const helperValues = {
    approve_action_label_helper: orgSettings?.approve_action_label
      ? t("Organization naming '{{name}}'", {
          name: orgSettings.approve_action_label,
        })
      : '',
    finalize_action_label_helper: orgSettings?.finalize_action_label
      ? t("Organization naming '{{name}}'", {
          name: orgSettings.finalize_action_label,
        })
      : '',
    reject_action_label_helper: orgSettings?.reject_action_label
      ? t("Organization naming '{{name}}'", {
          name: orgSettings.reject_action_label,
        })
      : '',
  };

  const initialValues: FormFields = {
    approve_action_label: approve_action_label as string,
    finalize_action_label: finalize_action_label as string,
    metadata_required: metadata_required
      ? metadata_required
      : RequiredMetaDataFieldOption.Enforce,
    reject_action_label: reject_action_label as string,
    version_name_instead_of_document_name_on_upload:
      version_name_instead_of_document_name_on_upload
        ? version_name_instead_of_document_name_on_upload
        : UploadFileOption.KeepCurrent,
  };
  return { helperValues, initialValues };
};
