import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Document, FinalizationState, LockType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { DocumentSelect } from '@pro4all/shared/ui/document-select';
import { Layer } from '@pro4all/shared/ui/layer';

import { getTabIndex } from '../field-types/helpers';
import { UploaderEditorTypes } from '../types';
import {
  ContentCellWrapper,
  ContentColumnWrapper,
  HeaderWrapper,
  ResizableColumnWrapper,
} from '../wrappers';

export const VersionNameColumn = ({
  currentDocumentsInFolder,
  deleteMetaVersion,
  folderId,
  initialTabIndex,
  isProcessing,
  meta,
  noOfInputs,
  publishDocument,
  updateMetaVersion,
  zIndexResizableColumnDown,
}: Pick<
  UploaderEditorTypes,
  | 'currentDocumentsInFolder'
  | 'deleteMetaVersion'
  | 'folderId'
  | 'initialTabIndex'
  | 'isProcessing'
  | 'meta'
  | 'noOfInputs'
  | 'publishDocument'
  | 'updateMetaVersion'
  | 'zIndexResizableColumnDown'
>) => {
  const { t } = useTranslation();
  const [showDocumentSelect, setShowDocumentSelect] = useState(false);

  // Construction to resolve `closures` problem. Else it will take the id of the last rendered.
  // So if we would pass in `id` directly to `updateVersionCell`, it would always be the last rendered id.
  const [cellDocumentId, setCellDocumentId] = useState('');

  const deleteVersionCell = useCallback(
    (id: string) => {
      deleteMetaVersion && deleteMetaVersion(id);
    },
    [deleteMetaVersion]
  );

  const updateVersionCell = (selectedDocument: Document) => {
    const { id, metadataInstanceId, name, versionNumber } = selectedDocument;
    updateMetaVersion({
      documentId: cellDocumentId,
      newVersionFor: { id, metadataInstanceId: metadataInstanceId || '', name },
      versionNumber: versionNumber ? versionNumber + 1 : 1,
    });
    setShowDocumentSelect(false);
  };

  const { canUploadVersion } = useUserFolderPermissions({ folderId });

  const documentsToExclude = [] as string[];
  meta.forEach(({ version }) => {
    if (version?.newVersionFor) {
      documentsToExclude.push(version.newVersionFor.id);
    }
  });

  currentDocumentsInFolder.forEach((document) => {
    const { id, lockType, state, versionNumber } = document;
    if (
      lockType === LockType.Prostream ||
      state === FinalizationState.Finalized ||
      versionNumber === 0 ||
      !canUploadVersion(document)
    ) {
      documentsToExclude.push(id);
    }
  });

  const showDocumentSelectDialog = useCallback((id: string) => {
    setShowDocumentSelect(true);
    setCellDocumentId(id);
  }, []);

  return (
    <ResizableColumnWrapper
      defaultWidth={300}
      zIndexResizableColumnDown={zIndexResizableColumnDown}
    >
      <HeaderWrapper>{t('New version for')}</HeaderWrapper>

      <ContentColumnWrapper>
        {meta.map(({ id, version }, index) => {
          const tabIndex = getTabIndex({ index, initialTabIndex, noOfInputs });
          return (
            <VersionNameCell
              deleteVersionCell={deleteVersionCell}
              id={id}
              isProcessing={isProcessing}
              key={id}
              newVersionForName={
                version ? version.newVersionFor?.name : undefined
              }
              publishDocument={publishDocument}
              selectDocumentLabel={t('Select document')}
              showDocumentSelectDialog={showDocumentSelectDialog}
              tabIndex={tabIndex}
            />
          );
        })}
      </ContentColumnWrapper>
      {showDocumentSelect && (
        <DocumentSelect
          closeModalCallback={() => {
            setShowDocumentSelect(false);
          }}
          documentsToExclude={documentsToExclude}
          handleSelectedDocumentCallback={(selectedDocument) =>
            updateVersionCell(selectedDocument)
          }
        />
      )}
    </ResizableColumnWrapper>
  );
};

type VersionNameCellProps = {
  deleteVersionCell: (id: string) => void;
  id: string;
  newVersionForName: string | undefined;
  selectDocumentLabel: string;
  showDocumentSelectDialog: (id: string) => void;
} & Pick<UploaderEditorTypes, 'isProcessing' | 'publishDocument' | 'tabIndex'>;

const VersionNameCellComponent = ({
  deleteVersionCell,
  id,
  isProcessing,
  newVersionForName,
  publishDocument,
  selectDocumentLabel,
  showDocumentSelectDialog,
  tabIndex,
}: VersionNameCellProps) => (
  <ContentCellWrapper key={id}>
    {isProcessing && <Layer />}
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      {newVersionForName ? (
        publishDocument ? (
          newVersionForName
        ) : (
          <Box
            onClick={() => showDocumentSelectDialog(id)}
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              width: '100%',
            }}
          >
            <Box
              sx={{
                flexGrow: '1',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {newVersionForName}
            </Box>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <IconButton
                color="inherit"
                disableBorder
                iconName="close"
                onClick={(event) => {
                  event.stopPropagation();
                  deleteVersionCell(id);
                }}
                tabIndex={tabIndex}
              />
            </Box>
          </Box>
        )
      ) : (
        <Button
          onClick={() => showDocumentSelectDialog(id)}
          tabIndex={tabIndex}
        >
          {selectDocumentLabel}
        </Button>
      )}
    </Box>
  </ContentCellWrapper>
);

const skipUpdate = (
  prevProps: VersionNameCellProps,
  nextProps: VersionNameCellProps
) =>
  prevProps.isProcessing === nextProps.isProcessing &&
  prevProps.newVersionForName === nextProps.newVersionForName &&
  prevProps.tabIndex === nextProps.tabIndex;

const VersionNameCell = React.memo(VersionNameCellComponent, skipUpdate);
