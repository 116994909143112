import React from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import { Row } from '../layout/Layout';

interface HeaderProps {
  icon?: IconName;
  title: React.ReactNode | string;
}

// #[ticket 19615] We need to create more generic Icon + Heading solution
export const Header: React.FC<HeaderProps> = ({
  children,
  icon,
  title = '',
}) => (
  <StyledHeader>
    <Row>
      {icon && <StyledIcon iconName={icon} />}
      <StyledTitle variant="h3">{title}</StyledTitle>
    </Row>
    {children}
  </StyledHeader>
);

const StyledHeader = styled.header`
  padding: 1.5rem;
  background-color: ${customColors.white};

  @media (min-height: 641px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const StyledIcon = styled(Icon)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const StyledTitle = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 60px);
`;
