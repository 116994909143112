/* This context covers the privately shared links overview:
 *  - Fetch data
 *  - Revoke link
 * */

import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import {
  SharedLinkDataResponse,
  usePrivateLinkDataQuery,
  useRevokeShareLinkMutation,
  useScopedLinkDataQuery,
} from '@pro4all/graphql';
import { useShareLinkParams } from '@pro4all/shared/assets-sharing';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { ConfirmRevokeDialog } from '../feedback/ConfirmRevokeDialog';

interface SharedDocsContextValue {
  confirmRevoke: () => void;
  data: SharedLinkDataResponse;
  error: ApolloError;
  handleRevoke: () => void;
  linkId: string;
  notFoundError: boolean;
  signature: string;
  template: string;
}

export const SharedDocsContext = createContext(null);

export const useSharedDocsContext = () =>
  useContext<SharedDocsContextValue>(SharedDocsContext);

export const SharedDocsContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params, searchParams, goTo } = useRouting();
  const isDocsSharedByInvite = Boolean(
    useRouteMatch({
      path: [Routes.docsSharedByInvite, Routes.projDocsSharedByInvite],
    })
  );
  const isDocsSharedInScope = Boolean(
    useRouteMatch({
      path: [Routes.docsSharedInScope, Routes.projDocsSharedInScope],
    })
  );

  const { linkId, signature, template } = useShareLinkParams();

  const projectId = searchParams.get('projectId');
  const isProjDocsSharedByInvite = Boolean(
    useRouteMatch({
      path: Routes.projDocsSharedByInvite,
    })
  );
  const isProjDocsSharedInScope = Boolean(
    useRouteMatch({
      path: Routes.projDocsSharedInScope,
    })
  );

  const privateLink = useRouteMatch(Routes.docsSharedByInvite);
  const scopedLink = useRouteMatch(Routes.docsSharedInScope);

  /* @Todo: remove redirect once BE returns a route that is compatible with our routing logic */
  if (projectId && !isProjDocsSharedByInvite && !isProjDocsSharedInScope) {
    if (privateLink) {
      goTo('projDocsSharedByInvite', {
        params: { linkId: linkId, projectId },
        searchParams: { s: signature, t: template },
      });
    }
    if (scopedLink) {
      goTo('projDocsSharedInScope', {
        params: { linkId: linkId, projectId },
        searchParams: { s: signature, t: template },
      });
    }
  }

  const {
    data: privateLinkData,
    refetch: fetchPrivateLinkData,
    error: privateLinkError,
  } = usePrivateLinkDataQuery({
    skip: !params.linkId || !signature || !template || !isDocsSharedByInvite,
    variables: { id: params.linkId, signature, template },
  });

  const {
    data: scopedLinkData,
    refetch: fetchScopedLinkData,
    error: scopedLinkError,
  } = useScopedLinkDataQuery({
    skip: !params.linkId || !signature || !template || !isDocsSharedInScope,
    variables: { id: params.linkId, signature, template },
  });

  const [revokeLink] = useRevokeShareLinkMutation();

  const handleRevoke = () => {
    searchParams.set('action', 'revoke');
  };

  const cancelRevoke = () => {
    searchParams.delete('action');
  };

  const confirmRevoke = async () => {
    try {
      const { data: result } = await revokeLink({
        variables: {
          id: linkId,
        },
      });
      isDocsSharedByInvite ? fetchPrivateLinkData() : fetchScopedLinkData();

      if (result.revokeShareLink.success) {
        enqueueSnackbar(t('The link has been revoked'));
      } else {
        enqueueSnackbar(t('Something went wrong'));
      }
    } catch (exception) {
      enqueueSnackbar(t('Something went wrong'));
    }
    cancelRevoke();
  };

  const error = isDocsSharedByInvite ? privateLinkError : scopedLinkError;

  const value = {
    confirmRevoke,
    data: isDocsSharedByInvite
      ? privateLinkData?.privateLinkData
      : scopedLinkData?.scopedLinkData,
    error,
    handleRevoke,
    linkId,
    signature,
    template,
  };

  return (
    <SharedDocsContext.Provider value={value}>
      <ConfirmRevokeDialog
        cancelRevoke={cancelRevoke}
        confirmRevoke={confirmRevoke}
      />
      {children}
    </SharedDocsContext.Provider>
  );
};
