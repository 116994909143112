import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const NotificationRules: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M16.1318 9.53453C15.8237 9.49189 15.5091 9.46985 15.1892 9.46985C11.445 9.46985 8.40973 12.4906 8.40973 16.2168C8.40973 16.7993 8.48389 17.3645 8.62332 17.9036H3.96509C3.10312 17.9036 2.67698 16.8627 3.28714 16.2554L4.5365 15.012V10.1928C4.5365 7.2241 6.11516 4.75663 8.89475 4.1012V3.44578C8.89475 2.64578 9.54365 2 10.3475 2C11.1514 2 11.8002 2.64578 11.8002 3.44578V4.1012C14.362 4.70738 15.9132 6.87179 16.1318 9.53453ZM8.95292 18.8675C9.23572 19.5256 9.62062 20.1299 10.0878 20.6607C9.86795 20.7474 9.62868 20.7952 9.37875 20.7952C8.30372 20.7952 7.44175 19.9277 7.44175 18.8675H8.95292Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.8222 16.2167C18.8222 16.3806 18.8077 16.5348 18.7883 16.689L19.8101 17.4842C19.9021 17.5565 19.9263 17.6866 19.8682 17.7926L18.8997 19.4601C18.8416 19.5661 18.7156 19.6095 18.6043 19.5661L17.3985 19.0842C17.1467 19.2722 16.8755 19.436 16.5801 19.5565L16.3961 20.8336C16.3816 20.9493 16.2799 21.036 16.1588 21.036H14.2218C14.1007 21.036 13.9991 20.9493 13.9845 20.8336L13.8005 19.5565C13.5051 19.436 13.2339 19.277 12.9821 19.0842L11.7763 19.5661C11.6698 19.6047 11.5391 19.5661 11.481 19.4601L10.5125 17.7926C10.4543 17.6866 10.4786 17.5565 10.5706 17.4842L11.5923 16.689C11.573 16.5348 11.5584 16.3758 11.5584 16.2167C11.5584 16.0577 11.573 15.8987 11.5923 15.7444L10.5706 14.9493C10.4786 14.877 10.4495 14.7469 10.5125 14.6408L11.481 12.9734C11.5391 12.8673 11.665 12.824 11.7763 12.8673L12.9821 13.3493C13.2339 13.1613 13.5051 12.9975 13.8005 12.877L13.9845 11.5999C13.9991 11.4842 14.1007 11.3975 14.2218 11.3975H16.1588C16.2799 11.3975 16.3816 11.4842 16.3961 11.5999L16.5801 12.877C16.8755 12.9975 17.1467 13.1565 17.3985 13.3493L18.6043 12.8673C18.7108 12.8288 18.8416 12.8673 18.8997 12.9734L19.8682 14.6408C19.9263 14.7469 19.9021 14.877 19.8101 14.9493L18.7883 15.7444C18.8077 15.8987 18.8222 16.0529 18.8222 16.2167ZM13.4954 16.2167C13.4954 17.1469 14.2557 17.9035 15.1903 17.9035C16.1249 17.9035 16.8852 17.1469 16.8852 16.2167C16.8852 15.2866 16.1249 14.53 15.1903 14.53C14.2557 14.53 13.4954 15.2866 13.4954 16.2167Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
