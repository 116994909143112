import React from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentVersionState } from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { isDefined } from '@pro4all/shared/utils';

import { documentVersionStateValues } from '../records';
import { hasLabel } from '../utils';

import { TagSelect } from './TagSelect';

export const VersionStateFilter: React.FC<FilterBaseProps> = ({
  value,
  type,
  facetGroup,
  facetLoading,
}) => {
  const { t } = useTranslation();

  /* Action naming mapping for filter */
  const getActionNamingMapping = useActionNamingMapping();

  const getLabel = (facetValue: string) =>
    Object.entries(documentVersionStateValues).reduce(
      (foundLabel, [key, value]) => {
        if (value === facetValue)
          return getActionNamingMapping(key as DocumentVersionState) as string;
        return foundLabel;
      },
      ''
    );

  const options =
    facetGroup?.items
      ?.filter(isDefined)
      .map((facetItem) => ({
        id: facetItem.value,
        inputValue: facetItem.value,
        label: t(getLabel(facetItem.value)),
      }))
      .filter(hasLabel) || [];
  const ids = value.split(delimiters.multiSelectOptions);

  const initialOptions = options.filter((option) => ids.includes(option.id));

  return (
    <TagSelect
      initialOptions={initialOptions}
      loading={facetLoading}
      options={options}
      showInputFilter={false}
      type={type}
      value={value}
    />
  );
};
