import React from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { StandardItemKey, ValueTypeName } from '@pro4all/graphql';
import { DndTypes } from '@pro4all/shared/config';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { LabelCard } from '@pro4all/shared/ui/label-card';

import { useFieldConfig } from '../../configs/useFieldConfig';
import { fieldCardsGlobalInfo } from '../fieldCardsGlobalInfo';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import { LabelCardType } from './configureDownloadNameReducer';

export const AvailableMetaDataFieldCard = ({
  field,
}: {
  field: LabelCardType;
}) => {
  const { t } = useTranslation();
  const { insertSegment } = useConfigureDownloadNameContext();
  const fieldConfig = useFieldConfig();

  let label = field.label;
  let startIcon = fieldConfig[field.type]?.icon;
  const isStandardItem = field.type === ValueTypeName.StandardItem;
  const getActionNamingMapping = useActionNamingMapping();

  if (isStandardItem) {
    const nameStandardFieldDefinition = label as StandardItemKey;
    const cardData = fieldCardsGlobalInfo[nameStandardFieldDefinition];
    label = t(
      cardData.label,
      getActionNamingMapping(nameStandardFieldDefinition)
    );
    startIcon = cardData.icon;
  }

  const tempId = uuid();
  const segment = {
    deleteId: tempId,
    id: field.id,
    type: field.segmentType,
  };

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: segment,
    type: DndTypes.NOT_INCLUDED_FIELD,
  });

  if (isDragging) return null;

  return drag(
    <div>
      <LabelCard
        colorBackground="pendingBg"
        colorFont="pendingHover"
        label={label}
        onClick={() =>
          insertSegment({
            segment,
          })
        }
        startIcon={startIcon}
      />
    </div>
  );
};
