import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import {
  HierarchicalList,
  Hierarchy,
  useHierarchicalListQuery,
} from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import {
  HierarchyEditor,
  useHierarchyEditorContext,
  useImportFromExcel,
} from '@pro4all/shared/hierarchy-editor';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FormFooter,
  FormikForm,
  FormikInput,
  Loader,
} from '@pro4all/shared/ui/general';

import * as Styled from './HierarchicalListForm.styles';
import { useHierarchicalListFormConfig } from './useHierarchicalListFormConfig';
import { useSubmit } from './useSubmit';

export const HierarchicalListForm = ({
  includeOrgItems,
  selectedHierarchicalList,
}: {
  includeOrgItems: boolean;
  selectedHierarchicalList?: HierarchicalList;
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const onClose = () => searchParams.clear();

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const createMode = searchParams.is('action', 'createHierarchicalList');

  const { fillInitialItems } = useHierarchyEditorContext();

  const { excelInputElement, handleButtonClick } = useImportFromExcel();

  const id = selectedHierarchicalList ? selectedHierarchicalList.id : '';
  const { data, loading } = useHierarchicalListQuery({
    fetchPolicy: 'cache-and-network',
    skip: !id,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data) {
      // Initially fill the provider the moment we have hierarchy data.
      fillInitialItems({
        currentBreadcrumbs: [],
        items: data.hierarchicalList.hierarchy,
      });
    }
  }, [data, fillInitialItems]);

  const inititalValues = {
    name: selectedHierarchicalList ? selectedHierarchicalList.name : '',
  };

  const onSubmit = useSubmit({ includeOrgItems, selectedHierarchicalList });

  const { getField, validationSchema } = useHierarchicalListFormConfig();
  const nameField = getField('name');

  const dataTestId = selectedHierarchicalList
    ? 'edit-hierarchical-list-form'
    : 'create-hierarchical-list-form';

  if (loading) return null;

  return (
    <Styled.HierarchicalListForm data-testid={dataTestId}>
      <Formik
        enableReinitialize
        initialValues={inititalValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting }) => (
          <FormikForm>
            <Styled.FormWrapper>
              <Styled.TitleBarWrapper>
                <Styled.TitleInput>
                  <FormikInput
                    autoFocus
                    label={nameField.label}
                    name={nameField.name}
                  />
                </Styled.TitleInput>
                <Styled.ActionZone>
                  <Styled.ActionButton
                    aria-label={t('Import Excel')}
                    data-testid="import-excel"
                    onClick={() => handleButtonClick({ item: null })}
                    startIcon="formatShapes"
                  >
                    {t('Import Excel')}
                  </Styled.ActionButton>
                  {excelInputElement}
                </Styled.ActionZone>
              </Styled.TitleBarWrapper>
              {!data && !createMode ? (
                <Loader />
              ) : (
                <Styled.ItemListWrapper ref={scrollableDivRef}>
                  <HierarchyEditor<Hierarchy>
                    scrollableDivRef={scrollableDivRef}
                  />
                </Styled.ItemListWrapper>
              )}
            </Styled.FormWrapper>
            <FormFooter
              disableSubmit={isSubmitDisabled({
                errors,
                isSubmitting,
              })}
              onClose={onClose}
              pt={2}
              sticky
              submitLabel={t('Save and close')}
            />
          </FormikForm>
        )}
      </Formik>
    </Styled.HierarchicalListForm>
  );
};
