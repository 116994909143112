import React from 'react';
import { useDrop } from 'react-dnd';

import { FieldDefinition, TemplateService } from '@pro4all/graphql';
import { DndField, DndTypes } from '@pro4all/shared/config';

import { useMetaDataContext } from '../../views/MetaDataContext';

import { NewCardField } from './NewCardField';
import { NewCardSection } from './NewCardSection';
import { StyledDropZone, StyledNewCards } from './Styles';
import { useFieldContext } from './TemplateMutationContext';

export const NewCards = ({
  parentSection,
}: {
  parentSection?: FieldDefinition;
}) => {
  const { templateService } = useMetaDataContext();

  const isQualityControl = templateService === TemplateService.QualityControl;

  const { insertField } = useFieldContext();

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [
      DndTypes.INCLUDED_FIELD,
      DndTypes.INCLUDED_SECTION,
      DndTypes.NOT_INCLUDED_FIELD,
    ],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: (field: DndField, monitor) => {
      if (!monitor.didDrop()) {
        // Drop of an field or section.
        insertField({
          fieldToInsert: field.fieldDefinition,
          move: field.move,
          nextField: null,
          parentSection,
        });
      }
    },
  });

  // Todo: for now we do not want a 'New field'-card for documents, because meta data for documents is not behind a feature flag.
  return drop(
    <div>
      {hoversOverCurrent && <StyledDropZone />}
      <StyledNewCards>
        {isQualityControl && <NewCardSection parentSection={parentSection} />}
        <NewCardField parentSection={parentSection} />
      </StyledNewCards>
    </div>
  );
};
