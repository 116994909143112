import styled from 'styled-components';

import { Autocomplete, InputAdornment } from '@pro4all/shared/mui-wrappers';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

// @TODO: SearchBarWrap display should be inline-flex, will get changed back again in the future when QC search design matches document search design
export const SearchBarWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

export const SearchBarAutocomplete = styled(Autocomplete)`
  && {
    background: ${({ theme }) => theme.palette.grey['200']};
    border-radius: ${StylingDefaults.borderRadiusSmall};
    border: none;
  }
  .MuiInputBase-root {
    border-radius: ${StylingDefaults.borderRadiusSmall};
    border: none;
  }
  fieldset {
    border: none;
  }
`;

export const StartAdornment = styled(InputAdornment)`
  && {
    margin: 0 0.5rem;
  }
`;

export const CustomIconContainer = styled.div`
  margin-right: 0.5rem;
`;

export const OptionWrap = styled.div`
  display: flex;
  flex: 1;
  gap: 0.5rem;
`;

export const Path = styled.div`
  font-weight: 400;
  color: ${customColors.grey600};
  font-style: italic;
  white-space: nowrap;
`;

export const DeleteOptionIcon = styled(Icon).attrs({
  htmlColor: '#585858',
  iconName: 'cancel',
})`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const ListBoxButton = styled.span`
  display: inline-flex;
  padding: 6px 1rem;
  font-weight: 500;
  color: ${customColors.grey900};
`;

export const ClearButton = styled.span`
  cursor: pointer;
  color: ${customColors.grey700};
`;
