import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoSubscriptionAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="trust"
      text={t(
        'Your current license doesn’t include this part in Prostream. To experience to full potential of Prostream, upgrade your license.'
      )}
      title={t('License upgrade required')}
    />
  );
};
