import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  InstanceResourceType,
  QualityControlInstance,
  QualityControlInstanceType,
  SearchDocument,
  SearchQuery,
  SearchResult,
  useCreateQualityControlInstanceMutation,
  useObjectIncludeQuery,
} from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';

import { useMapLinkingContext } from './MapLinkingContext';
import { SelectTemplateFields } from './types';

export const useSubmit = ({
  onCloseMenu,
  visualContextId,
}: {
  onCloseMenu: () => void;
  visualContextId: string;
}) => {
  const {
    searchParams,
    params: { projectId, objectId },
  } = useRouting();

  const { data: objectData } = useObjectIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !objectId,
    variables: { id: objectId, includeProjectId: true },
  });

  const objectProjectId = objectData?.object?.projectId;

  const page = Number(searchParams.get('page'));
  const { coordinates } = useMapLinkingContext();
  const [createInstance] = useCreateQualityControlInstanceMutation();

  const { showSingleError, showMutationMessage } = useShowMessages();
  const isDrawingSearchEnabled = useFeatureFlag('qc-search-freetext');
  const { enqueueSnackbar } = useSnackbar();

  const { addItems } = useOptimisticResponseContext<QualityControlInstance>();

  const { documents = [] } = useSnagFormSearchContext();

  const { t } = useTranslation();

  const onSubmit = async (values: SelectTemplateFields, taskId?: string) => {
    const { template } = values;
    const type = template.type as QualityControlInstanceType;

    try {
      const resourceType = taskId
        ? InstanceResourceType.Task
        : InstanceResourceType.VisualContext;
      const response = await createInstance({
        variables: {
          linkToTaskId: taskId,
          projectId: objectProjectId ?? projectId,
          resourceId: taskId ?? visualContextId,
          resourceType: resourceType as InstanceResourceType,
          templateId: template.id,
          visualContext: {
            page: page !== 0 ? page : 1,
            visualContextId,
            x: coordinates.x,
            y: coordinates.y,
          },
        },
      });
      const { instanceId } = response.data?.createQualityControlInstance ?? {};
      addItems([
        {
          id: instanceId,
          page: page,
          reference: '66',
          type,
          x: coordinates.x,
          y: coordinates.y,
        },
      ]);

      if (isDrawingSearchEnabled) {
        let searchRetries = 0;
        const maxRetries = 3;

        const interval = setInterval(async () => {
          searchRetries += 1;
          const [searchResponse] = await client.refetchQueries({
            include: [SearchDocument],
            optimistic: true,
          });

          const searchResponseData = searchResponse?.data as SearchQuery;
          const searchResults = searchResponseData?.search
            ?.searchResults as SearchResult[];

          if (documents.length !== searchResults?.length) {
            clearInterval(interval);
          }

          if (searchRetries >= maxRetries) {
            clearInterval(interval);
            enqueueSnackbar(
              t(
                "The added snag is hidden because it doesn't match the active filter(s)."
              ),
              {
                variant: 'error',
              }
            );
          }
        }, 1000);
      }

      onCloseMenu();
      searchParams.set({ action: 'editResult', id: instanceId });
      showMutationMessage({
        action: MessageAction.Create,
        entityType: EntityTypeTranslation.Result,
        name: template.label,
      });
      return response;
    } catch (e) {
      showSingleError(e);
    }
  };

  return onSubmit;
};
