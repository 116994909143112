import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

import { MuiAutompleteStyles } from './MuiAutocompleteStyles';
import { RbcCalendarStyles } from './RbcCalendarStyles';
import { Reset } from './Reset';
import { ScrollbarStyles } from './ScrollbarStyles';

const HeadLink: React.FC<Partial<HTMLLinkElement>> = ({ href, rel }) => {
  useEffect(() => {
    const link = document.createElement('link');
    Object.assign(link, { href, rel });
    document.head.appendChild(link);
  });

  return null;
};

export const GlobalStyles = () => {
  const StyledGlobalStyles = createGlobalStyle`
    ${ScrollbarStyles}
    ${MuiAutompleteStyles}
    ${RbcCalendarStyles}
`;

  return (
    <>
      <HeadLink
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap"
        rel="stylesheet"
      />
      <Reset />
      <StyledGlobalStyles />
    </>
  );
};

export default GlobalStyles;
