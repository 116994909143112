import React from 'react';
import styled from 'styled-components';

import { Menu, MenuProps } from '@pro4all/shared/mui-wrappers';
import { StylingDefaults } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    transformOrigin={{
      horizontal: 280,
      vertical: -50,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      '& .MuiCard-root': {
        cursor: 'default',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      padding: '0',
    },
    boxShadow: StylingDefaults.boxShadow,
    maxHeight: 328,
    minWidth: 350,
  },
}));

export const StyledButton = styled(Button)`
  && {
    border-radius: 5px;
    padding: 5px;
    min-width: 50px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
