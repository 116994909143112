import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const SmartFolderOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M12 6h8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2l.01-12c0-1.1.89-2 1.99-2h5.17c.53 0 1.04.21 1.42.59L12 6zM4 17c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v8z" />
    <path d="M14.071 10.5314v3.75a.418.418 0 00.4167.4167h.8333v2.9791c0 .2125.2792.2875.3875.1042l2.1625-3.7083c.1625-.2792-.0375-.625-.3583-.625h-.9417l1.0375-2.7709c.1042-.2708-.0958-.5625-.3875-.5625h-2.7333a.418.418 0 00-.4167.4167z" />
  </SvgIcon>
);
