import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/general';

import { useProjectObjects } from './useProjectObjects';

export const UnlinkProject: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { searchParams } = useRouting();
  const { unlinkProject, projectObject } = useProjectObjects();

  const handleSubmit = async () => {
    if (!projectObject) return;
    await unlinkProject(projectObject.id);
    searchParams.delete('action');
  };

  return (
    <Dialog
      buttonColor={theme.palette.error.main}
      iconName="warning"
      name="filesCannotBeSharedDialog"
      onClose={() => searchParams.delete('action')}
      onConfirm={handleSubmit}
      open={searchParams.is('action', 'unlinkProjectObject')}
      title={t('Remove from project')}
    >
      <Trans
        components={{ strong: <strong /> }}
        defaults="Are you sure you want to remove <strong>{{- objectName}}</strong> from project <strong>{{- projectName}}</strong>?"
        i18nKey="confirmDeleteProjectObject"
        values={{
          objectName: projectObject?.name,
          projectName: projectObject?.project?.name,
        }}
      />
    </Dialog>
  );
};
