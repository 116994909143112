import React from 'react';

import { useGenericContext } from '@pro4all/shared/providers';

import { usePermissionsEntityMatrixContext } from './entity-context/PermissionsEntityMatrixProvider';
import { BreakInheritanceForEntitySwitch } from './BreakInheritanceForEntitySwitch';

export interface BreakInheritanceWrapperProps {
  entityId: string;
}

/**
 * We use this wrapper to achieve optimal rendering for BreakInheritanceForFolderSwitch.
 * If we don't, ALL switches will be re-rendered if a user clicks on whatever change for whatever folder.
 * Which would have a major bad impact on performance in case of thousands of folders.
 */
export const BreakInheritanceForEntitySwitchWrapper = ({
  entityId,
}: BreakInheritanceWrapperProps) => {
  const { state } = usePermissionsEntityMatrixContext();
  const { displayPermissions } = state;
  const currentEntity = displayPermissions.find(
    (perm) => perm.entityId === entityId
  );

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  return showFinalPermissions ? null : (
    <BreakInheritanceForEntitySwitch
      checked={currentEntity?.breakInheritance || false}
    />
  );
};
