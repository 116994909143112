import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useInviteExternalsModalConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Email', // i18n
      email: true,
      name: 'externalUsersToAdd',
      required: true,
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Project Role', // i18n
      name: 'roleId',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Message', // i18n
      name: 'message',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
