import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoLinkedSnags: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="trust"
      text={t(
        'Place a new Snag or link an existing Snag to give more information to an answer of a question.'
      )}
      title={t('No linked Snags')}
    />
  );
};
