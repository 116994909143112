import React, { useMemo } from 'react';

import { Task, TaskType, useMyTasksQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import {
  TasksMain,
  TaskTablePostFix,
  usePredefinedFilters,
} from '@pro4all/shared/ui/tasks';

import { TasksActionBar } from './TasksActionBar';

export const MyTasks: React.FC = () => {
  const { data, loading } = useMyTasksQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
  });

  const tasks = useMemo(() => data?.myTasks || [], [data?.myTasks]) as Task[];

  const predefinedFilters = usePredefinedFilters(true);

  return (
    <DataViewContextProvider
      initialView={DataViewType.Table}
      viewKey="my-tasks"
      views={[DataViewType.Table, DataViewType.Charts, DataViewType.Calendar]}
    >
      <TasksMain
        TasksActionBar={
          <TasksActionBar
            dataViewToggle
            predefinedFilters={predefinedFilters}
          />
        }
        hideBorder
        hideProject={false}
        hideUser
        loading={loading}
        noTasksText="Have they been completed? On a roll! Need new ones? Create them."
        noTasksTitle="You don't have any document tasks yet"
        postfix={TaskTablePostFix.ALL}
        tasks={tasks}
        typeFilter={[
          TaskType.Document,
          TaskType.QualityControl,
          TaskType.Resolve,
          TaskType.Tbq,
          TaskType.TbqResolve,
          TaskType.TbqScan,
        ]}
      ></TasksMain>
    </DataViewContextProvider>
  );
};
