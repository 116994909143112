import React from 'react';
import { useTranslation } from 'react-i18next';

import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { isDefined } from '@pro4all/shared/utils';

import { TagSelect } from './TagSelect';

// This is how BE maps the template types for QC, there is a difference
// with our TemplateType is mapped to the BE template type
// So until I get all the info of how and why I rather just be on the safe side
// And create a new enum that matches the BE template type
export enum BackendTemplateType {
  Document = 0,
  Snag = 1,
  Form = 2,
  General = 3,
  TBQ = 4,
}

// A mapping object to convert BackendTemplateType numbers to strings
export const MappedTemplateType: { [key: number]: string } = {
  [BackendTemplateType.Document]: 'Document',
  [BackendTemplateType.Snag]: 'Snag',
  [BackendTemplateType.Form]: 'Form',
  [BackendTemplateType.General]: 'General',
  [BackendTemplateType.TBQ]: 'TBQ',
};

export const QCTypeFilter: React.FC<FilterBaseProps> = ({
  value,
  type,
  facetGroup,
  facetLoading,
}) => {
  const { t } = useTranslation();
  const options: Option[] =
    facetGroup?.items?.filter(isDefined).map((facetItem) => ({
      iconName: 'personOutline',
      id: facetItem.value,
      inputValue: facetItem.value,
      label:
        t(MappedTemplateType[Number(facetItem.value || 0)]) || facetItem.value,
    })) || [];

  const labels = value
    .split(delimiters.multiSelectOptions)
    .map((label) => t(MappedTemplateType[Number(label)]));
  const initialOptions = options.filter((option) =>
    labels.includes(option.label)
  );

  return (
    <TagSelect
      initialOptions={initialOptions}
      loading={facetLoading}
      options={options}
      type={type}
      value={value}
    />
  );
};
