import React from 'react';

import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDownloadZipButtonProps } from './actions/useDownloadZipButtonProps';

export const AttachmentsActionBar: React.FC = () => {
  const { downloadZipButtonProps } = useDownloadZipButtonProps();

  return (
    <TableActionBar
      dataTestid="shared-attachments-action-bar"
      mainActions={[downloadZipButtonProps]}
      search
    />
  );
};
