import { useBatchQualityControlInstancesIncludeLazyQuery } from '@pro4all/graphql';

export const useBatchFetchResultsLazy = (ids: string[]) => {
  const result = useBatchQualityControlInstancesIncludeLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ids,
      includeCreatedBy: true,
      includeName: true,
      includeVisualContext: true,
    },
  });
  return result;
};
