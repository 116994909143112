import { useEffect, useRef, useState } from 'react';

import { SignatureService } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useFetchSignature = (signatureId: string) => {
  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');
  const [loading, setLoading] = useState(true);
  const [signature, setSignature] = useState('');
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setLoading(true);

    const getSignature = async () => {
      const signatureUrl = signatureId
        ? await SignatureService.getSignature({
            instanceId,
            signatureId,
          })
        : '';
      if (isMounted.current) {
        setSignature(signatureUrl);
        setLoading(false);
      }
    };

    getSignature();

    return () => {
      isMounted.current = false;
    };
  }, [instanceId, signatureId]);

  return { loading, signature };
};
