import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { EditorCheckbox } from '../other';
import { UploaderEditorTypes } from '../types';

type HeaderWrapperProps = PropsWithChildren<{
  hasErrorCells?: boolean;
  hasWarningCells?: boolean;
  showCheckbox?: boolean;
}> &
  Pick<
    UploaderEditorTypes,
    'allDocumentsSelected' | 'selectDocumentsViaHeader'
  > &
  Partial<Pick<UploaderEditorTypes, 'tabIndex'>>;

export const HeaderWrapper = ({
  allDocumentsSelected,
  children,
  hasErrorCells = false,
  hasWarningCells = false,
  selectDocumentsViaHeader,
  showCheckbox = false,
  tabIndex,
}: HeaderWrapperProps) => {
  const bottomBorderColor = hasErrorCells
    ? customColors.errorAlert
    : hasWarningCells
    ? customColors.warning
    : customColors.grey400;

  return (
    <Box
      sx={{
        background: customColors.white,
        display: 'flex',
        flexDirection: 'column',
        height: '94px',
        justifyContent: 'flex-end',
        position: 'sticky',
        top: '0px',
        zIndex: 1,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          mr: 1,
          overflow: 'hidden',
          pt: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {showCheckbox && selectDocumentsViaHeader && (
          <EditorCheckbox
            checked={allDocumentsSelected || false}
            onChangeCallback={selectDocumentsViaHeader}
            tabIndex={tabIndex}
          />
        )}
        {children}
      </Box>

      {/* Bottom border */}
      <Box sx={{ pb: 2 }}>
        <Box
          sx={{
            borderBottom: `2px solid ${bottomBorderColor}`,
            height: '2px',
          }}
        />
      </Box>
    </Box>
  );
};
