import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { InviteExistingUsersForm } from './InviteExistingUsersForm';

export const InviteExistingUsersSidebar: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const isOpen = searchParams.is('action', 'inviteExistingUsers');

  const handleClose = () => searchParams.clear();

  return (
    <Sidebar onClose={handleClose} open={isOpen} wide>
      <Sidebar.Header title={t('Invite existing users')} />
      {isOpen && <InviteExistingUsersForm onClose={handleClose} />}
    </Sidebar>
  );
};
