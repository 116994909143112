import { useSubscriptionHelpers } from '@pro4all/settings/ui/src/subscription';
import { useSideNavContext } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';

import { SubscriptionCardNoActive } from './SubscriptionCardNoActive';
import { SubscriptionCardTrial } from './SubscriptionCardTrial';
import { SubscriptionCardWarning } from './SubscriptionCardWarning';

export const SubscriptionWarning = () => {
  const { isExpired, isTrial } = useSubscriptionHelpers();
  const { projectId } = useRouting().params;

  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  if (projectId) return null; // We do not display the subscription warning in a project context.

  if (!isSideNavOpen && (isTrial() || isExpired))
    return <SubscriptionCardWarning />;

  if (isTrial() && !isExpired) return <SubscriptionCardTrial />;

  if (isExpired) return <SubscriptionCardNoActive />;

  return null;
};
