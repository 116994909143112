import { ApolloCache } from '@apollo/client';

import {
  Message,
  MessageBranch,
  MessageBranchDocument,
  MessageInboxDocument,
  MessageStatus,
  Reference,
  SentOrInbox,
} from '@pro4all/graphql';

interface OptimisticUpdateOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>;
  data: Message;
  messageId: string;
  optimisticTables: SentOrInbox[];
  organizationId: string;
  projectId: string | undefined;
  replyId?: string | null;
  userDisplayName: string;
}

export const handleOptimisticUpdate = ({
  cache,
  data,
  organizationId,
  projectId,
  userDisplayName,
  messageId,
  replyId,
  optimisticTables,
}: OptimisticUpdateOptions) => {
  optimisticTables.forEach((type) => {
    const inboxQuery: { messageInbox: Message[] } | null = cache.readQuery({
      query: MessageInboxDocument,
      variables: { organizationId, projectId, sentOrInbox: type },
    });

    const includeRefId = (ref: Reference): Reference => ({
      ...ref,
      referenceData: null,
      referenceId: null,
    });

    const references = data.references?.map(includeRefId) || [];

    const optimisticMessage: Message = {
      ...data,
      bcc: [],
      cc: [],
      createdAt: new Date().toISOString(),
      createdBy: { ...data.createdBy, firstName: '', lastName: '' },
      read: data.status === MessageStatus.Draft ? true : false,
      references,
      subject: data.subject || '',
      to: [],
    };

    const filteredMessages = inboxQuery?.messageInbox?.filter(
      (message) => message.id !== data.id
    );

    const newMessageInbox = [optimisticMessage, ...(filteredMessages || [])];

    cache.writeQuery({
      data: {
        messageInbox: newMessageInbox,
      },
      query: MessageInboxDocument,
      variables: { organizationId, projectId, sentOrInbox: type },
    });

    const existingBranch: { messageBranch: MessageBranch } | null =
      cache.readQuery({
        query: MessageBranchDocument,
        variables: { authorName: userDisplayName, messageId, projectId },
      });

    if (replyId) {
      const original: Message | null =
        inboxQuery?.messageInbox?.find((message) => message.id === replyId) ||
        null;

      const replyBranch: MessageBranch = {
        id: existingBranch?.messageBranch.id || 'new',
        main: optimisticMessage,
        original,
        previous: null,
        replyAll: null,
        replyId: null,
      };

      cache.writeQuery({
        data: { messageBranch: replyBranch },
        query: MessageBranchDocument,
        variables: { authorName: userDisplayName, messageId, projectId },
      });
    } else {
      const newMessageBranch: MessageBranch = {
        id: 'new',
        main: optimisticMessage,
        original: null,
        previous: null,
        replyAll: null,
        replyId: null,
      };

      cache.writeQuery({
        data: { messageBranch: newMessageBranch },
        query: MessageBranchDocument,
        variables: { authorName: userDisplayName, messageId, projectId },
      });
    }
  });
};
