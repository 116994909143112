import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  LoadQueriesFragmentFragmentDoc,
  SavedSearch,
  useEditQueryMutation,
} from '@pro4all/graphql';

export const useOptimisticEdit = (id?: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [editQueryMutation] = useEditQueryMutation({
    onCompleted: () => {
      enqueueSnackbar(t('Search saved successfully'), { variant: 'success' });
    },
    onError: () =>
      enqueueSnackbar(t('Something went wrong'), { variant: 'error' }),
    optimisticResponse: {
      editQuery: {
        errors: [],
        success: true,
      },
    },
    update: (cache, result, options) => {
      if (!id) return;
      const data: SavedSearch | null = cache.readFragment({
        fragment: LoadQueriesFragmentFragmentDoc,
        id: `SavedSearch:${id}`,
        optimistic: true,
      });
      if (!options?.variables) return;
      const { variables } = options;

      if (data) {
        cache.writeFragment({
          data: {
            ...data,
            name: variables.name || data.name,
            notificationDays:
              variables.notificationDays || data.notificationDays,
            notificationTimes:
              variables.notificationTimes || data.notificationTimes,
            notificationsEnabled:
              variables.notificationsEnabled !== undefined
                ? variables.notificationsEnabled
                : data.notificationsEnabled,
          },
          fragment: LoadQueriesFragmentFragmentDoc,
          id: `SavedSearch:${id}`,
        });
      }
    },
  });

  return editQueryMutation;
};
