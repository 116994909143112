import { useTranslation } from 'react-i18next';

import { Task } from '@pro4all/graphql';
import { ChartOption } from '@pro4all/shared/ui/charts';

export const useTimeClusterOptionsTasks = () => {
  const { t } = useTranslation();

  const timeClusterOptions: ChartOption[] = [
    {
      id: 'completedAt',
      label: t('Completed at'),
      valueResolver: (task: Task) => task.completedAt,
    },
    {
      id: 'createdAt',
      label: t('Created on'),
      valueResolver: (task: Task) => task.createdAt,
    },
    {
      id: 'endTime',
      label: t('Deadline'),
      valueResolver: (task: Task) => task.endTime,
    },
  ];

  return timeClusterOptions;
};
