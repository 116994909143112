import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageScreenTooSmall: React.FC = () => {
  const { t } = useTranslation();

  //TODO: don't forget to add the translations after we agreed on a text with the PO's
  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Please increase the size of your window or view the page on a device with a bigger screen'
      )}
      title={t(
        'This page is not suitable for the size of your current browser window'
      )}
    />
  );
};
