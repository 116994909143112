import { useTranslation } from 'react-i18next';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { DrawingPageProps } from '@pro4all/shared/types';

import {
  DrawingContainer,
  DrawingHeader,
  DrawingLabel,
  DrawingList,
  DrawingTitle,
  FirstPage,
  FollowUpPage,
} from './elements';

const DrawingPage = ({
  group,
  hasFirstPageBeenPrinted,
  reportDrawingHtml,
  reportOptions,
}: DrawingPageProps) => {
  const { t } = useTranslation();

  const content = (
    <>
      <DrawingHeader>
        <DrawingList
          compactLists={reportOptions.compactLists}
          inlineLists={reportOptions.inlineLists}
          labelWidth={reportOptions.inlineLists ? 30 : 40}
        >
          <DrawingTitle>{group.visualContextName}</DrawingTitle>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
            }}
          >
            <DrawingLabel>
              {`${t('Snags')}: `}
              {
                group.instances.filter(
                  (instance) =>
                    instance.type === QualityControlInstanceType.Snag
                ).length
              }
            </DrawingLabel>
            <DrawingLabel>
              {`${t('Forms')}: `}
              {
                group.instances.filter(
                  (instance) =>
                    instance.type === QualityControlInstanceType.Form
                ).length
              }
            </DrawingLabel>
          </div>
        </DrawingList>
      </DrawingHeader>
      <DrawingContainer>
        <div
          className="drawing-container"
          dangerouslySetInnerHTML={{
            __html: reportDrawingHtml,
          }}
          id={`drawing-container-${group.visualContextId}${group.page}`}
        />
      </DrawingContainer>
    </>
  );

  return hasFirstPageBeenPrinted ? (
    <FollowUpPage>{content}</FollowUpPage>
  ) : (
    <FirstPage>{content}</FirstPage>
  );
};

export default DrawingPage;
