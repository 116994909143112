import React from 'react';

import { Group } from '@pro4all/graphql';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@pro4all/shared/mui-wrappers';
import { Chip } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';

const GroupListMobile: React.FC<{
  groups: Group[];
  onRowClick: (group: Group) => void;
}> = ({ groups, onRowClick }) => (
  <>
    <Divider />
    <List sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
      {groups &&
        groups.map((group) => (
          <ListItem
            dense
            divider
            key={group.id}
            onClick={() => {
              onRowClick(group);
            }}
          >
            <ListItemIcon>
              <Icon iconName="group" />
            </ListItemIcon>

            <ListItemText primary={group.displayName} />
            <IconButton edge="end" sx={{ gap: 1 }}>
              <Chip icon="personOutline" label={group.membersCount}></Chip>
              <Chip icon="group" label={group.subgroups?.length || 0}></Chip>
            </IconButton>
          </ListItem>
        ))}
    </List>
  </>
);

export default GroupListMobile;
