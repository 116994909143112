import React from 'react';

import { MiddleEllipsis } from '@pro4all/shared/ui/general';

import type { AnswerComponent } from '../AnswerView';

export const TextField: AnswerComponent = ({ children }) => {
  if (!children) {
    return null;
  }

  return <MiddleEllipsis text={children} width="100%"></MiddleEllipsis>;
};
