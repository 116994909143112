import { datadogRum } from '@datadog/browser-rum';

import { useOrganizationContext } from '@pro4all/organization/context';
import { isE2E } from '@pro4all/shared/utils';

export function init() {
  const version = process.env.NX_BUILD_VERSION;

  if (!version) return;

  datadogRum.init({
    actionNameAttribute: 'data-testid',
    allowedTracingUrls: [process.env.NX_GRAPHQL_API],
    applicationId: '82d20e1c-2a77-44cd-9aad-646555c7ba2d',
    clientToken: 'pub4c46535543b1e349d49a7a58197256bb',
    defaultPrivacyLevel: 'mask',
    env: window.location.hostname,
    service: 'prostream front-end',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.eu',
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version,
  });
}

let identified: null | string = null;

export function useIdentify() {
  const skip = isE2E();
  const { meData, userDisplayName, userEmail, userId } =
    useOrganizationContext();

  if (!skip && meData && !identified) {
    datadogRum.setUser({ email: userEmail, id: userId, name: userDisplayName });
    datadogRum.startSessionReplayRecording();
    identified = userId;
  }
}
