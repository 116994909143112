import styled from 'styled-components';

import { InputAdornment } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const InputWrap = styled.div`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: 0;
`;

export const TagListWrap = styled.div`
  max-height: 204px;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: 0;
  max-width: 326px;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  && {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div`
  height: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${customColors.grey300};
    color: ${customColors.grey900};
  }
`;
