import { MetaData } from '../../useUploaderEditorReducer';

export const useResetMetaData = () => {
  const getMetaData = ({ metaData }: { metaData: MetaData[] }) => {
    const metaDataUpdated = metaData.map((field) => {
      const { isUpdatedManually, value, valueInitial } = field;

      // 1. If the user removes the mapped document (New version for column) the meta data will be removed.
      // 2. However if the user did change the meta data in the meantime (isUpdatedManually = true), the meta data will NOT be removed if the user removes the mapped document.

      return {
        ...field,
        value: isUpdatedManually
          ? value
          : value === valueInitial
          ? ''
          : valueInitial,
        valueInitial: isUpdatedManually
          ? valueInitial
          : value === valueInitial
          ? ''
          : valueInitial,
      };
    });

    return metaDataUpdated;
  };

  return getMetaData;
};
