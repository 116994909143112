import { State } from '../useUploaderEditorReducer';

export const setTypingFromHeaderAction = ({
  payload,
  state,
}: {
  payload: boolean;
  state: State;
}) => ({
  ...state,
  isTypingFromHeader: payload,
});
