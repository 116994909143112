import { IntegrationType } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useIntegrationConfig } from '../config/useIntegrationConfig';

export const useGetIntegrationTypeData = () => {
  const { searchParams } = useRouting();
  const integrationConfig = useIntegrationConfig();

  const integrationType = searchParams.get(
    'integrationType'
  ) as IntegrationType;

  const integrationId = searchParams.get('id') as string;

  const integrationTypeLabel = integrationType
    ? integrationConfig[integrationType]?.label
    : '';

  const integrationTypeIcon = integrationType
    ? integrationConfig[integrationType]?.icon
    : undefined;

  return {
    integrationId,
    integrationType,
    integrationTypeIcon,
    integrationTypeLabel,
  };
};
