import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  TextField as TextFieldSharedComponent,
  TextFieldProps,
} from '@pro4all/shared/ui/general';

import { UploaderEditorTypes } from '../types';

import { usePreventLoseCursorFocus } from './usePreventLoseCursorFocus';

interface Props
  extends TextFieldProps,
    Pick<
      UploaderEditorTypes,
      | 'errorString'
      | 'isHeader'
      | 'isTypingFromHeader'
      | 'setTypingFromHeader'
      | 'updateProvider'
      | 'warningString'
    > {
  type?: 'number' | 'text';
}

const EditorTextFieldComponent = ({
  errorString,
  isHeader = false,
  isTypingFromHeader,
  label,
  name,
  tabIndex,
  type = 'text',
  setTypingFromHeader,
  updateProvider,
  value,
  warningString,
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value);
  const { inputRef } = usePreventLoseCursorFocus({
    errorString,
    isTypingFromHeader,
    warningString,
  });

  // In case value is changed in the provider, we have to set the updated value else it won't re-render.
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const debouncedUpdateProvider = useDebouncedCallback((value) => {
    updateProvider(value);
  }, 300);

  const handleChange: TextFieldProps['onChange'] = (event) => {
    setTypingFromHeader(isHeader);
    const value = event.target.value;
    debouncedUpdateProvider(value);
    setCurrentValue(value);
  };

  return (
    <TextFieldSharedComponent
      error={errorString ? true : false}
      inputProps={{ tabIndex }}
      inputRef={inputRef}
      label={label}
      name={name}
      onChange={handleChange}
      tooltipTitle={errorString || warningString}
      type={type}
      value={currentValue}
      warning={warningString ? true : false}
    />
  );
};

const skipUpdate = (prevProps: Props, nextProps: Props) =>
  prevProps.value === nextProps.value &&
  prevProps.errorString === nextProps.errorString &&
  prevProps.tabIndex === nextProps.tabIndex &&
  prevProps.warningString === nextProps.warningString;

export const EditorTextField = React.memo(EditorTextFieldComponent, skipUpdate);
