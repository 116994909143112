import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const SectionCondition: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M4.5 4H8.79C9.24 4 9.46 4.54 9.15 4.84L7.71 6.28L12.71 11.29C12.89 11.48 13 11.74 13 12V19C13 19.55 12.55 20 12 20C11.45 20 11 19.55 11 19V12.4L6.29 7.7L4.85 9.14C4.54 9.46 4 9.24 4 8.79V4.5C4 4.22 4.22 4 4.5 4ZM16.2902 6.29L14.8502 4.85C14.5402 4.54 14.7602 4 15.2102 4H19.5002C19.7802 4 20.0002 4.22 20.0002 4.5V8.79C20.0002 9.24 19.4602 9.46 19.1502 9.15L17.7102 7.71L14.8302 10.59L13.4102 9.17L16.2902 6.29Z"
      fill="#585858"
      fillRule="evenodd"
    />
  </SvgIcon>
);
