import { removeExclusiveFolderPermissions } from '../../shared/helpers/removeExclusiveFolderPermissions';
import { Permission } from '../types';

import { getChildFolders } from './getChildFolders';
import { getThisFolderPermission } from './getThisFolderPermission';

export const getUpdatedSubfoldersAllPermissionsAfterBreakInheritance = ({
  clickedFolder,
  initialPermissionsExplicit,
  otherFolderPermissions,
  savePermissions,
}: {
  clickedFolder: Permission;
  initialPermissionsExplicit: Permission[];
  otherFolderPermissions: Permission[];
  savePermissions: Permission[];
}) => {
  let response: Permission[] = [];

  const setChildFolders = ({
    folder,
    parentFolder,
  }: {
    folder: Permission;
    parentFolder: Permission;
  }) => {
    const isClickedFolder = clickedFolder.folderId === folder.folderId;

    const thisFolderInitial = getThisFolderPermission(
      folder.folderId,
      initialPermissionsExplicit
    );

    const thisFolderSave = getThisFolderPermission(
      folder.folderId,
      savePermissions
    );

    const thisFolder = thisFolderSave || thisFolderInitial;

    const thisFolderUpdated = isClickedFolder
      ? { ...thisFolder, breakInheritance: !thisFolder.breakInheritance }
      : {
          ...thisFolder,
          folderPermissions: removeExclusiveFolderPermissions({
            permissions: [
              ...thisFolder.folderPermissions,
              ...parentFolder.folderPermissions,
            ],
          }),
        };

    response = [...response, thisFolderUpdated];

    const childFolders = getChildFolders(
      folder.folderId,
      otherFolderPermissions
    );
    childFolders.forEach((folder) =>
      setChildFolders({ folder, parentFolder: thisFolderUpdated })
    );
  };

  setChildFolders({ folder: clickedFolder, parentFolder: null });

  const responseFolderIds = response.map((folder) => folder.folderId);
  const otherFoldersOutsideThisTree = otherFolderPermissions.filter(
    (folder) => !responseFolderIds.includes(folder.folderId)
  );
  return [...response, ...otherFoldersOutsideThisTree];
};
