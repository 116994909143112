import { BasePropsHierarchyItem, ItemProps } from '../types';

import { sortItems } from './sortItems';

export const getLastSibling = <HierarchyItem extends BasePropsHierarchyItem>({
  items,
}: Pick<ItemProps<HierarchyItem>, 'items'>) => {
  const sortedItems = sortItems<HierarchyItem>({
    allItems: items,
    itemsOnEqualLevel: items,
    level: 0,
  });

  return sortedItems[sortedItems.length - 1];
};
