import { useCallback, useEffect, useState } from 'react';

import {
  FieldDefinition,
  TemplateService,
  useFieldDefinitionsQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

export const useFieldDefinitions = ({
  includeOrgItems,
  templateService,
}: {
  includeOrgItems: boolean;
  templateService?: TemplateService;
}) => {
  const { params, searchParams } = useRouting();
  const [selectedFieldDefinition, setSelectedFieldDefinition] =
    useState<FieldDefinition | null>(null);

  const {
    state: { items },
  } = useOptimisticResponseContext<FieldDefinition>();

  const { data, loading, error } = useFieldDefinitionsQuery({
    // TODO: Somehow Apollo acts unexpected and only fetches from cache despite the 'cache-and-network' policy.
    // We experienced similar behaviour with other queries (subscription stuff).
    // We have to take some time in the future to investigate this issue.
    // For now we wanna be sure and user the 'no-cache' policy.
    fetchPolicy: 'no-cache',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    variables: {
      includeOrgItems,
      projectId: params.projectId,
      templateService,
    },
  });

  const fieldDefinitions = data?.fieldDefinitions;

  const selectFieldDefinition = useCallback(
    (fieldDefinition: FieldDefinition) => {
      setSelectedFieldDefinition(fieldDefinition);
      searchParams.set({ action: 'editItem', id: fieldDefinition.id });
    },
    [searchParams]
  );

  useEffect(() => {
    const itemFromUrl = searchParams.get('id');
    const itemByUrl = items?.find((item) => item.id === itemFromUrl);

    if (itemByUrl !== selectedFieldDefinition)
      setSelectedFieldDefinition(itemByUrl);
  }, [
    searchParams,
    selectedFieldDefinition,
    setSelectedFieldDefinition,
    items,
  ]);

  return {
    error,
    fieldDefinitions,
    loading,
    selectFieldDefinition,
    selectedFieldDefinition,
  };
};
