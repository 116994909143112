import { FolderPermission } from '@pro4all/graphql';

export const addRequiredPermissions = (permissions: FolderPermission[]) => {
  let permissionsResponse = permissions;

  // We should add `ReadContent` permission if `ReadAllDocuments` and `ReadAllVersions` are present.
  if (
    permissions.includes(FolderPermission.ReadAllDocuments) &&
    permissions.includes(FolderPermission.ReadAllVersions) &&
    !permissions.includes(FolderPermission.ReadContent)
  ) {
    permissionsResponse.push(FolderPermission.ReadContent);
  }

  // We should remove `ReadContent` permission if `ReadAllDocuments` or `ReadAllVersions` are not present.
  if (
    (!permissions.includes(FolderPermission.ReadAllDocuments) ||
      !permissions.includes(FolderPermission.ReadAllVersions)) &&
    permissions.includes(FolderPermission.ReadContent)
  ) {
    const index = permissionsResponse.indexOf(FolderPermission.ReadContent);
    permissionsResponse.splice(index, 1);
  }

  if (
    permissions.length > 0 &&
    !permissions.includes(FolderPermission.ReadFolder)
  ) {
    permissionsResponse.push(FolderPermission.ReadFolder);
  }

  // We should remove `ReadFolder` permission if this is the only permission left.
  if (
    permissions.length === 1 &&
    permissions.includes(FolderPermission.ReadFolder)
  ) {
    permissionsResponse = [];
  }

  return permissionsResponse;
};
