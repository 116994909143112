import React, { createContext, useContext, useState } from 'react';

import { OpenCdeRequest } from '@pro4all/shared/types';

type OpenCdeContextValue = {
  openCdeRequest: OpenCdeRequest | undefined;
  setOpenCdeRequest: React.Dispatch<React.SetStateAction<OpenCdeRequest>>;
};

const OpenCdeContext = createContext<OpenCdeContextValue>({
  openCdeRequest: undefined,
  setOpenCdeRequest: () => '',
});

export const useOpenCdeContext = () => useContext(OpenCdeContext);

export const OpenCdeContextProvider: React.FC = ({ children }) => {
  const [openCdeRequest, setOpenCdeRequest] = useState<
    OpenCdeRequest | undefined
  >(undefined);

  const openCdeContextValue = {
    openCdeRequest,
    setOpenCdeRequest,
  };

  return (
    <OpenCdeContext.Provider value={openCdeContextValue}>
      {children}
    </OpenCdeContext.Provider>
  );
};
