export const convertBooleanToString = (value: string | boolean) => {
  if (typeof value === 'boolean') {
    // Convert boolean to string 'true' or 'false'
    return String(value);
  } else if (typeof value === 'string') {
    // Check if the string is 'true' or 'false'
    if (value === 'true' || value === 'false') {
      return value;
    }
  }
  return '';
};
