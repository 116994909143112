import { BasePropsHierarchyItem, ItemProps } from '../types';

export function isAnyParentItemCollapsed<
  HierarchyItem extends BasePropsHierarchyItem
>({ allItems, item }: Pick<ItemProps<HierarchyItem>, 'allItems' | 'item'>) {
  let response = false;

  // Find parent item.
  let parentItem = allItems.find(
    (parentItem) => parentItem.id === item.parentNodeId
  );

  while (parentItem) {
    if (parentItem.expanded === false) {
      response = true;
      break;
    }

    // Find next parent item up in the tree.
    const parentId = parentItem.parentNodeId;
    parentItem = allItems.find(
      (nextParentItem) => nextParentItem.id === parentId
    );
  }

  return response;
}
