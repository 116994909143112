import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikSearchableMultiSelect,
} from '@pro4all/shared/ui/general';

import { useGenerateLinkContext } from '../GenerateLinkContext';
import { ModalActionsContainer } from '../modal/styles';

import { CopyToSelfCheckbox } from './CopyToSelfCheckbox';
import { useSendLinkByEmailFormConfig } from './useSendLinkByEmailFormConfig';
import { useSubmit } from './useSubmit';

export interface EmailFormValues {
  to: Option[];
}

export const SendLinkByEmailForm: React.FC = () => {
  const { t } = useTranslation();
  const { submitEmailForm } = useSubmit();

  const { openShareModal } = useGenerateLinkContext();

  const initialValues: EmailFormValues = {
    to: [],
  };

  const { getField, validationSchema } = useSendLinkByEmailFormConfig();
  const toField = getField('to');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitEmailForm}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, values, dirty }) => (
        <StyledForm>
          <FormikSearchableMultiSelect
            canAddNewOptions
            label={toField.label}
            name={toField.name}
            options={[]}
          />
          <ModalActionsContainer>
            <CopyToSelfCheckbox />
            <FormFooter
              cancelLabel="Go Back"
              disableSubmit={
                isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                }) || !values?.to?.length
              }
              onClose={openShareModal} // Back to previous modal
              pt={2}
              submitLabel={t('Send and close')}
            />
          </ModalActionsContainer>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
