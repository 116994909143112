import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';

import { useTableContextMenuRowContext } from '../TableContextMenuContext';
import { BaseRow } from '../types';

export function useContextMenu<Row extends BaseRow>() {
  const { setPosition, setContextMenuRow } =
    useTableContextMenuRowContext<Row>();

  const isSmartFolderRoute = useRouteMatch([
    Routes.projectSmartFolder,
    Routes.smartFolder,
  ]);

  const onContextMenu = useCallback(
    ({ event, row }: { event: React.MouseEvent; row: Row }) => {
      event.stopPropagation();
      event.preventDefault();
      if (isSmartFolderRoute) return;
      setPosition && setPosition({ left: event.clientX, top: event.clientY });
      setContextMenuRow && setContextMenuRow(row);
    },
    [isSmartFolderRoute, setPosition, setContextMenuRow]
  );

  return { onContextMenu };
}
