import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useLinkOsidDeviceMutation } from '@pro4all/graphql';

export const useLinkOsidDevice = ({
  objectId,
  deviceCodeToLink,
}: {
  deviceCodeToLink: string;
  objectId: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [linkOsidDevice] = useLinkOsidDeviceMutation();

  return async () => {
    try {
      await linkOsidDevice({
        variables: {
          osidLinkInput: { deviceCode: deviceCodeToLink, objectId },
        },
      });
      enqueueSnackbar(t('Osid device has been linked.'));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('Could not link device'));
    }
  };
};
