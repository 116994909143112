import { useCallback } from 'react';

export function useLocalStorage<ValueType>({
  key,
  prefix = 'p4a:ps:',
}: {
  key: string;
  prefix?: string;
}) {
  const getLocalStorageItem = useCallback(() => {
    const item = window.localStorage.getItem(`${prefix}${key}`);
    return item ? JSON.parse(item) : {};
  }, [key, prefix]);

  const setLocalStorageItem = useCallback(
    (value: ValueType) => {
      window.localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
    },
    [key, prefix]
  );

  return {
    getLocalStorageItem,
    localStorageItem: getLocalStorageItem(),
    setLocalStorageItem,
  };
}
