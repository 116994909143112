import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@pro4all/shared/ui/buttons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import * as Styled from './Footer.styles';
import { FooterProps } from './types';

export const Footer = ({ disableOnReset, onReset }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Styled.Footer>
      <Tooltip placement="bottom" title={t('Reset filter')}>
        <IconButton
          color="inherit"
          disabled={disableOnReset}
          iconName="reset"
          onClick={onReset}
        />
      </Tooltip>
    </Styled.Footer>
  );
};
