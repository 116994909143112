import React, { createContext, useContext, useMemo, useState } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';

interface SidePanelContextValue {
  setSidePanelIsModal: (isModal: boolean) => void;
  sidePanelIsModal: boolean;
}

export const SidePanelContext = createContext({} as SidePanelContextValue);

export const useSidePanelContext = () =>
  useContext<SidePanelContextValue>(SidePanelContext) || {
    setSidePanelIsModal: null,
    sidePanelIsModal: null,
  };

export const SidePanelContextProvider: React.FC = ({ children }) => {
  const { localStorageItem: sidenavIsModalLocalStorage } =
    useLocalStorage<boolean>({
      key: `sidenav-modal`,
    });

  const [sidePanelIsModal, setSidePanelIsModal] = useState(
    sidenavIsModalLocalStorage.constructor === Object
      ? false
      : sidenavIsModalLocalStorage
  );

  const value = useMemo(
    () => ({
      setSidePanelIsModal,
      sidePanelIsModal,
    }),
    [setSidePanelIsModal, sidePanelIsModal]
  );

  return (
    <SidePanelContext.Provider value={value}>
      {children}
    </SidePanelContext.Provider>
  );
};
