import React from 'react';
import styled from 'styled-components';

import { Avatar } from '@pro4all/shared/mui-wrappers';

const Badge = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.grey['500']};
`;

export const InitialsBadge = ({
  firstInitial,
  secondInitial,
  styles,
}: {
  firstInitial: string;
  secondInitial: string;
  styles: object;
}) => (
  <Badge sx={styles}>
    {firstInitial}
    {secondInitial}
  </Badge>
);
