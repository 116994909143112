import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/types';
import {
  useActionHelpCenter,
  useActionLogin,
} from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

export const SsoEnforcedPage = () => {
  const { t } = useTranslation();
  const actionHelperCenter = useActionHelpCenter();
  const actionLogin = useActionLogin({
    icon: 'arrowBack',
    isSsoEnforced: true,
    label: 'Try again',
  });

  const description = (
    <>
      {t(
        'It looks like your account does exist, but your organization requires you to login via SSO.'
      )}
      <br />
      <br />
      {t('You can try again by using the SSO buttons on the login page.')}
    </>
  );

  const actions: ActionProps[] = [actionHelperCenter, actionLogin];

  return (
    <InfoPage
      actions={actions}
      description={description}
      title={t('You need to login via SSO')}
      type="warning"
    />
  );
};
