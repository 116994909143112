import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const Font = styled(Text)`
  && {
    color: ${customColors.grey700};
    font-weight: 400;
  }
`;

export const Wrapper = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin: 0px ${({ theme }) => theme.spacing(2)}
      ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
    & * {
      align-content: center;
      align-items: center;
    }
    &:hover {
      cursor: grab;
    }
  }
`;

export const FieldCard = styled(Box)`
  && {
    display: flex;
    flex-grow: 1;
    padding: ${({ theme }) => theme.spacing(1)};
    overflow-x: hidden;
    background-color: ${customColors.white};
    border: 1px solid ${customColors.grey300};
    border-radius: 5px;
    &:hover {
      background-color: ${customColors.grey200};
    }
  }
`;

export const OrganizationIcon = styled(Box)`
  && {
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const IconCard = styled(Icon)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
    > * {
      font-size: 14px;
    }
  }
`;
