import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddFormTemplate: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M8.91667 2C8.91667 1.44772 9.36438 1 9.91667 1H10.75C11.3023 1 11.75 1.44772 11.75 2C11.75 2.55228 11.3023 3 10.75 3H9.91667C9.36438 3 8.91667 2.55228 8.91667 2ZM12.25 2C12.25 1.44772 12.6977 1 13.25 1H14.0833C14.6356 1 15.0833 1.44772 15.0833 2C15.0833 2.55228 14.6356 3 14.0833 3H13.25C12.6977 3 12.25 2.55228 12.25 2ZM5 6.28125C5.55228 6.28125 6 6.72897 6 7.28125V8.21875C6 8.77104 5.55228 9.21875 5 9.21875C4.44772 9.21875 4 8.77104 4 8.21875V7.28125C4 6.72897 4.44772 6.28125 5 6.28125ZM19 10.0312C19.5523 10.0312 20 10.479 20 11.0312V11.9688C20 12.521 19.5523 12.9688 19 12.9688C18.4477 12.9688 18 12.521 18 11.9688V11.0312C18 10.479 18.4477 10.0312 19 10.0312ZM5 13.7812C5.55228 13.7812 6 14.229 6 14.7812V15.7188C6 16.271 5.55228 16.7188 5 16.7188C4.44772 16.7188 4 16.271 4 15.7188V14.7812C4 14.229 4.44772 13.7812 5 13.7812ZM8.91667 21C8.91667 20.4477 9.36438 20 9.91667 20H10.75C11.3023 20 11.75 20.4477 11.75 21C11.75 21.5523 11.3023 22 10.75 22H9.91667C9.36438 22 8.91667 21.5523 8.91667 21Z"
      fillRule="evenodd"
    />
    <path d="M5 10.0312C5.55228 10.0312 6 10.479 6 11.0312V11.9688C6 12.521 5.55228 12.9688 5 12.9688C4.44772 12.9688 4 12.521 4 11.9688V11.0312C4 10.479 4.44772 10.0312 5 10.0312Z" />
    <path d="M19 6.28125C19.5523 6.28125 20 6.72896 20 7.28125V8.21875C20 8.77103 19.5523 9.21875 19 9.21875C18.4477 9.21875 18 8.77103 18 8.21875V7.28125C18 6.72896 18.4477 6.28125 19 6.28125Z" />
    <path d="M4 4C4 2.34315 5.34315 1 7 1C7 1 8 1 8 2C8 3 7 3 7 3C6.44772 3 6 3.44772 6 4C6 4 6 5 5 5C4 5 4 4 4 4Z" />
    <path d="M17 1C18.6569 1 20 2.34315 20 4C20 4 20 5 19 5C18 5 18 4 18 4C18 3.44772 17.5523 3 17 3C17 3 16 3 16 2C16 1 17 1 17 1Z" />
    <path d="M7 22C5.34315 22 4 20.6569 4 19C4 19 4 18 5 18C6 18 6 19 6 19C6 19.5523 6.44772 20 7 20C7 20 8 20 8 21C8 22 7 22 7 22Z" />
    <path d="M14.125 19.625H16.375V21.875C16.375 22.4937 16.8812 23 17.5 23C18.1188 23 18.625 22.4937 18.625 21.875V19.625H20.875C21.4937 19.625 22 19.1188 22 18.5C22 17.8812 21.4937 17.375 20.875 17.375H18.625V15.125C18.625 14.5062 18.1188 14 17.5 14C16.8812 14 16.375 14.5062 16.375 15.125V17.375H14.125C13.5062 17.375 13 17.8812 13 18.5C13 19.1188 13.5062 19.625 14.125 19.625Z" />
  </SvgIcon>
);
