import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const SandTimer: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 10 10"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1 0C0.45 0 0 0.45 0 1V2.585C0 2.85 0.105 3.105 0.295 3.295L2 5L0.29 6.71C0.105 6.9 0 7.155 0 7.42V9C0 9.55 0.45 10 1 10H5C5.55 10 6 9.55 6 9V7.42C6 7.155 5.895 6.9 5.71 6.715L4 5L5.705 3.3C5.895 3.11 6 2.855 6 2.59V1C6 0.45 5.55 0 5 0H1ZM5 7.25V8.5C5 8.775 4.775 9 4.5 9H1.5C1.225 9 1 8.775 1 8.5V7.25L3 5.25L5 7.25ZM1 1.5V2.75L3 4.75L5 2.75V1.5C5 1.225 4.775 1 4.5 1H1.5C1.225 1 1 1.225 1 1.5Z"
        fill="#585858"
        fillRule="evenodd"
      />
    </svg>
  </SvgIcon>
);
