import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  iconName: IconName;
  textTitle: string;
}

export const SidebarCategoryTitle = ({ iconName, textTitle }: Props) => {
  const { t } = useTranslation();

  return (
    <IconTextWrapper>
      <StyledTbqResolveIcon iconName={iconName} />
      <TaskText variant="h4">{t(textTitle)}</TaskText>
    </IconTextWrapper>
  );
};

const StyledTbqResolveIcon = styled(Icon)`
  display: block;
  margin-top: 9px;
`;

const TaskText = styled(Text)`
  && {
    width: 17rem;
  }
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;
