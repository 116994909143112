import React from 'react';

import {
  Box,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@pro4all/shared/mui-wrappers';
import { CustomColor, customColors } from '@pro4all/shared/themes';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export interface TextFieldProps extends MuiTextFieldProps {
  bgColor?: CustomColor;
  fieldsToSync?: string[];
  name: string | undefined;
  tooltipTitle?: string;
  variant?: 'standard' | 'outlined' | 'filled'; // Added variant prop
  warning?: boolean;
}

export const TextField: React.FC<TextFieldProps> = React.forwardRef(
  (
    {
      bgColor = 'white',
      InputProps,
      InputLabelProps,
      rows = 1,
      multiline = false,
      tooltipTitle = '',
      variant = 'outlined', // Added variant prop with default value
      warning = false,
      ...rest
    },
    ref
  ) => {
    const textField = (
      <Box alignItems="center" display="flex" flex={1}>
        <Box display="flex" width="100%">
          <MuiTextField
            {...rest}
            InputLabelProps={{ ...InputLabelProps }}
            InputProps={{
              ...InputProps,
            }}
            autoComplete="off"
            fullWidth
            id={rest.name}
            maxRows={4}
            multiline={multiline}
            ref={ref}
            rows={rows}
            size="small"
            sx={{
              background: customColors[bgColor],
              border: `1px solid ${warning ? customColors.warning : 'inherit'}`,
              borderRadius: '5px',
            }}
            variant={variant}
          />
        </Box>
      </Box>
    );

    return tooltipTitle ? (
      <Box sx={{ flexGrow: '1' }}>
        <Tooltip placement="bottom" title={tooltipTitle}>
          {textField}
        </Tooltip>
      </Box>
    ) : (
      textField
    );
  }
);
