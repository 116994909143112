import { ValueTypeName } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';

import { SelectOption } from './CustomSelection';
import { Status } from './CustomStatus';

export interface FormFields {
  description: string;
  displayDescription: string;
  displayName: string;
  displayType: string;
  hierarchyList?: Option;
  maxValue?: string;
  minValue?: string;
  multiSelect?: boolean;
  multipleAnswers?: boolean;
  name: string;
  options?: SelectOption[];
  rangeEnabled?: boolean;
  selectionOptions?: SelectOption[];
  staticBreadcrumbs?: boolean;
  statusOptions?: Status[];
  type: TypeProps;
}

export interface FieldDefinitionType {
  label: keyof typeof ValueTypeName;
}

export interface TypeProps {
  iconName?: IconName;
  id: ValueTypeName;
  label: string;
}

export interface CustomType {
  values: Partial<FormFields>;
}

export enum DisplayTypeValues {
  buttons = 'button',
  dropdown = 'dropdown',
}
