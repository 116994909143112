import React from 'react';

import { AttachmentContext, Message } from '@pro4all/graphql';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { tryDecodeString } from '@pro4all/shared/utils';

import { Attachment } from './Attachment';
import { PreviewWrap } from './Attachment.styles';

interface Props {
  attachments: Message['attachments'];
  context: AttachmentContext;
  newFiles: File[];
  onDelete?: ({ completed, id }: { completed: boolean; id: string }) => void;
  uploadProgress?: { [id: string]: number };
}

export const AttachmentsUploadPreview: React.FC<Props> = ({
  attachments,
  onDelete,
  newFiles,
  uploadProgress,
  context,
}) => {
  const uploadContext = useFileUploadContext();
  if (!uploadContext) return null;
  const { isUploading } = uploadContext;

  const names = Array.from(
    new Set([
      ...(attachments || []).map(
        ({ fileName }) => tryDecodeString(fileName) || fileName
      ),
      ...newFiles.map(({ name }) => name),
    ])
  ).filter(Boolean);
  /* Combine completed uploads (attachments) and queued uploads (newFiles), prioritize attachments */
  const combined = names.map((_name) => {
    const completed = attachments?.find(
      ({ fileName }) => tryDecodeString(fileName) === _name
    );
    const newFile = newFiles.find(({ name }) => name === _name);
    const name = completed ? completed.fileName : newFile?.name || 'unknown';

    return {
      completed: Boolean(completed),
      id: completed ? completed.fileId : newFile?.name,
      name,
      size: completed ? completed.fileSize : newFile?.size,
      uploadProgress: completed ? 1 : uploadProgress ? uploadProgress[name] : 0,
    };
  });

  return (
    <PreviewWrap>
      {combined?.map(({ completed, id, name, size, uploadProgress }, key) => (
        <Attachment
          completed={completed}
          context={context}
          id={id || 'unknown'}
          isUpload
          key={key}
          name={name}
          onDelete={completed ? onDelete : undefined}
          size={size}
          uploadProgress={uploadProgress}
          uploading={isUploading}
        />
      ))}
    </PreviewWrap>
  );
};
