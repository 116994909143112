import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { getDropdownRoutes } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { Select } from '@pro4all/shared/ui/general';

const NavigationDropdown = () => {
  const { t } = useTranslation();
  const {
    goTo,
    params: { projectId },
  } = useRouting();

  const selectOptions = getDropdownRoutes(projectId)?.map((option) => ({
    id: option.label,
    inputValue: option.route,
    label: t(`${option.label}`),
  }));

  const findOptionMatchingCurrentRoute = () =>
    selectOptions?.find((opt) => {
      const generatedProjectRoute = projectId
        ? generateRoute(opt.inputValue, {
            params: { projectId },
          })
        : Routes[opt.inputValue];
      return window.location.pathname === generatedProjectRoute;
    });

  const [selectedOption, setSelectedOption] = useState<Option>(
    findOptionMatchingCurrentRoute()
  );

  const handleChange = (e: Option) => {
    setSelectedOption(e);
    goTo(e.inputValue as keyof typeof Routes, {
      params: {
        projectId,
      },
    });
  };

  return (
    <StyledSelect
      name="project"
      onChange={(e) => handleChange(e)}
      options={selectOptions ?? []}
      value={selectedOption}
    />
  );
};

const StyledSelect = styled(Select)`
  && {
    width: 170px;
  }
`;

export default NavigationDropdown;
