import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { AddUsersToProjectForm } from './AddUsersToProjectForm';

interface Props {
  onClose: () => void;
  userToAdd?: User;
}

export const AddUsersToProjectSidebar: React.FC<Props> = ({
  onClose,
  userToAdd,
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const isOpen = searchParams.is('action', 'addUsersToProject');

  return (
    <Sidebar onClose={onClose} open={isOpen}>
      <Sidebar.Header title={t('Add to project')} />
      {isOpen && (
        <AddUsersToProjectForm onClose={onClose} userToAdd={userToAdd} />
      )}
    </Sidebar>
  );
};
