import React, { createContext, useContext, useMemo, useState } from 'react';

import { Answer, FieldDefinition } from '@pro4all/graphql';
import { InstanceValues } from '@pro4all/shared/types';

interface SavedAnswersContextValue {
  answers: InstanceValues | null;
  answersForApplying: Answer[] | null;
  items: FieldDefinition[];
  setAnswers: (answers: InstanceValues) => void;
  setAnswersForApplying: (answers: Answer[]) => void;
  setItems: (items: FieldDefinition[]) => void;
  setTemplateId: (templateId: string) => void;
  templateId: string;
}

export const SavedAnswersContext = createContext(
  {} as SavedAnswersContextValue
);

export const useSavedAnswersContext = () =>
  useContext<SavedAnswersContextValue>(SavedAnswersContext) || {
    answers: null,
    answersForApplying: null,
    items: [],
    setAnswers: null,
    setAnswersForApplying: null,
    setItems: null,
    setTemplateId: null,
    templateId: '',
  };

export const SavedAnswersContextProvider: React.FC = ({ children }) => {
  const [answers, setAnswers] = useState<InstanceValues | null>(null);
  const [answersForApplying, setAnswersForApplying] = useState<Answer[] | null>(
    null
  );
  const [templateId, setTemplateId] = useState('');
  const [items, setItems] = useState<FieldDefinition[]>([]);

  const value = useMemo(
    () => ({
      answers,
      answersForApplying,
      items,
      setAnswers,
      setAnswersForApplying,
      setItems,
      setTemplateId,
      templateId,
    }),
    [
      answers,
      answersForApplying,
      items,
      setAnswers,
      setAnswersForApplying,
      setItems,
      setTemplateId,
      templateId,
    ]
  );

  return (
    <SavedAnswersContext.Provider value={value}>
      {children}
    </SavedAnswersContext.Provider>
  );
};
