import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@pro4all/shared/ui/typography';

export const LinkedAncestor = () => {
  const { t } = useTranslation();

  /* @Todo: add a go-to-linked-object-button */
  return <Text>{t('This object inherits its linked project.')}</Text>;
};
