import React from 'react';
import { useTranslation } from 'react-i18next';

import { Member, ShareType, useUsersIncludeQuery } from '@pro4all/graphql';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useGenerateLinkContext } from '../GenerateLinkContext';

export const ProjectMembersFeedBack: React.FC = () => {
  const { selectedShareMethod } = useGenerateLinkContext();
  const { t } = useTranslation();
  const { projectId } = useRouting().params;
  // Get all included users in the project, users groups are returned as well
  const { data: userData } = useUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      projectId,
    },
  });

  const projectMembers = (userData?.users as Member[]) || [];
  return (
    selectedShareMethod === ShareType.Scoped &&
    projectMembers.length > 1 && (
      <Alert severity="warning" variant="filled">
        {t(
          `Pressing 'Send and Close' will send a message to all {{length}} project members`,
          {
            length: projectMembers.length - 1,
          }
        )}
      </Alert>
    )
  );
};
