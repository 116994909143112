import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  Document,
  DocumentVersion,
  DocumentVersionState,
  FinalizationState,
  UnapproveDocumentVersionsMutation,
  UnrejectDocumentVersionsMutation,
  useApproveDocumentVersionsMutation,
  useRejectDocumentVersionsMutation,
  useUnapproveDocumentVersionsMutation,
  useUnrejectDocumentVersionsMutation,
} from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';

export enum ApprovalAction {
  Approve = 'approve',
  Reject = 'reject',
  Unapprove = 'unapprove',
  Unreject = 'unreject',
}

interface Props {
  documents: Document[] | null;
  versions?: DocumentVersion[] | null;
}

export function useApproval({ documents, versions }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [approveDocumentVersions] = useApproveDocumentVersionsMutation();
  const [rejectDocumentVersions] = useRejectDocumentVersionsMutation();
  const [unapproveDocumentVersions] = useUnapproveDocumentVersionsMutation();
  const [unrejectDocumentVersions] = useUnrejectDocumentVersionsMutation();
  const { approve_action_label, reject_action_label } =
    useDocumentActionsLabels();

  const updateCachedDocuments = useUpdateCachedDocuments();

  const approveDocument = useCallback(async () => {
    try {
      if (documents || versions) {
        const approvalVersionIds = documents?.length
          ? (documents
              .filter(
                (document) =>
                  !document.isExpected &&
                  document.state !== FinalizationState.Finalized &&
                  document.versionState !== DocumentVersionState.Approved &&
                  document.versionState !== DocumentVersionState.Rejected
              )
              .map((document) => document.versionId) as string[])
          : versions?.length
          ? (versions
              .filter(
                (version) =>
                  version.versionState !== DocumentVersionState.Approved &&
                  version.versionState !== DocumentVersionState.Rejected
              )
              .map((version) => version.id) as string[])
          : [];

        const { data } = await approveDocumentVersions({
          variables: {
            versionIds: approvalVersionIds,
          },
        });

        const approveResponse =
          data && data.approveDocumentVersions
            ? data.approveDocumentVersions
            : null;

        updateCachedDocuments({
          documentIds: approveResponse?.successful || [],
          fieldToUpdate: 'versionState',
          keyField: 'versionId',
          value: DocumentVersionState.Approved,
        });

        if (
          approveResponse &&
          approveResponse.successful &&
          approveResponse.unsuccessful
        ) {
          if (
            approveResponse.successful.length > 0 &&
            approveResponse.unsuccessful.length === 0
          ) {
            enqueueSnackbar(
              t('{{name}} set for document version(s)', {
                name: approve_action_label,
              })
            );
          } else if (
            approveResponse.successful.length > 0 &&
            approveResponse.unsuccessful.length > 0
          ) {
            enqueueSnackbar(
              t('{{name}} set for document version(s), some errors occured', {
                name: approve_action_label,
              })
            );
          } else if (
            approveResponse.successful.length === 0 &&
            approveResponse.unsuccessful.length > 0
          ) {
            throw new Error();
          }
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  }, [
    approveDocumentVersions,
    documents,
    enqueueSnackbar,
    updateCachedDocuments,
    t,
    versions,
  ]);

  const rejectDocument = useCallback(async () => {
    try {
      if (documents || versions) {
        const unapprovalVersionIds = documents?.length
          ? (documents
              .filter(
                (document) =>
                  !document.isExpected &&
                  document.state !== FinalizationState.Finalized &&
                  document.versionState !== DocumentVersionState.Approved &&
                  document.versionState !== DocumentVersionState.Rejected
              )
              .map((document) => document.versionId) as string[])
          : versions?.length
          ? (versions
              .filter(
                (version) =>
                  version.versionState !== DocumentVersionState.Approved &&
                  version.versionState !== DocumentVersionState.Rejected
              )
              .map((document) => document.id) as string[])
          : [];

        const { data } = await rejectDocumentVersions({
          variables: { versionIds: unapprovalVersionIds },
        });

        const rejectResponse =
          data && data.rejectDocumentVersions
            ? data.rejectDocumentVersions
            : null;

        updateCachedDocuments({
          documentIds: rejectResponse?.successful || [],
          fieldToUpdate: 'versionState',
          keyField: 'versionId',
          value: DocumentVersionState.Rejected,
        });

        if (
          rejectResponse &&
          rejectResponse.successful &&
          rejectResponse.unsuccessful
        ) {
          if (
            rejectResponse.successful.length > 0 &&
            rejectResponse.unsuccessful.length === 0
          ) {
            enqueueSnackbar(
              t('{{name}} set for document version(s)', {
                name: reject_action_label,
              })
            );
          } else if (
            rejectResponse.successful.length > 0 &&
            rejectResponse.unsuccessful.length > 0
          ) {
            enqueueSnackbar(
              t('{{name}} set for document version(s), some errors occured', {
                name: reject_action_label,
              })
            );
          } else if (
            rejectResponse.successful.length === 0 &&
            rejectResponse.unsuccessful.length > 0
          ) {
            throw new Error();
          }
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  }, [
    documents,
    enqueueSnackbar,
    rejectDocumentVersions,
    updateCachedDocuments,
    t,
    versions,
  ]);

  const unapproveUnrejectDocument = useCallback(
    async (action: ApprovalAction) => {
      try {
        if (documents || versions) {
          const unapproveUnrejectDocuments = documents?.length
            ? (documents
                .filter(
                  (document) =>
                    !document.isExpected &&
                    document.state !== FinalizationState.Finalized &&
                    ((action === ApprovalAction.Unapprove &&
                      document.versionState ===
                        DocumentVersionState.Approved) ||
                      (action === ApprovalAction.Unreject &&
                        document.versionState ===
                          DocumentVersionState.Rejected))
                )
                .map((document) => document.versionId) as string[])
            : versions?.length
            ? (versions
                .filter(
                  (version) =>
                    (action === ApprovalAction.Unapprove &&
                      version.versionState === DocumentVersionState.Approved) ||
                    (action === ApprovalAction.Unreject &&
                      version.versionState === DocumentVersionState.Rejected)
                )
                .map((version) => version.id) as string[])
            : [];

          const response =
            action === ApprovalAction.Unapprove
              ? await unapproveDocumentVersions({
                  variables: { versionIds: unapproveUnrejectDocuments },
                })
              : action === ApprovalAction.Unreject
              ? await unrejectDocumentVersions({
                  variables: { versionIds: unapproveUnrejectDocuments },
                })
              : null;

          if (!response || !response.data) throw new Error();

          const unapproveUnrejectResponse =
            (response.data as UnapproveDocumentVersionsMutation)
              .unapproveDocumentVersions ??
            (response.data as UnrejectDocumentVersionsMutation)
              .unrejectDocumentVersions;

          if (
            unapproveUnrejectResponse &&
            unapproveUnrejectResponse.successful &&
            unapproveUnrejectResponse.unsuccessful
          ) {
            if (
              unapproveUnrejectResponse.successful.length > 0 &&
              unapproveUnrejectResponse.unsuccessful.length === 0
            ) {
              const successTranslation =
                action === ApprovalAction.Unapprove
                  ? t('{{name}} withdrawn from version(s)', {
                      name: approve_action_label,
                    })
                  : action === ApprovalAction.Unreject
                  ? t('{{name}} withdrawn from version(s)', {
                      name: reject_action_label,
                    })
                  : reject_action_label;
              enqueueSnackbar(successTranslation);

              updateCachedDocuments({
                documentIds: unapproveUnrejectResponse?.successful || [],
                fieldToUpdate: 'versionState',
                keyField: 'versionId',
                value: DocumentVersionState.None,
              });
            } else if (
              unapproveUnrejectResponse.successful.length > 0 &&
              unapproveUnrejectResponse.unsuccessful.length > 0
            ) {
              const partialSuccessTranslation =
                action === ApprovalAction.Unapprove
                  ? t(
                      '{{name}} withdrawn from version(s), some errors occured',
                      { name: reject_action_label }
                    )
                  : action === ApprovalAction.Unreject
                  ? t(
                      '{{name}} withdrawn from version(s), some errors occured',
                      { name: reject_action_label }
                    )
                  : '';
              enqueueSnackbar(partialSuccessTranslation);
            } else if (
              unapproveUnrejectResponse.successful.length === 0 &&
              unapproveUnrejectResponse.unsuccessful.length > 0
            ) {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        }
      } catch (e) {
        enqueueSnackbar(t('Something went wrong'));
      }
    },
    [
      documents,
      updateCachedDocuments,
      unapproveDocumentVersions,
      unrejectDocumentVersions,
      t,
      enqueueSnackbar,
      versions,
    ]
  );

  const approveOrRejectVersion = useCallback(
    (action: ApprovalAction): void => {
      switch (action) {
        case ApprovalAction.Approve:
          approveDocument();
          break;
        case ApprovalAction.Reject:
          rejectDocument();
          break;
        case ApprovalAction.Unapprove:
          unapproveUnrejectDocument(action);
          break;
        case ApprovalAction.Unreject:
          unapproveUnrejectDocument(action);
          break;
        default:
      }
    },
    [approveDocument, rejectDocument, unapproveUnrejectDocument]
  );

  return {
    approveOrRejectVersion,
  };
}
