import { getToken } from '@pro4all/authentication/src/utils';

export const AccessTokenInterceptor = (config: {
  headers: { [key: string]: string };
}) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

export default AccessTokenInterceptor;
