import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageMetaDataNoQualityControlSections: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Create re-usable sections of as many fields as you want and use them in multiple templates.'
      )}
      title={t("You don't have any sections yet")}
    />
  );
};
