import React from 'react';
import { SnackbarProvider as Provider } from 'notistack';

import { Snackbar } from './Snackbar';

export const SnackbarProvider: React.FC = ({ children }) => (
  <Provider
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom',
    }}
    autoHideDuration={4000}
    content={(key, message) => <Snackbar id={key} message={message} />}
    maxSnack={3}
  >
    {children}
  </Provider>
);

export default SnackbarProvider;
