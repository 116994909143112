import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@pro4all/shared/ui/general';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLButtonElement>, value: string) => void;
  showDocLinks: boolean;
  value: string;
}

export const DocumentSidebarTabs: React.FC<Props> = ({
  onChange,
  showDocLinks,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Tabs onChange={onChange} value={value}>
      <Tab
        data-testid="document-properties-tab"
        label={t('Properties')}
        value="props"
      />
      <Tab data-testid="document-log-tab" label={t('Log')} value="log" />
      {showDocLinks && (
        <Tab
          data-testid="document-links-tab"
          label={t('Linked tasks')}
          value="links"
        />
      )}
      <Tab
        data-testid="document-comments-tab"
        label={t('Comments')}
        value="comments"
      />
    </Tabs>
  );
};
