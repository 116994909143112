import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  Document,
  useCreateVisualContextFromDocumentsMutation,
  VisualContextStatus,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DocumentIdsToConvertToDrawings } from '@pro4all/shared/types';
import { Dialog, useTableCheck } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

export const ModalConvertDocumentsToDrawings = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { uncheckAllRows } = useTableCheck<Document>();
  const updateCachedDocuments = useUpdateCachedDocuments();

  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const [createVisualContextFromDocuments] =
    useCreateVisualContextFromDocumentsMutation();

  const { getLocalStorageItem: getdocumentIdsToConvertToDrawings } =
    useLocalStorage<DocumentIdsToConvertToDrawings>({
      key: StorageKeys.DOCUMENT_IDS_TO_CONVERT_TO_DRAWINGS,
    });

  const convertDocumentsToDrawings = searchParams.is(
    'action',
    'convertDocumentsToDrawings'
  );

  const documentsToConvert =
    getdocumentIdsToConvertToDrawings() as DocumentIdsToConvertToDrawings;

  const onConfirm = async () => {
    searchParams.delete('action');
    await createVisualContextFromDocuments({
      variables: {
        documents: documentsToConvert.supportedDocuments,
        projectId: projectId,
      },
    });
    enqueueSnackbar(t('Conversion has started'));
    uncheckAllRows();

    updateCachedDocuments({
      documentIds:
        documentsToConvert.supportedDocuments.map((doc) => doc.versionId) || [],
      fieldToUpdate: 'visualContext',
      keyField: 'versionId',
      value: {
        id: '1',
        name: '1',
        status: VisualContextStatus.Processed,
      },
    });
  };

  const onClose = () => {
    searchParams.delete('action');
  };

  const atLeastOneDocToConvert = Boolean(
    documentsToConvert?.supportedDocuments?.length
  );

  return (
    <Dialog
      confirmLabel={atLeastOneDocToConvert ? null : 'Ok'}
      iconName="info"
      name="convertDocumentsToDrawings"
      onClose={atLeastOneDocToConvert ? onClose : null}
      onConfirm={atLeastOneDocToConvert ? onConfirm : onClose}
      open={convertDocumentsToDrawings}
      title={t('Convert documents to drawings')}
    >
      <Text>
        {atLeastOneDocToConvert
          ? t(
              'Only {{count}} of {{selectedCount}} selected documents can be converted to drawings. Do you want to continue?',
              {
                count: documentsToConvert?.supportedDocuments?.length,
                selectedCount: documentsToConvert?.selectedCount,
              }
            )
          : t(
              'Latest version of selected documents already converted to a drawing.'
            )}
      </Text>
    </Dialog>
  );
};
