import React from 'react';

import {
  QualityControlInstance,
  QualityControlInstancesQuery,
} from '@pro4all/graphql';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';

import { AnswersPercentageContextProvider } from './form-answers-percentages/AnswersPercentageContext';
import { ResultSidebar } from './result-sidebar/ResultSidebar';
import { ResultsActionBar } from './ResultsActionBar';
import { useColumns } from './useColumns';
import { useViewResult } from './useViewResult';

type Props = {
  results: QualityControlInstancesQuery['qualityControlInstances'];
  searchEnabled?: boolean;
};

export const ResultsTable = ({ searchEnabled, results }: Props) => {
  const {
    searchParams,
    params: { projectId },
  } = useRouting();

  const columns = useColumns();

  const onRowClick = useViewResult(projectId, results);

  useSetItemsInLocalState(results || []);

  const {
    state: { items },
    deleteItems,
  } = useOptimisticResponseContext<QualityControlInstance>();

  return (
    <TableContextProvider
      columns={columns}
      id="table-qc-results"
      idFilteringSorting={`table-qc-results-${projectId}`}
      items={items}
    >
      <FilterContextProvider<QualityControlInstance>>
        <ResultsActionBar
          deleteItems={deleteItems}
          showDataViewContextToggle={searchEnabled}
          showSearch
        />
        <Table
          enableKeyboardControl
          onRowClick={onRowClick}
          selectedId={searchParams.get('id') ?? ''}
        />
        <PhotoProvider>
          <AnswersPercentageContextProvider>
            <ResultSidebar />
          </AnswersPercentageContextProvider>
        </PhotoProvider>
      </FilterContextProvider>
    </TableContextProvider>
  );
};
