import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useAddGroupsUsersToProjectFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Projects', // i18n
      name: 'projects',
      required: true,
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ formFields });
};
