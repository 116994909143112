import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  EntityScope,
  gqlType,
  Group,
  SelectedGroup,
  useCreatePrivateShareLinkMutation,
  useCreatePublicShareLinkMutation,
  useCreateScopedShareLinkMutation,
  useSendShareLinkMutation,
} from '@pro4all/graphql';
import { getGroupMemberIds } from '@pro4all/identity/ui';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { toApiDate } from '@pro4all/shared/utils';

import { useGenerateLinkContext } from '../GenerateLinkContext';
import {
  PrivateLinkFormValues,
  PublicLinkFormValues,
  ScopedLinkFormValues,
} from '../modal/types';

import { EmailFormValues } from './SendLinkByEmailForm';

// This is directly propagated to BE, which expects an enum
enum ResourceType {
  LatestVersion = 0,
  AllVersions = 1,
}

enum HyperLinkScope {
  Organization = 0,
  Project = 1,
}

type EmailValuesPrivate = {
  groupIds: string[];
  userIds: string[];
};

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useRouting().params;

  const {
    closeModal,
    composeMessage,
    copyToSelf,
    latestVersionOnly,
    linkId,
    members,
    message,
    setLink,
    setLinkId,
    setPassword,
    setRecipientIds,
    resourceIds,
    subject,
    setRecipientGroups,
  } = useGenerateLinkContext();

  const [createPublicLink] = useCreatePublicShareLinkMutation();
  const [createPrivateLink] = useCreatePrivateShareLinkMutation();
  const [createScopedLink] = useCreateScopedShareLinkMutation();
  const [sendShareLink] = useSendShareLinkMutation();

  const resources = resourceIds?.map((resourceId) => ({
    resourceId,
    resourceType: latestVersionOnly
      ? ResourceType.LatestVersion
      : ResourceType.AllVersions,
  }));

  const checkVersionsAndProceed = () => {
    if (!resourceIds?.length) {
      enqueueSnackbar(t('Something went wrong'));
      return;
    }

    composeMessage();
  };

  const submitPublicLink = async ({
    endTime,
    enablePassword,
    showFolders,
  }: PublicLinkFormValues) => {
    checkVersionsAndProceed();

    try {
      const res = await createPublicLink({
        variables: {
          expirationDate: toApiDate(endTime),
          passwordProtected: enablePassword,
          resources,
          showFolders,
        },
      });

      setLink(res?.data?.createPublicShareLink.link);
      setLinkId(res?.data?.createPublicShareLink.id);
      setPassword(res?.data?.createPublicShareLink.password);
    } catch {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const getRecipientIds = (selectedUserMembers: Option[]) =>
    selectedUserMembers.map((member) => member.id);
  const getGroupsIds = (groups: SelectedGroup[]) =>
    groups.map((group) => group.id);

  const calculateScope = (scope: number): number => {
    const scopeValue = projectId
      ? scope === EntityScope.Organization
        ? HyperLinkScope.Organization
        : HyperLinkScope.Project
      : HyperLinkScope.Organization;
    return scopeValue;
  };

  const submitPrivateLink = async ({
    endTime,
    selectedMembers,
  }: PrivateLinkFormValues) => {
    checkVersionsAndProceed();

    const availableGroups: Group[] = members.filter(gqlType('Group'));

    // We need to split users and groups.
    // Use reduce to differenciate user from groups.
    // If the id of the option matches with an id from our available groups it must be a group.
    // If there is no match it must mean that it is a user

    const selectedGroupMembers = selectedMembers.filter(
      (option) => option.type === 'Group'
    );
    const selectedUserMembers = selectedMembers.filter(
      (option) => option.type === 'User'
    );
    // Get a list of available members of a group
    const groups: SelectedGroup[] = availableGroups
      .filter((group) =>
        selectedGroupMembers.some((subgroup) => group.id === subgroup.id)
      )
      .map((group) => ({
        id: group.id,
        members: getGroupMemberIds({ group, members, recursive: true }) || [],
        scope: calculateScope(group?.scope || 0),
      }));

    const userIds = getRecipientIds(selectedUserMembers);

    setRecipientIds(userIds);
    setRecipientGroups(getGroupsIds(groups));

    try {
      const res = await createPrivateLink({
        variables: {
          expirationDate: toApiDate(endTime),
          groups,
          projectId,
          resources,
          userIds,
        },
      });

      const { createPrivateShareLink } = res.data;

      setLink(createPrivateShareLink.link);
      setLinkId(createPrivateShareLink.id);
    } catch (error) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const submitScopedLink = async ({ endTime }: ScopedLinkFormValues) => {
    checkVersionsAndProceed();

    try {
      const res = await createScopedLink({
        variables: {
          expirationDate: toApiDate(endTime),
          projectId,
          resources,
        },
      });

      setLink(res.data.createScopedShareLink.link);
      setLinkId(res?.data?.createScopedShareLink.id);
    } catch {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const submitEmailForm = async ({ to }: EmailFormValues) => {
    const selectedRecipients: string[] = to.map((recipient) => recipient.label);
    await sendShareLink({
      variables: {
        body: message,
        copyToSelf,
        id: linkId,
        subject,
        to: selectedRecipients,
      },
    });

    closeModal();
    enqueueSnackbar(t('Email sent!'));
  };

  const submitEmailsToMembers = async ({
    userIds,
    groupIds,
  }: EmailValuesPrivate) => {
    await sendShareLink({
      variables: {
        body: message,
        copyToSelf,
        groupIds,
        id: linkId,
        projectId: projectId || null,
        subject,
        userIds,
      },
    });

    closeModal();
    enqueueSnackbar(t('Email sent!'));
  };

  return {
    submitEmailForm,
    submitEmailsToMembers,
    submitPrivateLink,
    submitPublicLink,
    submitScopedLink,
  };
};
