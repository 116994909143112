import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  BatchQualityControlInstancesIncludeDocument,
  FormLinkedSnag,
  QualityControlInstance,
  useUnlinkLinkMutation,
} from '@pro4all/graphql';
import { getBatchFormLinkedSnagsIncludeVariables } from '@pro4all/quality-control/data-access';
import { useShowMessages } from '@pro4all/shared/ui/messages';

interface UnlinkProps {
  fieldId?: string;
  id?: string;
}

interface Props {
  linkedSnags?: FormLinkedSnag[] | null;
  refetchQualityInstance?: () => void;
}

export const useUnlinkSnag = ({
  linkedSnags,
  refetchQualityInstance,
}: Props) => {
  const [unlinkLink] = useUnlinkLinkMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();

  const UnlinkSnag = async ({ fieldId, id }: UnlinkProps) => {
    let linkToUnlink: FormLinkedSnag | undefined;

    if (fieldId) {
      linkToUnlink = linkedSnags?.find(
        (link) => link.appData?.fieldId === fieldId
      );
    } else if (id) {
      linkToUnlink = linkedSnags?.find((link) => link.id === id);
    }

    if (!linkToUnlink) {
      enqueueSnackbar('No matching link found.');
      return;
    }

    try {
      const { data } = await unlinkLink({
        variables: {
          linkId: linkToUnlink.linkId,
        },
      });

      if (data.unlinkLink?.errors) {
        enqueueSnackbar('Something went wrong. Please try again.');
      } else {
        const variables = getBatchFormLinkedSnagsIncludeVariables({
          ids: linkedSnags?.map((link) => link.id) ?? [],
        });
        const currentLinkedSnags = await client?.readQuery({
          query: BatchQualityControlInstancesIncludeDocument,
          variables,
        }).qualityControlInstances;

        await client.writeQuery({
          data: {
            qualityControlInstances: currentLinkedSnags?.filter(
              (snag: QualityControlInstance) => snag.id !== linkToUnlink.id
            ),
          },
          query: BatchQualityControlInstancesIncludeDocument,
          variables,
        });

        enqueueSnackbar('Snag is succesfully unlinked');
      }
    } catch (e) {
      if (showSingleError) showSingleError(e);
      else enqueueSnackbar('Something went wrong. Please try again.');
    }
  };

  return UnlinkSnag;
};
