import { Document } from '@pro4all/graphql';

import {
  Meta,
  MetaData,
  State,
  UpdateMetaVersionPayload,
} from '../useUploaderEditorReducer';

import { useGetErrorName, useGetMetaData } from './helpers';

export const useUpdateMetaVersionAction = () => {
  const getError = useGetErrorName();
  const getMetaData = useGetMetaData();

  const updateMetaVersionAction = ({
    payload,
    state,
  }: {
    payload: UpdateMetaVersionPayload;
    state: State;
  }) => {
    const { currentDocumentsInFolder, fields, filesToUpload, meta } = state;
    const metaUpdated = [...meta];
    const { documentId, newVersionFor, versionNumber } = payload;

    // Take the metadata of the selected document.
    const selectedDocument = currentDocumentsInFolder.find(
      (document) => document.id === newVersionFor.id
    ) as Document;

    const index = metaUpdated.findIndex((doc) => doc.id === documentId);

    const changedMetaDocument = meta.find(
      (meta) => meta.id === documentId
    ) as Meta;

    // Only update if the element has been found.
    if (index !== -1) {
      metaUpdated[index].error = getError({
        currentDocumentsInFolder,
        documentId,
        meta,
        uploadContext: Boolean(filesToUpload.length),
        value: changedMetaDocument.name,
      });
      metaUpdated[index].metaData = getMetaData({
        document: selectedDocument,
        fields,
        intialize: false,
        metaData: metaUpdated[index].metaData as MetaData[],
      });
      metaUpdated[index].version = { newVersionFor, versionNumber };
    }

    return {
      ...state,
      meta: metaUpdated,
    };
  };

  return updateMetaVersionAction;
};
