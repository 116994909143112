import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { EditFileForm } from './EditFileForm';

export const EditFileModal = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const showModal = searchParams.is('action', 'editFile');

  const onClose = () => {
    searchParams.delete('action');
    searchParams.delete('editDocumentId');
  };

  return (
    <FloatingModal
      data-testid="edit-file-modal"
      onClose={onClose}
      open={showModal}
    >
      <FloatingModal.Header iconName="download">
        {t('Download document to edit')}
      </FloatingModal.Header>
      <EditFileForm onClose={onClose} />
    </FloatingModal>
  );
};
