import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useLinkProjectModalConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Project', // i18n
      name: 'project',
      required: true,
      type: ValueTypeName.Selection,
    },
  ];

  return useFormConfig({ formFields });
};
