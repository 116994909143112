import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@pro4all/shared/ui/tooltip';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  active?: boolean;
}

export const EditorButton: React.FC<Props> = ({
  active,
  children,
  disabled,
  onClick,
  title = '',
}) => (
  <Tooltip disableHoverListener={disabled} placement="bottom" title={title}>
    <StyledButton
      $active={active}
      disabled={disabled}
      onMouseDown={onClick}
      tabIndex={-1}
    >
      {children}
    </StyledButton>
  </Tooltip>
);

export const StyledButton = styled.button.attrs({ type: 'button' })<{
  $active?: boolean;
}>`
  flex-shrink: 0;
  line-height: 1.42825;
  padding: 5px 5px;
  cursor: pointer;

  background-color: ${({ $active }) => ($active ? '#eee' : '0')};
  color: ${({ $active }) => ($active ? '#333333' : '#585858')};
  &:disabled {
    color: #afafaf;
  }
  &:hover {
    background-color: #eee;
    &:disabled {
      background-color: none;
    }
  }
`;
