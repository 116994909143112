import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
export const ReplyAll: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.82499 11L8.72499 14.9C8.92499 15.1 9.02082 15.3333 9.01249 15.6C9.00415 15.8667 8.89999 16.1 8.69999 16.3C8.49999 16.4833 8.26665 16.5792 7.99999 16.5875C7.73332 16.5958 7.49999 16.5 7.29999 16.3L2.69999 11.7C2.59999 11.6 2.52915 11.4917 2.48749 11.375C2.44582 11.2583 2.42499 11.1333 2.42499 11C2.42499 10.8667 2.44582 10.7417 2.48749 10.625C2.52915 10.5083 2.59999 10.4 2.69999 10.3L7.29999 5.7C7.48332 5.51667 7.71249 5.425 7.98749 5.425C8.26249 5.425 8.49999 5.51667 8.69999 5.7C8.89999 5.9 8.99999 6.1375 8.99999 6.4125C8.99999 6.6875 8.89999 6.925 8.69999 7.125L4.82499 11ZM10.825 12L13.725 14.9C13.925 15.1 14.0208 15.3333 14.0125 15.6C14.0042 15.8667 13.9 16.1 13.7 16.3C13.5 16.4833 13.2667 16.5792 13 16.5875C12.7333 16.5958 12.5 16.5 12.3 16.3L7.69999 11.7C7.59999 11.6 7.52915 11.4917 7.48749 11.375C7.44582 11.2583 7.42499 11.1333 7.42499 11C7.42499 10.8667 7.44582 10.7417 7.48749 10.625C7.52915 10.5083 7.59999 10.4 7.69999 10.3L12.3 5.7C12.4833 5.51667 12.7125 5.425 12.9875 5.425C13.2625 5.425 13.5 5.51667 13.7 5.7C13.9 5.9 14 6.1375 14 6.4125C14 6.6875 13.9 6.925 13.7 7.125L10.825 10H17C18.3833 10 19.5625 10.4875 20.5375 11.4625C21.5125 12.4375 22 13.6167 22 15V18C22 18.2833 21.9042 18.5208 21.7125 18.7125C21.5208 18.9042 21.2833 19 21 19C20.7167 19 20.4792 18.9042 20.2875 18.7125C20.0958 18.5208 20 18.2833 20 18V15C20 14.1667 19.7083 13.4583 19.125 12.875C18.5417 12.2917 17.8333 12 17 12H10.825Z"
      fill="black"
      fillOpacity="0.56"
    />
  </SvgIcon>
);
