import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

import { BaseRow } from './types';

type Callback<Row> = (row: Row[keyof Row]) => string[] | string;

type CustomCallbacks<Row> = {
  [key: string]: Callback<Row>;
};

type SetCustomCallback<Row extends BaseRow> = {
  customCallback: Callback<Row>;
  key: string;
  tableId: string;
};

type GetCustomCallback<Row extends BaseRow> = Pick<
  SetCustomCallback<Row>,
  'key' | 'tableId'
>;

export type SortColumnContextValue<Row extends BaseRow> = {
  customCallbacks: CustomCallbacks<Row>;
  getCustomCallback: ({
    key,
    tableId,
  }: GetCustomCallback<Row>) => Callback<Row>;
  setCustomCallback: ({
    customCallback,
    key,
    tableId,
  }: SetCustomCallback<Row>) => void;
};

const SortColumnContext = React.createContext(null);

export function useSortColumnContext<Row extends BaseRow>() {
  return useContext<SortColumnContextValue<Row>>(SortColumnContext);
}

export function SortColumnContextProvider<Row extends BaseRow>({
  children,
}: PropsWithChildren<{}>) {
  const customCallbacks = useRef<CustomCallbacks<Row>>({});

  const getCustomCallback = useCallback(
    ({ key, tableId }: GetCustomCallback<Row>) =>
      customCallbacks.current[`${tableId}.${key}`],
    [customCallbacks]
  );

  const setCustomCallback = useCallback(
    ({ customCallback, key, tableId }: SetCustomCallback<Row>) =>
      (customCallbacks.current[`${tableId}.${key}`] = customCallback),
    [customCallbacks]
  );

  const sortColumnContextValue = useMemo(
    () => ({
      getCustomCallback,
      setCustomCallback,
    }),
    [getCustomCallback, setCustomCallback]
  );

  return (
    <SortColumnContext.Provider value={sortColumnContextValue}>
      {children}
    </SortColumnContext.Provider>
  );
}
