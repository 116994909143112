import { useTranslation } from 'react-i18next';

import { TbqCategory, TbqGoal } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Text } from '@pro4all/shared/ui/typography';

import {
  BarPercentageContainer,
  Percentage,
  ProgressBar,
  ProgressBarsWrapper,
  ProgressItem,
  SubTitle,
} from './Progressbar.styles';

export const ResultsProgressBars = ({
  categories,
  operationGoals,
  onClickProgress,
}: {
  categories: TbqCategory[] | undefined;
  onClickProgress: (
    param: 'categoryId' | 'goalId',
    id: string,
    name: string
  ) => void;
  operationGoals: TbqGoal[] | undefined;
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  if (!categories) return null;
  if (!operationGoals) return null;

  return (
    <>
      <ProgressBarsWrapper>
        <SubTitle variant="h4">Operationele doelen</SubTitle>
        {operationGoals.map(({ id, name, percentage }: TbqGoal) => (
          <ProgressItem
            $selected={searchParams.is('goalId', id)}
            key={id}
            onClick={() => onClickProgress('goalId', id, name ?? '')}
          >
            <Text>{name}</Text>
            <BarPercentageContainer>
              <ProgressBar
                max="100"
                value={
                  percentage === 0
                    ? 0
                    : Math.min(Math.max(2, Math.ceil(percentage * 100), 1))
                }
              />
              <Percentage>{`${Math.ceil(percentage * 100)}%`}</Percentage>
            </BarPercentageContainer>
          </ProgressItem>
        ))}
      </ProgressBarsWrapper>
      <ProgressBarsWrapper>
        <SubTitle variant="h4">{t('Categories')}</SubTitle>
        {categories.map(({ id, name, percentage }: TbqCategory) => (
          <ProgressItem
            $selected={searchParams.is('categoryId', id)}
            key={id}
            onClick={() => onClickProgress('categoryId', id, name ?? '')}
          >
            <Text>{name}</Text>
            <BarPercentageContainer>
              <ProgressBar
                key={name}
                max="100"
                value={
                  percentage === 0
                    ? 0
                    : Math.min(Math.max(2, Math.ceil(percentage * 100), 1))
                } // +1 when is 1 to adjust UI bar radius
              />
              <Percentage>{`${Math.ceil(percentage * 100)}%`}</Percentage>
            </BarPercentageContainer>
          </ProgressItem>
        ))}
      </ProgressBarsWrapper>
    </>
  );
};
