import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const FlagHolland: React.FC<SvgIconProps> = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 22 14"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 9.33333H22V14H0V9.33333Z" fill="#0052B4" />
    <path d="M0 0H22V4.66667H0V0Z" fill="#DF0F0F" />
    <path d="M0 4.66667H22V9.33333H0V4.66667Z" fill="white" />
  </svg>
);
