import { useLayoutEffect } from 'react';

import { useQualityControlGetOrCreateInstanceQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useRedirect = () => {
  const {
    searchParams,
    params: { projectId },
  } = useRouting();

  const resultId = searchParams.get('id') ?? '';
  const taskId = searchParams.get('taskId') || '';
  const templateId = searchParams.get('templateId');

  // For those who think: WTF!?
  // --------------------------------------------------------------------------
  // Like the Mobile Client, we need to create an instance beforehand in some
  // cases when accessing Result Forms. The useLayoutEffect below acts as a
  // "redirect" where we update the searchParams with our instance id resulting
  // from the following query:
  const { data } = useQualityControlGetOrCreateInstanceQuery({
    skip: Boolean(resultId) || !taskId || !templateId || !projectId,
    variables: {
      projectId: projectId ?? '',
      taskId,
      templateId: templateId ?? '',
    },
  });

  useLayoutEffect(() => {
    if (!resultId && data?.qualityControlGetOrCreateInstance?.id) {
      searchParams.replace({
        id: data?.qualityControlGetOrCreateInstance?.id,
        projectId: undefined,
        templateId: undefined,
      });
    }
  });
};
