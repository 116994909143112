import React, {
  createContext,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FieldDefinition, TemplateType } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { usePersistentState } from '@pro4all/shared/hooks';

import { CoordinatesType } from './types';

type MapLinkingContextValue = {
  centering: boolean;
  coordinates: CoordinatesType;
  fieldId: string;
  fields: FieldDefinition[];
  formId: string;
  iconScale: number;
  isContextMenuOpen: boolean;
  isSelectTemplateDialogOpen: boolean;
  previousRoute: { current: keyof typeof Routes };
  selectedTemplateType: TemplateType;
  setCentering: (value: boolean) => void;
  setCoordinates: (value: CoordinatesType) => void;
  setFieldId: (value: string) => void;
  setFields: (value: FieldDefinition[]) => void;
  setFormInstanceId: (value: string) => void;
  setIconScale: React.Dispatch<SetStateAction<number>>;
  setIsContextMenuOpen: (value: boolean) => void;
  setIsSelectTemplateDialogOpen: (value: boolean) => void;
  setPreviousRoute: (route: keyof typeof Routes) => void;
  setSelectedTemplateType: (value: TemplateType) => void;
  setTbqInstance: (value: boolean) => void;
  tbqInstance: boolean;
};

export const MapLinkingContext = createContext(null);
export function useMapLinkingContext() {
  const {
    centering,
    coordinates,
    isContextMenuOpen,
    isSelectTemplateDialogOpen,
    previousRoute,
    selectedTemplateType,
    fields,
    formId,
    iconScale,
    setIconScale,
    tbqInstance,
    fieldId,
    setCentering,
    setCoordinates,
    setIsContextMenuOpen,
    setFormInstanceId,
    setTbqInstance,
    setFields,
    setFieldId,
    setIsSelectTemplateDialogOpen,
    setPreviousRoute,
    setSelectedTemplateType,
  } = useContext<MapLinkingContextValue>(MapLinkingContext);

  return {
    centering,
    coordinates,
    fieldId,
    fields,
    formId,
    iconScale,
    isContextMenuOpen,
    isSelectTemplateDialogOpen,
    previousRoute,
    selectedTemplateType,
    setCentering,
    setCoordinates,
    setFieldId,
    setFields,
    setFormInstanceId,
    setIconScale,
    setIsContextMenuOpen,
    setIsSelectTemplateDialogOpen,
    setPreviousRoute,
    setSelectedTemplateType,
    setTbqInstance,
    tbqInstance,
  };
}

export const MapLinkingContextProvider: React.FC = ({ children }) => {
  const [coordinates, setCoordinates] = useState<CoordinatesType>();
  const [formId, setFormInstanceId] = useState<string>();
  const [fieldId, setFieldId] = useState<string>();
  const [fields, setFields] = useState<FieldDefinition[]>();
  const [centering, setCentering] = useState<boolean>(false);
  const [tbqInstance, setTbqInstance] = useState<boolean>(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const [persistentIconScale, setPersistentIconScale] =
    usePersistentState<number>('iconScale', 1);
  const [iconScale, setIconScale] = useState(persistentIconScale ?? 1);

  const [isSelectTemplateDialogOpen, setIsSelectTemplateDialogOpen] =
    useState(false);
  const [selectedTemplateType, setSelectedTemplateType] =
    useState<TemplateType>();

  const previousRoute = useRef(null);
  const setPreviousRoute = (route: keyof typeof Routes) =>
    (previousRoute.current = route);

  const value = useMemo(
    () => ({
      centering,
      coordinates,
      fieldId,
      fields,
      formId,
      iconScale,
      isContextMenuOpen,
      isSelectTemplateDialogOpen,
      previousRoute,
      selectedTemplateType,
      setCentering,
      setCoordinates,
      setFieldId,
      setFields,
      setFormInstanceId,
      setIconScale,
      setIsContextMenuOpen,
      setIsSelectTemplateDialogOpen,
      setPreviousRoute,
      setSelectedTemplateType,
      setTbqInstance,
      tbqInstance,
    }),
    [
      fields,
      formId,
      fieldId,
      tbqInstance,
      centering,
      setCentering,
      coordinates,
      isContextMenuOpen,
      iconScale,
      isSelectTemplateDialogOpen,
      selectedTemplateType,
    ]
  );

  return (
    <MapLinkingContext.Provider value={value}>
      {children}
    </MapLinkingContext.Provider>
  );
};
