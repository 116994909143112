import {
  FacetItem,
  FieldDefinitionPropsFragmentFragment,
  Maybe,
  ValueTypeName,
} from '@pro4all/graphql';

import { TFieldDefinitionWithPath } from './SelectSnagFilterModal';

export const sortAlphabetically = (
  a?: Maybe<FacetItem>,
  b?: Maybe<FacetItem>
) => (a?.apiValue || '').localeCompare(b?.apiValue || '');

export const searchByQuery = (item: Maybe<FacetItem>, query: string) =>
  item?.apiValue?.toLowerCase().includes(query.toLowerCase());

export const addPathToSnagItems = (
  items: FieldDefinitionPropsFragmentFragment[],
  parentPath: string,
  currentPath: string[] = []
): TFieldDefinitionWithPath[] => {
  if (!items) return [];

  let paths: TFieldDefinitionWithPath[] = [];

  items.forEach((currentItem) => {
    if (Array.isArray(currentItem)) {
      const subPaths = addPathToSnagItems(currentItem, parentPath, currentPath);

      paths = paths.concat(subPaths);
    } else {
      const newPath = parentPath
        ? [...currentPath, currentItem.name]
        : [currentItem.name];

      if (currentItem.type === ValueTypeName.Section) {
        const subPaths = addPathToSnagItems(
          currentItem?.valueType?.subFields || [],
          newPath.join(),
          newPath as string[]
        );
        paths = paths.concat(subPaths);
      } else {
        paths.push({ ...currentItem, path: newPath as string[] });
      }
    }
  });

  return paths;
};
