import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { ActionType, fileUploadReducer, State } from './fileUploadReducer';

type AnswerEditContextValue = {
  canSubmit: boolean;
  linkedTaskId: string;
  setCanSubmit: (value: boolean) => void;
  setLinkedTaskId: (value: string) => void;
  startUploading: (id: string) => void;
  state: State;
  stopUploading: () => void;
};

export const AnswerEditContext = createContext(null);

export function useAnswerEditContext() {
  const {
    canSubmit,
    setCanSubmit,
    startUploading,
    state,
    stopUploading,
    linkedTaskId,
    setLinkedTaskId,
  } = useContext<AnswerEditContextValue>(AnswerEditContext);

  return {
    canSubmit,
    linkedTaskId,
    setCanSubmit,
    setLinkedTaskId,
    startUploading,
    state,
    stopUploading,
  };
}

export const AnswerEditContextProvider: React.FC = ({ children }) => {
  const [canSubmit, setCanSubmit] = useState(true);
  const [linkedTaskId, setLinkedTaskId] = useState<string>('');

  const [state, dispatch] = useReducer(fileUploadReducer, {
    idToUploadFor: '',
  });

  // Define reducer actions
  const startUploading = useCallback((id) => {
    dispatch({ payload: id, type: ActionType.START_UPLOADING });
  }, []);
  const stopUploading = useCallback(() => {
    dispatch({ type: ActionType.STOP_UPLOADING });
  }, []);

  const value = useMemo(
    () => ({
      canSubmit,
      linkedTaskId,
      setCanSubmit,
      setLinkedTaskId,
      startUploading,
      state,
      stopUploading,
    }),
    [
      canSubmit,
      setCanSubmit,
      startUploading,
      state,
      stopUploading,
      linkedTaskId,
      setLinkedTaskId,
    ]
  );

  return (
    <AnswerEditContext.Provider value={value}>
      {children}
    </AnswerEditContext.Provider>
  );
};
