import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import {
  Group,
  useOrganizationUsersIncludeQuery,
  useProjectMembersIncludeQuery,
} from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import type { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikForm,
  FormikInput,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { getInitialValues } from './getInitialValues';
import { GroupSelect } from './GroupSelect';
import { MemberSelect } from './MemberSelect';
import { useGroupFormConfig } from './useGroupFormConfig';
import { useSubmitGroupForm } from './useSubmitGroupForm';

interface Props {
  group?: Group;
  onClose: () => void;
}

export const GroupForm: React.FC<Props> = ({ group, onClose }) => {
  const { t } = useTranslation();

  const toIds = (value: Option) => value.id;

  const { projectId } = useRouting().params;
  const inputRef = useRef(null);
  const [selectedGroupMemberIds, setSelectedGroupMemberIds] =
    useState<string[]>();

  const includeProps = {
    includeActive: true,
    includeEmail: true,
    includeInvited: true,
  };

  const { data: dataProject, loading: loadingProject } =
    useProjectMembersIncludeQuery({
      fetchPolicy: 'cache-and-network',
      skip: !projectId,
      variables: {
        ...includeProps,
        projectId,
      },
    });

  const { data: dataOrg, loading: loadingOrg } =
    useOrganizationUsersIncludeQuery({
      fetchPolicy: 'cache-and-network',
      skip: Boolean(projectId),
      variables: {
        ...includeProps,
      },
    });

  const loading = projectId ? loadingProject : loadingOrg;

  const members =
    (projectId ? dataProject?.projectMembers : dataOrg?.organizationUsers) ||
    [];

  const options = useMemberOptions(members, {
    includeNotInvited: false,
  });

  const submitGroupForm = useSubmitGroupForm(group);
  const initialValues = getInitialValues(group);

  const initialMembers = initialValues?.members.map(toIds);

  const filteredOptions = options.filter(({ id }) =>
    selectedGroupMemberIds
      ? !selectedGroupMemberIds?.includes(id)
      : !initialMembers?.includes(id)
  );

  const { getField, validationSchema } = useGroupFormConfig();
  const nameField = getField('displayName');

  const onChangeGroupMember = (values: (string | Option)[]) => {
    const ids = values.map(toIds);
    setSelectedGroupMemberIds(ids);
  };

  return (
    <Formik
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={async (values) => {
        submitGroupForm({
          initialValues,
          onClose,
          values,
        });
      }}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <FormikInput
              autoFocus
              inputRef={inputRef}
              label={nameField.label}
              name={nameField.name}
              placeholder={t('Name')}
            />
            <MemberSelect
              loading={loading}
              onChange={onChangeGroupMember}
              options={filteredOptions}
            />
            <GroupSelect groupId={group?.id} />
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
