import { MessageStatus, useUpdateDraftMessageMutation } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';

import { handleOptimisticUpdate } from './handleOptimisticUpdate';
import { generateOptimisticTables, isUserInMessage } from './utils';

interface Props {
  messageId: string;
}

export const useOptimisticDraftMessageUpdate = ({ messageId }: Props) => {
  const { params, searchParams } = useRouting();
  const { projectId } = params;
  const replyId = searchParams.get('replyId');
  const { userDisplayName, userEmail } = useOrganizationContext();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  return useUpdateDraftMessageMutation({
    update: (cache, { data }) => {
      if (data?.updateDraftMessage) {
        const userInMessage = isUserInMessage(
          data.updateDraftMessage,
          userDisplayName,
          userEmail
        );
        const isDraft = data.updateDraftMessage.status === MessageStatus.Draft;
        const optimisticTables = generateOptimisticTables({
          isDraft,
          onlyUpdateDraft: true,
          userInMessage,
        });

        handleOptimisticUpdate({
          cache,
          data: data.updateDraftMessage,
          messageId,
          optimisticTables,
          organizationId,
          projectId,
          replyId,
          userDisplayName,
        });
      }
    },
  });
};
