import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Filter, SmartFolder, Tag, useTagsQuery } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Option } from '@pro4all/shared/types';
import {
  FormError,
  FormFooter,
  FormikCheckboxGroup,
  FormikForm,
  FormikInput,
  FormikSearchableMultiSelect,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useSmartFolderFormConfig } from './useSmartFolderFormConfig';
import { useSubmit } from './useSubmit';

export interface SmartFolderFormProps {
  onClose: () => void;
  smartFolder?: SmartFolder;
}

export const SmartFolderForm: React.FC<SmartFolderFormProps> = ({
  onClose,
  smartFolder,
}) => {
  const { t } = useTranslation();
  const { data } = useTagsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const allTags: Tag[] = data?.tags || [];
  const tagOptions = allTags.map((tag) => ({
    id: tag.id,
    label: tag.name,
  }));
  let selectedTags: Option[] = [];
  let selectedFileTypes: string[] = [];

  if (smartFolder) {
    const selectedTagIds = smartFolder.filters
      .filter((filter: Filter) => filter.property === 'Tag')
      .map((tag: Filter) => tag.value);

    selectedFileTypes = smartFolder.filters
      .filter((filter: Filter) => filter.property === 'Extension')
      .map((fileType: Filter) => fileType.value);

    // Temporary hack code to get the tag labels. API doesn't serve them yet.
    selectedTags = allTags
      .filter((selectedTag) => selectedTagIds.includes(selectedTag.id))
      .map((tag) => ({ id: tag.id, label: tag.name }));
  }

  const checkboxOptions = [
    { id: '.avi', label: '.avi' },
    { id: '.css', label: '.css' },
    { id: '.docx', label: '.docx' },
    { id: '.dwg', label: '.dwg' },
    { id: '.exe', label: '.exe' },
    { id: '.gif', label: '.gif' },
    { id: '.html', label: '.html' },
    { id: '.ifc', label: '.ifc' },
    { id: '.jpg', label: '.jpg' },
    { id: '.mp3', label: '.mp3' },
    { id: '.mp4', label: '.mp4' },
    { id: '.nwd', label: '.nwd' },
    { id: '.pdf', label: '.pdf' },
    { id: '.php', label: '.php' },
    { id: '.png', label: '.png' },
    { id: '.pptx', label: '.pptx' },
    { id: '.rvt', label: '.rvt' },
    { id: '.txt', label: '.txt' },
    { id: '.xlsx', label: '.xlsx' },
  ];

  const { getField, validationSchema } = useSmartFolderFormConfig();
  const nameField = getField('name');
  const fileTypesField = getField('fileTypes');
  const tagsField = getField('tags');

  const onSubmit = useSubmit({ allTags, onClose, smartFolder });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        fileTypes: selectedFileTypes,
        name: smartFolder?.name || '',
        tags: selectedTags,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, status }) => (
        <FormikForm>
          <FormWrapper>
            <FormikInput
              autoFocus
              label={nameField.label}
              name={nameField.name}
            />
            <FormikCheckboxGroup
              label={fileTypesField.label}
              name={fileTypesField.name}
              options={checkboxOptions}
              row
            />
            <FormikSearchableMultiSelect
              canAddNewOptions
              label={tagsField.label}
              limitTags={5}
              name={tagsField.name}
              options={tagOptions}
              placeholder={t('Add tags')}
            />

            {status === 'failed' && (
              <FormError>
                {t('Something went wrong. Please try again.')}
              </FormError>
            )}
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
