import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@pro4all/shared/ui/buttons';
import { DiscardWrapper } from '@pro4all/shared/ui/messages';

import type { Props } from '../useTBQSettings';
import { useTBQSettings } from '../useTBQSettings';

import { TBQEntityInput } from './TBQEntityInput';
import { TBQEntitySettings } from './TBQEntitySettings';
import * as Styled from './TBQSettingsForm.styles';

export const TBQSettingsForm = ({
  id,
  name,
  refetch,
  tbqConfig,
  tbqFunction,
  tbqFunctionAllowed,
  tbqType,
  tbqTypeAllowed,
}: Props) => {
  const { t } = useTranslation();
  const { cancel, form, optionsFunction, optionsType, save } = useTBQSettings({
    id,
    name,
    refetch,
    tbqConfig,
    tbqFunction,
    tbqFunctionAllowed,
    tbqType,
    tbqTypeAllowed,
  });

  return (
    <FormProvider {...form}>
      <DiscardWrapper dirty={form.formState.isDirty}>
        <Styled.Form onSubmit={save}>
          <div>
            {Boolean(optionsType.length) && (
              <TBQEntityInput
                disabled={Boolean(tbqType)}
                id="tbqType"
                label={t('Type')}
                name="tbqType"
                options={optionsType}
              />
            )}
            {Boolean(optionsFunction.length) && (
              <TBQEntityInput
                disabled={Boolean(tbqFunction)}
                id="tbqFunction"
                label={t('Function')}
                name="tbqFunction"
                options={optionsFunction}
              />
            )}
          </div>

          <TBQEntitySettings />

          <Styled.Footer $sticky>
            <Button color="inherit" onClick={cancel} variant="outlined">
              {t('Cancel')}
            </Button>
            <Button
              disabled={form.formState.isSubmitting}
              startIcon="save"
              type="submit"
              variant="contained"
            >
              {t('Save')}
            </Button>
          </Styled.Footer>
        </Styled.Form>
      </DiscardWrapper>
    </FormProvider>
  );
};
