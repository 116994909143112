import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

import { MoveExistingUserProps } from './types';

export const JoinedNewOrganization = ({
  newOrganizationName,
}: Pick<MoveExistingUserProps, 'newOrganizationName'>) => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({ isInviteExistingUser: true });

  const description = (
    <>
      <Trans
        components={{ strong: <strong /> }}
        defaults="Congratulations, you are now working in <strong>{{newOrganizationName}}</strong>!"
        values={{
          newOrganizationName,
        }}
      />
      <br />
      <br />
      {t(
        'Login and get started in your new organization. Collaborate with collegues to become the best in what you do.'
      )}
    </>
  );

  return (
    <InfoPage
      actions={[actionLogin]}
      description={description}
      title={t('You joined {{newOrganizationName}}', {
        newOrganizationName,
      })}
    />
  );
};
