import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  GetSignatureDocument,
  useAddSignatureMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useAddSignatureHandler = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userId, userOrganizationId } = useOrganizationContext();
  const { goBack } = useRouting();

  const [addSignature, { loading: addSignatureIsLoading }] =
    useAddSignatureMutation({
      onCompleted: () => {
        enqueueSnackbar(t('Changes are successfully saved'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Failed'), {
          variant: 'error',
        });
      },
      update: (cache, _, { variables }) => {
        cache.writeQuery({
          data: {
            getSignature: {
              data: {
                body: variables?.signatureBody,
              },
            },
          },
          query: GetSignatureDocument,
        });
      },
    });

  const addSignatureHandler = (signatureBody: string, signatureId?: string) => {
    addSignature({
      variables: {
        organizationId: userOrganizationId,
        signatureBody,
        signatureId: signatureId,
        userId,
      },
    });
    goBack();
    goBack();
  };

  return { addSignatureHandler, addSignatureIsLoading };
};
