import React from 'react';

import { IconButton } from '@pro4all/shared/ui/buttons';

export const PrintButton: React.FC = () => (
  <IconButton
    color="default"
    iconName="print"
    onClick={() => window.print()}
    size="large"
  />
);
