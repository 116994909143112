import { useTranslation } from 'react-i18next';

import { LoginMethodOption, ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useOrganizationSettingsFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Login', // i18n
      name: 'login_method',
      options: [
        {
          id: LoginMethodOption.NotOnlySso,
          label: t(t(LoginMethodOption.NotOnlySso)),
        },
        {
          id: LoginMethodOption.OnlySso,
          label: t(t(LoginMethodOption.OnlySso)),
        },
      ],
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
