import {
  DocumentVersionState,
  FinalizationState,
  StampState,
} from '@pro4all/graphql';

export const documentStateLabels: { [key in FinalizationState]: string } = {
  [FinalizationState.NotFinalized]: 'Not {{name}}',
  [FinalizationState.Finalized]: '{{name}}',
};

export const documentStateValues: { [key in FinalizationState]: string } = {
  [FinalizationState.NotFinalized]: '0',
  [FinalizationState.Finalized]: '1',
};

export const stampStateLabels: { [key in StampState]: string } = {
  [StampState.None]: 'Not stamped',
  [StampState.Done]: 'Stamped',
};

export const stampStateValues: { [key in StampState]: string } = {
  [StampState.None]: '0',
  [StampState.Done]: '2',
};

export const documentVersionStateLabels: {
  [key in DocumentVersionState]: string;
} = {
  [DocumentVersionState.None]: 'None',
  [DocumentVersionState.Approved]: 'Approved',
  [DocumentVersionState.Rejected]: 'Rejected',
};

export const documentVersionStateValues: {
  [key in DocumentVersionState]: string;
} = {
  [DocumentVersionState.None]: '0',
  [DocumentVersionState.Approved]: '2',
  [DocumentVersionState.Rejected]: '1',
};
