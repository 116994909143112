import { useEffect, useRef } from 'react';

import { UploaderEditorTypes } from '../types';

type Props = Pick<
  UploaderEditorTypes,
  'errorString' | 'isTypingFromHeader' | 'warningString'
>;

export const usePreventLoseCursorFocus = ({
  errorString,
  isTypingFromHeader,
  warningString,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // If errorString or warningString change, we wanna keep cursor focus on the textfield input.
    if (inputRef.current && !isTypingFromHeader) {
      inputRef.current.focus();
    }
  }, [errorString, isTypingFromHeader, warningString]);

  return { inputRef };
};
