import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { TrackingEvent } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { Typography, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import {
  getBarChartData,
  getTimeFormatForTimeOption,
  useBarChartTimeOptions,
} from './helpers/BarChartHelpers';
import {
  ChartGroupWrapper,
  ChartInputWrapper,
  ChartSelect,
  ChartWrapper,
} from './styled';
import {
  BarChartData,
  ChartOption,
  CommonChartProps,
  TimeOption,
} from './types';

export const BarChart: React.FC<
  {
    data: any[];
    initialTimeClusterOptionId?: string;
    initialTimeOptionId?: string;
    timeClusterOptions: ChartOption[];
  } & CommonChartProps
> = ({
  chartKey,
  columnSpan,
  data,
  initialTimeClusterOptionId,
  initialTimeOptionId,
  rowSpan,
  timeClusterOptions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { params } = useRouting();
  const { projectId } = params;
  const { projectOrganizationId } = useProjectContext();
  const BarChartTimeOptions = useBarChartTimeOptions();

  // Local storage for time option
  const {
    setLocalStorageItem: setLocalStorageTimeOption,
    getLocalStorageItem: getLocalStorageTimeOption,
  } = useLocalStorage<string>({
    key: `barchart-timeoption-${chartKey}`,
  });

  const localStorageTimeOptionId = getLocalStorageTimeOption();
  const timeOptionFromLocalStorage = BarChartTimeOptions.find(
    (timeOption) => timeOption.id === localStorageTimeOptionId
  );
  const initialTimeOption = initialTimeOptionId
    ? BarChartTimeOptions.find((option) => option.id === initialTimeOptionId)
    : undefined;

  const [currentTimeOption, setCurrentTimeOption] = useState<TimeOption>(
    timeOptionFromLocalStorage || initialTimeOption || BarChartTimeOptions[0]
  );

  // Local storage for time cluster option
  const {
    setLocalStorageItem: setLocalStorageTimeClusterOption,
    getLocalStorageItem: getLocalStorageTimeClusterOption,
  } = useLocalStorage<string>({
    key: `barchart-timeclusteroption-${chartKey}`,
  });

  const localStorageTimeClusterOptionId = getLocalStorageTimeClusterOption();
  const timeClusterOptionFromLocalStorage = timeClusterOptions.find(
    (timeClusterOption) =>
      timeClusterOption.id === localStorageTimeClusterOptionId
  );
  const initialTimeClusterOption = initialTimeClusterOptionId
    ? timeClusterOptions.find(
        (option) => option.id === initialTimeClusterOptionId
      )
    : undefined;

  const [currentTimeClusterOption, setCurrentTimeClusterOption] =
    useState<ChartOption>(
      timeClusterOptionFromLocalStorage ||
        initialTimeClusterOption ||
        timeClusterOptions[0]
    );
  const [currentChartData, setCurrentChartData] = useState<BarChartData | null>(
    null
  );

  useEffect(() => {
    setCurrentChartData(
      getBarChartData(data, currentTimeOption, currentTimeClusterOption)
    );
  }, [currentTimeOption, data, currentTimeClusterOption]);

  function handleTimeOptionChange(option: TimeOption) {
    setCurrentTimeOption(option);
    setLocalStorageTimeOption(option.id);
  }

  function handleTimeClusterOptionChange(option: ChartOption) {
    setCurrentTimeClusterOption(option);
    setLocalStorageTimeClusterOption(option.id);
    track(TrackingEvent.ChartOptionSelected, {
      chartKey: `${chartKey}`,
      chartType: 'bar',
      option: currentTimeClusterOption.id,
      projectId,
      projectOrganizationId,
      timeOption: currentTimeOption.id,
    });
  }

  return (
    <ChartGroupWrapper
      columnSpan={columnSpan}
      data-testid={chartKey}
      rowSpan={rowSpan}
    >
      <ChartInputWrapper>
        <Typography variant="h6">{t('Show amount of')}</Typography>
        <ChartSelect
          name="Timeclusters"
          onChange={(option) => {
            handleTimeClusterOptionChange(option as ChartOption);
          }}
          options={timeClusterOptions}
          size="small"
          value={currentTimeClusterOption.id} // Use the 'id' property instead of 'label'
        />
        <Typography variant="h6">{t('Per').toLowerCase()}</Typography>
        <ChartSelect
          name="Timescale"
          onChange={(option) => {
            handleTimeOptionChange(option as TimeOption);
          }}
          options={BarChartTimeOptions}
          size="small"
          value={currentTimeOption.id}
        />
      </ChartInputWrapper>
      {currentChartData && (
        <ChartWrapper>
          <Bar
            data={currentChartData}
            options={{
              layout: {
                padding: {
                  bottom: Number(theme.spacing(4).replace('px', '')),
                  top: Number(theme.spacing(4).replace('px', '')),
                },
              },
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  align: 'top',
                  anchor: 'end',
                  display: 'auto',
                  offset: -4,
                },
                legend: {
                  display: false,
                  labels: {
                    padding: 5,
                    pointStyle: 'rectRounded',
                    usePointStyle: true,
                  },
                },
              },
              responsive: true,
              scales: {
                x: {
                  display: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                  time: {
                    displayFormats: {
                      day: getTimeFormatForTimeOption(
                        BarChartTimeOptions.find(
                          (option: TimeOption) => option.id === 'day'
                        )
                      ),
                      month: getTimeFormatForTimeOption(
                        BarChartTimeOptions.find(
                          (option: TimeOption) => option.id === 'month'
                        )
                      ),
                      week: getTimeFormatForTimeOption(
                        BarChartTimeOptions.find(
                          (option: TimeOption) => option.id === 'week'
                        )
                      ),
                    },
                    tooltipFormat:
                      getTimeFormatForTimeOption(currentTimeOption),
                    unit: currentTimeOption.id,
                  },
                  type: 'time',
                },
                y: {
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          ></Bar>
        </ChartWrapper>
      )}
    </ChartGroupWrapper>
  );
};

export default BarChart;
