import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/general';
import { useTableCheck } from '@pro4all/shared/ui/general';

import { useActivateUsers } from '../organization/actions/useActivateUsers';
import { useDeactivateUsers } from '../organization/actions/useDeactivateUsers';
import { useResendEmail } from '../utils/useResendEmail';

export const useUsersActions = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { userId, userOrganizationName } = useOrganizationContext();

  const activateUsers = useActivateUsers();
  const deactivateUsers = useDeactivateUsers();

  const hasInviteExistingUsersFeatureFlag = useFeatureFlag(
    'inviteexistingusers'
  );

  const resend = useResendEmail();

  const { noRowsAreChecked, checkedRows } = useTableCheck<User>();
  const { contextMenuRow } = useTableContextMenuRowContext<User>();

  // It is necessary to store the selected user in the context
  // menu in a state to avoid losing the selected
  // user after the `Edit Role` or `Add to Project` modal is opened.
  const [userToEdit, setUserToEdit] = useState<User>(null);

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');

  const handleOpenEditRole = () => {
    if (contextMenuRow) {
      setUserToEdit(contextMenuRow);
    }
    searchParams.set('action', 'editRoles');
  };

  const handleCloseEditRole = () => {
    setUserToEdit(null);
    searchParams.clear();
  };

  const handleOpenAddToProject = () => {
    if (contextMenuRow) {
      setUserToEdit(contextMenuRow);
    }
    searchParams.set({ action: 'addUsersToProject' });
  };

  const handleCloseAddToProject = () => {
    setUserToEdit(null);
    searchParams.clear();
  };

  const invalidUserActiveSelection = (): boolean => {
    const validUsers = checkedRows.filter(
      (user) => user.active && user.id !== userId
    );

    if (validUsers.length && !contextMenuRow) {
      return false;
    } else {
      // If we have contextMenuRow, check if its me. If we don't have context menu row, for sure it is not a valid selection
      const userIsNotValid = contextMenuRow
        ? [contextMenuRow.id].find((user) => user === userId)
        : true;

      return Boolean(userIsNotValid);
    }
  };

  const activateAction: ActionProps = {
    ariaLabel: t('Activate'),
    dataTestId: 'activate-user',
    disabled: userCannotUpdateOrg,
    key: 'activate-user',
    label: t('Activate'),
    onClick: activateUsers,
    startIcon: 'activeUser',
  };

  const deactivateAction: ActionProps = {
    ariaLabel: t('Deactivate'),
    dataTestId: 'deactivate-user',
    disabled: userCannotUpdateOrg || invalidUserActiveSelection(),
    key: 'deactivate-user',
    label: t('Deactivate'),
    onClick: deactivateUsers,
    startIcon: 'inactiveUser',
  };

  const resendAction: ActionProps = {
    ariaLabel: t('Send invite'),
    dataTestId: 'resend-email',
    disabled: userCannotUpdateOrg,
    key: 'resend-email',
    label: t('Send invite'),
    onClick: resend,
    startIcon: 'mail',
  };

  const commonActions: ActionProps[] = [
    {
      ariaLabel: t('Add to project'),
      dataTestId: 'add-user-to-project',
      disabled: userCannotUpdateProject,
      key: 'add-user-to-project',
      label: t('Add to project'),
      onClick: handleOpenAddToProject,
      startIcon: 'project',
    },
    {
      ariaLabel: t('Edit roles'),
      dataTestId: 'edit-user-roles',
      disabled: userCannotUpdateOrg,
      key: 'edit-user-roles',
      label: t('Edit roles'),
      onClick: handleOpenEditRole,
      startIcon: 'verifiedUser',
    },
  ];

  const altActions: ActionProps[] = [
    ...commonActions,
    {
      ...resendAction,
      disabled:
        resendAction.disabled ||
        (!noRowsAreChecked && checkedRows.every((user) => !user.invited)),
    },
    {
      ...activateAction,
      disabled:
        activateAction.disabled ||
        (!noRowsAreChecked && checkedRows.every((user) => user.active)),
    },
    {
      ...deactivateAction,
      disabled:
        deactivateAction.disabled ||
        (!noRowsAreChecked && checkedRows.every((user) => !user.active)),
    },
  ];

  const contextMenuActions: ActionProps[] = [
    ...commonActions,
    contextMenuRow && contextMenuRow.active ? deactivateAction : null,
    ...(contextMenuRow && !contextMenuRow.active
      ? [activateAction, resendAction]
      : []),
  ]
    .filter(Boolean)
    .filter((action) => !action.disabled);

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('Invite {- myOrganization} colleagues', {
        myOrganization: userOrganizationName,
      }),
      dataTestId: 'new-user',
      disabled: userCannotUpdateOrg,
      key: 'new-user',
      label: t('Invite {{- myOrganization}} colleagues', {
        myOrganization: userOrganizationName,
      }),
      onClick: () => searchParams.set({ action: 'inviteUsers' }),
      startIcon: 'personAdd',
    },
    {
      ariaLabel: t('Invite existing users'),
      dataTestId: 'new-user',
      disabled: userCannotUpdateOrg || !hasInviteExistingUsersFeatureFlag,
      key: 'invite-existing-users',
      label: t('Invite existing users'),
      onClick: () => searchParams.set({ action: 'inviteExistingUsers' }),
      startIcon: 'send',
    },
  ];

  return {
    altActions,
    contextMenuActions,
    handleCloseAddToProject,
    handleCloseEditRole,
    mainActions,
    userToEdit,
  };
};
