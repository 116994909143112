import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { Folder, FolderPermission } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';

import { shouldDisableOrOmitBasedOnSubfolderPermissions } from './entity-context/helpers/shouldDisableOrOmitBasedOnSubfolderPermissions';
import { usePermissionsEntityMatrixContext } from './entity-context/PermissionsEntityMatrixProvider';
import { removeExclusiveFolderPermissions } from './shared/helpers/removeExclusiveFolderPermissions';
import { PermissionEntityCell } from './PermissionEntityCell';
import { shouldPermissionEntityCellBeDisabled } from './shouldPermissionEntityCellBeDisabled';

export interface PermissionCellEntityWrapperProps {
  entityId: string;
  folder: Folder;
  permission: FolderPermission;
}

/**
 * We use this wrapper to achieve optimal rendering for PermissionEntityCell (the permission checkbox).
 * If we don't, ALL checkboxes will be re-rendered if a user clicks on whatever checkbox or changes
 * whatever value for whatever folder. Which would have a major bad impact on performance in case of thousands of folders.
 */
export const PermissionCellEntityWrapper = ({
  entityId,
  folder,
  permission,
}: PermissionCellEntityWrapperProps) => {
  const { t } = useTranslation();
  const { state, togglePermission } = usePermissionsEntityMatrixContext();
  const { displayPermissions } = state;
  const currentPermissions = displayPermissions.find(
    (perm) => perm.entityId === entityId
  );

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const { userId: loggedInUserId } = AuthService.getProfile();

  const {
    breakInheritance,
    folderPermissions,
    inheritedFrom,
    inheritedFromParentFolders,
    subFoldersPermissions,
  } = currentPermissions || {};

  const permissionsSetOnSubfolders = subFoldersPermissions
    ? subFoldersPermissions.map(
        (subFolderPermission) => subFolderPermission.permission
      )
    : [];

  const allPermissions =
    inheritedFrom && folderPermissions
      ? [
          ...new Set([
            ...inheritedFrom.map((inh) => inh.permission),
            ...folderPermissions,
          ]),
        ]
      : [];

  const allPermissionsFinal = removeExclusiveFolderPermissions({
    permissions: allPermissions,
  });

  const inheritedFromGroups = inheritedFrom
    ? inheritedFrom.find(
        (inheritedPermission) => inheritedPermission.permission === permission
      )?.groups || []
    : [];

  const inheritedFromParent = inheritedFromParentFolders
    ? inheritedFromParentFolders.some(
        (inheritedParentFolderPermission) =>
          inheritedParentFolderPermission.permission === permission
      )
    : false;

  const inheritedFromParentFolder = inheritedFromParentFolders
    ? inheritedFromParentFolders.find(
        (inheritedParentFolderPermission) =>
          inheritedParentFolderPermission.permission === permission
      )
    : null;

  const inheritedFromParentFolderName = inheritedFromParentFolder
    ? inheritedFromParentFolder.parentFolder.name
    : '';

  const disabled = breakInheritance
    ? loggedInUserId === entityId ||
      shouldPermissionEntityCellBeDisabled({
        allPermissions: allPermissionsFinal,
        entityId,
        permission,
        state,
      })
    : loggedInUserId === entityId ||
      inheritedFromParent ||
      shouldDisableOrOmitBasedOnSubfolderPermissions({
        permissionToCheck: permission,
        subFolderPermissions: permissionsSetOnSubfolders,
      }) ||
      shouldPermissionEntityCellBeDisabled({
        allPermissions: allPermissionsFinal,
        entityId,
        permission,
        state,
      });

  // We can extend this piece of code to fill 'tooltip' with all kinds of other reasons why a permission checkbox is disabled.
  const tooltip =
    disabled && inheritedFromParentFolderName
      ? t("Permission inherited from '{{folderName}}'.", {
          folderName: inheritedFromParentFolderName,
        })
      : '';

  return (
    <PermissionEntityCell
      checked={currentPermissions?.folderPermissions.includes(permission)}
      disabled={disabled}
      entityId={entityId}
      folder={folder}
      inheritedFromGroups={inheritedFromGroups ?? []}
      permission={permission}
      showFinalPermissions={showFinalPermissions}
      togglePermission={togglePermission}
      tooltip={tooltip}
    />
  );
};
