import { useCallback } from 'react';

import { client } from '@pro4all/authentication/src/graph-ql';
import { DocumentDocument, VersionDocument } from '@pro4all/graphql';
import { DocumentAndVersionType } from '@pro4all/shared/types';

export const useUpdateDocumentOrVersion = () => {
  const updateCachedDocumentOrVersion = useCallback(
    ({
      document,
      fieldToUpdate,
      value,
      version,
    }: {
      fieldToUpdate: string;
      value: any;
    } & DocumentAndVersionType) => {
      // Update the Apollo client cache if a single document.
      if (document) {
        client.writeQuery({
          data: {
            document: {
              ...document,
              [fieldToUpdate]: value,
            },
          },
          query: DocumentDocument,
          variables: { id: document.id },
        });
      }

      // Update the Apollo client cache if a single version.
      if (version) {
        client.writeQuery({
          data: {
            version: {
              ...version,
              [fieldToUpdate]: value,
            },
          },
          query: VersionDocument,
          variables: { id: version.id },
        });
      }
    },
    []
  );

  return updateCachedDocumentOrVersion;
};
