import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

const Container = styled(Box)`
  && {
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
    background-color: ${customColors.grey300};
    padding: ${({ theme }) => theme.spacing(2)};
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      background-color: ${customColors.grey300};
      border-radius: 5px;
    }

    p {
      line-height: 2em;
    }
  }
`;

export const ColoredText = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const tagLine = 'Powered by Prostream ';

export const DefaultMessageTemplate: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row">
      <Text>{tagLine}</Text>
      <ColoredText>{t('more')}</ColoredText>
    </Box>
  );
};

/* Displays a message with a configurable template*/
export const MessagePreview: React.FC<{
  templateContent?: React.ReactNode;
}> = ({ children, templateContent = <DefaultMessageTemplate /> }) => (
  <Container>
    {children && <Text>{children}</Text>}
    {templateContent}
  </Container>
);
