import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { SavedSearch } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { FilterBaseProps, SearchEntities } from './shared-search-types';
import { delimiters, parseFilterString } from './shared-search-utils';

enum TrackingEvent {
  SearchDispatched = 'SearchDispatched',
}

enum Action {
  ClearSearchHistoryClicked = 'ClearSearchHistoryClicked',
  RecentSearchClicked = 'RecentSearchClicked',
  SavedSearchQueryOpened = 'SavedSearchQueryOpened',
  SearchResultClicked = 'SearchResultClicked',
  TopTenSearchResultClicked = 'TopTenSearchResultClicked',
}

export enum Page {
  DMS = 'DMS',
  QualityControl = 'QualityControl',
  SearchResults = 'SearchResults',
}

export const PageType: { [key in SearchEntities]: Page } = {
  [SearchEntities.Document]: Page.DMS,
  [SearchEntities.QualityControl]: Page.QualityControl,
};

export const useSearchTracking = (type: SearchEntities) => {
  const { track } = useAnalytics();
  const { userId } = AuthService.getProfile();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { searchParams, params } = useRouting();
  const { projectId } = params;

  const paramQuery = searchParams.get('search');
  const page: Page = paramQuery === null ? PageType[type] : Page.SearchResults; // Gets a string representing the page for tracking purposes

  const trackSearchDispatched = (searchText: string, filterString?: string) => {
    const filters = filterString ? parseFilterString(filterString) : [];
    const searchFilters = filters?.length ? toFilterData(filters) : null;
    track(TrackingEvent.SearchDispatched, {
      organizationId,
      page,
      projectId,
      searchFilters,
      searchText,
      userId,
    });
  };

  const trackTopTenSearchResultClicked = (
    documentId: string,
    listIndex: number,
    searchText: string
  ) =>
    track(Action.TopTenSearchResultClicked, {
      documentId,
      listIndex,
      organizationId,
      page,
      projectId,
      searchText,
      userId,
    });

  const trackRecentSearchClicked = (searchText: string) =>
    track(Action.RecentSearchClicked, {
      organizationId,
      page,
      projectId,
      searchText,
      userId,
    });

  const trackClearSearchHistoryClicked = () =>
    track(Action.ClearSearchHistoryClicked, {
      organizationId,
      page,
      projectId,
      userId,
    });

  const trackSearchResultClicked = (documentId: string, listIndex: number) =>
    track(Action.SearchResultClicked, {
      documentId,
      listIndex,
      organizationId,
      projectId,
      searchText: paramQuery,
      userId,
    });

  const trackSavedSearchQueryOpened = (
    name: SavedSearch['name'],
    filterString: SavedSearch['searchFilter'],
    searchText: SavedSearch['searchQuery']
  ) => {
    const filters = filterString ? parseFilterString(filterString) : [];
    const searchFilters = filters?.length ? toFilterData(filters) : null;
    track(Action.SavedSearchQueryOpened, {
      name,
      organizationId,
      projectId,
      searchFilters,
      searchText,
      userId,
    });
  };

  return {
    trackClearSearchHistoryClicked,
    trackRecentSearchClicked,
    trackSavedSearchQueryOpened,
    trackSearchDispatched,
    trackSearchResultClicked,
    trackTopTenSearchResultClicked,
  };
};

const toFilterData = (filters: FilterBaseProps[]) =>
  filters
    .filter(({ value }) => Boolean(value))
    .map(({ type, value }) => ({
      count: value.split(delimiters.multiSelectOptions).length,
      type,
    }));
