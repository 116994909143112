import { State } from '../useUploaderEditorReducer';

export const addDocumentToContainerCreatedArrayAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const { documentIdsContainerCreated } = state;

  return {
    ...state,
    documentIdsContainerCreated: [...documentIdsContainerCreated, payload],
  };
};
