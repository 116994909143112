import { DocumentService } from '@pro4all/documents/data-access';
import { Document, DocumentVersion } from '@pro4all/graphql';

export const downloadDocument = async (document: Document) => {
  if (!document.isExpected) {
    await DocumentService.downloadDocument({
      document,
    });
  }
};

export const downloadVersion = async (version: DocumentVersion) => {
  await DocumentService.downloadDocument({
    version,
  });
};
