import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useTaskCategoriesValidationSchema = () => {
  const { t } = useTranslation();
  const characterLimit = 150;

  return {
    selectionOptions: () =>
      Yup.array()
        .of(
          Yup.object()
            .shape({
              name: Yup.string()
                .required(t('This field is required'))
                .max(characterLimit),
            })
            .test('cannotBeEmpty', '', function (option) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;

              if (option?.name) {
                return true;
              }
              return Promise.resolve(
                this.createError({
                  message: t("'{{name}}' is required", { name: t('Name') }),
                  path: `selectionOptions[${currentIndex}].name`,
                })
              );
            })
            .test('cannotContainOnlySpaces', '', function (option) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;

              if (option.name && option.name.trim() !== '') {
                return true;
              }
              return Promise.resolve(
                this.createError({
                  message: t('Cannot contain only spaces'),
                  path: `selectionOptions[${currentIndex}].name`,
                })
              );
            })
            .test('characterLimit', '', function (option) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;

              const exceedsCharLimit = option.name?.length > characterLimit;

              if (!exceedsCharLimit) {
                return true;
              }
              return Promise.resolve(
                this.createError({
                  message: t('Cannot exceed {{number}} characters', {
                    number: `${characterLimit}`,
                  }),
                  path: `selectionOptions[${currentIndex}].name`,
                })
              );
            })
        )
        .when(['type'], {
          is: (type) => type?.id === 'Selection',
          path: `newOptionError`,
          then: Yup.array().required(),
        }),
  };
};
