import { client } from '@pro4all/authentication/src/graph-ql';
import type { SavedAnswers } from '@pro4all/graphql';
import { SavedAnswersIncludeDocument } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { includeProps } from './constants';

export const useApolloClientCacheHelpers = () => {
  const {
    params: { projectId },
  } = useRouting();

  const variables = {
    ...includeProps,
    projectId,
  };

  const getCurrentlyCachedSavedAnswers = () => {
    const cachedSavedAnswers = client?.readQuery({
      query: SavedAnswersIncludeDocument,
      variables,
    }).savedAnswers as SavedAnswers[];
    return cachedSavedAnswers;
  };

  const updateCachedSavedAnswers = (updatedSavedAnswers: SavedAnswers[]) => {
    client.writeQuery({
      data: {
        savedAnswers: updatedSavedAnswers,
      },
      query: SavedAnswersIncludeDocument,
      variables,
    });
  };

  return { getCurrentlyCachedSavedAnswers, updateCachedSavedAnswers };
};
