import { FolderPermission } from '@pro4all/graphql';

import { permissionsToCheck } from '../../shared/types';
import { State } from '../types';

import { inConflictWithPermissionsOfOwnEntity } from './inConflictWithPermissionsOfOwnEntity';
import { inConflictWithPermissionsOfSubfolder } from './inConflictWithPermissionsOfSubfolder';

export const getNonConflictingPermissions = ({
  entityId,
  newPermissions,
  state,
}: {
  entityId: string;
  newPermissions: FolderPermission[];
  state: State;
}) => {
  let response = newPermissions;

  permissionsToCheck.forEach((permission) => {
    if (
      inConflictWithPermissionsOfOwnEntity({
        entityId,
        permission,
        state,
      })
    ) {
      response = response.filter((perm) => perm !== permission);
    }
    if (
      inConflictWithPermissionsOfSubfolder({
        entityId,
        permission,
        state,
      })
    ) {
      response = response.filter((perm) => perm !== permission);
    }
  });

  return response;
};
