import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { ScopeType, TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';

import { RedirectToOrganizationTemplates } from '../components/form-template/RedirectToOrganizationTemplates';
import { TemplateFrame } from '../components/form-template/TemplateFrame';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';
import { useIncludeOrgItems } from './useIncludeOrgItems';

export const ProjectQualityControl = () => {
  const routeForms = useRouteMatch(Routes.projectMetaDataQualityControlForms);
  const routeSnags = useRouteMatch(Routes.projectMetaDataQualityControlSnags);
  const routeTemplates = useRouteMatch(
    Routes.projectMetaDataQualityControlTemplates
  );
  const routeFields = useRouteMatch(Routes.projectMetaDataQualityControlFields);

  const includeOrgItems = useIncludeOrgItems();

  const templateService = TemplateService.QualityControl;
  const templateType = routeForms
    ? TemplateType.Form
    : routeSnags
    ? TemplateType.Snag
    : routeTemplates
    ? TemplateType.Section
    : routeFields
    ? TemplateType.Field
    : undefined;

  const qualityControlRoutesTemplates = [
    Routes.projectMetaDataQualityControlForms,
    Routes.projectMetaDataQualityControlTemplates,
    Routes.projectMetaDataQualityControlSnags,
  ];

  const qualityControlRoutesFields = [
    Routes.projectMetaDataQualityControlFields,
  ];

  const qualityControlRoutesReportConfigurations = [
    Routes.projectMetaDataQualityControlReportConfigurations,
  ];

  const { selectedTemplate } = useTemplatesInclude({
    includeOrgItems,
    includeScope: true,
    templateService,
    templateType,
  });
  const { params, searchParams } = useRouting();
  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');
  const scope = selectedTemplate?.scope?.type;

  const { projectId } = params;
  const base = `${generateRoute('projectMetaDataQualityControl', {
    params: { projectId },
  })}/`;
  const redirect = `${generateRoute('projectMetaDataQualityControlForms', {
    params: { projectId },
  })}`;

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <Route exact path={Routes.projectMetaDataQualityControl}>
        <Redirect to={redirect} />
      </Route>
      <Route
        exact
        path={[
          ...qualityControlRoutesTemplates,
          ...qualityControlRoutesFields,
          ...qualityControlRoutesReportConfigurations,
        ]}
        render={() =>
          !createContext && !editContext ? (
            <MetaDataMain
              base={base}
              isProject
              pathFields={qualityControlRoutesFields}
              pathReportConfigurations={
                qualityControlRoutesReportConfigurations
              }
              pathTemplates={qualityControlRoutesTemplates}
            />
          ) : (editContext && scope === ScopeType.Project) || createContext ? (
            <TemplateFrame selectedTemplate={selectedTemplate} />
          ) : (
            scope === ScopeType.Organization && (
              <RedirectToOrganizationTemplates
                selectedTemplate={selectedTemplate}
              />
            )
          )
        }
      />
    </MetaDataContextProvider>
  );
};
