import styled from 'styled-components';

import { TextField } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Container = styled.div`
  display: inline-flex;
  padding: 0;
  align-items: center;
  gap: 4px;
  font-size: 28px;
  font-weight: 700;
`;

export const Input = styled(TextField)`
  width: 60px;
  height: 36px;
  border-radius: 5px;
  outline: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;

  :disabled {
    background-color: ${customColors.grey300};
    color: ${customColors.grey500};
  }
`;
