import { Box } from '@pro4all/shared/mui-wrappers';

export const Layer = () => (
  <Box
    sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: '999',
    }}
  />
);
