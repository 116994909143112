import React from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { FieldDefinition, StandardItemKey } from '@pro4all/graphql';
import { DndTypes } from '@pro4all/shared/config';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { Box } from '@pro4all/shared/mui-wrappers';

import * as Styled from './FieldCard.styles';
import { fieldCardsGlobalInfo } from './fieldCardsGlobalInfo';
import { useFieldContext } from './TemplateMutationContext';

interface FieldCardProps {
  fieldDefinition: FieldDefinition;
}

export const FieldCardGlobal: React.FC<FieldCardProps> = ({
  fieldDefinition,
}) => {
  const { t } = useTranslation();
  const { name } = fieldDefinition;
  const { appendField } = useFieldContext();

  const nameStandardFieldDefinition = name as StandardItemKey;

  const cardData = fieldCardsGlobalInfo[nameStandardFieldDefinition];
  const getActionNamingMapping = useActionNamingMapping();

  const [, drag] = useDrag({
    item: { fieldDefinition },
    type: DndTypes.NOT_INCLUDED_FIELD,
  });

  return drag(
    <div>
      <Styled.Font variant="h6">
        <Styled.Wrapper mb={1} onClick={() => appendField(fieldDefinition)}>
          <Styled.FieldCard>
            <Styled.IconCard fontSize="inherit" iconName={cardData.icon} />
            <Box>
              {t(
                cardData.label,
                getActionNamingMapping(nameStandardFieldDefinition)
              )}
            </Box>
          </Styled.FieldCard>
        </Styled.Wrapper>
      </Styled.Font>
    </div>
  );
};
