import React from 'react';

import { OsidDeviceLog } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/general';

import { DeviceManager } from './DeviceManager';
import { TBQOsidLinkedDeviceLogs } from './TBQOsidLinkedDeviceLogs';
import { useOsidData } from './useOsidLogsData';

export const OsidLogs: React.FC = () => {
  const { params } = useRouting();
  const objectId = params.objectId ?? '';

  const {
    osidDevices,
    noOsidDevices,
    setSelectedDevice,
    isLinkedDevice,
    linkedDeviceName,
    handleLinkStatusChange,
    deviceLogs,
    osidDeviceLogLoading,
  } = useOsidData(objectId);

  return (
    <>
      <DeviceManager
        isLinkedDevice={isLinkedDevice}
        linkedDeviceId={linkedDeviceName}
        noOsidDevices={noOsidDevices}
        objectId={objectId}
        onDeviceSelect={setSelectedDevice}
        onLinkStatusChange={handleLinkStatusChange}
        osidData={osidDevices}
      />
      <OptimisticResponseProvider<OsidDeviceLog>>
        <TBQOsidLinkedDeviceLogs
          isLinkedDevice={isLinkedDevice}
          linkedDeviceLogs={deviceLogs}
          noOsidDevices={noOsidDevices}
          osidDeviceLogLoading={osidDeviceLogLoading}
        />
      </OptimisticResponseProvider>
    </>
  );
};
