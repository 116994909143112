import React from 'react';
import { useTranslation } from 'react-i18next';

import { TemplateService } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { CutCopyPasteSection } from '@pro4all/shared/types';

import { useMetaDataContext } from '../../views/MetaDataContext';

import {
  StyledCardNew,
  StyledIcon,
  StyledNewCards,
  StyledQuestionFieldName,
} from './Styles';
import { useFieldContext } from './TemplateMutationContext';

export const NewCardSectionPasteInRoot = () => {
  const { t } = useTranslation();
  const { templateService } = useMetaDataContext();
  const isQualityControl = templateService === TemplateService.QualityControl;

  const { pasteSection } = useFieldContext();

  const { getLocalStorageItem: getCutCopyPasteTemplateSectionFromLs } =
    useLocalStorage<CutCopyPasteSection>({
      key: StorageKeys.COPY_CUT_PASTE_TEMPLATE_SECTION,
    });

  const { sourceSection } = getCutCopyPasteTemplateSectionFromLs() || {};

  return isQualityControl && sourceSection ? (
    <StyledNewCards>
      <StyledCardNew
        onClick={() => pasteSection({ targetSectionOrField: null })}
      >
        <StyledIcon iconName="clipboard" />
        <StyledQuestionFieldName variant="h6">
          {t('Paste section')}
        </StyledQuestionFieldName>
      </StyledCardNew>
    </StyledNewCards>
  ) : null;
};
