import React from 'react';

import { FieldDefinition } from '@pro4all/graphql';

import { DisplaySectionProps } from './DisplaySection.types';
import { SectionsAndFieldsList } from './SectionsAndFieldsList';
import * as Styled from './SectionsAndFieldsList.styles';

export const DisplaySection = ({
  Edit,
  View,
  editMode,
  field,
  hideExcludedTypes,
  isDirty,
  isTBQ,
  level,
  linkedSnags,
  onSubmit,
  refetchQualityInstance,
  root,
  searchTerm,
  sectionPercentageCounter,
  unnestedItems,
}: DisplaySectionProps) => {
  const open = isTBQ ? false : true;

  const getSectionAnsweredPercentage = (
    field: FieldDefinition
  ): string | null => {
    if (!sectionPercentageCounter || sectionPercentageCounter.length === 0)
      return '0%';

    const sectionPercentage = sectionPercentageCounter.find(
      (section) => section.id === field.id
    );
    return sectionPercentage?.percentage ?? '0%';
  };

  return (
    <Styled.StyledSection
      $root={root}
      defaultOpen={open}
      formLinkedSnags={linkedSnags}
      id={field.id}
      key={field.id}
      level={level}
      linksAllowed={field.linksAllowed}
      refetchQualityInstance={refetchQualityInstance}
      sectionAnsweredQuestionsCount={getSectionAnsweredPercentage(field)}
      showContentGuides
      storeCollapseState
      title={field.displayName || field.name}
    >
      <SectionsAndFieldsList
        Edit={Edit}
        View={View}
        editMode={editMode}
        fields={field.valueType.subFields}
        hideExcludedTypes={hideExcludedTypes}
        isDirty={isDirty}
        isTBQ={isTBQ}
        level={level + 1}
        linkedSnags={linkedSnags}
        onSubmit={onSubmit}
        refetchQualityInstance={refetchQualityInstance}
        root={false}
        searchTerm={searchTerm}
        sectionPercentageCounter={sectionPercentageCounter}
        unnestedItems={unnestedItems}
      />
    </Styled.StyledSection>
  );
};
