import { useEffect } from 'react';

export const usePreventSubmitOnEnter = () => {
  useEffect(() => {
    const handleKeyBinds = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyBinds);

    return () => {
      document.removeEventListener('keydown', handleKeyBinds);
    };
  }, []);
};
