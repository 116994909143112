import React from 'react';
import BaseTable from 'react-base-table';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const Table = styled((props) => <BaseTable {...props} />)`
  .BaseTable__table {
    background-color: transparent;
  }

  .BaseTable__body {
    /* reserve some room for drop target */
    padding-bottom: 1rem;
  }

  .BaseTable__row {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &::after {
    border: 4px dashed transparent;
    border-radius: 5px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: box-shadow ease-in-out 150ms;
    width: 100%;

    ${({ $dragOver }) =>
      $dragOver &&
      `
      border-color: ${customColors.grey500};
    `}
  }
`;
