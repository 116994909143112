import React from 'react';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { TrackingEvent } from '@pro4all/shared/config';
import { useAnalytics } from '@pro4all/shared/vendor';

interface Props {
  accept?: string[];
  children: ({
    isDragActive,
    openFileInput,
  }: {
    isDragActive: DropzoneState['isDragActive'];
    openFileInput: DropzoneState['open'];
  }) => React.ReactNode;
  maxSize?: number;
  multiple?: boolean;
  onDrop: DropzoneOptions['onDrop'];
  onDropRejected?: DropzoneOptions['onDropRejected'];
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: none;
`;

export const FileUpload: React.FC<Props> = ({
  children,
  onDrop,
  onDropRejected,
  multiple = true,
  accept,
  maxSize,
}) => {
  const { track } = useAnalytics();

  const onDropAccepted = (acceptedFiles: File[]) => {
    track(TrackingEvent.DocumentUploadSelected, {
      fileCount: acceptedFiles.length,
    });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileInput,
  } = useDropzone({
    accept,
    maxSize,
    multiple,
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <StyledContainer {...getRootProps()} data-testid="file-upload">
      <input data-testid="file-upload-input" {...getInputProps()} />
      {children({ isDragActive, openFileInput })}
    </StyledContainer>
  );
};
