import React from 'react';

import { ValueTypeName } from '@pro4all/graphql';

import { useFieldConfig } from '../configs/useFieldConfig';
import * as Styled from '../form-template/FieldCard.styles';

export const FieldTypeIcon = ({ type }: { type: ValueTypeName }) => {
  const fieldConfig = useFieldConfig();
  return (
    <Styled.IconCard fontSize="inherit" iconName={fieldConfig[type].icon} />
  );
};
