import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const BreakInheritance: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M20 15.25H19.5V14.25C19.5 12.87 18.38 11.75 17 11.75C15.86 11.75 14.865 12.52 14.58 13.625C14.51 13.895 14.67 14.165 14.94 14.235C15.205 14.305 15.48 14.145 15.55 13.875C15.72 13.215 16.315 12.75 17 12.75C17.825 12.75 18.5 13.425 18.5 14.25V15.25H14C13.45 15.25 13 15.7 13 16.25V21.25C13 21.8 13.45 22.25 14 22.25H20C20.55 22.25 21 21.8 21 21.25V16.25C21 15.7 20.55 15.25 20 15.25ZM17 17.75C16.45 17.75 16 18.2 16 18.75C16 19.3 16.45 19.75 17 19.75C17.55 19.75 18 19.3 18 18.75C18 18.2 17.55 17.75 17 17.75ZM19.5 21.25C19.775 21.25 20 21.025 20 20.75V16.75C20 16.475 19.775 16.25 19.5 16.25H14.5C14.225 16.25 14 16.475 14 16.75V20.75C14 21.025 14.225 21.25 14.5 21.25H19.5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.7587 2.14235L4.98203 4.70884C4.38786 4.97291 4 5.56708 4 6.21902V10.0976C4 14.6771 7.16804 18.9595 11.4254 20V10.9229H5.65003V6.21906L11.4267 3.65258V10.9103H18.8211C18.8431 10.6408 18.8543 10.3698 18.8543 10.0976V6.21902C18.8543 5.56708 18.4664 4.97291 17.8722 4.70884L12.0956 2.14235C11.6747 1.95255 11.1796 1.95255 10.7587 2.14235Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
