import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '@pro4all/shared/ui/messages';

export const NoResultsMessage = () => {
  const { t } = useTranslation();

  return <BigMessage shapeName="simplify" title={t('No results found')} />;
};
