import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Descendant, Editor, Transforms } from 'slate';

import { CommunicationService } from '@pro4all/communication/data-access';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';

import { CustomElementType } from '../slate-editor';

import {
  allowedFormats,
  checkInvalidFiles,
  checkOverSizedFiles,
} from './helpers';

interface Props {
  editor: Editor;
  editorName: string;
  onSuccess?: () => void;
}

export function useImageUpload<T>({
  onSuccess,
  editor,
  editorName = 'body',
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const {
    isUploading: isImageUploading,
    upload,
    hideProgress,
  } = useFileUploadContext() || {};

  useEffect(() => {
    hideProgress?.();
  }, [hideProgress]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setValue: UseFormMethods['setValue'],
    _getValues: UseFormMethods['getValues']
  ) => {
    const files = Array.from(event.target.files || []);

    const invalidFiles = checkInvalidFiles(files, () => {
      enqueueSnackbar(
        t('UPLOAD_IMAGES_FORMAT', {
          formats: allowedFormats.join(', '),
          nsSeparator: false,
        })
      );
    });

    const oversizedFiles = checkOverSizedFiles(files, () => {
      enqueueSnackbar(t('UPLOAD_IMAGES_SIZE'));
    });

    const finalFiles = files.filter(
      (file) => !oversizedFiles.includes(file) && !invalidFiles.includes(file)
    );

    try {
      if (!upload) {
        enqueueSnackbar(t('Something went wrong'));
        console.error('Upload context missing.');
        return;
      }

      upload(finalFiles, async (file) => {
        const uploadResult = await CommunicationService.uploadStaticImage({
          file,
          organizationId,
        });

        if (uploadResult.data.errorCode) {
          enqueueSnackbar(t('Something went wrong'));
          return;
        }

        const fileUrl = `https://${uploadResult.data.url}`;
        const editorNodes: Descendant[] = [
          {
            children: [{ text: '' }],
            type: CustomElementType.image,
            url: fileUrl,
          },
          {
            children: [{ text: '' }],
            type: 'Line',
          },
        ];

        Transforms.insertNodes(editor, editorNodes);
        setValue(editorName, editorNodes);

        onSuccess && onSuccess();
      });
    } catch (error) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  return {
    handleImageUpload,
    isImageUploading,
  };
}
