import React from 'react';
import { useTranslation } from 'react-i18next';

import type { AnswerComponent } from '../AnswerView';
import { sortAndSeparateRounds } from '../helpers';

export const TimeSpan: AnswerComponent = ({ children }) => {
  const { t } = useTranslation();
  if (!children) {
    return null;
  }

  let rounds;
  try {
    rounds = typeof children === 'string' ? JSON.parse(children) : [];
  } catch (error) {
    console.error('Error parsing TimeSpan children:', error);
    return <span>Invalid format</span>;
  }

  if (!Array.isArray(rounds) || rounds.length === 0) {
    return <span>No rounds information</span>;
  }

  const sortedRounds = sortAndSeparateRounds(rounds);

  return (
    <div>
      {sortedRounds.map((round, index) => (
        <div key={index}>
          <strong>
            {round.label === 'Final Time' ? t(round.label) : round.label}:{' '}
          </strong>
          {round.time}
        </div>
      ))}
    </div>
  );
};
