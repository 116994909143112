import { useCallback } from 'react';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  DocumentVersion,
  DocumentVersionDocument,
  Folder,
  FolderByPathDocument,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useUpdateCachedDocuments = () => {
  const {
    searchParams,
    params: { projectId, path },
  } = useRouting();

  const urlDocumentId = searchParams.get('id');

  // The keyField prop it's necessary because sometimes
  // the backend it's recibing the document id and sometimes
  // use the versionId of the document.
  const updateCachedDocuments = useCallback(
    ({
      documentIds,
      fieldToUpdate,
      keyField,
      value,
    }: {
      documentIds?: (string | null)[];
      fieldToUpdate: string;
      keyField: 'id' | 'versionId';
      value: unknown;
    }) => {
      const documentsQueryParams = {
        query: FolderByPathDocument,
        variables: projectId ? { path, projectId } : { path },
      };
      const cachedFolder = client?.readQuery(documentsQueryParams)
        ?.folder as Folder;
      const cachedDocuments = cachedFolder?.documents;

      const documents = cachedDocuments?.map((doc) =>
        documentIds?.includes(doc?.[keyField as keyof typeof doc] ?? '')
          ? { ...doc, [fieldToUpdate]: value }
          : doc
      );

      client.writeQuery({
        ...documentsQueryParams,
        data: {
          folder: {
            ...cachedFolder,
            documents,
          },
        },
      });

      for (const documentId of documentIds ?? []) {
        const currentDocument = cachedDocuments?.find(
          (document) =>
            document?.[keyField as keyof typeof document] === documentId
        );

        // If there is no currentDocument.id it is because you are
        // not applying the action in a document, it is being applied
        // in a version and it is necessary to obtain the id of the
        // document from the url.

        const versionQueryParams = {
          query: DocumentVersionDocument,
          variables: { id: currentDocument?.id ?? urlDocumentId },
        };

        const cachedVersions = client?.readQuery(versionQueryParams)
          ?.documentVersion as DocumentVersion[] | null;

        if (!cachedVersions) continue;

        const documentVersion = cachedVersions?.map((version) =>
          version.id === currentDocument?.versionId || version.id === documentId
            ? { ...version, [fieldToUpdate]: value }
            : version
        );

        client.writeQuery({
          ...versionQueryParams,
          data: {
            documentVersion,
          },
        });
      }
    },
    [urlDocumentId, path, projectId]
  );

  return updateCachedDocuments;
};
