import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { useFolderPath } from '../utils/useFolderPath';

export const useEditFolderPermissionsAction = (folder: Folder) => {
  const { goTo, searchParams } = useRouting();

  const { docsRoute, projectId } = useFolderPath();
  const { t } = useTranslation();
  const path = decodeURIComponent(searchParams.get('folderPath')).replace(
    ',',
    '/'
  );

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const toFolderPermissions = () => {
    goTo(docsRoute, {
      params: {
        path,
        projectId,
      },
      searchParams: {
        action: 'manageFolderPermissions',
      },
    });
  };

  const editFolderPermissionsMenuItem: ActionProps = {
    ariaLabel: 'Edit permissions',
    dataTestId: 'permissions-folder',
    disabled: !hasFolderPermission('UpdateFolder'),
    key: 'permissions-folder',
    label: t('Edit permissions'),
    onClick: toFolderPermissions,
    startIcon: 'security',
  };

  return { editFolderPermissionsMenuItem };
};
