import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

import { Group, User } from '@pro4all/graphql';

export const MODE_TYPES = ['bulleted-list', 'title', 'subtitle'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];

export type textAlignOptions = 'left' | 'center' | 'right';
export type modeTypes = 'bulleted-list' | 'title' | 'subtitle';

export function isModeType(
  element: textAlignOptions | modeTypes
): element is modeTypes {
  return MODE_TYPES.includes(element);
}

export function isTextAlign(
  element: textAlignOptions | modeTypes
): element is textAlignOptions {
  return TEXT_ALIGN_TYPES.includes(element);
}

export enum MentionType {
  group = 'GroupMention',
  user = 'Mention',
}

export enum CustomElementType {
  image = 'Image',
}

export type CustomText = {
  bold?: boolean;
  code?: boolean;
  italic?: boolean;
  text: string;
  underline?: boolean;
};

export type LineElement = {
  align?: textAlignOptions;
  children: CustomText[];
  mode?: modeTypes;
  type: 'Line';
};

type BaseElement = {
  align?: textAlignOptions;
  children: CustomText[];
  mode?: modeTypes;
  type: MentionType | CustomElementType.image;
};

export interface ImageElement extends BaseElement {
  url?: string;
}

export interface MentionElement extends BaseElement {
  displayName: User['displayName'];
  email: User['email'];
  userId: User['id'];
}

export interface GroupMentionElement extends BaseElement {
  displayName: Group['displayName'];
  groupId: Group['id'];
  members: Group['members'];
}

export type CustomElement =
  | MentionElement
  | LineElement
  | GroupMentionElement
  | ImageElement;
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
