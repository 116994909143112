import React from 'react';
import dayjs from 'dayjs';

import { SearchQcInstance } from '@pro4all/graphql';
import {
  ComposedPin,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';

export const ResultsListMobile: React.FC<{
  documents: SearchQcInstance[];
  onClick: (document: SearchQcInstance) => void;
}> = ({ documents, onClick }) => (
  <List sx={{ width: '100%' }}>
    {documents &&
      documents.map((document: SearchQcInstance) => (
        <ListItemButton
          dense
          divider
          key={document.id}
          onClick={() => {
            onClick(document);
          }}
        >
          <ListItemIcon
            sx={{ alignItems: 'center', minHeight: 30, minWidth: 30 }}
          >
            <ComposedPin
              currentFile={
                document?.templateIconProps?.iconType === 2
                  ? getFileUrlByTemplateId(
                      document?.templateIconProps?.templateId || ''
                    )
                  : null
              }
              customColor={document.indicativeState?.color || undefined}
              selectedIconName={
                document.templateIconProps?.iconName as TMuiIcon | undefined
              }
              simpleIcon
              top={1}
              type={document.type === 'form' ? 'form' : 'snag'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  color="text.primary"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {document.name}
                </Typography>
                <Typography color="text.secondary">
                  #{document.reference}
                </Typography>
              </Box>
            }
            secondary={
              <>
                <Typography
                  color="text.secondary"
                  sx={{ display: 'inline' }}
                  variant="body2"
                >
                  {document.createdBy?.displayName
                    ? `${document.createdBy?.displayName} - `
                    : ''}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ display: 'inline' }}
                  variant="body2"
                >
                  {dayjs(document.createdAt).format('LL')}
                </Typography>
              </>
            }
          />
        </ListItemButton>
      ))}
  </List>
);

export default ResultsListMobile;
