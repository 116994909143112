import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  GetProjectPermissionsDocument,
  InheritedPermission,
  QcPermissionCategory,
  useSetProjectPermissionsMutation,
} from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { QCPermissionsFormValue } from './PermissionsMatrix';

interface Props {
  category: QcPermissionCategory;
  inheritedPermissions: InheritedPermission[];
  isMemberGroupFromOrg: boolean;
  member: { id: string; organizationId?: string; type: number };
  projectId: string;
}

export const useSubmit = ({
  category,
  member,
  projectId,
  isMemberGroupFromOrg,
  inheritedPermissions,
}: Props) => {
  const [setProjectPermissions] = useSetProjectPermissionsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();
  const { t } = useTranslation();

  const formValuesToPermissions = (formValues: QCPermissionsFormValue) => {
    const memberPermissions = Object.keys(formValues)
      .map((key) =>
        formValues[key] === 'report-create' ? key : formValues[key]
      )
      .filter((permission) => Boolean(permission)) as string[];

    //Formik doesn't handle dots in the names of keys and values in a desirable way
    //so the formConfiguration data is written with slashes instead of dots
    //and needs to be converted before sending it to the backend.
    const dotConvertedPermissions = memberPermissions.map((str) =>
      str.replace(/-/g, '.')
    );
    return dotConvertedPermissions;
  };

  const onSubmit = (values: QCPermissionsFormValue) => {
    try {
      setProjectPermissions({
        variables: {
          category,
          isMemberGroupFromOrg,
          member,
          memberPermissions: formValuesToPermissions(values),
          projectId,
        },
      });
      client.writeQuery({
        data: {
          getProjectPermissions: {
            __typename: 'UserQCPermissions',
            directPermissions: formValuesToPermissions(values),
            inheritedPermissions,
          },
        },
        query: GetProjectPermissionsDocument,
        variables: {
          category,
          isMemberGroupFromOrg,
          memberId: member.id,
          memberType: member.type,
          projectId,
        },
      });
      enqueueSnackbar(t('The new permissions were saved'));
    } catch (e) {
      showSingleError(e);
    }
  };
  return onSubmit;
};
