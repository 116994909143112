import React from 'react';

import { ApiKey } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/general';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const useColumns = () => [
  {
    defaultSort: true,
    filterable: true,
    headerComponent: (
      <FilterHeader<ApiKey, {}>
        defaultWidth={400}
        filterType={FilterHeaderType.Text}
        label="Name" // i18n
        minWidth={100}
        propertyId="description"
        pullFromLocalStorage
      />
    ),
    key: 'description',
    width: 400,
  },
  {
    filterable: true,
    getValue: (apiKey: ApiKey) =>
      apiKey.expiresAt ? getFormattedDate(apiKey.expiresAt, 'lll').label : '',
    headerComponent: (
      <FilterHeader<ApiKey, {}>
        defaultWidth={200}
        filterType={FilterHeaderType.Date}
        label="Expiration date" // i18n
        minWidth={120}
        propertyId="expiresAt"
      />
    ),
    key: 'expiresAt',
    render: ({ expiresAt }: ApiKey) => (
      <Timestamp date={expiresAt} format="lll" />
    ),
    width: 200,
  },
];
