import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import type { ObjectNode, TbqFunction, TbqType } from '@pro4all/graphql';
import { useSetTbqConfigMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import type { Option } from '@pro4all/shared/types';
import { sortBy } from '@pro4all/shared/utils';

import { configToRecord, normalizeConfig } from './utils';

export interface Fields {
  tbqConfig: Record<string, string | Option> | undefined;
  tbqFunction: Option | undefined;
  tbqType: Option | undefined;
}

export interface Props
  extends Pick<
    ObjectNode,
    | 'id'
    | 'name'
    | 'tbqConfig'
    | 'tbqType'
    | 'tbqTypeAllowed'
    | 'tbqFunction'
    | 'tbqFunctionAllowed'
  > {
  refetch: () => void;
}

export function useTBQSettings({
  id,
  name,
  refetch,
  tbqConfig,
  tbqType,
  tbqTypeAllowed,
  tbqFunction,
  tbqFunctionAllowed,
}: Props) {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { enqueueSnackbar } = useSnackbar();
  const [setTbqConfig] = useSetTbqConfigMutation();

  const toOption = ({ id, name }: TbqFunction | TbqType): Option => ({
    id,
    label: t(`TBQ.${name}`),
  });

  const optionsFunction = (
    tbqFunctionAllowed ?? (tbqFunction ? [tbqFunction] : [])
  )
    .map(toOption)
    .sort(sortBy({ key: 'label' }));
  const optionsType = (tbqTypeAllowed ?? (tbqType ? [tbqType] : []))
    .map(toOption)
    .sort(sortBy({ key: 'label' }));

  const defaultValues: Fields = {
    tbqConfig: configToRecord(tbqConfig),
    tbqFunction: tbqFunction ? toOption(tbqFunction) : optionsFunction[0],
    tbqType: tbqType ? toOption(tbqType) : optionsType[0],
  };
  const form = useForm<Fields>({ defaultValues });

  const close = () => searchParams.delete('action');
  const save = form.handleSubmit(
    async ({
      tbqConfig = {},
      tbqFunction,
      tbqType = defaultValues.tbqType,
    }) => {
      try {
        await setTbqConfig({
          variables: {
            nodeId: id,
            tbqConfig: normalizeConfig(tbqConfig),
            tbqFunctionId: tbqFunction?.id,
            tbqTypeId: tbqType?.id,
          },
        });
        enqueueSnackbar(t('TBQ settings saved for "{{name}}"', { name }));
        form.reset(defaultValues);
        close();
        refetch();
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          t(
            'Could not save TBQ settings for "{{name}}". Something went wrong on the server.',
            { name }
          )
        );
      }
    }
  );

  return { cancel: close, form, optionsFunction, optionsType, save };
}
