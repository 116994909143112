import React from 'react';
import {
  AccountCircle,
  AddComment,
  AddRounded,
  AddToPhotos,
  Alarm,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeftRounded,
  ArrowUpward,
  AssignmentLate,
  AssignmentRounded,
  AssignmentTurnedIn,
  Attachment,
  BarChart,
  BookmarkAdd,
  BookmarkBorderRounded,
  BrokenImageRounded,
  BubbleChart,
  BusinessCenter,
  Cancel,
  CenterFocusStrong,
  CenterFocusWeak,
  ChatBubbleOutline,
  Check,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  CheckCircle,
  CheckCircleOutlined,
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
  Code,
  ContentCopyRounded,
  ContentCutRounded,
  ContentPasteRounded,
  CorporateFareRounded,
  CreateNewFolderRounded,
  DateRange,
  DeleteOutline,
  DeleteRounded,
  Details,
  DeviceHub,
  DonutLarge,
  EditRounded,
  EmojiObjectsOutlined,
  Equalizer,
  ErrorOutlineOutlined,
  ErrorRounded,
  Event,
  EventBusy,
  EventRounded,
  ExitToAppRounded,
  ExpandLess,
  ExpandMore,
  Explore,
  FiberManualRecordRounded,
  FileCopy as CopyDocuments,
  FilterList,
  FolderOpenRounded,
  FolderRounded,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatShapes,
  FormatUnderlined,
  Fullscreen,
  FullscreenExit,
  Gesture,
  GetAppRounded,
  Group,
  GroupAdd,
  HelpOutline,
  History,
  HourglassEmpty,
  HowToReg,
  ImageNotSupportedRounded,
  ImageRounded,
  InfoOutlined,
  InsertDriveFileOutlined,
  InsertDriveFileRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowUpRounded,
  LabelOutlined,
  Link,
  List,
  LocalOfferOutlined,
  Lock,
  LockOpen,
  LooksOne,
  LooksTwo,
  MailOutline,
  Map,
  Menu,
  MoreHoriz,
  MoreVertRounded,
  MyLocation,
  NewReleases,
  Pause,
  PauseCircleOutline,
  Payment,
  PeopleRounded,
  PermContactCalendarRounded,
  Person,
  PersonAdd,
  PersonOutline,
  PersonPinRounded,
  Phone,
  PlayArrow,
  PlayCircleOutline,
  PlaylistAdd,
  PlaylistAddCheckRounded,
  Print,
  Publish,
  PublishRounded,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveRedEye,
  ReportProblemOutlined,
  Restore,
  SaveRounded,
  School,
  Search,
  SecurityRounded,
  Send,
  SettingsBackupRestore,
  ShareRounded,
  SnoozeRounded,
  SortByAlpha,
  Stop,
  Storage,
  SubdirectoryArrowLeft,
  SupervisedUserCircle,
  SupervisorAccount,
  Sync,
  TableRows,
  TaskAltRounded,
  TextFields,
  ThreeDRotation,
  TimerOffOutlined,
  TimerOutlined,
  ToggleOff,
  Update,
  VerifiedUser,
  ViewList,
  ViewModule,
  Visibility,
  VisibilityOff,
  WarningRounded,
  Whatshot,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import styled from 'styled-components';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

import { ChangeableSimpleFormIcon } from './custom/ChangeableSimpleFormIcon';
import { ChangeableSimpleSnagIcon } from './custom/ChangeableSimpleSnagIcon';
import {
  ActiveUser,
  AddCondition,
  AddFieldDefinition,
  AddFile,
  AddFormTemplate,
  AddObject,
  AddSnagTemplate,
  AddTBQ,
  AddTemplate,
  Admin,
  AI,
  ApproveFile,
  BreakInheritance,
  Build12,
  ChangeableFormIcon,
  ChangeableSnagIcon,
  CollectionBin,
  ControlResults,
  CreateFolder,
  Csv,
  Cube,
  Dashboard,
  DeleteFile,
  DeleteFolder,
  DeleteOwnFile,
  DocumentTaskIcon,
  Drawing,
  EditDocumentOnComputer,
  EditFile,
  EditMetadata,
  EditObject,
  EditOwnFiles,
  EditSnag,
  Excel,
  ExitProject,
  ExpectedDocument,
  Export,
  FileCopy,
  Finalize,
  FlagDenmark,
  FlagGermany,
  FlagGreatBritain,
  FlagHolland,
  FlagNorway,
  Form,
  Forward,
  GenerateKeywords,
  GroupOff,
  HierarchicalList,
  ImportExport,
  InactiveUser,
  Indicative,
  Insight,
  InTaskAssigned,
  LastApproved,
  Logo,
  Mail,
  MarkUnread,
  MostRecent,
  MostRecentApproved,
  MSTeams,
  MyProjects,
  NavigateUp,
  NewTbqResolveTask,
  NotificationRules,
  NotInterested,
  Number,
  ObjectLinkActive,
  Office,
  OfficeOnlineProgress,
  OpenInFolder,
  OpenSearch,
  OwnAndFinalizedFiles,
  PlaceSnag,
  PowerPoint,
  Project,
  Prostream,
  ProstreamChat,
  ProstreamChatUnread,
  QR,
  QRRemove,
  QualityControl,
  QuestionMark,
  ReadFolder,
  RemoveCircleOutline,
  RemoveCollectionBin,
  Reply,
  ReplyAll,
  Resend,
  SandTimer,
  SectionCondition,
  SectionTwoBars,
  Settings,
  SmartFolderOutline,
  SmartFolderRounded,
  Snag,
  Snagstream,
  SolibriIcon,
  SquareMinus,
  Stamp,
  StaticFormIcon,
  StaticSnagIcon,
  TBQ,
  TBQBrandcheck,
  TBQMainGreen,
  TbqResolveTask,
  Template,
  TinyCircle,
  UndoFinalize,
  Unlink,
  UpdateFolderPermissions,
  ViewFile,
  WithdrawApproval,
  WithdrawRejection,
  Word,
} from './custom';

export const iconsMap = {
  TaskAltRounded: TaskAltRounded,
  accountCircle: AccountCircle,
  activeUser: ActiveUser,
  add: AddRounded,
  addCondition: AddCondition,
  addFieldDefinition: AddFieldDefinition,
  addFile: AddFile,
  addFormTemplate: AddFormTemplate,
  addObject: AddObject,
  addQuestionItem: AddComment,
  addQuestionsSet: AddToPhotos,
  addSnagTemplate: AddSnagTemplate,
  addTBQ: AddTBQ,
  addTemplate: AddTemplate,
  admin: Admin,
  ai: AI,
  alarm: Alarm,
  approveFile: ApproveFile,
  arrowBack: ArrowBack,
  arrowBackIos: ArrowBackIos,
  arrowDownward: ArrowDownward,
  arrowDropDown: ArrowDropDown,
  arrowDropUp: ArrowDropUp,
  arrowForward: ArrowForward,
  arrowFowardIos: ArrowForwardIos,
  arrowLeftRounded: ArrowLeftRounded,
  arrowUpward: ArrowUpward,
  attachment: Attachment,
  barChart: BarChart,
  bookmark: BookmarkBorderRounded,
  bookmarkAdd: BookmarkAdd,
  boolean: ToggleOff,
  breakInheritance: BreakInheritance,
  brokenImage: BrokenImageRounded,
  build12: Build12,
  businessCenter: BusinessCenter,
  calendar: EventRounded,
  cancel: Cancel,
  centerFocusStrong: CenterFocusStrong,
  centerFocusWeak: CenterFocusWeak,
  changeableFormIcon: ChangeableFormIcon,
  changeableSimpleFormIcon: ChangeableSimpleFormIcon,
  changeableSimpleSnagIcon: ChangeableSimpleSnagIcon,
  changeableSnagIcon: ChangeableSnagIcon,
  chatBubble: ChatBubbleOutline,
  check: Check,
  checkCircle: CheckCircle,
  checkCircleOutlined: CheckCircleOutlined,
  checkbox: CheckBoxRounded,
  checkboxOutline: CheckBoxOutlineBlankRounded,
  chevronLeftRounded: ChevronLeftRounded,
  chevronRightRounded: ChevronRightRounded,
  circle: FiberManualRecordRounded,
  clipboard: ContentPasteRounded,
  close: CloseRounded,
  collectionBin: CollectionBin,
  controlResults: ControlResults,
  copyDocuments: CopyDocuments,
  copyFile: ContentCopyRounded,
  createFolder: CreateFolder,
  csv: Csv,
  cube: Cube,
  cut: ContentCutRounded,
  dashboard: Dashboard,
  dateRange: DateRange,
  delete: DeleteRounded,
  deleteFile: DeleteFile,
  deleteFolder: DeleteFolder,
  deleteOutline: DeleteOutline,
  deleteOwnFile: DeleteOwnFile,
  deviceHub: DeviceHub,
  document: InsertDriveFileRounded,
  documentTask: DocumentTaskIcon,
  donutLarge: DonutLarge,
  download: GetAppRounded,
  drawing: Drawing,
  edit: EditRounded,
  editDocumentOnComputer: EditDocumentOnComputer,
  editFile: EditFile,
  editMetadata: EditMetadata,
  editObject: EditObject,
  editOwnFiles: EditOwnFiles,
  editSnag: EditSnag,
  error: ErrorRounded,
  errorOutlined: ErrorOutlineOutlined,
  event: Event,
  eventBusy: EventBusy,
  excel: Excel,
  exitProject: ExitProject,
  expandLess: ExpandLess,
  expandMore: ExpandMore,
  expectedDocument: ExpectedDocument,
  explore: Explore,
  export: Export,
  file: InsertDriveFileOutlined,
  fileCopy: FileCopy,
  filterList: FilterList,
  finalize: Finalize,
  flagDenmark: FlagDenmark,
  flagGermany: FlagGermany,
  flagGreatBritain: FlagGreatBritain,
  flagHolland: FlagHolland,
  flagNorway: FlagNorway,
  flame: Whatshot,
  folder: FolderRounded,
  folderNew: CreateNewFolderRounded,
  folderOpen: FolderOpenRounded,
  form: Form,
  formatAlignCenter: FormatAlignCenter,
  formatAlignLeft: FormatAlignLeft,
  formatAlignRight: FormatAlignRight,
  formatBold: FormatBold,
  formatCode: Code,
  formatItalic: FormatItalic,
  formatListNumbered: FormatListNumbered,
  formatShapes: FormatShapes,
  formatUnderlined: FormatUnderlined,
  forward: Forward,
  fullscreen: Fullscreen,
  fullscreenExit: FullscreenExit,
  generateKeywords: GenerateKeywords,
  group: Group,
  groupAdd: GroupAdd,
  groupOff: GroupOff,
  helpOutline: HelpOutline,
  hierarchicalList: HierarchicalList,
  history: History,
  hourglassEmpty: HourglassEmpty,
  howToReg: HowToReg,
  image: ImageRounded,
  imageNotSupported: ImageNotSupportedRounded,
  importExport: ImportExport,
  inTaskAssigned: InTaskAssigned,
  inactiveUser: InactiveUser,
  indicative: Indicative,
  info: InfoOutlined,
  insight: Insight,
  keyboardArrowDown: KeyboardArrowDownRounded,
  keyboardArrowRight: KeyboardArrowRightRounded,
  keyboardArrowUp: KeyboardArrowUpRounded,
  label: LabelOutlined,
  lastApproved: LastApproved,
  lightbulb: EmojiObjectsOutlined,
  link: Link,
  list: List,
  lock: Lock,
  lockOpen: LockOpen,
  logo: Logo,
  logout: ExitToAppRounded,
  looksOne: LooksOne,
  looksTwo: LooksTwo,
  mail: Mail,
  mailOutline: MailOutline,
  map: Map,
  markUnread: MarkUnread,
  menu: Menu,
  metaData: Details,
  moreHoriz: MoreHoriz,
  moreVert: MoreVertRounded,
  mostRecent: MostRecent,
  mostRecentApproved: MostRecentApproved,
  msTeams: MSTeams,
  myLocation: MyLocation,
  myOverview: Equalizer,
  myProjects: MyProjects,
  navigateUp: NavigateUp,
  newReleases: NewReleases,
  newTbqResolveTask: NewTbqResolveTask,
  notInterested: NotInterested,
  notificationRules: NotificationRules,
  number: Number,
  objectLinkActive: ObjectLinkActive,
  office: Office,
  officeOnlineProgress: OfficeOnlineProgress,
  openInFolder: OpenInFolder,
  openSearch: OpenSearch,
  organization: CorporateFareRounded,
  ownAndFinalizedFiles: OwnAndFinalizedFiles,
  pause: Pause,
  pauseCircleOutline: PauseCircleOutline,
  payment: Payment,
  person: Person,
  personAdd: PersonAdd,
  personCalendar: PermContactCalendarRounded,
  personOutline: PersonOutline,
  personPin: PersonPinRounded,
  phone: Phone,
  placeSnag: PlaceSnag,
  play: PlayArrow,
  playlistAdd: PlaylistAdd,
  powerPoint: PowerPoint,
  print: Print,
  procedure: PlaylistAddCheckRounded,
  project: Project.Default,
  projectCreate: Project.Add,
  prostream: Prostream,
  prostreamChat: ProstreamChat,
  prostreamChatUnread: ProstreamChatUnread,
  publish: Publish,
  qcTask: AssignmentRounded,
  qr: QR,
  qrRemove: QRRemove,
  qualityControl: QualityControl,
  questionMark: QuestionMark,
  readFolder: ReadFolder,
  refresh: Refresh,
  remove: Remove,
  removeCircle: RemoveCircle,
  removeCircleOutline: RemoveCircleOutline,
  removeCollectionBin: RemoveCollectionBin,
  removeRedEye: RemoveRedEye,
  reply: Reply,
  replyAll: ReplyAll,
  reportProblemOutlined: ReportProblemOutlined,
  resend: Resend,
  reset: SettingsBackupRestore,
  restore: Restore,
  rotate: ThreeDRotation,
  sandTimer: SandTimer,
  save: SaveRounded,
  school: School,
  search: Search,
  section: Storage,
  sectionCondition: SectionCondition,
  sectionTwoBars: SectionTwoBars,
  security: SecurityRounded,
  selection: FormatListBulleted,
  send: Send,
  settings: PeopleRounded,
  settingsApplications: Settings,
  share: ShareRounded,
  signature: Gesture,
  smartFolder: SmartFolderRounded,
  smartFolderOutline: SmartFolderOutline,
  snag: Snag,
  snagstream: Snagstream,
  snooze: SnoozeRounded,
  solibriIcon: SolibriIcon,
  sortByAlpha: SortByAlpha,
  squareMinus: SquareMinus,
  stamp: Stamp,
  start: PlayCircleOutline,
  staticFormIcon: StaticFormIcon,
  staticSnagIcon: StaticSnagIcon,
  status: BubbleChart,
  stop: Stop,
  subDirArrowLeft: SubdirectoryArrowLeft,
  supervisedUserCircle: SupervisedUserCircle,
  supervisorAccount: SupervisorAccount,
  sync: Sync,
  tableRows: TableRows,
  tag: LocalOfferOutlined,
  taskFailed: AssignmentLate,
  taskSucceeded: AssignmentTurnedIn,
  tbq: TBQ,
  tbqBrandcheck: TBQBrandcheck,
  tbqMainGreen: TBQMainGreen,
  tbqResolveTask: TbqResolveTask,
  template: Template,
  text: TextFields,
  timer: TimerOutlined,
  timerOff: TimerOffOutlined,
  tinyCircle: TinyCircle,
  undoFinalize: UndoFinalize,
  unlink: Unlink,
  update: Update,
  updateFolderPermissions: UpdateFolderPermissions,
  upload: PublishRounded,
  verifiedUser: VerifiedUser,
  viewFile: ViewFile,
  viewList: ViewList,
  viewModule: ViewModule,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  warning: WarningRounded,
  withdrawApproval: WithdrawApproval,
  withdrawRejection: WithdrawRejection,
  word: Word,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
};

export type IconName = keyof typeof iconsMap;

export const IconWrap = styled.span<{ hasLabel: boolean }>`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  .MuiSvgIcon-root {
    ${({ hasLabel, theme }) =>
      hasLabel && `margin-right: ${theme.spacing(1)}};`}
  }
`;

export type IconType = SvgIconProps & {
  customColor?: string;
  iconName: IconName;
  label?: React.ReactNode | string;
};

export const Icon: React.FC<IconType> = ({
  className,
  iconName,
  label,
  ...rest
}) =>
  iconsMap[iconName] ? (
    <IconWrap className={className} hasLabel={Boolean(label)}>
      {React.createElement(iconsMap[iconName], {
        ...rest,
      })}
      {label ? label : null}
    </IconWrap>
  ) : null;
