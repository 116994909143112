import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { FilterType, SearchQcInstance, VisualContext } from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import {
  Map,
  Marker,
  useMapLinkingContext,
} from '@pro4all/quality-control/ui/maps';
import { SearchToolbar, useFilters } from '@pro4all/search/ui';
import { Action } from '@pro4all/shared/config';
import { usePersistentState } from '@pro4all/shared/hooks';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Slider,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { SelectSnagFilterModal } from '@pro4all/shared/qc-search-metadata-modal';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { Button } from '@pro4all/shared/ui/buttons';
import {
  Checkbox,
  Dialog,
  hexToRgb,
  Sidebar,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/general';
import { FullScreen } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { useAnalytics } from '@pro4all/shared/vendor';

import Paginator from './Paginator/Paginator';
import { DrawingSearchBar } from './DrawingSearchBar';
import { SearchPager } from './SearchPager';

type Props = {
  visualContext: VisualContext;
};

export interface ErrorDialog {
  enteredPage: number;
}

export enum DirectionSearchNavigation {
  Next = 'next',
  Prev = 'prev',
}

// Set the following values to offset map coordinates and compensate for other on-screen elements
// Assuming sidebar is always open when a marker is selected (48px being the main container padding on the right)
const OFFSET_X = Sidebar.Size.Wide - 48;

export const DrawingSearchResults = ({ visualContext }: Props) => {
  const { iconScale, setIconScale } = useMapLinkingContext();
  const [_, setPersistentIconScale] = usePersistentState<number>('iconScale');
  const [showOnlyTaskSnags, setShowOnlyTaskSnags] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!iconScale) return;
    setPersistentIconScale(() => iconScale);
  }, [iconScale, setPersistentIconScale]);

  const {
    searchParams,
    params: { projectId, visualContextId },
    state,
    goTo,
  } = useRouting<DrawingRouterState>();

  const { t } = useTranslation();
  const [errorDialog, setErrorDialog] = useState<null | ErrorDialog>(null);

  const history = useHistory();

  const errorEnteredPage = errorDialog?.enteredPage;

  const page =
    Number(searchParams.get('page')) !== 0
      ? Number(searchParams.get('page'))
      : 1;

  const resultId = searchParams.get('id');
  const action = searchParams.get('action');

  const { track } = useAnalytics();

  const taskId = state?.clickedTaskId ?? searchParams.get('taskId');

  useEffect(() => {
    if (page > visualContext.pageCount) {
      setErrorDialog({ enteredPage: page });
      searchParams.set({ page: 1 });
    }
  }, [searchParams, visualContext.pageCount, page]);

  const goToPreviousPage = () => {
    if (state?.previousPageUrl) history.push(state?.previousPageUrl);
    else goTo('projectQualityControlDrawings', { params: { projectId } });
  };

  const {
    documents: searchDocuments = [],
    loading: isSearchLoading,
    paramQuery,
    facetGroups,
    refetch,
  } = useSnagFormSearchContext();

  // Ref to track if it's the first time
  const isFirstRender = useRef(true);

  // Track the previous predefinedHiddenFilters value
  const prevPredefinedHiddenFilters = useRef('');

  useEffect(() => {
    let callRefetch = true;
    const predefinedHiddenFilters = `appData.visualContext.visualContextId:${
      visualContext.id
    }${taskId && showOnlyTaskSnags ? `,appData.tasks.taskId:${taskId}` : ''}`;
    searchParams.set({
      predefinedHiddenFilters: predefinedHiddenFilters,
    });
    if (predefinedHiddenFilters !== prevPredefinedHiddenFilters.current) {
      callRefetch = false;
    }
    prevPredefinedHiddenFilters.current = predefinedHiddenFilters;
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (callRefetch) {
      refetch && refetch();
    }
    // We dont want to add refetch to the dependency array as it will cause an infinite loop
  }, [
    searchParams,
    visualContext.id,
    showOnlyTaskSnags,
    taskId,
    isFirstRender,
  ]);

  const {
    replaceAllItems,
    state: { items: documents },
  } = useOptimisticResponseContext<SearchQcInstance>();

  useEffect(() => {
    replaceAllItems(searchDocuments?.length ? searchDocuments : []);
  }, [replaceAllItems, searchDocuments]);

  const items = documents?.filter((document) => document.page === page);

  const availablePages = useMemo(
    () =>
      Array.from(
        new Set(documents?.map((item) => item.page).sort((a, b) => a - b))
      ) || [],
    [documents]
  );

  const [availablePagesCount, setAvailablePagesCount] = useState<null | number>(
    -1
  );

  useEffect(() => {
    const currentCount = availablePages.indexOf(page);
    setAvailablePagesCount(currentCount);
  }, [availablePages, page]);

  const searchPagesCount = documents?.length;
  const hasPrevPage = availablePages.some(
    (availablePage) => availablePage < page
  );
  const hasNextPage = availablePages.some(
    (availablePage) => availablePage > page
  );

  const handleSearchNavigation = (direction: DirectionSearchNavigation) => {
    if (direction === DirectionSearchNavigation.Next) {
      setAvailablePagesCount((prev) => {
        const pg = prev + 1;
        const nextPageToJump = availablePages.find((_, key) => key === pg);

        const currPage =
          typeof nextPageToJump === 'number' ? nextPageToJump : prev;

        searchParams.set({
          page: currPage,
        });

        return pg;
      });
    } else if (direction === DirectionSearchNavigation.Prev) {
      setAvailablePagesCount((prev) => {
        // If previous page is less than 0, then we know that we have passed the last available page
        if (prev < 0) {
          const lastPage = availablePages[availablePages.length - 1];
          const lastPagePosition = availablePages.indexOf(lastPage);
          searchParams.set({
            page: lastPage,
          });
          return lastPagePosition;
        }
        const pg = prev - 1;
        const prevPage = availablePages.find((_, key) => key === pg);
        const currPage = typeof prevPage === 'number' ? prevPage : prev;

        searchParams.set({
          page: currPage,
        });
        return pg;
      });
    }
  };

  const selectedResult = items
    ? resultId
      ? items.find((r) => r.id === resultId)
      : { x: 0, y: 0 }
    : { x: 0, y: 0 };

  const { currentFilters } = useFilters();
  const [isShowingFilters, setIsShowingFilters] = useState(
    Boolean(currentFilters?.length)
  );

  const handleToggleFilters = () => {
    setIsShowingFilters(!isShowingFilters);
  };

  const qcMetadataModalParam = searchParams.get('qcMetadataModal');

  return items ? (
    <>
      <Box p={2}>
        <BackButton
          color="inherit"
          onClick={goToPreviousPage}
          startIcon="arrowBack"
          variant="text"
        >
          {`${t('Back to')} ${t(
            state?.previousPageName ?? 'Drawings'
          ).toLowerCase()}`}
        </BackButton>
      </Box>
      <FullScreen
        headerChildren={
          <Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <HeaderTitle color="textPrimary" variant="h5">
                {visualContext.name}
              </HeaderTitle>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'flex-end',
                }}
              >
                {taskId && (
                  <Box mt={1.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showOnlyTaskSnags}
                          disabled={isSearchLoading}
                          onChange={() => {
                            setShowOnlyTaskSnags(!showOnlyTaskSnags);
                          }}
                        />
                      }
                      label={t('Show only snags and forms from this task')}
                    />
                  </Box>
                )}
                <Box mt={1.5}>
                  <Button
                    color="inherit"
                    onClick={handleToggleFilters}
                    variant="text"
                  >
                    {t('Filters')}
                  </Button>
                </Box>
                <Box>
                  <DrawingSearchBar />
                  {paramQuery && (
                    <SearchPager
                      currentPageCount={items?.length || 0}
                      handleSearchNavigation={handleSearchNavigation}
                      hasNextPage={hasNextPage}
                      hasPrevPage={hasPrevPage}
                      searchPagesCount={searchPagesCount}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {isShowingFilters && (
              <Box mt={-1}>
                <SearchToolbar
                  ScopeFilter={[]}
                  addFilterColor="inherit"
                  dataViewToggle
                  facetGroups={facetGroups}
                  loading={isSearchLoading}
                  onApply={refetch}
                  showResetButton={false}
                  showSaveButton={false}
                />
              </Box>
            )}
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" width="100%">
          <SliderHeaderContainer pageCount={visualContext.pageCount}>
            <StyledH5 variant="h5">Icon Size</StyledH5>
            <StyledSlider
              max={2}
              min={0.3}
              onChange={(e, value: number) => {
                setIconScale(value);
              }}
              step={0.1}
              value={iconScale}
            />
          </SliderHeaderContainer>

          <Map
            lat={selectedResult ? selectedResult.y : 0}
            lng={selectedResult ? selectedResult.x : 0}
            mapHeight={visualContext.height}
            mapWidth={visualContext.width}
            offsetX={resultId && OFFSET_X}
            visualContextId={visualContextId}
            zoomLevels={visualContext.zoomLevels}
          >
            {isSearchLoading && (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
                left={0}
                position="absolute"
                top={0}
                width="100%"
                zIndex={1000}
              >
                <CircularProgress />
              </Box>
            )}
            {items.map((instance) => {
              if (instance.x && instance.y) {
                return (
                  <Marker
                    draggable={
                      action &&
                      action === 'editResult' &&
                      resultId === instance.id
                    }
                    iconName={instance?.templateIconProps?.iconName}
                    iconType={instance?.templateIconProps?.iconType}
                    id={instance.id}
                    key={instance.id}
                    lat={instance.y ?? 0}
                    lng={instance.x ?? 0}
                    onClick={() => {
                      track(Action.ItemOpened, {
                        id: instance.id,
                        location: 'Drawing',
                        name: instance.name,
                        projectId,
                        type: instance.type,
                      });

                      searchParams.set({
                        action: 'viewResult',
                        id: instance.id,
                      });
                    }}
                    page={instance.page}
                    selectedId={resultId}
                    statusColor={
                      instance.indicativeState
                        ? instance.indicativeState.color
                        : ''
                    }
                    templateId={instance?.templateIconProps?.templateId}
                    type={instance.type}
                    visualContextId={instance.visualContextId}
                  />
                );
              }
              return '';
            })}
          </Map>
          {visualContext.pageCount > 1 ? (
            <Paginator
              pageCount={visualContext.pageCount}
              searchNavigation={handleSearchNavigation}
              setErrorDialog={setErrorDialog}
              visualContextId={visualContextId}
            />
          ) : null}
        </Box>
      </FullScreen>
      {Boolean(errorEnteredPage) && (
        <Dialog
          buttonVariant="outlined"
          confirmLabel={t('Ok')}
          iconName="reportProblemOutlined"
          onConfirm={() => setErrorDialog(null)}
          open
          title="No page number found"
        >
          {errorEnteredPage ? (
            <p>
              {t(
                `There is no page numbered {{errorEnteredPage}} on this drawing`,
                { errorEnteredPage }
              )}
            </p>
          ) : (
            <p>{t('You have not entered a page number')}</p>
          )}
        </Dialog>
      )}
      {Boolean(qcMetadataModalParam) && (
        <SelectSnagFilterModal
          filterType={qcMetadataModalParam as FilterType}
        />
      )}
    </>
  ) : null;
};

const BackButton = styled(Button)`
  && {
    width: fit-content;
  }
`;

const SliderHeaderContainer = styled('div')<{ pageCount: number }>`
  position: absolute;
  bottom: ${({ pageCount }) => (pageCount > 1 ? '70px' : '15px')};
  right: 35px;
  z-index: 99;
`;

const StyledH5 = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing(1.333)};
  }
`;

const HeaderTitle = styled(Text)`
  && {
    margin-left: ${({ theme }) => theme.spacing(4)};
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const StyledSlider = styled(Slider)`
  && {
    width: 180px;

    & .MuiSlider-rail,
    .MuiSlider-track {
      height: 6px;
      border-radius: 5px;
    }

    & .MuiSlider-track {
      color: ${({ theme }) => theme.palette.text.primary};
    }

    & .MuiSlider-rail {
      color: ${({ theme }) => theme.palette.grey['500']};
      opacity: 1;
    }

    & .MuiSlider-thumb {
      height: 20px;
      width: 20px;
      color: ${({ theme }) => theme.palette.text.primary};
      box-shadow: ${({ theme }) =>
        `0px 0px 0px 8px ${hexToRgb(theme.palette.text.primary, '0.16')}`};
      &:hover {
        box-shadow: ${({ theme }) =>
          `0px 0px 0px 8px ${hexToRgb(theme.palette.text.primary, '0.16')}`};
      }
    }
  }
`;
