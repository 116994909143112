import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useInviteExistingUsersMutation } from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

interface Props {
  onClose?: () => void;
  setShowBackdrop: (show: boolean) => void;
}

interface Values {
  admins: Array<{ id: string; inputValue: string; label: string }>;
  message: string;
  users: Array<{ id: string; inputValue: string; label: string }>;
}

export const useSubmitExistingUsers = ({ onClose, setShowBackdrop }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [inviteExistingUsers] = useInviteExistingUsersMutation();
  const { showBatchErrors, showSingleError } = useShowMessages();

  const onSubmit = async (values: Values) => {
    const { admins, message, users } = values;

    try {
      setShowBackdrop(true);
      const inviteExistingUsersResponse = await inviteExistingUsers({
        variables: {
          adminEmails: admins.map((admin) => admin.label),
          message,
          userEmails: users.map((user) => user.label),
        },
      });
      setShowBackdrop(false);

      const invitedSuccessfully =
        inviteExistingUsersResponse.data?.inviteExistingUsers;
      const countSuccessful = invitedSuccessfully.length || 0;
      const countInvited = [...admins, ...users].length;
      const emailsFailed = [...admins, ...users].filter(
        (person) => !invitedSuccessfully.includes(person.label)
      );

      if (countSuccessful === countInvited) {
        if (countSuccessful === 1) {
          enqueueSnackbar(
            t(
              'Invitation to join your organization has been sent to 1 existing user.'
            )
          );
        } else {
          enqueueSnackbar(
            t(
              'Invitation to join your organization has been sent to {{count}} existing users.',
              {
                count: countSuccessful,
              }
            )
          );
        }
      } else {
        showBatchErrors({
          errorCode: 'ERROR_FAILED_TO_INVITE_EXISTING_USERS',
          identifierData: emailsFailed.map((emailFailed) => ({
            displayName: emailFailed.label,
            id: emailFailed.id,
          })),
        });
      }
      onClose();
    } catch (e) {
      showSingleError(e);
    }
  };
  return onSubmit;
};
