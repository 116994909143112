import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button as UIButton } from '@pro4all/shared/ui/buttons';

export const StyledBox = styled(Box)`
  && {
    margin: 0;
  }
`;

export const Button = styled(UIButton)`
  && {
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const TbqQuestionButton = styled(Button)`
  && {
    border-radius: 4px;
    margin-right: 22px;
    margin-bottom: 0.5rem;
    display: flex;
    margin-left: auto;
  }
`;
