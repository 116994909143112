import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Document, DocumentVersion, LockType, User } from '@pro4all/graphql';
import { Position } from '@pro4all/shared/types';
import { useUnlock } from '@pro4all/shared/ui/actions';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

import { DocumentActionProps } from '../../types';

export const useOnClickUnlock = ({
  position,
  selection,
  uncheckAllRows,
}: Pick<DocumentActionProps, 'position' | 'selection' | 'uncheckAllRows'>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const unlock = useUnlock();
  const { editItems, setItem } = useOptimisticResponseContext<
    Document | DocumentVersion
  >();

  const onClick = async () => {
    if (!selection[0].lockedBy) {
      enqueueSnackbar(t('Document is not locked. You cannot unlock it now.'));
    } else {
      const isSuccessful = await unlock({
        document: selection[0],
        withNewVersion: false,
      });

      if (isSuccessful) {
        // Deselect all rows if applicable.
        if (position !== Position.Contextmenu) uncheckAllRows();

        // Optimistic response.
        const updatedItem = {
          ...selection[0],
          lockType: LockType.None,
          lockedAt: null as unknown as string,
          lockedBy: null as unknown as User,
        };
        setItem(updatedItem);
        editItems([updatedItem]);
      }
    }
  };

  return onClick;
};
