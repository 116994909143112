import { Document, useLockDocumentsMutation } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useLock = () => {
  const [lockDocuments] = useLockDocumentsMutation();
  const { track } = useAnalytics();
  const { showSingleError } = useShowMessages();

  const lock = async ({
    document,
    showError,
  }: {
    document: Document;
    showError: boolean;
  }) => {
    try {
      // Lock the document.
      await lockDocuments({
        variables: {
          documentIds: [document.id], // For now only on a per document base.
        },
      });

      // Analytics
      track(TrackingEvent.DocumentLocked, {
        documentId: document.id,
        extension: document.extension,
        folderId: document.folderId,
      });

      return true;
    } catch (e) {
      if (showError) showSingleError(e);
      return false;
    }
  };

  return lock;
};
