import { State } from '../useUploaderEditorReducer';

export const deleteFileFromProcessingListAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => ({
  ...state,
  meta: [...state.meta.filter((file) => file.id !== payload)],
});
