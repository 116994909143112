import React from 'react';

import { useOrganizationUsersIncludeQuery, User } from '@pro4all/graphql';
import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Table } from '@pro4all/shared/ui/general';
import {
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoOrganizationUsers } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { useUsersActions } from '../utils/useUsersActions';

import { AddUsersToProjectSidebar } from './AddUsersToProjectSidebar';
import { EditRolesSidebar } from './EditRolesSidebar';
import { organizationUsersIncludeProps } from './getIncludeProps';
import { InviteExistingUsersSidebar } from './InviteExistingUsersSidebar';
import { InviteUsersSidebar } from './InviteUsersSidebar';
import OrganizationUserListMobile from './OrganizationUserListMobile';
import { OrganizationUsersActionBar } from './OrganizationUsersActionBar';
import { useOrganizationColumns } from './useOrganizationColumns';

export const OrganizationUserManagement: React.FC = () => {
  const { data, error, loading, refetch } = useOrganizationUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...organizationUsersIncludeProps,
    },
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = useOrganizationColumns();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<User>();

  useSetItemsInLocalState<User>(data?.organizationUsers);

  const {
    contextMenuActions,
    handleCloseAddToProject,
    handleCloseEditRole,
    userToEdit,
  } = useUsersActions();

  return (
    <ResponseWrapper error={error} isLoading={loading && !data}>
      <TableContextProvider
        columns={columns}
        id="table-organization-user-management"
        items={items}
      >
        <FilterContextProvider<User>>
          <OrganizationUsersActionBar />
          {!items.length && !itemsInitial.length ? (
            <BigMessageNoOrganizationUsers />
          ) : isMobile ? (
            <OrganizationUserListMobile users={items} />
          ) : (
            <Table<User> contextMenuActions={contextMenuActions} />
          )}
          <AddUsersToProjectSidebar
            onClose={handleCloseAddToProject}
            userToAdd={userToEdit}
          />
          <EditRolesSidebar
            onClose={handleCloseEditRole}
            userToEdit={userToEdit}
          />
          <InviteUsersSidebar refetch={refetch} />
          <InviteExistingUsersSidebar />
        </FilterContextProvider>
      </TableContextProvider>
    </ResponseWrapper>
  );
};
