import { ValueTypeName } from '@pro4all/graphql';
import { forbiddenFolderNames } from '@pro4all/shared/config';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useFolderFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Name', // i18n
      matches: /^[^\n:/?#@!$&*+,;=<>"|%{}^[\]\\`]*$/,
      name: 'name',
      notOneOf: forbiddenFolderNames,
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
