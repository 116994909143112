import React from 'react';

import { CommentsList } from '@pro4all/communication/ui/comments';
import { ThreadContextProvider } from '@pro4all/communication/ui/comments';
import {
  QualityControlInstance,
  QualityControlInstanceType,
  ReferenceInput,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const ResultComments: React.FC<{
  instance: QualityControlInstance;
  projectId?: string;
}> = ({ instance, projectId }) => {
  const { searchParams } = useRouting();
  const resultId = searchParams.get('id') ?? '';

  const referenceType =
    instance.type ===
    (QualityControlInstanceType.Snag ||
      instance.type === QualityControlInstanceType.FreeSnag)
      ? ReferenceType.Snag
      : ReferenceType.Form;

  // TODO: when the backend refactoring is finished the referenceId key should be removed.
  //       Also should be removed in all the mappings and transformations of this references.
  const references: ReferenceInput[] = [
    ...(projectId
      ? [
          {
            referenceId: null,
            referenceKind: ReferenceKind.LinkInfo,
            referenceType: ReferenceType.Project,
            referenceValue: projectId,
          },
        ]
      : []),
  ];

  const target: ReferenceInput = {
    referenceId: null,
    referenceKind: ReferenceKind.Target,
    referenceType,
    referenceValue: resultId,
  };

  return (
    <ThreadContextProvider
      newComment
      references={references}
      target={target}
      targetId={resultId}
    >
      <CommentsList />
    </ThreadContextProvider>
  );
};
