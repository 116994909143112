import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import { ActionType } from '../shared/types';

import { permissionsEntityMatrixReducer } from './permissionsEntityMatrixReducer';
import {
  EntityPermission,
  EntityPermissionSet,
  EntityPermissionToggle,
  State,
} from './types';

type ContextType = {
  eraseState: () => void;
  resetPermissions: () => void;
  setInitialPermissions: (permissions: EntityPermission[]) => void;
  setPermission: (permission: EntityPermissionSet) => void;
  state: State;
  toggleAllPermissions: (entityId: string) => void;
  toggleBackdrop: () => void;
  togglePermission: (permission: EntityPermissionToggle) => void;
  updatePermissionsAfterSave: ({
    successful,
    unsuccessful,
  }: {
    successful: string[];
    unsuccessful: string[];
  }) => void;
};

const PermissionsEntityMatrixContext = React.createContext(null);

export function usePermissionsEntityMatrixContext() {
  return useContext<ContextType>(PermissionsEntityMatrixContext);
}

export function PermissionsEntityMatrixProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [state, dispatch] = useReducer(permissionsEntityMatrixReducer, {
    displayPermissions: [],
    initialPermissionsDisplay: [],
    initialPermissionsExplicit: [],
    savePermissions: [],
  });

  // Define all actions
  const updatePermissionsAfterSave = useCallback(
    ({
      successful,
      unsuccessful,
    }: {
      successful: string[];
      unsuccessful: string[];
    }) => {
      dispatch({
        payload: { successful, unsuccessful },
        type: ActionType.UPDATE_PERMISSIONS_AFTER_SAVE,
      });
    },
    []
  );
  const eraseState = useCallback(() => {
    dispatch({
      type: ActionType.ERASE_STATE,
    });
  }, []);
  const resetPermissions = useCallback(() => {
    dispatch({
      type: ActionType.RESET_PERMISSIONS,
    });
  }, []);
  const setInitialPermissions = useCallback(
    (permissions: EntityPermission[]) => {
      dispatch({
        payload: { permissions },
        type: ActionType.SET_INITIAL_PERMISSIONS,
      });
    },
    []
  );
  const setPermission = useCallback((permission: EntityPermissionSet) => {
    dispatch({
      payload: permission,
      type: ActionType.SET_PERMISSION,
    });
  }, []);
  const toggleAllPermissions = useCallback((entityId) => {
    dispatch({
      payload: entityId,
      type: ActionType.TOGGLE_ALL_PERMISSIONS,
    });
  }, []);
  const toggleBackdrop = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_BACKDROP,
    });
  }, []);
  const togglePermission = useCallback((permission: EntityPermissionToggle) => {
    dispatch({
      payload: permission,
      type: ActionType.TOGGLE_PERMISSION,
    });
  }, []);

  return (
    <PermissionsEntityMatrixContext.Provider
      value={{
        eraseState,
        resetPermissions,
        setInitialPermissions,
        setPermission,
        state,
        toggleAllPermissions,
        toggleBackdrop,
        togglePermission,
        updatePermissionsAfterSave,
      }}
    >
      {children}
    </PermissionsEntityMatrixContext.Provider>
  );
}

export function useSetPermissionsEntityInLocalState(
  permissions: EntityPermission[]
) {
  const { setInitialPermissions } = usePermissionsEntityMatrixContext();
  useEffect(() => {
    setInitialPermissions(permissions || []);
  }, [permissions, setInitialPermissions]);
}
