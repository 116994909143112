import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  QualityControlInstance,
  Task,
  TaskType,
} from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { Action, Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';
import { LinkedInstance } from '@pro4all/workflow/ui/utils';

import CardMeta from '../CardMeta';

import { CustomIconComponent } from './CustomIconComponent';
import { CardConfig, Drawing } from './types';

export const useGetCardConfigs = ({
  currentTask,
  drawings,
  taskType,
}: {
  currentTask: Task;
  drawings: Drawing[];
  taskType: TaskType;
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { goTo, params: routeParams, searchParams, url } = useRouting();
  const { userId } = AuthService.getProfile();
  const isSelectIconEnabled = useFeatureFlag('customericons');
  const formInstanceId = searchParams.get('id') || '';
  const isWorkflow = useRouteMatch([Routes.projectWorkflows]);
  const isTbq =
    currentTask.type === TaskType.Tbq || currentTask.type === TaskType.TbqScan;

  const assignedUser = currentTask?.assignment?.[0]?.id;
  const projectId = currentTask.project?.id;
  const taskId = currentTask?.id;

  const getClickQualityControlInstance = (templateId: string, name: string) => {
    track(Action.ItemOpened, {
      id: templateId,
      location: 'Quality task',
      name: name,
      projectId,
      type: 'form',
    });
    goTo('projectQualityControlTasks', {
      params: {
        projectId,
      },
      searchParams: {
        action: userId === assignedUser ? 'editResult' : 'viewResult',
        id: '',
        projectId,
        taskId,
        templateId,
      },
    });
  };

  const goToDrawings = (visualContextId: string) => {
    goTo(getDrawingRoute(routeParams), {
      params: {
        objectId: routeParams.objectId,
        projectId,
        visualContextId: visualContextId,
      },
      searchParams: {
        formInstanceId: currentTask?.tbqFormInstances
          ? currentTask?.tbqFormInstances[0]?.id
          : formInstanceId,
        id: visualContextId,
        placeSnag: isTbq || taskType === TaskType.QualityControl,
        taskId,
      },
      state: {
        clickedTaskId: taskId,
        previousPageName: isWorkflow ? 'workflow' : 'tasks',
        previousPageUrl: url,
      } as DrawingRouterState,
    });
  };

  const goToSnag = (id: string, name: string, documentFolder: string) => {
    track(Action.ItemOpened, {
      id: id,
      location: 'Resolve task',
      name: name,
      projectId,
      type: 'snag',
    });

    searchParams.set({
      action: userId === assignedUser ? 'editResult' : 'viewResult',
      id,
    });
  };

  const cardConfigs: CardConfig[] = [
    //deliverables are documents that are linked to the task
    {
      cardClick: (id: string, documentFolder: string) => {
        ['action', 'taskType'].forEach((param) => searchParams.delete(param));
        goTo('document', {
          params: { folderId: documentFolder, projectId: projectId },
          searchParams: { id: id },
        });
      },
      iconName: 'file',
      key: 'deliverables',
      label: () =>
        t(
          titleCaseToSentenceCase(
            currentTask?.documentAction ?? 'For information'
          ) ?? 'For information'
        ),
      meta: (instance: LinkedInstance) => (
        <CardMeta instance={instance as Document} />
      ),
    },
    {
      cardClick: (id: string, documentFolder: string, name: string) =>
        getClickQualityControlInstance(id, name),
      iconComponent: (currentTask: Task, instance: LinkedInstance) => (
        <CustomIconComponent
          currentTask={currentTask}
          instance={instance}
          type="form"
        />
      ),
      iconName: isSelectIconEnabled ? 'staticFormIcon' : 'clipboard',
      key: 'formTemplates',
      label: () => t('Forms'),
    },
    {
      cardClick: (id: string, documentFolder: string, name: string) =>
        goToSnag(id, name, documentFolder),
      condition: currentTask.type === TaskType.Resolve,
      iconComponent: (currentTask: Task, instance: LinkedInstance) => (
        <CustomIconComponent
          currentTask={currentTask}
          instance={instance}
          type="snag"
        />
      ),
      iconName: isSelectIconEnabled ? 'staticSnagIcon' : 'snag',
      key: 'linkedSnagInstances',
      label: () => t('Snags to solve'),
      meta: (instance: LinkedInstance) => (
        <CardMeta instance={instance as QualityControlInstance} />
      ),
    },
    {
      iconComponent: (currentTask: Task, instance: LinkedInstance) => (
        <CustomIconComponent
          currentTask={currentTask}
          instance={instance}
          type="snag"
        />
      ),
      iconName: isSelectIconEnabled ? 'staticSnagIcon' : 'snag',
      key: 'snagTemplates',
      label: () => t('Snags available to place'),
    },
    //these are drawings that are actually linked to the task themselves
    {
      cardClick: (id) => goToDrawings(id),
      iconName: 'map',
      key: 'visualContexts',
      label: () => t('Drawings'),
    },
    {
      // these are the drawings that linked snags have been placed on,
      // technically the drawings themselves are not actually linked to the task.
      cardClick: (id) => {
        goToDrawings(id);
      },
      condition: drawings.length > 0 && currentTask.type === TaskType.Resolve,
      iconName: 'map',
      key: 'linkedSnagDrawings',
      label: () => t('Drawings'),
    },
  ];

  return { cardConfigs };
};
