import { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client/errors';

import { Task, TbqRieResult, useObjectIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Loader,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { Table, TableContextProvider } from '@pro4all/shared/ui/general';
import { TBQTaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import * as Messages from '../../Messages';

import { TbqQuestionSidebar } from './question-sidebar/TbqQuestionSidebar';
import { QuestionsResultActionBar } from './QuestionsResultActionBar';
import { useCalculateResolveTaskPerQuestion } from './useCalculateResolveTaskPerQuestion';
import { useRieTableActions } from './useRieTableActions';
import { useTBQCalculateCurrentQuestionResolveTasks } from './useTBQCalculateResolveTasksState';
import { useTBQRieResultsColumns } from './useTBQRieResultsColumns';

interface TBQResultsProps {
  error: ApolloError | undefined;
  loading: boolean;
  rieResults: TbqRieResult[];
}

export const RieTaskResults = ({
  rieResults,
  error,
  loading,
}: TBQResultsProps) => {
  const {
    searchParams,
    params: { objectId: id },
  } = useRouting();
  const altActions = useRieTableActions();
  const [currentQuestion, setCurrentQuestion] = useState<TbqRieResult>();
  const [selectedRows, setSelectedRows] = useState<TbqRieResult[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [tableKey, setTableKey] = useState(0);

  const objectId = id ?? '';

  const selectedId = searchParams.get('selectedId') ?? '';
  const action = searchParams.get('action');

  const { data: objectData } = useObjectIncludeQuery({
    fetchPolicy: 'no-cache',
    skip: !objectId,
    variables: {
      id: objectId,
      includeProject: true,
      includeProjectId: true,
      includeTasks: true,
    },
  });

  const procedureId = objectData?.object?.project?.mainProcedure?.id ?? '';
  const projectId = objectData?.object?.project?.id ?? '';
  const objectTasks = objectData?.object?.tasks
    ? objectData?.object?.tasks
    : [];

  useEffect(() => {
    if (objectTasks.length > 0) {
      const updatedTasks = objectTasks
        .flatMap((task) => task)
        .filter((link) => link.tbqLinkedQuestions !== null) as Task[];
      setTasks(updatedTasks);
    }
  }, [objectTasks]);

  const {
    state: { items },
  } = useOptimisticResponseContext<TbqRieResult>();
  useSetItemsInLocalState<TbqRieResult>(rieResults || []);

  const relevantTasksForAllQuestions = useCalculateResolveTaskPerQuestion(
    items,
    tasks
  );

  useEffect(() => {
    if (relevantTasksForAllQuestions.length > 0) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [relevantTasksForAllQuestions]);

  const columns = useTBQRieResultsColumns(relevantTasksForAllQuestions);

  useEffect(() => {
    setCurrentQuestion(items.find((item) => item.id === selectedId));
  }, [selectedId, items]);

  const relevantTasks = useTBQCalculateCurrentQuestionResolveTasks(
    tasks,
    currentQuestion as TbqRieResult,
    currentQuestion?.taskId as string
  );

  if (error) return <Messages.Error error={error} />;

  const handleCloseQuestionSidebar = () => {
    searchParams.clear();
  };

  const handleRowClick = (row: TbqRieResult) => {
    searchParams.set('selectedId', row.id);
    searchParams.set('action', 'properties');
  };

  return (
    <TableContextProvider
      checkable
      checkableSpacing
      columns={columns}
      id="table-tbq-rie-results"
      items={items}
      key={tableKey}
    >
      <FilterContextProvider<TbqRieResult>>
        <QuestionsResultActionBar
          projectId={projectId}
          setSelectedRows={setSelectedRows}
        />
        <Table
          contextMenuActions={altActions}
          emptyRenderer={loading ? <Loader /> : null}
          headerHeight={40}
          onRowClick={(row) => handleRowClick(row as TbqRieResult)}
          selectedId={selectedId}
        />
        {Boolean(selectedId) && Boolean(action) && (
          <TbqQuestionSidebar
            linkedTasks={relevantTasks}
            onClose={handleCloseQuestionSidebar}
            question={currentQuestion}
          />
        )}
        <TBQTaskSidebar
          procedureId={procedureId}
          projectId={projectId}
          tbqQuestion={selectedRows.length ? selectedRows : currentQuestion}
          tbqRieOnClose={handleCloseQuestionSidebar}
        />
      </FilterContextProvider>
    </TableContextProvider>
  );
};
