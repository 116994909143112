import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageError: React.FC<{ error?: ApolloError | Error }> = ({
  error,
}) => {
  const { t } = useTranslation();

  return (
    <BigMessage
      data-testid="error"
      shapeName="flow1"
      text={t(
        error?.message ?? 'Something went wrong. Please try again later.'
      )}
      title={t(error?.name ?? 'Oops...')}
    />
  );
};
