import { Folder } from '@pro4all/graphql';

export const findNearestParentFolderWithMetaDataSet = ({
  folders,
  parentFolderId,
}: {
  folders: Folder[];
  parentFolderId: string;
}) => {
  // Function to find a folder based on an id
  const findFolderById = (id: string) =>
    folders.find((folder) => folder.id === id);

  let currentFolder = findFolderById(parentFolderId);

  // Loop through the parent folders until we find a folder with metadataSetId or no longer have a parent folder
  while (currentFolder) {
    if (
      currentFolder.metadataSetId !== null &&
      currentFolder.inheritParentMetadata
    ) {
      return currentFolder; // Return the folder if metadataSetId is not null
    }
    // Find the parent folder
    currentFolder = findFolderById(currentFolder.parentFolderId);
  }

  return null; // If we do not find a folder with metadataSetId, return null
};
