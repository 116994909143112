import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Folder } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { QuickAction } from '@pro4all/shared/ui/general';

import { FolderForm } from './FolderForm';

interface Props {
  folder: Folder;
}

export const FolderRename: React.FC<Props> = ({ folder }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(() => searchParams.is('action', 'renameFolder'));
  }, [searchParams]);

  const handleClose = () => searchParams.clear();
  return (
    <QuickAction
      headerText={t('Rename folder')}
      input={isOpen && <FolderForm folder={folder} onClose={handleClose} />}
      open={isOpen}
    />
  );
};
