import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const ProjectUsersGroupsTabs: React.FC = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { projectId } = params;

  const base = `${generateRoute('projectUsersGroups', {
    params: { projectId },
  })}/`;

  return (
    <SubTabs base={base}>
      <Tab
        data-testid="project-members-tab"
        label={t('Members')}
        onClick={() => {
          goTo('projectMembers', {
            params: { projectId },
          });
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId,
            route: 'projectMembers',
          });
        }}
        value="members"
      />
      <Tab
        data-testid="project-groups-tab"
        label={t('Groups')}
        onClick={() => {
          goTo('projectGroups', {
            params: { projectId },
          });
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId,
            route: 'projectGroups',
          });
        }}
        value="groups"
      />
    </SubTabs>
  );
};
