import { Form } from 'formik';
import styled from 'styled-components';

import { Text } from '@pro4all/shared/ui/typography';

export const ButtonLabel = styled(Text)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
    margin-bottom: 0;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ModalActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Title = styled(Text)`
  && {
    margin: ${({ theme }) => theme.spacing(1)} 0
      ${({ theme }) => theme.spacing(1)} 0;
  }
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1rem;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
`;
