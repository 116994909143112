import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Group,
  GroupsIncludeDocument,
  useCreateGroupMutation,
  User,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';
import { sortBy } from '@pro4all/shared/utils';

import { groupsIncludeProps } from './getIncludeProps';
import { FormFields } from './Types';

export const useCreateGroup = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useRouting().params;
  const { showMutationMessage } = useShowMessages();
  const [create] = useCreateGroupMutation();
  const { meData, userId } = useOrganizationContext();

  const {
    state: { items },
  } = useOptimisticResponseContext<Group>();

  return async ({
    onClose,
    values,
  }: {
    onClose: () => void;
    values: FormFields;
  }) => {
    const { displayName: name, subgroups, members: memberOptions } = values;
    const addedSubgroupIds = subgroups.map((subgroup) => subgroup.id);

    try {
      const { data } = await create({
        variables: {
          members: memberOptions.map(({ id, type: memberType }) => ({
            id,
            type: memberType === 'Group' ? 1 : 0,
          })),
          name,
          projectId,
          subgroups: addedSubgroupIds,
        },
      });

      onClose();

      if (data.createGroup.success) {
        showMutationMessage({
          action: MessageAction.Create,
          entityType: EntityTypeTranslation.ProjectGroup,
          name,
        });

        const loggedInUser: User = {
          ...meData?.user,
          __typename: 'User',
          id: userId,
        };
        const { __typename, displayName, id } = loggedInUser;

        const newGroup: Group = {
          __typename: 'Group',
          createdAt: Date.now(),
          createdBy: { __typename, displayName, id },
          displayName: name,
          id: data.createGroup.id,
          membersCount: memberOptions.length,
          subgroupIds: addedSubgroupIds,
        };

        const updatedItems = [...items, newGroup].sort(
          sortBy({ key: 'displayName' })
        );

        client.writeQuery({
          data: {
            groups: updatedItems,
          },
          query: GroupsIncludeDocument,
          variables: {
            ...groupsIncludeProps,
            projectId: projectId ? projectId : null,
          },
        });
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      }
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
