import { StorageKeys } from '@pro4all/shared/config';

export const ProjectIdInterceptor = (config: {
  headers: { [key: string]: string };
}) => {
  const projectId = sessionStorage.getItem(StorageKeys.PROJECT_ID);

  if (projectId && projectId !== '') {
    config.headers['X-P4-ProjectId'] = projectId;
  }

  return config;
};

export default ProjectIdInterceptor;
