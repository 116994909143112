import { FilterType } from '@pro4all/graphql';
import { filterKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  AUTO_OPEN_FLAG,
  delimiters,
  FilterBaseProps,
  HIDDEN_FLAG,
  isMetaDataType,
  parseFilterString,
  RELOAD_FLAG,
  SetFilterValueArgs,
} from '@pro4all/shared/search-utils';

import { filterNames } from '../records';

import { extraFilterOptions } from './extraFilterOptions';
import { parseFilterSection } from './parseFilterString';

export const useFilters = () => {
  const { searchParams } = useRouting(true);
  const paramFilters = searchParams.get('filters');
  const paramsFilterSection = searchParams.get('filterSection');
  const currentFilters = paramFilters ? parseFilterString(paramFilters) : null;
  const currentFilterSection = paramsFilterSection
    ? parseFilterSection(paramsFilterSection)
    : null;

  // Types of filters that are rendered in the FilterBar based on current params
  const includedTypes = currentFilters
    ?.map((filter) => filter.type?.toString())
    .filter(Boolean);

  // Extra filter (types) tucked away in the dropdown menu
  const availableFilterOptions = includedTypes?.length
    ? extraFilterOptions.filter(
        ({ id: type }) => !includedTypes?.includes(type) || isMetaDataType(type)
      )
    : extraFilterOptions;

  // Set params according to incoming filters
  const setParams = (
    filters: FilterBaseProps[],
    reload?: boolean,
    hidden?: boolean
  ) => {
    const newFilterParams = filters.map(
      ({ type, value, metaDataKey }) =>
        `${filterKeys[type]}${
          metaDataKey ? `${delimiters.subTypeKey}${metaDataKey}` : ''
        }${delimiters.keyValue}${value}${reload ? RELOAD_FLAG : ''}${
          hidden ? HIDDEN_FLAG : ''
        }`
    );

    // Ensure reload flag is present if no filters are present
    // This is necessary for QC Search, since doesn't have any default filters like Document Search
    if (newFilterParams.length === 0) {
      newFilterParams.push(RELOAD_FLAG);
    }
    const joined = newFilterParams.join(delimiters.filters);
    searchParams.set('filters', joined);
  };

  // Update single filter in params
  const updateFilterParam = ({
    index,
    filterValue,
    reload,
    hidden,
  }: {
    filterValue: FilterBaseProps;
    hidden?: boolean;
    index: number;
    reload?: boolean;
  }) => {
    if (!currentFilters) return;
    // Clone currentFilters and replace with updated filter at index
    const clonedFilters = [...currentFilters];
    clonedFilters[index] = filterValue;
    setParams(clonedFilters, reload, hidden);
  };

  // Add filter or init first in params
  const addFilter = ({
    type,
    value,
    metaDataKey,
    isPreOpened = false,
    filterSectionValue,
    reload,
  }: Omit<
    FilterBaseProps & {
      filterSectionValue?: string;
      isPreOpened?: boolean;
      reload?: boolean;
    },
    'index'
  >) => {
    const mdKeyString = metaDataKey
      ? `${delimiters.subTypeKey}${metaDataKey}`
      : '';

    const delimiter = isPreOpened ? delimiters.subTypeKey : delimiters.keyValue;

    const filterString = `${
      filterKeys[type]
    }${mdKeyString}${delimiter}${value}${isPreOpened ? AUTO_OPEN_FLAG : ''}${
      reload ? RELOAD_FLAG : ''
    }`;
    const filterSectionString = `${filterKeys[type]}${delimiters.keyValue}${
      filterSectionValue || ''
    }`;

    searchParams.set({
      filterSection: filterSectionString,
      filters: paramFilters
        ? `${paramFilters}${delimiters.filters}${filterString}`
        : filterString,
    });
  };

  // works similarly to addFilter, but for multiple filters
  const addFilters = (
    params: Omit<
      FilterBaseProps & { filterSectionValue?: string; isPreOpened?: boolean },
      'index'
    >[],
    options?: { closeSnagMetaDataModal?: boolean }
  ) => {
    params.forEach(
      ({
        type,
        value,
        metaDataKey,
        isPreOpened = false,
        filterSectionValue,
      }) => {
        const updatedFilterParams = searchParams.get('filters');
        const updatedSectionParams = searchParams.get('filterSection');
        const qcMetadataModalParams = searchParams.get('qcMetadataModal') || '';
        const mdKeyString = metaDataKey
          ? `${delimiters.subTypeKey}${metaDataKey}`
          : '';

        const delimiter = delimiters.subTypeKey;

        const filterString = `${
          filterKeys[type]
        }${mdKeyString}${delimiter}${value}${
          isPreOpened ? AUTO_OPEN_FLAG : ''
        }`;
        const filterSectionString = `${value}${delimiters.keyValue}${
          filterSectionValue || ''
        }`;

        searchParams.set({
          filterSection: updatedSectionParams
            ? `${updatedSectionParams}${delimiters.filters}${filterSectionString}`
            : filterSectionString,
          filters: updatedFilterParams
            ? `${updatedFilterParams}${delimiters.filters}${filterString}`
            : filterString,
          qcMetadataModal: options?.closeSnagMetaDataModal
            ? ''
            : qcMetadataModalParams,
        });
      }
    );
  };

  // Set a value for a given filter type in params
  const setFilterValue = ({
    metaDataKey,
    reload,
    type,
    value,
    hidden = false,
  }: SetFilterValueArgs) => {
    if (!currentFilters) {
      // Init standard filter
      const newFilter = {
        active: false,
        metaDataKey,
        name: filterNames[type],
        type,
        value,
      };
      setParams([newFilter], reload, hidden);
    } else {
      const index = currentFilters.findIndex(
        (filter) =>
          filter.type === type &&
          (filter.metaDataKey === metaDataKey || !metaDataKey)
      );
      const selectedFilter = currentFilters[index];
      // Overwrite value
      const updatedFilter = { ...selectedFilter, value };
      updateFilterParam({ filterValue: updatedFilter, hidden, index, reload });
    }
  };

  // Clear value for a given filter type in params
  const resetFilter = (type: FilterType, metaDataKey?: string) => {
    if (!currentFilters) return;
    const index = currentFilters.findIndex((filter) =>
      metaDataKey
        ? filter.type === type && filter.metaDataKey === metaDataKey
        : filter.type === type
    );
    if (index < 0) return;
    const selectedFilter = currentFilters[index];
    // Update state and params
    const updatedFilter = { ...selectedFilter, value: RELOAD_FLAG };
    updateFilterParam({ filterValue: updatedFilter, index });
  };

  // Clear all filter values in params
  const resetAllFilters = () => {
    if (!currentFilters) return;
    const initialValues = currentFilters
      ?.map((filter) => filter.value)
      .filter(Boolean);
    if (!currentFilters || !initialValues?.length) return;
    const emptyFilters = currentFilters?.map((filter) => ({
      ...filter,
      value: filter.type === FilterType.ParentFolderIds ? filter.value : '', // Reset all except folder
    }));
    setParams(emptyFilters, true);
  };

  // Remove filter from params
  const removeFilter = ({
    type,
    metaDataKey,
    refetch,
  }: {
    metaDataKey?: string;
    refetch?: boolean;
    type: FilterType;
  }) => {
    if (!currentFilters) return;
    const index = currentFilters.findIndex(
      (filter) =>
        filter.type === type &&
        (filter.metaDataKey === metaDataKey || !filter.metaDataKey)
    );
    if (index < 0) return;
    // Remove the filter at index or clear (splice does not remove the last element)
    const updatedFilters = [...currentFilters];
    const removedFilter = updatedFilters.splice(index, 1)[0];
    const refreshSearch = Boolean(removedFilter.value !== '' || refetch);

    setParams(updatedFilters, refreshSearch);
  };

  const setFieldDefinition = ({
    newKey,
    oldKey,
    type,
  }: {
    newKey?: string;
    oldKey?: string;
    type: string;
  }) => {
    if (!currentFilters) return;
    const index = currentFilters?.findIndex(
      (currentFilter) =>
        currentFilter.type === type && currentFilter.metaDataKey === oldKey
    );
    if (!index || index < 0) return;
    const updatedField = {
      ...currentFilters[index],
      metaDataKey: newKey,
      value: '',
    };
    const copiedFields = [...currentFilters];
    copiedFields[index] = updatedField;
    setParams(copiedFields);
  };

  return {
    addFilter,
    addFilters,
    availableFilterOptions,
    currentFilterSection,
    currentFilters,
    removeFilter,
    resetAllFilters,
    resetFilter,
    setFieldDefinition,
    setFilterValue,
  };
};
