import userflow from 'userflow.js';

import { useOrganizationContext } from '@pro4all/organization/context';
import { isE2E } from '@pro4all/shared/utils';

let initialized = false;

export function init() {
  // Because we do not have separate builds for different environments we have
  // programmed nginx to replace text in our bundles with env variables. This
  // is a hack which makes nginx replace the string below with the actual env
  // variables if they are present and set on the container.
  const token = process.env.NX_USERFLOW_TOKEN ?? 'process.env.USERFLOW_TOKEN';

  // nginx will replace every instance of the process.env.USERFLOW_TOKEN string
  // we need to check validity without sub_filter borking the check
  if (isE2E() || !token || token.endsWith('USERFLOW_TOKEN')) return;

  userflow.init(token);
  userflow.setResourceCenterLauncherHidden(true);

  initialized = true;
}

export function useIdentify() {
  const skip = isE2E() || !initialized || userflow.isIdentified();
  const { meData, userflowSignature, userId } = useOrganizationContext();

  if (!meData || skip) return;

  userflow.identify(userId, {}, { signature: userflowSignature });
}
