import { InheritedPermission, UserQcPermissions } from '@pro4all/graphql';
export const getPermissionName = (permStr: string) =>
  permStr?.substring(0, permStr.lastIndexOf('.'));
export const getPermissionValue = (permStr: string) =>
  permStr?.substring(permStr.lastIndexOf('.') + 1);
export const hierarchy = ['none', 'own', 'assigned', 'ownAssigned', 'all'];
export const getHierarchyIndex = (permVal: string) =>
  hierarchy.findIndex((val) => val === getPermissionValue(permVal));
export const getInheritedPermissionsHierarchy = (
  permissionName: string,
  inheritedPermissions: InheritedPermission[]
) =>
  inheritedPermissions?.map((inherited) => {
    const matchedInheritedPermission = inherited?.permissions?.find(
      (perm: string) => getPermissionName(perm) === permissionName
    );
    const hierarchyIndex = getHierarchyIndex(matchedInheritedPermission ?? '');
    return hierarchyIndex;
  });

export const getHighestPermissions = (userPermissions?: UserQcPermissions) => {
  const directPermissions = userPermissions?.directPermissions;
  //There is a bug in Be where if a user becomes part of a project via a group they don't have directPermissions
  // so this userMemberViaGroup is a workaround until they are able to properly fix that.
  const userMemberViaGroup =
    directPermissions?.length === 0 ||
    directPermissions === null ||
    directPermissions === undefined;

  const highestPermissions = (
    userMemberViaGroup
      ? userPermissions?.inheritedPermissions?.[0]?.permissions
      : userPermissions?.directPermissions
  )?.map((permStr: string) => {
    const permissionName = getPermissionName(permStr);
    const inheritedHierarchy = getInheritedPermissionsHierarchy(
      permissionName,
      userPermissions?.inheritedPermissions ?? []
    );
    const directPermVal = getPermissionValue(permStr);
    const hierarchyIndexes = [
      getHierarchyIndex(directPermVal),
      ...inheritedHierarchy,
    ];
    const highestValue = hierarchy[Math.max(...hierarchyIndexes)];
    const highestPermission = permissionName + '.' + highestValue;
    return highestPermission;
  });
  return highestPermissions;
};
