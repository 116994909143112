import { Folder, useFolderByPathQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetFolder = () => {
  const { path, projectId } = useRouting().params;

  const { loading, data, refetch, error } = useFolderByPathQuery({
    fetchPolicy: 'cache-and-network',
    variables: { path: path ?? '/', projectId },
  });

  return { error, folder: data?.folder as Folder, loading, path, refetch };
};
