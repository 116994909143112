import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const Container = styled(Box)`
  && {
    padding: ${({ theme }) => theme.spacing(1)} 0;
    border-top: 2px solid ${customColors.grey400};
  }
`;

export const Header = styled(Box)`
  && {
    display: flex;
    align-items: center;
  }
`;

export const HeaderText = styled(Text)`
  && {
    cursor: pointer;
  }
`;

export const VersionPaneWrapper = styled(Box)<{ $expanded: boolean }>`
  && {
    height: ${({ $expanded }) => ($expanded ? '240px' : '0px')};
    transition: height 0.1s;
    transition-timing-function: ease-out;
  }
`;
