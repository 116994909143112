import React, { createContext, useContext, useMemo, useState } from 'react';

interface ApiKeyContextValue {
  apiToken: string;
  setApiToken: (token: string) => void;
  setShowRevokeWarning: (show: boolean) => void;
  showRevokeWarning: boolean;
}

export const ApiKeyContext = createContext({} as ApiKeyContextValue);

export const useApiKeyContext = () =>
  useContext<ApiKeyContextValue>(ApiKeyContext) || {
    apiToken: '',
    setApiToken: null,
    setShowRevokeWarning: null,
    showRevokeWarning: false,
  };

export const ApiKeyContextProvider: React.FC = ({ children }) => {
  const [apiToken, setApiToken] = useState('');
  const [showRevokeWarning, setShowRevokeWarning] = useState(false);

  const value = useMemo(
    () => ({
      apiToken,
      setApiToken,
      setShowRevokeWarning,
      showRevokeWarning,
    }),
    [apiToken, setApiToken, setShowRevokeWarning, showRevokeWarning]
  );

  return (
    <ApiKeyContext.Provider value={value}>{children}</ApiKeyContext.Provider>
  );
};
