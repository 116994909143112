import React from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentContext } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Link } from '@pro4all/shared/ui/general';

import { DownloadText } from './Attachment.styles';
import { useDownloadAll } from './useDownloadAll';

interface Props {
  context: AttachmentContext;
  messageId: string;
  onDownload?: () => void;
}

export const DownloadAllAttachments: React.FC<Props> = ({
  messageId,
  onDownload,
  context,
}) => {
  const onClick = useDownloadAll({ context, messageId, onDownload });

  const { t } = useTranslation();
  return (
    <Box alignSelf="flex-start">
      <Link onClick={onClick}>
        <DownloadText variant="h6">
          {t('Download all attachments as Zip')}
        </DownloadText>
      </Link>
    </Box>
  );
};
