import { RouteParams } from '@pro4all/shared/config';

import { GoToRoute } from './useRouting';

interface UpdateProjectStorageProps {
  overwriteCurrentValue?: boolean;
  params?: RouteParams;
  projectId: string;
  route: GoToRoute;
}

interface UpdateOrganizationStorageProps {
  params?: RouteParams;
}

type OrganizationLocalStorageValue = { path?: string };
type LocalStorageValue = { params?: RouteParams; route: GoToRoute };
type LocalStorageType = {
  [key: string]: LocalStorageValue;
};

const EXCLUDED_ROUTES: GoToRoute[] = [
  'projDocsSharedInScope',
  'projDocsSharedByInvite',
];

const localStorageAttribute = 'prostream-projects-route-last-visited';
const localProjectDocumentsPath = 'prostream-projects-document-route';
const localOrganizationDocumentsPath = 'prostream-organization-document-route';

export const getLastVisitedRouteProjects = () => {
  const localStorageValue = localStorage.getItem(localStorageAttribute);
  return localStorageValue
    ? (JSON.parse(localStorageValue) as LocalStorageType)
    : null;
};

export const getLastVisitedFolderProjects = () => {
  const localStorageValue = localStorage.getItem(localProjectDocumentsPath);
  return localStorageValue
    ? (JSON.parse(localStorageValue) as LocalStorageType)
    : null;
};

export const getLastVisitedOrganizationFolder = () => {
  const localStorageValue = localStorage.getItem(
    localOrganizationDocumentsPath
  );
  return localStorageValue
    ? (JSON.parse(localStorageValue) as OrganizationLocalStorageValue)
    : null;
};

export const getLastVisitedProjectRoute = (projectId: string) => {
  const localStorageValue = getLastVisitedRouteProjects();
  if (!localStorageValue) return null;
  return localStorageValue[projectId]
    ? (localStorageValue[projectId] as LocalStorageValue)
    : null;
};

export const getLastVisitedProjectFolder = (projectId: string) => {
  const localStorageValue = getLastVisitedFolderProjects();
  if (!localStorageValue) return null;
  return localStorageValue[projectId]
    ? (localStorageValue[projectId] as LocalStorageValue)
    : null;
};

export const updateLsLastVisitedProjectRoute = ({
  overwriteCurrentValue = true,
  params,
  projectId,
  route,
}: UpdateProjectStorageProps) => {
  if (EXCLUDED_ROUTES.includes(route)) return;

  let localStorageValue = getLastVisitedRouteProjects();

  const newValue = { params, route };
  if (localStorageValue) {
    // Local storage exists, check if we have to write the value.
    if (overwriteCurrentValue) {
      localStorageValue[projectId] = newValue;
    }
  } else {
    // No local storage yet, create it and add initial value.
    localStorageValue = { [projectId]: newValue };
  }

  localStorage.setItem(
    localStorageAttribute,
    JSON.stringify(localStorageValue)
  );
};

export const updateLsLastVisitedProjectFolder = ({
  params,
  projectId,
  route,
}: UpdateProjectStorageProps) => {
  if (EXCLUDED_ROUTES.includes(route)) return;

  const newValue = { params, route };
  const documentPath = getLastVisitedFolderProjects() ?? {
    [projectId]: null,
  };

  documentPath[projectId] = newValue;

  localStorage.setItem(localProjectDocumentsPath, JSON.stringify(documentPath));
};

export const updateLsLastVisitedOrganizationFolder = ({
  params,
}: UpdateOrganizationStorageProps) => {
  const documentPath = params ?? getLastVisitedOrganizationFolder() ?? '';

  localStorage.setItem(
    localOrganizationDocumentsPath,
    JSON.stringify(documentPath)
  );
};
