import { toReference } from '@pro4all/communication/data-access';
import {
  Reference,
  ReferenceKind,
  ReferenceType,
  User,
} from '@pro4all/graphql';

export const createReferenceFromUser = (
  referenceKind: ReferenceKind,
  id: string,
  users: User[]
): Reference | undefined => {
  const foundUser = users.find((user) => user.id === id);
  return foundUser
    ? toReference({
        referenceData: null,
        referenceId: null,
        referenceKind,
        referenceType: ReferenceType.User,
        referenceValue: foundUser.id,
      })
    : undefined;
};
