import React from 'react';
import styled from 'styled-components';

import { TextField, TextFieldProps } from '../text-field/TextField';

const StyledTextArea = styled(TextField)`
  && {
    .MuiInput-root {
      textArea {
        ::-webkit-scrollbar-track {
          border-radius: 5px;
        }
      }

      &.MuiInput-marginDense {
        textarea {
          padding: ${({ theme }) => theme.spacing(1)}
            ${({ theme }) => theme.spacing(1)};
        }
      }
    }
  }
`;

export const TextArea: React.FC<TextFieldProps> = (props) => (
  <StyledTextArea {...props} multiline rows={props.rows} />
);
