import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { unnestInstance } from '@pro4all/metadata/ui/utils';
import { InstancePageProps } from '@pro4all/shared/types';

import { useInjectDummyData } from './helpers/useInjectDummyData';
import {
  FirstPage,
  FollowUpPage,
  Instance,
  MetaLeft,
  MetaRight,
} from './elements';
import { InstancePageHeader } from './InstancePageHeader';
import { InstancePageSection } from './InstancesPageSection';

export const InstancePage = ({
  instance,
  hasFirstPageBeenPrinted,
  members,
  miniMapHtml,
  photoBlobs,
  reportConfigurationDummyTemplate,
  reportOptions,
  signatures,
}: InstancePageProps) => {
  const { t } = useTranslation();
  const userName = 'John Doe';

  const createdAt = instance ? instance.createdAt : new Date();
  const createdBy = instance
    ? instance.createdBy?.displayName
    : userName || t('Unknown');
  const id = instance ? instance?.id : uuid();
  const items = instance
    ? instance.items
    : reportConfigurationDummyTemplate
    ? reportConfigurationDummyTemplate.items
    : [];
  const linkedSnagInstances = instance ? instance.linkedSnagInstances : [];
  const name = instance
    ? instance.name
    : reportConfigurationDummyTemplate
    ? reportConfigurationDummyTemplate.name
    : '';
  const reference = instance ? instance.reference : '';
  const templateId = instance
    ? instance.templateId
    : reportConfigurationDummyTemplate
    ? reportConfigurationDummyTemplate.id
    : '';
  const type = instance ? instance.type : QualityControlInstanceType.Form;
  const visualContext = instance ? instance.visualContext : null;

  // So now we have `items`. In case the source of the items is a report configuration template, we need to fill `items` with some dummy data.
  const { injectDummyData } = useInjectDummyData();
  const itemsWithData = reportConfigurationDummyTemplate
    ? injectDummyData({ items: items ? items : [] })
    : items;

  const content = (
    <>
      <Instance.Header>
        <MetaLeft>
          <Instance.ItemList
            compactLists={reportOptions.compactLists}
            inlineLists={reportOptions.inlineLists}
            labelWidth={reportOptions.inlineLists ? 30 : 40}
            width={miniMapHtml ? undefined : 675}
          >
            <Instance.Title id={id}>
              <Instance.Icon iconName={type === 'snag' ? 'snag' : 'form'} />
              {type === 'snag' ? t('Snag') : t('Form')} - {name}
            </Instance.Title>
            <Instance.Item>
              <Instance.Label>{t('Created by')}</Instance.Label>
              <Instance.Value>{createdBy}</Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Created on')}</Instance.Label>
              <Instance.Value>{dayjs(createdAt).format('lll')}</Instance.Value>
            </Instance.Item>
            {instance && (
              <>
                <Instance.Item>
                  <Instance.Label>{t('Reference')}</Instance.Label>
                  <Instance.Value>{reference}</Instance.Value>
                </Instance.Item>
                <Instance.Item>
                  <Instance.Label>{t('Drawing')}</Instance.Label>
                  <Instance.Value>{visualContext?.name}</Instance.Value>
                </Instance.Item>
              </>
            )}
            {itemsWithData && (
              <InstancePageHeader
                instanceId={id}
                items={itemsWithData}
                reportOptions={reportOptions}
              />
            )}
          </Instance.ItemList>
        </MetaLeft>
        {miniMapHtml && miniMapHtml.length > 0 && (
          <MetaRight>
            <div
              className="map-container"
              dangerouslySetInnerHTML={{
                __html: miniMapHtml,
              }}
              id={`map-container-${id}`}
            />
          </MetaRight>
        )}
      </Instance.Header>
      {itemsWithData && (
        <InstancePageSection
          instanceId={id}
          items={itemsWithData}
          linkedSnagInstances={linkedSnagInstances ?? []}
          members={members}
          photoBlobs={photoBlobs}
          reportConfigurationDummyTemplate={reportConfigurationDummyTemplate}
          reportOptions={reportOptions}
          signatures={signatures}
          templateId={templateId || ''}
          unnestedItems={unnestInstance(itemsWithData)}
        />
      )}
    </>
  );

  return hasFirstPageBeenPrinted ? (
    <FollowUpPage>{content}</FollowUpPage>
  ) : (
    <FirstPage>{content}</FirstPage>
  );
};
