import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export const Wrapper = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

export const TitleWrapper = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    width: 100%;
  }
`;

export const TitleBox = styled(Box)`
  && {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

export const PaneWrapper = styled(Box)`
  && {
    display: flex;
    flex-wrap: wrap;
    padding: ${({ theme }) => theme.spacing(2)};
    overflow: scroll;
    height: 100%;
  }
`;
