import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useDeleteTbqScansItemsMutation } from '@pro4all/graphql';
import { ItemChangedMessage } from '@pro4all/shared/ui/messages';

export const useDeleteTbqScan = ({
  procedureId,
  taskIds,
  tbqFormIds,
  linkedSnagIds,
  deleteItems,
}: {
  deleteItems(ids: string[]): void;
  linkedSnagIds: string[];
  procedureId: string;
  taskIds: string[];
  tbqFormIds: string[];
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteTbqScansItems] = useDeleteTbqScansItemsMutation();

  const handleDelete = async () => {
    try {
      const result = await deleteTbqScansItems({
        variables: {
          linkedSnagIds,
          procedureId,
          taskIds,
          tbqFormIds,
        },
      });
      const data = result.data?.deleteTBQScansItems;

      if ((data?.successIds?.length ?? 0) > 0) {
        const messageTemplate = `{{successNumber}} item(s) deleted.`;
        const messageVariables = {
          successNumber: data?.successIds?.length,
        };
        enqueueSnackbar(
          <ItemChangedMessage>
            {t(messageTemplate, messageVariables)}
          </ItemChangedMessage>
        );
      }
      if ((data?.errorIds?.length ?? 0) > 0) {
        const messageTemplate = `{{errorNumber}} item(s) could not be deleted.`;
        const messageVariables = {
          errorNumber: data?.errorIds?.length,
        };
        enqueueSnackbar(
          <ItemChangedMessage>
            {t(messageTemplate, messageVariables)}
          </ItemChangedMessage>,
          {
            variant: 'error',
          }
        );
      }
      if (data?.successIds && data.successIds.length > 0) {
        deleteItems(data.successIds);
      }
    } catch (_) {
      enqueueSnackbar(t('Failed to delete items'), {
        variant: 'error',
      });
    }
    return true;
  };

  return {
    handleDelete,
  };
};
