// The any is needed for the generic to function properly.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toRecord<T extends Record<string, any>>(
  list: T[],
  key: keyof T
): Record<string, T> {
  if (!list?.length) return {};

  return list.reduce(
    (record, node) =>
      node ? Object.assign(record, { [node[key] as string]: node }) : record,
    {}
  );
}
