import { useLabels } from './useLabels';
import {
  CustomValidationObject,
  FormFieldConfig,
  useValidation,
} from './useValidation';

export const useFormConfig = ({
  customValidation = {},
  formFields,
}: {
  customValidation?: CustomValidationObject;
  formFields: FormFieldConfig[];
}) => {
  const { formFieldsWithLabels } = useLabels(formFields);

  const validationSchema = useValidation({
    customValidation,
    formFields: formFieldsWithLabels,
  });

  const getField = (name: string) =>
    formFieldsWithLabels.find((field) => field.name === name);

  return { getField, validationSchema };
};
