import { useEffect, useRef } from 'react';
import { Handler, Previewer } from 'pagedjs';

import { pagedJS } from '@pro4all/quality-control/ui/report';
import { ReportRendererProps } from '@pro4all/shared/types';

class ListContinuationHandler extends Handler {
  afterPageLayout(
    pageElement: { querySelectorAll: (arg0: string) => any },
    page: { element: { previousElementSibling: any } },
    breakToken: any
  ) {
    const listContainers = pageElement.querySelectorAll('.list-container');
    listContainers.forEach(
      (container: {
        firstChild: any;
        insertBefore: (arg0: any, arg1: any) => void;
        querySelector: (arg0: string) => any;
      }) => {
        if (!container.querySelector('.label')) {
          const previousPage = page.element.previousElementSibling;
          if (previousPage) {
            const originalContainer =
              previousPage.querySelector('.list-container');
            if (originalContainer) {
              const labelClone = originalContainer
                .querySelector('.label')
                .cloneNode(true);

              // Clear the text content of the label on subsequent pages
              labelClone.textContent = '';

              // Add a class to identify continuation labels
              labelClone.classList.add('continuation-label');

              container.insertBefore(labelClone, container.firstChild);
            }
          }
        }
      }
    );
  }
}

export const useReportRenderer = ({
  html,
  id,
  mediaLoaded,
}: ReportRendererProps) => {
  const htmlRef = useRef<string>();

  useEffect(() => {
    if (!mediaLoaded) return;

    const prerenderId = `${id}-prerender`;
    const previewer = new Previewer();
    const element = document.querySelector(`#${id}-preview`);
    const prerenderElement = document.querySelector(`#${prerenderId}`);
    const container = document.querySelector(`#${id}`);

    previewer.registerHandlers(ListContinuationHandler);

    const render = async () => {
      await previewer.preview(html, [pagedJS], element);
    };

    const rerender = async () => {
      if (!container) {
        return;
      }
      prerenderElement && prerenderElement.remove();
      const tempElement = document.createElement('div');
      tempElement.id = prerenderId;
      tempElement.style.visibility = 'hidden';
      container.appendChild(tempElement);
      await previewer.preview(html, [pagedJS], tempElement);

      if (!element) {
        return;
      }
      if (element) element.innerHTML = tempElement.innerHTML;
      tempElement.remove();
    };

    if (htmlRef.current !== html) {
      if (!element?.innerHTML) {
        render();
      } else {
        rerender();
      }
      htmlRef.current = html;
    }
  }, [html, id, mediaLoaded]);
};
