import React from 'react';

import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { IconName } from '@pro4all/shared/ui/icons';

import * as Styled from './Actions.styled';
import { ButtonColor, ButtonOrientation } from './types';

export const Actions = ({
  actions = [],
  buttonColor,
  buttonOrientation,
  hideLabels = false,
  flexEnd,
}: {
  actions?: ActionProps[];
  buttonColor?: ButtonColor;
  buttonOrientation?: ButtonOrientation;
  flexEnd: boolean;
  hideLabels?: boolean;
}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const actionsFiltered = actions.filter(
    (action) => !action.disabled && !action.hidden
  );
  const showIconsOnly = actions ? actionsFiltered.length > 5 : false;

  const increasedIconSpacing = (showIconsOnly && largeScreen) ?? false;
  const dividers = ['Edit', 'QR codes', 'Unfinalize'];

  return (
    <Styled.StyledActionBar
      $increasedBottomSpacing={buttonOrientation === 'vertical'}
      $increasedIconSpacing={increasedIconSpacing}
      $largeScreen={largeScreen}
    >
      <Styled.StyledList
        color={theme.palette.primary.main}
        direction="horizontal"
        dividers={dividers}
        flexEnd={flexEnd}
        overflow
      >
        {actions?.length
          ? actionsFiltered.map((action, i) => {
              const {
                actuallyDisabled,
                color,
                dataTestId,
                label,
                onClick,
                startIcon,
                subItems,
              } = action;

              const colorPropButton = color || buttonColor || 'primary';
              const colorPropIconButton =
                'inherit' || 'primary' || 'secondary' || 'default'; // Todo: hardcoded for now.
              const dataTestIdProp = dataTestId || 'button';

              if (largeScreen && !hideLabels && !showIconsOnly) {
                return (
                  <Button
                    color={colorPropButton}
                    data-testid={dataTestIdProp}
                    disabled={actuallyDisabled}
                    key={i}
                    onClick={onClick}
                    startIcon={startIcon}
                    subItems={subItems}
                  >
                    {label}
                  </Button>
                );
              } else {
                return (
                  <IconButton
                    ariaLabel={label}
                    color={colorPropIconButton}
                    dataTestId={dataTestIdProp}
                    disabled={actuallyDisabled}
                    iconName={startIcon as IconName}
                    key={i}
                    onClick={onClick}
                    subItems={subItems}
                    tooltipPlacement="bottom"
                  />
                );
              }
            })
          : null}
      </Styled.StyledList>
    </Styled.StyledActionBar>
  );
};
