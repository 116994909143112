import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

import { HEIGHT } from './types';

export const SlideContainer = styled.div`
  position: relative;
  height: ${HEIGHT}px; /** Why fixed? See description above */
  overflow: hidden;
  flex: 1;
`;

export const VerticalSlideContainer = styled.div<{
  $largeScreen: boolean;
  $numberOfActions: number;
}>`
  position: relative;
  height: ${({ theme, $largeScreen, $numberOfActions }) =>
    `${
      $largeScreen
        ? $numberOfActions * HEIGHT +
          ($numberOfActions - 1) * theme.spacing(1.5)
        : HEIGHT
    }`};
  overflow: hidden;
  flex: 1;
`;

export const Slide = styled(Box)<{ $showAltBar: boolean }>`
  && {
    position: relative;
    transform: ${({ $showAltBar }) =>
      `translateY(${$showAltBar ? -HEIGHT : 0}px)`};
    transition: transform 150ms ease-in-out;
  }
`;
