import type { ApiKey } from '@pro4all/graphql';

import { FormFields } from './types';
import { useCreateApiKey } from './useCreateApiKey';
import { useEditApiKey } from './useEditApiKey';

export const useSubmitApiKey = (apiKey: ApiKey | null) => {
  const createApiKey = useCreateApiKey();
  const editApiKey = useEditApiKey();

  return ({ onClose, values }: { onClose: () => void; values: FormFields }) => {
    if (apiKey) {
      return editApiKey({ apiKey, onClose, values });
    } else {
      return createApiKey({ onClose, values });
    }
  };
};
