import styled from 'styled-components';

import { Typography } from '@pro4all/shared/mui-wrappers';

export const ValueWrapperAligner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ValueChartTitle = styled(Typography).attrs(() => ({
  variant: 'h3',
}))``;

export const CountChartSupplementalValue = styled(Typography).attrs({
  variant: 'h1',
})`
  && {
    font-size: 3rem;
  }
`;

export const CountChartValue = styled(Typography).attrs({
  variant: 'h1',
})`
  && {
    font-size: 4rem;
  }
`;
