import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { FormValues, NameType } from './types';

export const FormObserver = ({
  setValues,
}: {
  setValues: (value: NameType) => void;
}): null => {
  const {
    values: { firstName, lastName },
  } = useFormikContext<FormValues>();

  useEffect(() => {
    setValues({ firstName, lastName });
  }, [firstName, lastName, setValues]);

  return null;
};
