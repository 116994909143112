import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const ListItem = styled.li<{ $active?: boolean; $disabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 ${({ theme }) => theme.spacing(1)};
  background-color: ${({ $active }) =>
    $active ? ({ theme }) => theme.palette.secondary.light : 'inherit'};
  color: ${({ $active }) =>
    $active ? ({ theme }) => theme.palette.secondary.dark : 'inherit'};
  cursor: pointer;
  &:hover {
    background-color: ${({ $active }) =>
      $active
        ? ({ theme }) => theme.palette.secondary.light
        : customColors.grey300};
    color: ${({ $active }) =>
      $active
        ? ({ theme }) => theme.palette.secondary.dark
        : customColors.grey900};
  }
  > span {
    margin-left: auto;
  }
`;
export const CustomDateWrapper = styled.div`
  border-left: 1px solid ${customColors.grey400};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
`;
