import { MapContainer } from 'react-leaflet';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

import 'leaflet/dist/leaflet.css';
import './leaflet.css';

export const StyledMapContainer = styled(MapContainer)<{
  $cursor?: 'crosshair' | 'grab' | 'pointer' | 'default';
  height?: number;
  width?: number;
}>`
  background-color: ${customColors.grey300};
  z-index: 1;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  cursor: ${({ $cursor }) => $cursor || 'grab'};
  .leaflet-map-pane .leaflet-marker-pane {
    .leaflet-div-icon {
      background: none;
      border: 0;
      border-radius: 50%;
    }
  }

  .leaflet-control-container .leaflet-bottom .leaflet-control-zoom {
    margin: 0 0 18px 18px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(44, 45, 86, 0.24);

    > a {
      color: #848484;
      border: none;
    }

    .leaflet-control-zoom-in {
      border-radius: 10px 10px 0 0;
    }

    .leaflet-control-zoom-out {
      border-radius: 0 0 10px 10px;
    }
  }
`;
