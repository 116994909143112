import React from 'react';

import { CircularProgress } from '@pro4all/shared/mui-wrappers';

import type { AnswerComponent } from '../AnswerView';
import { useFetchSignature } from '../hooks/useFetchSignature';
import * as Styled from '../styles/Signature.styles';

export const Signature: AnswerComponent = ({ children }) => {
  const { loading, signature } = useFetchSignature(children);
  return (
    <Styled.SignatureCanvas>
      {loading ? (
        <CircularProgress />
      ) : (
        <Styled.SignatureImage alt="" src={signature} />
      )}
    </Styled.SignatureCanvas>
  );
};
