import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ChangeableSnagIcon: React.FC<
  SvgIconProps & { customColor: string }
> = (props) => {
  const { scale = 1 } = props;

  return (
    <svg
      fill="none"
      height={44 * Number(scale)}
      viewBox="0 0 27 44"
      width={27 * Number(scale)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13.2 0C5.90984 0 0 5.90984 0 13.2C0 20.2131 5.46915 25.9488 12.375 26.3746V40.1042C12.375 40.1042 13.2188 44 13.5 44C13.7812 44 14.625 40.1042 14.625 40.1042V26.3746C21.5309 25.9488 27 20.2131 27 13.2C27 5.90984 21.0902 0 13.8 0H13.2Z"
        fill={props.customColor || '#533BE2'}
        fillRule="evenodd"
      />
      <rect
        fill="white"
        height="20.8"
        rx="10.4"
        width="21.25"
        x="2.875"
        y="2.7998"
      />
      <rect
        height="20.8"
        rx="10.4"
        stroke="white"
        width="21.25"
        x="2.875"
        y="2.7998"
      />
    </svg>
  );
};
