import React from 'react';

import type { Document } from '@pro4all/graphql';
import { ConversionStatus } from '@pro4all/graphql';
import { Icon } from '@pro4all/shared/ui/icons';

export const ThreeDConversionStatus: React.FC<Document> = ({
  threeDConversionState,
}) => {
  const currentStatus = threeDConversionState?.status;
  if (!currentStatus) return null;
  if (!Object.values(ConversionStatus).includes(currentStatus)) return null;

  const iconName =
    currentStatus === ConversionStatus.Failed
      ? 'error'
      : currentStatus === ConversionStatus.Success
      ? 'cube'
      : 'sync';
  const htmlColor =
    currentStatus === ConversionStatus.Failed ? '#CF6679' : '#848484';
  return <Icon htmlColor={htmlColor} iconName={iconName} />;
};
