import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSearchableSelect, InfoBlock } from '@pro4all/shared/ui/general';

import { useGetIntegrationTypeData } from '../form/useGetIntegrationTypeData';

import { useConnectionFormConfig } from './useConnectionFormConfig';
import { useGetConnection } from './useGetConnection';
import { useGetProjectOptions } from './useGetProjectOptions';

export const CustomSnagstream = () => {
  const { t } = useTranslation();
  const { getField } = useConnectionFormConfig();
  const projectField = getField('project');
  const { integrationId } = useGetIntegrationTypeData();
  const { dataConnection, loadingConnection } = useGetConnection();
  const { projectOptions } = useGetProjectOptions(integrationId);

  return !loadingConnection ? (
    <>
      <InfoBlock
        paragraph={`${t(
          "Select which project from '{{integrationName}}' you want to connect.",
          { integrationName: dataConnection?.connectionName || t('Unknown') }
        )}`}
      />
      <FormikSearchableSelect
        id={projectField?.name}
        label={projectField?.displayName}
        name={projectField?.name || ''}
        options={projectOptions}
        placeholder={t('Select application')}
      />
    </>
  ) : null;
};
