import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DomainToClaim } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Typography } from '@pro4all/shared/mui-wrappers';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

import { OrganizationDomainsForm } from './OrganizationDomainsForm';
import { OrganizationDomainTables } from './OrganizationDomainTables';
import { useDomainStatus } from './useDomainStatus';

const StyledBox = styled(Box)`
  padding: 5px 15px;
`;

export const OrganizationDomains = () => {
  const { t } = useTranslation();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const domainsToClaim: DomainToClaim[] = useDomainStatus(organizationId) ?? [];

  return (
    <StyledBox>
      <Text color="inherit" variant="h5">
        {t('Domains')}
      </Text>

      <Typography>
        {domainsToClaim.length > 0
          ? t(
              'Each domain you add needs to be verified. You or your system admin needs to create a TXT record with the "Domain" column as text and "Value" column as value.'
            )
          : t(
              'You need to add a domain to get a TXT value for the SCIM setup.'
            )}
      </Typography>

      {domainsToClaim.length > 0 && (
        <OrganizationDomainTables domainsToClaim={domainsToClaim} />
      )}

      <div style={{ marginTop: '3%' }}>
        <OrganizationDomainsForm
          domainsToClaim={domainsToClaim ?? []}
          existingDomains={domainsToClaim.map(
            (domainToClaim) => domainToClaim.domain
          )}
          organizationId={organizationId}
        />
      </div>
    </StyledBox>
  );
};
