import React, { PropsWithChildren, useContext } from 'react';

import { useFolderTree } from './useFolderTree';

const FolderTreeContext = React.createContext({});

export function useFolderTreeContextInner() {
  return useContext<Partial<ReturnType<typeof useFolderTree>>>(
    FolderTreeContext
  );
}

export function FolderTreeContextProvider({
  inherit = true,
  projectId,
  userId,
  children,
}: PropsWithChildren<{
  inherit?: boolean;
  projectId?: string;
  userId?: string;
}>) {
  const folderTree = useFolderTree({ inherit, projectId, userId });

  return (
    <FolderTreeContext.Provider value={folderTree}>
      {children}
    </FolderTreeContext.Provider>
  );
}
