import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageRotateScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Please rotate your device in landscape orientation to view the page.'
      )}
      title={t('This page is not suitable for your current screen width')}
    />
  );
};
