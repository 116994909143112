export enum Status {
  DOCUMENT_LOADED = 'DOCUMENT_LOADED',
  DOCUMENT_LOADING = 'DOCUMENT_LOADING',
  DOCUMENT_NOT_LOADED = 'DOCUMENT_NOT_LOADED',
  DOCUMENT_SWITCH = 'DOCUMENT_SWITCH',
  INITIALIZING = 'INITIALIZING',
  NOT_INITIALIZED = 'NOT_INITIALIZED',
}

export enum Actions {
  SET_BASE_VERSION_ID = 'SET_BASE_VERSION_ID',
  SET_OVERLAY_VERSION_ID = 'SET_OVERLAY_VERSION_ID',
  SET_STATUS = 'SET_STATUS',
}

export type Action =
  | ActionTypes[Actions.SET_BASE_VERSION_ID]
  | ActionTypes[Actions.SET_OVERLAY_VERSION_ID]
  | ActionTypes[Actions.SET_STATUS];

export interface State {
  baseVersionId: string;
  overlayVersionId: string;
  status: Status;
}

export interface ActionTypes {
  [Actions.SET_BASE_VERSION_ID]: {
    payload: {
      versionId: string;
    };
    type: Actions.SET_BASE_VERSION_ID;
  };
  [Actions.SET_OVERLAY_VERSION_ID]: {
    payload: {
      versionId: string;
    };
    type: Actions.SET_OVERLAY_VERSION_ID;
  };
  [Actions.SET_STATUS]: {
    payload: {
      status: Status;
    };
    type: Actions.SET_STATUS;
  };
}

export const pdftronReducer = (state: State, action: Action) => {
  switch (action.type) {
    case Actions.SET_BASE_VERSION_ID:
      return {
        ...state,
        baseVersionId: action.payload.versionId,
      };
    case Actions.SET_OVERLAY_VERSION_ID:
      return {
        ...state,
        overlayVersionId: action.payload.versionId,
      };
    case Actions.SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};
