import React from 'react';

import { AppFallback } from '@pro4all/shared/ui/general';
import { BatchInfoModal, DiscardModal } from '@pro4all/shared/ui/messages';

import { AppRoutes } from './AppRoutes';
import { Init } from './Init';
import { Providers } from './Providers';

export const App = () => (
  <Providers>
    <Init />
    <AppFallback>
      <AppRoutes />
      <BatchInfoModal />
      <DiscardModal />
    </AppFallback>
  </Providers>
);

export default App;
