import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Member,
  ProjectMembersIncludeDocument,
  useAddProjectMembersMutation,
  useGroupsAndUsersIncludeQuery,
  useProjectMembersIncludeQuery,
} from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikForm,
  FormikSearchableMultiSelect,
  FormikTextarea,
  Sidebar,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/general';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import { sortBy } from '@pro4all/shared/utils';

import { projectMembersIncludeProps } from './getIncludeProps';
import { useEditProjectMembersConfig } from './useEditProjectMembersConfig';

interface FormFields {
  admins: Option[];
  message: string;
  users: Option[];
}

interface Props {
  onClose: () => void;
  open: boolean;
}

export const EditProjectMembers: React.FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();
  const { projectId } = useRouting().params;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAdminIds, setSelectedAdminIds] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [addProjectMembers] = useAddProjectMembersMutation({
    onCompleted: () => {
      enqueueSnackbar(t('Project members have been updated'));
      handleClose();
    },
    onError: () =>
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      ),
  });

  const {
    data: dataProject,
    error: errorProject,
    loading: loadingProject,
  } = useProjectMembersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: {
      projectId,
    },
  });

  const {
    data: dataOrg,
    error: errorOrg,
    loading: loadingOrg,
  } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: {
      fetchInvitedUsers: true,
      includeActive: true,
      includeEmail: true,
      includeInvited: true,
      includeOrganization: true,
    },
  });

  const projectMembers = (dataProject?.projectMembers as Member[]) || [];
  const orgMembers = (dataOrg?.groupsAndUsers as Member[]) || [];

  const loading = (loadingOrg && !dataOrg) || (loadingProject && !dataProject);
  const error = errorOrg || errorProject;

  const orgMemberUserOptions = useMemberOptions(orgMembers, {
    includeInactive: false,
    type: 'User',
  });

  const orgMemberGroupOptions = useMemberOptions(orgMembers, {
    type: 'Group',
  });

  const orgMemberOptions = orgMemberUserOptions.concat(orgMemberGroupOptions);

  const projectMemberIds = projectMembers?.map((member) => member.id) || [];
  const projectMemberFilter = (user: Option) =>
    !projectMemberIds.includes(user.id);

  const onChangeAdmin = (values: (string | Option)[]) => {
    const ids = values.map((value: Option) => value.id);
    setSelectedAdminIds(ids);
  };

  const onChangeUser = (values: (string | Option)[]) => {
    const ids = values.map((value: Option) => value.id);
    setSelectedUserIds(ids);
  };

  // Org members not yet added as project members, not selected as new project member
  const availableAdminOptions = orgMemberOptions
    .filter(projectMemberFilter)
    .filter((option) => !selectedUserIds.includes(option.id));

  // Org members not yet added as project members, not selected as new project admin
  const availableUserOptions = orgMemberOptions
    .filter(projectMemberFilter)
    .filter((option) => !selectedAdminIds.includes(option.id));

  const initialValues: FormFields = {
    admins: [],
    message: '',
    users: [],
  };

  const handleClose = () => {
    setSelectedAdminIds([]);
    setSelectedUserIds([]);
    onClose();
  };

  const { getField, validationSchema } = useEditProjectMembersConfig();
  const adminsField = getField('admins');
  const usersField = getField('users');
  const messageField = getField('message');

  const toMemberInput = (selectedMember: Option) => ({
    id: selectedMember.id,
    type: selectedMember.type === 'Group' ? 1 : 0,
  });

  const getMemberProps = (
    { __typename, active, id, organization }: Member,
    label: string
  ) => ({
    __typename,
    active,
    displayName: label,
    email: label,
    id,
    organization,
  });

  const {
    state: { items },
  } = useOptimisticResponseContext<Member>();

  return (
    <Sidebar onClose={handleClose} open={open}>
      <ResponseWrapper error={error} isLoading={loading}>
        <Sidebar.Header title={t('Add members')} />
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            await addProjectMembers({
              variables: {
                admins: values.admins.map(toMemberInput),
                message: values.message,
                projectId,
                users: values.users.map(toMemberInput),
              },
            });

            const admins = values.admins.map((admin) => {
              const memberProps = getMemberProps(
                orgMembers.find((member) => member.id === admin.id),
                admin.label
              );
              return {
                ...memberProps,
                isAdmin: true,
                roles: [{ id: '1', name: 'Administrator', permissions: [] }], // id must be added ts-wise, but is not used at table rendering
              };
            });

            const users = values.users.map((user) => {
              const memberProps = getMemberProps(
                orgMembers.find((member) => member.id === user.id),
                user.label
              );
              return {
                ...memberProps,
                isAdmin: false,
                roles: [{ id: '2', name: 'User', permissions: [] }], // id must be added ts-wise, but is not used at table rendering
              };
            });

            const updatedItems = [...items, ...admins, ...users].sort(
              sortBy({ key: 'displayName' })
            );

            client.writeQuery({
              data: {
                projectMembers: updatedItems,
              },
              query: ProjectMembersIncludeDocument,
              variables: {
                ...projectMembersIncludeProps,
                projectId,
              },
            });

            handleClose();
          }}
          validationSchema={validationSchema}
        >
          {({ dirty, errors, isSubmitting }) => (
            <FormikForm>
              <FormWrapper>
                <FormikSearchableMultiSelect
                  label={adminsField.label}
                  loading={false}
                  name={adminsField.name}
                  onChange={(event, value) => onChangeAdmin(value)}
                  options={availableAdminOptions}
                  placeholder={t('Search')}
                />
                <FormikSearchableMultiSelect
                  label={usersField.label}
                  loading={false}
                  name={usersField.name}
                  onChange={(event, value) => onChangeUser(value)}
                  options={availableUserOptions}
                  placeholder={t('Search')}
                />
                <FormikTextarea
                  id={messageField.name}
                  label={messageField.label}
                  name={messageField.name}
                  rows={4}
                />
              </FormWrapper>

              <FormFooter
                disableSubmit={isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                })}
                onClose={handleClose}
                pb={3}
                pt={2}
                px={3}
              />
            </FormikForm>
          )}
        </Formik>
      </ResponseWrapper>
    </Sidebar>
  );
};
