import { useTranslation } from 'react-i18next';

import {
  Document,
  QualityControlInstance,
  SearchDocument,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { CopyTextButton } from '@pro4all/shared/ui/buttons';

export const useGetCardTitle = () => {
  const { t } = useTranslation();

  const getCardTitle = (
    card: SearchDocument | QualityControlInstance | Document
  ) => {
    if (!card?.name) {
      return t('No permissions to view this document');
    }

    if (card?.__typename === 'QualityControlInstance') {
      const referencePart = card.reference ? (
        <CopyTextButton textName={t('Reference')} textToCopy={card.reference} />
      ) : null;
      const statusPart = `status: ${
        card?.indicativeState?.value ? card?.indicativeState.value : '-'
      }`;
      return (
        <Box display="flex" gap="5px">
          <Box>{card?.name}</Box>
          <Box display="flex">#{referencePart}</Box>
          <Box>{statusPart}</Box>
        </Box>
      );
    }

    if (card?.path) {
      return `${card?.name} - ${card?.path}`;
    }
    return card?.name;
  };

  return { getCardTitle };
};
