import React from 'react';
import styled from 'styled-components';

import { IntegrationType } from '@pro4all/graphql';
import { Icon } from '@pro4all/shared/ui/icons';

import { useIntegrationConfig } from '../config/useIntegrationConfig';

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const IntegrationTypeIcon = ({
  integrationType,
}: {
  integrationType: IntegrationType;
}) => {
  const integrationConfig = useIntegrationConfig();
  return (
    <StyledIcon
      fontSize="inherit"
      iconName={integrationConfig[integrationType].icon}
    />
  );
};
