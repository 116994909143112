import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { useSharedFormFieldsConfig } from '../useSharedFormFieldsConfig';

export const useStep1Config = () => {
  const { firstNameConfig, lastNameConfig, phoneNumberConfig } =
    useSharedFormFieldsConfig();

  const formFields: FormFieldConfig[] = [
    firstNameConfig,
    lastNameConfig,
    {
      displayName: 'Organization name', // i18n
      name: 'organizationName',
      required: true,
      type: ValueTypeName.Text,
    },
    phoneNumberConfig,
  ];

  return useFormConfig({ formFields });
};
