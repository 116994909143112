import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import {
  ActionType,
  BaseEntityType,
  optimisticResponseReducer,
  State,
} from './optimisticResponseReducer';

type ContextType<EntityType extends BaseEntityType> = {
  addItems: (item: EntityType[]) => void;
  deleteItems: (ids: string[]) => void;
  editItems: (items: EntityType[]) => void;
  filterItems: (items: EntityType[]) => void;
  replaceAllItems: (items: EntityType[]) => void;
  resetInitialItems: () => void;
  restoreItems: (ids: string[]) => void;
  setFilteredItems: (items: EntityType[]) => void;
  setItem: (item: EntityType) => void;
  setRecalculateFilters: (recalculate: boolean) => void;
  state: State<EntityType>;
  triggerColumnSort: (items: EntityType[]) => void;
};

const OptimisticResponseContext = React.createContext(null);

export function useOptimisticResponseContext<
  EntityType extends BaseEntityType
>() {
  return useContext<ContextType<EntityType>>(OptimisticResponseContext);
}

export function OptimisticResponseProvider<EntityType extends BaseEntityType>({
  children,
}: {
  children: JSX.Element;
}) {
  const [state, dispatch] = useReducer(optimisticResponseReducer, {
    deletedItems: [],
    item: null,
    items: [],
    itemsInitial: [],
    recalculateFilters: false,
  });

  // Define all actions
  const addItems = useCallback((items: EntityType[]) => {
    dispatch({
      payload: items,
      type: ActionType.ADD_ITEMS,
    });
  }, []);
  const deleteItems = useCallback((ids: string[]) => {
    dispatch({ payload: ids, type: ActionType.DELETE_ITEMS });
  }, []);
  const editItems = useCallback((items: EntityType[]) => {
    dispatch({
      payload: items,
      type: ActionType.EDIT_ITEMS,
    });
  }, []);
  const filterItems = useCallback((items: EntityType[]) => {
    dispatch({
      payload: items,
      type: ActionType.FILTER_ITEMS,
    });
  }, []);
  const replaceAllItems = useCallback((items: EntityType[]) => {
    dispatch({
      payload: items,
      type: ActionType.REPLACE_ALL_ITEMS,
    });
  }, []);
  const resetInitialItems = useCallback(() => {
    dispatch({ type: ActionType.RESET_INITIAL_ITEMS });
  }, []);
  const restoreItems = useCallback((ids: string[]) => {
    dispatch({ payload: ids, type: ActionType.RESTORE_ITEMS });
  }, []);
  const setFilteredItems = useCallback((items: EntityType[]) => {
    dispatch({
      payload: items,
      type: ActionType.SET_FILTERED_ITEMS,
    });
  }, []);
  const setItem = useCallback((item: EntityType) => {
    dispatch({
      payload: item,
      type: ActionType.SET_ITEM,
    });
  }, []);
  const setRecalculateFilters = useCallback((recalculate: boolean) => {
    dispatch({
      payload: recalculate,
      type: ActionType.SET_RECALCULATE_FILTERS,
    });
  }, []);
  const triggerColumnSort = useCallback((items: EntityType[]) => {
    dispatch({
      payload: items,
      type: ActionType.TRIGGER_COLUMN_SORT,
    });
  }, []);

  return (
    <OptimisticResponseContext.Provider
      value={{
        addItems,
        deleteItems,
        editItems,
        filterItems,
        replaceAllItems,
        resetInitialItems,
        restoreItems,
        setFilteredItems,
        setItem,
        setRecalculateFilters,
        state,
        triggerColumnSort,
      }}
    >
      {children}
    </OptimisticResponseContext.Provider>
  );
}

export function useSetItemsInLocalState<EntityType extends BaseEntityType>(
  items: EntityType[]
) {
  const { replaceAllItems } = useOptimisticResponseContext<EntityType>();
  useEffect(() => {
    replaceAllItems(items || []);
  }, [items, replaceAllItems]);
}

export function useSetItemInLocalState<EntityType extends BaseEntityType>(
  item: EntityType
) {
  const { setItem } = useOptimisticResponseContext<EntityType>();
  useEffect(() => {
    setItem(item);
  }, [item, setItem]);
}
