import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Office: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" {...props}>
    <polygon
      fill="#d83b01"
      points="84,12.4 58.4,5 12,22 12,74 28,67.8 28,25.8 58,18.5 58,80 12,74 58.4,91 84,83.9 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);
