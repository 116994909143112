import React from 'react';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useActions } from './useActions';
import { usePredefinedFilters } from './usePredefinedFilters';

export const ProjectMembersActionBar = () => {
  const { altActions, mainActions } = useActions({});
  const showExportButton = useFeatureFlag('export-user-tables');

  const predefinedFilters = usePredefinedFilters();

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="project-members-action-bar"
      mainActions={mainActions}
      predefinedFilters={predefinedFilters}
      search
      showExportButton={showExportButton}
    />
  );
};
