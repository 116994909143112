import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  Document,
  DocumentVisualContextInput,
  useCreateVisualContextFromDocumentsMutation,
  VisualContextStatus,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  ActionProps,
  DocumentIdsToConvertToDrawings,
} from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/general';

import { DocumentActionProps } from '../../types';

import { supportedExtensions } from './supportedExtensions';

export const useVisualContextsAction = ({
  selection,
}: Pick<DocumentActionProps, 'selection'>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { uncheckAllRows } = useTableCheck<Document>();
  const updateCachedDocuments = useUpdateCachedDocuments();

  const [createVisualContextFromDocuments] =
    useCreateVisualContextFromDocumentsMutation();

  const { setLocalStorageItem: setdocumentIdsToConvertToDrawings } =
    useLocalStorage<DocumentIdsToConvertToDrawings>({
      key: StorageKeys.DOCUMENT_IDS_TO_CONVERT_TO_DRAWINGS,
    });

  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const extensions = selection.map(({ extension }) => extension);
  const noneSupported = extensions.every(
    (ext) => !supportedExtensions.includes(ext || '')
  );
  const allAlreadyConverted = selection.every(
    (document) => document.visualContext
  );

  const onClick = async () => {
    // Action only applies in a project context.
    if (projectId) {
      const supportedDocuments: DocumentVisualContextInput[] = selection
        .filter((doc) => supportedExtensions.includes(doc.extension || ''))
        .filter((doc) => !doc.visualContext)
        .map(({ downloadName, name, versionId }) => ({
          name: downloadName || name,
          versionId: versionId || '',
        }));

      if (selection.length !== supportedDocuments.length) {
        // Not all selected document extensions are supported and/or user selected documents that are already converted.
        // We have to inform the user with a modal where the conversion can be cancelled or continued.
        setdocumentIdsToConvertToDrawings({
          selectedCount: selection.length,
          supportedDocuments,
        });
        searchParams.set('action', 'convertDocumentsToDrawings');
      } else {
        await createVisualContextFromDocuments({
          variables: { documents: supportedDocuments, projectId },
        });
        enqueueSnackbar(t('Conversion has started'));
        uncheckAllRows();

        updateCachedDocuments({
          documentIds: supportedDocuments.map((doc) => doc.versionId) || [],
          fieldToUpdate: 'visualContext',
          keyField: 'versionId',
          value: { id: '1', name: '1', status: VisualContextStatus.Processed },
        });
      }
    }
  };

  const visualContextsAction: ActionProps = {
    ariaLabel: t('Send for conversion'),
    dataTestId: 'send-for-conversion',
    disabled:
      !projectId ||
      selection.length === 0 ||
      noneSupported ||
      allAlreadyConverted,
    key: 'send-for-conversion',
    label: t('Send for conversion'),
    onClick,
    startIcon: 'map',
  };

  return visualContextsAction;
};
