import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';
import { useRouting } from '@pro4all/shared/routing-utils';

import { endTimeConfig } from './endTimeConfig';

export const useGeneratePrivateLinkFormConfig = () => {
  const { projectId } = useRouting().params;

  const projectLabel = 'Project members'; // i18n
  const orgLabel = 'Organization members'; // i18n

  const formFields: FormFieldConfig[] = [
    endTimeConfig,
    {
      displayName: projectId ? projectLabel : orgLabel,
      name: 'selectedMembers',
      required: true,
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ formFields });
};
