import {
  ScopeType,
  Template,
  TemplateService,
  TemplateType,
} from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { GetTemplateOptionsProps, Option } from '@pro4all/shared/types';
import { sortBy } from '@pro4all/shared/utils';

export const useGetTemplateOptions = () => {
  const { templateOptions: formsAllOptions, templates: formTemplates } =
    useTemplatesInclude({
      includeScope: true,
      templateService: TemplateService.QualityControl,
      templateType: TemplateType.Form,
    });

  const { templateOptions: snagsAllOptions, templates: snagTemplates } =
    useTemplatesInclude({
      includeScope: true,
      templateService: TemplateService.QualityControl,
      templateType: TemplateType.Snag,
    });

  const getTemplateOptions = ({
    formTemplateIdsWithDuplicates,
    formTemplateOptions,
    snagTemplateIdsWithDuplicates,
    snagTemplateOptions,
  }: GetTemplateOptionsProps) => {
    const formTemplatesSorted = formTemplateOptions.sort(
      sortBy({ key: 'label' })
    );
    // Filter out options from formsAllOptions that already exist in formTemplatesWithIconSorted
    const filteredFormsAllOptions = formsAllOptions.filter(
      (formOption) =>
        !formTemplatesSorted.some(
          (sortedOption) => sortedOption.id === formOption.id
        )
    );

    const snagTemplatesSorted = snagTemplateOptions.sort(
      sortBy({ key: 'label' })
    );
    // Filter out options from snagsAllOptions that already exist in snagTemplatesWithIconSorted
    const filteredSnagsAllOptions = snagsAllOptions.filter(
      (snagOption) =>
        !snagTemplatesSorted.some(
          (sortedOption) => sortedOption.id === snagOption.id
        )
    );

    return {
      formTemplateOptionsAll: addIconToOptions({
        options: [...formTemplatesSorted, ...filteredFormsAllOptions],
        templateIdsWithDuplicates: formTemplateIdsWithDuplicates,
        templates: formTemplates,
      }),
      snagTemplateOptionsAll: addIconToOptions({
        options: [...snagTemplatesSorted, ...filteredSnagsAllOptions],
        templateIdsWithDuplicates: snagTemplateIdsWithDuplicates,
        templates: snagTemplates,
      }),
    };
  };

  return { getTemplateOptions };
};

const addIconToOptions = ({
  options,
  templateIdsWithDuplicates,
  templates,
}: {
  options: Option[];
  templateIdsWithDuplicates: string[];
  templates: Template[];
}) =>
  options.map((option) => {
    const template = templates?.find((template) => template.id === option.id);
    const templateIdCount = templateIdsWithDuplicates.filter(
      (templateId) => templateId === option.id
    ).length;
    return {
      ...option,
      iconName:
        template?.scope && template?.scope.type === ScopeType.Organization
          ? 'organization'
          : 'project',
      label:
        templateIdCount && templateIdCount > 0
          ? `${option.label} (${templateIdCount})`
          : option.label,
    };
  }) as Option[];
