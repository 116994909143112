import React from 'react';

import { UserState } from '@pro4all/graphql';
import { Chip } from '@pro4all/shared/ui/general';

import { useUserStatuses } from './useUserStatuses';

export const StatusChip: React.FC<{
  size?: 'small' | 'medium';
  state: UserState;
}> = ({ size = 'small', state }) => {
  const statuses = useUserStatuses();

  function getColorForStatus(status: UserState) {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Deactivated':
        return 'default';
      case 'InvitationExpired':
        return 'error';
      case 'InvitationNotSent':
        return 'warning';
      case 'InvitationSent':
        return 'primary';
      default:
        return 'default';
    }
  }

  if (!statuses || !statuses[state]) return null;
  return (
    <Chip
      color={getColorForStatus(state)}
      icon={statuses[state].icon}
      label={statuses[state].label}
      size={size}
      variant="outline"
    ></Chip>
  );
};
