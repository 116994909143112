import {
  Calendar as BigCalendar,
  Event,
  momentLocalizer,
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

export interface SharedUiCalendarProps {
  events: Event[];
  onSelect: (event: Event) => void;
}

export function Calendar({ events, onSelect }: SharedUiCalendarProps) {
  const { t } = useTranslation();

  const eventPropGetter = (event: Event) => ({
    style: {
      backgroundColor: event.resource.color || 'transparent',
      color: event.resource.textColor || 'black',
    },
  });

  return (
    <BigCalendar
      endAccessor="end"
      eventPropGetter={eventPropGetter}
      events={events}
      localizer={localizer}
      messages={{
        next: '>',
        previous: '<',
        today: t('Today'),
      }}
      onSelectEvent={onSelect}
      popup
      startAccessor="start"
      style={{ height: '100%', width: '100%' }}
      views={['month']}
    />
  );
}

export default Calendar;
