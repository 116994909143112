import React from 'react';
import styled from 'styled-components';

import {
  Organization,
  Project,
  ProjectsUnreadCountQuery,
} from '@pro4all/graphql';
import { Box, Chip, CircularProgress } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  getFormattedDate,
  MiddleEllipsis,
  StyledCellContent,
  Timestamp,
} from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

import { getUnreadCountByProjectId } from './getUnreadCountByProjectId';
import { ProjectMessage, ProjectWithMessage } from './types';
import { isExpired } from './utils';

const HeaderMessage = ({
  defaultWidth,
  isLoading,
  label,
}: {
  defaultWidth: number;
  isLoading?: boolean;
  label: string;
}) =>
  isLoading ? (
    <Box pl={1.5}>
      <CircularProgress color="primary" size="2rem" />
    </Box>
  ) : (
    <FilterHeader<ProjectWithMessage, ProjectMessage>
      defaultWidth={defaultWidth}
      filterType={FilterHeaderType.SelectNumber}
      getCustomValueCallback={({ unreadCount }: ProjectMessage) =>
        `${unreadCount}`
      }
      label={label}
      minWidth={100}
      propertyId="projectMessages"
      subPropertyId="hasUnread"
    />
  );

type UseColumnsType = {
  displayMessages?: boolean;
  messagesData?: ProjectsUnreadCountQuery;
  messagesLoading: boolean;
};
export const useColumns = ({
  displayMessages,
  messagesData,
  messagesLoading,
}: UseColumnsType): ColumnProps<Project>[] => {
  const { goTo } = useRouting();

  const toMessages = (projectId: string) =>
    goTo('projectMessages', { params: { projectId } });

  const unreadMessages = displayMessages
    ? [
        {
          filterable: true,
          getValue: ({ id }: Project) =>
            getUnreadCountByProjectId(id, messagesData),
          headerComponent: (
            <HeaderMessage
              defaultWidth={200}
              isLoading={messagesLoading}
              label="Messages"
            />
          ),
          key: 'project.messageCount',
          render: ({ id, organization }: Project) => {
            const unreadCount = getUnreadCountByProjectId(id, messagesData);
            return (
              <StyledCellContent active={!isExpired(organization?.expireDate)}>
                {!messagesLoading && (
                  <StyledChip
                    icon={
                      <Box pl={0.2}>
                        <Icon
                          fontSize="small"
                          iconName={
                            unreadCount > 0
                              ? 'prostreamChatUnread'
                              : 'prostreamChat'
                          }
                        />
                      </Box>
                    }
                    label={unreadCount}
                    onClick={
                      !isExpired(organization?.expireDate)
                        ? (e) => {
                            e.stopPropagation();
                            toMessages(id);
                          }
                        : null
                    }
                    size="small"
                  />
                )}
              </StyledCellContent>
            );
          },
          width: 200,
        },
      ]
    : [];

  return [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<Project, {}>
          defaultWidth={400}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="name"
        />
      ),
      key: 'name',
      render: ({ id, name, organization }: Project) => (
        <StyledCellContent active={!isExpired(organization?.expireDate)}>
          <MiddleEllipsis
            data-testid={`project-name-${id}`}
            endLength={9}
            text={name}
          />
        </StyledCellContent>
      ),
      width: 400,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<Project, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label="Code" // i18n
          minWidth={100}
          propertyId="projectNumber"
          pullFromLocalStorage
        />
      ),
      key: 'projectNumber',
      render: ({ organization, projectNumber }: Project) => (
        <StyledCellContent active={!isExpired(organization?.expireDate)}>
          <MiddleEllipsis endLength={9} text={projectNumber} />
        </StyledCellContent>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: (project: Project) =>
        project.startDate ? getFormattedDate(project.startDate).label : '',
      headerComponent: (
        <FilterHeader<Project, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Start date" // i18n
          minWidth={120}
          propertyId="startDate"
        />
      ),
      key: 'startDate',
      render: ({ startDate, organization }: Project) => (
        <StyledCellContent active={!isExpired(organization?.expireDate)}>
          <Timestamp date={startDate} />
        </StyledCellContent>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: (project: Project) =>
        project.deadline ? getFormattedDate(project.deadline).label : '',
      headerComponent: (
        <FilterHeader<Project, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Deadline" // i18n
          minWidth={120}
          propertyId="deadline"
        />
      ),
      key: 'deadLine',
      render: ({ deadline, organization }: Project) => (
        <StyledCellContent active={!isExpired(organization?.expireDate)}>
          <Timestamp date={deadline} />
        </StyledCellContent>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ organization }) => organization?.name,
      headerComponent: (
        <FilterHeader<Project, Organization>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Organization" // i18n
          minWidth={100}
          propertyId="organization"
          subPropertyId="name"
        />
      ),
      key: 'organization.name',
      render: ({ organization }: Project) => (
        <StyledCellContent active={!isExpired(organization?.expireDate)}>
          <MiddleEllipsis endLength={9} text={organization?.name} />
        </StyledCellContent>
      ),
      width: 200,
    },
    ...unreadMessages,
  ];
};

const StyledChip = styled(Chip)`
  && {
    &.MuiChip-root {
      justify-content: flex-start;
      align-items: center;

      color: ${customColors.grey900};

      .MuiChip-icon {
        color: ${customColors.grey900};
        display: flex;
      }
    }
  }
`;
