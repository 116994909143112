import React, { useState } from 'react';

import { useRegisterOrganizationMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { initialValues as s1Values, Step1 } from './Step1';
import { initialValues as s2Values, Step2 } from './Step2';
import { ThankYou } from './ThankYou';

export const RegistrationForm: React.FC<{ children?: never }> = () => {
  const [step, setStep] = useState(0);
  const [step1Values, setStep1Values] = useState(s1Values);
  const [step2Values, setStep2Values] = useState(s2Values);
  const [registerOrganization] = useRegisterOrganizationMutation();

  const { searchParams } = useRouting();

  const locale = navigator.language;

  if (step === 0) {
    return (
      <Step1
        onSubmit={(values) => {
          setStep1Values(values);
          setStep(1);
        }}
        values={step1Values}
      />
    );
  }

  if (step === 1) {
    return (
      <Step2
        goBack={() => setStep(0)}
        onSubmit={async (values) => {
          setStep2Values(values);
          // Remove confirmPassword from the values
          const { confirmPassword, ...step2Values } = values; // eslint-disable-line unused-imports/no-unused-vars-ts
          await registerOrganization({
            variables: {
              ...step1Values,
              ...step2Values,
              locale,
              subscriptionIntent:
                searchParams.get('subscription_intent') || 'unknown',
            },
          });

          localStorage.setItem('i18nextLng', locale);

          setStep(2);
        }}
        values={step2Values}
      />
    );
  }

  if (step === 2) {
    return <ThankYou email={step2Values.email} />;
  }

  return null;
};
