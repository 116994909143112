import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';

import { Document } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

import {
  getCollectionFolderDocuments,
  removeDocumentsFromStore,
  storeDocuments,
} from '../utils/collectionFolderStorage';

import { useCollectionFolderContext } from './CollectionFolderContext';

export const useCollectionFolder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const matchCollection = useRouteMatch([
    Routes.collectionFolder,
    Routes.projectCollectionFolder,
  ]);
  const isInCollectionFolder = Boolean(matchCollection);

  const { userId } = useOrganizationContext();
  const {
    params: { projectId },
  } = useRouting();

  const { updateDocumentsCount } = useCollectionFolderContext();
  const { deleteItems } = useOptimisticResponseContext<Document>();
  const getDocumentsIds = useCallback(
    () => getCollectionFolderDocuments(projectId, userId),
    [projectId, userId]
  );

  const addDocuments = (newDocuments: Document[]) => {
    const currentDocumentsIds = getCollectionFolderDocuments(projectId, userId);

    storeDocuments(
      [
        ...new Set([
          ...currentDocumentsIds,
          ...newDocuments.map((document) => document.id),
        ]),
      ],
      projectId,
      userId
    );
    updateDocumentsCount();
    enqueueSnackbar(t('Documents have been added to collection folder.'));
  };

  const removeDocuments = (documentsToRemoveIds: string[]) => {
    if (isInCollectionFolder) {
      deleteItems(documentsToRemoveIds);
    }

    removeDocumentsFromStore(documentsToRemoveIds, projectId, userId);
    updateDocumentsCount();

    if (getDocumentsIds().length === 0) {
      enqueueSnackbar(t('Collection folder has been emptied.'));
    } else {
      enqueueSnackbar(t('Documents have been removed from collection folder.'));
    }
  };

  const isInCollection = (documentsToVerify: Document[]): boolean => {
    const currentDocumentsIds = getDocumentsIds();
    return documentsToVerify.some((document) =>
      currentDocumentsIds.includes(document.id)
    );
  };

  const allInCollection = (documentsToVerify: Document[]): boolean => {
    const currentDocumentsIds = getDocumentsIds();
    return documentsToVerify.every((document) =>
      currentDocumentsIds.includes(document.id)
    );
  };

  return {
    addDocuments,
    allInCollection,
    document,
    getDocumentsIds,
    isInCollection,
    removeDocuments,
  };
};
