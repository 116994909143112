import React from 'react';
import { useEffect } from 'react';

import { useDiscardModalContext } from '../provider/DiscardModalProvider';

export const DiscardWrapper = ({
  children,
  dirty,
  onSubmit,
  values,
}: {
  children: JSX.Element;
  dirty: boolean;
  onSubmit?: any;
  values?: any;
}) => {
  const { setIsDirty, setOnSubmit } = useDiscardModalContext();

  // Update `isDirty` state of the DiscardModalProvider if `dirty` has changed.
  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);

  // Update `onSubmit` state of the DiscardModalProvider if `values` have changed.
  useEffect(() => {
    if (onSubmit) {
      setOnSubmit(() => onSubmit);
    }
  }, [setOnSubmit, values]);

  return <>{children}</>;
};
