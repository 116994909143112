import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import { SmartFolderCreatedOrUpdated } from '@pro4all/documents/ui/snackbars';
import {
  DocumentPropertyType,
  Filter,
  SmartFolder,
  Tag,
  useCreateSmartFolderMutation,
  useCreateTagsMutation,
  useUpdateSmartFolderMutation,
} from '@pro4all/graphql';
import { useGetDocumentTagsData } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

import { SmartFolderFormProps } from './SmartFolderForm';

export interface FormFields {
  fileTypes: string[];
  name: string;
  tags: Option[];
}

export const useSubmit = ({
  allTags,
  onClose,
  smartFolder,
}: SmartFolderFormProps & { allTags: Tag[] }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { goTo, params, searchParams } = useRouting();
  const { projectId } = params;
  const [createTags] = useCreateTagsMutation();
  const [createSmartFolder] = useCreateSmartFolderMutation();
  const [updateSmartFolder] = useUpdateSmartFolderMutation();
  const editMode = Boolean(smartFolder?.name);
  const getDocumentTagsData = useGetDocumentTagsData();

  const { addItems, state, editItems } =
    useOptimisticResponseContext<SmartFolder>();
  const { items: smartFolders } = state;

  return async (
    values: FormFields,
    { setFieldError, setStatus }: FormikHelpers<FormikValues>
  ) => {
    setStatus(null);

    const invalidDuplicateName =
      !editMode && smartFolders.some((sf) => sf?.name === values.name);
    if (invalidDuplicateName) {
      setFieldError('name', t('Name must be unique'));
      return;
    }

    const { tags, ...rest } = values;

    const { newTagsToCreate, tagIds } = getDocumentTagsData({ allTags, tags });

    const props = { ...rest, tagIds };

    let tagsCreateFailed: string[] = [];

    if (newTagsToCreate.length) {
      try {
        const { data } = await createTags({
          variables: {
            tags: newTagsToCreate,
          },
        });

        if (data.createTags.unsuccessful.length) {
          tagsCreateFailed = data.createTags.unsuccessful.map((id) => id);
          enqueueSnackbar(
            `${t('Not all new tags successfully created')}. ${t(
              'Please try again'
            )}.`
          );
        }
      } catch (e) {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      }
    }

    const { fileTypes, name } = values;

    const tagsExludingFailedToCreate = tagIds.filter(
      (tagId) => !tagsCreateFailed.includes(tagId)
    );

    const filterTags: Filter[] = tagsExludingFailedToCreate.map((tag) => ({
      property: DocumentPropertyType.Tag,
      type: 'ValueFilter',
      value: tag,
    }));
    const filterExtensions: Filter[] = fileTypes.map((fileType) => ({
      property: DocumentPropertyType.Extension,
      type: 'ValueFilter',
      value: fileType,
    }));
    const filters = [...filterTags, ...filterExtensions];

    if (smartFolder) {
      const { id } = smartFolder;
      await updateSmartFolder({ variables: { id, ...props } });
      editItems([{ filters, id, name }]);
      searchParams.delete('action');
    } else {
      const res = await createSmartFolder({
        onCompleted: () => {
          setStatus('success');
          onClose();
          const message = (
            <SmartFolderCreatedOrUpdated
              isUpdate={editMode}
              smartFolderName={values.name}
            />
          );
          enqueueSnackbar(message);
          goTo(projectId ? 'projectSmartFolder' : 'smartFolder', {
            params: {
              name: props.name,
              projectId,
            },
          });
        },
        onError: (e) => {
          const code = e.graphQLErrors[0].extensions.exception.code;
          if (code === 409) {
            setFieldError('name', t('Name must be unique'));
          } else {
            setStatus('failed');
            setTimeout(() => setStatus(), 3000);
          }
        },
        variables: { projectId, ...props },
      });
      addItems([{ filters, id: res?.data?.createSmartFolder, name }]);
      searchParams.delete('action');
    }
  };
};
