import type { ReactChild, ReactFragment, ReactPortal } from 'react';
import React, { Children, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Dialog, DialogProps } from '@pro4all/shared/mui-wrappers';
import { StylingDefaults } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { handlePickersModalClick } from '@pro4all/shared/ui/general';

import { Content, Wrapper } from './Content';
import * as Styled from './FloatingModal.styles';
import { Footer } from './Footer';
import { Header } from './Header';

type ValidElement = ReactChild | ReactFragment | ReactPortal | null;

export const FloatingModal: React.FC<DialogProps & { padding?: boolean }> = ({
  children,
  onClose,
  padding = true,
  ...dialogProps
}) => {
  const { t } = useTranslation();

  const [header, footer, content] = Children.toArray(children).reduce<
    [ValidElement, ValidElement, ValidElement[]]
  >(
    ([header, footer, content], child) => {
      switch (true) {
        case isValidElement(child) && child.type === Footer:
          return [header, child, content];
        case isValidElement(child) && child.type === Header:
          return [child, footer, content];
        default:
          return [header, footer, [...content, child]];
      }
    },
    [null, null, []]
  );

  return (
    <StyledDialog
      {...dialogProps}
      disableEnforceFocus
      onClick={handlePickersModalClick}
      onClose={onClose}
    >
      <Styled.Header>
        {header}
        {onClose && (
          <IconButton
            ariaLabel={t('Close')}
            color="default"
            dataTestId="close-modal"
            disableBorder
            iconName="close"
            onClick={(e) => onClose(e, 'backdropClick')}
            sx={(theme) => ({
              position: 'absolute',
              right: theme.spacing(2),
              top: theme.spacing(2),
            })}
          />
        )}
      </Styled.Header>
      <Content>{padding ? <Wrapper>{content}</Wrapper> : content}</Content>
      {footer}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiBackdrop-root {
      background-color: ${StylingDefaults.backdropColor};
    }

    .MuiDialog-paper {
      border-radius: 16px;
      box-shadow: 0px 6px 24px rgba(51, 51, 51, 0.24);
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }

    .MuiDialog-paperWidthSm {
      width: 398px;
    }

    .MuiDialog-paperWidthMd {
      width: 798px;
    }

    .MuiDialog-paperWidthLg {
      width: 798px;
    }

    .MuiDialog-paperWidthXl {
      height: calc(100% - 100px);
      width: calc(100% - 100px);
    }
  }
`;
