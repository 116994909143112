import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayRenderProps } from 'formik';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import {
  FormikColorPicker,
  FormikInput,
  statusColors,
} from '@pro4all/shared/ui/general';

export const StyledFormikInput = styled(FormikInput)`
  margin: 0;
`;

interface CustomStatusOptionProps {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  onPasteOverWriteHandler?: (
    event: React.ClipboardEvent<HTMLInputElement>
  ) => void;
}

export const CustomStatusOptionComponent = ({
  arrayHelpers,
  index,
  onPasteOverWriteHandler,
}: CustomStatusOptionProps) => {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      key={index}
      mb={1}
    >
      <StyledFormikInput
        inputProps={{ 'aria-label': `statusOptions[${index}].name` }}
        margin="dense"
        name={`statusOptions[${index}].name`}
        onPaste={onPasteOverWriteHandler}
        placeholder={t('Name')}
        showErrorMessage={false}
        type="text"
      />
      <FormikColorPicker
        colors={statusColors}
        index={index}
        inputProps={{ 'aria-label': `statusOptions[${index}].color` }}
        name={`statusOptions[${index}].color`}
      />
      <IconButton
        color="default"
        disableBorder
        iconName="close"
        onClick={() => arrayHelpers.remove(index)}
      />
    </Box>
  );
};

const skipUpdate = (
  prevProps: CustomStatusOptionProps,
  nextProps: CustomStatusOptionProps
) => prevProps.index === nextProps.index;

export const CustomStatusOption = React.memo(
  CustomStatusOptionComponent,
  skipUpdate
);
