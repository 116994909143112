import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageLinkExpired: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Contact the person who gave you this link to give you access to these documents.'
      )}
      title={t('This link is expired')}
    />
  );
};
