import React from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentVersion } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';
import { sortByWithInnerSort } from '@pro4all/shared/utils';

import { DocumentVersionCard } from './DocumentVersionCard';

interface Props {
  hide?: boolean;
  versions: DocumentVersion[];
}

export const VersionList: React.FC<Props> = ({ hide, versions }) => {
  const { t } = useTranslation();

  if (hide) return null;

  const versionsSorted = versions
    .sort(
      sortByWithInnerSort({
        mainProp: 'createdAt',
        mainPropSortOrder: 'desc',
        subProp: 'name',
        subPropSortOrder: 'desc',
      })
    )
    .slice(1); //to skip the current version

  return (
    <Box p={3}>
      <Box mb={1}>
        <Text variant="h5">{t('Older versions')}</Text>
      </Box>

      {versionsSorted.map((version: DocumentVersion) => (
        <DocumentVersionCard
          documentVersion={version}
          key={version.id}
          versionNumber={version.versionNumber ?? 0}
        />
      ))}
    </Box>
  );
};

export default VersionList;
