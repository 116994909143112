export const includePropsReportConfigurations = {
  includeCreatedAt: true,
  includeCreatedBy: true,
  includeIsDefault: true,
  includeProjectId: true,
  includeReportOptions: true,
  includeScope: true,
  includeUpdatedAt: true,
  includeUpdatedBy: true,
};
