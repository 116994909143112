import React, { useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Documents3DViewer } from '@pro4all/documents/ui/3d';
import {
  DocumentsPdfTronViewer,
  PdfTronOpenAction,
} from '@pro4all/documents/ui/annotate';
import { useFolderTreeContextInner } from '@pro4all/documents/ui/folders';
import {
  UploaderEditor,
  UploaderEditorProvider,
} from '@pro4all/documents/ui/uploader-editor';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { BigMessageNoProjectAccess } from '@pro4all/shared/ui/messages';

import { DocumentsStamper } from '../DocumentsStamper';
import { FolderPermissionsEditor } from '../FolderPermissionsEditor';

import { Modals } from './modals/Modals';
import { DocumentNotifications } from './DocumentNotifications';
import { Documents } from './Documents';
import { DocumentTasks } from './DocumentTasks';

export const DocumentsBrowserContents: React.FC = () => {
  const { noAccess } = useFolderTreeContextInner();
  const { searchParams } = useRouting();

  const routeTasks = useRouteMatch([
    Routes.projectDocsTasks,
    Routes.projectDocsNotifications,
  ]);

  const getDefaultComponent = () => {
    if (routeTasks?.path === Routes.projectDocsTasks) {
      return (
        <DataViewContextProvider
          initialView={DataViewType.Table}
          viewKey="document-tasks"
          views={[
            DataViewType.Table,
            DataViewType.Charts,
            DataViewType.Calendar,
          ]}
        >
          <DocumentTasks />
        </DataViewContextProvider>
      );
    }
    if (routeTasks?.path === Routes.projectDocsNotifications) {
      return <DocumentNotifications />;
    }
    return <Documents />;
  };

  const actions = useRef<Record<string, JSX.Element>>({
    editMetaData: (
      <UploaderEditorProvider>
        <UploaderEditor />
      </UploaderEditorProvider>
    ),
    editSearch: <Documents editSavedSearch />,
    manageFolderPermissions: <FolderPermissionsEditor />,
    stampDocuments: <DocumentsStamper />,
    uploadDocuments: (
      <UploaderEditorProvider>
        <UploaderEditor />
      </UploaderEditorProvider>
    ),
  });

  if (noAccess) return <BigMessageNoProjectAccess />;

  if (searchParams.get('urn') !== null) return <Documents3DViewer />;
  if (searchParams.get('annotate') === 'true')
    return <DocumentsPdfTronViewer action={PdfTronOpenAction.ANNOTATE} />;

  const actionComponent = actions.current[searchParams.get('action')];

  const defaultComponent = (
    <>
      <Modals />
      {getDefaultComponent()}
    </>
  );

  return actionComponent ?? defaultComponent;
};
