import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Build: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M10.617 128.132a4.273 4.273 0 002.97-1.316 4.16 4.16 0 001.152-3.012c-.107-2.293-2.12-4.315-4.502-4.213H4.125c-2.381.099-4.226 2.279-4.12 4.572a4.227 4.227 0 001.424 2.903 4.313 4.313 0 003.076 1.069l6.112-.003zM29.006 128.132a4.272 4.272 0 002.969-1.316 4.161 4.161 0 001.152-3.012c-.103-2.293-2.12-4.315-4.501-4.213h-6.112c-2.382.099-4.227 2.279-4.121 4.572a4.226 4.226 0 001.425 2.903 4.313 4.313 0 003.076 1.069l6.112-.003zM239.999 13.493a4.37 4.37 0 01-.319 1.697 4.42 4.42 0 01-.951 1.447 4.476 4.476 0 01-3.145 1.334H212.28c-7.439 0-13.497 6.14-13.497 13.69v79.726c0 9.708-7.788 17.61-17.364 17.61s-17.364-7.902-17.364-17.61V46.681c0-4.772-3.832-8.654-8.536-8.654-4.703 0-8.536 3.882-8.536 8.654v64.709c0 9.708-7.788 17.61-17.364 17.61s-17.367-7.902-17.367-17.61V34.53c0-4.775-3.827-8.66-8.533-8.66-4.706 0-8.54 3.885-8.54 8.66v76.86c0 9.078-6.813 16.572-15.521 17.511l-36.45-.065a4.493 4.493 0 01-3.145-1.338 4.39 4.39 0 01-1.267-3.149c0-2.471 1.976-4.523 4.412-4.523l36.45.025a8.736 8.736 0 004.837-3.077 8.583 8.583 0 001.854-5.387V34.53c0-9.712 7.791-17.61 17.37-17.61s17.361 7.913 17.361 17.61v76.86c0 4.772 3.833 8.656 8.539 8.656 4.707 0 8.536-3.884 8.536-8.656V46.681c0-9.706 7.791-17.608 17.364-17.608s17.361 7.916 17.361 17.608v64.709c0 4.772 3.83 8.656 8.539 8.656 4.71 0 8.539-3.884 8.539-8.656V31.663c0-11.088 7.806-21.693 18.315-22.27 1.328-.073 27.023-.37 27.026-.37 2.587-.006 4.7 1.999 4.7 4.47z"
      fill={colors.primary}
    />
    <path
      d="M187.303 117.657c-.766.806-3.059 2.389-5.692 2.389-3.244 0-8.728-1.837-8.728-9.954 0-20.215-.034-44.517 0-63.86.017-8.505-7.362-17.337-17.595-17.156 4.327-.076 14.063 6.972 14.063 16.912 0 21.057-5.255 26.174-5.255 66.6 0 8.993 11.966 18.727 23.207 5.069zM135.862 117.281a8.674 8.674 0 01-2.943 2.062 8.764 8.764 0 01-3.537.698c-3.584 0-8.305-2.915-8.305-9.194 0-22.411.02-53.855.02-75.61 0-13.147-10.632-18.903-18.512-18.281 4.316-.34 14.985 7.35 14.985 18.377 0 23.35-5.314 31.028-5.314 75.856 0 10.432 12.751 20.435 23.606 6.092zM235.867 9.051c-.04 0-.105-.017-.157-.017-5 .017-35.984-1.724-35.984 17.529 2.328-5.578 7.223-8.606 12.566-8.606 4.141-2.39 9.967-7.325 23.575-8.906zM53.235 128.853c9.313-.181 33.034 3.11 33.034-16.27-1.09 5.824-5.651 7.135-6.61 7.271-4.15 2.403-12.815 7.419-26.424 8.999z"
      fill={colors.secondary}
    />
  </SvgIcon>
);
