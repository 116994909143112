import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const LastApproved: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12ZM10 14.17L12.94 11.23L15.88 8.29C16.27 7.9 16.91 7.9 17.3 8.29C17.69 8.68 17.69 9.31 17.3 9.7L10.71 16.29C10.32 16.68 9.69 16.68 9.3 16.29L6.71 13.7C6.32 13.31 6.32 12.68 6.71 12.29C6.89683 12.1027 7.15048 11.9975 7.415 11.9975C7.67952 11.9975 7.93317 12.1027 8.12 12.29L10 14.17Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.28032 2.11046C10.1451 1.57006 11.0574 1 12 1C12.9426 1 13.8549 1.57006 14.7197 2.11046C15.1595 2.38528 15.587 2.65243 16 2.83304C16.4769 3.04158 17.0968 3.12385 17.7253 3.20728C18.6566 3.33088 19.6069 3.45702 20.1393 4C20.7385 4.611 20.8782 5.6802 21.0141 6.71988C21.0981 7.36303 21.1807 7.99488 21.3696 8.5C21.523 8.91015 21.792 9.33304 22.0678 9.76663C22.5246 10.4848 23 11.2323 23 12C23 12.6171 22.5953 13.2212 22.2026 13.8075C21.9302 14.2141 21.6636 14.6121 21.5416 15C21.3839 15.5017 21.3363 16.1351 21.2876 16.7821C21.2102 17.813 21.1301 18.8783 20.5976 19.5C19.9812 20.2197 18.8656 20.4522 17.7762 20.6792C17.1404 20.8117 16.5135 20.9424 16 21.167C15.587 21.3476 15.1595 21.6147 14.7197 21.8895C13.8549 22.4299 12.9426 23 12 23C11.0574 23 10.1451 22.4299 9.2803 21.8895C8.84051 21.6147 8.41299 21.3476 8 21.167C7.48648 20.9424 6.85958 20.8117 6.22377 20.6792C5.13438 20.4522 4.01879 20.2197 3.40236 19.5C2.86987 18.8783 2.78982 17.813 2.71235 16.7821C2.66374 16.1351 2.61614 15.5017 2.45838 15C2.33643 14.6121 2.0698 14.2141 1.79743 13.8075C1.40468 13.2212 1 12.6171 1 12C1 11.2323 1.47545 10.4848 1.93222 9.76663C2.20799 9.33304 2.47695 8.91015 2.63037 8.5C2.81931 7.99488 2.90188 7.36303 2.98593 6.71988C3.1218 5.68019 3.26152 4.611 3.86068 4C4.39315 3.45702 5.34344 3.33088 6.27468 3.20728C6.90325 3.12385 7.52314 3.04158 8 2.83304C8.413 2.65243 8.84052 2.38528 9.28032 2.11046ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
