import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateProfileMutation } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { FormFields } from './types';

export const useSubmit = (previousLanguage?: string) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useRouting();

  const [updateProfile] = useUpdateProfileMutation();

  const {
    userOrganizationName,
    setUserFirstName,
    setUserLastName,
    setUserJobTitle,
    setUserPhoneNumber,
  } = useOrganizationContext();

  return async (values: FormFields) => {
    try {
      const {
        active,
        id,
        organizationId,
        email,
        firstName,
        jobTitle,
        language,
        lastName,
        phoneNumber,
      } = values;

      const lang = language ? language.id : '';

      await updateProfile({
        variables: {
          active,
          email,
          firstName,
          id,
          jobTitle: jobTitle ?? '',
          language: lang,
          lastName,
          organizationId,
          phoneNumber: phoneNumber ?? '',
        },
      });

      setUserFirstName(firstName);
      setUserLastName(lastName);
      setUserPhoneNumber(phoneNumber ?? '');
      setUserJobTitle(jobTitle ?? '');

      track(TrackingEvent.UserProfileUpdated, {
        displayName: firstName + ' ' + lastName,
        email,
        firstName,
        id,
        language: lang,
        lastName,
        organization: {
          id: organizationId,
          name: userOrganizationName,
        },
      });

      enqueueSnackbar(t('Changes are successfully saved'), {
        variant: 'success',
      });

      // Update localStorage with new language setting
      localStorage.setItem('i18nextLng', lang);

      //Reload the app in case the language did change.
      if (!previousLanguage || previousLanguage !== lang) {
        window.location.reload();
      } else {
        // We cannot do the goBack() together with the reload.
        goBack();
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
      console.warn(e);
    }
  };
};
