import React from 'react';

import { HierarchyEditorProvider } from '@pro4all/shared/hierarchy-editor';

import type { AnswerComponent } from '../AnswerView';

import { HierarchyListOutput } from './HierarchyListOutput';

export const HierarchyList: AnswerComponent = ({ children, item }) => (
  <HierarchyEditorProvider>
    <HierarchyListOutput item={item} value={children} />
  </HierarchyEditorProvider>
);
