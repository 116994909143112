import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ControlResults: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M19.386 21.4929L11.6197 17.3807C11.4173 17.2734 11.1704 17.2882 10.9791 17.4178L5.04051 21.5299C4.81837 21.6841 4.72211 21.9643 4.80356 22.2222C4.88254 22.4777 5.12196 22.6542 5.391 22.6542H19.096C19.3786 22.6542 19.6267 22.4616 19.6946 22.1852C19.7637 21.91 19.6353 21.6249 19.386 21.4929Z" />
    <path d="M21.9854 20.3197L20.1589 12.0967C20.1145 11.8955 19.9726 11.7314 19.7813 11.6561C19.5888 11.5808 19.3728 11.6068 19.2049 11.7228L13.2651 15.8349C13.0874 15.9583 12.9874 16.1644 13.001 16.3779C13.0133 16.5939 13.138 16.7864 13.328 16.8864L21.0932 20.9985C21.1845 21.0466 21.2832 21.0713 21.3819 21.0713C21.514 21.0713 21.6461 21.0293 21.7559 20.9442C21.9472 20.7985 22.0373 20.5554 21.9854 20.3197Z" />
    <path d="M17.6882 10.7457C17.6067 10.4877 17.3685 10.3125 17.0983 10.3125H11.4657C10.4414 12.1674 8.92585 13.9408 8.30262 14.6344C7.95213 15.0244 7.44984 15.249 6.92533 15.249C6.40083 15.249 5.89854 15.0244 5.54804 14.6344C5.30739 14.3666 4.93468 13.9384 4.50767 13.4077L3.01438 20.1288C2.96008 20.3768 3.06251 20.6323 3.27231 20.7742C3.37598 20.8446 3.49692 20.8791 3.61663 20.8791C3.74005 20.8791 3.86222 20.8433 3.96836 20.7693L17.4487 11.4356C17.6721 11.2838 17.7672 11.0036 17.6882 10.7457Z" />
    <path
      d="M3 6.15629C3 3.85846 4.85846 2 7.15629 2C9.45412 2 11.3126 3.85846 11.3126 6.15629C11.3126 8.63225 8.68818 12.0463 7.61348 13.3467C7.37598 13.6317 6.94254 13.6317 6.70504 13.3467C5.6244 12.0463 3 8.63225 3 6.15629ZM5.67186 6.15625C5.67186 6.97564 6.33687 7.64064 7.15625 7.64064C7.97564 7.64064 8.64064 6.97564 8.64064 6.15625C8.64064 5.33687 7.97564 4.67186 7.15625 4.67186C6.33687 4.67186 5.67186 5.33687 5.67186 6.15625Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
