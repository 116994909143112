import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Folder, FolderPermission } from '@pro4all/graphql';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { naturalCompare } from '@pro4all/shared/utils';

import { useGetSinglePermissionsData } from './hooks/useGetSinglePermissionsData';

interface InheritedPermissionProps {
  folder?: Folder;
  groups: string[];
  permission?: FolderPermission;
}

const TooltipWrapper = styled(Tooltip)`
  && {
    display: flex;
    align-items: center;
  }
`;

const Container = styled.div`
  width: 1.5em;
`;

export const InheritedPermission = ({
  folder,
  groups,
  permission,
}: InheritedPermissionProps) => {
  const { t } = useTranslation();

  const getSinglePermissionData = useGetSinglePermissionsData();
  const singlePermissionData = getSinglePermissionData(permission);

  if (!groups?.length) {
    return <Container />;
  }

  const groupsSorted = groups.sort((a, b) => naturalCompare(a, b));

  const title = (
    <>
      {t(
        "You have '{{- permissionText}}' permissions in '{{folderName}}' and subfolders determined via the following groups",
        {
          folderName: folder?.name,
          permissionText: t(singlePermissionData?.permissionText),
        }
      )}

      <ul>
        {groupsSorted?.map((group) => (
          <li key={group}>{group}</li>
        ))}
      </ul>
    </>
  );

  return (
    <Container>
      <TooltipWrapper placement="bottom" title={title}>
        <Icon
          color="secondary"
          data-testid="inheritedPermission"
          fontSize="small"
          iconName={singlePermissionData?.icon || 'group'}
        />
      </TooltipWrapper>
    </Container>
  );
};
