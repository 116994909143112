/*
 * We'll ask BE teams to standardize their responses.
 * Until then we'll use these.
 * */
export interface FilesData {
  data: {
    errorCode?: string;
    files?: {
      existingFile: boolean;
      fileName: string;
      id: string;
      imageId: string;
      success: boolean;
    }[];
    id: string;
  };
}

// Comments API
export interface FileData {
  data: {
    completed: boolean;
    errorCode?: string;
    fileName: string;
    fileSize?: number;
    id: string;
    imageId: string;
    url?: string;
  };
}

export enum ErrorCode {
  API_ERROR_FILE_NAME_EXISTS = 'API_ERROR_FILE_NAME_EXISTS',
  API_ERROR_FILE_UPLOAD_FAILED = 'API_ERROR_FILE_UPLOAD_FAILED',
  API_ERROR_FLOORPLAN_NAME_EXISTS = 'API_ERROR_FLOORPLAN_NAME_EXISTS',
}
