import React from 'react';

import { TbqAnswer, TbqRieResult } from '@pro4all/graphql';

import { QuestionSidebarProperties } from './QuestionSidebarProperties';
import { TBQQuestionSidebarColumnContentWrapper } from './TBQQuestionSidebar.styled';

interface PropertiesContentProps {
  allTasksCount: number;
  completedTasksCount: number;
  question: TbqRieResult | TbqAnswer | undefined;
}

export const PropertiesContent: React.FC<PropertiesContentProps> = ({
  allTasksCount,
  completedTasksCount,
  question,
}) => (
  <TBQQuestionSidebarColumnContentWrapper>
    <QuestionSidebarProperties
      allTasksNumber={allTasksCount}
      numberCompletedTasks={completedTasksCount}
      question={question}
    />
  </TBQQuestionSidebarColumnContentWrapper>
);
