import React from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { DivIcon, MarkerCluster, Point } from 'leaflet';
import { v4 as uuid } from 'uuid';

import { useMapLinkingContext } from './MapLinkingContext';

const createClusterCustomIcon = (cluster: MarkerCluster, iconScale: number) => {
  const CLUSTER_ICON_SIZE = 38 * iconScale;

  const icon = `
      <div style='
        height: 100%;
        color: #fff;
        line-height: ${CLUSTER_ICON_SIZE}px;
        font-size: ${CLUSTER_ICON_SIZE / 2}px;
        text-align: center;
        vertical-align: middle;
        background-color: #36ccba;
        border: 0;
        border-radius: 50%;
        box-shadow: 0px 3px 6px rgba(44, 45, 86, 0.24);'>
        ${cluster.getChildCount()}
      </div>`;

  return new DivIcon({
    html: icon,
    iconAnchor: new Point(CLUSTER_ICON_SIZE / 2, CLUSTER_ICON_SIZE / 2),
    iconSize: new Point(CLUSTER_ICON_SIZE, CLUSTER_ICON_SIZE),
  });
};

export const ClusterGroup: React.FC = ({ children }) => {
  const { iconScale } = useMapLinkingContext();

  return (
    <MarkerClusterGroup
      iconCreateFunction={(cluster) =>
        createClusterCustomIcon(cluster, iconScale)
      }
      key={uuid()}
      maxClusterRadius={50 * iconScale}
    >
      {children}
    </MarkerClusterGroup>
  );
};
