import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Task,
  TbqModuleType,
  useGetDeleteTbqScanItemsLazyQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { ActionProps } from '@pro4all/shared/types';
import { Dialog, useTableCheck } from '@pro4all/shared/ui/general';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { Text } from '@pro4all/shared/ui/typography';
import { useNavigateToTBQResults } from '@pro4all/workflow/ui/task-sidebar';

import { useDeleteTbqScan } from './useDeleteTbqScan';

export const TasksActionBar: React.FC<{
  deleteItems: (ids: string[]) => void;
}> = ({ deleteItems }) => {
  const { t } = useTranslation();
  const { params, goTo } = useRouting();
  const { checkedRows } = useTableCheck<Task>();
  const [showRieMultiDashboardButton, setRieMultiDashboardButton] =
    useState<boolean>(false);
  const [showBrandcheckDashboardButton, setShowBrandcheckDashboardButton] =
    useState<boolean>(false);

  const objectId = params.objectId ?? '';
  const projectId = params.projectId;
  const isOrganizationLevel = Boolean(!projectId);

  const [
    fetchDeleteTbqScanItems,
    { loading: loadingScanItems, data: dataScanItems },
  ] = useGetDeleteTbqScanItemsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const procedureId = checkedRows[0]?.procedureId;
  const allSameProcedure = checkedRows.every(
    (row) => row.procedureId === procedureId
  );

  const { handleDelete } = useDeleteTbqScan({
    deleteItems,
    linkedSnagIds: dataScanItems?.getDeleteTBQScanItems?.deletingSnags ?? [],
    procedureId,
    taskIds: dataScanItems?.getDeleteTBQScanItems?.deletingTasks ?? [],
    tbqFormIds: dataScanItems?.getDeleteTBQScanItems?.deletingForms ?? [],
  });

  const navigateToTBQResults = useNavigateToTBQResults({
    isOrganizationLevel,
    objectId,
    projectId,
    taskId: checkedRows[0]?.id ?? '',
  });
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    // Do not update states when deselecting items.
    // Instead use the action bar non selected hide animation
    if (checkedRows.length < 1) return;
    setShowBrandcheckDashboardButton(
      checkedRows.length === 1 &&
        checkedRows[0].tbq?.tbqModuleType === TbqModuleType.Brandcheck
    );

    const allRIEScans = checkedRows.every(
      (row) => row.tbq?.tbqModuleType === TbqModuleType.Rie
    );
    setRieMultiDashboardButton(allRIEScans);
  }, [checkedRows]);

  useEffect(() => {
    const idsToDelete = checkedRows.map((result) => result.id);
    if (showDeletePrompt === true) {
      fetchDeleteTbqScanItems({ variables: { taskIds: idsToDelete } });
    }
  }, [fetchDeleteTbqScanItems, checkedRows, showDeletePrompt]);

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Dashboard'),
      disabled: !showBrandcheckDashboardButton,
      key: 'brandcheck-dashboard',
      label: t('Dashboard'),
      onClick: () => {
        navigateToTBQResults();
      },
      startIcon: 'dashboard',
    },
    {
      ariaLabel: t('Dashboard'),
      disabled: !showRieMultiDashboardButton,
      key: 'multi-rie-dashboard',
      label: t('Dashboard'),
      onClick: () => {
        goTo('objectTBQMultiRieResults', {
          params: { objectId },
          state: {
            taskIds: checkedRows.map((task: Task) => task.id),
          },
        });
      },
      startIcon: 'dashboard',
    },
    {
      ariaLabel: t('Delete'),
      hidden: !allSameProcedure,
      key: 'delete-scan',
      label: t('Delete'),
      onClick: () => {
        setShowDeletePrompt(true);
      },
      startIcon: 'delete',
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={altActions}
        dataTestid="tbq-tasks-action-bar"
        search
      />
      {showDeletePrompt && !loadingScanItems && (
        <Dialog
          buttonColor={customColors['errorAlert']}
          confirmLabel={t('Delete')}
          iconName="warning"
          name="forgeDialog"
          onClose={() => setShowDeletePrompt(false)}
          onConfirm={async () => {
            await handleDelete();
            setShowDeletePrompt(false);
          }}
          open={showDeletePrompt}
          title={t('Are you sure?')}
        >
          <Text display="inline">
            {t(
              'You are about to delete {{scans}} scans, and {{snags}} linked snags. This action can’t be undone.',
              {
                scans:
                  dataScanItems?.getDeleteTBQScanItems?.deletingTasks?.length,
                snags:
                  dataScanItems?.getDeleteTBQScanItems?.deletingSnags?.length,
              }
            )}
          </Text>
        </Dialog>
      )}
    </>
  );
};
