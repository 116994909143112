import { Folder } from '@pro4all/graphql';

import { unflattenFolders } from '../helpers/unflattenFolders';
import { MoveFolderPayload, State } from '../types';

export const moveFolderAction = ({
  payload,
  state,
}: {
  payload: MoveFolderPayload;
  state: State;
}) => {
  const childFolder = state.folders.find(
    (folder) => folder.id === payload.childFolderId
  );

  const originalParentFolder = state.folders.find(
    (folder) => folder.id === childFolder.parentFolderId
  );

  const parentFolder = state.folders.find(
    (folder) => folder.id === payload.parentFolderId
  );

  // Scenario 1: Subfolder to root.
  if (!payload.parentFolderId) {
    // Path property of deeper nested folders from the childFolder must also change according the new parent of the childFolder, which is the root.
    const nestedFolders = getUpdatedNestedFolders({
      childFolderId: childFolder.id,
      folders: state.folders,
      newPath: childFolder.name,
    });
    const nestedFolderIds = nestedFolders.map((folder) => folder.id);

    const otherFolders = state.folders.filter(
      (folder) =>
        folder.id !== payload.childFolderId &&
        folder.id !== originalParentFolder.id &&
        !nestedFolderIds.includes(folder.id)
    );

    const childFolderUpdated = {
      ...childFolder,
      parentFolderId: null as string,
      path: childFolder.name,
    };
    const originalParentFolderUpdated = {
      ...originalParentFolder,
      hasSubFolders: Boolean(
        otherFolders.find(
          (folder) => folder.parentFolderId === originalParentFolder.id
        )
      ),
    };

    const folders = [
      ...otherFolders,
      ...nestedFolders,
      childFolderUpdated,
      originalParentFolderUpdated,
    ];

    return {
      ...state,
      folderTree: unflattenFolders(folders),
      folders,
    };
  }

  // Scenario 2: Subfolder to other parent folder.
  if (payload.parentFolderId && childFolder.parentFolderId) {
    // Path property of deeper nested folders from the childFolder must also change according the new parent of the childFolder.
    const nestedFolders = getUpdatedNestedFolders({
      childFolderId: childFolder.id,
      folders: state.folders,
      newPath: `${parentFolder.path}/${childFolder.name}`,
    });
    const nestedFolderIds = nestedFolders.map((folder) => folder.id);

    const otherFolders = state.folders.filter(
      (folder) =>
        folder.id !== payload.childFolderId &&
        folder.id !== payload.parentFolderId &&
        folder.id !== originalParentFolder.id &&
        !nestedFolderIds.includes(folder.id)
    );

    const childFolderUpdated = {
      ...childFolder,
      parentFolderId: parentFolder.id,
      path: `${parentFolder.path}/${childFolder.name}`,
    };

    const parentFolderUpdated = {
      ...parentFolder,
      hasSubFolders: true,
    };

    const originalParentFolderUpdated = {
      ...originalParentFolder,
      hasSubFolders: Boolean(
        otherFolders.find(
          (folder) => folder.parentFolderId === originalParentFolder.id
        )
      ),
    };

    const folders = [
      ...otherFolders,
      ...nestedFolders,
      childFolderUpdated,
      originalParentFolderUpdated,
      parentFolderUpdated,
    ];

    return {
      ...state,
      folderTree: unflattenFolders(folders),
      folders,
    };
  }

  // Scenario 3: Root folder to other folder.
  if (payload.parentFolderId && !childFolder.parentFolderId) {
    // Path property of deeper nested folders from the childFolder must also change according the new parent of the childFolder.
    const nestedFolders = getUpdatedNestedFolders({
      childFolderId: childFolder.id,
      folders: state.folders,
      newPath: `${parentFolder.path}/${childFolder.name}`,
    });
    const nestedFolderIds = nestedFolders.map((folder) => folder.id);

    const otherFolders = state.folders.filter(
      (folder) =>
        folder.id !== payload.childFolderId &&
        folder.id !== payload.parentFolderId &&
        !nestedFolderIds.includes(folder.id)
    );

    const childFolderUpdated = {
      ...childFolder,
      parentFolderId: parentFolder.id,
      path: `${parentFolder.path}/${childFolder.name}`,
    };

    const parentFolderUpdated = {
      ...parentFolder,
      hasSubFolders: true,
    };

    const folders = [
      ...otherFolders,
      ...nestedFolders,
      childFolderUpdated,
      parentFolderUpdated,
    ];

    return {
      ...state,
      folderTree: unflattenFolders(folders),
      folders,
    };
  }

  // One of the three scenarios should happen, but just in case.
  return {
    ...state,
  };
};

const getUpdatedNestedFolders = ({
  childFolderId,
  folders,
  newPath,
}: {
  childFolderId: string;
  folders: Folder[];
  newPath: string;
}) => {
  let returnValue: Folder[] = [];

  const getIds = (id: string, path: string) => {
    const childFolders = folders.filter(
      (folder) => folder.parentFolderId === id
    );
    if (childFolders.length) {
      const childFoldersUpdatedPath = childFolders.map((folder) => ({
        ...folder,
        path: `${path}/${folder.name}`,
      }));
      returnValue = [...returnValue, ...childFoldersUpdatedPath];
      childFolders.forEach((folder) =>
        getIds(folder.id, `${path}/${folder.name}`)
      );
    }
  };

  getIds(childFolderId, newPath);

  return returnValue;
};
