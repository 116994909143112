import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Admin: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M11 11C13.21 11 15 9.21 15 7C15 4.79 13.21 3 11 3C8.79 3 7 4.79 7 7C7 9.21 8.79 11 11 11Z" />
    <path d="M11 13C8.33 13 3 14.34 3 17V18C3 18.55 3.45 19 4 19H12.0015C12.0015 18.78 12.0215 18.56 12.0481 18.3467L10.6415 17.2467C10.5148 17.1467 10.4748 16.9667 10.5615 16.82L11.8948 14.5133C11.9748 14.3667 12.1481 14.3067 12.3015 14.3667L13.9615 15.0333C14.3081 14.7733 14.6815 14.5467 15.0881 14.38L15.1843 13.7091C13.7152 13.2369 12.1225 13 11 13Z" />
    <path
      clipRule="evenodd"
      d="M20.1259 19C20.1259 19.1417 20.1134 19.275 20.0968 19.4083L20.9759 20.0958C21.0551 20.1583 21.0759 20.2708 21.0259 20.3625L20.1926 21.8042C20.1426 21.8958 20.0343 21.9333 19.9384 21.8958L18.9009 21.4792C18.6843 21.6417 18.4509 21.7833 18.1968 21.8875L18.0384 22.9917C18.0259 23.0917 17.9384 23.1667 17.8343 23.1667H16.1676C16.0634 23.1667 15.9759 23.0917 15.9634 22.9917L15.8051 21.8875C15.5509 21.7833 15.3176 21.6458 15.1009 21.4792L14.0634 21.8958C13.9718 21.9292 13.8593 21.8958 13.8093 21.8042L12.9759 20.3625C12.9259 20.2708 12.9468 20.1583 13.0259 20.0958L13.9051 19.4083C13.8884 19.275 13.8759 19.1375 13.8759 19C13.8759 18.8625 13.8884 18.725 13.9051 18.5917L13.0259 17.9042C12.9468 17.8417 12.9218 17.7292 12.9759 17.6375L13.8093 16.1958C13.8593 16.1042 13.9676 16.0667 14.0634 16.1042L15.1009 16.5208C15.3176 16.3583 15.5509 16.2167 15.8051 16.1125L15.9634 15.0083C15.9759 14.9083 16.0634 14.8333 16.1676 14.8333H17.8343C17.9384 14.8333 18.0259 14.9083 18.0384 15.0083L18.1968 16.1125C18.4509 16.2167 18.6843 16.3542 18.9009 16.5208L19.9384 16.1042C20.0301 16.0708 20.1426 16.1042 20.1926 16.1958L21.0259 17.6375C21.0759 17.7292 21.0551 17.8417 20.9759 17.9042L20.0968 18.5917C20.1134 18.725 20.1259 18.8583 20.1259 19ZM15.5426 19C15.5426 19.8042 16.1968 20.4583 17.0009 20.4583C17.8051 20.4583 18.4593 19.8042 18.4593 19C18.4593 18.1958 17.8051 17.5417 17.0009 17.5417C16.1968 17.5417 15.5426 18.1958 15.5426 19Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
