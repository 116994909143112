import React from 'react';
import { useTranslation } from 'react-i18next';

import { REPORT_IMAGE_EXTENSIONS } from '@pro4all/shared/qc-report-sources';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

interface UploadButtonProps {
  disabled?: boolean;
  name: string;
  onClick: () => void;
  uploading: boolean;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  disabled,
  name,
  onClick,
  uploading,
}) => {
  const { t } = useTranslation();

  return disabled ? (
    <IconButton
      ariaLabel={`Upload ${name}`}
      color="default"
      disabled
      iconName="upload"
      onClick={onClick}
    />
  ) : (
    <Tooltip
      aria-label="tooltip"
      placement="left"
      title={`${t(
        'Supported document types include'
      )}: ${REPORT_IMAGE_EXTENSIONS?.join(', ')}`}
    >
      <IconButton
        ariaLabel={`Upload ${name}`}
        color="default"
        disabled={uploading}
        iconName="upload"
        onClick={onClick}
      />
    </Tooltip>
  );
};
