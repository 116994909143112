import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikRadioGroup,
  FormikSearchableMultiSelect,
  FormikTextarea,
  RadioOption,
} from '@pro4all/shared/ui/general';
import { isAnySameEmailDomain } from '@pro4all/shared/utils';

import { splitEmailAddress } from '../../utils/splitEmailAddress';
import { useDelayedFormikUpdate } from '../../utils/useDelayedFormikUpdate';
import { InviteExternalsFormValues } from '../types';

import { useInviteExternalsModalConfig } from './useInviteExternalsModalConfig';

interface Props {
  onClose: () => void;
  options: RadioOption[];
}

export const InviteExternalsForm: React.FC<Props> = ({ onClose, options }) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const { dirty, errors, isSubmitting, values } =
    useFormikContext<InviteExternalsFormValues>();

  const [currentValue, setNextValue] =
    useDelayedFormikUpdate<InviteExternalsFormValues>('externalUsersToAdd');

  const { getField } = useInviteExternalsModalConfig();
  const externalUsersToAddField = getField('externalUsersToAdd');
  const roleIdField = getField('roleId');
  const messageField = getField('message');

  const { isExternalUserInProject, projectOrganizationName } =
    useProjectContext();

  // Calculate if we have to show the warning.
  const { email } = AuthService.getProfile();
  const emails = values.externalUsersToAdd.map(
    (externalUserToAdd) => externalUserToAdd.inputValue
  );
  const showDomainWarning = isAnySameEmailDomain({
    emailToCheck: email,
    emailsToCheck: emails,
  });

  return (
    <>
      <FormikSearchableMultiSelect
        autoFocus
        canAddNewOptions
        data-testid="email*"
        label={externalUsersToAddField.label}
        name={externalUsersToAddField.name}
        onInputChange={(_, value) =>
          splitEmailAddress(
            value,
            currentValue as unknown as Option[],
            setNextValue
          )
        }
        options={[]}
        placeholder={t('Search')}
      />

      <FormikRadioGroup
        label={roleIdField.label}
        name={roleIdField.name}
        options={options}
      />

      <FormikTextarea
        id={messageField.name}
        label={messageField.label}
        name={messageField.name}
        rows={4}
      />
      {showDomainWarning && !isExternalUserInProject ? (
        <Alert
          action={
            <Button color="inherit" onClick={() => goTo('members')}>
              {t('Organization')}
            </Button>
          }
          severity="warning"
          variant="filled"
        >
          {t(
            "You have entered an email address that has the same extension as your own email address. This may indicate that you might want to invite a direct colleague. If you are an administrator (admin) within the {{- projectOrganizationName}} organization, you can easily add a direct colleague to the organization, and then assign them to the project. Click on 'Organization' to navigate to that section. Add users from other companies here as external to this project.",
            {
              projectOrganizationName,
            }
          )}
        </Alert>
      ) : null}

      <FormFooter
        disableSubmit={isSubmitDisabled({
          dirty,
          errors,
          isSubmitting,
        })}
        onClose={onClose}
        pt={2}
        sticky
      />
    </>
  );
};
