import React from 'react';
import { useField } from 'formik';

import {
  FormControlLabel,
  FormControlLabelProps,
} from '@pro4all/shared/mui-wrappers';

import { Checkbox } from '../../inputs/checkbox/Checkbox';

interface FormikCheckboxProps {
  label: string;
  name: string;
}

export const FormikCheckbox: React.FC<
  Omit<FormControlLabelProps, 'control'> & FormikCheckboxProps
> = ({ disabled, label, name }) => {
  const [field, , helpers] = useField<boolean>({ name });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true);
    helpers.setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={field.value} onChange={handleChange} />}
      disabled={disabled}
      label={label}
    />
  );
};
