import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

import { MoveExistingUserProps } from './types';

export const LastAdmin = ({
  currentOrganizationName,
}: Pick<MoveExistingUserProps, 'currentOrganizationName'>) => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({ isInviteExistingUser: true });

  const description = (
    <div>
      <Trans
        components={{ strong: <strong /> }}
        defaults="You are the <strong>last admin</strong> in {{currentOrganizationName}} and there are still <strong>other users</strong> in here as well. Moving would leave {{currentOrganizationName}} withouth an admin. Please assign someone to the admin role and try again."
        values={{
          currentOrganizationName,
        }}
      />
    </div>
  );

  return (
    <InfoPage
      actions={[actionLogin]}
      description={description}
      title={t('You are the last admin')}
      type="error"
    />
  );
};
