import { Action, ActionType, State } from './types';

export function genericReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.TOGGLE_PERMISSIONS_SAVED: {
      return { ...state, permissionsSaved: !state.permissionsSaved };
    }
    case ActionType.TOGGLE_SHOW_FINAL_PERMISSIONS: {
      return { ...state, showFinalPermissions: !state.showFinalPermissions };
    }
    case ActionType.TOGGLE_SHOW_QC_GROUP_PERMISSIONS: {
      return {
        ...state,
        showQCGroupPermissions: !state.showQCGroupPermissions,
      };
    }
    case ActionType.TOGGLE_SHOW_HIGHEST_QC_PERMISSION: {
      return {
        ...state,
        showHighestQCPermission: !state.showHighestQCPermission,
      };
    }
    default:
      return state;
  }
}
