import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { usePubliclySharedDocsContext } from '../publicly-shared-docs/PubliclySharedDocsContext';

export const LoginToRevokeDialog: React.FC = () => {
  const { t } = useTranslation();
  const { toConfirmDialog, setShowLoginDialog, showLoginDialog } =
    usePubliclySharedDocsContext();

  return (
    <Dialog
      aria-label="Revoke link dialog"
      buttonVariant="outlined"
      confirmLabel={t('Login')}
      iconName="warning"
      name="loginToRevokeDialog"
      onClose={() => setShowLoginDialog(false)}
      onConfirm={toConfirmDialog}
      open={showLoginDialog}
      title={t('Revoke link')}
    >
      <Text display="inline">
        {t('If you are the creator, you can revoke the link after logging in.')}
      </Text>
    </Dialog>
  );
};
