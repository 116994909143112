import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { BigMessageSearchNoSavedQueries } from '@pro4all/shared/ui/messages';

export const NoContent: React.FC<{ onDismiss: () => void }> = ({
  onDismiss,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" height="419px" px={3} py={2}>
      <BigMessageSearchNoSavedQueries />
      <ButtonsBar>
        <Button onClick={onDismiss} variant="contained">
          {t('Ok')}
        </Button>
      </ButtonsBar>
    </Box>
  );
};

const ButtonsBar = styled(Box)`
  && {
    display: flex;
    justify-content: flex-end;
  }
`;
