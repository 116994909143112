import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  Folder,
  FolderByPathDocument,
  LockType,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Action, TrackingEvent } from '@pro4all/shared/config';
import {
  getAuthUserDisplayName,
  useContextScopedOrganizationId,
} from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/general';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useOnClickOnlineOffice = ({
  extension,
  readOnly,
  selection,
}: Pick<DocumentActionProps, 'readOnly' | 'selection'> & {
  extension: string;
}) => {
  const { track } = useAnalytics();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const { params } = useRouting();
  const { projectId } = params;

  const { id, versionId } = selection[0] || {};
  const { uncheckAllRows } = useTableCheck<Document>();
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const { userLanguage } = useOrganizationContext();

  const openOfficeOnline = async () => {
    track(TrackingEvent.OfficeOnlineOpened, {
      action: Action.OfficeOnlineButtonClicked,
      documentId: id,
      fileType: extension,
      organizationId,
      projectId,
      versionId,
    });

    // Update Apollo Cache
    const cachedFolder = client?.readQuery({
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    })?.folder as Folder;

    if (cachedFolder.documents) {
      const documents = cachedFolder.documents.map((doc) => {
        if (doc && doc.id === id) {
          return {
            ...doc,
            lockType: readOnly ? doc.lockType : LockType.OfficeOnline,
            lockedAt: new Date().toISOString(),
            lockedBy: {
              __typename: 'User',
              displayName: userName,
              id: userId,
            },
          };
        }
        return doc;
      });
      client?.writeQuery({
        data: {
          folder: {
            ...cachedFolder,
            documents: [...documents],
          },
        },
        query: FolderByPathDocument,
        variables: { path: params?.path ?? '/', projectId },
      });
    }
    uncheckAllRows();

    window.open(
      `${window.location.origin}/office?versionId=${versionId}&extension=${extension}&ui=${userLanguage}`,
      '_blank'
    ); // Opens the office host page in a new tab. Microsoft requirement to have it edge to edge.
  };

  return openOfficeOnline;
};
