import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useFolderMetaDataFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Template', // i18n
      name: 'selectedTemplate',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Apply to subfolders', // i18n
      name: 'applyToSubfolders',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
