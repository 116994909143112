import { useMoveToNewOrganizationMutation } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { MoveUserState } from '../types';

export const useActionMoveToNewOrganization = (
  updateMoveState: (newState: MoveUserState) => void
) => {
  const [move] = useMoveToNewOrganizationMutation();
  const { showSingleError } = useShowMessages();

  const moveToNewOrganization = async () => {
    try {
      const token = sessionStorage.getItem(StorageKeys.MOVE_ORG_TOKEN);
      await move({
        variables: {
          token,
        },
      });
      updateMoveState(MoveUserState.MoveSuccesful);
    } catch (e) {
      showSingleError(e);
      updateMoveState(MoveUserState.Error);
    }
  };

  return moveToNewOrganization;
};
