import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useTrialDaysLeft } from './useTrialDaysLeft';

export const useSubscriptionAnalytics = () => {
  const { track } = useAnalytics();
  const { userId } = AuthService.getProfile();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const daysLeftIntrial = useTrialDaysLeft();

  const trackChooseLicenseClicked = () => {
    track(TrackingEvent.ChooseLicenseClicked, {
      organizationId,
      userId,
    });
  };

  const trackLicenseSelected = ({ licenseId }: { licenseId: string }) => {
    track(TrackingEvent.LicenseSelected, {
      licenseId,
      organizationId,
      userId,
    });
  };

  const trackUpgradeLicenseButtonClicked = () => {
    track(TrackingEvent.UpgradeLicenseButtonClicked, {
      daysLeftIntrial,
      organizationId,
      userId,
    });
  };

  return {
    trackChooseLicenseClicked,
    trackLicenseSelected,
    trackUpgradeLicenseButtonClicked,
  };
};
