import React from 'react';
import { useTranslation } from 'react-i18next';

import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/general';

import { TBQSettingsForm } from './form';

export const TBQSettings = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const {
    object: { tbqConfig, tbqTypeAbstract, tbqTypeAllowedAbstract, ...object },
    refetch,
  } = useObjectDetailContext();

  const close = () => searchParams.delete('action');
  const i18nProps = {
    type: `$t(TBQ.${tbqTypeAbstract ?? tbqTypeAllowedAbstract})`,
  };

  return (
    <Sidebar
      onClose={close}
      open={searchParams.is('action', 'tbqSettings')}
      wide
    >
      <Sidebar.Header
        icon="addTBQ"
        title={
          tbqConfig
            ? t('Edit TBQ - {{type}}', i18nProps)
            : t('Enable TBQ - {{type}}', i18nProps)
        }
      />
      <TBQSettingsForm {...object} refetch={refetch} tbqConfig={tbqConfig} />
    </Sidebar>
  );
};
