import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateDocumentOrVersion } from '@pro4all/documents/ui/utils';
import {
  Keyword,
  useGenerateDocumentKeywordsSingleDocMutation,
  useMutateDocumentKeywordsMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps, DocumentAndVersionType } from '@pro4all/shared/types';

import { useDocumentKeywordsContext } from './DocumentKeywordsProvider';

export const useKeywordsDisplayActions = ({
  document,
  editable,
  isFinalized,
  setDisplayKeywords,
  version,
  versionId,
}: {
  editable: boolean;
  isFinalized: boolean;
  setDisplayKeywords: (value: boolean) => void;
  versionId: string;
} & DocumentAndVersionType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { searchParams } = useRouting();

  const [keywordsBeingGenerated, setKeywordsBeingGenerated] = useState(false);

  const {
    parkKeywords,
    state: { keywords },
  } = useDocumentKeywordsContext();

  const [mutateDocumentKeywords] = useMutateDocumentKeywordsMutation();
  const [generateDocumentKeywordsSingleDoc] =
    useGenerateDocumentKeywordsSingleDocMutation();
  const updateCachedDocumentOrVersion = useUpdateDocumentOrVersion();

  const onDelete = async (id: string) => {
    const keywordToDelete = keywords.find((keyword) => keyword.id === id);
    const keywordsPayload = [
      { newText: '', oldText: keywordToDelete?.oldText },
    ];
    try {
      await mutateDocumentKeywords({
        variables: { keywords: keywordsPayload, versionId },
      });
      const newKeywords = keywords
        .filter((keyword) => keyword.id !== id)
        .map(({ score, text }) => ({
          __typename: 'Keyword',
          score,
          text,
        }));
      updateCachedDocumentOrVersion({
        document,
        fieldToUpdate: 'keywords',
        value: newKeywords,
        version,
      });
    } catch (error) {
      enqueueSnackbar(t('Could not delete keyword, please try again.'));
    }
  };

  const onEditClick = (id: string) => {
    searchParams.set({ action: 'editKeyword', keywordId: id });
  };

  const onGenerateClick = async () => {
    try {
      setKeywordsBeingGenerated(true);
      const response = await generateDocumentKeywordsSingleDoc({
        variables: { versionId },
      });
      const keywords = response.data
        ?.generateDocumentKeywordsSingleDoc as Keyword[];
      if (keywords) {
        const newKeywords = keywords.map((keyword) => ({
          ...keyword,
          __typename: 'Keyword',
        }));
        updateCachedDocumentOrVersion({
          document,
          fieldToUpdate: 'keywords',
          value: newKeywords,
          version,
        });
      }
      setKeywordsBeingGenerated(false);
    } catch (error) {
      setKeywordsBeingGenerated(false);
      enqueueSnackbar(t('Could not generate keywords, please try again.'));
    }
  };

  const actions: ActionProps[] = [
    {
      ariaLabel: t('Edit keywords'),
      color: 'inherit',
      dataTestId: 'edit-keywords',
      disabled: !editable || !keywords || !keywords.length || isFinalized,
      key: 'edit-keywords',
      label: t('Edit keywords'),
      onClick: () => {
        parkKeywords(); // Freeze the current keywords state, so that we can use it to reset if user wants to cancel.
        setDisplayKeywords(false);
      },
      startIcon: 'edit',
    },
    {
      ariaLabel: t('Add keyword'),
      color: 'inherit',
      dataTestId: 'add-keyword',
      disabled: !editable || isFinalized,
      key: 'add-keyword',
      label: t('Add keyword'),
      onClick: () => searchParams.set({ action: 'addKeyword' }),
      startIcon: 'add',
    },
    {
      ariaLabel: t('Generate new'),
      color: 'inherit',
      dataTestId: 'generate-new-keywords',
      disabled: !editable || keywordsBeingGenerated || isFinalized,
      key: 'generate-new-keywords',
      label: t('Generate new'),
      onClick: onGenerateClick,
      startIcon: 'ai',
    },
  ];

  return { actions, keywordsBeingGenerated, onDelete, onEditClick };
};
