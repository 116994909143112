import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useGroupFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Name', // i18n
      name: 'displayName',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Members', // i18n
      name: 'members',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Subgroups', // i18n
      name: 'subgroups',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
  ];

  return useFormConfig({ formFields });
};
