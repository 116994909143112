import { FilterType } from '@pro4all/graphql';

export const filterKeys: Record<FilterType, string> = {
  [FilterType.CreatedBy]: `CreatedBy.displayName`,
  [FilterType.UpdatedBy]: `UpdatedBy.displayName`,
  [FilterType.UpdatedAt]: `UpdatedAt`,
  [FilterType.CreatedAt]: `CreatedAt`,
  [FilterType.Extension]: `Extension`,
  [FilterType.ParentFolderIds]: `parentFolderIds`,
  [FilterType.FolderId]: `FolderId`,
  [FilterType.State]: `State`,
  [FilterType.VersionState]: `VersionState`,
  [FilterType.Versions]: `Versions.qrCodeState`,
  [FilterType.Bool]: `Bool`,
  [FilterType.Number]: `Number`,
  [FilterType.Selection]: `Selection`,
  [FilterType.Status]: `Status`,
  [FilterType.DateTime]: `DateTime`,
  [FilterType.Text]: `Text`,
  [FilterType.Drawing]: `Drawing.name`,
  [FilterType.Template]: `Template.templateId`,
  [FilterType.TemplateId]: `TemplateId`,
  [FilterType.TemplateType]: `TemplateType`,
  [FilterType.ProjectNumber]: `ProjectNumber`,
  [FilterType.ProjectName]: `ProjectName`,
  [FilterType.Tasks]: `Tasks.status`, // returning number
  [FilterType.CreatedOn]: 'CreatedOn',
  [FilterType.IndivativeStatus]: 'IndivativeStatus',
  [FilterType.ReferenceNumber]: 'ReferenceNumber',
  [FilterType.UpdatedOn]: 'UpdatedOn',
  [FilterType.Snags]: 'Snags',
  [FilterType.Forms]: 'Forms',
  [FilterType.UserSelection]: 'UserSelection',
  [FilterType.Highlight]: 'Highlight',
  [FilterType.HierarchyList]: 'HierarchyList',
};
