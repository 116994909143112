import { useCallback, useEffect, useState } from 'react';

import { getMultiValue, useTranslateTbq } from '@pro4all/shared/qc-meta';
import { Option } from '@pro4all/shared/types';

type Value = Option | Option[] | string;

export const useHandleValueChangeSelects = ({
  multiSelect,
  name, // prop 'name' is the name of the form input which equals the input id.
  setValue,
  value,
}: {
  multiSelect: boolean;
  name: string;
  setValue: (name: string, value: Option | Option[]) => void;
  value: string;
}) => {
  const { translateTbq } = useTranslateTbq();
  const [currentValue, setCurrentValue] = useState<Value>(value);
  const [currentValueMulti, setCurrentValueMulti] = useState<Value>(value);

  const handleValueChange = useCallback(
    ({ name, value }: { name: string; value: Value }) => {
      if (multiSelect) {
        setCurrentValueMulti(value);
      } else {
        setCurrentValue(value);
      }
      const updatedValue =
        typeof value === 'string'
          ? multiSelect
            ? convertStringToMultiOption(value)
            : convertStringToOption(value)
          : value;

      setValue?.(name, updatedValue);
    },
    []
  );

  useEffect(() => {
    if (Array.isArray(value)) {
      handleValueChange({ name, value });
    } else if (multiSelect && !value) {
      handleValueChange({ name, value: [] });
    } else {
      try {
        // SavedAnswers have a multi values not stored anymore in a comma separated string.
        // So we need to convert that still to a comma separated string.
        // TODO: In the future we will only support the JSON stringified array.
        const possibleJsonValue = JSON.parse(value);
        if (Array.isArray(possibleJsonValue)) {
          handleValueChange({
            name,
            value: possibleJsonValue.join(','),
          });
        } else {
          handleValueChange({ name, value });
        }
      } catch (e) {
        handleValueChange({ name, value });
      }
    }
  }, [handleValueChange, multiSelect, name, value]);

  const handleChange = (value: Option) => {
    handleValueChange({ name, value });
  };

  const handleChangeMulti = (
    event: React.ChangeEvent | null,
    value: Option[]
  ) => {
    handleValueChange({ name, value });
  };

  const convertStringToOption = (value: string) =>
    ({
      id: value,
      label: translateTbq(value),
    } as Option);

  const convertStringToMultiOption = (value: string) =>
    value.length
      ? (getMultiValue(value).map((value) => ({
          id: value,
          label: translateTbq(value),
        })) as Option[])
      : [];

  // Initially `value` is passed as a string, convert it to `Option`.
  const currentValueOption =
    typeof currentValue === 'string'
      ? convertStringToOption(currentValue)
      : (currentValue as Option);

  // Initially `value` is passed as a string, convert it to `Option[]`.
  const currentValueMultiOption =
    typeof currentValueMulti === 'string'
      ? convertStringToMultiOption(currentValueMulti)
      : (currentValueMulti as Option[]) || ([] as Option[]);

  return {
    currentValueMultiOption,
    currentValueOption,
    handleChange,
    handleChangeMulti,
  };
};
