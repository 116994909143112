import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export interface ContainerProps {
  $direction?: 'horizontal' | 'vertical';
  $flexEnd: boolean;
  $overflow?: boolean;
}

export interface ItemProps {
  $direction: 'horizontal' | 'vertical';
  $marginRight?: number | never;
  index?: number | never;
}

export const Ul = styled(Box)<ContainerProps>`
  && {
    list-style: none;
    display: flex;
    justify-content: ${({ $flexEnd }) =>
      $flexEnd ? 'flex-end' : 'flex-start'};
    align-items: center;

    flex-direction: ${({ $direction }) =>
      $direction === 'horizontal' ? 'row' : 'column'};

    ${({ $direction }) =>
      $direction === 'horizontal' && '> li { display: inline-block; }'}

    ${({ $overflow }) =>
      $overflow &&
      `
    width: 100%;
  `}
  }
`;

export const Li = styled.li<ItemProps>`
  width: ${({ $direction }) =>
    $direction === 'horizontal' ? 'fit-content' : '100%'};
`;
