import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const MarkUnread: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M22 7.76853L12.5096 2.14095C12.1927 1.95302 11.8073 1.95302 11.4904 2.14095L2 7.76853V19.7599C2 20.9971 2.93965 22 4.09877 22H19.9012C21.0604 22 22 20.9971 22 19.7599V7.76853ZM18.7588 8.40919L12 4.40138L5.24122 8.40919L12 12.417L18.7588 8.40919ZM4.09877 10.2944V19.7599H19.9012L19.9012 10.2944L13.0193 14.3752C12.3854 14.7511 11.6146 14.7511 10.9807 14.3752L4.09877 10.2944Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
