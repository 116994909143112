import { useEffect, useMemo } from 'react';

import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsQCSearchRoute } from '@pro4all/shared/ui/filtering';

import { TFetchSearch } from './shared-search-types';
import {
  delimiters,
  RELOAD_FLAG,
  staticParamFilters,
  toFilterArray,
} from './shared-search-utils';

type TUseInitializeFilters = {
  fetch: TFetchSearch;
};

export const useInitializeFilters = ({ fetch }: TUseInitializeFilters) => {
  const { searchParams } = useRouting(true);
  const paramFilters = searchParams.get('filters');
  const paramQuery = searchParams.get('search');

  const isQCSearch = useIsQCSearchRoute();

  // Not considered static filters for QC search
  const staticFilters = useMemo(
    () => (!isQCSearch ? staticParamFilters : []),
    [isQCSearch]
  );
  const staticFiltersInitialized = staticFilters.every(
    (filterString) => paramFilters && paramFilters.includes(filterString)
  );

  const filters = toFilterArray(paramFilters);

  useEffect(() => {
    // Refetch data: any reload flag in params will trigger a fetch and then remove all reload flags
    if (paramFilters && paramFilters.includes(RELOAD_FLAG)) {
      const updatedParamFilters = paramFilters.replace(RELOAD_FLAG, '');
      const updatedCustomFilters = toFilterArray(updatedParamFilters);
      searchParams.replace('filters', updatedParamFilters);

      fetch({
        filters: updatedCustomFilters,
        query: paramQuery || '',
      });
    }

    // Initialize static filters in params (append or add)
    if (paramQuery !== null && !staticFiltersInitialized) {
      // Remove duplicate filters found in static filters

      const uniqueStaticFilters = staticFilters.filter(
        (standardFilter) =>
          !filters.find((filter) => filter.includes(standardFilter))
      );

      const nextParamValue = paramFilters
        ? `${uniqueStaticFilters}${delimiters.filters}${paramFilters}`
        : `${uniqueStaticFilters}`;
      searchParams.set('filters', `${nextParamValue}`);
    }
    return;
  }, [
    fetch,
    filters,
    staticFiltersInitialized,
    paramFilters,
    paramQuery,
    searchParams,

    staticFilters,
  ]);
};
