import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddFieldDefinition: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M8.422 17.45L1.132 4.49A.998.998 0 012.002 3h14.58c.76 0 1.25.82.87 1.49l-7.29 12.96a.997.997 0 01-1.74 0zM14.922 5H3.672l5.62 10 5.63-10z" />
    <path d="M14.416 18.105h2.25v2.25c0 .62.507 1.125 1.125 1.125.62 0 1.125-.506 1.125-1.125v-2.25h2.25c.62 0 1.125-.506 1.125-1.125 0-.618-.506-1.125-1.125-1.125h-2.25v-2.25c0-.618-.506-1.125-1.125-1.125-.618 0-1.125.507-1.125 1.125v2.25h-2.25c-.618 0-1.125.507-1.125 1.125 0 .62.507 1.125 1.125 1.125z" />
  </SvgIcon>
);
