import { Resolver } from 'react-hook-form';

import { ReferenceType } from '@pro4all/graphql';
import { isValidEmail } from '@pro4all/shared/utils';

import { MessageFormFields, RecipientField } from '../types';

export const resolver: Resolver<MessageFormFields> = async (values) => {
  const validateEmails = (recipients: RecipientField[]) =>
    recipients.every((recipient) => {
      try {
        if (recipient.type === ReferenceType.Email)
          return Boolean(isValidEmail(recipient.email));
        return true;
      } catch (e) {
        return false; // If there is an issue with regex, better safe than sorry that is not a valid email
      }
    });

  const valuesTo = values.to || [];
  const valuesCc = values.cc || [];
  const valuesBcc = values.bcc || [];
  //
  const toRequiredError =
    !valuesTo || !valuesTo.length
      ? {
          to: { type: 'required' },
        }
      : null;

  const patternToErrors = !validateEmails(valuesTo)
    ? {
        to: { message: 'Not a valid email', type: 'pattern' },
      }
    : null;
  const patternCcErrors = !validateEmails(valuesCc)
    ? {
        cc: { message: 'Not a valid email', type: 'pattern' },
      }
    : null;
  const patternBccErrors = !validateEmails(valuesBcc)
    ? {
        bcc: { message: 'Not a valid email', type: 'pattern' },
      }
    : null;
  let errors = {};
  if (toRequiredError) errors = { ...errors, ...toRequiredError };
  if (patternToErrors) errors = { ...errors, ...patternToErrors };
  if (patternCcErrors) errors = { ...errors, ...patternCcErrors };
  if (patternBccErrors) errors = { ...errors, ...patternBccErrors };

  return {
    errors,
    values,
  };
};
