import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
export const Settings: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    fill="none"
    height="20"
    viewBox="0 0 14 14"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.0015 7.00016C12.0015 7.22683 11.9815 7.44016 11.9548 7.6535L13.3615 8.7535C13.4881 8.8535 13.5215 9.0335 13.4415 9.18016L12.1081 11.4868C12.0281 11.6335 11.8548 11.6935 11.7015 11.6335L10.0415 10.9668C9.69481 11.2268 9.32148 11.4535 8.91481 11.6202L8.66148 13.3868C8.64148 13.5468 8.50148 13.6668 8.33481 13.6668H5.66815C5.50148 13.6668 5.36148 13.5468 5.34148 13.3868L5.08815 11.6202C4.68148 11.4535 4.30815 11.2335 3.96148 10.9668L2.30148 11.6335C2.15481 11.6868 1.97481 11.6335 1.89481 11.4868L0.56148 9.18016C0.48148 9.0335 0.514813 8.8535 0.64148 8.7535L2.04815 7.6535C2.02148 7.44016 2.00148 7.22016 2.00148 7.00016C2.00148 6.78016 2.02148 6.56016 2.04815 6.34683L0.64148 5.24683C0.514813 5.14683 0.474813 4.96683 0.56148 4.82016L1.89481 2.5135C1.97481 2.36683 2.14815 2.30683 2.30148 2.36683L3.96148 3.0335C4.30815 2.7735 4.68148 2.54683 5.08815 2.38016L5.34148 0.613496C5.36148 0.453496 5.50148 0.333496 5.66815 0.333496H8.33481C8.50148 0.333496 8.64148 0.453496 8.66148 0.613496L8.91481 2.38016C9.32148 2.54683 9.69481 2.76683 10.0415 3.0335L11.7015 2.36683C11.8481 2.3135 12.0281 2.36683 12.1081 2.5135L13.4415 4.82016C13.5215 4.96683 13.4881 5.14683 13.3615 5.24683L11.9548 6.34683C11.9815 6.56016 12.0015 6.7735 12.0015 7.00016ZM4.66815 7.00016C4.66815 8.28683 5.71481 9.3335 7.00148 9.3335C8.28815 9.3335 9.33481 8.28683 9.33481 7.00016C9.33481 5.7135 8.28815 4.66683 7.00148 4.66683C5.71481 4.66683 4.66815 5.7135 4.66815 7.00016Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </SvgIcon>
);
