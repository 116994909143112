import styled from 'styled-components';

export const KeywordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const ScoreWrapper = styled.div`
  width: 60px;
`;

export const DeleteIconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
  width: 30px;
`;
