import { DropzoneOptions, useDropzone } from 'react-dropzone';

export const usePublishUpload = ({
  onDrop,
}: {
  onDrop?: DropzoneOptions['onDrop'];
}) => {
  const {
    getInputProps,
    getRootProps,
    isDragActive,
    open: openFileInput,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return {
    getInputPropsPublish: getInputProps,
    getRootPropsPublish: getRootProps,
    isDragActivePublish: isDragActive,
    openFileInputPublish: openFileInput,
  };
};
