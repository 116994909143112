import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionNamingMapping } from '@pro4all/shared/label-config';
import {
  getFormattedDate,
  useOptimisticResponseContext,
  useSortColumn,
  useTableCheck,
  useTableContext,
} from '@pro4all/shared/ui/general';

// Define outside so we dont generate a billion workers that
// ultimately crash the page
let excelWorker: Worker;
let csvWorker: Worker;
if (typeof Worker !== 'undefined') {
  excelWorker = new Worker(new URL('./ExcelWorker.tsx', import.meta.url));
  csvWorker = new Worker(new URL('./CsvWorker.tsx', import.meta.url));
}

export const useExportTableData = () => {
  const { checkedRows } = useTableCheck();
  const { state } = useOptimisticResponseContext() || {};
  const { columns, id } = useTableContext();
  const { getSortedItems } = useSortColumn({ tableId: id }) || {};
  const { t } = useTranslation();
  /* Action naming mapping for filter */
  const getActionNamingMapping = useActionNamingMapping();

  const workersAvailable = useRef(false);

  if (typeof Worker !== 'undefined') {
    workersAvailable.current = true;
    if (excelWorker && csvWorker) {
      excelWorker.onmessage = (message: MessageEvent) => {
        handleWorkerMessage(message, 'xlsx');
      };
      csvWorker.onmessage = (message: MessageEvent) => {
        handleWorkerMessage(message, 'csv');
      };
    }
  }

  // We expect workers to return a dataUri in the message.data property.
  // This is passed in the postMessage(dataUri) of the respective worker
  const handleWorkerMessage = (message: MessageEvent, extension: string) => {
    const date = getFormattedDate(new Date(), 'MM-DD-YY hhmmss');
    const a = document.createElement('a');
    a.download = `prostream-export ${date?.label}.${extension}`;
    a.href = message.data;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  function accessProperty<T>(obj: T, accessor: string): any {
    const pathParts = accessor.split('.');
    let result: any = obj;

    for (const pathPart of pathParts) {
      if (typeof result === 'object' && result !== null) {
        result = result[pathPart];
      } else {
        return undefined;
      }
    }

    return result;
  }

  // If there are selected rows take these, else take the entire current (filtered) table contents.
  const exportItems = checkedRows?.length ? checkedRows : state?.items || [];

  // Sort the items as they are currently sorted in the table.
  const exportItemsSorted = getSortedItems({
    items: exportItems,
  });

  //  as unknown as Item[]

  // Filter out the columns that are excluded from export.
  const exportColumns = columns.filter((column) => !column.excludeFromExport);

  // Map through all items and collect the data to export according the table columns.
  const exportData = exportItemsSorted.map((item) => {
    const data = exportColumns.map((column) => {
      const value = column.getValue
        ? getActionNamingMapping(column.getValue(item) as string, true)
        : accessProperty(item, column.key as keyof typeof item);
      return value;
    });
    return data;
  });

  const headers = exportColumns.map((column) =>
    t(column.headerComponent?.props?.label)
  );
  const exportToExcel = () => {
    excelWorker.postMessage({
      headers,
      rows: exportData,
    });
  };

  const exportToCsv = () => {
    csvWorker.postMessage({
      headers,
      rows: exportData,
    });
  };

  return {
    count: exportItemsSorted.length,
    exportToCsv,
    exportToExcel,
    workersAvailable,
  };
};
