import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageTBQNoTasks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      tbq
      text={t('This question has not been linked to any task yet.')}
      title={t('No tasks')}
    />
  );
};
