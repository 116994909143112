import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

import { useOpenCdeUrlQuery } from '@pro4all/graphql';
import {
  useOpenCdeContext,
  useOpenCdeTracking,
} from '@pro4all/opencde/context';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { ActionProps, CdeActions } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useOpenCdeAction = ({
  allDocumentsUnfinalized,
  editableDocuments,
  isLocked,
  selection,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsUnfinalized'
  | 'editableDocuments'
  | 'isLocked'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const extension = selection[0]?.extension ?? '';

  const hasOpenCDEFeatureFlag = useFeatureFlag('open-cde');

  const { openCdeRequest } = useOpenCdeContext();
  const { organizationId } = useOrganizationContext();
  const { trackOpenCdeShareDocument } = useOpenCdeTracking();

  const { data } = useOpenCdeUrlQuery({});

  const getLabel = () =>
    t(`Open in {{application}}`, {
      application: openCdeRequest?.cdeApplication || '',
    });
  // Verify included extension (the one supported by or external client)
  const isIFCSelection =
    openCdeRequest?.supportedFileExtensions?.includes(extension);

  const openCdeAction: ActionProps = {
    ariaLabel: getLabel(),
    dataTestId: 'open-cde',
    disabled:
      editableDocuments.length === 0 ||
      !allDocumentsUnfinalized ||
      isLocked ||
      !isIFCSelection ||
      !openCdeRequest ||
      !hasOpenCDEFeatureFlag ||
      openCdeRequest.cdeSelect !== CdeActions.CdeSelect,
    key: 'open-cde',
    label: getLabel(),
    onClick: async () => {
      if (openCdeRequest?.cdeSelect !== CdeActions.CdeUpload) {
        const documents = selection.map((document) => ({
          documentId: document.id,
          folderId: document.folderId ?? '',
          id: document.versionId,
          organizationId: organizationId,
          projectId: document.projectId ?? '',
        }));

        const documentsBase64 = Buffer.from(JSON.stringify(documents)).toString(
          'base64'
        );
        trackOpenCdeShareDocument(
          documents.map((document) => document.id ?? ''),
          documents.map((document) => document.folderId ?? '')
        );
        window.open(
          `${openCdeRequest?.callbackUrl}?selected_documents_url=${data?.openCdeUrl}/documents/1.0/share-documents?state=${documentsBase64}`,
          '_self'
        );
      }
    },
    startIcon: 'solibriIcon',
  };

  return openCdeAction;
};
