import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TaskType,
  useGetOrganizationTaskCategoriesByTypeQuery,
  useGetProjectTaskCategoriesByTypeQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormikSearchableSelect } from '@pro4all/shared/ui/general';

import { useTaskFormConfig } from './useTaskFormConfig';

export const TaskCategorySelect = ({ taskType }: { taskType: TaskType }) => {
  const { t } = useTranslation();

  const { params } = useRouting();
  const projectId = params.projectId;

  const { data: orgCategoriesData } =
    useGetOrganizationTaskCategoriesByTypeQuery({
      variables: { taskType: taskType },
    });

  const orgCategories = orgCategoriesData?.getOrganizationTaskCategoriesByType;

  const { data: projCategoriesData } = useGetProjectTaskCategoriesByTypeQuery({
    skip: !projectId,
    variables: {
      projectId,
      taskType: taskType,
    },
  });

  const projCategories = projCategoriesData?.getProjectTaskCategoriesByType;

  const categories = orgCategories
    ? orgCategories.concat(projCategories ? projCategories : [])
    : [];

  const options = categories.map((category) => ({
    id: category?.taskCategoryId ? category?.taskCategoryId : '',
    label: category?.name ? category?.name : '',
  }));

  const { getField } = useTaskFormConfig();
  const categoryField = getField('category');

  return (
    <FormikSearchableSelect
      disabled={!options}
      id={categoryField ? categoryField.name : ''}
      label={categoryField ? categoryField.label : ''}
      name={categoryField ? categoryField.name : ''}
      options={options}
      placeholder={t('Select category')}
    />
  );
};
