import { SaveResponse } from '../../shared/types';
import { State } from '../types';

export const updatePermissionsAfterSaveAction = ({
  payload,
  state,
}: {
  payload: SaveResponse;
  state: State;
}) => {
  const {
    initialPermissionsDisplay,
    initialPermissionsExplicit,
    savePermissions,
  } = state;

  const { successful, unsuccessful } = payload || {};

  const initialPermissionsExcludingSuccessfullySaved =
    initialPermissionsDisplay.filter(
      (entity) => !successful.includes(entity.entityId)
    );

  const initialPermissionsExplicitExcludingSuccessfullySaved =
    initialPermissionsExplicit.filter(
      (entity) => !successful.includes(entity.entityId)
    );

  const savePermissionsSuccessfullySaved = savePermissions.filter((entity) =>
    successful.includes(entity.entityId)
  );
  const savePermissionsUnsuccessfullySaved = savePermissions.filter((entity) =>
    unsuccessful.includes(entity.entityId)
  );

  return {
    ...state,
    initialPermissionsDisplay: [
      // Replace the permissions in initialPermissionsDisplay that where modified.
      ...initialPermissionsExcludingSuccessfullySaved,
      ...savePermissionsSuccessfullySaved,
    ],
    initialPermissionsExplicit: [
      // Replace the permissions in initialPermissionsExplicit that where modified.
      ...initialPermissionsExplicitExcludingSuccessfullySaved,
      ...savePermissionsSuccessfullySaved,
    ],
    savePermissions: savePermissionsUnsuccessfullySaved,
  };
};
