import { FilterType } from '@pro4all/graphql';
import { staticFilterTypes } from '@pro4all/shared/search-utils';

import { filterIconNames, filterNames } from '../records';
import { excludedFilterTypes } from '../types';

const types = Object.values(FilterType);

export const extraTypes = types?.filter?.(
  (type) =>
    !staticFilterTypes?.includes?.(type) &&
    !excludedFilterTypes?.includes?.(type)
);

export const extraFilterOptions = extraTypes.map((filterType) => ({
  iconName: filterIconNames[filterType],
  id: filterType,
  label: filterNames[filterType],
}));
