import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDefinition } from '@pro4all/graphql';
import { TextField } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';

import { parseTimeSpanToMilliseconds } from '../helpers';

import { StopWatchModal } from './StopWatchModal';
import {
  StopWatchButtonWrapper,
  StopWatchContainer,
  StopWatchRow,
  StyledEditTextField,
  TimeFieldContainer,
} from './StopWatchModalUI.styles';
import { StopWatchRound } from './useStopWatch';

interface TimeSpanProps {
  item: FieldDefinition;
  name: string;
  setValue: (name: string, value: string) => void;
}
export const TimeSpan = ({ name, setValue, item }: TimeSpanProps) => {
  // prop 'name' is the name of the form input which equals the input id.
  const { t } = useTranslation();
  const [displayStopWatchModal, setDisplayStopWatchModal] = useState(false);

  //Store rounds data and final time in a state
  const [roundsData, setRoundsData] = useState<StopWatchRound[]>([]);

  useEffect(() => {
    try {
      const data = item.value ? JSON.parse(item.value) : [];
      if (Array.isArray(data)) {
        const finalTimeEntry = data.find((d) => d.label === 'Final Time');
        const roundEntries = data.filter((d) => d.label !== 'Final Time');

        roundEntries.sort(
          (a, b) =>
            parseTimeSpanToMilliseconds(b.time) -
            parseTimeSpanToMilliseconds(a.time)
        );

        setRoundsData(
          finalTimeEntry ? [finalTimeEntry, ...roundEntries] : roundEntries
        );
      } else {
        throw new Error('Parsed data is not an array');
      }
    } catch (error) {
      console.error('Error parsing item.value:', error);
    }
  }, [item.value]);

  const handleRoundNameChange = (roundId: string, newLabel: string) => {
    const updatedRounds = roundsData.map((round) =>
      round.id === roundId ? { ...round, label: newLabel } : round
    );

    setRoundsData(updatedRounds);

    //When saving the updated state, the time is already in the correct format
    const serializedValue = JSON.stringify(updatedRounds);
    setValue(name, serializedValue);
  };

  return (
    <>
      <StopWatchContainer>
        {roundsData.map((round) => (
          <StopWatchRow key={round.id}>
            <StyledEditTextField
              disabled={round.label === 'Final Time'}
              name={`roundName_${round.id}`}
              onChange={(event) =>
                handleRoundNameChange(round.id, event.target.value)
              }
              value={
                round.label === 'Final Time' ? t(round.label) : round.label
              }
              variant="outlined"
            />
            <TimeFieldContainer>
              <TextField
                InputProps={{ readOnly: true }}
                disabled
                name={`roundTime_${round.id}`}
                value={round.time}
                variant="outlined"
              />
            </TimeFieldContainer>
          </StopWatchRow>
        ))}
      </StopWatchContainer>
      <StopWatchButtonWrapper>
        <Button
          aria-label={t('Open stopwatch')}
          onClick={() => setDisplayStopWatchModal(true)}
          startIcon="timer"
        >
          {t('Open stopwatch')}
        </Button>
      </StopWatchButtonWrapper>
      <StopWatchModal
        item={item}
        name={name}
        onClose={() => setDisplayStopWatchModal(false)}
        open={displayStopWatchModal}
        setValue={(modalName, value) => {
          if (modalName === name) {
            const parsedValue = JSON.parse(value);
            setRoundsData(parsedValue);
            setValue(modalName, value);
          }
        }}
        updatedRounds={roundsData}
      />
    </>
  );
};
