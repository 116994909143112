import { Document, DocumentVersion, FieldDefinition } from '@pro4all/graphql';

export const getAnswer = (document: Document, field: FieldDefinition) =>
  document.metaData?.answers?.find(
    (answer) =>
      answer.fieldDefinitionId === field.fieldDefinitionId ||
      answer.fieldDefinitionId === field.id
  )?.value || '';

export const getAnswerForVersion = (
  version: DocumentVersion,
  field: FieldDefinition
) => {
  const versionToUse = version.versions.find(
    (nestedVersion) => version.id === nestedVersion.id
  );
  if (!versionToUse) return '';
  return (
    versionToUse.metaData?.answers?.find(
      (answer) =>
        answer.fieldDefinitionId === field.fieldDefinitionId ||
        answer.fieldDefinitionId === field.id
    )?.value || ''
  );
};
