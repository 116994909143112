import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Resizable } from 're-resizable';

import { TableColumnDataType } from '@pro4all/shared/types';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { useFilterContext } from '@pro4all/shared/ui/filtering';
import {
  Filter,
  FilterColumnIdProps,
  FilterColumnProps,
  FilterHeaderType,
} from '@pro4all/shared/ui/filtering';
import {
  BaseRow,
  useOptimisticResponseContext,
  useSortColumn,
  useSortColumnContext,
  useTableContext,
} from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import * as Styled from './FilterHeader.styled';
import { getColumnKey } from './helpers';
import { useGetOptions } from './useGetOptions';
import { useInitializeFromLocalStorage } from './useInitializeFromLocalStorage';
import { useOnReset } from './useOnReset';
import { useOnSet } from './useOnSet';
import { useResize } from './useResize';
import { useShowFilter } from './useShowFilter';
import { useValues } from './useValues';

export function FilterHeader<Row extends BaseRow, SubProp>({
  customCallbackId,
  defaultWidth,
  flattenOptions,
  getCustomValueCallback,
  filterType,
  iconName,
  isMetaData = false,
  isMultiSelect = false,
  label = '',
  metaDataHeaderId = '',
  minWidth,
  onColumnResizeCallback,
  propertyId,
  pullFromLocalStorage = false, // Just set a true value on the first table column for initially filling from filters stored in localStorage.
  showFilterIcon = true,
  subPropertyId,
  translateOptions = false,
}: Pick<
  FilterColumnProps,
  | 'customCallbackId'
  | 'defaultWidth'
  | 'flattenOptions'
  | 'filterType'
  | 'iconName'
  | 'isMetaData'
  | 'isMultiSelect'
  | 'label'
  | 'metaDataHeaderId'
  | 'minWidth'
  | 'onColumnResizeCallback'
  | 'pullFromLocalStorage'
  | 'showFilterIcon'
  | 'translateOptions'
> &
  Pick<
    FilterColumnIdProps<Row, SubProp>,
    'propertyId' | 'subPropertyId' | 'getCustomValueCallback'
  >) {
  const { t } = useTranslation();

  const initialRendering = useRef(true);

  const {
    triggerColumnSort,
    state: { items },
  } = useOptimisticResponseContext<Row>();

  const { id, idFilteringSorting } = useTableContext();
  const { storeColumnSortingInLocalStorage } = useSortColumn<Row>({
    tableId: idFilteringSorting || id,
  });
  const { setCustomCallback } = useSortColumnContext<Row>();

  const { filters, removeFilter } = useFilterContext<Row>();

  const { openFilter, position, setShowFilter, showFilter } = useShowFilter();

  const { onResize, onResizeStop } = useResize<Row, SubProp>({
    customCallbackId,
    defaultWidth,
    metaDataHeaderId,
    minWidth,
    onColumnResizeCallback,
    propertyId,
    subPropertyId,
  });

  // All values in the column, that can be used to present the user a list of options to select from.
  const { columnValues, getOptionCount } = useValues<Row, SubProp>({
    flattenOptions,
    getCustomValueCallback,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    propertyId,
    subPropertyId,
    translateOptions,
  });

  // Callback that will be triggered every time user selects/removes a value to filter on.
  const { onSet } = useOnSet<Row, SubProp>({
    filterType,
    flattenOptions,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    onColumnResizeCallback,
    propertyId,
    subPropertyId,
    translateOptions,
  });

  // Callback that will be triggered every time user resets all filters for a single column.
  const onReset = useOnReset<Row, SubProp>({
    metaDataHeaderId,
    onColumnResizeCallback,
    propertyId,
    subPropertyId,
  });

  const { keyPropertyId, keySubPropertyId } = getColumnKey<Row, SubProp>({
    metaDataHeaderId,
    propertyId,
    subPropertyId,
  });

  // Callback to get the current options for this column.
  const getOptions = useGetOptions<Row>({
    flattenOptions,
    propertyId: keyPropertyId,
    subPropertyId: keySubPropertyId,
  });

  // Re-active filters in case user left the table with activated filters and filter the table automatically.
  useInitializeFromLocalStorage<Row, SubProp>({
    filterType,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    propertyId,
    pullFromLocalStorage,
    subPropertyId,
    translateOptions,
  });

  const filter =
    filters && subPropertyId
      ? filters.find(
          (filter) =>
            filter.propertyId === keyPropertyId &&
            filter.subPropertyId === keySubPropertyId
        )
      : filters &&
        filters.find((filter) => filter.propertyId === keyPropertyId);
  const hasActiveFilter = Boolean(filter && filter?.filterValues.length);
  const count = filter?.filterValues.length;

  // Because we cannot take a custom callback from localStorage, we have to store these custom callbacks dynamically in a context.
  // Then later on if we need such a custom callback to sort values for a table column, we pull the callback out this context via a tableId/key-combination.
  if (getCustomValueCallback) {
    setCustomCallback({
      customCallback: getCustomValueCallback,
      key: propertyId.toString(),
      tableId: idFilteringSorting || id,
    });
    // Because of synchronous rendering the sorting of the table is already done.
    // However in case sort data is taken from LocalStorage, the callback was missing during intital sorting.
    // If we re-populate the items list, the sorting will be done again in the useEffect that takes care of sorting in TableContext.
    // React does not like this, it throws a warning: Cannot update a component (`OptimisticResponseProvider`) while rendering a different component (`FilterHeader`).
    // However this is the only known way to have the sorting done correctly on initial rendering for columns that take their value from a custom callback.
    // We tried to wrap this entire if-construction in a useEffect, but this did not work. Then for tables with a custom callback initial filtering is not triggered.
    if (initialRendering.current) {
      triggerColumnSort(items);
      initialRendering.current = false;
    }
  }

  const dataType =
    filterType === (FilterHeaderType.Number || FilterHeaderType.SelectNumber)
      ? TableColumnDataType.NUMERIC
      : TableColumnDataType.TEXT;

  return (
    <>
      <Styled.ResizeWrapper hasActiveFilter={hasActiveFilter}>
        <Resizable
          enable={{ right: true }}
          onResize={(e, direction, ref, d) => {
            onResize(d.width);
          }}
          onResizeStop={() => onResizeStop()}
          size={{ height: '100%', width: '100%' }}
        >
          <Styled.Wrapper className="WrapperClass">
            <Styled.Label
              hasActiveFilter={hasActiveFilter}
              onClick={() => {
                storeColumnSortingInLocalStorage({
                  dataType,
                  items,
                  metaDataHeaderId,
                  propertyId: propertyId.toString(),
                  subPropertyId: subPropertyId?.toString(),
                });
              }}
            >
              {iconName ? <Icon iconName={iconName} /> : t(label)}
            </Styled.Label>
            {hasActiveFilter && (
              <>
                <Styled.Counter>{count}</Styled.Counter>
                <Styled.Icon
                  className="IconClass RemoveFilterClass"
                  hasActiveFilter={hasActiveFilter}
                  onClick={() =>
                    removeFilter({
                      onColumnResizeCallback,
                      propertyId: keyPropertyId,
                      subPropertyId: keySubPropertyId,
                    })
                  }
                >
                  <Tooltip placement="bottom" title={t('Reset filter')}>
                    <IconButton
                      color="inherit"
                      disableBorder
                      iconName="reset"
                    />
                  </Tooltip>
                </Styled.Icon>
              </>
            )}
            {showFilterIcon && (
              <Styled.Icon
                className="IconClass OpenFilterClass"
                hasActiveFilter={hasActiveFilter}
                onClick={openFilter}
              >
                <Tooltip placement="bottom" title={t('Show filter options')}>
                  <IconButton
                    color="inherit"
                    disableBorder
                    iconName="filterList"
                  />
                </Tooltip>
              </Styled.Icon>
            )}
          </Styled.Wrapper>
        </Resizable>
      </Styled.ResizeWrapper>
      <Filter
        columnValues={columnValues}
        filterType={filterType}
        getOptionCount={getOptionCount}
        getOptions={getOptions}
        onReset={onReset}
        onSet={onSet}
        position={position}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
    </>
  );
}
