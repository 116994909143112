import React, { useState } from 'react';
import { NumberSize, Resizable as ResizableComponent } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';

import { useResizeWindow } from './useResizeWindow';

export const Resizable: React.FC<{
  initialWidth?: number;
  onResize?: (width: number) => void;
  onResizeEnd?: (width: number) => void;
  threshold: number;
}> = ({ children, threshold, onResize, onResizeEnd, initialWidth = 300 }) => {
  // If user resizes the window, width will be recalculated and this component re-renders.
  // This is necessary because if the 960px border is crossed,
  // the <Resizable/> component should or should not be rendered.
  const width = useResizeWindow();

  const [currentPanelWidth, setCurrentPanelWidth] =
    useState<number>(initialWidth);

  const handleOnResize = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    elementRef: HTMLElement,
    delta: NumberSize
  ) => {
    onResize && onResize(currentPanelWidth + delta.width);
  };

  const handleOnResizeStop = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    elementRef: HTMLElement,
    delta: NumberSize
  ) => {
    setCurrentPanelWidth(currentPanelWidth + delta.width);
    onResizeEnd && onResizeEnd(currentPanelWidth);
  };

  return width >= threshold ? (
    <ResizableComponent
      enable={{ right: true }}
      onResize={handleOnResize}
      onResizeStop={handleOnResizeStop}
      size={{ height: '100%', width: currentPanelWidth }}
    >
      {children}
    </ResizableComponent>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
