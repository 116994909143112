import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useTBQTaskFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Title', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Description', // i18n
      name: 'description',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Deadline', // i18n
      name: 'endTime',
      type: ValueTypeName.DateTime,
    },
    {
      displayName: 'Participant', // i18n
      name: 'participant',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Type', // i18n
      name: 'tbqScanTypeId',
      type: ValueTypeName.Selection,
    },
  ];

  return useFormConfig({ formFields });
};
