import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoOrganizationUsers: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="collaborate"
      text={t(
        'Invite users from your organization to collaborate in Prostream.'
      )}
      title={t("Your organization doesn't have any users yet")}
    />
  );
};
