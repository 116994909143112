import React from 'react';

import { StyledAttachmentInput } from './Attachment.styles';

export const AttachmentInput: React.FC<JSX.IntrinsicElements['input']> = ({
  onClick,
  ref,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = '';
    onClick && onClick(e);
  };

  return (
    <StyledAttachmentInput
      {...props}
      onClick={handleClick}
      ref={ref as React.MutableRefObject<HTMLInputElement>}
    />
  );
};
