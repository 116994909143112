import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  OrganizationUsersIncludeDocument,
  useActivateUsersMutation,
  User,
  UserState,
} from '@pro4all/graphql';
import {
  useOptimisticResponseContext,
  useTableCheck,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

import { organizationUsersIncludeProps } from '../getIncludeProps';

export const useActivateUsers = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activateUsers] = useActivateUsersMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<User>();
  const { contextMenuRow } = useTableContextMenuRowContext<User>();

  const {
    state: { items },
  } = useOptimisticResponseContext<User>();

  return async () => {
    try {
      const userIds = contextMenuRow
        ? [contextMenuRow.id]
        : checkedRows.map((user) => user.id);

      await activateUsers({
        variables: { userIds },
      });

      const { length } = checkedRows;

      const usersUpdated = items
        .filter((item) => userIds.includes(item.id))
        .map((item) => ({ ...item, active: true, state: UserState.Active }));

      const itemsRest = items.filter((item) => !userIds.includes(item.id));

      client.writeQuery({
        data: {
          organizationUsers: [...itemsRest, ...usersUpdated],
        },
        query: OrganizationUsersIncludeDocument,
        variables: {
          ...organizationUsersIncludeProps,
        },
      });

      if (length > 1) {
        enqueueSnackbar(
          t('{{count}} persons have been activated', {
            count: length,
          })
        );
      } else {
        const user = checkedRows?.[0] ?? contextMenuRow;
        const message = (
          <ItemChangedMessage
            description={MessageAction.Activated}
            entityName={user.displayName}
            entityTypeTranslation={EntityTypeTranslation.User}
          />
        );
        enqueueSnackbar(message);
      }

      uncheckAllRows();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
