import React from 'react';
import styled from 'styled-components';

import { Box, BoxProps } from '@pro4all/shared/mui-wrappers';

const StyledWrapper = styled(Box)`
  && {
    height: 100%;
  }
`;

export const Content = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }
`;

export const Wrapper = (props: BoxProps) => (
  <StyledWrapper pt={1} px={3} {...props} />
);
