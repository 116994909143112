import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { InstanceValues } from '@pro4all/shared/types';

export function isMixedSectionsAndFields(
  unnestedSectionFields: FieldDefinition[],
  unnestedFieldsExcludeSections: FieldDefinition[]
): boolean {
  const level1Sections = unnestedSectionFields.filter(
    (item) => !item.parentSectionId
  );
  const level1Fields = unnestedFieldsExcludeSections.filter(
    (item) => !item.parentSectionId
  );
  return level1Sections.length > 0 && level1Fields.length > 0;
}

export const filterFieldsByType = (
  fields: FieldDefinition[],
  excludeOorzaak = false
): FieldDefinition[] =>
  fields.filter(
    (field) =>
      field.type === ValueTypeName.Selection &&
      (!excludeOorzaak || field.displayName !== 'Oorzaak')
  );

export const calculatePercentage = (achieved: number, total: number): string =>
  total > 0 ? `${Math.round((achieved / total) * 100)}%` : '0%';

export const isFieldAnswered = (
  field: FieldDefinition,
  formikUpdatedValues: InstanceValues
): boolean => {
  if (!formikUpdatedValues) {
    return false;
  }
  const updatedValue = formikUpdatedValues[field.id];
  const isValueArray = Array.isArray(updatedValue);
  const isValueNonEmptyString = !isValueArray && updatedValue !== '';
  const isValueBoolean = typeof updatedValue === 'boolean';
  const isValueNonEmptyArray =
    isValueArray && (updatedValue as unknown as InstanceValues[]).length > 0;
  const isBoolField = field.type === ValueTypeName.Bool;
  const isSelectionField = field.type === ValueTypeName.Selection;

  if (
    (isBoolField && updatedValue) ||
    (isValueBoolean && isBoolField && isValueNonEmptyString) ||
    isValueNonEmptyArray ||
    (isValueNonEmptyArray && !isSelectionField) ||
    (!isValueArray && updatedValue && !isBoolField)
  ) {
    return true;
  } else {
    return false;
  }
};
