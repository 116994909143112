import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

import { MoveExistingUserProps } from './types';

export const MoveInvitationExpired = ({
  newOrganizationName,
}: Pick<MoveExistingUserProps, 'newOrganizationName'>) => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({ isInviteExistingUser: true });

  const description = (
    <div>
      <Trans
        components={{ strong: <strong /> }}
        defaults="The request to move to organization <strong>{{newOrganizationName}}</strong> has been expired. If you still want to join this organization, ask the one that is responsible to send the invitation again."
        values={{
          newOrganizationName,
        }}
      />
    </div>
  );

  return (
    <InfoPage
      actions={[actionLogin]}
      description={description}
      title={t('Move invitation expired')}
      type="error"
    />
  );
};
