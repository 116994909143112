export enum Application {
  pro4all = 'pro4all',
  tbq = 'tbq',
}

export function useApplication(): Application {
  if (window.location.origin.includes('tbq')) {
    return Application.tbq;
  } else {
    return Application.pro4all;
  }
}
