import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useDocumentTaskFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Title', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Description', // i18n
      name: 'description',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Status', // i18n
      name: 'nextStatus',
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'Date', // i18n
      name: 'endTime',
      type: ValueTypeName.DateTime,
    },

    {
      displayName: 'Participant', // i18n
      name: 'participant',
      type: ValueTypeName.Selection,
    },

    {
      displayName: 'Document action', // i18n
      name: 'documentAction',
      required: true,
      type: ValueTypeName.Selection,
    },
  ];

  return useFormConfig({ formFields });
};
