import { useDomainStatusQuery } from '@pro4all/graphql';

export const useDomainStatus = (organizationId: string) => {
  const response = useDomainStatusQuery({
    fetchPolicy: 'cache-and-network',
    variables: { organizationId },
  });

  return response.data?.domainStatus;
};
