import React from 'react';
import styled from 'styled-components';

import { useOrganizationContext } from '@pro4all/organization/context';
import { Breadcrumbs as MuiBreadcrumbs } from '@pro4all/shared/mui-wrappers';
import { Box, CircularProgress } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

const StyledIcon = styled(Icon)`
  && {
    margin: 0 -0.5em;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

interface Props {
  projectName?: string;
}

export const Breadcrumbs: React.FC<Props> = ({ projectName }) => {
  const { meData } = useOrganizationContext();

  return !meData ? (
    <CircularProgress />
  ) : (
    <Box alignItems="center" display="flex">
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        separator={<StyledIcon iconName="chevronRightRounded" />}
      >
        {projectName && <Text variant="h4">{projectName}</Text>}
      </MuiBreadcrumbs>
    </Box>
  );
};
