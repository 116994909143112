import React, { useEffect, useRef, useState } from 'react';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';

type Props = {
  children: React.ReactNode;
};

export const RenderWhenVisible: React.FC<Props> = ({ children }) => {
  const enabled = useFeatureFlag('render-when-visible');
  if (!enabled) return <>{children}</>;
  else {
    return <FeaturedRenderWhenVisible>{children}</FeaturedRenderWhenVisible>;
  }
};

const FeaturedRenderWhenVisible: React.FC<Props> = ({ children }) => {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (targetRef) {
      // Nested hide component doesnt work. Therefore use this component as virtual LazyLoad
      const newHeight = targetRef?.current?.clientHeight;
      if (newHeight > height) {
        setHeight(newHeight);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting === true && !isVisible) {
          setIsVisible(entry.isIntersecting);
        }
      },
      {
        root: null, // viewport
        rootMargin: '0px', // no margin
        threshold: 0, // 100% of target visible
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Clean up the observer
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div ref={targetRef}>
        {/*the !isVisible <div></div> part is not working when having nested components*/}
        {isVisible ? children : <div style={{ height: height }}></div>}
      </div>
    </div>
  );
};

export default RenderWhenVisible;
