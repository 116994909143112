import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Folder } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  FormFooter,
  FormikCheckbox,
  FormikForm,
  FormikSearchableSelect,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useFolderMetaDataFormConfig } from './useFolderMetaDataFormConfig';
import { useInitialValues } from './useInitialValues';
import { useSubmit } from './useSubmit';

export interface FolderMetaDataFormProps {
  folder?: Folder;
  onClose: () => void;
}

export const FolderMetaDataForm: React.FC<FolderMetaDataFormProps> = ({
  folder,
  onClose,
}) => {
  const { t } = useTranslation();
  const onSubmit = useSubmit({ folder, onClose });
  const { initialValues, templateOptions } = useInitialValues(folder);
  const { getField, validationSchema } = useFolderMetaDataFormConfig();
  const selectedTemplateField = getField('selectedTemplate');
  const applyToSubfoldersField = getField('applyToSubfolders');

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting }) => (
        <FormikForm>
          <FormWrapper>
            <Box>
              <Text variant="h6">{folder?.name}</Text>
            </Box>
            <Text paragraph variant="body1">
              {t(
                'Select a template to apply to this folder. The change will be also be applied to subfolders.'
              )}
            </Text>
            <FormikSearchableSelect
              id={selectedTemplateField.name}
              label={selectedTemplateField.label}
              name={selectedTemplateField.name}
              options={templateOptions}
            />
            <FormikCheckbox
              label={applyToSubfoldersField.label}
              name={applyToSubfoldersField.name}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
