import { Reference, ReferenceType } from '@pro4all/graphql';

export const toUserInfo = (reference: Reference) => {
  if (reference?.referenceType === ReferenceType.GroupSnapshot)
    try {
      const destructuredValue: { groupId: string } = JSON.parse(
        reference?.referenceValue || ''
      );
      return {
        email: destructuredValue?.groupId || '',
        id: destructuredValue?.groupId || '',
        type: ReferenceType.Group,
      };
    } catch (e) {
      console.error(e);
      return undefined;
    }
  return {
    email: reference?.referenceData || '',
    id: reference?.referenceValue || '',
    type: reference?.referenceType || ReferenceType.User,
  };
};
