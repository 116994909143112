import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Editor, Transforms } from 'slate';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { EditCommentFooter, Left, Right } from './Styles';
import { CommentProps, useThreadContext } from './ThreadContext';
import { useDelete } from './useDelete';

export interface InnerFooterProps extends CommentProps {
  editMode?: boolean;
  editor: Editor;
}

export const InnerFooter: React.FC<InnerFooterProps> = ({
  editMode,
  editor,
  fromId,
  messageId,
  resolved,
  referenceText,
  threadId,
  updatedAt,
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { enqueueSnackbar } = useSnackbar();

  const { userId } = useOrganizationContext();

  const context = useThreadContext();
  if (!context) throw Error('Comments context not initialized.');
  const { threadResolved } = context;

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const { deleteComment } = useDelete({
    messageId,
    onError: () => enqueueSnackbar(t('Something went wrong')),
    onSuccess: () => enqueueSnackbar(t('Comment deleted')),
    targetId: searchParams.get('id') || '',
    threadId,
  });

  const handleEdit = () => {
    searchParams.set({ editComment: messageId });
    /* Move cursor to end of content */
    setTimeout(() => {
      Transforms.select(editor, Editor.end(editor, []));
    });
  };

  const handleDelete = async () => {
    setShowDeletePrompt(false);
    await deleteComment();
  };

  if (!fromId || !messageId || editMode) return null;

  return (
    <EditCommentFooter>
      <Dialog
        buttonColor={customColors['errorAlert']}
        confirmLabel={t('Delete')}
        iconName="warning"
        name="forgeDialog"
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={handleDelete}
        open={showDeletePrompt}
        title={t('Are you sure?')}
      >
        <Text display="inline">
          {t(
            'You are about to delete your comment, this action can’t be undone.'
          )}
        </Text>
      </Dialog>
      <Left>
        {fromId === userId && resolved && (
          <>
            {messageId && (
              <IconButton
                color="default"
                disableBorder
                iconName="edit"
                onClick={handleEdit}
              />
            )}
            <IconButton
              color="default"
              disableBorder
              disabled={!threadResolved}
              iconName="delete"
              onClick={() => setShowDeletePrompt(true)}
            />
          </>
        )}
      </Left>
      <Right>
        {Boolean(updatedAt) && <span>{t('Edited')}</span>}
        {referenceText && <span>{referenceText}</span>}
      </Right>
    </EditCommentFooter>
  );
};
