import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateDocumentOrVersion } from '@pro4all/documents/ui/utils';
import {
  DocumentKeyword,
  Keyword,
  useMutateDocumentKeywordsMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DocumentAndVersionType } from '@pro4all/shared/types';

import { useDocumentKeywordsContext } from './DocumentKeywordsProvider';
import { DocumentKeyword as DocumentKeywordState } from './documentKeywordsReducer';
import { KeywordFormValues } from './types';

export const useOnSubmitSingleKeyword = ({
  document,
  handleClose,
  version,
  versionId,
}: {
  handleClose: () => void;
  versionId: string;
} & DocumentAndVersionType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [mutateDocumentKeywords] = useMutateDocumentKeywordsMutation();
  const updateCachedDocumentOrVersion = useUpdateDocumentOrVersion();

  const { searchParams } = useRouting();
  const keywordId = searchParams.get('keywordId');
  const isAdding = searchParams.is('action', 'addKeyword');
  const isEditing = searchParams.is('action', 'editKeyword');

  const {
    state: { keywords },
  } = useDocumentKeywordsContext();

  const updateApolloCache = (newKeywords: Keyword[]) => {
    updateCachedDocumentOrVersion({
      document,
      fieldToUpdate: 'keywords',
      value: newKeywords,
      version,
    });
  };

  const onSubmit = async (values: KeywordFormValues) => {
    const { score, text } = values;

    let keywordMutation: DocumentKeyword[] = [];
    let keywordUpdateClientCache: Keyword[] = [];

    const editedKeyword = keywords.find(
      (keyword) => keyword.id === keywordId
    ) as DocumentKeywordState;

    if (isAdding) {
      keywordMutation = [
        {
          newScore: parseInt(score, 10),
          newText: text,
          oldScore: 0,
          oldText: '',
        },
      ];

      keywordUpdateClientCache = [
        {
          score: parseInt(score, 10),
          text,
        },
      ];
    } else if (isEditing) {
      keywordMutation = [
        {
          newScore: editedKeyword.score,
          newText: text,
          oldText: editedKeyword.oldText,
        },
      ];

      keywordUpdateClientCache = [
        {
          __typename: 'Keyword',
          score: editedKeyword.score,
          text,
        },
      ];
    }

    try {
      await mutateDocumentKeywords({
        variables: { keywords: keywordMutation, versionId },
      });
      if (isEditing) {
        const newKeywords = [
          ...(keywords
            .filter((keyword) => keyword.text !== editedKeyword.text)
            .map(({ score, text }) => ({
              __typename: 'Keyword',
              score,
              text,
            })) as Keyword[]),
          ...keywordUpdateClientCache,
        ];
        updateApolloCache(newKeywords);
      } else if (isAdding) {
        // Check if the keyword is already in the list.
        const existingKeyword = keywords.find(
          (keyword) => keyword.text === text
        );
        if (existingKeyword) {
          const newKeywords = [
            ...(keywords
              .filter((keyword) => keyword.text !== existingKeyword.text)
              .map(({ score, text }) => ({
                __typename: 'Keyword',
                score,
                text,
              })) as Keyword[]),
            ...keywordUpdateClientCache,
          ];
          updateApolloCache(newKeywords);
        } else {
          const newKeywords = [
            ...(keywords.map(({ score, text }) => ({
              __typename: 'Keyword',
              score,
              text,
            })) as Keyword[]),
            ...keywordUpdateClientCache,
          ];
          updateApolloCache(newKeywords);
        }
      }
      handleClose();
    } catch (error) {
      enqueueSnackbar(t('Could not add keyword, please try again.'));
    }
  };

  return onSubmit;
};
