import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DomainToClaim } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Typography } from '@pro4all/shared/mui-wrappers';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

import { OrganizationProvisioningTable } from './OrganizationProvisioningTable';
import { useDomainStatus } from './useDomainStatus';

const StyledBox = styled(Box)`
  padding: 5px 15px;
`;

export const OrganizationProvisioning = () => {
  const { t } = useTranslation();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const domainsToClaimRaw = useDomainStatus(organizationId);

  const verifiedDomains = useMemo(
    () =>
      domainsToClaimRaw
        ? domainsToClaimRaw.filter(
            (domainToClaimRaw: DomainToClaim) => domainToClaimRaw.status === 1
          )
        : [],
    [domainsToClaimRaw]
  );

  return (
    <StyledBox>
      <Text color="inherit" variant="h5">
        {t('Provisioning')}
      </Text>
      <Typography>
        {verifiedDomains.length > 0
          ? t(
              'To setup SCIM in your identity provider, you will need to enter the tenant-url and a secret token, which you can generate per each domain. (IMPORTANT) Please copy the token after generating it, because it wont be saved. You can only have one token per each domain, so if you need a new token, you will have to revoke your existing one.'
            )
          : t('Verified domains will be shown here to finish the SCIM setup.')}
      </Typography>

      {verifiedDomains.length > 0 && (
        <OrganizationProvisioningTable domainsToProvision={verifiedDomains} />
      )}
    </StyledBox>
  );
};
