import { FieldDefinition } from '@pro4all/graphql';
import { excludedTypes } from '@pro4all/quality-control/ui/shared';

export const getExcludedItemIds = (items: FieldDefinition[]) => {
  const excludedItemIds: string[] = [];

  // Helper function to recursively traverse items
  const traverseItems = (items: FieldDefinition[]) => {
    for (const item of items) {
      if (item.type && excludedTypes.includes(item.type)) {
        excludedItemIds.push(item.id);
      }
      // Check if the item has valueType.subFields (nested items)
      if (
        item.valueType &&
        item.valueType.subFields &&
        item.valueType.subFields.length > 0
      ) {
        traverseItems(item.valueType.subFields);
      }
    }
  };

  // Start traversal from the top level items array
  traverseItems(items);

  return excludedItemIds;
};
