import { useTranslation } from 'react-i18next';

import { Instance } from '@pro4all/graphql';
import {
  AdvancedFilterValue,
  FilterColumnIdProps,
  FilterColumnProps,
  FilterHeaderType,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import {
  BaseRow,
  useOptimisticResponseContext,
  useSortColumnContext,
  useTableContext,
} from '@pro4all/shared/ui/general';

import { NO_VALUE } from './constants';
import { getColumnKey, isPropFilled } from './helpers';
import {
  includeDate,
  isExactMatch,
  isFilterValueIncluded,
} from './onSetHelpers';

export function useOnSet<Row extends BaseRow, SubProp>({
  flattenOptions,
  filterType,
  isMetaData = false,
  isMultiSelect = false,
  metaDataHeaderId = '',
  onColumnResizeCallback,
  propertyId,
  subPropertyId,
  translateOptions,
}: Pick<
  FilterColumnProps,
  | 'flattenOptions'
  | 'filterType'
  | 'isMetaData'
  | 'isMultiSelect'
  | 'metaDataHeaderId'
  | 'onColumnResizeCallback'
  | 'translateOptions'
> &
  Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId' | 'subPropertyId'>) {
  const { t } = useTranslation();
  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Row>();

  const { setSelectedOptions } = useFilterContext<Row>();

  const { getCustomCallback } = useSortColumnContext<Row>();
  const { id: tableId } = useTableContext();

  const getFilteredItems = ({
    flattenOptions,
    filterType,
    filterValues,
    isMetaDataLocalStorage,
    metaDataHeaderIdLocalStorage,
    isMultiSelectLocalStorage,
    propertyId,
    subPropertyId,
    translateOptionsLocalStorage,
  }: Pick<
    FilterColumnProps,
    | 'flattenOptions'
    | 'filterType'
    | 'filterValues'
    | 'isMetaDataLocalStorage'
    | 'metaDataHeaderIdLocalStorage'
    | 'isMultiSelectLocalStorage'
    | 'translateOptionsLocalStorage'
  > &
    Pick<
      FilterColumnIdProps<Row, SubProp>,
      'propertyId' | 'subPropertyId'
    >) => {
    const itemsToMap = itemsInitial.length ? itemsInitial : items;

    const customCallback = getCustomCallback({
      key: propertyId.toString(),
      tableId,
    });

    const isMetaDataFinal = isMetaDataLocalStorage
      ? isMetaDataLocalStorage
      : isMetaData;
    const multiSelectFinal = isMultiSelectLocalStorage
      ? isMultiSelectLocalStorage
      : isMultiSelect;
    const metaDataHeaderIdFinal = metaDataHeaderIdLocalStorage
      ? metaDataHeaderIdLocalStorage
      : metaDataHeaderId;

    const partialMatch = filterType === FilterHeaderType.Text;
    const isDate = filterType === FilterHeaderType.Date;

    // Filter items based on selected filter values for this column.
    let itemsFiltered = itemsToMap;
    if (filterValues.length) {
      if (subPropertyId) {
        if (isMetaDataFinal) {
          // Filtering meta data column.
          itemsFiltered = itemsToMap.filter((item) => {
            const objectValues = item[propertyId] as unknown as SubProp;
            if (!objectValues) {
              // In case there is no meta data instance for this item.
              return isExactMatch({ columnValue: NO_VALUE, filterValues });
            } else {
              const value = objectValues[subPropertyId] as unknown as string;
              const instances = value as unknown as Instance[];
              const instance = instances.find(
                (instance) =>
                  instance.fieldDefinitionId === metaDataHeaderIdFinal
              );
              if (!instance) {
                return isExactMatch({ columnValue: NO_VALUE, filterValues });
              } else {
                if (isDate) {
                  // Date type, so special treatment, we have to compare dates with the current data.
                  return includeDate({
                    columnValue: instance.value || '',
                    filterValues,
                  });
                } else {
                  // Other type than 'Date'.
                  if (multiSelectFinal) {
                    if (instance.value) {
                      const valuesSplitted = instance.value.split(',');
                      return filterValues.some((filterValue) =>
                        isFilterValueIncluded({
                          columnValue: valuesSplitted,
                          filterValue,
                        })
                      );
                    } else {
                      return isExactMatch({
                        columnValue: NO_VALUE,
                        filterValues,
                      });
                    }
                  } else {
                    if (partialMatch) {
                      // If the value to search for is part of the column value, return the item.
                      return filterValues.some((filterValue) =>
                        instance.value
                          ? isFilterValueIncluded({
                              columnValue: instance.value,
                              filterValue,
                            })
                          : false
                      );
                    } else {
                      // Value to search for must be equal to the column value (apart from case sensitivity).
                      return instance.value
                        ? isExactMatch({
                            columnValue: instance.value,
                            filterValues,
                          })
                        : isExactMatch({ columnValue: NO_VALUE, filterValues });
                    }
                  }
                }
              }
            }
          });
        } else {
          // Filtering on subProperty value for a normal column.
          itemsFiltered = itemsToMap.filter((item) => {
            const objectValues = item[propertyId] as unknown as SubProp;
            const subValue = objectValues
              ? (objectValues[subPropertyId] as unknown as string)
              : NO_VALUE;

            if (isDate) {
              // Date type, so special treatment, we have to compare dates with the current data.
              return includeDate({
                columnValue: subValue,
                filterValues,
              });
            } else {
              // Other type than 'Date'.
              if (partialMatch) {
                // If the value to search for is part of the column value, return the item.
                return filterValues.some((filterValue) =>
                  isFilterValueIncluded({
                    columnValue: subValue,
                    filterValue,
                  })
                );
              } else {
                // Value to search for must be equal to the column value (apart from case sensitivity).
                return isExactMatch({
                  columnValue: translateOptionsLocalStorage
                    ? t(subValue)
                    : subValue,
                  filterValues,
                });
              }
            }
          });
        }
      } else {
        // Filtering on property value for a normal column.
        itemsFiltered = itemsToMap.filter((item) => {
          const value = customCallback
            ? customCallback(item[propertyId])
            : isPropFilled<Row, SubProp>({ item, propertyId })
            ? (item[propertyId] as unknown as string)
            : NO_VALUE;

          if (isDate) {
            // Date type, so special treatment, we have to compare dates with the current data.
            return includeDate({
              columnValue: value.toString(),
              filterValues,
            });
          } else {
            // Other type than 'Date'.
            if (partialMatch || flattenOptions) {
              // If the value to search for is part of the column value, return the item.
              return filterValues.some((filterValue) =>
                isFilterValueIncluded({
                  columnValue: value,
                  filterValue,
                })
              );
            } else {
              // Value to search for must be equal to the column value (apart from case sensitivity).
              return isExactMatch({
                columnValue: translateOptionsLocalStorage
                  ? t(value.toString())
                  : value.toString(),
                filterValues,
              });
            }
          }
        });
      }
    }

    return itemsFiltered;
  };

  const onSet = (filterValues: string[] | AdvancedFilterValue[]) => {
    // Filter items based on selected filter values for this column.
    const itemsFiltered = getFilteredItems({
      filterType,
      filterValues,
      flattenOptions,
      propertyId,
      subPropertyId,
      translateOptionsLocalStorage: translateOptions,
    });

    const { keyPropertyId, keySubPropertyId } = getColumnKey<Row, SubProp>({
      metaDataHeaderId,
      propertyId,
      subPropertyId,
    });

    setSelectedOptions({
      filterType,
      filterValues,
      isMultiSelect,
      itemsFiltered,
      onColumnResizeCallback,
      propertyId: keyPropertyId,
      subPropertyId: keySubPropertyId,
      translateOptions,
    });
  };

  return { getFilteredItems, onSet };
}
