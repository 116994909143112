export const EditSnag = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13.75C0.7875 13.75 0.609375 13.6781 0.465625 13.5344C0.321875 13.3906 0.25 13.2125 0.25 13V11.1812C0.25 10.9812 0.2875 10.7906 0.3625 10.6094C0.4375 10.4281 0.54375 10.2688 0.68125 10.1313L10.15 0.68125C10.3 0.54375 10.4656 0.4375 10.6469 0.3625C10.8281 0.2875 11.0188 0.25 11.2188 0.25C11.4187 0.25 11.6125 0.2875 11.8 0.3625C11.9875 0.4375 12.15 0.55 12.2875 0.7L13.3188 1.75C13.4688 1.8875 13.5781 2.05 13.6469 2.2375C13.7156 2.425 13.75 2.6125 13.75 2.8C13.75 3 13.7156 3.19062 13.6469 3.37187C13.5781 3.55312 13.4688 3.71875 13.3188 3.86875L3.86875 13.3188C3.73125 13.4563 3.57188 13.5625 3.39062 13.6375C3.20937 13.7125 3.01875 13.75 2.81875 13.75H1ZM11.2 3.85L12.25 2.8L11.2 1.75L10.15 2.8L11.2 3.85Z"
      fill="black"
    />
  </svg>
);
