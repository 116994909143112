import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageMetaDataNoQualityControlFormTemplates: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Create a template within seconds and make quality control a walk in the park.'
      )}
      title={t("You don't have any form templates yet")}
    />
  );
};
