import { Dispatch, SetStateAction } from 'react';

import {
  GetNotificationsQuery,
  NotificationScope,
  ScheduledTime,
  WeekDay,
} from '@pro4all/graphql';
import { defaultTime } from '@pro4all/shared/ui/general';

export type NotificationsSettingsFields = {
  notificationDays: WeekDay[];
  notificationTimes: ScheduledTime[];
};

export type NotificationsSetttingsModalProps = {
  dataQuery?: GetNotificationsQuery;
  isModalOpen: boolean;
  scope: NotificationScope;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const defaultValues: NotificationsSettingsFields = {
  notificationDays: [],
  notificationTimes: [defaultTime],
};

export const notificationTimeError = {
  notificationTimes: {
    message: 'Different times need to be configured', // i18n
  },
};

export const notificationDaysError = {
  notificationDays: {
    message: 'Select at least one day', // i18n
    minLength: 1,
    type: 'min',
  },
};

export const formValidator = {
  checkDuplicatedTimes: (values: NotificationsSettingsFields) =>
    values?.notificationTimes.reduce((_acc, curr, _index, arr) => {
      const duplicates = arr.filter(
        (item) => item.hour === curr.hour && item.minute === curr.minute
      );

      if (duplicates.length > 1) return true;
      return false;
    }, false),
  checkSelectedNotificationDays: (values: NotificationsSettingsFields) =>
    (values?.notificationDays || []).length > 0,
  resolver: async function (values: NotificationsSettingsFields) {
    const errors = {};

    const hasSelectedNotificationDays =
      this.checkSelectedNotificationDays(values);
    const hasDuplicatedTimes = this.checkDuplicatedTimes(values);

    if (!hasSelectedNotificationDays)
      Object.assign(errors, notificationDaysError);
    if (hasDuplicatedTimes) Object.assign(errors, notificationTimeError);

    return {
      errors,
      values,
    };
  },
};
