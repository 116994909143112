import { useCallback, useEffect, useState } from 'react';

import { useTableContext } from '../TableContext';
import { BaseRow } from '../types';

export function useTableKeyboardControl<Row extends BaseRow>({
  enableKeyboardControl,
  onRowClick,
  selectedId,
}: {
  enableKeyboardControl: boolean;
  onRowClick?: (row: Row) => void;
  selectedId: string;
}) {
  const { rows } = useTableContext<Row>();

  const [currentIndex, setCurrentIndex] = useState<number | undefined>();

  useEffect(() => {
    const index = rows.findIndex((row) => row.id === selectedId);
    setCurrentIndex(index);
  }, [rows, selectedId]);

  const selectRow = useCallback(
    (index: number) => {
      const selectedRow = rows[index];
      if (selectedRow) {
        onRowClick && onRowClick(selectedRow);
      }
    },
    [rows, onRowClick]
  );

  const moveNext = useCallback(() => {
    if (currentIndex === null || currentIndex === undefined) {
      selectRow(0);
    } else if (currentIndex < rows.length - 1) {
      selectRow(currentIndex + 1);
    }
  }, [currentIndex, rows, selectRow]);

  const movePrevious = useCallback(() => {
    if (currentIndex && currentIndex > 0) {
      selectRow(currentIndex - 1);
    }
  }, [currentIndex, selectRow]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (window.document.activeElement instanceof HTMLInputElement) return;
      if (event.key === 'ArrowDown') moveNext();
      if (event.key === 'ArrowUp') movePrevious();
    },
    [moveNext, movePrevious]
  );

  useEffect(() => {
    if (enableKeyboardControl) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [enableKeyboardControl, moveNext, movePrevious, onKeyDown]);

  return currentIndex;
}
