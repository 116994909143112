import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useRouting } from '@pro4all/shared/routing-utils';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { useDocumentKeywordsContext } from './DocumentKeywordsProvider';
import { KeywordForm } from './KeywordForm';
import { KeywordFormValues } from './types';
import { useKeywordModalConfig } from './useKeywordModalConfig';
import { useOnSubmitSingleKeyword } from './useOnSubmitSingleKeyword';

export const KeywordModal = ({
  document,
  version,
  versionId,
}: {
  versionId: string;
} & DocumentAndVersionType) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const isAdding = searchParams.is('action', 'addKeyword');
  const isEditing = searchParams.is('action', 'editKeyword');
  const keywordId = searchParams.get('keywordId');

  const {
    state: { keywords },
  } = useDocumentKeywordsContext();

  const handleClose = (resetForm?: () => void) => {
    resetForm && resetForm();
    searchParams.delete('action');
    searchParams.delete('keywordId');
  };

  const onSubmit = useOnSubmitSingleKeyword({
    document,
    handleClose,
    version,
    versionId,
  });

  const editedKeyword = isEditing
    ? keywords.find((keyword) => keyword.id === keywordId)
    : null;

  const initialValues: KeywordFormValues = {
    // Score is not displayed but still needed for BE acceptance and sorting of keywords, we give new keywords a score of 100 so they are added to the top of the list.
    score: isAdding ? '100' : editedKeyword?.score.toString() || '',
    text: isAdding ? '' : editedKeyword?.text || '',
  };

  const { validationSchema } = useKeywordModalConfig();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ resetForm }) => (
        <FloatingModal
          data-testid="document-keyword-modal"
          maxWidth="sm"
          onClose={() => handleClose(resetForm)}
          open={isAdding || isEditing}
        >
          <FloatingModal.Header>
            {isAdding ? t('Add keyword') : t('Edit keyword')}
          </FloatingModal.Header>
          <KeywordForm onClose={() => handleClose(resetForm)} />
        </FloatingModal>
      )}
    </Formik>
  );
};
