import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import {
  ConversionStatus,
  Document,
  DocumentVersion,
  useConvertTo3DMutation,
  useSupportedFormatsQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { ActionProps } from '@pro4all/shared/types';

import { excludedFormats } from './extensions';
import { use3DDocuments } from './use3DDocuments';

interface Props {
  activatePolling?: boolean;
  selected: Document[] | DocumentVersion[];
  versionIds: string[];
}

// Fetches the conversion state,
// Returns the correct threeDMenuItemProps
export function use3DConversion({
  activatePolling,
  selected,
  versionIds,
}: Props) {
  const { data } = useSupportedFormatsQuery({ fetchPolicy: 'cache-first' });
  const supportedFormats = data?.supportedFormats ?? [];

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const updateCachedDocuments = useUpdateCachedDocuments();

  const isSupportedFormat = (extension: string) =>
    supportedFormats
      .filter(
        (supported) =>
          !excludedFormats.find((excluded) => excluded === supported)
      )
      ?.includes((extension || '').replace('.', ''));

  const is3D = selected?.some(({ extension }) =>
    isSupportedFormat(extension || '')
  );

  const [convertTo3D] = useConvertTo3DMutation();

  const multipleSelected = selected.length > 1;

  const [queuedVersions, setQueuedVersions] = useState<string[]>([]);

  const {
    conversionStatus,
    loading,
    refetch,
    startPolling,
    stopPolling,
    urns,
    view3D,
  } = use3DDocuments({
    activatePolling,
    is3D,
    versionIds,
  });

  const conversionIsQueued = Boolean(
    queuedVersions.find((queuedVersion) => queuedVersion === versionIds[0])
  );

  const startConversion = async () => {
    if (versionIds[0]) setQueuedVersions([...queuedVersions, versionIds[0]]);
    await convertTo3D({ variables: { versionId: versionIds[0] } });
    updateCachedDocuments({
      documentIds: [versionIds[0]],
      fieldToUpdate: 'threeDConversionState',
      keyField: 'versionId',
      value: { status: ConversionStatus.Uploading },
    });
    stopPolling();
    refetch({ versionIds: [versionIds[0]] });
    startPolling(10000 * ApiConfig.pollEnabled);
    enqueueSnackbar(t('3D conversion started. This may take several minutes.'));
  };

  const threeDActions: {
    [key in ConversionStatus]: ActionProps;
  } = {
    [ConversionStatus.Failed]: {
      ariaLabel: 'Retry',
      label: t('Try again with another version'),
      startIcon: 'rotate',
    },
    [ConversionStatus.Uploading]: {
      ariaLabel: '3D conversion in progress',
      label: t('3D conversion in progress'),
      startIcon: 'sync',
    },
    [ConversionStatus.Inprogress]: {
      ariaLabel: '3D conversion in progress',
      label: t('3D conversion in progress'),
      startIcon: 'sync',
    },
    [ConversionStatus.Pending]: {
      ariaLabel: '3D conversion in progress',
      label: t('3D conversion in progress'),
      startIcon: 'sync',
    },
    [ConversionStatus.NotStarted]: {
      ariaLabel: 'Convert to 3D',
      disabled: conversionIsQueued || multipleSelected,
      label: t('Convert to 3D'),
      onClick: () => startConversion(),
      startIcon: 'rotate',
    },
    [ConversionStatus.Success]: {
      ariaLabel: 'View 3D model',
      disabled: conversionIsQueued,
      label: t('View 3D model'),
      onClick: () => view3D(),
      startIcon: 'cube',
    },
  };

  const { ...rest } =
    threeDActions[conversionStatus || ConversionStatus.Success];

  const threeDAction: ActionProps = useMemo(
    () => ({
      ...rest,
      dataTestId: `3d-menu-item-${conversionStatus}`,
      key: `3d-menu-item-${conversionStatus}`,
    }),
    [conversionStatus, rest]
  );

  // Provide feedback and remove from queue when conversion has finished
  useEffect(() => {
    if (conversionIsQueued && versionIds[0]) {
      const filteredVersions = queuedVersions.filter(
        (queuedVersion) => queuedVersion !== versionIds[0]
      );
      if (conversionStatus === ConversionStatus.Failed) {
        stopPolling();
        enqueueSnackbar(
          t('3D conversion failed. Try again with another version.')
        );
        setQueuedVersions(filteredVersions);
      }
      if (conversionStatus === ConversionStatus.Success) {
        stopPolling();
        updateCachedDocuments({
          documentIds: [versionIds[0]],
          fieldToUpdate: 'threeDConversionState',
          keyField: 'versionId',
          value: { status: ConversionStatus.Success },
        });
        enqueueSnackbar(t('3D conversion completed'));
        setQueuedVersions(filteredVersions);
      }
    }
  }, [conversionStatus, conversionIsQueued]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideAction = loading || !is3D;

  return {
    refetch,
    threeDAction: hideAction ? null : threeDAction,
    urns,
  };
}
