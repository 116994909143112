import { FolderPermission } from '@pro4all/graphql';

import { Permission } from '../types';

import { getThisFolderPermission } from './getThisFolderPermission';

export const isParentEnabled = ({
  displayPermissions,
  parentFolderId,
  permission,
}: {
  displayPermissions: Permission[];
  parentFolderId: string;
  permission: FolderPermission;
}) => {
  if (!parentFolderId) return false;
  const parentFolder = getThisFolderPermission(
    parentFolderId,
    displayPermissions
  );
  return parentFolder.folderPermissions.includes(permission);
};
