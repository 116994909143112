import React, { Reducer, useCallback, useContext, useReducer } from 'react';

import {
  Action,
  ActionType,
  DocumentKeyword,
  documentKeywordsReducer,
  State,
} from './documentKeywordsReducer';

type ContextType = {
  fillInitialKeywords: (keywords: DocumentKeyword[]) => void;
  parkKeywords: () => void;
  resetKeywords: () => void;
  state: State;
};

const DocumentKeywordsContext = React.createContext(null);

export function useDocumentKeywordsContext() {
  return useContext<ContextType>(DocumentKeywordsContext);
}

export function DocumentKeywordsProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [state, dispatch] = useReducer(
    documentKeywordsReducer as Reducer<State, Action>,
    {
      keywords: [],
      keywordsForReset: [],
    }
  );
  // Define all actions
  const fillInitialKeywords = useCallback((keywords: DocumentKeyword[]) => {
    dispatch({
      payload: keywords || [],
      type: ActionType.FILL_INITIAL_KEYWORDS,
    });
  }, []);
  const parkKeywords = useCallback(() => {
    dispatch({
      type: ActionType.PARK_KEYWORDS,
    });
  }, []);
  const resetKeywords = useCallback(() => {
    dispatch({
      type: ActionType.RESET_KEYWORDS,
    });
  }, []);

  return (
    <DocumentKeywordsContext.Provider
      value={{
        fillInitialKeywords,
        parkKeywords,
        resetKeywords,
        state,
      }}
    >
      {children}
    </DocumentKeywordsContext.Provider>
  );
}
