import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const UpdateFolderPermissions: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM19.3544 10.5206C19.5494 10.3256 19.5494 10.0106 19.3544 9.81561L18.1844 8.64561C18.091 8.55198 17.9642 8.49937 17.8319 8.49937C17.6997 8.49937 17.5728 8.55198 17.4794 8.64561L16.5644 9.56061L18.4394 11.4356L19.3544 10.5206ZM10.4994 15.7307V17.2507C10.4994 17.3907 10.6094 17.5007 10.7494 17.5007H12.2694C12.3344 17.5007 12.3994 17.4757 12.4444 17.4257L17.9044 11.9707L16.0294 10.0957L10.5744 15.5507C10.5244 15.6007 10.4994 15.6607 10.4994 15.7307Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
