import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Form: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M16 2.5H11.82C11.4 1.34 10.3 0.5 9 0.5C7.7 0.5 6.6 1.34 6.18 2.5H2C0.9 2.5 0 3.4 0 4.5V20.5C0 21.6 0.9 22.5 2 22.5H16C17.1 22.5 18 21.6 18 20.5V4.5C18 3.4 17.1 2.5 16 2.5ZM9 2.5C9.55 2.5 10 2.95 10 3.5C10 4.05 9.55 4.5 9 4.5C8.45 4.5 8 4.05 8 3.5C8 2.95 8.45 2.5 9 2.5ZM2 19.5C2 20.05 2.45 20.5 3 20.5H15C15.55 20.5 16 20.05 16 19.5V5.5C16 4.95 15.55 4.5 15 4.5H14V5.5C14 6.6 13.1 7.5 12 7.5H6C4.9 7.5 4 6.6 4 5.5V4.5H3C2.45 4.5 2 4.95 2 5.5V19.5Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
