import { v4 as uuid } from 'uuid';

import { Routes, TrackingEvent } from '@pro4all/shared/config';
import { useSideNavContext } from '@pro4all/shared/contexts';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';
import { MenuItem, MenuSubItem } from './types';

interface Props {
  menuItems: MenuItem[];
}

export const NavigationItems = ({ menuItems }: Props) => {
  const { setSideNavVisibleOnMobile } = useSideNavContext();
  const { track } = useAnalytics();
  const {
    params: { projectId },
  } = useRouting();
  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  const SideNavItem = ({ item }: { item: MenuItem | MenuSubItem }) => (
    <NavItem
      count={item.messageCount}
      dataTestId={item.dataTestId}
      iconName={'icon' in item ? item.icon : null}
      label={item.displayName}
      onClick={() => {
        updateLsLastVisitedProjectRoute({
          projectId,
          route: item.route,
        });
        track(TrackingEvent.NavigationSelected, item.trackPayload);
        setSideNavVisibleOnMobile(false);
      }}
      to={
        projectId
          ? generateRoute(item.route, {
              params: { projectId },
            })
          : Routes[item.route as keyof typeof Routes]
      }
    />
  );

  return (
    <>
      {menuItems.map((item) => {
        const { condition } = item;
        return condition === undefined || condition === true ? (
          (item?.subItems?.length ?? 0) > 0 ? (
            <NavGroup
              count={isSideNavOpen ? item.messageCount : undefined}
              dataTestId={item.dataTestId}
              iconName={item.icon}
              id={item.id}
              key={uuid()}
              label={item.displayName}
              route={item.route}
              to={
                projectId
                  ? generateRoute(item.route, {
                      params: { projectId },
                    })
                  : Routes[item.route as keyof typeof Routes]
              }
            >
              {isSideNavOpen &&
                item.subItems?.map((subItem) => {
                  const { condition } = subItem;
                  return condition === undefined || condition === true ? (
                    <SideNavItem item={subItem} key={uuid()} />
                  ) : null;
                })}
            </NavGroup>
          ) : (
            <SideNavItem item={item} key={uuid()} />
          )
        ) : null;
      })}
    </>
  );
};
