import { FolderPermission } from '@pro4all/graphql';

import { OptionValue } from '../../types/types';

/*
If user selects a permission from a dropdown we have to remove the previous selected permission
in that dropdown. This map contains the permissions that should be removed based on the selected permission.
*/
export const permissionsToRemove = new Map<string, FolderPermission[]>([
  // Documents dropdown
  [
    OptionValue.None,
    [
      FolderPermission.CreateContent,
      FolderPermission.DeleteContent,
      FolderPermission.DeleteOwnContent,
      FolderPermission.ReadAllDocuments,
      FolderPermission.ReadAllVersions,
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastApproval,
      FolderPermission.ReadLastVersion,
      FolderPermission.ReadLastVersionAndApproved,
      FolderPermission.ReadOwn,
      FolderPermission.ReadOwnAndFinalized,
      FolderPermission.ReadOwnVersions,
      FolderPermission.UpdateContent,
      FolderPermission.UpdateOwn,
    ],
  ],
  [
    OptionValue.ReadAllDocuments,
    [FolderPermission.ReadOwnAndFinalized, FolderPermission.ReadOwn],
  ],
  [
    OptionValue.ReadOwn,
    [
      FolderPermission.FinalizeContent,
      FolderPermission.ReadAllDocuments,
      FolderPermission.ReadOwnAndFinalized,
    ],
  ],
  [
    OptionValue.ReadOwnAndFinalized,
    [
      FolderPermission.FinalizeContent,
      FolderPermission.ReadAllDocuments,
      FolderPermission.ReadOwn,
    ],
  ],

  // Versions dropdown
  [
    OptionValue.ReadAllVersions,
    [
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastApproval,
      FolderPermission.ReadLastVersion,
      FolderPermission.ReadLastVersionAndApproved,
      FolderPermission.ReadOwnVersions,
    ],
  ],
  [
    OptionValue.ReadApproval,
    [
      FolderPermission.ReadAllVersions,
      FolderPermission.ReadLastApproval,
      FolderPermission.ReadLastVersion,
      FolderPermission.ReadLastVersionAndApproved,
      FolderPermission.ReadOwnVersions,
      FolderPermission.ApproveContent,
    ],
  ],
  [
    OptionValue.ReadLastApproval,
    [
      FolderPermission.ReadAllVersions,
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastVersion,
      FolderPermission.ReadLastVersionAndApproved,
      FolderPermission.ReadOwnVersions,
      FolderPermission.ApproveContent,
    ],
  ],
  [
    OptionValue.ReadLastVersion,
    [
      FolderPermission.ReadAllVersions,
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastApproval,
      FolderPermission.ReadLastVersionAndApproved,
      FolderPermission.ReadOwnVersions,
    ],
  ],
  [
    OptionValue.ReadLastVersionAndApproved,
    [
      FolderPermission.ReadAllVersions,
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastApproval,
      FolderPermission.ReadLastVersion,
      FolderPermission.ReadOwnVersions,
      FolderPermission.ApproveContent,
    ],
  ],
  [
    OptionValue.ReadOwnVersions,
    [
      FolderPermission.ReadAllVersions,
      FolderPermission.ReadApproval,
      FolderPermission.ReadLastApproval,
      FolderPermission.ReadLastVersion,
      FolderPermission.ReadLastVersionAndApproved,
    ],
  ],

  // Delete dropdown
  [OptionValue.DeleteContent, [FolderPermission.DeleteOwnContent]],
  [OptionValue.DeleteOwnContent, [FolderPermission.DeleteContent]],
  [
    OptionValue.DeleteNone,
    [FolderPermission.DeleteContent, FolderPermission.DeleteOwnContent],
  ],

  // Update dropdown
  [OptionValue.UpdateContent, [FolderPermission.UpdateOwn]],
  [OptionValue.UpdateOwn, [FolderPermission.UpdateContent]],
  [
    OptionValue.UpdateNone,
    [FolderPermission.UpdateContent, FolderPermission.UpdateOwn],
  ],
]);
