import { useEffect, useRef } from 'react';

export type MoveEvent = PointerEvent | React.PointerEvent;

interface Point {
  dx: number;
  dy: number;
  x: number;
  y: number;
}

export function useMove(
  callback: (point: Point) => void,
  relativeTo?: HTMLElement | null
) {
  const initialPosition = useRef({ x: 0, y: 0 });
  const target = useRef<HTMLElement | null>(null);

  const getPosition = (event: MoveEvent) => ({
    x: event.clientX,
    y: event.clientY,
  });

  const move = (event: MoveEvent) => {
    // We don't want the user to accidentally select stuff while dragging
    event.preventDefault();

    const { x, y } = getPosition(event);

    const dx = x - initialPosition.current.x;
    const dy = y - initialPosition.current.y;

    initialPosition.current = { x, y };

    if (relativeTo) {
      const { x: rectX, y: rectY } = relativeTo.getBoundingClientRect();
      callback({ dx, dy, x: x - rectX, y: y - rectY });
    } else {
      callback({ dx, dy, x, y });
    }
  };

  const moveEnd = () => {
    target.current?.setAttribute('aria-grabbed', 'false');
    target.current = null;

    window.removeEventListener('pointermove', move);
    window.removeEventListener('pointerup', moveEnd);
  };

  const moveStart = (event: MoveEvent) => {
    // We don't want the user to accidentally select stuff while dragging
    event.preventDefault();
    event.stopPropagation();

    initialPosition.current = getPosition(event);

    target.current = event.currentTarget as HTMLElement;
    target.current?.setAttribute('aria-grabbed', 'true');

    window.addEventListener('pointermove', move);
    window.addEventListener('pointerup', moveEnd);
  };

  useEffect(
    () => () => {
      target.current?.setAttribute('aria-grabbed', 'false');
      target.current = null;

      window.removeEventListener('pointermove', move);
      window.removeEventListener('pointerup', moveEnd);
    },
    // Only run cleanup function whenever the component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { onPointerDown: moveStart };
}
