import { useTranslation } from 'react-i18next';

import { FormFieldConfig } from './useValidation';

export const useLabels = (formFields: FormFieldConfig[]) => {
  const { t } = useTranslation();

  const addLabels = (formFields?: FormFieldConfig[]) =>
    formFields?.map((formField) => {
      const translatedLabel = t(formField.displayName || '');
      return {
        ...formField,
        displayName: translatedLabel,
        label: formField.required ? `* ${translatedLabel}` : translatedLabel,
      };
    }) || [];

  return { formFieldsWithLabels: addLabels(formFields) };
};
