import { useState } from 'react';
import { ExpandLess, ExpandMore, MoreHoriz } from '@mui/icons-material';

import { Collapse, List } from '@pro4all/shared/mui-wrappers';
import {
  ContextMenu,
  OFFSET_X,
  OFFSET_Y,
  Position,
} from '@pro4all/shared/ui/context-menu';
import { MiddleEllipsis, StyledIcon } from '@pro4all/shared/ui/general';

import { ViewerNode } from '../../viewers/Viewer.types';

import {
  LeafNodeIndent,
  NodeCollapseButtonContainer,
  NodeListItem,
  NodeRowButton,
} from './EntityTree.styles';
import { LevelIndent } from './Levelndent';
import { useContextMenuActions } from './useContextMenuActions';

export const EntityTreeNode: React.FC<{
  activeNodes: ViewerNode[];
  level: number;
  node: ViewerNode;
  onFocus: (nodes: ViewerNode[]) => void;
  onHide: (nodes: ViewerNode[]) => void;
  onIsolate: (nodes: ViewerNode[]) => void;
  onSelect: (nodes: ViewerNode[]) => void;
  onShowAll: () => void;
}> = ({
  activeNodes,
  level,
  onHide,
  onIsolate,
  onFocus,
  onSelect,
  onShowAll,
  node,
}) => {
  const [open, setOpen] = useState<boolean>(level < 3 ? true : false);
  const [timeoutId, setTimeoutId] = useState<number | undefined>();
  const [subMenuPosition, setSubMenuPosition] = useState<Position | undefined>(
    undefined
  );

  const isSelected = () =>
    activeNodes.find(
      (activeNode) =>
        activeNode.model.id === node.model.id && activeNode.id === node.id
    )
      ? true
      : false;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClick = (event: any) => {
    let parentNode = event.target;
    let breakFound = false;
    while (!breakFound && parentNode.parentNode) {
      if (parentNode.nodeName === 'svg' || parentNode.nodeName === 'path') {
        breakFound = true;
      }
      parentNode = parentNode.parentNode;
    }
    if (!breakFound) {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
        setTimeoutId(undefined);
        setOpen(!open);
      } else {
        setTimeoutId(
          window.setTimeout(() => {
            setTimeoutId(undefined);
            onSelect([node]);
          }, 200)
        );
      }
    }
  };

  const handleOnHide = () => onHide([node]);
  const handleOnIsolate = () => onIsolate([node]);
  const handleOnFocus = () => onFocus([node]);
  const handleOnShowAll = () => onShowAll();

  const subMenuActions = useContextMenuActions({
    onFocus: handleOnFocus,
    onHide: handleOnHide,
    onIsolate: handleOnIsolate,
    onShowAll: handleOnShowAll,
  });

  const openSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSubMenuPosition({
      left: event.clientX + OFFSET_X,
      top: event.clientY + OFFSET_Y,
    });
  };

  const closeSubMenu = () => {
    setSubMenuPosition(undefined);
  };

  return (
    <div>
      <NodeListItem
        disableGutters
        key={`${node.model.id}-${node.id}`}
        onClick={handleClick}
        onContextMenu={(e) => openSubMenu(e)}
        selected={isSelected()}
      >
        <LevelIndent indent={level}></LevelIndent>
        {node.children.length > 0 ? (
          <NodeCollapseButtonContainer>
            <NodeRowButton aria-label="toggle" onClick={toggleOpen}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </NodeRowButton>
          </NodeCollapseButtonContainer>
        ) : (
          level > 0 && <LeafNodeIndent></LeafNodeIndent>
        )}
        <StyledIcon color="inherit" iconName="cube" />
        <MiddleEllipsis endLength={5} text={node.name} />
        <NodeRowButton onClick={(e) => openSubMenu(e)} value={node.id}>
          <MoreHoriz />
        </NodeRowButton>
        <ContextMenu
          initialPosition={subMenuPosition}
          menuItems={subMenuActions}
          onClose={closeSubMenu}
          open={Boolean(subMenuPosition)}
        />
      </NodeListItem>
      {node.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {node.children.map((value) => (
              <EntityTreeNode
                activeNodes={activeNodes}
                key={`${value.model.id}-${value.id}`}
                level={level + 1}
                node={value}
                onFocus={onFocus}
                onHide={onHide}
                onIsolate={onIsolate}
                onSelect={onSelect}
                onShowAll={onShowAll}
              ></EntityTreeNode>
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
};
