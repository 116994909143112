import React from 'react';
import { useTranslation } from 'react-i18next';

import { useVisualContextsQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

import { MultiSelectList } from './MultiSelectList';
import { useTaskFormConfig } from './useTaskFormConfig';

interface Props {
  id: string;
  projectId: string;
  tooltip: string;
}

export const VisualContextSelect: React.FC<Props> = ({
  id,
  tooltip,
  projectId,
}) => {
  const { t } = useTranslation();

  const { data, loading } = useVisualContextsQuery({
    pollInterval: 5000 * ApiConfig.pollEnabled,
    variables: {
      projectId,
    },
  });

  const options = (data?.visualContexts || []).filter(Boolean).map((vc) => ({
    id: vc.id,
    label: vc.name,
  }));

  const { getField } = useTaskFormConfig();
  const idField = getField(id);

  return (
    <MultiSelectList
      iconName="map"
      id={id}
      label={idField.label}
      loading={!data?.visualContexts || loading}
      noOptionsText={t('Add drawings first to add them to a task')}
      options={options}
      placeholder={t('Search drawings...')}
      tooltip={tooltip}
    />
  );
};
