export const groupIncludeProps = {
  includeMembers: true,
  includeSubgroups: true,
};

export const groupsIncludeProps = {
  includeCreatedAt: true,
  includeCreatedBy: true,
  includeMembersCount: true,
  includeSubgroupIds: true,
};
