import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ValueTypeName } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  FormFieldConfig,
  SchemaExtender,
  useFormConfig,
} from '@pro4all/shared/forms';
import { Option } from '@pro4all/shared/types';

export const useProjectFormConfig = () => {
  const { t } = useTranslation();

  const membersAndAdmins: SchemaExtender<Yup.ArraySchema<Option>> = (schema) =>
    schema.test(
      'members',
      t('You cannot assign the same member to multiple roles'),
      function (value) {
        const members = value || [];
        const admins: Option[] = this?.parent?.admins || [];

        const intersection = admins.filter((admin) =>
          members.find((member) => admin.id === member.id)
        );

        return !intersection.length;
      }
    );

  const { projectName } = useProjectContext();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Name', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Project code', // i18n
      name: 'projectNumber',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Description', // i18n
      name: 'description',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Start date', // i18n
      name: 'startDate',
      type: ValueTypeName.DateTime,
    },
    {
      displayName: 'Deadline', // i18n
      name: 'deadline',
      type: ValueTypeName.DateTime,
    },
    {
      displayName: 'Admins', // i18n
      name: 'admins',
      required: true,
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Members', // i18n
      name: 'members',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: t("Enable AI for project '{{projectName}}'", {
        projectName: projectName,
      }),
      name: 'settings.ai_admin_settings',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({
    customValidation: {
      members: membersAndAdmins,
    },
    formFields,
  });
};
