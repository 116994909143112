import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { CommunicationService } from '@pro4all/communication/data-access';
import { AttachmentContext } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { getFileSize, tryDecodeString } from '@pro4all/shared/utils';

import { Buttons } from '../buttons';

import {
  AttachmentLink,
  AttachmentWrap,
  FileInfoEnd,
  StyledProgress,
  StyledText,
} from './Attachment.styles';

export interface AttachmentProps {
  completed: boolean;
  context: AttachmentContext;
  id: string;
  isUpload?: boolean;
  messageId?: string;
  name: string;
  onDelete?: ({ completed, id }: { completed: boolean; id: string }) => void;
  onDownload?: () => void;
  size?: number;
  uploadProgress?: number;
  uploading?: boolean;
}

export const Attachment: React.FC<AttachmentProps> = ({
  completed,
  id,
  isUpload = false,
  name,
  onDelete,
  onDownload,
  uploadProgress: _uploadProgress,
  size,
  uploading,
  messageId,
  context,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const organizationId = getContextScopedOrganizationId();

  useEffect(() => {
    if (_uploadProgress && _uploadProgress > uploadProgress)
      setUploadProgress(_uploadProgress);
  }, [_uploadProgress, uploadProgress]);

  const downloadAttachment = async () => {
    if (!isUpload)
      try {
        await CommunicationService.downloadAttachment({
          context,
          fileId: id,
          fileName: tryDecodeString(name),
          messageId,
          organizationId,
        });
        onDownload && onDownload();
      } catch (error) {
        console.log(error);
        enqueueSnackbar(t('Something went wrong'));
      }
  };

  const downloadable = !isUpload && id !== name;

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete && onDelete({ completed, id });
  };

  return (
    <AttachmentWrap>
      <Box alignItems="center" display="flex" flex={2} minWidth="0">
        <AttachmentLink
          $downloadable={downloadable}
          endLength={8}
          onClick={downloadAttachment}
          text={tryDecodeString(name)}
        />
      </Box>
      {isUpload && (
        <Box alignItems="center" display="flex" flex={4}>
          <StyledProgress
            color="secondary"
            data-testid="upload-progress"
            value={uploadProgress ? uploadProgress * 100 : 0}
            variant="determinate"
          />
        </Box>
      )}
      <FileInfoEnd>
        {size && (completed || !isUpload) && (
          <StyledText color="inherit">
            {t(getFileSize(size) || 'Unknown')}
          </StyledText>
        )}
        {onDelete && completed && (
          <Buttons.DeleteAttachment
            disabled={uploading}
            onClick={handleDelete}
          />
        )}
      </FileInfoEnd>
    </AttachmentWrap>
  );
};
