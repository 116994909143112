import { BasePropsHierarchyItem } from '@pro4all/shared/hierarchy-editor';

export const getBreadCrumbHierarchy = ({
  item,
  items,
}: {
  item: BasePropsHierarchyItem;
  items: BasePropsHierarchyItem[];
}) => {
  const nameArray = [item.name];
  // This item might be a deeply nested item, we need all the names of all parents to populate the breadcrumb.
  // We seek for the parent via the prop `parentNodeId`.
  let parentItem = items.find(
    (itemParent) => itemParent.id === item.parentNodeId
  );
  while (parentItem) {
    nameArray.unshift(parentItem.name);
    parentItem = items.find(
      (itemParent) => itemParent.id === parentItem.parentNodeId
    );
  }

  return nameArray;
};
