import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

type Props = {
  text: string;
  title: string;
};

export const BigMessageNoQCorDocTasks = ({ text, title }: Props) => {
  const { t } = useTranslation();

  return <BigMessage shapeName="flow1" text={t(text)} title={t(title)} />;
};
