import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

export const ViewerSideNav = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const ViewerEntityContainer = styled('div')`
  width: 100%;
  display: flex;
  height: 60%;
  flex-direction: column;
`;

export const ViewerPropertyContainer = styled('div')`
  width: 100%;
  height: 40%;
  overflow: auto;
  border-top: 1px solid #cecece;
`;

export const ViewerContainer = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

export const ModelNode = styled('div')``;

export const ModelNodeChildren = styled('div')`
  padding-left: 8px;
`;

export const EntityTreeContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const CollapseContainer = styled('div')`
  background: ${customColors.grey100};
  width: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 24px;
  }
  &:hover {
    background: ${customColors.grey200};
  }
`;

export const CollapseIcon = styled(Icon)`
  transform: rotate(-90deg);
  width: 16px;
  height: 16px;
`;
