import React from 'react';

import {
  Tooltip as MuiTooltip,
  TooltipProps as MUITooltipProps,
} from '@pro4all/shared/mui-wrappers';

/**
 * The tooltip is wrapped in a DOM element to determine its position by passing a ref.
 * Styling of the Tooltip is done on a globally in `GlobalStyles.tsx`
 * because the tooltip is rendered through a portal, and is rendered outside the
 * scope of this Styled component
 */

export type Options = Omit<MUITooltipProps, 'children' | 'arrow'>;

type TooltipChildren =
  | React.ReactElement<string | never, string | never>
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal;
interface TooltipProps extends Options {
  children: TooltipChildren;
}

/* Design system states that arrows are disabled */
export const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => (
  <MuiTooltip {...props} arrow={false}>
    <span>{children}</span>
  </MuiTooltip>
);
