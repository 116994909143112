/* To minimize calls, we are moving the check for supported formats to  */

export const supportedFormats = [
  '3dm',
  '3ds',
  'a',
  'asm',
  'axm',
  'brd',
  'catpart',
  'catproduct',
  'cgr',
  'collaboration',
  'dae',
  'ddx',
  'ddz',
  'dgk',
  'dgn',
  'dlv3',
  'dmt',
  'dwf',
  'dwfx',
  'dwg',
  'dwt',
  'dxf',
  'emodel',
  'exp',
  'f3d',
  'fbrd',
  'fbx',
  'fsch',
  'g',
  'gbxml',
  'glb',
  'gltf',
  'iam',
  'idw',
  'ifc',
  'ifczip',
  'ige',
  'iges',
  'igs',
  'ipt',
  'iwm',
  'jt',
  'max',
  'model',
  'mpf',
  'msr',
  'neu',
  'nwc',
  'nwd',
  'obj',
  'osb',
  'par',
  'pdf',
  'pmlprj',
  'pmlprjz',
  'prt',
  'psm',
  'psmodel',
  'rcc',
  'rcs',
  'rvm',
  'rvt',
  'sab',
  'sat',
  'sch',
  'session',
  'skp',
  'sldasm',
  'sldprt',
  'smb',
  'smt',
  'ste',
  'step',
  'stl',
  'stla',
  'stlb',
  'stp',
  'stpz',
  'vpb',
  'vue',
  'wire',
  'x_b',
  'x_t',
  'xas',
  'xpr',
  'zip',
  'asm\\.\\d+$',
  'neu\\.\\d+$',
  'prt\\.\\d+$',
];

export const excludedFormats = ['iam', 'pdf', 'sldasm', 'zip', 'xml'];
