export type ButtonColor = 'inherit' | 'primary' | 'secondary';

export type ButtonOrientation = 'horizontal' | 'vertical';

/**
 * TODO: This component is built around a fixed height,
 * because we can always assume for now that the bar will
 * container elements not higher than 32px; This makes the
 * component much easier because we don't have to listen for
 * changes to the DOM structure.
 */
export const HEIGHT = 40;
