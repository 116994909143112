import { FolderPermission } from '@pro4all/graphql';

import {
  isDeleteAll,
  isDeleteNone,
  isDeleteOwn,
  isDocumentsAll,
  isDocumentsNone,
  isDocumentsOwn,
  isDocumentsOwnAndFinalized,
  isUpdateAll,
  isUpdateNone,
  isUpdateOwn,
  isVersionsAll,
  isVersionsApproved,
  isVersionsLast,
  isVersionsLastAndApproved,
  isVersionsLastApproved,
  isVersionsOwn,
} from '../../shared/helpers/checkPermissionsWrappers';
import { OptionValue } from '../../types/types';

export const shouldChangeSubFolderPermission = ({
  currentSubFolderPermissions,
  permission,
}: {
  currentSubFolderPermissions: FolderPermission[];
  permission: OptionValue;
}) => {
  // For the exclusive permissions (Document, Version, Delete) we have to check if the current value
  // of subfolders should change after we change an exclusive permission of the parent folder

  // Document permissions: To 'None' > leave all subs as they are.
  if (
    permission === OptionValue.None &&
    !isDocumentsNone(currentSubFolderPermissions)
  ) {
    return false;
  }

  // Delete permissions: To 'None' > leave all subs as they are
  if (
    permission === OptionValue.DeleteNone &&
    !isDeleteNone(currentSubFolderPermissions)
  ) {
    return false;
  }

  // Update permissions: To 'None' > leave all subs as they are
  if (
    permission === OptionValue.UpdateNone &&
    !isUpdateNone(currentSubFolderPermissions)
  ) {
    return false;
  }

  // Document permissions: To 'Own' > do not change subs with 'All', 'Own and finalized' and 'Own'.
  if (
    permission === OptionValue.ReadOwn &&
    (isDocumentsAll(currentSubFolderPermissions) ||
      isDocumentsOwnAndFinalized(currentSubFolderPermissions) ||
      isDocumentsOwn(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Document permissions: To 'Own and finalized' > do not change subs with 'Own and finalized' and 'All'.
  if (
    permission === OptionValue.ReadOwnAndFinalized &&
    (isDocumentsOwnAndFinalized(currentSubFolderPermissions) ||
      isDocumentsAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Version permissions: To 'ReadOwnVersions' > leave all subs as they are
  if (
    permission === OptionValue.ReadOwnVersions &&
    !isVersionsOwn(currentSubFolderPermissions)
  ) {
    return false;
  }

  // Version permissions: To 'ReadLastVersion' > do not change subs with 'ReadLastVersion', 'ReadLastApproval', 'ReadApproval', 'ReadLastVersionAndApproved' and 'ReadAllVersions'.
  if (
    permission === OptionValue.ReadLastVersion &&
    (isVersionsLast(currentSubFolderPermissions) ||
      isVersionsLastApproved(currentSubFolderPermissions) ||
      isVersionsApproved(currentSubFolderPermissions) ||
      isVersionsLastAndApproved(currentSubFolderPermissions) ||
      isVersionsAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Version permissions: To 'ReadLastApproval' > do not change subs with 'ReadLastApproval', 'ReadApproval', 'ReadLastVersionAndApproved' and 'ReadAllVersions'.
  if (
    permission === OptionValue.ReadLastApproval &&
    (isVersionsLastApproved(currentSubFolderPermissions) ||
      isVersionsApproved(currentSubFolderPermissions) ||
      isVersionsLastAndApproved(currentSubFolderPermissions) ||
      isVersionsAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Version permissions: To 'ReadApproval' > do not change subs with 'ReadApproval', 'ReadLastVersionAndApproved' and 'ReadAllVersions'.
  if (
    permission === OptionValue.ReadApproval &&
    (isVersionsApproved(currentSubFolderPermissions) ||
      isVersionsLastAndApproved(currentSubFolderPermissions) ||
      isVersionsAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Version permissions: To 'ReadLastVersionAndApproved' > do not change subs with 'ReadLastVersionAndApproved' and 'ReadAllVersions'.
  if (
    permission === OptionValue.ReadLastVersionAndApproved &&
    (isVersionsLastAndApproved(currentSubFolderPermissions) ||
      isVersionsAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Delete permissions: To 'Own' > do not change subs with 'Own' and 'All'.
  if (
    permission === OptionValue.DeleteOwnContent &&
    (isDeleteOwn(currentSubFolderPermissions) ||
      isDeleteAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  // Update permissions: To 'Own' > do not change subs with 'Own' and 'All'.
  if (
    permission === OptionValue.UpdateOwn &&
    (isUpdateOwn(currentSubFolderPermissions) ||
      isUpdateAll(currentSubFolderPermissions))
  ) {
    return false;
  }

  return true;
};
