import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHierarchyEditorContext } from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';

import { useFillInitialItemsHierarchy } from '../shared/hierarchy-list/useFillInitialItemsHierarchy';
import { useSelectedItemsBreadcrumbHierarchy } from '../shared/hierarchy-list/useSelectedItemsBreadcrumbHierarchy';

import { HierarchyListProps } from './HierarchyList';
import { HierarchyOptionsSelector } from './HierarchyOptionsSelector';

export const HierarchyListOutput = ({
  item,
  name, //prop 'name' is the name of the form input which equals the input id.
  setValue,
  value,
}: HierarchyListProps) => {
  const { t } = useTranslation();

  const [displayOptionsSelector, setDisplayOptionsSelector] = useState(false);

  const { valueType } = item || {};
  const multipleAnswers =
    valueType && valueType.multipleAnswers ? valueType.multipleAnswers : false;

  const { freezeItems } = useHierarchyEditorContext();

  useFillInitialItemsHierarchy({ item, value });
  const selectedItemsBreadCrumb = useSelectedItemsBreadcrumbHierarchy({
    fieldDefinitionId: item.id,
    setValue,
    staticBreadcrumbs: item.valueType.staticBreadcrumbs,
    value,
  });

  useEffect(() => {
    setValue?.(name, value);
  }, [name, value]);

  return (
    <>
      <Box sx={{ mb: 1 }}>{selectedItemsBreadCrumb}</Box>

      <Button
        aria-label={t('Select an option')}
        data-testid="select-option-hierarchy-field"
        onClick={() => {
          freezeItems();
          setDisplayOptionsSelector(true);
        }}
        startIcon="hierarchicalList"
      >
        {t('Select an option')}
      </Button>
      {displayOptionsSelector && (
        <HierarchyOptionsSelector
          fieldDefinitionId={name}
          item={item}
          multipleAnswers={multipleAnswers}
          onClose={() => setDisplayOptionsSelector(false)}
          setValue={setValue}
        />
      )}
    </>
  );
};
