import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageSearchNoSavedQueries: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="build"
      text={t(
        'You don’t have any saved searches yet. Save a search by using keywords and or filters and it will show up here.'
      )}
      title={t('No saved searches')}
    />
  );
};
