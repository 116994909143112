import React from 'react';

import { groupInstancesByDrawing } from '@pro4all/quality-control/utils';
import { ReportProps } from '@pro4all/shared/types';

import { FrontPage } from './pages/FrontPage';
import { InstancePage } from './pages/InstancePage';
import InstancesGroupedByDrawing from './pages/instancesGroupedByDrawing/InstancesGroupedByDrawing';
import { TaskInstancePage } from './pages/TaskInstancePage';

export const Report = ({
  companyLogo,
  currentUser,
  date,
  members,
  downloadPdfContext, // Because rendering of the first page (FrontPage disabled Y/N) differs between PDF and Preview we need the context.
  instancesGroupedByDrawing,
  miniMaps,
  photoBlobs,
  project,
  reportConfigurationDummyTemplates,
  reportDrawings,
  reportOptions,
  signatures,
  tasks,
}: ReportProps) => {
  const { enableFrontPage } = reportOptions || {};

  const ReportConfigurationReport = () => (
    <div>
      {reportConfigurationDummyTemplates.map(
        (reportConfigurationDummyTemplate, index) => (
          <InstancePage
            hasFirstPageBeenPrinted={enableFrontPage || index > 0}
            key={reportConfigurationDummyTemplate.id}
            members={members}
            reportConfigurationDummyTemplate={reportConfigurationDummyTemplate}
            reportOptions={reportOptions}
          />
        )
      )}
    </div>
  );

  const instancesGroupedByFpProps = {
    downloadPdfContext,
    enableFrontPage,
    members,
    miniMaps,
    photoBlobs,
    reportDrawings,
    reportOptions,
    signatures,
    tasks,
  };

  return (
    <>
      {enableFrontPage && (
        <FrontPage
          companyLogo={companyLogo}
          date={date}
          project={project}
          reportOptions={reportOptions}
          user={currentUser}
        />
      )}
      {tasks && tasks?.length > 0 ? (
        tasks.map((task, index) => (
          <div key={index}>
            <TaskInstancePage
              hasFirstPageBeenPrinted={
                downloadPdfContext ? enableFrontPage || index > 0 : true
              }
              instance={task}
              reportOptions={reportOptions}
            />
            {task.linkedSnagInstances && (
              <InstancesGroupedByDrawing
                {...instancesGroupedByFpProps}
                groupedInstances={groupInstancesByDrawing(
                  task.linkedSnagInstances
                )}
              />
            )}
          </div>
        ))
      ) : reportConfigurationDummyTemplates &&
        reportConfigurationDummyTemplates.length ? (
        <ReportConfigurationReport />
      ) : (
        instancesGroupedByDrawing && (
          <InstancesGroupedByDrawing
            {...instancesGroupedByFpProps}
            groupedInstances={instancesGroupedByDrawing}
          />
        )
      )}
    </>
  );
};
