import { Group } from '@pro4all/graphql';

import { FormFields } from './Types';

export const getInitialValues = ({
  displayName = '',
  subgroups = [],
  members = [],
}: Partial<Group> = {}): FormFields => ({
  displayName,
  members: (members || []).map((member) => ({
    id: member.id,
    label: member.displayName,
    type: member.__typename,
  })),
  subgroups: subgroups.map((subgroup) => ({
    id: subgroup.id,
    label: subgroup.displayName,
  })),
});
