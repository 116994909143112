import React, { useRef } from 'react';

import { TbqModuleType, ValueTypeName } from '@pro4all/graphql';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { SnagActionButtons } from '@pro4all/shared/qc-sources/snag-action-buttons';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';

import { useFieldConfig } from '../configs/useFieldConfig';

import { DisplayFieldProps } from './DisplayField.types';
import * as Styled from './SectionsAndFieldsList.styles';

export const DisplayField = ({
  Edit,
  View,
  editMode,
  field,
  isDirty,
  linkedSnags,
  padding,
  refetchQualityInstance,
  tbqInstance,
}: DisplayFieldProps) => {
  const { searchParams } = useRouting();
  const tbqScanType = searchParams.get('tbqScanType');
  const isTBQQuestionView = searchParams.is('view', 'view-question-sidebar');
  const fieldConfig = useFieldConfig();
  const showIcon = [ValueTypeName.Media, ValueTypeName.Signature].includes(
    field.type
  );

  const refEditValue = useRef<any>(field.value);
  const { answersForApplying } = useSavedAnswersContext();
  if (answersForApplying?.length) {
    // Find the answer for the field.
    const answerForField = answersForApplying.find(
      (answer) => answer.fieldId === field.id
    );
    if (answerForField) {
      refEditValue.current = answerForField.value;
    }
  } else {
    refEditValue.current = field.value;
  }

  const getLinks = (fieldId: string) =>
    linkedSnags?.length
      ? linkedSnags?.filter((link) => link.appData.fieldId === fieldId)
      : [];

  const { translateTbq } = useTranslateTbq();

  const isTBQFieldOrTBQBrandcheck =
    (tbqInstance && tbqScanType === TbqModuleType.Brandcheck) ||
    isTBQQuestionView;

  return (
    <Styled.FieldWrapper key={field.id} padding={padding}>
      <Styled.Label $orientation="vertical" as="div">
        {showIcon && (
          <Styled.IconWrapper>
            <Icon iconName={fieldConfig[field.type].icon} />
          </Styled.IconWrapper>
        )}

        <strong>
          {editMode && field.required
            ? `* ${field.displayName || field.name}`
            : field.displayName || field.name}
        </strong>

        {field.linksAllowed && (
          <SnagActionButtons
            fieldId={
              tbqInstance && tbqScanType === TbqModuleType.Brandcheck
                ? field.parentSectionId
                : tbqScanType?.toUpperCase() === TbqModuleType.Rie
                ? field.parentSectionId
                : field.id
            }
            linkedSnags={getLinks(
              isTBQFieldOrTBQBrandcheck
                ? field.parentSectionId
                : tbqInstance &&
                  tbqScanType?.toUpperCase() === TbqModuleType.Rie
                ? field.parentSectionId
                : field.id
            )}
            refetchQualityInstance={refetchQualityInstance}
          />
        )}
      </Styled.Label>
      {field.displayDescription && (
        <Styled.Info>{field.displayDescription}</Styled.Info>
      )}

      {editMode && field.type !== 'Description' && (
        <Edit item={field} value={refEditValue.current} />
      )}
      {!editMode && <View item={field} value={translateTbq(field.value)} />}
    </Styled.FieldWrapper>
  );
};
