import { TFunction } from 'react-i18next';

import { FieldDefinition, FormLinkedSnag } from '@pro4all/graphql';
import { AnswerView, SectionsAndFieldsList } from '@pro4all/metadata/ui';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import { IconTextWrapper } from '../LinkedResolveTasksTitle';

import {
  TBQQuestionSidebarContentWrapper,
  TBQQuestionSidebarSectionAnswersWrapper,
} from './TBQQuestionSidebar.styled';

interface QuestionContentProps {
  linkedSnags?: FormLinkedSnag[];
  questionFields: FieldDefinition[] | undefined | null;
  t: TFunction;
}

export const QuestionContent: React.FC<QuestionContentProps> = ({
  questionFields,
  linkedSnags,
  t,
}) => (
  <TBQQuestionSidebarContentWrapper>
    <IconTextWrapper>
      <Icon iconName="tbqBrandcheck" />
      <Text variant="h4">{t('Answer')}</Text>
    </IconTextWrapper>
    <TBQQuestionSidebarSectionAnswersWrapper>
      <SectionsAndFieldsList
        View={AnswerView}
        editMode={false}
        fields={questionFields ?? []}
        isDirty={false}
        isTBQ
        linkedSnags={linkedSnags}
        padding
        searchTerm=""
      />
    </TBQQuestionSidebarSectionAnswersWrapper>
  </TBQQuestionSidebarContentWrapper>
);
