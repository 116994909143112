import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@pro4all/shared/ui/general';

import { QuestionSidebarTabs } from './TbqQuestionSidebar';

interface Props {
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => void;
  value: string;
}

export const TbqQuestionSidebarTabs: React.FC<Props> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Tabs onChange={onChange} value={value}>
      <Tab label={t('Properties')} value={QuestionSidebarTabs.PROPERTIES} />
    </Tabs>
  );
};
