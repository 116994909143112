import { FolderPermission } from '@pro4all/graphql';

import { hasConflictWithApprovePermission } from '../../shared/helpers/hasConflictWithApprovePermission';
import { hasConflictWithFinalizePermission } from '../../shared/helpers/hasConflictWithFinalizePermission';
import { Permission, State } from '../types';

import { getThisFolderPermission } from './getThisFolderPermission';

export const inConflictWithPermissionsOfOwnFolderOrNestedFolders = ({
  folderId,
  permission,
  state,
}: {
  folderId: string;
  permission: FolderPermission;
  state: State;
}) => {
  let response = false;

  const { displayPermissions } = state;

  // Check own folder.
  const thisFolder = getThisFolderPermission(folderId, displayPermissions);
  if (thisFolder) {
    response = checkPermissions({
      folder: thisFolder,
      permission,
    });
  }

  const checkChildFolders = (folderId: string) => {
    // Get all subfolders.
    const subFolders = displayPermissions.filter(
      (folder) => folder.parentFolderId === folderId
    );

    // Navigate through all subfolders.
    subFolders.forEach((folder) => {
      // Check if the subfolder has been changed, cause then we need the changed permissions.
      const subFolder = getThisFolderPermission(
        folder.folderId,
        displayPermissions
      );

      if (!subFolder.breakInheritance) {
        response = checkPermissions({
          folder: subFolder,
          permission,
        });

        if (!response) {
          // Only continue searching deeper if conflicts have not been found yet.
          checkChildFolders(subFolder.folderId);
        }
      } else {
        response = false;
      }
    });
  };

  if (!response) {
    // Only continue searching deeper if conflicts have not been found yet.
    checkChildFolders(folderId);
  }

  return response;
};

const checkPermissions = ({
  folder,
  permission,
}: {
  folder: Permission;
  permission: FolderPermission;
}) => {
  let response = false;

  if (!response && permission === FolderPermission.FinalizeContent) {
    response = hasConflictWithFinalizePermission({
      currentPermissions: [FolderPermission.FinalizeContent],
      newPermissions: folder.folderPermissions,
    });
  }

  if (!response && permission === FolderPermission.ApproveContent) {
    // Only continue checking if conflicts have not been found yet.
    response = hasConflictWithApprovePermission({
      currentPermissions: [FolderPermission.ApproveContent],
      newPermissions: folder.folderPermissions,
    });
  }

  return response;
};
