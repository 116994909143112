import { useCallback, useRef } from 'react';
import { orderBy } from 'lodash';

import { useLocalStorage } from '@pro4all/shared/hooks';
import { ColumnSortOrder, TableColumnDataType } from '@pro4all/shared/types';

import { useOptimisticResponseContext } from '../OptimisticResponseProvider';
import { useSortColumnContext } from '../SortColumnContext';
import { BaseRow } from '../types';

import {
  GetSortedItems,
  MetaDataProps,
  SortColumn,
  SortColumnTypeLS,
  StandardProps,
} from './typesSortColumn';

export const useSortColumn = <Row extends BaseRow>({
  tableId,
}: {
  tableId: string;
}) => {
  const { triggerColumnSort } = useOptimisticResponseContext() || {};

  const { getCustomCallback } = useSortColumnContext<Row>() || {
    getCustomCallback: () => undefined,
  };

  const refSortOder = useRef(ColumnSortOrder.ASC);

  const { setLocalStorageItem } = useLocalStorage<SortColumnTypeLS>({
    key: `prostream-table-sorting-${tableId}`,
  });

  const getLocalStorageSources = useCallback(() => {
    // We cannot use the hook `useLocalStorage` in a function so we get the localStorageItem here like so.
    const localStorageItemJSON = window.localStorage.getItem(
      `p4a:ps:prostream-table-sorting-${tableId}`
    );
    const localStorageItem = localStorageItemJSON
      ? JSON.parse(localStorageItemJSON)
      : {};

    const {
      dataType: dataTypeLs,
      sortKey: sortKeyLs,
      sortOrder: sortOrderLs,
      subProperty: subPropertyLs,
    } = localStorageItem;
    return { dataTypeLs, sortKeyLs, sortOrderLs, subPropertyLs };
  }, [tableId]);

  const isNumericColumnMetaData = useCallback(
    ({
      items,
      metaDataHeaderIdToUse,
    }: {
      items: Row[];
      metaDataHeaderIdToUse: string;
    }) => {
      // We cannot get info returned from the BE which value Type the columns is related to.
      // But we need this value type to know whether to sort on a string or a number.
      // So what we gonna do right now is to collect all values.
      // If all these values are numeric or empty then we sort numeric otherwise we sort alphanumeric.
      const values = items.map((item) => {
        const itemMd = item as unknown as MetaDataProps;
        const metaDataAnswers = itemMd.metaData?.answers || [];
        const index = metaDataAnswers.findIndex(
          (answer) => answer.fieldDefinitionId === metaDataHeaderIdToUse
        );
        return index === -1 ? '' : metaDataAnswers[index].value;
      });

      return values.every((value) => value === '' || /^\d+$/.test(value));
    },
    []
  );

  const getFinalValue = (value: string[] | string) => {
    if (Array.isArray(value)) {
      return value.length ? value.join(', ') : '';
    } else if (
      // We have to do this because for customCallbacks the first render will not get the callback from context in time.
      typeof value !== 'boolean' &&
      typeof value !== 'number' &&
      typeof value !== 'string'
    ) {
      return '';
    } else {
      return typeof value === 'boolean' || typeof value === 'number'
        ? value
        : value
        ? value?.toLowerCase()
        : '';
    }
  };

  const getSortedItems = useCallback(
    ({ items }: GetSortedItems<Row>) => {
      let propertyIdToUse: string;
      let subPropertyIdToUse: string;
      let metaDataHeaderIdToUse: string;

      const { dataTypeLs, sortKeyLs, sortOrderLs, subPropertyLs } =
        getLocalStorageSources();

      // In case values for a column are calculated via a custom callback,
      // this callback is stored in a context file. Get the callback from there.
      const customCallback = getCustomCallback({ key: sortKeyLs, tableId });

      if (!sortKeyLs) {
        // Data not found in localStorage so return the items as they are currently sorted.
        return items;
      } else {
        // Take props (sort data) from localStorage.

        // Check if the sortKey taken from localStorage is related to a meta data property.
        const isMetaData = sortKeyLs.includes('metaData.');
        propertyIdToUse = isMetaData ? sortKeyLs.split('.')[0] : sortKeyLs;
        metaDataHeaderIdToUse = isMetaData ? sortKeyLs.split('.')[1] : '';
        subPropertyIdToUse = subPropertyLs;
        refSortOder.current = sortOrderLs
          ? (sortOrderLs as ColumnSortOrder)
          : ColumnSortOrder.ASC;
      }

      const propertyIdTyped = propertyIdToUse as unknown as keyof Row;

      // Now calculate the new sorted list of items.
      let sortedItems = items;

      if (subPropertyIdToUse && metaDataHeaderIdToUse) {
        // Sorting on meta data column.
        sortedItems = orderBy(
          items,
          [
            (item) => {
              const itemMd = item as unknown as MetaDataProps;
              const metaDataAnswers = itemMd.metaData?.answers || [];
              const index = metaDataAnswers.findIndex(
                (answer) => answer.fieldDefinitionId === metaDataHeaderIdToUse
              );
              return index === -1
                ? ''
                : isNumericColumnMetaData({ items, metaDataHeaderIdToUse })
                ? parseInt(metaDataAnswers[index].value, 10)
                : metaDataAnswers[index].value?.toLowerCase();
            },
          ],
          [refSortOder.current]
        );
      } else {
        if (customCallback) {
          // Value to be sorted on is calculated via a custom callback.
          sortedItems = orderBy(
            items,
            [
              (item) => {
                const value = getFinalValue(
                  customCallback(item[propertyIdTyped])
                );
                return dataTypeLs === TableColumnDataType.NUMERIC
                  ? parseInt(value, 10)
                  : value;
              },
            ],
            [refSortOder.current]
          );
        } else if (subPropertyIdToUse) {
          // Sorting on standard column that takes its value from a sub property.
          sortedItems = orderBy(
            items,
            [
              (item) => {
                const itemSubProp = item as unknown as StandardProps;
                if (itemSubProp[propertyIdToUse]) {
                  const value = getFinalValue(
                    itemSubProp[propertyIdToUse][subPropertyIdToUse]
                  );
                  return dataTypeLs === TableColumnDataType.NUMERIC
                    ? parseInt(value, 10)
                    : value;
                } else {
                  return '';
                }
              },
            ],
            [refSortOder.current]
          );
        } else {
          // Sorting on standard column that takes its value directly from the property.
          sortedItems = orderBy(
            items,
            [
              (item) => {
                const value = getFinalValue(
                  item[propertyIdTyped] as unknown as string
                );
                return dataTypeLs === TableColumnDataType.NUMERIC
                  ? parseInt(value, 10)
                  : value;
              },
            ],
            [refSortOder.current]
          );
        }
      }

      return sortedItems;
    },
    [
      getCustomCallback,
      getLocalStorageSources,
      isNumericColumnMetaData,
      tableId,
    ]
  );

  const storeColumnSortingInLocalStorage = useCallback(
    ({
      dataType = TableColumnDataType.TEXT,
      defaultSortOrder = ColumnSortOrder.ASC,
      items,
      metaDataHeaderId,
      propertyId,
      subPropertyId,
    }: SortColumn<Row>) => {
      const { sortOrderLs } = getLocalStorageSources();
      const sortOrder = sortOrderLs
        ? sortOrderLs === ColumnSortOrder.ASC
          ? ColumnSortOrder.DESC
          : ColumnSortOrder.ASC
        : defaultSortOrder;

      // Save the sorting in localStorage.
      setLocalStorageItem({
        dataType,
        sortKey: metaDataHeaderId
          ? `${propertyId as string}.${metaDataHeaderId}`
          : (propertyId as string),
        sortOrder,
        subProperty: subPropertyId as string,
      });

      // This triggers the useEffect in TableContext which takes care of the actual sorting.
      // We could have done the sorting here too, but that would do it double, because the useEffect triggers anyway.
      triggerColumnSort(items);
    },
    [getLocalStorageSources, setLocalStorageItem, triggerColumnSort]
  );

  return {
    getLocalStorageSources,
    getSortedItems,
    storeColumnSortingInLocalStorage,
  };
};
