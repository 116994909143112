import { useTranslation } from 'react-i18next';

import { Button } from '@pro4all/shared/ui/buttons';
import { Footer } from '@pro4all/shared/ui/general';

interface Props {
  dirty: boolean;
  onSubmit: () => void;
  resetForm: () => void;
}

const MatrixFooter = ({ resetForm, onSubmit, dirty }: Props) => {
  const { t } = useTranslation();
  return (
    <Footer pb={3} pt={2} px={3}>
      <Button
        disabled={!dirty}
        onClick={() => {
          resetForm();
        }}
      >
        {t('Reset changes')}
      </Button>
      <Button
        disabled={!dirty}
        onClick={() => {
          onSubmit();
          resetForm();
        }}
        type="submit"
        variant="contained"
      >
        {t('Save')}
      </Button>
    </Footer>
  );
};
export default MatrixFooter;
