import { useOrganizationContext } from '@pro4all/organization/context';

import { FormFields } from './types';

export const useInitialValues = () => {
  const { organization } = useOrganizationContext();
  const { settings } = organization || {};

  const initialValues: FormFields = {
    approve_action_label: settings?.approve_action_label ?? '',
    finalize_action_label: settings?.finalize_action_label ?? '',
    reject_action_label: settings?.reject_action_label ?? '',
  };

  return initialValues;
};
