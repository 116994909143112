import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Member } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Select } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { sortBy } from '@pro4all/shared/utils';

import { PermissionUserOption } from '../../views/types';

const StyledSelect = styled(Select)`
  && {
    width: 450px;
  }
`;

interface Props {
  disabled?: boolean;
  members: Member[];
  onChange: (selectedOption: PermissionUserOption) => void;
  value?: PermissionUserOption;
}

export const PersonOrGroupSelect = ({
  disabled = false,
  members = [],
  onChange,
  value,
}: Props) => {
  const { t } = useTranslation();

  const options: PermissionUserOption[] = members.map((member) => ({
    iconName:
      member.__typename === 'User'
        ? member.isAdmin
          ? 'admin'
          : 'personOutline'
        : 'group',
    id: member.id,
    label:
      member.__typename === 'User'
        ? `${member.displayName} ${member.email && `(${member.email})`}`
        : member.displayName,
    ...(member.organizationId ? { organizationId: member.organizationId } : {}),
    explicitProjectMember: member.explicitProjectMember,
    type: member.__typename,
  }));

  return (
    <Box>
      <Text variant="h5">{t('Person/Group')}</Text>
      <StyledSelect
        data-testid="selectEntity"
        disabled={disabled}
        name="selectEntity"
        onChange={onChange}
        options={options.sort(sortBy({ key: 'label' }))}
        value={value?.id ?? ''}
      />
    </Box>
  );
};
