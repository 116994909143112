import { PasteType } from '@pro4all/shared/types';

import { changeItem } from '../helpers/changeItem';
import { copyItemTree } from '../helpers/copyItemTree';
import { getChildIds } from '../helpers/getChildIds';
import { moveItemAfterPaste } from '../helpers/moveItemAfterPaste';
import { PasteItemPayload } from '../hierarchyEditorReducer';
import { BasePropsHierarchyItem, ItemProps } from '../types';

export const pasteItemAction = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  newParentNodeId,
  pasteType,
  sourceId,
}: Pick<ItemProps<HierarchyItem>, 'allItems'> & PasteItemPayload) => {
  if (pasteType === PasteType.CUT) {
    // Remove the item from the clipboard if the context is a cut operation.
    window.localStorage.removeItem(
      'p4a:ps:prostream-copy-cut-paste-hierarchy-item'
    );
  }

  // If the source id does not exist do not change anything.
  const sourceItem = allItems.find((item) => item.id === sourceId);
  if (!sourceItem) {
    return allItems;
  }

  let updatedItems = allItems;

  // The source item will become the first child of the parent, so that the user always sees the change instantly.
  // If we would add it as the last item, it might be that the user does not see the impact in case of a long list.
  // If the user wants to change the order, he can do that afterwards.

  if (pasteType === PasteType.CUT) {
    // If the source item is equal to the parent item do not change anything.
    if (sourceId === newParentNodeId) {
      return allItems;
    }

    // If the source item is dropped on one of its children do not change anything.
    const childIds = getChildIds<HierarchyItem>({ allItems, id: sourceId });
    if (childIds.includes(newParentNodeId)) {
      return allItems;
    }

    updatedItems = moveItemAfterPaste<HierarchyItem>({
      allItems: updatedItems,
      newParentNodeId,
      previousNodeId: sourceId,
    });

    // The source item can have a previousNodeId. We have to search the item following the source item and set the previousNodeId to the previousNodeId of the source item.
    const itemNext = allItems.find(
      (item) => item.previousNodeId === sourceItem.id
    );
    if (itemNext) {
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: { ...itemNext, previousNodeId: sourceItem.previousNodeId },
      });
    }

    // Update the source item.
    updatedItems = changeItem<HierarchyItem>({
      allItems: updatedItems,
      item: {
        ...sourceItem,
        parentNodeId: newParentNodeId,
        previousNodeId: null,
      },
    });
  } else if (pasteType === PasteType.COPY) {
    updatedItems = copyItemTree<HierarchyItem>({
      allItems: updatedItems,
      newParentNodeId,
      sourceItem,
    });
  }

  return updatedItems;
};
