import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoProjects: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="follow"
      text={t('No projects')}
      title={t(
        'There are no projects for your organization. Create a new project to start collaboration.'
      )}
    />
  );
};
