import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const OwnAndFinalizedFiles: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M13.4482 7.73689H16.0986C16.5585 7.73689 16.9347 8.11315 16.9347 8.57302C16.9347 8.64743 16.9248 8.71965 16.9064 8.78844C16.8677 8.78789 16.8289 8.78761 16.79 8.78761C15.6918 8.78761 14.6453 9.00883 13.6926 9.40914H13.4482L13.5222 9.4831C12.9916 9.72077 12.4916 10.0145 12.0297 10.3569L11.0821 9.40914H9.40988V11.0814L10.3574 12.029C10.0151 12.4908 9.72128 12.9907 9.48355 13.5213L9.40988 13.4476V13.691C9.00911 14.6442 8.78761 15.6914 8.78761 16.7903C8.78761 16.829 8.78789 16.8676 8.78843 16.9061C8.71988 16.9245 8.64792 16.9342 8.57379 16.9342C8.11394 16.9342 7.7377 16.558 7.7377 16.0981V13.4476L5.61403 15.5714C5.28796 15.8974 4.76122 15.8974 4.43515 15.5714C4.10907 15.2453 4.10907 14.7102 4.43515 14.3841L7.7377 11.0814V9.40914H6.06552L2.76297 12.7118C2.43689 13.0379 1.90179 13.0379 1.57572 12.7118C1.24964 12.3857 1.24964 11.859 1.57572 11.5329L3.69939 9.40914H1.04898C0.589131 9.40914 0.212891 9.03288 0.212891 8.57302C0.212891 8.11315 0.589131 7.73689 1.04898 7.73689H3.69939L1.57572 5.61314C1.24964 5.28705 1.24964 4.7603 1.57572 4.43421C1.90179 4.10812 2.43689 4.10812 2.76297 4.43421L6.06552 7.73689H7.7377V6.06465L4.43515 2.76197C4.10907 2.43588 4.10907 1.90076 4.43515 1.57467C4.59135 1.41811 4.80343 1.33012 5.02459 1.33012C5.24575 1.33012 5.45783 1.41811 5.61403 1.57467L7.7377 3.69842V1.04791C7.7377 0.588047 8.11394 0.211792 8.57379 0.211792C9.03364 0.211792 9.40988 0.588047 9.40988 1.04791V3.69842L11.5336 1.57467C11.6898 1.41811 11.9018 1.33012 12.123 1.33012C12.3442 1.33012 12.5562 1.41811 12.7124 1.57467C13.0385 1.90076 13.0385 2.43588 12.7124 2.76197L9.40988 6.06465V7.73689H11.0821L14.3846 4.43421C14.7107 4.10812 15.2458 4.10812 15.5719 4.43421C15.8979 4.7603 15.8979 5.28705 15.5719 5.61314L13.4482 7.73689Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7881 9.7876C12.9241 9.7876 9.78809 12.9237 9.78809 16.7879C9.78809 20.652 12.9241 23.7881 16.7881 23.7881C20.6521 23.7881 23.7881 20.652 23.7881 16.7879C23.7881 12.9237 20.6521 9.7876 16.7881 9.7876ZM16.7879 11.8866C17.9499 11.8866 18.8879 12.8247 18.8879 13.9867C18.8879 15.1488 17.9499 16.0868 16.7879 16.0868C15.6259 16.0868 14.6879 15.1488 14.6879 13.9867C14.6879 12.8247 15.6259 11.8866 16.7879 11.8866ZM12.5887 19.5756C13.4917 20.9337 15.0387 21.8297 16.7887 21.8297C18.5387 21.8297 20.0857 20.9337 20.9887 19.5756C20.9677 18.1826 18.1817 17.4195 16.7887 17.4195C15.3887 17.4195 12.6097 18.1826 12.5887 19.5756Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
