import React from 'react';
import styled from 'styled-components';

import { DocumentService } from '@pro4all/documents/data-access';
import { DocumentVersion } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { DocTablePropertyKeys } from '@pro4all/shared/types';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  FilePath,
  MiddleEllipsis,
} from '@pro4all/shared/ui/general';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const useColumns = ({
  canNavigateToFolder = true,
}: {
  canNavigateToFolder?: boolean;
}) => {
  const nameColumn: ColumnProps<DocumentVersion> = {
    defaultSort: true,
    filterable: true,
    headerComponent: (
      <FilterHeader<DocumentVersion, {}>
        defaultWidth={400}
        filterType={FilterHeaderType.Text}
        label="Name" // i18n
        minWidth={96}
        propertyId={DocTablePropertyKeys.Name}
        pullFromLocalStorage
      />
    ),
    key: DocTablePropertyKeys.Name,
    render: (document: DocumentVersion) => (
      <>
        <StyledIconButton
          disableBorder
          iconName="download"
          onClick={() =>
            DocumentService.downloadDocument({
              downloadUrl: document.downloadUrl, // We get this download URL from the BE. This prop will force the download via that URL.
              version: document,
            })
          }
        />
        <MiddleEllipsis endLength={9} text={document.name} />
      </>
    ),
    width: 400,
  };

  const pathColumn: ColumnProps<DocumentVersion> = {
    filterable: true,
    getValue: ({ path }: DocumentVersion) => path,
    headerComponent: (
      <FilterHeader<DocumentVersion, {}>
        defaultWidth={400}
        filterType={FilterHeaderType.Text}
        label="Location" // i18n
        minWidth={100}
        propertyId={DocTablePropertyKeys.Path}
      />
    ),
    key: DocTablePropertyKeys.Path,
    render: (doc: DocumentVersion) => {
      const { documentId, path, pathIds } = doc;
      return (
        <FilePath
          canNavigateToFolder={canNavigateToFolder}
          id={documentId}
          path={path || ''}
          pathIds={pathIds}
          updateExpandedKeysInLocalStorage
        />
      );
    },
    width: 400,
  };

  return {
    nameColumn,
    pathColumn,
  };
};

const StyledIconButton = styled(IconButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
    color: ${customColors.grey600};
  }
`;
