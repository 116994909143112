import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MinBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import { useCollapseButtons } from '@pro4all/shared/contexts';
import { IconButton, Tooltip } from '@pro4all/shared/mui-wrappers';

export const CollapseExpandButtons = ({ maxLevel }: { maxLevel: number }) => {
  const { collapseLevel, setCollapseLevel } = useCollapseButtons();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('Expand one level')}>
        <IconButton
          color="inherit"
          onClick={() => {
            setCollapseLevel?.({
              initial: false,
              level: Math.min(collapseLevel.level + 1, maxLevel),
            });
          }}
          size="large"
        >
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Collapse one level')}>
        <IconButton
          color="inherit"
          onClick={() => {
            setCollapseLevel?.({
              initial: false,
              level: Math.max(collapseLevel.level - 1, 0),
            });
          }}
          size="large"
        >
          <MinBoxIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
