import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  FieldDefinition,
  useDeleteFieldDefinitionsMutation,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/general';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

export const useDeleteFieldDefinitions = () => {
  const { t } = useTranslation();
  const { templateType, templateService } = useMetaDataContext();
  const { entity } =
    featureConfig[templateService]?.fieldTypes[templateType] ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFieldDefinitions] = useDeleteFieldDefinitionsMutation();
  const { checkedRows } = useTableCheck<FieldDefinition>();

  const { deleteItems } = useOptimisticResponseContext<FieldDefinition>();

  const {
    getLocalStorageItem: getPublishTemplatesAfterFieldUpdateLocalStorage,
  } = useLocalStorage<boolean>({
    key: StorageKeys.PUBLISH_TEMPLATES_AFTER_FIELD_UPDATE,
  });

  return async () => {
    const ids = checkedRows.map((row) => row.id);
    try {
      await deleteFieldDefinitions({
        variables: {
          autoPublish:
            getPublishTemplatesAfterFieldUpdateLocalStorage() || false,
          ids,
          templateService,
        },
      });
      deleteItems(ids);
      enqueueSnackbar(t(`Selected ${entity}s deleted`));
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
