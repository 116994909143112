import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import {
  DomainToClaim,
  useGenerateSecretTokenMutation,
  useRevokeSecretTokenMutation,
} from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Button, MuiPlainTableWrapper } from '@pro4all/shared/mui-wrappers';
import { Box } from '@pro4all/shared/mui-wrappers';

import { SecretDisplay } from './SecretDisplay';

type OrganizationProvisioningTableProps = {
  domainsToProvision: DomainToClaim[];
};

export const OrganizationProvisioningTable = ({
  domainsToProvision,
}: OrganizationProvisioningTableProps) => {
  const { t } = useTranslation();

  const provisioningColumns = [t('Domain'), t('Secret')];

  const [secrets, setSecrets] = useState<{ domain: string; secret: string }[]>(
    []
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (domainsToProvision) {
      setSecrets(
        domainsToProvision.map((domainToProvision: DomainToClaim) => ({
          domain: domainToProvision.domain,
          secret: '',
        }))
      );
    }
  }, [domainsToProvision]);

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const [generateSecretToken] = useGenerateSecretTokenMutation();
  const [revokeSecretToken] = useRevokeSecretTokenMutation();

  const handleGenerateToken = useMemo(
    () => async (domain: string, claimId: string) => {
      try {
        const { data } = await generateSecretToken({
          variables: { claimId, organizationId },
        });
        const token = data?.generateSecretToken?.secretToken;
        if (token) {
          setSecrets(
            secrets.map((item) =>
              item.domain === domain ? { ...item, secret: token } : item
            )
          );
          enqueueSnackbar('Token generated successfully');
        }
      } catch (error) {
        enqueueSnackbar('Error generating token. Please revoke.');
      }
    },
    [generateSecretToken, organizationId, secrets, enqueueSnackbar]
  );

  const handleRevokeToken = useMemo(
    () => async (domain: string, claimId: string) => {
      try {
        const { data } = await revokeSecretToken({
          variables: { claimId, organizationId },
        });
        setSecrets(
          secrets.map((item) =>
            item.domain === domain ? { ...item, secret: '' } : item
          )
        );
        enqueueSnackbar('Token revoked successfully.');
      } catch (error) {
        enqueueSnackbar('Error revoking token. Please generate.');
      }
    },
    [revokeSecretToken, organizationId, secrets, enqueueSnackbar]
  );

  const returnSecretValue = useMemo(
    () => (domain: string, domainClaimId: string) => {
      const secret = secrets.find((item) => item.domain === domain)?.secret;

      if (secret && secret !== '') {
        return (
          <SecretDisplay
            domain={domain}
            domainClaimId={domainClaimId}
            handleRevokeToken={handleRevokeToken}
            secret={secret}
          />
        );
      } else {
        return (
          <>
            <Button onClick={() => handleGenerateToken(domain, domainClaimId)}>
              {t('Generate')}
            </Button>
            <Button onClick={() => handleRevokeToken(domain, domainClaimId)}>
              {t('Revoke')}
            </Button>
          </>
        );
      }
    },
    [secrets, handleRevokeToken, handleGenerateToken, t]
  );

  const provisioningData = domainsToProvision
    ? domainsToProvision.map((domainToProvision: DomainToClaim) => ({
        [t('Domain')]: <p>{domainToProvision.domain}</p>,
        [t('Secret')]: returnSecretValue(
          domainToProvision.domain,
          domainToProvision.domainClaimId
        ),
      }))
    : [];

  return (
    <TableBox>
      <MuiPlainTableWrapper
        columns={provisioningColumns}
        data={provisioningData}
      />
      {domainsToProvision.length === 0 && (
        <p>{t('Add a domain to start verification')}</p>
      )}
    </TableBox>
  );
};

const TableBox = styled(Box)`
  && {
    margin: 3% 0%;
  }
`;
