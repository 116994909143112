import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Folder, TemplateService, TemplateType } from '@pro4all/graphql';
import { MetaDataContextProvider } from '@pro4all/metadata/ui';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { Sidebar } from '@pro4all/shared/ui/general';

import { FolderMetaDataForm } from './FolderMetaDataForm';

export interface FormFields {
  applyToSubfolders: boolean;
  selectedTemplate: Option;
}

export const FolderMetaDataSidebar: React.FC<{ folder?: Folder }> = ({
  folder,
}) => {
  const { t } = useTranslation();

  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // If no folder is targeted (i.e. page refresh), do not open sidebar and clear params
    if (!folder && searchParams.is('action', 'applyTemplate')) {
      searchParams.clear();
    }
    setIsOpen(
      () => searchParams.is('action', 'applyTemplate') && Boolean(folder)
    );
  }, [searchParams, folder]);

  const folderFromUrl = searchParams.get('folder');

  const onClose = () => {
    searchParams.clear();
  };

  return (
    <Sidebar onClose={onClose} open={isOpen && folderFromUrl === folder?.name}>
      <Sidebar.Header icon="metaData" title={t('Meta data')} />

      <MetaDataContextProvider
        templateService={TemplateService.Documents}
        templateType={TemplateType.Document}
      >
        <FolderMetaDataForm folder={folder} onClose={onClose} />
      </MetaDataContextProvider>
    </Sidebar>
  );
};
