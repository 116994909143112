import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Text } from '@pro4all/shared/ui/typography';

import { DirectionSearchNavigation } from './DrawingSearchResults';

type TSearchPagerProps = {
  currentPageCount: number;
  handleSearchNavigation: (direction: DirectionSearchNavigation) => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  searchPagesCount: number;
};

export const SearchPager = (props: TSearchPagerProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 1,
      }}
    >
      <Text>
        {props.searchPagesCount} {t('results')} • {props.currentPageCount}{' '}
        {t('results on this page')}
      </Text>
      <Box marginLeft={2} sx={{ display: 'flex' }}>
        <IconButton
          ariaLabel={t('Previous result page')}
          color="default"
          disableBorder
          disabled={!props.hasPrevPage}
          iconName="arrowBackIos"
          onClick={() =>
            props.handleSearchNavigation(DirectionSearchNavigation.Prev)
          }
          type="button"
        />
        <IconButton
          ariaLabel={t('Next result page')}
          color="default"
          disableBorder
          disabled={!props.hasNextPage}
          iconName="arrowFowardIos"
          onClick={() =>
            props.handleSearchNavigation(DirectionSearchNavigation.Next)
          }
          type="button"
        />
      </Box>
    </Box>
  );
};
