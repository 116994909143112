import React from 'react';
import styled from 'styled-components';

import { Avatar as MUIAvatar } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

type Size = 'small' | 'large';

export interface RoundedImageProps {
  name?: string;
  size?: Size;
  src?: string;
}

export const Avatar: React.FC<RoundedImageProps> = ({
  name,
  size = 'small',
  src,
}) => (
  <StyledAvatar $size={size} src={src}>
    {(name || '-').substring(0, 2)}
  </StyledAvatar>
);

const StyledAvatar = styled(MUIAvatar)<{ $size: Size }>`
  && {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${customColors.white};
    font-size: ${({ theme, $size }) =>
      $size === 'small'
        ? theme.typography.subtitle2.fontSize
        : theme.typography.subtitle1.fontSize};
    height: ${({ $size }) => ($size === 'small' ? 1.7 : 2.6)}rem;
    width: ${({ $size }) => ($size === 'small' ? 1.7 : 2.6)}rem;
  }
`;
