import React, { RefObject, useCallback } from 'react';

import type { InitState, SetState, UseState } from '@pro4all/shared/utils';
import { unique } from '@pro4all/shared/utils';

import { Key, ToggleContext } from './useToggleContext';

export interface UseToggleProviderOptions<K extends Key = Key> {
  // DEPRECATED: `container` should be its own hook.
  // You can use `container` to portal elements to the level where the
  // ToggleProvider is instantiated.
  container?: RefObject<HTMLElement>;
  // Whether to automatically toggle first element off when `limit` is reached.
  cycle?: boolean;
  // A filter function which provides additional control for determining which
  // toggles are on when using `setToggles`
  filter?: (key: K) => boolean;
  // The initial state of which toggles are on.
  initial?: InitState<K[]>;
  // Limit the amount of items which can be toggled on.
  limit?: number;
  // A callback you can use to perform additional work when an item is toggled.
  onToggle?: (key?: K, toggled?: boolean) => void;
  // A `useState` dependency injection. By default we utilize `React.useState`
  // but you can provide your own `useState` hooks, as long as they conform to
  // the same interface as `useState`.
  useState?: UseState<K[]>;
}

/**
 * This creates a stateful array which can keep track of `toggles`.
 * It exports an interface for setting and mutating toggles.
 */
export function useToggleProvider<K extends Key>({
  container,
  cycle = false,
  filter,
  initial = [],
  limit = Infinity,
  onToggle,
  useState = React.useState,
}: UseToggleProviderOptions<K> = {}): ToggleContext<K> {
  const constrain = useCallback(
    (toggles: K[]) => {
      toggles = filter ? toggles.filter(filter) : toggles;
      if (toggles.length > limit) cycle ? toggles.shift() : toggles.pop();
      return toggles;
    },
    [cycle, filter, limit]
  );

  const [toggles, _setToggles] = useState(
    constrain(unique(initial instanceof Function ? initial() : initial))
  );

  const toggled = (key: K) => toggles.includes(key);

  const setToggles: SetState<K[]> = useCallback(
    (stateArg) => {
      _setToggles((prevToggles) => {
        const nextToggles =
          stateArg instanceof Function ? stateArg(prevToggles) : stateArg;

        return constrain(unique(nextToggles));
      });
    },
    [constrain]
  );

  const toggle = (key: K, force?: boolean) => {
    if (!cycle && toggles.length >= limit) return;

    _setToggles((toggles) => {
      // If cycle is set, the first toggle will be shifted out.
      if (cycle && toggles.length >= limit) toggles.shift();

      // Having `force` set ignores any `filter` applied, reasoning being:
      // `force` should _really_ force a setting, regardless of other effects.
      if (force === true) return [...toggles, key];
      if (force === false) return toggles.filter((_key) => _key !== key);

      // We don't want to use toggled(key) here, since we use a Dispatch
      // function: we need the most recent value from our state.
      const toggled = toggles.includes(key);
      const nextToggles = toggled
        ? toggles.filter((_key) => _key !== key)
        : [...toggles, key];

      return constrain(nextToggles);
    });

    if (onToggle) onToggle(key, toggles.includes(key));
  };

  return {
    container,
    setToggles,
    toggle,
    toggled,
    toggles,
  };
}
