import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  TextField,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import { TFileParams } from '../shared-select-snag-icon';

import { CustomIconUpload } from './CustomIconUpload';
import { MuiIconList } from './MuiIconList';

type TIconListProps = {
  onIconSelect: (iconName?: TMuiIcon, fileParams?: TFileParams) => void;
  selectedIcon?: TMuiIcon;
};

export const IconList = (props: TIconListProps) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [searchValue, setSearchValue] = useState('');
  const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSearchValue(event?.target?.value || '');
  };

  const isCustomSnagImagesEnabled = useFeatureFlag('custom-snag-images');

  return (
    <Box
      sx={{
        left: 0,
        position: 'absolute',
        top: '42px',
        width: '488px',
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Paper>
        <Box padding={4}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Tabs onChange={handleChange} value={tabValue}>
                <Tab
                  label="Icons"
                  onClick={(event) => event.stopPropagation()}
                />
                {isCustomSnagImagesEnabled && (
                  <Tab
                    label="Custom"
                    onClick={(event) => event.stopPropagation()}
                  />
                )}
              </Tabs>
            </Box>
            {props.selectedIcon && (
              <Box>
                <Button
                  color="inherit"
                  onClick={() => props.onIconSelect(undefined)}
                  size="small"
                >
                  {t('Remove')}
                </Button>
              </Box>
            )}
          </Box>
          <Box mb={2} mt={1}>
            <TextField
              InputProps={{
                startAdornment: (
                  <Box padding={1}>
                    <Icon iconName="search" />
                  </Box>
                ),
              }}
              fullWidth
              onChange={handleOnchange}
              onClick={(event) => event.stopPropagation()}
              placeholder={t('Search')}
              value={searchValue}
            />
          </Box>
          <Box>
            {tabValue === 0 && (
              <MuiIconList
                filter={searchValue}
                onIconClick={props.onIconSelect}
              />
            )}
            {tabValue === 1 && (
              <CustomIconUpload onUpload={props.onIconSelect} />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
