import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteGroups } from '../useDeleteGroups';

export const ProjectGroupsActionBar: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');
  const deleteGroups = useDeleteGroups();

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: 'delete-group',
      disabled: userCannotUpdateProject,
      key: 'delete-group',
      label: t('Delete'),
      onClick: deleteGroups,
      startIcon: 'delete',
    },
  ];

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('New group'),
      dataTestId: 'new-group',
      disabled: userCannotUpdateProject,
      key: 'new-group',
      label: t('New group'),
      onClick: () => {
        searchParams.delete('name');
        searchParams.set({ action: 'createGroup' });
      },
      startIcon: 'groupAdd',
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="groups-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
