import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const DeleteOwnFile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M9.56197 14.835C10.9197 14.835 12.0195 13.7353 12.0195 12.3775C12.0195 11.0198 10.9197 9.92004 9.56197 9.92004C8.2042 9.92004 7.10447 11.0198 7.10447 12.3775C7.10447 13.7353 8.2042 14.835 9.56197 14.835ZM9.56197 16.0638C7.92159 16.0638 4.64697 16.8871 4.64697 18.5213V19.1357C4.64697 19.4736 4.92344 19.75 5.26135 19.75H13V16.8394C11.9177 16.323 10.4825 16.0638 9.56197 16.0638Z"
      fillRule="evenodd"
    />
    <path d="M14.5884 21.7752C14.5884 22.5863 15.2237 23.25 16.0001 23.25H20.2353C21.0117 23.25 21.647 22.5863 21.647 21.7752V14.4009H14.5884V21.7752Z" />
    <path d="M21.6468 12.1886H20.2351L19.7339 11.665C19.6069 11.5323 19.4234 11.4512 19.2398 11.4512H16.9952C16.8117 11.4512 16.6281 11.5323 16.5011 11.665L15.9999 12.1886H14.5882C14.2 12.1886 13.8823 12.5204 13.8823 12.926C13.8823 13.3316 14.2 13.6635 14.5882 13.6635H21.6468C22.035 13.6635 22.3527 13.3316 22.3527 12.926C22.3527 12.5204 22.035 12.1886 21.6468 12.1886Z" />
    <path
      clipRule="evenodd"
      d="M4.39697 1.25C2.87803 1.25 1.65723 2.49043 1.65697 3.99953L1.64697 20C1.64697 21.5093 2.86787 22.75 4.38697 22.75H13V21.25H4.38697C3.70615 21.25 3.1471 20.6906 3.14697 20L3.15697 4C3.15697 3.30931 3.71607 2.75 4.39697 2.75H11.567C11.8996 2.75 12.2142 2.8818 12.4396 3.11326L17.2766 7.95038L17.2838 7.95735C17.5152 8.18273 17.647 8.49739 17.647 8.83V10H19.147V8.83C19.147 8.1044 18.8601 7.40075 18.3341 6.88645L13.5105 2.0629C12.9962 1.53684 12.2926 1.25 11.567 1.25H4.39697ZM11.397 3.5V8C11.397 8.55 11.847 9 12.397 9H16.897L11.397 3.5Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
