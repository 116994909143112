import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useTemplateFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Title', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
