import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const HierarchicalList: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M10.5 2C9.67157 2 9 2.67157 9 3.5V6.5C9 7.32843 9.67157 8 10.5 8H11V11H5C3.89 11 3 11.89 3 13V16H2.5C1.67157 16 1 16.6716 1 17.5V20.5C1 21.3284 1.67157 22 2.5 22H5.5C6.32843 22 7 21.3284 7 20.5V17.5C7 16.6716 6.32843 16 5.5 16H5V13H11V16H10.5C9.67157 16 9 16.6716 9 17.5V20.5C9 21.3284 9.67157 22 10.5 22H13.5C14.3284 22 15 21.3284 15 20.5V17.5C15 16.6716 14.3284 16 13.5 16H13V13H19V16H18.5C17.6716 16 17 16.6716 17 17.5V20.5C17 21.3284 17.6716 22 18.5 22H21.5C22.3284 22 23 21.3284 23 20.5V17.5C23 16.6716 22.3284 16 21.5 16H21V13C21 11.89 20.11 11 19 11H13V8H13.5C14.3284 8 15 7.32843 15 6.5V3.5C15 2.67157 14.3284 2 13.5 2H10.5Z" />
    </g>
  </SvgIcon>
);
