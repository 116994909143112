/*
 * This is a temporary variation of a number input with +/- controls
 * Reason being that our current layout design does not account for these controls
 * Once the designs are updated for consistency, we should update the existing TextField component
 * */
import React from 'react';

import { Box, TextField } from '@pro4all/shared/mui-wrappers';

import { TextFieldProps } from './TextField';

export const NumberInput: React.FC<TextFieldProps> = React.forwardRef(
  ({ InputProps, label, ...rest }, ref) => (
    <Box display="flex" flex={1}>
      <TextField
        {...rest}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...InputProps,
        }}
        autoComplete="off"
        fullWidth
        id={rest.name}
        label={label}
        ref={ref}
        type="number"
        variant="outlined"
      />
    </Box>
  )
);
