import styled from 'styled-components';

import { Text } from '@pro4all/shared/ui/typography';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};

  @media print {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const HeaderLeft = styled.div`
  width: 30%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
export const HeaderMiddle = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
`;
export const HeaderRight = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderTitle = styled(Text)`
  && {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
`;
