import type { RemoveStampInput, Stamp, StampParams } from '@pro4all/graphql';
import { StampType } from '@pro4all/graphql';

import { Rect } from '../shape';
import * as Stamps from '../stamps';

import type {
  Canvas as CanvasState,
  Shape as ShapeState,
  StamperDocument,
} from './state';

export const toShape =
  ({ height, width }: CanvasState) =>
  ({
    id,
    scaleY,
    scaleX,
    offsetX,
    offsetY,
    type,
  }: Partial<Stamp>): ShapeState => {
    const Stamp = Stamps[type ?? StampType.QrCode];
    return {
      children: <Stamp />,
      id,
      rect: new Rect({
        constrainProportions: true,
        height: scaleY ? height * scaleY : 100,
        width: scaleX ? width * scaleX : 100,
        // See comment below on `toStampInput`. We need to invert the offsets we
        // get back from BE and also take into account scaleY in order to let the
        // shape origin correspond to top/left positioning in browsers.
        x: offsetX ? width * (1 - offsetX) : 10,
        y: offsetY && scaleY ? height * (1 - (offsetY + scaleY)) : 10,
      }),
      type,
    };
  };

export const toStampInput =
  ({ height, width }: CanvasState) =>
  ({ id, rect }: ShapeState): StampParams => ({
    // 0,0 == bottom-right in the API and an object on the PDF is drawn towards
    // top-right, so we need to pass in the inverted x/y offsets of the
    // bottom-left anchor of our shape, relative to the canvas.
    offsetX: 1 - rect.left / width,
    offsetY: 1 - rect.bottom / height,

    scaleX: rect.width / width,
    scaleY: rect.height / height,
    stampId: id,
  });

export const toRemoveStampInput = (
  documentId: StamperDocument['documentId'],
  stamps: Stamp[] = []
): RemoveStampInput[] =>
  stamps
    .filter(({ id, type }) => id && type === StampType.QrCode)
    .map(({ id }) => ({ documentId, id }));
