import { useMemo } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import type { LocationState } from 'history';

import { RouteParams, Routes } from '@pro4all/shared/config';

import type { GenerateRouteOptions } from './generateRoute';
import { generateRoute } from './generateRoute';
import { generateUrl } from './generateUrl';

export type GoToRoute = keyof typeof Routes;
type GoToOptions<State extends LocationState> = GenerateRouteOptions & {
  next?: 'push' | 'replace';
  state?: State;
};

export function useRouting<State extends LocationState = LocationState>(
  disableMemoizedSearchParams?: boolean
) {
  const history = useHistory<State>();
  const location = useLocation<State>();
  const match = useRouteMatch();
  const params = useParams<RouteParams>();

  // Workaround: searchParams should not be memoized. What if we actually want to rerender when it changes?
  // I don't want to break anything, so I will introduce a hook arg to use searchParams without memo:
  const _searchParams = new RoutingSearchParams(history, location);

  const searchParams = useMemo(
    () => new RoutingSearchParams(history, location),
    [history, location]
  );
  const url = `${location.pathname}${location.search}${location.hash}`;

  const goBack = () => {
    history.goBack();
  };

  const goTo = (
    route: GoToRoute | GoToOptions<State>,
    { next = 'push', params, state, ...options }: GoToOptions<State> = {}
  ) => {
    const go =
      history[(typeof route === 'string' ? next : route.next) ?? 'push'];

    const nextUrl = makeUrl(route, { params, ...options });
    const nextState = typeof route === 'string' ? state : route.state;

    if (nextUrl !== url || nextState !== location.state) go(nextUrl, nextState);
  };

  const makeUrl = (
    route: GoToRoute | GenerateRouteOptions,
    options: GenerateRouteOptions = {}
  ) =>
    typeof route === 'string'
      ? generateRoute(route, options)
      : generateUrl(match.path, {
          params: { ...params, ...route.params },
          searchParams: { ...searchParams.toObject(), ...route.searchParams },
        });

  return {
    goBack,
    goTo,
    makeUrl,
    params,
    searchParams: disableMemoizedSearchParams ? _searchParams : searchParams,
    state: location.state,
    url,
  };
}

type RouterHistory = ReturnType<typeof useHistory>;
type RouterLocation = ReturnType<typeof useLocation>;

class RoutingSearchParams extends URLSearchParams {
  private readonly history: RouterHistory;
  private readonly location: RouterLocation;

  constructor(history: RouterHistory, location: RouterLocation) {
    super(location.search);
    this.history = history;
    this.location = location;
  }

  clear() {
    // HACK: somehow keys are skpped at certain times. I think this is due to
    // searchParams reference not being updated to reflect the actual params.
    this.forEach((value, key) => this.delete(key));
    // This means we cannot use this.go() and need to explicitly set search to
    // undefined.  Replace the following with this.go() and the test fails.
    this.history.push({
      ...this.location,
      search: undefined,
    });
  }

  is(name: string, value: string) {
    return this.get(name) === value;
  }

  set(
    params: string | Record<string, string | number | boolean | undefined>,
    value?: string
  ) {
    this.update(params, value);

    this.go();
  }

  replace(
    params: string | Record<string, string | number | boolean | undefined>,
    value?: string
  ) {
    this.update(params, value);
    this.go('replace');
  }

  delete(name: string) {
    super.delete(name);
    this.go();
  }

  deleteMultiple(names: string[]) {
    names.forEach((name) => super.delete(name));
    this.go();
  }

  toObject() {
    const searchParams: Record<string, string> = {};
    // URLSearchParams does not expose array interface methods, hence
    // the 'ugly' forEach to object.
    this.forEach((value, name) => {
      searchParams[name] = value;
    });
    return searchParams;
  }

  private go(method: 'push' | 'replace' = 'push') {
    // location.search prepends the questionmark (?) so we slice it off
    if (this.location.search.slice(1) === this.toString()) return;

    this.history[method]({
      ...this.location,
      search: this.toString(),
    });
  }

  private update(
    params: string | Record<string, string | number | boolean | undefined>,
    value?: string
  ) {
    if (typeof params === 'string') {
      if (value === null || value === undefined) super.delete(params);
      else super.set(params, value);
    } else {
      Object.entries(params).forEach(([name, value]) => {
        if (value === null || value === undefined) super.delete(name);
        else super.set(name, value.toString());
      });
    }
  }
}
