import { FolderPermission } from '@pro4all/graphql';

import { getDisabledPermissionsCount } from '../../shared/helpers/getDisabledPermissionsCount';
import { getExclusivePermissionsBasedOnParentPermissions } from '../../shared/helpers/getExclusivePermissionsBasedOnParentPermissions';
import { NOT_INCLUDED_PERMISSONS } from '../../shared/types';
import { getNonConflictingPermissions } from '../helpers/getNonConflictingPermissions';
import { getOtherFolderPermissions } from '../helpers/getOtherFolderPermissions';
import { getThisFolderPermission } from '../helpers/getThisFolderPermission';
import { getUpdatedSavePermissions } from '../helpers/getUpdatedSavePermissions';
import { getUpdatedSubfoldersAllPermissions } from '../helpers/getUpdatedSubfoldersAllPermissions';
import { State } from '../types';

const filterPermissions = (permissions: FolderPermission[]) =>
  permissions.filter((p) => !NOT_INCLUDED_PERMISSONS.includes(p));

const allPermissions = Object.values(FolderPermission);

export const toggleAllPermissionsAction = ({
  folderId,
  state,
}: {
  folderId: string;
  state: State;
}) => {
  const otherFolderPermissions = getOtherFolderPermissions(
    folderId,
    state.displayPermissions
  );
  const thisFolderPermission = getThisFolderPermission(
    folderId,
    state.displayPermissions
  );

  // Might be that subfolder permissions block permissions of the parent folder.
  // In that case these blocking permissions should be included in the count to determine whether all permissions are checked.
  const disabledPermissionsCount = getDisabledPermissionsCount({
    permissions: thisFolderPermission.subFoldersPermissions.map(
      (subFolderPermission) => subFolderPermission.permission
    ),
  });

  // If not all permissions are there yet we include the missing.
  // If all permissions are there, we remove them all.
  let newPermissions =
    filterPermissions(thisFolderPermission.folderPermissions).length +
      disabledPermissionsCount !==
    filterPermissions(allPermissions).length
      ? filterPermissions(allPermissions)
      : [];

  if (!thisFolderPermission.breakInheritance) {
    // In case the user wants to disable all permissions,
    // we should check per individual permission whether
    // that permission is disabled in the parent folder (if any)
    const parentFolder = getThisFolderPermission(
      thisFolderPermission.parentFolderId,
      state.displayPermissions
    );

    if (newPermissions.length === 0 && parentFolder) {
      newPermissions = parentFolder.folderPermissions;
    } else if (parentFolder) {
      const { subFolderPermissions } =
        getExclusivePermissionsBasedOnParentPermissions({
          currentSubFolderPermissions: thisFolderPermission.folderPermissions,
          parentFolderPermissions: parentFolder.folderPermissions,
          subFolderPermissions: newPermissions,
        });
      newPermissions = subFolderPermissions;
    }
  }

  // Filter out the conflicting permissions.
  newPermissions =
    !thisFolderPermission.parentFolderId ||
    thisFolderPermission.breakInheritance
      ? newPermissions
      : getNonConflictingPermissions({
          folderId,
          newPermissions,
          state,
        });

  const updatedFolderPermission = {
    ...thisFolderPermission,
    folderPermissions: newPermissions,
  };

  // Subfolder permissions may also change in case a permission of a parent folders is clicked.
  const folderPermissionsWithInheritance = getUpdatedSubfoldersAllPermissions({
    displayPermissions: state.displayPermissions,
    folderId,
    initialPermissionsExplicit: state.initialPermissionsExplicit,
    otherFolderPermissions,
    parent: updatedFolderPermission,
    permissions: newPermissions,
    savePermissions: state.savePermissions,
    toggleAll: true,
  });

  const savePermissionsUpdated = getUpdatedSavePermissions(
    folderPermissionsWithInheritance.find(
      (folder) => folder.folderId === folderId
    ),
    state
  );

  return {
    ...state,
    displayPermissions: folderPermissionsWithInheritance,
    savePermissions: savePermissionsUpdated,
  };
};
