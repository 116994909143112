import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  useMyProjectsQuery,
  useSetFolderProjectLinkMutation,
} from '@pro4all/graphql';
import { useYupValidationResolver } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { SearchableSelect } from '@pro4all/shared/ui/general';
import { FormFooter, Loader, Option } from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';

import { DMSItem } from './tree/types';
import { useFolderTreeContextOuter } from '.';

interface Props {
  folderId?: string;
  folderName?: string;
  folderPath?: string;
  folderProjectsIds: string[];
}

interface FormFields {
  project: Option | null;
}

const defaultValues: FormFields = {
  project: null,
};

const StyledForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LinkProject = ({
  folderId,
  folderName,
  folderPath,
  folderProjectsIds = [],
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId: currentProjectId } = useRouting().params;

  const schema = yup.object().shape({
    project: yup
      .object()
      .shape({ id: yup.string(), label: yup.string() })
      .nullable()
      .required(t('Project required.')),
  });

  const resolver = useYupValidationResolver(schema);
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    formState: { isValid, isSubmitting },
  } = useForm<FormFields>({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const [linkProject] = useSetFolderProjectLinkMutation();
  const { createDMSItem: createProjectLinkInLocalState } =
    useFolderTreeContextOuter();

  const { searchParams } = useRouting();
  const { data, loading: loadingProjects } = useMyProjectsQuery({});

  const showModal = searchParams.is('action', 'linkProjectToFolder');

  const projectOptions: Option[] =
    data?.projects
      ?.filter(
        (project) =>
          !folderProjectsIds.includes(project.id) &&
          currentProjectId !== project.id
      )
      .map((project) => ({
        id: project.id,
        label: project.name,
      })) || [];

  useEffect(() => {
    register('project');
  }, [register]);

  const onSubmit = async ({ project }: FormFields) => {
    if (!project) return;
    const response = await linkProject({
      variables: {
        folderId,
        projectInfo: { id: project.id, name: project.label },
      },
    });

    if (!response.data.setFolderProjectLink.success) {
      enqueueSnackbar(t('Something went wrong'));
      searchParams.clear();
      return;
    }

    enqueueSnackbar(
      t('Link to project "{{projectName}}" created.', {
        projectName: project.label,
      })
    );

    createProjectLinkInLocalState({
      id: response.data.setFolderProjectLink.id,
      name: project.label,
      parentFolderId: folderId,
      path: `${folderPath}/${project.label}`,
      projectId: project.id,
    } as DMSItem);

    searchParams.clear();
  };

  const handleChange = (option: Option) => {
    setValue('project', option, { shouldValidate: true });
  };

  const onClose = () => {
    searchParams.delete('action');
  };

  return (
    <FloatingModal open={showModal}>
      <FloatingModal.Header iconName="objectLinkActive">
        {t('Place and link project')}
      </FloatingModal.Header>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1} mt={2}>
          <Text>
            {t(
              'Select the project that you want to create a link for in folder'
            )}{' '}
            <b>{folderName}</b>
          </Text>
        </Box>
        {loadingProjects ? (
          <Loader />
        ) : (
          <SearchableSelect
            error={Object.entries(errors).length > 0}
            helperText={errors?.project}
            name="project"
            onChange={handleChange}
            options={projectOptions}
            placeholder={t('Select a project')}
          />
        )}

        <FormFooter
          disableSubmit={!isValid || isSubmitting}
          onClose={onClose}
          pt={2}
          sticky
        />
      </StyledForm>
    </FloatingModal>
  );
};
