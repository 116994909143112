import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Group,
  GroupsIncludeDocument,
  useDeleteGroupsMutation,
} from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { groupsIncludeProps } from './getIncludeProps';

export const useDeleteGroups = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const {
    params: { projectId },
    searchParams,
  } = useRouting();
  const [deleteGroups] = useDeleteGroupsMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<Group>();
  const groupName = searchParams.get('name');
  const {
    state: { items },
  } = useOptimisticResponseContext<Group>();
  const group = items.find((group) => group.name === groupName);
  const { track } = useAnalytics();

  return async () => {
    const ids: string[] = checkedRows.length
      ? checkedRows.map((row) => row.id)
      : [group.id];
    try {
      track(TrackingEvent.GroupDeleted, {
        groupsDeleted: ids.length,
        organizationId,
        projectId,
      });
      await deleteGroups({
        variables: { groups: ids },
      });
      enqueueSnackbar(t('Selected groups deleted'));

      const itemsMinusDeleted = items.filter((item) => !ids.includes(item.id));

      // Also update groups that include this group as subgroup
      const groupsWithDeletedSubgroup = itemsMinusDeleted.filter((item) =>
        item.subgroupIds.some((id) => ids.some((idDeleted) => idDeleted === id))
      );
      const groupsWithDeletedSubgroupMinusSubgroup =
        groupsWithDeletedSubgroup.map((item) => ({
          ...item,
          subgroupIds: item.subgroupIds.filter(
            (id) => !ids.some((idDeleted) => idDeleted === id)
          ),
        }));
      const groupsRest = itemsMinusDeleted.filter(
        (item) =>
          !item.subgroupIds.some((id) =>
            ids.some((idDeleted) => idDeleted === id)
          )
      );

      const groups = [
        ...groupsWithDeletedSubgroupMinusSubgroup,
        ...groupsRest,
      ].sort(sortBy({ key: 'name' }));

      client.writeQuery({
        data: {
          groups,
        },
        query: GroupsIncludeDocument,
        variables: {
          ...groupsIncludeProps,
          projectId: projectId ? projectId : null,
        },
      });

      uncheckAllRows();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
