import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup } from '@pro4all/shared/mui-wrappers';
import { FormikCheckbox, FormikInput } from '@pro4all/shared/ui/general';

export const ConfigureFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <FormikInput label={t('Footer text')} name="footerText" />
      <FormikCheckbox label={t('Show page numbers')} name="showPageNumbers" />
    </FormGroup>
  );
};
