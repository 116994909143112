import { useTranslation } from 'react-i18next';

import { SubscriptionLevel } from '@pro4all/graphql';
import { sortBy } from '@pro4all/shared/utils';

import { useSubscriptionContext } from '../SubscriptionContext';

export enum SubscriptionStatus {
  Expired = 'Expired',
  Trial = 'Trial',
  ValidSubscription = 'ValidSubscription',
  ValidSubscriptionCancelled = 'ValidSubscriptionCancelled',
}

export const useSubscriptionHelpers = () => {
  const { t } = useTranslation();
  const { dataLevels, dataOrg, dataOrgUsers } = useSubscriptionContext();

  const getCurrentSubscriptionStatus = () => {
    const { isTrial } = getSubscriptionLevel() || {};
    if (isTrial) return SubscriptionStatus.Trial;

    const isValidSubscriptionCancelled = Boolean(getNextSubscriptionLevel());
    if (isValidSubscriptionCancelled)
      return SubscriptionStatus.ValidSubscriptionCancelled;

    if (isExpired) return SubscriptionStatus.Expired;

    return SubscriptionStatus.ValidSubscription;
  };

  const getExpireDate = () => {
    const { expireDate } = dataOrg?.subscriptionOrganization || {};
    return expireDate;
  };

  const getSubscriptionEndDate = () => {
    const { subscriptionEndDate } = dataOrg?.subscriptionOrganization || {};
    return subscriptionEndDate;
  };

  const getNextSubscriptionLevel = () => {
    const { nextSubscriptionLevelId } = dataOrg?.subscriptionOrganization || {};
    return nextSubscriptionLevelId
      ? getSubscriptionLevel(nextSubscriptionLevelId)
      : undefined;
  };

  const getSelectableSubscriptionLevels = () =>
    dataLevels?.subscriptionLevels
      .filter((level) => level?.order >= 0)
      .sort(sortBy({ key: 'order' }));

  const getSubscriptionLevel = (subscriptionLevelId?: string) => {
    subscriptionLevelId = subscriptionLevelId
      ? subscriptionLevelId
      : dataOrg?.subscriptionOrganization.subscriptionLevelId;

    return dataLevels?.subscriptionLevels.filter(
      (level) => level.id === subscriptionLevelId
    )[0];
  };

  const getSubscriptionLevelInactive = () =>
    dataLevels?.subscriptionLevels.filter(
      (level) => level.name === 'inactive'
    )[0];

  const getSubscriptionLevelPrice = (subscriptionLevel?: SubscriptionLevel) => {
    subscriptionLevel = subscriptionLevel
      ? subscriptionLevel
      : getSubscriptionLevel()
      ? getSubscriptionLevel()
      : undefined;

    return subscriptionLevel ? subscriptionLevel.pricing : 0;
  };

  const getSubscriptionLevelTranslates = ({
    checkExpired = false,
    subscriptionLevel,
  }: {
    checkExpired?: boolean;
    subscriptionLevel?: SubscriptionLevel;
  }) => {
    if (checkExpired && isExpired)
      return {
        description: t('SUBSCRIPTION-INACTIVE-DESCRIPTION'),
        title: t('SUBSCRIPTION-INACTIVE-TITLE'),
      };

    subscriptionLevel = subscriptionLevel
      ? subscriptionLevel
      : getSubscriptionLevel()
      ? getSubscriptionLevel()
      : undefined;

    const transSubtitle =
      subscriptionLevel &&
      `SUBSCRIPTION-${subscriptionLevel.name}-SUBTITLE`.toUpperCase();

    const bullets: string[] = [];
    const bulletsAmount = [1, 2, 3, 4, 5, 6];
    bulletsAmount.forEach((no) => {
      const bullet =
        subscriptionLevel &&
        `SUBSCRIPTION-${subscriptionLevel.name}-BULLET${no}`.toUpperCase();
      const transKeyBullet = bullet && t(bullet) !== bullet && t(bullet);
      if (transKeyBullet) bullets.push(t(transKeyBullet));
    });

    return subscriptionLevel
      ? {
          bullets,
          description: t(
            `SUBSCRIPTION-${subscriptionLevel.name}-DESCRIPTION`.toUpperCase()
          ),
          subtitle:
            transSubtitle &&
            t(transSubtitle) !== transSubtitle &&
            t(transSubtitle),
          title: t(
            `SUBSCRIPTION-${subscriptionLevel.name}-TITLE`.toUpperCase()
          ),
        }
      : null;
  };

  const getUniqueActiveUsers = () => dataOrgUsers?.users;
  const getPricePerUnit = () =>
    dataOrgUsers?.pricePerUnit ?? getSubscriptionLevelPrice;
  const isFixedPrice = dataOrgUsers?.isFixedPrice ?? false;

  const isCurrentSubscription = (subscriptionLevel: SubscriptionLevel) => {
    const { subscriptionLevelId } = dataOrg?.subscriptionOrganization || {};
    return subscriptionLevel?.id === subscriptionLevelId;
  };

  const isExpired = new Date(getExpireDate()) < new Date();
  const isCancelled = Boolean(getSubscriptionEndDate());

  const isDowngraded = () => {
    const { nextSubscriptionLevelId, subscriptionLevelId } =
      dataOrg?.subscriptionOrganization || {};
    return Boolean(nextSubscriptionLevelId && subscriptionLevelId);
  };

  const isTrial = () => {
    const { isTrial } = getSubscriptionLevel() || {};
    return isTrial;
  };

  return {
    getCurrentSubscriptionStatus,
    getExpireDate,
    getNextSubscriptionLevel,
    getPricePerUnit,
    getSelectableSubscriptionLevels,
    getSubscriptionEndDate,
    getSubscriptionLevel,
    getSubscriptionLevelInactive,
    getSubscriptionLevelPrice,
    getSubscriptionLevelTranslates,
    getUniqueActiveUsers,
    isCancelled,
    isCurrentSubscription,
    isDowngraded,
    isExpired,
    isFixedPrice,
    isTrial,
  };
};
