import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Group, Member, User } from '@pro4all/graphql';
import { Chip } from '@pro4all/shared/ui/general';
import { Property, PropertyList } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledSpan = styled.span`
  max-width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const None = styled.span`
  font-style: italic;
  line-height: 1.8em;
`;

const EntityList = ({
  rows,
}: {
  icon?: IconName;
  rows: (Group | Member | User)[];
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      {!rows?.length && <None>{t('None')}</None>}
      {rows?.map((row) => (
        <StyledSpan key={row.id}>
          <Chip
            color="secondary"
            icon={row.__typename === 'User' ? 'personOutline' : 'group'}
            label={row?.displayName || t('Unknown')}
          ></Chip>
        </StyledSpan>
      ))}
    </StyledWrapper>
  );
};

interface Props {
  group: Group;
}

export const GroupProperties: React.FC<Props> = ({ group }) => {
  const { t } = useTranslation();
  const { members, subgroups } = group || {};

  if (!group) return null;

  const properties: Property[] = [
    {
      label: t('Members'),
      value: <EntityList rows={members} />,
    },
    {
      label: t('Subgroups'),
      value: <EntityList rows={subgroups} />,
    },
  ];

  return <PropertyList items={properties} orientation="horizontal" px={3} />;
};
