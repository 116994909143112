export const getContextMenuEvent = (rect: DOMRect) => {
  const hLimit = window.innerWidth - rect.width - 14;

  // Clamp left if repositioned element exceeds screen size
  const clientX = rect.right < hLimit ? rect.left : hLimit;
  const clientY = rect.bottom + 14;

  return new MouseEvent('contextmenu', {
    bubbles: true,
    clientX,
    clientY,
  });
};
