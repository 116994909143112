import { v4 as uuid } from 'uuid';

import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';

import { getLastSibling } from './helpers/getLastSibling';
import { getRootItems } from './helpers/getRootItems';
import { useHierarchyEditorContext } from './HierarchyEditorProvider';
import {
  AddData,
  AddType,
  BasePropsHierarchyItem,
  CallBackProps,
  ItemFields,
  OnClose,
} from './types';

export const useSubmitItem = <HierarchyItem extends BasePropsHierarchyItem>({
  callbackAddEdit,
  onClose,
}: Pick<CallBackProps<HierarchyItem>, 'callbackAddEdit'> & OnClose) => {
  const { searchParams } = useRouting();
  const isAddItem = searchParams.has('additem');
  const isAddFirstItem = searchParams.has('addfirstitem');
  const isAddLastItem = searchParams.has('addlastitem');
  const isEditItem = searchParams.has('edititem');

  const {
    addItem,
    editItem,
    state: { items },
  } = useHierarchyEditorContext();

  const itemsTyped = items as HierarchyItem[]; // We have to do this because TypeScript is confused and returns items typed as BasePropsHierarchyItem[].

  const { getLocalStorageItem: getAddHierarchyItemFromLs } =
    useLocalStorage<AddData>({
      key: `prostream-add-hierarchy-item`,
    });

  const { getLocalStorageItem: getEditHierarchyItemFromLs } =
    useLocalStorage<AddData>({
      key: `prostream-edit-hierarchy-item`,
    });

  const dataAddItem = getAddHierarchyItemFromLs() as AddData;
  const dataEditItem = getEditHierarchyItemFromLs() as AddData;

  return async (values: ItemFields) => {
    const { name } = values;

    if (callbackAddEdit) {
      callbackAddEdit({
        dataAddItem,
        dataEditItem,
        isAddFirstItem,
        isAddItem,
        isAddLastItem,
        isEditItem,
        items: itemsTyped,
        name,
      });
    } else {
      if (isAddFirstItem) {
        const newItem: BasePropsHierarchyItem = {
          id: uuid(),
          name,
          parentNodeId: null,
          previousNodeId: null,
        };
        addItem(newItem);
      } else if (isAddLastItem) {
        const rootItems = getRootItems<HierarchyItem>({ items: itemsTyped });
        const lastRootItem = getLastSibling<HierarchyItem>({
          items: rootItems,
        });
        const newItem: BasePropsHierarchyItem = {
          id: uuid(),
          name,
          parentNodeId: null,
          previousNodeId: lastRootItem.id,
        };
        addItem(newItem);
      } else if (isAddItem && dataAddItem) {
        if (dataAddItem.addType === AddType.CHILD) {
          const newItem: BasePropsHierarchyItem = {
            id: uuid(),
            name,
            parentNodeId: dataAddItem.sourceId,
            previousNodeId: null,
          };
          addItem(newItem);
        } else if (dataAddItem.addType === AddType.SIBLING) {
          const newItem: BasePropsHierarchyItem = {
            id: uuid(),
            name,
            parentNodeId: dataAddItem.sourceParentId,
            previousNodeId: dataAddItem.sourceId,
          };
          addItem(newItem);
        }
      } else if (isEditItem && dataEditItem) {
        // Find the edited item in the current list of items.
        const editedItem = itemsTyped.find(
          (item) => item.id === dataEditItem.sourceId
        );
        if (editedItem) {
          const updatedItem: BasePropsHierarchyItem = {
            ...editedItem,
            name,
          };
          editItem(updatedItem);
        }
      }
    }

    onClose();
  };
};
