import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Number: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M10.7587 3.01965C10.217 2.91191 9.69054 3.26367 9.5828 3.80535L6.46135 19.4979C6.35361 20.0396 6.70537 20.566 7.24705 20.6738C7.78872 20.7815 8.31518 20.4298 8.42292 19.8881L11.5444 4.19553C11.6521 3.65385 11.3003 3.1274 10.7587 3.01965Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.7587 3.01965C15.217 2.91191 14.6905 3.26367 14.5828 3.80535L11.4614 19.4979C11.3536 20.0396 11.7054 20.566 12.247 20.6738C12.7887 20.7815 13.3152 20.4298 13.4229 19.8881L16.5444 4.19553C16.6521 3.65385 16.3003 3.1274 15.7587 3.01965Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M19.9996 14.353C19.9961 14.9052 19.5455 15.3501 18.9933 15.3466L2.9936 15.2451C2.44132 15.2416 1.99646 14.7911 1.99996 14.2388C2.00346 13.6865 2.45401 13.2416 3.00628 13.2452L19.006 13.3466C19.5582 13.3502 20.0031 13.8007 19.9996 14.353Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M21.0062 9.45502C21.0027 10.0073 20.5521 10.4522 19.9999 10.4487L4.00019 10.3472C3.44791 10.3437 3.00305 9.89311 3.00655 9.34084C3.01005 8.78856 3.4606 8.3437 4.01287 8.3472L20.0126 8.4487C20.5648 8.4522 21.0097 8.90275 21.0062 9.45502Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
