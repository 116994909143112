import React from 'react';

import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { isDefined } from '@pro4all/shared/utils';

import { TagSelect } from './TagSelect';

export const QCSelectFilter: React.FC<FilterBaseProps> = ({
  value,
  type,
  facetGroup,
  facetLoading,
}) => {
  const options: Option[] =
    facetGroup?.items?.filter(isDefined).map((facetItem) => ({
      iconName: 'personOutline',
      id: facetItem.value,
      inputValue: facetItem.value,
      label: facetItem.value,
    })) || [];

  const labels = value.split(delimiters.multiSelectOptions);
  const initialOptions = options.filter((option) =>
    labels.includes(option.label)
  );

  return (
    <TagSelect
      initialOptions={initialOptions}
      loading={facetLoading}
      options={options}
      type={type}
      value={value}
    />
  );
};
