import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputAdornment } from '@pro4all/shared/mui-wrappers';
import { TextField } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { SearchFieldWrapperDialog } from '@pro4all/shared/ui/wrappers';

export const useSearchInput = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const searchInput = (
    <SearchFieldWrapperDialog>
      <TextField
        InputProps={{
          startAdornment: (
            <StartAdornment position="start">
              <Icon iconName="search" />
            </StartAdornment>
          ),
        }}
        id="query"
        name="query"
        onChange={(e) => setQuery(e.currentTarget.value)}
        placeholder={t('Search')}
        role="search"
        type="text"
        value={query}
      />
    </SearchFieldWrapperDialog>
  );

  return { query, searchInput, setQuery };
};

const StartAdornment = styled(InputAdornment)`
  && {
    margin-right: 0;
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;
