import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ReadFolder: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM9.5 13C10.365 10.805 12.5 9.25 15 9.25C17.5 9.25 19.635 10.805 20.5 13C19.635 15.195 17.5 16.75 15 16.75C12.5 16.75 10.365 15.195 9.5 13ZM12.5 13C12.5 14.38 13.62 15.5 15 15.5C16.38 15.5 17.5 14.38 17.5 13C17.5 11.62 16.38 10.5 15 10.5C13.62 10.5 12.5 11.62 12.5 13ZM15 11.5C14.17 11.5 13.5 12.17 13.5 13C13.5 13.83 14.17 14.5 15 14.5C15.83 14.5 16.5 13.83 16.5 13C16.5 12.17 15.83 11.5 15 11.5Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
