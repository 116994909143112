import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const Header = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ExampleSample = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ExampleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  background-color: ${customColors.grey300};
  min-height: 46px;
  border-radius: 8px;
`;

export const ExampleCardContainer = styled.div`
  display: flex;
`;

export const DropZone = styled.div`
  width: 4px;
  background-color: ${customColors.grey500};
  margin: 0 8px 0 0;
`;
