import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Resend: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 24 16"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.593738 15.591C0.893738 15.7827 1.2104 15.8118 1.54374 15.6785L7.96702 12.9674C8.21264 11.5806 8.8649 10.3415 9.90456 9.3018C11.256 7.95036 12.9444 7.25351 14.8563 7.25351C15.753 7.25351 16.6231 7.42414 17.4525 7.76254C17.3503 7.57744 17.1807 7.43277 16.9437 7.32851L1.54374 0.828511C1.2104 0.695178 0.893738 0.724345 0.593738 0.916011C0.293738 1.10768 0.143738 1.38684 0.143738 1.75351V6.25351L8.14374 8.25351L0.143738 10.2535V14.7535C0.143738 15.1202 0.293738 15.3993 0.593738 15.591Z"
        fill="black"
        fillOpacity="0.56"
      />
      <path
        d="M14.8563 19.2535C15.8063 19.2535 16.6729 19.016 17.4563 18.541C18.2396 18.066 18.8479 17.4285 19.2813 16.6285C19.3979 16.4285 19.4188 16.216 19.3438 15.991C19.2688 15.766 19.1229 15.6118 18.9063 15.5285C18.7229 15.4452 18.5396 15.4452 18.3563 15.5285C18.1729 15.6118 18.0313 15.7452 17.9313 15.9285C17.6313 16.4785 17.2063 16.9202 16.6563 17.2535C16.1063 17.5868 15.5063 17.7535 14.8563 17.7535C13.8896 17.7535 13.0646 17.4118 12.3813 16.7285C11.6979 16.0452 11.3563 15.2202 11.3563 14.2535C11.3563 13.2868 11.6979 12.4618 12.3813 11.7785C13.0646 11.0952 13.8896 10.7535 14.8563 10.7535C15.3229 10.7535 15.7646 10.8368 16.1813 11.0035C16.5979 11.1702 16.9729 11.4202 17.3063 11.7535H16.6063C16.3896 11.7535 16.2104 11.8243 16.0688 11.966C15.9271 12.1077 15.8563 12.2868 15.8563 12.5035C15.8563 12.7202 15.9271 12.8993 16.0688 13.041C16.2104 13.1827 16.3896 13.2535 16.6063 13.2535H18.8563C19.1396 13.2535 19.3771 13.1577 19.5688 12.966C19.7604 12.7743 19.8563 12.5368 19.8563 12.2535V10.0035C19.8563 9.78684 19.7854 9.60768 19.6438 9.46601C19.5021 9.32434 19.3229 9.25351 19.1063 9.25351C18.8896 9.25351 18.7104 9.32434 18.5688 9.46601C18.4271 9.60768 18.3563 9.78684 18.3563 10.0035V10.6785C17.8729 10.2285 17.3313 9.87851 16.7313 9.62851C16.1313 9.37851 15.5063 9.25351 14.8563 9.25351C13.4729 9.25351 12.2938 9.74101 11.3188 10.716C10.3438 11.691 9.85626 12.8702 9.85626 14.2535C9.85626 15.6368 10.3438 16.816 11.3188 17.791C12.2938 18.766 13.4729 19.2535 14.8563 19.2535Z"
        fill="black"
        fillOpacity="0.56"
      />
    </svg>
  </SvgIcon>
);
