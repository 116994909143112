/**
 * Do not edit directly
 * Generated on Wed, 25 Oct 2023 13:48:46 GMT
 */

export const GlobalH1TitleFontWeight = '900';
export const GlobalH1TitleFontFamily = 'Roboto';
export const GlobalH1TitleFontSize = '24px';
export const GlobalH2TitleFontFamily = 'Roboto';
export const GlobalH2TitleFontWeight = '700';
export const GlobalH2TitleFontSize = '20px';
export const GlobalH3TitleFontFamily = 'Roboto';
export const GlobalH3TitleFontWeight = '500';
export const GlobalH3TitleFontSize = '18px';
export const GlobalH4TitleFontFamily = 'Roboto';
export const GlobalH4TitleFontWeight = '500';
export const GlobalH4TitleFontSize = '16px';
export const GlobalH5TitleFontFamily = 'Roboto';
export const GlobalH5TitleFontWeight = '500';
export const GlobalH5TitleFontSize = '14px';
export const GlobalH6TitleFontFamily = 'Roboto';
export const GlobalH6TitleFontWeight = '500';
export const GlobalH6TitleFontSize = '13px';
export const GlobalBody1FontFamily = 'Roboto';
export const GlobalBody1FontWeight = '400';
export const GlobalBody1FontSize = '13px';
export const GlobalBody2FontFamily = 'Roboto';
export const GlobalBody2FontWeight = '400';
export const GlobalBody2FontSize = '12px';
export const GlobalButtonLargeFontFamily = 'Roboto';
export const GlobalButtonLargeFontWeight = '400';
export const GlobalButtonLargeFontSize = '13px';
export const GlobalCaptionFontFamily = 'Roboto';
export const GlobalCaptionFontWeight = '400';
export const GlobalCaptionFontSize = '11px';
export const GlobalNotBlack = '#212121';
export const GlobalCharcoalGrey = '#585858';
export const GlobalSpacing8 = '6px';
export const GlobalSpacing4 = '3px';
export const GlobalJustWhite = '#ffffff';
