import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  useDocument,
  useUserFolderPermissions,
} from '@pro4all/documents/data-access';
import { DocumentComments } from '@pro4all/documents/ui/comments';
import { DocumentHistoryTab } from '@pro4all/documents/ui/history';
import { useDocumentsContext } from '@pro4all/documents/ui/share';
import {
  Document,
  DocumentAction,
  DocumentVersion,
  Task,
} from '@pro4all/graphql';
import { TaskListDisplay } from '@pro4all/shared/linked-tasks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import { FileUploadOverlay } from '@pro4all/shared/ui/file-upload';
import {
  Loader,
  OptimisticResponseProvider,
  Sidebar,
  useOptimisticResponseContext,
  useSetItemInLocalState,
} from '@pro4all/shared/ui/general';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { useLinkedTasks } from '@pro4all/workflow/data-access';
import { TaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import {
  DocumentProperties,
  FolderPicked,
} from './document-properties/DocumentProperties';
import { DocumentSidebarTabs } from './tabs/DocumentSidebarTabs';

export enum SidebarContent {
  document = 'Document',
  version = 'Version',
}

interface Props {
  documentId?: Document['id'];
  folder?: FolderPicked;
  getRootProps?: () => void;
  isDragActive?: boolean;
  isSmartFolder?: boolean;
  onClose?: () => void;
  sidebarActions?: ActionProps[];
  sidebarContent?: SidebarContent;
  versionId?: Document['versionId'];
}

const FileUploadOverlayWrapper = styled.div`
  height: 100%;
`;

const SidebarWrapper = styled.div<{ $fullHeight?: boolean }>`
  width: 100%;
  height: ${({ $fullHeight }) => ($fullHeight ? '100vh' : 'auto')};
`;

export const DocumentSidebar: React.FC<Props> = ({
  documentId,
  folder,
  getRootProps,
  isDragActive = false,
  isSmartFolder = false,
  onClose,
  sidebarActions,
  sidebarContent = SidebarContent.document,
  versionId,
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const initialTab = searchParams.get('tab') || 'props';
  const isSearchResults = Boolean(
    searchParams.get('search') || searchParams.get('filters')
  );
  const isFocusComment = Boolean(searchParams.get('focusComment'));
  const { currentView } = useDataViewContext();
  const [currentTab, setCurrentTab] = useState(initialTab);
  const { versionPaneExpanded } = useDocumentsContext();

  const { document, error, loading, version } = useDocument();
  const { linkedTasks, loading: linkedTaskLoading } = useLinkedTasks();

  const {
    state: { item },
  } = useOptimisticResponseContext<Document | DocumentVersion>();

  useSetItemInLocalState<Document | DocumentVersion>(version || document);

  // We have several places with a column called 'Original location' (Search results, Collection folder and Smartfolder).
  // If the user click on that the system navigates to the particular folder and opens the document form there in the Document Sidebar.
  // There is a kind of race condition which causes sometimes that the useEffect in 'useSetItemInLocalState' is not triggered,
  // and therefore the prop 'item' is not set. In this case we use the 'document' or 'version' as fallback.
  // This will not cause problems, because if we change anything on 'item' it will fallback to 'item' again.
  const itemFallback = item || version || document;

  const rootProps = getRootProps ? getRootProps() : {};

  const changeTabs = (value: string) => {
    setCurrentTab(value);
    searchParams.set({ tab: value });
    if (isFocusComment) searchParams.delete('focusComment');
  };

  const viewingTask = searchParams.is('action', 'viewTask');
  const editOrViewTask =
    searchParams.is('action', 'viewTask') ||
    searchParams.is('action', 'editTask');
  const createTask = searchParams.is('action', 'createTask');
  const parametersCheck = Boolean((documentId || versionId) && !editOrViewTask);

  const versionPaneCheck =
    Boolean(
      (sidebarContent === SidebarContent.document && !versionPaneExpanded) ||
        sidebarContent === SidebarContent.version
    ) ||
    isSmartFolder ||
    isSearchResults ||
    isFocusComment ||
    currentView === DataViewType.Cards;

  const title = `${itemFallback?.name} ${
    document?.isExpected ? `(${t('Expected document')}) ` : ''
  }`;

  const handleTaskClick = (taskId: string) => {
    searchParams.set({ action: 'viewTask', id: taskId });
  };

  const { canUploadVersion, loadingFolderPermissions } =
    useUserFolderPermissions({
      folderId: folder?.id || itemFallback?.folderId || '',
    });

  const userCanUploadVersion = canUploadVersion(document);

  const loaderMode = <Loader />;
  const documentDetailsMode = (
    <FileUploadOverlayWrapper {...rootProps}>
      <FileUploadOverlay
        isActive={isDragActive && userCanUploadVersion}
        message="Let go to upload a new version"
        topMarginMessage={160}
      >
        <SidebarWrapper $fullHeight={!document}>
          <ResponseWrapper error={error} isLoading={loading}>
            <Sidebar.Header title={title}>
              <Sidebar.SidebarActionBar sidebarActions={sidebarActions || []} />
              <DocumentSidebarTabs
                onChange={(e, value) => changeTabs(value)}
                showDocLinks={Boolean(itemFallback?.projectId)}
                value={currentTab}
              />
            </Sidebar.Header>
            {itemFallback && (
              <>
                {currentTab === 'props' && (
                  <DocumentProperties
                    document={document ? (itemFallback as Document) : null}
                    folder={folder}
                    version={version ? (itemFallback as DocumentVersion) : null}
                  />
                )}

                {currentTab === 'log' &&
                  ((document && document.versionId) || version) && (
                    <DocumentHistoryTab
                      documentOrVersion={
                        version
                          ? (version as DocumentVersion)
                          : (document as Document)
                      }
                    />
                  )}

                {currentTab === 'links' && (
                  <TaskListDisplay
                    getTaskType={(task: Task) => task.documentAction ?? ''}
                    loading={linkedTaskLoading}
                    noTasksMessage="This document has not been linked to any task yet" // I18n
                    onTaskClick={handleTaskClick}
                    taskTypeEnum={DocumentAction}
                    tasks={linkedTasks as Task[]}
                  />
                )}
                {currentTab === 'comments' && (document || version) && (
                  <DocumentComments
                    documentOrVersion={
                      version
                        ? (version as DocumentVersion)
                        : (document as Document)
                    }
                  />
                )}
              </>
            )}
          </ResponseWrapper>
        </SidebarWrapper>
      </FileUploadOverlay>
    </FileUploadOverlayWrapper>
  );

  return (
    <>
      <Sidebar
        onClose={onClose ? onClose : () => null}
        open={parametersCheck && versionPaneCheck}
        wide
      >
        {itemFallback === undefined || loadingFolderPermissions
          ? loaderMode
          : documentDetailsMode}
      </Sidebar>
      {(createTask || viewingTask) && (
        <OptimisticResponseProvider<Task>>
          <TaskSidebar />
        </OptimisticResponseProvider>
      )}
    </>
  );
};
