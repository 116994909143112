import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageSelectPermissionsTarget: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t('Select a person or group to set permissions for')}
      title={t('No person or group selected')}
    />
  );
};
