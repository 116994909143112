import React from 'react';

import type { AnswerComponent } from '../AnswerView';
import { PhotoSlider } from '../components/PhotoSlider';
import { usePhotoIds } from '../hooks/usePhotoIds';

export const Media: AnswerComponent = ({ children, item: { id } }) => {
  const { photoIds } = usePhotoIds({ fieldId: id, initialValue: children });
  return <PhotoSlider fieldId={id} ids={photoIds} />;
};
