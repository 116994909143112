import React from 'react';
import styled from 'styled-components';

import { Text } from '@pro4all/shared/ui/typography';

import type { AnswerComponent } from '../AnswerView';

export const TBQRieTextArea: AnswerComponent = ({ children }) => {
  if (!children) {
    return null;
  }

  return <TextTypography variant="body1">{children}</TextTypography>;
};

const TextTypography = styled(Text)`
  && {
    max-height: 80px;
    overflow-y: auto;
    white-space: pre-line;
  }
`;
