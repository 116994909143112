import { useMemo } from 'react';

import {
  ResultSet,
  Task,
  TaskType,
  TbqAnswer,
  TbqRieResult,
  useGetTbqResultQuery,
  useGetTbqRieResultQuery,
} from '@pro4all/graphql';
import { isTbqBrandcheck, isTbqRie } from '@pro4all/workflow/ui/utils';

export const useTBQTaskQuestions = (item: Task | null) => {
  const notFilteredTaskTbqLinkedFormTaskId =
    item?.tbqLinkedQuestions?.[0]?.id ?? '';

  //This filter filter Scan form id from combined id-linkId uniqueId to enable task cache-and-network
  const existingTaskTbqLinkedFormTaskId = notFilteredTaskTbqLinkedFormTaskId
    .split('-')
    .slice(0, 5)
    .join('-');

  const taskLinkedQuestionsFieldIds =
    item?.tbqLinkedQuestions?.map((link) => link.appData) ?? [];

  const { data: rieResult, loading: loadingRieResult } =
    useGetTbqRieResultQuery({
      fetchPolicy: 'no-cache',
      skip:
        !existingTaskTbqLinkedFormTaskId || item?.type !== TaskType.TbqResolve,
      variables: { taskId: existingTaskTbqLinkedFormTaskId },
    });

  const rieFields = rieResult?.getTBQRieResult ?? [];

  const filteredRieQuestions: TbqRieResult[] = useMemo(
    () =>
      rieFields
        .filter(
          (rieField): rieField is TbqRieResult =>
            rieField !== null && rieField !== undefined
        )
        .filter((rieField) =>
          taskLinkedQuestionsFieldIds.some((id) => id === rieField?.section.id)
        ),
    [rieFields]
  );

  const { data: brandcheckResultData, loading: loadingBrandcheckResult } =
    useGetTbqResultQuery({
      fetchPolicy: 'no-cache',
      skip:
        !existingTaskTbqLinkedFormTaskId || item?.type !== TaskType.TbqResolve,
      variables: {
        resultSet: ResultSet.Victims,
        taskId: existingTaskTbqLinkedFormTaskId,
      },
    });

  const { answers = [] } = useMemo(() => {
    const emptyResult = {
      answers: [] as TbqAnswer[],
    };
    return brandcheckResultData?.getTBQResult ?? emptyResult;
  }, [brandcheckResultData?.getTBQResult]);

  const filteredBrandcheckQuestions: TbqAnswer[] = useMemo(
    () =>
      answers
        ? answers.filter((answer: TbqAnswer) =>
            taskLinkedQuestionsFieldIds.includes(answer.sectionId)
          )
        : [],
    [answers, taskLinkedQuestionsFieldIds]
  );

  let scanQuestion: TbqRieResult[] | TbqAnswer[] = [];
  if (filteredRieQuestions.length > 0 && isTbqRie(filteredRieQuestions[0])) {
    scanQuestion = filteredRieQuestions;
  } else if (
    filteredBrandcheckQuestions.length > 0 &&
    isTbqBrandcheck(filteredBrandcheckQuestions[0])
  ) {
    scanQuestion = filteredBrandcheckQuestions;
  }

  return { loading: loadingRieResult || loadingBrandcheckResult, scanQuestion };
};
