import { useEffect, useMemo, useState } from 'react';

import {
  FieldDefinition,
  QualityControlInstance,
  TaskFragmentFragment,
  TbqAnswer,
  TbqRieResult,
  ValueTypeName,
} from '@pro4all/graphql';
import { unnestInstance } from '@pro4all/metadata/ui/utils';
import { useRouting } from '@pro4all/shared/routing-utils';
import { isTbqBrandcheck, isTbqRie } from '@pro4all/workflow/ui/utils';

import { QuestionSidebarTabs } from './TbqQuestionSidebar';

export const useTbqQuestionSidebarLogic = (
  linkedTasks?: TaskFragmentFragment[],
  qcInstance?: QualityControlInstance,
  question?: TbqRieResult | TbqAnswer
) => {
  const { searchParams } = useRouting();
  const initialTab =
    searchParams.get('action') || QuestionSidebarTabs.PROPERTIES;

  const [currentTab, setCurrentTab] = useState(initialTab);

  useEffect(() => {
    setCurrentTab(initialTab);
  }, [initialTab]);

  const completedTasksCount =
    linkedTasks?.filter((task) => task.completedAt !== null).length ?? 0;
  const allTasksCount = linkedTasks?.length ?? 0;

  const questionTitle = (() => {
    if (!question) return '';
    if (isTbqRie(question)) return question.section.name;
    if (isTbqBrandcheck(question)) return question.sectionText;
    return '';
  })();

  const itemsInstance = qcInstance?.items ?? [];
  const unnestedSectionFields: FieldDefinition[] = unnestInstance(
    itemsInstance as FieldDefinition[]
  ).filter((field) => field.type === ValueTypeName.Section);

  const questionSection = useMemo(() => {
    if (isTbqBrandcheck(question)) {
      return unnestedSectionFields.find(
        (section) => section.id === question.sectionId
      );
    }
    return null;
  }, [question, unnestedSectionFields]);

  const questionFields = questionSection?.valueType?.subFields;

  const changeTabs = (value: string) => {
    searchParams.set('action', value);
    setCurrentTab(value);
  };

  return {
    allTasksCount,
    changeTabs,
    completedTasksCount,
    currentTab,
    questionFields,
    questionTitle,
    searchParams,
    setCurrentTab,
  };
};
