import React from 'react';
import { useTranslation } from 'react-i18next';

import { InstancePageHeaderProps } from '@pro4all/shared/types';

import { applyBackground, applyColor } from '../Colors';

import { Instance } from './elements';

export const InstancePageHeader = ({
  instanceId,
  items = [],
  reportOptions,
}: InstancePageHeaderProps) => {
  const { t } = useTranslation();

  if (items.length === 0) {
    return null;
  }

  return (
    <Instance.ItemList
      compactLists={reportOptions.compactLists}
      inlineLists={reportOptions.inlineLists}
    >
      {items.map((item) => {
        const { id, indicative, value, type, valueType } = item;
        const key = `${instanceId}_${id}`;

        if (value && type === 'Status' && indicative) {
          return (
            <Instance.Item key={key}>
              <Instance.Label>{t('Status')}</Instance.Label>
              <Instance.Value>
                <Instance.Status
                  background={applyBackground(
                    valueType?.options?.find((option) => option.name === value)
                      ?.color || 'grey'
                  )}
                  color={applyColor(
                    valueType?.options?.find((option) => option.name === value)
                      ?.color || 'grey'
                  )}
                >
                  {value}
                </Instance.Status>
              </Instance.Value>
            </Instance.Item>
          );
        }

        return <div key={key}></div>;
      })}
    </Instance.ItemList>
  );
};
