import React from 'react';

import { Icon, IconType } from '@pro4all/shared/ui/icons';

import * as Styled from './Styles';

export const IconHeader = ({ ...rest }: IconType) => (
  <Styled.HeaderWrapper>
    <Icon {...rest} />
  </Styled.HeaderWrapper>
);
