import { useEffect, useState } from 'react';

/**
 * Include this hook in any component you want to re-render on window resize.
 * Include it like this:
 *   import { useResizeWindow } from '@pro4all/shared/utils';
 *   const width = useResizeWindow();
 */
export const useResizeWindow = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return width;
};
