import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { SavedAnswers, useRestoreSavedAnswersMutation } from '@pro4all/graphql';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { SelectedIdName } from '@pro4all/shared/types';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

import { useApolloClientCacheHelpers } from './useApolloClientCacheHelpers';

export const useRestoreDeletedSavedAnswers = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [restoreAnswers] = useRestoreSavedAnswersMutation();
  const { updateCachedSavedAnswers } = useApolloClientCacheHelpers();

  const onRestoreDocument = async ({
    cachedSavedAnswers,
    succeeded,
  }: {
    cachedSavedAnswers: SavedAnswers[];
    succeeded: SelectedIdName[];
  }) => {
    try {
      await restoreAnswers({
        variables: {
          ids: [succeeded[0].id],
        },
      });
      updateCachedSavedAnswers(cachedSavedAnswers);
      enqueueSnackbar(
        <ItemChangedMessage
          description={MessageAction.Restore}
          entityName={succeeded[0].name}
          entityTypeTranslation={EntityTypeTranslation.SavedAnswers}
        />
      );
    } catch (e) {
      enqueueSnackbar(
        <Alert severity="error">{t('Something went wrong')}</Alert>
      );
    }
  };

  const onRestoreDocuments = async ({
    cachedSavedAnswers,
    succeeded,
  }: {
    cachedSavedAnswers: SavedAnswers[];
    succeeded: SelectedIdName[];
  }) => {
    try {
      await restoreAnswers({
        variables: {
          ids: succeeded.map((savedAnswers) => savedAnswers.id),
        },
      });
      updateCachedSavedAnswers(cachedSavedAnswers);
      enqueueSnackbar(
        <Alert>
          {t('Restored {{count}} saved answers', {
            count: succeeded.length,
          })}
        </Alert>
      );
    } catch (e) {
      enqueueSnackbar(
        <Alert severity="error">{t('Something went wrong')}</Alert>
      );
    }
  };

  return { onRestoreDocument, onRestoreDocuments };
};
