import { useMemo } from 'react';

import {
  FieldDefinition,
  FormLinkedSnag,
  QualityControlInstanceType,
} from '@pro4all/graphql';
import { AnswerEditFormik, SectionsAndFieldsList } from '@pro4all/metadata/ui';
import { unnestInstance } from '@pro4all/metadata/ui/utils';
import { useDiscardModalContext } from '@pro4all/shared/ui/messages';

import { useCountFormAnsweredFieldsPercentage } from '../form-answers-percentages/useCountFormAnsweredFieldsPercentage';

import { useGetFilteredSectionsCategoriesAndQuestions } from './useGetFilteredSectionsCategoriesAndQuestions';

export const useGetSectionsAndFields = ({
  edit,
  filterTerm,
  formType,
  hideExcludedTypes = false,
  items,
  linkedSnags,
  onSubmit,
  refetchQualityInstance,
}: {
  edit: boolean;
  filterTerm: string;
  formType?: string;
  hideExcludedTypes?: boolean;
  items: FieldDefinition[];
  linkedSnags?: FormLinkedSnag[];
  onSubmit: ((keepFormOpen?: boolean) => Promise<boolean>) | (() => void);
  refetchQualityInstance?: () => void;
}) => {
  const { getFilteredSectionsCategoriesAndQuestions } =
    useGetFilteredSectionsCategoriesAndQuestions();

  const { sectionPercentages } = useCountFormAnsweredFieldsPercentage(items);

  const { isDirty } = useDiscardModalContext();

  const isTBQ = formType === QualityControlInstanceType.Tbq ?? false;

  const sectionsAndFields = useMemo(() => {
    const filteredSections = getFilteredSectionsCategoriesAndQuestions({
      sections: items,
      term: filterTerm,
    });

    return (
      <SectionsAndFieldsList
        Edit={AnswerEditFormik}
        editMode={edit}
        fields={filteredSections}
        hideExcludedTypes={hideExcludedTypes}
        isDirty={isDirty}
        isTBQ={isTBQ}
        linkedSnags={linkedSnags}
        onSubmit={onSubmit}
        padding
        refetchQualityInstance={refetchQualityInstance}
        searchTerm={filterTerm}
        sectionPercentageCounter={sectionPercentages}
        unnestedItems={unnestInstance(items)}
      />
    );
  }, [edit, filterTerm, hideExcludedTypes, items, sectionPercentages]);

  return { sectionsAndFields };
};
