import { Document } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export function useEditDocuments(documents: Document[]) {
  const { goTo } = useRouting();
  const action = 'editMetaData';
  const stateIdsPropName = 'documentIdsForEdit';

  const editDocuments = () => {
    goTo({
      searchParams: { action, f: undefined, fullscreen: true },
      state: {
        [stateIdsPropName]: documents.map(({ id }) => id),
      },
    });
  };

  return {
    editDocuments,
  };
}
