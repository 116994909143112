import React from 'react';
import dayjs from 'dayjs';

import { Integration } from '@pro4all/graphql';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@pro4all/shared/mui-wrappers';

import { IntegrationTypeIcon } from './IntegrationTypeIcon';

const IntegrationsListMobile: React.FC<{
  integrations: Integration[];
}> = ({ integrations }) => (
  <>
    <Divider />
    <List sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
      {integrations &&
        integrations.map((integration) => (
          <ListItem dense divider key={integration.id}>
            <ListItemIcon>
              <IntegrationTypeIcon
                integrationType={integration.integrationType}
              />
            </ListItemIcon>

            <ListItemText
              primary={integration.integrationName}
              secondary={dayjs(integration.createdAt).format('ll')}
            />
          </ListItem>
        ))}
    </List>
  </>
);

export default IntegrationsListMobile;
