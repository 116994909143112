import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { ApiKey, useCreateApiKeyMutation } from '@pro4all/graphql';
import { toApiDateUtc } from '@pro4all/shared/utils';

import { useApiKeyContext } from './ApiKeyContext';
import { FormFields } from './types';
import { useApolloClientCacheHelpers } from './useApolloClientCacheHelpers';

export const useCreateApiKey = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createApiKey] = useCreateApiKeyMutation();
  const { setApiToken } = useApiKeyContext();
  const { getCurrentlyCachedApiKeys, updateCachedApiKeys } =
    useApolloClientCacheHelpers();

  return async ({
    onClose,
    values,
  }: {
    onClose: () => void;
    values: FormFields;
  }) => {
    const { description, expiresAt } = values;
    const id = uuid();

    const expiresAtIsoFormat = toApiDateUtc(expiresAt);

    try {
      const { data } = await createApiKey({
        variables: {
          description,
          expiresAt: expiresAtIsoFormat,
          id,
        },
      });

      if (data?.createApiKey) {
        // 1. Inform user and offer the one time provided token so that the user can copy it.
        setApiToken(data.createApiKey);

        // 2. Update Apollo client cache.
        const currentlyCachedApiKeys = getCurrentlyCachedApiKeys();
        const newApiKey = {
          __typename: 'ApiKey',
          description,
          expiresAt: expiresAtIsoFormat ? expiresAtIsoFormat : null,
          id,
        } as ApiKey;
        updateCachedApiKeys([...currentlyCachedApiKeys, newApiKey]);

        // 3. Close the sidebar.
        onClose();
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      }
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
