import { Member } from '@pro4all/graphql';
import { getMultiValue } from '@pro4all/shared/qc-meta';
import { ReportUserSelectionFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportUserSelectionField = ({
  item,
  linkedSnags,
  members,
  templateOptions,
}: ReportUserSelectionFieldProps) => {
  if (!item || !item.value) return null;

  const { displayDescription, displayName, value } = item;
  const items = getMultiValue(value);
  const membersFiltered = members.filter((member) => items.includes(member.id));

  const userDisplays = membersFiltered
    .map((member: Member) => {
      const organizationName = member.organization?.name
        ? ` (${member.organization.name})`
        : '';
      return `${member.displayName}${organizationName}`;
    })
    .join(', ');

  return (
    <Instance.Item>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value>
        {userDisplays}
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        <LinkedSnagsList linkedSnags={linkedSnags} />
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportUserSelectionField;
