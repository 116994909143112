import { FieldDefinition } from '@pro4all/graphql';

export const getColor = (field: FieldDefinition | Record<string, never>) => {
  if (field.valueType && field) {
    const options = field.valueType['options'] ?? [];
    const option = options.find((option) => option.name === field.value);
    return option ? option.color : '';
  } else {
    return '';
  }
};
