import React from 'react';

import { IntegrationWithConnection } from '@pro4all/graphql';
import { IntegrationTypeIcon } from '@pro4all/settings/ui/src/integrations';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@pro4all/shared/mui-wrappers';

const ConnectionsListMobile: React.FC<{
  integrationWithConnections: IntegrationWithConnection[];
}> = ({ integrationWithConnections }) => (
  <>
    <Divider />
    <List sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
      {integrationWithConnections &&
        integrationWithConnections.map((integrationWithConnection) => (
          <ListItem dense divider key={integrationWithConnection.id}>
            <ListItemIcon>
              <IntegrationTypeIcon
                integrationType={integrationWithConnection.integrationType}
              />
            </ListItemIcon>

            <ListItemText
              primary={integrationWithConnection.integrationName}
              secondary={
                integrationWithConnection.integrationConnection
                  ? integrationWithConnection.integrationConnection
                      ?.connectionName
                  : '-'
              }
            />
          </ListItem>
        ))}
    </List>
  </>
);

export default ConnectionsListMobile;
