import React from 'react';
import { useDrag } from 'react-dnd';
import { v4 as uuid } from 'uuid';

import { DownloadFilenameCharacter, SegmentType } from '@pro4all/graphql';
import { DndTypes } from '@pro4all/shared/config';
import { LabelCard } from '@pro4all/shared/ui/label-card';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';

export const AvailableCharacterCard = ({
  character,
}: {
  character: DownloadFilenameCharacter;
}) => {
  const { insertSegment } = useConfigureDownloadNameContext();

  const tempId = uuid();
  const segment = {
    deleteId: tempId,
    id: character.id,
    type: SegmentType.Character,
  };

  const [, drag] = useDrag({
    item: segment,
    type: DndTypes.NOT_INCLUDED_CHARACTER,
  });

  return drag(
    <div>
      <LabelCard
        colorBackground="pendingBg"
        colorFont="pendingHover"
        label={character.name}
        onClick={() =>
          insertSegment({
            segment,
          })
        }
      />
    </div>
  );
};
