import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { TemplateFrame } from '../components/form-template/TemplateFrame';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';

export const OrganizationDocuments = () => {
  const routeTemplates = useRouteMatch(Routes.metaDataDocumentsTemplates);
  const routeItems = useRouteMatch(Routes.metaDataDocumentsFields);

  const { searchParams } = useRouting();

  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');

  const templateType = routeTemplates
    ? TemplateType.Document
    : routeItems
    ? TemplateType.Field
    : undefined;
  const templateService = TemplateService.Documents;

  const { selectedTemplate } = useTemplatesInclude({
    includeScope: true,
    templateService,
    templateType,
  });

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <Route exact path={Routes.metaDataDocuments}>
        <Redirect to={Routes.metaDataDocumentsTemplates} />
      </Route>
      <Route
        exact
        path={[
          Routes.metaDataDocumentsFields,
          Routes.metaDataDocumentsTemplates,
        ]}
        render={() =>
          !createContext && !editContext ? (
            <MetaDataMain
              base={`${Routes.metaDataDocuments}/`}
              pathFields={Routes.metaDataDocumentsFields}
              pathTemplates={Routes.metaDataDocumentsTemplates}
            />
          ) : (
            <TemplateFrame selectedTemplate={selectedTemplate} />
          )
        }
      />
    </MetaDataContextProvider>
  );
};
