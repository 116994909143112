import React from 'react';

import { ModalConvertDocumentsToDrawings } from './ModalConvertDocumentsToDrawings';
import { ModalMoveDocuments } from './ModalMoveDocuments';

// This file is a wrapper to include future modals, because there will become more the moment we make the UI/UX consistent.
export const Modals = () => (
  <>
    <ModalMoveDocuments />
    <ModalConvertDocumentsToDrawings />
  </>
);
