import React from 'react';

import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import {
  DummyPicture1,
  DummyPicture2,
  DummyPicture3,
  DummyPicture4,
  DummyPicture5,
  DummyPicture6,
  DummyPicture7,
  DummyPicture8,
  DummyPicture9,
} from '@pro4all/shared/qc-report-assets';
import { ReportImagesProps } from '@pro4all/shared/types';

import { PhotoGrid } from './pages/elements';

export const ReportImages: React.FC<ReportImagesProps> = ({
  photoBlobs,
  reportConfigurationDummyTemplate,
  reportOptions,
  templateId,
  values,
}) => {
  const column =
    templateId &&
    reportOptions?.templates &&
    reportOptions?.templates[templateId]
      ? reportOptions?.templates[templateId]?.imageColumn
      : 'bTwo';

  const loading = !photoBlobs && !reportConfigurationDummyTemplate;

  const dummyPictures = [
    DummyPicture1,
    DummyPicture2,
    DummyPicture3,
    DummyPicture4,
    DummyPicture5,
    DummyPicture6,
    DummyPicture7,
    DummyPicture8,
    DummyPicture9,
  ];

  return (
    <PhotoGrid column={column} inline={reportOptions?.inlineLists}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {values.map((id, index) => {
            const url = photoBlobs ? photoBlobs[id] : '';
            const dummyPicture = dummyPictures[index % dummyPictures.length];
            return (
              <div className="reports-img-container" key={index}>
                {reportConfigurationDummyTemplate ? (
                  <img alt="qcimage" src={dummyPicture} />
                ) : (
                  <img alt="qcimage" key={id} src={url || ''} />
                )}
              </div>
            );
          })}
        </>
      )}
    </PhotoGrid>
  );
};
