import { useTranslation } from 'react-i18next';

import { useGotoStamper } from '@pro4all/documents/ui/stamper';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useStampAction = ({
  editableDocuments,
  editableVersions,
  isLocked,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'editableVersions' | 'isLocked'
>) => {
  const { t } = useTranslation();

  const stampAction: ActionProps = {
    ariaLabel: t('QR codes'),
    dataTestId: 'stamp-document',
    disabled:
      isLocked ||
      (editableDocuments.length === 0 && editableVersions.length === 0) ||
      editableDocuments.some(
        ({ extension, isExpected }) =>
          isExpected || extension?.replace('.', '') !== 'pdf'
      ) ||
      editableVersions.some(
        ({ extension }) => extension?.replace('.', '') !== 'pdf'
      ),
    key: 'stamp-document',
    label: t('QR codes'),
    onClick: useGotoStamper(
      editableDocuments.length
        ? editableDocuments.map(({ id: documentId, name, versionId: id }) => ({
            documentId,
            id,
            name,
          }))
        : editableVersions.map(({ documentId, name, id }) => ({
            documentId,
            id,
            name,
          }))
    ),
    startIcon: 'stamp',
  };

  return stampAction;
};
