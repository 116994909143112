import React from 'react';

import { SvgIcon } from '@pro4all/shared/mui-wrappers';

import { ShapeProps } from '../Shape';

export const Follow: React.FC<ShapeProps> = ({ colors, ...rest }) => (
  <SvgIcon {...rest}>
    <path
      d="M178.812 68.75c.089 8.79-7.971 15.76-16.593 15.618h-57.041c-4.499 0-8.158 3.442-8.158 7.676s3.659 7.678 8.158 7.678h34.347c9.149 0 16.596 7.007 16.596 15.618v21.144c0-14.592-3.078-28.835-16.598-28.835h-34.34c-8.97 0-16.299-6.729-16.586-15.103-.394-9.014 7.762-16.272 16.586-16.132.696 0 56.839.015 57.513 0 6.186-.044 10.191-7.81 5.856-12.502a8.349 8.349 0 00-6.333-2.84H93.175c-3.406 0-6.447-.13-9.164-.388h.143c-9.741-3.28-15.895-6.366-19.22-16.918-.939-2.968-1.652-6.528-2.158-10.848a5.043 5.043 0 012.813-.782c2.178 0 3.688 1.306 4.066 2.646v6.247c.015 11.11 13.72 12.104 23.308 12.104h69.256c9.148 0 16.593 7.007 16.593 15.618z"
      fill={colors.primary}
    />
    <path
      d="M168.543 63.907a7.413 7.413 0 011.832 4.917c0 3.855-3.529 7.585-7.917 7.585h-56.527c-11.6 0-17.688 8.082-17.346 16.49-.152-3.79 5.616-13.3 13.301-13.3 16.036 0 48.244 4.742 60.931 4.742 9.547.005 17.736-9.542 5.726-20.434zM151.902 140c-2.326 0-4.214-1.778-4.214-3.973 0 0-.504-19.468-.543-21.84-.047-2.921-2.56-5.309-5.857-6.452 13.491 0 14.818 18.504 14.818 26.197l.015 2.095c0 2.195-1.888 3.973-4.219 3.973zM84.154 60.696h-.142c-7.94-.492-13.42-2.358-17.031-5.721-2.029-1.886-3.467-4.246-4.426-7.1a19.923 19.923 0 01-.226-.8 38.394 38.394 0 01-.755-5.544c-.167-2.097-.246-4.3-.31-6.147a3.281 3.281 0 011.505-2.459c.506 4.32 1.23 7.868 2.159 10.848 3.331 10.545 9.485 13.63 19.226 16.923z"
      fill={colors.secondary}
    />
    <path
      d="M62.331 47.06c.071.27.145.536.226.8a9.462 9.462 0 01-.226-.8z"
      fill={colors.secondary}
    />
    <path
      d="M69.23 3.28c-.078-1.89-2.315-3.358-4.154-3.277-1.84.081-4.143 1.69-4.074 3.58v4.856c.086 1.89 2.515 3.366 4.356 3.275 1.842-.091 3.946-1.684 3.868-3.575l.005-4.858zM69.495 19.032c0-1.532-1.637-3.275-4.157-3.275-2.385 0-4.337 1.362-4.337 3.602l.063 4.821c.08 1.883 2.717 3.359 4.556 3.285 1.84-.074 3.949-1.687 3.87-3.578l.005-4.855zM170.639 56.964c-.032-.04-.062-.081-.096-.12.035.037.067.077.096.12zM170.885 57.323l-.045-.076a.291.291 0 01.045.076z"
      fill={colors.primary}
    />
  </SvgIcon>
);
