import {
  FORBIDDEN_DOCUMENT_NAMES,
  VALID_DOCUMENT_REGEX,
} from '@pro4all/documents/ui/share';
import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';
import { MAX_LENGTH } from '@pro4all/shared/utils';

export const useNewExpectedDocumentConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Title', // i18n
      matches: VALID_DOCUMENT_REGEX,
      max: MAX_LENGTH - 5,
      name: 'name',
      notOneOf: FORBIDDEN_DOCUMENT_NAMES,
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'File Type', // i18n
      name: 'fileType',
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
