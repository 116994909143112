import {
  FilterColumnProps,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import { BaseRow } from '@pro4all/shared/ui/general';

export function useGetOptions<Row extends BaseRow>({
  flattenOptions,
  propertyId,
  subPropertyId,
}: Pick<FilterColumnProps, 'flattenOptions' | 'propertyId' | 'subPropertyId'>) {
  const { getFilterValuesFromContext } = useFilterContext<Row>();
  return () =>
    getFilterValuesFromContext({ flattenOptions, propertyId, subPropertyId });
}
