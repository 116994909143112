import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const ChangeableSimpleSnagIcon: React.FC<
  SvgIconProps & { customColor: string }
> = (props) => {
  const { scale = 1 } = props;

  return (
    <svg
      fill="none"
      height={20 * Number(scale)}
      viewBox="0 0 20 20"
      width={20 * Number(scale)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill={props.customColor || '#533BE2'}
        height="20"
        rx="10"
        width="20"
      />
      <rect fill="white" height="16" rx="8" width="16" x="2" y="2" />
    </svg>
  );
};
