import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useSetEntitiesFolderPermissionsForFolderMutation } from '@pro4all/graphql';
import { addRequiredPermissions } from '@pro4all/shared/permissions';
import { useGenericContext } from '@pro4all/shared/providers';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { usePermissionsEntityMatrixContext } from '../entity-context/PermissionsEntityMatrixProvider';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    updatePermissionsAfterSave,
    state: { savePermissions },
    toggleBackdrop,
  } = usePermissionsEntityMatrixContext();
  const [setEntitiesPermissions] =
    useSetEntitiesFolderPermissionsForFolderMutation();
  const { showSingleError } = useShowMessages();
  const { togglePermissionsSaved } = useGenericContext();

  const onSubmit = async () => {
    toggleBackdrop();

    try {
      const variables = {
        entities: savePermissions.map((entity) => ({
          breakInheritance: entity.breakInheritance,
          entityId: entity.entityId,
          permissions: addRequiredPermissions(entity.folderPermissions),
        })),
        folderId: savePermissions[0].folderId,
      };

      const response = await setEntitiesPermissions({
        variables,
      });

      const { successful, unsuccessful } =
        response?.data?.setEntitiesFolderPermissionsForFolder || {};
      if (unsuccessful.length === 0) {
        enqueueSnackbar(t('Permissions successfully saved'));
      } else if (unsuccessful.length === 1) {
        // Toast single
        const entityName =
          savePermissions.find((folder) => folder.folderId === unsuccessful[0])
            ?.name || '';
        enqueueSnackbar(
          `${t("Permissions for '{{folderName}}' not properly saved.", {
            entityName,
          })} ${t('Please try again')}.`
        );
      } else if (unsuccessful.length > 1) {
        // Toast multiple
        enqueueSnackbar(
          `${t('Permissions for {{count}} users/groups not properly saved.', {
            count: unsuccessful.length,
          })} ${t('Please try again')}.`
        );
      }

      setTimeout(() => {
        toggleBackdrop();
        togglePermissionsSaved();
      }, 500); // A little delay else it could go too quick and that would cause an annoying flicker on the screen.

      updatePermissionsAfterSave({ successful, unsuccessful });
    } catch (e) {
      showSingleError(e);
    }
  };

  return onSubmit;
};
