import { useState } from 'react';

import { Position } from '@pro4all/shared/ui/context-menu';

export const useContextMenuPosition = () => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [position, setPosition] = useState<Position>();

  const openContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setPosition({ left: event.clientX, top: event.clientY + 20 });
    setIsContextMenuOpen(true);
  };

  const closeContextMenu = () => {
    setPosition(undefined);
    setIsContextMenuOpen(false);
  };

  return { closeContextMenu, isContextMenuOpen, openContextMenu, position };
};
