import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { Preview } from '../data';
import { Status as PreviewStatus } from '../data';
import { Shape } from '../shape';
import type { Canvas as CanvasState, Shape as ShapeState } from '../store';
import { Status as StamperStatus } from '../store';

import * as Styled from './Canvas.styles';
import { ImageNotFound } from './ImageNotFound';

export interface Props extends Partial<CanvasState> {
  background: Preview;
  children?: never;
  onInit: (canvas: CanvasState) => void;
  shapes?: ShapeState[];
  status: StamperStatus;
}

export const Canvas: React.FC<Props> = ({
  background,
  height = 0,
  onInit,
  shapes = [],
  status,
  width = 0,
}) => {
  const figureRef = useRef<HTMLElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [canvas, setCanvas] = useState({ height, width });

  const calculateCanvas = useCallback(() => {
    if (!figureRef.current || !canvasRef.current || !imgRef.current) return;

    const { naturalHeight, naturalWidth } = imgRef.current;
    const scaleProp = naturalHeight > naturalWidth ? 'Height' : 'Width';
    const scale =
      imgRef.current[`client${scaleProp}` as 'clientHeight' | 'clientWidth'] /
      imgRef.current[`natural${scaleProp}` as 'naturalHeight' | 'naturalWidth'];

    const { offsetHeight: maxHeight, offsetWidth: maxWidth } =
      figureRef.current;

    let height = naturalHeight * scale;
    let width = naturalWidth * scale;

    if (height > maxHeight) {
      width = width * (maxHeight / height);
      height = maxHeight;
    } else if (width > maxWidth) {
      height = height * (maxWidth / width);
      width = maxWidth;
    }

    const canvas = { height, width };

    onInit(canvas);
    setCanvas(canvas);
  }, [onInit]);

  useEffect(() => {
    window.addEventListener('resize', calculateCanvas);
    return () => window.removeEventListener('resize', calculateCanvas);
  }, [calculateCanvas]);

  if (background.status === PreviewStatus.NotFound)
    return <ImageNotFound retry={background.retry} />;

  return (
    <Styled.Figure ref={figureRef}>
      {background.src && (
        <Styled.Img
          alt=""
          onLoad={calculateCanvas}
          ref={imgRef}
          src={background.src}
        />
      )}

      <Styled.Canvas ref={canvasRef} style={canvas}>
        {shapes.map((shape, index) => (
          <Shape
            {...shape}
            canvas={canvas}
            container={canvasRef?.current}
            key={shape.id ?? index}
          />
        ))}
      </Styled.Canvas>

      {status !== StamperStatus.Idle && <Styled.Loader />}
    </Styled.Figure>
  );
};
