import { FieldDefinition, QualityControlInstance } from '@pro4all/graphql';
import { InstanceWithSignature } from '@pro4all/shared/types';

export const getSignatureIds = (instances: QualityControlInstance[]) => {
  const signatureIds: InstanceWithSignature[] = [];

  const findSignatures = (instanceId: string, items: FieldDefinition[]) => {
    items.forEach((item: FieldDefinition) => {
      if (item.type === 'Signature' && item.value) {
        signatureIds.push({
          instanceId,
          itemId: item.id || '',
          signatureId: item.value,
        });
      }
      if (
        item.type === 'Section' &&
        item.valueType &&
        item.valueType.subFields
      ) {
        findSignatures(instanceId, item.valueType.subFields);
      }
    });
  };

  instances.forEach((instance) => {
    findSignatures(instance.id, instance.items ? instance.items : []);
  });

  return signatureIds;
};
