import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const StyledCellContent = styled.span<{ active: boolean }>`
  ${({ active }) => !active && `color: ${customColors.grey500};`}
`;

export const HeaderWrapper = styled.div`
  padding-left: 8px;
  padding-right: 2px;
`;
