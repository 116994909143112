import React, { createContext, useContext, useMemo, useState } from 'react';

interface DiscardModalContextValue {
  bypass: boolean;
  isDirty: boolean;
  isValid: boolean;
  onSubmit: () => void;
  setBypass: (bypass: boolean) => void;
  setIsDirty: (isDirty: boolean) => void;
  setIsValid: (isValid: boolean) => void;
  setOnSubmit: (onSubmit: () => void) => void;
}

export const DiscardModalContext = createContext(
  {} as DiscardModalContextValue
);

export const useDiscardModalContext = () =>
  useContext<DiscardModalContextValue>(DiscardModalContext) || {
    bypass: false,
    isDirty: false,
    isValid: true,
    onSubmit: null,
    setBypass: null,
    setIsDirty: null,
    setIsValid: null,
    setOnSubmit: null,
  };

export const DiscardModalProvider: React.FC = ({ children }) => {
  const [bypass, setBypass] = useState(false); // For route changes that should bypass the DiscardModal.
  const [isDirty, setIsDirty] = useState(false); // Discard modal can only pop up if this prop is true.
  const [isValid, setIsValid] = useState(true); // To enable/disable the `Save` button in the DiscardModal.
  const [onSubmit, setOnSubmit] = useState(); // Callback to be executed when the user click the `Save` button in the DiscardModal.

  const value = useMemo(
    () => ({
      bypass,
      isDirty,
      isValid,
      onSubmit,
      setBypass,
      setIsDirty,
      setIsValid,
      setOnSubmit,
    }),
    [
      bypass,
      isDirty,
      isValid,
      onSubmit,
      setBypass,
      setIsDirty,
      setIsValid,
      setOnSubmit,
    ]
  );

  return (
    <DiscardModalContext.Provider value={value}>
      {children}
    </DiscardModalContext.Provider>
  );
};
