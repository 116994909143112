import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ValueTypeName } from '@pro4all/graphql';
import {
  FormFieldConfig,
  SchemaExtender,
  useFormConfig,
} from '@pro4all/shared/forms';

export const useInviteUsersFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Admins', // i18n
      email: true,
      name: 'admins',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Members', // i18n
      email: true,
      name: 'members',
      type: ValueTypeName.Selection,
      valueType: { multiSelect: true },
    },
    {
      displayName: 'Message', // i18n
      name: 'message',
      type: ValueTypeName.Text,
    },
  ];

  const admins: SchemaExtender = (schema) =>
    schema.when(['members'], {
      is: (members) => members.length === 0,
      then: Yup.array().required(t('Add at least one admin or member')),
    });

  return useFormConfig({ customValidation: { admins }, formFields });
};
