import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { useTBQTemplates } from '@pro4all/metadata/data-access';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import type { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikDate,
  FormikForm,
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import { ParticipantSelect } from '@pro4all/workflow/ui/task-sidebar';

import { CallBackProps, useTBQModulesSubmit } from './useTBQModulesSubmit';
import { useTBQModulesTaskFormConfig } from './useTBQModulesTaskFormConfig';

interface Props {
  onClose: () => void;
}

export const TBQModulesTaskForm: React.FC<Props> = ({ onClose }) => {
  const { searchParams } = useRouting();
  const scopeId = searchParams.get('scopeId');
  const inputRef = useRef(null);

  if (!scopeId) {
    throw Error('`projectId` required for a TBQTaskForm');
  }

  const {
    object: { id: objectId, projectId, project },
  } = useObjectDetailContext();

  const mainProcedureId = project?.mainProcedure?.id;
  if (!mainProcedureId)
    throw Error('`mainProcedureId` required for a TBQTaskForm');
  if (!projectId) throw Error('`projectId` required for a TBQTaskForm');

  const initialValues: CallBackProps = {
    description: '',
    endTime: '',
    name: '',
    participant: { id: '' },
    tbqScanTemplate: null,
  };

  const { templates } = useTBQTemplates({
    scopeId: scopeId,
  });
  const options: Option[] =
    templates?.map((t) => ({ id: t.id, label: t.name ?? 'unknown' })) ?? [];

  const submit = useTBQModulesSubmit({
    moduleTypeId: scopeId,
    objectId: objectId,
    onCreate: onClose,
    procedureId: mainProcedureId,
    projectId,
  });

  const { getField, validationSchema } = useTBQModulesTaskFormConfig();
  const nameField = getField('name');
  const descriptionField = getField('description');
  const endTimeField = getField('endTime');
  const tbqScanTemplateField = getField('tbqScanTemplate');
  if (!nameField || !descriptionField || !endTimeField || !tbqScanTemplateField)
    throw Error(
      `Form setup incomplete, ${!nameField}, ${!descriptionField}, ${!endTimeField}, ${!tbqScanTemplateField}`
    );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <FormikInput
              autoFocus
              id={nameField.name}
              inputRef={inputRef}
              label={nameField.displayName}
              name={nameField.name}
            />
            <FormikTextarea
              id={descriptionField.name}
              label={descriptionField.displayName}
              name={descriptionField.name}
              rows={4}
            />
            <Box mb={2} mt={2}>
              <FormikSelect
                id={tbqScanTemplateField.name}
                label={tbqScanTemplateField.displayName}
                name={tbqScanTemplateField.name}
                options={options}
              ></FormikSelect>
            </Box>
            <ParticipantSelect projectId={projectId} />
            <FormikDate
              label={endTimeField.displayName}
              minDate={dayjs()}
              name={endTimeField.name}
            />
          </FormWrapper>

          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
