import { customColors, ThemeColor } from '@pro4all/shared/themes';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

import * as Styled from './InfoHeader.styles';

interface InfoHeaderProps {
  color: ThemeColor;
  iconName: IconName;
  title: string;
}

export const InfoHeader = ({ color, iconName, title }: InfoHeaderProps) => (
  <Styled.Container>
    <Icon
      fontSize="large"
      htmlColor={customColors[color]}
      iconName={iconName}
    />
    <Styled.HeaderTitle>
      <Styled.StyledH2 $color={color} variant="h2">
        {title}
      </Styled.StyledH2>
    </Styled.HeaderTitle>
  </Styled.Container>
);
