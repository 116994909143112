import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

import { useCustomValidation } from './useCustomValidation';

export const useIntegrationFormConfig = () => {
  const customValidation = useCustomValidation();

  const formFields: FormFieldConfig[] = [
    // Default fields
    {
      displayName: 'Name', // i18n
      name: 'name',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Application', // i18n
      name: 'application',
      required: true,
      type: ValueTypeName.Selection,
    },

    // CustomBuild12 fields
    {
      displayName: 'Username', // i18n
      name: 'userName',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'API-key', // i18n
      name: 'apiKey',
      type: ValueTypeName.Text,
    },

    // CustomSnagstream fields
    {
      displayName: 'Snagstream environment url', // i18n
      name: 'environmentUrl',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ customValidation, formFields });
};
