import { useState } from 'react';

import { IconButton } from '@pro4all/shared/ui/buttons';

import * as Styled from './FilterSelect.styles';
import { FilterColumnProps } from './types';

export const useValue = ({
  onSet,
  selectedOptions,
  setSelectedOptions,
}: Pick<
  FilterColumnProps,
  'onSet' | 'selectedOptions' | 'setSelectedOptions'
>) => {
  const [value, setValue] = useState<string>('');

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onValueKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && value) {
      const updatedOptions = [...new Set([...selectedOptions, value])].sort();
      onSet(updatedOptions);
      setSelectedOptions(updatedOptions);
      setValue('');
    }
    event.stopPropagation();
  };

  const endAdornment = (
    <Styled.StyledInputAdornment position="end">
      <IconButton
        color="default"
        disableBorder
        iconName="cancel"
        onClick={() => setValue('')}
      />
    </Styled.StyledInputAdornment>
  );

  return {
    endAdornment,
    onValueChange,
    onValueKeyDown,
    value,
  };
};
