import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useOrganizationContext } from '@pro4all/organization/context';
import {
  CompareType,
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';
import { useTaskStatuses } from '@pro4all/workflow/ui/utils';

export const usePredefinedFilters = (hideMy?: boolean) => {
  const { t } = useTranslation();
  const { userDisplayName } = useOrganizationContext();

  const now = dayjs();
  const due = now.add(7, 'day');

  const TaskStatuses = useTaskStatuses();

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: [userDisplayName],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'assignment',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('My tasks'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: [
            TaskStatuses.InProgress.label,
            TaskStatuses.ToDo.label,
          ],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'status',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Open tasks'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Date,
          filterValues: [
            {
              compareType: CompareType.isBefore,
              valueFirst: dayjs(
                `${now.year()}-${now.month() + 1}-${now.date()}`
              ).toISOString(),
            },
          ],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'endTime',
          subPropertyId: '',
          translateOptions: true,
        },
        {
          filterType: FilterHeaderType.Select,
          filterValues: [
            TaskStatuses.InProgress.label,
            TaskStatuses.ToDo.label,
          ],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'status',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Overdue'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Date,
          filterValues: [
            {
              compareType: CompareType.isBetween,
              valueFirst: dayjs(
                `${now.year()}-${now.month() + 1}-${now.date()}`
              ).toISOString(),
              valueSecond: dayjs(
                `${due.year()}-${due.month() + 1}-${due.date()}`
              ).toISOString(),
            },
          ],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'endTime',
          subPropertyId: '',
          translateOptions: true,
        },
        {
          filterType: FilterHeaderType.Select,
          filterValues: [
            TaskStatuses.InProgress.label,
            TaskStatuses.ToDo.label,
          ],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'status',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Due within a week'),
    },
  ];

  return predefinedFiltes.filter((predefinedFilter) => {
    if (predefinedFilter.title === t('My tasks')) {
      return !hideMy;
    }
    return true;
  });
};
