import React, { PropsWithChildren } from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { FileUpload, FileUploadOverlay } from '@pro4all/shared/ui/file-upload';

import { useDropFilesOnUploadEditor } from '../helpers/useDropFilesOnUploadEditor';
import { UploaderEditorTypes } from '../types';
import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const ColumnsWrapper = ({
  children,
  scrollableDivRef,
}: PropsWithChildren<Pick<UploaderEditorTypes, 'scrollableDivRef'>>) => {
  const {
    state: { filesToUpload },
  } = useUploaderEditorContext();

  const dropFilesOnUploadEditor = useDropFilesOnUploadEditor();

  const columns = (
    <Box
      ref={scrollableDivRef}
      sx={{
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}
    >
      {/* This `width: 1px` is a hack to make the horizontal scrollbar work, without this the right border will be pushed outside the screen when resizing */}
      <Box
        sx={{
          display: 'flex',
          width: '1px',
        }}
      >
        {children}
      </Box>
    </Box>
  );

  const uploadContext = Boolean(filesToUpload && filesToUpload.length);

  return (
    <FileUpload
      multiple
      onDrop={uploadContext ? dropFilesOnUploadEditor : undefined}
    >
      {({ isDragActive }) => (
        <FileUploadOverlay isActive={uploadContext && isDragActive}>
          {columns}
        </FileUploadOverlay>
      )}
    </FileUpload>
  );
};
