import React, { useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BaseEditor, Descendant } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  MessageFormBase,
  SlateEditor,
} from '@pro4all/communication/ui/general';
import {
  AttachmentList,
  AttachmentsUploadPreview,
  defaultEditorValues,
} from '@pro4all/communication/ui/general';
import { AttachmentContext } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Timestamp } from '@pro4all/shared/ui/general';
import { tryParseString } from '@pro4all/shared/utils';

import { CommentFormProps } from './forms';
import { InnerFooter } from './InnerFooter';
import { CommentContainer, Heading, UserName } from './Styles';
import { useThreadContext } from './ThreadContext';
interface Props extends CommentFormProps {
  CustomButtons?: React.ReactNode[];
  containerRef?: React.Ref<HTMLDivElement>;
  editMode: boolean;
  editor: BaseEditor & ReactEditor;
  id: string;
  onInputClicked?: () => void;
  projectId?: string;
  readOnly?: boolean;
  threadId: string;
  uploadProgress: { [p: string]: number } | null;
}

export const CommentInput: React.FC<Props> = ({
  editor,
  editMode,
  newComment,
  onInputClicked,
  readOnly,
  uploadProgress,
  id,
  containerRef,
  threadId,
  CustomButtons,
  ...commentProps
}) => {
  const { messageId } = commentProps;
  const { searchParams } = useRouting();

  const formContext = useFormContext<MessageFormBase>();
  const threadContext = useThreadContext();

  const focusId = searchParams.get('focusComment');
  const editId = searchParams.get('editComment');
  const commentSeen = searchParams.get('commentSeen');

  const thisFocus = focusId === messageId;
  const otherFocus = focusId && focusId !== messageId;
  const otherEdit = editId && editId !== messageId;

  const initialEditFocus =
    !otherEdit && ((!otherFocus && newComment) || editMode);
  const initialFocus = !otherEdit && !otherFocus && thisFocus && !commentSeen;

  /* Comment focus
   * Read-only comments:
   * The focussed state renders a blue border around the comment and scrolls it into view.
   * Clicking outside the focussed element will blur it, preventing the element from scrolling back into view again
   *
   * Edit comments:
   * The focussed state is also used as an arg for the Editor's autoFocus prop, which focuses the input.
   * */
  const [focussed, setFocussed] = useState(initialEditFocus || initialFocus);

  useLayoutEffect(() => {
    setFocussed(initialEditFocus || initialFocus);
  }, [initialEditFocus, initialFocus]);

  if (!formContext || !threadContext) return null;

  const { getValues, setValue: setFieldValue, control } = formContext;
  const setFieldTouched = control.trigger;

  const focusSlate = () => {
    ReactEditor.focus(editor);
    setFocussed(true);
  };
  const blurSlate = () => {
    ReactEditor.blur(editor);
    setFocussed(false);
  };

  const onClick = () => {
    if (!readOnly) {
      focusSlate();
      onInputClicked && onInputClicked();
    }
  };

  const onChange = async (value: string) => {
    setFieldValue('body', value);
    await setFieldTouched('body');
  };

  const value: Descendant[] = commentProps.body?.length
    ? tryParseString(commentProps.body) || defaultEditorValues
    : defaultEditorValues;

  const { onAttachmentDownload, onAttachmentRemoved } =
    threadContext.callbackOptions || {};

  const onDelete = async ({
    completed,
    id,
  }: {
    completed: boolean;
    id: string;
  }) => {
    const attachments = getValues('attachments');
    const newFiles = getValues('newFiles');

    const filteredFiles = newFiles?.filter((newFile) => newFile.name !== id);
    setFieldValue('newFiles', filteredFiles);

    if (completed) {
      const filteredAttachments = attachments?.filter(
        (attachment) => attachment.fileId !== id
      );
      setFieldValue('attachments', filteredAttachments);

      const name = attachments?.find(
        (attachment) => attachment.fileId === id
      )?.fileName;
      if (name) {
        const filteredFiles = newFiles?.filter(
          (newFile) => newFile.name !== decodeURIComponent(name)
        );
        setFieldValue('newFiles', filteredFiles);
      }
    }

    onAttachmentRemoved && onAttachmentRemoved();
    await setFieldTouched();
  };

  return (
    <CommentContainer
      $hasFocus={focussed}
      $readOnly={readOnly}
      id={id}
      onClick={onClick}
      ref={containerRef}
    >
      {messageId && (
        <Heading>
          <UserName>{commentProps.createdBy?.displayName}</UserName>
          <Timestamp date={commentProps.createdAt} format="YYYY-MM-DD HH:mm" />
        </Heading>
      )}
      <SlateEditor
        CustomButtons={CustomButtons}
        autofocus={focussed}
        displayGroups
        editor={editor}
        onBlur={blurSlate}
        onChange={onChange}
        onFocus={focusSlate}
        projectId={threadContext.projectId}
        readOnly={readOnly}
        showToolbar={newComment || editMode}
        value={value}
      />
      {editMode || newComment ? (
        <AttachmentsUploadPreview
          attachments={getValues('attachments')}
          context={AttachmentContext.Comm}
          newFiles={getValues('newFiles')}
          onDelete={onDelete}
          uploadProgress={uploadProgress || undefined}
        />
      ) : (
        <AttachmentList
          attachments={commentProps.attachments}
          context={AttachmentContext.Comm}
          messageId={commentProps.messageId}
          onDownload={onAttachmentDownload}
        />
      )}
      <InnerFooter
        {...commentProps}
        editMode={editMode}
        editor={editor}
        threadId={threadId}
      />
    </CommentContainer>
  );
};
