import { removeExclusiveFolderPermissions } from '../../shared/helpers/removeExclusiveFolderPermissions';
import { SetInitial, State } from '../types';

export const setInitialPermissionsAction = ({
  payload,
  state,
}: {
  payload: SetInitial;
  state: State;
}) => {
  const { displayPermissions, initialPermissionsExplicit, savePermissions } =
    state;

  const { permissions } = payload;

  // Only append folderIds that are not included yet to both 'displayPermissions' and 'initialPermissionsDisplay'.
  const currentlyIncludedFolderIds = displayPermissions.map(
    (entity) => entity.entityId
  );
  const additionalFolders = permissions.filter(
    (folder) => !currentlyIncludedFolderIds.includes(folder.folderId)
  );

  const displayPermissionsWithoutExclusiveDuplicates = [
    ...displayPermissions,
    ...additionalFolders,
  ].map((entity) => ({
    ...entity,
    folderPermissions: removeExclusiveFolderPermissions({
      permissions: entity.folderPermissions,
    }),
  }));

  return {
    displayPermissions: displayPermissionsWithoutExclusiveDuplicates,
    initialPermissionsDisplay: displayPermissionsWithoutExclusiveDuplicates,
    initialPermissionsExplicit: [
      ...additionalFolders,
      ...initialPermissionsExplicit,
    ],
    savePermissions,
  };
};
