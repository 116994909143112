import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

export const OrganizationTitle = ({
  organizationName,
}: {
  organizationName: string;
}) => (
  <Tooltip placement="bottom" title={organizationName}>
    <Text className="organization-title" variant="h4">
      {organizationName}
    </Text>
  </Tooltip>
);
