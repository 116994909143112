import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { FacetItem } from '@pro4all/graphql';
import {
  Box,
  CircularProgress,
  List,
  ListItemButton,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

type TFilterModalSnagListProps = {
  filteredSnagTemplates?: FacetItem[] | null;
  handleSelectTemplate: (templateId: string) => void;
  isLoading?: boolean;
  isSnag?: boolean;
  projectAndOrganizationSnagTemplatesError?: ApolloError;
  query: string;
};

export const FilterModalSnagList = (props: TFilterModalSnagListProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  if (props.projectAndOrganizationSnagTemplatesError) {
    enqueueSnackbar(t('Could not fetch snags.'));
    return null;
  }

  if (props.isLoading) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <>
      {!props.filteredSnagTemplates?.length && !props.isLoading && (
        <p>
          {t('No snags found for')} <strong>{props.query}</strong>
        </p>
      )}

      <List>
        {props.filteredSnagTemplates?.map((snag) => (
          <ListItemButton
            key={snag.value}
            onClick={() => props.handleSelectTemplate(snag.value)}
          >
            <Icon iconName={props.isSnag ? 'snag' : 'form'} />
            <Box sx={{ marginLeft: (theme) => theme.spacing(1) }}>
              <Text variant="body1">{snag.apiValue}</Text>
            </Box>
          </ListItemButton>
        ))}
      </List>
    </>
  );
};
