import React from 'react';
import { useTranslation } from 'react-i18next';

import { Document, FinalizationState, LockType } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const FinalizeStatus: React.FC<Document> = ({
  lockedBy,
  lockType,
  state,
}) => {
  const { t } = useTranslation();

  return state === FinalizationState.Finalized ? (
    <Icon htmlColor={customColors.purplePrimary} iconName="finalize" />
  ) : lockType === LockType.Prostream ? (
    <Tooltip
      placement="bottom"
      title={`${lockedBy?.displayName || t('Unknown')} ${t(
        "is editing this document's content."
      )}`}
    >
      <Icon htmlColor={customColors.grey700} iconName="lock" />
    </Tooltip>
  ) : lockType === LockType.OfficeOnline ? (
    <Tooltip placement="bottom" title={t('Document being edited in Office.')}>
      <Icon htmlColor={customColors.grey700} iconName="officeOnlineProgress" />
    </Tooltip>
  ) : null;
};
