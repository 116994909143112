import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const FlagGermany: React.FC<SvgIconProps> = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 22 14"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h22v4.67H0z" fill="#000000" />
    <path d="M0 4.67h22v4.66H0z" fill="#d00" />
    <path d="M0 9.33h22v4.67H0z" fill="#ffce00" />
  </svg>
);
