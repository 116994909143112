import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterType } from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { delimiters, RELOAD_FLAG } from '@pro4all/shared/search-utils';
import {
  FilterBaseProps,
  FilterButtonProps,
} from '@pro4all/shared/search-utils';
import { Position } from '@pro4all/shared/ui/context-menu';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { useFilterMenuContext } from '../search-bar/FilterMenuContext';

import { ComponentByType } from './filter-content/FilterComponent';
import { filterNames } from './records/';
import { useFilters } from './utils/';
import { getContextMenuEvent } from './utils/getContextMenuEvent';
import * as Styled from './Filter.styles';
import { StyledMenu } from './Filter.styles';
import { Footer } from './Footer';

type Props = FilterBaseProps & FilterButtonProps;

export const Filter: React.FC<Props> = ({
  onApply,
  metaDataKey,
  name,
  readonly,
  tooltip,
  type,
  value,
  facetGroup,
  facetLoading,
  hidden,
  open: openByDefault,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openByDefault || false);
  const [error, setError] = useState<string>('');

  const [position, setPosition] = useState<Position>({
    left: -9999,
    top: -9999,
  });

  const { removeFilter } = useFilters();
  const { selectedFilterMenu, setSelectedFilterMenu } = useFilterMenuContext();

  /* Action naming mapping for filter */
  const getActionNamingMapping = useActionNamingMapping();

  const valueLength = value
    ?.replace(RELOAD_FLAG, '')
    ?.split(delimiters.multiSelectOptions)
    .filter(Boolean).length;
  const count = valueLength ? `${valueLength}` : '';
  const active = Boolean(valueLength);

  useEffect(() => {
    const filterHeight = 27;

    setPosition({
      left: containerRef.current?.offsetLeft || 0,
      top: (containerRef.current?.offsetTop || 0) + filterHeight,
    });
  }, [open, openByDefault]);

  const openMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (readonly) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const evt = getContextMenuEvent(rect);
    e.currentTarget.dispatchEvent(evt);
    setOpen(true);
  };

  const applyAndClose = () => {
    handleClose();
    onApply();
  };

  const CustomComponent = ComponentByType[type];

  const onError = (error: string) => {
    setError(error);
  };

  const handleClose = () => {
    setSelectedFilterMenu(null);
    setOpen(false);
    setError('');
  };

  const containerRef = React.useRef<HTMLDivElement>(null);

  // Re-open the menu at new anchorpoint after the Filter has been added from <AddFilterButton/>
  useEffect(() => {
    const isNewMd = !open && !value && !metaDataKey;
    const isMatch = type === selectedFilterMenu?.type;
    const shouldInit = isNewMd && isMatch;

    if (shouldInit) {
      containerRef.current?.dispatchEvent(
        new Event('click', { bubbles: true })
      );
    }
  }, [selectedFilterMenu, metaDataKey, open, type, value]);

  const isFolderFilter =
    type === FilterType.FolderId || type === FilterType.ParentFolderIds;
  const removeFolderFilter = () => removeFilter({ refetch: true, type });

  if (isFolderFilter) {
    return (
      <Tooltip placement="bottom" title={tooltip || name || ''}>
        <Styled.IconWrap $active={active}>
          <Box display="inline-flex" mr={1}>
            <Icon htmlColor="#006B5E" iconName="folder" />
          </Box>
          {name}
          <Icon
            cursor="pointer"
            htmlColor="#006B5E"
            iconName="close"
            onClick={removeFolderFilter}
          />
        </Styled.IconWrap>
      </Tooltip>
    );
  }

  if (hidden) {
    return (
      <Tooltip placement="bottom" title={tooltip || name || ''}>
        <Styled.IconWrap $active={active}>
          {filterNames[type]}
          <Icon
            cursor="pointer"
            htmlColor="#006B5E"
            iconName="close"
            onClick={removeFolderFilter}
          />
        </Styled.IconWrap>
      </Tooltip>
    );
  }

  return (
    <Box>
      <Styled.IconWrap $active={active} onClick={openMenu} ref={containerRef}>
        <>
          {name ||
            t(
              filterNames[type],
              filterNames[getActionNamingMapping(type) as FilterType]
            )}
          {count && !facetLoading && <Styled.Counter>{count}</Styled.Counter>}
        </>
        <Icon iconName="arrowDropDown" />
      </Styled.IconWrap>
      <StyledMenu
        anchorPosition={position}
        anchorReference="anchorPosition"
        disableAutoFocusItem
        onClose={applyAndClose}
        open={open}
      >
        <Styled.ContentWrap>
          {CustomComponent && (
            <Box display="flex" flex={1} flexDirection="column" overflow="auto">
              <CustomComponent
                facetGroup={facetGroup}
                facetLoading={facetLoading || false}
                metaDataKey={metaDataKey}
                name={name}
                onError={onError}
                type={type}
                value={value}
              />
            </Box>
          )}
        </Styled.ContentWrap>
        <Footer
          error={error}
          metaDataKey={metaDataKey}
          onApply={applyAndClose}
          type={type}
          value={value}
        />
      </StyledMenu>
    </Box>
  );
};
