import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { useDocument } from '@pro4all/documents/data-access';
import { useFolderByPathQuery } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import {
  ContentRow,
  FullScreen,
  Loader,
  Resizable,
  Tab,
  Tabs,
} from '@pro4all/shared/ui/general';
import { BigMessage } from '@pro4all/shared/ui/messages';

import { EntitySearch } from './entity-viewer/entity-search/EntitySearch';
import { EntityTree } from './entity-viewer/tree/EntityTree';
import { PropertyViewer } from './property-viewer/PropertyViewer';
import { ForgeViewer } from './viewers/forge/ForgeViewer';
import {
  NodeSelection,
  ViewerNode,
  ViewerPropertyGroup,
} from './viewers/Viewer.types';
import {
  CollapseContainer,
  CollapseIcon,
  EntityTreeContainer,
  ViewerContainer,
  ViewerEntityContainer,
  ViewerPropertyContainer,
  ViewerSideNav,
} from './Documents3DViewer.styles';

const searchNodeTreshold = 500;

export const Documents3DViewer = () => {
  const { t } = useTranslation();
  const { meData, userLanguage } = useOrganizationContext();

  const { params, searchParams } = useRouting();
  const isMobileScreen = useIsMobileScreen();

  const { data, loading: loadingFolder } = useFolderByPathQuery({
    fetchPolicy: 'cache-and-network',
    variables: { path: params?.path ?? '/', projectId: params?.projectId },
  });
  const { document, loading: loadingDocument, version } = useDocument();

  const [showSideNav, setShowSideNav] = useState<boolean>(!isMobileScreen);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sideNavWidth, setSideNavWidth] = useState<number>(300);
  const [entityView, setEntityView] = useState<string>('tree');
  const [viewer, setViewer] = useState<ForgeViewer | null>(null);
  const [viewerInitialized, setViewerInitialized] = useState<boolean>(false);
  const [searchDebounce, setSearchDebounce] = useState<number>();
  const [searchNodes, setSearchNodes] = useState<ViewerNode[] | undefined>([]);
  const [activeNodes, setActiveNodes] = useState<ViewerNode[]>([]);
  const [treeRootNodes, setTreeRootNodes] = useState<ViewerNode[]>();
  const [nodeProperties, setNodeProperties] = useState<ViewerPropertyGroup[]>(
    []
  );

  const folderIdByPath: string =
    data?.folder?.id !== undefined ? data?.folder?.id : '';

  const folderId = document?.folderId || version?.folderId || folderIdByPath;

  const { loading: loadingFolderPermissions } = useUserFolderPermissions({
    folderId,
  });

  const viewContainer = useRef<HTMLDivElement>(null);

  const handleSearch = (term: string) => {
    setSearchQuery(term);
    window.clearTimeout(searchDebounce);
    setSearchDebounce(
      window.setTimeout(async () => {
        const searchResults = await viewer?.search(term, 0, searchNodeTreshold);
        setSearchNodes(searchResults);
      }, 200)
    );
  };

  // Define viewer action handlers
  const handleOnSelect = (nodes: ViewerNode[]) => viewer?.select(nodes);
  const handleOnHide = (nodes: ViewerNode[]) => viewer?.hide(nodes);
  const handleOnIsolate = (nodes: ViewerNode[]) => viewer?.isolate(nodes);
  const handleOnFocus = (nodes: ViewerNode[]) => {
    viewer?.focus(nodes);
    viewer?.isolate(nodes);
  };
  const handleOnShowAll = () => viewer?.showAll();

  useEffect(() => {
    setShowSideNav(!isMobileScreen);
  }, [isMobileScreen]);

  // Create a new viewer when document and folder are loaded
  useEffect(() => {
    if (!viewer && !loadingDocument && !loadingFolder && meData) {
      // Because we can not track viewContainer, and the loading might be faster than the
      // creation of the container in the DOM, we set an interval to check if it has been set
      const interval = window.setInterval(() => {
        if (viewContainer.current && !viewer) {
          setViewer(
            new ForgeViewer(
              {
                accessToken: AuthService.getToken(),
                api: 'derivativeV2_EU',
                language: userLanguage ? userLanguage : 'en-US',
              },
              viewContainer.current,
              `${ApiConfig.threeDApi}/autodeskforgeproxy`
            )
          );
          window.clearInterval(interval);
        }
      }, 500);
    }
  }, [
    loadingDocument,
    loadingFolder,
    meData,
    searchParams,
    userLanguage,
    viewer,
  ]); // Don't remove 'loading' and 'loadingFolder' as a dependancy, else reloading the page won't initialize the viewer for sure!

  // Initialize the viewer when the viewer is created, and destroy
  // it when the component gets unloaded to avoid unwanted memory
  // usage
  useEffect(() => {
    const initializeViewer = async () => {
      if (viewer) {
        await viewer.init();
        viewer.registerSelectionEventHandler(
          async (nodeSelection: NodeSelection[]) => {
            // Fetch activeNode list from NodeSelection
            const activeNodes: ViewerNode[] = [];
            for (const selection of nodeSelection) {
              for (const nodeId of selection.ids) {
                const node = await viewer.getNode(nodeId, selection.model);
                activeNodes.push(node);
              }
            }
            setActiveNodes(activeNodes);

            // Set properties if a single object was selected
            if (activeNodes.length === 1) {
              const properties: ViewerPropertyGroup[] =
                await viewer.getNodeProperties(activeNodes[0]);
              setNodeProperties(properties);
            } else {
              setNodeProperties([]);
            }
          }
        );
        setViewerInitialized(true);
      }
    };

    initializeViewer();

    return () => {
      viewer?.destroy();
    };
  }, [viewer]);

  // When the viewer is initialized, load the models
  useEffect(() => {
    const loadModels = async () => {
      const urns: string[] = JSON.parse(searchParams.get('urn') || '{}');

      for (const urn of urns) {
        if (urn.length > 0) {
          await viewer?.loadModel(`urn:${urn}`);
          // Without Collapsible, the viewer needs a resize after loading the model
          setTimeout(() => {
            viewer?.resize();
          }, 500);
        }
      }

      if (viewer) {
        const entityTrees = await viewer.getNodeTrees();
        setTreeRootNodes(entityTrees);
      }
    };

    viewerInitialized && loadModels();
  }, [searchParams, viewer, viewerInitialized]);

  const toggleOpen = () => {
    setShowSideNav(!showSideNav);
    // Timeout required since collapsible
    setTimeout(() => {
      viewer?.resize();
    }, 200);
  };

  const handleSideNavResize = (width: number) => {
    setSideNavWidth(width);
  };

  const handleSideNavResizeEnd = () => {
    viewer?.resize();
  };

  if (loadingDocument || loadingFolder || loadingFolderPermissions)
    return <Loader />;

  return (
    <FullScreen
      onClose={() => {
        searchParams.delete('urn');
        searchParams.delete('fullscreen');
      }}
    >
      <ContentRow>
        {/* Commented out due to incompatibility with MUI Collapse. can be brought back when migrated to 5, since there is an orientation prop there */}
        {/* <Collapsible
              id="viewer-sidenav"
              onTransitionEnd={handleSideNavCollapse}
              open={showSideNav}
              orientation="horizontal"
              to={sideNavWidth}
            > */}
        {showSideNav ? (
          <Resizable
            initialWidth={sideNavWidth}
            onResize={handleSideNavResize}
            onResizeEnd={handleSideNavResizeEnd}
            threshold={0}
          >
            <ViewerSideNav>
              <ViewerEntityContainer>
                <Tabs
                  onChange={(_, value) => setEntityView(value)}
                  value={entityView}
                  variant="fullWidth"
                >
                  <Tab
                    data-testid="viewer-entity-view-tree"
                    label={t('Hierarchy')}
                    value="tree"
                  />
                  <Tab
                    data-testid="viewer-entity-view-type"
                    label={t('Search')}
                    value="search"
                  />
                </Tabs>
                {entityView === 'tree' && (
                  <EntityTreeContainer>
                    {treeRootNodes &&
                      treeRootNodes.map((treeRootNode) => (
                        <EntityTree
                          activeNodes={activeNodes}
                          key={`${treeRootNode.model.id}-${treeRootNode.id}`}
                          onFocus={handleOnFocus}
                          onHide={handleOnHide}
                          onIsolate={handleOnIsolate}
                          onSelect={handleOnSelect}
                          onShowAll={handleOnShowAll}
                          rootNode={treeRootNode}
                        ></EntityTree>
                      ))}
                  </EntityTreeContainer>
                )}
                {entityView === 'search' && (
                  <EntitySearch
                    activeNodes={activeNodes}
                    displayWarningTreshhold={searchNodeTreshold}
                    nodes={searchNodes}
                    onFocus={handleOnFocus}
                    onHide={handleOnHide}
                    onIsolate={handleOnIsolate}
                    onSearch={handleSearch}
                    onSelect={handleOnSelect}
                    onShowAll={handleOnShowAll}
                    searchQuery={searchQuery}
                  ></EntitySearch>
                )}
              </ViewerEntityContainer>
              <ViewerPropertyContainer>
                {nodeProperties.length > 1 ? (
                  <PropertyViewer
                    propertyGroups={nodeProperties}
                  ></PropertyViewer>
                ) : (
                  <BigMessage
                    shapeName="flow1"
                    title={t('Select an entity to show details')}
                  />
                )}
              </ViewerPropertyContainer>
            </ViewerSideNav>
          </Resizable>
        ) : (
          <></>
        )}
        {/* </Collapsible> */}
        <CollapseContainer onClick={toggleOpen}>
          <CollapseIcon
            iconName={showSideNav ? 'expandLess' : 'expandMore'}
          ></CollapseIcon>
        </CollapseContainer>
        <ViewerContainer ref={viewContainer} />
        <link
          href="https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
          rel="stylesheet"
          type="text/css"
        />
      </ContentRow>
    </FullScreen>
  );
};
