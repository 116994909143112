/*
This method is required to calculate the value for a multiValue selection type field.
This can be:
1. A comma separated string (e.g. "value1, value2")
2. A JSON stringified array (e.g. '["value1", "value2"]')
The second option will be the future and is already implemented for `SavedAnswers`,
That is because the first option causes issues when users include a comma in the value.
So we have to support both options for now. But in the future we will only support the JSON stringified array.
*/
export const getMultiValue = (value: string) => {
  let multiValue = [] as string[];
  try {
    const parsedValue = JSON.parse(value);
    if (Array.isArray(parsedValue)) {
      multiValue = parsedValue;
    } else {
      throw new Error('Parsed value is not an array');
    }
  } catch (e) {
    const items = value.split(',').map((item) => item.trim());
    multiValue = items;
  }

  return multiValue;
};
