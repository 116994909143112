import { useTranslation } from 'react-i18next';

import { useUpdateCachedDocumentsList } from '@pro4all/documents/ui/utils';
import {
  Document,
  DocumentVersion,
  FinalizationState,
  LockType,
  UploadFileOption,
  useMoveDocumentMutation,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { firstCharToLowerCase } from '@pro4all/shared/utils';

export enum ValidateStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const useDropDocument = () => {
  const { t } = useTranslation();
  const [moveDocument] = useMoveDocumentMutation();
  const updateCachedDocument = useUpdateCachedDocumentsList();
  const { showSingleError } = useShowMessages();
  const { finalize_action_label } = useDocumentActionsLabels();
  const { settings } = useProjectContext();

  const dropDocument = async (
    currentDocumentId: string,
    currentDocumentVersion: DocumentVersion,
    destinationDocumentId: string
  ) => {
    try {
      const response = await moveDocument({
        variables: {
          moveDocumentData: {
            destinationDocumentId: destinationDocumentId,
            overwriteDocumentName:
              settings.version_name_instead_of_document_name_on_upload ===
              UploadFileOption.UseFromUploaded,
            sourceDocumentId: currentDocumentId,
          },
        },
      });
      await updateCachedDocument({
        currentDocumentId,
        currentDocumentVersion,
        destinationDocumentId,
        newVersionId: response.data.moveDocument,
      });
      return true;
    } catch (e) {
      showSingleError(e);
      return false;
    }
  };

  const validateDropDocument = (
    destinationDocument: Document,
    sourceDocument: Document,
    canUserUpdate: boolean,
    versions: number
  ) => {
    // Validate same document
    if (destinationDocument.id === sourceDocument.id)
      return {
        message: t(
          "No new version. A file can't be a new version of itself. Please select a different file to save as a new version for this file."
        ),
        state: ValidateStatus.ERROR,
      };
    // Validate permissions
    if (!canUserUpdate)
      return {
        message: t('You do not have permissions to modify this document.'),
        state: ValidateStatus.ERROR,
      };

    // Validate state
    if (destinationDocument.state === FinalizationState.Finalized)
      return {
        message: t(
          'The document you are attempting to change is set to {{status}} and cannot be modified.',
          { status: firstCharToLowerCase(finalize_action_label) }
        ),
        state: ValidateStatus.ERROR,
      };

    if (sourceDocument.state === FinalizationState.Finalized)
      return {
        message: t(
          'The source document is set to {{status}} and therefore cannot be moved.',
          { status: firstCharToLowerCase(finalize_action_label) }
        ),
        state: ValidateStatus.ERROR,
      };

    // Validate version
    // Changed to use versions.length to check just current versions
    if (versions > 1)
      return {
        message: t(
          'This task can only be performed with documents that only have one version.'
        ),
        state: ValidateStatus.ERROR,
      };

    // Validate lock
    if (destinationDocument.lockType !== LockType.None)
      return {
        message: t(
          'The document you are attempting to change is locked and cannot be modified.'
        ),
        state: ValidateStatus.ERROR,
      };

    if (sourceDocument.lockType !== LockType.None)
      return {
        message: t(
          'The source document is locked and therefore cannot be moved.'
        ),
        state: ValidateStatus.ERROR,
      };
    // Validate expeted file
    if (sourceDocument.isExpected)
      return {
        message: t(
          'The source document is expected and therefore cannot be moved.'
        ),
        state: ValidateStatus.ERROR,
      };

    return {
      message: 'success',
      state: ValidateStatus.SUCCESS,
    };
  };

  return { dropDocument, validateDropDocument };
};
