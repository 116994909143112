import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Answer,
  FieldDefinition,
  useAddSavedAnswersMutation,
  useUpdateSavedAnswersMutation,
} from '@pro4all/graphql';
import {
  convertQcInstanceValue,
  getItem,
} from '@pro4all/quality-control/ui/shared';
import { useRouting } from '@pro4all/shared/routing-utils';
import { InstanceValues } from '@pro4all/shared/types';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';
import { stringifyArrayValue } from '@pro4all/shared/utils';

import { useCreateSavedAnswersContext } from './CreateSavedAnswersContext';
import { useApolloClientCacheHelpers } from './useApolloClientCacheHelpers';

export const useSubmit = ({
  items,
  onClose,
}: {
  items: FieldDefinition[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [addSavedAnswers] = useAddSavedAnswersMutation();
  const [updateSavedAnswers] = useUpdateSavedAnswersMutation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();
  const { getCurrentlyCachedSavedAnswers, updateCachedSavedAnswers } =
    useApolloClientCacheHelpers();

  const { selectedTemplate } = useCreateSavedAnswersContext();

  // Edit context.
  const id = searchParams.get('id');

  // Create context.
  const routeCreate = searchParams.is('action', 'createSavedAnswer');
  const templateId = selectedTemplate?.id || '';

  const onSubmit = async ({
    initialValues,
    resetForm,
    values,
  }: {
    initialValues: InstanceValues;
    resetForm: () => void;
    values: InstanceValues;
  }) => {
    let newName: string | null = null;
    if (values['name'] !== initialValues['name']) {
      newName = values['name'] as string;
    }

    const answers = Object.keys(values)
      .filter(
        (key) => key !== 'name' && !isEqual(values[key], initialValues[key])
      )
      .map((key) => {
        const item = getItem({ id: key, items });
        const valuePayload = item
          ? convertQcInstanceValue({ item, value: values[key] })
          : stringifyArrayValue(values[key]);
        return {
          fieldId: key,
          value: valuePayload,
        };
      });

    try {
      let message = null;
      if (routeCreate) {
        const response = await addSavedAnswers({
          variables: {
            answers: answers as Answer[],
            name: newName || '',
            projectId,
            templateId,
          },
        });

        const savedAnswersId = response.data?.addSavedAnswers;

        message = (
          <ItemChangedMessage
            description={MessageAction.Create}
            entityName={newName || ''}
            entityTypeTranslation={EntityTypeTranslation.SavedAnswers}
          />
        );

        resetForm();

        const cachedSavedAnswers = getCurrentlyCachedSavedAnswers();
        const newSavedAnswers = {
          __typename: 'SavedAnswers' as const,
          createdOn: new Date().toISOString(),
          id: savedAnswersId || '',
          name: newName || '',
          templateId: templateId,
          templateName: selectedTemplate.name,
          type: selectedTemplate.type,
        };
        updateCachedSavedAnswers([...cachedSavedAnswers, newSavedAnswers]);

        enqueueSnackbar(message);
        onClose();
      } else {
        await updateSavedAnswers({
          variables: {
            answers: answers as Answer[],
            id: id || '',
            name: newName,
          },
        });

        message = (
          <ItemChangedMessage
            description={MessageAction.Update}
            entityName={newName || (initialValues['name'] as string)}
            entityTypeTranslation={EntityTypeTranslation.SavedAnswers}
          />
        );

        resetForm();

        const cachedSavedAnswers = getCurrentlyCachedSavedAnswers();
        const otherSavedAnswers = cachedSavedAnswers.filter(
          (savedAnswers) => savedAnswers.id !== id
        );
        const thisSavedAnswers = cachedSavedAnswers.find(
          (savedAnswers) => savedAnswers.id === id
        );
        if (thisSavedAnswers) {
          updateCachedSavedAnswers([
            ...otherSavedAnswers,
            { ...thisSavedAnswers, name: newName || thisSavedAnswers.name },
          ]);
        }

        enqueueSnackbar(message);
        onClose();
      }
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };

  return onSubmit;
};
