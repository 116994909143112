import React, { createContext, useContext, useMemo, useState } from 'react';

// Must be CountObject. In this way when level = 0, and user presses Collapse one level, the level stays 0, but the object is refreshed
// causing the sections to update.
type CountObject = {
  initial: boolean;
  level: number;
};

type CounterContextValue = {
  collapseLevel: CountObject;
  setCollapseLevel: (count: CountObject) => void;
};

const CollapseButtonsContext = createContext<CounterContextValue>({
  collapseLevel: { initial: true, level: -1 },
  setCollapseLevel: (_) => {
    throw new Error('Default');
  },
});

export const CollapseButtonProvider: React.FC = ({ children }) => {
  const [collapseLevel, setCollapseLevel] = useState({
    initial: true,
    level: 0,
  });
  const value = useMemo(
    () => ({ collapseLevel, setCollapseLevel }),
    [collapseLevel, setCollapseLevel]
  );

  return (
    <CollapseButtonsContext.Provider value={value}>
      {children}
    </CollapseButtonsContext.Provider>
  );
};

export const useCollapseButtons = () => {
  const context = useContext(CollapseButtonsContext);
  if (!context) {
    throw new Error('useCounter must be used within a CounterProvider');
  }
  return context;
};
