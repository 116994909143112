import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useDocument } from '@pro4all/documents/data-access';
import {
  useFolderPath,
  useFolderTreeContextInner,
  useFolderTreeContextOuter,
} from '@pro4all/documents/ui/folders';
import {
  CollectionTable,
  DocumentTable,
  SmartFolderTable,
  VersionPane,
} from '@pro4all/documents/ui/table';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import {
  BigMessageNoAccess,
  BigMessageNoFolders,
} from '@pro4all/shared/ui/messages';

export const TableRoutes: React.FC = () => {
  const { loading } = useFolderTreeContextInner();
  const {
    state: { folderTree },
  } = useFolderTreeContextOuter();
  const { smartFolderName } = useFolderPath();

  const { params, searchParams } = useRouting();
  const { projectId } = params;

  const documentId = searchParams.get('id');
  const versionId = searchParams.get('versionid');

  const { document, version } = useDocument();

  if (
    !loading &&
    documentId &&
    document === null &&
    versionId &&
    version === null
  )
    return <BigMessageNoAccess />;
  if (!loading && !folderTree?.length) return <BigMessageNoFolders />;

  const redirect = projectId
    ? `${generateRoute('projectDocsBase', {
        params: { projectId },
      })}`
    : '';

  return (
    <Switch>
      <Route exact path={Routes.projectDmsBase}>
        <Redirect to={redirect} />
      </Route>
      <Route path={[Routes.collectionFolder, Routes.projectCollectionFolder]}>
        <CollectionTable />
      </Route>
      <Route path={[Routes.smartFolder, Routes.projectSmartFolder]}>
        <SmartFolderTable folderName={smartFolderName} />
      </Route>
      <Route path={[Routes.docs, Routes.projectDocs]}>
        {folderTree?.length && (
          <>
            <DocumentTable />
            <VersionPane />
          </>
        )}
      </Route>
    </Switch>
  );
};
