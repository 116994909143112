import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useDownloadZipFormConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Include subfolders', // i18n
      name: 'includeSubfolders',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
