import { FolderPermission } from '@pro4all/graphql';

import {
  hasAnyDocumentsPermission,
  isVersionsNone,
} from './checkPermissionsWrappers';

export const addReadAllVersionsPermission = (
  folderPermissions: FolderPermission[]
) => {
  const response =
    hasAnyDocumentsPermission(folderPermissions) &&
    isVersionsNone(folderPermissions)
      ? [...folderPermissions, FolderPermission.ReadAllVersions]
      : folderPermissions;

  return response;
};
