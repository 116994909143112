import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  DocumentVersion,
  FinalizationState,
  FolderPermission,
  LockType,
  useFolderWithEntityPermissionsQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { DocumentAndVersionType } from '@pro4all/shared/types';

export type FolderPermissionType = keyof typeof FolderPermission;

export interface UseUserFolderPermissionsArgs {
  folderId: string;
  userId?: string;
}

export const useUserFolderPermissions = ({
  folderId,
  userId = AuthService.getProfile()?.userId,
}: UseUserFolderPermissionsArgs) => {
  const { t } = useTranslation();
  const { userId: loggedInUserId } = AuthService.getProfile() || {};

  const folderWithUserPermissionsQuery = useFolderWithEntityPermissionsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !folderId,
    variables: {
      entityIds: [userId],
      id: folderId,
    },
  });

  const userFolderPermissions =
    folderWithUserPermissionsQuery.data?.folder?.entityFolderPermissions?.find(
      (efp) => efp.id === userId
    );
  const directFolderPermissions = useMemo(
    () => userFolderPermissions?.folderPermissions || [],
    [userFolderPermissions?.folderPermissions]
  );
  const inheritedFolderPermissions = useMemo(
    () =>
      userFolderPermissions?.inheritedFrom.map(
        (inheritedPermissionProps) => inheritedPermissionProps.permission
      ) || [],
    [userFolderPermissions?.inheritedFrom]
  );

  const currentPermissions = useMemo(
    () => [...directFolderPermissions, ...inheritedFolderPermissions],
    [directFolderPermissions, inheritedFolderPermissions]
  );

  const hasFolderPermission = useCallback(
    (permission: FolderPermissionType) =>
      currentPermissions?.includes(FolderPermission[permission]),
    [currentPermissions]
  );

  // Can user update the name of the document container, meta data, tags, AI Summary, AI Keywords.
  const canUpdateDocumentOrVersion = useCallback(
    ({ document, version }: DocumentAndVersionType) =>
      hasFolderPermission('UpdateContent') ||
      (hasFolderPermission('UpdateOwn') &&
        (document?.createdBy?.id === loggedInUserId ||
          version?.createdBy?.id === loggedInUserId)),
    [hasFolderPermission, loggedInUserId]
  );

  const canUploadVersion = useCallback(
    (document: Document | null) =>
      hasFolderPermission('CreateContent') &&
      document?.state !== FinalizationState.Finalized &&
      document?.lockType !== LockType.Prostream,
    [hasFolderPermission]
  );

  const getEditableDocumentsOrVersions = useCallback(
    ({
      documents,
      excludeFinalized = true,
      versions,
    }: {
      documents?: Document[];
      excludeFinalized?: boolean;
      versions?: DocumentVersion[];
    }) => {
      const filterDocumentsOrVersions = (
        documentOrVersion: Document | DocumentVersion
      ) =>
        excludeFinalized
          ? documentOrVersion.state !== FinalizationState.Finalized
          : true;

      const documentsFiltered = documents?.filter(filterDocumentsOrVersions);
      const versionsFiltered = versions?.filter(filterDocumentsOrVersions);

      return documentsFiltered
        ? documentsFiltered?.filter(
            (document) =>
              !document.isExpected &&
              canUpdateDocumentOrVersion({
                document: document ? document : null,
                version: null,
              })
          )
        : versionsFiltered?.filter(
            (version) =>
              !version.isExpected &&
              canUpdateDocumentOrVersion({
                document: null,
                version: version ? version : null,
              })
          );
    },
    [canUpdateDocumentOrVersion]
  );

  const disabled = userId === loggedInUserId;

  const availablePermissions =
    folderWithUserPermissionsQuery.data?.folder?.availablePermissions.map(
      (id) => ({
        disabled,
        id,
        label: t(`Api.permissions.${id}`),
      })
    );

  return useMemo(
    () => ({
      ...folderWithUserPermissionsQuery,
      availablePermissions,
      canUpdateDocumentOrVersion,
      canUploadVersion,
      currentPermissions,
      folder: folderWithUserPermissionsQuery.data?.folder,
      getEditableDocumentsOrVersions,
      hasFolderPermission,
      loadingFolderPermissions: folderWithUserPermissionsQuery.loading,
    }),
    [
      folderWithUserPermissionsQuery,
      availablePermissions,
      canUpdateDocumentOrVersion,
      canUploadVersion,
      currentPermissions,
      getEditableDocumentsOrVersions,
      hasFolderPermission,
    ]
  );
};
