import React, { useState } from 'react';

import { useId } from '@pro4all/shared/hooks';

import type { Rect } from './rect';
import * as Styled from './Shape.styles';
import { useRect } from './useRect';

export interface Props {
  canvas: { height: number; width: number };
  children: React.ReactNode;
  container?: HTMLElement | null;
  id?: string;
  rect: Rect;
}

export const Shape: React.FC<Props> = ({
  canvas,
  children,
  container,
  id,
  rect,
}) => {
  const _id = useId(id);
  const [style, setStyle] = useState<{ height: number; width: number }>(
    rect.style
  );

  const outOfBounds = (nextRect: Partial<Rect>) => {
    if (!nextRect) return;

    return (
      (nextRect.bottom && nextRect.bottom > canvas.height) ||
      (nextRect.left && nextRect.left < 0) ||
      (nextRect.right && nextRect.right > canvas.width) ||
      (nextRect.top && nextRect.top < 0)
    );
  };

  const {
    move,
    resizeBottomLeft,
    resizeBottomRight,
    resizeTopLeft,
    resizeTopRight,
  } = useRect({
    container,
    rect,
    update: (nextRect) => {
      if (outOfBounds(nextRect)) return;

      // We dispatch an update without a payload so that the store returns
      // a new state reference: this makes React rerender every component
      // dependent on this state, including every shape.
      rect.set(nextRect);
      setStyle(rect.style);
    },
  });

  return (
    <Styled.Guide aria-grabbed="false" {...move} id={_id} style={style}>
      {children}
      <Styled.Handle
        $position="topLeft"
        aria-grabbed="false"
        title="Resize top-left"
        {...resizeTopLeft}
      />
      <Styled.Handle
        $position="topRight"
        aria-grabbed="false"
        title="Resize top-right"
        {...resizeTopRight}
      />
      <Styled.Handle
        $position="bottomLeft"
        aria-grabbed="false"
        title="Resize bottom-left"
        {...resizeBottomLeft}
      />
      <Styled.Handle
        $position="bottomRight"
        aria-grabbed="false"
        title="Resize bottom-right"
        {...resizeBottomRight}
      />
    </Styled.Guide>
  );
};
