import { Option } from '@pro4all/shared/types';

import { useGetIntegrationTypeData } from '../form/useGetIntegrationTypeData';

import { useGetConnection } from './useGetConnection';
import { useGetProjectOptions } from './useGetProjectOptions';

export const useGetInitialValues = () => {
  const { integrationId } = useGetIntegrationTypeData();
  const { dataConnection, loadingConnection } = useGetConnection();
  const { loadingProjects, projectOptions } =
    useGetProjectOptions(integrationId);

  const initialProject =
    !loadingConnection && !loadingProjects
      ? projectOptions.find(
          (projectOption) => projectOption.id === dataConnection?.projectId
        )
      : null;

  const customBuild12InitialValues = {
    project: initialProject ? initialProject : (null as unknown as Option),
  };

  const customSnagstreamInitialValues = {
    project: initialProject ? initialProject : (null as unknown as Option),
  };

  return {
    ...customBuild12InitialValues,
    ...customSnagstreamInitialValues,
  };
};
