import React, { createContext, useContext, useMemo, useState } from 'react';

import { TemplateType } from '@pro4all/graphql';

type SelectedTemplate = {
  id: string;
  name: string;
  type: TemplateType;
};

interface CreateSavedAnswersContextValue {
  selectedTemplate: SelectedTemplate;
  setSelectedTemplate: (template: SelectedTemplate) => void;
}

export const CreateSavedAnswersContext = createContext(
  {} as CreateSavedAnswersContextValue
);

export const useCreateSavedAnswersContext = () =>
  useContext<CreateSavedAnswersContextValue>(CreateSavedAnswersContext) || {
    selectedTemplate: null,
    setSelectedTemplate: null,
  };

export const CreateSavedAnswersContextProvider: React.FC = ({ children }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>();

  const value = useMemo(
    () => ({
      selectedTemplate,
      setSelectedTemplate,
    }),
    [selectedTemplate, setSelectedTemplate]
  );

  return (
    <CreateSavedAnswersContext.Provider value={value}>
      {children}
    </CreateSavedAnswersContext.Provider>
  );
};
