import { ReportConfiguration, Template } from '@pro4all/graphql';
import {
  ActiveReportConfigFormFields,
  MapElements,
  Option,
  PayloadHiddenSectionsAndFields,
  ReportOptions,
  TemplateOptions,
} from '@pro4all/shared/types';
import { sortBy } from '@pro4all/shared/utils';

import { defaultTemplate } from './useGetInitialReportOptions';
import { useGetInitialReportOptions } from './useGetInitialReportOptions';

export enum ActionType {
  ADD_BACKGROUND_OPTION = 'ADD_BACKGROUND_OPTION',
  ADD_LOGO_OPTION = 'ADD_LOGO_OPTION',
  SELECT_REPORT_CONFIGURATION = 'SELECT_REPORT_CONFIGURATION',
  SET_ACTIVE_REPORT_CONFIGURATION = 'SET_ACTIVE_REPORT_CONFIGURATION',
  SET_BACKGROUND = 'SET_BACKGROUND',
  SET_BACKGROUND_FRONT_PAGE = 'SET_BACKGROUND_FRONT_PAGE',
  SET_BACKGROUND_OPTIONS = 'SET_BACKGROUND_OPTIONS',
  SET_HIDDEN_SECTIONS_AND_FIELDS = 'SET_HIDDEN_SECTIONS_AND_FIELDS',
  SET_LOGO = 'SET_LOGO',
  SET_LOGO_OPTIONS = 'SET_LOGO_OPTIONS',
  SET_MINI_MAPS = 'SET_MINI_MAPS',
  SET_REPORT_CONFIGURATIONS = 'SET_REPORT_CONFIGURATIONS',
  SET_REPORT_DRAWINGS = 'SET_REPORT_DRAWINGS',
  SET_REPORT_OPTIONS = 'SET_REPORT_OPTIONS',
  SET_TEMPLATE_OPTIONS = 'SET_TEMPLATE_OPTIONS',
  TOGGLE_CUSTOM_MODE = 'TOGGLE_CUSTOM_MODE',
}

export type State = {
  activeReportConfiguration: ActiveReportConfigFormFields | undefined;
  backgroundOptions: Option[];
  customMode: boolean;
  logoOptions: Option[];
  miniMaps: MapElements | undefined;
  reportConfigurations: ReportConfiguration[];
  reportDrawings: MapElements | undefined;
  reportOptions: ReportOptions;
};

export type PayloadAddBackgroundOption = Option;
export type PayloadAddLogoOption = Option;
export type PayloadSelectReportConfiguration = Option;
export type PayloadSetActiveReportConfiguration = ActiveReportConfigFormFields;
export type PayloadSetBackground = string;
export type PayloadSetBackgroundFrontPage = string;
export type PayloadSetBackgroundOptions = Option[];
export type PayloadSetHiddenSectionsAndFields = PayloadHiddenSectionsAndFields;
export type PayloadSetLogo = string;
export type PayloadSetLogoOptions = Option[];
export type PayloadSetMiniMaps = MapElements;
export type PayloadSetReportConfigurationTemplates = Template[];
export type PayloadSetReportConfigurations = ReportConfiguration[];
export type PayloadSetReportDrawings = MapElements;
export type PayloadSetReportOptions = ReportOptions;
export type PayloadSetTemplateOptions = {
  id: string;
  options: TemplateOptions;
};

type ActionAddBackgroundOption = {
  payload: PayloadAddBackgroundOption;
  type: ActionType.ADD_BACKGROUND_OPTION;
};

type ActionAddLogoOption = {
  payload: PayloadAddLogoOption;
  type: ActionType.ADD_LOGO_OPTION;
};

type ActionSelectReportConfiguration = {
  payload: PayloadSelectReportConfiguration;
  type: ActionType.SELECT_REPORT_CONFIGURATION;
};

type ActionSetActiveReportConfiguration = {
  payload: PayloadSetActiveReportConfiguration;
  type: ActionType.SET_ACTIVE_REPORT_CONFIGURATION;
};

type ActionSetBackground = {
  payload: PayloadSetBackground;
  type: ActionType.SET_BACKGROUND;
};

type ActionSetBackgroundFrontPage = {
  payload: PayloadSetBackgroundFrontPage;
  type: ActionType.SET_BACKGROUND_FRONT_PAGE;
};

type ActionSetBackgroundOptions = {
  payload: PayloadSetBackgroundOptions;
  type: ActionType.SET_BACKGROUND_OPTIONS;
};

type ActionSetHiddenSectionsAndFields = {
  payload: PayloadSetHiddenSectionsAndFields;
  type: ActionType.SET_HIDDEN_SECTIONS_AND_FIELDS;
};

type ActionSetLogo = {
  payload: PayloadSetLogo;
  type: ActionType.SET_LOGO;
};

type ActionSetLogoOptions = {
  payload: PayloadSetLogoOptions;
  type: ActionType.SET_LOGO_OPTIONS;
};

type ActionSetMiniMaps = {
  payload: PayloadSetMiniMaps;
  type: ActionType.SET_MINI_MAPS;
};

type ActionSetReportConfigurations = {
  payload: PayloadSetReportConfigurations;
  type: ActionType.SET_REPORT_CONFIGURATIONS;
};

type ActionSetReportDrawings = {
  payload: PayloadSetReportDrawings;
  type: ActionType.SET_REPORT_DRAWINGS;
};

type ActionSetReportOptions = {
  payload: PayloadSetReportOptions;
  type: ActionType.SET_REPORT_OPTIONS;
};

type ActionSetTemplateOptions = {
  payload: PayloadSetTemplateOptions;
  type: ActionType.SET_TEMPLATE_OPTIONS;
};

type ActionToggleCustomMode = {
  type: ActionType.TOGGLE_CUSTOM_MODE;
};

export type Action =
  | ActionAddBackgroundOption
  | ActionAddLogoOption
  | ActionSelectReportConfiguration
  | ActionSetActiveReportConfiguration
  | ActionSetBackground
  | ActionSetBackgroundFrontPage
  | ActionSetBackgroundOptions
  | ActionSetHiddenSectionsAndFields
  | ActionSetLogo
  | ActionSetLogoOptions
  | ActionSetMiniMaps
  | ActionSetReportConfigurations
  | ActionSetReportDrawings
  | ActionSetReportOptions
  | ActionSetTemplateOptions
  | ActionToggleCustomMode;

const sortOptions = (options: Option[]) =>
  options.sort(sortBy({ key: 'label' }));

export const useReportOptionsReducer = () => {
  const initialReportOptions = useGetInitialReportOptions();

  const reportOptionsReducer = (state: State, action: Action) => {
    switch (action.type) {
      case ActionType.ADD_BACKGROUND_OPTION: {
        const updatedOptions = [...state.backgroundOptions, action.payload];
        return {
          ...state,
          backgroundOptions: sortOptions(updatedOptions),
        };
      }

      case ActionType.ADD_LOGO_OPTION: {
        const updatedOptions = [...state.logoOptions, action.payload];
        return { ...state, logoOptions: sortOptions(updatedOptions) };
      }

      case ActionType.SELECT_REPORT_CONFIGURATION: {
        // Find the selected report configuration.
        const reportConfiguration = state.reportConfigurations.find(
          (config) => config.id === action.payload?.id
        );

        // So we can take the reportOptions from that report configuration.
        const reportOptions = reportConfiguration
          ? JSON.parse(reportConfiguration.reportOptions)
          : initialReportOptions;

        // Calculate the new activeReportConfiguration.
        const { id, label } = action.payload || {};
        const activeReportConfiguration = {
          id: id ? id : 'default',
          name: label ? label : 'default',
          setAsDefault: Boolean(reportConfiguration?.isDefault),
          storeOnOrganizationLevel: Boolean(!reportConfiguration?.projectId),
        };

        return {
          ...state,
          activeReportConfiguration,
          reportOptions,
        };
      }

      case ActionType.SET_ACTIVE_REPORT_CONFIGURATION: {
        // This action is triggered if:
        // 1. user saves a new report configuration.
        // 2. user updates an existing report configuration.

        // Check if there's already a report configuration in `reportConfigurations`.
        const { id, name } = action.payload;
        const reportConfiguration = state.reportConfigurations.find(
          (config) => config.id === id
        );

        const reportConfigurations = reportConfiguration
          ? state.reportConfigurations // TODO: overwrite the existing report configuration in `reportConfigurations` with the new one when we have the props `setAsDefault` and `storeOnOrganizationLevel` fetched.
          : [
              ...state.reportConfigurations,
              { id, name, reportOptions: JSON.stringify(state.reportOptions) }, // Add the new report configuration to `reportConfigurations`.
            ].sort(sortBy({ key: 'name' }));

        return {
          ...state,
          activeReportConfiguration: action.payload,
          reportConfigurations,
        };
      }

      case ActionType.SET_BACKGROUND: {
        const reportOptions = { ...state.reportOptions };
        return {
          ...state,
          reportOptions: { ...reportOptions, bgId: action.payload },
        };
      }

      case ActionType.SET_BACKGROUND_FRONT_PAGE: {
        const reportOptions = { ...state.reportOptions };
        return {
          ...state,
          reportOptions: { ...reportOptions, bgIdFrontPage: action.payload },
        };
      }

      case ActionType.SET_BACKGROUND_OPTIONS: {
        if (state.backgroundOptions.length) return state; // Prevent overwriting the newly added background options.
        return { ...state, backgroundOptions: sortOptions(action.payload) };
      }

      case ActionType.SET_HIDDEN_SECTIONS_AND_FIELDS: {
        const { add, ids, templateId } = action.payload || {};

        if (!ids || !templateId) return state;

        const updatedTemplate =
          state.reportOptions.templates &&
          state.reportOptions.templates[templateId]
            ? state.reportOptions.templates[templateId]
            : defaultTemplate;

        const updatedHiddenSectionsAndFields = add
          ? [...updatedTemplate.hiddenSectionsAndFields, ...ids]
          : updatedTemplate.hiddenSectionsAndFields.filter(
              (id) => !ids.includes(id)
            );

        const isTemplateAlreadyInReportOptions = Boolean(
          state?.reportOptions?.templates?.[templateId]
        );

        return {
          ...state,
          reportOptions: {
            ...state.reportOptions,
            templates: {
              ...state.reportOptions.templates,
              ...{
                [templateId]: isTemplateAlreadyInReportOptions
                  ? {
                      ...state?.reportOptions?.templates?.[templateId],
                      hiddenSectionsAndFields: updatedHiddenSectionsAndFields,
                    }
                  : {
                      ...defaultTemplate,
                      hiddenSectionsAndFields: updatedHiddenSectionsAndFields,
                    },
              },
            },
          },
        };
      }

      case ActionType.SET_LOGO: {
        const reportOptions = { ...state.reportOptions };
        return {
          ...state,
          reportOptions: { ...reportOptions, logoId: action.payload },
        };
      }

      case ActionType.SET_LOGO_OPTIONS: {
        if (state.logoOptions.length) return state; // Prevent overwriting the newly added logo options.
        return { ...state, logoOptions: sortOptions(action.payload) };
      }

      case ActionType.SET_MINI_MAPS: {
        return { ...state, miniMaps: action.payload };
      }

      case ActionType.SET_REPORT_CONFIGURATIONS: {
        const reportConfigurations = action.payload;
        return { ...state, reportConfigurations };
      }

      case ActionType.SET_REPORT_DRAWINGS: {
        return { ...state, reportDrawings: action.payload };
      }

      case ActionType.SET_REPORT_OPTIONS: {
        return { ...state, reportOptions: action.payload };
      }

      case ActionType.SET_TEMPLATE_OPTIONS: {
        const { id, options } = action.payload;
        const isTemplateAlreadyInReportOptions = Boolean(
          state?.reportOptions?.templates?.[id]
        );
        return {
          ...state,
          reportOptions: {
            ...state.reportOptions,
            templates: {
              ...state.reportOptions.templates,
              ...{
                [id]: isTemplateAlreadyInReportOptions
                  ? { ...state?.reportOptions?.templates?.[id], ...options }
                  : { ...defaultTemplate, ...options },
              },
            },
          },
        };
      }

      case ActionType.TOGGLE_CUSTOM_MODE: {
        return { ...state, customMode: !state.customMode };
      }

      default:
        return state;
    }
  };

  return { reportOptionsReducer };
};
