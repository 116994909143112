import {
  FilterColumnIdProps,
  FilterColumnProps,
} from '@pro4all/shared/ui/filtering';
import { BaseRow } from '@pro4all/shared/ui/general';

export function getColumnKey<Row extends BaseRow, SubProp>({
  metaDataHeaderId = '',
  propertyId,
  subPropertyId,
}: Pick<FilterColumnProps, 'metaDataHeaderId'> &
  Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId' | 'subPropertyId'>) {
  const keyPropertyId = metaDataHeaderId
    ? `${propertyId as string}.${metaDataHeaderId}`
    : (propertyId as string);
  const keySubPropertyId = subPropertyId as string;

  return { keyPropertyId, keySubPropertyId };
}

export function isPropFilled<Row extends BaseRow, SubProp>({
  item,
  propertyId,
}: { item: Row } & Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId'>) {
  const value = item[propertyId] as unknown as string;
  return value !== null && value !== undefined && value !== '';
}
