import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Folder,
  useApplyTemplateMutation,
  useFolderByPathQuery,
} from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useFolderTreeContextOuter } from '../tree/FolderTreeProviderOuter';

import { FolderMetaDataFormProps } from './FolderMetaDataForm';
import { FormFields } from './FolderMetaDataSidebar';

export const useSubmit = ({ folder, onClose }: FolderMetaDataFormProps) => {
  const { params, searchParams } = useRouting();

  // We want to use the path of the selected folderpath here to only refetch that folder
  const [path, setPath] = useState<string>();
  const { mutateFolderProp } = useFolderTreeContextOuter();

  useEffect(() => {
    if (searchParams.get('folderPath')) {
      const folderPathToSet = decodeURIComponent(
        searchParams.get('folderPath')
      ).replace(',', '/');
      setPath(folderPathToSet);
    }
  }, [searchParams, setPath]);

  const { refetch } = useFolderByPathQuery({
    fetchPolicy: 'cache-and-network',
    skip: true,
    variables: { path: path ?? '/', projectId: params?.projectId },
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [applyTemplate] = useApplyTemplateMutation();
  const { track } = useAnalytics();

  const onSubmit = async (values: FormFields) => {
    const { applyToSubfolders, selectedTemplate } = values;
    try {
      await applyTemplate({
        update: () => {
          refetch();
        },
        variables: {
          applyToSubfolders,
          folderId: folder.id,
          templateId: selectedTemplate?.id || '',
        },
      });
      mutateFolderProp({
        folderId: folder.id,
        key: 'metadataSetId',
        value: selectedTemplate?.id ?? '',
      });

      mutateFolderProp({
        folderId: folder.id,
        key: 'inheritParentMetadata',
        value: applyToSubfolders,
      });

      if (applyToSubfolders) updateChildren(folder, selectedTemplate?.id ?? '');

      track(TrackingEvent.MetadataTemplateAssigned);
      if (selectedTemplate) {
        enqueueSnackbar(
          <ItemChangedMessage
            description={MessageAction.Applied}
            entityName={selectedTemplate.label}
            entityTypeTranslation={EntityTypeTranslation.Template}
          />
        );
      } else {
        enqueueSnackbar(
          t('The meta data template has been cleared from this folder')
        );
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
    onClose();
  };

  const updateChildren = (folder: Folder, templateId: string) => {
    if (folder.hasSubFolders)
      folder.children.forEach((child: Folder) => {
        child.metadataSetId = templateId;
        mutateFolderProp({
          folderId: child.id,
          key: 'metadataSetId',
          value: templateId,
        });
        updateChildren(child, templateId);
      });
  };
  return onSubmit;
};
