import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RieCard } from './RieCard';
import { RieRiskLevelResultCount } from './useCountRieResultsByRiskLevel';
import {
  RESULT_BACKGROUND_COLORS_MAP,
  ResultTypesRieResult,
} from './useTBQRieResultsColumns';

interface RieCardData {
  bgColor?: string;
  details: string;
  questionCount?: number;
  textColor?: string;
  title: string;
}

interface RieCardListProp {
  resultCount?: RieRiskLevelResultCount | null;
}

export const RieCardsList = ({ resultCount }: RieCardListProp) => {
  const { t } = useTranslation();

  const cardsData: RieCardData[] = [
    {
      bgColor: RESULT_BACKGROUND_COLORS_MAP[ResultTypesRieResult.NoPriority],
      details: 'R ≤ 20',
      questionCount: resultCount?.noPriority,
      title: t('No priority'),
    },
    {
      bgColor:
        RESULT_BACKGROUND_COLORS_MAP[ResultTypesRieResult.AttentionRequired],
      details: '20 < R ≤ 70',
      questionCount: resultCount?.attentionRequired,
      title: t('Attention required'),
    },
    {
      bgColor:
        RESULT_BACKGROUND_COLORS_MAP[ResultTypesRieResult.MeasuresRequired],
      details: '70 < R < 200',
      questionCount: resultCount?.measuresRequired,
      title: t('Measures required'),
    },
    {
      bgColor:
        RESULT_BACKGROUND_COLORS_MAP[ResultTypesRieResult.ImprovementRequired],
      details: '200 < R ≤ 400',
      questionCount: resultCount?.improvementRequired,
      title: t('Improvement required'),
    },
    {
      bgColor: RESULT_BACKGROUND_COLORS_MAP[ResultTypesRieResult.WorkOnHold],
      details: 'R > 400',
      questionCount: resultCount?.workOnHold,
      textColor: '#FFFFFF',
      title: t('Work on hold'),
    },
    {
      bgColor: RESULT_BACKGROUND_COLORS_MAP[ResultTypesRieResult.NotAnswered],
      details: '-',
      questionCount: resultCount?.notAnswered,
      title: t('Not answered'),
    },
  ];

  return (
    <CardsContainer>
      {cardsData.map((card, index) => (
        <RieCard
          bgColor={card.bgColor || '#36CCBA'}
          details={card.details}
          key={index}
          questionCount={card.questionCount}
          textColor={card.textColor}
          title={card.title}
        />
      ))}
    </CardsContainer>
  );
};

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
