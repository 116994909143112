import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';

import { ConditionSettingsSidebar } from './ConditionSettingsSidebar';
import {
  useConditionsSidebarContext,
  useFieldContext,
} from './TemplateMutationContext';

interface ConditionProps {
  sectionId: string;
}

export const ConditionButton = ({ sectionId }: ConditionProps) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { fieldDefinitions } = useFieldContext();
  const { conditions } = useConditionsSidebarContext();

  const [conditionCount, setConditionCount] = useState(0);

  useEffect(() => {
    const newSectionCondition = conditions.filter(
      (condition) => condition.fromSectionId === sectionId
    );
    setConditionCount(newSectionCondition.length);
  }, [conditions, fieldDefinitions, sectionId]);

  const handleCloseSidebar = () => {
    setSidebarVisible(false);
  };

  return (
    <>
      <StyledButton
        id="demo-customized-button"
        onClick={() => setSidebarVisible(true)}
        startIcon="sectionCondition"
      >
        {conditionCount}
      </StyledButton>
      {sidebarVisible && (
        <ConditionSettingsSidebar
          onClose={handleCloseSidebar}
          open={sidebarVisible}
          sectionId={sectionId}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  && {
    border-radius: 5px;
    padding: 0 5px;
    min-width: 50px;
    color: ${customColors.grey700};
    border: 1px solid ${customColors.grey700};
  }
`;
