import React, { useCallback, useContext, useState } from 'react';

import { Document } from '@pro4all/graphql';
export interface DocumentToCopy extends Document {
  organizationId: string;
}

type ClipboardContextValue = {
  documentsToCopy: DocumentToCopy[];
  documentsToMove: DocumentToCopy[];
  setDocumentsToCopy: (docIds: DocumentToCopy[]) => void;
  setDocumentsToMove: (docIds: DocumentToCopy[]) => void;
};

const ClipboardContext = React.createContext<ClipboardContextValue>({
  documentsToCopy: [],
  documentsToMove: [],
  setDocumentsToCopy: () => [],
  setDocumentsToMove: () => [],
});

export function useClipboardContext() {
  return useContext(ClipboardContext);
}

export const ClipboardContextProvider: React.FC = ({ children }) => {
  const [documentsToCopy, setDocumentsToCopy] = useState<DocumentToCopy[]>([]);
  const [documentsToMove, setDocumentsToMove] = useState<DocumentToCopy[]>([]);

  const setDocumentsToCopyAction = useCallback(
    (documents: DocumentToCopy[]) => {
      setDocumentsToMove([]);
      setDocumentsToCopy(documents);
    },
    []
  );

  const setDocumentsToMoveAction = useCallback(
    (documents: DocumentToCopy[]) => {
      setDocumentsToCopy([]);
      setDocumentsToMove(documents);
    },
    []
  );

  return (
    <ClipboardContext.Provider
      value={{
        documentsToCopy,
        documentsToMove,
        setDocumentsToCopy: setDocumentsToCopyAction,
        setDocumentsToMove: setDocumentsToMoveAction,
      }}
    >
      {children}
    </ClipboardContext.Provider>
  );
};
