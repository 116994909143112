import { ApolloError } from '@apollo/client';
import { Core } from '@pdftron/webviewer';

import { Annotation, DocumentVersion } from '@pro4all/graphql';

export type State = Record<string, Version>;

export enum Actions {
  INIT_VERSION = 'INIT_VERSION',
  SET_STATUS = 'SET_STATUS',
  SYNC_REJECTED = 'SYNC_REJECTED',
  SYNC_RESOLVED = 'SYNC_RESOLVED',
  TOGGLE_SHOW_ANNOTATIONS = 'TOGGLE_SHOW_ANNOTATIONS',
}

export enum Status {
  INITIAL = 'INITIAL',
  PENDING_RENDER = 'PENDING_RENDER',
  SYNCED = 'SYNCED',
  SYNCING = 'SYNCING',
  SYNC_REJECTED = 'SYNC_REJECTED',
  SYNC_REQUIRED = 'SYNC_REQUIRED',
}

export type Version = {
  annotations?: Annotation[];
  documentVersion: DocumentVersion;
  downloadUrl?: string;
  error?: ApolloError;
  id: string;
  pages: Core.PDFNet.Page[];
  showAnnotations: boolean;
  status: Status;
};

export interface ActionTypes {
  [Actions.INIT_VERSION]: {
    payload: {
      syncNeeded: boolean;
      version: DocumentVersion;
    };
    type: Actions.INIT_VERSION;
  };
  [Actions.SYNC_RESOLVED]: {
    payload: {
      annotations: Annotation[];
      downloadUrl?: string;
      pages: Core.PDFNet.Page[];
      versionId: string;
    };
    type: Actions.SYNC_RESOLVED;
  };
  [Actions.SYNC_REJECTED]: {
    payload: {
      error: ApolloError;
      versionId: string;
    };
    type: Actions.SYNC_REJECTED;
  };
  [Actions.SET_STATUS]: {
    payload: {
      status: Status;
      versionId: string;
    };
    type: Actions.SET_STATUS;
  };
  [Actions.TOGGLE_SHOW_ANNOTATIONS]: {
    payload: {
      versionId: string;
    };
    type: Actions.TOGGLE_SHOW_ANNOTATIONS;
  };
}

export type Action =
  | ActionTypes[Actions.INIT_VERSION]
  | ActionTypes[Actions.SYNC_REJECTED]
  | ActionTypes[Actions.SYNC_RESOLVED]
  | ActionTypes[Actions.SET_STATUS]
  | ActionTypes[Actions.TOGGLE_SHOW_ANNOTATIONS];

export const versionsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.INIT_VERSION:
      return {
        ...state,
        [action.payload.version.id]: {
          documentVersion: action.payload.version,
          id: action.payload.version.id,
          pages: null,
          showAnnotations: action.payload.syncNeeded ? true : false,
          status: action.payload.syncNeeded
            ? Status.SYNC_REQUIRED
            : Status.INITIAL,
        },
      };
    case Actions.SYNC_REJECTED:
      return {
        ...state,
        [action.payload.versionId]: {
          ...state[action.payload.versionId],
          error: action.payload.error,
          status: Status.SYNC_REJECTED,
        },
      };
    case Actions.SYNC_RESOLVED:
      return {
        ...state,
        [action.payload.versionId]: {
          ...state[action.payload.versionId],
          ...action.payload,
          status: Status.PENDING_RENDER,
        },
      };
    case Actions.TOGGLE_SHOW_ANNOTATIONS:
      return {
        ...state,
        [action.payload.versionId]: {
          ...state[action.payload.versionId],
          showAnnotations: !state[action.payload.versionId].showAnnotations,
          status: state[action.payload.versionId].annotations
            ? Status.PENDING_RENDER
            : Status.SYNC_REQUIRED,
        },
      };
    case Actions.SET_STATUS:
      return {
        ...state,
        [action.payload.versionId]: {
          ...state[action.payload.versionId],
          status: action.payload.status,
        },
      };
    default:
      throw new Error(`No reducer found for action: ${action}`);
  }
};
