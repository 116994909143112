import { getOtherFolderPermissions } from '../helpers/getOtherFolderPermissions';
import { getThisFolderPermission } from '../helpers/getThisFolderPermission';
import { getUpdatedSavePermissions } from '../helpers/getUpdatedSavePermissions';
import { getUpdatedSubfoldersSinglePermission } from '../helpers/getUpdatedSubfoldersSinglePermission';
import { isParentEnabled } from '../helpers/isParentEnabled';
import { PermissionToggle, State } from '../types';

export const togglePermissionAction = ({
  payload,
  state,
}: {
  payload: PermissionToggle;
  state: State;
}) => {
  const { folderId, permission } = payload;
  const { displayPermissions, initialPermissionsExplicit, savePermissions } =
    state;

  const otherFolderPermissions = getOtherFolderPermissions(
    folderId,
    displayPermissions
  );
  const thisFolderPermission = getThisFolderPermission(
    folderId,
    displayPermissions
  );

  const { breakInheritance } = thisFolderPermission;

  const currentPermissions = thisFolderPermission.folderPermissions;
  const updatedFolderPermission = {
    ...thisFolderPermission,
    folderPermissions: currentPermissions.includes(permission)
      ? isParentEnabled({
          displayPermissions,
          parentFolderId: thisFolderPermission.parentFolderId,
          permission,
        }) && !breakInheritance
        ? currentPermissions
        : currentPermissions.filter((perm) => perm !== permission)
      : [...currentPermissions, permission],
  };

  const folderPermissionsWithInheritance = getUpdatedSubfoldersSinglePermission(
    {
      displayPermissions,
      folderId,
      initialPermissionsExplicit,
      otherFolderPermissions,
      parent: updatedFolderPermission,
      permission,
      savePermissions,
    }
  );

  const savePermissionsUpdated = getUpdatedSavePermissions(
    updatedFolderPermission,
    state
  );

  return {
    ...state,
    displayPermissions: folderPermissionsWithInheritance,
    savePermissions: savePermissionsUpdated,
  };
};
