import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useOrganizationContext } from '@pro4all/organization/context';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Alert, Box, useTheme } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import {
  Backdrop,
  FormFooter,
  FormikCheckbox,
  FormikForm,
  FormikSearchableMultiSelect,
  FormikTextarea,
  InfoBlock,
  Link,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import { isAnyOtherEmailDomain } from '@pro4all/shared/utils';

import {
  InvitedUser,
  InviteExternalsFormValues,
} from '../../user-management/components/types';
import { splitEmailAddress } from '../utils/splitEmailAddress';
import { useDelayedFormikUpdate } from '../utils/useDelayedFormikUpdate';
import { useSubmitUsers } from '../utils/useSubmitUsers';

import { useInviteUsersFormConfig } from './useInviteUsersFormConfig';

interface Props {
  onClose: () => void;
  refetch: () => void;
}

export const InviteUsersForm: React.FC<Props> = ({ onClose, refetch }) => {
  const { validationSchema } = useInviteUsersFormConfig();

  const [showBackdrop, setShowBackdrop] = useState(false);
  const onSubmit = useSubmitUsers({ onClose, refetch, setShowBackdrop });

  return (
    <Formik
      initialValues={{
        admins: [],
        doNotSendEmail: false,
        members: [],
        message: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <InviteUsersFormInputs onClose={onClose} showBackdrop={showBackdrop} />
      )}
    </Formik>
  );
};

export const InviteUsersFormInputs = ({
  onClose,
  showBackdrop,
}: {
  onClose: () => void;
  showBackdrop: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { email } = AuthService.getProfile();

  const { dirty, errors, isSubmitting, values } =
    useFormikContext<InvitedUser>();

  const [inputField, setInputField] = useState<string>('');

  const [currentValue, setNextValue] =
    useDelayedFormikUpdate<InviteExternalsFormValues>(inputField);

  const { userOrganizationName } = useOrganizationContext();

  const { getField } = useInviteUsersFormConfig();
  const adminsField = getField('admins');
  const membersField = getField('members');
  const messageField = getField('message');

  const adminEmails = values.admins.map((admin) => admin.inputValue);
  const memberEmails = values.members.map((member) => member.inputValue);

  const showDomainWarning = isAnyOtherEmailDomain({
    emailToCheck: email,
    emailsToCheck: [...adminEmails, ...memberEmails],
  });

  return (
    <>
      {showBackdrop && <Backdrop />}
      <FormikForm>
        <FormWrapper>
          <InfoBlock
            paragraph={t(
              'When inviting {{- organizationName}} colleagues, they will receive an email to register and log in to join your organization.',
              {
                organizationName: userOrganizationName,
              }
            )}
          />
          <FormikSearchableMultiSelect
            autoFocus
            canAddNewOptions
            label={adminsField.label}
            limitTags={5}
            name={adminsField.name}
            onFocus={() => setInputField(adminsField.name)}
            onInputChange={(_, value) =>
              splitEmailAddress(
                value,
                currentValue as unknown as Option[],
                setNextValue
              )
            }
            options={[]}
            placeholder={t('Enter email addresses')}
          />
          <FormikSearchableMultiSelect
            canAddNewOptions
            label={membersField.label}
            limitTags={5}
            name={membersField.name}
            onFocus={() => setInputField(membersField.name)}
            onInputChange={(_, value) =>
              splitEmailAddress(
                value,
                //  this was the only way TS could accept this parameter
                currentValue as unknown as Option[],
                setNextValue
              )
            }
            options={[]}
            placeholder={t('Enter email addresses')}
          />
          <FormikTextarea
            id={messageField.name}
            label={messageField.label}
            name={messageField.name}
            rows={4}
          />
          <FormikCheckbox
            label={t("Don't send invitation, I'll do this later.")}
            name="doNotSendEmail"
          />
          {showDomainWarning && (
            <Alert
              action={
                <Box
                  m={1}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    placeSelf: 'stretch',
                    width: 'max-content',
                  }}
                >
                  <Link
                    color="inherit"
                    href={t('INVITE_NEW_USER_LINK')}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('More info')}
                  </Link>
                </Box>
              }
              severity="warning"
              variant="filled"
            >
              {t(
                'You entered an e-mail address with a different extension than your own e-mail address. This seems to indicate that you want to add someone from another company. Within the {{- organizationName}} organization you add colleagues who are also part of {{- organizationName}}. You add users from other companies as external parties in your projects.',
                {
                  organizationName: userOrganizationName,
                }
              )}
            </Alert>
          )}
        </FormWrapper>
        <FormFooter
          disableSubmit={isSubmitDisabled({
            dirty,
            errors,
            isSubmitting,
          })}
          onClose={onClose}
          pb={3}
          pt={2}
          px={3}
          sticky
        />
      </FormikForm>
    </>
  );
};
