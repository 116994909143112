import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useAppPermissions } from '@pro4all/shared/identity';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const base = `${Routes.settingsOrganization}/`;

export const OrganizationOrganizationTabs: React.FC = () => {
  const { t } = useTranslation();
  const { hasAppPermission } = useAppPermissions();
  const canUpdateOrganizationSettings = hasAppPermission('OrganizationUpdate');
  const { userAuthType } = useOrganizationContext();

  const hasScim = useFeatureFlag('scim');
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense } = useSubscriptionRestriction(
    contextScopedOrganizationSubscriptionLevel
  );

  return canUpdateOrganizationSettings ? (
    <SubTabs base={base}>
      <Tab
        data-testid="organization-settings-organization-tab"
        label={t('Organization')}
        value="organization"
      />
      <Tab
        data-testid="organization-settings-license-tab"
        label={t('License')}
        value="license"
      />
      <Tab
        data-testid="organization-settings-integrations-tab"
        label={t('Integrations')}
        value="integrations"
      />
      {hasDmsLicense && (
        <Tab
          data-testid="organization-settings-documents-tab"
          label={t('Documents')}
          value="documents"
        />
      )}

      {userAuthType === AuthType.Sso && canUpdateOrganizationSettings && (
        <Tab
          data-testid="organization-settings-settings-tab"
          label={t('Security')}
          value="settings"
        />
      )}

      {hasScim && (
        <Tab
          data-testid="organization-settings-domains-tab"
          label={t('Domains')}
          value="domains"
        />
      )}

      {hasScim && (
        <Tab
          data-testid="organization-settings-provisioning-tab"
          label={t('Provisioning')}
          value="provisioning"
        />
      )}
    </SubTabs>
  ) : null;
};
