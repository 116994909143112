import { FieldDefinition } from '@pro4all/graphql';

export const useGetChildLevel = ({ items }: { items: FieldDefinition[] }) => {
  const childLevelCounter = (fields: FieldDefinition[], level = 0): number => {
    const childs = fields?.flatMap((x) => x.valueType?.subFields ?? []);
    if (childs.length) {
      return childLevelCounter(childs, level + 1);
    }
    return level;
  };
  const childLevel = items ? childLevelCounter(items, 0) : 0;

  return { childLevel };
};
