import { getOtherFolderPermissions } from '../helpers/getOtherFolderPermissions';
import { getUpdatedSubfoldersAllPermissions } from '../helpers/getUpdatedSubfoldersAllPermissions';
import { Permission, SetInitial, State } from '../types';

export const setInitialPermissionsAction = ({
  payload,
  state,
}: {
  payload: SetInitial;
  state: State;
}) => {
  const {
    displayPermissions,
    initialPermissionsDisplay,
    initialPermissionsExplicit,
    savePermissions,
  } = state;

  const { permissions } = payload;

  // Only append folderIds that are not included yet to both 'displayPermissions'.
  const currentlyIncludedFolderIds = displayPermissions.map(
    (folder) => folder.folderId
  );
  const additionalFolders = permissions.filter(
    (folder) => !currentlyIncludedFolderIds.includes(folder.folderId)
  );

  // Get all root folders to let possible subfolders inherit their values.
  const rootFolders = [...displayPermissions, ...additionalFolders].filter(
    (folder) => !folder.parentFolderId
  );

  const setSubFolderPermissions = (
    values: Permission[],
    item: Permission
  ): Permission[] => {
    const otherFoldersInitially = getOtherFolderPermissions(item.folderId, [
      ...displayPermissions,
      ...additionalFolders,
    ]);
    const updatedSubFoldersPermissions = getUpdatedSubfoldersAllPermissions({
      displayPermissions: [...initialPermissionsDisplay, ...additionalFolders],
      initialPermissionsExplicit: [
        ...initialPermissionsExplicit,
        ...additionalFolders,
      ],
      otherFolderPermissions: values.length
        ? values.filter((folder) => folder.folderId !== item.folderId)
        : otherFoldersInitially,
      parent: item,
      permissions: item.folderPermissions,
      savePermissions: state.savePermissions,
      toggleAll: false,
    });
    return [...updatedSubFoldersPermissions];
  };

  const displayPermissionsWithInheritance = rootFolders.reduce(
    setSubFolderPermissions,
    []
  );

  // Only data for additionalFolders should be added to the initialPermissionsDisplay array.
  const initialPermissionsAdditionalFolders =
    displayPermissionsWithInheritance.filter(
      (folder) => !currentlyIncludedFolderIds.includes(folder.folderId)
    );

  return {
    displayPermissions: displayPermissionsWithInheritance,
    initialPermissionsDisplay: [
      ...initialPermissionsDisplay,
      ...initialPermissionsAdditionalFolders,
    ],
    initialPermissionsExplicit: [
      ...additionalFolders,
      ...initialPermissionsExplicit,
    ],
    savePermissions,
  };
};
