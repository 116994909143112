import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Document, useRequestUnlockDocumentsMutation } from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

export const useRequestUnlock = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [requestUnlockDocuments] = useRequestUnlockDocumentsMutation();
  const { showSingleError } = useShowMessages();

  const requestUnlock = async ({ document }: { document: Document }) => {
    try {
      // Trigger unlock request for this locked document.
      await requestUnlockDocuments({
        variables: {
          documentIds: [document.id], // For now only on a per document base.
        },
      });

      enqueueSnackbar(
        t('The unlock request has been sent to {{receiver}}', {
          receiver: document?.lockedBy?.displayName || '',
        })
      );

      return true;
    } catch (e: any) {
      showSingleError(e);
      return false;
    }
  };

  return requestUnlock;
};
