import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

interface ISelectable {
  selected: boolean;
}

export const CollectionContainer = styled.div<ISelectable>`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.light : ''};
  cursor: pointer;
  display: flex;
  margin-top: 16px;
  margin-bottom: 4px;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  &:hover {
    background-color: ${({ selected }) =>
      selected ? '' : customColors.grey200};
  }
`;

export const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: auto;
`;

export const NameText = styled(Text)<ISelectable>`
  && {
    color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.dark : ''};
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
