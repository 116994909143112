import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  Project,
  useMyOrganizationProjectsQuery,
  useMyProjectsQuery,
} from '@pro4all/graphql';
import { ApiConfig, Routes } from '@pro4all/shared/config';
import { Box, Divider } from '@pro4all/shared/mui-wrappers';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Column,
  Main,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoProjects } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { useProjectActions } from './actions/useProjectActions';
import { ConfirmProjectDeleteDialog } from './ConfirmDeleteProjectDialog';
import ProjectListMobile from './ProjectListMobile';
import { ProjectsActionBar } from './ProjectsActionBar';
import { ProjectWithMessage } from './types';
import { useAddMessagesLabelForFiltering } from './useAddMessagesLabelForFiltering';
import { useColumns } from './useColumns';
import { useOnRowClick } from './useOnRowClick';

export enum ProjectsTablePostFix {
  ALL = 'all',
  OWN = 'own',
}

export const ProjectsTable = ({
  postfix,
}: {
  postfix: ProjectsTablePostFix;
}) => {
  const isMobileScreen = useIsMobileScreen();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<ProjectWithMessage>();

  const matchMyProjects = useRouteMatch({
    exact: true,
    path: Routes.myProjects,
  });

  const myProjects = useMyProjectsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !matchMyProjects,
  });

  const orgProjects = useMyOrganizationProjectsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: Boolean(matchMyProjects),
  });

  const { data, loading, error } = matchMyProjects ? myProjects : orgProjects;

  const { messagesData, messagesLoading, projectState } =
    useAddMessagesLabelForFiltering({ projects: data?.projects || [] });

  useSetItemsInLocalState<ProjectWithMessage>(projectState);

  const columns = useColumns({
    displayMessages: Boolean(matchMyProjects),
    messagesData,
    messagesLoading,
  });

  const { altActions: contextMenuActions } = useProjectActions();

  const { onRowClick } = useOnRowClick();

  return (
    <Main sx={{ overflow: 'hidden' }}>
      <TableContextProvider
        checkable={false}
        columns={columns}
        id={`table-projects-${postfix}`}
        items={items}
      >
        <FilterContextProvider<Project>>
          <Column>
            <ProjectsActionBar />
            <Divider />
            <ResponseWrapper error={error} isLoading={!data && loading}>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  height: '100%;',
                  overflowY: isMobileScreen ? 'auto' : 'inherit',
                }}
              >
                {items.length === 0 && itemsInitial.length === 0 ? (
                  <BigMessageNoProjects />
                ) : isMobileScreen ? (
                  <ProjectListMobile
                    itemClick={onRowClick}
                    projects={items}
                    showMessageBadge={Boolean(matchMyProjects)}
                  ></ProjectListMobile>
                ) : (
                  <Box flex="auto">
                    <Table
                      contextMenuActions={contextMenuActions}
                      onRowClick={onRowClick}
                    />
                  </Box>
                )}
              </Box>
            </ResponseWrapper>
          </Column>
        </FilterContextProvider>
        <ConfirmProjectDeleteDialog />
      </TableContextProvider>
    </Main>
  );
};
