import React from 'react';

import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { usePredefinedFilters } from '@pro4all/shared/ui/tasks';

export const TasksActionBar: React.FC<{
  dataViewToggle?: boolean;
  search?: boolean;
}> = ({ dataViewToggle, search = true }) => {
  const predefinedFilters = usePredefinedFilters();

  return (
    <TableActionBar
      dataTestid="doc-tasks-action-bar"
      dataViewToggle={dataViewToggle}
      predefinedFilters={predefinedFilters}
      search={search}
    />
  );
};
