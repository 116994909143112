import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ObjectProjectStatus } from '@pro4all/graphql';
import { TableNode } from '@pro4all/objects/utils';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import * as Styled from './Row.styles';

export const Row: React.FC<TableNode> = ({
  name,
  project,
  projectStatus,
  tbqType,
  tbqTypeAbstract,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const hasProject = projectStatus === ObjectProjectStatus.Linked;
  const hasTBQ = Boolean(tbqType);

  return (
    <Styled.Row>
      <Styled.Text title={name}>{name}</Styled.Text>
      {hasProject && (
        <Styled.Tooltip
          title={t<string>('This object is linked to {{- projectName}}', {
            projectName: project?.name,
          })}
        >
          <Styled.LiteIcon
            color={theme.palette.primary.main}
            iconName="objectLinkActive"
          />
        </Styled.Tooltip>
      )}

      {hasTBQ && (
        <Styled.Tooltip
          title={
            <Trans
              i18nKey="TBQ.IsObject"
              values={{ type: `$t(TBQ.${tbqTypeAbstract})` }}
            >
              This object is a TBQ <strong>BuildingType</strong>.
            </Trans>
          }
        >
          <Styled.LiteIcon color={customColors.grey600} iconName="tbq" />
        </Styled.Tooltip>
      )}
    </Styled.Row>
  );
};
