import React from 'react';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import {
  FieldDefinition,
  TbqModuleType,
  ValueTypeName,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import * as Edit from './edit';

export interface AnswerEditProps {
  error?: boolean;
  helperText?: string;
  item: FieldDefinition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any; // Todo type this correctly
}

export const AnswerEdit: React.FC<AnswerEditProps> = ({
  item,
  value,
  ...rest
}) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const { searchParams } = useRouting();
  const isTbqRie =
    searchParams.get('tbqScanType')?.toUpperCase() === TbqModuleType.Rie;

  const debounceSetValue = useDebouncedCallback((name, value) => {
    setValue(name, value);
  }, 300);

  // Adjust setValue to handle JSON serialization conditionally
  const setValue = (name: string, value: unknown) => {
    setFieldTouched(name);

    // Determine if serialization is necessary based on the item type and/or the specific component
    if (item.type === ValueTypeName.TimeSpan) {
      let normalizedValue = value;
      if (typeof value === 'string') {
        normalizedValue = JSON.parse(value);
      }

      const valueAsString = JSON.stringify(normalizedValue);
      setFieldValue(name, valueAsString);
    } else {
      setFieldValue(name, value);
    }
  };

  const Component = React.useMemo(() => {
    if (isTbqRie && item.type === ValueTypeName.Text) {
      return Edit.TextArea;
    } else if (
      item.type === ValueTypeName.UserSelection ||
      item.type === ValueTypeName.Status
    ) {
      return Edit.Selection;
    } else {
      return Edit[item.type as keyof typeof Edit] ?? Edit.TextField;
    }
  }, []);

  return (
    <Component
      item={item}
      name={item.id}
      setValue={debounceSetValue}
      value={value}
      {...rest}
    />
  );
};
