import { ReportMiniMapsProps } from '@pro4all/shared/types';

import { ReportMiniMap } from '../ReportMiniMap';

export const ReportMiniMaps = ({ instances }: ReportMiniMapsProps) => (
  <>
    {instances.map((instance, key) => (
      <div id={`temp-map-${instance.id}`} key={key}>
        <ReportMiniMap
          {...instance}
          id={instance.id}
          indicativeState={instance.indicativeState}
          key={key}
        />
      </div>
    ))}
  </>
);
