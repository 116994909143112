import React from 'react';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { ProjectSettingsForm } from './ProjectSettingsForm';

export const ProjectSettingsFormEdit: React.FC = () => (
  <ResponseWrapper>
    <StyledBox height="100%" maxWidth="400px">
      <ProjectSettingsForm showCancel={false} />
    </StyledBox>
  </ResponseWrapper>
);

export const StyledBox = styled(Box)`
  && {
    overflow: auto;
  }
`;
