import React, { useEffect, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { Messages } from '@pro4all/communication/ui/messages';
import { SharedDocsContextProvider } from '@pro4all/documents/ui/share';
import { Documents, SharedDocuments } from '@pro4all/documents/ui/views';
import {
  Connection,
  IntegrationWithConnection,
  OrganizationSubscriptionLevel,
  Project,
  Task,
  Template,
  useConnectionsQuery,
  useProjectQuery,
} from '@pro4all/graphql';
import {
  ProjectDocuments,
  ProjectHierarchicalLists,
  ProjectQualityControl,
  TemplatesTasks,
} from '@pro4all/metadata/ui';
import { Objects } from '@pro4all/objects/ui';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  ProjectAccessType,
  useProjectContext,
} from '@pro4all/projects/ui/context';
import { ProjectNavigation } from '@pro4all/projects/ui/navigation';
import { ProjectSettings } from '@pro4all/projects/ui/settings';
import { QualityControlResults } from '@pro4all/quality-control/ui/views';
import { ProjectAccess, ProjectMembers } from '@pro4all/settings/ui';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useScreenTooSmallForPage } from '@pro4all/shared/hooks/mobile-hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import {
  ContentLayout,
  Loader,
  MainLayout,
  OptimisticResponseProvider,
  TopBar,
} from '@pro4all/shared/ui/general';
import {
  BigMessageNoAccess,
  BigMessageNoSubscriptionAccess,
} from '@pro4all/shared/ui/messages';
import { Workflow } from '@pro4all/workflow/ui/workflow';

import { PageNoProjectAccess } from './PageNoProjectAccess';
import { PageNoProjectAccessLicenseExpired } from './PageNoProjectAccessLicenseExpired';
import { useBlockProjectRoutes } from './useBlockProjectRoutes';

export const Projects: React.FC = () => {
  const { blockRoutes, isLoadingBlockProjectRoutesInfo } =
    useBlockProjectRoutes();
  const BigMessageScreenTooSmall = useScreenTooSmallForPage();
  const { meData, userFirstName, userLastName } = useOrganizationContext();
  const {
    params: { projectId },
  } = useRouting();

  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');

  const { data } = useProjectQuery({
    // Do NOT include a pollInterval here because that will re-render every project navigation item
    // and will cause issues f.i. in the project members table when adding/deleting members.
    skip: !projectId,
    variables: { projectId: projectId || '' },
  });

  const { data: integrationData } = useConnectionsQuery({
    fetchPolicy: 'no-cache',
    variables: { projectId: projectId || '' },
  });

  const connectionsData = useMemo(
    () => integrationData?.connections || [],
    [integrationData?.connections]
  ) as IntegrationWithConnection[];

  const {
    isExternalUserInProject,
    isExplicitProjectAdmin,
    isExplicitProjectUser,
    setConnections,
    setMainProcedureId,
    setProjectAccessLoadStatus,
    setProjectData,
    setSettings,
  } = useProjectContext();

  const projectData = useMemo(
    () => (data ? (data?.project as Project) : undefined),
    [data]
  );
  useEffect(() => {
    // Set project data initially in the ProjectContext.
    setProjectData(projectData as Project);
  }, [projectData, projectId, setProjectData]);

  const { userIsOrganizationAdmin } = useOrganizationContext();

  useEffect(() => {
    if (projectData && projectData) {
      if (projectData.settings) {
        setSettings(projectData.settings);
      }
      if (projectData.mainProcedure) {
        setMainProcedureId(projectData.mainProcedure.id);
      }
    }
  }, [projectData, setMainProcedureId, setSettings]);

  useEffect(() => {
    if (connectionsData && connectionsData.length > 0) {
      const connections = connectionsData
        .filter(
          (iwc) => iwc.integrationConnection && iwc.connectionStatus === true
        )
        .map((iwc) => iwc.integrationConnection as Connection);
      setConnections(connections);
    }
  }, [connectionsData, setConnections]);

  const { organizationSubscriptionLevel: subscriptionLevel } =
    projectData?.organization || {};
  const organizationSubscriptionLevel =
    subscriptionLevel || OrganizationSubscriptionLevel.Inactive;

  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(organizationSubscriptionLevel);

  // Calculate what the user access to the project is.
  let projectAccess = ProjectAccessType.NoAccess; // By default no access.
  if (isExternalUserInProject) {
    // External user:
    // 1. Organization permissions not relevant because this user is from another organization.
    if (isExplicitProjectAdmin) {
      // 2. If not an organization admin but project admin then ProjectUpdate.
      projectAccess = ProjectAccessType.ProjectUpdate;
    } else if (isExplicitProjectUser) {
      // 3. If not an organization admin but project user then ProjectRead.
      projectAccess = ProjectAccessType.ProjectRead;
    }
  } else {
    // Internal user:
    if (userIsOrganizationAdmin) {
      // 1. If organization admin then ProjectUpdate.
      projectAccess = ProjectAccessType.ProjectUpdate;
    } else if (isExplicitProjectAdmin) {
      // 2. If not an organization admin but project admin then ProjectUpdate.
      projectAccess = ProjectAccessType.ProjectUpdate;
    } else if (isExplicitProjectUser) {
      // 3. If not an organization admin but project user then ProjectRead.
      projectAccess = ProjectAccessType.ProjectRead;
    }
  }

  useEffect(() => {
    setProjectAccessLoadStatus({
      projectId: projectId || '',
      status: projectAccess,
    });
  }, [projectAccess, projectId, setProjectAccessLoadStatus]);

  if (!meData || isLoadingBlockProjectRoutesInfo) return <Loader />;

  if (blockRoutes) return <PageNoProjectAccessLicenseExpired />;

  if (projectAccess === ProjectAccessType.NoAccess)
    return <PageNoProjectAccess />;

  return (
    <Box display="flex">
      {projectId && (
        <PrivateRoute exact path={Routes.project}>
          <Redirect
            to={generateRoute('projectWorkflows', { params: { projectId } })}
          />
        </PrivateRoute>
      )}
      <ProjectNavigation projectPermission={projectAccess} />
      <MainLayout>
        <ContentLayout>
          <TopBar
            firstName={userFirstName || ''}
            lastName={userLastName || ''}
            projectName={projectData?.name}
          />
          {BigMessageScreenTooSmall || (
            <>
              <PrivateRoute path={Routes.projectWorkflows}>
                <OptimisticResponseProvider<Task>>
                  <Workflow />
                </OptimisticResponseProvider>
              </PrivateRoute>

              <PrivateRoute
                path={[
                  Routes.projectDocs,
                  Routes.projectSmartFolder,
                  Routes.projectSmartFolders,
                  Routes.projectDocsTasks,
                  Routes.projectDmsBase,
                  Routes.projectDocsNotifications,
                ]}
              >
                {hasDmsLicense ? (
                  <Documents />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )}
              </PrivateRoute>

              <PrivateRoute path={Routes.projectQualityControlMain}>
                {hasQualityControlLicense && hasQualityControlFeature ? (
                  <QualityControlResults />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )}
              </PrivateRoute>

              <PrivateRoute path={Routes.projectMetaDataDocuments}>
                {hasDmsLicense ? (
                  <OptimisticResponseProvider<Template>>
                    <ProjectDocuments />
                  </OptimisticResponseProvider>
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )}
              </PrivateRoute>

              <PrivateRoute path={Routes.projectMetaDataQualityControl}>
                {hasQualityControlLicense && hasQualityControlFeature ? (
                  <OptimisticResponseProvider<Template>>
                    <ProjectQualityControl />
                  </OptimisticResponseProvider>
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )}
              </PrivateRoute>

              <PrivateRoute path={Routes.projectMetaDataHierarchicalLists}>
                {hasQualityControlLicense && hasQualityControlFeature ? (
                  <ProjectHierarchicalLists />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )}
              </PrivateRoute>

              <PrivateRoute path={Routes.projectUsersGroups}>
                <ProjectMembers />
              </PrivateRoute>
              <PrivateRoute path={Routes.projectTemplatesTasks}>
                {((hasQualityControlLicense && hasQualityControlFeature) ||
                  hasDmsLicense) &&
                projectAccess === ProjectAccessType.ProjectUpdate ? (
                  <TemplatesTasks />
                ) : projectAccess === ProjectAccessType.ProjectUpdate ? (
                  <BigMessageNoSubscriptionAccess />
                ) : (
                  <BigMessageNoAccess />
                )}
              </PrivateRoute>

              <OptimisticResponseProvider<Template>>
                <Switch>
                  <PrivateRoute
                    path={[
                      Routes.projectAllMessages,
                      Routes.projectInboxMessages,
                      Routes.projectSentMessages,
                      Routes.projectDraftMessages,
                    ]}
                  >
                    <Messages />
                  </PrivateRoute>
                  <PrivateRoute path={Routes.projectMessages}>
                    <Messages />
                  </PrivateRoute>
                </Switch>
              </OptimisticResponseProvider>
              <PrivateRoute path={[Routes.projectObjects, Routes.objects]}>
                <Objects />
              </PrivateRoute>

              <PrivateRoute path={Routes.projectAccess}>
                {hasDmsLicense ||
                (hasQualityControlLicense && hasQualityControlFeature) ? (
                  <ProjectAccess />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )}
              </PrivateRoute>

              <PrivateRoute path={Routes.projectSettings}>
                <ProjectSettings />
              </PrivateRoute>

              <PrivateRoute
                path={[
                  Routes.projDocsSharedByInvite,
                  Routes.projDocsSharedInScope,
                ]}
              >
                <SharedDocsContextProvider>
                  <SharedDocuments />
                </SharedDocsContextProvider>
              </PrivateRoute>
            </>
          )}
        </ContentLayout>
      </MainLayout>
    </Box>
  );
};

export default Projects;
