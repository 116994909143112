export const allowedFormats = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.webp',
  '.tiff',
  '.bmp',
];

export const checkOverSizedFiles = (files: File[], cb: () => void) => {
  // check if any image is too big
  const maxSize = 10 * 1024 * 1024; // 10MB
  const oversizedFiles = files.filter((file) => file.size > maxSize);

  if (oversizedFiles.length > 0) {
    cb();
  }

  return oversizedFiles;
};

export const checkInvalidFiles = (files: File[], cb: () => void) => {
  const invalidFiles = files.filter(
    (file) =>
      !allowedFormats.some((format) => file.name.toLowerCase().endsWith(format))
  );

  if (invalidFiles.length > 0) {
    cb();
  }

  return invalidFiles;
};
