import { LogEvent, LogEventLevel, Sink } from 'serilogger';

// Create a reversed map of the LogEventLevel enum.
const LogEventLevelName = {} as { [key: number]: string };
Object.keys(LogEventLevel).forEach((key) => {
  const value = LogEventLevel[key as keyof typeof LogEventLevel];
  LogEventLevelName[value] = key;
});

function isEnabled(level: LogEventLevel, target: LogEventLevel): boolean {
  return typeof level === 'undefined' || (level & target) === target;
}

function toString(level: LogEventLevel): string {
  return LogEventLevelName[level];
}

type Output = {
  error?: Error;
  level: string;
  messageTemplate: string;
  properties: any;
  renderedMessage: string;
  timestamp: string;
};

export interface StructuredConsoleProxy {
  debug(message?: any, ...properties: any[]): void;
  error(message?: any, ...properties: any[]): void;
  info(message?: any, ...properties: any[]): void;
  log(message?: any, ...properties: any[]): void;
  warn(message?: any, ...properties: any[]): void;
}

export interface StructuredConsoleSinkOptions {
  console?: any;
  restrictedToMinimumLevel?: LogEventLevel;
}

export class StructuredConsoleSink implements Sink {
  private options: StructuredConsoleSinkOptions;
  private console: StructuredConsoleProxy;

  constructor(options?: StructuredConsoleSinkOptions) {
    this.options = options || {};
    const internalConsole =
      this.options.console ||
      (typeof console !== 'undefined' && console) ||
      null;

    const stub = function () {
      // Placeholder function for future use
    };

    // console.debug is no-op for Node, so use console.log instead.
    const nodeConsole =
      !this.options.console &&
      typeof process !== 'undefined' &&
      process.versions &&
      process.versions.node;

    this.console = {
      debug:
        (internalConsole &&
          ((!nodeConsole && internalConsole.debug) || internalConsole.log)) ||
        stub,
      error:
        (internalConsole && (internalConsole.error || internalConsole.log)) ||
        stub,
      info:
        (internalConsole && (internalConsole.info || internalConsole.log)) ||
        stub,
      log: (internalConsole && internalConsole.log) || stub,
      warn:
        (internalConsole && (internalConsole.warn || internalConsole.log)) ||
        stub,
    };
  }

  public emit(events: LogEvent[]) {
    for (let i = 0; i < events.length; ++i) {
      const e = events[i];
      if (!isEnabled(this.options.restrictedToMinimumLevel, e.level)) continue;

      switch (e.level) {
        case LogEventLevel.fatal:
          this.writeToConsole(this.console.error, 'Fatal', e);
          break;

        case LogEventLevel.error:
          this.writeToConsole(this.console.error, 'Error', e);
          break;

        case LogEventLevel.warning:
          this.writeToConsole(this.console.warn, 'Warning', e);
          break;

        case LogEventLevel.information:
          this.writeToConsole(this.console.info, 'Information', e);
          break;

        case LogEventLevel.debug:
          this.writeToConsole(this.console.debug, 'Debug', e);
          break;

        case LogEventLevel.verbose:
          this.writeToConsole(this.console.debug, 'Verbose', e);
          break;

        default:
          this.writeToConsole(this.console.log, 'Log', e);
          break;
      }
    }
  }

  public flush() {
    return Promise.resolve();
  }

  private writeToConsole(
    logMethod: (logData: string) => void,
    prefix: string,
    e: LogEvent
  ) {
    const output: Output = {
      error: e.error,
      level: toString(e.level),
      messageTemplate: e.messageTemplate.raw,
      properties: e.properties,
      renderedMessage: e.messageTemplate.render(e.properties),
      timestamp: e.timestamp,
    };
    logMethod(JSON.stringify(output));
  }
}
