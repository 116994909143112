import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoDocumentsInFolder: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="flow1"
      text={t('Upload new documents or create an expected document.')}
      title={t("There aren't any documents in this folder yet")}
    />
  );
};
