const baseDms = `/dms`;
const baseDocs = `${baseDms}/docs`;
const docs = `${baseDocs}/:path*`;
const smartFolderPath = 'smart-folders';
const smartFolders = `${baseDocs}/${smartFolderPath}`;
const smartFolder = `${smartFolders}/:name`;
const collectionFolder = `${baseDocs}/collection-folder`;
const projects = '/projects';
const config = '/config';
const office = '/office';

const myApiKeyManagement = '/my-api-key-management';
const myProfile = '/my-profile';
const mySignature = '/my-signature';
const mySupport = '/my-support';
const myOverview = '/my-overview';
const myProjects = `${myOverview}/my-projects`;
const myTasks = `${myOverview}/my-tasks`;
const openCdeMetadata = '/open-cde-metadata';

const templates = `${config}/templates`;
const metaDataDocuments = `${config}/metadata/documents`;
const metaDataHierarchicalLists = `${config}/metadata/hierarchicallists`;
const metaDataDocumentsFields = `${metaDataDocuments}/fields`;
const metaDataDocumentsTemplates = `${metaDataDocuments}/templates`;

const metaDataQualityControl = `${config}/metadata/qualitycontrol`;
const metaDataQualityControlForms = `${metaDataQualityControl}/forms`;
const metaDataQualityControlSnags = `${metaDataQualityControl}/snags`;
const metaDataQualityControlFields = `${metaDataQualityControl}/fields`;
const metaDataQualityControlReportConfigurations = `${metaDataQualityControl}/reportconfigurations`;
const metaDataQualityControlTemplates = `${metaDataQualityControl}/sets`;

const metaDataQualityControlTBQ = `${config}/metadata/qualitycontrol`;
const metaDataQualityControlTBQTemplates = `${metaDataQualityControlTBQ}/tbq`;
const metaDataQualityControlTBQTemplatesScope = `${metaDataQualityControlTBQTemplates}/:tbqScopeId`;

const orgTemplatesTasks = `${templates}/tasks`;

const access = `${config}/access`;
const baseAccessDocuments = `${access}/documents`;
const accessDocuments = `${baseAccessDocuments}`;

const settingsOrganization = `${config}/organization`;

const settingsOrganizationIntegrations = `${settingsOrganization}/integrations`;
const settingsOrganizationLicense = `${settingsOrganization}/license`;
const settingsOrganizationOrganization = `${settingsOrganization}/organization`;
const settingsOrganizationSettings = `${settingsOrganization}/settings`;
const settingsOrganizationDocuments = `${settingsOrganization}/documents`;
const settingsOrganizationProvisioning = `${settingsOrganization}/provisioning`;
const settingsOrganizationDomains = `${settingsOrganization}/domains`;

const settings = `${config}/settings`;
const baseSettingsProject = `${settings}/project`;

const usersGroups = `${config}/usersgroups`;
const members = `${usersGroups}/members`;
const groups = `${usersGroups}/groups`;

const organization = '/organization';
const confirmEmail = `${organization}/finalize/:token`;
const finalizeInvitation = '/user/finalize';

const project = `${projects}/:projectId`;

const projectWorkflows = `${project}/workflow`;

const projectDmsBase = `${project}${baseDms}`;
const projectDocsBase = `${project}${baseDocs}`;
const document = `${projectDocsBase}/:folderId`;

const projectDocs = `${project}${docs}`;
const projectDocsTasks = `${project}${baseDms}/document-tasks`;
const projectDocsNotifications = `${project}${baseDms}/notifications`;

const projectMetaDataDocuments = `${project}${metaDataDocuments}`;
const projectMetaDataHierarchicalLists = `${project}${metaDataHierarchicalLists}`;
const projectMetaDataDocumentsFields = `${projectMetaDataDocuments}/fields`;
const projectMetaDataDocumentsTemplates = `${projectMetaDataDocuments}/templates`;

const projectMetaDataQualityControl = `${project}${metaDataQualityControl}`;
const projectMetaDataQualityControlForms = `${projectMetaDataQualityControl}/forms`;
const projectMetaDataQualityControlSnags = `${projectMetaDataQualityControl}/snags`;
const projectMetaDataQualityControlFields = `${projectMetaDataQualityControl}/fields`;
const projectMetaDataQualityControlReportConfigurations = `${projectMetaDataQualityControl}/reportconfigurations`;
const projectMetaDataQualityControlTemplates = `${projectMetaDataQualityControl}/templates`;

const projectTemplatesTasks = `${project}${templates}/tasks`;

const projectQualityControlMain = `${project}/qc`;
const projectQualityControlResults = `${projectQualityControlMain}/results`;
const projectQualityControlDrawings = `${projectQualityControlMain}/drawings`;
const projectQualityControlDrawing = `${projectQualityControlDrawings}/:visualContextId`;
const projectQualityControlMySavedAnswers = `${projectQualityControlMain}/mysavedanswers`;
const projectQualityControlTasks = `${projectQualityControlMain}/tasks`;
const projectQualityControlNotifications = `${projectQualityControlMain}/notifications`;

const projectAccess = `${project}${access}`;
const projectAccessDocuments = `${projectAccess}/documents`;
const projectAccessQualityControl = `${projectAccess}/qualitycontrol`;
const projectAccessTasks = `${projectAccess}/tasks`;

const projectSettings = `${project}${settings}`;
const projectSettingsProject = `${projectSettings}/project`;
const projectSettingsConnections = `${projectSettings}/connections`;
const projectSettingsDocuments = `${projectSettings}/documents`;

const projectUsersGroups = `${project}${usersGroups}`;
const projectMembers = `${projectUsersGroups}/members`;
const projectGroups = `${projectUsersGroups}/groups`;
const projectCollectionFolder = `${project}${collectionFolder}`;

const sharedDocs = `/shared`;

const publiclySharedDocs = `${sharedDocs}/public/:linkId?`;
const revokePublicLink = `${publiclySharedDocs}/revoke`;

const docsSharedByInvite = `${sharedDocs}/private/:linkId`;
const projDocsSharedByInvite = `${project}${docsSharedByInvite}`;

const docsSharedInScope = `${sharedDocs}/scoped/:linkId`;
const projDocsSharedInScope = `${project}${docsSharedInScope}`;

const objects = `/objects/:objectId?`;
const objectsBase = '/objects';
const objectDocuments = `${objects}/documents`;
const objectDrawings = `${objects}/floor-plans`;
const objectResults = `${objects}/results`;
const objectTasks = `${objects}/tasks`;
const objectTBQ = `${objects}/tbq`;
const objectTBQOsidLog = `${objects}/osid-log`;
const objectTBQResults = `${objects}/tbq-results/:resultSet?`;
const objectTBQMultiBrandcheckResults = `${objects}/tbq-multi-results/:resultSet?`;
const objectTBQRieResults = `${objects}/tbq-rie/:taskId`;
const objectDrawing = `${objects}/floor-plans/:visualContextId`;
const objectTBQMultiRieResults = `${objects}/tbq-rie`;

const projectObjects = `${project}${objects}`;
const projectObjectDocuments = `${project}${objectDocuments}`;
const projectObjectDrawings = `${project}${objectDrawings}`;
const projectObjectResults = `${project}${objectResults}`;
const projectObjectTasks = `${project}${objectTasks}`;
const projectObjectTBQOsidLog = `${project}${objectTBQOsidLog}`;
const projectObjectTbq = `${project}${objectTBQ}`;
const projectObjectTBQResults = `${project}${objectTBQResults}`;
const projectObjectTBQMultiBrandcheckResults = `${project}${objectTBQMultiBrandcheckResults}`;
const projectObjectTBQRieResults = `${project}${objectTBQRieResults}`;
const projectObjectDrawing = `${project}${objectDrawing}`;
const projectObjectTBQMultiRieResults = `${project}${objectTBQMultiRieResults}`;

const messages = '/messages/:messageId?';
const projectMessages = `${project}${messages}`;
const projectAllMessages = `${project}/messages/all`;
const projectInboxMessages = `${project}/messages/inbox`;
const projectSentMessages = `${project}${messages}/sent`;
const projectDraftMessages = `${project}${messages}/draft`;
const publicAttachments = '/messages/shared/public/:linkId?';

const organizationMessages = '/messages';
const allMessages = `${organizationMessages}/all`;
const inboxMessages = `${organizationMessages}/inbox`;
const sentMessages = `${organizationMessages}/sent`;
const draftMessages = `${organizationMessages}/draft`;

const moveUser = `/moveuser/:token`;

const organizationNotifications = `${baseDms}/notifications`;

export const Routes = {
  access,
  accessDocuments,
  allMessages,
  base: '/',
  baseAccessDocuments,
  baseDms,
  baseDocs,
  baseSettingsProject,
  collectionFolder,
  config,
  confirmEmail,
  docs,
  docsSharedByInvite,
  docsSharedInScope,
  document,
  draftMessages,
  finalizeInvitation,
  groups,
  inboxMessages,
  logout: '/logout',
  members,
  messages,
  metaDataDocuments,
  metaDataDocumentsFields,
  metaDataDocumentsTemplates,
  metaDataHierarchicalLists,
  metaDataQualityControl,
  metaDataQualityControlFields,
  metaDataQualityControlForms,
  metaDataQualityControlReportConfigurations,
  metaDataQualityControlSnags,
  metaDataQualityControlTBQTemplates,
  metaDataQualityControlTBQTemplatesScope,
  metaDataQualityControlTemplates,
  moveUser,
  myApiKeyManagement,
  myOverview,
  myProfile,
  myProjects,
  mySignature,
  mySupport,
  myTasks,
  objectDocuments,
  objectDrawing,
  objectDrawings,
  objectResults,
  objectTBQ,
  objectTBQMultiBrandcheckResults,
  objectTBQMultiRieResults,
  objectTBQOsidLog,
  objectTBQResults,
  objectTBQRieResults,
  objectTasks,
  objects,
  objectsBase,
  office,
  openCdeMetadata,
  orgTemplatesTasks,
  organization,
  organizationMessages,
  organizationNotifications,
  projDocsSharedByInvite,
  projDocsSharedInScope,
  project,
  projectAccess,
  projectAccessDocuments,
  projectAccessQualityControl,
  projectAccessTasks,
  projectAllMessages,
  projectCollectionFolder,
  projectDmsBase,
  projectDocs,
  projectDocsBase,
  projectDocsNotifications,
  projectDocsTasks,
  projectDraftMessages,
  projectGroups,
  projectInboxMessages,
  projectMembers,
  projectMessages,
  projectMetaDataDocuments,
  projectMetaDataDocumentsFields,
  projectMetaDataDocumentsTemplates,
  projectMetaDataHierarchicalLists,
  projectMetaDataQualityControl,
  projectMetaDataQualityControlFields,
  projectMetaDataQualityControlForms,
  projectMetaDataQualityControlReportConfigurations,
  projectMetaDataQualityControlSnags,
  projectMetaDataQualityControlTemplates,
  projectObjectDocuments,
  projectObjectDrawing,
  projectObjectDrawings,
  projectObjectResults,
  projectObjectTBQMultiBrandcheckResults,
  projectObjectTBQMultiRieResults,
  projectObjectTBQOsidLog,
  projectObjectTBQResults,
  projectObjectTBQRieResults,
  projectObjectTasks,
  projectObjectTbq,
  projectObjects,
  projectQualityControlDrawing,
  projectQualityControlDrawings,
  projectQualityControlMain,
  projectQualityControlMySavedAnswers,
  projectQualityControlNotifications,
  projectQualityControlResults,
  projectQualityControlTasks,
  projectSentMessages,
  projectSettings,
  projectSettingsConnections,
  projectSettingsDocuments,
  projectSettingsProject,
  projectSmartFolder: `${project}${smartFolder}`,
  projectSmartFolders: `${project}${smartFolders}`,
  projectTemplatesTasks,
  projectUsersGroups,
  projectWorkflows,
  projects,
  publicAttachments,
  publiclySharedDocs,
  registration: '/registration',
  revokePublicLink,
  sentMessages,
  settings,
  settingsOrganization,
  settingsOrganizationDocuments,
  settingsOrganizationDomains,
  settingsOrganizationIntegrations,
  settingsOrganizationLicense,
  settingsOrganizationOrganization,
  settingsOrganizationProvisioning,
  settingsOrganizationSettings,
  smartFolder,
  smartFolderPath,
  smartFolders,
  usersGroups,
  workflow: '/workflow',
};

export interface RouteParams {
  folderId?: string;
  globalId?: string;
  id?: string;
  linkId?: string;
  messageId?: string;
  name?: string;
  objectId?: string;
  path?: string;
  projectId?: string;
  resultSet?: string;
  taskId?: string;
  tbqScopeId?: string;
  token?: string;
  visualContextId?: string;
}
export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const forbiddenFolderNames = [smartFolderPath];
