import styled from 'styled-components';

export const ListOfDays = styled.ol`
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 0 4px 0;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: 1rem;
`;
