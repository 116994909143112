import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const RemoveCollectionBin: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M4.94629 19.5H18.9463C19.4963 19.5 19.9463 19.05 19.9463 18.5V8.5C19.9463 7.95 20.3963 7.5 20.9463 7.5C21.4963 7.5 21.9463 7.95 21.9463 8.5V19.5C21.9463 20.6 21.0463 21.5 19.9463 21.5H3.94629C2.84629 21.5 1.94629 20.6 1.94629 19.5V8.5C1.94629 8 2.44629 7.5 2.94629 7.5C3.44629 7.50009 3.94629 8 3.94629 8.5V18.5C3.94629 19.05 4.39629 19.5 4.94629 19.5Z" />
    <rect height="5" rx="1" width="5" x="5.94629" y="12.5" />
    <rect height="5" rx="1" width="5" x="5.94629" y="5.5" />
    <rect height="5" rx="1" width="5" x="12.9463" y="12.5" />
    <path
      d="M3.94629 0.5L23.7453 20.299L22.2721 21.7721L2.47315 1.97314L3.94629 0.5Z"
      fill="white"
    />
    <path d="M1.76604 2.68025C2.15656 2.28973 2.78973 2.28973 3.18025 2.68025L21.565 21.065C21.9556 21.4556 21.9556 22.0887 21.565 22.4792L21.5061 22.5382C21.1156 22.9287 20.4824 22.9287 20.0919 22.5382L1.70711 4.15339C1.31659 3.76287 1.31659 3.1297 1.70711 2.73918L1.76604 2.68025Z" />
  </SvgIcon>
);
