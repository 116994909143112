import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import {
  SearchableMultiSelect,
  SearchableMultiSelectProps,
} from '@pro4all/shared/ui/general';

import { UploaderEditorTypes } from '../types';

interface Props
  extends Omit<SearchableMultiSelectProps, 'label' | 'name' | 'value'>,
    Pick<
      UploaderEditorTypes,
      | 'errorString'
      | 'label'
      | 'name'
      | 'updateProvider'
      | 'value'
      | 'warningString'
    > {}

const EditorSelectionMultiFieldComponent = ({
  errorString,
  label,
  name,
  options,
  tabIndex,
  updateProvider,
  value,
  warningString,
}: Props) => {
  const { t } = useTranslation();

  const calculateCurrentOption = useCallback(
    (value) => options.find((option) => option.inputValue === value) as Option,
    [options]
  );

  const calculateCurrentOptions = useCallback(
    (value) => {
      if (!value) {
        return [];
      }
      const values = value.split(',') as string[];
      return values.map((value) => calculateCurrentOption(value));
    },
    [calculateCurrentOption]
  );

  const [currentValue, setCurrentValue] = useState(
    calculateCurrentOptions(value)
  );

  // In case value is changed in the provider, we have to set the updated value else it won't re-render.
  useEffect(() => {
    setCurrentValue(calculateCurrentOptions(value));
  }, [calculateCurrentOptions, value]);

  const handleChange: Props['onChange'] = (event, values) => {
    const value = values.length
      ? values.map((value) => value.inputValue as string).join(',')
      : '';
    updateProvider(value);
    setCurrentValue(values);
  };

  return (
    <Box sx={{ flexGrow: '1' }}>
      <SearchableMultiSelect
        error={errorString ? true : false}
        fullWidth
        isOptionEqualToValue={(option, value) => option.label === value.label}
        label={label}
        limitTags={2}
        name={name}
        onChange={handleChange}
        options={options}
        placeholder={t('Choose an option')}
        renderOption={(props, option: Option) => option.label}
        showValuesInTooltip
        tabIndex={tabIndex}
        tooltipTitle={errorString || warningString}
        value={currentValue}
        warning={warningString ? true : false}
      />
    </Box>
  );
};

const skipUpdate = (prevProps: Props, nextProps: Props) =>
  prevProps.value === nextProps.value &&
  prevProps.errorString === nextProps.errorString &&
  prevProps.tabIndex === nextProps.tabIndex &&
  prevProps.warningString === nextProps.warningString;

export const EditorSelectionMultiField = React.memo(
  EditorSelectionMultiFieldComponent,
  skipUpdate
);
