import { Trans } from 'react-i18next';
import { Formik } from 'formik';

import { Folder } from '@pro4all/graphql';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import {
  FormFooter,
  FormikCheckbox,
  FormikForm,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useDownloadZipFormConfig } from './useDownloadZipFormConfig';
import { useSubmit } from './useSubmit';

export const DownloadZipForm = ({
  folder,
  hasSubFolders,
  onClose,
}: {
  folder: Folder;
  hasSubFolders: boolean;
  onClose: () => void;
}) => {
  const { getField, validationSchema } = useDownloadZipFormConfig();
  const includeSubfoldersField = getField('includeSubfolders');
  const onSubmit = useSubmit({ folder, onClose });

  return (
    <Formik
      initialValues={{ includeSubfolders: false }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting }) => (
        <>
          <Trans defaults="Downloading a zip can take some time depending the number of documents and nested folders." />
          <FormikForm>
            {hasSubFolders && (
              <FormWrapper>
                <FormikCheckbox
                  label={includeSubfoldersField.label}
                  name={includeSubfoldersField.name}
                />
              </FormWrapper>
            )}
            <FormFooter
              disableSubmit={isSubmitDisabled({
                errors,
                isSubmitting,
              })}
              onClose={onClose}
              pt={2}
              sticky
              submitLabel="Download zip"
            />
          </FormikForm>
        </>
      )}
    </Formik>
  );
};
