import { use3DConversion } from '@pro4all/documents/ui/3d';

import { DocumentActionProps } from '../../types';

export const use3DAction = ({
  allDocumentsExpected,
  selected,
  versionIds,
}: Pick<
  DocumentActionProps,
  'allDocumentsExpected' | 'selected' | 'versionIds'
>) => {
  const { threeDAction, urns } = use3DConversion({
    selected,
    versionIds,
  });

  return allDocumentsExpected
    ? { threeDAction: null, urns: null }
    : { threeDAction, urns };
};
