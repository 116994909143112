import { Folder } from '@pro4all/graphql';

export type TreeFolder = DMSItem & {
  children?: TreeFolder[];
  isParentOfSelected: boolean;
  isSelected: boolean;
  onContextMenu?: (
    event: React.MouseEvent,
    folder: Folder,
    path: string[]
  ) => void;
  onProjectLinkContextMenu?: (
    event: React.MouseEvent,
    folder: DMSProjectLink,
    path: string[]
  ) => void;
};

export enum ActionType {
  CLEAR_FOLDER_TREE = 'CLEAR_FOLDER_TREE',
  CREATE_DMS_ITEM = 'CREATE_DMS_ITEM',
  DELETE_DMS_ITEM = 'DELETE_DMS_ITEM',
  MOVE_FOLDER = 'MOVE_FOLDER',
  MUTATE_FOLDER = 'MUTATE_FOLDER',
  RESTORE_FOLDER = 'RESTORE_FOLDER',
  SET_INITIAL_FOLDER_TREE = 'SET_INITIAL_FOLDER_TREE',
  SET_LOADING_FOLDER_DATA = 'SET_LOADING_FOLDER_DATA',
}

export type State = {
  folderTree: TreeFolder[];
  folders: DMSItem[];
  foldersPermissions: Folder[];
  foldersPrevious: DMSItem[];
  loadingFolderData: boolean;
};

export type MoveFolderPayload = {
  childFolderId: string;
  parentFolderId: string;
};

export type MutateFolderPropPayload = {
  folderId: string;
  key: string;
  value: string | boolean;
};

export type DMSProjectLink = {
  id: string;
  name: string;
  parentFolderId: string;
  path: string;
  projectId: string;
};

export function isFolder(item: TreeFolder | DMSItem) {
  return item['__typename'] === 'Folder';
}

export type DMSItem = Folder & DMSProjectLink;

export type SetInitialFolderTreePayload = {
  folders?: DMSItem[];
  foldersPermissions?: Folder[];
  path: string;
};

type ActionClearFolderTree = {
  type: ActionType.CLEAR_FOLDER_TREE;
};

type ActionCreateDMSItem = {
  payload: TreeFolder;
  type: ActionType.CREATE_DMS_ITEM;
};

type ActionDeleteDMSItem = {
  payload: string;
  type: ActionType.DELETE_DMS_ITEM;
};

type ActionMoveFolder = {
  payload: MoveFolderPayload;
  type: ActionType.MOVE_FOLDER;
};

type ActionMutatePropFolder = {
  payload: MutateFolderPropPayload;
  type: ActionType.MUTATE_FOLDER;
};

type ActionRestoreFolder = {
  type: ActionType.RESTORE_FOLDER;
};

type ActionSetInitialFolderTree = {
  payload: SetInitialFolderTreePayload;
  type: ActionType.SET_INITIAL_FOLDER_TREE;
};

type ActionLoadingFolderData = {
  payload: boolean;
  type: ActionType.SET_LOADING_FOLDER_DATA;
};

export type Action =
  | ActionClearFolderTree
  | ActionCreateDMSItem
  | ActionDeleteDMSItem
  | ActionMoveFolder
  | ActionMutatePropFolder
  | ActionRestoreFolder
  | ActionSetInitialFolderTree
  | ActionLoadingFolderData;
