import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Option } from '@pro4all/shared/types';
import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/general';

import { useGroupFormConfig } from './useGroupFormConfig';

type onChangeGroupMemberFunction = {
  onChange: (values: (string | Option)[]) => void;
};

export const MemberSelect = ({
  loading,
  options,
  onChange,
}: { options: Option[] } & {
  loading: boolean;
} & onChangeGroupMemberFunction) => {
  const { t } = useTranslation();

  const { getField } = useGroupFormConfig();
  const membersField = getField('members');

  return (
    <FormikSearchableMultiSelect
      label={membersField.label}
      limitTags={10}
      loading={loading}
      name={membersField.name}
      onChange={(event, value) => onChange(value)}
      options={options}
      placeholder={t('Add members')}
    />
  );
};
