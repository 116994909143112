import { v4 as uuid } from 'uuid';

import { BasePropsHierarchyItem, ItemProps } from '../types';

import { duplicateChildItems } from './duplicateChildItems';
import { getChildItems } from './getChildItems';
import { moveItemAfterPaste } from './moveItemAfterPaste';

export const copyItemTree = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  newParentNodeId,
  sourceItem,
}: Pick<
  ItemProps<HierarchyItem>,
  'allItems' | 'newParentNodeId' | 'sourceItem'
>) => {
  let updatedItems = allItems;

  // Copy of the item being pasted. The new item.
  const newId = uuid();
  const newItem = {
    ...sourceItem,
    id: newId,
    parentNodeId: newParentNodeId,
    previousNodeId: null,
  } as HierarchyItem;

  // This new item wil be inserted on the first position below the parent.
  // Move the item that is currently on the first position to the second position.
  updatedItems = moveItemAfterPaste<HierarchyItem>({
    allItems: updatedItems,
    newParentNodeId,
    previousNodeId: newItem.id,
  });

  // Find the child items of the item that is being inserted.
  const childItems = getChildItems<HierarchyItem>({
    allItems,
    id: sourceItem.id,
  });

  // Make an exact copy of these child items but with different IDs obviously.
  const childItemsCopy = duplicateChildItems<HierarchyItem>({
    childItemsToBeCopied: [...childItems],
    newParentNodeId: newId,
    parentNodeId: sourceItem.id,
  });

  return [...updatedItems, newItem, ...childItemsCopy];
};
