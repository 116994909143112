import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { User } from '@pro4all/graphql';
import { getFlatBranch, TableNode } from '@pro4all/objects/utils';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Button } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';
import { resolveNameDuplicates } from '@pro4all/shared/utils';

import { useEditObjectsContext } from './EditObjectsContext';

export function useEditObject({
  node,
  toggleRow,
}: {
  node: TableNode;
  toggleRow: (rowKey: string) => void;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(!node.name);
  const { getObject, objects, update } = useEditObjectsContext();
  const { meData } = useOrganizationContext();

  const object = getObject(node.id);

  const updateName = (name: string) => {
    name && update({ id: node.id, name });
  };

  const undo = () => {
    update({ deletedAt: null, id: node.id });
    enqueueSnackbar(
      <ItemChangedMessage
        description={MessageAction.Restore}
        entityName={node.name}
        entityTypeTranslation={EntityTypeTranslation.ObjectNode}
      />
    );
  };

  const deleteObject = () => {
    update({
      deletedAt: new Date().toUTCString(),
      id: node.id,
      projectId: null, // @Todo: prompt user: Project link will be deleted
    });
    enqueueSnackbar(
      <ItemChangedMessage
        action={
          <Button color="inherit" onClick={undo} size="small">
            {t('Undo')}
          </Button>
        }
        description={MessageAction.Delete}
        entityName={node.name}
        entityTypeTranslation={EntityTypeTranslation.ObjectNode}
      />
    );
  };

  const saveName = (value: string) => {
    setEditing(false);
    updateName(value);
  };

  const editObject = () => setEditing(true);

  const copyObject = () => {
    if (!object || !objects.length) return;

    const objectBranch = getFlatBranch({
      allNodes: objects,
      targetNode: object,
    });

    // First make sure all ids are represented in idRecord
    const idRecord: Record<string, string> = {}; // newIds indexed by oldIds
    objectBranch.forEach((originalObject) => {
      if (!originalObject?.id) return;
      idRecord[originalObject?.id] = uuid();
    });

    objectBranch.forEach((originalObject, key) => {
      if (!originalObject) return;

      const { name, parentNodeId, id } = originalObject;

      // Names that should be unique
      const names = objects
        .filter(
          (updatedNode) => updatedNode.parentNodeId === object.parentNodeId
        ) // Resolve name duplicates only for objects under the same parent
        .map((updatedNode) => updatedNode.name);

      const resolvedName = resolveNameDuplicates(name, names);

      if (!resolvedName) {
        enqueueSnackbar(t('Copy limit reached', { variant: 'error' }));
        return;
      }

      // Only resolve name of the target node at key === 0
      const newObjectName = key === 0 ? resolvedName : name;

      const originalParent = parentNodeId && getObject(parentNodeId);
      const newParentNodeId =
        (originalParent && idRecord[originalParent.id]) || parentNodeId;

      let createdBy: User | null = null;
      if (meData?.user) {
        const { __typename, userId, ...props } = meData.user;
        createdBy = { ...props, id: userId };
      }

      update({
        createdBy,
        id: idRecord[id],
        name: newObjectName,
        parentNodeId: newParentNodeId,
      });
    });

    // Expand new rows
    Object.values(idRecord).forEach((id) => {
      toggleRow(id);
    });
  };

  return {
    copyObject,
    deleteObject,
    editObject,
    editing,
    object,
    saveName,
  };
}
