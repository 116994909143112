import { unflattenFolders } from '../helpers/unflattenFolders';
import { MutateFolderPropPayload, State } from '../types';

export const mutateFolderPropAction = ({
  payload,
  state,
}: {
  payload: MutateFolderPropPayload;
  state: State;
}) => {
  const thisFolder = state.folders.find(
    (folder) => folder.id === payload.folderId
  );
  const otherFolders = state.folders.filter(
    (folder) => folder.id !== payload.folderId
  );

  const folders = [
    ...otherFolders,
    { ...thisFolder, [payload.key]: payload.value },
  ];

  return {
    ...state,
    folderTree: unflattenFolders(folders),
    folders,
  };
};
