import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useCompanyDataRegistrationConfig = () => {
  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Address 1', // i18n
      name: 'addressLine1',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Address 2', // i18n
      name: 'addressLine2',
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Zip code', // i18n
      name: 'postalcode',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Town', // i18n
      name: 'city',
      required: true,
      type: ValueTypeName.Text,
    },
    {
      displayName: 'Country', // i18n
      name: 'country',
      required: true,
      type: ValueTypeName.Selection,
    },
    {
      displayName: 'VAT number', // i18n
      name: 'vatIdentificationNumber',
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
