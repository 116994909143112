import { DropzoneOptions, useDropzone } from 'react-dropzone';

export const useVersionUpload = ({
  onDrop,
}: {
  onDrop?: DropzoneOptions['onDrop'];
}) => {
  const {
    getInputProps,
    getRootProps,
    isDragActive,
    open: openFileInput,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return { getInputProps, getRootProps, isDragActive, openFileInput };
};
