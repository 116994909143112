import { FolderPermission } from '@pro4all/graphql';

import { removeExclusiveFolderPermissions } from '../../shared/helpers/removeExclusiveFolderPermissions';
import { getCollectedPermissionsAncestors } from '../helpers/getCollectedPermissionsAncestors';
import { getNestedChildFolders } from '../helpers/getNestedChildFolders';
import { getOtherFolderPermissions } from '../helpers/getOtherFolderPermissions';
import { getThisFolderPermission } from '../helpers/getThisFolderPermission';
import { getUpdatedSavePermissions } from '../helpers/getUpdatedSavePermissions';
import { getUpdatedSubfoldersAllPermissionsAfterBreakInheritance } from '../helpers/getUpdatedSubfoldersAllPermissionsAfterBreakInheritance';
import { State } from '../types';

export const toggleBreakInheritanceAction = ({
  folderId,
  state,
}: {
  folderId: string;
  state: State;
}) => {
  const { displayPermissions, initialPermissionsExplicit, savePermissions } =
    state;

  const otherFolderPermissions = getOtherFolderPermissions(
    folderId,
    displayPermissions
  );

  const thisFolderPermission = getThisFolderPermission(
    folderId,
    displayPermissions
  );

  const folderPermissions =
    getUpdatedSubfoldersAllPermissionsAfterBreakInheritance({
      clickedFolder: thisFolderPermission,
      initialPermissionsExplicit,
      otherFolderPermissions,
      savePermissions,
    });

  const savePermissionsUpdated = getUpdatedSavePermissions(
    folderPermissions.find((folder) => folder.folderId === folderId),
    state
  );

  const foldersToChange = [
    thisFolderPermission.folderId,
    ...getNestedChildFolders(folderId, otherFolderPermissions)
      .filter((item) => !item.breakInheritance)
      .map((item) => item.folderId),
  ];

  const folderPermissionsWithParent = folderPermissions.map((permission) => {
    let folderPermissions: FolderPermission[] = [];
    if (foldersToChange.includes(permission.folderId)) {
      // We have two possible scenarios that we have to take into account:
      // 1. The subfolder has a save permission, in that case we should use that.
      // 2. The subfolder does not have a save permission, in that case we should
      //    use the initial explicit permissions of the subfolder combined with the
      //    inherited permissions of the parent folder.

      const folderInitialPermission = getThisFolderPermission(
        permission.folderId,
        initialPermissionsExplicit
      );

      // We have to take into account a scenario with multiple deeply nested subfolders.
      // So collect all the permissions of all the parent folders.
      const collectedParentPermissions = getCollectedPermissionsAncestors({
        folder: permission,
        initialPermissionsExplicit,
        savePermissions: savePermissionsUpdated,
      });

      const folderSavePermissions = getThisFolderPermission(
        permission.folderId,
        savePermissionsUpdated
      );

      const saveOrInitialExplicitPermissions =
        folderSavePermissions || folderInitialPermission;

      folderPermissions = [
        ...new Set([
          ...saveOrInitialExplicitPermissions.folderPermissions,
          ...collectedParentPermissions,
        ]),
      ];
    }

    return foldersToChange.includes(permission.folderId)
      ? {
          ...permission,
          folderPermissions: removeExclusiveFolderPermissions({
            permissions: folderPermissions,
          }),
        }
      : permission;
  });

  // If user breaks inheritance, we take `folderPermissions`.
  // If user restores inheritance, we take `folderPermissionsWithParent`.

  return {
    ...state,
    displayPermissions: thisFolderPermission.breakInheritance
      ? folderPermissionsWithParent
      : folderPermissions,
    savePermissions: savePermissionsUpdated,
  };
};
