import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Column, Footer } from '@pro4all/shared/ui/general';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { EditObjectsProvider } from './EditObjectsContext';
import { Table } from './Table';
import { useEditObjects } from './useEditObjects';

export const EditObjects: React.FC = () => {
  const { t } = useTranslation();
  const { hasAppPermission } = useAppPermissions();
  const noAccess = !hasAppPermission('OrganizationUpdate');

  const { create, goBack, save, staged, ...context } = useEditObjects();

  if (noAccess) return <BigMessageNoAccess />;

  return (
    <EditObjectsProvider
      create={create}
      goBack={goBack}
      save={save}
      staged={staged}
      {...context}
    >
      <Column>
        <Box padding={3}>
          <StyledButton
            label={t('New object')}
            onClick={create}
            startIcon="addObject"
          />
        </Box>
        <Table />
        <Footer $sticky pb={3} pt={2} px={3}>
          <Button
            color="inherit"
            onClick={goBack}
            type="button"
            variant="outlined"
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={!Object.keys(staged).length}
            onClick={save}
            startIcon="save"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </Footer>
      </Column>
    </EditObjectsProvider>
  );
};

const StyledButton = styled(Button)`
  && {
    border-radius: 0.5rem;
    padding: 4px 11px;
  }
`;
