import { ReactNode } from 'react';
import {
  createLayerComponent,
  LayerProps,
  LeafletContextInterface,
} from '@react-leaflet/core';
import L, { DoneCallback } from 'leaflet';

import { TileService } from '@pro4all/quality-control/data-access';

interface AuthTileProps extends LayerProps {
  children?: ReactNode;
  maxZoom?: number;
  minZoom?: number;
  noWrap?: boolean;
  page: number;
  tileSize?: number;
  visualContextId: string;
  zoomOffset?: number;
}

class AuthTile extends L.TileLayer {
  visualContextId: string;
  page: number;

  createTile(coords: L.Coords, done: DoneCallback) {
    const img = document.createElement('img');
    img.setAttribute('role', 'presentation');

    TileService.getTileUrl(
      this.visualContextId,
      coords.z,
      coords.x,
      coords.y,
      this.page
    ).then((src) => {
      if (src) {
        img.src = src;
      } else {
        img.setAttribute('style', 'visibility: hidden;');
      }
      done();
    }, null);

    return img;
  }

  setVisualContextId(id: string) {
    this.visualContextId = id;
  }
  setPage(page: number) {
    this.page = page;
  }
}

const createAuthTileLayer = (
  props: AuthTileProps,
  context: LeafletContextInterface
) => {
  const instance = new AuthTile('placeholder', { ...props });

  instance.setVisualContextId(props.visualContextId);
  instance.setPage(props.page);
  return { context, instance };
};

const updateAuthTileLayer = (
  instance: AuthTile,
  props: AuthTileProps,
  prevProps: AuthTileProps
) => {
  if (prevProps.visualContextId !== props.visualContextId) {
    instance.visualContextId = props.visualContextId;
  }
  if (prevProps.page !== props.page) {
    instance.page = props.page;
    instance.redraw();
  }
};

export const AuthTileLayer = createLayerComponent(
  createAuthTileLayer,
  updateAuthTileLayer
);
