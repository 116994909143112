import React from 'react';

import { FilterBaseProps } from '@pro4all/shared/search-utils';

import * as Styled from '../../Filter.styles';
import { useMDFacets } from '../../utils/useMDFacets';

import { BooleanSelect } from './BooleanSelect';
import { DefinitionSelect } from './DefinitionSelect';

export const BooleanFilter: React.FC<FilterBaseProps> = ({
  ...filterProps
}) => {
  const { selectedFacet } = useMDFacets(filterProps);
  const { name } = filterProps;

  return (
    <div>
      {name ? (
        <Styled.ContentWrap>
          <BooleanSelect facetItem={selectedFacet} {...filterProps} />
        </Styled.ContentWrap>
      ) : (
        <DefinitionSelect {...filterProps} />
      )}
    </div>
  );
};
