import { FieldDefinition, ValueType } from '@pro4all/graphql';
import {
  FormFieldConfig,
  FormFieldConfigValueType,
} from '@pro4all/shared/forms';

export const fieldDefinitionToFormFieldConfig = (
  item: FieldDefinition
): FormFieldConfig => ({
  displayName: item.displayName,
  name: item.id,
  required: item.required,
  type: item.type,
  valueType: valueTypeToFormFieldConfigValueType(item.valueType),
});

const valueTypeToFormFieldConfigValueType = (
  valueType: ValueType
): FormFieldConfigValueType => ({
  ...valueType,
  subFields: (valueType.subFields || []).map(fieldDefinitionToFormFieldConfig),
});
