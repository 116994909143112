import styled from 'styled-components';

import { Text } from '@pro4all/shared/ui/typography';

export const ColumnLeft = styled.div`
  width: 400px;
`;

export const ColumnRight = styled.div`
  width: 400px;
  margin-left: ${({ theme }) => theme.spacing(8)};
`;

export const InitialsTitle = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
