import styled from 'styled-components';

import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Header = styled(Text)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Description = styled.div`
  width: 600px;
`;
