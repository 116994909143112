import React from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  FieldDefinition,
  ScopeType,
  Template,
  TemplateState,
  useCreateTemplateMutation,
  useEditTemplateMutation,
  usePublishTemplatesMutation,
  ValueTypeName,
} from '@pro4all/graphql';
import {
  Condition,
  conditionsUpdated,
  getConditions,
} from '@pro4all/metadata/ui/utils';
import { TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { TrackingEvent } from '@pro4all/shared/config';
import { useStoreErrorInDataDog } from '@pro4all/shared/datadog-logging';
import {
  getAuthUserDisplayName,
  useContextScopedOrganizationId,
} from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SelectPinServiceInstance } from '@pro4all/shared/select-snag-icon/api';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import { MessageAction, useShowMessages } from '@pro4all/shared/ui/messages';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useMetaDataContext } from '../../../views/MetaDataContext';
import { featureConfig } from '../../configs/featureConfig';
import {
  useConditionsSidebarContext,
  useFieldContext,
} from '../TemplateMutationContext';

export const useTemplateSubmit = ({
  selectedTemplate,
  selectedTemplateIcon,
  selectedCustomIconImage,
}: {
  selectedCustomIconImage?: File;
  selectedTemplate?: Template;
  selectedTemplateIcon?: TMuiIcon;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { params, searchParams } = useRouting();
  const createMode = searchParams.is('action', 'createTemplate');
  const { track } = useAnalytics();
  const { fieldDefinitions, fieldDefinitionsInitial } = useFieldContext();
  const { templateType, templateService } = useMetaDataContext();
  const [createTemplate] = useCreateTemplateMutation();
  const [editTemplate] = useEditTemplateMutation();
  const [publishTemplates] = usePublishTemplatesMutation();
  const { projectId, tbqScopeId } = params;
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const { addItems, editItems } = useOptimisticResponseContext<Template>();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { conditions } = useConditionsSidebarContext();

  const onClose = () => {
    setTimeout(() => {
      searchParams.clear();
    }, 500);
  };
  const { showMutationMessage } = useShowMessages();
  const { entityType } =
    featureConfig[templateService].templateTypes[templateType];

  const getTotalAmountOfQuestions = () => {
    // Count any occurrence of a question in the set
    const flatSum = (fields: FieldDefinition[]) => {
      let count = 0;
      if (fields.length > 0) {
        fields.forEach((field) => {
          if (field.valueType?.subFields?.length > 0) {
            count += flatSum(field.valueType.subFields);
          }
          if (field.type !== ValueTypeName.Section) {
            count++;
          }
        });
      }
      return count;
    };

    return flatSum(fieldDefinitions);
  };

  const cleanNullIndicatives = (fieldDefinitions: FieldDefinition[]) => {
    fieldDefinitions.forEach((field) => {
      if (field.type === 'Section') {
        cleanNullIndicatives(field.valueType.subFields);
      } else {
        if (!field.indicative) field.indicative = false;
      }
    });
  };

  const assignColorsToStatusOptions = (fieldDefinitions: FieldDefinition[]) => {
    fieldDefinitions.forEach((fieldDefinition: FieldDefinition) => {
      if (
        fieldDefinition.type === 'Status' &&
        fieldDefinition.valueType?.options.length
      ) {
        fieldDefinition.valueType.options.forEach((option) => {
          if (option.color === undefined) {
            option.color = '#7E78F9';
          }
        });
      }
    });

    return fieldDefinitions;
  };

  const addConditionsRecursive = (
    fieldDefinition: FieldDefinition,
    conditions: Condition[]
  ) => {
    const matchingConditions = conditions.filter(
      (condition) => condition.fromSectionId === fieldDefinition.id
    );

    if (matchingConditions.length > 0) {
      fieldDefinition.conditions = matchingConditions.map(
        (matchingCondition) => ({
          fieldId: matchingCondition.field,
          value: matchingCondition.answer,
        })
      );
    } else {
      fieldDefinition.conditions = [];
    }

    if (fieldDefinition.valueType?.subFields) {
      fieldDefinition.valueType.subFields.forEach((subfield) => {
        addConditionsRecursive(subfield, conditions);
      });
    }
  };

  const addConditions = (
    fieldDefinitions: FieldDefinition[]
  ): FieldDefinition[] => {
    fieldDefinitions.forEach((fieldDefinition: FieldDefinition) => {
      addConditionsRecursive(fieldDefinition, conditions);
    });

    return fieldDefinitions;
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const { storeErrorInDataDog } = useStoreErrorInDataDog();

  const onSubmit = async (values: FormikValues, publishTemplate?: boolean) => {
    try {
      setIsLoading(true);
      const { name } = values;

      const iconPayload = {
        iconType: selectedCustomIconImage ? 2 : 1,
        selectedTemplateIcon,
      };

      if (selectedCustomIconImage) {
        const iconUploadResponse =
          await SelectPinServiceInstance.uploadCustomIcon({
            file: selectedCustomIconImage,
          });
        const fileId = iconUploadResponse?.data?.files?.[0]?.id;
        Object.assign(iconPayload, { fileId });
      }

      if (createMode) {
        track(TrackingEvent.MetadataTemplateCreated, {
          amountOfQuestions: getTotalAmountOfQuestions(),
          templateType: templateType,
        });

        const scope = tbqScopeId
          ? ScopeType.Global
          : projectId
          ? ScopeType.Project
          : ScopeType.Organization;

        const fieldDefinitionsWithConditions = addConditions(fieldDefinitions);

        const fieldDefinitionsWithColors = assignColorsToStatusOptions(
          fieldDefinitionsWithConditions
        );

        cleanNullIndicatives(fieldDefinitionsWithColors);

        const response = await createTemplate({
          variables: {
            ...iconPayload,
            fieldDefinitionsBody: JSON.stringify(fieldDefinitionsWithColors),
            id: tbqScopeId || projectId || organizationId,
            name,
            scope,
            templateService,
            templateType,
          },
        });

        const { id, success } = response.data?.createTemplate ?? {};

        if (success) {
          // Publish if needed. BE endpoint can only publish a draft set, so we have to do this in two steps.
          if (publishTemplate) {
            track(TrackingEvent.MetadataTemplatePublished);
            await publishTemplates({
              variables: { ids: [id], templateService },
            });
          }

          addItems([
            {
              createdAt: new Date().toISOString(),
              createdBy: { displayName: userName, id: userId },
              id,
              name,
              scope: { type: scope },
              state: publishTemplate
                ? TemplateState.Published
                : TemplateState.Draft,
            },
          ]);

          onClose();
          showMutationMessage({
            action: MessageAction.Create,
            entityType,
            name,
          });
        } else {
          enqueueSnackbar(
            t('This name already exists, please use a different name')
          );
        }
      } else {
        const conditions = getConditions(fieldDefinitions);

        const fieldDefinitionsWithConditions = addConditions(fieldDefinitions);

        const someConditionUpdated = conditionsUpdated(
          fieldDefinitions,
          conditions
        );

        if (
          !publishTemplate &&
          selectedTemplate.state === TemplateState.Published &&
          isEqual(
            fieldDefinitionsInitial.map((field) => {
              delete field.index;
              return field;
            }),
            fieldDefinitions && someConditionUpdated
          )
        ) {
          // There must be change in the included fields list before users can save a published set to draft.
          enqueueSnackbar(t('Change fields to save a published set as draft'));
        } else {
          track(TrackingEvent.MetadataTemplateSaved);

          const fieldDefinitionsWithColors = assignColorsToStatusOptions(
            fieldDefinitionsWithConditions
          );

          cleanNullIndicatives(fieldDefinitionsWithColors);

          const response = await editTemplate({
            variables: {
              ...iconPayload,
              fieldDefinitionsBody: JSON.stringify(fieldDefinitionsWithColors),
              id: selectedTemplate.id,
              name:
                selectedTemplate.name !== name ? name : selectedTemplate.name,
              selectedTemplateIcon,
              templateService,
            },
          });

          const { success } = response.data?.editTemplate ?? {};
          if (success) {
            // Set becomes draft after edit. Re-publish if needed.
            if (publishTemplate) {
              await publishTemplates({
                variables: {
                  ids: [selectedTemplate.id],
                  templateService,
                },
              });
            }

            editItems([
              {
                ...selectedTemplate,
                name,
                state: publishTemplate
                  ? TemplateState.Published
                  : TemplateState.Draft,
              },
            ]);

            onClose();
            showMutationMessage({
              action: MessageAction.Update,
              entityType,
              name,
            });
          } else {
            enqueueSnackbar(
              t(
                'Something went wrong. Please try again or contact an administrator.'
              )
            );
          }
        }
      }
    } catch (error) {
      storeErrorInDataDog({
        errorTitle: 'Template submit error',
        reactComponent: 'useTemplateSubmit',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, onSubmit };
};
