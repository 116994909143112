import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikErrors,
  useFormikContext,
} from 'formik';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { useFieldValidation, usePaste } from '@pro4all/metadata/ui/utils';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';

import { CustomSelectionOption } from './CustomSelectionOption';
import { StyledAddOption } from './Styles';
import { FormFields } from './Types';

export type SelectOption = {
  name: string;
};

interface Props {
  addOptionPlaceholder?: string;
  selectionOptions: SelectOption[];
}

export const SelectionOptions = ({
  selectionOptions,
  addOptionPlaceholder,
}: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const optContainerRef = useRef(null);
  const {
    params: { projectId },
  } = useRouting();
  const { errors } = useFormikContext<FormFields>();
  const { setFieldTouched } = useFormikContext<FormFields>();
  const { fieldValidation } = useFieldValidation();
  const { t } = useTranslation();

  const [newOptionName, setNewOptionName] = useState('');
  const newOptionRef = useRef<HTMLInputElement>();
  const { onPasteHandler, onPasteOverWriteHandler } = usePaste();

  const nextOptionError = Array.isArray(errors?.selectionOptions)
    ? errors?.selectionOptions?.findIndex((err) => err)
    : -1;
  const onScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setScrollPosition(target.scrollTop);
  };

  // Do not allow comma's because it is used as a separator for multiselect options
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOptionName(event.currentTarget.value);
  };

  // Use Formik's arrayHelper to dynamically add an option field and color input
  const onEnter = (
    event: React.KeyboardEvent<HTMLInputElement>,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (newOptionName) {
        arrayHelpers.push({
          id: uuid(),
          name: newOptionName,
          projectId: projectId ?? null,
        });
      }
      fieldValidation({
        newOptionRef,
        selectionOptions,
        setFieldTouched,
        setNewOptionName,
      });
      scrollToBottom();
    }
  };

  const itemHeight = 50;
  const numVisibleItems = 8;
  const totalItems = selectionOptions.length;

  const scrollErrorIntoView = () => {
    const nextErrorPosition = nextOptionError * itemHeight;
    optContainerRef.current.scrollTop = nextErrorPosition;
  };

  const scrollToBottom = () => {
    if (optContainerRef.current) {
      setTimeout(() => {
        const scrollContainer = optContainerRef.current;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 100);
    }
  };

  const errorsCount = () => {
    if (Array.isArray(errors?.selectionOptions)) {
      const optionErrors =
        errors?.selectionOptions as FormikErrors<SelectOption>[];
      return optionErrors?.filter((opt) => Boolean(opt)).length;
    }
  };

  const renderItems = (arrayHelpers: FieldArrayRenderProps) => {
    const startIndex = Math.floor(scrollPosition / itemHeight);
    const buffer = 1;
    const endIndex = startIndex + numVisibleItems + buffer;

    const visibleItems = selectionOptions
      .slice(startIndex, endIndex)
      .map((item: any, index: number) => {
        const itemIndex = startIndex + index;

        const translateY = itemIndex * itemHeight;
        const itemError = errors?.selectionOptions?.[itemIndex] as SelectOption;
        return (
          <Box
            className={`item-index-${itemIndex}`}
            key={itemIndex}
            style={{
              height: itemHeight,
              position: 'absolute',
              transform: `translateY(${translateY}px)`,
              width: '100%',
            }}
          >
            <CustomSelectionOption
              arrayHelpers={arrayHelpers}
              hideDeleteButton={
                item?.__typename === 'TaskCategory' &&
                !item.projectId &&
                Boolean(projectId)
              }
              index={itemIndex}
              key={itemIndex}
              onPasteOverWriteHandler={(
                event: React.ClipboardEvent<HTMLInputElement>
              ) =>
                onPasteOverWriteHandler({
                  arrayHelpers,
                  event,
                  index: itemIndex,
                  newOptionRef,
                  selectionOptions,
                  setFieldTouched,
                  setNewOptionName,
                })
              }
            />

            {/* Formik isn't handling the virtualization well when it comes to showing the error messages
                 so this is a simple custom solution. */}
            {itemError ? (
              <StyledErrorMessage> {itemError.name}</StyledErrorMessage>
            ) : null}
          </Box>
        );
      });
    return visibleItems;
  };
  return (
    <>
      <FieldArray
        name="selectionOptions"
        render={(arrayHelpers) => (
          <>
            <div
              onScroll={onScroll}
              ref={optContainerRef}
              style={{
                maxHeight: numVisibleItems * itemHeight,
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <div
                style={{
                  height: totalItems * itemHeight,
                  position: 'relative',
                }}
              >
                {renderItems(arrayHelpers)}
              </div>
            </div>
            <StyledAddOption
              autoFocus
              name="newOptionName"
              onChange={onInputChange}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                onEnter(e, arrayHelpers)
              }
              onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
                onPasteHandler({
                  arrayHelpers,
                  event,
                  newOptionRef,
                  selectionOptions,
                  setFieldTouched,
                  setNewOptionName,
                });
                scrollToBottom();
              }}
              placeholder={addOptionPlaceholder ?? t('Add option')}
              ref={newOptionRef}
              value={newOptionName}
            />
          </>
        )}
      />
      {nextOptionError > -1 ? (
        <Box alignItems="center" display="flex" mb={2} mt={2}>
          <StyledErrorButton onClick={scrollErrorIntoView}>
            {`${t('Go to first error')} `}
          </StyledErrorButton>
          <StyledErrorCount>{`${errorsCount()} error(s)`}</StyledErrorCount>
        </Box>
      ) : null}
    </>
  );
};

export const StyledErrorMessage = styled('span')`
  color: ${({ theme }) => theme.palette.error.main};
`;

const StyledErrorButton = styled(Button)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const StyledErrorCount = styled('li')`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
