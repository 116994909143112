import React, { useMemo } from 'react';

import { Group, useGroupsIncludeQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoGroups } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { groupsIncludeProps } from '../getIncludeProps';
import GroupListMobile from '../GroupListMobile';
import { GroupSidebar } from '../GroupSidebar';
import { useGroupColumns } from '../useGroupColumns';

import { OrganizationGroupsActionBar } from './OrganizationGroupsActionBar';

export const OrganizationGroupManagement: React.FC = () => {
  const isMobileScreen = useIsMobileScreen();

  const { searchParams } = useRouting();
  const columns = useGroupColumns();
  const { data, error, loading } = useGroupsIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...groupsIncludeProps,
    },
  });

  const selectGroup = (group: Group) => {
    searchParams.set({ action: 'viewGroup', id: group.id });
  };

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Group>();

  const groups = useMemo(() => data?.groups || [], [data?.groups]) as Group[];

  useSetItemsInLocalState(groups);

  return (
    <TableContextProvider
      columns={columns}
      id="table-group-management"
      items={items}
    >
      <FilterContextProvider<Group>>
        <OrganizationGroupsActionBar />
        <ResponseWrapper error={error} isLoading={loading && !data}>
          {!items.length && !itemsInitial.length ? (
            <BigMessageNoGroups />
          ) : isMobileScreen ? (
            <GroupListMobile groups={items} onRowClick={selectGroup} />
          ) : (
            <Table<Group> onRowClick={selectGroup} />
          )}
        </ResponseWrapper>
        <GroupSidebar />
      </FilterContextProvider>
    </TableContextProvider>
  );
};
