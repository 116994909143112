import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useUsersActions } from '../utils/useUsersActions';

import { usePredefinedFilters } from './usePredefinedFilters';

export const OrganizationUsersActionBar = () => {
  const { mainActions, altActions } = useUsersActions();
  const showExportButton = useFeatureFlag('export-user-tables');
  const predefinedFilters = usePredefinedFilters();

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="users-action-bar"
      mainActions={mainActions}
      predefinedFilters={predefinedFilters}
      search
      showExportButton={showExportButton}
    />
  );
};
