import { Editor, Text } from 'slate';

import { CustomText } from './CustomTypes';

type TFormat = keyof Omit<CustomText, 'text'>;

export const toggleMark = (editor: Editor, format: TFormat) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: Editor, format: TFormat) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => Text.isText(n) && n[format] === true,
    universal: true,
  });

  if (match) return Boolean(match);

  const marks = editor.marks;
  return marks ? marks[format] === true : false;
};
