import { useCallback, useEffect, useMemo } from 'react';

import {
  useTableCheckContext,
  useTableContext,
  useTableLastCheckedContext,
} from '../TableContext';
import { BaseRow } from '../types';

export function useTableCheck<Row extends BaseRow>() {
  const { rows } = useTableContext<Row>();
  const { checkedRows, setCheckedRows } = useTableCheckContext<Row>();
  const { lastChecked, setLastChecked } = useTableLastCheckedContext();

  // When rows are removed, remove them from the checkedRows array.
  useEffect(() => {
    const ids = rows.map((row) => row.id);
    const newCheckedRows = checkedRows.filter((selectedRow) =>
      ids.includes(selectedRow.id)
    );

    if (checkedRows.length !== newCheckedRows.length) {
      setCheckedRows && setCheckedRows(newCheckedRows);
    }
  }, [checkedRows, rows, setCheckedRows]);

  const checkAllRows = useCallback(
    () =>
      setCheckedRows &&
      setCheckedRows(rows.filter((rowData: Row) => !rowData.disabled)),
    [rows, setCheckedRows]
  );

  const uncheckAllRows = useCallback(() => {
    setCheckedRows && setCheckedRows([]);
  }, [setCheckedRows]);

  const removeRows = useCallback(
    (rows: Row[], start: number, finish: number) => {
      const removeRowsIds = rows.slice(start, finish).map((row) => row.id);
      return checkedRows.filter(
        (checkedRow) => !removeRowsIds.includes(checkedRow.id)
      );
    },
    [checkedRows]
  );

  const checkRowBatch = (currentRows: Row[]) => setCheckedRows?.(currentRows);

  const checkRow = useCallback(
    (row: Row, rowIndex: number, holdShift: boolean) => {
      const clickedAboveLastCheckbox = lastChecked.index >= rowIndex;
      const selected = checkedRows.some(
        (checkedRow) => checkedRow.id === row.id
      );

      let newCheckedRows: Row[] = [];

      if (holdShift) {
        if (clickedAboveLastCheckbox) {
          if (lastChecked.selected) {
            newCheckedRows = rows
              .slice(rowIndex, lastChecked.index)
              .concat(checkedRows)
              .reverse();
          } else {
            newCheckedRows = removeRows(rows, rowIndex, lastChecked.index + 2);
          }
        } else {
          if (lastChecked.index !== -1) {
            if (lastChecked.selected) {
              newCheckedRows = checkedRows
                .concat(rows.slice(lastChecked.index + 1, rowIndex + 1))
                .reverse();
            } else {
              newCheckedRows = removeRows(
                rows,
                lastChecked.index,
                rowIndex + 1
              );
            }
          }
        }
      } else {
        if (selected) {
          newCheckedRows = checkedRows.filter(
            (checkedRow) => checkedRow.id !== row.id
          );
        } else {
          newCheckedRows = [...checkedRows, row];
        }
      }
      setCheckedRows && setCheckedRows(newCheckedRows);
      setLastChecked &&
        setLastChecked({ index: rowIndex, selected: !selected });
    },
    [lastChecked, checkedRows, setCheckedRows, setLastChecked, rows, removeRows]
  );

  const isChecked = useCallback(
    (row: Row) =>
      Boolean(checkedRows.find((selectedRow) => selectedRow.id === row.id)),
    [checkedRows]
  );

  const returnValue = useMemo(() => {
    const anyRowsAreChecked = checkedRows.length > 0;
    return {
      allRowsAreChecked:
        checkedRows.length !== 0 && checkedRows.length === rows.length,
      anyRowsAreChecked,
      checkAllRows,
      checkRow,
      checkRowBatch,
      checkedRows,
      isChecked,
      noRowsAreChecked: !anyRowsAreChecked,
      someRowsAreChecked:
        checkedRows.length > 0 && checkedRows.length !== rows.length,
      toggleCheckAllRows: anyRowsAreChecked ? uncheckAllRows : checkAllRows,
      uncheckAllRows,
    };
  }, [
    checkAllRows,
    checkedRows,
    checkRow,
    isChecked,
    rows,
    uncheckAllRows,
    checkRowBatch,
  ]);

  return returnValue;
}
