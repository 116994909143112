import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const GreySmallMetaText = styled('span')`
  color: ${customColors.grey700};
  display: flex;
  align-items: center;
  margin-top: 5px;
  & > * {
    margin-right: 5px;
  }
`;
