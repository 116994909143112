import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import {
  createTheme,
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@pro4all/shared/mui-wrappers';

import { themePro4all } from '../themes/themePro4all';
import { themeTbq } from '../themes/themeTbq';

export enum availableThemes {
  pro4all = 'pro4all',
  tbq = 'tbq',
}

const themes = {
  pro4all: themePro4all,
  tbq: themeTbq,
};

export const ThemeProvider: React.FC<{
  themeName: keyof typeof availableThemes;
}> = ({ children, themeName }) => {
  const muiTheme = createTheme(themes[themeName]);
  return (
    <StylesProvider injectFirst>
      <CssBaseline>
        <MuiThemeProvider theme={muiTheme}>
          <SCThemeProvider theme={muiTheme}>{children}</SCThemeProvider>
        </MuiThemeProvider>
      </CssBaseline>
    </StylesProvider>
  );
};
