import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, useFormConfig } from '@pro4all/shared/forms';

export const useOrganizationSettingsFormConfig = () => {
  const { t } = useTranslation();

  const formFields: FormFieldConfig[] = [
    {
      displayName: t('Approve'),
      name: 'approve_action_label',
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Reject'),
      name: 'reject_action_label',
      type: ValueTypeName.Text,
    },
    {
      displayName: t('Finalize'),
      name: 'finalize_action_label',
      type: ValueTypeName.Text,
    },
  ];

  return useFormConfig({ formFields });
};
