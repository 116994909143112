import React from 'react';
import { useTranslation } from 'react-i18next';

import { BigMessage } from '../big-message-wrapper/BigMessage';

export const BigMessageNoQcPermissionsForGroups: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BigMessage
      shapeName="collaborate"
      text={t('Group permissions will be available soon')}
      title={t('No permissions for groups')}
    />
  );
};
