import { BasePropsHierarchyItem, ItemProps } from '../types';

export const selectItemAction = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  ids,
  multipleAnswers,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'ids' | 'multipleAnswers'>) => {
  let updatedItems = allItems;

  const itemsToSelectOrDeselect = allItems.filter((item) =>
    ids.includes(item.id)
  );
  const itemsToSelectOrDeselectUpdated = itemsToSelectOrDeselect.map(
    (item) => ({ ...item, selected: !item.selected })
  );

  // In case multiple selected items are not allowed we have to deselect all other items
  const otherItems = allItems.filter((item) => !ids.includes(item.id));
  const otherItemsUpdated = !multipleAnswers
    ? (updatedItems = otherItems.map((item) => ({
        ...item,
        selected: false,
      })))
    : otherItems;

  updatedItems = [...otherItemsUpdated, ...itemsToSelectOrDeselectUpdated];

  return updatedItems;
};
