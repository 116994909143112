import { useTranslation } from 'react-i18next';

import { useCancelSubscriptionMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { CancelSubscriptionFields } from '../CancelSubscription';

import { Reason } from './useCancelSubscriptionOptions';

export const useSubmitCancelSubscription = () => {
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const { goTo } = useRouting();
  const { t } = useTranslation();

  return async (values: CancelSubscriptionFields) => {
    const { description, reason, reasonDifferent } = values;
    await cancelSubscription({
      variables: {
        description,
        reason:
          reason === Reason.Different
            ? `${t(reason)}: ${reasonDifferent}`
            : t(reason),
      },
    });

    // Dirty trick, because somehow the Apollo queries do not reload in 'SubscriptionContext'.
    // This in between route forces a reload of the subscription data.
    goTo('usersGroups');

    goTo('settingsOrganizationLicense');
  };
};
