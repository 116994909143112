import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(4)} 0;
`;

export const StyledH2 = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.warning.main};
  }
`;

export const StyledH5 = styled(Text)`
  && {
    color: ${({ theme }) => theme.palette.warning.main};
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Link = styled(Text)`
  && {
    color: ${customColors.white};
    margin-top: ${({ theme }) => theme.spacing(2)};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
