import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { ReportConfigTypes } from '@pro4all/shared/types';

const findItemById = ({
  id,
  items,
}: Pick<ReportConfigTypes, 'id' | 'items'>): FieldDefinition | null => {
  for (const item of items) {
    if (item.id === id) {
      return item;
    } else if (
      item.type === ValueTypeName.Section &&
      item.valueType?.subFields &&
      item.valueType?.subFields.length > 0
    ) {
      const subItem = findItemById({ id, items: item.valueType.subFields });
      if (subItem) return subItem;
    }
  }
  return null;
};

export const getNestedItemIds = ({
  id,
  items,
}: Pick<ReportConfigTypes, 'id' | 'items'>) => {
  // Search the item with the given id.
  const item = findItemById({ id, items });

  // If the item is not found or it is not of type `section` then return an empty array.
  if (!item || item.type !== ValueTypeName.Section) {
    return [];
  }

  // Function to collect all ids of nested items within a section
  const collectIds = (subItems: FieldDefinition[]) => {
    let ids: string[] = [];

    subItems.forEach((subItem) => {
      ids.push(subItem.id);
      if (
        subItem.type === ValueTypeName.Section &&
        subItem.valueType?.subFields &&
        subItem.valueType?.subFields?.length > 0
      ) {
        ids = ids.concat(collectIds(subItem.valueType.subFields));
      }
    });

    return ids;
  };

  // Start collecting ids from the subFields of the found item
  return item?.valueType?.subFields ? collectIds(item.valueType.subFields) : [];
};
