import { ThemeOptions } from '@pro4all/shared/mui-wrappers';

import * as variables from '../style-dictionary-dist/variables';

import { components } from './components';
import { customColors } from './customColors';

export const themeTbq: ThemeOptions = {
  components,
  palette: {
    /**
     * These action properties define how interactible elements behave on each state.
     * MUI puts a transparent layer on top to get to the endresult
     */
    action: {
      activatedOpacity: 0.12,
      active: customColors.grey600,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      hover: 'rgba(0, 0, 0, 0.10)',
      hoverOpacity: 0.1,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
    },
    background: {
      default: customColors.white,
    },
    error: {
      contrastText: customColors.white,
      dark: customColors.errorHover,
      light: customColors.errorBg,
      main: customColors.errorAlert,
    },
    grey: {
      50: customColors.grey50,
      100: customColors.grey100,
      200: customColors.grey200,
      300: customColors.grey300,
      400: customColors.grey400,
      500: customColors.grey500,
      600: customColors.grey600,
      700: customColors.grey700,
      800: customColors.grey800,
      900: customColors.grey900,
    },
    primary: {
      contrastText: customColors.white,
      dark: customColors.greenDarkTbq,
      light: customColors.greenWhiteLight,
      main: customColors.greenPrimaryTbq,
    },
    secondary: {
      contrastText: customColors.white,
      dark: customColors.greenDark,
      light: customColors.greenAccent,
      main: customColors.greenPrimary,
    },
    success: {
      contrastText: customColors.grey900,
      dark: customColors.successHover,
      light: customColors.successBg,
      main: customColors.success,
    },
    text: {
      primary: customColors.grey700,
      secondary: customColors.notBlack,
    },
    warning: {
      contrastText: customColors.grey900,
      dark: customColors.warningDark,
      light: customColors.warningLight,
      main: customColors.warning,
    },
  },
  spacing: Number(variables.GlobalSpacing8.replace('px', '')),
  typography: {
    body1: {
      color: variables.GlobalCharcoalGrey,
      fontSize: variables.GlobalBody1FontSize,
      fontWeight: Number(variables.GlobalBody1FontWeight),
    },
    body2: {
      color: variables.GlobalCharcoalGrey,
      fontSize: variables.GlobalBody2FontSize,
      fontWeight: Number(variables.GlobalBody2FontWeight),
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      color: variables.GlobalCharcoalGrey,
      fontSize: variables.GlobalCaptionFontSize,
      fontWeight: Number(variables.GlobalCaptionFontWeight),
    },
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: variables.GlobalNotBlack,
      fontSize: variables.GlobalH1TitleFontSize,
      fontWeight: Number(variables.GlobalH1TitleFontWeight),
    },
    h2: {
      color: variables.GlobalNotBlack,
      fontSize: variables.GlobalH2TitleFontSize,
      fontWeight: Number(variables.GlobalH2TitleFontWeight),
    },
    h3: {
      color: customColors.notBlack,
      fontSize: variables.GlobalH3TitleFontSize,
      fontWeight: Number(variables.GlobalH3TitleFontWeight),
    },
    h4: {
      color: customColors.notBlack,
      fontSize: variables.GlobalH4TitleFontSize,
      fontWeight: Number(variables.GlobalH4TitleFontWeight),
    },

    h5: {
      color: customColors.notBlack,
      fontSize: variables.GlobalH5TitleFontSize,
      fontWeight: Number(variables.GlobalH5TitleFontWeight),
    },
    h6: {
      color: customColors.notBlack,
      fontSize: variables.GlobalH6TitleFontSize,
      fontWeight: Number(variables.GlobalH6TitleFontWeight),
    },
    htmlFontSize: Number(variables.GlobalBody1FontSize.replace('px', '')),
  },
};
