import React from 'react';

import { useVisualContextQuery } from '@pro4all/graphql';
import {
  Marker,
  ReportMapContainer as MiniMapInner,
} from '@pro4all/quality-control/ui/maps';
import { ReportMiniMapProps } from '@pro4all/shared/types';

import { ReportMapContents } from './ReportMapContents';

export const ReportMiniMap = ({
  id,
  visualContext,
  x,
  y,
  indicativeState,
  type,
  page,
  templateIconProps,
  templateId,
}: ReportMiniMapProps) => {
  const visualContextId = visualContext?.id || '';

  const { data } = useVisualContextQuery({
    skip: !visualContextId,
    variables: { id: visualContextId },
  });

  if (
    !data?.visualContext?.height ||
    !data.visualContext.width ||
    !x ||
    !y ||
    !id ||
    !visualContext
  ) {
    return null;
  }

  const { height, width, zoomLevels } = data.visualContext;

  return (
    <MiniMapInner attributionControl={false} zoomControl={false}>
      <ReportMapContents
        lat={y}
        lng={x}
        mapHeight={height}
        mapWidth={width}
        page={page}
        type={type}
        visualContextId={visualContextId}
        zoomLevels={zoomLevels || 0}
      >
        <Marker
          iconName={templateIconProps?.iconName}
          iconType={templateIconProps?.iconType || 1}
          lat={y}
          lng={x}
          page={page}
          report
          statusColor={indicativeState?.color || undefined}
          templateId={templateId || ''}
          type={type}
        />
      </ReportMapContents>
    </MiniMapInner>
  );
};
