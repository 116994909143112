import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { EntityFolderPermission } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';
import { IconButton } from '@pro4all/shared/ui/buttons';

import { usePermissionsEntityMatrixContext } from './entity-context/PermissionsEntityMatrixProvider';

interface Props {
  currentPermissions: EntityFolderPermission;
  entityId: string;
  projectId: string;
}

export const SelectAllPermissionsForEntityButton = ({
  entityId,
  currentPermissions,
}: Props) => {
  const { id: userId } = currentPermissions;
  const { userId: loggedInUserId } = AuthService.getProfile();
  const { toggleAllPermissions } = usePermissionsEntityMatrixContext();

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  return showFinalPermissions ? null : (
    <IconButton
      dataTestId="selectPermissionsRow"
      disableBorder
      disabled={loggedInUserId === userId}
      iconName="arrowLeftRounded"
      onClick={async () => {
        toggleAllPermissions(entityId);
      }}
    />
  );
};
