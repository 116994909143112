import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import type { TbqTask } from '@pro4all/graphql';
import { useCreateTbqTaskMutation } from '@pro4all/graphql';
import { noop } from '@pro4all/shared/utils';
import { toApiDate } from '@pro4all/shared/utils';

import type { Fields } from './form';

export const useTBQTask = ({
  objectName,
  onCreate = noop,
}: { objectName?: string; onCreate?: () => void } = {}): ((
  values: Fields,
  helpers: FormikHelpers<Fields>
) => void) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createTbqTask] = useCreateTbqTaskMutation();

  return async (values) => {
    try {
      await createTbqTask({
        variables: { task: toTbqTaskInput(values) },
      });
      enqueueSnackbar(
        t('TBQ Task "{{name}}" has been created.', { name: values.name })
      );
      onCreate();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        t('Could not create TBQ Task for "{{objectName}}"', { objectName })
      );
    }
  };
};

export function toTbqTaskInput({
  endTime,
  name,
  projectId,
  nodeId,
  participant,
  tbqScanTypeId,
  tbqModuleType,
  ...rest
}: Fields): TbqTask {
  if (!name) throw Error('`name` cannot be empty');
  if (!nodeId) throw Error('`nodeId` cannot be empty');
  if (!projectId) throw Error('`projectId` cannot be empty');
  if (!tbqScanTypeId) throw Error('`tbqScanTypeId` cannot be empty');

  return {
    assignedUser: participant?.id,
    endTime: toApiDate(endTime) || undefined,
    name,
    nodeId,
    projectId,
    tbqModuleType,
    tbqScanTypeId,
    ...rest,
  };
}
