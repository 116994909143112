import { useState } from 'react';

import { useRouting } from '@pro4all/shared/routing-utils';

import {
  MuiChangeEvent,
  ResultsSidebarTabs,
  ResultTabKey,
} from './ResultsSidebarTabs';

interface Props {
  initialTab: ResultTabKey;
}

export const useResultTabs = ({ initialTab }: Props) => {
  const { searchParams } = useRouting();
  const [currentTab, setCurrentTab] = useState<ResultTabKey>(initialTab);

  const onChange = (e: MuiChangeEvent, value: ResultTabKey) => {
    setCurrentTab(value);
    searchParams.set({ tab: value });
    if (searchParams.get('focusComment')) searchParams.delete('focusComment');
  };
  const Tabs = () => (
    <ResultsSidebarTabs onChange={onChange} value={currentTab} />
  );

  return { Tabs, currentTab };
};
