import React from 'react';
import { ZoomControl } from 'react-leaflet';

import { useRouting } from '@pro4all/shared/routing-utils';

import { MapContents } from './MapContents';
import { StyledMapContainer } from './MapStyles';
import { ContentsProps } from './types';

interface Props extends ContentsProps {
  height?: number;
  width?: number;
}

export const Map: React.FC<Props> = ({
  children,
  disableControls = false,
  height,
  width,
  ...rest
}) => {
  const { searchParams } = useRouting();
  const placeSnag = searchParams.get('placeSnag');

  return (
    <StyledMapContainer
      $cursor={placeSnag === 'true' ? 'crosshair' : 'grab'}
      attributionControl={false}
      doubleClickZoom={!disableControls}
      dragging={!disableControls}
      height={height}
      scrollWheelZoom={!disableControls}
      wheelDebounceTime={10}
      wheelPxPerZoomLevel={350}
      width={width}
      zoomControl={false}
      zoomSnap={0.1}
    >
      {!disableControls && <ZoomControl position="bottomleft" />}
      <MapContents disableControls={disableControls} {...rest}>
        {children}
      </MapContents>
    </StyledMapContainer>
  );
};
