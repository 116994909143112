import styled from 'styled-components';

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const TreeList = styled(List)`
  && {
    width: 100%;
  }
`;

export const NodeListItem = styled(ListItem)`
  && {
    color: ${customColors.grey700};
    height: 26px;
    font-size: 14px;
    padding-right: ${({ theme }) => theme.spacing(0.5)};
    border-left: 3px solid rgba(0, 0, 0, 0);
    ${({ theme }) => theme.breakpoints.down('md')} {
      height: 40px;
    }
    &.MuiListItem-root.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.light};
      border-left: 3px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const LeafNodeListItem = styled(NodeListItem)`
  && {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

export const NodeCollapseButtonContainer = styled(ListItemIcon)`
  && {
    max-width: 16px;
    min-width: 16px;
    margin: ${({ theme }) => theme.spacing(1)};
  }
`;

export const NodeRowButton = styled(IconButton)`
  && {
    padding: 0;
    width: 16px;
    height: 16px;
  }
`;

export const LeafNodeIndent = styled('div')`
  width: ${({ theme }) => `calc(${theme.spacing(3)} + 36px)`};
`;
