import React, { useMemo } from 'react';

import { Member, useProjectMembersIncludeQuery } from '@pro4all/graphql';
import { useFetchProject } from '@pro4all/projects/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import {
  BigMessageNoProjectAccess,
  BigMessageNoProjectUsers,
} from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { InviteExternalsModal } from '../components/InviteExternalsModalForm/InviteExternalsModal';

import { EditProjectMembers } from './EditProjectMembers';
import { EditRolesSidebar } from './EditRolesSidebar';
import { projectMembersIncludeProps } from './getIncludeProps';
import { ProjectMembersActionBar } from './ProjectMembersActionBar';
import ProjectUserManagementMobileList from './ProjectUserManagementMobileList';
import { useProjectColumns } from './useProjectColumns';

export const ProjectUserManagement: React.FC = () => {
  const { searchParams, params } = useRouting();

  const isMobileScreen = useIsMobileScreen();

  const { data, error, loading, refetch } = useProjectMembersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...projectMembersIncludeProps,
      projectId: params.projectId,
    },
  });

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Member>();

  const projectMembers = useMemo(
    () => data?.projectMembers || [],
    [data?.projectMembers]
  ) as Member[];

  useSetItemsInLocalState(projectMembers);

  const { projectData } = useFetchProject();
  const columns = useProjectColumns();

  const showMembersSidebar = searchParams.is('action', 'addMembers');
  const showRolesSidebar = searchParams.is('action', 'editRoles');
  const showExternalsDialog = searchParams.is('action', 'inviteExternals');

  return (
    <ResponseWrapper
      CustomErrorComponent={<BigMessageNoProjectAccess />}
      error={error}
      isLoading={loading && !data}
    >
      <TableContextProvider
        columns={columns}
        id="table-project-user-management"
        items={items}
      >
        <FilterContextProvider<Member>>
          <ProjectMembersActionBar />
          <>
            {!items.length && !itemsInitial.length ? (
              <BigMessageNoProjectUsers />
            ) : isMobileScreen ? (
              <ProjectUserManagementMobileList
                projectMembers={projectMembers}
              ></ProjectUserManagementMobileList>
            ) : (
              <Table<Member> />
            )}
            <EditProjectMembers
              onClose={() => searchParams.clear()}
              open={showMembersSidebar}
            />
            <EditRolesSidebar
              onClose={() => searchParams.clear()}
              open={showRolesSidebar}
            />
            {showExternalsDialog && (
              <InviteExternalsModal
                onClose={() => searchParams.clear()}
                open={showExternalsDialog}
                project={projectData}
                refetchMembers={refetch}
              />
            )}
          </>
        </FilterContextProvider>
      </TableContextProvider>
    </ResponseWrapper>
  );
};
