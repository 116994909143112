import { FolderPermission } from '@pro4all/graphql';

import { permissionsToCheck } from '../../shared/types';
import { State } from '../types';

import { inConflictWithPermissionsOfOwnFolderOrNestedFolders } from './inConflictWithPermissionsOfOwnFolderOrNestedFolders';

export const getNonConflictingPermissions = ({
  folderId,
  newPermissions,
  state,
}: {
  folderId: string;
  newPermissions: FolderPermission[];
  state: State;
}) => {
  // User cannot set permission of a parent folder in case there are conflicting permissions on the subfolder.
  // So we have to check on that to see if we have to disable the checkbox yes or no.

  let response = newPermissions;

  permissionsToCheck.forEach((permission) => {
    if (
      inConflictWithPermissionsOfOwnFolderOrNestedFolders({
        folderId,
        permission,
        state,
      })
    ) {
      response = response.filter((perm) => perm !== permission);
    }
  });

  return response;
};
