import styled from 'styled-components';

import { Text } from '@pro4all/shared/ui/typography';

export const LabelChoosenLicence = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const LabelBillingInformation = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
