import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const ActionBar = styled.div`
  position: sticky;
  top: -1px;
  background: white;
  z-index: 2;

  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  @media print {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  && {
    > * {
      margin-left: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;

export const Main = styled.div`
  overflow: hidden;

  @media screen {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: none;
  }

  @media print {
    overflow: hidden;
  }
`;

export const EditReportLayout = styled(Box)`
  overflow: scroll;
  flex: 0 0 400px;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  @media print {
    display: none;
    overflow: hidden;
  }
`;

export const ReportMainContainer = styled(Box)`
  align-self: stretch;
  flex: auto;
  overflow: auto;
  @media print {
    overflow: hidden;
  }
`;

export const ReportPreviewContainer = styled.div`
  width: 100%;
  padding-top: 1em;
  background: ${customColors.grey300};
  min-height: 100vh;
  @media print {
    background: white;
  }
`;
