import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const DeleteFolder: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM18 9H16.75L16.395 8.645C16.305 8.555 16.175 8.5 16.045 8.5H13.955C13.825 8.5 13.695 8.555 13.605 8.645L13.25 9H12C11.725 9 11.5 9.225 11.5 9.5C11.5 9.775 11.725 10 12 10H18C18.275 10 18.5 9.775 18.5 9.5C18.5 9.225 18.275 9 18 9ZM12 16.5C12 17.05 12.45 17.5 13 17.5H17C17.55 17.5 18 17.05 18 16.5V11.5C18 10.95 17.55 10.5 17 10.5H13C12.45 10.5 12 10.95 12 11.5V16.5Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
