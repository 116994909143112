import React from 'react';

import { IconButton } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { StyledSvg } from './StyledSvg';

const CloseIcon = ({ $disabled }: { $disabled?: boolean }) => (
  <StyledSvg fill={$disabled ? customColors.grey400 : customColors.grey600}>
    <path d="M9.20047 0.806638C9.07591 0.681802 8.90681 0.611647 8.73047 0.611647C8.55412 0.611647 8.38502 0.681802 8.26047 0.806638L5.00047 4.05997L1.74047 0.799971C1.61591 0.675136 1.44681 0.60498 1.27047 0.60498C1.09412 0.60498 0.925023 0.675136 0.800469 0.799971C0.540469 1.05997 0.540469 1.47997 0.800469 1.73997L4.06047 4.99997L0.800469 8.25997C0.540469 8.51997 0.540469 8.93997 0.800469 9.19997C1.06047 9.45997 1.48047 9.45997 1.74047 9.19997L5.00047 5.93997L8.26047 9.19997C8.52047 9.45997 8.94047 9.45997 9.20047 9.19997C9.46047 8.93997 9.46047 8.51997 9.20047 8.25997L5.94047 4.99997L9.20047 1.73997C9.4538 1.48664 9.4538 1.05997 9.20047 0.806638Z" />
  </StyledSvg>
);

export const DeleteAttachment: React.FC<{
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ disabled, onClick }) => (
  <IconButton color="inherit" disabled={disabled} onClick={onClick}>
    <CloseIcon $disabled={disabled} />
  </IconButton>
);
