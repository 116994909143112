import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { TrackingEvent } from '@pro4all/shared/config';
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useDataViewContext } from './DataViewProvider';
import { DataViewType } from './types';

const DataViewContextToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DataViewContextToggle: React.FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { params } = useRouting();
  const { projectId } = params;
  const { projectOrganizationId } = useProjectContext();

  const { availableViews, currentView, setCurrentView, viewKey } =
    useDataViewContext();

  function getIconName(viewType: DataViewType) {
    switch (viewType) {
      case DataViewType.Table:
        return 'tableRows';
      case DataViewType.Cards:
        return 'viewModule';
      case DataViewType.Charts:
        return 'barChart';
      case DataViewType.Calendar:
        return 'calendar';
      default:
        return 'tableRows';
    }
  }

  function getDisplayName(viewType: DataViewType) {
    switch (viewType) {
      case DataViewType.Table:
        return t('Table');
      case DataViewType.Cards:
        return t('Cards');
      case DataViewType.Charts:
        return t('Charts');
      case DataViewType.Calendar:
        return t('Calendar');
      default:
        return t('Table');
    }
  }

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    viewType: DataViewType
  ) => {
    if (viewType === currentView || !viewType) return;
    track(TrackingEvent.DataViewChanged, {
      projectId,
      projectOrganizationId,
      viewKey: `${viewKey}`,
      viewType: viewType,
    });

    // Unconventional null check is required to enforce a value to be set at all times
    // as describes in the docs: https://mui.com/material-ui/react-toggle-button/#enforce-value-set
    viewType !== null && setCurrentView(viewType);
  };

  return (
    <DataViewContextToggleWrapper>
      {availableViews.length > 1 && (
        <ToggleButtonGroup
          exclusive
          onChange={handleChange}
          size="small"
          value={currentView}
        >
          {availableViews.map((viewType, index) => (
            <Tooltip
              key={index}
              placement="top"
              title={getDisplayName(viewType)}
            >
              <ToggleButton
                data-testid={`${'data-view-toggle'}-${currentView}`}
                key={`${'data-view-toggle'}-${currentView}`}
                value={viewType}
              >
                <Icon iconName={getIconName(viewType)}></Icon>
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      )}
    </DataViewContextToggleWrapper>
  );
};

export default DataViewContextToggle;
