import { useEffect } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';

import { BaseRow, ColumnResizeEnd, ColumnSizes } from '../types';

export function useOnColumnResize<Row extends BaseRow>(
  tableId: string,
  onColumnResizeCallback?: (value: ColumnSizes) => void
) {
  const { localStorageItem: columnSizes, setLocalStorageItem } =
    useLocalStorage<ColumnSizes>({
      key: `prostream-column-sizes-${tableId}`,
    });

  useEffect(() => {
    // Because the tableId might include data that is loaded async,
    // the initialization of the state prop 'columnSizes' might be done
    // based on a 'undefined' part. In that case we need to set the column
    // sizes again when the data has been loaded and therefore the tableId
    // prop has changed. For instance the tableId for the documents table
    // contains the folderId, which is loaded asynchronously.

    // Cannot use the hook `usePersistentState` in a useEffect, so we have to get the value like so.
    const sizes = window.localStorage.getItem(
      `p4a:ps:prostream-column-sizes-${tableId}`
    );
    if (sizes) {
      setLocalStorageItem(JSON.parse(sizes));
    }
  }, [setLocalStorageItem, tableId]);

  const onColumnResize = (resizeData: ColumnResizeEnd<Row>) => {
    const {
      column: { key, width },
    } = resizeData;

    const roundedWidth = Math.round(width);

    const updatedColumnSizes = columnSizes
      ? { ...columnSizes, [key]: roundedWidth }
      : { [key]: roundedWidth };

    setLocalStorageItem(updatedColumnSizes);
    onColumnResizeCallback && onColumnResizeCallback(updatedColumnSizes);
  };

  return {
    columnSizes,
    onColumnResize,
  };
}
