import isEqual from 'react-fast-compare';

import { addReadAllVersionsPermission } from '../../shared/helpers/addReadAllVersionsPermission';
import { EntityPermission, State } from '../types';

import { getInheritedFromFolderPermissions } from './getInheritedFromFolderPermissions';
import { getOtherEntityPermissions } from './getOtherEntityPermissions';

export const getUpdatedSaveAndDisplayPermissions = ({
  // If this method is called from the matrix on selected folder, the subfolder already contains all the inherited permissions.
  // The prop `includeInherited` is used to prevent adding f.i. both a `ReadOwn` and `ReadOwnAndFinalized` permission.
  // Else this would happen if the user changes `ReadOwnAndFinalized` to `ReadOwn`.
  includeInherited = true,
  state,
  updatedFolderPermission,
}: {
  includeInherited?: boolean;
  state: State;
  updatedFolderPermission: EntityPermission;
}) => {
  const { initialPermissionsExplicit, savePermissions, displayPermissions } =
    state;

  const savePermissionsOtherEntities = getOtherEntityPermissions(
    updatedFolderPermission.entityId,
    savePermissions
  );

  const inheritedFromFolderPermissions = includeInherited
    ? getInheritedFromFolderPermissions({
        entityPermission: updatedFolderPermission,
      })
    : [];

  // If we put the breakInheritance prop to false, we have to add the inherited permissions to the folderPermissions prop.
  const updatedFolderPermissionWithInheritedPermissions = {
    ...updatedFolderPermission,
    folderPermissions: updatedFolderPermission.breakInheritance
      ? updatedFolderPermission.folderPermissions
      : [
          ...new Set([
            ...inheritedFromFolderPermissions,
            ...updatedFolderPermission.folderPermissions,
          ]),
        ],
  };

  // In case the user did select any documents permission (`ReadAllDocuments`, `ReadOwn` or `ReadOwnAndFinalized`)
  // and currently there's no active versions permission, we add the `ReadAllVersions` permission to the folderPermissions prop.
  const folderPermissions =
    updatedFolderPermissionWithInheritedPermissions.folderPermissions;
  const updatedFolderPermissionWithVersionsPermission = {
    ...updatedFolderPermissionWithInheritedPermissions,
    folderPermissions: addReadAllVersionsPermission(folderPermissions),
  };

  // Now we have to check if the updated permissions of the entity is equal to the initial state.
  // In that case we do not have to save the permissions for this entity,
  // so we don't have to add it the the savePermissions state prop.

  const initialEntityPermission = initialPermissionsExplicit.find(
    (perm) =>
      perm.entityId === updatedFolderPermissionWithVersionsPermission.entityId
  );
  const updatedPermissionSorted =
    initialEntityPermission && initialEntityPermission.folderPermissions
      ? [...initialEntityPermission.folderPermissions].sort()
      : null;

  const isEqualToInitialState =
    isEqual(
      updatedFolderPermissionWithVersionsPermission.folderPermissions.sort(),
      updatedPermissionSorted
    ) &&
    initialEntityPermission.breakInheritance ===
      updatedFolderPermissionWithVersionsPermission.breakInheritance;

  const combinedOtherEntityAndUpdateFolderPermissions = [
    ...savePermissionsOtherEntities,
    updatedFolderPermissionWithVersionsPermission,
  ];

  const displayPermissionsUpdated = [
    ...combinedOtherEntityAndUpdateFolderPermissions,
    ...displayPermissions.filter(
      (entity) =>
        !combinedOtherEntityAndUpdateFolderPermissions.find(
          (saveEntity) => saveEntity.entityId === entity.entityId
        )
    ),
  ];

  return isEqualToInitialState
    ? {
        displayPermissionsUpdated,
        savePermissionsUpdated: savePermissionsOtherEntities,
      }
    : {
        displayPermissionsUpdated,
        savePermissionsUpdated: combinedOtherEntityAndUpdateFolderPermissions,
      };
};
