import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableNode } from '@pro4all/objects/utils';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';

import * as Styled from './Row.styles';
import { useDragAndDropObject } from './useDragAndDropObject';
import { useEditObject } from './useEditObject';

interface Props extends TableNode {
  depth: number;
  expanded: boolean;
  toggleRow: (rowKey: string) => void;
}

export const Row: React.FC<Props> = ({
  depth,
  expanded,
  toggleRow,
  ...node
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { copyObject, deleteObject, editObject, editing, saveName } =
    useEditObject({ node, toggleRow });
  const { canDrop, drop, drag, hoversOverCurrent, isDragging } =
    useDragAndDropObject(node);
  const hasChildren = node?.children && node.children.length > 0;

  const rowContent = (
    <>
      {editing ? (
        <Styled.Input
          aria-label="New object name"
          autoFocus
          defaultValue={node.name}
          name="name"
          onBlur={(event) => {
            event.currentTarget.value !== ''
              ? saveName(event.currentTarget.value)
              : deleteObject();
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') event.currentTarget.blur();
            if (event.key === 'Escape') {
              event.stopPropagation();
              event.currentTarget.value = node.name;
              event.currentTarget.blur();
            }
          }}
        />
      ) : (
        <Styled.Name>{node.name ? node.name : t('New object')}</Styled.Name>
      )}

      <Styled.ButtonWrapper>
        <IconButton
          aria-label={`Copy ${node.name}`}
          color="default"
          disableBorder
          iconName="copyFile"
          onClick={copyObject}
        />
        <IconButton
          aria-label={`Edit ${node.name}`}
          color="default"
          disableBorder
          iconName="edit"
          onClick={editObject}
        />
        <IconButton
          aria-label={`Delete ${node.name}`}
          color="default"
          disableBorder
          iconName="deleteOutline"
          onClick={deleteObject}
        />
      </Styled.ButtonWrapper>
    </>
  );

  // Create array for mapped rendering with indents and guides
  const indents = Array.from({ length: depth });

  return (
    <>
      {indents.map((_, index) => (
        <Styled.Indent $depth={depth} $expandable={hasChildren} key={index}>
          <Styled.Guide />
        </Styled.Indent>
      ))}

      {drag(
        drop(
          <div style={{ flex: 1, overflow: 'hidden' }}>
            <Styled.Header
              $bgColor={
                hoversOverCurrent && canDrop
                  ? theme.palette.primary.light
                  : customColors.white
              }
              $canDrag={!node.tbqType}
              $dragging={isDragging}
              $expandable={hasChildren}
              $focus={editing}
              $hoverColor={customColors.grey300}
              $rounded
              $showBorder
            >
              {hasChildren ? (
                <Styled.Toggle
                  checked={!expanded}
                  onChange={() => toggleRow(node.id)}
                >
                  {rowContent}
                </Styled.Toggle>
              ) : (
                rowContent
              )}
            </Styled.Header>
          </div>
        )
      )}
    </>
  );
};
