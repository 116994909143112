import React from 'react';

import { Box, BoxProps } from '@pro4all/shared/mui-wrappers';

import { RouterTabs } from './RouterTabs';

interface Props extends BoxProps {
  base: string;
}

export const SubTabs: React.FC<Props> = ({ base, children, ...boxProps }) => (
  <Box m={1} {...boxProps}>
    <RouterTabs base={base}>{children}</RouterTabs>
  </Box>
);
