import type { Context, Key as ReactKey } from 'react';
import { createContext, RefObject, useContext, useEffect } from 'react';

import type { SetState } from '@pro4all/shared/utils';

export type Key = ReactKey | symbol;
export type Scope = Key | undefined;

export interface ToggleContext<K extends Key = Key> {
  // DEPRECATED: `container` should be its own hook.
  // You can use `container` to portal elements to the level where the
  // ToggleProvider is instantiated.
  container?: RefObject<HTMLElement>;
  // `setState` function for `toggles`. The array you pass will be made unique.
  setToggles: SetState<K[]>;
  // Toggles `key` on or off. Use `force` to force a state.
  toggle: (key: K, force?: boolean) => void;
  // Check if `key` is toggled.
  toggled: (key: K) => boolean;
  // The array of toggles.
  toggles: K[];
}

type ContextArg = ToggleContext | undefined;
type ContextResult = Context<ToggleContext | undefined>;

/**
 * In order to support multiple (nested or parallel) `ToggleScope`s we cache
 * every `scope` to store stable references.
 */
const cache = new Map<Scope, ContextResult>();
const ToggleContext = createContext<ContextArg>(undefined);

/**
 * Creates a new `ToggleContext` using `scope`. If `scope` is not set, returns
 * the default, top-level, `ToggleContext`.
 */
export function useToggleScope(scope?: Scope): ContextResult {
  useEffect(
    () => () => {
      scope && cache.delete(scope);
    },
    [scope]
  );

  if (!scope) return ToggleContext;
  if (cache.has(scope)) return cache.get(scope) as ContextResult;

  const context = createContext<ContextArg>(undefined);
  cache.set(scope, context);

  return context;
}

/**
 * Use the `ToggleContext` from `scope`, else use the default `ToggleContext`.
 */
export function useToggleContext(scope?: Scope) {
  const context = scope ? cache.get(scope) : ToggleContext;
  return useContext(context ?? ToggleContext);
}
