import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { FolderPermission } from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { Option } from '@pro4all/shared/types';

import { usePermissionsEntityMatrixContext } from '../entity-context/PermissionsEntityMatrixProvider';
import { shouldAlterPermission } from '../shared/helpers/shouldAlterPermission';
import { ExclusivePermissionType, OptionValue } from '../types/types';

export const useExclusivePermissionEntityChangeHandler = ({
  entityId,
  entityPermissions,
  exclusivePermissionType,
  folderId,
  selectedOption,
  setSelectedOption,
}: {
  entityId: string;
  entityPermissions: FolderPermission[];
  exclusivePermissionType: ExclusivePermissionType;
  folderId: string;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setPermission } = usePermissionsEntityMatrixContext();
  const { approve_action_label, finalize_action_label } =
    useDocumentActionsLabels();

  const handleChange = async (option: Option) => {
    const selectedPermission =
      option.id !== OptionValue.None
        ? ([option.id] as FolderPermission[])
        : ([] as FolderPermission[]);

    const alterPermissionResult = shouldAlterPermission(
      selectedPermission,
      entityPermissions
    );
    if (!alterPermissionResult.shouldAlterPermission) {
      enqueueSnackbar(
        t(alterPermissionResult.message, {
          name: alterPermissionResult.isApproved
            ? approve_action_label
            : finalize_action_label,
        })
      );
      setSelectedOption(selectedOption);
      return;
    }

    const permission = option.id as FolderPermission;

    setPermission({
      entityId,
      exclusivePermissionType,
      folderId,
      permission,
    });
  };

  return handleChange;
};
