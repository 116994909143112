import { changeItem } from '../helpers/changeItem';
import { BasePropsHierarchyItem, ItemProps } from '../types';

export const addItemAction = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  newItem,
}: Pick<ItemProps<HierarchyItem>, 'allItems' | 'newItem'>) => {
  let updatedItems = allItems;

  if (newItem.previousNodeId) {
    // Check if there is an item that previously had the same previousNodeId as the new item.
    // This item must get the id of the new item as value for previousNodeId.
    const previousItemNext = allItems.find(
      (item) => item.previousNodeId === newItem.previousNodeId
    );
    if (previousItemNext) {
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...previousItemNext,
          previousNodeId: newItem.id,
        },
      });
    }
  } else {
    // Check if there is an item that previously acted as the first child.
    // The new item will become the first child and the previous first child will become the second child.
    const previousFirstItem = allItems.find(
      (item) =>
        item.previousNodeId === null &&
        item.parentNodeId === newItem.parentNodeId
    );
    if (previousFirstItem) {
      updatedItems = changeItem<HierarchyItem>({
        allItems: updatedItems,
        item: {
          ...previousFirstItem,
          previousNodeId: newItem.id,
        },
      });
    }
  }

  return [...updatedItems, newItem];
};
