import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import {
  useActionHelpCenter,
  useActionLogin,
} from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

export const SsoFailPage = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();
  const actionHelpCenter = useActionHelpCenter();
  const actionLogin = useActionLogin({ icon: 'arrowBack', label: 'Try again' });

  const description = (
    <>
      {t(
        "It looks like we can't find your account. This can be caused by the following"
      )}
      :
      <br />
      <ul>
        <li>
          {t(
            "Your organization isn't registered yet. Register it first before logging in."
          )}
        </li>
        <li>
          {t(
            "The organization exists, but your email address hasn't been added to it yet. Ask the administrator to add you to the organization."
          )}
        </li>
        <li>{t('Double check the email address and try again.')}</li>
      </ul>
    </>
  );

  const actions: ActionProps[] = [
    actionHelpCenter,
    actionLogin,
    {
      ariaLabel: 'Register organization',
      color: 'inherit',
      dataTestId: 'register-organization',
      key: 'register-organization',
      label: t('Register organization'),
      onClick: () => goTo('registration'),
      startIcon: 'prostream',
    },
  ];

  return (
    <InfoPage
      actions={actions}
      description={description}
      title={t('Oops! we couldn’t find that account')}
      type="warning"
    />
  );
};
