import { useRef } from 'react';
import {
  FormProvider,
  Resolver,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createEditor, Descendant, Editor } from 'slate';
import { ReactEditor, withReact } from 'slate-react';
import styled from 'styled-components';

import {
  AttachmentInput,
  defaultEditorValues,
  EditorButton,
  ImageUploading,
  SlateEditor,
  useImageUpload,
  withImages,
} from '@pro4all/communication/ui/general';
import { Box } from '@pro4all/shared/mui-wrappers';
import { StylingDefaults } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';
import { tryParseString } from '@pro4all/shared/utils';

import { useAddSignatureHandler } from './useAddSignatureHandler';

type SignatureFormValues = { body: string };

type TSignatureFormProps = {
  signatureBody?: string;
  signatureId?: string;
  signatureIsLoading?: boolean;
};

export const SignatureForm = ({
  signatureBody,
  signatureId,
  signatureIsLoading,
}: TSignatureFormProps) => {
  const { t } = useTranslation();

  const { addSignatureHandler, addSignatureIsLoading } =
    useAddSignatureHandler();

  const editorRef = useRef<Editor>();
  if (!editorRef.current)
    editorRef.current = withImages(withReact(createEditor() as ReactEditor));
  const editor = editorRef.current;

  const resolver: Resolver<SignatureFormValues> = async (values) => {
    const maxCharacters = 8000;
    const errors = {};
    const bodyLength = values.body?.length || 0;
    if (bodyLength > maxCharacters) {
      Object.assign(errors, {
        body: {
          message: t('At max {{number}} characters', { number: maxCharacters }),
        },
      });
    }

    return {
      errors,
      values,
    };
  };

  const imageInputId = `image-input-upload`;
  const onUploadSuccess = () => form.trigger();

  const { handleImageUpload, isImageUploading = false } =
    useImageUpload<SignatureFormValues>({
      editor,
      editorName: 'body',
      onSuccess: onUploadSuccess,
    });

  const form = useForm<SignatureFormValues>({
    defaultValues: {
      body: signatureBody || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const value: Descendant[] =
    tryParseString(form.getValues().body) || defaultEditorValues;

  const onSubmit: SubmitHandler<SignatureFormValues> = async (data) => {
    addSignatureHandler(JSON.stringify(data.body), signatureId as string);
  };

  const onChange = async (value: string) => {
    form.setValue('body', value);
  };

  const isLoading = signatureIsLoading || addSignatureIsLoading;

  const errorMessage = form.errors.body?.message || '';

  const openFileInput = (currentInputId: string) => {
    const fileInput = document.getElementById(currentInputId);
    fileInput && fileInput.click();
  };

  const CustomButtons: React.ReactNode[] = [
    <EditorButton
      disabled={isImageUploading}
      key="staticImage"
      onClick={() => openFileInput(imageInputId)}
      title={t('Add image')}
    >
      <Icon iconName="image" />
    </EditorButton>,
  ];

  const withPlaceholder =
    JSON.stringify(value) === '[{"type":"Line","children":[{"text":""}]}]';

  return (
    <Box mt={2}>
      <FormProvider {...form}>
        <Box p={1}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <SBox p={2}>
              <SlateEditor
                CustomButtons={CustomButtons}
                autofocus={false}
                displayGroups
                editor={editor}
                editorMaxHeight={500}
                editorMinHeight={30}
                errorMessage={errorMessage}
                onChange={onChange}
                placeholder="Your signature" //i18n
                readOnly={isImageUploading}
                showToolbar
                value={value}
                withPlaceholder={withPlaceholder}
              />
            </SBox>
            <ImageUploading isLoading={isImageUploading} />
            <AttachmentInput
              id={imageInputId}
              multiple
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleImageUpload(event, form.setValue, form.getValues)
              }
              type="file"
            />
            <SFormFooter>
              <Button
                aria-label={t('Add attachment')}
                disabled={isLoading || isImageUploading}
                type="submit"
                variant="contained"
              >
                {t('Save and close')}
              </Button>
            </SFormFooter>
          </form>
        </Box>
      </FormProvider>
    </Box>
  );
};

const SFormFooter = styled(Box)`
  && {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const SBox = styled(Box)`
  && {
    max-width: 21cm;
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-radius: ${StylingDefaults.borderRadiusSmall};
  }
`;
