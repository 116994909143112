import { useEffect, useRef } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';

interface DataObject {
  account: string;
  applicationId: string;
  user: {
    email: string;
    id: string;
    name: string;
  };
}

declare global {
  interface Window {
    aha: (action: string, data: string | DataObject) => void;
    'aha-widget': string;
  }
}

export function useIdeaWidget() {
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;
    if (!AuthService.isAuthenticated() || !window.aha) return;

    const { email, name, userId } = AuthService.getProfile();

    window.aha('initialize', {
      account: 'pro4all',
      applicationId: '6941401088345341355',
      user: {
        email,
        id: userId,
        name,
      },
    });

    initialized.current = true;
  }, []);

  // NOTE: I don't exactly know why, but we need to hard-core the applicationId
  // here. Referencing a constant defined in the top-level scope of this module
  // makes aha crap out..?
  return () => initialized.current && window.aha('open', '6952853491282538520');
}
