import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardActionArea, CardProps } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import { ContextMenuInstance } from '@pro4all/shared/ui/context-menu-instance';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

import {
  CardMetaItem,
  CardMetaList,
  CardStyle,
  StyledCard,
  StyledCardHeader,
} from './Card.styles';

export interface CardPropsInterface extends CardProps {
  cardStyle?: CardStyle;
  deleted?: boolean;
  disabledButtonBorder?: boolean;
  disabledCardBorder?: boolean;
  iconComponent?: React.ReactNode;
  iconName?: IconName;
  menuItems?: ActionProps[];
  meta?: React.ReactNode;
  onClick?: () => void;
  onContextMenu?: () => void;
  title?: string;
  titleWithElement?: React.ReactNode;
}

export const Card: React.FC<CardPropsInterface> = ({
  cardStyle = 'default',
  deleted,
  iconName = 'file',
  menuItems = [],
  disabledButtonBorder = true,
  meta,
  onClick,
  onContextMenu,
  title,
  titleWithElement,
  iconComponent = null,
}) => {
  const { t } = useTranslation();
  const determineAction = () =>
    deleted ? (
      <span>{t('Deleted')}</span>
    ) : (
      <ContextMenuInstance
        disableBorder={disabledButtonBorder}
        menuItems={menuItems}
        name={title}
        onContextMenu={onContextMenu}
      />
    );

  const cardMeta = meta && (
    <CardMetaList $cardStyle={cardStyle}>
      {React.Children.map(meta, (child) => (
        <CardMetaItem>{child}</CardMetaItem>
      ))}
    </CardMetaList>
  );

  return (
    <StyledCard $cardStyle={cardStyle} className={cardStyle} variant="outlined">
      <CardActionArea component="article" onClick={onClick}>
        <StyledCardHeader
          $cardStyle={cardStyle}
          action={determineAction()}
          avatar={iconComponent ? iconComponent : <Icon iconName={iconName} />}
          subheader={cardMeta}
          subheaderTypographyProps={{
            variant: 'body2',
          }}
          title={titleWithElement || title || t('Untitled')}
          titleTypographyProps={{ variant: 'h6' }}
        />
      </CardActionArea>
    </StyledCard>
  );
};
