import { FolderPermission } from '@pro4all/graphql';

import { addReadAllVersionsPermission } from '../../shared/helpers/addReadAllVersionsPermission';
import { getExclusivePermissionsBasedOnParentPermissions } from '../../shared/helpers/getExclusivePermissionsBasedOnParentPermissions';
import { getExclusivePermissionsToUse } from '../../shared/helpers/getExclusivePermissionsToUse';
import { permissionsToRemove } from '../../shared/helpers/permissionsToRemove';
import { getOtherFolderPermissions } from '../helpers/getOtherFolderPermissions';
import { getThisFolderPermission } from '../helpers/getThisFolderPermission';
import { getUpdatedSavePermissions } from '../helpers/getUpdatedSavePermissions';
import { getUpdatedSubfoldersSinglePermission } from '../helpers/getUpdatedSubfoldersSinglePermission';
import { PermissionSet, State } from '../types';

export const setPermissionAction = ({
  payload,
  state,
}: {
  payload: PermissionSet;
  state: State;
}) => {
  const { exclusivePermissionType, folderId, permission } = payload;
  const { initialPermissionsExplicit, savePermissions } = state;

  const otherFolderPermissions = getOtherFolderPermissions(
    folderId,
    state.displayPermissions
  );
  const thisFolderPermission = getThisFolderPermission(
    folderId,
    state.displayPermissions
  );

  const exclusivePermissionsToUse = getExclusivePermissionsToUse(
    exclusivePermissionType
  );

  let permissions = [
    ...new Set([
      ...exclusivePermissionsToUse,
      ...thisFolderPermission.folderPermissions,
    ]),
  ].filter((perm) => !permissionsToRemove.get(permission).includes(perm));

  let permissionReverted: FolderPermission = null;

  if (!thisFolderPermission.breakInheritance) {
    // The possible exclusive permissions depend on the parent,
    // we should check what exclusive permissions the parent holds.
    const parentFolder = getThisFolderPermission(
      thisFolderPermission.parentFolderId,
      state.displayPermissions
    );

    if (parentFolder) {
      const { permissionRevertedTo, subFolderPermissions } =
        getExclusivePermissionsBasedOnParentPermissions({
          currentSubFolderPermissions: thisFolderPermission.folderPermissions,
          parentFolderPermissions: parentFolder.folderPermissions,
          subFolderPermissions: permissions,
        });
      permissions = subFolderPermissions;
      permissionReverted = permissionRevertedTo;
    }
  }

  // In case the user did select any documents permission (`ReadAllDocuments`, `ReadOwn` or `ReadOwnAndFinalized`)
  // and currently there's no active versions permission, we add the `ReadAllVersions` permission to the folderPermissions prop.
  const updatedFolderPermission = {
    ...thisFolderPermission,
    folderPermissions: addReadAllVersionsPermission(permissions),
  };

  // Subfolder permissions may also change in case a permission of a parent folders is clicked.
  // So this `folderPermissionsWithInheritance` constant will include the permissions for the parent folder + all updated permissions of the subfolders.
  // ATTTENTION: This parent folder can be a subfolder on its own.
  const folderPermissionsWithInheritance = getUpdatedSubfoldersSinglePermission(
    {
      displayPermissions: state.displayPermissions,
      exclusivePermissionType,
      folderId,
      initialPermissionsExplicit,
      otherFolderPermissions,
      parent: updatedFolderPermission,
      permission: permissionReverted || permission,
      savePermissions,
    }
  );

  const savePermissionsUpdated = getUpdatedSavePermissions(
    updatedFolderPermission,
    state
  );

  return {
    ...state,
    displayPermissions: folderPermissionsWithInheritance,
    savePermissions: savePermissionsUpdated,
  };
};
