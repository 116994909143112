import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchDocument } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  BarChart,
  ChartsWrapper,
  DonutChart,
  LoadingOverlay,
  ValueChart,
} from '@pro4all/shared/ui/charts';
import { Loader } from '@pro4all/shared/ui/general';

import { useDonutOptionsSearch } from './useDonutOptionsSearch';
import { useTimeClusterOptionsSearch } from './useTimeClusterOptionsSearch';

const DocumentSearchCharts: React.FC<{
  loading: boolean;
  searchDocuments: SearchDocument[];
}> = ({ loading, searchDocuments }) => {
  const { t } = useTranslation();

  const donutOptions = useDonutOptionsSearch();
  const timeClusterOptions = useTimeClusterOptionsSearch();

  return (
    <Box p={2} sx={{ flexGrow: 'inherit' }}>
      <ChartsWrapper columns={3} rows={2}>
        {loading && (
          <LoadingOverlay>
            <Loader />
          </LoadingOverlay>
        )}
        <ValueChart
          afterValue={` ${t('Documents')}`}
          chartKey="search-value-top-left"
          columnSpan={1}
          rowSpan={1}
          title={`${t('Search results')}`}
          value={searchDocuments.length.toString()}
        ></ValueChart>
        <DonutChart
          chartKey="search-donut-top-left"
          columnSpan={1}
          data={searchDocuments}
          initialOptionId="updatedBy"
          options={donutOptions}
          rowSpan={1}
        />
        <DonutChart
          chartKey="search-donut-top-right"
          columnSpan={1}
          data={searchDocuments}
          initialOptionId="extension"
          options={donutOptions}
          rowSpan={1}
        />
        <BarChart
          chartKey="search-bar-right"
          columnSpan={2}
          data={searchDocuments}
          initialTimeClusterOptionId="updatedAt"
          initialTimeOptionId="month"
          rowSpan={1}
          timeClusterOptions={timeClusterOptions}
        />
      </ChartsWrapper>
    </Box>
  );
};

export default DocumentSearchCharts;
