import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const useDisableSave = ({
  uploadContext,
}: {
  uploadContext: boolean;
}) => {
  const {
    state: { isProcessed, isProcessing, meta },
  } = useUploaderEditorContext();

  if ((isProcessing || isProcessed) && uploadContext) return true;
  if (isProcessing && !uploadContext) return true;

  let disableSave = false;

  // Check if one of the documents has an error in the name.
  meta.forEach((document) => {
    if (document.error) {
      disableSave = true;
      return;
    }
  });

  // Check for errors.
  if (!disableSave) {
    meta.forEach((document) => {
      if (document.metaData) {
        document.metaData.forEach((metaDataCell) => {
          if (metaDataCell.error) {
            disableSave = true;
            return;
          }
        });
      }
      if (disableSave) return;
    });
  }

  // Check for changes, only relevant if there are no errors.
  // Because if there are errors, the save buttons are disabled regardless of whether there are changes or not.
  if (!disableSave && !uploadContext) {
    // There are no errors, reset `disableSave` to true and change it to false in case there are changes.
    // Because in case there are changes we wanna enable the save buttons.
    disableSave = true;
    meta.forEach((document) => {
      // Check `name`.
      if (document.name !== document.nameInitial) {
        disableSave = false;
        return;
      }

      // Check metadata.
      if (document.metaData) {
        document.metaData.forEach((metaDataCell) => {
          if (metaDataCell.value !== metaDataCell.valueInitial) {
            disableSave = false;
            return;
          }
        });
      }
      if (!disableSave) return;
    });
  }

  return disableSave;
};
