import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ValueTypeName } from '@pro4all/graphql';
import { FormFieldConfig, SchemaExtender } from '@pro4all/shared/forms';

export const useSharedFormFieldsConfig = () => {
  const { t } = useTranslation();

  const confirmPasswordConfig: FormFieldConfig = {
    displayName: 'Confirm password', // i18n
    name: 'confirmPassword',
    required: true,
    type: ValueTypeName.Text,
  };

  const confirmPasswordCustomValidation: SchemaExtender<Yup.StringSchema> = (
    schema
  ) => schema.oneOf([Yup.ref('password'), null], t('Passwords must match'));

  const firstNameConfig: FormFieldConfig = {
    displayName: 'First name', // i18n
    matches: /^[A-Za-z0-9-'`_ \p{L}]+$/u,
    name: 'firstName',
    required: true,
    type: ValueTypeName.Text,
  };

  const lastNameConfig: FormFieldConfig = {
    displayName: 'Last name', // i18n
    matches: /^[A-Za-z0-9-'`_ \p{L}]+$/u,
    name: 'lastName',
    required: true,
    type: ValueTypeName.Text,
  };

  const passwordConfig: FormFieldConfig = {
    displayName: 'Password', // i18n
    min: 8,
    name: 'password',
    required: true,
    type: ValueTypeName.Text,
  };

  const phoneNumberConfig: FormFieldConfig = {
    displayName: 'Phone number', // i18n
    label: '+31612345678',
    name: 'phoneNumber',
    phoneNumber: true,
    type: ValueTypeName.Text,
  };

  return {
    confirmPasswordConfig,
    confirmPasswordCustomValidation,
    firstNameConfig,
    lastNameConfig,
    passwordConfig,
    phoneNumberConfig,
  };
};
