import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { InjectDummyDataProps } from '@pro4all/shared/types';

import { useSetDummyDataPerFieldType } from './useSetDummyDataPerFieldType';

export const useInjectDummyData = () => {
  const { setDummyDataPerFieldType } = useSetDummyDataPerFieldType();

  const injectDummyData = ({ items }: InjectDummyDataProps) => {
    const filledItems = items.map((item) => {
      if (item.type === ValueTypeName.Section && item.valueType?.subFields) {
        return {
          ...item,
          valueType: {
            ...item.valueType,
            subFields: injectDummyData({ items: item.valueType.subFields }),
          },
        };
      }

      return {
        ...item,
        value: setDummyDataPerFieldType({
          type: item.type || ValueTypeName.Text,
        }),
      };
    }) as FieldDefinition[];
    return filledItems;
  };

  return { injectDummyData };
};
