import React, { useMemo } from 'react';

import {
  Task,
  TaskFragmentFragment,
  TaskType,
  useProjectTasksQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { useSetItemsInLocalState } from '@pro4all/shared/ui/general';
import { TasksMain, TaskTablePostFix } from '@pro4all/shared/ui/tasks';

import { TasksActionBar } from './TaskActionBar';

export const Workflow: React.FC = () => {
  const {
    params: { projectId = '' },
  } = useRouting();

  const { data, loading } = useProjectTasksQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    variables: { id: projectId },
  });

  const tasks = useMemo(
    () => data?.project.mainProcedure?.tasks || [],
    [data?.project.mainProcedure?.tasks]
  ) as Task[];

  useSetItemsInLocalState<TaskFragmentFragment>(
    data?.project?.mainProcedure?.tasks
  );

  const procedureId = data?.project?.mainProcedure?.id;

  return (
    <DataViewContextProvider
      initialView={DataViewType.Table}
      viewKey="project-workflow"
      views={[DataViewType.Table, DataViewType.Charts, DataViewType.Calendar]}
    >
      <TasksMain
        TasksActionBar={<TasksActionBar dataViewToggle />}
        hideBorder
        hideProject
        hideUser={false}
        loading={loading}
        mainProcedureId={procedureId}
        noTasksText="Have they been completed? On a roll! Need new ones? Create them."
        noTasksTitle="You don't have any document tasks yet"
        postfix={TaskTablePostFix.ALL}
        tasks={tasks}
        typeFilter={[
          TaskType.Document,
          TaskType.QualityControl,
          TaskType.Resolve,
          TaskType.Tbq,
          TaskType.TbqResolve,
          TaskType.TbqScan,
        ]}
      ></TasksMain>
    </DataViewContextProvider>
  );
};

export default Workflow;
